import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { ConsentToHandlePersonalDataSchema } from "lib/validation/ConsentToHandlePersonalDataSchema";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideReadonlyText } from "uiLibrary/components/RideReadonlyText/RideReadonlyText";
import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";

const ConsentToHandlePersonalDataStep = ({
  order,
  controls,
  saveData,
  currentStep,
  t
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const initialValues = {
    consentToHandlePersonalData: order?.extra?.consentToHandlePersonalData ?? false
  };
  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        consentToHandlePersonalData: values.consentToHandlePersonalData,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: ConsentToHandlePersonalDataSchema(t)
  });

  return (
    <div data-testid="consent-to-handle-personal-data-step">
      <FormikProvider value={formik}>
        <Form>
          <ConsentToHandlePersonalData />
          {controls}
        </Form>
      </FormikProvider>
    </div>
  );
};

const ConsentToHandlePersonalData = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div data-testid="consent-to-handle-personal-data" className="consent-to-handle-personal-data">
      <StepHeading
        text={t("generic:ride-broker-onboarding.consent-to-handle-personal-data.title")}
      />
      <RideReadonlyText
        text={t("generic:ride-broker-onboarding.consent-to-handle-personal-data.description")}
      />
      <RideCheckbox
        name="consentToHandlePersonalData"
        label={t("generic:ride-broker-onboarding.consent-to-handle-personal-data.label")}
        className="consent-to-handle-personal-data__accept-checkbox"
      />
    </div>
  );
});
export default withTranslationReady(["generic"])(ConsentToHandlePersonalDataStep);
