import React, { useState } from "react";
import RideInput, { InputProps } from "../RideInput/RideInput";
import { EyeOn } from "../../icons/EyeOn";
import { EyeOff } from "../../icons/EyeOff";

interface PasswordProps extends InputProps {
  showClearText?: boolean;
  shouldShowErrorFeedback?: boolean;
}

export const RidePassword = ({
  className = "",
  showClearText,
  children,
  shouldShowErrorFeedback = true,
  ...args
}: PasswordProps) => {
  const [isClear, setIsClear] = useState(showClearText);

  const handleToggle = () => {
    setIsClear(!isClear);
  };

  const type = isClear ? "text" : "password";

  const toggleButton = (
    <button
      onClick={handleToggle}
      type="button"
      data-testid="password-visibility-toggle"
      className="ride-password__toggle">
      {isClear ? <EyeOn /> : <EyeOff />}
    </button>
  );

  return (
    <RideInput
      appendComponent={toggleButton}
      shouldShowErrorFeedback={shouldShowErrorFeedback}
      type={type}
      className={`ride-password ${className}`}
      {...args}>
      {children}
    </RideInput>
  );
};

export default RidePassword;
