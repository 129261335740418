import React from "react";
import {
  FiscalYearDataUploadConfirmationContainer,
  FiscalYearDataUploadConfirmationContainerProps
} from "../../../client/components/FiscalYearDataUploadConfirmation/FiscalYearDataUploadConfirmationContainer";

const AdminFiscalYear = (props: FiscalYearDataUploadConfirmationContainerProps) => (
  <FiscalYearDataUploadConfirmationContainer {...props} />
);

export default AdminFiscalYear;
