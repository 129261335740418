import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";

const RealStateQuestion = ({ t }: WithTranslation) => {
  const module = "real-state-question";

  return (
    <div data-testid={module} className={module}>
      <Typography category={"Headline"} size={300} weight={"Heavy"} data-testid={`${module}-title`}>
        {t("generic:short-order-form.real-state-question.title")}
      </Typography>
      <RideRadioSelect
        className={`${module}__options`}
        name={"companyOwnsRealEstate"}
        options={[
          {
            label: t("generic:short-order-form.real-state-question.yes-up-to-15"),
            value: "yes-up-to-15"
          },
          {
            label: t("generic:short-order-form.real-state-question.yes-more-than-15"),
            value: "yes-more-than-15"
          },
          {
            label: `${t("generic:no")}.`,
            value: "no"
          }
        ]}
        variant={RadioSelectVariant.Light}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(RealStateQuestion);
