import React from "react";
import OrderCompanyFoundingContainer from "../../components/OrderCompanyFounding/OrderCompanyFoundingContainer";
import GuestLayout from "../../../sharedComponents/templates/GuestLayout/GuestLayout";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../global-query-types";
import { useHistory } from "react-router-dom";
import { DisabledFeatureFlag } from "../../../sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";

const OrderCompanyFoundingScene = () => {
  const sunsetOldFlow = FeatureFlagService.instance.isEnabled(FeatureFlags.SunsetOldOrderFlows);
  const history = useHistory();

  if (sunsetOldFlow) history.push("/order/account-creation/start");

  return (
    <DisabledFeatureFlag name={FeatureFlags.SunsetOldOrderFlows}>
      <GuestLayout>
        <div className="order-form-scene" data-testid={"order-company-founding-scene"}>
          <OrderCompanyFoundingContainer />
        </div>
      </GuestLayout>
    </DisabledFeatureFlag>
  );
};

export default OrderCompanyFoundingScene;
