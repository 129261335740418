import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { CompanyFields } from "./CompanyFields";
import {
  CompanyAddress,
  FlowTypeEnum,
  LegalForm,
  OrdererCompanyData
} from "../../../../../global-query-types";
import { START_ADMIN_SERVICE, UPDATE_ORDERER_COMPANY } from "../../../CompanyCreation/Mutations";
import { useMutation } from "react-apollo";
import {
  StartAdminService,
  StartAdminServiceVariables
} from "../../../CompanyCreation/graphql/StartAdminService";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import logger from "../../../../../common/Logger";
import { AssertionError } from "assert";
import { useHistory, useLocation } from "react-router";
import { TFunction } from "i18next";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../CompanyCreation/graphql/MyCompanyCreationQuery";
import {
  UpdateOrdererCompany,
  UpdateOrdererCompanyVariables
} from "../../../CompanyCreation/graphql/UpdateOrdererCompany";
import { AdminServiceCompanyInfoSchema } from "../../../../../lib/validation/AdminServiceCompanyInfoSchema";
import { getReferral } from "../../../../../common/getReferral";
import { storeToken } from "../../../../../common/Authentication/storeToken";
import { adminServiceLegalForms } from "../../../../../common/staticData/LegalForms";

interface CompanyFormProps {
  t: TFunction;
  myCompanyCreationDetails?: MyCompanyCreationQuery_myCompanyCreation;
  refetchAndResetStep: (id: string) => Promise<void>;
  currentFlowType: FlowTypeEnum;
}

export const CompanyForm = ({
  t,
  myCompanyCreationDetails,
  refetchAndResetStep,
  currentFlowType
}: CompanyFormProps) => {
  const history = useHistory();
  const location = useLocation();
  const referral = getReferral(location);

  const disabled = myCompanyCreationDetails?.company?.locked ?? false;

  const [startAdminService, { error: errorStarting, loading: loadingStarting }] = useMutation<
    StartAdminService,
    StartAdminServiceVariables
  >(START_ADMIN_SERVICE);

  const [updateOrdererCompany, { error: errorUpdating, loading: loadUpdating }] = useMutation<
    UpdateOrdererCompany,
    UpdateOrdererCompanyVariables
  >(UPDATE_ORDERER_COMPANY);

  const error = errorStarting || errorUpdating;
  const loading = loadingStarting || loadUpdating;

  const submitHandler = async (values) => {
    const companyInfo: OrdererCompanyData = {
      name: values.companyName,
      legalForm: values.legalForm,
      registration: values.registration,
      registerCourt: values.registerCourt,
      economicId: values.economicId ?? "",
      taxNumber: values.taxNumber ?? ""
    };
    const businessAddress: CompanyAddress = {
      city: values.city,
      street: values.street,
      postalCode: values.postalCode
    };

    if (myCompanyCreationDetails?.id) {
      try {
        await updateOrdererCompany({
          variables: {
            companyCreationId: myCompanyCreationDetails.id,
            companyInfo,
            businessAddress
          }
        });

        await refetchAndResetStep(myCompanyCreationDetails.id);
        return;
      } catch (error) {
        console.error(error);
        logger.errorMessage(`Unable to Update Admin Service:`);
        logger.error(error);
        return;
      }
    }

    try {
      const { data } = await startAdminService({
        variables: {
          companyInfo,
          businessAddress,
          referral
        }
      });

      if (data?.startAdminService) {
        const newToken = data.startAdminService.authentication.token;
        if (newToken) {
          storeToken(newToken);

          const companyCreationId = data?.startAdminService?.companyCreation.id;
          history.push(`/admin-service/${companyCreationId}`);
        } else {
          throw new AssertionError({ message: "Admin Service authorization not successful" });
        }
      }
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to create Admin Service:`);
      logger.error(error);
    }
  };

  const companyDetails = {
    companyName: myCompanyCreationDetails?.company?.name ?? "",
    legalForm: myCompanyCreationDetails?.company?.legalForm ?? LegalForm.GmbH,
    registration: myCompanyCreationDetails?.company?.registration ?? "",
    registerCourt: myCompanyCreationDetails?.company?.registerCourt ?? "",
    taxNumber: myCompanyCreationDetails?.company?.taxNumber ?? "",
    economicId: myCompanyCreationDetails?.company?.economicId ?? "",
    street: myCompanyCreationDetails?.company?.businessAddress.street ?? "",
    city: myCompanyCreationDetails?.company?.businessAddress.city ?? "",
    postalCode: myCompanyCreationDetails?.company?.businessAddress.postalCode ?? ""
  };

  const formik = useFormik({
    initialValues: { ...companyDetails },
    validateOnChange: true,
    validationSchema: AdminServiceCompanyInfoSchema(currentFlowType),
    enableReinitialize: true,
    onSubmit: submitHandler
  });

  const render = () => {
    return (
      <FormikProvider value={formik}>
        <div>
          <Row>
            <Col className="steps-form-description">
              {t("admin-service:company-form-description")}
            </Col>
          </Row>
          <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
            <Container>
              <Row>
                <Col>{renderForm()}</Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <LoadingAlert t={t} loading={loading} />
                  <ErrorAlertLegacy t={t} error={error?.message} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </FormikProvider>
    );
  };

  const renderForm = () => {
    return (
      <Form>
        <CompanyFields
          prefix={currentFlowType}
          t={t}
          formik={formik}
          legalForms={adminServiceLegalForms}
          currentFlowType={currentFlowType}
          disabled={disabled}
        />
      </Form>
    );
  };

  return render();
};
