import { Form, FormikProvider, useFormik } from "formik";
import { OrderCompanyStatusEnum } from "global-query-types";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";

interface ChangeOrderStatusProps extends WithTranslation {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order;
  upsertHandler: (order: UpsertOrderVariables) => Promise<void>;
}

const ChangeOrderStatus = ({ order, upsertHandler, t }: ChangeOrderStatusProps) => {
  const formik = useFormik({
    initialValues: {
      status: order?.status
    },
    onSubmit: () => upsertHandler({ id: order?.id, status: formik.values["status"] })
  });

  if (!formik.values["status"]) {
    formik.values["status"] = order.status;
  }

  const orderStatusOptions = [
    { label: "Completed", value: OrderCompanyStatusEnum.Completed },
    { label: "Booked", value: OrderCompanyStatusEnum.Booked },
    { label: "In Progress", value: OrderCompanyStatusEnum.InProgress },
    { label: "Assigned", value: OrderCompanyStatusEnum.Assigned },
    { label: "Canceled", value: OrderCompanyStatusEnum.Canceled },
    { label: "Transferred", value: OrderCompanyStatusEnum.Transferred }
  ];

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="low-tax-broker-order-details__update-order-status-row">
          <RideDropdownSelect
            className="low-tax-broker-order-details__select-status"
            data-testid="status-select"
            name="status"
            label={t("generic:status")}
            options={orderStatusOptions}
          />
          <RideButtonDeprecated
            className="update-status-button"
            name="update-status-button"
            variant={ButtonVariant.Primary}
            data-testid={"update-status-button"}
            type="submit">
            {t("generic:update")}
          </RideButtonDeprecated>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ChangeOrderStatus);
