import React from "react";
import { useMutation } from "react-apollo";
import { ASSIGN_SHELF_COMPANY_TO_ORDER } from "../../../lib/api/mutations/ASSIGN_SHELF_COMPANY_TO_ORDER";
import {
  assignShelfCompanyToOrder,
  assignShelfCompanyToOrderVariables
} from "../../../lib/api/mutations/graphql/assignShelfCompanyToOrder";
import ShelfCompanyDetailsTable, { ShelfCompanyDetails } from "./ShelfCompanyDetailsTable";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

interface ShelfCompanyDetailsTableContainerProps {
  companies: ShelfCompanyDetails[];
  orderId?: string;
  onRefresh: () => Promise<void>;
}

const ShelfCompanyDetailsTableContainer = ({
  orderId,
  companies,
  onRefresh
}: ShelfCompanyDetailsTableContainerProps) => {
  const [assignShelfCompanyToOrderRequest] = useMutation<
    assignShelfCompanyToOrder,
    assignShelfCompanyToOrderVariables
  >(ASSIGN_SHELF_COMPANY_TO_ORDER);

  const handleAssign = async (companyId) => {
    if (orderId) {
      await assignShelfCompanyToOrderRequest({
        variables: {
          orderId,
          companyId
        }
      });
    }

    await onRefresh();
  };

  return (
    <RequestFeedbackHandler requests={[]}>
      <ShelfCompanyDetailsTable companies={companies} handleAssign={handleAssign} />
    </RequestFeedbackHandler>
  );
};

export default ShelfCompanyDetailsTableContainer;
