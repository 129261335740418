import React, { useCallback, useEffect, useState } from "react";
import CompanyDetails from "./CompanyDetails";
import { getEndpoint } from "../../../common/GraphqlClient/httpLink";
import { getToken } from "../../../common/Authentication/getToken";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { ApolloQueryResult } from "apollo-client/core/types";
import LoadingAlert from "../../../components/generic/LoadingAlert";
import { withTranslation, WithTranslation } from "react-i18next";

interface CompanyDetailsContainerProps extends WithTranslation {
  companyId: string;
}

export const useGetNomineeCompanyDetailsById = (companyId: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<NomineeCompanyDetails_companyById>();

  const companyById = useCallback(async () => {
    const response = await fetch(`${getEndpoint()}/api/company/${companyId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      mode: "cors",
      credentials: "include"
    });

    let company = (await response.json()) as NomineeCompanyDetails_companyById;
    setData(company);
  }, [companyId]);

  useEffect(() => {
    setLoading(true);
    companyById().then(() => setLoading(false));
  }, [companyId, companyById]);

  return { data, loading };
};
const CompanyDetailsContainer = ({ companyId, t }: CompanyDetailsContainerProps) => {
  const { data, loading } = useGetNomineeCompanyDetailsById(companyId);

  const refetch = async () => {
    return {
      data: { companyById: data },
      loading
    } as ApolloQueryResult<NomineeCompanyDetails>;
  };

  return (
    <>
      {loading ? (
        <LoadingAlert t={t} noLoadingAnimation={false} />
      ) : (
        <CompanyDetails company={data ?? null} refetch={refetch} />
      )}
    </>
  );
};
export default withTranslation(["generic"])(CompanyDetailsContainer);
