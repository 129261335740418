import React from "react";

export const DescriptionWithEmails = ({ content }: { content: string }) => {
  const pieces = splitByEmail(content);

  const toElement = (text, index) => {
    if (text.indexOf("@") === -1) {
      return text;
    }

    return (
      <a data-testid={`link-${index}`} href={`mailto:${text}`} key={index}>
        {text}
      </a>
    );
  };

  return <p data-testid="message">{pieces.map(toElement)}</p>;
};

function splitByEmail(str: string): string[] {
  let pieces: string[] = [];

  const regex = /\S+@\S+\.[a-zA-Z]+/gi;
  const emailsArray = regex.exec(str);

  if (!emailsArray) {
    pieces.push(str);
    return pieces;
  }

  let rest = str;
  for (let match of emailsArray) {
    const index = rest.indexOf(match);

    pieces.push(rest.substr(0, index));
    pieces.push(match);

    rest = rest.substr(index + match.length);
  }

  pieces.push(rest);

  return pieces;
}
