import React from "react";
import GuestLayout from "../../../sharedComponents/templates/GuestLayout/GuestLayout";
import MobileViewError from "../../components/MobileViewError/MobileViewError";

export const MobileViewErrorScene = () => {
  return (
    <GuestLayout>
      <div className={"mobile-view-error-scene"}>
        <MobileViewError />
      </div>
    </GuestLayout>
  );
};
