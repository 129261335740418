import React from "react";
import { WithTranslation } from "react-i18next";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided } from "../../../taxAdvisor/graphql/GetMyTaxAdvisorCompanyWithClients";
import { IconVariant, RideTile } from "../../../sharedComponents/RideTile/RideTile";
import { BrokerageAccountStatus } from "../../../sharedComponents/BrokerageAccountStatus/BrokerageAccountStatus";

interface ThirdPartyTaxAdvisorClientCardProps extends WithTranslation {
  onClick?: () => void;
  taxService: GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided;
}

const ThirdPartyTaxAdvisorClientCard = ({
  taxService: { client },
  onClick,
  t
}: ThirdPartyTaxAdvisorClientCardProps) => {
  const brokerageAccounts = client?.brokerageAccounts ?? [];

  const subTitleSlot = <BrokerageAccountStatus brokerageAccounts={brokerageAccounts} t={t} />;

  if (!client) return null;

  const details = (
    <div className="third-party-tax-advisor-client-card__details">
      <div data-testid="card-client-number" className="third-party-tax-advisor-client-card__list">
        <span className="key">{t("third-party-tax-advisor:clients.card.clientNumber")}&nbsp;</span>
        <>
          {client.thirdPartyTaxAdvisorClientNumber ? (
            client.thirdPartyTaxAdvisorClientNumber
          ) : (
            <TextLink
              to={`/third-party-tax-advisor/clients/${client.id}/third-party-tax-advisor-client-number`}
              data-testid={"third-party-tax-advisor-client-number-link"}
              rel="noopener noreferrer"
              onClick={(event) => event.stopPropagation()}
              variant={LinkVariant.primary}>
              {t("third-party-tax-advisor:clients.card.addField")}
            </TextLink>
          )}
        </>
      </div>
      <div data-testid="card-fiscal-year" className="third-party-tax-advisor-client-card__list">
        <span className="key">{t("third-party-tax-advisor:clients.card.fiscalYear")}&nbsp;</span>
        <>
          {client.fiscalYear ? (
            client.fiscalYear
          ) : (
            <TextLink
              to={`/third-party-tax-advisor/clients/${client.id}/fiscal-year`}
              data-testid={"fiscal-year-link"}
              rel="noopener noreferrer"
              onClick={(event) => event.stopPropagation()}
              variant={LinkVariant.primary}>
              {t("third-party-tax-advisor:clients.card.addField")}
            </TextLink>
          )}
        </>
      </div>
    </div>
  );

  return (
    <RideTile
      name={client.name}
      detailsSlot={details}
      onClick={onClick}
      subTitleSlot={subTitleSlot}
      iconVariant={IconVariant.arrowIcon}
      data-testid="third-party-tax-advisor-client-card"
    />
  );
};

export default withTranslationReady(["tax-service", "third-party-tax-advisor"])(
  ThirdPartyTaxAdvisorClientCard
);
