import { FolderSelectOption } from "../FolderSelectField/FolderSelectField";

export const fixedOptionsForAdminService: FolderSelectOption[] = [
  {
    isDisabled: false,
    label: "2019",
    value: "financialAndAssetAccounting/2019/incomingInvoice"
  },
  {
    isDisabled: false,
    label: "2020",
    value: "financialAndAssetAccounting/2020/incomingInvoice"
  },
  {
    isDisabled: false,
    label: "2021",
    value: "financialAndAssetAccounting/2021/incomingInvoice"
  }
];
