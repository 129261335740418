import {
  ButtonProps,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import React, { useState } from "react";
import { ConfirmationModal, ConfirmationModalProps } from "../ConfirmationModal/ConfirmationModal";

export interface RideButtonWithPromptProps extends ButtonProps {
  prompt: Pick<ConfirmationModalProps, "type" | "title" | "message" | "confirmLabel">;
}

export const RideButtonWithPrompt = ({ prompt, ...buttonProps }: RideButtonWithPromptProps) => {
  const [promptShown, setPromptShown] = useState(false);
  const show = () => setPromptShown(true);
  const close = () => setPromptShown(false);

  return (
    <>
      <ConfirmationModal
        title={prompt.title}
        message={prompt.message}
        type={prompt.type}
        confirmLabel={prompt.confirmLabel}
        visible={promptShown}
        onConfirm={() => buttonProps.onClick?.(null)}
        onClose={close}
      />
      <RideButtonDeprecated {...buttonProps} onClick={show} />
    </>
  );
};
