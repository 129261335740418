import React from "react";
import { WithTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import RideInput from "../../../sharedComponents/FormControls/RideInput/RideInput";
import RequestFeedbackForm from "../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../components/generic/RequestFeedbackHandler";
import { useHistory } from "react-router";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import StringSchema from "yup/lib/string";
import * as Yup from "yup";

interface ClientTextFieldEditProps extends WithTranslation {
  value: string;
  label: string;
  asTextArea?: boolean;
  submitPath: string;
  validation?: StringSchema;
  onSave?: (registration: string) => Promise<void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
  subtitle?: React.ReactNode;
}

export const ClientTextFieldEdit = ({
  t,
  onSave,
  value,
  label,
  requests,
  submitPath,
  asTextArea,
  validation,
  subtitle
}: ClientTextFieldEditProps) => {
  const history = useHistory();
  const initialValues = {
    value
  };

  const validationSchema = validation
    ? Yup.object().shape({
        value: validation
      })
    : undefined;

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await onSave?.(values["value"]);
      history.push(submitPath);
    },

    validationSchema,
    validateOnChange: true,
    validateOnBlur: true
  });

  return (
    <div className="client-field-edit">
      {subtitle && <div className="mb-47">{subtitle}</div>}
      <FormikProvider value={formik}>
        <RequestFeedbackForm requests={requests} submitLabel={t("generic:save")}>
          <RideInput
            formik={formik}
            label={label}
            isOptional={false}
            asTextArea={asTextArea}
            name="value"
          />
        </RequestFeedbackForm>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic", "client"])(ClientTextFieldEdit);
