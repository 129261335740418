import { TFunction } from "i18next";
import {
  AllDiscountCodesQuery_allDiscountCodes,
  AllDiscountCodesQuery_allDiscountCodes_uses
} from "../../../lib/api/queries/graphql/AllDiscountCodesQuery";
import React from "react";
import EntityLink from "../../../components/common/EntityLink/EntityLink";
import Table from "../../../components/generic/Table";
import Timestamp from "../../../components/generic/Timestamp";
import ReferralOrderType from "../../../components/generic/ReferralOrderType";
import { ReferralCodeFormContainer } from "../ReferralCodeForm/ReferralCodeFormContainer";

export const ReferralCodeUsagesTable = ({
  t,
  code,
  allCodes
}: {
  t: TFunction;
  code: string;
  allCodes: AllDiscountCodesQuery_allDiscountCodes[];
}) => {
  const referralCode = allCodes.find((referralCode) => referralCode.code === code);
  const referrerPerson = referralCode?.referrerPerson;
  if (!referralCode || !referrerPerson) return <>{t("generic:no-data-error")}</>;

  const defaultSorted = [
    {
      dataField: "usedAt",
      order: "desc"
    }
  ];

  const columns = [
    {
      dataField: "usedByCompany.name",
      text: t("nominee-dashboard:referral-code.company"),
      sort: true,
      formatter: (_, row: AllDiscountCodesQuery_allDiscountCodes_uses) => (
        <EntityLink membership={{ company: row.usedByCompany }} testIdPrefix="referred" />
      )
    },
    {
      dataField: "usedByPerson.firstName",
      text: t("nominee-dashboard:referral-code.person"),
      sort: true,
      formatter: (_, row: AllDiscountCodesQuery_allDiscountCodes_uses) => (
        <EntityLink membership={{ person: row.usedByPerson }} testIdPrefix="referred" />
      )
    },
    {
      dataField: "usedAt",
      text: t("nominee-dashboard:referral-code.used-at"),
      sort: true,
      formatter: (value) => <Timestamp date={value} testId="used-at" />
    },
    {
      dataField: "status",
      text: t("nominee-dashboard:referral-code.status"),
      sort: true,
      formatter: (value) => <div data-testid="status">{value}</div>
    },
    {
      dataField: "confirmedAt",
      text: t("nominee-dashboard:referral-code.confirmed-at"),
      sort: true,
      formatter: (value) => <Timestamp date={value} testId="confirmed-at" />
    },
    {
      dataField: "flowType",
      text: t("nominee-dashboard:referral-code.order-type"),
      sort: true,
      formatter: (value) => <ReferralOrderType flowType={value} testId="order-type" />
    }
  ];

  return (
    <>
      <ReferralCodeFormContainer referralCode={referralCode} />
      <Table
        data={referralCode.uses ?? []}
        columns={columns}
        keyField="id"
        tableStyle="greenTable"
        defaultSorted={defaultSorted}
      />
    </>
  );
};
