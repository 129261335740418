import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { ClientSelect } from "client/components/ClientSelect/ClientSelect";
import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { CompanyIBComplianceSchema } from "lib/validation/CompanyIBComplianceSchema";

const CompanyRegulatoryInformationStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const { countriesListCodes, companyIsNotARegulatoryExchange, companyIsNotAForeignBank } =
    order.extra.companyRegulatoryInformation ?? {};

  const initialValues = {
    "countries-list": parseCountryCodesToOption(countriesListCodes) ?? "",
    "company-is-not-in-a-regulatory-exchange": companyIsNotARegulatoryExchange ?? false,
    "company-is-not-a-foreign-bank": companyIsNotAForeignBank ?? false
  };

  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        companyRegulatoryInformation: {
          countriesListCodes: parseCountryOptionToCodes(values["countries-list"]) ?? [],
          companyIsNotAForeignBank: values["company-is-not-a-foreign-bank"],
          companyIsNotARegulatoryExchange: values["company-is-not-in-a-regulatory-exchange"]
        },
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: CompanyIBComplianceSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div
          data-testid="company-regulatory-information"
          className="company-regulatory-information">
          <StepHeading
            text={t("generic:ride-broker-onboarding.company-regulatory-information.title")}
          />
          <ClientSelect
            name="countries-list"
            className="company-regulatory-information__countries-list"
            options={[
              {
                label: t(
                  "generic:ride-broker-onboarding.company-regulatory-information.select-options.germany-only-option"
                ),
                value: "DE"
              },
              {
                label: t(
                  "generic:ride-broker-onboarding.company-regulatory-information.select-options.germany-and-austria-option"
                ),
                value: "DE, AT"
              }
            ]}
          />
          <div className="company-regulatory-information__checkboxes">
            <RideCheckbox
              label={t(
                "generic:ride-broker-onboarding.company-regulatory-information.checkbox-1-text"
              )}
              name="company-is-not-in-a-regulatory-exchange"
            />
            <RideCheckbox
              label={t(
                "generic:ride-broker-onboarding.company-regulatory-information.checkbox-2-text"
              )}
              name="company-is-not-a-foreign-bank"
            />
          </div>
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["generic"])(CompanyRegulatoryInformationStep);

function parseCountryCodesToOption(countriesListCodes: string[] | undefined): string | undefined {
  if (!countriesListCodes) {
    return undefined;
  }

  return countriesListCodes.join(", ");
}

function parseCountryOptionToCodes(countriesList: string | undefined): string[] | undefined {
  if (!countriesList) {
    return undefined;
  }

  return countriesList.split(", ");
}
