import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Container } from "react-bootstrap";
import LoadingAlert from "../generic/LoadingAlert";
import ErrorAlertLegacy from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import DetailPageView from "./DetailPageView";
import { GetRealEstateById } from "./getRealEstateById";
import { GetRealEstateGallery } from "./getRealEstateGallery";

export interface RealEstateDetailsMatchProps {
  id: string;
}

interface RealEstateDetailsProps
  extends WithTranslation,
    RouteComponentProps<RealEstateDetailsMatchProps> {
  backUrl: string;
}

export const RealEstateDetailPage: React.FunctionComponent<RealEstateDetailsProps> = (props) => {
  const { t, backUrl, match } = props;

  const realEstateId = match.params.id;
  const realEstateDetails = GetRealEstateById(realEstateId);
  const gallery = GetRealEstateGallery(realEstateId);

  if (realEstateDetails.loading || gallery.loading) {
    return (
      <Container>
        <br />
        {realEstateDetails.loading ? (
          <LoadingAlert t={t} message={t("wallet:loading-message")} />
        ) : null}
      </Container>
    );
  }

  const errorReason = realEstateDetails.error?.message || gallery.error?.message;
  if (errorReason) {
    return (
      <Container>
        <br />
        <ErrorAlertLegacy t={t} error={errorReason} />
      </Container>
    );
  }

  if (!realEstateDetails.data && !gallery.data) return <p>{t("generic:no-data-error")}</p>;
  return (
    <>
      <DetailPageView
        detailRealEstateData={realEstateDetails.data?.realEstatesById!}
        gallery={gallery.data}
        refetchGallery={gallery?.refetch || (() => {})}
        detailInvestmentRealEstateData={null}
        investmentDetail={null}
        backUrl={backUrl}
      />
    </>
  );
};

export default withTranslation(["realEstate", "wallet", "errorCodes", "generic"])(
  RealEstateDetailPage
);
