import React from "react";
import { RideCheckbox } from "../RideCheckbox/RideCheckbox";

export interface RideOutlinedCheckboxProps {
  name: string;
  label: string;
  checked: boolean;
  onChange?: (value: boolean) => void;
  isUnavailable?: boolean;
}

const RideOutlinedCheckbox = ({
  name,
  label,
  checked,
  onChange,
  isUnavailable
}: RideOutlinedCheckboxProps) => {
  return (
    <div
      data-testid="ride-outlined-checkbox"
      className={`ride-outlined-checkbox  ${
        isUnavailable
          ? "ride-outlined-checkbox--unavailable"
          : `${checked ? "ride-outlined-checkbox--active" : ""}`
      }`}>
      <RideCheckbox
        name={name}
        label={label}
        checked={checked}
        onChange={(checked) => {
          if (onChange) onChange(checked);
        }}
      />
    </div>
  );
};

export default RideOutlinedCheckbox;
