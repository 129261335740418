import React from "react";

interface ShareholderDetailsSectionTitleProps {
  text: string;
  prefix?: string;
  isCompleted?: boolean;
}

const ShareholderDetailsSectionTitle = ({
  text,
  prefix = "",
  isCompleted = false
}: ShareholderDetailsSectionTitleProps) => {
  const testIdentifier = "shareholder-details-section-title";

  return (
    <div data-testid={`${testIdentifier}-container`} className="shareholder-details-section-title">
      {isCompleted === true && (
        <div
          data-testid={`${testIdentifier}-success-icon`}
          className={`${testIdentifier}-success-icon`}></div>
      )}

      {prefix.length > 0 && !isCompleted && (
        <div
          data-testid={`${testIdentifier}-prefix`}
          className="shareholder-details-section-title-prefix">
          {prefix}
        </div>
      )}

      <div
        data-testid="shareholder-details-section-title-text"
        className="shareholder-details-section-title-text">
        {text}
      </div>
    </div>
  );
};

export default ShareholderDetailsSectionTitle;
