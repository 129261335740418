import React from "react";
import { Row } from "react-bootstrap";
import { FlowTypeEnum } from "../../../../../global-query-types";

export const OrderProduct = ({ currentFlowType, selectedPackage, t }) => {
  if (currentFlowType === FlowTypeEnum.ADMIN_SERVICE) {
    return (
      <div className="mt-3">
        <Row className="px-md-45 px-3 py-35" data-testid="admin-service-product-2">
          <span className="admin-service-ordering" data-testid="admin-service-product-info">
            {t("admin-service:i-am-ordering")}
            <span className="admin-service-package">{t(selectedPackage?.ordering)}</span>
            {t(selectedPackage?.price)}
          </span>
        </Row>
      </div>
    );
  }
  return <></>;
};
