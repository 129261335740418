import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  NOMINEE_CREATE_ADMIN_SERVICE_STORAGE_STRUCTURE,
  NOMINEE_CREATE_COMPANY_STORAGE_STRUCTURE,
  NOMINEE_UPDATE_COMPANY_STORAGE_STRUCTURE
} from "../Mutations";
import { DocumentsSectionAdminOperations } from "../../../../admin/components/DocumentsSectionAdminOperations/DocumentsSectionAdminOperations";
import {
  DocumentsSectionContainer,
  DocumentsSectionContainerProps
} from "../../../../admin/components/DocumentsSectionAdminOperations/DocumentsSectionAdminOperationsContainer";
import { StorageNodesEntityType } from "../../../../global-query-types";
import { NodeTreeCardContainer } from "../../../../client/components/Files/NodeTreeCard/NodeTreeCardContainer";

export const CompanyDocumentsSectionContainer: DocumentsSectionContainer = ({
  entity: company,
  refetch,
  t
}: DocumentsSectionContainerProps) => {
  const [createStorageStructureMutation, storageStructureCreateRequest] = useMutation(
    NOMINEE_CREATE_COMPANY_STORAGE_STRUCTURE
  );

  const [createAdminServiceFolderStructureMutation, createFoldersRequest] = useMutation(
    NOMINEE_CREATE_ADMIN_SERVICE_STORAGE_STRUCTURE
  );

  const [updateStorageStructureMutation, storageStructureUpdateRequest] = useMutation(
    NOMINEE_UPDATE_COMPANY_STORAGE_STRUCTURE
  );

  const createStorageStructure = async () => {
    await createStorageStructureMutation({
      variables: {
        id: company.id
      }
    });
  };

  const createAdminServiceFolderStructure = async () => {
    await createAdminServiceFolderStructureMutation({
      variables: {
        id: company.id
      }
    });
  };

  const updateStorageStructure = async () => {
    await updateStorageStructureMutation({
      variables: {
        id: company.id
      }
    });
  };

  const externalStorageNodes = JSON.parse(company.externalStorageNodes || "{}");
  const companyRootNodeId: string | undefined = externalStorageNodes.companyRoot;

  return (
    <DocumentsSectionAdminOperations
      rootNodeId={companyRootNodeId}
      createStorageStructure={createStorageStructure}
      createAdminServiceFolderStructure={createAdminServiceFolderStructure}
      updateStorageStructure={updateStorageStructure}
      externalStorageNodes={externalStorageNodes}
      refetch={refetch}
      requests={[
        storageStructureCreateRequest,
        createFoldersRequest,
        storageStructureUpdateRequest
      ]}
      t={t}>
      <NodeTreeCardContainer
        title={t("nominee-dashboard:company.company-documents")}
        nodeId={companyRootNodeId}
        entityId={company.id}
        entityType={StorageNodesEntityType.Company}
      />
    </DocumentsSectionAdminOperations>
  );
};
