import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getWebForm, getWebFormVariables } from "./graphql/getWebForm";

export const GET_WEB_FORM = gql`
  query getWebForm($iban: String!, $companyId: String!, $bankAccountId: String!) {
    getWebForm(iban: $iban, companyId: $companyId, bankAccountId: $bankAccountId) {
      url
    }
  }
`;

export const GetWebForm = (iban: string, companyId: string, bankAccountId: string) =>
  useQuery<getWebForm, getWebFormVariables>(GET_WEB_FORM, {
    variables: { iban, companyId, bankAccountId },
    fetchPolicy: "no-cache"
  });
