import React, { PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

export const RideLink = ({
  children,
  to,
  className,
  onClick,
  ...rest
}: PropsWithChildren<LinkProps>) => {
  const isExternal =
    typeof to === "string" &&
    (to.startsWith("http://") || to.startsWith("https://") || to.startsWith("mailto:"));

  if (isExternal) {
    return (
      <a href={`${to}`} className={`ride-link ${className ?? ""}`} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} onClick={onClick} className={`ride-link ${className ?? ""}`} {...rest}>
      {children}
    </Link>
  );
};
