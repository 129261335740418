import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { Col, Container, Row } from "react-bootstrap";
import { NomineeCompanyDetails_companyById } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { formatValue } from "../../../../common/formatters";

export interface CompanyAssetsAndActivitiesProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
}

const CompanyAssetsAndActivities = ({ company, t }: CompanyAssetsAndActivitiesProps) => {
  return (
    <Container className="company-assets-and-activities">
      <Row
        data-testid="company-assets-and-activities-title"
        className="company-assets-and-activities__title">
        {t("nominee-dashboard:company.company-assets-and-activities")}
      </Row>
      <Row>
        <Col className="company-assets-and-activities__label" data-testid="company-assets-label">
          {t("trader-gmbh:assets-choice-description-1")}
        </Col>
        <Col data-testid="company-assets-value">
          {(company.assets ?? [])
            .map((asset) => t(`shared-components:tiles.businessPurpose.${asset}`))
            .join(", ")}
        </Col>
      </Row>
      <Row>
        <Col
          className="company-assets-and-activities__label"
          data-testid="operational-activities-label">
          {t("trader-gmbh:operational-activities.question")}
        </Col>
        <Col data-testid="operational-activities-value">
          {company.companyCreation?.hasOperationalActivities ? t("generic:yes") : t("generic:no")}
        </Col>
      </Row>
      <Row>
        <Col
          className="company-assets-and-activities__label"
          data-testid="operational-activities-details-label">
          {t("trader-gmbh:operational-activities.optional-question")}
        </Col>
        <Col data-testid="operational-activities-details-value">
          {formatValue(company.companyCreation?.operationalActivities)}
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslationReady(["nominee-dashboard", "shared-components", "trader-gmbh"])(
  CompanyAssetsAndActivities
);
