import React from "react";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import BankAccountDetailsContainer from "../../components/Banking/BankAccountDetails/BankAccountDetailsContainer";

const BankAccountDetailsScene = () => (
  <>
    <PageMetaData title={""} />
    <BankAccountDetailsContainer />
  </>
);

export default BankAccountDetailsScene;
