import React from "react";

export const DiscountIcon = () => (
  <svg
    data-testid="discount-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5408_12)">
      <path
        d="M7.5 10.5C6.90666 10.5 6.32664 10.3241 5.83329 9.99441C5.33994 9.66476 4.95542 9.19623 4.72836 8.64805C4.5013 8.09987 4.44189 7.49667 4.55764 6.91473C4.6734 6.33279 4.95912 5.79824 5.37868 5.37868C5.79824 4.95912 6.33279 4.6734 6.91473 4.55764C7.49667 4.44189 8.09987 4.5013 8.64805 4.72836C9.19623 4.95542 9.66476 5.33994 9.99441 5.83329C10.3241 6.32664 10.5 6.90666 10.5 7.5C10.4991 8.29538 10.1827 9.05792 9.62033 9.62033C9.05792 10.1827 8.29538 10.4991 7.5 10.5ZM7.5 6C7.2034 5.9997 6.91338 6.08734 6.66658 6.25184C6.41978 6.41634 6.22729 6.6503 6.11343 6.92418C5.99957 7.19805 5.96946 7.49952 6.0269 7.7905C6.08434 8.08148 6.22676 8.3489 6.43615 8.55896C6.64554 8.76902 6.9125 8.91229 7.20329 8.97066C7.49409 9.02903 7.79566 8.99989 8.06989 8.88691C8.34413 8.77393 8.57871 8.58218 8.744 8.33591C8.90928 8.08964 8.99785 7.79989 8.9985 7.5033C8.99898 7.10529 8.84141 6.72336 8.56042 6.44147C8.27943 6.15959 7.89801 6.00079 7.5 6Z"
        fill="black"
      />
      <path
        d="M12.4827 22.0609L1.93935 11.5166C1.65806 11.2353 1.50003 10.8538 1.5 10.456V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H10.456C10.8538 1.50001 11.2353 1.65804 11.5166 1.93935L22.0608 12.4827C22.342 12.7641 22.5 13.1456 22.5 13.5434C22.5 13.9412 22.342 14.3227 22.0608 14.6041L14.604 22.061C14.3226 22.3421 13.9411 22.5001 13.5433 22.5001C13.1455 22.5001 12.7641 22.342 12.4827 22.0609ZM3 3V10.4563L13.5434 21L21 13.5434L10.4562 3H3Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5408_12">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
