import { RolesInGroup } from "../../../global-query-types";
import {
  NomineeCompanyDetails_companyById_group_memberships_company,
  NomineeCompanyDetails_companyById_group_memberships_person
} from "../../api/queries/graphql/NomineeCompanyDetails";
import { round, sum, sumBy } from "lodash";

export interface RoleEntry {
  id: string;
  share: number | null;
  votes: number | null;
  isKycRequired: boolean;
  isKycSucceeded?: boolean;
  isManagingDirector: boolean | null;
  isSilentPartner: boolean | null;
  person: NomineeCompanyDetails_companyById_group_memberships_person | null;
  company: NomineeCompanyDetails_companyById_group_memberships_company | null;
}

const companyMemberships = (memberships) => {
  return memberships
    .filter((elem) => elem.role !== RolesInGroup.CompanyCreator)
    .filter((m) => !(m.company || m.group?.company)?.deletedAt)
    .filter((elem) => elem.company || (elem.group && elem.group.company));
};

const personMemberships = (memberships) => {
  return memberships
    .filter((elem) => elem.role !== RolesInGroup.CompanyCreator)
    .filter((elem) => !elem.company && (!elem.group || !elem.group.company));
};

export const companyRolesFromMemberships = (memberships): RoleEntry[] => {
  if (!memberships) {
    return [];
  }

  const entries = new Map<String, RoleEntry>();

  const totalSilentPartnersShares = sum(
    memberships
      .filter((m) => m.role === RolesInGroup.SilentPartner)
      .filter((m) => !(m.company || m.group?.company)?.deletedAt)
      .map((m) => m?.share?.share ?? m?.share ?? 0)
  );

  companyMemberships(memberships)
    .filter((m) => !m.group?.company.deletedAt)
    .forEach((m) => {
      const company = m.company || m.group.company;
      const key = `c_${company.id}`;

      const entry = entries.has(key)
        ? entries.get(key)!
        : {
            id: m.id,
            share: null,
            votes: null,
            isKycRequired: false,
            isKycSucceeded: false,
            isManagingDirector: null,
            isSilentPartner: null,
            person: m.person,
            company
          };

      if (m.share) {
        const share: number = m.share.share ?? m.share ?? 0;
        const voteRights = calculateMemberVoteRightsForRole(
          m.role,
          share,
          totalSilentPartnersShares
        );
        entry.share = share;
        entry.votes = round(voteRights, 4);
      }

      if (company?.kycEntries?.length) {
        entry.isKycSucceeded = company?.kycEntries[0].status === "COMPLETED";
      }

      if (!entry.isManagingDirector) {
        entry.isManagingDirector = m.role === RolesInGroup.Director;
      }

      if (!entry.isSilentPartner) {
        entry.isSilentPartner = m.role === RolesInGroup.SilentPartner;
      }

      entry.isKycRequired = determineKycRequirement(
        entry.share,
        entry.votes,
        entry.isManagingDirector
      );

      entries.set(key, entry);
    });

  personMemberships(memberships).forEach((m) => {
    let person = m.person;
    const key = `p_${person.id}`;

    if (!person.firstName && !person.lastName) {
      person = {
        ...person,
        firstName: "Unknown",
        lastName: "Person"
      };
    }

    const entry = entries.has(key)
      ? entries.get(key)!
      : {
          id: m.id,
          share: null,
          votes: null,
          isKycRequired: false,
          isKycSucceeded: false,
          isManagingDirector: null,
          isSilentPartner: false,
          person,
          company: null
        };

    if (m.share) {
      const share: number = m.share.share ?? m.share ?? 0;
      const voteRights = calculateMemberVoteRightsForRole(m.role, share, totalSilentPartnersShares);
      entry.share = share;
      entry.votes = round(voteRights, 4);
    }

    const kycEntries = (person?.kycEntries || []).filter((kyc) => !kyc.company);
    if (kycEntries.length) {
      entry.isKycSucceeded = kycEntries[0].status === "COMPLETED";
    }

    if (!entry.isManagingDirector) {
      entry.isManagingDirector = m.role === RolesInGroup.Director;
    }

    if (!entry.isSilentPartner) {
      entry.isSilentPartner = m.role === RolesInGroup.SilentPartner;
    }

    entry.isKycRequired = determineKycRequirement(
      entry.share,
      entry.votes,
      entry.isManagingDirector
    );

    entries.set(key, entry);
  });

  return Array.from(entries.values());
};

export const determineKycRequirement = (
  share: number | null,
  votes: number | null,
  isManagingDirector: boolean
): boolean => (share ?? 0) >= 25 || (votes ?? 0) >= 25 || isManagingDirector;

export const shareholderListValidation = (roles: RoleEntry[]): string | undefined => {
  const shareholders = roles
    .filter((shareholder) => !shareholder.isSilentPartner)
    .filter((m) => !m.company?.deletedAt);

  if (shareholders.length === 0) {
    return;
  }

  if (sumBy(shareholders, "share") !== 100) {
    return "nominee-dashboard:roles.total-shares-must-be-100";
  }
};

export const calculateMemberVoteRightsForRole = (
  role: RolesInGroup,
  share: number,
  totalSilentPartnerShares: number
): number => {
  return role === "SilentPartner" ? share : share * (1 - totalSilentPartnerShares / 100);
};
