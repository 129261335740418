import { CurrencyFormatter } from "./CurrencyFormatter";
import { DateFormatter } from "./DateFormatter";
import { DateTimeFormatter } from "./DateTimeFormatter";
import { PercentageFormatter } from "./PercentageFormatter";
import { TimeFormatter } from "./TimeFormatter";
import { ValueFormatter } from "./ValueFormatter";
import { BooleanFormatter } from "./BooleanFormatter";
import i18n from "../i18n";
import enFormatters from "./locales/en/formatters.json";
import deFormatters from "./locales/de/formatters.json";
import { RemoveWhitespaceFormatter } from "./RemoveWhitespaceFormatter";
import { FiscalFormatter } from "./FiscalFormatter";
import { FoundationDateFormatter } from "../../lib/formatters/FoundationDateFormatter";

// Only load when not running tests
if (!process.env.RIDE_WD_TEST) {
  i18n.addResourceBundle("en", "formatters", enFormatters);
  i18n.addResourceBundle("de", "formatters", deFormatters);
}

export const formatCurrency = (value, ...args) => new CurrencyFormatter().format(value, ...args);
export const formatDate = (value) => new DateFormatter().format(value);
export const formatDateTime = (value) => new DateTimeFormatter().format(value);
export const formatPercentage = (value, ...args) =>
  new PercentageFormatter().format(value, ...args);
export const formatTime = (value) => new TimeFormatter().format(value);
export const formatValue = (value) => new ValueFormatter().format(value);
export const formatBool = (value) => new BooleanFormatter().format(value);
export const removeWhitespace = (value) => new RemoveWhitespaceFormatter().format(value);
export const formatFiscalYear = (value, format = "") => new FiscalFormatter().format(value, format);
export const formatFoundationDate = (value, format = "") =>
  new FoundationDateFormatter().format(value, format);
export const transformFiscalYearToMoment = (value) =>
  new FiscalFormatter().transformToMoment(value);
