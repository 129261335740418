import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { deepClone } from "common/deepClone";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { AddressSchema } from "lib/validation/AddressSchema";
import React, { useImperativeHandle } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import GenericAddress from "../GenericAddress/GenericAddress";

const PersonAddressComponent = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef,
  entityKey
}: WithTranslation & StepComponentProps & { entityKey: "shareholder" | "employee" }) => {
  const entityIndex = currentStep?.chapter?.index ?? 0;
  let dictionaryCopy = order.extra[`${entityKey}s`] ? deepClone(order.extra[`${entityKey}s`]) : {};
  const entity = dictionaryCopy[entityIndex] ?? {};
  const personAddress = entity.personAddress ?? {};

  const initialValues = {
    country: personAddress.country ?? "DE",
    streetLine1: personAddress.streetLine1 ?? "",
    streetLine2: personAddress.streetLine2 ?? "",
    city: personAddress.city ?? "",
    postalCode: personAddress.postalCode ?? ""
  };

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    currentStep.complete();

    const { shouldReturnToParentChapter, ...data } = values;
    entity.personAddress = data;

    await saveData(
      {
        ...order.extra,
        [`${entityKey}s`]: dictionaryCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldReturnToParentChapter ?? false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: AddressSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="person-address-form subchapter-order-layout__scrollable">
          <GenericAddress
            formik={formik}
            prefix={`${currentStep?.disposition}`}
            title={t("generic:shareholder-details.residential-address")}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

const PersonAddress =
  (entityKey: "shareholder" | "employee") => (props: StepComponentProps & WithTranslation) =>
    <PersonAddressComponent {...props} entityKey={entityKey} />;

export default (entityKey: "shareholder" | "employee") =>
  withTranslationReady(["generic"])(PersonAddress(entityKey));
