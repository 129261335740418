import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { withTranslation, WithTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { ExperienceLevelPerAreasSchema } from "lib/validation/ExperienceLevelPerAreasSchema";
import { AssetTypes } from "lib/types/types";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Step } from "lib/models/client/order/Step";

const ExperienceLevelStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  let initialValues = {};

  const assetsOptions =
    Object.values(AssetTypes).filter((asset) => {
      if (order.extra.assetExperiences?.[asset]) {
        initialValues[asset] = order.extra.assetExperiences?.[asset]?.knowledgeLevel ?? "";
      }

      return order.extra.assetExperiences?.[asset];
    }) ?? [];

  const onSubmit = async () => {
    currentStep.complete();

    const chapter = currentStep.chapter;

    const assetsAvailableToTradeStep = chapter.getStepByKey("assets-available-to-trade");
    if (!assetsAvailableToTradeStep) {
      chapter.insertStepAfter(currentStep, new Step({ key: "assets-available-to-trade" }, chapter));
    }

    let assetExperiences = {};

    assetsOptions.forEach((asset) => {
      assetExperiences[asset] = {
        ...order.extra.assetExperiences?.[asset],
        knowledgeLevel: formik.values[asset]
      };
    });

    await saveData(
      {
        ...order.extra,
        assetExperiences,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validationSchema: ExperienceLevelPerAreasSchema(t, assetsOptions)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <ExperienceLevelPerAreas assetOptionsSelected={assetsOptions} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

interface ExperienceLevelPerAreasProps extends WithTranslation {
  assetOptionsSelected: AssetTypes[];
}

const ExperienceLevelPerAreas = withTranslationReady(["generic"])(
  ({ t, assetOptionsSelected }: ExperienceLevelPerAreasProps) => {
    const experienceLevelOptions: { value: string; label: string }[] = [
      "limited",
      "good",
      "extensive"
    ].map((value) => ({
      label: t(`generic:ride-broker-onboarding.experience-level-per-areas.options.${value}`),
      value
    }));

    return (
      <div data-testid={"experience-level-per-areas"} className="experience-level-per-areas">
        <StepHeading
          data-testid="experience-level-per-areas-heading"
          text={t("generic:ride-broker-onboarding.experience-level-per-areas.title")}
        />
        {assetOptionsSelected.map((asset) => (
          <RideDropdownSelect
            key={asset}
            options={experienceLevelOptions}
            name={asset}
            label={t(`generic:ride-broker-onboarding.investment-options.${asset}`)}
            placeholder={t(
              "generic:ride-broker-onboarding.experience-level-per-areas.select-experience-level"
            )}
            className="experience-level-per-areas__select"
          />
        ))}
      </div>
    );
  }
);
export default withTranslation(["generic"])(ExperienceLevelStep);
