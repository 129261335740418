import React from "react";
import { TFunction } from "i18next";
import { SelectCompanyCategory } from "components/generic/company-form/fields";
import { FormikProps } from "formik";
import { nomineeCompanyTestType } from "common/staticData/CompanyTestType";

export interface IProps {
  t: TFunction;
  formik: FormikProps<any>;
}

export const TestCompanyTypesField = ({ t, formik }: IProps) => {
  const defaultOption = {
    label: "Unmark",
    value: ""
  };

  const translatedTestCompany = nomineeCompanyTestType.map((testCompanyType) => {
    return {
      label: t(`generic:testCompanyType.${testCompanyType}`),
      value: testCompanyType
    };
  });

  const listAllTestCompanyTypes: Array<{ label: string; value: any }> = [defaultOption].concat(
    translatedTestCompany
  );

  return (
    <>
      <label>{t("generic:test-company")}</label>
      <SelectCompanyCategory
        t={t}
        name="testCompany"
        formik={formik}
        options={listAllTestCompanyTypes}
      />
    </>
  );
};
