import { AdminServiceTier, UnifiedShortOrderFormValues } from "./UnifiedShortOrderFormValues";
import { Product } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormServerData";
import { getCurrentYear } from "lib/dateTime/getCurrentYear";

export const shortOrderFormProductPrices = {
  gmbhFounding: 2299,
  adminService: {
    lite: 1399,
    standard: 1999,
    pro: 2449
  },
  adminServiceFeeWhenExistingGmbh: 500,
  lowTaxBroker: 0
};

export class OrderSummary {
  constructor(private readonly items: ItemOrder[], private readonly discount?: number) {}

  static fromShortOrderForm(data: UnifiedShortOrderFormValues): OrderSummary {
    const items: ItemOrder[] = [];
    const discount = data.discountValue;

    if (data.companyFoundingService) {
      items.push(CompanyFoundingItem());
    }
    if (data.adminService) {
      items.push(
        AdminServiceItem({
          tier: data.adminService,
          startingFiscalYear: data.startingFiscalYear,
          hasCompanyFoundingService: !!data.companyFoundingService
        })
      );
    }
    if (data.lowTaxBroker) {
      items.push(LowTaxBrokerItem());
    }

    return new OrderSummary(items, discount);
  }

  getItem(product: Product): ItemOrder | undefined {
    return this.items.find((i) => i.product === product);
  }

  getItems(): ItemOrder[] {
    return this.items;
  }

  hasItem(product: Product): boolean {
    return this.items.some((p) => p.product === product);
  }

  getTotalOneTimeFee(): number {
    return this.items
      .map((i) => (i.acquisitionPrice ?? 0) + i.recurringPrice * (i.numberOfAdditionalYears ?? 0))
      .reduce((total, current) => total + current, 0);
  }

  getRecurringPrice(product: Product): number | undefined {
    return this.items.find((p) => p.product === product)?.recurringPrice;
  }

  getTotalRecurringPrice(): number {
    return this.items.map((i) => i.recurringPrice).reduce((total, current) => total + current, 0);
  }

  getTotalDiscount(): number {
    return this.discount ?? 0;
  }

  getTotal(): number {
    return Math.max(
      this.getTotalRecurringPrice() + this.getTotalOneTimeFee() - this.getTotalDiscount(),
      0
    );
  }
}

export interface ItemOrder {
  product: Product;
  recurringPrice: number;
  acquisitionPrice?: number;
  numberOfAdditionalYears?: number;
  tier?: AdminServiceTier;
}

export const CompanyFoundingItem = (): ItemOrder => {
  return {
    product: Product.GS,
    recurringPrice: shortOrderFormProductPrices.gmbhFounding
  };
};

export const AdminServiceItem = (data: {
  tier: AdminServiceTier;
  startingFiscalYear?: number;
  hasCompanyFoundingService: boolean;
}): ItemOrder => {
  return {
    product: Product.AS,
    recurringPrice: shortOrderFormProductPrices.adminService[data.tier],
    acquisitionPrice: !data.hasCompanyFoundingService
      ? shortOrderFormProductPrices.adminServiceFeeWhenExistingGmbh
      : undefined,
    tier: data.tier,
    numberOfAdditionalYears: data.startingFiscalYear
      ? getCurrentYear() - data.startingFiscalYear
      : undefined
  };
};

export const LowTaxBrokerItem = (): ItemOrder => {
  return {
    product: Product.LTB,
    recurringPrice: shortOrderFormProductPrices.lowTaxBroker
  };
};
