import React, { ReactElement, ReactNode } from "react";
import { TFunction, Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  AdminServiceLiteProductCard,
  AdminServiceProProductCard,
  AdminServiceStandardProductCard
} from "client/components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceProductCard/AdminServiceProductCard";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import RideModal from "uiLibrary/designSystem/RideModal/RideModal";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  AlertType,
  BarVariant,
  RideAlertBar
} from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { BreakpointUp } from "uiLibrary/responsive/BreakpointUp/BreakpointUp";
import { BreakpointDown } from "uiLibrary/responsive/BreakpointDown/BreakpointDown";
import { CloseIcon } from "sharedComponents/icons/DesignSystemIcons/CloseIcon";
import { CheckIcon } from "sharedComponents/icons/DesignSystemIcons/CheckIcon";

interface AdminServiceTiersModalProps extends WithTranslation {
  setAdminServiceTier: (tierValue: string) => void;
  disabledOptions?: AdminServiceTier[];
  onClose: () => void;
}

enum AdminServiceFeature {
  FOLDER_MANAGEMENT_SYSTEM = "folderManagementSystem",
  ANNUAL_FINANCIAL_STATEMENTS = "annualFinancialStatements",
  CONTRACT_TEMPLATES = "contractTemplates",
  AUTOMATED_SECURITIES_BOOKING = "automatedSecuritiesBooking",
  BOOKING_YOUR_BILLS = "bookingYourBills",
  OTHER_MANDATORY_BOOKINGS = "otherMandatoryBookings"
}

interface Feature {
  name: AdminServiceFeature;
  label: ReactNode;
}

interface Tier {
  name: AdminServiceTier;
  productCard: React.ReactElement;
  features: any;
}

const AdminServiceTiersModal = ({
  t,
  setAdminServiceTier,
  disabledOptions,
  onClose
}: AdminServiceTiersModalProps) => {
  const features: Feature[] = [
    {
      name: AdminServiceFeature.FOLDER_MANAGEMENT_SYSTEM,
      label: t(
        "generic:short-order-form.admin-service-tiers-modal.properties.folder-management-system"
      )
    },
    {
      name: AdminServiceFeature.ANNUAL_FINANCIAL_STATEMENTS,
      label: t(
        "generic:short-order-form.admin-service-tiers-modal.properties.annual-financial-statements"
      )
    },
    {
      name: AdminServiceFeature.CONTRACT_TEMPLATES,
      label: t("generic:short-order-form.admin-service-tiers-modal.properties.contract-templates")
    },
    {
      name: AdminServiceFeature.AUTOMATED_SECURITIES_BOOKING,
      label: t(
        "generic:short-order-form.admin-service-tiers-modal.properties.automated-securities-booking.label"
      )
    },
    {
      name: AdminServiceFeature.BOOKING_YOUR_BILLS,
      label: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.booking-your-bills.label"
          }
          components={{
            bullet: "• ",
            small: <Typography size={300} weight={"Light"} category={"Paragraph"} />
          }}
        />
      )
    },
    {
      name: AdminServiceFeature.OTHER_MANDATORY_BOOKINGS,
      label: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.other-mandatory-bookings.label"
          }
          components={{
            bullet: "• ",
            small: <Typography size={300} weight={"Light"} category={"Paragraph"} />
          }}
        />
      )
    }
  ];

  const supportedIcon: ReactElement = (
    <CheckIcon size={32} className={"admin-service-tiers-modal__supported-icon"} />
  );
  const notSupportedIcon: ReactElement = (
    <CloseIcon size={32} className={"admin-service-tiers-modal__not-supported-icon"} />
  );

  const lite: Tier = {
    name: AdminServiceTier.Lite,
    productCard: (
      <AdminServiceLiteProductCard
        showTitle={true}
        disabled={disabledOptions?.includes(AdminServiceTier.Lite)}
      />
    ),
    features: {
      [AdminServiceFeature.FOLDER_MANAGEMENT_SYSTEM]: supportedIcon,
      [AdminServiceFeature.ANNUAL_FINANCIAL_STATEMENTS]: supportedIcon,
      [AdminServiceFeature.CONTRACT_TEMPLATES]: supportedIcon,
      [AdminServiceFeature.AUTOMATED_SECURITIES_BOOKING]: notSupportedIcon,
      [AdminServiceFeature.BOOKING_YOUR_BILLS]: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.booking-your-bills.lite-description"
          }
          components={{
            bullet: "• ",
            small: <Typography size={300} weight={"Light"} category={"Paragraph"} />
          }}
        />
      ),
      [AdminServiceFeature.OTHER_MANDATORY_BOOKINGS]: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.other-mandatory-bookings.lite-description"
          }
          components={{ bullet: "• " }}
        />
      )
    }
  };
  const standard: Tier = {
    name: AdminServiceTier.Standard,
    productCard: (
      <AdminServiceStandardProductCard
        showTitle={true}
        disabled={disabledOptions?.includes(AdminServiceTier.Standard)}
      />
    ),
    features: {
      ...lite.features,
      [AdminServiceFeature.AUTOMATED_SECURITIES_BOOKING]: t(
        "generic:short-order-form.admin-service-tiers-modal.properties.automated-securities-booking.standard-description"
      ),
      [AdminServiceFeature.BOOKING_YOUR_BILLS]: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.booking-your-bills.standard-description"
          }
          components={{
            bullet: "• ",
            lessThan: "<",
            small: <Typography size={300} weight={"Light"} category={"Paragraph"} />
          }}
        />
      ),
      [AdminServiceFeature.OTHER_MANDATORY_BOOKINGS]: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.other-mandatory-bookings.standard-description"
          }
          components={{
            bullet: "• ",
            lessThan: "<",
            small: <Typography size={300} weight={"Light"} category={"Paragraph"} />
          }}
        />
      )
    }
  };
  const pro: Tier = {
    name: AdminServiceTier.Pro,
    productCard: (
      <AdminServiceProProductCard
        showTitle={true}
        disabled={disabledOptions?.includes(AdminServiceTier.Pro)}
      />
    ),
    features: {
      ...standard.features,
      [AdminServiceFeature.BOOKING_YOUR_BILLS]: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.booking-your-bills.pro-description"
          }
          components={{
            bullet: "• ",
            small: <Typography size={300} weight={"Light"} category={"Paragraph"} />
          }}
        />
      ),
      [AdminServiceFeature.OTHER_MANDATORY_BOOKINGS]: (
        <Trans
          i18nKey={
            "generic:short-order-form.admin-service-tiers-modal.properties.other-mandatory-bookings.pro-description"
          }
          components={{ bullet: "• " }}
        />
      )
    }
  };

  const tiers: Tier[] = [lite, standard, pro];

  let className = "admin-service-tiers-modal";
  return (
    <RideModal title={""} onClose={onClose} fitContentWidth={true} fitContentHeight={true}>
      <div data-testid="admin-service-tiers-modal" className={className}>
        <div className={`${className}__content`}>
          <div className={`${className}__header`}>
            <BreakpointUp breakpoint={"lg"}>
              <div />
            </BreakpointUp>
            {tiers.map((tier) => (
              <div key={tier.name}>{tier.productCard}</div>
            ))}
          </div>
          <div className={`${className}__body`}>
            <BreakpointUp breakpoint={"lg"}>
              <div
                className={`${className}__column ${className}__column-features`}
                data-testid={"features-column"}>
                {features.map((feature) => (
                  <div
                    className={`${className}__cell ${className}__cell-feature`}
                    key={feature.name}>
                    {feature.label}
                  </div>
                ))}
              </div>
            </BreakpointUp>

            {tiers.map((tier) => {
              return (
                <div className={`${className}__column`} key={`${tier.name}-column`}>
                  {features.map((feature, index) => (
                    <TierFeatureCell
                      t={t}
                      grayBackground={index % 2 === 0}
                      tier={tier}
                      feature={feature}
                      key={`${tier.name}-${index}`}
                    />
                  ))}
                  <RideButton
                    type="button"
                    data-testid={`select-${tier.name}`}
                    label={t(
                      `generic:product-card.admin-service.options.${tier.name}.select-button`
                    )}
                    variant={RideButtonVariant.PRIMARY}
                    size={RideButtonSize.SMALL}
                    onClick={() => {
                      setAdminServiceTier(tier.name);
                      onClose();
                    }}
                    disabled={disabledOptions?.includes(tier.name)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </RideModal>
  );
};

const TierFeatureCell = ({
  t,
  tier,
  feature,
  grayBackground
}: {
  t: TFunction;
  tier: Tier;
  feature: Feature;
  grayBackground: boolean;
}) => {
  const wepaLiteCell =
    feature.name === AdminServiceFeature.AUTOMATED_SECURITIES_BOOKING &&
    tier.name === AdminServiceTier.Lite;

  return (
    <div
      data-testid={`${tier.name}-${feature.name}`}
      className={getClassNames(tier, feature, grayBackground, wepaLiteCell)}>
      <BreakpointDown breakpoint={"md"}>
        <Typography size={100} category={"Paragraph"} weight={"Heavy"}>
          {feature.label}
        </Typography>
      </BreakpointDown>
      <Typography size={200} category={"Paragraph"} weight={"Light"}>
        {tier.features[feature.name]}
      </Typography>
      {
        //TODO: use design system Alert component when it's been created
      }
      {wepaLiteCell && (
        <RideAlertBar
          message={t(
            "generic:short-order-form.admin-service-tiers-modal.lite-not-recommended-active-traders"
          )}
          type={AlertType.WARNING}
          barVariant={BarVariant.Rounded}
        />
      )}
    </div>
  );
};

const getClassNames = (tier, feature, grayBackground, wepaLiteCell) => {
  const className = "admin-service-tiers-modal__cell";
  const isTextNotBulleted =
    (feature.name === AdminServiceFeature.BOOKING_YOUR_BILLS ||
      feature.name === AdminServiceFeature.OTHER_MANDATORY_BOOKINGS) &&
    tier.name !== AdminServiceTier.Standard;
  const isTextualCell =
    isTextNotBulleted ||
    feature.name === AdminServiceFeature.OTHER_MANDATORY_BOOKINGS ||
    (feature.name === AdminServiceFeature.AUTOMATED_SECURITIES_BOOKING &&
      tier.name !== AdminServiceTier.Lite)
      ? "admin-service-tiers-modal__cell-textual"
      : "";

  return `${className} ${isTextNotBulleted ? className + "-not-bulleted" : ""} ${isTextualCell} ${
    wepaLiteCell ? className + "-wepa" : ""
  } ${grayBackground ? className + "--grey" : ""}`;
};

export default withTranslationReady(["generic"])(AdminServiceTiersModal);
