import React from "react";
import { Route, Switch } from "react-router";
import { ThirdPartyTaxAdvisorRoute } from "./ThirdPartyTaxAdvisorRoute";
import ThirdPartyTaxAdvisorClientsScene from "./scenes/ThirdPartyTaxAdvisorClients/ThirdPartyTaxAdvisorClientsScene";
import ThirdPartyTaxAdvisorClientOverviewScene from "./scenes/ThirdPartyTaxAdvisorClientOverview/ThirdPartyTaxAdvisorClientOverviewScene";
import InviteClientsEmailScene from "./scenes/InviteClientsEmail/InviteClientsEmailScene";
import InviteClientsEmailSuccessScene from "./scenes/InviteClientsEmailSuccess/InviteClientsEmailSuccessScene";
import CompanyThirdPartyTaxAdvisorClientNumber from "./scenes/Company/CompanyThirdPartyTaxAdvisorClientNumberScene";
import CompanyFiscalYearsScene from "../client/scenes/company/CompanyFiscalYears/CompanyFiscalYearsScene";
import ThirdPartyTaxAdvisorAdvisorNumberScene from "./scenes/ThirdPartyTaxAdvisorAdvisorNumber/ThirdPartyTaxAdvisorAdvisorNumberScene";
import ThirdPartyTaxAdvisorSignUpScene from "./scenes/ThirdPartyTaxAdvisorSignUp/ThirdPartyTaxAdvisorSignUpScene";
import CompanyAccountingFrameworkScene from "../taxAdvisor/scenes/CompanyAccountingFramework/CompanyAccountingFrameworkScene";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";
import { ThirdPartyTaxAdvisorMigrateScene } from "./scenes/ThirdPartyTaxAdvisorMigrate/ThirdPartyTaxAdvisorMigrateScene";
import { FeatureFlagService } from "../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../global-query-types";
import { ThirdPartyTaxAdvisorMigrateSuccessScene } from "./scenes/ThirdPartyTaxAdvisorMigrateSuccess/ThirdPartyTaxAdvisorMigrateSuccessScene";

interface ThirdPartyTaxAdvisorRoutesProps {
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy;
}

export function ThirdPartyTaxAdvisorAppRoutes({ passwordPolicy }: ThirdPartyTaxAdvisorRoutesProps) {
  const path = `/third-party-tax-advisor`;

  const migration3PTAFlowEnabled = FeatureFlagService.instance.isEnabled(
    FeatureFlags.GRO982Migrate3PTAToLowtaxSteuerberatungsGmbH
  );

  return (
    <Switch>
      <ThirdPartyTaxAdvisorRoute exact path={"/"} component={() => <></>} />

      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/clients`}
        component={ThirdPartyTaxAdvisorClientsScene}
      />
      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/clients/:id/third-party-tax-advisor-client-number`}
        component={CompanyThirdPartyTaxAdvisorClientNumber}
      />
      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/clients/:id/fiscal-year`}
        component={CompanyFiscalYearsScene}
      />
      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/clients/:id/accounting-framework`}
        component={CompanyAccountingFrameworkScene}
      />

      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/clients/:companyId`}
        component={ThirdPartyTaxAdvisorClientOverviewScene}
      />

      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/invite-clients/email`}
        component={InviteClientsEmailScene}
      />

      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/invite-clients/email/success`}
        component={InviteClientsEmailSuccessScene}
      />

      <ThirdPartyTaxAdvisorRoute
        exact
        path={`${path}/my-company/advisor-number/:clientId`}
        component={ThirdPartyTaxAdvisorAdvisorNumberScene}
      />
      <Route
        exact
        path={`${path}/signup/:id`}
        component={() => <ThirdPartyTaxAdvisorSignUpScene />}
      />

      {migration3PTAFlowEnabled && (
        <ThirdPartyTaxAdvisorRoute
          exact
          path={`${path}/migrate`}
          component={ThirdPartyTaxAdvisorMigrateScene}
        />
      )}

      {migration3PTAFlowEnabled && (
        <ThirdPartyTaxAdvisorRoute
          exact
          path={`${path}/migrate/success`}
          component={ThirdPartyTaxAdvisorMigrateSuccessScene}
        />
      )}
    </Switch>
  );
}
