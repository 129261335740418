import React from "react";
import { ReviewCard } from "../../../sharedComponents/ReviewCard/ReviewCard";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  ReviewPersonalDocuments,
  PersonalDocumentsEntry
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ReviewDocuments/ReviewPersonalDocuments/ReviewPersonalDocuments";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import ReviewCompanyDocuments from "./ReviewCompanyDocuments/ReviewCompanyDocuments";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";

interface ReviewDocumentsProps {
  personalDocumentsEntries: PersonalDocumentsEntry[];
  agreedWithLegalDocuments: boolean;
  editLink?: string;
  isCompleted?: boolean;
}

export const ReviewDocuments = withTranslationReady(["generic"])(
  ({
    t,
    order,
    personalDocumentsEntries,
    agreedWithLegalDocuments,
    editLink,
    isCompleted
  }: ReviewDocumentsProps &
    Pick<StepComponentProps<LowTaxBrokerOrderData>, "order"> &
    WithTranslation) => {
    return (
      <ReviewCard>
        <ReviewSectionTitle
          title={
            order.extra.isNewBrokerEnabled
              ? t("generic:ride-broker-onboarding.review.documents.title-v2")
              : t("generic:ride-broker-onboarding.review.documents.title")
          }
          editLink={editLink}
          isCompleted={isCompleted}
        />
        <div className="ltb-review-documents__entries">
          {!order.extra?.isNewBrokerEnabled && (
            <>
              <ReviewCompanyDocuments order={order} />
              {personalDocumentsEntries.map((personalDocuments) => (
                <ReviewPersonalDocuments
                  {...personalDocuments}
                  key={personalDocuments.personName}
                />
              ))}
            </>
          )}
          <ReviewField
            data-testid="review-confirm-legal-documents"
            label={t("generic:ride-broker-onboarding.review.documents.legal-compliance-documents")}
            value={
              agreedWithLegalDocuments
                ? t("generic:ride-broker-onboarding.review.documents.legal-documents-agreed")
                : undefined
            }
            valueWhenMissing={t(
              "generic:ride-broker-onboarding.review.documents.legal-documents-not-agreed"
            )}
          />
        </div>
      </ReviewCard>
    );
  }
);
