import ShortOrderReview from "./ShortOrderReview";
import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  VerifyDiscountCode,
  VerifyDiscountCodeVariables
} from "../../../../../../../lib/api/mutations/graphql/VerifyDiscountCode";
import { VERIFY_DISCOUNT_CODE } from "../../../../../../../lib/api/mutations/VERIFY_DISCOUNT_CODE";
import { withTranslationReady } from "../../../../../../../common/i18n/withTranslationReady";

const ShortOrderReviewContainer = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps & WithTranslation) => {
  const [callVerifyDiscountCode] = useMutation<VerifyDiscountCode, VerifyDiscountCodeVariables>(
    VERIFY_DISCOUNT_CODE
  );

  const verifyDiscountCode = async (
    discountCode: string
  ): Promise<{ isValid: boolean; discountValue: number }> => {
    const request = await callVerifyDiscountCode({ variables: { discountCode } });
    return request.data?.verifyDiscountCode ?? { isValid: false, discountValue: 0 };
  };

  return (
    <ShortOrderReview
      currentStep={currentStep}
      order={order}
      controls={controls}
      saveData={saveData}
      verifyDiscountHandler={verifyDiscountCode}
    />
  );
};

export default withTranslationReady(["generic"])(ShortOrderReviewContainer);
