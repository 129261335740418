import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { CompanyRegistryNumberSchema } from "lib/validation/CompanyRegistryNumberSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";

const CompanyRegistryNumberStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const initialValues = {
    "company-registry-number": order.extra.companyRegistryNumber ?? ""
  };

  const onSubmit = async () => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        companyRegistryNumber: formik.values["company-registry-number"],
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validationSchema: CompanyRegistryNumberSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <CompanyRegistryNumber />
        {controls}
      </Form>
    </FormikProvider>
  );
};

const CompanyRegistryNumber = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div data-testid="company-registry-number" className="company-registry-number">
      <StepHeading text={t("generic:ride-broker-onboarding.company-registry-number.title")} />
      <RideTextInput
        name="company-registry-number"
        label={t("generic:ride-broker-onboarding.company-registry-number.label")}
        placeholder={t("generic:ride-broker-onboarding.company-registry-number.placeholder")}
        className="company-registry-number__ride-input"
      />
    </div>
  );
});

export default withTranslation(["generic"])(CompanyRegistryNumberStep);
