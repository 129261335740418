import React from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { AdminServiceListContainer } from "../../components/AdminServiceList/AdminServiceListContainer";

const AdminServiceListScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:admin-service")}</h2>
        </Col>
      </Row>
      <AdminServiceListContainer />
    </>
  );
};

export default withTranslation("header")(AdminServiceListScene);
