import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { NomineeCompanyDetails_companyById_bankAccounts } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import Table from "../../../../components/generic/Table";

interface BankAccountsTableProps extends WithTranslation {
  bankAccounts: NomineeCompanyDetails_companyById_bankAccounts[];
}

const BankAccountTable = ({ bankAccounts, t }: BankAccountsTableProps) => {
  const columns = [
    {
      dataField: "name",
      text: t("nominee-dashboard:company.bank-accounts-table.name"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts) => {
        return <span data-testid="bank-account-name">{row.name}</span>;
      }
    },
    {
      dataField: "bankProvider",
      text: t("nominee-dashboard:company.bank-accounts-table.bankProvider"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts) => (
        <span data-testid="bank-account-provider">
          {t(`client:banking.banks.${row.bankProvider}`)}
        </span>
      )
    },
    {
      dataField: "iban",
      text: t("nominee-dashboard:company.bank-accounts-table.iban"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts) => (
        <span data-testid="bank-account-iban">{row.iban}</span>
      )
    },
    {
      dataField: "plan",
      text: t("nominee-dashboard:company.bank-accounts-table.plan"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts) => (
        <span data-testid="bank-account-plan">
          {row.plan ? t(`client:banking.bank-account-plans.${row.plan}`) : null}
        </span>
      )
    }
  ];

  return (
    <Table
      testid="bank-accounts-table"
      data={bankAccounts}
      columns={columns}
      tableStyle="greenTable"
      keyField="id"
    />
  );
};

export default withTranslationReady(["nominee-dashboard", "client"])(BankAccountTable);
