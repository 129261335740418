import React from "react";

export interface RideRadioButtonProps {
  label: string;
  selected?: boolean;
  callback: (id: string | number) => void;
  id: string | number;
  className?: string;
  name?: string;
}

export const RideRadioButton = ({
  label,
  selected = false,
  callback,
  id,
  className,
  name
}: RideRadioButtonProps) => {
  return (
    <div>
      <div
        data-testid={name ? `${name}-ride-radio-button` : "ride-radio-button"}
        data-selected={selected}
        data-name={name}
        className={`ride-radio-button ${className ? className : ""}`}
        onClick={() => {
          callback(id);
        }}>
        <div
          data-testid="ride-radio-button__pointer"
          className={`ride-radio-button__pointer ${
            selected ? "ride-radio-button__pointer--selected" : ""
          }`}
        />
        <div data-testid="ride-radio-button__label" className="ride-radio-button__label">
          {label}
        </div>
      </div>
    </div>
  );
};
