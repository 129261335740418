import React, { KeyboardEventHandler } from "react";
import { Field } from "formik";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";
import { useRideField } from "../../../lib/hooks/useRideField";

export enum InputAutoComplete {
  Off = "new-password", // Chrome hurts us here, so we need to use this hack
  On = "on"
}

interface RideTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  placeholder?: string;
  asTextArea?: boolean;
  className?: string;
  disabled?: boolean;
  autoComplete?: InputAutoComplete;
}

export const RideTextInput = ({
  name,
  label,
  placeholder,
  asTextArea,
  className,
  disabled = false,
  autoComplete = InputAutoComplete.On,
  ...extraProps
}: RideTextInputProps) => {
  const field = useRideField(name);

  const containerClasses = `ride-text-input ${className ?? ""}`;
  const labelClasses = `ride-text-input__label ${className ? className + "__label" : ""}`;
  const inputClasses = `ride-text-input__input ${
    asTextArea ? "ride-text-input__input--as-textarea" : ""
  } ${field.touched && field.error ? "ride-text-input__input--error" : ""} ${
    className ? className + "__input" : ""
  }`;

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Escape") {
      field.setValue("");
    }
    extraProps.onKeyDown?.(e);
  };

  return (
    <div data-testid={`${name}-input-container`} className={containerClasses}>
      {label && (
        <label data-testid={`${name}-label`} className={labelClasses} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        {...extraProps}
        data-testid={`${name}-input`}
        autoComplete={autoComplete}
        id={name}
        name={name}
        placeholder={placeholder}
        className={inputClasses}
        as={asTextArea ? "textarea" : "input"}
        disabled={disabled}
        onKeyDown={handleKeyDown}
      />
      <ErrorMessageWithT name={name} extra={{}} />
    </div>
  );
};
