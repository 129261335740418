import * as React from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TFunction } from "i18next";
import { useMutation } from "react-apollo";
import {
  START_COMPANY_CREATION,
  UPDATE_SHAREHOLDERS
} from "../../../../../components/public/CompanyCreation/Mutations";
import {
  StartCompanyCreation,
  StartCompanyCreationVariables
} from "../../../../../components/public/CompanyCreation/graphql/StartCompanyCreation";
import { AssertionError } from "assert";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "../../../../../components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import {
  UpdateShareholders,
  UpdateShareholdersVariables
} from "../../../../../components/public/CompanyCreation/graphql/UpdateShareholders";
import logger from "../../../../../common/Logger";
import { ApolloQueryResult } from "apollo-client";
import { getFlowTypePath } from "../../../../../components/public/CompanyCreationFlowType/currentFlowType";
import { getReferral } from "../../../../../common/getReferral";
import { FlowTypeEnum, RolesInGroup, SalutationType } from "../../../../../global-query-types";
import { Col, Container, Row } from "react-bootstrap";
import { PersonListShareholderForm } from "../../../../../components/public/CompanyCreation/Steps/Shareholders/PersonListShareholderForm";
import { ShareHolderFormDescription } from "../../../../../components/public/CompanyCreation/Steps/Shareholders/ShareHolderFormDescription";
import {
  ShareHolderType,
  ShareHolderTypeSelector
} from "../../../../../components/public/CompanyCreation/Steps/Shareholders/ShareHolderTypeSelector";
import { CompanyShareholderFormContainer } from "../../../../../components/public/CompanyCreation/Steps/Shareholders/CompanyShareholderFormContainer";
import { storeToken } from "../../../../../common/Authentication/storeToken";

interface Props {
  t: TFunction;
  myCompanyCreationDetails?: MyCompanyCreationQuery_myCompanyCreation;
  resetActiveStep: (string) => void;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
  isActiveUser: boolean;
}

export enum SalutationValueEnum {
  Mr,
  Ms
}

const shareHolderDetails = (elem) => {
  return {
    id: elem.person?.id,
    title: elem.person?.title,
    firstName: elem.person?.firstName,
    lastName: elem.person?.lastName,
    phoneNumber: elem.person?.phoneNumber,
    salutation: SalutationValueEnum[elem.person?.salutation!],
    email: elem.person?.user?.email ?? ""
  };
};

const companyCreationShareholderDetails = (
  myCompanyCreationDetails?: MyCompanyCreationQuery_myCompanyCreation
) => {
  return myCompanyCreationDetails?.company?.group?.memberships
    ?.filter((elem) => elem.role === RolesInGroup.Shareholder)
    ?.map((elem) => shareHolderDetails(elem));
};

const convertValuesToShareholder = (values) => ({
  id: values.id,
  title: values.title,
  email: values.email,
  firstName: values.firstName,
  lastName: values.lastName,
  phoneNumber: values.phoneNumber,
  salutation: SalutationType[SalutationValueEnum[values.salutation]]
});

const convertValuesToShareholderList = (values) =>
  values.shareholders.map(convertValuesToShareholder);

export const CompanyCreationForm: React.FC<Props> = ({
  t,
  myCompanyCreationDetails,
  resetActiveStep,
  refetchMyCompanyCreation,
  currentFlowType,
  isActiveUser
}) => {
  const companyCreationId = myCompanyCreationDetails?.id;

  const history = useHistory();
  const location = useLocation();
  const referral = getReferral(location);

  const [startCompanyCreation, { error: errorStarting, loading: loadingStarting }] = useMutation<
    StartCompanyCreation,
    StartCompanyCreationVariables
  >(START_COMPANY_CREATION);

  const [updateShareholders, { error: errorUpdating, loading: loadingUpdating }] = useMutation<
    UpdateShareholders,
    UpdateShareholdersVariables
  >(UPDATE_SHAREHOLDERS);

  const error = errorStarting || errorUpdating;
  const loading = loadingStarting || loadingUpdating;

  const flowType = getFlowTypePath(currentFlowType);
  const handleFormUpdate = async () => {
    const { data } = await refetchMyCompanyCreation({
      id: companyCreationId!
    });

    resetActiveStep(data.myCompanyCreation?.currentStep);
  };
  const handleNewFlow = async (
    token: string | null | undefined,
    companyCreation: { id: string; flowType: FlowTypeEnum | null } | null | undefined
  ) => {
    if (!token || !companyCreation || !companyCreation.flowType) {
      throw new AssertionError({ message: "Company Creation authorization not successful" });
    }

    storeToken(token);

    const companyCreationId = companyCreation.id;
    const postOrderFlowType = getFlowTypePath(companyCreation.flowType);

    history.push(`/${postOrderFlowType}/${companyCreationId}`);
  };

  const handleSubmit = async (values) => {
    const creator = convertValuesToShareholder(values);
    const shareholders = convertValuesToShareholderList(values);

    if (companyCreationId) {
      try {
        await updateShareholders({
          variables: {
            companyCreationId: companyCreationId,
            creator,
            shareholders: shareholders,
            flowType: currentFlowType
          }
        });

        await handleFormUpdate();

        return;
      } catch (error) {
        logger.error(error);
        throw new AssertionError({ message: "update shareholders not successful" });
      }
    }

    try {
      const { data } = await startCompanyCreation({
        variables: {
          creator: creator,
          shareholders: shareholders,
          flowType: currentFlowType,
          referral
        }
      });

      const response = data?.startCompanyCreation;
      handleNewFlow(response?.authentication?.token, response?.companyCreation);
    } catch (error) {
      logger.error(error);
      if ((error as any).message.includes("EMAIL_ACCOUNT_EXISTS_RESUME_EMAIL_SENT")) {
        history.push("/booking/gmbh/resume-link-sent");
        return;
      }

      throw new AssertionError({ message: `Company Creation not successful: ${error.message}` });
    }
  };

  const companyCreationDetails = companyCreationShareholderDetails(myCompanyCreationDetails);

  const getDefaultValuesOfShareholderStep = () => {
    return {
      salutation: SalutationValueEnum.Mr,
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: ""
    };
  };

  const stepOneFields = getDefaultValuesOfShareholderStep();

  const companyCreationValues =
    companyCreationDetails && companyCreationDetails.length ? companyCreationDetails : [];

  const stepOneFieldValues =
    companyCreationValues.length >= 1 ? companyCreationValues[0] : stepOneFields;

  const initialShareholders = companyCreationValues.slice(1);
  const memberships = myCompanyCreationDetails?.company?.group?.memberships;

  const initialShareHolderType = memberships?.some((shareholder) => !!shareholder.company)
    ? ShareHolderType.HOLDING
    : ShareHolderType.PERSONAL;

  const [shareHolderType, setShareHolderType] = useState(initialShareHolderType);

  return (
    <div>
      <Row>
        <Col className="steps-form-description" data-testid="shareholder-form-description">
          <ShareHolderFormDescription t={t} currentFlowType={currentFlowType} />
        </Col>
      </Row>
      <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
        <Container>
          <Row>
            <Col>
              <ShareHolderTypeSelector
                t={t}
                className="pt-3"
                value={shareHolderType}
                onChange={setShareHolderType}
                disabled={
                  !!myCompanyCreationDetails && initialShareHolderType === ShareHolderType.HOLDING
                }
              />

              {shareHolderType === ShareHolderType.HOLDING ? (
                <CompanyShareholderFormContainer
                  t={t}
                  flowType={currentFlowType}
                  referral={referral}
                  companyCreationId={myCompanyCreationDetails?.id}
                  memberships={memberships}
                  handleNewFlow={handleNewFlow}
                  handleFormUpdate={handleFormUpdate}
                />
              ) : (
                <PersonListShareholderForm
                  t={t}
                  handleSubmit={handleSubmit}
                  initialShareholders={initialShareholders}
                  stepOneFieldValues={stepOneFieldValues}
                  flowType={flowType}
                  error={error}
                  loading={loading}
                  stepOneFields={stepOneFields}
                  isActiveUser={isActiveUser}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CompanyCreationForm;
