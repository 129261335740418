import React from "react";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";
import { formatValue } from "../../../common/formatters";
import { PendingText } from "../../../components/client/company/components/PendingText";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";
import { RideCardBodySeparator } from "../../RideCardBodySeparator/RideCardBodySeparator";

interface GmbHNumbersAndIdsDetailsProps extends CompanyCardProps {}

const CompanyNumbersAndIdsCard = ({ t, gmbh, handleEditField }: GmbHNumbersAndIdsDetailsProps) => {
  return (
    <RideSectionCard
      data-testid="gmbh-numbers-and-ids-details"
      title={t("client:company:numbers-and-ids")}
      className="shadow-none mt-5">
      <RideCardValuePair
        name={t("generic:company-tax-number")}
        value={gmbh.taxNumber ? formatValue(gmbh.taxNumber) : <PendingText t={t} />}
        data-testid="company-tax-number"
        onEdit={handleEditField?.("tax-number")}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}
      />

      <RideCardBodySeparator />

      <RideCardValuePair
        name={t("generic:company-vat-number")}
        value={gmbh.vatNumber ? formatValue(gmbh.vatNumber) : <PendingText t={t} />}
        onEdit={handleEditField?.("vat-number")}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}
        data-testid="company-vat-number"
      />

      <RideCardBodySeparator />

      <RideCardValuePair
        name={t("generic:company-lei")}
        value={gmbh.lei ? formatValue(gmbh.lei) : <PendingText t={t} />}
        data-testid="company-lei"
        onEdit={handleEditField?.("lei")}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}
      />
    </RideSectionCard>
  );
};

export default CompanyNumbersAndIdsCard;
