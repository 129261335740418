import { withTranslationReady } from "common/i18n/withTranslationReady";
import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";

export interface PasswordRequirementsProps extends PropsWithChildren<{}>, WithTranslation {
  password?: string;
  isTouched?: boolean;
}

const PasswordRequirements = ({ password, isTouched, t }: PasswordRequirementsProps) => {
  return (
    <div className="password-policy" data-testid="set-password-policy">
      <div className="password-policy__title">{t("auth:set-password.policy.title")}</div>
      {mountPasswordRequirementIfTheRequirementIsMet(
        t("auth:set-password.policy.requirements.shouldHaveMinimumNumberOfCharacters"),
        isPasswordWithMinimumLength(password ?? "", isTouched ?? false),
        "minimum-8-characters"
      )}
      {mountPasswordRequirementIfTheRequirementIsMet(
        t("auth:set-password.policy.requirements.shouldContainNumberAndSpecialCharacter"),
        isPasswordWithNumbers(password ?? "", isTouched ?? false) &&
          isPasswordWithALeastOneSpecialChar(password ?? "", isTouched ?? false),
        "should-contain-a-number-and-a-special-character"
      )}
      {mountPasswordRequirementIfTheRequirementIsMet(
        t("auth:set-password.policy.requirements.shouldNotHaveBlankSpaces"),
        isPasswordWithoutBlankSpaces(password ?? "", isTouched ?? false),
        "no-blank-spaces"
      )}
    </div>
  );
};

function mountPasswordRequirementIfTheRequirementIsMet(
  description: string,
  isRequirementMet: boolean,
  testPrefix: string
) {
  return isRequirementMet === true
    ? mountPasswordRequirement(description, testPrefix)
    : mountInvalidPasswordRequirementFeedback(description, testPrefix);
}

function mountPasswordRequirement(description: string, testPrefix: string) {
  return (
    <div className="password-policy__requirement" data-testid={`${testPrefix}-requirement`}>
      {description}
    </div>
  );
}

function mountInvalidPasswordRequirementFeedback(description: string, testPrefix: string) {
  return (
    <InvalidFeedback testPrefix={testPrefix} hidden={false}>
      {description}
    </InvalidFeedback>
  );
}

function isPasswordWithMinimumLength(password: string, isTouched: boolean): boolean {
  if (!isTouched) {
    return true;
  }

  const minimumLength = 8;

  return password?.length >= minimumLength;
}

function isPasswordWithNumbers(password: string, isTouched: boolean): boolean {
  if (!isTouched) {
    return true;
  }

  const stringContainsNumbersRegex: RegExp = new RegExp("[0-9]");
  return password.match(stringContainsNumbersRegex) !== null;
}

function isPasswordWithALeastOneSpecialChar(password: string, isTouched: boolean): boolean {
  if (!isTouched) {
    return true;
  }

  const stringContainsSpecialCharRegex: RegExp = new RegExp("[^\\w\\n]|_");

  return password.match(stringContainsSpecialCharRegex) !== null;
}

function isPasswordWithoutBlankSpaces(password: string, isTouched: boolean): boolean {
  if (!isTouched) {
    return true;
  }

  return !password.includes(" ");
}

export default withTranslationReady(["auth"])(PasswordRequirements);
