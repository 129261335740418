import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import ContinueOrderBanner from "client/components/ContinueOrderBanner/ContinueOrderBanner";
import { WithTranslation } from "react-i18next";

const ThirdPartyTaxAdvisorMigrateBanner = ({ t }: WithTranslation) => {
  return (
    <ContinueOrderBanner
      dataTestId={"update-contract-banner"}
      ctaLink={`/third-party-tax-advisor/migrate`}
      title={t("generic:migrate-3pta.banner.title")}
      message={t("generic:migrate-3pta.banner.description")}
      continueButtonLabel={t("generic:migrate-3pta.banner.cta")}
    />
  );
};

export default withTranslationReady(["generic"])(ThirdPartyTaxAdvisorMigrateBanner);
