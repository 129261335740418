import React from "react";
import PhoneInput from "react-phone-input-2";
import { InputState } from "../RideInput/RideInput";

interface RidePhoneInputProps {
  name: string;
  value: string;
  label: string;
  labelDescription?: string;
  className?: string;
  onChange: (
    value: string,
    data: {},
    event: React.ChangeEvent<HTMLInputElement>,
    formattedData: string
  ) => void;
  state?: InputState;
  testId?: string;
}

const RidePhoneInput = ({
  name,
  value,
  label,
  labelDescription = undefined,
  onChange,
  className = "",
  state = InputState.DEFAULT,
  testId = `${name}-input`
}: RidePhoneInputProps) => {
  return (
    <>
      <div data-testid={`${name}-phone`} className={`ride-input ride-input--${state}`}>
        <label className={"ride-input__label"}>{label}</label>
        {labelDescription && (
          <label className={"ride-input__label-description"}>{labelDescription}</label>
        )}
        <PhoneInput
          country="de"
          inputClass={`ride-input__input ${className}`}
          inputProps={{ "data-testid": testId }}
          disableSearchIcon
          autoFormat
          onChange={onChange}
          value={value}
          specialLabel={""}
          isValid={value.length > 7 && value.length < 16}
        />
      </div>
    </>
  );
};

export default RidePhoneInput;
