import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const CalendarIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="calendar-icon"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 3H16.5V1.5H15V3H9V1.5H7.5V3H4.5C3.675 3 3 3.675 3 4.5V19.5C3 20.325 3.675 21 4.5 21H19.5C20.325 21 21 20.325 21 19.5V4.5C21 3.675 20.325 3 19.5 3ZM4.5 19.5H19.5V9H4.5V19.5ZM4.5 7.5H19.5V4.5H16.5V6H15V4.5H9V6H7.5V4.5H4.5V7.5Z"
      fill="currentColor"
    />
  </svg>
);
