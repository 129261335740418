import React, { PropsWithChildren } from "react";
import { AuthHeader } from "sharedComponents/AuthHeader/AuthHeader";
import RideFooter from "sharedComponents/RideFooter/RideFooter";

interface AuthLayoutProps extends PropsWithChildren<{}> {
  title?: string;
  subtitle?: string;
}

export const AuthLayout = ({ children, title, subtitle }: AuthLayoutProps) => {
  const hasHeader = title || subtitle;

  return (
    <div className="auth-layout">
      <div className="auth-layout__container" data-testid="auth-layout__container">
        <div className="auth-layout__header" data-testid="auth-auth-layout__header">
          <img src="/assets/RIDE-Logo.svg" alt=".RIDE logo" className="auth-layout__logo" />
        </div>

        {hasHeader && <AuthHeader title={title} subtitle={subtitle} />}

        <div className="auth-layout__children">{children}</div>
      </div>
      <RideFooter />
    </div>
  );
};
