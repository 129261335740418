import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import RideModal from "uiLibrary/designSystem/RideModal/RideModal";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { useHistory } from "react-router-dom";
import { AdminServicePTAMigrationToRideSteuerberatungHelper } from "lib/helpers/AdminServicePTAMigrationToRideSteuerberatungHelper";

interface PtaTerminationContractModalProps extends WithTranslation {
  onConfirm?: () => void;
  onClose: () => void;
}
const PtaTerminationContractModal = ({
  t,
  onConfirm,
  onClose
}: PtaTerminationContractModalProps) => {
  const history = useHistory();
  const keyPrefix = "steuragenter-customer-migration.modal";

  const goToSteueragentenASCheckout = () => {
    onConfirm?.();
    history.push(`${AdminServicePTAMigrationToRideSteuerberatungHelper.adminServiceFlowUrlSuffix}`);
  };

  return (
    <RideModal
      data-testid="pta-termination-contract-modal"
      title={""}
      onClose={onClose}
      fitContentWidth={true}
      fitContentHeight={true}>
      <div className="d-flex justify-content-center">
        <article className={"pta-termination-contract-modal d-flex flex-column px-3 px-lg-5"}>
          <div className="mt-5">
            <Typography category={"Headline"} size={200} weight={"Heavy"}>
              <Trans i18nKey={t(`generic:${keyPrefix}.title`)} />
            </Typography>
          </div>
          <div className="mt-3">
            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              <Trans i18nKey={t(`generic:${keyPrefix}.description`)} />
            </Typography>
          </div>
          <section className="mt-4">
            <div>
              <Typography category={"Paragraph"} size={100} weight={"Heavy"}>
                {t(`generic:${keyPrefix}.whats-new.header`)}
              </Typography>
            </div>
            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              <Trans
                i18nKey={`generic:${keyPrefix}.whats-new.items`}
                components={{
                  bullet: "• "
                }}
              />
            </Typography>
          </section>
          <section className="mt-3">
            <div>
              <Typography category={"Paragraph"} size={100} weight={"Heavy"}>
                {t(`generic:${keyPrefix}.next-steps.header`)}
              </Typography>{" "}
            </div>
            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              <Trans
                i18nKey={`generic:${keyPrefix}.next-steps.items`}
                components={{
                  bullet: "• "
                }}
              />
            </Typography>
          </section>
          <section className="mt-4">
            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              <Trans
                i18nKey={`generic:${keyPrefix}.more-info`}
                components={{
                  FAQLink: (
                    <TextLink
                      variant={LinkVariant.primary}
                      to={t(`generic:${keyPrefix}.faq-link`)}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    />
                  ),
                  YoutubeVideoLink: (
                    <TextLink
                      variant={LinkVariant.primary}
                      to={t(`generic:${keyPrefix}.youtube-video-link`)}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    />
                  )
                }}
              />
            </Typography>
          </section>
          <section className="mt-4">
            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              {t(`generic:${keyPrefix}.approval-deadline`)}
            </Typography>
          </section>
          <section className="d-flex justify-content-end mt-3 mb-5">
            <RideButton
              data-testid={"remind-me-btn"}
              label={t(`generic:${keyPrefix}.buttons.remind-me`)}
              className="flex-grow-0 mr-3"
              variant={RideButtonVariant.SECONDARY}
              size={RideButtonSize.BIG}
              onClick={onClose}
            />
            <RideButton
              data-testid={"sign-new-contract-btn"}
              label={t(`generic:${keyPrefix}.buttons.sign-new-contract`)}
              className="flex-grow-0"
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
              onClick={goToSteueragentenASCheckout}
            />
          </section>
        </article>
      </div>
    </RideModal>
  );
};

export default withTranslationReady(["generic"])(PtaTerminationContractModal);
