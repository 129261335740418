import { FeatureFlags } from "global-query-types";
import React, { PropsWithChildren } from "react";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";

interface FeatureFlagProps extends PropsWithChildren<any> {
  name: FeatureFlags;
}

export const FeatureFlag = ({ name, children }: FeatureFlagProps) => {
  const isEnabled = FeatureFlagService.instance.isEnabled(name);
  return <>{isEnabled && children}</>;
};
