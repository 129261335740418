import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import TaxAdvisorProfile from "components/client/company/components/TaxAdvisorProfile";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { AdminServiceStatusEnum, GmbH } from "lib/models/client/GmbH";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";

interface AdminServiceSectionProps extends WithTranslation {
  gmbh: GmbHDetails;
}

const AdminServiceSection = ({ gmbh, t }: AdminServiceSectionProps) => {
  const taxAdvisor = gmbh.taxAdvisor;

  const adminServiceWaitingForConfirmation =
    gmbh.adminServiceStatus === AdminServiceStatusEnum.WAITING_FOR_CONFIRMATION;

  return (
    <Container data-testid="admin-service-section" className="admin-service-section" fluid>
      {taxAdvisor && <TaxAdvisorRow taxAdvisor={taxAdvisor} t={t} />}
      {adminServiceWaitingForConfirmation && <AdminServiceBeingPrepared t={t} />}
    </Container>
  );
};

export const TaxAdvisorRow = ({
  taxAdvisor,
  t,
  type = "GmbH",
  fistName,
  lastName
}: {
  taxAdvisor?: GmbH;
  t: TFunction;
  type?: "GmbH" | "User";
  fistName?: string | null;
  lastName?: string | null;
}) => {
  return (
    <Row className="pt-35 pt-md-0 bg-white" data-testid="tax-advisor-row">
      <Col className="d-flex justify-content-center px-4">
        <div className="admin-service-section__header d-sm-flex align-items-center">
          <div className="pr-sm-3 mb-3 mb-sm-0 admin-service-section__tax-advisor">
            <TaxAdvisorProfile taxAdvisor={taxAdvisor} firstName={fistName} lastName={lastName} />
          </div>
          {type === "GmbH" && (
            <div
              data-testid="admin-service-article"
              className="admin-service-section__article pl-sm-3">
              <p className="my-2">{t("client:tax-advisor.article")}</p>
            </div>
          )}
          {type === "User" && (
            <div
              data-testid="admin-service-article"
              className="admin-service-section__article pl-sm-3">
              <p className="my-2">{t("third-party-tax-advisor:invitation")}</p>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

const AdminServiceBeingPrepared = ({ t }: { t: TFunction }) => (
  <Container className="my-5">
    <div data-testid="admin-service-being-prepared">
      <RideAlertBar
        type={AlertType.INFO}
        message={t("client:gmbh-tile.admin-service-is-being-prepared")}
      />
    </div>
  </Container>
);

export default withTranslationReady(["generic", "client"])(AdminServiceSection);
