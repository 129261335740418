import React from "react";
import { ClubDealContainer } from "../../components/investments/ClubDealDetails/ClubDealDetailsContainer";
import { ModalLayout } from "../../../sharedComponents/templates/ModalLayout/ModalLayout";
import { useHistory } from "react-router-dom";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";

export const ClubDealScene = () => {
  const history = useHistory();

  const handleClose = () => {
    history.push(`/client/investments`);
  };

  const handleBack = () => {
    history.push(`/client/club-deals`);
  };

  return (
    <FeatureFlag name={FeatureFlags.ClientInvestments}>
      <ModalLayout onBack={handleBack} onClose={handleClose}>
        <ClubDealContainer />
      </ModalLayout>
    </FeatureFlag>
  );
};
