import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import EditableSection from "../../../../sharedComponents/EditableSection/EditableSection";
import { withTranslation, WithTranslation } from "react-i18next";
import { NotaryLinksComponent } from "./NotaryLinksComponent";
import { FullPersonDetailsQuery_getPersonDetails_notaryLinks } from "../graphql/FullPersonDetailsQuery";
import { EditNotaryLinksComponent } from "./EditNotaryLinksComponent";
import { NotaryLinksSchema } from "../../../../lib/validation/NotaryLinksSchema";
import { NotaryDocumentType, NotaryLinkItem } from "../../../../global-query-types";

interface NotaryLinksSectionProps extends WithTranslation {
  personId: string;
  links: FullPersonDetailsQuery_getPersonDetails_notaryLinks[];
  onSubmit: (notaryPersonId: string, links: NotaryLinkItem[]) => Promise<void>;
}

type FormValues = {
  [key in NotaryDocumentType]: string;
};

export const NotaryLinksSection = ({ t, personId, links, onSubmit }: NotaryLinksSectionProps) => {
  const initialValues = Object.keys(NotaryDocumentType).reduce((initialValues, type) => {
    initialValues[type] = links.find((link) => link.notaryDocumentType === type)?.link;
    return initialValues;
  }, {}) as FormValues;

  const handleSubmit = async (values: FormValues, actions) => {
    const links = Object.entries(values).map(([key, value]) => ({
      notaryDocumentType: key as NotaryDocumentType,
      link: value
    }));
    await onSubmit(personId, links);
    actions.resetForm();
    setEditMode(false);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: NotaryLinksSchema,
    onSubmit: handleSubmit
  });

  const [editMode, setEditMode] = useState(false);

  const onEdit = () => {
    setEditMode(true);
  };

  const onView = () => {
    setEditMode(false);
  };

  return (
    <div data-testid="notary-links-section">
      <FormikProvider value={formik}>
        <Form data-testid="roles-edit-form">
          <EditableSection
            testIdPrefix="notary-links-"
            title={t("nominee-dashboard:person.notary-links.title")}
            isEditMode={editMode}
            onEdit={onEdit}
            onView={onView}>
            {editMode ? (
              <EditNotaryLinksComponent formik={formik} t={t} />
            ) : (
              <NotaryLinksComponent links={links ?? []} t={t} />
            )}
          </EditableSection>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslation(["nominee-dashboard"])(NotaryLinksSection);
