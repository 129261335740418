import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LegalForm } from "global-query-types";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { RideHeaderColumn } from "lib/types/types";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, { RideTableCellVariant } from "sharedComponents/RideTableCell/RideTableCell";
import { companyName } from "lib/formatters/companyFormatter";
import { personNameWithFallback } from "lib/formatters/personFormatter";

interface TradingTransactionHistoryCompaniesListProps extends WithTranslation {
  companies: CompanyEntry[];
}

export interface CompanyEntry {
  id: string;
  name: string;
  legalForm: LegalForm;
  contactPerson: {
    id: string;
    firstName: string | null;
    lastName: string | null;
  } | null;
}

const TradingTransactionHistoryCompaniesList = ({
  t,
  companies
}: TradingTransactionHistoryCompaniesListProps) => {
  const columns: RideHeaderColumn[] = [
    t("nominee-dashboard:trading-transaction-history-companies-list.company"),
    t("nominee-dashboard:trading-transaction-history-companies-list.client"),
    t("nominee-dashboard:trading-transaction-history-companies-list.trading-transactions")
  ].map((label) => ({
    label,
    name: ""
  }));

  return (
    <div
      data-testid="trading-transaction-history-companies-list"
      className="trading-transaction-history-companies-list">
      <RideTableHeader columns={columns} />
      {companies.map((company, index) => (
        <RideTableRow
          colCount={columns.length}
          key={index}
          className="trading-transaction-history-companies-list__row">
          <RideTableCell
            value={companyName(company)}
            link={`/nominee-companies/${company.id}`}
            variant={RideTableCellVariant.secondaryLinkDecorated}
          />
          {company.contactPerson ? (
            <RideTableCell
              value={personNameWithFallback(company.contactPerson)}
              link={`/nominee-persons/${company.contactPerson!.id}`}
              variant={RideTableCellVariant.secondaryLinkDecorated}
            />
          ) : (
            <RideTableCell />
          )}
          <RideTableCell
            value={t("nominee-dashboard:trading-transaction-history-companies-list.see-more")}
            link={`/admin/client-services/trading/transaction-history/${company.id}`}
            variant={RideTableCellVariant.primaryLinkDecorated}
          />
        </RideTableRow>
      ))}
    </div>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(
  TradingTransactionHistoryCompaniesList
);
