import { Field } from "formik";
import React from "react";

interface RideNewEntryProps {
  name: string;
  label: string;
  disabled?: boolean;
  renderValidationErrors?: boolean;
  onClick?: () => Promise<void>;
}

export const RideNewEntry = ({ name, label, disabled, onClick }: RideNewEntryProps) => (
  <>
    <label data-testid={`${name}-checkbox`} className={`ride-new-entry`}>
      <Field type="checkbox" name={name} disabled={disabled} onClick={onClick} />
      <span className="label">{label}</span>
    </label>
  </>
);
