import React from "react";
import { WithTranslation } from "react-i18next";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { getFinanceAdsCodes_getFinanceAdsCodes_financeAdsCodes } from "../../../lib/api/queries/graphql/getFinanceAdsCodes";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell from "../../../sharedComponents/RideTableCell/RideTableCell";
import { DateFormatter } from "../../../common/formatters/DateFormatter";
import NoResultsPlaceholder from "../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";

export interface FinanceAdsCodesTableProps extends WithTranslation {
  financeAdsCodes: getFinanceAdsCodes_getFinanceAdsCodes_financeAdsCodes[];
}

const extractDateFromCode = (financeAdsCode: string) => {
  const dateInMilliseconds = financeAdsCode.split("-")[1];
  if (isNaN(Number(dateInMilliseconds))) return "---";
  return new DateFormatter().format(new Date(Number.parseInt(dateInMilliseconds)));
};

const FinanceAdsCodesTable = ({ t, financeAdsCodes }: FinanceAdsCodesTableProps) => {
  const columnHeaders = [
    { name: "index", label: t("generic:finance-ads.index") },
    { name: "code", label: t("generic:finance-ads.code") },
    { name: "email", label: t("generic:finance-ads.email") },
    { name: "date", label: t("generic:finance-ads.date") }
  ];

  return (
    <div className="finance-ads-list">
      <RideTableHeader columns={columnHeaders} />
      {financeAdsCodes.length ? (
        financeAdsCodes.map((financeAdsCode, index) => {
          return (
            <RideTableRow
              className="finance-ads-list__row"
              colCount={columnHeaders.length}
              key={index}>
              <RideTableCell value={index + 1} />
              <RideTableCell value={financeAdsCode.financeAdsCode} />
              <RideTableCell value={financeAdsCode.email} />
              <RideTableCell value={extractDateFromCode(financeAdsCode.financeAdsCode)} />
            </RideTableRow>
          );
        })
      ) : (
        <NoResultsPlaceholder text={t("generic:finance-ads.no-results")} />
      )}
    </div>
  );
};

export default withTranslationReady(["generic"])(FinanceAdsCodesTable);
