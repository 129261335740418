import React from "react";
import { useHistory } from "react-router-dom";
import { WithTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import MyTaxAdvisorCompanyEditContainer from "../../../sharedComponents/MyTaxAdvisorCompanyEditContainer/MyTaxAdvisorCompanyEditContainer";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { PageMetaData } from "../../../sharedComponents/PageMetaData/PageMetaData";

interface ThirdPartyTaxAdvisorAdvisorNumberSceneProps extends WithTranslation {}

const ThirdPartyTaxAdvisorAdvisorNumberScene = ({
  t
}: ThirdPartyTaxAdvisorAdvisorNumberSceneProps) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.fiscalYear")} />
      <ModalFormLayout title={t("client:company.edit.advisorNumber")} onBack={goBack}>
        <MyTaxAdvisorCompanyEditContainer fieldName="advisorNumber" />
      </ModalFormLayout>
    </>
  );
};

export default withTranslationReady("client")(ThirdPartyTaxAdvisorAdvisorNumberScene);
