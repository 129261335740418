import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import RequestFeedbackForm from "../../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import RidePassword from "../../../../sharedComponents/FormControls/RidePassword/RidePassword";
import PasswordRequirements from "../../../../auth/components/PasswordRequirements/PasswordRequirements";
import { LinkVariant, TextLink } from "../../../../sharedComponents/TextLink/TextLink";
import { UpdatePasswordSchema } from "../../../../lib/validation/UpdatePasswordSchema";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";
import { storeToken } from "../../../../common/Authentication/storeToken";
import { useHistory } from "react-router-dom";

interface ProfilePasswordCardProps extends WithTranslation {
  onSubmit: (currentPassword: string, newPassword: string) => Promise<string | null | void>;
  passwordPolicy?: AppConfigQuery_appConfig_passwordPolicy | null;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
}

const ProfilePasswordEdit = ({
  onSubmit,
  passwordPolicy,
  requests,
  t
}: ProfilePasswordCardProps) => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: ""
    },
    onSubmit: async (values) => {
      const token = await onSubmit(values["currentPassword"] ?? "", values["password"] ?? "s");

      if (token) {
        storeToken(token);
        history.push(`/client/my-profile`);
      }
    },
    validateOnChange: true,
    validationSchema: UpdatePasswordSchema(passwordPolicy, t)
  });

  return (
    <FormikProvider value={formik}>
      <div className="profile-password-edit">
        <RequestFeedbackForm submitLabel={t("auth:set-password.submit")} requests={requests}>
          <div className="set_password__content">
            <RidePassword
              formik={formik}
              name="currentPassword"
              label={t("client:profile.field.currentPassword")}
            />

            <div className="profile-password-edit__forgot-password">
              <TextLink
                variant={LinkVariant.secondary}
                to="/lost-password"
                data-testid="reset-link">
                {t("auth:login.forgot-password-link")}
              </TextLink>
            </div>

            <RidePassword
              formik={formik}
              name="password"
              label={t("client:profile.field.newPassword")}
              shouldShowErrorFeedback={false}
            />
            <PasswordRequirements
              isTouched={formik.touched["password"]}
              password={formik.values["password"]}
            />
            <RidePassword
              formik={formik}
              name="confirmPassword"
              label={t("client:profile.field.newPasswordRepeat")}
              shouldShowErrorFeedback={true}
            />
          </div>
        </RequestFeedbackForm>
      </div>
    </FormikProvider>
  );
};

export default withTranslation(["client", "auth", "password"])(ProfilePasswordEdit);
