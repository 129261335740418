import React from "react";

export interface TabGroupProps {
  dataTestId?: string;
  tabs: TabProps[];
  currentTab?: string;
  onChange: (newValue: string) => void;
}

export interface TabProps {
  label: string;
  value: string;
}

export const TabGroup = ({
  dataTestId = "tab-group",
  tabs,
  currentTab = tabs[0]?.value,
  onChange
}: TabGroupProps) => {
  const changeTab = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <div data-testid={dataTestId} className={"tab-group"}>
      {tabs.map((tab) => (
        <TabItem
          dataTestId={`${tab.value}-tab-item`}
          key={tab.value}
          label={tab.label}
          isActive={tab.value === currentTab}
          onClick={() => changeTab(tab.value)}
        />
      ))}
    </div>
  );
};

interface TabItemProps {
  dataTestId: string;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const TabItem = ({ dataTestId, label, isActive, onClick }: TabItemProps) => {
  const moduleName = "tab-group";

  return (
    <div
      data-testid={dataTestId}
      className={`${moduleName}__item ${isActive ? `${moduleName}__item--active` : ""}`}
      onClick={onClick}>
      {label}
    </div>
  );
};
