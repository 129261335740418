import React, { PropsWithChildren, useState } from "react";
import { copyToClipboard } from "../../common/copyToClipboard";
import { CopyIcon } from "../icons/CopyIcon";
import { RideTooltip } from "../RideTooltip/RideTooltip";

export enum ClickToCopyColorVariant {
  green = "green",
  black = "black"
}

export interface ClickToCopyProps {
  label?: string;
  value: string;
  tooltipContent?: string;
  clickToCopyColorVariant?: ClickToCopyColorVariant;
  showOutline?: boolean;
  onClickExtra?: () => void;
}

export const ClickToCopy = ({
  label,
  value,
  tooltipContent,
  showOutline,
  onClickExtra,
  clickToCopyColorVariant = ClickToCopyColorVariant.green
}: ClickToCopyProps) => {
  const [showConfirmation, setShowTooltip] = useState(false);
  const showTooltipTimeInterval = 1000;
  let timerToCloseTooltip;

  const handleOnClick = () => {
    onClickExtra?.();
    copyToClipboard(value);
    setShowTooltip(true);

    clearTimeout(timerToCloseTooltip);
    timerToCloseTooltip = setTimeout(() => {
      setShowTooltip(false);
    }, showTooltipTimeInterval);
  };
  return (
    <div className="click-to-copy" data-testid="click-to-copy">
      <Tooltip showConfirmation={showConfirmation} tooltipContent={tooltipContent}>
        <button
          data-testid="click-to-copy-button"
          className={`click-to-copy__copy-button click-to-copy__copy-button--${clickToCopyColorVariant} d-flex align-items-center ${
            showOutline ? "click-to-copy__copy-button--outline" : ""
          }`}
          onClick={handleOnClick}
          type={"button"}>
          {label && <span className="mr-2">{label}</span>}
          <CopyIcon />
        </button>
      </Tooltip>
    </div>
  );
};

const Tooltip = ({
  showConfirmation,
  tooltipContent,
  children
}: {
  showConfirmation: boolean;
  tooltipContent?: string;
} & PropsWithChildren<{}>) => {
  return (
    <RideTooltip
      name="click-to-copy"
      content={tooltipContent ?? ""}
      show={!!tooltipContent && showConfirmation}>
      {children}
    </RideTooltip>
  );
};
