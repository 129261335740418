import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const BankIcon = ({ size = 24, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    className={className}>
    <path d="M1.5 21H22.5V22.5H1.5V21Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.25 8.25C20.4489 8.25 20.6397 8.17098 20.7803 8.03033C20.921 7.88968 21 7.69891 21 7.5V5.25C20.9999 5.09586 20.9524 4.94549 20.8638 4.81935C20.7752 4.6932 20.65 4.59741 20.505 4.545L12.255 1.545C12.0902 1.48543 11.9098 1.48543 11.745 1.545L3.495 4.545C3.35005 4.59741 3.22476 4.6932 3.13619 4.81935C3.04762 4.94549 3.00007 5.09586 3 5.25V7.5C3 7.69891 3.07902 7.88968 3.21967 8.03033C3.36032 8.17098 3.55109 8.25 3.75 8.25H4.5V18H3V19.5H21V18H19.5V8.25H20.25ZM12 3.045L4.5 5.775V6.75H19.5V5.775L12 3.045ZM10.5 18H13.5V8.25H10.5V18ZM9 8.25H6V18H9V8.25ZM15 18H18V8.25H15V18Z"
      fill="currentColor"
    />
  </svg>
);
