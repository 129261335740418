import React, { FC } from "react";
import { flowType } from "../currentFlowType";
import AdminServiceLayout from "../../AdminService/AdminServiceLayout";
import { FeatureFlags, FlowTypeEnum } from "../../../../global-query-types";
import { FeatureFlagService } from "../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { useHistory } from "react-router-dom";
import { QueryResult } from "react-apollo";
import { MeQuery } from "../../../../lib/api/queries/graphql/MeQuery";

export interface AdminServiceProps {
  flowTypeOnboarding: FlowTypeEnum;
  meRequest?: QueryResult<MeQuery>;
}

export const AdminService: FC<AdminServiceProps> = ({ flowTypeOnboarding, meRequest }) => {
  const sunsetOldFlow = FeatureFlagService.instance.isEnabled(FeatureFlags.SunsetOldOrderFlows);
  const { push } = useHistory();

  if (sunsetOldFlow && !meRequest?.data?.me?.id) {
    push("/order/account-creation/start");
  }

  if (flowTypeOnboarding !== flowType.ADMIN_SERVICE_ONBOARDING) {
    return <AdminServiceLayout flowType={flowType.ADMIN_SERVICE} />;
  }

  return <AdminServiceLayout flowType={flowType.ADMIN_SERVICE_ONBOARDING} />;
};

export default AdminService;
