import { TFunction } from "i18next";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import React from "react";
import { GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided_client_brokerageAccounts } from "../../taxAdvisor/graphql/GetMyTaxAdvisorCompanyWithClients";

export const BrokerageAccountStatus = ({
  brokerageAccounts,
  t
}: {
  brokerageAccounts: GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided_client_brokerageAccounts[];
  t: TFunction;
}) => {
  return <>{brokerageAccounts.length === 0 && <NoBrokerageAccountBadge t={t} />}</>;
};

const NoBrokerageAccountBadge = ({ t }: { t: TFunction }) => {
  return (
    <RideBadgeStatus
      dataTestId="no-brokerage-account-badge"
      color={RideBadgeColorsEnum.WARNING}
      label={t("tax-service:tax-advisor.no-brokerage-account")}
      variant={RideBadgeVariantsEnum.OUTLINED}
    />
  );
};
