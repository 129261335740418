import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { FeatureModalContent } from "components/client/company/components/FeatureModalContent";
import { WithTranslation } from "react-i18next";
import NavigationService from "lib/services/NavigationService/NavigationService";

const WepaUpsellLegacy = ({ t }: WithTranslation) => {
  const dangerouslySetFeatures = [
    {
      __html: t(`generic:wepa-upsell-legacy.features.${1}`),
      index: 1
    },
    {
      __html: t(`generic:wepa-upsell-legacy.features.${2}`),
      index: 2
    },
    {
      __html: t(`generic:wepa-upsell-legacy.features.${3}`),
      index: 3
    },
    {
      __html: t(`generic:wepa-upsell-legacy.features.${4}`),
      index: 4
    },
    {
      __html: t(`generic:wepa-upsell-legacy.features.${5}`),
      index: 5
    },
    {
      __html: t(`generic:wepa-upsell-legacy.features.${6}`),
      index: 6
    }
  ];

  const goToBookACall = () => {
    NavigationService.instance?.openLink(t("generic:wepa-upsell-legacy.book-a-call-url"));
  };

  return (
    <div data-testid="wepa-upsell-legacy" className="admin-service-modal__wrapper wepa-upsell">
      <div data-testid="wepa-upsell-title" className="admin-service-modal__title">
        {t("generic:wepa-upsell-legacy.title")}
      </div>
      <FeatureModalContent
        dangerouslySetFeatures={dangerouslySetFeatures}
        productSheet={t("generic:wepa-upsell-legacy.productSheet")}
        productSheetText={t("generic:wepa-upsell-legacy.productSheetText")}
        price={t("generic:wepa-upsell-legacy.price")}
        priceType={t("generic:wepa-upsell-legacy.priceType")}
        ctaAction={goToBookACall}
        ctaLabel={t("generic:wepa-upsell-legacy.cta-label")}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(WepaUpsellLegacy);
