import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { AddExistingGmbHForm } from "./AddExistingGmbHForm";
import { CompanyType, LegalForm } from "../../../../global-query-types";
import logger from "../../../../common/Logger";
import { AddExistingGmbhSchema } from "../../../../lib/validation/AddExistingGmbhSchema";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import AddGmbHResult, { FinalResultType } from "../AddGmbHResult/AddGmbHResult";

interface AddExistingGmbHProps extends WithTranslation {
  handleCompanySubmit: (companyData) => Promise<void>;
  onClose: () => void;
}

const AddExistingGmbH = ({ t, handleCompanySubmit, onClose }: AddExistingGmbHProps) => {
  const [promptType, setPromptType] = useState<FinalResultType | null>(null);

  const clientCompanyDetails = {
    name: "",
    legalForm: LegalForm.GmbH,
    registerCourt: "",
    registration: "",
    capitalContribution: 0.0,
    type: CompanyType.ClientEntity,
    street: "",
    city: "",
    postalCode: ""
  };

  const submitHandler = async (values) => {
    try {
      await handleCompanySubmit(values);
    } catch (error) {
      logger.errorMessage(`Unable to create holding company for client`);
      logger.error(error);
    }
  };

  const formik = useFormik({
    initialValues: { ...clientCompanyDetails },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: AddExistingGmbhSchema,
    onSubmit: async (values) => {
      try {
        await submitHandler(values);
        setPromptType(FinalResultType.SUCCESS);
      } catch (error) {
        logger.errorMessage(`Unable to create company for client`);
        logger.error(error);
        setPromptType(FinalResultType.ALERT);
      }
    }
  });

  if (promptType !== null) {
    return <AddGmbHResult resetAndCloseModal={onClose} promptType={promptType} />;
  }

  return (
    <div className="add-existing-gmbh">
      <div className="admin-service-modal__wrapper">
        <div
          className="admin-service-modal__title d-flex justify-content-center"
          data-testid={`modal-title-existing`}>
          <span className="text-black">{t("client:add-gmbh.existing.title")}</span>
        </div>
      </div>

      <div className="mt-35">
        <div className="ride-form ride-form--label rounded-sm px-md-65 pt-md-5 pb-md-55">
          <FormikProvider value={formik}>
            <Form>
              <AddExistingGmbHForm t={t} formik={formik} />

              <div className="d-flex justify-content-end mt-3">
                <RideButtonDeprecated
                  data-testid="add-gmbh-btn"
                  size={ButtonSize.Big}
                  variant={ButtonVariant.Primary}
                  type="submit">
                  {t("client:add-gmbh.save")}
                </RideButtonDeprecated>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady(["client"])(AddExistingGmbH);
