import React, { PropsWithChildren } from "react";
import { ButtonProps } from "react-bootstrap";
import { Link } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";

type BackgroundVariants = "light" | "dark";

export interface ReturnLinkProps extends PropsWithChildren<ButtonProps>, WithTranslation {
  to?: string;
  onClick?: () => void;
  "data-testid"?: string;
  className?: string;
  backgroundVariant?: BackgroundVariants;
}

const ReturnLinkWithChevron = (args: ReturnLinkProps) => {
  const { to = "", backgroundVariant = "dark", className = "", onClick, children, t } = args;

  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <Link
      to={to}
      onClick={handleClick}
      data-testid={args["data-testid"]}
      className={`${className} return-link return-link--${backgroundVariant} text-decoration-none`}>
      <FaChevronLeft /> <span className="align-middle">{children ?? t("generic:back")}</span>
    </Link>
  );
};

export default withTranslation(["generic"])(ReturnLinkWithChevron);
