import gql from "graphql-tag";

export const NOMINEE_UPDATE_DISCOUNT_CODE = gql`
  mutation nomineeUpdateDiscountCode(
    $id: ID!
    $code: String!
    $referrerValue: Float!
    $referralValue: Float!
    $discountCodeAllowedService: Json!
  ) {
    nomineeUpdateDiscountCode(
      id: $id
      code: $code
      referrerValue: $referrerValue
      referralValue: $referralValue
      discountCodeAllowedService: $discountCodeAllowedService
    ) {
      feedback
    }
  }
`;
