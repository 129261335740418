import { orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { SortDirection } from "./SortDirection";
import { RideHeaderColumn } from "../../lib/types/types";

export const useSortBehavior = <T extends any>(data: any[]) => {
  const [sortedData, setSortedData] = useState([...data]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const sortCallback = useCallback(
    (column: RideHeaderColumn, direction?: SortDirection) => {
      let sortedData: any[];

      if (column.name === "name") {
        sortedData = data.sort((element, nextElement) => {
          const name = element.company
            ? `${element.company.name} ${element.company.legalForm}`
            : `${element.person.firstName} ${element.person.lastName}`;
          const nextName = nextElement.company
            ? `${nextElement.company.name} ${nextElement.company.legalForm}`
            : `${nextElement.person.firstName} ${nextElement.person.lastName}`;

          if (name > nextName) {
            return direction === SortDirection.ASCENDING ? 1 : -1;
          }
          if (name < nextName) {
            return direction === SortDirection.ASCENDING ? -1 : 1;
          }
          return 0;
        });
      } else {
        sortedData = orderBy(
          data,
          [column.name],
          direction === SortDirection.ASCENDING ? ["asc"] : ["desc"]
        );
      }

      setSortedData(sortedData);
    },
    [data, setSortedData]
  );

  return { sortedData, sortCallback };
};
