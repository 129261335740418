import { StepComponentProps, StepHeading } from "../../../ChaptersOrderLayout.partials";
import { AddExistingCompanyOrderData } from "lib/models/client/AddExistingCompany/AddExistingCompanyOrderData";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import React, { useEffect } from "react";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import {
  MultiSelectOption,
  RideMultiSelect
} from "uiLibrary/components/RideMultiSelect/RideMultiSelect";
import { CompanyMainActivities } from "lib/models/client/AddExistingCompany/CompanyMainActivities";
import { CompanyData } from "lib/models/client/CompanyData";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";

const CompanyMainActivitiesStep = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps<AddExistingCompanyOrderData> & WithTranslation) => {
  useEffect(() => {
    if (
      !FeatureFlagService.instance.isEnabled(
        FeatureFlags.CompanyMainActivitiesForAddExistingCompanyForm
      )
    ) {
      currentStep.complete();

      saveData({ ...order.extra, ...currentStep.chapter.order.serialize() }, false).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useRideFormik({
    initialValues: {
      ...order.extra?.companyData?.companyMainActivities
    },
    onSubmit: async (values) => {
      currentStep.complete();

      const companyMainActivities = {
        ...values
      };

      if (!values[CompanyMainActivities.OTHER]) {
        delete companyMainActivities["otherContext"];
      }

      await saveData({
        ...order.extra,
        ...currentStep.chapter.order.serialize(),
        companyData: {
          ...order.extra.companyData,
          companyMainActivities
        } as CompanyData
      });
    },
    validate(values) {
      let selectedMainActivities = Object.keys(values).filter(
        (mainActivity) => !!values[mainActivity]
      );

      const noContextProvidedForOther =
        !!selectedMainActivities.find((a) => a === CompanyMainActivities.OTHER) &&
        (!values.otherContext || values.otherContext.trim() === "");

      if (selectedMainActivities.length === 0 || noContextProvidedForOther) {
        return { error: t("generic:validation-required") };
      } else if (selectedMainActivities.length >= 1) {
        return;
      }
    }
  });

  const options: MultiSelectOption[] = Object.values(CompanyMainActivities).map(
    (companyMainActivities) => ({
      label: (
        <Trans
          i18nKey={`generic:add-existing-company.company-details.company-main-activities.options.${companyMainActivities}`}
        />
      ),
      name: companyMainActivities
    })
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <div data-testid="company-main-activities" className="company-main-activities">
          <StepHeading
            text={t("generic:add-existing-company.company-details.company-main-activities.title")}
            dataTestId="company-main-activities-title"
          />
          <RideMultiSelect options={options} columns={1} />
          {formik.values[CompanyMainActivities.OTHER] && (
            <RideTextInput
              name="otherContext"
              placeholder={""}
              asTextArea
              className="company-main-activities__other-context-input"
            />
          )}
          <InvalidFeedback hidden={!formik.errors["error"]} testPrefix="error">
            {formik.errors["error"]}
          </InvalidFeedback>
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(CompanyMainActivitiesStep);
