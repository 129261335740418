import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { FormikProps } from "formik";
import { SalutationValueEnum } from "../../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import {
  EmailArrayField,
  FirstNameArrayField,
  LastNameArrayField,
  PhoneField,
  SalutationField,
  TitleArrayField
} from "../../fields";
import { IoMdClose } from "react-icons/io";
import { ShareHolderFormTitle } from "./ShareHolderFormTitle";

interface ICompanyCreationFieldProps {
  t: TFunction;
  formik: FormikProps<any>;
  values: any;
  prefix: string;
  index: number;
  arrayHelpers;
  currentFlowType;
  touched;
  errors;
  currentStep?: string;
  title?: string;
  isCurrentUser: boolean;
}

export const CompanyCreationField = ({
  t,
  formik,
  values,
  prefix,
  index,
  touched,
  errors,
  arrayHelpers,
  currentFlowType,
  currentStep,
  title,
  isCurrentUser
}: ICompanyCreationFieldProps) => {
  const salutationTypeOptions = [{ label: t("trader-gmbh:mr") }, { label: t("trader-gmbh:ms") }];

  const handleSalutationTypeChange = (event) => {
    const name = currentStep === "EMPLOYEE_MANAGING_DIRECTOR" ? "salutationExtMD" : "salutation";
    const newSalutationType: SalutationValueEnum = +event.target["value"];
    formik.setFieldValue(prefix + name, newSalutationType);
  };

  const handlePhoneNumberChange = (value) => {
    const newPhoneNumber = `${value}`;
    formik.setFieldValue(prefix + "phoneNumber", newPhoneNumber);
  };

  return (
    <>
      <Card.Body data-testid="shareholder-form">
        <Row>
          <Col>{!!arrayHelpers ? <hr className="border border-dark" /> : ""}</Col>
        </Row>
        <Row
          className={
            (!!arrayHelpers ? "mb-3" : "mb-45") +
            " no-gutters align-items-center justify-content-between"
          }>
          <Col className="col-auto">
            <ShareHolderFormTitle
              t={t}
              arrayHelpers={arrayHelpers}
              index={index}
              currentStep={currentStep}
              title={title}
            />
          </Col>
          {!!arrayHelpers ? (
            <Col className="col-auto">
              <Button
                variant="outline-light"
                className="delete-btn"
                data-testid={`delete${index}`}
                onClick={(event) => {
                  event.preventDefault();
                  arrayHelpers.remove(index);
                }}>
                <span className="mr-1">
                  <IoMdClose />
                </span>
                {t("trader-gmbh:delete")}
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <SalutationField
              t={t}
              prefix={prefix}
              values={values}
              options={salutationTypeOptions}
              handleChange={(event) => handleSalutationTypeChange(event)}
              currentStep={currentStep}
            />
          </Col>
        </Row>
        <Row className="mt-35">
          <Col xs={"12"} md={"6"}>
            <TitleArrayField
              t={t}
              values={values}
              formik={formik}
              prefix={prefix}
              touched={touched}
              currentFlowType={currentFlowType}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={"12"} md={"6"}>
            <FirstNameArrayField
              t={t}
              values={values}
              formik={formik}
              prefix={prefix}
              touched={touched}
              errors={errors}
            />
          </Col>
          <Col xs={"12"} md={"6"}>
            <LastNameArrayField
              t={t}
              values={values}
              formik={formik}
              prefix={prefix}
              touched={touched}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={"12"} md={"6"}>
            <EmailArrayField
              t={t}
              values={values}
              formik={formik}
              prefix={prefix}
              touched={touched}
              errors={errors}
              currentStep={currentStep}
              disabled={isCurrentUser}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={6}>
            <PhoneField
              t={t}
              formik={formik}
              values={values}
              prefix={prefix}
              defaultCountry={"de"}
              handleChange={handlePhoneNumberChange}
            />
          </Col>
        </Row>
        {!arrayHelpers ? (
          <Row className="mt-3">
            <Col className="legal-text">
              {t("trader-gmbh:privacy-notice")}{" "}
              <a
                href="https://ride.capital/datenschutz?utm_source=app&utm_medium=app"
                target="_blank"
                rel="noreferrer noopener nofollow">
                {t("trader-gmbh:privacy-notice-link")}
              </a>{" "}
              {t("trader-gmbh:privacy-notice-suffix")}
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Card.Body>
    </>
  );
};
