import { OperationVariables } from "@apollo/react-common";
import { DocumentNode } from "graphql";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import { useMutation } from "@apollo/react-hooks";
import { getPaymentsContext } from "lib/api/payment/context";

export const usePaymentMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
) => {
  return useMutation(mutation, { ...options, ...getPaymentsContext() });
};
