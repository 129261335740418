import React, { PropsWithChildren } from "react";

export interface RideTableRowProps extends PropsWithChildren<{}> {
  colCount: number;
  className?: string;
  dataTestId?: string;
}

export const RideTableRow = ({
  children,
  colCount,
  className = "",
  dataTestId
}: RideTableRowProps) => {
  return (
    <div
      data-testid={dataTestId ?? "ride-table-row"}
      className={`ride-table-row ${className}`}
      data-columns={colCount}>
      {children}
    </div>
  );
};
