import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  CompanyWithWepaTransactionSummary,
  CompanyWithWepaTransactionSummaryVariables
} from "./graphql/CompanyWithWepaTransactionSummary";

export const COMPANY_WITH_WEPA_TRANSACTION_SUMMARY = gql`
  query CompanyWithWepaTransactionSummary(
    $companyId: ID!
    $companyIdAsString: String!
    $from: String
    $to: String
  ) {
    companyById(companyId: $companyIdAsString) {
      id
      name
      legalForm
    }
    getWepaTransactionSummary(companyId: $companyId, from: $from, to: $to) {
      netLiquidationValue
      totalNumberOfTransactions
      transactionsByCurrency {
        currency
        transactionCount
      }
      financialProducts {
        product
        transactionCount
      }
    }
  }
`;

export const GetCompanyWithWepaTransactionSummary = (companyId: string) =>
  useQuery<CompanyWithWepaTransactionSummary, CompanyWithWepaTransactionSummaryVariables>(
    COMPANY_WITH_WEPA_TRANSACTION_SUMMARY,
    {
      variables: {
        companyId: companyId,
        companyIdAsString: companyId
      }
    }
  );
