import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { ClickToCopy } from "../../../../sharedComponents/ClickToCopy/ClickToCopy";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { DiscountCodeStatus } from "global-query-types";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import { MyDiscountCode_myDiscountCode } from "components/public/CompanyCreation/graphql/MyDiscountCode";
import RideTableCell, {
  RideTableCellAlignment,
  RideTableCellVariant
} from "sharedComponents/RideTableCell/RideTableCell";

interface ProfileReferralCodeCardProps extends WithTranslation {
  referralCode: MyDiscountCode_myDiscountCode | null;
}

const ProfileReferralCodeCard = ({ t, referralCode }: ProfileReferralCodeCardProps) => {
  const columns = [
    { name: "email", label: t("client:profile.referralCode.uses.columns.email") },
    { name: "name", label: t("client:profile.referralCode.uses.columns.name") },
    {
      name: "status",
      label: t("client:profile.referralCode.uses.columns.status"),
      alignment: RideTableCellAlignment.RIGHT
    }
  ];

  const statuses = {
    [DiscountCodeStatus.REJECTED]: "client:profile.referralCode.uses.statuses.rejected",
    [DiscountCodeStatus.PENDING]: "client:profile.referralCode.uses.statuses.pending",
    [DiscountCodeStatus.CONFIRMED]: "client:profile.referralCode.uses.statuses.confirmed"
  };

  const badgeColors = {
    [DiscountCodeStatus.REJECTED]: RideBadgeColorsEnum.DANGER,
    [DiscountCodeStatus.PENDING]: RideBadgeColorsEnum.WARNING,
    [DiscountCodeStatus.CONFIRMED]: RideBadgeColorsEnum.SUCCESS
  };

  return (
    <RideSectionCard
      title={t("client:profile.referralCode.title")}
      data-testid="profile-referral-code"
      fixed>
      <div className="profile-referral-code__info">
        <div className="profile-referral-code__label-container">
          <div data-testid="profile-referral-code-label" className="profile-referral-code__label">
            {t("client:profile.referralCode.label")}
          </div>
          <div
            data-testid="profile-referral-code-description"
            className="profile-referral-code__description">
            {t("client:profile.referralCode.description", {
              referralValue: referralCode?.referralValue,
              referrerValue: referralCode?.referrerValue
            })}
          </div>
        </div>

        <div className="profile-referral-code__value-container">
          <div data-testid="profile-referral-code-value" className="profile-referral-code__value">
            {referralCode?.code}
          </div>
          <div className="profile-referral-code__action">
            <ClickToCopy
              label={t("client:profile.referralCode.copyButton")}
              value={referralCode?.code ?? ""}
              tooltipContent={t("client:profile.referralCode.copyButtonTooltip")}
            />
          </div>
        </div>
      </div>

      <div
        className="profile-referral-code__uses-title"
        data-testid="profile-referral-code-uses-title">
        {t("client:profile.referralCode.uses.title")}
      </div>

      <RideTableHeader isColumnsDisplay={true} columns={columns} />
      {referralCode?.uses?.map((use) => {
        const email = use.usedByPerson?.user?.email ?? "";
        const name = `${use.usedByPerson?.firstName} ${use.usedByPerson?.lastName}`;
        const badge = use.status && (
          <RideBadgeStatus
            variant={RideBadgeVariantsEnum.OUTLINED}
            color={badgeColors[use.status]}
            label={t(statuses[use.status])}
          />
        );
        return (
          <RideTableRow
            key={use.usedByPerson?.user?.email}
            colCount={3}
            data-testid="referral-code-use">
            <RideTableCell variant={RideTableCellVariant.text} value={email} />
            <RideTableCell variant={RideTableCellVariant.secondaryText} value={name} />
            <RideTableCell
              variant={RideTableCellVariant.badge}
              badge={badge}
              alignment={RideTableCellAlignment.RIGHT}
            />
          </RideTableRow>
        );
      })}
    </RideSectionCard>
  );
};

export default withTranslationReady("client")(ProfileReferralCodeCard);
