import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import React from "react";
import { WithTranslation } from "react-i18next";
import { TaskStatesEnum } from "../../../../../global-query-types";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import OpenBankAccountTaskContainer from "../OpenBankAccountTaskContainer/OpenBankAccountTaskContainer";
import { TFunction } from "i18next";
import { formatRegisterCourt } from "../../../../../common/formatters/registerCourtFormatter";
import { formatDate } from "../../../../../common/formatters";
import { formatCapitalContribution } from "../../../../../common/formatters/formatCapitalContribution";

interface OpenBankAccountOwnTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const OpenBankAccountOwnTask = ({ task, onChange, t }: OpenBankAccountOwnTaskProps) => {
  const data = task.options?.data ?? {};

  return (
    <OpenBankAccountTaskContainer
      onChange={onChange}
      task={task}
      bankName="Own"
      useLargeIcon={true}>
      <>
        {t("company-founding-steps:tasks.openBankAccountOwn.firstStep")}

        <div className="open-bank-account-own-task__section">
          <TaskField t={t} value={data["companyName"]} field={`company-name`} />
          <TaskField t={t} value={data["address"]} field={`address`} />
          <TaskField
            t={t}
            value={formatRegisterCourt(data["registerCourt"])}
            field={`register-court`}
          />
          <TaskField t={t} value={data["director"]} field={`director`} />
          <TaskField t={t} value={data["shareholders"]} field={`shareholders`} />
        </div>
        <div className="open-bank-account-own-task__section">
          <TaskField
            t={t}
            value={formatDate(data["notarizationDate"])}
            field={`notarization-date`}
          />
          <TaskField t={t} value={data["businessPurpose"]} field={`business-purpose`} />
          <TaskField
            t={t}
            value={formatCapitalContribution(data["capital"]) ?? ""}
            field={`capital`}
          />
        </div>
      </>
      {t("company-founding-steps:tasks.openBankAccountOwn.secondStep")}
      {t("company-founding-steps:tasks.openBankAccountOwn.thirdStep")}
    </OpenBankAccountTaskContainer>
  );
};

interface TaskFieldProps {
  t: TFunction;
  field: string;
  value: string;
}

const TaskField = ({ t, field, value }: TaskFieldProps) => {
  return (
    <div className="open-bank-account-own-task__field" data-testid={`task-${field}`}>
      <div className="open-bank-account-own-task__label">
        {t(`company-founding-steps:tasks.openBankAccountOwn.${field}`)}:
      </div>
      {value}
    </div>
  );
};

export default withTranslationReady(["company-founding-steps"])(OpenBankAccountOwnTask);
