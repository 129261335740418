import { AppConfigQuery_appConfig } from "lib/api/queries/graphql/AppConfigQuery";
import { ClientAppRoutes } from "./client/routes";
import { FeatureFlags, FlowTypeEnum } from "global-query-types";
import { Founder } from "components/public/CompanyCreationFlowType/FlowType/Founder";
import { Holding } from "components/public/CompanyCreationFlowType/FlowType/Holding";
import { Investor } from "components/public/CompanyCreationFlowType/FlowType/Investor";
import { Logout } from "sharedComponents/Logout/Logout";
import { MyGmbHMagicLink } from "components/client/MyGmbHs/MyGmbHMagicLink";
import { NomineeAppRoutes } from "./admin/routes";
import { Redirect, Route, Switch } from "react-router";
import { ShadowLogin } from "components/public/CompanyCreation/ShadowLogin/ShadowLogin";
import { WepaServiceStatusPage } from "components/public/WepaService/WepaServiceStatusPage";
import AdminServiceStatusPageLayout from "components/public/AdminService/AdminServiceStatusPageLayout";
import ConfirmAccount from "auth/components/CompanyCreation/ConfirmAccount/ConfirmAccount";
import LoginScene from "auth/scenes/LoginScene";
import LostPasswordScene from "auth/scenes/LostPasswordScene";
import React from "react";
import RealEstateTable from "components/Investments/RealEstateTable";
import ChangePassword from "client/scenes/Auth/ChangePasswordScene";
import Trader from "components/public/CompanyCreationFlowType/FlowType/Trader";
import WelcomeScene from "auth/scenes/Welcome/WelcomeScene";
import WepaServiceOrderPage from "components/public/WepaService/WepaServiceOrderPage";
import { ThirdPartyTaxAdvisorAppRoutes } from "./thirdPartyTaxAdvisor/routes";
import { EmailUpdateScene } from "./auth/scenes/EmailUpdateScene";
import { TaxAdvisorRoutes } from "./taxAdvisor/routes";
import TwoFactorAuthenticationSetupScene from "./auth/scenes/TwoFactorAuthenticationSetupScene";
import LoginCodeScene from "./auth/scenes/LoginCodeScene";
import { PrivateRoute } from "./sharedComponents/PrivateRoute/PrivateRoute";
import AdminServiceUpsellOfferingScene from "./client/scenes/AdminServiceUpsellOffering/AdminServiceUpsellOfferingScene";
import OrderAdminServiceGSUpsellScene from "client/scenes/OrderAdminServiceGSUpsell/OrderAdminServiceGSUpsellScene";
import OrderCompanyFoundingScene from "./client/scenes/OrderCompanyFounding/OrderCompanyFoundingScene";
import { OrderCompanyFoundingConfirmationScene } from "./client/scenes/OrderCompanyFoundingConfirmation/OrderCompanyFoundingConfirmationScene";
import ResumeLinkSentScene from "./client/scenes/ResumeLinkSent/ResumeLinkSentScene";
import ResetPasswordScene from "client/scenes/Auth/ResetPasswordScene";
import OrderLeiScene from "./client/scenes/./OrderLei/OrderLeiScene";
import { OrderLeiConfirmationScene } from "./client/scenes/OrderLeiConfirmation/OrderLeiConfirmationScene";
import UnifiedShortOrderFormScene from "client/scenes/UnifiedShortOrderForm/UnifiedShortOrderFormScene";
import { FeatureFlagService } from "./lib/services/FeatureFlagService/FeatureFlagService";
import AdminServiceContainer from "./components/public/CompanyCreationFlowType/FlowType/AdminServiceContainer";
import BookingGmbHSceneContainer from "./client/scenes/BookingGmbH/BookingGmbHSceneContainer";
import RedirectingScene from "./client/scenes/Redirecting/RedirectingScene";
import { WepaManagerOauthScene } from "admin/scenes/WepaManagerOauthScene/WepaManagerOauthScene";
import PrivilegedRoute from "admin/PrivilegedRoute";
import { AccountantAppRoutes } from "./accountant/routes";

export const RouterSwitch = ({ appConfig }: { appConfig: AppConfigQuery_appConfig }) => (
  <>
    <AccountantAppRoutes passwordPolicy={appConfig.passwordPolicy!} />
    <ClientAppRoutes passwordPolicy={appConfig.passwordPolicy!} />
    <TaxAdvisorRoutes passwordPolicy={appConfig.passwordPolicy!} />
    <NomineeAppRoutes passwordPolicy={appConfig.passwordPolicy!} />
    <ThirdPartyTaxAdvisorAppRoutes passwordPolicy={appConfig.passwordPolicy!} />

    <Switch>
      <Route exact path={"/logout"} component={Logout} />
      <Route exact path={"/redirecting"} component={RedirectingScene} />
      <Route
        exact
        path={"/booking/gmbh/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.GMBH} />
        )}
      />
      <Route exact path={"/booking/gmbh/resume-link-sent"} component={ResumeLinkSentScene} />
      <Route exact path={"/booking/gmbh/:id"} component={BookingGmbHSceneContainer} />

      <Route exact path={["/trader-gmbh/start", "/trader-gmbh/:id"]} component={Trader} />
      <Route exact path={["/investor-gmbh/start", "/investor-gmbh/:id"]} component={Investor} />
      <Route exact path={["/mini-holding/start", "/mini-holding/:id"]} component={Founder} />
      <Route
        exact
        path={["/real-estate-gmbh/start", "/real-estate-gmbh/:id"]}
        component={RealEstateTable}
      />
      <Route exact path={["/holding-gmbh/start", "/holding-gmbh/:id"]} component={Holding} />
      <Route
        path={"/trader-gmbh/verify/:token"}
        component={(props) => (
          <ConfirmAccount
            {...props}
            passwordPolicy={appConfig.passwordPolicy!}
            flowType={FlowTypeEnum.TRADER_HOLDING}
          />
        )}
      />
      <Route
        path={"/investor-gmbh/verify/:token"}
        component={(props) => (
          <ConfirmAccount
            {...props}
            passwordPolicy={appConfig.passwordPolicy!}
            flowType={FlowTypeEnum.INVESTOR_HOLDING}
          />
        )}
      />
      <Route
        path={"/mini-holding/verify/:token"}
        component={(props) => (
          <ConfirmAccount
            {...props}
            passwordPolicy={appConfig.passwordPolicy!}
            flowType={FlowTypeEnum.FOUNDER_HOLDING}
          />
        )}
      />
      <Route
        path={"/real-estate-gmbh/verify/:token"}
        component={(props) => (
          <ConfirmAccount
            {...props}
            passwordPolicy={appConfig.passwordPolicy!}
            flowType={FlowTypeEnum.REAL_ESTATE_GMBH}
          />
        )}
      />
      <Route
        path={"/holding-gmbh/verify/:token"}
        component={(props) => (
          <ConfirmAccount
            {...props}
            passwordPolicy={appConfig.passwordPolicy!}
            flowType={FlowTypeEnum.HOLDING_GMBH}
          />
        )}
      />
      <Route
        path={"/admin-service/verify/:token"}
        component={(props) => (
          <ConfirmAccount
            {...props}
            passwordPolicy={appConfig.passwordPolicy!}
            flowType={FlowTypeEnum.ADMIN_SERVICE}
          />
        )}
      />
      <Route path="/client/welcome/:token" component={(props) => <WelcomeScene {...props} />} />
      <Route
        exact
        path={"/trader-gmbh/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.TRADER_HOLDING} />
        )}
      />
      <Route
        exact
        path={"/investor-gmbh/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.INVESTOR_HOLDING} />
        )}
      />
      <Route
        exact
        path={"/mini-holding/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.FOUNDER_HOLDING} />
        )}
      />
      <Route
        exact
        path={"/admin-service/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.ADMIN_SERVICE} />
        )}
      />
      <Route
        exact
        path={"/admin-service/:id/status/:confirmed"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.ADMIN_SERVICE} />
        )}
      />
      <Route
        exact
        path={"/admin-service/onboarding/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout
            {...props}
            flowType={FlowTypeEnum.ADMIN_SERVICE_ONBOARDING}
          />
        )}
      />
      <Route
        exact
        path={"/real-estate-gmbh/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.REAL_ESTATE_GMBH} />
        )}
      />
      <Route
        exact
        path={"/holding-gmbh/:id/status"}
        component={(props) => (
          <AdminServiceStatusPageLayout {...props} flowType={FlowTypeEnum.HOLDING_GMBH} />
        )}
      />
      <Route
        exact
        path={["/wepa-service/start/:taxAdvisorCode?", "/wepa-service/:id"]}
        component={(props) => <WepaServiceOrderPage {...props} />}
      />
      <Route exact path="/wepa-service/:id/status" component={WepaServiceStatusPage} />
      <Route
        exact
        path="/ws/:taxServiceCode"
        component={(props) => (
          <Redirect to={`/wepa-service/start/${props.match.params.taxServiceCode}`} />
        )}
      />
      <Route
        exact
        path={[
          "/booking/gmbh/resume/:token",
          "/trader-gmbh/resume/:token",
          "/investor-gmbh/resume/:token",
          "/mini-holding/resume/:token",
          "/admin-service/resume/:token",
          "/real-estate-gmbh/resume/:token",
          "/holding-gmbh/resume/:token",
          "/admin-service/onboarding/resume/:token"
        ]}
        component={ShadowLogin}
      />
      <Route exact path="/client/magic-link/:token" component={MyGmbHMagicLink} />
      <Route exact path={"/admin-service/start"} component={AdminServiceContainer} />
      <Route exact path={"/admin-service/:id"} component={AdminServiceContainer} />
      <Route exact path={"/admin-service/:id"} component={AdminServiceContainer} />
      <Route
        exact
        path={"/admin-service/onboarding/:id"}
        component={(props) => (
          <AdminServiceContainer
            {...props}
            flowTypeOnboarding={FlowTypeEnum.ADMIN_SERVICE_ONBOARDING}
          />
        )}
      />
      <Route path={"/login"} component={LoginScene} />
      <Route path={"/lost-password"} component={LostPasswordScene} />
      <Route path={"/reset-password"} component={ResetPasswordScene} />
      <Route
        path={"/changePassword/:id"}
        component={(props) => (
          <ChangePassword {...props} passwordPolicy={appConfig.passwordPolicy!} />
        )}
      />
      <Route path={"/verifyEmailChange/:tokenId"} component={EmailUpdateScene} />
      <Route
        exact
        path={"/offer/admin-service/:companyCreationId"}
        component={AdminServiceUpsellOfferingScene}
      />
      <Route
        exact
        path={"/company-foundation/offer/admin-service/:orderId/:submissionId"}
        component={AdminServiceUpsellOfferingScene}
      />
      <Route
        exact
        path={"/confirm/admin-service/:companyCreationId"}
        component={() => OrderAdminServiceGSUpsellScene({ isNewFlow: false })}
      />
      <Route
        exact
        path={"/company-foundation/confirm/admin-service/:companyCreationId"}
        component={() => OrderAdminServiceGSUpsellScene({ isNewFlow: true })}
      />
      <Route exact path={"/order/gmbh/start"} component={OrderCompanyFoundingScene} />
      <Route
        exact
        path={"/order/gmbh/continue/:orderId/:submissionId"}
        component={OrderCompanyFoundingScene}
      />
      <Route
        exact
        path={"/order/gmbh/complete"}
        component={OrderCompanyFoundingConfirmationScene}
      />
      <Route exact path={"/order/lei/start"} component={OrderLeiScene} />
      <Route exact path={"/order/lei/complete"} component={OrderLeiConfirmationScene} />
      <PrivateRoute
        exact
        path={"/2fa"}
        component={() => <TwoFactorAuthenticationSetupScene currentPath="/2fa" />}
        hasAuthorization={(data) => !!data?.me}
      />
      <Route
        exact
        path={"/2fa/edit"}
        component={() => <TwoFactorAuthenticationSetupScene currentPath="/2fa/edit" />}
      />
      <Route exact path={"/2fa/login"} component={LoginCodeScene} />
      {FeatureFlagService.instance.isEnabled(FeatureFlags.CreateNewAccountShortOrderForm) && (
        <Route
          exact
          path={"/order/account-creation/:stepIndex"}
          component={UnifiedShortOrderFormScene}
        />
      )}
      <PrivilegedRoute
        exact
        path={"/oauth/authorize/wepa-manager"}
        component={WepaManagerOauthScene}
      />
    </Switch>
  </>
);
