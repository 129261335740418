import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import { ReviewShareholderSection } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholderSection/ReviewShareholderSection";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { Gender } from "lib/models/client/LowTaxBroker/CitizenshipData";

export interface ReviewCitizenshipInformationProps {
  gender: Gender | undefined;
  citizenship: string | undefined;
  secondCitizenship: string | undefined;
  dateOfBirth: string | null | undefined;
  cityOfBirth: string | undefined;
  countryOfBirth: string | undefined;
  usCitizenOrTaxResident: boolean | undefined;
}

export const ReviewCitizenshipInformation = withTranslationReady([
  "generic",
  "shareholder-details"
])(
  ({
    t,
    gender,
    citizenship,
    secondCitizenship,
    dateOfBirth,
    cityOfBirth,
    countryOfBirth,
    usCitizenOrTaxResident
  }: ReviewCitizenshipInformationProps & WithTranslation) => {
    return (
      <ReviewShareholderSection
        title={t("shareholder-details:citizenship-information.person-title")}>
        <ReviewField
          name="gender"
          label={t("shareholder-details:citizenship-information.gender")}
          value={gender && t(`shareholder-details:citizenship-information.${gender}`)}
        />
        <ReviewField
          name="date-of-birth"
          label={t("shareholder-details:citizenship-information.birthday")}
          value={dateOfBirth}
        />
        <ReviewField
          name="citizenship"
          label={t("shareholder-details:citizenship-information.nationality")}
          value={citizenship && getCountryNameByCode(citizenship)}
        />
        <ReviewField
          name="second-citizenship"
          label={t("shareholder-details:citizenship-information.second-nationality")}
          value={secondCitizenship && getCountryNameByCode(secondCitizenship)}
          isMandatory={false}
        />
        <ReviewField
          name="city-of-birth"
          label={t("shareholder-details:citizenship-information.birthplace")}
          value={cityOfBirth}
        />
        <ReviewField
          name="country-of-birth"
          label={t("shareholder-details:citizenship-information.birth-country")}
          value={countryOfBirth && getCountryNameByCode(countryOfBirth)}
        />
        <ReviewField
          name="us-citizen-or-tax-resident"
          label={t("generic:ride-broker-onboarding.review.shareholders.us-citizen-or-tax-resident")}
          value={
            usCitizenOrTaxResident === undefined
              ? undefined
              : usCitizenOrTaxResident
              ? t("generic:yes")
              : t("generic:no")
          }
        />
      </ReviewShareholderSection>
    );
  }
);
