import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetMyPermissionsQuery } from "./graphql/GetMyPermissionsQuery";

export const GET_MY_PERMISSIONS_QUERY = gql`
  query GetMyPermissionsQuery {
    me {
      id
      canLoginAsTaxAdvisor
    }
  }
`;

export const GetMyPermissions = () => useQuery<GetMyPermissionsQuery>(GET_MY_PERMISSIONS_QUERY);
