import React from "react";
import { AllDiscountCodes } from "../../../lib/api/queries/AllDiscountCodes";
import ReferralCodeBlacklisting from "./ReferralCodeBlacklisting";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_DISCOUNT_CODE } from "../../../lib/api/mutations/UPDATE_DISCOUNT_CODE";
import {
  UpdateDiscountCode,
  UpdateDiscountCodeVariables
} from "../../../lib/api/mutations/graphql/UpdateDiscountCode";

interface ReferralCodeBlacklistingContainerProps {
  code: string;
}

export const ReferralCodeBlacklistingContainer = ({
  code
}: ReferralCodeBlacklistingContainerProps) => {
  const allCodesRequest = AllDiscountCodes();
  const [callUpdateDiscountCode, updateDiscountCodeRequest] = useMutation<
    UpdateDiscountCode,
    UpdateDiscountCodeVariables
  >(UPDATE_DISCOUNT_CODE);

  const updateReferralCode = async (data: UpdateDiscountCodeVariables) => {
    await callUpdateDiscountCode({
      variables: data
    });
    await allCodesRequest.refetch();
  };

  return (
    <RequestFeedbackHandler requests={[allCodesRequest, updateDiscountCodeRequest]}>
      <ReferralCodeBlacklisting
        allCodes={allCodesRequest.data?.allDiscountCodes ?? []}
        code={code}
        updateReferralCode={updateReferralCode}
      />
    </RequestFeedbackHandler>
  );
};
