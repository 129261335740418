import React from "react";
import OrderApplicationSubmitted from "./OrderApplicationSubmitted";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import { useParams } from "react-router";
import { GetOrder } from "lib/api/queries/GetOrder";
import { OrderOwnerType } from "global-query-types";

const OrderApplicationSubmittedContainer = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const myOrderRequest = GetOrder(orderId, OrderOwnerType.Person);
  const order = myOrderRequest.data?.getOrder;

  // TODO: refactor this get that is duplicated from the admin dashboard preview file
  const downloadPdfSummaryHandler = async (): Promise<void> => {
    const fileId = order?.extra.orderSummaryPdf?.id;

    try {
      await fetch(`${getEndpoint()}/api/file/Order/${orderId}/${fileId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        mode: "cors",
        credentials: "include"
      }).then((response) => {
        response.arrayBuffer().then((buffer) => {
          const contentType = response.headers.get("Content-Type");
          var blob = new Blob([buffer], { type: contentType ?? "" });
          var url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
      });
    } catch (err) {
      console.log("Error fetching file ", err);
    }
  };

  return (
    <OrderApplicationSubmitted
      downloadPdfSummary={downloadPdfSummaryHandler}
      companyId={order?.entityId}
    />
  );
};

export default OrderApplicationSubmittedContainer;
