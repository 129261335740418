import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import RideIcon from "uiLibrary/components/RideIcon/RideIcon";
import { RideStatus } from "lib/types/types";

export interface ChapterData {
  icon: string;
  title: string;
  status: RideStatus;
  active: boolean;
  key: string;
}

export interface ChaptersSidebarProps extends WithTranslation {
  title: string; // maybe receive the type (GmbH) to concatenate
  chapters: ChapterData[];
  onChapterClick: (key: string) => void;
}

const ChaptersSidebar = ({ t, title, chapters, onChapterClick }: ChaptersSidebarProps) => {
  const module = "chapters-sidebar";

  return (
    <div className={module} data-testid={`${module}`}>
      <div className={`${module}__header`}>
        <div className={`${module}__title`}>{title}</div>
      </div>
      {chapters.map((chapter) => (
        <div
          onClick={() => onChapterClick(chapter.key)}
          className={`${module}__item ${module}__item--${chapter.status} ${
            chapter.active ? module + "__item--ACTIVE" : ""
          }`}
          key={chapter.key}>
          <RideIcon name={chapter.icon} status={chapter.status} />
          <div data-testid="chapter-title" className={`${module}__item-title`}>
            {t(chapter.title)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default withTranslationReady("generic")(ChaptersSidebar);
