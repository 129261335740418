import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { CreateReferralCode } from "../../components/CreateReferralCode/CreateReferralCode";
import ReferralCodeUsageFilter from "../../components/ReferralFilter/ReferralFilter";
import ListAllReferralCodes from "../../components/ReferralCodesTable/ReferralCodesTable";
import { getMonth, getYear } from "date-fns";

export const ReferralOverview = ({ t }) => {
  const [selectMonth, setSelectMonth] = useState(new Date());
  const [viewAll, setViewAll] = useState(true);

  const handleReset = () => {
    setViewAll(true);
    setSelectMonth(new Date());
  };

  const handleEnableDatePicker = () => setViewAll(false);

  const handleSelectMonth = (date: Date) => setSelectMonth(date);

  const month = getMonth(selectMonth!);
  const year = getYear(selectMonth!);

  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:referral-program")}</h2>
        </Col>
      </Row>

      <CreateReferralCode t={t} />
      <ReferralCodeUsageFilter
        selectMonth={selectMonth}
        viewAll={viewAll}
        handleReset={handleReset}
        enableDatePicker={handleEnableDatePicker}
        handleSelectMonth={handleSelectMonth}
      />
      <ListAllReferralCodes month={month} year={year} viewAll={viewAll} />
    </>
  );
};

export default withTranslation(["header", "nominee-dashboard", "generic"])(ReferralOverview);
