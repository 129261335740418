import React, { PropsWithChildren } from "react";
import { Alert } from "react-bootstrap";
import { TFunction } from "i18next";
import { convertErrorCode } from "../../lib/converters";
import logger from "../../common/Logger";
import { AlertMessage, AlertType } from "../AlertMessage/AlertMessage";
import { Trans } from "react-i18next";
import { LinkVariant, TextLink } from "../TextLink/TextLink";

interface ErrorAlertProps {
  error?: string;
  t: TFunction;
}

/**
 * @Deprecated Use ErrorAlert instead
 */
export default class ErrorAlertLegacy extends React.Component<ErrorAlertProps> {
  render() {
    const { t, error } = this.props;
    if (error != null && error.length > 0) {
      logger.debug(`Showing error with message: '${error}'`);
      return (
        <Alert variant="danger" className="alert" data-testid="error-alert">
          <h3>{t("generic:error_heading")}</h3>
          <p>{t("generic:please_contact_support")}</p>
          <p>{t("generic:error_message")}</p>
          <p>{t(convertErrorCode(error))}</p>
        </Alert>
      );
    }
    return null;
  }
}

const LoginLink = ({ children }: PropsWithChildren<{}>) => (
  <TextLink to={`/login`} variant={LinkVariant.primary} data-testid={"error-alert-link"}>
    {children}
  </TextLink>
);

export const ErrorAlert = ({ t, error }: { t: TFunction; error?: string }) => {
  if (error && error.length > 0) {
    logger.debug(`Showing error with message: '${error}'`);

    if (error.includes("EMAIL_ACCOUNT_ALREADY_EXISTS")) {
      return (
        <AlertMessage data-testid="error-alert" type={AlertType.warning}>
          <Trans
            i18nKey={"errorCodes:generic.email-already-exists-with-link"}
            components={{
              rideLink: <LoginLink />
            }}
          />
        </AlertMessage>
      );
    }

    return <AlertMessage data-testid="error-alert">{t(convertErrorCode(error))}</AlertMessage>;
  }
  return null;
};
