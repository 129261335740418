import gql from "graphql-tag";

export const SEND_SMS_FOR_TWO_FACTOR_AUTH = gql`
  mutation SendSmsForTwoFactorAuth {
    sendSmsForTwoFactorAuth {
      smsSent
      nextSmsAvailableAt
    }
  }
`;
