import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideReadonlyText } from "uiLibrary/components/RideReadonlyText/RideReadonlyText";
import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { StepHeading } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { CompanyAssets } from "global-query-types";
import { GetOrderQuery_getOrder } from "lib/api/queries/graphql/GetOrderQuery";

interface ReviewPurposeProps extends WithTranslation {}

export type ReviewPurposeValues = {
  noAdditionalOperatingActivities: boolean;
};

const ReviewPurpose = ({
  t,
  order,
  saveData,
  controls,
  currentStep
}: ReviewPurposeProps & StepComponentProps) => {
  async function onSubmit(values: ReviewPurposeValues) {
    currentStep.complete();

    const nextStepKey = "describe-operating-activities";
    const describeOperatingActivitiesStep = currentStep.chapter.getStepByKey(nextStepKey);

    if (values.noAdditionalOperatingActivities) {
      describeOperatingActivitiesStep?.remove();
    } else if (!describeOperatingActivitiesStep) {
      currentStep.insertAfter({ key: nextStepKey });
    }

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize(),
      noAdditionalOperatingActivities: values.noAdditionalOperatingActivities
    });
  }

  const module: string = "review-business-purpose";

  const initialValues: ReviewPurposeValues = {
    noAdditionalOperatingActivities: order.extra?.noAdditionalOperatingActivities ?? true
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit
  });

  const businessPurpose = constructBusinessPurpose(order);

  return (
    <div data-testid="review-purpose" className={module}>
      <StepHeading text={t("generic:company-setup.steps.review-business-purpose.title")} />

      <div data-testid="business-purpose-content" className={`${module}__business-purpose`}>
        <RideReadonlyText text={businessPurpose} />
      </div>

      <FormikProvider value={formik}>
        <Form>
          <div className={`${module}__confirmation`}>
            <RideCheckbox
              name={"noAdditionalOperatingActivities"}
              label={t(
                "generic:company-setup.steps.review-business-purpose.no-additional-operating-activities-planned"
              )}
            />
          </div>
          {controls}
        </Form>
      </FormikProvider>
    </div>
  );
};

function constructBusinessPurpose(order: GetOrderQuery_getOrder) {
  if (!order.extra.assetTypes) return "";

  let businessPurpose = "";

  if (
    order.extra.assetTypes[CompanyAssets.DERIVATIVES] ||
    order.extra.assetTypes[CompanyAssets.SHARES_AND_FUNDS] ||
    order.extra.assetTypes[CompanyAssets.HOLDING]
  ) {
    businessPurpose +=
      "Gegenstand des Unternehmens ist das Eingehen von Stillhaltergeschäften zur Erzielung von Einnahmen und die Anlage des Gesellschaftsvermögens in Termingeschäften, Vermögensanlagen und Finanzinstrumenten aller Art sowie der Erwerb, die Verwaltung und die Verwertung von Beteiligungen an anderen Unternehmen im eigenen Namen, auf eigene Rechnung, nicht für Dritte und unter Ausschluss von Tätigkeiten, die einer Erlaubnis, insbesondere nach dem Kreditwesengesetz (KWG) oder dem Kapitalanlagegesetzbuch (KAGB), bedürfen.";
  }

  if (order.extra.assetTypes[CompanyAssets.REAL_ESTATE]) {
    businessPurpose +=
      " Gegenstand des Unternehmens ist der Kauf von bebauten und unbebauten Grundstücken im eigenen Namen und auf eigene Rechnung, die Errichtung von Gebäuden auf solchen Grundstücken, deren Nutzungsüberlassung, insbesondere deren Vermietung und Verwaltung, sowie deren sonstige Verwertung sowie die Beteiligung an Gesellschaften die den vorgenannten Unternehmensgegenstand (ganz oder teilweise) erfüllen.";
  }

  return businessPurpose;
}

export default withTranslationReady(["generic"])(ReviewPurpose);
