import React, { useState } from "react";
import { TFunction } from "i18next";
import { RideCheckbox } from "sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import {
  AllowedDiscountCodeService,
  AllowedServiceExpansionEnum,
  DiscountCodeAllowedServiceEnum
} from "../ReferralCodeForm/ReferralCodeForm";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { keys } from "lodash";

export const ReferralCodeFormEditMode = ({ t, formik, allowedReferralCodeServices }) => {
  const [allowedServiceState, setAllowedServiceState] = useState<AllowedDiscountCodeService>(
    allowedReferralCodeServices
  );

  const handleOnCheckboxChange = (service: string) => {
    if (DiscountCodeAllowedServiceEnum[service] === service) {
      Object.keys(allowedServiceState).forEach((key) =>
        key === service
          ? (allowedServiceState[key] = !allowedServiceState[service])
          : allowedServiceState
      );
    }
    updateReferralCodeService(allowedServiceState);
  };

  const updateReferralCodeService = (allowedServiceToUpdate: AllowedDiscountCodeService) => {
    formik.setFieldValue("discountCodeAllowedService", allowedServiceToUpdate);
    setAllowedServiceState(allowedServiceToUpdate);
  };

  return (
    <div>
      <RideInput
        formik={formik}
        name="code"
        label={t("nominee-dashboard:referral-code.code")}
        className="mb-3"
      />

      <SelectMultipleAllowedServices
        t={t}
        allowedReferralCodeServices={allowedServiceState}
        handleOnCheckboxChange={handleOnCheckboxChange}
      />
      <RideInput
        formik={formik}
        name="referrerValue"
        type="number"
        label={t("nominee-dashboard:referral-code.referrer-amount")}
        className="mb-3"
      />
      <RideInput
        formik={formik}
        name="referralValue"
        type="number"
        label={t("nominee-dashboard:referral-code.referral-amount")}
      />
    </div>
  );
};

const SelectMultipleAllowedServices = ({
  t,
  allowedReferralCodeServices,
  handleOnCheckboxChange
}: {
  t: TFunction;
  allowedReferralCodeServices: AllowedDiscountCodeService;
  handleOnCheckboxChange: (service: string) => void;
}) => {
  return (
    <>
      {keys(allowedReferralCodeServices).map((service, index) => {
        return (
          <RideCheckbox
            label={t(`generic:${AllowedServiceExpansionEnum[service]}`)}
            onChange={() => handleOnCheckboxChange(service)}
            checked={allowedReferralCodeServices[service]}
            name={`discountCodeAllowedService-${service}`}
            key={index}
            className="mb-3"
          />
        );
      })}
    </>
  );
};

export default ReferralCodeFormEditMode;
