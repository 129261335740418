import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { deepClone } from "common/deepClone";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LowTaxBrokerAddressSchema, RideBrokerAddressSchema } from "lib/validation/AddressSchema";
import React, { useImperativeHandle } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import GenericAddress from "../GenericAddress/GenericAddress";
import { FeatureFlagService } from "../../../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../../../../global-query-types";

const PersonAddressComponent = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef,
  entityKey
}: WithTranslation & StepComponentProps & { entityKey: "shareholder" | "employee" }) => {
  const includeBuildingNumber =
    (order.extra.isNewBrokerEnabled &&
      !FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerWithoutHouseNumber)) ||
    !order.extra.isNewBrokerEnabled;

  const entityIndex = currentStep?.chapter?.index ?? 0;
  let dictionaryCopy = order.extra[`${entityKey}s`] ? deepClone(order.extra[`${entityKey}s`]) : {};
  const entity = dictionaryCopy[entityIndex] ?? {};
  const personAddress = entity.personAddress ?? {};

  const initialValues = {
    country: personAddress.country ?? "DE",
    stateCode: personAddress.stateCode,
    streetName: personAddress.streetName,
    streetLine1: personAddress.streetLine1,
    buildingNumber: personAddress.buildingNumber,
    streetLine2: personAddress.streetLine2,
    city: personAddress.city,
    postalCode: personAddress.postalCode
  };

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    currentStep.complete();

    const { shouldReturnToParentChapter, ...data } = values;

    entity.personAddress = data;

    await saveData(
      {
        ...order.extra,
        [`${entityKey}s`]: dictionaryCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldReturnToParentChapter ?? false
    );
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: includeBuildingNumber
      ? LowTaxBrokerAddressSchema(t)
      : RideBrokerAddressSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="person-address-form subchapter-order-layout__scrollable-address">
          <GenericAddress
            formik={formik}
            prefix={`${currentStep?.disposition}`}
            title={t("generic:shareholder-details.residential-address")}
            includeBuildingNumber={includeBuildingNumber}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

const PersonAddress =
  (entityKey: "shareholder" | "employee") => (props: StepComponentProps & WithTranslation) =>
    <PersonAddressComponent {...props} entityKey={entityKey} />;

export default (entityKey: "shareholder" | "employee") =>
  withTranslationReady(["generic"])(PersonAddress(entityKey));
