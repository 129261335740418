import * as Yup from "yup";
import { EmailValidatorWithBackend } from "./EmailValidatorWithBackend";

export const ShareholderPersonalInformationSchema = (
  t,
  otherEmails: string[] = [],
  isLTBFlow = false
) => {
  let emailValidatorFunction = isLTBFlow
    ? Yup.string().required(t("generic:validation-required"))
    : EmailValidatorWithBackend(t).notOneOf(
        otherEmails,
        t("errorCodes:generic.email-already-exists")
      );

  return Yup.object().shape({
    firstName: Yup.string().required(t("generic:validation-required")),
    middleName: Yup.string().notRequired(),
    lastName: Yup.string().required(t("generic:validation-required")),
    email: emailValidatorFunction,
    phoneNumber: Yup.string().required(t("generic:validation-required")),
    authorizedToShareShareholderInfo: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};
