import React from "react";

export const OwnershipStructure = ({
  size = 26,
  className = ""
}: {
  size?: number;
  className?: string;
}) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="13" r="12.5" fill="currentColor" />
    <path
      d="M14.5457 8.38568C14.5457 7.34388 13.7011 6.49933 12.6593 6.49933C11.6175 6.49933 10.7729 7.34388 10.7729 8.38568C10.7729 9.42748 11.6175 10.272 12.6593 10.272C13.7011 10.272 14.5457 9.42748 14.5457 8.38568Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.6576 19.5003L12.6576 10.103" stroke="white" strokeLinejoin="round" />
    <path
      d="M7 19.122V14.5947H18.3181V19.122"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
