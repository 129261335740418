import { FullScreenModal } from "../../components/FullScreenModal";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import RequestFeedbackHandler from "../../../generic/RequestFeedbackHandler";
import { FeatureModalContent } from "./FeatureModalContent";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";

interface Props extends WithTranslation {
  modalOpen: boolean;
  handleModalClose: () => void;
  createAdminServiceAddOn: (companyId: string) => Promise<any>;
  createAdminServiceAddOnRequest: any;
}

export const PurchaseAdminService = withTranslationReady(["admin-service-details"])(
  ({
    adminServiceAddOnRequest,
    ctaAction,
    t
  }: {
    adminServiceAddOnRequest: any;
    ctaAction: () => Promise<void>;
  } & WithTranslation) => {
    const dangerouslySetFeatures = [
      {
        __html: t(`admin-service-details:features.${1}`),
        index: 1
      },
      {
        __html: t(`admin-service-details:features.${2}`),
        index: 2
      },
      {
        __html: t(`admin-service-details:features.${3}`),
        index: 3
      },
      {
        __html: t(`admin-service-details:features.${4}`),
        index: 4
      },
      {
        __html: t(`admin-service-details:features.${5}`),
        index: 5
      },
      {
        __html: t(`admin-service-details:features.${6}`),
        index: 6
      }
    ];

    return (
      <div data-testid={"purchase-admin-service"} className="admin-service-modal__wrapper">
        <RequestFeedbackHandler requests={[adminServiceAddOnRequest]} />
        <div className="admin-service-modal__title" data-testid="modal-title">
          {t("admin-service-details:title")}
        </div>
        <FeatureModalContent
          dangerouslySetFeatures={dangerouslySetFeatures}
          productSheet={t("admin-service-details:productSheet")}
          productSheetText={t("admin-service-details:productSheetText")}
          price={t("admin-service-details:price")}
          priceType={t("admin-service-details:priceType")}
          ctaAction={ctaAction}
        />
      </div>
    );
  }
);

export const AdminServiceModal = ({
  modalOpen,
  handleModalClose,
  createAdminServiceAddOn,
  createAdminServiceAddOnRequest
}: Props) => {
  const { id: companyId } = useParams<{ id: string }>();
  const history = useHistory();

  const handleClick = async () => {
    const adminServiceId = (await createAdminServiceAddOn(companyId))?.data?.createAdminServiceAddOn
      ?.id;
    if (adminServiceId) {
      history.push(`/admin-service/${adminServiceId}`);
    }
  };

  return (
    <FullScreenModal show={modalOpen} onHide={handleModalClose} name="admin-service-upsell-modal">
      <PurchaseAdminService
        adminServiceAddOnRequest={createAdminServiceAddOnRequest}
        ctaAction={handleClick}
      />
    </FullScreenModal>
  );
};

export default withTranslation(["admin-service-details", "client"])(AdminServiceModal);
