import { addSeconds, isPast, parseISO } from "date-fns";

export class LocalStore {
  static get defaultMaxAge() {
    return 60 * 60 * 24 * 7;
  }

  getExpiringValue(key) {
    const data = this.getValue(key);
    if (!data) return null;
    if (isPast(parseISO(data.expires))) return null;

    return data.value;
  }

  getValue(key) {
    const value = localStorage.getItem(key);
    if (!value) return null;

    return JSON.parse(value);
  }

  getSessionValue(key) {
    const value = sessionStorage.getItem(key);
    if (!value) return null;

    return JSON.parse(value);
  }

  setExpiringValue(key, value, secondsExpire: number = LocalStore.defaultMaxAge) {
    const data = {
      value: value,
      expires: addSeconds(new Date(), secondsExpire)
    };
    this.setValue(key, data);
  }

  setValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  setSessionValue(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeValue(key) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

const localStore = new LocalStore();
export default localStore;
