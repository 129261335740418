import logger from "../Logger";

export interface FileIpfsAdd {
  path: string;
  content: File | ReadableStream | Buffer | string;
}

export interface FileIpfs {
  path: string;
  cid: string;
  size: number;
}

const ipfsUpload = async (ipfs: any, fileDetails, options) => {
  let result = [] as FileIpfs[];
  let ipfsResponse: FileIpfs;

  for await (ipfsResponse of ipfs.add(fileDetails, options)) {
    logger.trace(
      `ipfsResponse: ${ipfsResponse.cid.toString()}, ${ipfsResponse.path}, ${ipfsResponse.size}`
    );

    if (ipfsResponse.cid) {
      result.push(ipfsResponse);
    } else {
      result = [];
    }
  }
  return result;
};

export async function addToIpfs(ipfs: any, fileBuffer: ArrayBuffer, fileName: string) {
  const options = {
    pin: true,
    progress: (prog) => logger.trace(`received: ${prog}`)
  };

  const fileDetails = {
    path: fileName as string,
    content: fileBuffer as ArrayBuffer
  };

  let ipfsResult = await ipfsUpload(ipfs, fileDetails, options);
  let ipfsLocationInfo = { cid: "", path: "", size: 0 };
  for (let f = 0; f < ipfsResult.length; f++) {
    let file = ipfsResult[f];
    ipfsLocationInfo = {
      cid: file.cid,
      path: file.path,
      size: file.size
    };
  }
  return ipfsLocationInfo;
}
