import React from "react";
import GuestLayout from "../../../sharedComponents/templates/GuestLayout/GuestLayout";
import OrderCompanyFoundingServiceConfirmation from "../../components/OrderCompanyFoundingServiceConfirmation/OrderCompanyFoundingServiceConfirmation";

export const OrderCompanyFoundingConfirmationScene = () => {
  return (
    <GuestLayout>
      <div className="order-confirmation-scene">
        <OrderCompanyFoundingServiceConfirmation />
      </div>
    </GuestLayout>
  );
};
