import React from "react";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { useMutation } from "@apollo/react-hooks";
import logger from "common/Logger";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import WepaServiceDetails from "./WepaServiceDetails";
import { UPDATE_WEPA_SERVICE } from "lib/api/mutations/UPDATE_WEPA_SERVICE";
import {
  UpdateWepaService,
  UpdateWepaServiceVariables
} from "lib/api/mutations/graphql/UpdateWepaService";
import {
  CreateWepaServiceForWepaStandalone,
  CreateWepaServiceForWepaStandaloneVariables
} from "lib/api/mutations/graphql/CreateWepaServiceForWepaStandalone";
import { CREATE_WEPA_SERVICE_FOR_WEPA_STANDALONE } from "lib/api/mutations/CREATE_WEPA_SERVICE_FOR_WEPA_STANDALONE";

export const WepaServiceDetailsContainer = ({
  gmbh,
  refetch
}: {
  gmbh: GmbHDetails;
  refetch: () => Promise<any>;
}) => {
  const [nomineeUpdateWepaServiceMutation, nomineeUpdateWepaServiceRequest] = useMutation<
    UpdateWepaService,
    UpdateWepaServiceVariables
  >(UPDATE_WEPA_SERVICE);

  const [createWepaServiceForWepaStandaloneMutation, createWepaServiceForWepaStandaloneRequest] =
    useMutation<CreateWepaServiceForWepaStandalone, CreateWepaServiceForWepaStandaloneVariables>(
      CREATE_WEPA_SERVICE_FOR_WEPA_STANDALONE
    );

  const updateWepaService = async (data: UpdateWepaServiceVariables) => {
    try {
      await nomineeUpdateWepaServiceMutation({
        variables: data
      });
    } catch (error) {
      logger.errorMessage(`Unable to update wepa service`);
      logger.error(error);
    }
  };

  const createWepaServiceForWepaStandalone = async (
    data: CreateWepaServiceForWepaStandaloneVariables
  ) => {
    try {
      await createWepaServiceForWepaStandaloneMutation({
        variables: data
      });
    } catch (error) {
      logger.errorMessage(`Unable to update wepa service`);
      logger.error(error);
    }
  };

  return (
    <RequestFeedbackHandler
      requests={[nomineeUpdateWepaServiceRequest, createWepaServiceForWepaStandaloneRequest]}>
      <WepaServiceDetails
        gmbh={gmbh}
        refetch={refetch}
        updateWepaService={updateWepaService}
        createWepaServiceForWepaStandalone={createWepaServiceForWepaStandalone}
      />
    </RequestFeedbackHandler>
  );
};
