import i18n from "i18next";
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LocalLanguageCodeProvider from "./i18n/LocalLanguageCodeProvider";

const localLanguageCodeProvider = new LocalLanguageCodeProvider();

const loadPathConfig = process.env["STORYBOOK"]
  ? { loadPath: "./locales/{{lng}}/{{ns}}.json" }
  : {};

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: localLanguageCodeProvider.getLanguageCode() || "de",
    fallbackLng: "de", // use de if detected lng is not available

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    // react-i18next options
    react: {
      wait: true,
      useSuspense: false
    },

    backend: {
      ...loadPathConfig,
      queryStringParams: {
        v: process.env["REACT_APP_DEPLOYMENT_VERSION"]
      }
    }
  });

export default i18n;
