import React from "react";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";

import ChapterSummary from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ChapterSummary/ChapterSummary";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";

interface CompanyInformationCompleteProps extends StepComponentProps, WithTranslation {}

const CompanyInformationComplete = ({
  currentStep,
  order,
  saveData,
  controls,
  t
}: CompanyInformationCompleteProps) => {
  const title = t("generic:ride-broker-onboarding.company-information-complete.title");

  const summary = t("generic:ride-broker-onboarding.chapter-summary.message");

  async function onSubmit() {
    currentStep.complete();

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {},
    onSubmit: onSubmit
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div data-testid="company-information-complete">
          <ChapterSummary title={title} message={summary} />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(CompanyInformationComplete);
