import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { CheckboxField } from "../../../../generic/CheckboxField";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQuery_myCompanyCreation_company_group_memberships,
  MyCompanyCreationQuery_myCompanyCreation_company_group_memberships_person,
  MyCompanyCreationQueryVariables
} from "../../graphql/MyCompanyCreationQuery";
import { FlowTypeEnum, RolesInGroup } from "../../../../../global-query-types";
import { useMutation } from "react-apollo";
import { UPDATE_DIRECTOR_ROLE } from "../../Mutations";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import logger from "../../../../../common/Logger";
import { ApolloQueryResult } from "apollo-client";
import { TraderDirectorRoleFormKey } from "../../fields";
import { UpdateDirectorRole, UpdateDirectorRoleVariables } from "../../graphql/UpdateDirectorRole";
import { doNothing } from "common/stubs";
import { DataLayer } from "../../../../../lib/services/GoogleTagManager/GoogleTagManager";

interface DirectorFormProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation | undefined;
  resetActiveStep: (string) => void;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
}

export const DirectorForm = ({
  t,
  myCompanyCreationDetails,
  resetActiveStep,
  refetchMyCompanyCreation,
  currentFlowType
}: DirectorFormProps) => {
  const shareholdersInfo = myCompanyCreationDetails?.company?.group.memberships || [];

  const [localError, setLocalError] = useState("");

  const [updateDirectorRole, { error, loading }] = useMutation<
    UpdateDirectorRole,
    UpdateDirectorRoleVariables
  >(UPDATE_DIRECTOR_ROLE);

  const getCompanyCreationId = () =>
    (myCompanyCreationDetails && myCompanyCreationDetails.id) || "";

  const submitHandler = async (values) => {
    setLocalError("");

    let minOneDirector = values.directors.some((director) => director.isDirector === true);

    if (!minOneDirector) {
      let noDirectorRoleError = t("trader-gmbh:director-noDirector-errorInfo");
      setLocalError(noDirectorRoleError);

      DataLayer.FormValidationError({
        formName: TraderDirectorRoleFormKey,
        fieldName: "noDirector",
        errorMessage: noDirectorRoleError
      });

      return;
    }

    const allShareholders = values.directors.map((director) => ({
      id: director.id,
      isDirector: director.isDirector
    }));

    const companyCreationId = getCompanyCreationId();

    try {
      await updateDirectorRole({
        variables: {
          companyCreationId: companyCreationId,
          allShareholders
        }
      });

      const { data } = await refetchMyCompanyCreation({ id: companyCreationId });

      resetActiveStep(data.myCompanyCreation?.currentStep);
      return;
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to update directors:`);
      logger.error(error);
    }
  };

  const hasRole = (
    person: MyCompanyCreationQuery_myCompanyCreation_company_group_memberships_person,
    role: RolesInGroup
  ) => {
    return !!shareholdersInfo.find(
      (member) => member.role === role && member?.person?.id === person.id
    );
  };

  const toDirectorOption = (
    member: MyCompanyCreationQuery_myCompanyCreation_company_group_memberships
  ) => {
    const person = member.person;

    if (!person) {
      return {};
    }

    return {
      id: person.id,
      fullName: `${person.firstName} ${person.lastName}`,
      isDirector: hasRole(person, RolesInGroup.Director),
      isShareholder: hasRole(person, RolesInGroup.Shareholder)
    };
  };

  const directors = shareholdersInfo
    .filter(
      (member) => [RolesInGroup.Director, RolesInGroup.Shareholder].indexOf(member.role) !== -1
    )
    .filter(
      (member, index, all) => all.map((a) => a?.person?.id).indexOf(member?.person?.id) === index
    )
    .map((member) => toDirectorOption(member))
    .filter((item) => item.id);

  const formik = useFormik({
    initialValues: { directors },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: submitHandler
  });

  const canSubmit = directors.every((option) => option.isShareholder);

  const render = () => {
    return (
      <FormikProvider value={formik}>
        <div>
          <Row>
            <Col className="steps-form-description" data-testid="director-form-description">
              {currentFlowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING
                ? t("trader-gmbh:director-form-description-admin-service-onboarding")
                : t("trader-gmbh:director-form-description")}
            </Col>
          </Row>
          <Row className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
            <Col>
              <Form>
                <FieldArray name="directors" render={handleDirectorsRender} />
              </Form>
            </Col>
          </Row>
        </div>
      </FormikProvider>
    );
  };

  const handleDirectorsRender = () => {
    const directorFields = formik.values.directors.map((option, index) => (
      <div key={index} data-testid="directors-form" className="mt-md-4 px-md-4">
        <CheckboxField
          key={option.id}
          name={`directors.${index}.isDirector`}
          label={option.fullName ?? ""}
          state={option.isDirector ?? false}
          disabled={!option.isShareholder}
          handleClick={(e) => {
            formik.setFieldValue(`directors.${index}.isDirector`, e.target.checked);
          }}
        />
      </div>
    ));

    return (
      <div className="flex-column step-bg">
        {directorFields}

        <RideEmployeeDirectorCheckbox currentFlowType={currentFlowType} t={t} />

        {localError ? (
          <div className="mt-md-4 px-md-4">
            <div className="invalid-feedback d-block" data-testid="noDirector-invalid-feedback">
              {localError}
            </div>
          </div>
        ) : (
          ""
        )}

        {loading || error?.message ? (
          <Row className="mt-3">
            <Col>
              <LoadingAlert t={t} loading={loading} />
              <ErrorAlertLegacy t={t} error={error?.message} />
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className="mt-3">
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                disabled={!canSubmit}
                data-testid="submit"
                className="step-submit-btn"
                variant="outline-light">
                {t("generic:save")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return render();
};

// this serves as a configuration option whether we want to show the RIDE employee director option or not
const shouldHaveRideEmployeeAsDirector = () => false;

const RideEmployeeDirectorCheckbox = ({
  currentFlowType,
  t
}: {
  currentFlowType: FlowTypeEnum;
  t: TFunction;
}) => (
  <>
    {shouldHaveRideEmployeeAsDirector() &&
    currentFlowType !== FlowTypeEnum.ADMIN_SERVICE_ONBOARDING ? (
      <div className="mt-md-4 px-md-4">
        <CheckboxField
          name={""}
          label={t("trader-gmbh:ride-employee-checkbox")}
          disabled={true}
          state={true}
          handleClick={doNothing}
        />
        <div className="pl-5 ml-n1 disabled-label">{t("trader-gmbh:ride-employee-checkbox-2")}</div>
      </div>
    ) : (
      ""
    )}
  </>
);
