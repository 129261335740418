import * as React from "react";
import { useQuery } from "react-apollo";
import { CHECK_WALLET_WHITELISTED_STATUS, USER_INFO_BY_PERSON_ID } from "./Queries";
import {
  checkWalletWhitelistedStatus,
  checkWalletWhitelistedStatusVariables
} from "./graphql/checkWalletWhitelistedStatus";
import { userInfoByPersonId, userInfoByPersonIdVariables } from "./graphql/userInfoByPersonId";

export const GetUserWhitelistedStatus = (providerId: string) => {
  const [firstRender, setFirstRender] = React.useState(true);

  const whitelistedStatus = useQuery<
    checkWalletWhitelistedStatus,
    checkWalletWhitelistedStatusVariables
  >(CHECK_WALLET_WHITELISTED_STATUS, {
    variables: { walletProviderId: providerId },
    fetchPolicy: firstRender ? "network-only" : undefined,
    onCompleted: () => setFirstRender(false)
  });
  return whitelistedStatus;
};

export const GetUserInfoByPersonId = (personId: string) => {
  const userInfo = useQuery<userInfoByPersonId, userInfoByPersonIdVariables>(
    USER_INFO_BY_PERSON_ID,
    {
      variables: { personId: personId }
    }
  );
  return userInfo;
};
