import React, { PropsWithChildren } from "react";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";

interface ReviewShareholderSectionProps extends PropsWithChildren<{}> {
  title: string;
}

export const ReviewShareholderSection = ({ title, children }: ReviewShareholderSectionProps) => {
  return (
    <div className="review-shareholder-section">
      <ReviewSectionTitle title={title} />
      <div className="review-shareholder-section__fields">{children}</div>
    </div>
  );
};
