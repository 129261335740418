import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { deepClone } from "common/deepClone";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { ShareholderPersonalInformationSchema } from "lib/validation/ShareholderPersonalInformationSchema";
import React, { useImperativeHandle } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import ShareholderDetailsSectionTitle from "client/components/ChaptersOrderLayout/sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import { NaturalPersonInputs } from "client/components/ChaptersOrderLayout/sharedComponents/EntityGeneralInformation/NaturalPersonForm/NaturalPersonForm.partials";
import { CompanyFoundingOrder } from "lib/models/client/CompanyFounding/CompanyFoundingOrder";
import { splitPhoneNumberIntoComponents } from "../../../../../../../lib/parsers/splitPhoneNumberIntoComponents";

const CompanyRepresentative = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef,
  "data-testid": dataTestId
}: WithTranslation & StepComponentProps) => {
  const companySetup = new CompanyFoundingOrder(order);

  const shareholderIndex = currentStep?.chapter?.index ?? 0;
  let shareholdersCopy = order.extra.shareholders ? deepClone(order.extra.shareholders) : {};
  const shareholder = shareholdersCopy[shareholderIndex] ?? {};
  const companyRepresentative = shareholder.companyRepresentative ?? {};

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const initialValues = {
    firstName: companyRepresentative.firstName ?? "",
    lastName: companyRepresentative.lastName ?? "",
    phoneNumber: companyRepresentative.phoneNumber ?? "",
    email: companyRepresentative.email ?? "",
    authorizedToShareShareholderInfo:
      companyRepresentative.authorizedToShareShareholderInfo ?? false
  };

  const onSubmit = async (values) => {
    currentStep.complete();

    let { shouldReturnToParentChapter, ...data } = values;

    const { phoneNumberWithoutCountry, phoneNumber, phoneCountry } = splitPhoneNumberIntoComponents(
      values.phoneNumber
    );

    data = {
      ...data,
      phoneNumber,
      phoneCountry,
      phoneNumberWithoutCountry
    };

    shareholder.companyRepresentative = data;

    await saveData(
      {
        ...order.extra,
        shareholders: shareholdersCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldReturnToParentChapter ?? false
    );
  };

  const otherEmails = companySetup.getEmails({ key: "shareholder", index: shareholderIndex });

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: ShareholderPersonalInformationSchema(t, otherEmails)
  });

  const isComplete = !!(
    formik.values["firstName"] &&
    formik.values["lastName"] &&
    formik.values["phoneNumber"] &&
    formik.values["email"] &&
    formik.values["authorizedToShareShareholderInfo"]
  );

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div
          data-testid="company-representative"
          className="company-representative subchapter-order-layout__scrollable">
          <ShareholderDetailsSectionTitle
            text={t("generic:shareholder-details:company-representative")}
            prefix={`${currentStep?.disposition}`}
            isCompleted={isComplete}
          />
          <NaturalPersonInputs formik={formik} dataTestId={dataTestId} />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic", "errorCodes"])(CompanyRepresentative);
