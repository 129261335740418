import gql from "graphql-tag";

export const NOMINEE_UPDATE_COMPANY = gql`
  mutation NomineeUpdateCompany(
    $id: ID!
    $name: String
    $legalForm: LegalForm
    $locked: Boolean
    $street: String
    $streetLine2: String
    $postalCode: String
    $city: String
    $careOf: String
    $country: String
    $registerCourt: String
    $registration: String
    $taxNumber: String
    $vatNumber: String
    $advisorNumber: String
    $economicId: String
    $notarizationDate: String
    $applicationForEntryDate: String
    $commercialRegisterEntryDate: String
    $lei: String
    $businessPurpose: String
    $businessPurposeShort: String
    $employees: String
    $liability: String
    $clientNumber: String
    $fiscalYear: String
    $foundationDate: String
    $testCompany: CompanyTestType
  ) {
    nomineeUpdateCompany(
      id: $id
      name: $name
      legalForm: $legalForm
      locked: $locked
      street: $street
      streetLine2: $streetLine2
      postalCode: $postalCode
      city: $city
      careOf: $careOf
      country: $country
      registerCourt: $registerCourt
      registration: $registration
      taxNumber: $taxNumber
      vatNumber: $vatNumber
      advisorNumber: $advisorNumber
      economicId: $economicId
      notarizationDate: $notarizationDate
      applicationForEntryDate: $applicationForEntryDate
      commercialRegisterEntryDate: $commercialRegisterEntryDate
      lei: $lei
      businessPurpose: $businessPurpose
      businessPurposeShort: $businessPurposeShort
      employees: $employees
      liability: $liability
      clientNumber: $clientNumber
      fiscalYear: $fiscalYear
      foundationDate: $foundationDate
      testCompany: $testCompany
    ) {
      feedback
    }
  }
`;
