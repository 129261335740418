import React from "react";
import { UserRole } from "../../../global-query-types";
import { SignupContainer } from "../../../auth/components/Signup/SignupContainer";

const TaxAdvisorSignUpScene = () => {
  return (
    <div data-testid="TaxAdvisorSignUpScene">
      <SignupContainer asUser={UserRole.TaxAdvisor} />
    </div>
  );
};

export default TaxAdvisorSignUpScene;
