import React, { PropsWithChildren, ReactNode, useState } from "react";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { RideTooltip } from "../../../sharedComponents/RideTooltip/RideTooltip";
import { InformationIcon } from "../../../sharedComponents/icons/DesignSystemIcons/InformationIcon";
import { uniqueId } from "lodash";

export interface ProductSectionCardProps extends PropsWithChildren<{}> {
  dataTestId?: string;
  icon: ReactNode;
  title: string;
  description: string;
  price?: string;
  tooltipText?: string;
}

export const ProductSectionCard = ({
  dataTestId = "product-card",
  icon,
  title,
  description,
  children,
  tooltipText,
  price
}: ProductSectionCardProps) => {
  const [tooltipId] = useState(uniqueId());
  const moduleName = "product-section-card";

  return (
    <div
      data-testid={dataTestId}
      className={`${moduleName} ${tooltipText ? `${moduleName}--disabled` : ""}`}>
      <div className={`${moduleName}__main-area`}>
        <div className={`${moduleName}__header`}>
          <div className={`${moduleName}__icon-title-wrapper`}>
            <div className={`${moduleName}__icon-and-input-area`}>
              <div
                data-testid={`${dataTestId}-icon-slot`}
                className={`${moduleName}__icon ${moduleName}__can-be-disabled`}>
                {icon}
              </div>
              <div className={`${moduleName}__tooltip-area`}>
                {tooltipText && (
                  <RideTooltip name={tooltipId} content={tooltipText ?? ""}>
                    <span className={`${moduleName}__tooltip-icon-wrapper`}>
                      <InformationIcon size={20} />
                    </span>
                  </RideTooltip>
                )}
              </div>
            </div>
            <Typography
              category="Headline"
              data-testid={`${dataTestId}-title`}
              className={`${moduleName}__can-be-disabled `}
              size={300}
              weight="Heavy">
              {title}
            </Typography>
          </div>
        </div>
        {price && (
          <Typography
            data-testid={`${dataTestId}-price-per-year`}
            category={"Paragraph"}
            weight={"Heavy"}
            size={100}
            className={`${moduleName}__price`}>
            {price}
          </Typography>
        )}
        <Typography
          category="Paragraph"
          size={200}
          weight="Light"
          className={`${moduleName}__can-be-disabled`}
          data-testid={`${dataTestId}-description `}>
          {description}
        </Typography>
        {children}
      </div>
    </div>
  );
};
