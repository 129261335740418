import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { ConfirmAccountSchema } from "../../../lib/validation/ConfirmAccountSchema";
import RidePassword from "sharedComponents/FormControls/RidePassword/RidePassword";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../components/generic/RequestFeedbackHandler";
import RequestFeedbackForm from "../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import PasswordRequirements from "auth/components/PasswordRequirements/PasswordRequirements";
import RideInput from "../../../sharedComponents/FormControls/RideInput/RideInput";
import AppConfigService from "../../../lib/services/AppConfigService";

export interface SetPasswordFormProps extends WithTranslation {
  onSubmit: (password: string) => Promise<string | null | void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
  email?: string;
}

const SetPasswordForm = ({ onSubmit, requests, t, email }: SetPasswordFormProps) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      email: email
    },
    onSubmit: async (values) => {
      await onSubmit(values["password"]);
    },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: ConfirmAccountSchema(AppConfigService.instance.appConfig.passwordPolicy, t)
  });

  return (
    <FormikProvider value={formik}>
      <div className="set_password">
        <RequestFeedbackForm submitLabel={t("auth:set-password.submit")} requests={requests}>
          <div className="set_password__content">
            <div className="set_password__title" data-testid="set-password-title">
              {t("auth:set-password.title")}
            </div>
            {email && (
              <div className="set_password__email" data-testid="set-password-email">
                <RideInput
                  isDisabled
                  type="text"
                  name="email"
                  formik={formik}
                  label={t("auth:set-password.email")}
                  className="ride-password"
                />
              </div>
            )}
            <RidePassword
              formik={formik}
              name="password"
              label={t("auth:set-password.passwordLabel")}
              shouldShowErrorFeedback={false}
            />
            <PasswordRequirements
              isTouched={formik.touched["password"]}
              password={formik.values["password"]}
            />
            <RidePassword
              formik={formik}
              name="confirmPassword"
              label={t("auth:set-password.confirmPasswordLabel")}
              shouldShowErrorFeedback={true}
            />
          </div>
        </RequestFeedbackForm>
      </div>
    </FormikProvider>
  );
};

export default withTranslationReady(["auth", "password"])(SetPasswordForm);
