import React from "react";
import { WithTranslation } from "react-i18next";
import NoResultsPlaceholder from "../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import {
  GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients,
  GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided
} from "../../../taxAdvisor/graphql/GetMyTaxAdvisorCompanyWithClients";
import ThirdPartyTaxAdvisorClientCard from "../ThirdPartyTaxAdvisorClientCard/ThirdPartyTaxAdvisorClientCard";
import { useHistory } from "react-router-dom";
import ThirdPartyTaxAdvisorMigrateBanner from "../WepaReform/ThirdPartyTaxAdvisorMigrateBanner/ThirdPartyTaxAdvisorMigrateBanner";
import { should3PTAMigrateWepaStandalone } from "../../../common/functions/should3PTAMigrateWepaStandalone";
import localStore from "../../../common/LocalStore";

interface InvestmentsListWrapperProps extends WithTranslation {
  taxServiceProvided:
    | GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided[]
    | null
    | undefined;
  myTaxAdvisorCompanyWithClients:
    | GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients
    | null
    | undefined;
}

const ThirdPartyTaxAdvisorClientsWrapper = ({
  taxServiceProvided,
  myTaxAdvisorCompanyWithClients,
  t
}: InvestmentsListWrapperProps) => {
  const history = useHistory();

  const showMigrateBanner =
    myTaxAdvisorCompanyWithClients &&
    should3PTAMigrateWepaStandalone(myTaxAdvisorCompanyWithClients);
  if (showMigrateBanner && !localStore.getSessionValue("3pta-migrate-flow-snoozed")) {
    history.push(`/third-party-tax-advisor/migrate`);
  }

  const navigateToCompanyOverview = (companyId: string) =>
    history.push(`/third-party-tax-advisor/clients/${companyId}`);

  const filtered = taxServiceProvided
    ? taxServiceProvided.filter((taxService) => taxService.client?.deletedAt === null)
    : [];

  return (
    <>
      {showMigrateBanner && <ThirdPartyTaxAdvisorMigrateBanner />}
      <div className="third-party-tax-advisor-clients-wrapper">
        {!filtered.length ? (
          <NoResultsPlaceholder text={t("third-party-tax-advisor:clients.noClients")} />
        ) : (
          <div data-testid="third-party-tax-advisor-clients-list">
            {filtered.map((taxService) => (
              <ThirdPartyTaxAdvisorClientCard
                key={taxService.id}
                onClick={() => navigateToCompanyOverview(taxService.client?.id || "")}
                taxService={taxService}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslationReady("third-party-tax-advisor")(ThirdPartyTaxAdvisorClientsWrapper);
