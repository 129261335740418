import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepComponentProps, StepHeading } from "../../ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { FeatureFlags, LegalPersonType } from "global-query-types";
import { Shareholder } from "lib/models/client/CompanyFounding/Shareholder";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";
import { deepClone } from "common/deepClone";
import { ManagingDirectorControl, MDPersonCandidate } from "./ManagingDirectorsList.partials";
import { Employee } from "lib/models/client/CompanyFounding/Employee";
import { getNextDictionaryIndex } from "lib/models/client/CompanyFounding/getNextDictionaryIndex";
import { useHistory } from "react-router-dom";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { OrderExtraService } from "../../../../../lib/services/OrderExtraService/OrderExtraService";

interface ManagingDirectorListProps {
  isLowTaxBrokerFlow: boolean;
}

interface WithManagingDirectors {
  shareholders: { [key: number]: Shareholder };
  employees: { [key: number]: Employee };
}

const ManagingDirectorsListStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData,
  isLowTaxBrokerFlow
}: ManagingDirectorListProps & StepComponentProps<WithManagingDirectors> & WithTranslation) => {
  const { push } = useHistory();

  const shareholders = order.extra.shareholders ?? {};
  const employees = order.extra.employees ?? {};
  const considerMiddleName = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddMiddleNameToLTBForm
  );

  const persons: MDPersonCandidate[] = Object.keys(shareholders).map((index) => {
    const shareholder = shareholders[index] as Shareholder;

    let person: Shareholder["personData"];
    let name: string;
    let field: "isManagingDirector" | "isRepresentativeManagingDirector";

    if (shareholder.shareholderType === LegalPersonType.Company) {
      person = shareholder.companyRepresentative;
      name = `shareholder_${index}_representative`;
      field = "isRepresentativeManagingDirector";
    } else {
      person = shareholder.personData;
      name = `shareholder_${index}`;
      field = "isManagingDirector";
    }

    let label = personNameWithFallback(person, considerMiddleName);
    if (!(person?.firstName && person?.lastName)) {
      label = `${t("generic:shareholder")} ${parseInt(index) + 1}`;
    }

    return {
      index,
      name,
      label,
      field,
      initialValue: shareholder[field] ?? false,
      entityKey: "shareholder"
    };
  });

  Object.keys(employees).forEach((index) => {
    const employee = employees[index] as Employee;
    const person = employee.personData;

    let label = personNameWithFallback(person, considerMiddleName);
    if (!(person?.firstName && person?.lastName)) {
      label = `${t("generic:managing-director")} ${parseInt(index) + 1}`;
    }

    persons.push({
      index,
      name: `employee_${index}`,
      label: label,
      field: "isManagingDirector",
      initialValue: true,
      isFullControl: true,
      entityKey: "employee"
    });
  });

  const initialValues = { fullForm: null };
  persons.forEach((person) => {
    initialValues[person.name] = person.initialValue;
  });

  const onSubmit = async (values) => {
    currentStep.complete();

    const shareholdersCopy = deepClone(shareholders);
    const employeesCopy = deepClone(employees);

    persons.forEach((person) => {
      if (person.entityKey === "shareholder") {
        const shareholder = shareholdersCopy[person.index] as Shareholder;
        shareholder[person.field] = values[person.name];
      } else {
        const employee = employeesCopy[person.index] as Employee;
        employee[person.field] = values[person.name];
      }
    });

    order.extra = {
      ...order.extra,
      shareholders: shareholdersCopy,
      employees: employeesCopy
    };

    await OrderExtraService.normalizeManagingDirectorsData(order.extra, currentStep);

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  };

  const onAdd = async () => {
    const nextIndex = getNextDictionaryIndex(employees);

    const employeesCopy = deepClone(employees);
    employeesCopy[nextIndex] = Employee({
      isManagingDirector: true
    });

    let steps;
    if (isLowTaxBrokerFlow) {
      steps = [
        { key: "employee-general-information" },
        { key: "employee-citizenship-information" },
        { key: "employee-residential-address" },
        { key: "employee-status-information" },
        { key: "employee-review", completed: true }
      ];
    } else {
      steps = [
        { key: "employee-general-information" },
        { key: "employee-citizenship-information" },
        { key: "employee-residential-address" },
        { key: "employee-review", completed: true }
      ];
    }

    const subChapter = currentStep.chapter.addSubChapter({
      index: nextIndex,
      key: "edit-managing-director-data",
      steps: steps
    });

    currentStep.reOpen();

    await saveData(
      {
        ...order.extra,
        employees: employeesCopy,
        ...currentStep.chapter.order.serialize()
      },
      true
    );

    push(
      `/client/order/generic/${currentStep.chapter.key}/${subChapter.positionalIndex}/0/${order.id}/${order.submissionId}`
    );
  };

  const onPreview = (person: MDPersonCandidate) => {
    const subChapter = currentStep.chapter.getSubChapterByEntityIndex(person.index);
    if (!subChapter) return;

    const reviewStep = subChapter.getStepByKey("employee-review");

    push(
      `/client/order/generic/${currentStep.chapter.key}/${subChapter.positionalIndex}/${
        reviewStep?.index ?? 0
      }/${order.id}/${order.submissionId}`
    );
  };

  const onEdit = (person: MDPersonCandidate) => {
    if (person.entityKey !== "employee") return;

    const subChapter = currentStep.chapter.getSubChapterByEntityIndex(person.index);
    if (!subChapter) return;

    push(
      `/client/order/generic/${currentStep.chapter.key}/${subChapter.positionalIndex}/0/${order.id}/${order.submissionId}`
    );
  };

  const onDelete = async (person: MDPersonCandidate) => {
    const subChapter = currentStep.chapter.getSubChapterByEntityIndex(person.index);
    if (!subChapter) return;

    const employeesCopy = deepClone(employees);
    delete employeesCopy[person.index];

    subChapter.parent?.removeSubChapterByEntityIndex(person.index);

    currentStep.reOpen();

    await saveData(
      {
        ...order.extra,
        employees: employeesCopy,
        ...currentStep.chapter.order.serialize()
      },
      true
    );
  };

  const validate = (values) => {
    const anySelected = Object.values(values).some((value) => value === true);

    if (!anySelected) {
      return {
        fullForm: t("generic:managing-directors.must-select-at-least-one")
      };
    }

    const result = {};

    const employees = persons.filter((person) => person.entityKey === "employee");
    for (const person of employees) {
      const subChapter = currentStep.chapter.getSubChapterByEntityIndex(person.index);
      if (!subChapter?.completed) {
        result[person.name] = "generic:managing-directors.please-complete-information";
      }
    }

    return result;
  };

  const formik = useRideFormik({
    initialValues,
    initialTouched: { fullForm: true },
    onSubmit,
    enableReinitialize: true,
    validate
  });

  const module = "managing-directors-list";

  return (
    <FormikProvider value={formik}>
      <Form className={module}>
        <StepHeading text={t("generic:company-setup.steps.managing-directors-list.title")} />

        <div data-testid={module}>
          {persons.map((person) => (
            <ManagingDirectorControl
              key={person.name}
              module={module}
              person={person}
              onPreview={async () => onPreview(person)}
              onDelete={async () => onDelete(person)}
              onClick={async () => onEdit(person)}
            />
          ))}

          <ManagingDirectorControl.AddButton module={module} onClick={onAdd} />
        </div>

        <ErrorMessageWithT name="fullForm" extra={{}} />

        {controls}
      </Form>
    </FormikProvider>
  );
};

const ManagingDirectorsList =
  (isLowTaxBrokerFlow: boolean) => (props: WithTranslation & StepComponentProps) =>
    <ManagingDirectorsListStep {...props} isLowTaxBrokerFlow={isLowTaxBrokerFlow} />;

export default (isLowTaxBrokerFlow: boolean = false) =>
  withTranslationReady("generic")(ManagingDirectorsList(isLowTaxBrokerFlow));
