import EditableSection from "../../../../sharedComponents/EditableSection/EditableSection";
import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Form, FormikProvider, useFormik } from "formik";
import { Alert, Col, Row } from "react-bootstrap";
import { ValueView } from "../../../common/layout/ValueView";
import { formatDateTime } from "../../../../common/formatters";
import ReactSelect from "react-select";
import DateTimeField from "../../../generic/DateTimeField";
import FormField from "../../../generic/FormField";
import { toISOString } from "../../../../common/dateProvider";
import { AllNotaryPersonsQuery_allNotaryPersons } from "../../NotaryList/graphql/AllNotaryPersonsQuery";
import {
  NomineeCompanyDetails_companyById_companyCreation,
  NomineeCompanyDetails_companyById_companyCreation_notaryService
} from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

interface NotaryServiceSectionProps extends WithTranslation {
  notaryPersons: AllNotaryPersonsQuery_allNotaryPersons[];
  companyCreation: NomineeCompanyDetails_companyById_companyCreation;
  onSave: (
    firstParty: string,
    firstPartyAppointment: string | null,
    thirdParty: string | null,
    thirdPartyAppointment: string | null
  ) => Promise<void>;
}

interface NotaryServiceProps {
  companyCreation: NomineeCompanyDetails_companyById_companyCreation;
  t: TFunction;
}

const ViewNotaryService = ({ companyCreation, t }: NotaryServiceProps) => {
  return (
    <div data-testid={"notaryService-view"}>
      <div data-testid="notaryService-alert">
        {!companyCreation.notaryService && (
          <Alert variant="danger">{t("nominee-dashboard:notary.notSet")}</Alert>
        )}
        {companyCreation.notaryService && (
          <NotaryServiceFields notaryService={companyCreation.notaryService} t={t} />
        )}
      </div>
    </div>
  );
};

interface NotaryServiceFieldsProps {
  notaryService: NomineeCompanyDetails_companyById_companyCreation_notaryService;
  t: TFunction;
}

const NotaryServiceFields = ({ notaryService, t }: NotaryServiceFieldsProps) => {
  const firstPartyName = `${notaryService.firstParty.firstName} ${notaryService.firstParty.lastName}`;
  const firstPartyAppointment = formatDateTime(notaryService.firstPartyAppointment);
  const thirdPartyName = notaryService.thirdParty
    ? `${notaryService.thirdParty.firstName} ${notaryService.thirdParty.lastName}`
    : "---";
  const thirdPartyAppointment = formatDateTime(notaryService.thirdPartyAppointment);

  return (
    <Row>
      <Col xs={12} md={6}>
        <ValueView
          name={"nominee-dashboard:notary.firstPartyName"}
          value={firstPartyName}
          t={t}
          testId="notaryService-firstPartyName"
        />
      </Col>
      <Col xs={12} md={6}>
        <ValueView
          name={"nominee-dashboard:notary.firstPartyAppointment"}
          value={firstPartyAppointment}
          t={t}
          testId="notaryService-firstPartyAppointment"
        />
      </Col>
      <Col xs={12} md={6}>
        <ValueView
          name={"nominee-dashboard:notary.thirdPartyName"}
          value={thirdPartyName}
          t={t}
          testId="notaryService-thirdPartyName"
        />
      </Col>
      <Col xs={12} md={6}>
        <ValueView
          name={"nominee-dashboard:notary.thirdPartyAppointment"}
          value={thirdPartyAppointment}
          t={t}
          testId="notaryService-thirdPartyAppointment"
        />
      </Col>
    </Row>
  );
};

interface EditNotaryServiceProps {
  formik: any;
  persons: AllNotaryPersonsQuery_allNotaryPersons[];
  t: TFunction;
}

function EditNotaryService({ persons, formik, t }: EditNotaryServiceProps) {
  const notaryPersons = persons.map((person) => ({
    value: person.id,
    label: `${person.firstName} ${person.lastName}`
  }));

  const notaryOptions = [{ value: "", label: "not set" }, ...notaryPersons];

  const firstPartyValue = notaryOptions.find((option) => {
    return option.value === formik.values["firstParty"];
  });

  const thirdPartyValue = notaryOptions.find((option) => {
    return option.value === formik.values["thirdParty"];
  });

  const [firstPartyAppointment, handleFirstPartyAppointmentChange] = useState(
    formik.values["firstPartyAppointment"]
      ? new Date(formik.values["firstPartyAppointment"])
      : new Date()
  );

  const [thirdPartyAppointment, handleThirdPartyAppointmentChange] = useState(
    formik.values["thirdPartyAppointment"]
      ? new Date(formik.values["thirdPartyAppointment"])
      : new Date()
  );

  return (
    <Row data-testid="notaryService-edit">
      <Col xs={12} md={6}>
        <FormField
          name="firstParty"
          testid="firstParty-select-container"
          label={"nominee-dashboard:notary.firstPartyName"}
          t={t}>
          <ReactSelect
            className="select-field"
            name="firstParty"
            isSearchable={true}
            value={firstPartyValue}
            onBlur={() => formik.setFieldTouched("firstParty", true)}
            options={notaryOptions}
            onChange={(selectedOption) => {
              formik.handleChange("firstParty")(selectedOption?.value ?? null);
            }}
          />
        </FormField>
      </Col>
      <Col xs={12} md={6}>
        <DateTimeField
          t={t}
          value={firstPartyAppointment}
          name="firstPartyAppointment"
          label={"nominee-dashboard:notary.firstPartyAppointment"}
          onChange={(val) => {
            handleFirstPartyAppointmentChange(val);
            formik.setFieldTouched("firstPartyAppointment", val);
            formik.setFieldValue("firstPartyAppointment", val, true);
          }}
        />
      </Col>
      <Col xs={12} md={6}>
        <FormField
          name="firstParty"
          testid="thirdParty-select-container"
          label={"nominee-dashboard:notary.thirdPartyName"}
          t={t}>
          <ReactSelect
            className="select-field"
            name="thirdParty"
            isSearchable={true}
            value={thirdPartyValue}
            onBlur={() => formik.setFieldTouched("thirdParty", true)}
            options={notaryOptions}
            onChange={(selectedOption) => {
              formik.handleChange("thirdParty")(selectedOption?.value ?? null);
            }}
          />
        </FormField>
      </Col>
      <Col xs={12} md={6}>
        <DateTimeField
          t={t}
          value={thirdPartyAppointment}
          name="thirdPartyAppointment"
          label={"nominee-dashboard:notary.thirdPartyAppointment"}
          onChange={(val) => {
            handleThirdPartyAppointmentChange(val);
            formik.setFieldTouched("thirdPartyAppointment", val);
            formik.setFieldValue("thirdPartyAppointment", val, true);
          }}
        />
      </Col>
    </Row>
  );
}

export const NotaryServiceSection = ({
  companyCreation,
  onSave,
  notaryPersons,
  t,
  tReady
}: NotaryServiceSectionProps) => {
  const [editMode, setEditMode] = useState(false);

  const onEdit = () => {
    setEditMode(true);
  };

  const onView = () => {
    setEditMode(false);
  };

  const formik = useFormik({
    initialValues: {
      firstParty: companyCreation.notaryService?.firstParty.id,
      firstPartyAppointment: companyCreation.notaryService?.firstPartyAppointment,
      thirdParty: companyCreation.notaryService?.thirdParty?.id,
      thirdPartyAppointment: companyCreation.notaryService?.thirdPartyAppointment
    },
    onSubmit: (values) => {
      return onSave(
        values["firstParty"] ?? "",
        toISOString(values["firstPartyAppointment"]),
        values["thirdParty"] || null,
        toISOString(values["thirdPartyAppointment"])
      );
    },
    enableReinitialize: true
  });

  if (!tReady) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditableSection
          onEdit={onEdit}
          isEditMode={editMode}
          onView={onView}
          title={t("nominee-dashboard:company.notaryService")}
          testIdPrefix={"notaryService-"}>
          {editMode ? (
            <EditNotaryService persons={notaryPersons} formik={formik} t={t} />
          ) : (
            <ViewNotaryService companyCreation={companyCreation} t={t} />
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["nominee-dashboard", "generic"])(NotaryServiceSection);
