import { GetRealEstateInfoByCompanyId } from "./getRealEstateInfo";
import { GetCompanyInfoByCompanyId } from "./getCompanyInfo";

export const useCompanyRealEstateDetails = (companyId: string) => {
  const {
    loading: loadingCompanyInfo,
    error: errorCompanyInfo,
    data: companyInfo
  } = GetCompanyInfoByCompanyId(companyId);
  const {
    loading: loadingRealEstateInfo,
    error: errorrealEstateInfo,
    data: realEstateInfo
  } = GetRealEstateInfoByCompanyId(companyId);

  const loading = loadingCompanyInfo || loadingRealEstateInfo;
  const errorReason = errorCompanyInfo || errorrealEstateInfo;

  return {
    loading,
    errorReason,
    companyInfo: loading ? null : companyInfo?.companyById,
    realEstateInfo: loading ? null : realEstateInfo?.realEstateByCompanyId
  };
};
