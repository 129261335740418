import * as Yup from "yup";
import { EmailValidation } from "./EmailSchema";
import { cityRegex, postalCode } from "./common";
import { LegalForm } from "../../global-query-types";

export const CompanyHoldingStructureSchema = () => {
  return Yup.object().shape({
    companyName: Yup.string().required("generic:validation-required"),
    legalForm: Yup.mixed<LegalForm>()
      .required("generic:validation-required")
      .oneOf(Object.values(LegalForm)),
    registration: Yup.string().required("generic:validation-required"),
    registerCourt: Yup.string().required("generic:validation-required"),
    street: Yup.string().required("generic:validation-required"),
    city: Yup.string()
      .matches(cityRegex, { message: "generic:validation-only-letters-spaces-dash" })
      .required("generic:validation-required"),
    postalCode: Yup.string()
      .matches(postalCode, { message: "generic:validation-only-numbers" })
      .required("generic:validation-required"),

    title: Yup.string(),
    firstName: Yup.string().required("generic:validation-required"),
    lastName: Yup.string().required("generic:validation-required"),
    email: EmailValidation,
    phoneNumber: Yup.string().required("generic:validation-required")
  });
};
