import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RideLogoMinimalist } from "../../../../sharedComponents/icons/RideLogoMinimalist";
import { CircleWithArrowIcon } from "../../../../sharedComponents/icons/CircleWithArrowIcon";

export interface ClubBannerProps extends WithTranslation {
  onClick: () => void;
}

const ClubBanner = ({ t, onClick }: ClubBannerProps) => {
  return (
    <button className="club-banner" onClick={onClick}>
      <div className="club-banner__title-container">
        <div className="club-banner__ride-club">
          <RideLogoMinimalist />
          {t("generic:clubDealLogoText")}
        </div>
        <div className="club-banner__title">{t("client:investments.card.clubBannerTitle")}</div>
        <div className="club-banner__arrow">
          <div className="club-banner__ride-club-mobile">
            <RideLogoMinimalist />
            {t("generic:clubDealLogoText")}
          </div>

          <div>
            <CircleWithArrowIcon
              className="default"
              circleFill="transparent"
              circleStroke="#303436"
            />
            <CircleWithArrowIcon
              className="hovered"
              circleFill="#303436"
              circleStroke="#303436"
              arrowColor="#48FFAF"
            />
          </div>
        </div>
      </div>
      <div className="club-banner__image">
        <div className="club-banner__overlay" />
      </div>
    </button>
  );
};

export default withTranslationReady(["generic", "client"])(ClubBanner);
