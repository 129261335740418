import i18next from "i18next";
import { ValueFormatter } from "./ValueFormatter";

export class DateTimeFormatter extends ValueFormatter {
  format(value, ...args) {
    if (value) {
      const date = new Date(value);
      return (
        date.toLocaleDateString(i18next.language) + " " + date.toLocaleTimeString(i18next.language)
      );
    }
    return super.format(value, ...args);
  }
}
