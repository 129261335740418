import React from "react";
import FoundationServiceTasksTable from "../FoundationServiceTasksTable/FoundationServiceTasksTable";
import { GetProcessTasksQuery_getProcessTasks_processTasks } from "../../../lib/api/queries/graphql/GetProcessTasksQuery";
import { TaskStatesEnum } from "../../../global-query-types";

export interface FoundationServiceTasksProps {
  tasks: GetProcessTasksQuery_getProcessTasks_processTasks[];
  sortCallback: ({ sortColumn, sortOrder }: { sortColumn: string; sortOrder: string }) => void;
}

export const FoundationServiceTasks = ({ tasks, sortCallback }: FoundationServiceTasksProps) => {
  const sanitizeType = (task) => ({
    ref: task.ref ?? "",
    state: task.state ?? TaskStatesEnum.NOT_POSSIBLE_YET,
    companyCreation: {
      company: task.companyCreation?.company ?? {
        id: "",
        name: "---"
      }
    },
    responsibleParty: task.responsibleParty,
    updatedAt: task.updatedAt
  });

  const sanitizedTasks = tasks.map(sanitizeType);
  return <FoundationServiceTasksTable tasks={sanitizedTasks} sortCallback={sortCallback} />;
};
