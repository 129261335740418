import React from "react";
import { useParams } from "react-router";
import { toNumber } from "lodash";
import {
  AdminServiceTier,
  ProductSelectionStepForm
} from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import UnifiedShortOrderForm from "./UnifiedShortOrderForm";
import { useQueryParams } from "../../../lib/hooks/useQueryParams";
import { useUtmParams } from "lib/hooks/useUtmParams";

export const UnifiedShortOrderFormContainer = () => {
  const utmParams = useUtmParams();
  const queryParams = useQueryParams();
  const params = useParams<{ stepIndex: string }>();

  const preselectedProducts: ProductSelectionStepForm = {
    companyFoundingService: queryParams.get("companyFoundingService") === "true" ?? undefined,
    adminService: (queryParams.get("adminService") ?? undefined) as AdminServiceTier | undefined,
    lowTaxBroker: queryParams.get("lowTaxBroker") === "true" ?? undefined
  };

  const canExtractStepIndex = !Number.isNaN(toNumber(params.stepIndex));
  const stepIndex = canExtractStepIndex ? toNumber(params.stepIndex) : 0;

  return (
    <UnifiedShortOrderForm
      utmParams={utmParams}
      preselectedProducts={preselectedProducts}
      stepIndex={stepIndex}
    />
  );
};
