import React from "react";
import { useMutation } from "react-apollo";
import {
  UpsertBankAccount,
  UpsertBankAccountVariables
} from "../../../../lib/api/mutations/graphql/UpsertBankAccount";
import { UPSERT_BANK_ACCOUNT } from "../../../../lib/api/mutations/UPSERT_BANK_ACCOUNT";
import { GetMyGmbHs, MY_GMBHS_QUERY } from "components/client/MyGmbHs/Queries";
import AddExistingBankAccount from "./AddExistingBankAccount";

export const AddExistingBankAccountContainer = () => {
  const [upsertBankAccount, updateRequest] = useMutation<
    UpsertBankAccount,
    UpsertBankAccountVariables
  >(UPSERT_BANK_ACCOUNT);

  const getAllOfTheUserCompaniesRequest = GetMyGmbHs();

  const companies = getAllOfTheUserCompaniesRequest?.data?.companies ?? [];
  const companyOptions = companies.map((company) => {
    return {
      label: company.name,
      value: company.id
    };
  });

  const handleAddBAnk = async (variables) => {
    await upsertBankAccount({
      variables,
      refetchQueries: [{ query: MY_GMBHS_QUERY }]
    });
  };

  return (
    <>
      {getAllOfTheUserCompaniesRequest?.data?.companies && (
        <AddExistingBankAccount
          onAddBankAccount={handleAddBAnk}
          companyOptions={companyOptions}
          requests={[updateRequest]}
        />
      )}
    </>
  );
};
