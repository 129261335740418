import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SPVCompaniesQuery, SPVCompaniesQuery_nomineeCompanies } from "./graphql/SPVCompaniesQuery";
import { Button, Col, Container, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import Table from "../../generic/Table";
import LoadingAlert from "../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import AddSPVCompany from "./AddSPVCompany";
import { TFunction } from "i18next";
import Dropzone, { FileDropzone } from "../../generic/DropZone";
import { addToIpfs } from "../../../common/IPFS/ipfs";
import IPFSConfig from "../../../common/IPFS/IPFSConfig";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import logger from "../../../common/Logger";
import {
  uploadDocumentToIPFS,
  uploadDocumentToIPFSVariables
} from "./graphql/uploadDocumentToIPFS";
import { AssertionError } from "assert";
import SuccessAlert from "../../generic/SuccessAlert";
import DocumentQueryByCompanyId, { IPFS_DOCUMENT_IDS_QUERY } from "./DocumentQueryByCompanyId";
import { Link } from "react-router-dom";
import { ipfsConfig } from "../../../common/IPFS/IPFSSettings";
import TokenizedInvestment from "./TokenizedInvestment";
import { FeatureFlags, LegalForm } from "../../../global-query-types";
import { defaultCountry } from "../../../common/defaultCountry";
import { formatRegisterCourt } from "../../../common/formatters/registerCourtFormatter";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

interface SPVCompanyProps extends WithTranslation {
  companyList: SPVCompaniesQuery;
  loading: boolean;
  SPVCompaniesError;
}

export const UPLOAD_DOCUMENT_TO_IPFS = gql`
  mutation uploadDocumentToIPFS($companyId: String!, $CID: String!, $fileName: String!) {
    createIPFSDocument(companyId: $companyId, CID: $CID, fileName: $fileName) {
      id
      CID
      fileName
    }
  }
`;

export const SPVCompany: React.FunctionComponent<SPVCompanyProps> = (props) => {
  const { t, companyList, loading, SPVCompaniesError } = props;
  const { ipfs, ipfsVersion, isIpfsReady, ipfsError, ipfsMessage, isIPFS } = IPFSConfig(ipfsConfig);
  const [fileBuffer, setFileBuffer] = useState<Uint8Array | null>(null);
  const [fileName, setFileName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [callUpdateMutation, { error: ipfsMutationError }] = useMutation<
    uploadDocumentToIPFS,
    uploadDocumentToIPFSVariables
  >(UPLOAD_DOCUMENT_TO_IPFS, {
    refetchQueries: [{ query: IPFS_DOCUMENT_IDS_QUERY, variables: { companyId: companyId } }]
  });

  let errorReason =
    (SPVCompaniesError && SPVCompaniesError.message) ||
    ipfsError ||
    (ipfsMutationError && ipfsMutationError.message);

  if (loading) {
    return (
      <Container>
        <LoadingAlert loading={loading} t={t} message={t("generic:loading-message")} />
      </Container>
    );
  }

  if (!companyList || !companyList.nomineeCompanies)
    return <Container>{t("spv-company:spv-company-not-found")}</Container>;

  const handleOnDrop = async (acceptedFiles: FileDropzone[]) => {
    const file = acceptedFiles[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async () => {
      const ipfsContent = reader.result;
      const buffer = Buffer.from(ipfsContent as Uint8Array);
      setFileBuffer(buffer);
      setFileName(file.name);
    };
  };

  const uploadFileToIpfs = async (companyId: string) => {
    setUploading(true);
    setCompanyId(companyId);
    const uploadFile = await addToIpfs(ipfs, fileBuffer!, fileName);
    if (isIPFS.cid(uploadFile.cid)) {
      try {
        const { data } = await callUpdateMutation({
          variables: {
            companyId: companyId,
            CID: uploadFile.cid.toString(),
            fileName: uploadFile.path.toString()
          }
        });
        if (data) {
          setSuccess(true);
        }
      } catch (error) {
        logger.error(error);
        setFileBuffer(null);
        setUploading(false);
        throw new AssertionError({ message: "Uploading Documents to IPFS not successful" });
      }
    }

    setFileBuffer(null);
    setUploading(false);
  };

  const buildTableColumns = (t: TFunction) => {
    return [
      {
        dataField: "name",
        text: t("generic:company-name"),
        formatter: (name: string, row: SPVCompaniesQuery_nomineeCompanies) => (
          <Link
            to={`/nominee-companies/${row.id}`}
            className="text-reset text-underlined"
            data-testid="company-link">
            {name}
          </Link>
        )
      },
      {
        dataField: "legalForm",
        text: t("generic:legal-form"),
        formatter: (legalForm: LegalForm) => t(`generic:legalForm.${legalForm}`)
      },
      {
        dataField: "projectName",
        text: t("generic:projectName"),
        formatter: (cell, row) => (
          <Link
            to={{
              pathname: `/nominee-investment/detail/${row.id}`
            }}>
            {row.projectName}
          </Link>
        )
      },
      {
        dataField: "registerCourt",
        text: t("generic:registerCourt"),
        formatter: (cell, row) => formatRegisterCourt(row.registerCourt)
      },
      {
        dataField: "registration",
        text: t("generic:registration")
      },
      {
        dataField: "tradeTaxFree",
        text: t("generic:trade-tax-free")
      },
      {
        dataField: "taxNumber",
        text: t("generic:taxNumber")
      },
      {
        dataField: "vatNumber",
        text: t("generic:vatNumber")
      },
      {
        dataField: "businessAddress",
        text: t("generic:address"),
        formatter: (address) => (
          <>
            {address.street}, {address.city}, {address.postalCode}, {defaultCountry}
          </>
        )
      },
      {
        dataField: "tokenized",
        text: t("generic:tokenized")
      },
      {
        dataField: "status",
        text: t("generic:publish-status"),
        formatter: (value) => t(`generic:publishStatus.${value}`)
      }
    ];
  };

  const nomineeCompaniesArray = (companyList.nomineeCompanies! || []).map((company) => {
    return {
      ...company,
      untranslated: company,
      tokenized: company.tokenized ? t("generic:yes") : t("generic:no"),
      tradeTaxFree: company.tradeTaxFree ? t("generic:yes") : t("generic:no")
    };
  });

  const expandRow = {
    renderer: (row) => (
      <Container>
        <ErrorAlertLegacy t={t} error={errorReason} />
        {success ? <SuccessAlert t={t} message={t("generic:success_heading")} /> : null}
        <Row>
          <Col sm={4}>
            <Dropzone
              handleOnDrop={handleOnDrop}
              multiple={false}
              dragLabel={t("generic:drop-the-files-here")}
              dropLabel={t("generic:drop-or-click")}
            />
            <OverlayTrigger
              overlay={
                <Tooltip id="tt-IPFS">
                  {ipfsMessage}
                  {"v"}
                  {ipfsVersion}
                </Tooltip>
              }>
              <Button
                onClick={() => uploadFileToIpfs(row.id)}
                disabled={!fileBuffer! || !isIpfsReady}
                size={"sm"}>
                {uploading ? (
                  <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                ) : (
                  <></>
                )}
                {t("generic:upload")}
              </Button>
            </OverlayTrigger>
          </Col>
          <Col sm={8}>
            <DocumentQueryByCompanyId companyId={row.id} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TokenizedInvestment projectName={row.projectName} companyId={row.id} />
          </Col>
        </Row>
      </Container>
    ),
    showExpandColumn: true
  };

  return (
    <>
      <FeatureFlag name={FeatureFlags.DeprecatedAssetManager}>
        <div className="mb-3 text-right">
          <AddSPVCompany />
        </div>
      </FeatureFlag>

      <Table
        data={nomineeCompaniesArray!}
        columns={buildTableColumns(t)}
        tableStyle="greenTable"
        expandRow={expandRow}
        keyField="id"
      />
    </>
  );
};

export default withTranslation(["spv-company", "generic"])(SPVCompany);
