import React from "react";

export interface ButtonCardProps {
  text: string;
  onPress: () => Promise<void>;
}

const ButtonCard = ({ text, onPress }: ButtonCardProps) => {
  return (
    <div data-testid="button-card-container" className="button-card" onClick={onPress}>
      <div className="button-card-wrapper">
        <div data-testid="button-card-icon" className="button-card-icon">
          <div className="button-card-icon--plus-BLACK"></div>
        </div>
        <div data-testid="button-card-text" className="button-card--text">
          {text}
        </div>
      </div>
    </div>
  );
};

export default ButtonCard;
