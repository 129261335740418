import { BankType, FeatureFlags } from "global-query-types";
import React from "react";
import { IconVariant, RideTile } from "sharedComponents/RideTile/RideTile";
import { RideCardOwnership } from "../../../../sharedComponents/RideCardOwnership/RideCardOwnership";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import NavigationService from "../../../../lib/services/NavigationService/NavigationService";
import BankIcon from "../BankIcon/BankIcon";
import { DisabledFeatureFlag } from "../../../../sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { Company } from "lib/types/types";
import { companyName } from "../../../../lib/formatters/companyFormatter";

export interface BankAccountCardProps extends WithTranslation {
  bankAccountId: string;
  bank: BankType;
  accountName: string | null;
  iban: string | null;
  company: Company;
  onEdit?: () => void;
}

const BankAccountCard = ({
  bankAccountId,
  bank,
  accountName,
  iban,
  company,
  onEdit,
  t
}: BankAccountCardProps) => {
  const handleEdit = (e) => {
    e.preventDefault();
    onEdit?.();
  };

  const isIBANValid = (iban: string | null): boolean => {
    return !!iban && iban.replaceAll(" ", "") !== "";
  };

  const ibanDetails = (
    <>
      <div className="bank-account-card__iban-details">
        <div className="bank-account-card__iban-details--light" data-testid="iban-label">
          IBAN
        </div>
        <div
          className={`bank-account-card__iban-details${isIBANValid(iban) ? "--dark" : "--red"}`}
          data-testid="iban-value">
          {isIBANValid(iban) ? iban : t("client:banking.no-iban-yet")}
        </div>
      </div>
      {onEdit && (
        <div
          className="bank-account-card__iban-details__edit"
          data-testid="edit-link"
          onClick={handleEdit}>
          {t(`client:banking.edit`)}
        </div>
      )}
    </>
  );

  const ownershipLabel = (
    <RideCardOwnership
      ownership={[companyName({ name: company.name, legalForm: company.legalForm })]}
    />
  );

  const name =
    !accountName || accountName?.trim() === "" ? t(`client:banking.banks.${bank}`) : accountName;

  const openBankAccountPage = (e): void => {
    if (e.target?.dataset.testid === "edit-link") {
      return;
    }

    NavigationService.instance?.openLink(t(`client:banking.bank-account-login.${bank}`));
  };

  const openBankAccountDetailsPage = (e): void => {
    if (e.target?.dataset.testid === "edit-link" || !iban || !company.id) {
      return;
    }
    const existsABankConnection: boolean = !!company.finApiAccount?.finApiBankConnections?.length;
    const sanitizeIBAN = (iban) => iban.replaceAll(" ", "");

    let url = `${NavigationService.instance?.baseUrl}/client/banking`;
    if (existsABankConnection) {
      url += `/${company.id}/${bankAccountId}/${sanitizeIBAN(iban)}`;
    } else {
      url += `/webform/${company.id}/${bankAccountId}/${sanitizeIBAN(iban)}`;
    }

    NavigationService.instance?.openLink(url);
  };

  return (
    <div className="bank-account-card">
      <DisabledFeatureFlag name={FeatureFlags.BankAccountDetails}>
        <RideTile
          name={name}
          detailsSlot={ibanDetails}
          subTitleSlot={ownershipLabel}
          iconVariant={bank === BankType.Own ? undefined : IconVariant.externalURLIcon}
          imageSlot={<BankIcon bank={bank} className="bank-account-card__bank-icon" />}
          data-testid="bank-account-card"
          onClick={bank !== BankType.Own ? openBankAccountPage : undefined}
        />
      </DisabledFeatureFlag>
      <FeatureFlag name={FeatureFlags.BankAccountDetails}>
        <RideTile
          name={name}
          detailsSlot={ibanDetails}
          subTitleSlot={ownershipLabel}
          iconVariant={bank === BankType.Own ? undefined : IconVariant.arrowIcon}
          imageSlot={<BankIcon bank={bank} className="bank-account-card__bank-icon" />}
          data-testid="bank-account-card"
          onClick={bank !== BankType.Own ? openBankAccountDetailsPage : undefined}
        />
      </FeatureFlag>
    </div>
  );
};

export default withTranslationReady("client")(BankAccountCard);
