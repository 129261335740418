import React from "react";

export const ArrowLeft = ({ className, size }: { className?: string; size?: number }) => (
  <svg
    className={className}
    width={size ?? 16}
    height={size ?? 12}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.52023 0.853553C6.32497 0.658291 6.00839 0.658291 5.81312 0.853552L1.02023 5.64643C0.824968 5.8417 0.824967 6.15828 1.02023 6.35354L5.81312 11.1464C6.00839 11.3417 6.32497 11.3417 6.52023 11.1464L7.10949 10.5572C7.30475 10.3619 7.30475 10.0453 7.10949 9.85008L4.17607 6.91665H14.8333C15.1095 6.91665 15.3333 6.6928 15.3333 6.41665V5.58332C15.3333 5.30718 15.1095 5.08332 14.8333 5.08332H4.17607L7.10948 2.14992C7.30475 1.95465 7.30475 1.63807 7.10948 1.44281L6.52023 0.853553Z"
      fill="#303436"
    />
  </svg>
);
