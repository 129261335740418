import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalLayout,
  ModalLayoutVariant
} from "../../../sharedComponents/templates/ModalLayout/ModalLayout";
import AddBankAccount from "../../components/Banking/AddBankAccount/AddBankAccount";

const AddBankAccountScene = () => {
  const history = useHistory();

  const handleClose = () => {
    history.replace(`/client/banking`);
  };

  const handleAddNew = () => {
    history.push(`/client/banking/add/select-gmbh`);
  };

  const handleAddExisting = () => {
    history.push(`/client/banking/add/existing`);
  };

  return (
    <ModalLayout onClose={handleClose} variant={ModalLayoutVariant.dark}>
      <AddBankAccount onAddNew={handleAddNew} onAddExisting={handleAddExisting} />
    </ModalLayout>
  );
};

export default AddBankAccountScene;
