import { TFunction } from "i18next";
import { MyCompanyCreationQuery_myCompanyCreation_company_group_memberships } from "../../graphql/MyCompanyCreationQuery";
import { CompanyHoldingStructure, CompanyShareholderForm } from "./CompanyShareholderForm";
import * as React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  StartHoldingCompanyCreation,
  StartHoldingCompanyCreationVariables
} from "../../graphql/StartHoldingCompanyCreation";
import { START_HOLDING_COMPANY_CREATION, UPDATE_HOLDING_COMPANY_CREATION } from "../../Mutations";
import {
  UpdateHoldingCompanyCreation,
  UpdateHoldingCompanyCreationVariables
} from "../../graphql/UpdateHoldingCompanyCreation";
import { FlowTypeEnum } from "../../../../../global-query-types";
import RequestFeedbackHandler from "../../../../generic/RequestFeedbackHandler";

type Props = {
  t: TFunction;
  companyCreationId?: string;
  flowType: FlowTypeEnum;
  referral: string | null;
  handleNewFlow: (
    token: string | null | undefined,
    companyCreation: { id: string; flowType: FlowTypeEnum | null } | null | undefined
  ) => Promise<void>;
  handleFormUpdate: () => Promise<void>;
  memberships:
    | MyCompanyCreationQuery_myCompanyCreation_company_group_memberships[]
    | null
    | undefined;
};

export const CompanyShareholderFormContainer = ({
  companyCreationId,
  flowType,
  referral,
  memberships,
  handleNewFlow,
  handleFormUpdate,
  t
}: Props) => {
  const [createMutation, createRequest] = useMutation<
    StartHoldingCompanyCreation,
    StartHoldingCompanyCreationVariables
  >(START_HOLDING_COMPANY_CREATION);

  const onCreate = async (value: CompanyHoldingStructure) => {
    const result = await createMutation({
      variables: {
        creator: value.person,
        shareholders: [value.company],
        flowType,
        referral
      }
    });

    const response = result.data?.startHoldingCompanyCreation;
    await handleNewFlow(response?.authentication?.token, response?.companyCreation);
  };

  const [updateMutation, updateRequest] = useMutation<
    UpdateHoldingCompanyCreation,
    UpdateHoldingCompanyCreationVariables
  >(UPDATE_HOLDING_COMPANY_CREATION);

  const onUpdate = async (value: CompanyHoldingStructure) => {
    await updateMutation({
      variables: {
        companyCreationId: companyCreationId!,
        creator: value.person,
        shareholders: [value.company]
      }
    });

    await handleFormUpdate();
  };

  return (
    <RequestFeedbackHandler requests={[createRequest, updateRequest]}>
      <CompanyShareholderForm
        t={t}
        onCreate={onCreate}
        onUpdate={onUpdate}
        memberships={memberships}
      />
    </RequestFeedbackHandler>
  );
};
