import React, { useState } from "react";
import { TFunction } from "i18next";
import { FlowTypeEnum, SalutationType, ShareholderPerson } from "../../../../global-query-types";
import { ExecutionResult } from "graphql";
import { UpdateWepaDirectorDetails } from "../../../../lib/api/mutations/graphql/UpdateWepaDirectorDetails";
import { Col, Container, Row } from "react-bootstrap";
import { PersonalInfoFields } from "../../AdminService/Steps/PersonalInfo/PersonalInfoFields";
import { Form, FormikProvider, useFormik } from "formik";
import { SalutationValueEnum } from "../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import { CompanyCreationShareholdersSchema } from "../../../../lib/validation/CompanyCreationShareholdersSchema";
import { GetWepaServiceById_getWepaServiceById_company_contactPerson } from "../../../../lib/api/queries/graphql/GetWepaServiceById";

interface WepaDirectorFormProps {
  t: TFunction;
  person: GetWepaServiceById_getWepaServiceById_company_contactPerson;
  submitWepaDirectorHandler: (
    director: ShareholderPerson
  ) => Promise<ExecutionResult<UpdateWepaDirectorDetails>>;
  refetchWepaService: () => Promise<void>;
}

export const WepaDirectorForm = ({
  t,
  person,
  refetchWepaService,
  submitWepaDirectorHandler
}: WepaDirectorFormProps) => {
  const [localError, setLocalError] = useState<string | null>(null);
  const [agreed, setAgreed] = useState(false);

  const initialValues = {
    id: person.id,
    email: "",
    firstName: person.firstName ?? "",
    lastName: person.lastName ?? "",
    phoneNumber: person.phoneNumber ?? "",
    salutation: person.salutation ? SalutationValueEnum[person.salutation] : SalutationValueEnum.Mr,
    title: person.title ?? ""
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: CompanyCreationShareholdersSchema,
    onSubmit: async (values) => {
      if (!agreed) {
        setLocalError(t("generic:must-indicate-eligibility"));
        return;
      }

      await submitWepaDirectorHandler({
        ...values,
        salutation: SalutationType[SalutationValueEnum[values.salutation]]
      });
      await refetchWepaService();
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Row>
          <Col className="steps-form-description" data-testid="WEPA_SERVICE-order-step-description">
            {t("wepa-service:steps-description.DIRECTOR")}
          </Col>
        </Row>
        <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
          <Container>
            <Row>
              <Col>
                <PersonalInfoFields
                  t={t}
                  formik={formik}
                  agreed={agreed}
                  setAgreed={setAgreed}
                  disabled={false}
                  currentFlowType={FlowTypeEnum.WEPA_SERVICE}
                  errors={formik.errors}
                  localError={localError}
                  prefix={""}
                  touched={formik.touched}
                  values={formik.values}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Form>
    </FormikProvider>
  );
};
