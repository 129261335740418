import { useEffect } from "react";
import { debounce } from "lodash";

// from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export const ViewportHeightAdjuster = () => {
  const redefineVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    redefineVh();

    const resizeCallback = debounce(redefineVh, 100);

    window.addEventListener("resize", resizeCallback);
    return () => window.removeEventListener("resize", resizeCallback);
  }, []);

  return null;
};
