import { Field, FormikContextType } from "formik";
import React, { ReactNode } from "react";
import { CheckboxFrame } from "sharedComponents/icons/CheckboxFrame";
import { CheckboxSelected } from "sharedComponents/icons/CheckboxSelected";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import { CheckboxIndeterminateSelected } from "../../icons/CheckboxIndeterminateSelected";

export enum LabelVariant {
  Default = "default",
  Medium = "medium"
}

interface RideCheckboxProps {
  label?: string | ReactNode;
  name: string;
  isIndeterminate?: boolean;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  formik?: FormikContextType<any>;
  "data-testid"?: string;
  className?: string;
  shouldShowErrorFeedback?: boolean;
  labelVariant?: LabelVariant;
}

export const RideCheckbox = ({
  label,
  name,
  isIndeterminate,
  checked,
  onChange,
  formik,
  "data-testid": testId,
  className,
  shouldShowErrorFeedback = false,
  labelVariant = LabelVariant.Default
}: RideCheckboxProps) => {
  const inputProps = {
    className: "ride-checkbox__input",
    type: "checkbox",
    "data-testid": `${testId}-checkbox`,
    name
  };

  const hasError = formik?.touched[name] && !!formik?.errors[name];

  const errorFeedback = (
    <InvalidFeedback testPrefix={name} hidden={!hasError}>
      {formik?.errors[name]}
    </InvalidFeedback>
  );

  const inputField = formik ? (
    <Field {...inputProps} />
  ) : (
    <input {...inputProps} checked={checked} onChange={() => onChange?.(!checked)} />
  );

  return (
    <div
      className={`ride-checkbox ride-checkbox--${isIndeterminate ? "indeterminate" : "normal"} ${
        className ?? ""
      }`}
      data-testid={testId ?? `${name}-checkbox`}>
      <label
        className={`ride-checkbox__label ride-checkbox__label${
          labelVariant === LabelVariant.Medium ? `--medium` : `--default`
        }`}>
        {inputField}
        <div className="ride-checkbox__images">
          <span
            className={`ride-checkbox__frame ride-checkbox__frame${
              hasError && shouldShowErrorFeedback ? "--error" : ""
            }`}>
            <CheckboxFrame />
          </span>
          <span className="ride-checkbox__selected">
            <CheckboxSelected />
          </span>
          <span className="ride-checkbox__indeterminate-selected">
            <CheckboxIndeterminateSelected />
          </span>
        </div>
        {label}
      </label>
      {shouldShowErrorFeedback ? errorFeedback : null}
    </div>
  );
};
