import React from "react";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";
import { formatRegisterCourt } from "../../../common/formatters/registerCourtFormatter";
import { PendingText } from "../../../components/client/company/components/PendingText";
import { formatDate } from "../../../common/formatters";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";
import { RideCardBodySeparator } from "../../RideCardBodySeparator/RideCardBodySeparator";

interface GmbHRegistrationDetailsProps extends CompanyCardProps {}

const CompanyRegistrationCard = ({ t, gmbh, handleEditField }: GmbHRegistrationDetailsProps) => {
  return (
    <RideSectionCard
      title={t("client:company:registration")}
      data-testid="gmbh-registration-details"
      className="shadow-none mt-5 company-registration-card">
      <RideCardValuePair
        name={t("generic:company-register-court")}
        data-testid="company-register-court"
        onEdit={handleEditField?.("registry-court")}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}>
        {gmbh.registerCourt && <>{formatRegisterCourt(gmbh.registerCourt)}</>}
        {!gmbh.registerCourt && <PendingText t={t} />}
      </RideCardValuePair>

      <RideCardBodySeparator />

      <RideCardValuePair
        name={t("generic:company-registration")}
        data-testid="company-registration"
        onEdit={handleEditField?.("registration")}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}>
        {gmbh.registration && <>{gmbh.registration}</>}
        {!gmbh.registration && <PendingText t={t} />}
      </RideCardValuePair>

      <RideCardBodySeparator />

      <RideCardValuePair
        name={t("generic:company-commercial-register-entry-date")}
        data-testid="company-commercial-register-entry-date"
        onEdit={handleEditField?.("commercial-register-date")}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}
        value={
          gmbh.commercialRegisterEntryDate ? (
            formatDate(gmbh.commercialRegisterEntryDate)
          ) : (
            <PendingText t={t} />
          )
        }
      />
    </RideSectionCard>
  );
};

export default CompanyRegistrationCard;
