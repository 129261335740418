import React from "react";
import { ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { withTranslation, WithTranslation } from "react-i18next";

interface ReferralCodeUsageFilterProps extends WithTranslation {
  selectMonth: Date;
  viewAll: boolean;
  handleReset: () => void;
  enableDatePicker: () => void;
  handleSelectMonth: (date: Date) => void;
}

export const ReferralFilter = ({
  selectMonth,
  viewAll,
  handleReset,
  enableDatePicker,
  handleSelectMonth,
  t
}: ReferralCodeUsageFilterProps) => {
  return (
    <Row className={"pb-45 d-flex"}>
      <Col xs={12} md={4}>
        <label className="pr-3">{t("nominee-dashboard:referral-code.filter-by-month")}</label>
        <span data-testid="select-month" onClick={enableDatePicker}>
          <DatePicker
            selected={selectMonth}
            onChange={(date: Date) => handleSelectMonth(date)}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            className={"text-input mt-n3"}
            name="select-month"
          />
        </span>
      </Col>
      <Col xs={12} md={4}>
        <ButtonGroup toggle>
          <ToggleButton
            type="checkbox"
            data-testid="view-all-discount-code"
            variant="primary"
            checked={viewAll}
            size={"sm"}
            value="1"
            disabled={viewAll}
            onChange={handleReset}>
            {t("nominee-dashboard:referral-code.view-all")}
          </ToggleButton>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(ReferralFilter);
