import React from "react";
import { Field, useField } from "formik";
import { ErrorMessageWithT } from "../../../../components/generic/ErrorMessage";

interface RideDatePickerProps {
  label: string;
  name: string;
  className?: string;
}

export const RideDatePicker = ({ label, name, className }: RideDatePickerProps) => {
  const [, meta] = useField(name);
  let state = "";

  if (meta.value) {
    state = "selected";
  } else if (meta.touched && !!meta.error) {
    state = "error";
  }

  return (
    <div
      data-testid={`${name}-date-container`}
      className={`ride-date-picker-v2 ${className ?? ""}`}>
      <label data-testid={`${name}-label`} className="ride-date-picker-v2__label">
        {label}
      </label>
      <Field
        data-testid={`${name}-input`}
        className={`ride-date-picker-v2__input ${state}`}
        name={name}
        type="date"
      />
      <ErrorMessageWithT name={name} extra={{}} />
    </div>
  );
};
