import React from "react";
import { FeatureFlags, OrderCompanyStatusEnum } from "global-query-types";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { MyLowTaxBrokerOrder_myLowTaxBrokerOrder } from "lib/api/queries/graphql/MyLowTaxBrokerOrder";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import AngelInvestFundCampaignBanner from "client/components/AngelInvestFundCampaignBanner/AngelInvestFundCampaignBanner";
import LTBContinueOrderBanner from "client/components/LTBContinueOrderBanner/LTBContinueOrderBanner";

export interface MyGmbHsBannersProps {
  order: MyLowTaxBrokerOrder_myLowTaxBrokerOrder | null | undefined;
}

export const MyGmbHsBanners = ({ order }: MyGmbHsBannersProps) => {
  const isLtbContinueOrderBannerEnabled = FeatureFlagService.instance.isEnabled(
    FeatureFlags.LTBContinueOrderBanner
  );
  const orderIsInProgress = order?.status === OrderCompanyStatusEnum.InProgress;
  return (
    <>
      {isLtbContinueOrderBannerEnabled && orderIsInProgress ? (
        <LTBContinueOrderBanner companyId={order?.entityId} />
      ) : (
        <FeatureFlag name={FeatureFlags.showAngelInvestFundCampaign}>
          <AngelInvestFundCampaignBanner />
        </FeatureFlag>
      )}
    </>
  );
};
