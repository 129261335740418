import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const InformationIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="information-icon"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12.75 16.5V10.5H9.75V12H11.25V16.5H9V18H15V16.5H12.75Z" fill="#106CC1" />
    <path
      d="M12 6C11.7775 6 11.56 6.06598 11.375 6.1896C11.19 6.31321 11.0458 6.48891 10.9606 6.69448C10.8755 6.90005 10.8532 7.12625 10.8966 7.34448C10.94 7.56271 11.0472 7.76316 11.2045 7.9205C11.3618 8.07783 11.5623 8.18498 11.7805 8.22838C11.9988 8.27179 12.225 8.24951 12.4305 8.16436C12.6361 8.07922 12.8118 7.93502 12.9354 7.75002C13.059 7.56501 13.125 7.3475 13.125 7.125C13.125 6.82663 13.0065 6.54048 12.7955 6.3295C12.5845 6.11853 12.2984 6 12 6Z"
      fill="currentColor"
    />
    <path
      d="M12 22.5C9.9233 22.5 7.89323 21.8842 6.16651 20.7304C4.4398 19.5767 3.09399 17.9368 2.29926 16.0182C1.50454 14.0996 1.29661 11.9884 1.70175 9.95155C2.1069 7.91475 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91475 2.1069 9.95155 1.70175C11.9884 1.29661 14.0996 1.50454 16.0182 2.29926C17.9368 3.09399 19.5767 4.4398 20.7304 6.16651C21.8842 7.89323 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36627 6.91131 3.68508 8.55585C3.0039 10.2004 2.82567 12.01 3.17293 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36627 6.91131 3.68508 8.55585C3.0039 10.2004 2.82567 12.01 3.17293 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89471 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM12.75 10.5V16.5H15V18H9V16.5H11.25V12H9.75V10.5H12.75ZM11.375 6.1896C11.56 6.06598 11.7775 6 12 6C12.2984 6 12.5845 6.11853 12.7955 6.3295C13.0065 6.54048 13.125 6.82663 13.125 7.125C13.125 7.3475 13.059 7.56501 12.9354 7.75002C12.8118 7.93502 12.6361 8.07922 12.4305 8.16436C12.225 8.24951 11.9988 8.27179 11.7805 8.22838C11.5623 8.18498 11.3618 8.07783 11.2045 7.9205C11.0472 7.76316 10.94 7.56271 10.8966 7.34448C10.8532 7.12625 10.8755 6.90005 10.9606 6.69448C11.0458 6.48891 11.19 6.31321 11.375 6.1896ZM12 22.5C9.9233 22.5 7.89323 21.8842 6.16651 20.7304C4.4398 19.5767 3.09399 17.9368 2.29926 16.0182C1.50454 14.0996 1.29661 11.9884 1.70175 9.95155C2.1069 7.91475 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91475 2.1069 9.95155 1.70175C11.9884 1.29661 14.0996 1.50454 16.0182 2.29926C17.9368 3.09399 19.5767 4.4398 20.7304 6.16651C21.8842 7.89323 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5Z"
      fill="currentColor"
    />
  </svg>
);
