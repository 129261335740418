import * as Yup from "yup";
import moment from "moment";

export const ShareholderProofOfAddressSchema = (t) => {
  return Yup.object({
    typeOfDocument: Yup.string().required(t("generic:validation-required")),
    issuingDate: Yup.string()
      .required(t("generic:validation-required"))
      .test(
        "issuingDate",
        t(
          "generic:ride-broker-onboarding.documents.upload-file.validation-errors.proof-of-address-too-old"
        ),
        (value) => moment(value!) > moment().add(-3, "months")
      )
      .test(
        "issuingDate",
        t("generic:validation-not-future-date"),
        (value) => moment(value!) <= moment()
      )
  });
};
