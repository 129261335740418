import React, { useEffect } from "react";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { Container } from "react-bootstrap";
import { getWepaBookings_getWepaBookings_bookings } from "lib/api/queries/graphql/getWepaBookings";
import { StorageConfigStructure } from "components/client/company/components/GetStorageConfig";
import { AdminServiceStatusEnum } from "lib/models/client/GmbH";
import WepaGeneralData from "client/components/WepaSection/WepaGeneralData/WepaGeneralData";
import WepaIsBeingPrepared from "client/components/WepaSection/WepaIsBeingPrepared/WepaIsBeingPrepared";
import WepaUpsellLegacy from "client/components/WepaSection/WepaUpsellLegacy/WepaUpsellLegacy";
import { GetFormerClientAssignments_getFormerClientAssignments_items } from "lib/api/queries/graphql/GetFormerClientAssignments";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags, OrderCompanyStatusEnum } from "global-query-types";
import WepaUpsell from "./WepaUpsell/WepaUpsell";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "lib/api/mutations/graphql/UpsertOrder";
import WepaOnboardingContainer from "./WepaOnboarding/WepaOnboardingContainer";
import { DataLayer } from "lib/services/GoogleTagManager/GoogleTagManager";
import { WepaDirectOnboardingEvents } from "lib/services/GoogleTagManager/WepaDirectOnboardingEvents";

interface WepaSectionProps {
  gmbh: GmbHDetails;
  bookings: getWepaBookings_getWepaBookings_bookings[];
  onConfirmBooking: (bookingId: number) => void;
  wepaStructure: StorageConfigStructure[];
  formerClientAssignments: GetFormerClientAssignments_getFormerClientAssignments_items[];
  upsertOrder: (order: UpsertOrderVariables) => Promise<UpsertOrder_upsertOrder | null | undefined>;
}

const WepaSection = ({
  gmbh,
  bookings,
  onConfirmBooking,
  wepaStructure,
  formerClientAssignments,
  upsertOrder
}: WepaSectionProps) => {
  const [showWepaOnboarding, setShowWepaOnboarding] = React.useState(false);

  const adminServiceStatus = gmbh.adminServiceStatus;

  const hasPendingAdminService: boolean =
    adminServiceStatus === AdminServiceStatusEnum.ORDER_PENDING ||
    adminServiceStatus === AdminServiceStatusEnum.WAITING_FOR_CONFIRMATION;

  const hasActiveAdminService: boolean = gmbh.adminServiceStatus === AdminServiceStatusEnum.ACTIVE;

  const inProgressWepaDirectOrder = gmbh.orders.find(
    (o) => o.productType === "WepaDirect" && o.status === OrderCompanyStatusEnum.InProgress
  );

  useEffect(() => {
    if (inProgressWepaDirectOrder) {
      setShowWepaOnboarding(true);
    }
    // eslint-disable-next-line
  }, [gmbh]);

  const showWepaUpsell =
    !gmbh.isWepaClient && !hasActiveAdminService && !hasPendingAdminService && !showWepaOnboarding;

  const showWepaIsBeingPrepared = !gmbh.isWepaClient && hasPendingAdminService;

  const hasFormerClientAssignments = formerClientAssignments.length > 0;

  const showGeneralData = hasActiveAdminService || gmbh.isWepaClient || hasFormerClientAssignments;

  const wepaDirectEnabled = FeatureFlagService.instance.isEnabled(FeatureFlags.WepaDirect);

  const startWepaOnboarding = () => {
    DataLayer.OrderStarted({
      ordered_products: WepaDirectOnboardingEvents.wd_ordered_products
    });

    setShowWepaOnboarding(true);
  };

  return (
    <Container data-testid="wepa-section" className="mb-5">
      {showWepaUpsell &&
        (wepaDirectEnabled ? (
          <WepaUpsell goToWepaOnboarding={startWepaOnboarding} />
        ) : (
          <WepaUpsellLegacy />
        ))}
      {showWepaOnboarding && <WepaOnboardingContainer gmbh={gmbh} upsertOrder={upsertOrder} />}
      {showWepaIsBeingPrepared && <WepaIsBeingPrepared />}
      {showGeneralData && (
        <WepaGeneralData
          gmbh={gmbh}
          bookings={bookings}
          onConfirmBooking={onConfirmBooking}
          wepaStructure={wepaStructure}
        />
      )}
    </Container>
  );
};

export default WepaSection;
