import gql from "graphql-tag";

export const GET_INVESTMENT_STORAGE_NODES = gql`
  query GetInvestmentStorageNodes($id: ID!) {
    getInvestmentStorageNodes(id: $id) {
      nodeId
      name
      type
      childNodes {
        nodeId
        name
        type
      }
    }
  }
`;
