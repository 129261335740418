import React from "react";
import { WithTranslation } from "react-i18next";
import { Company } from "../../../lib/types/types";
import { formatCompanyName } from "../../../common/formatters/CompanyNameFormatter";
import RideDatePicker from "../../../sharedComponents/RideDatePicker/RideDatePicker";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { FormikProvider, useFormik } from "formik";
import { ClientSelect } from "../ClientSelect/ClientSelect";
import { startOfFiscalYearQuartersOptionsAsISO } from "sharedComponents/FiscalYearQuarterSelect/FiscalYearQuarterSelect";

interface FiscalYearSectionProps extends WithTranslation {
  onSelectDate: (date: string) => void;
  onSave: () => void;
  fiscalYearEnd?: string | null;
  fiscalYearStart?: string | null;
  company?: Company;
}

const FiscalYearSection = ({
  t,
  onSelectDate,
  onSave,
  fiscalYearEnd,
  fiscalYearStart,
  company
}: FiscalYearSectionProps) => {
  const companyName = formatCompanyName(t, company!);

  const formik = useFormik({
    initialValues: {
      fiscalYearStart,
      fiscalYearEnd
    },
    onSubmit() {}
  });

  return (
    <FormikProvider value={formik}>
      <div className="fiscal-year-section">
        <div data-testid="fiscal-year-section-title">
          {t("third-party-tax-advisor:fiscal-years.text", { companyName })}
        </div>
        <div className="mt-4 d-flex">
          <div className="mr-37 fiscal-year-datepicker-container">
            {company?.companyCreation ? (
              <ClientSelect
                name="fiscalYearStart"
                options={startOfFiscalYearQuartersOptionsAsISO}
                label="Von"
                testIdPrefix="fiscalYearStart-select"
                changeHandler={onSelectDate}
              />
            ) : (
              <RideDatePicker
                dateFormat="dd.MM"
                placeholder="TT.MM"
                label="Von"
                name="fiscalYearStart"
                className="fiscal-year-date-picker"
                selectedDate={fiscalYearStart}
                onSelectDate={onSelectDate}
              />
            )}
          </div>
          <RideDatePicker
            dateFormat="dd.MM"
            placeholder="TT.MM"
            label="Bis"
            className="fiscal-year-date-picker"
            name="fiscalYearEnd"
            selectedDate={fiscalYearEnd}
            disabled={true}
          />
        </div>
        <div className="mt-55 d-flex justify-content-center">
          <RideButtonDeprecated
            onClick={onSave}
            variant={ButtonVariant.Primary}
            size={ButtonSize.Big}>
            {t("third-party-tax-advisor:fiscal-years.button")}
          </RideButtonDeprecated>
        </div>
      </div>
    </FormikProvider>
  );
};

export default withTranslationReady("third-party-tax-advisor")(FiscalYearSection);
