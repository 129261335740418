import React, { useState } from "react";
import OperationalActivities from "./OperationalActivities";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../../../components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import { useMutation } from "react-apollo";
import { UPDATE_OPERATIONAL_ACTIVITIES } from "../../../../../components/public/CompanyCreation/Mutations";
import {
  UpdateOperationalActivities,
  UpdateOperationalActivitiesVariables
} from "../../../../../components/public/CompanyCreation/graphql/UpdateOperationalActivities";
import RequestFeedbackHandler from "../../../../../components/generic/RequestFeedbackHandler";
import * as Sentry from "@sentry/react";

interface OperationalActivitiesContainerProps {
  companyCreation: MyCompanyCreationQuery_myCompanyCreation;
  refetch: (id: string) => Promise<void>;
}

export const OperationalActivitiesContainer = ({
  companyCreation,
  refetch
}: OperationalActivitiesContainerProps) => {
  const [updateOperationalActivities, updateOperationalActivitiesRequest] = useMutation<
    UpdateOperationalActivities,
    UpdateOperationalActivitiesVariables
  >(UPDATE_OPERATIONAL_ACTIVITIES);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (hasOperationalActivities, operationalActivities) => {
    try {
      setIsLoading(true);
      await updateOperationalActivities({
        variables: {
          companyCreationId: companyCreation.id,
          hasOperationalActivities,
          operationalActivities
        }
      });

      await refetch(companyCreation.id);
    } catch (error) {
      Sentry.captureException(error);
    }

    setIsLoading(false);
  };

  return (
    <RequestFeedbackHandler
      requests={[updateOperationalActivitiesRequest]}
      forceLoading={isLoading}>
      <OperationalActivities onSubmit={handleSubmit} companyCreation={companyCreation} />
    </RequestFeedbackHandler>
  );
};
