import { withTranslationReady } from "common/i18n/withTranslationReady";
import React, { useState } from "react";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { Trans, WithTranslation } from "react-i18next";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import ThirdPartyTaxAdvisorAddress, {
  ThirdPartyTaxAdvisorAddressData
} from "thirdPartyTaxAdvisor/components/ThirdPartyTaxAdvisorAddress/ThirdPartyTaxAdvisorAddress";
import PaymentAndContractSection from "thirdPartyTaxAdvisor/components/PaymentAndContractSection/PaymentAndContractSection";
import { PaymentMethodType } from "payments-query-types";

const moduleName = "migrate-3pta";

interface ThirdPartyTaxAdvisorMigrateProps extends WithTranslation {
  goToSuccessPage: () => void;
  downloadUnsignedContract: () => void;
  updatePaymentMethod: (paymentMethod: PaymentMethodType) => Promise<void>;
  signContract: () => void;
  saveAddress: (address: ThirdPartyTaxAdvisorAddressData) => Promise<void>;
  existingAddress?: ThirdPartyTaxAdvisorAddressData;
  getRedirectUrlHandler: () => Promise<{ redirectUrl: string } | null | undefined>;
  isFirstBatchMigration?: boolean | null | undefined;
}

const ThirdPartyTaxAdvisorMigrate = ({
  t,
  goToSuccessPage,
  downloadUnsignedContract,
  updatePaymentMethod,
  signContract,
  saveAddress,
  existingAddress,
  getRedirectUrlHandler,
  isFirstBatchMigration = false
}: ThirdPartyTaxAdvisorMigrateProps) => {
  const [step, setStep] = useState(0);

  const saveAddressAndGoToNextStep = async (address: ThirdPartyTaxAdvisorAddressData) => {
    await saveAddress(address);
    setStep(1);
  };

  return (
    <div data-testid={"migrate-3pta"} className={moduleName}>
      <Typography
        data-testid={"title"}
        category={"Headline"}
        size={200}
        weight={"Heavy"}
        className={`${moduleName}__page-title`}>
        {t(`generic:migrate-3pta.title${isFirstBatchMigration ? "" : "-batch-2"}`)}
      </Typography>
      <div className={`${moduleName}__content`}>
        <MigrationDescription isFirstBatchMigration={isFirstBatchMigration} />
        {step === 0 && (
          <ThirdPartyTaxAdvisorAddress
            saveAddress={saveAddressAndGoToNextStep}
            address={existingAddress}
          />
        )}
        {step === 1 && (
          <PaymentAndContractSection
            goToSuccessPage={goToSuccessPage}
            downloadUnsignedContract={downloadUnsignedContract}
            updatePaymentMethod={updatePaymentMethod}
            signContract={signContract}
            getRedirectUrlHandler={getRedirectUrlHandler}
          />
        )}
      </div>
    </div>
  );
};

const MigrationDescription = withTranslationReady(["generic"])(
  ({ t, isFirstBatchMigration }: { isFirstBatchMigration: boolean | null } & WithTranslation) => (
    <Typography data-testid={"description"} category={"Paragraph"} size={100} weight={"Light"}>
      {/*
        first batch = 3PTAs that migrated to Lowtax Steuerberatungs
        second batch = 3PTAs that stayed with the same PTAs
      */}
      <Trans
        i18nKey={`generic:migrate-3pta.description${isFirstBatchMigration ? "" : "-batch-2"}`}
        components={{
          bullet: <div className={`${moduleName}__bullet-point`} />,
          bold: <strong />,
          contactUs: (
            <TextLink
              data-testid={"contact-us"}
              variant={LinkVariant.primary}
              to={t(`generic:contact.email.href`)}
              target={"_blank"}
              rel={"noopener noreferrer"}
            />
          )
        }}
      />
    </Typography>
  )
);

export default withTranslationReady(["generic"])(ThirdPartyTaxAdvisorMigrate);
