import { Container } from "react-bootstrap";
import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { GmbH } from "lib/models/client/GmbH";
import { AdminServiceModalContainer } from "components/client/company/components/AdminServiceModalContainer";
import { useParams } from "react-router";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { useHistory } from "react-router-dom";
import { CompanyTabbedNavigationValue } from "../CompanyTabbedNavigation/CompanyTabbedNavigation";

interface Props extends WithTranslation {
  gmbh: GmbH;
}

const AdminServiceSignUpSection = ({ t, gmbh }: Props) => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const { id: companyId } = useParams<{ id: string }>();
  const shouldGoToAdminServiceTab = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AdminServiceInAppOnboarding
  );

  if (gmbh.hasAdminService) {
    return null;
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const goToAdminServiceView = () => {
    history.push(`/client/entities/${companyId}/${CompanyTabbedNavigationValue.ADMIN_SERVICE}`);
  };

  const handleMoreInfo = () => {
    if (shouldGoToAdminServiceTab) {
      goToAdminServiceView();
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      <AdminServiceModalContainer
        handleModalClose={handleModalClose}
        modalOpen={modalOpen}
        companyId={companyId}
      />

      <Container
        className="admin-service-sign-up-section__container"
        data-testid="admin-service-sign-up-section"
        onClick={() => handleMoreInfo()}>
        <div className="admin-service-sign-up-section bg-success shadow-md rounded-md pl-3 pr-3 pt-md-2 pb-md-2">
          <div data-testid="admin-service-status" className="font-weight-bold">
            {t("client:company.admin-service.status-inactive")}
          </div>
          <div
            data-testid="admin-service-link"
            className="admin-service-sign-up-section__link text-decoration-none text-dark">
            {t("client:company.admin-service.more-info")}
            <div className="admin-service-sign-up-section__cta ride-cta ride-cta--primary" />
          </div>
        </div>
      </Container>
    </>
  );
};

export default withTranslation(["generic", "client"])(AdminServiceSignUpSection);
