import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { CheckboxField } from "../../generic/CheckboxField";

interface IsPartnerCheckboxProps extends WithTranslation {
  formik: FormikProps<any>;
}

const IsPartnerCheckbox = ({ formik, t }: IsPartnerCheckboxProps) => {
  const state = formik.values["isPartner"];

  return (
    <CheckboxField
      name="isPartner"
      label={t("nominee-dashboard:person:partner-status")}
      state={state}
      handleClick={formik.handleChange}
    />
  );
};

export default withTranslation()(IsPartnerCheckbox);
