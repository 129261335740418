import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";

export interface ReviewShareDistributionProps {
  shareDistribution: Shareholder[];
  editLink?: string;
  isCompleted?: boolean;
}

interface Shareholder {
  name: string;
  shares: number;
}

export const ReviewShareDistribution = withTranslationReady(["generic"])(
  ({
    t,
    shareDistribution,
    editLink,
    isCompleted
  }: ReviewShareDistributionProps & WithTranslation) => {
    return (
      <ReviewCard dataTestId="shares-distribution">
        <ReviewSectionTitle
          title={t("generic:ride-broker-onboarding.review.shareholders.share-distribution")}
          editLink={editLink}
          isCompleted={isCompleted}
        />
        <div className="review-share-distribution__list">
          {shareDistribution.map((shareholder) => (
            <ReviewShareDistributionEntry {...shareholder} key={shareholder.name} />
          ))}
        </div>
      </ReviewCard>
    );
  }
);

const ReviewShareDistributionEntry = ({ name, shares }: Shareholder) => (
  <div data-testid="review-share-distribution-entry" className="review-share-distribution__entry">
    <span>{name}</span>
    <span>{shares}%</span>
  </div>
);
