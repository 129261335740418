import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { ReviewCard } from "../../LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewSectionTitle } from "../../LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { ReviewFieldList } from "../../LowTaxBrokerAccount/sharedComponents/ReviewFieldList/ReviewFieldList";
import { ReviewField } from "../../LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { OrderData, StepComponentProps, StepHeading } from "../../ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import { useHistory } from "react-router-dom";
import { MissingInformationWarning } from "../../LowTaxBrokerAccount/chapters/ReviewAndSubmitLowTaxBroker/ReviewAndSubmitLowTaxBroker.partials";
import { AdminServiceOrderData } from "lib/models/client/AdminService/AdminServiceOrderData";
import { ReviewDocumentEntry } from "../../LowTaxBrokerAccount/chapters/UploadDocuments/ReviewDocuments/ReviewDocuments.partials";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

const ReviewAndSubmitAdminServiceForm = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps<AdminServiceOrderData> & WithTranslation) => {
  const { push } = useHistory();

  const chaptersKeys = Object.keys(order?.extra?.chapters).filter((c) => c !== "review-and-submit");
  const chapters = chaptersKeys.map((key) => currentStep.chapter.order.getChapter(key));
  const hasIncompleteChapters = chapters.filter((chapter) => !chapter?.completed).length > 0;

  const onSubmit = async () => {
    if (hasIncompleteChapters) {
      return;
    }

    currentStep.complete();

    await saveData(
      {
        ...currentStep.chapter.order.serialize()
      },
      true,
      false
    );

    push(`/client/entities/${order.entityId}/admin-service`);
  };

  const formik = useRideFormik({
    initialValues: {},
    onSubmit: onSubmit
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          dataTestId="review-and-submit-admin-service-order-title"
          text={t("generic:admin-service-form.review.title")}
        />
        {hasIncompleteChapters && <MissingInformationWarning />}
        <div className={"review-and-submit-admin-service-entries"}>
          <ReviewAdminServiceOrderDocuments
            order={order}
            isCompleted={!!currentStep.chapter.order.getChapter("documents")?.completed}
          />
          <ReviewFiscalYear order={order} />
          <ReviewShareholders order={order} />
          <ReviewTaxAdvisor order={order} />
        </div>
        {hasIncompleteChapters && <MissingInformationWarning />}
        {{ ...controls, props: { ...controls.props, disabled: hasIncompleteChapters } }}
      </Form>
    </FormikProvider>
  );
};

const ReviewTaxAdvisor = withTranslationReady(["generic"])(
  ({ t, order }: { order: OrderData<AdminServiceOrderData> } & WithTranslation) => {
    const dataTestId = "review-tax-advisor";

    const taxAdvisor = order.extra.taxAdvisor;

    if (!taxAdvisor) {
      return null;
    }

    return (
      <ReviewCard dataTestId={dataTestId}>
        <ReviewSectionTitle
          dataTestId={`${dataTestId}-title`}
          title={t("generic:admin-service-form.chapters.tax-advisor")}
          editLink={`/client/order/generic/tax-advisor/0/${order.id}/${order.submissionId}`}
          isCompleted={
            !!(taxAdvisor && taxAdvisor.fullName && taxAdvisor.email && taxAdvisor.phoneNumber)
          }
        />
        <ReviewFieldList>
          <ReviewField
            data-testid={"tax-advisor-name"}
            label={t("generic:name")}
            value={taxAdvisor?.fullName}
          />
          <ReviewField
            data-testid={"tax-advisor-email"}
            label={t("generic:email")}
            value={taxAdvisor?.email}
          />
          <ReviewField
            data-testid={"tax-advisor-phone-number"}
            label={t("generic:phone-number")}
            value={taxAdvisor?.phoneNumber}
          />
        </ReviewFieldList>
      </ReviewCard>
    );
  }
);

const ReviewFiscalYear = withTranslationReady(["generic"])(
  ({ t, order }: { order: OrderData<AdminServiceOrderData> } & WithTranslation) => {
    return (
      <ReviewCard dataTestId={"review-fiscal-year"}>
        <ReviewSectionTitle
          dataTestId={"review-fiscal-year-title"}
          title={t("generic:admin-service-form.chapters.fiscal-year")}
          editLink={`/client/order/generic/fiscal-year/0/${order.id}/${order.submissionId}`}
        />
        <ReviewFieldList>
          <ReviewField
            data-testid={"fiscal-year-start"}
            label={t("generic:admin-service-form.fiscal-year-start.title")}
            value={order.extra.companyData?.fiscalYearStart}
          />
        </ReviewFieldList>
      </ReviewCard>
    );
  }
);

const ReviewShareholders = withTranslationReady(["generic"])(
  ({ t, order }: { order: OrderData<AdminServiceOrderData> } & WithTranslation) => {
    return (
      <ReviewCard dataTestId={"review-shareholders"}>
        <ReviewSectionTitle
          dataTestId={"review-shareholders-title"}
          title={t("generic:company-setup.chapters.shareholder-details")}
          editLink={`/client/order/generic/shareholder-details/0/${order.id}/${order.submissionId}`}
        />
        {Object.values(order.extra?.shareholders ?? {}).map((shareholder, index) => {
          return (
            <React.Fragment key={shareholder.personId}>
              {index > 0 && <hr />}
              <div className={"mb-2"}>
                <Typography
                  data-testid={`shareholder-${index}-name`}
                  category={"Headline"}
                  size={400}
                  weight={"Heavy"}>
                  {shareholder.name}
                </Typography>
              </div>
              <ReviewFieldList>
                <ReviewField
                  data-testid={`shareholder-${index}-pep`}
                  label={t("generic:pep")}
                  value={shareholder.pep === "yes" ? t("generic:yes") : t("generic:no")}
                />
                {shareholder.pep === "yes" && (
                  <ReviewField
                    data-testid={`shareholder-${index}-pep-context`}
                    label={t("generic:pep-context")}
                    value={shareholder.pepContext}
                  />
                )}
                <ReviewField
                  data-testid={`shareholder-${index}-source-of-wealth`}
                  label={t("generic:admin-service-form.shareholders-context.source-of-wealth")}
                  value={shareholder.sourceOfWealth}
                />
              </ReviewFieldList>
            </React.Fragment>
          );
        })}
      </ReviewCard>
    );
  }
);

interface ReviewAdminServiceOrderDocumentsProps {
  order: OrderData<AdminServiceOrderData>;
  isCompleted: boolean;
}

const ReviewAdminServiceOrderDocuments = withTranslationReady(["generic"])(
  ({ t, order, isCompleted }: ReviewAdminServiceOrderDocumentsProps & WithTranslation) => {
    return (
      <ReviewCard dataTestId={"review-documents-section"}>
        <ReviewSectionTitle
          dataTestId={"review-documents-section-title"}
          title={t("generic:documents")}
          editLink={`/client/order/generic/documents/0/${order.id}/${order.submissionId}`}
          isCompleted={isCompleted}
        />
        <div className="review-documents" data-testid="review-personal-documents">
          <div className="review-documents__list">
            <ReviewDocumentEntry
              name={t(
                "generic:ride-broker-onboarding.documents.personal-documents-list.id-or-passport"
              )}
              uploaded={
                !!(
                  order.extra.documents?.passportOrID?.id &&
                  (order.extra.documents?.passportOrID?.typeOfDocument === "ID"
                    ? !!order.extra.documents?.passportOrID?.back?.id
                    : true)
                )
              }
            />
            <ReviewDocumentEntry
              name={t("generic:admin-service-form.documents-step.last-yearly-report")}
              uploaded={!!order.extra.documents?.lastYearlyReport?.id}
            />
          </div>
        </div>
      </ReviewCard>
    );
  }
);

export default withTranslationReady(["generic"])(ReviewAndSubmitAdminServiceForm);
