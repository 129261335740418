import React, { useState } from "react";
import { RideNavbar } from "sharedComponents/RideNavbar/RideNavbar";
import { withTranslation, WithTranslation } from "react-i18next";
import { MenuLink } from "sharedComponents/MenuLink/MenuLink";
import { RideSidebar } from "sharedComponents/RideSidebar/RideSidebar";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import NavigationService from "lib/services/NavigationService/NavigationService";
import { FeatureFlags } from "global-query-types";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { NavDropdown } from "react-bootstrap";
import { AddGmbHModalContainer } from "../AddGmbHModal/AddGmbHModalContainer";
import CompanyDropdownContainer from "../CompanyDropdown/CompanyDropdownContainer";

export const ClientUrls = {
  MY_GMBHS: "/client/entities",
  PENDING_TASKS: "/client/pending-tasks",
  INVESTMENTS: "/client/investments",
  KNOWLEDGE: "/client/knowledge",
  PROFILE: "/client/my-profile",
  BANKING: "/client/banking",
  LOW_TAX_BROKER: "/client/low-tax-broker"
};

const ClientHeader = ({ t }: WithTranslation) => {
  const [showAddGmbHModal, setShowAddGmbHModal] = useState(false);

  const menu = [
    <FeatureFlag key="company-dropdown" name={FeatureFlags.ReplaceCompanyListByCompanyDropdown}>
      {showAddGmbHModal && (
        <AddGmbHModalContainer
          onClose={() => {
            setShowAddGmbHModal(false);
          }}
        />
      )}
      <NavDropdown
        id="my-gmbhs"
        key="my-gmbhs"
        data-testid="my-gmbhs-dropdown-nav-item"
        title={t("client.my-gmbhs")}
        alignRight
        className="ride-navbar-dropdown">
        <NavDropdown.Item>
          <CompanyDropdownContainer
            openTheAddCompanyModal={() => {
              setShowAddGmbHModal(true);
            }}
          />
        </NavDropdown.Item>
      </NavDropdown>
    </FeatureFlag>,
    <DisabledFeatureFlag
      key="client-entities"
      name={FeatureFlags.ReplaceCompanyListByCompanyDropdown}>
      <MenuLink eventKey={ClientUrls.MY_GMBHS} path={ClientUrls.MY_GMBHS}>
        {t("client.my-gmbhs")}
      </MenuLink>
    </DisabledFeatureFlag>,
    // TODO: Remove this feature flag
    <FeatureFlag key="pending-actions" name={FeatureFlags.PendingActions}>
      <MenuLink eventKey={ClientUrls.PENDING_TASKS} path={ClientUrls.PENDING_TASKS}>
        {t("client.pending-tasks")}
      </MenuLink>
    </FeatureFlag>,
    <DisabledFeatureFlag key="banking" name={FeatureFlags.BankingPageInCompanyNavigation}>
      <FeatureFlag name={FeatureFlags.BankAccountsList}>
        <MenuLink eventKey={ClientUrls.BANKING} path={ClientUrls.BANKING}>
          {t("client.banking")}
        </MenuLink>
      </FeatureFlag>
    </DisabledFeatureFlag>,
    <FeatureFlag key="investments" name={FeatureFlags.ClientInvestments}>
      <MenuLink eventKey={ClientUrls.INVESTMENTS} path={ClientUrls.INVESTMENTS}>
        {t("client.investments")}
      </MenuLink>
    </FeatureFlag>,
    <MenuLink key="knowledge" eventKey={ClientUrls.KNOWLEDGE} path={ClientUrls.KNOWLEDGE}>
      {t("client.knowledge")}
    </MenuLink>,
    <MenuLink key="my-profile" eventKey={ClientUrls.PROFILE} path={ClientUrls.PROFILE}>
      {t("client.my-profile")}
    </MenuLink>,
    <MenuLink key="logout" path="/logout">
      {t("logout")}
    </MenuLink>
  ];

  NavigationService.instance = new NavigationService(ClientUrls);

  return (
    <div data-testid="client-header">
      <RideNavbar sidebar={<RideSidebar menu={menu} activeKey="client-entities" />}>
        {menu}
      </RideNavbar>
    </div>
  );
};

export default withTranslation(["header", "client"])(ClientHeader);
