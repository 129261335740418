import React from "react";
import { CheckIcon } from "sharedComponents/icons/DesignSystemIcons/CheckIcon";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

type StepProgressBarMobileLayout = "vertical" | "horizontal";

export interface StepProgressBarProps {
  steps: string[];
  currentStepIndex: number;
  mobileLayout?: StepProgressBarMobileLayout;
}

const moduleName = "step-progress-bar";

const getLabelStateClassName = (index: number, currentStepIndex: number) =>
  `${moduleName}__label ${index > currentStepIndex ? moduleName + "__label--disabled" : ""}`;

const getLabelWeight = (index: number, currentStepIndex: number): "Heavy" | "Light" =>
  `${index === currentStepIndex ? "Heavy" : "Light"}`;

export const StepProgressBar = ({
  steps,
  currentStepIndex,
  mobileLayout
}: StepProgressBarProps) => {
  return (
    <div
      data-testid={moduleName}
      className={`${moduleName} ${moduleName}--${mobileLayout ?? "horizontal"}`}>
      {steps.map((step, index) => (
        <React.Fragment key={`step-progress-bar-item-${index}`}>
          {index > 0 && (
            <div
              key={`${moduleName}__bar-inbetween-${index}`}
              data-testid={`bar-inbetween-${index}`}
              className={`${moduleName}__bar-inbetween ${
                index <= currentStepIndex ? `${moduleName}__bar-inbetween--complete` : ""
              }`}></div>
          )}
          <div
            data-testid={`checklist-item-${index}`}
            key={`checklist-item-${index}`}
            className={`${moduleName}__item`}>
            <div
              data-testid={`checklist-item-${index}-bullet`}
              className={`${moduleName}__bullet ${
                index <= currentStepIndex ? moduleName + "__bullet--enabled" : ""
              }`}>
              {index < currentStepIndex ? <CheckIcon size={20} /> : index + 1}
            </div>
            <Typography
              data-testid={`checklist-item-${index}-label`}
              category="Paragraph"
              size={200}
              weight={getLabelWeight(index, currentStepIndex)}
              className={getLabelStateClassName(index, currentStepIndex)}>
              {step}
            </Typography>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
