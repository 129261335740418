import gql from "graphql-tag";

export const ALL_COMPANIES_WITH_CONNECTED_BROKERAGE_ACCOUNT = gql`
  query AllCompaniesWithConnectedBrokerageAccount(
    $type: CompanyType
    $limit: Int
    $start: Int
    $query: String
  ) {
    allCompaniesWithConnectedBrokerageAccount(
      type: $type
      limit: $limit
      start: $start
      query: $query
    ) {
      count
      companies {
        id
        name
        legalForm
        contactPerson {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
