import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { Trans, WithTranslation } from "react-i18next";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { useHistory } from "react-router-dom";

interface WepaDirectBanner {
  companyId: string;
}

const WepaDirectBanner = ({ t, companyId }: WepaDirectBanner & WithTranslation) => {
  const history = useHistory();

  return (
    <div data-testid="wepa-direct-banner" className={"wepa-direct-banner"}>
      <Typography category={"Paragraph"} size={100} weight={"Heavy"} data-testid={"title"}>
        {t("generic:wepa-direct.banner.title")}
      </Typography>
      <Typography category={"Paragraph"} size={200} weight={"Light"} data-testid={"description"}>
        <Trans i18nKey={"generic:wepa-direct.banner.description"} />
      </Typography>
      <RideButton
        className={"wepa-direct-banner__cta"}
        size={RideButtonSize.SMALL}
        variant={RideButtonVariant.GHOST}
        data-testid={"cta"}
        label={t("generic:wepa-direct.banner.cta-label")}
        onClick={() => history.push(`/client/entities/${companyId}/securities-booking`)}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(WepaDirectBanner);
