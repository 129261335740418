import { TFunction } from "i18next";
import { useMutation } from "@apollo/react-hooks";
import React, { useCallback, useEffect, useState } from "react";
import { GetTaxAdvisorCompaniesMinimal_taxAdvisorCompanies } from "../../../lib/api/queries/graphql/GetTaxAdvisorCompaniesMinimal";
import { getEndpoint } from "../../../common/GraphqlClient/httpLink";
import { getToken } from "../../../common/Authentication/getToken";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { ApolloQueryResult } from "apollo-client";
import {
  CreateAdminServiceFlow,
  CreateAdminServiceFlowVariables
} from "../../../components/nominee/NomineeCompany/graphql/CreateAdminServiceFlow";
import {
  ASSIGN_TAX_ADVISOR_STORAGE_PERMISSIONS,
  ASSIGN_TAX_SERVICE_TO_COMPANY,
  CREATE_ADMIN_SERVICE_FLOW,
  NOMINEE_CREATE_ADMIN_SERVICE_STORAGE_STRUCTURE,
  NOMINEE_UPDATE_TAX_SERVICE
} from "../../../components/nominee/NomineeCompany/Mutations";
import logger from "../../../common/Logger";
import {
  NomineeCreateAdminServiceStorageStructure,
  NomineeCreateAdminServiceStorageStructureVariables
} from "../../../components/nominee/NomineeCompany/graphql/NomineeCreateAdminServiceStorageStructure";
import {
  NomineeUpdateTaxService,
  NomineeUpdateTaxServiceVariables
} from "../../../components/nominee/NomineeCompany/graphql/NomineeUpdateTaxService";
import { TaxAdvisorStatusEnum } from "../../../global-query-types";
import {
  AssignTaxServiceToCompany,
  AssignTaxServiceToCompanyVariables
} from "../../../components/nominee/NomineeCompany/graphql/AssignTaxServiceToCompany";
import {
  AssignTaxAdvisorStoragePermissions,
  AssignTaxAdvisorStoragePermissionsVariables
} from "../../../components/nominee/NomineeCompany/graphql/AssignTaxAdvisorStoragePermissions";
import {
  ResetStoragePermissions,
  ResetStoragePermissionsVariables
} from "../../../lib/api/mutations/graphql/ResetStoragePermissions";
import { RESET_STORAGE_PERMISSIONS } from "../../../lib/api/mutations/RESET_STORAGE_PERMISSIONS";
import {
  UpdateCompanyCreation,
  UpdateCompanyCreationVariables
} from "../../../lib/api/mutations/graphql/UpdateCompanyCreation";
import { UPDATE_COMPANY_CREATION } from "../../../lib/api/mutations/UPDATE_COMPANY_CREATION";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { AdminServiceSection } from "./AdminServiceSection";
import { Col, Row } from "react-bootstrap";

const useTaxServiceCompaniesMinimal = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetTaxAdvisorCompaniesMinimal_taxAdvisorCompanies[]>([]);

  const fetchTaxAdvisorsMinimal = useCallback(async () => {
    const response = await fetch(`${getEndpoint()}/api/tax-advisor/company/minimal`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      mode: "cors",
      credentials: "include"
    });

    let taxAdvisorCompanies =
      (await response.json()) as GetTaxAdvisorCompaniesMinimal_taxAdvisorCompanies[];
    setData(taxAdvisorCompanies);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchTaxAdvisorsMinimal().then(() => setLoading(false));
  }, [fetchTaxAdvisorsMinimal]);

  return { data, loading };
};

export const AdminServiceSectionContainer = ({
  t,
  company,
  refetchCompany
}: {
  t: TFunction;
  company: NomineeCompanyDetails_companyById;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}) => {
  const [createAdminServiceFlowMutation, createFlowRequest] = useMutation<
    CreateAdminServiceFlow,
    CreateAdminServiceFlowVariables
  >(CREATE_ADMIN_SERVICE_FLOW);

  const { data } = useTaxServiceCompaniesMinimal();

  const createAdminServiceFlow = async (companyId: string) => {
    try {
      await createAdminServiceFlowMutation({
        variables: { companyId }
      });
    } catch (error) {
      logger.errorMessage(`Unable to create admin-service addon`);
      logger.error(error);
    }
  };

  const clientCompanyId = company?.id;
  const taxAdvisorCompaniesList = data ?? [];

  const [createAdminServiceFolderStructureMutation, createFoldersRequest] = useMutation<
    NomineeCreateAdminServiceStorageStructure,
    NomineeCreateAdminServiceStorageStructureVariables
  >(NOMINEE_CREATE_ADMIN_SERVICE_STORAGE_STRUCTURE);

  const createAdminServiceFolderStructure = async (companyId: string) => {
    try {
      await createAdminServiceFolderStructureMutation({
        variables: { id: companyId }
      });
    } catch (error) {
      logger.errorMessage(`Unable to create admin service folder structure`);
      logger.error(error);
    }
  };

  const [nomineeUpdateTaxServiceMutation, nomineeUpdateTaxServiceRequest] = useMutation<
    NomineeUpdateTaxService,
    NomineeUpdateTaxServiceVariables
  >(NOMINEE_UPDATE_TAX_SERVICE);

  const activateTaxService = async (taxServiceId: string): Promise<boolean> => {
    try {
      await nomineeUpdateTaxServiceMutation({
        variables: { taxServiceId, advisorStatus: TaxAdvisorStatusEnum.ACTIVE }
      });

      return true;
    } catch (error) {
      logger.errorMessage(`Unable to activate tax service`);
      logger.error(error);
      return false;
    }
  };

  const updateTaxServiceActivatedAt = async (
    taxServiceId: string,
    activatedAt: string
  ): Promise<void> => {
    try {
      await nomineeUpdateTaxServiceMutation({
        variables: { taxServiceId, activatedAt }
      });
      await refetchCompany();
    } catch (error) {
      logger.errorMessage(`Unable to update taxService's activatedAt`);
      logger.error(error);
    }
  };

  const [assignTaxServiceToCompany, assignTaxServiceRequest] = useMutation<
    AssignTaxServiceToCompany,
    AssignTaxServiceToCompanyVariables
  >(ASSIGN_TAX_SERVICE_TO_COMPANY);

  const handleAssignTaxServiceCompanyChange = async (taxServiceCompanyId: string) => {
    try {
      await assignTaxServiceToCompany({
        variables: { clientCompanyId, taxServiceCompanyId }
      });
    } catch (error) {
      logger.errorMessage(`Unable to assign tax service company entity`);
      logger.error(error);
    }
  };

  const [assignTaxAdvisorStoragePermissions, assignTaxAdvisorStoragePermissionsRequest] =
    useMutation<AssignTaxAdvisorStoragePermissions, AssignTaxAdvisorStoragePermissionsVariables>(
      ASSIGN_TAX_ADVISOR_STORAGE_PERMISSIONS
    );

  const handleAssignTaxAdvisorStoragePermissions = async () => {
    try {
      await assignTaxAdvisorStoragePermissions({
        variables: { clientCompanyId }
      });
    } catch (error) {
      logger.errorMessage(`Unable to assign tax advisor storage permissions`);
      logger.error(error);
    }
  };

  const [resetStoragePermissionsCall, resetStoragePermissionsRequest] = useMutation<
    ResetStoragePermissions,
    ResetStoragePermissionsVariables
  >(RESET_STORAGE_PERMISSIONS);

  const resetStoragePermissions = async () => {
    try {
      await resetStoragePermissionsCall({
        variables: { clientCompanyId }
      });
    } catch (error) {
      logger.errorMessage(`Unable to reset storage permissions`);
      logger.error(error);
    }
  };

  const [nomineeUpdateAdminServiceMutation, nomineeUpdateAdminServiceRequest] = useMutation<
    UpdateCompanyCreation,
    UpdateCompanyCreationVariables
  >(UPDATE_COMPANY_CREATION);

  const updateAdminService = async (data: UpdateCompanyCreationVariables) => {
    try {
      await nomineeUpdateAdminServiceMutation({
        variables: data
      });
    } catch (error) {
      logger.errorMessage(`Unable to update admin service`);
      logger.error(error);
    }
  };

  return (
    <>
      <AdminServiceSectionTitle t={t} />
      <RequestFeedbackHandler
        requests={[
          createFlowRequest,
          createFoldersRequest,
          assignTaxServiceRequest,
          nomineeUpdateTaxServiceRequest,
          assignTaxAdvisorStoragePermissionsRequest,
          nomineeUpdateAdminServiceRequest,
          resetStoragePermissionsRequest
        ]}>
        <AdminServiceSection
          t={t}
          company={company}
          createAdminServiceFlow={createAdminServiceFlow}
          createAdminServiceFolderStructure={createAdminServiceFolderStructure}
          activateTaxService={activateTaxService}
          refetchCompany={refetchCompany}
          taxAdvisorCompaniesList={taxAdvisorCompaniesList}
          handleAssignTaxServiceCompanyChange={handleAssignTaxServiceCompanyChange}
          handleAssignTaxAdvisorStoragePermissions={handleAssignTaxAdvisorStoragePermissions}
          updateTaxServiceActivatedAt={updateTaxServiceActivatedAt}
          updateAdminService={updateAdminService}
          resetStoragePermissions={resetStoragePermissions}
        />
      </RequestFeedbackHandler>
    </>
  );
};

const AdminServiceSectionTitle = ({ t }: { t: TFunction }) => (
  <Row>
    <Col>
      <h2>{t("nominee-dashboard:company.admin-service")}</h2>
    </Col>
  </Row>
);
