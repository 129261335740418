import { countries, Country } from "countries-list";
import { defaultCountry, defaultCountryCode } from "../defaultCountry";

export const formatCompanyAddress = ({
  t,
  name,
  careOf,
  street,
  postalCode,
  city,
  countryCode
}) => {
  const country: Country | undefined = countries[countryCode ?? defaultCountryCode];
  const countryName = country?.native ?? defaultCountry;
  const careOfMaybeComa = name ? ", " : "";

  return `${name}${
    careOf ? `${careOfMaybeComa}${t("generic:c/o")} ${careOf}` : ""
  }, ${street}, ${postalCode} ${city}, ${countryName}`;
};
