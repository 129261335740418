import localStore from "../LocalStore";
import { KEY_TOKEN, KEY_TOKEN_REMEMBER } from "./constants";

export const getToken = () => {
  const remember = ["true", true].indexOf(localStore.getValue(KEY_TOKEN_REMEMBER)) !== -1;

  if (remember) {
    return localStore.getExpiringValue(KEY_TOKEN) as string;
  }

  return localStore.getSessionValue(KEY_TOKEN) as string;
};
