import React, { useState } from "react";
import { GetProcessTasks } from "../../../lib/api/queries/GetProcessTasks";
import { RidePagination } from "../../../uiLibrary/designSystem/RidePagination/RidePagination";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import RideSearch from "../../../sharedComponents/RideSearch/RideSearch";
import FoundationServiceTasksFilters, {
  FoundationServiceTasksTableFilters
} from "../FoundationServiceTasksFilters/FoundationServiceTasksFilters";
import { FoundationServiceTasks } from "./FoundationServiceTasks";

interface FoundationServiceTasksContainerProps {
  searchPlaceholder: string;
}

export const FoundationServiceTasksContainer = ({
  searchPlaceholder
}: FoundationServiceTasksContainerProps) => {
  const pageSize = 30;
  const [start, setStart] = useState(0);
  const [query, setQuery] = useState("");
  const [sorting, setSorting] = useState({
    sortColumn: "",
    sortOrder: ""
  });
  const initialFilters: FoundationServiceTasksTableFilters = {};
  const [filters, setFilters] = React.useState<FoundationServiceTasksTableFilters>(initialFilters);

  const request = GetProcessTasks({
    start,
    limit: pageSize,
    companyName: query?.length ? query : undefined,
    ...filters,
    ...sorting
  });

  return (
    <RideSearch
      placeholder={searchPlaceholder}
      query={query}
      onQueryChange={(query) => {
        setStart(0);
        setQuery(query);
      }}>
      <RidePagination
        count={request.data?.getProcessTasks?.count ?? 0}
        start={start}
        pageSize={pageSize}
        setStart={setStart}>
        <FoundationServiceTasksFilters
          onFilter={async (filters) => {
            setStart(0);
            setFilters(filters);
          }}
        />
        <RequestFeedbackHandler requests={[request]}>
          <FoundationServiceTasks
            tasks={request.data?.getProcessTasks?.processTasks ?? []}
            sortCallback={setSorting}
          />
        </RequestFeedbackHandler>
      </RidePagination>
    </RideSearch>
  );
};
