import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { Image } from "react-bootstrap";
import { WithTranslation } from "react-i18next";

export interface LostPasswordSuccessProps extends WithTranslation {
  email: string;
}

const LostPasswordSuccess = ({ email, t }: LostPasswordSuccessProps) => {
  return (
    <div data-testid="lost-password-success" className="lost-password-success">
      <Image src="/assets/sharedComponents/email-icon.svg" alt="email icon" />
      <div className="lost-password-success__success-message" data-testid="success-message">
        {t("auth:lost-password.successMessage", { email })}
      </div>
      <div className="lost-password-success__success-note" data-testid="sucess-notes">
        {t("auth:lost-password.successNote")}
      </div>
    </div>
  );
};

export default withTranslationReady(["auth"])(LostPasswordSuccess);
