import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { formatDate } from "../../../../common/formatters";
import TextLinkAsButton from "../../../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";

interface ProfilePasswordCardProps extends WithTranslation {
  lastUpdate: string | null;
  onEdit?: () => void;
  "data-testid"?: string;
}

const ProfilePasswordCard = ({
  lastUpdate,
  onEdit,
  "data-testid": dataTestId,
  t
}: ProfilePasswordCardProps) => {
  return (
    <RideSectionCard
      data-testid={dataTestId}
      title={t("client:profile.password.title")}
      fixed={true}>
      <div className="profile-password-card__text">
        {t(lastUpdate ? "profile.password.changed" : "profile.password.unchanged", {
          date: formatDate(lastUpdate)
        })}
        <div className="profile-password-card__edit">
          <TextLinkAsButton onClick={onEdit} data-testid="profile-change-password-edit" />
        </div>
      </div>
    </RideSectionCard>
  );
};

export default withTranslation(["client"])(ProfilePasswordCard);
