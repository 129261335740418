import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { PageLayout } from "sharedComponents/templates/PageLayout/PageLayout";
import { ThirdPartyTaxAdvisorCustomersListContainer } from "admin/components/ThirdPartyTaxAdvisorCustomersList/ThirdPartyTaxAdvisorCustomersListContainer";

const ThirdPartyTaxAdvisorsCustomersScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("header:tax-advisor-third-party-customers")}
      mainContent={<ThirdPartyTaxAdvisorCustomersListContainer />}
    />
  );
};

export default withTranslation(["header"])(ThirdPartyTaxAdvisorsCustomersScene);
