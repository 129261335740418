import React from "react";
import { Field, useField } from "formik";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";

interface RidePercentageInputProps {
  name: string;
  placeholder?: string;
  className?: string;
  showErrors?: boolean;
}

const RidePercentageInput = ({
  name,
  placeholder,
  className,
  showErrors = false
}: RidePercentageInputProps) => {
  const [input, meta] = useField(name);
  let { value } = input;
  const isEmpty = value === "" || value === 0;

  const handleChange = (e) => {
    e.target.value = Math.abs(+e.target.value);
  };

  return (
    <div className={"ride-percentage-input"}>
      <Field
        type={"number"}
        data-testid={`${name}-input`}
        name={name}
        placeholder={placeholder}
        min={0}
        max={100}
        step="0.01"
        onKeyUp={handleChange}
        className={`${className ? className : ""} ride-percentage-input__input ${
          isEmpty && meta.touched ? "ride-percentage-input__input--error" : ""
        }`}
      />
      <span className={"ride-percentage-input__percent"}>%</span>
      {showErrors ? <ErrorMessageWithT name={name} extra={{}} /> : null}
    </div>
  );
};

export default RidePercentageInput;
