import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import React, { useState } from "react";
import { RideCheckbox } from "../../../../sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import FolderSelectField, { FolderSelectOption } from "../FolderSelectField/FolderSelectField";
import {
  ButtonSize,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { fixedOptionsForAdminService } from "../UploadModal/fixedOptionsForAdminService";

interface FileMultiSelectionHeaderProps extends WithTranslation {
  selectedCount: number;
  total: number;
  options: FolderSelectOption[];
  onChooseOption: (option?: FolderSelectOption) => void;
  onSelectAll?: () => void;
  onSelectNone?: () => void;
  "data-testid"?: string;
}

const FileMultiSelectionHeader = ({
  selectedCount,
  total,
  options,
  onSelectNone,
  onSelectAll,
  onChooseOption,
  t,
  "data-testid": testId
}: FileMultiSelectionHeaderProps) => {
  const isIndeterminate = selectedCount > 0 && selectedCount < total;
  const checked = selectedCount > 0;
  const [selectedFolder, setSelectedFolder] = useState<FolderSelectOption | undefined>();

  const onCheckboxChange = () => {
    if (isIndeterminate || selectedCount === 0) {
      return onSelectAll?.();
    }

    if (!isIndeterminate) {
      return onSelectNone?.();
    }
  };

  const handleSubmit = () => {
    onChooseOption(selectedFolder);
  };

  return (
    <div className="file-multi-selection-header" data-testid={testId}>
      <div className="file-multi-selection-header__container">
        <div className="file-multi-selection-header__checkbox-section">
          <RideCheckbox
            label={
              <span
                className="file-multi-selection-header__checkbox-label"
                data-testid="selection-label">{`${selectedCount} ${t(
                "client:documents.count-label"
              )}`}</span>
            }
            isIndeterminate={isIndeterminate}
            checked={checked}
            onChange={onCheckboxChange}
            name={"selection"}
          />
        </div>

        <div
          data-testid="folderSelect-select-container"
          className={`file-multi-selection-header__select_section`}>
          <FolderSelectField
            name={`folderSelect`}
            placeholder={t("client:documents.modal.placeholder")}
            options={options}
            onChange={setSelectedFolder}
            fixedOptions={fixedOptionsForAdminService}
          />
        </div>

        <div className={`file-multi-selection-header__submit-section`}>
          <RideButtonDeprecated
            size={ButtonSize.Medium}
            data-testid="folder-selection-submit"
            onClick={handleSubmit}>
            {t("client:documents.label-choose-multiple")}
          </RideButtonDeprecated>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady(["client", "generic"])(FileMultiSelectionHeader);
