import * as Yup from "yup";
import { DocumentType } from "../../global-query-types";
import { cityRegex, postalCode, taxIdTINOptional } from "./common";
import { EmailValidationNonRequired } from "./EmailSchema";

export const PersonDetailsValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    dateOfBirth: Yup.date().transform((value) =>
      typeof value === "string" ? new Date(value) : value
    ),
    street: Yup.string(),
    documentNumber: Yup.string(),
    documentType: Yup.mixed<DocumentType>().oneOf(Object.values(DocumentType)),
    issuingAuthority: Yup.string(),
    city: Yup.string().matches(cityRegex, {
      message: "generic:validation-only-letters-spaces-dash"
    }),
    postalCode: Yup.string().matches(postalCode, { message: "generic:validation-only-numbers" }),
    placeOfBirth: Yup.string(),
    title: Yup.string(),
    taxNumber: Yup.string(),
    email: EmailValidationNonRequired,
    personalTaxId: taxIdTINOptional
  });
};
