import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import Table from "../../../generic/Table";
import EntityLink from "../../../common/EntityLink/EntityLink";
import { FormikProps } from "formik";
import { CheckboxField } from "../../../generic/CheckboxField";
import TextInput from "../../../generic/TextInput";
import KYCStatusLabel from "./KYCStatusLabel";
import { RoleEntry } from "../../../../lib/dataAdapters/membershipCategorization/membershipCategorization";

interface EditRolesTable extends WithTranslation {
  formik: FormikProps<any>;
  onFinishEdit: () => void;
  formWarning: string;
  areVotesDisabled: boolean;
}

const EditRolesTable = ({ formik, t, formWarning, areVotesDisabled }: EditRolesTable) => {
  const columns = [
    {
      dataField: "name",
      classes: "align-middle",
      text: t("nominee-dashboard:company.shareholder-entity"),
      formatter: (_, row) => <EntityLink testIdPrefix="shareholder" membership={row.role} />
    },
    {
      dataField: "role.isKycRequired",
      classes: "align-middle",
      text: t("nominee-dashboard:company.status-kyc"),
      formatter: (isKycRequired, row) => (
        <KYCStatusLabel isKycRequired={isKycRequired} isKycSucceeded={row.role.isKycSucceeded} />
      )
    },
    {
      dataField: "isManagingDirector",
      classes: "align-middle",
      text: t("nominee-dashboard:company.is-director"),
      formatter: (_, row, index) => (
        <span data-testid="shareholder-share">
          <CheckboxField
            label=""
            state={row.role.isManagingDirector ?? false}
            name={`roles.${index}.isManagingDirector`}
            handleClick={(e) => {
              formik.setFieldValue(`roles.${index}.isManagingDirector`, e.target.checked);
            }}
          />
        </span>
      )
    },
    {
      dataField: "isSilentPartner",
      classes: "align-middle",
      text: t("nominee-dashboard:company.is-silent-partner"),
      formatter: (_, row, index) => (
        <span data-testid="shareholder-is-silent-partner">
          <CheckboxField
            label=""
            state={row.role.isSilentPartner ?? false}
            name={`roles.${index}.isSilentPartner`}
            handleClick={(e) => {
              formik.setFieldValue(`roles.${index}.isSilentPartner`, e.target.checked);
            }}
          />
        </span>
      )
    },
    {
      dataField: "shareholders",
      text: t("nominee-dashboard:company.shareholder-share"),
      formatter: (_, row, index) => (
        <span data-testid="shareholder-director">
          <TextInput
            t={t}
            name={`roles.${index}.share`}
            type={"number"}
            value={row.role.share}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={row.error?.share}
            isInvalid={!!row.error?.share}
          />
        </span>
      )
    },
    {
      dataField: "votes",
      text: t("nominee-dashboard:company.shareholder-votes"),
      formatter: (_, row, index) => (
        <span data-testid="shareholder-votes">
          <TextInput
            t={t}
            readOnly={areVotesDisabled}
            name={`roles.${index}.votes`}
            type={"number"}
            value={row.role.votes ?? "---"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={row.error?.votes}
            isInvalid={!!row.error?.votes}
          />
        </span>
      )
    }
  ];

  let tableRows = formik.values.roles.map((role: RoleEntry, index: number) => {
    role["share"] = role["share"] ?? 0;
    role["votes"] = role["votes"] ?? 0;

    return {
      role,
      error: (formik.errors.roles ?? [])[index]
    };
  });

  return (
    <div data-testid="edit-roles-table">
      <Table data={tableRows} columns={columns} tableStyle="greenTable" keyField="role.id" />

      {formWarning && (
        <div className="alert alert-warning" data-testid="form-error-invalid-feedback">
          {formWarning}
        </div>
      )}
    </div>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(EditRolesTable);
