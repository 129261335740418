import React from "react";
import { companyToShelfCompany } from "../../../lib/dataAdapters/companyToShelfCompany";
import KeyValueCard from "../KeyValueCard/KeyValueCard";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { shelfCompanyFormToKeyValueCardProps } from "lib/dataAdapters/shelfCompanyFormToKeyValueCardProps/shelfCompanyFormToKeyValueCardProps";
import { getOrderDetails_getOrderDetails_reservedCompany } from "../../../lib/api/queries/graphql/getOrderDetails";
import { OrderCompanyStatusEnum } from "../../../global-query-types";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import ShelfCompanyDetailsTableContainer from "../ShelfCompanyDetailsTable/ShelfCompanyDetailsTableContainer";
import { EntityLinkColorVariant } from "../../../components/common/EntityLink/EntityLink";
import { Link } from "react-router-dom";
import { getAvailableShelfCompanies_getAvailableShelfCompanies_companies } from "../../../lib/api/queries/graphql/getAvailableShelfCompanies";

interface ShelfCompanyOrderDetailsProps extends WithTranslation {
  onRefresh: () => Promise<void>;
  handleUnAssign: () => Promise<void>;
  handleTransfer: () => Promise<void>;
  companies: getAvailableShelfCompanies_getAvailableShelfCompanies_companies[];
  orderId?: string;
  orderDetails?: any;
  orderCompany?: getOrderDetails_getOrderDetails_reservedCompany | null;
}

const sortOldestToNewest = (
  companies: getAvailableShelfCompanies_getAvailableShelfCompanies_companies[]
): getAvailableShelfCompanies_getAvailableShelfCompanies_companies[] => {
  return companies.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
};

const ShelfCompanyOrderDetails = ({
  orderId,
  companies,
  orderDetails,
  orderCompany,
  handleUnAssign,
  handleTransfer,
  t,
  onRefresh
}: ShelfCompanyOrderDetailsProps) => {
  const { data, title } = orderDetails
    ? shelfCompanyFormToKeyValueCardProps(orderDetails, t)
    : {
        title: t("generic:vg-order-details.order-details-card.title"),
        data: []
      };

  return (
    <div className="order-details-page" data-testid="shelf-company-order-details">
      <KeyValueCard className="order-details-page__card" title={title} data={data} />

      {orderDetails?.status === OrderCompanyStatusEnum.Booked && (
        <ShelfCompanyDetailsTableContainer
          orderId={orderId}
          companies={sortOldestToNewest(companies).map(companyToShelfCompany)}
          onRefresh={onRefresh}
        />
      )}

      {orderDetails?.status === OrderCompanyStatusEnum.Assigned && (
        <CompanyCard
          t={t}
          company={orderCompany}
          handleUnAssign={handleUnAssign}
          handleTransfer={handleTransfer}
        />
      )}

      {orderDetails?.status === OrderCompanyStatusEnum.Transferred && (
        <CompanyCard t={t} company={orderCompany} />
      )}
    </div>
  );
};

const CompanyCard = ({
  t,
  company,
  handleUnAssign,
  handleTransfer
}: {
  t: TFunction;
  company?: getOrderDetails_getOrderDetails_reservedCompany | null;
  handleUnAssign?: () => Promise<void>;
  handleTransfer?: () => Promise<void>;
}) => (
  <div
    data-testid={"company-card"}
    className={"order-details-page__shelf-company-section card-container"}>
    <Link
      className={`entity-link--${EntityLinkColorVariant.GREY}`}
      to={`/nominee-companies/${company?.id}`}
      data-testid="company-card-name">
      <div>{company?.fullName}</div>
    </Link>

    <div>
      {handleUnAssign ? (
        <RideButtonDeprecated
          onClick={handleUnAssign}
          size={ButtonSize.Small}
          variant={ButtonVariant.Secondary}
          data-testid="unreserve-company-button"
          className="order-details-page__unreserve-button">
          {t("generic:vg-order-details.company-card.unreserve")}
        </RideButtonDeprecated>
      ) : (
        <></>
      )}
      {handleTransfer ? (
        <RideButtonDeprecated
          onClick={handleTransfer}
          size={ButtonSize.Small}
          variant={ButtonVariant.Primary}
          data-testid="transfer-company-button">
          {t("generic:vg-order-details.company-card.transfer")}
        </RideButtonDeprecated>
      ) : (
        <></>
      )}
    </div>
  </div>
);

export default withTranslationReady(["generic"])(ShelfCompanyOrderDetails);
