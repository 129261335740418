import React from "react";
import { ApolloQueryResult } from "apollo-client";
import { TFunction } from "i18next";
import { Button } from "react-bootstrap";

import { isAdminServiceActivated } from "./AdminServiceSectionAddon";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const ReAssignFolderPermissions = ({
  t,
  company,
  refetchCompany,
  handleAssignTaxAdvisorStoragePermissions
}: {
  t: TFunction;
  company: NomineeCompanyDetails_companyById;
  handleAssignTaxAdvisorStoragePermissions: () => Promise<void>;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}) => {
  if (!isAdminServiceActivated(company)) {
    return <></>;
  }

  const handleClick = async () => {
    await handleAssignTaxAdvisorStoragePermissions();
    await refetchCompany();
  };

  return (
    <Button onClick={handleClick} data-testid="re-assign-folder-permissions">
      {t("nominee-dashboard:admin-service.re-assign-folder-permissions")}
    </Button>
  );
};
