import ReactSelect, { components } from "react-select";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Lock } from "sharedComponents/icons/Lock";
import { FolderBreadcrumb } from "../FolderBreadcrumb/FolderBreadcrumb";

export type FolderSelectOption = {
  label: string;
  value: string;
  isDisabled: boolean;
};

export interface FolderSelectFieldProps extends WithTranslation {
  name: string;
  options: FolderSelectOption[];
  placeholder: string;
  onChange: (value?: FolderSelectOption) => void;
  className?: string;
  selectedOption?: FolderSelectOption;
  error?: string;
  fixedOptions?: FolderSelectOption[];
  isDisabled?: boolean;
}

const customStyles = {
  option: (styles, { data }) => ({
    ...styles,
    cursor: data.isDisabled ? "auto" : "pointer"
  }),
  control: (styles) => ({
    ...styles,
    cursor: "pointer"
  })
};

const FolderSelectField = ({
  options,
  name,
  onChange,
  placeholder,
  selectedOption,
  className,
  fixedOptions,
  isDisabled,
  t
}: FolderSelectFieldProps) => {
  const fixedOptionsDescriptionLabel = t("client:documents:incoming-invoices-quick-selection");
  const fixedOptionsDescription = {
    label: fixedOptionsDescriptionLabel,
    value: "",
    breadcrumbs: "",
    isDisabled: true
  };

  let shouldShowFixedOptions: boolean;
  if (fixedOptions) {
    const onlyExistingOptions = (fixedOption) =>
      options.map(({ value }) => value).includes(fixedOption.value);
    shouldShowFixedOptions = fixedOptions && fixedOptions?.filter(onlyExistingOptions).length > 0;

    if (shouldShowFixedOptions) {
      options = [fixedOptionsDescription, ...fixedOptions, ...options];
    }
  }

  return (
    <ReactSelect
      id={name}
      name={name}
      isDisabled={isDisabled}
      options={options}
      placeholder={placeholder}
      className={`ride-select-field ${className ?? ""}`}
      onChange={(value) => onChange(value ?? undefined)}
      value={selectedOption}
      menuPlacement="auto"
      styles={customStyles}
      components={{
        SingleValue: ({ children, ...rest }) => {
          const value = rest.getValue()?.[0];
          if (!value) return null;

          return (
            <components.SingleValue {...rest}>
              <span data-testid={`${name}-current-value`}>
                <FolderBreadcrumb path={value.label} />
              </span>
            </components.SingleValue>
          );
        },
        Option: ({ children, ...rest }) => {
          const isFixedOptionsDescription = rest.label === fixedOptionsDescriptionLabel;
          if (isFixedOptionsDescription) {
            return (
              <FixedOptionsDescription optionName={fixedOptionsDescriptionLabel} rest={rest} />
            );
          }

          const optionName = children?.toString() ?? "";
          const index = options.map((option) => option.label).indexOf(optionName);
          const numberOfFixedOptions =
            shouldShowFixedOptions && fixedOptions ? fixedOptions.length : 0;

          return children ? (
            <FolderOption
              optionName={optionName}
              isFixed={index <= numberOfFixedOptions}
              rest={rest}
            />
          ) : null;
        }
      }}
    />
  );
};

const FixedOptionsDescription = ({ optionName, rest }) => {
  return (
    <components.Option className="option option--fixed-option" {...rest}>
      <div
        className="option__name option__name--fixed-description option__name--fixed-option"
        data-testid="fixed-options-description">
        {optionName}
      </div>
    </components.Option>
  );
};

const FolderOption = ({ optionName, isFixed, rest }) => {
  const path = optionName.split("/");
  const indentation = path.length - 1;
  const name = path.slice(-1);

  return (
    <components.Option
      className={`
        option 
        ${isFixed ? "option--fixed-option" : ""}
      `}
      {...rest}>
      <div
        className={`
          option__name
          option__name--indent-${indentation}
          ${isFixed ? "option__name--fixed-option" : ""}
        `}
        data-testid="option-name">
        {name}
      </div>
      <div className="option__lock-icon">
        {rest.isDisabled && <Lock data-testid="is-disabled-icon" />}
      </div>
    </components.Option>
  );
};

export default withTranslation(["client"])(FolderSelectField);
