import { sortRemoteStorageNodeByFilesFirst } from "../../lib/dataAdapters/sortRemoteStorageNodeByFilesFirst";
import React from "react";
import FileSystemNode, { FileSystemNodeProps } from "./FileSystemNode";
import { RemoteStorageNode } from "../../lib/dataAdapters/toRemoteStorageNode";

interface FileSystemNodeListProps extends FileSystemNodeProps {
  expanded: boolean;
  childNodes: RemoteStorageNode[];
}

export const FileSystemNodeList = (props: FileSystemNodeListProps) => {
  const { level = 0 } = props;

  return (
    <>
      {props.expanded &&
        sortRemoteStorageNodeByFilesFirst(props.childNodes).map((childNode, index) => {
          return (
            <FileSystemNode
              {...props}
              node={childNode}
              key={index}
              onDelete={props.onDelete}
              level={level + 1}
              entityType={props.entityType}
              entityId={props.entityId}
              refetch={props.refetch}
            />
          );
        })}
    </>
  );
};
