import gql from "graphql-tag";

export const START_COMPANY_CREATION = gql`
  mutation StartCompanyCreation(
    $creator: ShareholderPerson!
    $shareholders: [ShareholderPerson!]!
    $flowType: FlowTypeEnum!
    $referral: String
  ) {
    startCompanyCreation(
      creator: $creator
      shareholders: $shareholders
      flowType: $flowType
      referral: $referral
    ) {
      companyCreation {
        id
        flowType
      }
      authentication {
        token
        feedback
      }
    }
  }
`;

export const START_HOLDING_COMPANY_CREATION = gql`
  mutation StartHoldingCompanyCreation(
    $creator: ShareholderPerson!
    $shareholders: [ShareholderCompany!]!
    $flowType: FlowTypeEnum!
    $referral: String
  ) {
    startHoldingCompanyCreation(
      creator: $creator
      shareholders: $shareholders
      flowType: $flowType
      referral: $referral
    ) {
      companyCreation {
        id
        flowType
      }
      authentication {
        token
        feedback
      }
    }
  }
`;

export const UPDATE_HOLDING_COMPANY_CREATION = gql`
  mutation UpdateHoldingCompanyCreation(
    $companyCreationId: ID!
    $creator: ShareholderPerson!
    $shareholders: [ShareholderCompany!]!
  ) {
    updateHoldingCompanyCreation(
      companyCreationId: $companyCreationId
      creator: $creator
      shareholders: $shareholders
    ) {
      feedback
    }
  }
`;

export const UPDATE_SHAREHOLDERS = gql`
  mutation UpdateShareholders(
    $companyCreationId: ID!
    $creator: ShareholderPerson!
    $shareholders: [ShareholderPerson!]!
    $flowType: FlowTypeEnum!
  ) {
    updateShareholders(
      companyCreationId: $companyCreationId
      creator: $creator
      shareholders: $shareholders
      flowType: $flowType
    ) {
      feedback
    }
  }
`;

export const UPDATE_SHAREHOLDERS_SHARES = gql`
  mutation UpdateShareholdersShares($companyCreationId: ID!, $allShareholders: [PersonShare!]!) {
    updateShares(companyCreationId: $companyCreationId, allShareholders: $allShareholders) {
      feedback
    }
  }
`;

export const UPDATE_DIRECTOR_ROLE = gql`
  mutation UpdateDirectorRole($companyCreationId: ID!, $allShareholders: [PersonRole!]!) {
    updateDirectorRole(companyCreationId: $companyCreationId, allShareholders: $allShareholders) {
      feedback
    }
  }
`;

export const UPDATE_COMPANY_NAME = gql`
  mutation UpdateCompanyName($companyCreationId: ID!, $name: String!) {
    updateCompanyName(companyCreationId: $companyCreationId, name: $name) {
      feedback
    }
  }
`;

export const UPDATE_SHAREHOLDERS_DETAILS = gql`
  mutation UpdateShareholdersDetails(
    $companyCreationId: ID!
    $allShareholders: [ShareholderDetails!]!
  ) {
    updateShareholdersDetails(
      companyCreationId: $companyCreationId
      allShareholders: $allShareholders
    ) {
      feedback
    }
  }
`;

export const COMPLETE_COMPANY_CREATION_ORDER = gql`
  mutation CompleteCompanyCreationOrder($companyCreationId: ID!, $isASUpsellFlow: Boolean) {
    completeCompanyCreationOrder(
      companyCreationId: $companyCreationId
      isASUpsellFlow: $isASUpsellFlow
    ) {
      feedback
    }
  }
`;

export const UPDATE_COMPANY_ADDRESS = gql`
  mutation UpdateCompanyAddress($companyCreationId: ID!, $businessAddress: CompanyAddress!) {
    updateCompanyAddress(companyCreationId: $companyCreationId, businessAddress: $businessAddress) {
      feedback
    }
  }
`;

export const UPDATE_OPERATIONAL_ACTIVITIES = gql`
  mutation UpdateOperationalActivities(
    $companyCreationId: ID!
    $hasOperationalActivities: Boolean!
    $operationalActivities: String
  ) {
    updateOperationalActivities(
      companyCreationId: $companyCreationId
      hasOperationalActivities: $hasOperationalActivities
      operationalActivities: $operationalActivities
    ) {
      feedback
    }
  }
`;

export const RESUME_COMPANY_CREATION_STEP = gql`
  mutation ResumeCompanyCreationStep($resumeToken: ID!) {
    resumeCompanyCreationStep(resumeToken: $resumeToken) {
      companyCreation {
        id
        flowType
        company {
          id
          orderStatus
          companyFoundationOrderId
        }
        adminCompany {
          id
        }
      }
      authentication {
        token
        feedback
      }
    }
  }
`;

export const START_ADMIN_SERVICE = gql`
  mutation StartAdminService(
    $companyInfo: OrdererCompanyData!
    $businessAddress: CompanyAddress!
    $referral: String
  ) {
    startAdminService(
      companyInfo: $companyInfo
      businessAddress: $businessAddress
      referral: $referral
    ) {
      companyCreation {
        id
      }
      authentication {
        token
        feedback
      }
    }
  }
`;

export const UPDATE_ORDERER_COMPANY = gql`
  mutation UpdateOrdererCompany(
    $companyCreationId: ID!
    $companyInfo: OrdererCompanyData!
    $businessAddress: CompanyAddress!
  ) {
    updateOrdererCompany(
      companyCreationId: $companyCreationId
      companyInfo: $companyInfo
      businessAddress: $businessAddress
    ) {
      feedback
    }
  }
`;

export const UPDATE_ORDERER_PERSONAL_DETAILS = gql`
  mutation UpdateOrdererPersonalDetail($companyCreationId: ID!, $orderer: ShareholderPerson!) {
    updateOrdererPersonalDetail(companyCreationId: $companyCreationId, orderer: $orderer) {
      feedback
    }
  }
`;

export const SELECT_ORDERER_PACKAGE_TYPE = gql`
  mutation UpdateOrdererPackage($companyCreationId: ID!, $selectPackage: PackageTypeEnum!) {
    updateOrdererPackage(companyCreationId: $companyCreationId, selectPackage: $selectPackage) {
      feedback
    }
  }
`;

export const UPDATE_EMPLOYEE_MANAGING_DIRECTOR = gql`
  mutation UpdateEmployeeManagingDirector(
    $companyCreationId: ID!
    $directors: [ShareholderPerson!]!
    $flowType: FlowTypeEnum!
  ) {
    updateEmployeeManagingDirector(
      companyCreationId: $companyCreationId
      directors: $directors
      flowType: $flowType
    ) {
      feedback
    }
  }
`;

export const APPLY_DISCOUNT_CODE = gql`
  mutation ApplyDiscountCode($companyCreationId: ID!, $discountCode: String!) {
    applyDiscountCode(companyCreationId: $companyCreationId, discountCode: $discountCode) {
      feedback
    }
  }
`;
