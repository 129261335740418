import { withTranslationReady } from "common/i18n/withTranslationReady";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import {
  ButtonSize,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import ClubDealLogo from "sharedComponents/ClubDealLogo/ClubDealLogo";
import { ButtonVariant } from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { Image } from "react-bootstrap";
import { formatCurrency } from "common/formatters";
import { ErrorAlert } from "../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";

export interface ClubDealDetailsCardProps extends WithTranslation {
  name: string;
  image?: string;
  description?: string;
  targetYield: number;
  minInvestment: number;
  maxInvestment: number;
  alreadyFinanced: number;
  onExpressInterest: () => Promise<void>;
}

const ClubDealDetailsCard = (props: ClubDealDetailsCardProps) => {
  const {
    name,
    image,
    description,
    targetYield,
    minInvestment,
    maxInvestment,
    alreadyFinanced,
    onExpressInterest,
    t
  } = props;
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fundingStatus = (style: string): string => {
    if (alreadyFinanced !== 100) return style;
    return `${style} ${style}__fully-funded`;
  };

  const handleExpressInterest = async () => {
    setIsLoading(true);
    try {
      await onExpressInterest?.();
      setSubmitError(null);
    } catch (err) {
      if (typeof err === "string") {
        setSubmitError(err);
      }

      if (typeof err === "object") {
        setSubmitError(err.message ?? "UNKNOWN_ERROR");
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="club-deal-details-card">
      <div className="club-deal-details-card__title-container">
        <ClubDealLogo />
        <div className="club-deal-details-card__title-text" data-testid="card-name">
          {name}
        </div>
      </div>

      <div
        className={`club-deal-details-card__image-container ${
          image ? "" : "club-deal-details-card__image-container--no-image"
        }`}>
        <div className="club-deal-details-card__overlay">
          <div
            className={fundingStatus("club-deal-details-card__completion")}
            data-testid="funding-status">
            {alreadyFinanced === 100 && t("client:investments.clubDeal.fullyFunded")}
            {alreadyFinanced !== 100 &&
              t("client:investments.clubDeal.alreadyFinanced", {
                alreadyFinanced: alreadyFinanced.toFixed(0)
              })}
          </div>
        </div>
        {image && (
          <Image className="club-deal-details-card__image" src={image} data-testid="card-image" />
        )}
      </div>

      <div className="club-deal-details-card__description" data-testid="card-description">
        {description}
      </div>

      <div className="club-deal-details-card__fields">
        <div className="club-deal-details-card__field" data-testid="target-yield">
          <div className="club-deal-details-card__field-name">
            {t("client:investments.clubDeal.targetReturn")}
          </div>
          <div className="club-deal-details-card__field-value">{targetYield.toFixed(0)}% p.a.</div>
        </div>
        <div className="club-deal-details-card__field" data-testid="investment-range">
          <div className="club-deal-details-card__field-name">
            {t("client:investments.clubDeal.yourInvestment")}
          </div>
          <div className="club-deal-details-card__field-value">
            {`${formatCurrency(minInvestment)}`} - {`${formatCurrency(maxInvestment)}`}
          </div>
        </div>
      </div>

      {submitError !== null && <ErrorAlert error={submitError} t={t} />}

      <div className="club-deal-details-card__button">
        <RideButtonDeprecated
          variant={ButtonVariant.Primary}
          onClick={handleExpressInterest}
          size={ButtonSize.Big}
          isLoading={isLoading}
          data-testid={"express-interest-button"}>
          {t("client:investments.clubDeal.expressInterest")}
        </RideButtonDeprecated>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic", "client"])(ClubDealDetailsCard);
