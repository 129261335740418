import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const PhoneFilledIcon = ({ size = 24, className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none">
    <g clipPath="url(#clip0_5285_79)">
      <path
        d="M15.2475 16.11L16.9275 14.43C17.1538 14.2065 17.4401 14.0536 17.7516 13.9897C18.0631 13.9257 18.3865 13.9537 18.6825 14.07L20.73 14.8875C21.0291 15.0089 21.2856 15.2161 21.4671 15.4831C21.6486 15.75 21.747 16.0647 21.75 16.3875V20.1375C21.7483 20.3571 21.7021 20.574 21.6143 20.7753C21.5265 20.9766 21.3989 21.158 21.2391 21.3087C21.0793 21.4593 20.8907 21.5761 20.6847 21.6519C20.4786 21.7278 20.2593 21.7611 20.04 21.75C5.69249 20.8575 2.79749 8.7075 2.24999 4.0575C2.22458 3.82915 2.2478 3.59801 2.31813 3.37928C2.38846 3.16055 2.5043 2.95919 2.65804 2.78845C2.81178 2.61771 2.99993 2.48145 3.21011 2.38865C3.42029 2.29584 3.64774 2.24859 3.87749 2.25H7.49999C7.82325 2.25096 8.13883 2.3486 8.40614 2.53037C8.67345 2.71214 8.88027 2.96973 8.99999 3.27L9.81749 5.3175C9.93768 5.6123 9.96835 5.93598 9.90565 6.24811C9.84296 6.56024 9.68969 6.84697 9.46499 7.0725L7.78499 8.7525C7.78499 8.7525 8.75249 15.3 15.2475 16.11Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5285_79">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
