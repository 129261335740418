import React, { useEffect } from "react";
import { useMutation } from "react-apollo";
import logger from "../../../common/Logger";
import { CONFIRM_EMAIL_LINK } from "../../../lib/api/mutations/CONFIRM_EMAIL_LINK";
import { SalutationType } from "../../../global-query-types";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

export interface EmailConfirmResult {
  firstName: string | undefined;
  salutation: SalutationType | undefined;
  email: string | null;
}

interface ConfirmEmailAsyncContainerProps {
  token: string;
  onConfirm: (confirmResult: EmailConfirmResult) => void;
}

export const ConfirmEmailAsyncContainer = ({
  token,
  onConfirm
}: ConfirmEmailAsyncContainerProps) => {
  const [confirmEmailLink, confirmEmailLinkRequest] = useMutation(CONFIRM_EMAIL_LINK);

  useEffect(() => {
    const confirmEmail = async (verificationToken) => {
      try {
        const { data } = await confirmEmailLink({ variables: { verificationToken } });
        const { firstName, salutation, email } = data.confirmEmailLink;
        onConfirm({ firstName, salutation, email });
      } catch (error) {
        logger.error(error);
      }
    };

    confirmEmail(token);
    // NOTE: this is disabled so that this useEffect ever only runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RequestFeedbackHandler requests={[confirmEmailLinkRequest]} noLoadingAnimation={true} />;
};
