import React, { PropsWithChildren } from "react";
import ButtonClose from "uiLibrary/v2/components/Buttons/ButtonClose/ButtonClose";

const moduleName = "ride-modal";

export interface RideModalProps {
  "data-testid"?: string;
  title: string;
  onClose: () => void;
  fitContentHeight?: boolean; // TODO: review this with design
  fitContentWidth?: boolean; // TODO: review this with design
}

const RideModal = ({
  "data-testid": dataTestId = moduleName,
  title,
  onClose,
  children,
  fitContentHeight,
  fitContentWidth
}: RideModalProps & PropsWithChildren<{}>) => {
  return (
    <div className={moduleName} data-testid={dataTestId}>
      <div className={`${moduleName}__backdrop`} data-testid="modal-backdrop" onClick={onClose} />

      <div
        className={`${moduleName}__dialog ${
          fitContentHeight ? `${moduleName}__dialog--fit-content-height` : ""
        }
          ${fitContentWidth ? `${moduleName}__dialog--fit-content-width` : ""}`}>
        <div className={`${moduleName}__header`}>
          <span data-testid={`${dataTestId}-title`} className={`${moduleName}__title`}>
            {title}
          </span>
          <ButtonClose
            data-testid={`${dataTestId}-close-btn`}
            className={`${moduleName}__close-button`}
            onClick={onClose}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export const RideModalBody = ({
  children,
  className
}: PropsWithChildren<{ className?: string }>) => (
  <div className={`${moduleName}__body ${className}`}>{children}</div>
);

export const RideModalControls = ({ children }: PropsWithChildren<{}>) => (
  <div className={`${moduleName}__controls`}>{children}</div>
);

export default RideModal;
