import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RideNavbar } from "sharedComponents/RideNavbar/RideNavbar";
import { MenuLink } from "sharedComponents/MenuLink/MenuLink";
import { AccountantUrls } from "./AccountantUrls";
import { RideSidebar } from "sharedComponents/RideSidebar/RideSidebar";
import NavigationService from "lib/services/NavigationService/NavigationService";

const AccountantHeader = ({ t }: WithTranslation) => {
  const menu = [
    <MenuLink
      key="accountant-clients"
      eventKey={AccountantUrls.CLIENTS_COMPANIES}
      path={AccountantUrls.CLIENTS_COMPANIES}>
      {t("tax-advisor-clients")}
    </MenuLink>,
    <MenuLink key="logout" path="/logout">
      {t("profile.logout")}
    </MenuLink>
  ];

  NavigationService.instance = new NavigationService(AccountantUrls);

  return (
    <div data-testid="tax-advisor-header">
      <RideNavbar sidebar={<RideSidebar menu={menu} activeKey="tax-advisor-clients" />}>
        {menu}
      </RideNavbar>
    </div>
  );
};

export default withTranslation("header")(AccountantHeader);
