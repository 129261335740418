import React from "react";
import BookingGmbH from "./BookingGmbHScene";
import { GetMe } from "../../../lib/api/queries/MeQuery";

const BookingGmbHSceneContainer = () => {
  const meRequest = GetMe();
  return <BookingGmbH meRequest={meRequest} />;
};

export default BookingGmbHSceneContainer;
