import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { PageLayout } from "../../../sharedComponents/templates/PageLayout/PageLayout";
import { TradingTransactionHistoryCompaniesListContainer } from "../../components/TradingTransactionHistoryCompaniesList/TradingTransactionHistoryCompaniesListContainer";

const TradingTransactionHistoryCompaniesListScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("header:trading-transaction-history")}
      mainContent={<TradingTransactionHistoryCompaniesListContainer />}
    />
  );
};

export default withTranslationReady(["header"])(TradingTransactionHistoryCompaniesListScene);
