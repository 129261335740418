import React from "react";
import { MembersList } from "../../../components/common/MembersList";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";

interface GmbHShareholdersDetailsProps extends CompanyCardProps {}

const CompanyShareholdersCard = ({ t, gmbh }: GmbHShareholdersDetailsProps) => {
  return (
    <RideSectionCard
      data-testid="gmbh-shareholders-details"
      title={t("client:company:shareholders")}
      className="company-shareholders-card">
      <MembersList
        t={t}
        members={gmbh.shareholders}
        silentPartnerShares={gmbh.silentPartnerShares}
        showShares={true}
        showVotes={true}
        showAddress={true}
      />
    </RideSectionCard>
  );
};

export default CompanyShareholdersCard;
