import gql from "graphql-tag";

export const CREATE_REAL_ESTATE_MUTATION = gql`
  mutation CreateRealEstateMutation(
    $label: String!
    $propertyValue: Float!
    $shares: Float
    $ownerUserId: ID!
    $ownerCompanyId: ID
  ) {
    createRealEstate(
      label: $label
      propertyValue: $propertyValue
      shares: $shares
      ownerUserId: $ownerUserId
      ownerCompanyId: $ownerCompanyId
    ) {
      id
      label
      propertyValue
    }
  }
`;
