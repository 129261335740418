import { withTranslation, WithTranslation } from "react-i18next";
import { AssetTypes } from "lib/types/types";
import { FormikProps } from "formik";
import { StepHeading } from "../../../../ChaptersOrderLayout.partials";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import React from "react";

interface TradesLastThreeYearsProps extends WithTranslation {
  assetOptionsSelected: AssetTypes[];
  formik: FormikProps<any>;
}

const TradesLastThreeYears = ({ t, assetOptionsSelected, formik }: TradesLastThreeYearsProps) => {
  return (
    <div data-testid={"trades-last-three-years"} className="trades-last-three-years">
      <StepHeading
        data-testid="trades-last-three-years-heading"
        text={t("generic:ride-broker-onboarding.trades-last-three-years.title")}
      />
      {assetOptionsSelected.map((investmentOption) => (
        <div
          className="trades-last-three-years"
          key={`${investmentOption}-trades-last-three-years`}>
          <RideInput
            key={investmentOption}
            type="number"
            name={investmentOption}
            className={`trades-last-three-years__input ${
              String(formik.values[investmentOption]) ? "selected" : ""
            }`}
            formik={formik}
            label={t(`generic:ride-broker-onboarding.investment-options.${investmentOption}`)}
            placeholder={t(
              "generic:ride-broker-onboarding.trades-last-three-years.select-trades-last-three-years"
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default withTranslation(["generic"])(TradesLastThreeYears);
