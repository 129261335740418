import React, { Children, PropsWithChildren } from "react";
import { MissingValue } from "../../chapters/ReviewAndSubmitLowTaxBroker/ReviewAndSubmitLowTaxBroker.partials";

interface ReviewTableProps {
  columns: string[];
  dataTestId?: string;
}

export enum BadgeColor {
  Grey = "grey",
  Green = "green",
  Purple = "purple"
}

export const ReviewTable = ({
  columns,
  children,
  dataTestId
}: ReviewTableProps & PropsWithChildren<{}>) => {
  return (
    <div
      data-testid={dataTestId}
      className="ltb-review-table"
      style={{ "--columns": columns.length } as React.CSSProperties}>
      {columns.map((column) => (
        <span className="ltb-review-table__column-name" key={column}>
          {column}
        </span>
      ))}
      {children}
    </div>
  );
};

ReviewTable.Entry = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div className="ltb-review-table__entry" data-testid="review-table-entry">
      {Children.map(children, (child, index) => (
        <div className="ltb-review-table__cell" key={index}>
          {child}
        </div>
      ))}
    </div>
  );
};

ReviewTable.Text = ({ content }: { content?: string }) => (
  <span className="ltb-review-table__text">{content ?? <MissingValue isMandatory={true} />}</span> //TODO: TEST WHEN MISSING VALUE
);

ReviewTable.Badge = ({
  content,
  color = BadgeColor.Grey,
  dataTestId = "review-table-badge"
}: {
  content: string;
  color?: BadgeColor;
  dataTestId?: string;
}) => (
  <span
    data-testid={dataTestId}
    className={`ltb-review-table__badge ltb-review-table__badge--${color}`}>
    {content}
  </span>
);
