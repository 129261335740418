import React, { HTMLProps } from "react";
import { ErrorMessage, useField } from "formik";
import ReactSelect from "react-select";

export interface ClientSelectProps extends HTMLProps<any> {
  name: string;
  options: ClientSelectOption[];
  label?: string;
  isSearchable?: boolean;
  isOnModal?: boolean;
  alignCaretRight?: boolean;
  isClearable?: boolean;
  testIdPrefix?: string;
  placeholder?: string;
  menuPortalTarget?: HTMLElement;
  styles?: any;
  changeHandler?: (value: string) => void;
}

export interface ClientSelectOption {
  label: string;
  value: string;
}

export const ClientSelect = ({
  name,
  options,
  label,
  isSearchable = false,
  isClearable = false,
  isOnModal = false,
  testIdPrefix,
  placeholder,
  changeHandler,
  alignCaretRight = false,
  menuPortalTarget,
  styles,
  ...rest
}: ClientSelectProps) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;

  const containerErrorClass = touched && error ? "client-select__container--error" : "";
  return (
    <div {...rest} data-testid={`${testIdPrefix || `${name}-select`}-container`}>
      {label && (
        <label
          htmlFor={name}
          className="client-select__label d-block"
          data-testid="client-select-label">
          {label}
        </label>
      )}
      <ReactSelect
        name={name}
        id={name}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isOnModal={isOnModal}
        value={options.find((option) => option.value === field.value)}
        onChange={(selection) =>
          changeHandler ? changeHandler(selection?.value ?? "") : helpers.setValue(selection?.value)
        }
        onBlur={() => helpers.setTouched(true)}
        classNamePrefix="client-select"
        placeholder={placeholder}
        className={`client-select__container ${containerErrorClass} ${
          alignCaretRight
            ? "client-select__control--align-caret-right"
            : "client-select__control--align-caret-left"
        }`}
        styles={isOnModal ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : undefined}
        menuPortalTarget={isOnModal ? document.body : undefined}
      />
      {touched && (
        <ErrorMessage
          className="client-input__error mt-2"
          component="div"
          name={name}
          data-testid={testIdPrefix ? `${testIdPrefix}-error` : "client-select-error"}
        />
      )}
    </div>
  );
};
