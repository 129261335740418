import React from "react";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";

interface ReviewTraderProps {
  traderName: string;
}

export const ReviewTrader = withTranslationReady(["generic"])(
  ({ t, traderName }: ReviewTraderProps & WithTranslation) => {
    return (
      <div data-testid="review-trader">
        <ReviewCard>
          <ReviewSectionTitle title={t("generic:ride-broker-onboarding.review.trader")} />
          {traderName && <div className="ltb-review-trader__entry">{traderName}</div>}
        </ReviewCard>
      </div>
    );
  }
);
