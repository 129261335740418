import React from "react";
import { useParams } from "react-router-dom";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { GetTaxAdvisorClientCompanyQuery } from "lib/api/queries/GetTaxAdvisorClientCompanyQuery";
import TaxAdvisorClientOverviewSection from "taxAdvisor/components/TaxAdvisorClientOverviewSection/TaxAdvisorClientOverviewSection";

const AccountantClientOverviewContainer = () => {
  const { companyId, ref } = useParams<{ companyId: string; ref?: string }>();
  const getClientCompanyRequest = GetTaxAdvisorClientCompanyQuery(companyId);

  return (
    <RequestFeedbackHandler requests={[getClientCompanyRequest]}>
      <TaxAdvisorClientOverviewSection
        routeRef={ref}
        company={getClientCompanyRequest.data?.getTaxAdvisorClientCompany}
        myTaxAdvisorCompany={null}
      />
    </RequestFeedbackHandler>
  );
};

export default AccountantClientOverviewContainer;
