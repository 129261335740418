import React from "react";
import { useHistory } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import CompanyTextFieldEditContainer from "../../components/Company/CompanyTextFieldEdit/CompanyTextFieldEditContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

const CompanyLeiScene = ({ t }: WithTranslation) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.lei")} />
      <ModalFormLayout title={t("client:company.edit.lei")} onBack={goBack}>
        <CompanyTextFieldEditContainer fieldName={"lei"} section="numbers-and-ids" />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation("client")(CompanyLeiScene);
