import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import {
  updateAdminServiceInvoiceDataByCompany,
  updateAdminServiceInvoiceDataByCompanyVariables
} from "./graphql/updateAdminServiceInvoiceDataByCompany";

const UPDATE_ADMIN_SERVICE_INVOICE_DATA_BY_COMPANY = gql`
  mutation updateAdminServiceInvoiceDataByCompany(
    $companyId: String!
    $setupFeeType: String
    $setupFeeValue: String
    $contractSignDate: String
    $servicePeriodStartingDate: String
    $servicePeriodEndingDate: String
    $invoicePeriodType: String
    $invoiceableStatus: String
    $additionalNotes: String
    $conditionType: String
    $conditionTypeSpecial: String
    $terminationEndingDate: String
    $terminationStatus: String
  ) {
    updateAdminServiceInvoiceDataByCompany(
      companyId: $companyId
      setupFeeType: $setupFeeType
      setupFeeValue: $setupFeeValue
      contractSignDate: $contractSignDate
      servicePeriodStartingDate: $servicePeriodStartingDate
      servicePeriodEndingDate: $servicePeriodEndingDate
      invoicePeriodType: $invoicePeriodType
      invoiceableStatus: $invoiceableStatus
      additionalNotes: $additionalNotes
      conditionType: $conditionType
      conditionTypeSpecial: $conditionTypeSpecial
      terminationEndingDate: $terminationEndingDate
      terminationStatus: $terminationStatus
    ) {
      feedback
    }
  }
`;

export const UpdateAdminServiceInvoiceDataByCompany = () =>
  useMutation<
    updateAdminServiceInvoiceDataByCompany,
    updateAdminServiceInvoiceDataByCompanyVariables
  >(UPDATE_ADMIN_SERVICE_INVOICE_DATA_BY_COMPANY);
