import React, { useEffect, useState } from "react";
import { WithTranslation } from "react-i18next";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideNewEntry } from "uiLibrary/components/RideNewEntry/RideNewEntry";
import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { CompanyFoundingOrder } from "lib/models/client/CompanyFounding/CompanyFoundingOrder";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { AddressData } from "lib/models/client/AddressData";
import { PersonData } from "lib/models/client/CompanyFounding/PersonData";
import { CompanyData } from "lib/models/client/CompanyData";

const SelectCompanyAddress = ({
  t,
  order,
  saveData,
  currentStep,
  controls
}: WithTranslation & StepComponentProps) => {
  const orderSetup = new CompanyFoundingOrder(order);
  const availableAddresses = orderSetup
    .getAddresses()
    .filter((address) => address.address.country === "DE");
  const module = "select-company-address";
  const [newAddressAdded, setNewAddressAdded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressData>(
    order.extra.companyAddress || {
      country: "DE",
      city: "",
      postalCode: "",
      streetLine1: "",
      streetLine2: ""
    }
  );
  let companyAddress;

  const onSubmit = async () => {
    currentStep.complete();
    await saveData(
      {
        ...order.extra,
        candidateCompanyAddress: newAddressAdded ? null : selectedAddress,
        ...currentStep.chapter.order.serialize()
      },
      false,
      true
    );
  };

  useEffect(() => {
    if (newAddressAdded) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAddressAdded]);

  let initialValues = {};
  availableAddresses.forEach((data, index) => {
    initialValues[`address_${index}`] = false;
  });

  if (order.extra.companyAddress) {
    companyAddress = {
      entity: undefined,
      address: {
        country: order.extra.companyAddress.country,
        city: order.extra.companyAddress.city,
        postalCode: order.extra.companyAddress.postalCode,
        streetLine1: order.extra.companyAddress.streetLine1,
        streetLine2: order.extra.companyAddress.streetLine2
      }
    };

    availableAddresses.forEach((addressData, index) => {
      if (companyAddress && orderSetup.isSameAddress(companyAddress.address, addressData.address)) {
        companyAddress = undefined;
        initialValues[`address_${index}`] = true;
      }
    });

    initialValues[`address_${availableAddresses.length}`] = true;
  }

  const formik = useRideFormik({
    initialValues,
    onSubmit
  });

  const allFieldKeys = Object.keys(formik.values);

  const deselectOthers = (index: number) => {
    allFieldKeys.forEach((key) => {
      if (key !== `address_${index}`) {
        formik.values[key] = false;
      }
    });
  };

  const isAddressSelected = () => {
    let selected = false;
    allFieldKeys.forEach((key) => {
      if (key !== "addNewAddress" && formik.values[key] === true) {
        selected = true;
      }
    });
    return selected;
  };

  const addNewAddress = async () => {
    setNewAddressAdded(true);
  };

  const handleClick =
    (
      index: number,
      addressData: { address: AddressData; entity: CompanyData | PersonData | undefined }
    ) =>
    async () => {
      deselectOthers(index);

      let co: string = "";
      if (addressData.entity?.["companyName"]) {
        co = `c/o ${addressData.entity["companyName"]} ${addressData.entity["legalForm"]}`;
      } else if (addressData.entity?.["firstName"]) {
        co = addressData.entity?.["firstName"]
          ? `c/o ${addressData.entity?.["firstName"] || ""} ${addressData.entity["lastName"] || ""}`
          : "";
      }

      setSelectedAddress({
        country: addressData.address.country,
        city: addressData.address.city,
        postalCode: addressData.address.postalCode,
        streetLine1: addressData.address.streetLine1,
        streetLine2: `${co} ${addressData.address.streetLine2}`
      });
    };

  return (
    <div data-testid={`${module}__content`}>
      <FormikProvider value={formik}>
        <Form className="select-company-address">
          <StepHeading
            text={t("generic:company-setup.steps.company-address.select-company-address.title")}
            dataTestId={`${module}__title`}
          />
          {availableAddresses?.map((addressData, index) => (
            <RideCheckbox
              key={index}
              name={`address_${index}`}
              onClick={handleClick(index, addressData)}
              label={`${addressData.address.streetLine1}, ${addressData.address.city}, ${addressData.address.postalCode}, ${addressData.address.country}`}
            />
          ))}
          {companyAddress && (
            <RideCheckbox
              key={availableAddresses.length}
              name={`address_${availableAddresses.length}`}
              onClick={handleClick(availableAddresses.length, companyAddress)}
              label={`${companyAddress.address.streetLine1}, ${companyAddress.address.city}, ${companyAddress.address.postalCode}, ${companyAddress.address.country}`}
            />
          )}
          <RideNewEntry
            name={"addNewAddress"}
            label={t(
              "generic:company-setup.steps.company-address.select-company-address.add-new-address"
            )}
            onClick={addNewAddress}
          />
          {{ ...controls, props: { ...controls.props, disabled: !isAddressSelected() } }}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic"])(SelectCompanyAddress);
