import React, { useEffect, useState } from "react";
import { logout } from "../../common/Authentication/logout";
import { Redirect } from "react-router";
import logger from "../../common/Logger";

export const Logout = () => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    if (!isLoggedOut) handleLogout();
  }, [isLoggedOut]);

  const handleLogout = async () => {
    try {
      await logout();
      setIsLoggedOut(true);
    } catch (e) {
      logger.debug("failed logout");
    }
  };

  return isLoggedOut ? <Redirect to="/login" /> : null;
};
