import React, { FormEvent } from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useHistory } from "react-router-dom";
import DocumentCard from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/DocumentCard/DocumentCard";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { Chapter } from "lib/models/client/order/Chapter";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { DocumentsListHeading } from "../../../../sharedComponents/DocumentsListHeading/DocumentsListHeading";

const PersonalDocumentsList = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const history = useHistory();
  let personalDocumentsSubchapters = currentStep.getAllStepSubchapters();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (currentStep.chapter.subChapters.every((s) => s.completed)) {
      currentStep.complete();
    }

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  };

  const areAllDocumentsUploaded = (shareholderIndex: number[]): boolean => {
    let result = true;

    shareholderIndex.forEach((index) => {
      const shareholder = order?.extra?.shareholders?.[`${index}`];
      if (shareholder) {
        if (!shareholder.proofOfIDFile || !shareholder.proofOfAddressFile) {
          result = false;
        }
      }
    });

    return result;
  };

  if (!order.extra.shareholders) {
    return (
      <DocumentsListHeading
        title={t("generic:ride-broker-onboarding.documents.personal-documents-list.title")}
      />
    );
  }

  let shareholderKeys = order.extra.shareholders
    ? Object.keys(order.extra.shareholders).map((key) => parseInt(key))
    : [];

  const additionalIdDocumentInformationToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.IDDocumentAdditionalInformationForClients
  );

  const hasSimpleStep = personalDocumentsSubchapters.some((chapter) =>
    chapter.steps.some((s) => s.key === "shareholder-id-or-passport")
  );

  const hasAdditionalInformationStep = personalDocumentsSubchapters.some((chapter) =>
    chapter.steps.some((s) => s.key === "select-id-document-type")
  );

  if (
    (additionalIdDocumentInformationToggle && hasSimpleStep) ||
    (!additionalIdDocumentInformationToggle && hasAdditionalInformationStep)
  ) {
    currentStep.chapter.subChapters = [];
    personalDocumentsSubchapters = currentStep.getAllStepSubchapters();
  }

  if (shareholderKeys.length !== personalDocumentsSubchapters.length) {
    const subChaptersIndexes = currentStep
      .getAllSubchapters()
      .map((subchapter) => subchapter.index);

    shareholderKeys = shareholderKeys.filter((key) => !subChaptersIndexes.includes(key));

    shareholderKeys.forEach((key) => {
      if (additionalIdDocumentInformationToggle) {
        currentStep.chapter.addSubChapter({
          key: `edit-shareholder-${key}-personal-documents`,
          index: key,
          parentStepKey: "personal-documents-list",
          steps: [
            { key: "select-id-document-type" },
            { key: "shareholder-passport-file" },
            { key: "shareholder-id-document-review" },
            { key: "shareholder-proof-of-address" }
          ]
        });
      } else {
        currentStep.chapter.addSubChapter({
          key: `edit-shareholder-${key}-personal-documents`,
          index: key,
          parentStepKey: "personal-documents-list",
          steps: [{ key: "shareholder-id-or-passport" }, { key: "shareholder-proof-of-address" }]
        });
      }
    });
  }

  personalDocumentsSubchapters = currentStep.getAllStepSubchapters();

  order.extra.chapters[currentStep.chapter.key].subChapters = currentStep.chapter.subChapters;

  const onEdit = (subchapter: Chapter, stepKey: string) => {
    const step = subchapter.getStepByKey(stepKey);
    const positionalIndex = subchapter.positionalIndex;

    history.push(
      `/client/order/generic/documents/${positionalIndex}/${step?.index}/${order.id}/${order.submissionId}`
    );
  };

  const considerMiddleName = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddMiddleNameToLTBForm
  );

  return (
    <DocumentsListHeading
      title={t("generic:ride-broker-onboarding.documents.personal-documents-list.title")}>
      {personalDocumentsSubchapters?.map((subchapter) => {
        const shareholder = order?.extra?.shareholders?.[subchapter?.index!];

        if (shareholder) {
          return (
            <div
              data-testid="shareholder-documents-info"
              key={`shareholder-${subchapter.index}-documents-info`}>
              <div
                className={"documents-list__shareholder-name"}
                key={`shareholder-${subchapter.index}-name`}>
                {personNameWithFallback(shareholder.personData, considerMiddleName)}
              </div>
              <div
                className={"documents-list__content"}
                key={`shareholder-${subchapter.index}-documents-list`}>
                <DocumentCard
                  data-testid={`shareholder-${subchapter.index}-id-or-passport-card`}
                  text={t(
                    "generic:ride-broker-onboarding.documents.personal-documents-list.id-or-passport"
                  )}
                  numberOfAttachments={
                    shareholder.proofOfIDFile && shareholder.proofOfIDFile.id ? 1 : 0
                  }
                  onEdit={() =>
                    onEdit(
                      subchapter,
                      additionalIdDocumentInformationToggle
                        ? "select-id-document-type"
                        : "shareholder-id-or-passport"
                    )
                  }
                  key={`shareholder-${subchapter.index}-id-or-passport`}
                />
                <DocumentCard
                  data-testid={`shareholder-${subchapter.index}-proof-of-address-card`}
                  text={t(
                    "generic:ride-broker-onboarding.documents.personal-documents-list.proof-of-address"
                  )}
                  numberOfAttachments={
                    shareholder.proofOfAddressFile && shareholder.proofOfAddressFile.id ? 1 : 0
                  }
                  onEdit={() => onEdit(subchapter, "shareholder-proof-of-address")}
                  key={`shareholder-${subchapter.index}-proof-of-address`}
                />
              </div>
            </div>
          );
        }
      })}
      <form onSubmit={onSubmit}>
        {{
          ...controls,
          props: {
            ...controls.props,
            disabled: !areAllDocumentsUploaded(shareholderKeys)
          }
        }}
      </form>
    </DocumentsListHeading>
  );
};

export default withTranslationReady(["generic"])(PersonalDocumentsList);
