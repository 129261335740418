import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "lib/api/queries/graphql/NomineeCompanyDetails";
import { ApolloQueryResult } from "apollo-client";
import { UpdateAdminServiceInvoiceDataByCompany } from "lib/api/mutations/UPDATE_ADMIN_SERVICE_INVOICE_DATA_BY_COMPANY";
import ServiceInvoiceSection, {
  ServiceInvoiceData
} from "../ServiceInvoiceSection/ServiceInvoiceSection";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

interface AdminServiceInvoiceSectionContainerProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
  refetch: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

const AdminServiceInvoiceSectionContainer = ({
  t,
  company,
  refetch
}: AdminServiceInvoiceSectionContainerProps) => {
  const [updateAdminServiceInvoiceDataMutation, updateAdminServiceInvoiceDataRequest] =
    UpdateAdminServiceInvoiceDataByCompany();

  const handleSubmit = async (values: ServiceInvoiceData) => {
    await updateAdminServiceInvoiceDataMutation({
      variables: {
        ...values,
        companyId: company.id
      }
    });
    await refetch();
  };

  return (
    <RequestFeedbackHandler requests={[updateAdminServiceInvoiceDataRequest]}>
      <ServiceInvoiceSection
        title={t("nominee-dashboard:company.invoice.admin-service.title")}
        service="admin"
        serviceInvoiceData={company.adminServiceInvoiceData}
        onSubmit={handleSubmit}
      />
    </RequestFeedbackHandler>
  );
};

export default withTranslationReady(["nominee-dashboard"])(AdminServiceInvoiceSectionContainer);
