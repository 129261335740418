import React from "react";
import { Route, Switch } from "react-router";
import { UserRole } from "global-query-types";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";
import { SignupContainer } from "auth/components/Signup/SignupContainer";
import AccountantRoute from "./AccountantRoute";
import AccountantClientsScene from "accountant/scenes/AccountantClients/AccountantClientsScene";
import TaxAdvisorPersonDetailsContainer from "../taxAdvisor/components/TaxAdvisorPersonDetails/TaxAdvisorPersonDetailsContainer";
import CompanyTaxNumberScene from "../client/scenes/company/CompanyTaxNumberScene";
import CompanyVatNumberScene from "../client/scenes/company/CompanyVatNumberScene";
import CompanyLeiScene from "../client/scenes/company/CompanyLeiScene";
import CompanyClientNumberScene from "../taxAdvisor/scenes/CompanyClientNumberScene/CompanyClientNumberScene";
import TaxAdvisorNumberScene from "../taxAdvisor/scenes/TaxAdvisorAdvisorNumberScene/TaxAdvisorAdvisorNumberScene";
import CompanyFiscalYearsScene from "../client/scenes/company/CompanyFiscalYears/CompanyFiscalYearsScene";
import CompanyAccountingFrameworkScene from "../taxAdvisor/scenes/CompanyAccountingFramework/CompanyAccountingFrameworkScene";
import AccountantClientOverviewScene from "./scenes/AccountantClientOverview/AccountantClientOverviewScene";

interface ClientAppRoutesProps {
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy;
}

export function AccountantAppRoutes({ passwordPolicy }: ClientAppRoutesProps) {
  const path = `/accountant/`;
  return (
    <Switch>
      <AccountantRoute exact path={`${path}client-entities`} component={AccountantClientsScene} />
      <AccountantRoute
        exact
        path={`${path}persons/:id`}
        component={TaxAdvisorPersonDetailsContainer}
      />
      <AccountantRoute
        exact
        path={`${path}client-entities/:id/tax-number`}
        component={CompanyTaxNumberScene}
      />
      <AccountantRoute
        exact
        path={`${path}client-entities/:id/vat-number`}
        component={CompanyVatNumberScene}
      />
      <AccountantRoute exact path={`${path}client-entities/:id/lei`} component={CompanyLeiScene} />
      <AccountantRoute
        exact
        path={`${path}client-entities/:id/client-number`}
        component={CompanyClientNumberScene}
      />
      <AccountantRoute
        exact
        path={`${path}my-company/advisor-number/:clientId`}
        component={TaxAdvisorNumberScene}
      />
      <AccountantRoute
        exact
        path={`${path}client-entities/:id/fiscal-year`}
        component={CompanyFiscalYearsScene}
      />
      <AccountantRoute
        exact
        path={`${path}client-entities/:id/accounting-framework`}
        component={CompanyAccountingFrameworkScene}
      />
      <AccountantRoute
        exact
        path={`${path}client-entities/:companyId/:ref?`}
        component={AccountantClientOverviewScene}
      />
      <Route
        exact
        path={"/accountant/signup/:id"}
        component={() => <SignupContainer asUser={UserRole.Accountant} />}
      />
    </Switch>
  );
}
