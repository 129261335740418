import { Nav, NavLinkProps } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { PropsWithChildren } from "react";

interface MenuLinkProps extends PropsWithChildren<NavLinkProps> {
  path?: string | { pathname: string };
  target?: string;
  active?: boolean;
  onClick?: (e?: any) => void;
}

export const MenuLink = ({
  active = true,
  children,
  className,
  eventKey,
  onClick,
  path = "#",
  target = "_self"
}: MenuLinkProps) => {
  return (
    <Nav.Item className={className}>
      <Nav.Link
        to={path}
        as={Link}
        target={target}
        disabled={!active}
        onClick={onClick}
        eventKey={eventKey}
        data-testid={`menu-link-${eventKey}`}>
        {children}
      </Nav.Link>
    </Nav.Item>
  );
};
