import * as Yup from "yup";
import { NumberSchema } from "yup";

export const DefineSharesSchema = (numberOfShareholders: number, shareValidator: NumberSchema) => {
  return Yup.object().shape({
    shares: Yup.array().of(shareValidator).length(numberOfShareholders)
  });
};

export const MajorityShareSchema = (t) =>
  Yup.number()
    .required(t("generic:validation-required"))
    .min(25, t("generic:percentage-ownership-min-25"))
    .max(100, t("generic:percentage-ownership-max-100"));

export const MinorityShareSchema = (t) =>
  Yup.number()
    .required(t("generic:validation-required"))
    .min(1, t("generic:percentage-ownership-min-1"))
    .lessThan(25, t("generic:percentage-ownership-less-than-25"));

export const ShareSchema = (t) =>
  Yup.number()
    .required(t("generic:validation-required"))
    .min(1, t("generic:percentage-ownership-min-1"))
    .max(100, t("generic:percentage-ownership-max-100"));
