import OrderLei from "./OrderLei";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { UpsertOrder, UpsertOrderVariables } from "../../../lib/api/mutations/graphql/UpsertOrder";
import { UPSERT_ORDER } from "../../../lib/api/mutations/UPSERT_ORDER";
import {
  FinalizeOrder,
  FinalizeOrderVariables
} from "../../../lib/api/mutations/graphql/FinalizeOrder";
import { FINALIZE_ORDER } from "../../../lib/api/mutations/FINALIZE_ORDER";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

export const OrderLeiContainer = () => {
  const [callUpsertOrder, upsertOrderRequest] = useMutation<UpsertOrder, UpsertOrderVariables>(
    UPSERT_ORDER
  );

  const [callFinalizeOrder, finalizeOrderRequest] = useMutation<
    FinalizeOrder,
    FinalizeOrderVariables
  >(FINALIZE_ORDER);

  const upsertOrder = async (variables: UpsertOrderVariables) => {
    const result = await callUpsertOrder({ variables });
    return result.data?.upsertOrder;
  };

  const finalizeOrder = async (variables: FinalizeOrderVariables) => {
    const result = await callFinalizeOrder({ variables });
    return result.data?.finalizeOrder;
  };

  return (
    <RequestFeedbackHandler requests={[upsertOrderRequest, finalizeOrderRequest]}>
      <OrderLei orderData={null} upsertOrder={upsertOrder} finalizeOrder={finalizeOrder} />
    </RequestFeedbackHandler>
  );
};
