import gql from "graphql-tag";
import { GetEndOfYearTasksByCompany } from "./graphql/GetEndOfYearTasksByCompany";
import { useAdministrationQuery } from "lib/hooks/administration/useAdministrationQuery";

const GET_END_OF_YEAR_TASKS_BY_COMPANY = gql`
  query GetEndOfYearTasksByCompany($companyId: ID!) {
    getEndOfYearTasksByCompany(companyId: $companyId) {
      id
      companyId
      taskName
      requiredSince
      dueDate
      completedAt
      fiscalYearStart
      fiscalYearEnd
      referenceType
      referenceId
      extra
    }
  }
`;

export const GetEndOfYearTasksByCompanyQuery = (companyId: string) =>
  useAdministrationQuery<GetEndOfYearTasksByCompany>(GET_END_OF_YEAR_TASKS_BY_COMPANY, {
    variables: { companyId }
  });
