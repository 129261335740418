import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";

interface RideCardTableProps {
  title: string;
  fixed?: boolean;
  children: React.ReactNode;
  className?: string;
  "data-testid"?: string;
}

const RideCardTable = ({ title, fixed, children, className, ...props }: RideCardTableProps) => {
  return (
    <RideSectionCard
      title={title}
      className={`${className} no-padding`}
      data-testid={props["data-testid"]}
      fixed={fixed}>
      {children}
    </RideSectionCard>
  );
};

export default RideCardTable;
