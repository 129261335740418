import { Form, FormikProvider, useFormik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { CompanyFields } from "../../AdminService/Steps/CompanyInfo/CompanyFields";
import { wepaServiceLegalForms } from "../../../../common/staticData/LegalForms";
import React from "react";
import {
  CompanyAddress,
  FlowTypeEnum,
  LegalForm,
  OrdererCompanyData
} from "../../../../global-query-types";
import { TFunction } from "i18next";
import {
  StartWepaService,
  StartWepaServiceVariables
} from "../../../../lib/api/mutations/graphql/StartWepaService";
import { WepaServiceCompanyInfoSchema } from "../../../../lib/validation/WepaServiceCompanySchema";
import { storeToken } from "../../../../common/Authentication/storeToken";
import { ExecutionResult } from "graphql";
import { useHistory } from "react-router-dom";

interface WepaCompanyFormProps {
  t: TFunction;
  submitCompanyInfoHandler: (
    data: StartWepaServiceVariables
  ) => Promise<ExecutionResult<StartWepaService>>;
  taxAdvisorCode: string | undefined;
}

export const WepaCompanyForm = ({
  t,
  submitCompanyInfoHandler,
  taxAdvisorCode
}: WepaCompanyFormProps) => {
  const history = useHistory();

  const submitHandler = async (values) => {
    const companyInfo: OrdererCompanyData = {
      name: values.companyName,
      legalForm: values.legalForm,
      registration: values.registration,
      registerCourt: values.registerCourt
    };
    const businessAddress: CompanyAddress = {
      city: values.city,
      street: values.street,
      postalCode: values.postalCode
    };
    const result = await submitCompanyInfoHandler({
      companyInfo,
      businessAddress,
      taxAdvisorCode: taxAdvisorCode || ""
    });
    const startWepaServiceData = result.data?.startWepaService;
    const token = startWepaServiceData?.authentication?.token;
    if (!token) return;
    storeToken(token);
    history.push(`/wepa-service/${startWepaServiceData?.wepaService?.id}`);
  };

  const companyDetails = {
    companyName: "",
    legalForm: LegalForm.GmbH,
    registration: "",
    registerCourt: "",
    street: "",
    city: "",
    postalCode: ""
  };

  const formik = useFormik({
    initialValues: { ...companyDetails },
    validateOnChange: true,
    validationSchema: WepaServiceCompanyInfoSchema(),
    enableReinitialize: true,
    onSubmit: submitHandler
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Row>
          <Col className="steps-form-description" data-testid="order-step-description">
            {t("wepa-service:steps-description.COMPANY")}
          </Col>
        </Row>
        <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
          <Container>
            <Row>
              <Col>
                <CompanyFields
                  prefix={FlowTypeEnum.WEPA_SERVICE}
                  t={t}
                  formik={formik}
                  legalForms={wepaServiceLegalForms}
                  currentFlowType={FlowTypeEnum.WEPA_SERVICE}
                  disabled={false}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Form>
    </FormikProvider>
  );
};
