import React, { useState } from "react";
import { TFunction } from "i18next";
import { Button, Col, Row } from "react-bootstrap";
import { OrderNextSteps } from "../../../generic/Trader-GmbH/companyCreation/OrderNextSteps";
import { FiAtSign } from "react-icons/fi";
import { FlowTypeEnum } from "../../../../global-query-types";
import { OrderAssignment } from "../../../generic/Trader-GmbH/companyCreation/OrderAssignment";
import { CheckboxField } from "../../../generic/CheckboxField";
import { OrderAGBAgreement } from "../../../generic/Trader-GmbH/companyCreation/OrderAGBAgreement";
import logger from "../../../../common/Logger";
import { useHistory } from "react-router-dom";
import { GetWepaServiceById_getWepaServiceById } from "../../../../lib/api/queries/graphql/GetWepaServiceById";
import { PhoneIcon } from "../../../../sharedComponents/icons/PhoneIcon";

interface AccountCreationFormProps {
  t: TFunction;
  completeWepaServiceOrderHandler: () => Promise<void>;
  wepaService: GetWepaServiceById_getWepaServiceById;
}

export const AccountCreationForm = ({
  t,
  completeWepaServiceOrderHandler,
  wepaService
}: AccountCreationFormProps) => {
  const [agreed, setAgreed] = useState(false);
  const [localError, setLocalError] = useState("");
  const history = useHistory();

  const handleSubmit = async () => {
    setLocalError("");

    if (!agreed) {
      setLocalError(t("trader-gmbh:not-agreed-error"));
      return;
    }

    try {
      await completeWepaServiceOrderHandler();

      history.push(`/wepa-service/${wepaService.id}/status`);
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to complete the company creation order:`);
      logger.error(error);
    }
  };

  return (
    <>
      <div>
        <div className="step-bg">
          <div className="md-45 px-md-6 pb-md-45 mt-35 px-0 pb-0 pt-45">
            <OrderAssignment t={t} currentFlowType={FlowTypeEnum.WEPA_SERVICE} />
            <div className="align-agreement">
              <Row>
                <Col
                  data-testid="account-creation-fields"
                  className={
                    "mt-4 agreement-checkbox " + (localError ? "agreement-checkbox-error" : "")
                  }>
                  <CheckboxField
                    label={<OrderAGBAgreement t={t} currentFlowType={FlowTypeEnum.WEPA_SERVICE} />}
                    state={agreed}
                    name="WEPA_SERVICE-accept-agreement"
                    handleClick={(e) => setAgreed(e.target.checked)}
                  />
                </Col>
              </Row>
              {localError && (
                <Row>
                  <Col>
                    <div
                      className="invalid-feedback d-block"
                      data-testid="WEPA_SERVICE-agreement-invalid-feedback">
                      {localError}
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="mt-45">
                <Col xs={{ span: 4, offset: 8 }}>
                  <Button
                    variant="outline-light"
                    className="order-btn"
                    block
                    onClick={handleSubmit}
                    data-testid="WEPA_SERVICE-order-now">
                    {t("wepa-service:order-now")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="next-steps-card mt-5 px-md-6 px-3 py-35">
        <Row className="mt-md-5">
          <OrderNextSteps t={t} currentFlowType={FlowTypeEnum.WEPA_SERVICE} />
        </Row>
        <Row className="align-next-steps-btn mt-3">
          <Col xs={12} md={6} lg={5} className="mt-3">
            <a
              href={t(`trader-gmbh:email@.href.${FlowTypeEnum.WEPA_SERVICE}`)}
              className="btn btn-outline-light contact-btn-blk"
              data-testid="contact-email">
              <span className="mr-2">
                <FiAtSign size="20" />
              </span>
              {t(`trader-gmbh:email@.${FlowTypeEnum.WEPA_SERVICE}`)}
            </a>
          </Col>
          <Col xs={12} md={6} lg={5} className="mt-3">
            <a
              href={t(`generic:contact.phone.href`)}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-light contact-btn-blk"
              data-testid="contact-phone">
              <span className="mr-2">
                <PhoneIcon />
              </span>
              {t("generic:contact.phone.number")}
            </a>
            <br />
          </Col>
        </Row>
      </div>
    </>
  );
};
