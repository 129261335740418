import React from "react";
import UploadToS3 from "./UploadToS3";

interface ConfirmPhotoUploadProps {
  files: File[];
  submitHandler: () => void;
  realEstateId: string;
  onUploadStarted: () => void;
  isDisabled: boolean;
}

export const ConfirmPhotoUpload: React.FC<ConfirmPhotoUploadProps> = (props) => {
  const { files, submitHandler, realEstateId, onUploadStarted, isDisabled } = props;

  return (
    <UploadToS3
      files={files}
      submitHandler={submitHandler}
      realEstateId={realEstateId}
      onUploadStarted={onUploadStarted}
      isDisabled={isDisabled || !files.length}
    />
  );
};

export default ConfirmPhotoUpload;
