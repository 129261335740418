import React from "react";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideHeaderColumn } from "../../../lib/types/types";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell from "../../../sharedComponents/RideTableCell/RideTableCell";
import { FoundingServiceProcessTask } from "../../../common/types/ProcessStatesTypes";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { formatDateTime } from "../../../common/formatters";
import { TaskStatesEnum } from "global-query-types";
import {
  RideBadgeColorsEnum,
  RideBadgeSizeVariant,
  RideBadgeStatus
} from "uiLibrary/components/RideBadgeStatus";
import EntityLink, { EntityLinkColorVariant } from "components/common/EntityLink/EntityLink";
import { SortDirection } from "../../../sharedComponents/useSimpleSortBehaviour/SortDirection";

interface FoundationServiceTasksTableProps extends WithTranslation {
  tasks: FoundingServiceProcessTask[];
  sortCallback: ({ sortColumn, sortOrder }: { sortColumn: string; sortOrder: string }) => void;
}

enum FoundationServiceTasksColumnsEnum {
  "task" = "ref",
  "company" = "",
  "owner" = "responsibleParty",
  "status" = "state",
  "last change" = "updatedAt"
}

const FoundationServiceTasksTable = ({
  tasks,
  t,
  sortCallback
}: FoundationServiceTasksTableProps) => {
  const columns: RideHeaderColumn[] = ["Task", "Company", "Owner", "Status", "Last Change"].map(
    (label) => ({
      label,
      name: label.toLowerCase()
    })
  );

  const TaskStateToColorMap = {
    [TaskStatesEnum.COMPLETED]: RideBadgeColorsEnum.INFO,
    [TaskStatesEnum.IN_PROGRESS]: RideBadgeColorsEnum.WARNING,
    [TaskStatesEnum.NOT_POSSIBLE_YET]: RideBadgeColorsEnum.DARK,
    [TaskStatesEnum.NOT_REQUIRED]: RideBadgeColorsEnum.LIGHT_GREY,
    [TaskStatesEnum.TO_BE_DONE]: RideBadgeColorsEnum.DANGER
  };

  const sortClickHandler = (column: RideHeaderColumn, sortDirection?: SortDirection) => {
    sortCallback({
      sortColumn: FoundationServiceTasksColumnsEnum[column.name],
      sortOrder:
        sortDirection === SortDirection.ASCENDING
          ? SortDirection.ASCENDING
          : SortDirection.DESCENDING
    });
  };

  return (
    <div data-testid="foundation-service-tasks-table">
      <RideTableHeader isColumnsDisplay={true} columns={columns} sortCallback={sortClickHandler} />
      {tasks.map((task, index) => (
        <RideTableRow colCount={columns.length} key={index}>
          <RideTableCell value={t(`company-founding-steps:tasks.name.${task.ref}`)} />
          <RideTableCell
            value={
              <EntityLink
                membership={{ company: task.companyCreation.company }}
                testIdPrefix="client-entity"
                colorVariant={EntityLinkColorVariant.GREY}
              />
            }
          />
          <RideTableCell
            value={t(
              `company-founding-steps:owners-lists.${(
                task.responsibleParty ?? "null"
              ).toLowerCase()}`
            )}
          />
          <RideTableCell
            value={
              <RideBadgeStatus
                label={t(`company-founding-steps:tasks.state.${task.state}`)}
                color={TaskStateToColorMap[task.state]}
                sizeVariant={RideBadgeSizeVariant.SMALL}
              />
            }
          />
          <RideTableCell value={formatDateTime(task.updatedAt)} />
        </RideTableRow>
      ))}
    </div>
  );
};

export default withTranslationReady("company-founding-steps")(FoundationServiceTasksTable);
