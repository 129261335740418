import { UserRole } from "../../../global-query-types";
import { useMutation } from "react-apollo";
import {
  SignupMutation,
  SignupMutationVariables
} from "../../../lib/api/mutations/graphql/SignupMutation";
import { SIGNUP_MUTATION } from "../../../lib/api/mutations/SIGNUP";
import { storeToken } from "../../../common/Authentication/storeToken";
import logger from "../../../common/Logger";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import React from "react";
import Signup from "./Signup";

interface SignupContainerProps {
  asUser?: UserRole;
}

export const SignupContainer = ({ asUser = UserRole.Client }: SignupContainerProps) => {
  const [signupMutation, signupMutationRequest] = useMutation<
    SignupMutation,
    SignupMutationVariables
  >(SIGNUP_MUTATION);

  const handleSignup = async (password: string, signupToken: string): Promise<boolean> => {
    const result = await signupMutation({
      variables: {
        password: password,
        signupToken: signupToken
      }
    });

    const signUpData = result?.data?.signup;
    if (signUpData != null && signUpData.token != null) {
      storeToken(signUpData.token);
    } else {
      logger.error(new Error("Signup successful but no token returned"));
      return false;
    }

    return true;
  };

  return (
    <RequestFeedbackHandler requests={[signupMutationRequest]}>
      <Signup asUser={asUser} handleSignUp={handleSignup} />
    </RequestFeedbackHandler>
  );
};
