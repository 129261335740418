import React, { FC, ReactNode } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";
import RideModal, {
  RideModalBody,
  RideModalControls
} from "uiLibrary/designSystem/RideModal/RideModal";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { ArrowRight } from "sharedComponents/icons/DesignSystemIcons/ArrowRight";
import { FxFlatIcon } from "sharedComponents/icons/DesignSystemIcons/FxFlatIcon";
import { InteractiveBrokersIcon } from "sharedComponents/icons/DesignSystemIcons/InteractiveBrokersIcon";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

export interface ProductLearnMoreModalProps {
  productName: string;
  description: ReactNode;
  features: ProductFeatureProps[];
  price: string;
  onSelectProduct: () => void;
  onClose: () => void;
}

export interface ProductFeatureProps {
  icon: FC<IconProps>;
  tagline: string;
  description: string;
}

const ProductLearnMoreModal = ({
  productName,
  description,
  features,
  price,
  onClose,
  onSelectProduct,
  t
}: ProductLearnMoreModalProps & WithTranslation) => {
  const isLTBProduct: boolean = productName === t("generic:product-card.low-tax-broker.title");

  const isRIDEBrokerProduct: boolean =
    productName === t(rideBrokerT("generic:product-card.low-tax-broker.title"));

  return (
    <div className="product-learn-more-modal">
      <RideModal onClose={onClose} title="" data-testid="product-learn-more-modal" fitContentHeight>
        <RideModalBody className="product-learn-more-modal__body">
          <Typography
            className="product-learn-more-modal__title"
            data-testid="product-learn-more-modal-name"
            category="Headline"
            size={100}
            weight="Heavy">
            {productName}
          </Typography>
          <Typography
            className="product-learn-more-modal__description"
            data-testid="product-learn-more-modal-description"
            category="Paragraph"
            size={100}
            weight="Light">
            {description}
          </Typography>
          <div className="product-learn-more-modal__feature-list">
            {features.map((feature) => (
              <FeatureItem key={feature.tagline} {...feature} />
            ))}
          </div>
          <div className="product-learn-more-modal__price_and_partners">
            <div className="product-learn-more-modal__price-section">
              <Typography
                className="product-learn-more-modal__price-label"
                data-testid="product-learn-more-modal-price-label"
                category="Paragraph"
                size={200}
                weight="Light">
                {t("generic:price")}
              </Typography>
              <Typography
                className="product-learn-more-modal__price"
                data-testid="product-learn-more-modal-price"
                category="Headline"
                size={100}
                weight="Heavy">
                <Trans
                  defaults={price}
                  components={{
                    Small: <Typography category="Headline" size={300} weight="Heavy" />
                  }}
                />
              </Typography>
            </div>
            {(isLTBProduct || isRIDEBrokerProduct) && (
              <BrokerPartners newBroker={isRIDEBrokerProduct} />
            )}
          </div>
        </RideModalBody>
        <RideModalControls>
          <RideButton
            data-testid="product-learn-more-modal-select-button"
            label={t("generic:select-product")}
            rightIcon={<ArrowRight />}
            variant={RideButtonVariant.PRIMARY}
            size={RideButtonSize.BIG}
            type="button"
            onClick={onSelectProduct}
          />
        </RideModalControls>
      </RideModal>
    </div>
  );
};

const FeatureItem = ({ icon: Icon, tagline, description }: ProductFeatureProps) => (
  <div className="feature-item" data-testid="feature">
    <div className="feature-item__icon" data-testid="feature-icon">
      <Icon />
    </div>
    <div>
      <Typography category="Paragraph" size={100} weight="Heavy" data-testid="feature-tagline">
        {tagline}
      </Typography>
      <br />
      <Typography category="Paragraph" size={100} weight="Light" data-testid="feature-description">
        {description}
      </Typography>
    </div>
  </div>
);

export const BrokerPartners = withTranslationReady(["generic"])(
  ({ t, newBroker }: { newBroker: boolean } & WithTranslation) => {
    return (
      <div
        data-testid={"low-tax-broker-modal-partners"}
        className={"low-tax-broker-modal-partners"}>
        <Typography category="Paragraph" size={200} weight="Light">
          {t("generic:partners-section-text")}
        </Typography>
        <div className={"low-tax-broker-modal-partners__logos"}>
          {!newBroker && <FxFlatIcon className="low-tax-broker-modal-partners__partner-logo" />}
          <InteractiveBrokersIcon className="low-tax-broker-modal-partners__partner-logo" />
        </div>
      </div>
    );
  }
);

export default withTranslationReady(["generic"])(ProductLearnMoreModal);
