import React, { useState } from "react";
import { useField } from "formik";
import { getSeparator } from "lib/formatters/numberFormatter";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";

export interface RideCurrencyInputProps {
  name: string;
  label?: string;
}

export const RideCurrencyInput = ({ name, label }: RideCurrencyInputProps) => {
  const [field, meta, helpers] = useField(name);
  const [displayValue, setDisplayValue] = useState(
    DisplayFormat.format(StorageFormat.parse(field.value))
  );

  const setFormValue = (value: string) => helpers.setValue(value);

  const setValueFromInput = (inputValue: string, formatOptions?: FormatOptions) => {
    const parsedNumber = DisplayFormat.parse(inputValue);

    setDisplayValue(DisplayFormat.format(parsedNumber, formatOptions));
    setFormValue(StorageFormat.format(parsedNumber));
  };

  return (
    <div data-testid={`${name}-input-container`} className="ride-currency-input">
      {label && (
        <label data-testid={`${name}-label`} className="ride-currency-input__label">
          {label}
        </label>
      )}
      <div className="ride-currency-input__input__wrapper">
        <span
          className={`ride-currency-input__input__currency-symbol ${
            meta.touched || field.value ? "" : "ride-currency-input__input-placeholder"
          }`}>
          €
        </span>
        <input
          className="ride-currency-input__input"
          data-testid={`${name}-currency-input`}
          inputMode="numeric"
          placeholder="0"
          onFocus={() => helpers.setTouched(true)}
          onBlur={() => setValueFromInput(displayValue, { autofillDecimal: true })}
          onChange={(event) => setValueFromInput(event.target.value)}
          value={displayValue}
        />
        <input
          name={name}
          data-testid={`${name}-currency-input-value`}
          type="hidden"
          value={field.value ?? ""}
        />
        <ErrorMessageWithT name={name} extra={{}} />
      </div>
    </div>
  );
};

interface FormatOptions {
  autofillDecimal: boolean;
}

interface ParsedNumber {
  integerPart: string;
  decimalPart?: string;
}

class DisplayFormat {
  static format(parts: ParsedNumber | undefined, options?: FormatOptions): string {
    if (!parts) {
      return "";
    }

    const { integerPart, decimalPart } = parts;

    return formatGroups(integerPart) + formatDecimal(decimalPart, options);
  }

  static parse(value: string | undefined): ParsedNumber | undefined {
    if (!value) {
      return undefined;
    }

    const decimalSeparator = getDecimalSeparator();
    const decimalSeparatorIndex = value.indexOf(decimalSeparator);
    const hasDecimalSeparator = decimalSeparatorIndex >= 0;

    if (hasDecimalSeparator) {
      return {
        integerPart: removeNonNumeric(value.substring(0, decimalSeparatorIndex)),
        decimalPart: removeNonNumeric(value.substring(decimalSeparatorIndex + 1))
      };
    } else {
      return {
        integerPart: removeNonNumeric(value)
      };
    }
  }
}

class StorageFormat {
  static format(parts: ParsedNumber | undefined): string {
    if (!parts) {
      return "";
    }

    const { integerPart, decimalPart } = parts;

    if (!integerPart && !decimalPart) {
      return "";
    }

    return integerPart + "." + completeDecimalPart(decimalPart ?? "00");
  }

  static parse(value: string | undefined): ParsedNumber | undefined {
    if (!value) {
      return undefined;
    }

    const decimalSeparatorIndex = value.indexOf(".");

    return {
      integerPart: value.substring(0, decimalSeparatorIndex),
      decimalPart: value.substring(decimalSeparatorIndex + 1)
    };
  }
}

const removeNonNumeric = (value: string): string => {
  return value.replaceAll(/\D/g, "");
};

const formatGroups = (integerPart: string): string => {
  return integerPart.replaceAll(/\B(?=(\d{3})+(?!\d))/g, getGroupSeparator());
};

const formatDecimal = (decimalPart: string | undefined, options?: FormatOptions): string => {
  const decimalSeparator = getDecimalSeparator();

  if (decimalPart === undefined) {
    return options?.autofillDecimal ? decimalSeparator + "00" : "";
  } else {
    return options?.autofillDecimal
      ? decimalSeparator + completeDecimalPart(decimalPart)
      : decimalSeparator + limitDecimalPart(decimalPart);
  }
};

const completeDecimalPart = (decimalPart: string): string => {
  return limitDecimalPart(decimalPart + "00");
};

const limitDecimalPart = (decimalPart: string): string => {
  return decimalPart.substring(0, 2);
};

const getDecimalSeparator = () => getSeparator("decimal");

const getGroupSeparator = () => getSeparator("group");
