import * as Yup from "yup";

export const CompanyInformationSchema = (t) => {
  return Yup.object().shape({
    companyStreet: Yup.string().required(t("generic:validation-required")),
    companyPostalCode: Yup.string().required(t("generic:validation-required")),
    companyCity: Yup.string().required(t("generic:validation-required")),
    companyFullName: Yup.string().required(t("generic:validation-required")),
    companyRegistryNumber: Yup.string().required(t("generic:validation-required")),
    companyRegistryCourt: Yup.string().required(t("generic:validation-required"))
  });
};
