import { WithTranslation } from "react-i18next";
import { RideStatus } from "lib/types/types";
import {
  RideBadgeColorsEnum,
  RideBadgeSizeVariant,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components";
import { RideBadgeIconEnum } from "uiLibrary/components/RideBadgeStatus/RideBadgeStatus";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";

interface DocumentStatusBadgeProps {
  status: RideStatus.COMPLETED | RideStatus.PENDING;
}

export const DocumentStatusBadge = withTranslationReady(["generic"])(
  ({ t, status }: DocumentStatusBadgeProps & WithTranslation) => {
    return (
      <div className="document-status-badge">
        <RideBadgeStatus
          color={RideBadgeColorsEnum.GMBH_BADGE_STATUS}
          variant={RideBadgeVariantsEnum.SOLID}
          sizeVariant={RideBadgeSizeVariant.SMALL}
          label={t(`generic:document-card.status.${status}`)}
          iconVariant={
            status === RideStatus.COMPLETED ? RideBadgeIconEnum.GREEN : RideBadgeIconEnum.ORANGE
          }
        />
      </div>
    );
  }
);
