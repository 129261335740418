import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import {
  RideRadioSelect,
  SelectOption
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { TradingIntentionType } from "lib/models/client/LowTaxBroker/TradingIntention";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";

const TradingIntentionStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        ...currentStep.chapter.order.serialize(),
        tradingIntention: values["trading-intention"]
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: {
      "trading-intention": order.extra?.tradingIntention
    },
    onSubmit,
    validate(values) {
      if (!values["trading-intention"]) {
        return { error: t("generic:validation-required") };
      }
    }
  });

  const options: SelectOption[] = Object.values(TradingIntentionType).map((tradingIntention) => ({
    label: t(`generic:ride-broker-onboarding.trading-intention.options.${tradingIntention}`),
    value: tradingIntention
  }));

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.trading-intention.title")} />
        <RideRadioSelect name={"trading-intention"} options={options} columns={1} />
        <InvalidFeedback hidden={!formik.errors["error"]} testPrefix="error">
          {formik.errors["error"]}
        </InvalidFeedback>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(TradingIntentionStep);
