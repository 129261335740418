import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { ArrowRight } from "sharedComponents/icons/DesignSystemIcons/ArrowRight";
import { useHistory } from "react-router-dom";

interface ThankYouPageProps {
  tier: string | undefined;
  continueUrl: string;
}

const ThankYouPage = ({ tier, continueUrl, t }: ThankYouPageProps & WithTranslation) => {
  const history = useHistory();

  const className = "admin-service-onboarding-thank-you-page";
  return (
    <div data-testid={"thank-you-page"} className={className}>
      <Typography
        category={"Headline"}
        size={200}
        weight={"Heavy"}
        data-testid={"title"}
        className={`${className}__title`}>
        {t("generic:admin-service-tiers-onboarding.thank-you.title")}
      </Typography>
      <Typography category={"Paragraph"} size={100} weight={"Light"} data-testid={"description"}>
        <Trans
          i18nKey={"generic:admin-service-tiers-onboarding.thank-you.description"}
          values={{ tier: tier ? capitalizeFirstLetter(tier) : "" }}
        />
      </Typography>
      <RideButton
        data-testid={"continue-button"}
        label={t("generic:admin-service-tiers-onboarding.thank-you.continue-button")}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        className={`${className}__button`}
        rightIcon={<ArrowRight />}
        onClick={() => history.push(continueUrl)}
      />
    </div>
  );
};

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export default withTranslationReady(["generic"])(ThankYouPage);
