import { Col, Row } from "react-bootstrap";
import { EntryDateField } from "../../../../../generic/commonFields";
import React from "react";
import { TFunction } from "react-i18next";
import { FormikProps } from "formik";

interface IProps {
  t: TFunction;
  formik: FormikProps<any>;
}

export const FoundationDateInput = ({ formik, t }: IProps) => {
  return (
    <Row className="mt-5">
      <Col>
        <EntryDateField
          t={t}
          formik={formik}
          name="foundationDate"
          label={t("nominee-dashboard:company.foundation-date")}
        />
      </Col>
    </Row>
  );
};
