import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { FileUploadService } from "../../lib/services/FileUploadService/FileUploadService";
import { StorageNodesEntityType } from "../../global-query-types";
import TextLinkAsButton from "../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import { LoadingBar } from "../LoadingBar/LoadingBar";
import { RemoteStorageNode } from "../../lib/dataAdapters/toRemoteStorageNode";
import { InvalidFeedback } from "../../uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AddIcon } from "sharedComponents/icons/add";
import UploadWrapper, { UploadNodeState } from "sharedComponents/UploadWrapper/UploadWrapper";

interface UploadToFolderProps extends WithTranslation {
  entityId: string;
  destinationNodeId: string;
  uploadService: FileUploadService;
  entityType: StorageNodesEntityType;
  onUploaded: (storageNode: RemoteStorageNode) => Promise<void>;
}

const UploadToFolder = ({
  t,
  uploadService,
  destinationNodeId,
  entityType,
  entityId,
  onUploaded
}: UploadToFolderProps) => (
  <UploadWrapper
    uploadService={uploadService}
    destinationNodeId={destinationNodeId}
    entityType={entityType}
    entityId={entityId}
    onUploaded={async (nodes) => {
      if (nodes[0]) {
        await onUploaded(nodes[0]);
      }
    }}
    noDragAndDrop>
    {({ fileNodes, onReset, openFileSelectPopUp }) => {
      const isUploaded = fileNodes[0]?.uploadingState === UploadNodeState.UPLOADED;
      const isUploading = fileNodes[0]?.uploadingState === UploadNodeState.UPLOADING;
      const hasError = fileNodes[0]?.uploadingState === UploadNodeState.FAILED;
      const fileName = fileNodes[0]?.name;

      return (
        <div data-testid="upload-files-to-folder" className="upload-to-folder">
          {!isUploading && !isUploaded && !hasError && (
            <RideButtonDeprecated
              size={ButtonSize.Medium}
              variant={ButtonVariant.Secondary}
              hasIcon={true}
              onClick={openFileSelectPopUp}
              data-testid="select-file-button">
              <AddIcon />
              {t("generic:upload")}
            </RideButtonDeprecated>
          )}
          {isUploaded && <div data-testid="upload-completed-icon" />}
          {fileName && (
            <div
              data-testid="file-name"
              className={`upload-to-folder__filename ${
                hasError ? "upload-to-folder__filename--error" : ""
              }`}>
              {fileName}
            </div>
          )}
          {isUploading && <LoadingBar />}
          {hasError && (
            <div className="upload-to-folder__error-feedback">
              <InvalidFeedback hidden={false}>
                {t("generic:upload-invalid-feedback")}
              </InvalidFeedback>
              <TextLinkAsButton
                className="upload-to-folder__repeat-link"
                onClick={onReset}
                data-testid="upload-repeat">
                {t("generic:repeat")}
              </TextLinkAsButton>
            </div>
          )}
        </div>
      );
    }}
  </UploadWrapper>
);

export default withTranslationReady(["generic"])(UploadToFolder);
