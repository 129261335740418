import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import CodeInput from "../CodeInput/CodeInput";
import TextLinkAsButton from "../../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import moment from "moment";

interface CodeFormProps extends WithTranslation {
  onVerifyClick: (code: string) => Promise<boolean | undefined | null>;
  phoneNumber: string;
  sendSmsForTwoFactorAuth: () => Promise<Date | undefined | null>;
  nextSmsRequestAvailableAt?: Date;
}

const CodeForm = ({
  t,
  onVerifyClick,
  phoneNumber,
  sendSmsForTwoFactorAuth,
  nextSmsRequestAvailableAt
}: CodeFormProps) => {
  const calculateTimeLeftInSeconds = () => {
    if (!smsRequestAvailableAt) {
      return 0;
    }

    const now = moment.now();
    let result = Math.round((smsRequestAvailableAt.getTime() - now) / 1000);

    return result > 0 ? result : 0;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [validCode, setValidCode] = useState(true);
  const [smsRequestAvailableAt, setSmsRequestAvailableAt] = useState(
    nextSmsRequestAvailableAt ?? moment().add(60, "second").toDate()
  );
  const [timeLeft, setTimeLeft] = useState(
    nextSmsRequestAvailableAt ? calculateTimeLeftInSeconds() : 60
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeftInSeconds());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState = (setState: () => void) => {
    if (mountedRef.current) {
      setState();
    }
  };

  const formik = useFormik({
    initialValues: {
      code: ["", "", "", "", "", ""]
    },
    onSubmit: async ({ code }) => {
      safeSetState(() => setIsLoading(true));

      const verificationCode = code.join("");

      if (verificationCode.length === 6) {
        safeSetState(() => setValidCode(true));
        const verified = await onVerifyClick(verificationCode);

        if (verified === false) {
          safeSetState(() => setValidCode(false));
        }
      } else {
        safeSetState(() => setValidCode(false));
      }
      safeSetState(() => setIsLoading(false));
    },
    validateOnChange: true
  });

  const onClickReSendCode = async () => {
    const nextSmsRequestAvailableAt = await sendSmsForTwoFactorAuth();
    safeSetState(() =>
      setSmsRequestAvailableAt(nextSmsRequestAvailableAt ?? moment().add(60, "second").toDate())
    );
  };

  const onChange = (value, index) => {
    setValidCode(true);
    const values = formik.values.code;
    values[index] = value;
    formik.setValues({ code: values });
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`;
  };

  return (
    <div data-testid="code-form" className="codeForm">
      <div className="codeForm__description" data-testid="code-form-description">
        <Trans
          i18nKey={"auth:code-form.description"}
          values={{ phoneNumber: formatPhoneNumber(phoneNumber) }}
        />
      </div>
      <FormikProvider value={formik}>
        <Form>
          <div className="codeForm__title" data-testid="label-code">
            {t("auth:code-form.label-code")}
          </div>
          <CodeInput
            className="codeForm__codeInput"
            inputChange={onChange}
            isCodeValid={validCode}
          />
          {!validCode && <div className="codeForm__error">{t("auth:code-form.error")}</div>}
          {timeLeft <= 0 ? (
            <div className="codeForm__resendLink">
              <TextLinkAsButton onClick={onClickReSendCode} data-testid="re-send-code">
                {t("auth:code-form.re-send-code")}
              </TextLinkAsButton>
            </div>
          ) : (
            <div data-testid="code-form-countdown" className="codeForm__countdown">
              <Trans
                i18nKey={"auth:code-form.countdown-text"}
                values={{ remainingTime: timeLeft }}
              />
            </div>
          )}
          <div className="codeForm__saveButton">
            <RideButtonDeprecated
              data-testid="verify-code-button"
              variant={ButtonVariant.Primary}
              isLoading={isLoading}
              type="submit">
              {t("auth:code-form.verify-code-button")}
            </RideButtonDeprecated>
          </div>
          <div className="codeForm__adviceText" data-testid="advice-text">
            <Trans
              i18nKey={"auth:code-form.advice-text"}
              components={{
                addLinkHere: <TextLink to={`#`} variant={LinkVariant.primary} />,
                contactLinkHere: <TextLink to={`#`} variant={LinkVariant.primary} />
              }}
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["auth"])(CodeForm);
