import React, { useEffect } from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { WithTranslation } from "react-i18next";
import { RideCurrencyInput } from "uiLibrary/components/RideCurrencyInput/RideCurrencyInput";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import * as Yup from "yup";
import { requiredPositiveNumber } from "lib/validation/requiredPositiveNumber";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";

const IntendedInitialInvestmentStep = withTranslationReady(["generic"])(
  ({
    saveData,
    order,
    controls,
    currentStep,
    t
  }: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
    useEffect(() => {
      if (FeatureFlagService.instance.isEnabled(FeatureFlags.removeIntendedInitialInvestmentStep)) {
        currentStep.complete();

        saveData({ ...order.extra, ...currentStep.chapter.order.serialize() }, false).then();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = {
      intendedInitialInvestment: order.extra.intendedInitialInvestment ?? 0
    };

    const onSubmit = async (values) => {
      const contextStep = currentStep.chapter.getStepByKey(
        "compliance-intended-initial-investment-context"
      );
      if (parseFloat(values.intendedInitialInvestment) > 200000) {
        !contextStep &&
          currentStep.insertAfter({ key: "compliance-intended-initial-investment-context" });
      } else {
        contextStep?.remove();
      }

      currentStep.complete();

      await saveData(
        {
          ...order.extra,
          intendedInitialInvestment: values.intendedInitialInvestment,
          ...currentStep.chapter.order.serialize()
        },
        false
      );
    };

    const formik = useRideFormik({
      initialValues: initialValues,
      onSubmit: onSubmit,
      validationSchema: Yup.object({
        intendedInitialInvestment: requiredPositiveNumber(t)
      })
    });

    return (
      <DisabledFeatureFlag name={FeatureFlags.removeIntendedInitialInvestmentStep}>
        <div data-testid="intended-initial-investment-step">
          <FormikProvider value={formik}>
            <Form>
              <IntendedInitialInvestment />
              {controls}
            </Form>
          </FormikProvider>
        </div>
      </DisabledFeatureFlag>
    );
  }
);

const IntendedInitialInvestment = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div data-testid="intended-initial-investment">
      <StepHeading text={t("generic:ride-broker-onboarding.intended-initial-investment.title")} />
      <RideCurrencyInput
        name={"intendedInitialInvestment"}
        label={t("generic:ride-broker-onboarding.intended-initial-investment.label")}
      />
    </div>
  );
});

export default IntendedInitialInvestmentStep;
