import { NomineeCompanyDetails_companyById } from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { ValueView } from "../../../components/common/layout/ValueView";
import { RideButtonDeprecated } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";

interface HubspotIntegrationSectionProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
  syncCompanyInHubspot: (values: { companyCreationId: string }) => void;
}

const HubspotIntegrationSection = ({
  company,
  syncCompanyInHubspot,
  t
}: HubspotIntegrationSectionProps) => {
  const companyCreation = company.companyCreation;
  const adminService = company.adminService;

  return (
    <Row data-testid="hubspot-integration-section">
      <Col>
        <h2>{t("nominee-dashboard:company.hubspot-integration")}</h2>
        <p>{t("nominee-dashboard:company.hubspot-integration-description")}</p>

        <ValueView
          name="nominee-dashboard:company.ride-creation-id-gs"
          value={companyCreation?.id}
          testId="company-creation-id"
          t={t}
        />
        <ValueView
          name="nominee-dashboard:company.ride-creation-id-as"
          value={adminService?.id}
          testId="admin-service-creation-id"
          t={t}
        />
        {companyCreation && (
          <RideButtonDeprecated
            onClick={() => syncCompanyInHubspot({ companyCreationId: companyCreation.id })}
            data-testid="ride-button-hubspot-sync">
            {t("nominee-dashboard:company.hubspot-integration-sync")}
          </RideButtonDeprecated>
        )}
        {adminService && (
          <FeatureFlag name={FeatureFlags.SyncASDealFromADB}>
            <RideButtonDeprecated
              onClick={() => syncCompanyInHubspot({ companyCreationId: adminService.id })}
              data-testid="ride-button-hubspot-sync-as">
              {`${t("nominee-dashboard:company.hubspot-integration-sync")} (Admin-Service)`}
            </RideButtonDeprecated>
          </FeatureFlag>
        )}
      </Col>
    </Row>
  );
};

export default withTranslationReady(["generic"])(HubspotIntegrationSection);
