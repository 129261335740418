import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { useHistory } from "react-router-dom";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

interface UpgradeAdminServiceToSelectTierBannerProps extends WithTranslation {
  companyId: string;
}

const UpgradeAdminServiceToSelectTierBanner = ({
  t,
  companyId
}: UpgradeAdminServiceToSelectTierBannerProps) => {
  const { push } = useHistory();

  const goToMigrateAdminServicePackageLink = () => {
    push(`/client/entities/${companyId}/admin-service/migrate`);
  };

  return (
    <span className={"upgrade-admin-service-to-select-tier-banner"}>
      <Typography
        data-testid="upgrade-admin-service-to-select-tier-banner"
        category={"Paragraph"}
        size={200}
        weight={"Light"}
        className={"upgrade-admin-service-to-select-tier-banner__content"}>
        <span data-testid="title" className="upgrade-admin-service-to-select-tier-banner__title">
          {t("generic:migrate-admin-service-package.information-banner.title")}
        </span>
        <span data-testid="message">
          <Trans i18nKey={t("generic:migrate-admin-service-package.information-banner.message")} />
        </span>
      </Typography>
      <RideButton
        size={RideButtonSize.SMALL}
        variant={RideButtonVariant.SECONDARY}
        className="upgrade-admin-service-to-select-tier-banner__button"
        data-testid={"go-to-migrate-admin-service-package-button"}
        label={t("generic:migrate-admin-service-package.information-banner.button")}
        onClick={goToMigrateAdminServicePackageLink}
      />
    </span>
  );
};

export default withTranslationReady(["generic"])(UpgradeAdminServiceToSelectTierBanner);
