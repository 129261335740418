import { BankType } from "../../../../global-query-types";
import React from "react";

interface BankIconProps {
  bank: BankType;
  className?: string;
}

const BankIcon = ({ bank, className = "" }: BankIconProps) => {
  return (
    <img
      data-testid="bank-icon"
      className={`bank-icon ${className}`}
      src={`/assets/client/banks/${bank}.svg`}
      alt={bank}
    />
  );
};

export default BankIcon;
