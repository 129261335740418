import * as React from "react";

export const NotSelectedOption = (props?) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="not-selected-option"
      {...props}>
      <rect x={0.5} y={0.5} width={23} height={23} rx={11.5} fill="#fff" stroke="#E1E1E1" />
    </svg>
  );
};
