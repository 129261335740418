import { TFunction } from "i18next";
import React from "react";
import { AllDiscountCodes } from "../../../lib/api/queries/AllDiscountCodes";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { ReferralCodeUsagesTable } from "./ReferralCodeUsagesTable";

export const ReferralCodeUsagesTableContainer = ({ t, code }: { t: TFunction; code: string }) => {
  const discountCodesRequest = AllDiscountCodes();

  return (
    <RequestFeedbackHandler requests={[discountCodesRequest]}>
      <ReferralCodeUsagesTable
        t={t}
        code={code}
        allCodes={discountCodesRequest.data?.allDiscountCodes ?? []}
      />
    </RequestFeedbackHandler>
  );
};
