import {
  EXTERNAL_CONTENT_FALLBACK,
  ExternalContentItem,
  ExternalContentNamespace,
  ExternalContentProvider
} from "lib/ports/ExternalContentProvider";
import * as queryString from "query-string";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/types/dist/severity";
import { BackOffService } from "../../services/BackOffService/BackOffService";

export interface HubDBResponseTable {
  total: number;
  results: HubDBResponseRow[];
}

interface HubDBResponseRow {
  id: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  path: string | null;
  name: string | null;
  childTableId: string;
  values: ExternalContentItem;
}

// @ts-ignore
const myFetch = (...args: any[]) => fetch(...args);

export class HubDBExternalContentProvider implements ExternalContentProvider {
  private readonly cache: Map<ExternalContentNamespace, Map<string, ExternalContentItem>> =
    new Map();
  private readonly fullNamespaceCache: Map<ExternalContentNamespace, any[]> = new Map();
  private backOff: BackOffService<Response>;

  constructor(
    private readonly baseUrl: string,
    private readonly portalId: string,
    private readonly namespaces: Record<ExternalContentNamespace, string>,
    private readonly fetchFn: typeof global.fetch = myFetch
  ) {
    this.backOff = new BackOffService<Response>({
      retryIf: (result) => result.status === 429,
      logPrefix: "HubDBExternalContentProvider"
    });
  }

  async getExternalContentByKey(
    namespace: ExternalContentNamespace,
    name: string,
    suffix?: string
  ): Promise<ExternalContentItem> {
    const results = await this.getResults(namespace);
    return results?.get(`${name}-${suffix}`) ?? results?.get(name) ?? EXTERNAL_CONTENT_FALLBACK;
  }

  async getFullNamespace(namespace: ExternalContentNamespace): Promise<any[]> {
    if (this.fullNamespaceCache.get(namespace)) {
      return this.fullNamespaceCache.get(namespace)!;
    }

    const tableId = this.namespaces[namespace];
    const queryParams = queryString.stringify({ portalId: this.portalId, limit: 100 });
    const url = `${this.baseUrl}/tables/${tableId}/rows?${queryParams}`;

    try {
      const response = await this.backOff.execute(() => this.fetchFn(url));

      if (!response.ok) {
        Sentry.captureMessage(`Failure response of HubDB trying to GET ${url}`, Severity.Warning);
        return [];
      }

      const data = (await response.json()) as HubDBResponseTable;

      const results = new Array<any>();

      for (const result of data.results) {
        results.push({
          ...result.values,
          id: result.id
        });
      }

      this.fullNamespaceCache.set(namespace, results);

      return results;
    } catch (e) {
      if (e instanceof TypeError) {
        throw e;
      }
      Sentry.captureException(e);
      return [];
    }
  }

  private async getResults(
    namespace: ExternalContentNamespace
  ): Promise<Map<string, ExternalContentItem> | undefined> {
    if (this.cache.get(namespace)) {
      return this.cache.get(namespace);
    }

    const tableId = this.namespaces[namespace];
    const queryParams = queryString.stringify({ portalId: this.portalId, limit: 100 });
    const url = `${this.baseUrl}/tables/${tableId}/rows?${queryParams}`;

    try {
      const response = await this.backOff.execute(() => this.fetchFn(url));

      if (!response.ok) {
        Sentry.captureMessage(`Failure response of HubDB trying to GET ${url}`, Severity.Warning);
        return undefined;
      }

      const data = (await response.json()) as HubDBResponseTable;

      const results = new Map<string, ExternalContentItem>();

      for (const result of data.results) {
        if (results.has(result.values.name)) continue;
        results.set(result.values.name, result.values);
      }

      this.cache.set(namespace, results);

      return results;
    } catch (e) {
      if (e instanceof TypeError) {
        throw e;
      }
      Sentry.captureException(e);
      return undefined;
    }
  }
}
