import React from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { FoundationServiceTasksContainer } from "../../components/FoundationServiceTasks/FoundationServiceTasksContainer";

const FoundationServiceTasksScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:foundation-service-tasks")}</h2>
        </Col>
      </Row>
      <FoundationServiceTasksContainer
        searchPlaceholder={t("nominee-dashboard:foundation-service-tasks.search-placeholder")}
      />
    </>
  );
};

export default withTranslation("header")(FoundationServiceTasksScene);
