import React from "react";
import { ArchiveService } from "../ArchiveService/ArchiveService";
import { TFunction } from "i18next";
import { getAllWepaServices_getAllWepaServices } from "../../../lib/api/queries/graphql/getAllWepaServices";
import { useMutation } from "react-apollo";
import { ARCHIVE_WEPA_SERVICE } from "../../../lib/api/mutations/ARCHIVE_WEPA_SERVICE";
import {
  ArchiveWepaService,
  ArchiveWepaServiceVariables
} from "../../../lib/api/mutations/graphql/ArchiveWepaService";

interface WepaArchiveContainerProps {
  wepaService: getAllWepaServices_getAllWepaServices;
  t: TFunction;
  refetch: () => any;
}

export const WepaArchiveContainer = ({ t, wepaService, refetch }: WepaArchiveContainerProps) => {
  const [setArchiveWepaServiceMutation] = useMutation<
    ArchiveWepaService,
    ArchiveWepaServiceVariables
  >(ARCHIVE_WEPA_SERVICE);

  const setArchiveStatus = async (id: string) => {
    await setArchiveWepaServiceMutation({
      variables: { wepaServiceId: id }
    });
    await refetch();
  };

  return <ArchiveService t={t} id={wepaService.id} setArchiveStatus={setArchiveStatus} />;
};
