import React, { useEffect, useRef, useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { GetWepaCompanyQuery_getWepaCompany } from "../../../lib/api/queries/graphql/GetWepaCompanyQuery";
import { ValueView } from "../../../components/common/layout/ValueView";
import RideCardTable from "../../../sharedComponents/RideCardTable/RideCardTable";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { ApiFeedback, FeatureFlags } from "../../../global-query-types";
import { AlertMessage, AlertType } from "../../../sharedComponents/AlertMessage/AlertMessage";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

interface WepaCompanyDetailsProps extends WithTranslation {
  details: GetWepaCompanyQuery_getWepaCompany;
}

interface ProcessingStatuses {
  completed: number;
  failed: number;
  running: number;
  scheduled: number;
}

export interface WepaProcessingTasks {
  downloading: ProcessingStatuses;
  generatingAnnual: ProcessingStatuses;
  importing: ProcessingStatuses;
}

interface WepaProcessesProps {
  wepaProcessingTasks: WepaProcessingTasks | undefined;
}

interface WepaCompanyDetailsSyncProps extends WepaCompanyDetailsProps {
  verifyIfSyncIsAvailable: () => Promise<boolean | undefined>;
  syncWepaCompany: () => Promise<ApiFeedback | null | undefined>;
  wepaProcesses: WepaProcessesProps | null | undefined;
  refetch: () => Promise<void>;
}

type WepaCompanyDetailsSimpleProps = Omit<WepaCompanyDetailsProps, "tReady" | "i18n">;

const WepaCompanyDetails = ({
  t,
  details,
  verifyIfSyncIsAvailable,
  syncWepaCompany,
  wepaProcesses,
  refetch
}: WepaCompanyDetailsSyncProps) => {
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState = (setState: () => void) => {
    if (mountedRef.current) {
      setState();
    }
  };

  const [successSyncTimeLeft, setSuccessSyncTimeLeft] = useState(5);
  const [showSyncMessage, setShowSyncMessage] = useState(() => false);
  const [showSyncWarningMessage, setShowSyncWarningMessage] = useState(() => false);
  const [isLoading, setIsLoading] = useState(false);

  const calculateTimeLeft = () => {
    if (successSyncTimeLeft <= 0) {
      safeSetState(() => setIsLoading(false));
      return 0;
    }

    return safeSetState(() => setSuccessSyncTimeLeft(successSyncTimeLeft - 1));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearTimeout(timer);
  });

  const onSyncClicked = async () => {
    if (details.id) {
      const syncIsAvailable = await verifyIfSyncIsAvailable();

      if (syncIsAvailable || (!syncIsAvailable && showSyncWarningMessage)) {
        safeSetState(() => setSuccessSyncTimeLeft(5));
        safeSetState(() => setIsLoading(true));
        safeSetState(() => setShowSyncMessage(true));
        safeSetState(() => setShowSyncWarningMessage(false));
        await syncWepaCompany();
        await refetch();
      } else {
        safeSetState(() => setShowSyncMessage(false));
        safeSetState(() => setShowSyncWarningMessage(true));
      }
    }
  };

  return (
    <div className="wepa-info">
      <div>
        <FeatureFlag key="sync-wepa-company" name={FeatureFlags.SyncWepaCompany}>
          <div className="wepa-info__sync-wepa-company">
            <RideButtonDeprecated
              className="wepa-info__sync-button"
              variant={showSyncWarningMessage ? ButtonVariant.Danger : ButtonVariant.Primary}
              data-testid="wepa-company-details-sync-button"
              type="button"
              onClick={onSyncClicked}
              isLoading={isLoading}>
              {showSyncWarningMessage
                ? t("wepa-bookings:wepa-info.force-sync")
                : t("wepa-bookings:wepa-info.sync")}
            </RideButtonDeprecated>
            {showSyncMessage && successSyncTimeLeft > 0 && (
              <AlertMessage
                type={AlertType.success}
                data-testid="wepa-company-details-sync-message">
                {t("wepa-bookings:wepa-info.sync-message")}
              </AlertMessage>
            )}
            {showSyncWarningMessage && (
              <AlertMessage
                type={AlertType.warning}
                data-testid="wepa-company-details-warning-message">
                {t("wepa-bookings:wepa-info.warning")}
              </AlertMessage>
            )}
          </div>
        </FeatureFlag>
        <div className="wepa-info__title">
          <h2 data-testid="wepa-company-details-section-title">
            {t("wepa-bookings:wepa-info.title")}
          </h2>
        </div>
      </div>

      <ValueView t={t} name="wepaID" value={details.wepaId} testId="wepa-id" />

      <ProcessingStatusTable t={t} wepaProcessingTasks={wepaProcesses?.wepaProcessingTasks} />

      <BrokerAccessTable t={t} details={details} />

      <BrokerAccountsTable t={t} details={details} />

      <BrokerAnnualsTable t={t} details={details} />
    </div>
  );
};

const BrokerAccessTable = ({ t, details }: WepaCompanyDetailsSimpleProps) => (
  <RideCardTable title={t("wepa-bookings:wepa-info.broker-access")} data-testid="broker-accesses">
    <RideTableHeader
      columns={[
        { name: "id", label: t("wepa-bookings:wepa-info.id") },
        { name: "type", label: t("wepa-bookings:wepa-info.type") },
        { name: "valid", label: t("wepa-bookings:wepa-info.valid") },
        { name: "expiredAt", label: t("wepa-bookings:wepa-info.expired-at") },
        { name: "errors", label: t("wepa-bookings:wepa-info.errors") }
      ]}
    />

    {details.brokerAccesses?.map(({ id, type, valid, expiredAt, errors }) => (
      <RideTableRow colCount={5} key={id}>
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-access-id"
          value={id}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-access-type"
          value={type}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-access-valid"
          value={valid}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-access-expiredAt"
          value={expiredAt}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-access-errors"
          value={
            <>
              {errors?.map((error, index) => (
                <div className="wepa-info__small" key={index}>
                  {error}
                </div>
              ))}
            </>
          }
        />
      </RideTableRow>
    ))}
  </RideCardTable>
);

const BrokerAccountsTable = ({ t, details }: WepaCompanyDetailsSimpleProps) => (
  <RideCardTable title={t("wepa-bookings:wepa-info.broker-accounts")} data-testid="broker-accounts">
    <RideTableHeader
      columns={[
        { name: "id", label: t("wepa-bookings:wepa-info.id") },
        { name: "type", label: t("wepa-bookings:wepa-info.type") },
        { name: "accountId", label: t("wepa-bookings:wepa-info.accountId") },
        { name: "errors", label: t("wepa-bookings:wepa-info.errors") }
      ]}
    />

    {details.brokerAccounts?.map(({ id, type, accountId, errors }) => (
      <RideTableRow colCount={4} key={id}>
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-account-id"
          value={id}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-account-type"
          value={type}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-account-accountId"
          value={accountId}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-account-errors"
          value={
            <>
              {errors?.map((error, index) => (
                <div className="wepa-info__small" key={index}>
                  {error}
                </div>
              ))}
            </>
          }
        />
      </RideTableRow>
    ))}
  </RideCardTable>
);

const BrokerAnnualsTable = ({ t, details }: WepaCompanyDetailsSimpleProps) => (
  <RideCardTable title={t("wepa-bookings:wepa-info.broker-annuals")} data-testid="broker-annuals">
    <RideTableHeader
      columns={[
        { name: "id", label: t("wepa-bookings:wepa-info.id") },
        { name: "dateRange", label: t("wepa-bookings:wepa-info.dateRange") },
        { name: "errors", label: t("wepa-bookings:wepa-info.errors") }
      ]}
    />

    {details.annuals?.map(({ id, dateRange, errors }) => (
      <RideTableRow colCount={3} key={id}>
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-annual-id"
          value={id}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-annual-dateRange"
          value={dateRange}
        />
        <RideTableCell
          variant={RideTableCellVariant.text}
          dataTestId="broker-annual-errors"
          value={
            <>
              {errors?.map((error, index) => (
                <div className="wepa-info__small" key={index}>
                  {error}
                </div>
              ))}
            </>
          }
        />
      </RideTableRow>
    ))}
  </RideCardTable>
);

const ProcessingStatusTable = ({
  t,
  wepaProcessingTasks
}: WepaProcessesProps & Omit<WithTranslation, "tReady" | "i18n">) => {
  const getWepaProcessingTaskRow = (
    processingTaskName: string,
    fieldNamePrefix: string,
    processingTaskData: ProcessingStatuses
  ) => (
    <RideTableRow colCount={5}>
      <RideTableCell
        variant={RideTableCellVariant.text}
        dataTestId={`${fieldNamePrefix}-process-statuses`}
        value={processingTaskName}
      />
      <RideTableCell
        variant={RideTableCellVariant.text}
        dataTestId={`${fieldNamePrefix}-completed`}
        value={processingTaskData.completed}
      />
      <RideTableCell
        variant={RideTableCellVariant.text}
        dataTestId={`${fieldNamePrefix}-scheduled`}
        value={processingTaskData.scheduled}
      />
      <RideTableCell
        variant={RideTableCellVariant.text}
        dataTestId={`${fieldNamePrefix}-running`}
        value={processingTaskData.running}
      />
      <RideTableCell
        variant={RideTableCellVariant.text}
        dataTestId={`${fieldNamePrefix}-failed`}
        value={processingTaskData.failed}
      />
    </RideTableRow>
  );

  const importingPrefix = "importing";
  const downloadingPrefix = "downloading";
  const generatingAnnualPrefix = "generating-annual";

  return (
    <RideCardTable
      title={t("wepa-bookings:wepa-info.process-statuses-card-table.table-title")}
      data-testid="process-statuses-card-table">
      <RideTableHeader
        columns={[
          {
            name: "process",
            label: t("wepa-bookings:wepa-info.process-statuses-card-table.process")
          },
          {
            name: "completed",
            label: t("wepa-bookings:wepa-info.process-statuses-card-table.completed")
          },
          {
            name: "scheduled",
            label: t("wepa-bookings:wepa-info.process-statuses-card-table.scheduled")
          },
          {
            name: "running",
            label: t("wepa-bookings:wepa-info.process-statuses-card-table.running")
          },
          { name: "failed", label: t("wepa-bookings:wepa-info.process-statuses-card-table.failed") }
        ]}
      />

      {wepaProcessingTasks &&
        getWepaProcessingTaskRow(
          t(`wepa-bookings:wepa-info.process-statuses-card-table.${importingPrefix}`),
          importingPrefix,
          wepaProcessingTasks.importing
        )}
      {wepaProcessingTasks &&
        getWepaProcessingTaskRow(
          t(`wepa-bookings:wepa-info.process-statuses-card-table.${downloadingPrefix}`),
          downloadingPrefix,
          wepaProcessingTasks.downloading
        )}
      {wepaProcessingTasks &&
        getWepaProcessingTaskRow(
          t(`wepa-bookings:wepa-info.process-statuses-card-table.${generatingAnnualPrefix}`),
          generatingAnnualPrefix,
          wepaProcessingTasks.generatingAnnual
        )}
    </RideCardTable>
  );
};

export default withTranslationReady(["nominee-dashboard", "wepa-bookings"])(WepaCompanyDetails);
