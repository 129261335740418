import React, { CSSProperties, useEffect, useRef } from "react";
import { ECharts, EChartsOption, getInstanceByDom, init, SetOptionOpts } from "echarts";

export interface ChartWrapperProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
  onResize?: (width: number) => void;
}

const ChartWrapper = ({
  option,
  style,
  settings,
  loading,
  theme = "light",
  onResize
}: ChartWrapperProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const eChartsOption: EChartsOption = option;

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);

      if (onResize) {
        onResize(chart?.getWidth() ?? 0);
      }
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
      onResize && onResize(chart?.getWidth() ?? 0);
    }

    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart: ECharts | undefined = getInstanceByDom(chartRef.current);
      if (chart) chart.setOption({ ...eChartsOption }, settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart: ECharts | undefined = getInstanceByDom(chartRef.current);
      if (chart) loading === true ? chart.showLoading() : chart.hideLoading();
    }
  }, [loading, theme]);

  return <div data-testid="chart-wrapper" ref={chartRef} style={style} />;
};

export default ChartWrapper;
