import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../../../common/i18n/withTranslationReady";
import React from "react";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";
import { CardContainer } from "../../../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { Form } from "react-bootstrap";

export interface ReviewHoldingCompanyDataStepProps extends StepComponentProps, WithTranslation {}

export const ReviewHoldingCompanyDataStep = withTranslationReady(["generic"])(
  ({ order, t, controls, currentStep, saveData }: ReviewHoldingCompanyDataStepProps) => {
    const holdingCompanies = order.extra?.holdingCompanies ?? {};

    const chapterKeyParts = currentStep.chapter.key.split("-");
    const holdingIndex = chapterKeyParts[chapterKeyParts.length - 1];

    const company = holdingCompanies[holdingIndex];

    const onSubmit = async () => {
      currentStep.complete();

      await saveData({
        ...currentStep.chapter.order.serialize()
      });
    };

    return (
      <Form
        onSubmit={(e) => {
          onSubmit();
          e.preventDefault();
          return false;
        }}>
        <CardContainer data-testid="review-holding-company-data">
          <ReviewEntry
            name={"company-info"}
            fields={{
              name: [company.companyInfo?.name],
              legalForm: [company.companyInfo?.legalForm],
              taxId: [company.companyInfo?.taxId],
              lei: [company.companyInfo?.lei],
              registryNumber: [company.companyInfo?.registryNumber]
            }}
            editLink={"#todo"}
          />

          <ReviewEntry
            name={"company-address"}
            fields={{
              streetLine1: [company.companyAddress?.streetLine1],
              streetLine2: [company.companyAddress?.streetLine2],
              country: [company.companyAddress?.country],
              city: [company.companyAddress?.city],
              postalCode: [company.companyAddress?.postalCode]
            }}
            editLink={"#todo"}
          />
        </CardContainer>
        {controls}
      </Form>
    );
  }
);
