import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { ConsentToTradingOnOwnAccountSchema } from "lib/validation/ConsentToTradingOnOwnAccountSchema";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideReadonlyText } from "uiLibrary/components/RideReadonlyText/RideReadonlyText";
import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";

const ConsentToTradingOnOwnAccountStep = ({
  order,
  controls,
  saveData,
  currentStep,
  t
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const initialValues = {
    consentToTradingOnOwnAccount: order.extra.consentToTradingOnOwnAccount ?? false
  };

  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        consentToTradingOnOwnAccount: values.consentToTradingOnOwnAccount,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: ConsentToTradingOnOwnAccountSchema(t)
  });

  return (
    <div data-testid="consent-to-trading-on-own-account-step">
      <FormikProvider value={formik}>
        <Form>
          <ConsentToTradingOnOwnAccount />
          {controls}
        </Form>
      </FormikProvider>
    </div>
  );
};

const ConsentToTradingOnOwnAccount = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div
      data-testid="consent-to-trading-on-own-account"
      className="consent-to-trading-on-own-account">
      <StepHeading
        text={t("generic:ride-broker-onboarding.consent-to-trading-on-own-account.title")}
      />
      <RideReadonlyText
        text={t(
          rideBrokerT(
            "generic:ride-broker-onboarding.consent-to-trading-on-own-account.description"
          )
        )}
      />
      <RideCheckbox
        name="consentToTradingOnOwnAccount"
        label={t("generic:ride-broker-onboarding.consent-to-trading-on-own-account.label")}
        className="consent-to-handle-personal-data__accept-checkbox"
      />
    </div>
  );
});

export default withTranslationReady(["generic"])(ConsentToTradingOnOwnAccountStep);
