import React from "react";
import { Image } from "react-bootstrap";

interface Props {
  title: string;
  iconSrc: string;
  children?: React.ReactNode;
}

const InvestmentCardGroup = ({ title, iconSrc, children }: Props) => {
  const wrap = (child: React.ReactNode, index) => {
    return (
      <div
        className={`investment-card-group__wrapper ${
          index === 0 ? "investment-card-group__wrapper--first" : ""
        }`}>
        <div className="investment-card-group__vertical-line" />
        {child}
      </div>
    );
  };

  const cards = children ? (children instanceof Array ? children : [children]) : [];

  return (
    <div data-testid="investment-card-group" className="investment-card-group">
      {cards.length > 0 && (
        <div className="investment-card-group">
          <div className="investment-card-group__title">
            <Image data-testid="investment-card-group-icon" src={iconSrc} />
            <span className="investment-card-group__title-text">{title}</span>
          </div>
          {React.Children.map(cards, (child, index) => wrap(child, index))}
        </div>
      )}
    </div>
  );
};

export default InvestmentCardGroup;
