import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags, OrderCompanyStatusEnum } from "global-query-types";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { DownloadIcon } from "sharedComponents/icons/DownloadIcon";
import React from "react";
import { SectionWrapper } from "admin/components/LowTaxBrokerOrderDetails/SectionWrapper/SectionWrapper";
import { RideButtonWithPrompt } from "uiLibrary/v2/components/RideButtonWithPrompt/RideButtonWithPrompt";
import { ConfirmationModalType } from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { TFunction, WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, { RideTableCellVariant } from "sharedComponents/RideTableCell/RideTableCell";
import { companyName } from "lib/formatters/companyFormatter";
import { LowTaxBrokerOrderDetailsProps } from "admin/components/LowTaxBrokerOrderDetails/LowTaxBrokerOrderDetails";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import ChangeOrderStatus from "admin/components/LowTaxBrokerOrderDetails/DevToolsSection/ChangeOrderStatus/ChangeOrderStatus";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

interface DevToolsSectionProps extends WithTranslation {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order;
  softDeleteOrder: (orderId: string) => Promise<void>;
  generateLowTaxBrokerMaskedJson: (orderId: string) => Promise<void>;
  upsertHandler: (data: UpsertOrderVariables) => Promise<void>;
}

const DevToolsSection = ({
  order,
  softDeleteOrder,
  generateLowTaxBrokerMaskedJson,
  upsertHandler,
  t
}: DevToolsSectionProps) => {
  const { push } = useHistory();

  return (
    <SectionWrapper title={t("generic:broker-order-details.dev-tools")}>
      <FeatureFlag name={FeatureFlags.NomineeUpdateLTBOrderStatus}>
        <ChangeOrderStatus order={order} upsertHandler={upsertHandler} />
      </FeatureFlag>

      <Holdings t={t} order={order} upsertHandler={upsertHandler} />

      <GenerateMaskedDataJson
        order={order}
        generateLowTaxBrokerMaskedJson={generateLowTaxBrokerMaskedJson}
      />

      <RideButton
        size={RideButtonSize.SMALL}
        variant={RideButtonVariant.PRIMARY}
        label="Open Order"
        onClick={() => {
          push(`/client/order/generic/broker-setup/0/${order?.id}/${order?.submissionId}`);
        }}
      />

      <SoftDeleteOrder
        t={t}
        status={order.status}
        onClick={async () => {
          await softDeleteOrder(order.id);
        }}
      />
    </SectionWrapper>
  );
};

const GenerateMaskedDataJson = ({
  order,
  generateLowTaxBrokerMaskedJson
}: Pick<LowTaxBrokerOrderDetailsProps, "order" | "generateLowTaxBrokerMaskedJson">) => {
  const downloadURL = `${getEndpoint()}/api/file/BrokerageAccount/${
    order!.entityId
  }/data-masked.json?apiToken=${getToken()}`;

  const generateMaskedJsonAndDownload = async () => {
    await generateLowTaxBrokerMaskedJson(order!.id);
  };

  return (
    <FeatureFlag name={FeatureFlags.LTBHoldingInADB}>
      <div className="low-tax-broker-order-details__row" data-testid="masked-json-functionality">
        <div className="low-tax-broker-order-details__hint">{`The following button will download the data.json (masked):  `}</div>

        <RideButtonDeprecated
          data-testid="generate-masked-json"
          variant={ButtonVariant.Secondary}
          onClick={() => {
            generateMaskedJsonAndDownload();
          }}>
          <b>Re-generate</b>
        </RideButtonDeprecated>

        <RideButtonDeprecated
          data-testid="download-masked-json"
          variant={ButtonVariant.Secondary}
          openExternalUrl={true}
          hasIcon={true}
          href={downloadURL}>
          <DownloadIcon />
          <b>Download masked JSON</b>
        </RideButtonDeprecated>
      </div>
    </FeatureFlag>
  );
};

interface HoldingsProps {
  t: TFunction;
  upsertHandler: LowTaxBrokerOrderDetailsProps["upsertHandler"];
  order: NonNullable<LowTaxBrokerOrderDetailsProps["order"]>;
}

const Holdings = ({ order, upsertHandler, t }: HoldingsProps) => {
  const { push } = useHistory();

  const holdingCompanyIndices = Object.keys(order.extra?.chapters ?? {})
    .filter((key) => key.startsWith("holding-company-"))
    .map((key) => {
      const parts = key.split("-");
      return parseInt(parts[parts.length - 1]);
    });

  const addHoldingCompany = async () => {
    const lastHoldingCompanyIndex = Math.max(-1, ...holdingCompanyIndices);
    const holdingCompanyIndex = lastHoldingCompanyIndex + 1;

    await upsertHandler({
      id: order.id,
      extra: {
        chapters: {
          ...(order.extra?.chapters ?? {}),
          [`holding-company-${holdingCompanyIndex}`]: {
            steps: [
              { key: "choose-holding-company" },
              { key: "review-holding-company-data" },
              { key: "assign-holding-shareholders-directors" }
            ]
          }
        },
        holdingCompanies: {
          ...(order.extra?.holdingCompanies ?? {}),
          [holdingCompanyIndex]: {}
        }
      }
    });

    push(
      `/client/order/generic/holding-company-${holdingCompanyIndex}/0/${order.id}/submission-id-irrelevant`
    );
  };

  const removeHoldingCompany = async (index: number) => {
    const chapters = { ...(order.extra?.chapters ?? {}) };
    delete chapters[`holding-company-${index}`];

    const holdingCompanies = { ...(order.extra?.holdingCompanies ?? {}) };
    delete holdingCompanies[index];

    await upsertHandler({
      id: order.id,
      extra: {
        chapters,
        holdingCompanies
      }
    });
  };

  const columns = [
    { name: "name", label: t("generic:name") },
    { name: "actions", label: t("generic:actions") }
  ];

  return (
    <FeatureFlag name={FeatureFlags.LTBHoldingInADB}>
      <div data-testid="holdings-functionality">
        <div className="low-tax-broker-order-details__row">
          <div className="low-tax-broker-order-details__hint">{`The following button will add a holding company to the LTB order: `}</div>

          <RideButtonWithPrompt
            prompt={{
              message: "Are you sure that you want to add new Holding Company for this LTB Order?",
              title: "Confirmation",
              type: ConfirmationModalType.DANGER,
              confirmLabel: t("generic:confirm")
            }}
            data-testid="transform-to-holding-button"
            variant={ButtonVariant.Danger}
            disabled={order.status === OrderCompanyStatusEnum.Completed}
            onClick={addHoldingCompany}>
            <b>Add Holding Company</b>
          </RideButtonWithPrompt>
        </div>

        {holdingCompanyIndices.length > 0 ? (
          <div className="low-tax-broker-order-details__row">
            <div className="low-tax-broker-order-details__table" data-testid="holdings-table">
              <RideTableHeader columns={columns} />

              {holdingCompanyIndices.map((index) => {
                const holdingCompany = order.extra?.holdingCompanies?.[index] ?? {};

                return (
                  <RideTableRow colCount={columns.length} key={index}>
                    <RideTableCell
                      dataTestId="company"
                      variant={RideTableCellVariant.primaryLink}
                      value={companyName(holdingCompany.companyInfo)}
                      link={`/nominee-companies/${holdingCompany.entityId}`}
                    />
                    <RideTableCell
                      value={
                        <>
                          <RideButtonDeprecated
                            data-testid={`edit-holding-company-chapter-${index}`}
                            variant={ButtonVariant.Secondary}
                            openExternalUrl={true}
                            href={`/client/order/generic/holding-company-${index}/0/${order.id}/submission-id-irrelevant`}>
                            Jump to Order flow
                          </RideButtonDeprecated>

                          <RideButtonWithPrompt
                            prompt={{
                              message:
                                "Are you sure you want to remove this holding company from LTB order?",
                              title: "Confirmation",
                              type: ConfirmationModalType.DANGER,
                              confirmLabel: t("generic:delete")
                            }}
                            data-testid={`delete-holding-company-${index}`}
                            variant={ButtonVariant.Danger}
                            disabled={order.status === OrderCompanyStatusEnum.Completed}
                            onClick={() => removeHoldingCompany(index)}>
                            <b>Remove</b>
                          </RideButtonWithPrompt>
                        </>
                      }
                    />
                  </RideTableRow>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </FeatureFlag>
  );
};

interface SoftDeleteOrderProps {
  t: TFunction;
  status: any;
  onClick: () => void;
}

const SoftDeleteOrder = ({ t, status, onClick }: SoftDeleteOrderProps) => {
  return (
    <div className="low-tax-broker-order-details__row">
      <div className="low-tax-broker-order-details__hint">{`The following button will soft delete this order:  `}</div>

      <RideButtonWithPrompt
        prompt={{
          message:
            "Are you sure you want to delete this order? This will make this order inaccessible for the user.",
          title: t("generic:confirm-action-modal-delete.title"),
          type: ConfirmationModalType.DANGER,
          confirmLabel: t("generic:delete")
        }}
        data-testid="soft-delete-order-button"
        variant={ButtonVariant.Danger}
        disabled={status === OrderCompanyStatusEnum.Completed}
        onClick={onClick}>
        <b>Delete the order</b>
      </RideButtonWithPrompt>
    </div>
  );
};

export default withTranslationReady(["generic"])(DevToolsSection);
