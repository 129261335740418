import * as Yup from "yup";

export const NomineeCompanyDetailsSchema = () => {
  return Yup.object().shape({
    notarizationDate: Yup.date().notRequired().nullable(),

    applicationForEntryDate: Yup.date()
      .notRequired()
      .nullable()
      .min(Yup.ref("notarizationDate"), "nominee-dashboard:date-not-less-than-notarizationDate"),

    commercialRegisterEntryDate: Yup.date()
      .notRequired()
      .nullable()
      .when("applicationForEntryDate", (applicationForEntryDate, schema) =>
        applicationForEntryDate
          ? schema.min(
              Yup.ref("applicationForEntryDate"),
              "nominee-dashboard:date-not-less-than-applicationForEntryDate"
            )
          : schema.min(
              Yup.ref("notarizationDate"),
              "nominee-dashboard:date-not-less-than-notarizationDate"
            )
      )
  });
};
