import React, { ChangeEvent } from "react";
import Form from "react-bootstrap/Form";

interface TextAreaProps {
  name: string;
  label?: string;
  className?: string;
  rowCount?: number;
  placeholder?: string;
  value: string;
  onChange?: (e: ChangeEvent<any>) => void;
  onBlur?: (e: ChangeEvent<any>) => void;
  disabled?: boolean;
  extra?: { [key: string]: any };
}

export default function TextAreaInput(props: TextAreaProps) {
  const {
    name,
    label,
    className,
    placeholder,
    rowCount,
    value,
    onChange,
    onBlur,
    disabled,
    extra
  } = props;
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        className={className}
        placeholder={placeholder ?? ""}
        data-testid={`${name}-input`}
        name={name}
        as="textarea"
        value={String(value)}
        onChange={onChange}
        onBlur={onBlur}
        rows={rowCount ?? 3}
        disabled={disabled}
        {...extra}
      />
    </Form.Group>
  );
}
