import * as Yup from "yup";

export const UpdateSPVDetailsSchema = () => {
  return Yup.object().shape({
    projectName: Yup.string().required("generic:validation-required"),
    capitalContribution: Yup.number()
      .positive("generic:must-be-positive-number")
      .required("generic:validation-required"),
    tokenized: Yup.string(),
    tokenizedShares: Yup.number().when("tokenized", {
      is: "YES",
      then: Yup.number()
        .min(1, "spv-company:non-zero")
        .max(Yup.ref("capitalContribution"), "spv-company:tokenizedShares-limit")
        .required("generic:validation-required"),
      otherwise: Yup.number()
        .min(0, "spv-company:non-tokenized-share")
        .max(0, "spv-company:non-tokenized-share")
        .required("generic:validation-required")
    }),
    boxLink: Yup.string().url("generic:expected-url").required("generic:validation-required")
  });
};
