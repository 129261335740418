import logger from "common/Logger";
import { FeatureFlags } from "../../../global-query-types";

export class FeatureFlagService {
  static instance: FeatureFlagService;
  isEnabledByFeatureName: Map<FeatureFlags, boolean>;

  constructor(featureFlags: Map<FeatureFlags, boolean>) {
    this.isEnabledByFeatureName = featureFlags;
  }

  isEnabled(flag: FeatureFlags): boolean {
    if (!this.isEnabledByFeatureName.has(flag)) {
      logger.warn("Could not find Feature flag " + flag.toString());
    }

    return this.isEnabledByFeatureName.get(flag) ?? false;
  }
}
