import React from "react";

export const CaretDownIcon = () => (
  <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.79167 0.540364L0.75 2.58203L9.5 11.332L18.25 2.58203L16.2083 0.540364L9.5 7.2487L2.79167 0.540364Z"
      fill="currentColor"
    />
  </svg>
);
