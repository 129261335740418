import React from "react";
import TwoFactorAuthenticationUserSetup from "./TwoFactorAuthenticationUserSetup";
import { FullPersonDetailsQuery_getPersonDetails_user } from "../../../components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import { useMutation } from "react-apollo";
import { UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT } from "../../../lib/api/mutations/UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT";
import {
  resetTwoFactorAuthentication,
  resetTwoFactorAuthenticationVariables
} from "../../../lib/api/mutations/graphql/resetTwoFactorAuthentication";
import { RESET_TWO_FACTOR_AUTHENTICATION } from "../../../lib/api/mutations/RESET_TWO_FACTOR_AUTHENTICATION";
import {
  UpdateAllowTwoFactorAuthPrompt,
  UpdateAllowTwoFactorAuthPromptVariables
} from "../../../lib/api/mutations/graphql/UpdateAllowTwoFactorAuthPrompt";

interface TwoFactorAuthenticationUserSetupTestProps {
  user: FullPersonDetailsQuery_getPersonDetails_user | null;
}

export const TwoFactorAuthenticationUserSetupContainer = ({
  user
}: TwoFactorAuthenticationUserSetupTestProps) => {
  const [updateAllowTwoFactorAuthPromptMutation] = useMutation<
    UpdateAllowTwoFactorAuthPrompt,
    UpdateAllowTwoFactorAuthPromptVariables
  >(UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT);
  const [resetTwoFactorAuthenticationMutation] = useMutation<
    resetTwoFactorAuthentication,
    resetTwoFactorAuthenticationVariables
  >(RESET_TWO_FACTOR_AUTHENTICATION);

  const updateAllowTwoFactorAuthPrompt = async () => {
    const userId = user?.id;

    if (userId) {
      const result = await updateAllowTwoFactorAuthPromptMutation({
        variables: { userId }
      });
      return result.data?.updateAllowTwoFactorAuthPrompt ?? false;
    }
  };

  const resetTwoFactorAuth = async () => {
    const userId = user?.id;

    if (userId) {
      const result = await resetTwoFactorAuthenticationMutation({
        variables: { userId }
      });
      return result.data?.resetTwoFactorAuthentication?.feedback;
    }
  };

  return (
    <>
      <TwoFactorAuthenticationUserSetup
        hasTwoFactorAuth={user?.hasTwoFactorAuth ?? false}
        allowTwoFactorAuthPrompt={user?.allowTwoFactorAuthPrompt ?? true}
        updateAllowTwoFactorAuthPrompt={updateAllowTwoFactorAuthPrompt}
        resetTwoFactorAuth={resetTwoFactorAuth}
      />
    </>
  );
};
