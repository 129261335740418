import { getToken } from "./getToken";

const wepaManagerBaseUrl = process.env.REACT_APP_WEPA_MANAGER_URL as string;

export function getWepaManagerOauthRedirectionUrl(
  wepaId?: number | null,
  baseUrl: string = wepaManagerBaseUrl
) {
  const token = getToken();
  if (!!token) {
    const paramsWithToken = new URLSearchParams();
    paramsWithToken.append("token", token);

    const urlPath = wepaId ? `${baseUrl}/companies/${wepaId}` : `${baseUrl}/companies`;
    const url = `${urlPath}?${paramsWithToken.toString()}`;
    return url;
  } else {
    return window.location.origin;
  }
}
