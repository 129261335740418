import { useMutation } from "react-apollo";
import {
  DELETE_BROKERAGE_ACCOUNT,
  UPSERT_BROKERAGE_ACCOUNT
} from "../../../lib/api/mutations/UPSERT_BROKERAGE_ACCOUNT";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import React from "react";
import { UpsertBrokerageAccountVariables } from "../../../lib/api/mutations/graphql/UpsertBrokerageAccount";
import BrokerageAccountForm from "client/components/BrokerageAccountForm/BrokerageAccountForm";
import { useParams } from "react-router";
import { COMPANY_BY_ID, QueryCompanyById } from "../../../lib/api/queries/GetCompanyById";

export const BrokerageAccountFormContainer = () => {
  const { companyId, id: brokerageAccountId } = useParams<{ companyId: string; id: string }>();
  const [upsertBrokerageAccountMutation, upsertBrokerageAccountRequest] =
    useMutation(UPSERT_BROKERAGE_ACCOUNT);
  const [deleteBrokerageAccountMutation, deleteBrokerageAccountRequest] =
    useMutation(DELETE_BROKERAGE_ACCOUNT);

  const companyRequest = QueryCompanyById(companyId);

  const upsertBrokerageAccount = async (variables: UpsertBrokerageAccountVariables) => {
    await upsertBrokerageAccountMutation({
      variables,
      refetchQueries: [{ query: COMPANY_BY_ID, variables: { companyId: variables.companyId } }]
    });
  };

  const deleteBrokerageAccount = async (brokerageAccountId: string, companyId: string) => {
    await deleteBrokerageAccountMutation({
      variables: { brokerageAccountId, companyId },
      refetchQueries: [{ query: COMPANY_BY_ID, variables: { companyId } }]
    });
  };

  return (
    <RequestFeedbackHandler
      requests={[upsertBrokerageAccountRequest, deleteBrokerageAccountRequest, companyRequest]}>
      <BrokerageAccountForm
        deleteBrokerageAccount={deleteBrokerageAccount}
        upsertBrokerageAccount={upsertBrokerageAccount}
        companyId={companyId}
        brokerageAccount={companyRequest.data?.companyById?.brokerageAccounts?.find(
          (account) => account.id === brokerageAccountId
        )}
        brokerageAccountId={brokerageAccountId}
      />
    </RequestFeedbackHandler>
  );
};
