import React from "react";
import { RouteComponentProps } from "react-router";
import { AuthLayout } from "sharedComponents/templates/AuthLayout/AuthLayout";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LoginFormContainer } from "../components/LoginForm/LoginFormContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { useLocation } from "react-router-dom";

interface LoginSceneProps extends WithTranslation, RouteComponentProps {}

const LoginScene = ({ t, ...args }: LoginSceneProps) => {
  const location = useLocation<{ from: string } | undefined>();

  return (
    <>
      <PageMetaData title={t("auth:login.page-title")} />
      <AuthLayout title={t("auth:login.title")} subtitle={t("auth:login.subtitle")}>
        <LoginFormContainer redirectUrl={location.state?.from} />
      </AuthLayout>
    </>
  );
};

export default withTranslationReady(["auth"])(LoginScene);
