import React from "react";
import { GetMe } from "lib/api/queries/MeQuery";
import { LegalForm, UserRole } from "global-query-types";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { Form, FormikProvider, useFormik } from "formik";
import { companyName } from "lib/formatters/companyFormatter";
import { StepHeading } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { RideRadioSelect } from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import {
  MyCompaniesSummarized_companies,
  MyCompaniesSummarized_companies_group_memberships
} from "lib/api/queries/graphql/MyCompaniesSummarized";

interface NomineeOnlyPersonIdControlProps {
  personId: string | null;
  setPersonId: (value: string | null) => void;
  role?: UserRole;
}

export const NomineeOnlyPersonIdControl = (props: NomineeOnlyPersonIdControlProps) => {
  if (props.role !== "Nominee") return null;
  return <NomineeOnlyPersonIdForm {...props} />;
};

const NomineeOnlyPersonIdForm = ({ personId, setPersonId }: NomineeOnlyPersonIdControlProps) => {
  const formik = useFormik({
    initialValues: { personId },
    onSubmit: (values) => {
      setPersonId(values.personId);
    },
    enableReinitialize: true
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <RideTextInput name="personId" label="personId" />
      </Form>
    </FormikProvider>
  );
};

export const NomineeOnlyPersonIdControlContainer = (props: NomineeOnlyPersonIdControlProps) => {
  const me = GetMe();
  return <NomineeOnlyPersonIdControl {...props} role={me.data?.me?.role} />;
};

interface ChooseCompanyProps {
  heading: string;
  field: string;
  companies: CompanySummary[];
}

export interface CompanySummary {
  id: string;
  name: string;
  legalForm: LegalForm;
  lei?: string | null;
  registration?: string | null;
  taxNumber?: string | null;
  businessAddress?: CompanySummaryBusinessAddress;
  memberships: MyCompaniesSummarized_companies_group_memberships[];
}

export interface CompanySummaryBusinessAddress {
  streetLine1: string;
  streetLine2?: string | null;
  city: string;
  postalCode: string;
  country?: string | null;
}

export const ChooseCompany = ({ companies, field, heading }: ChooseCompanyProps) => {
  const options = companies.map((company) => ({
    label: companyName(company),
    value: company.id
  }));

  return (
    <div data-testid="choose-company">
      <StepHeading text={heading} />
      <RideRadioSelect name={field} options={options} />
    </div>
  );
};

export const toCompanySummaries = (
  rawCompanies: MyCompaniesSummarized_companies[]
): CompanySummary[] =>
  rawCompanies.map((company) => {
    return {
      ...company,
      businessAddress: {
        ...company.businessAddress,
        streetLine1: company.businessAddress.street
      },
      memberships: company.group.memberships ?? []
    };
  });
