import { WePaDirectDirectSalesStep } from "./lib/services/GoogleTagManager/WepaDirectOnboardingEvents";
import { AdminServicePTAMigrationToRideSteuerberatungSteps } from "./lib/helpers/AdminServicePTAMigrationToRideSteuerberatungHelper";

export enum RIDE_FLOWS {
  WEPA_DIRECT_DIRECT_SALES = "wepa_direct-direct_sales",
  ADMIN_SERVICE_PTA_MIGRATION_TO_RIDE_STEUERBERATUNG = "admin_service_pta_migration_to_ride_steuerbeatung"
}

export type RIDE_FLOWS_STEPS =
  | WePaDirectDirectSalesStep
  | AdminServicePTAMigrationToRideSteuerberatungSteps;
