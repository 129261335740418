export enum Gender {
  MALE = "male",
  FEMALE = "female",
  DIVERSE = "diverse"
}

export interface LowTaxBrokerCitizenshipData {
  gender: Gender;
  citizenship: string;
  secondCitizenship?: string;
  birthDate: string | null;
  birthCity: string;
  birthCountry: string;
  notUSCitizenOrTaxResident: boolean;
}
