import { useQuery } from "react-apollo";
import { GET_MY_ACCOUNTING_CLIENTS } from "lib/api/queries/GetMyAccountingClients";
import {
  GetMyAccountingClients,
  GetMyAccountingClientsVariables
} from "lib/api/queries/graphql/GetMyAccountingClients";
import TaxAdvisorClients, {
  TaxAdvisorClientsProps
} from "taxAdvisor/components/TaxAdvisorClients/TaxAdvisorClients";
import React from "react";

export const AccountantClientsContainer = () => {
  const limit = 10;

  const getRequest = useQuery<GetMyAccountingClients, GetMyAccountingClientsVariables>(
    GET_MY_ACCOUNTING_CLIENTS,
    {
      variables: {
        searchTerm: "",
        start: 0,
        limit
      }
    }
  );

  const accountingServices = getRequest.data?.getMyAccountingClients?.items;
  const totalCount = getRequest.data?.getMyAccountingClients?.count;

  const searchHandler = async (searchTerm: string) => {
    await getRequest.refetch({
      searchTerm
    });
  };

  const setStartHandler = async (start: number) => {
    await getRequest.refetch({
      start,
      limit
    });
  };

  const taxServices: TaxService[] | undefined = accountingServices?.map(
    (accSvc) =>
      ({
        id: accSvc.id,
        client: accSvc.client,
        taxAdvisorStatus: null
      } as TaxService)
  );

  return (
    <TaxAdvisorClients
      taxServices={taxServices}
      totalTaxServicesCount={totalCount}
      loading={getRequest.loading}
      searchHandler={searchHandler}
      setStartHandler={setStartHandler}
      pageSize={limit}
    />
  );
};

type TaxService = NonNullable<TaxAdvisorClientsProps["taxServices"]>[number];
