import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useFormik } from "formik";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import EditableSectionWithFormik from "sharedComponents/EditableSectionWithFormik/EditableSectionWithFormik";
import { companyName } from "lib/formatters/companyFormatter";
import { formatCompanyAddress } from "common/formatters/formatCompanyAddress";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import { ValueFormatter } from "common/formatters/ValueFormatter";
import { InvoiceField } from "../InvoiceFields/InvoiceFields";
import {
  GetTaxAdvisorCompaniesByName_getTaxAdvisorCompaniesByName,
  GetTaxAdvisorCompaniesByName_getTaxAdvisorCompaniesByName_businessAddress
} from "lib/api/queries/graphql/GetTaxAdvisorCompaniesByName";
import { LegalForm } from "global-query-types";
import { ErrorAlert } from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";

export type PartnerTaxAdvisorWithAddress = Pick<
  GetTaxAdvisorCompaniesByName_getTaxAdvisorCompaniesByName,
  "id" | "legalForm" | "name"
> & { businessAddress: PartnerTaxAdvisorAddress | null };

export type PartnerTaxAdvisorAddress = Pick<
  GetTaxAdvisorCompaniesByName_getTaxAdvisorCompaniesByName_businessAddress,
  "city" | "country" | "street" | "postalCode" | "careOf"
>;

export interface AssignPartnerTaxAdvisorProps extends WithTranslation {
  ptaCompanies: PartnerTaxAdvisorWithAddress[];
  onSubmit: ({ partnerTaxAdvisorCompanyId: string }) => Promise<void>;
  ptaCompany: NomineeCompanyDetails_companyById | null;
  subContractCompanyId: string | null | undefined;
}

interface SelectProps {
  options: { value: string | null; label: string }[];
  name: string;
  onChange: (param) => void;
  defaultValue?: string | null;
}

const Select = ({ options, name, defaultValue, onChange }: SelectProps) => {
  const defaultOption = options.find((option) => option.value === defaultValue);
  return (
    <div data-testid={`${name}-select-container`}>
      <ReactSelect
        name={name}
        defaultValue={defaultOption}
        onChange={(value) => onChange(value?.value)}
        options={options}
        isSearchable
      />
    </div>
  );
};

const mapToPartnerTaxAdvisorWithAddress = (
  company?: NomineeCompanyDetails_companyById | null
): PartnerTaxAdvisorWithAddress => ({
  id: company?.id ?? "",
  name: company?.name ?? "",
  legalForm: company?.legalForm as LegalForm,
  businessAddress: {
    street: company?.businessAddress?.street ?? "",
    city: company?.businessAddress?.city ?? "",
    country: company?.businessAddress?.country ?? "",
    careOf: company?.businessAddress?.careOf ?? "",
    postalCode: company?.businessAddress?.postalCode ?? ""
  }
});

const AssignPartnerTaxAdvisor = ({
  t,
  ptaCompanies,
  onSubmit,
  ptaCompany,
  subContractCompanyId
}: AssignPartnerTaxAdvisorProps) => {
  const valueFormatter = new ValueFormatter();

  const [selectedTaxAdvisorCompany, setSelectedTaxAdvisorCompany] = useState<
    PartnerTaxAdvisorWithAddress | null | undefined
  >(ptaCompany ? mapToPartnerTaxAdvisorWithAddress(ptaCompany) : null);

  const formik = useFormik<{ partnerTaxAdvisorCompanyId: string | null }>({
    initialValues: {
      partnerTaxAdvisorCompanyId: ptaCompany ? ptaCompany?.id : null
    },
    onSubmit: async (values) => {
      await onSubmit(values);
    }
  });

  useEffect(() => {
    if (ptaCompany) {
      const company = ptaCompanies.find((_ptaCompany) => _ptaCompany.id === ptaCompany.id);

      setSelectedTaxAdvisorCompany(company ? company : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptaCompany]);

  useEffect(() => {
    if (formik.values["partnerTaxAdvisorCompanyId"] === null) {
      setSelectedTaxAdvisorCompany(null);
      return;
    }

    const company = ptaCompanies.find(
      (_ptaCompany) => _ptaCompany.id === formik.values["partnerTaxAdvisorCompanyId"]
    );

    setSelectedTaxAdvisorCompany(company ? company : undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values["partnerTaxAdvisorCompanyId"]]);

  const unassignOption = { label: "Unassign", value: null };

  const options = ptaCompanies.map(({ id, name, legalForm }) => ({
    value: id,
    label: companyName({ name, legalForm })
  }));

  const formattedOptions = [unassignOption, ...options];

  if (ptaCompany && selectedTaxAdvisorCompany === undefined) {
    return (
      <ErrorAlert t={t} error="nominee-dashboard:company.tax-advisor-pta-set-but-not-loaded" />
    );
  }

  return (
    <EditableSectionWithFormik
      formik={formik}
      title={t("nominee-dashboard:admin-service.pta")}
      testId="assign-partner-tax-advisor"
      disableEditButton={!!subContractCompanyId}>
      {({ isEditMode }) => (
        <>
          <InvoiceField
            data-testid="pta-company-name"
            label={t("nominee-dashboard:admin-service.company")}
            value={
              isEditMode ? (
                <Select
                  options={formattedOptions}
                  name="partnerTaxAdvisorCompanyId"
                  defaultValue={selectedTaxAdvisorCompany ? selectedTaxAdvisorCompany?.id : null}
                  onChange={(value) => {
                    formik.setFieldValue("partnerTaxAdvisorCompanyId", value);
                  }}
                />
              ) : (
                valueFormatter.format(selectedTaxAdvisorCompany?.name)
              )
            }
          />
          <InvoiceField
            data-testid="pta-address"
            label={t("nominee-dashboard:admin-service.address")}
            value={valueFormatter.format(
              selectedTaxAdvisorCompany &&
                formatCompanyAddress({
                  t,
                  name: "",
                  careOf: selectedTaxAdvisorCompany.businessAddress?.careOf,
                  street: selectedTaxAdvisorCompany.businessAddress?.street,
                  postalCode: selectedTaxAdvisorCompany.businessAddress?.postalCode,
                  city: selectedTaxAdvisorCompany.businessAddress?.city,
                  countryCode: null
                })
            )}
          />
          <InvoiceField
            data-testid="pta-country"
            label={t("nominee-dashboard:admin-service.country")}
            value={valueFormatter.format(
              selectedTaxAdvisorCompany?.businessAddress?.country &&
                getCountryNameByCode(selectedTaxAdvisorCompany.businessAddress?.country)
            )}
          />
        </>
      )}
    </EditableSectionWithFormik>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(AssignPartnerTaxAdvisor);
