import React from "react";
import { WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

interface BackToMyGmbHButtonProps extends WithTranslation {
  isShadowUser: boolean;
  action?: () => void;
  companyId?: string;
}

export const BackToMyGmbHsButton = ({
  t,
  isShadowUser,
  action,
  companyId
}: BackToMyGmbHButtonProps) => {
  const canShowCompanyList = !FeatureFlagService.instance.isEnabled(
    FeatureFlags.ReplaceCompanyListByCompanyDropdown
  );

  const url = companyId && !canShowCompanyList ? `/client/entities/${companyId}/general-info` : "/";

  return (
    <Link to={url} data-testid="back-to-myGmbH" hidden={isShadowUser} onClick={action}>
      <RideButtonDeprecated variant={ButtonVariant.Primary} size={ButtonSize.Big}>
        <strong>{t("admin-service:back-to-my-gmbh")}</strong>
      </RideButtonDeprecated>
    </Link>
  );
};

export default withTranslationReady(["admin-service"])(BackToMyGmbHsButton);
