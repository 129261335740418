import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ActionableTask, ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import { textAreaFieldFor } from "components/generic/commonFields";
import { useFormik } from "formik";
import { TaskStatesEnum } from "global-query-types";
import React from "react";
import { WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { TaskContainerWithFormik } from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainerWithFormik";

interface NotaryAppointmentTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

export const NotaryAppointmentTask = ({ task, onChange, t }: NotaryAppointmentTaskProps) => {
  const isStateToBeDone = task.state === TaskStatesEnum.TO_BE_DONE;
  const nextState = isStateToBeDone ? TaskStatesEnum.COMPLETED : TaskStatesEnum.TO_BE_DONE;
  const validationSchema = isStateToBeDone
    ? Yup.object().shape({
        "notary-appointment": Yup.string().required(
          t(`company-founding-steps:tasks.appointment-text-validation.${task.ref}`)
        )
      })
    : null;

  const formik = useFormik({
    initialValues: {
      "notary-appointment": task.options?.data?.value ?? ""
    },
    onSubmit: (values) =>
      onChange(nextState, {
        type: "text",
        data: {
          value: values["notary-appointment"]
        }
      }),
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema
  });

  const getNotaryComponentBasedOnState = () =>
    isStateToBeDone ? (
      textAreaFieldFor(
        formik,
        "notary-appointment",
        "",
        t(`company-founding-steps:tasks.appointment-text-placeholder.${task.ref}`),
        10,
        false
      )
    ) : (
      <div
        data-testid="notary-appointment-completed"
        className="notary-appointment-task__completed">
        {task.options?.data?.value}
      </div>
    );

  return (
    <TaskContainerWithFormik
      formik={formik}
      testid="notary-appointment"
      translationKey={task.ref}
      task={task}>
      <div className="notary-appointment-task">
        {getNotaryComponentBasedOnState()}
        {!formik.isValid && (
          <div data-testid="error-message" className="invalid-feedback d-block">
            {t(`company-founding-steps:tasks.appointment-text-validation.${task.ref}`)}
          </div>
        )}
      </div>
    </TaskContainerWithFormik>
  );
};

export default withTranslationReady(["company-founding-steps"])(NotaryAppointmentTask);
