import React from "react";

export const PaginationPrev = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.5" y="2.5" width="27" height="27" rx="2.5" stroke="#E1E1E1" />
    <path
      d="m17 20-4-4 4-4"
      stroke="#A3A3A3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
