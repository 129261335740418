import { ClientSelect, ClientSelectOption } from "client/components/ClientSelect/ClientSelect";
import { FormikProvider, useFormik } from "formik";
import React from "react";
import { WithTranslation } from "react-i18next";
import RequestFeedbackForm from "sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { SelectGmbhSchema } from "lib/validation/SelectGmbhSchema";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";

interface SelectGmbhProps extends WithTranslation {
  options: ClientSelectOption[];
  onCompanySelect: (companyId: string) => void;
}

const SelectGmbh = ({ options, onCompanySelect, t }: SelectGmbhProps) => {
  const formik = useFormik({
    initialValues: { companyId: "" },
    onSubmit: (value) => onCompanySelect(value.companyId),
    validateOnBlur: false,
    validationSchema: SelectGmbhSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <div className="select-gmbh" data-testid="available-club-deals">
        <div className="select-gmbh__wrapper">
          <RequestFeedbackForm
            requests={[]}
            submitLabel={t("client:banking.addNewBankAccount.selectGmbhButton")}>
            <div>
              <div className="select-gmbh__title" data-testid="select-gmbh-title">
                {t("client:banking.addNewBankAccount.selectGmbhTitle")}
              </div>
              <div className="select-gmbh__description" data-testid="select-gmbh-description">
                {t("client:banking.addNewBankAccount.selectGmbhDescription")}
              </div>
              <ClientSelect
                name="companyId"
                className="select-gmbh__company-select"
                testIdPrefix={`select-gmbh-company-select`}
                label={t("client:banking.addNewBankAccount.companySelectLabel")}
                options={options}
              />
            </div>
          </RequestFeedbackForm>
        </div>
      </div>
    </FormikProvider>
  );
};

export default withTranslationReady(["client", "generic"])(SelectGmbh);
