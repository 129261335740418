import React from "react";
import {
  formatPersonName,
  PersonNameParams
} from "../../../common/formatters/CompanyNameFormatter";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

export interface PersonNameProps extends WithTranslation {
  person: PersonNameParams;
}

const PersonName = ({ t, person }: PersonNameProps) => (
  <span data-testid="person-full-name">{formatPersonName(t, person)}</span>
);

export default withTranslationReady(["generic"])(PersonName);
