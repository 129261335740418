import React from "react";
import { FormikProvider, useFormik } from "formik";
import { withTranslation, WithTranslation } from "react-i18next";
import { NotarySelectField, NotarySelectOption } from "./NotarySelectField";
import { Col, Row } from "react-bootstrap";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { UpdateNotary } from "./NotaryTypeAssignmentContainer";
import {
  AllNotaryPersonsQuery_allNotaryPersons,
  AllNotaryPersonsQuery_allNotaryPersons_notaryGroups
} from "./graphql/AllNotaryPersonsQuery";
import { NotaryType } from "../../../global-query-types";

interface NotaryTypeAssignmentContainerProps extends WithTranslation {
  persons: AllNotaryPersonsQuery_allNotaryPersons[];
  onSubmit: (variables: UpdateNotary) => Promise<void>;
  refetch: () => Promise<void>;
}

export const NotaryTypeAssignment = ({
  t,
  persons,
  onSubmit,
  refetch
}: NotaryTypeAssignmentContainerProps) => {
  const _notaryGroups: { [key: string]: NotarySelectOption } = {};

  const isNotaryGroupExist = (person: AllNotaryPersonsQuery_allNotaryPersons) =>
    person.notaryGroups && person.notaryGroups.length > 0;

  const fillDefaultNotary = (
    notaryGroup: AllNotaryPersonsQuery_allNotaryPersons_notaryGroups,
    person: AllNotaryPersonsQuery_allNotaryPersons
  ) => {
    _notaryGroups[notaryGroup.notaryType as NotaryType] = {
      label: `${person.firstName} ${person.lastName}`,
      value: person.id
    } as NotarySelectOption;
  };

  persons.forEach((person) => {
    if (isNotaryGroupExist(person)) {
      person.notaryGroups?.forEach((notaryGroup) => fillDefaultNotary(notaryGroup, person));
    }
  });

  const getNotaryFromType = (type) => _notaryGroups[type] || null;
  const defaultNotaries = {
    singleShareholder: getNotaryFromType(NotaryType.SingleShareholder),
    multipleShareholders: getNotaryFromType(NotaryType.MultipleShareholders),
    companyHolding: getNotaryFromType(NotaryType.CompanyHolding)
  };

  const notaries: NotarySelectOption[] = [
    ...persons.map((person) => ({
      label: `${person.firstName} ${person.lastName}`,
      value: person.id
    }))
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: defaultNotaries,
    onSubmit: async (values: any) => {
      const variables: UpdateNotary = {
        companyHolding: values.companyHolding?.value,
        multipleShareholders: values.multipleShareholders?.value,
        singleShareholder: values.singleShareholder?.value
      };
      await onSubmit(variables);
      await refetch();
    }
  });

  const notaryFieldOptions = [
    { name: "singleShareholder" },
    { name: "multipleShareholders" },
    { name: "companyHolding" }
  ];

  return (
    <>
      <Row className="mb-5">
        <FormikProvider value={formik}>
          {notaryFieldOptions.map((field, index) => (
            <Col xs={12} md={3} key={`NotarySelectField-${index}`} className="align-items-center">
              <div>
                <div data-testid={`notary-type-assignment-dropdown-${index + 1}`}>
                  <NotarySelectField
                    formik={formik}
                    testId={field.name}
                    name={field.name}
                    label={t(`nominee-dashboard:person.notary-filters.${field.name}`)}
                    isClearable
                    t={t}
                    options={notaries}
                  />
                </div>
              </div>
            </Col>
          ))}
          <Col xs={12} md={3} className="align-items-end justify-content-start d-flex pt-sm-3">
            <RideButtonDeprecated
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Medium}
              onClick={formik.submitForm}
              data-testid="notary-type-assignment-button">
              {t("generic:save")}
            </RideButtonDeprecated>
          </Col>
        </FormikProvider>
      </Row>
    </>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(NotaryTypeAssignment);
