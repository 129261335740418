import React, { useEffect, useState } from "react";
import { TFunction, Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import OnboardingStepCard, {
  EditableOnboardingStepCardProps,
  OnboardingStepCardStatus
} from "../../OnboardingStepCard/OnboardingStepCard";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { DownloadIcon } from "sharedComponents/icons/DesignSystemIcons/DownloadIcon";
import NavigationService from "lib/services/NavigationService/NavigationService";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { useHistory } from "react-router-dom";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import { RideCheckbox, RideCheckboxVariant } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { PaymentMethod } from "lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import RideSteuerberatungInformationModal from "./RideSteuerberatungInformationModal/RideSteuerberatungInformationModal";
import {
  WepaDirectOnboardingEvents,
  WePaDirectDirectSalesStep
} from "lib/services/GoogleTagManager/WepaDirectOnboardingEvents";

export enum ContractSignatureCardFlow {
  ADMIN_SERVICE_ONBOARDING = "ADMIN_SERVICE_ONBOARDING",
  ADMIN_SERVICE_MIGRATION = "ADMIN_SERVICE_MIGRATION",
  WEPA_DIRECT = "WEPA_DIRECT"
}

export interface ContractSignatureCardProps extends EditableOnboardingStepCardProps {
  stepNumber: number;
  status: OnboardingStepCardStatus;
  selectedTier: AdminServiceTier | null | undefined;
  paymentMethod: PaymentMethod | null | undefined;
  contractUrl: string;
  onDownloadContract?: () => void;
  downloadSignedContractUrl: string;
  successPageUrl: string;
  invoicedBySteueragenten?: boolean;
  flow?: ContractSignatureCardFlow;
  onAgreeToTermsAndConditions?: (() => Promise<void>) | null;
}

const ContractSignatureCard = ({
  t,
  stepNumber,
  status,
  selectedTier,
  paymentMethod,
  contractUrl,
  onDownloadContract,
  downloadSignedContractUrl,
  successPageUrl,
  forceOpening,
  flow = ContractSignatureCardFlow.ADMIN_SERVICE_MIGRATION,
  invoicedBySteueragenten = false,
  toggleEditing = null,
  onAgreeToTermsAndConditions = null
}: ContractSignatureCardProps & WithTranslation) => {
  const history = useHistory();
  const [hasDownloadContract, setHasDownloadContract] = useState(false);
  const [showRideSteuerberatungModal, setShowRideSteuerberatungModal] = useState(false);

  // ask santhosh
  const renameVvToRideSteuerberatung = FeatureFlagService.instance.isEnabled(
    FeatureFlags.RenameVvSteuerberatungToRideSteuerberatung
  );

  const asOnboardingOrWepaDirectFlow =
    flow === ContractSignatureCardFlow.ADMIN_SERVICE_ONBOARDING ||
    flow === ContractSignatureCardFlow.WEPA_DIRECT;

  const onClose = () => {
    setShowRideSteuerberatungModal(false);
  };

  const downloadContractHandler = async () => {
    NavigationService.instance?.openLink(contractUrl);
    setHasDownloadContract(true);
    if (flow === ContractSignatureCardFlow.WEPA_DIRECT) {
      WepaDirectOnboardingEvents.StepCompleted({
        step_name: WePaDirectDirectSalesStep.DOWNLOAD_CONTRACT
      });
    }
    await onDownloadContract?.();
  };

  const downloadSignedContract = () => {
    NavigationService.instance?.openLink(downloadSignedContractUrl);
  };

  const submit = async () => {
    if (asOnboardingOrWepaDirectFlow) {
      await onAgreeToTermsAndConditions?.();
    } else if (flow === ContractSignatureCardFlow.ADMIN_SERVICE_MIGRATION) {
      downloadSignedContract();
      history.push(successPageUrl);
    }
  };

  const formik = useRideFormik({
    initialValues: {
      hasAgreedWithServiceDescriptionAndGeneralAgreement: false,
      hasAgreedWithSEPA: false,
      hasConsentToElectronicInvoicing: false,
      hasAgreedToReleaseTaxConsultancy: false,
      hasConsentWithTaxAdvisorDuty: false,
      hasAgreedWithGeneralTermsAndConditions: false,
      steueragentenGeneralRemunerationAgreement: false,
      remunerationAgreement: false,
      informedOfRideSteuerberatungDuty: false,
      informedOfVvSteuerberatungDuty: false,
      consentToAssignment: false,
      obligationOfConfidentiality: false
    },
    onSubmit: submit
  });

  const showCheckboxesAgreementsForSteueragenten = FeatureFlagService.instance.isEnabled(
    FeatureFlags.UpdateCheckboxesSectionForSteueragenten
  );

  const informedOfDuty = renameVvToRideSteuerberatung
    ? formik.values.informedOfRideSteuerberatungDuty
    : formik.values.informedOfVvSteuerberatungDuty;

  function canSubmitForASOnboarding() {
    return (
      formik.values.remunerationAgreement &&
      informedOfDuty &&
      formik.values.consentToAssignment &&
      formik.values.obligationOfConfidentiality
    );
  }

  function canSubmitForASMigration() {
    return (
      formik.values.hasAgreedWithServiceDescriptionAndGeneralAgreement &&
      (formik.values.hasAgreedWithSEPA || paymentMethod !== PaymentMethod.Sepa) &&
      formik.values.hasConsentToElectronicInvoicing &&
      formik.values.hasAgreedToReleaseTaxConsultancy &&
      formik.values.hasConsentWithTaxAdvisorDuty &&
      formik.values.hasAgreedWithGeneralTermsAndConditions
    );
  }

  const canSubmit = () => {
    return (
      hasDownloadContract &&
      (showCheckboxesAgreementsForSteueragenten && invoicedBySteueragenten
        ? formik.values.steueragentenGeneralRemunerationAgreement
        : asOnboardingOrWepaDirectFlow
        ? canSubmitForASOnboarding()
        : canSubmitForASMigration())
    );
  };

  const addCompanyNameToPackageSelectorFlow = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddCompanyNameToPackageSelectorFlow
  );

  useEffect(() => {
    if (!selectedTier || asOnboardingOrWepaDirectFlow) {
      setHasDownloadContract(false);
      formik.setValues({
        hasAgreedWithServiceDescriptionAndGeneralAgreement: false,
        hasAgreedWithSEPA: false,
        hasConsentToElectronicInvoicing: false,
        hasAgreedToReleaseTaxConsultancy: false,
        hasConsentWithTaxAdvisorDuty: false,
        hasAgreedWithGeneralTermsAndConditions: false,
        steueragentenGeneralRemunerationAgreement: false,
        remunerationAgreement: false,
        informedOfRideSteuerberatungDuty: false,
        informedOfVvSteuerberatungDuty: false,
        consentToAssignment: false,
        obligationOfConfidentiality: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier]);

  const prefixSteueragenten = invoicedBySteueragenten ? "steueragenten-" : "";

  return (
    <FormikProvider value={formik}>
      <Form>
        {showRideSteuerberatungModal && (
          <RideSteuerberatungInformationModal
            onClose={onClose}
            useRideSteuerberatung={renameVvToRideSteuerberatung}
          />
        )}
        <OnboardingStepCard
          data-testid={"contract-signature-card"}
          number={stepNumber!}
          toggleEditing={toggleEditing ? () => toggleEditing?.("termsAndConditions") : null}
          forceShowContent={forceOpening}
          status={status}
          title={t(
            `generic:migrate-admin-service-package.steps.contract-signature.${prefixSteueragenten}title`
          )}>
          <div className={"contract-signature-card"}>
            <span className={"contract-signature-card__content"}>
              <div
                data-testid={"download-contract-section"}
                className={"contract-signature-card__section"}>
                <SectionTitle
                  t={t}
                  prefixKey={`generic:migrate-admin-service-package.steps.contract-signature.${prefixSteueragenten}download-contract`}
                />
                {asOnboardingOrWepaDirectFlow && (
                  <Typography
                    category={"Paragraph"}
                    size={100}
                    weight={"Light"}
                    data-testid={"ride-steuerberatung-partnership-description"}>
                    <Trans
                      i18nKey={`generic:admin-service-tiers-onboarding.steps.contract-signature.${
                        renameVvToRideSteuerberatung ? "ride-steuerberatung" : "vvSteuerberatung"
                      }-partnership-description`}
                      components={{
                        bold: <strong />,
                        modalLink: (
                          <TextLink
                            variant={LinkVariant.secondary}
                            to={""}
                            rel={"noopener noreferrer"}
                            onClick={(e) => {
                              e.preventDefault();
                              setShowRideSteuerberatungModal(true);
                            }}
                          />
                        )
                      }}
                    />
                  </Typography>
                )}
                {addCompanyNameToPackageSelectorFlow && (
                  <Typography
                    category={"Paragraph"}
                    size={100}
                    weight={"Light"}
                    data-testid={"contract-download-note"}>
                    <Trans
                      i18nKey={`generic:migrate-admin-service-package.steps.contract-signature.${prefixSteueragenten}download-contract-note`}
                      components={{ bold: <strong /> }}
                    />
                  </Typography>
                )}
                <RideButton
                  type={"button"}
                  data-testid={"download-contract-button"}
                  leftIcon={<DownloadIcon />}
                  label={t(
                    `generic:migrate-admin-service-package.steps.contract-signature.${prefixSteueragenten}download-contract-button`
                  )}
                  variant={RideButtonVariant.SECONDARY}
                  size={RideButtonSize.BIG}
                  className={"contract-signature-card__button-center"}
                  onClick={downloadContractHandler}
                />
              </div>
              <div
                data-testid={"checkboxes-section"}
                className={"contract-signature-card__section"}>
                <SectionTitle
                  t={t}
                  prefixKey={`generic:migrate-admin-service-package.steps.contract-signature.${prefixSteueragenten}service-agreement`}
                  renderSubtitle={!invoicedBySteueragenten}
                />
                {invoicedBySteueragenten && showCheckboxesAgreementsForSteueragenten ? (
                  <>
                    <ContractSignatureCheckbox
                      t={t}
                      disabled={!hasDownloadContract}
                      name={"steueragentenGeneralRemunerationAgreement"}
                      labelSuffix={"steueragenten-general-remuneration-agreement"}
                    />
                  </>
                ) : asOnboardingOrWepaDirectFlow ? (
                  <AgreementsForASOnboarding
                    t={t}
                    hasDownloadContract={hasDownloadContract}
                    useRideSteuerberatung={renameVvToRideSteuerberatung}
                  />
                ) : (
                  <AgreementsForASMigration
                    t={t}
                    hasDownloadContract={hasDownloadContract}
                    paymentMethod={paymentMethod}
                  />
                )}
              </div>
              {!asOnboardingOrWepaDirectFlow && !invoicedBySteueragenten && (
                <RideAlertBar
                  type={AlertType.INFO}
                  message={t(
                    asOnboardingOrWepaDirectFlow
                      ? "generic:admin-service-tiers-onboarding.steps.contract-signature.alert-info"
                      : "generic:migrate-admin-service-package.steps.contract-signature.alert-info"
                  )}
                />
              )}
            </span>
            <RideButton
              disabled={!canSubmit()}
              data-testid={"sign-contract-button"}
              label={t(
                asOnboardingOrWepaDirectFlow
                  ? "generic:admin-service-tiers-onboarding.steps.contract-signature.sign-contract-button"
                  : "generic:migrate-admin-service-package.steps.contract-signature.sign-contract-button"
              )}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
            />
          </div>
        </OnboardingStepCard>
      </Form>
    </FormikProvider>
  );
};

const ContractSignatureCheckbox = ({ t, name, labelSuffix, disabled, isASOnboarding = false }) => {
  return (
    <RideCheckbox
      name={name}
      isReadonly={disabled}
      variant={RideCheckboxVariant.Light}
      label={
        <Trans
          i18nKey={t(
            isASOnboarding
              ? `generic:admin-service-tiers-onboarding.agreements.${labelSuffix}`
              : `generic:migrate-admin-service-package.steps.contract-signature.service-agreement-checkboxes.${labelSuffix}`
          )}
          components={{
            subitem: <Typography size={100} weight={"Light"} category={"Paragraph"} />,
            bold: <strong />
          }}
        />
      }
    />
  );
};

const SectionTitle = ({ t, prefixKey, renderSubtitle = true }) => {
  return (
    <div className={"contract-signature-card__section-title"}>
      <Typography data-testid={"title"} category={"Headline"} size={300} weight={"Heavy"}>
        {t(`${prefixKey}-title`)}
      </Typography>
      {renderSubtitle && (
        <Typography data-testid={"subtitle"} category={"Paragraph"} size={100} weight={"Light"}>
          {t(`${prefixKey}-subtitle`)}
        </Typography>
      )}
    </div>
  );
};

const AgreementsForASOnboarding = ({
  t,
  hasDownloadContract,
  useRideSteuerberatung
}: {
  t: TFunction;
  hasDownloadContract: boolean;
  useRideSteuerberatung: boolean;
}) => {
  return (
    <>
      <Typography data-testid={"anlage-1-title"} category={"Paragraph"} size={100} weight={"Light"}>
        <Trans
          i18nKey={"generic:admin-service-tiers-onboarding.agreements.anlage-1.title"}
          components={{ bold: <strong /> }}
        />
      </Typography>
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"remunerationAgreement"}
        labelSuffix={"anlage-1.remuneration-agreement"}
        isASOnboarding={true}
      />
      <Typography data-testid={"anlage-2-title"} category={"Paragraph"} size={100} weight={"Light"}>
        <Trans
          i18nKey={
            useRideSteuerberatung
              ? "generic:admin-service-tiers-onboarding.agreements.anlage-2-ride-steuerberatung.title"
              : "generic:admin-service-tiers-onboarding.agreements.anlage-2.title"
          }
          components={{ bold: <strong /> }}
        />
      </Typography>
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={
          useRideSteuerberatung
            ? "informedOfRideSteuerberatungDuty"
            : "informedOfVvSteuerberatungDuty"
        }
        labelSuffix={
          useRideSteuerberatung
            ? "anlage-2-ride-steuerberatung.informed-of-ride-steuerberatung-duty"
            : "anlage-2.informed-of-vvSteuerberatung-duty"
        }
        isASOnboarding={true}
      />
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"consentToAssignment"}
        labelSuffix={"anlage-2.consent-to-assignment"}
        isASOnboarding={true}
      />
      <Typography data-testid={"anlage-3-title"} category={"Paragraph"} size={100} weight={"Light"}>
        <Trans
          i18nKey={"generic:admin-service-tiers-onboarding.agreements.anlage-3.title"}
          components={{ bold: <strong /> }}
        />
      </Typography>
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"obligationOfConfidentiality"}
        labelSuffix={
          useRideSteuerberatung
            ? "anlage-3-ride-steuerberatung.obligation-of-confidentiality"
            : "anlage-3.obligation-of-confidentiality"
        }
        isASOnboarding={true}
      />
    </>
  );
};

const AgreementsForASMigration = ({
  t,
  hasDownloadContract,
  paymentMethod
}: {
  t: TFunction;
  hasDownloadContract: boolean;
  paymentMethod: PaymentMethod | null | undefined;
}) => {
  return (
    <>
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"hasAgreedWithServiceDescriptionAndGeneralAgreement"}
        labelSuffix={"service-description"}
      />
      <ContractSignatureCheckbox
        t={t}
        disabled={paymentMethod !== PaymentMethod.Sepa || !hasDownloadContract}
        name={"hasAgreedWithSEPA"}
        labelSuffix={"agreed-with-sepa-debit"}
      />
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"hasConsentToElectronicInvoicing"}
        labelSuffix={"consent-electronic-invoicing"}
      />
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"hasAgreedToReleaseTaxConsultancy"}
        labelSuffix={"agreed-to-release-tax-consultancy"}
      />
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"hasConsentWithTaxAdvisorDuty"}
        labelSuffix={"consent-with-tax-advisor-duty"}
      />
      <ContractSignatureCheckbox
        t={t}
        disabled={!hasDownloadContract}
        name={"hasAgreedWithGeneralTermsAndConditions"}
        labelSuffix={"agreed-general-terms-and-conditions"}
      />
    </>
  );
};

export default withTranslationReady(["generic"])(ContractSignatureCard);
