import React from "react";
import { GetFullPersonDetails } from "components/nominee/NomineePerson/Queries";
import { useMutation } from "react-apollo";
import {
  NomineeUpdateMembership,
  NomineeUpdateMembershipVariables
} from "components/nominee/NomineeCompany/graphql/NomineeUpdateMembership";
import {
  NOMINEE_ADD_EMPTY_COMPANY_FOR_PERSON,
  NOMINEE_UPDATE_MEMBERSHIP
} from "components/nominee/NomineeCompany/Mutations";
import {
  NomineeAddEmptyCompanyForPerson,
  NomineeAddEmptyCompanyForPersonVariables
} from "components/nominee/NomineeCompany/graphql/NomineeAddEmptyCompanyForPerson";
import { RouteComponentProps } from "react-router";
import { FullPersonDetailsQuery } from "components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import NomineePersonScene from "./NomineePersonScene";
import { NOMINEE_COMPANY_DETAILS_QUERY } from "lib/api/queries/GetNomineeCompanyDetailsById";

interface MatchProps {
  id: string;
}

interface Props extends RouteComponentProps<MatchProps> {
  areVotesDisabled: boolean;
}

export const NomineePersonSceneContainer = ({ match, areVotesDisabled }: Props) => {
  const getRequest = GetFullPersonDetails(match.params.id);
  const data: FullPersonDetailsQuery | undefined = getRequest.data;

  const [callUpdateMembership] = useMutation<
    NomineeUpdateMembership,
    NomineeUpdateMembershipVariables
  >(NOMINEE_UPDATE_MEMBERSHIP);

  const [callCreateEmptyCompany, createEmptyCompanyRequest] = useMutation<
    NomineeAddEmptyCompanyForPerson,
    NomineeAddEmptyCompanyForPersonVariables
  >(NOMINEE_ADD_EMPTY_COMPANY_FOR_PERSON);

  const updateMembership = async (data: NomineeUpdateMembershipVariables) => {
    await callUpdateMembership({
      variables: data,
      refetchQueries: [
        {
          query: NOMINEE_COMPANY_DETAILS_QUERY,
          variables: { id: data.companyId }
        }
      ]
    });
  };

  const createEmptyCompany = async (personId: string) => {
    await callCreateEmptyCompany({ variables: { personId } });
  };

  const refetch = async () => {
    await getRequest.refetch();
  };

  return (
    <RequestFeedbackHandler requests={[getRequest]}>
      {data?.getPersonDetails && (
        <NomineePersonScene
          areVotesDisabled={areVotesDisabled}
          person={data.getPersonDetails}
          refetch={refetch}
          createEmptyCompany={createEmptyCompany}
          createEmptyCompanyRequest={createEmptyCompanyRequest}
          updateMembership={updateMembership}
        />
      )}
    </RequestFeedbackHandler>
  );
};
