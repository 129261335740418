import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Trans, WithTranslation } from "react-i18next";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { BlankPage, WidthVariantEnum } from "../templates/BlankPage/BlankPage";
import { CardContainer } from "../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { RideCheckbox } from "../../../sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import {
  ButtonRadius,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "lib/api/mutations/graphql/UpsertOrder";
import { OrderCompanyStatusEnum, OrderProductTypeEnum, OrderOwnerType } from "global-query-types";
import { PersonalInformationSchema } from "../../../lib/validation/PersonalInformationSchema";
import logger from "../../../common/Logger";
import {
  FinalizeOrder_finalizeOrder,
  FinalizeOrderVariables
} from "../../../lib/api/mutations/graphql/FinalizeOrder";
import { useHistory } from "react-router-dom";
import { RideUrls } from "../../../common/URLs";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { storeToken } from "common/Authentication/storeToken";
import {
  PriceItem,
  PriceOperation,
  PriceSection
} from "../ChaptersOrderLayout/sharedComponents/PriceSection/PriceSection";
import { registerCourt } from "../../../common/staticData/registerCourtOptions/registerCourt";
import { parseFullCompanyName } from "../../../lib/parsers/parseFullCompanyName";
import { parseGermanStreet } from "../../../lib/parsers/parseGermanStreet";
import { RideRadioSelect } from "../../../uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { CompanyInformationSchema } from "../../../lib/validation/CompanyInformationSchema";
import { RideDropdownSelect } from "../../../uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { DataLayer } from "../../../lib/services/GoogleTagManager/GoogleTagManager";

interface OrderData {
  ordererPerson: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  companyData: {
    fullName: string;
    name: string;
    legalForm: string;
    registryNumber: string;
    registryCourt: string;
    hasHoldingShareholderWithMoreThanHalfOwnership: boolean;
  };
  companyAddress: {
    street: string;
    streetName: string;
    buildingNumber: string;
    postalCode: string;
    stateCode: string;
    city: string;
    country: string;
  };
}

export interface OrderLeiProps extends WithTranslation {
  orderData: OrderData | null;
  upsertOrder: (
    variables: UpsertOrderVariables
  ) => Promise<UpsertOrder_upsertOrder | null | undefined>;
  finalizeOrder: (
    variables: FinalizeOrderVariables
  ) => Promise<FinalizeOrder_finalizeOrder | null | undefined>;
}

const OrderLei = ({ t, orderData, upsertOrder, finalizeOrder }: OrderLeiProps) => {
  const { ordererPerson, companyAddress, companyData } = orderData ?? {};
  const { firstName, lastName, email, phoneNumber } = ordererPerson ?? {};
  const { street, postalCode, city } = companyAddress ?? {};
  const {
    fullName,
    registryNumber,
    registryCourt,
    hasHoldingShareholderWithMoreThanHalfOwnership
  } = companyData ?? {};

  const productPrice = 25.2;
  const { push } = useHistory();

  const hasHoldingShareholderWithMoreThanHalfOwnershipValue =
    hasHoldingShareholderWithMoreThanHalfOwnership ? "yes" : "no";

  const initialValues = {
    firstName,
    lastName,
    email,
    phoneNumber,
    companyStreet: street,
    companyPostalCode: postalCode,
    companyCity: city,
    companyFullName: fullName,
    companyRegistryNumber: registryNumber,
    companyRegistryCourt: registryCourt,
    hasHoldingShareholderWithMoreThanHalfOwnership:
      hasHoldingShareholderWithMoreThanHalfOwnershipValue,
    authorisedLegalRepresentative: false,
    termsAndConditionsCheckbox: false
  };

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    phoneNumber,
    companyStreet,
    companyPostalCode,
    companyCity,
    companyFullName,
    companyRegistryNumber,
    companyRegistryCourt,
    hasHoldingShareholderWithMoreThanHalfOwnership,
    authorisedLegalRepresentative,
    termsAndConditionsCheckbox
  }) => {
    try {
      const { name, legalForm } = parseFullCompanyName(companyFullName);
      const { streetName, buildingNumber } = parseGermanStreet(companyStreet);

      const result = await upsertOrder({
        status: OrderCompanyStatusEnum.InProgress,
        ownerType: OrderOwnerType.Guest,
        productType: OrderProductTypeEnum.LEI,
        extra: {
          ordererPerson: {
            firstName,
            lastName,
            email,
            phoneNumber
          },
          companyData: {
            fullName: companyFullName,
            name,
            legalForm,
            registryNumber: companyRegistryNumber,
            registryCourt: companyRegistryCourt,
            hasHoldingShareholderWithMoreThanHalfOwnership:
              hasHoldingShareholderWithMoreThanHalfOwnership === "yes"
          },
          companyAddress: {
            street: companyStreet,
            streetName,
            buildingNumber,
            postalCode: companyPostalCode,
            city: companyCity,
            country: "DE"
          },
          authorisedLegalRepresentative,
          termsAndConditionsCheckbox,
          hubspotFormSubmissionRequested: true
        }
      });

      if (!result) return;

      const finalizeOrderResult = await finalizeOrder({
        id: result.id,
        submissionId: result.submissionId,
        ownerType: OrderOwnerType.Guest
      });

      if (finalizeOrderResult?.token) {
        const { token } = finalizeOrderResult;
        storeToken(token);
      }

      DataLayer.LeiRegistrationSubmission();

      push(`/order/lei/complete`);
    } catch (error) {
      logger.errorMessage(`Could not create or update order`);
      logger.error(error as Error);
    }
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: PersonalInformationSchema(t).concat(CompanyInformationSchema(t))
  });

  const prices: PriceItem[] = [
    {
      value: productPrice,
      key: t("generic:order-form.subtotal"),
      operation: PriceOperation.BASE
    },
    {
      value: productPrice * 0.19,
      key: t("generic:order-form.vat-19"),
      operation: PriceOperation.ADD
    }
  ];

  return (
    <BlankPage
      title={t("generic:lei-order-form.title")}
      subTitle={t("generic:lei-order-form.sub-title")}
      variant={WidthVariantEnum.NARROW}>
      <FormikProvider value={formik}>
        <Form className={`order-form`}>
          <CompanyInformation formik={formik} t={t} />
          <PersonalInformation formik={formik} t={t} />
          <LeiOrderOverview t={t} />
          <hr className={"order-form__divider"} />
          <PriceSection prices={prices} />
          <div className={"order-form__checkboxes"}>
            <RideCheckbox
              formik={formik}
              name="authorisedLegalRepresentative"
              data-testid="authorised-legal-representative"
              label={t("generic:order-form.checkboxes.authorised-legal-representative")}
              className={"order-form__checkbox"}
            />
            <RideCheckbox
              formik={formik}
              name="termsAndConditionsCheckbox"
              data-testid="terms-and-conditions"
              label={
                <Trans
                  i18nKey="generic:order-form.checkboxes.terms-and-conditions"
                  components={{
                    terms: (
                      <TextLink
                        to={RideUrls.TermsAndConditions}
                        variant={LinkVariant.primary}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                    privacy: (
                      <TextLink
                        to={RideUrls.PrivacyPolicy}
                        variant={LinkVariant.primary}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    )
                  }}
                />
              }
              className={"order-form__checkbox"}
            />
          </div>
          <div className={"order-form__submit"}>
            <RideButtonDeprecated
              className={"order-form__submit-button"}
              disabled={
                !formik.values["authorisedLegalRepresentative"] ||
                !formik.values["termsAndConditionsCheckbox"]
              }
              radius={ButtonRadius.Soft}
              data-testid="order-button"
              variant={ButtonVariant.Primary}
              type="submit">
              {t("generic:order-form.order-button")}
            </RideButtonDeprecated>
          </div>
        </Form>
      </FormikProvider>
    </BlankPage>
  );
};

const PersonalInformation = ({ formik, t }) => (
  <>
    <div className={"order-form__section-title"}>
      {t("generic:lei-order-form.section-personal-information")}
    </div>

    <div className="order-form__inputs">
      <Row className={"order-form__input-row"}>
        <Col sm={12} md={6}>
          <RideInput
            formik={formik}
            name="firstName"
            label={t("generic:first-name")}
            placeholder={t("generic:first-name")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>

        <Col sm={12} md={6}>
          <RideInput
            formik={formik}
            name="lastName"
            label={t("generic:last-name")}
            placeholder={t("generic:last-name")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="phoneNumber"
            className={"order-form__input"}
            label={t("generic:order-form.phone")}
            type={"tel"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="email"
            type="email"
            label={t("generic:order-form.email")}
            placeholder={t("generic:email")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>
    </div>
  </>
);

const CompanyInformation = ({ formik, t }) => {
  return (
    <>
      <div className={"order-form__section-title"}>
        {t("generic:lei-order-form.section-company-information")}
      </div>

      <div className="order-form__inputs">
        <Row className={"order-form__input-row"}>
          <Col>
            <RideInput
              formik={formik}
              name="companyFullName"
              label={t("generic:company-name")}
              placeholder={t("generic:company-name")}
              className={"order-form__input"}
              autoComplete="on"
            />
          </Col>
        </Row>

        <Row className={"order-form__input-row"}>
          <Col sm={12} md={6}>
            <RideInput
              formik={formik}
              name="companyRegistryNumber"
              label={t("generic:registration")}
              placeholder={t("generic:registration")}
              className={"order-form__input"}
              autoComplete="on"
            />
          </Col>

          <Col sm={12} md={6}>
            <RideDropdownSelect
              name="companyRegistryCourt"
              label={t("generic:registerCourt")}
              placeholder={t("generic:registerCourt")}
              className={"order-form__input"}
              options={registerCourt}
              isSearchable={true}
            />
          </Col>
        </Row>

        <Row className={"order-form__input-row"}>
          <Col sm={12} md={6}>
            <RideInput
              formik={formik}
              name="companyStreet"
              label={t("generic:street")}
              placeholder={t("generic:street")}
              className={"order-form__input"}
              autoComplete="on"
            />
          </Col>

          <Col sm={12} md={6}>
            <RideInput
              formik={formik}
              name="companyPostalCode"
              label={t("generic:postal-code")}
              placeholder={t("generic:postal-code")}
              className={"order-form__input"}
              autoComplete="on"
            />
          </Col>
        </Row>

        <Row className={"order-form__input-row"}>
          <Col>
            <RideInput
              formik={formik}
              name="companyCity"
              label={t("generic:city")}
              placeholder={t("generic:city")}
              className={"order-form__input"}
              autoComplete="on"
            />
          </Col>
        </Row>

        <Row className={"order-form__input-row"}>
          <Col>
            <RideRadioSelect
              name="hasHoldingShareholderWithMoreThanHalfOwnership"
              label={t("generic:lei-order-form:hasHoldingShareholderWithMoreThanHalfOwnership")}
              className={"order-form__input"}
              options={[
                { value: "yes", label: t("generic:yes") },
                { value: "no", label: t("generic:no") }
              ]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const LeiOrderOverview = ({ t }) => (
  <>
    <div className={"order-form__section-title"}>
      {t("generic:lei-order-form.section-your-order")}
    </div>

    <CardContainer className="order-form__summary">
      <div className="order-form__summary-header">
        <div className="order-form__summary-icon" />
        <Col className="order-form__summary-title">
          <div>{t("generic:lei-order-form.summary-title")}</div>
          <div className="order-form__summary-time-frame">
            {t("generic:lei-order-form.time-frame")}
          </div>
        </Col>
      </div>

      <div className="order-form__summary-body">
        <ul className="order-form__summary-items">
          <li className="order-form__summary-item">{t("generic:lei-order-form.items.1")}</li>
          <li className="order-form__summary-item">{t("generic:lei-order-form.items.2")}</li>
          <li className="order-form__summary-item">{t("generic:lei-order-form.items.3")}</li>
        </ul>
      </div>

      <div className="order-form__summary-footer">
        <div className="order-form__summary-price">{t("generic:lei-order-form.price")}</div>
        <div className="order-form__summary-small-print">
          {t("generic:lei-order-form.small-print")}
        </div>
      </div>
    </CardContainer>
  </>
);

export default withTranslationReady(["generic"])(OrderLei);
