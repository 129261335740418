import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RideTableRow } from "../../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../../sharedComponents/RideTableCell/RideTableCell";
import KYCStatusLabel from "./KYCStatusLabel";
import { RoleEntry } from "../../../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import { FormikProps } from "formik";

interface EditRolesProp extends WithTranslation {
  roles: RoleEntry[] | null;
  formik: FormikProps<any>;
}

const EditRoles = ({ t, roles, formik }: EditRolesProp) => {
  const getPersonValue = (role) => {
    const fullName = role.company
      ? `${role.company.name} ${t(`generic:legalForm.${role.company.legalForm}`)}`
      : `${role.person?.firstName} ${role.person?.lastName}`;
    return fullName;
  };

  return (
    <div data-testid="roles-edit-form">
      {roles &&
        roles.map((role, index) => {
          return (
            <RideTableRow colCount={6} key={role.id}>
              <RideTableCell
                variant={RideTableCellVariant.text}
                value={getPersonValue(role)}
                dataTestId="shareholder-person"
                classes="mobile-full"
              />
              <RideTableCell
                variant={RideTableCellVariant.badge}
                dataTestId="shareholder-status"
                classes="mobile-full"
                badge={
                  <KYCStatusLabel
                    isKycRequired={role.isKycRequired}
                    isKycSucceeded={role.isKycSucceeded}
                  />
                }
              />
              {role.person?.id ? (
                <RideTableCell
                  dataTestId="shareholder-director"
                  name={`roles.${index}.isManagingDirector`}
                  variant={RideTableCellVariant.checkbox}
                  checked={formik.values.roles[index]?.isManagingDirector ?? false}
                  action={(checked) => {
                    formik.setFieldValue(`roles.${index}.isManagingDirector`, checked);
                  }}
                />
              ) : (
                <RideTableCell
                  variant={RideTableCellVariant.secondaryText}
                  name={`roles.${index}.isManagingDirector`}
                  value={t("generic:not-applicable")}
                />
              )}
              <RideTableCell
                dataTestId="shareholder-silent-partner"
                name={`roles.${index}.isSilentPartner`}
                variant={RideTableCellVariant.checkbox}
                checked={formik.values.roles[index]?.isSilentPartner ?? false}
                action={(checked) => {
                  formik.setFieldValue(`roles.${index}.isSilentPartner`, checked);
                }}
              />
              <RideTableCell
                dataTestId="shareholder-share"
                variant={RideTableCellVariant.input}
                inputType="number"
                name="shareholder-share"
                inputValue={formik.values.roles[index]?.share}
                action={(event) => {
                  formik.setFieldValue(`roles.${index}.share`, event.target.value * 1);
                }}
              />
              <RideTableCell
                dataTestId="shareholder-votes"
                variant={RideTableCellVariant.input}
                inputType="number"
                name="shareholder-votes"
                inputValue={formik.values.roles[index]?.votes}
                action={(event) => {
                  formik.setFieldValue(`roles.${index}.votes`, event.target.value * 1);
                }}
              />
            </RideTableRow>
          );
        })}
    </div>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(EditRoles);
