import React from "react";
import { WithTranslation } from "react-i18next";
import { PageLayout } from "sharedComponents/templates/PageLayout/PageLayout";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import NewBrokerOrdersListContainer from "../../components/NewBrokerOrdersList/NewBrokerOrdersListContainer";

const NewBrokerOrdersListScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("generic:new-broker-order-list.title")}
      mainContent={<NewBrokerOrdersListContainer />}
    />
  );
};

export default withTranslationReady(["generic"])(NewBrokerOrdersListScene);
