import React from "react";
import { PaginationFirst } from "../../../sharedComponents/icons/PaginationFirst";
import { PaginationPrev } from "../../../sharedComponents/icons/PaginationPrev";
import { PaginationLast } from "../../../sharedComponents/icons/PaginationLast";
import { PaginationNext } from "../../../sharedComponents/icons/PaginationNext";
import { range } from "lodash";

interface RidePaginationProps {
  count?: number;
  pageSize?: number;
  start?: number;
  children?: React.ReactNode;
  setStart: (start: number) => void;
}

export const RidePagination = ({
  children,
  count = 0,
  pageSize = 0,
  start,
  setStart
}: RidePaginationProps) => {
  let pageCount = 0;

  const currentPage = Math.round((start ?? 0) / pageSize + 1);

  if (count && pageSize) {
    pageCount = Math.ceil(count / pageSize);
  }

  const pages = range(1, pageCount + 1).map((page) => {
    return (
      <li
        key={`page-${page}`}
        data-testid={`ride-pagination-page-${page}`}
        onClick={() => selectPageHandler(page)}
        className={`ride-pagination__button ride-pagination__button--page ${
          currentPage === page ? "ride-pagination__button--active" : ""
        }`}>
        <span
          className={`ride-pagination__button-number ${
            currentPage === page ? "ride-pagination__button-number--active" : ""
          }`}>
          {page}
        </span>
      </li>
    );
  });

  if (pageCount - currentPage > 3) {
    pages.splice(
      currentPage + 2,
      pageCount - (currentPage + 3),
      <li
        key={`page-dots-before`}
        data-testid={`ride-pagination-page-dots`}
        className={`ride-pagination__button`}>
        ...
      </li>
    );
  }

  if (currentPage >= 5) {
    pages.splice(
      1,
      currentPage - 4,
      <li
        key={`page-dots-after`}
        data-testid={`ride-pagination-page-dots`}
        className={`ride-pagination__button`}>
        ...
      </li>
    );
  }

  const selectPageHandler = (pageValue) => {
    let pageNumber = pageValue;
    if (pageNumber < 1) pageNumber = 1;
    if (pageNumber > pageCount) pageNumber = pageCount;

    setStart((pageNumber - 1) * pageSize);
  };

  return (
    <div className="ride-pagination" data-testid="ride-pagination">
      {children}
      {pageCount > 0 && count > pageSize && (
        <nav data-testid="ride-pagination-container">
          <ul className="ride-pagination-list">
            <li
              data-testid="ride-pagination-page-first"
              onClick={() => selectPageHandler(1)}
              className="ride-pagination__button">
              <PaginationFirst />
            </li>
            <li
              data-testid="ride-pagination-page-prev"
              onClick={() => selectPageHandler(currentPage - 1)}
              className="ride-pagination__button">
              <PaginationPrev />
            </li>
            {pages.map((page) => page)}
            <li
              data-testid="ride-pagination-page-next"
              onClick={() => selectPageHandler(currentPage + 1)}
              className="ride-pagination__button">
              <PaginationNext />
            </li>
            <li
              data-testid="ride-pagination-page-last"
              onClick={() => selectPageHandler(pageCount)}
              className="ride-pagination__button">
              <PaginationLast />
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};
