import React from "react";

interface YearItemProps {
  year: String;
  before: number | string;
  after: number | string;
  unit: String;
}

export const YearItem = ({ year, before, after, unit = "" }: YearItemProps) => {
  return (
    <div className="year-item">
      <div data-testid="year-item-year" className="year-item__year">
        {year}
      </div>
      <div data-testid="year-item-value" className="year-item__value">
        <strong>
          {before}
          {unit}
        </strong>{" "}
        <span className="year-item__after">
          ({after}
          {unit})
        </span>
      </div>
    </div>
  );
};
