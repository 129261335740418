import React from "react";
import { TaxAdvisorRow } from "client/components/AdminServiceSection/AdminServiceSection";
import { WithTranslation, withTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { GET_THIRD_PARTY_TAX_ADVISOR_BY_CODE } from "lib/api/queries/GetThirdPartyTaxAdvisorByCode";
import {
  getThirdPartyTaxAdvisorByCode,
  getThirdPartyTaxAdvisorByCodeVariables
} from "lib/api/queries/graphql/getThirdPartyTaxAdvisorByCode";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { Container } from "react-bootstrap";

interface WepaThirdPartyTaxAdvisorContainerProps extends WithTranslation {
  taxAdvisorCode: string;
}

export const WepaThirdPartyTaxAdvisorContainer = ({
  taxAdvisorCode,
  t
}: WepaThirdPartyTaxAdvisorContainerProps) => {
  const getThirdPartyTaxAdvisorByCodeRequest = useQuery<
    getThirdPartyTaxAdvisorByCode,
    getThirdPartyTaxAdvisorByCodeVariables
  >(GET_THIRD_PARTY_TAX_ADVISOR_BY_CODE, { variables: { taxAdvisorCode } });

  const taxAdvisor =
    getThirdPartyTaxAdvisorByCodeRequest.data?.getThirdPartyTaxAdvisorByCode?.person;

  return (
    <Container className="d-flex p-0">
      <RequestFeedbackHandler requests={[getThirdPartyTaxAdvisorByCodeRequest]}>
        {taxAdvisor && (
          <TaxAdvisorRow
            fistName={taxAdvisor?.firstName}
            lastName={taxAdvisor?.lastName}
            type="User"
            t={t}
          />
        )}
      </RequestFeedbackHandler>
    </Container>
  );
};

export default withTranslation(["generic", "third-party-tax-advisor"])(
  WepaThirdPartyTaxAdvisorContainer
);
