import { GetOrderQuery_getOrder } from "../../../lib/api/queries/graphql/GetOrderQuery";
import {
  OrderCompanyStatusEnum,
  OrderProductTypeEnum,
  OrderEntityType,
  OrderOwnerType
} from "../../../global-query-types";
import React from "react";
import {
  NextButton,
  OrderChaptersConfig
} from "../ChaptersOrderLayout/ChaptersOrderLayout.partials";
import BackButton from "../../../uiLibrary/components/Buttons/BackButton/BackButton";
import { ExternalContentItem } from "../../../lib/ports/ExternalContentProvider";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";

export const fakeContextualHelp: ExternalContentItem = {
  name: "sample-key",
  en_content:
    '<h3>Why RIDE?</h3>\n<p>RIDE is the first FinTech to focus on real returns, after taxes and costs. We help our clients structure their assets intelligently so they can invest more and consume more on net. <a href="https://www.ride.capital/blog" rel="noopener" target="_blank">Learn more</a></p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>',
  de_content:
    '<h3>Warum RIDE?</h3>\n<p>RIDE ist das erste FinTech, das sich auf die echte Rendite, nach Steuern und Kosten, konzentriert. Wir helfen unseren Kundinnen und Kunden, ihr Vermögen intelligent zu strukturieren, um mehr investieren und netto mehr konsumieren zu können. <a href="https://www.ride.capital/blog" rel="noopener" target="_blank">Learn more</a></p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>'
};

export const tooMuchContentFakeContextualHelp: ExternalContentItem = {
  ...fakeContextualHelp,
  en_content: [
    fakeContextualHelp.en_content,
    fakeContextualHelp.en_content,
    fakeContextualHelp.en_content,
    fakeContextualHelp.en_content,
    fakeContextualHelp.en_content,
    fakeContextualHelp.en_content,
    fakeContextualHelp.en_content,
    fakeContextualHelp.en_content
  ].join("\n"),
  de_content: [
    fakeContextualHelp.de_content,
    fakeContextualHelp.de_content,
    fakeContextualHelp.de_content,
    fakeContextualHelp.de_content,
    fakeContextualHelp.de_content,
    fakeContextualHelp.de_content,
    fakeContextualHelp.de_content,
    fakeContextualHelp.de_content
  ].join("\n")
};

const Step1 = (props) => (
  <div>
    <h3>Step One</h3>
    <div className="chapters-order-layout__step-navigation-controls">
      <BackButton onClick={() => {}} />
      <NextButton {...props} />
    </div>
  </div>
);

const SubChapterStep1 = (props) => (
  <div>
    <div>HELLO THERE!</div>
    {props.controls}
  </div>
);

const Step2 = (props) => (
  <div>
    <h3>Step Two</h3>
    <div className="chapters-order-layout__step-navigation-controls">
      <BackButton onClick={() => {}} />
      <NextButton {...props} />
    </div>
  </div>
);
const Step3 = (props) => (
  <div>
    <h3>Step Three</h3>
    <div className="chapters-order-layout__step-navigation-controls">
      <BackButton onClick={() => {}} />
      <NextButton {...props} />
    </div>
  </div>
);
const Step4 = (props) => (
  <div>
    <h3>Step Four</h3>
    <div className="chapters-order-layout__step-navigation-controls">
      <BackButton onClick={() => {}} />
      <NextButton {...props} />
    </div>
  </div>
);
const Step5 = (props) => (
  <div>
    <h3>Step Five</h3>
    <div className="chapters-order-layout__step-navigation-controls">
      <BackButton onClick={() => {}} />
      <NextButton {...props} />
    </div>
  </div>
);
const Step6 = (props) => (
  <div>
    <h3>Step Six</h3>
    <div className="chapters-order-layout__step-navigation-controls">
      <BackButton onClick={() => {}} />
      <NextButton {...props} />
    </div>
  </div>
);

export const exampleConfig: OrderChaptersConfig = {
  chapters: {
    "business-purpose": {
      icon: "rocket",
      title: "generic:company-setup.chapters.business-purpose"
    },
    "shareholder-details": {
      icon: "id-card",
      title: "generic:company-setup.chapters.shareholder-details"
    },
    "managing-directors": {
      icon: "grid",
      title: "generic:company-setup.chapters.managing-directors"
    },
    "company-name": {
      icon: "bulb",
      title: "generic:company-setup.chapters.company-name"
    },
    "company-address": {
      icon: "location",
      title: "generic:company-setup.chapters.company-address"
    }
  },
  steps: {
    "step-1": Step1,
    "step-2": Step2,
    "step-3": Step3,
    "step-4": Step4,
    "step-5": Step5,
    "step-6": Step6,
    "sub-chapter-key-1": SubChapterStep1
  },
  contextualHelpSuffix: "suffix"
};

export const exampleConfigWithAlert: OrderChaptersConfig = {
  ...exampleConfig,
  alerts: {
    "some-alert": {
      shouldShow: () => true,
      component: () => (
        <RideAlertBar
          type={AlertType.INFO}
          title={"Important information"}
          message={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean non efficitur est, ac sollicitudin felis. Maecenas eu dictum metus. Donec molestie hendrerit dui, quis facilisis neque egestas ut. Integer tempus nunc at nibh rhoncus, malesuada luctus eros egestas. Cras turpis urna, porta vel mollis pharetra, pulvinar ut nibh. Pellentesque suscipit pellentesque massa nec tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec viverra purus id laoreet fermentum. Ut placerat eget nibh sit amet vulputate."
          }
        />
      )
    }
  }
};

export const exampleOrder: GetOrderQuery_getOrder = {
  __typename: "OrderDetails",
  id: "order-id",
  status: OrderCompanyStatusEnum.InProgress,
  ownerId: "owner-id",
  entityId: "entity-id",
  createdAt: null,
  ownerType: OrderOwnerType.Person,
  updatedAt: null,
  entityType: OrderEntityType.Company,
  submissionId: "submission-id",
  transferredAt: null,
  productType: OrderProductTypeEnum.CompanyFoundation,

  extra: {
    chapters: {
      "business-purpose": {
        steps: [{ key: "step-1" }, { key: "step-2" }, { key: "step-3" }]
      },
      "shareholder-details": {
        steps: [
          { key: "step-1", completed: true },
          { key: "step-2", completed: true }
        ]
      },
      "managing-directors": {
        steps: [{ key: "step-1" }, { key: "step-2" }],
        subChapters: [
          {
            key: "a-sub-chapter",
            steps: [
              {
                key: "sub-chapter-key-1",
                index: 0
              }
            ]
          }
        ]
      },
      "company-name": {
        steps: [{ key: "step-1" }, { key: "step-2" }]
      },
      "company-address": {
        steps: [{ key: "step-1" }, { key: "step-2" }]
      }
    }
  }
};

export const exampleOrderWithShareholders: GetOrderQuery_getOrder = {
  __typename: "OrderDetails",
  id: "order-id",
  status: OrderCompanyStatusEnum.InProgress,
  ownerId: "owner-id",
  entityId: "entity-id",
  createdAt: null,
  ownerType: OrderOwnerType.Person,
  updatedAt: null,
  entityType: OrderEntityType.Company,
  submissionId: "submission-id",
  transferredAt: null,
  productType: OrderProductTypeEnum.CompanyFoundation,

  extra: {
    chapters: {
      "business-purpose": {
        steps: [{ key: "step-1" }, { key: "step-2" }, { key: "step-3" }]
      },
      "shareholder-details": {
        steps: [
          {
            key: "shareholders-list"
          }
        ],
        "sub-chapters": [
          {
            key: "edit-shareholder-data",
            steps: [
              {
                key: "shareholder-general-information",
                index: 0,
                completed: false
              },
              {
                key: "shareholder-review",
                index: 0,
                completed: true
              }
            ]
          },
          {
            key: "edit-shareholder-data",
            steps: [
              {
                key: "shareholder-general-information",
                index: 1,
                completed: true
              },
              {
                key: "shareholder-review",
                index: 1,
                completed: true
              }
            ]
          },
          {
            key: "edit-shareholder-data",
            steps: [
              {
                key: "shareholder-general-information",
                index: 2
              },
              {
                key: "shareholder-review",
                index: 2,
                completed: true
              }
            ]
          }
        ]
      },
      "managing-directors": {
        steps: [{ key: "step-1" }, { key: "step-2" }],
        subChapters: [
          {
            key: "a-sub-chapter",
            steps: [
              {
                key: "sub-chapter-key-1",
                index: 0
              }
            ]
          }
        ]
      },
      "company-name": {
        steps: [{ key: "step-1" }, { key: "step-2" }]
      },
      "company-address": {
        steps: [{ key: "step-1" }, { key: "step-2" }]
      }
    },
    shareholders: {
      0: {
        shareholderType: "Person",
        personData: {
          firstName: "Abdul",
          lastName: "Mustafa"
        },
        companyAddress: {
          country: "NL",
          streetLine1: "Musterstr 42",
          streetLine2: "c/o Meme Lord",
          city: "Amsterdam",
          postalCode: "27002"
        }
      },
      1: {
        shareholderType: "Person",
        personData: {
          firstName: "Elton",
          lastName: "John"
        },
        companyAddress: {
          country: "NL",
          streetLine1: "Kantstrasse 54",
          streetLine2: "c/o Daniel Schwarzmüller",
          city: "Berlin",
          postalCode: "36520"
        }
      },
      2: {
        shareholderType: "Company",
        companyData: {
          companyName: "Kinitos"
        },
        companyAddress: {
          country: "NL",
          streetLine1: "Paderborner Strasse 17",
          city: "Sielenbach",
          postalCode: "27002"
        }
      }
    }
  }
};

export const exampleOrderWithoutChapters: GetOrderQuery_getOrder = {
  __typename: "OrderDetails",
  id: "order-id",
  status: OrderCompanyStatusEnum.InProgress,
  ownerId: "owner-id",
  entityId: "entity-id",
  createdAt: null,
  ownerType: OrderOwnerType.Person,
  updatedAt: null,
  entityType: OrderEntityType.Company,
  submissionId: "submission-id",
  transferredAt: null,
  productType: OrderProductTypeEnum.CompanyFoundation,

  extra: {
    chapters: undefined
  }
};
