import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Formik } from "formik";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import TextInput from "../../generic/TextInput";
import { useMutation } from "react-apollo";
import { CREATE_TOKENIZED_INVESTMENT_MUTATION } from "./Queries";
import {
  createTokenizedInvestmentMutation,
  createTokenizedInvestmentMutationVariables
} from "./graphql/createTokenizedInvestmentMutation";
import { AssertionError } from "assert";
import SuccessAlert from "../../generic/SuccessAlert";
import LoadingAlert from "../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { UpsertTokenizedInvestmentSchema } from "../../../lib/validation/UpsertTokenizedInvestmentSchema";

interface UpsertTokenizedInvestmentProps extends WithTranslation {
  projectName: string;
  companyId: string;
  image: string;
  equityTokenAddress: string;
  spvWalletAddress: string;
}

export interface IUpdateTokenizedValues {
  projectName: string;
  companyId: string;
  image: string;
  equityTokenAddress: string;
  spvWalletAddress: string;
}

export const UpsertTokenizedInvestment: React.FunctionComponent<UpsertTokenizedInvestmentProps> = (
  props
) => {
  const { t, projectName, companyId, image, equityTokenAddress, spvWalletAddress } = props;
  const [success, setSuccess] = useState(false);

  const [callTokenizedInvestmentMutation, { error, loading }] = useMutation<
    createTokenizedInvestmentMutation,
    createTokenizedInvestmentMutationVariables
  >(CREATE_TOKENIZED_INVESTMENT_MUTATION);

  const errorReason = error && error.message;
  const assetWarningAlert = (
    <Alert variant="danger">{t("spv-company:warn-no-project-linked")}</Alert>
  );

  const initialData: IUpdateTokenizedValues = {
    companyId: companyId,
    image: image,
    equityTokenAddress: equityTokenAddress,
    spvWalletAddress: spvWalletAddress,
    projectName: projectName
  };

  const onSubmit = async (values: IUpdateTokenizedValues) => {
    try {
      const { data } = await callTokenizedInvestmentMutation({
        variables: {
          assetName: values.projectName,
          equityTokenAddress: values.equityTokenAddress,
          spvWalletAddress: values.spvWalletAddress,
          assetImage: values.image,
          companyId: values.companyId
        }
      });
      if (data) {
        setSuccess(true);
      }
    } catch (error) {
      throw new AssertionError({ message: "tokenized Investment not successful" });
    }
  };

  return (
    <>
      {success ? <SuccessAlert t={t} message={t("generic:success_heading")} /> : null}
      <LoadingAlert t={t} loading={loading} message={t("generic:loading-message")} />
      <ErrorAlertLegacy t={t} error={errorReason} />
      {image === null ? assetWarningAlert : ""}
      <Formik
        initialValues={initialData}
        validationSchema={UpsertTokenizedInvestmentSchema}
        validateOnChange={true}
        onSubmit={onSubmit}>
        {({ handleSubmit, handleChange, values, touched, isValid, errors }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="show-grid">
                <Col>
                  <TextInput
                    name="equityTokenAddress"
                    t={t}
                    type="text"
                    label="spv-company:equityContractAddress"
                    onChange={handleChange}
                    value={values.equityTokenAddress}
                    error={errors.equityTokenAddress}
                    isValid={touched.equityTokenAddress && !errors.equityTokenAddress}
                    isInvalid={errors.equityTokenAddress != null}
                    data-testid="equityTokenAddress-input"
                  />
                </Col>
                <Col>
                  <TextInput
                    name="spvWalletAddress"
                    t={t}
                    type="text"
                    label="spv-company:nominee-wallet-address"
                    onChange={handleChange}
                    value={values.spvWalletAddress}
                    error={errors.spvWalletAddress}
                    isValid={touched.spvWalletAddress && !errors.spvWalletAddress}
                    isInvalid={errors.spvWalletAddress != null}
                    data-testid="nomineeWalletAddress-input"
                  />
                </Col>
              </Row>
              <Button
                type="submit"
                data-testid="submit"
                size={"sm"}
                disabled={!isValid || image === null}>
                {t("generic:update")}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withTranslation(["generic", "spv-company", "realEstate"])(UpsertTokenizedInvestment);
