import gql from "graphql-tag";

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod(
    $companyId: String!
    $paymentMethod: PaymentMethodType!
    $paymentPlatformAccount: PaymentPlatformAccount!
  ) {
    updatePaymentMethod(
      companyId: $companyId
      paymentMethod: $paymentMethod
      paymentPlatformAccount: $paymentPlatformAccount
    ) {
      result
    }
  }
`;
