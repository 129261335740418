import React from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { Trans, WithTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import RequestFeedbackHandler from "../../components/generic/RequestFeedbackHandler";
import ClientTextFieldEdit from "../../client/components/ClientTextFieldEdit/ClientTextFieldEdit";
import { GetMyTaxAdvisorCompanyWithClientsQuery } from "../../taxAdvisor/Queries";
import { CompanyType } from "../../global-query-types";
import {
  UpdateCompanyById,
  UpdateCompanyByIdVariables
} from "../../lib/api/mutations/graphql/UpdateCompanyById";
import { UPDATE_COMPANY_BY_ID } from "../../lib/api/mutations/UPDATE_COMPANY_BY_ID";

interface MyTaxAdvisorCompanyEditContainerProps extends WithTranslation {
  fieldName: string;
}

const MyTaxAdvisorCompanyEditContainer = ({
  t,
  fieldName
}: MyTaxAdvisorCompanyEditContainerProps) => {
  const { pathname } = useLocation();
  const { clientId } = useParams<{ clientId: string }>();
  const companyType =
    pathname.indexOf("/tax-advisor") > -1
      ? CompanyType.TaxService
      : CompanyType.ThirdPartyTaxService;

  const getTaxAdvisorCompanyRequest = GetMyTaxAdvisorCompanyWithClientsQuery(companyType);

  const [updateCompanyById, updateCompanyByIdRequests] = useMutation<
    UpdateCompanyById,
    UpdateCompanyByIdVariables
  >(UPDATE_COMPANY_BY_ID);

  const company = getTaxAdvisorCompanyRequest.data?.getMyTaxAdvisorCompanyWithClients ?? null;

  const handleSave = async (value: string): Promise<void> => {
    await updateCompanyById({
      variables: {
        companyId: company?.id ?? "",
        [fieldName]: value
      }
    });
  };

  const getSubtitle = () => (
    <Trans
      i18nKey={`client:company.modal-subtitle.${fieldName}`}
      values={{ company: company }}
      components={{ bold: <strong /> }}
    />
  );

  const getSubmitPath = () =>
    companyType === CompanyType.TaxService
      ? `/tax-advisor/client-entities/${clientId}`
      : `/third-party-tax-advisor/clients/${clientId}`;

  return (
    <RequestFeedbackHandler requests={[getTaxAdvisorCompanyRequest]} noLoadingAnimation>
      {company && (
        <ClientTextFieldEdit
          requests={[updateCompanyByIdRequests]}
          onSave={handleSave}
          value={company[fieldName] ?? ""}
          label={t(`client:company.field.${fieldName}`)}
          submitPath={getSubmitPath()}
          subtitle={getSubtitle()}
        />
      )}
    </RequestFeedbackHandler>
  );
};

export default withTranslationReady("client")(MyTaxAdvisorCompanyEditContainer);
