import { storeToken } from "common/Authentication/storeToken";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RequestFeedbackInfo } from "components/generic/RequestFeedbackHandler";
import React, { useState } from "react";
import { MutationResult, QueryResult } from "react-apollo";
import { WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthSuccessMessage } from "../shared/AuthSuccessMessage/AuthSuccessMessage";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";

export enum ResetPasswordPhase {
  form,
  success
}

interface ResetPasswordProps extends WithTranslation {
  onSubmit: (password: string) => Promise<string>;
  phase: ResetPasswordPhase;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
}

const ResetPassword = ({ onSubmit, phase, t, ...props }: ResetPasswordProps) => {
  const history = useHistory();
  const [currentPhase, setCurrentPhase] = useState(phase);
  const [token, setToken] = useState("");

  const handleSubmit = async (password: string) => {
    const receivedToken = await onSubmit(password);
    setCurrentPhase(ResetPasswordPhase.success);

    setToken(receivedToken);
  };

  const handleLogin = async () => {
    storeToken(token);
    history.replace("/");
  };

  return (
    <div className="reset-password">
      {currentPhase === ResetPasswordPhase.form && (
        <ResetPasswordForm {...props} onSubmit={handleSubmit} />
      )}
      {currentPhase === ResetPasswordPhase.success && (
        <AuthSuccessMessage
          message={t("auth:reset-password.successMessage")}
          data-testid="reset-password-success"
          submitText={t("auth:reset-password.signIn")}
          onSubmit={handleLogin}
        />
      )}
    </div>
  );
};

export default withTranslationReady(["auth"])(ResetPassword);
