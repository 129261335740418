import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { Trans, WithTranslation } from "react-i18next";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { GmbHDetails } from "../../../../lib/models/client/GmbHDetails";
import PreliminaryTask from "./PreliminaryTask/PreliminaryTask";
import { checkForPreliminaryTask } from "../../../../common/checkForPreliminaryTask";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import { GetEndOfYearTasksByCompanyQuery } from "lib/api/administration/queries/GetEndOfYearTasksByCompany";
import { ClientTasks } from "../ClientTasks/ClientTasks";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

const useTasks = (companyId: string) => {
  const request = GetEndOfYearTasksByCompanyQuery(companyId);

  const tasks = request.data?.getEndOfYearTasksByCompany || [];

  const openTasks = tasks.filter((task) => !task.completedAt);

  return { openTasks, request };
};

export interface HomeTabProps {
  name: string;
  gmbh: GmbHDetails;
  bankAccountTaskCompleted?: boolean;
}

const HomeTab = ({ t, name, gmbh, bankAccountTaskCompleted }: HomeTabProps & WithTranslation) => {
  const preliminaryTask = checkForPreliminaryTask(gmbh, bankAccountTaskCompleted);
  const { openTasks, request } = useTasks(gmbh.id);

  const hasTasks = preliminaryTask.showTask || openTasks.length >= 1;

  return (
    <div data-testid={"home-component"} className={"home-tab"}>
      <Typography
        category={"Headline"}
        size={100}
        weight={"Heavy"}
        data-testid={"welcome-user"}
        className={"home-tab__welcome"}>
        <Trans i18nKey={"generic:welcome-user"} values={{ name }} />
      </Typography>
      {!hasTasks && (
        <Typography
          category={"Paragraph"}
          size={100}
          weight={"Light"}
          data-testid={"no-tasks"}
          className={"home-tab__welcome-subtitle"}>
          <Trans i18nKey={"generic:no-tasks"} />
        </Typography>
      )}
      {hasTasks && (
        <div className={"home-tab__tasks-header"}>
          <Typography
            category={"Paragraph"}
            size={100}
            weight={"Light"}
            data-testid={"todo-tasks-message"}
            className={"home-tab__welcome-subtitle"}>
            <Trans i18nKey={"generic:complete-following-tasks"} />
          </Typography>
          <Typography category={"Headline"} size={200} weight={"Heavy"} data-testid={"your-todos"}>
            {t("generic:your-todos")}
          </Typography>
          <PreliminaryTask task={preliminaryTask} companyId={gmbh.id} />
        </div>
      )}
      <FeatureFlag name={FeatureFlags.EndOfYearTasks}>
        <RequestFeedbackHandler requests={[request]}>
          <ClientTasks tasks={openTasks} />
        </RequestFeedbackHandler>
      </FeatureFlag>
    </div>
  );
};

export default withTranslationReady(["generic"])(HomeTab);
