import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import RideCardTextPair from "../../../sharedComponents/RideCardTextPair/RideCardTextPair";
import { RideCardBodySeparator } from "../../../sharedComponents/RideCardBodySeparator/RideCardBodySeparator";
import { GetAdvisedPerson_getAdvisedPerson } from "../../../taxAdvisor/graphql/GetAdvisedPerson";

export interface PersonDetailsSectionProps extends WithTranslation {
  person: GetAdvisedPerson_getAdvisedPerson;
  dataTestId?: string;
}

const PersonDetailsSection = ({ t, person, dataTestId }: PersonDetailsSectionProps) => {
  const {
    phoneNumber,
    salutation,
    title,
    firstName,
    lastName,
    birthName,
    address,
    placeOfBirth,
    dateOfBirth,
    citizenship,
    personalTaxId,
    taxNumber,
    taxOffice,
    documentType,
    documentNumber,
    issuingAuthority
  } = person;

  const streetLine2 = address?.streetLine2;
  const street = address?.street;
  const postalCode = address?.postalCode;
  const city = address?.city;
  const country = address?.country;

  return (
    <>
      <RideSectionCard
        title={t(`generic:details`)}
        data-testid={dataTestId}
        className="shadow-none my-5">
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.phone-number")}
          secondText={phoneNumber}
          dataTestId="phone-number"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.salutation")}
          secondText={salutation}
          dataTestId="salutation"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.title")}
          secondText={title}
          dataTestId="title"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.first-name")}
          secondText={firstName}
          dataTestId="first-name"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.last-name")}
          secondText={lastName}
          dataTestId="last-name"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.birthName")}
          secondText={birthName}
          dataTestId="birth-name"
        />
        <RideCardBodySeparator />
        <RideCardTextPair firstText={t("generic:street")} secondText={street} dataTestId="street" />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("generic:street-line-2")}
          secondText={streetLine2}
          dataTestId="streetLine2"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("generic:postal-code")}
          secondText={postalCode}
          dataTestId="postal-code"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.city")}
          secondText={city}
          dataTestId="city"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("generic:country")}
          secondText={country}
          dataTestId="country"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.place-of-birth")}
          secondText={placeOfBirth}
          dataTestId="place-of-birth"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("generic:birth-date")}
          secondText={dateOfBirth}
          dataTestId="birth-date"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.citizenship")}
          secondText={citizenship}
          dataTestId="citizenship"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.personal-tax-id")}
          secondText={personalTaxId}
          dataTestId="personal-tax-id"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.tax-number")}
          secondText={taxNumber}
          dataTestId="tax-number"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.tax-office")}
          secondText={taxOffice}
          dataTestId="tax-office"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.document-type")}
          secondText={documentType}
          dataTestId="document-type"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.document-number")}
          secondText={documentNumber}
          dataTestId="document-number"
        />
        <RideCardBodySeparator />
        <RideCardTextPair
          firstText={t("nominee-dashboard:person.issuing-authority")}
          secondText={issuingAuthority}
          dataTestId="issuing-authority"
        />
      </RideSectionCard>
    </>
  );
};

export default withTranslationReady(["generic"])(PersonDetailsSection);
