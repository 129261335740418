import { Container } from "react-bootstrap";
import { IoIosCheckmarkCircle } from "react-icons/io";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Jumbotron from "react-bootstrap/Jumbotron";
import GuestHeaderDeprecated from "../../../guest/components/GuestHeaderDeprecated/GuestHeaderDeprecated";

interface WepaServiceStatusPageProps extends WithTranslation {}

export const WepaServiceStatusPageComponent = ({ t }: WepaServiceStatusPageProps) => {
  return (
    <div className="trader-gmbh">
      <GuestHeaderDeprecated />
      <Jumbotron fluid className="admin-service-post-order-banner py-5 py-md-5">
        <Container className="text-light d-flex justify-content-center">
          <h3 data-testid="post-order-banner">{t("wepa-service:post-order-banner-heading")}</h3>
        </Container>
      </Jumbotron>
      <Container className="trader-gmbh">
        <div className="d-flex justify-content-center">
          <IoIosCheckmarkCircle size="100" color="#82d6e8" />
        </div>

        <div className="order-status-description mt-3">
          <span data-testid="wepa-service-confirm-message">
            {t("wepa-service:post-order-status-description")}
          </span>
        </div>
      </Container>
    </div>
  );
};

export const WepaServiceStatusPage = withTranslation(["wepa-service", "company-creation"])(
  WepaServiceStatusPageComponent
);
