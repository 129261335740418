import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import RideInput from "../../../sharedComponents/FormControls/RideInput/RideInput";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { TFunction } from "i18next";
import * as Yup from "yup";
import { emailValidationWithTranslation } from "../../../lib/validation/EmailSchema";

const InviteClientsViaEmailSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: emailValidationWithTranslation(t)
  });
};

interface ThirdPartyTaxAdvisorInviteViaEmailProps {
  t: TFunction;
  onSubmit: (values: any) => void;
}

export const ThirdPartyTaxAdvisorInviteViaEmail = ({
  t,
  onSubmit
}: ThirdPartyTaxAdvisorInviteViaEmailProps) => {
  const submitHandler = (values: any) => {
    onSubmit({
      email: values.email
    });
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: submitHandler,
    validationSchema: InviteClientsViaEmailSchema(t),
    validateOnChange: true
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Form data-testid="invite-client-form">
          <p className="invite-client__question-text">{t("invite-clients:email.text")}</p>
          <div className="invite-client__variants mt-5">
            <RideInput
              formik={formik}
              name="email"
              type="text"
              label={t("invite-clients:email.email-address")}
              className="invite-client__client-email"
            />
          </div>
          <div className="d-flex justify-content-md-end mt-55 justify-content-center">
            <RideButtonDeprecated
              data-testid="invite-client-submit-button"
              type="submit"
              variant={ButtonVariant.Primary}
              size={ButtonSize.Big}>
              {t("invite-clients:email.button")}
            </RideButtonDeprecated>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};
