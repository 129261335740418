import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  getTaxServiceDetails,
  getTaxServiceDetailsVariables
} from "./graphql/getTaxServiceDetails";

const GET_TAX_SERVICE_DETAILS = gql`
  query getTaxServiceDetails($advisorId: String!) {
    getTaxServiceDetails(advisorId: $advisorId) {
      partnerTaxAdvisorId
      thirdPartyTaxAdvisorId
    }
  }
`;

export const GetTaxServiceDetails = (advisorId: string) =>
  useQuery<getTaxServiceDetails, getTaxServiceDetailsVariables>(GET_TAX_SERVICE_DETAILS, {
    variables: { advisorId },
    fetchPolicy: "no-cache"
  });
