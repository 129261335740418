import * as Yup from "yup";

export const leiRegex = /^[A-Z0-9]{18}[0-9]{2}$/;

export const leiNumberValidation = (value: string | undefined): boolean => {
  if (value === null || value === undefined) return false;
  const sanitizedValue = value.toString().toUpperCase().replace(/\s/g, "");
  return leiRegex.test(sanitizedValue);
};

export const leiNumberValidationSchema = (t) =>
  Yup.string()
    .required(t("generic:validation-required"))
    .test(
      "matches-fx-flat-requirements",
      t("generic:invalid-pattern-of-lei-number"),
      leiNumberValidation
    );
