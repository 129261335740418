import React from "react";
import ShareholderDetailsSectionTitle from "../../../sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import { RideCheckbox, RideCheckboxVariant } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { RideDatePicker } from "uiLibrary/v2/components/RideDatePicker/RideDatePicker";

interface CitizenshipInformationProps extends WithTranslation {
  title: string;
  formik: FormikProps<any>;
  prefix: string;
  entityKey?: "shareholder" | "employee";
}

const CitizenshipInformation = ({
  t,
  formik,
  prefix,
  title,
  entityKey = "shareholder"
}: CitizenshipInformationProps) => {
  const isCompleted = !!(
    formik.values["gender"] &&
    formik.values["birthDate"] &&
    formik.values["birthCity"] &&
    formik.values["citizenship"] &&
    formik.values["notUSCitizenOrTaxResident"]
  );

  return (
    <div
      data-testid="shareholder-details-citizenship-information"
      className="shareholder-details-citizenship-information">
      <ShareholderDetailsSectionTitle text={title} prefix={prefix} isCompleted={isCompleted} />
      <RideRadioSelect
        label={t("shareholder-details:citizenship-information.gender")}
        options={[
          { value: "male", label: t("shareholder-details:citizenship-information.male") },
          { value: "female", label: t("shareholder-details:citizenship-information.female") }
        ]}
        name="gender"
        variant={RadioSelectVariant.Light}
        className="shareholder-details-citizenship-information__field"
      />
      <RideDatePicker
        name="birthDate"
        label={t("shareholder-details:citizenship-information.birthday")}
      />
      <CountrySelect
        className="shareholder-details-citizenship-information__field"
        name="citizenship"
        label={t("shareholder-details:citizenship-information.nationality")}
        isOnModal
      />
      <RideTextInput
        className="shareholder-details-citizenship-information__field"
        name="birthCity"
        label={t("shareholder-details:citizenship-information.birthplace")}
        placeholder="Berlin"
      />
      <RideCheckbox
        className="shareholder-details-citizenship-information__field"
        name="notUSCitizenOrTaxResident"
        label={t(`shareholder-details:citizenship-information.not-us-citizen--${entityKey}`)}
        variant={RideCheckboxVariant.Light}
      />
    </div>
  );
};

export default withTranslation(["shareholder-details"])(CitizenshipInformation);
