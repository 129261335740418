import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { RideCheckbox } from "../../../sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import { useHistory } from "react-router-dom";

interface OrderAdminServiceGSUpsellProps extends WithTranslation {
  isShadowUser: boolean;
  onSubmit: () => void;
  shadowUserSuccessPageUrl: string;
}

const OrderAdminServiceGSUpsell = ({
  t,
  isShadowUser,
  onSubmit,
  shadowUserSuccessPageUrl
}: OrderAdminServiceGSUpsellProps) => {
  const [checkboxIsSelected, setCheckboxIsSelected] = React.useState(false);
  const history = useHistory();

  return (
    <div className="order-admin-service-gs-upsell">
      <div className="order-admin-service-gs-upsell--title" data-testid="title">
        {t("admin-service:upsell-title")}
      </div>
      <div className="order-admin-service-gs-upsell--card">
        <div className="order-admin-service-gs-upsell--card-title" data-testid="consent-title">
          {t("admin-service:assignment-title")}
        </div>
        <ul className="order-admin-service-gs-upsell--card-bullet-points">
          <li data-testid="consent-topic-1">{t("admin-service:assignment-1")}</li>
          <li data-testid="consent-topic-2">{t("admin-service:assignment-2")}</li>
          <li data-testid="consent-topic-3">{t("admin-service:assignment-3")}</li>
        </ul>
        <RideCheckbox
          name="terms-and-conditions-field"
          className="order-admin-service-gs-upsell--card-checkbox"
          data-testid="terms-and-conditions"
          label={
            <span data-testid="checkbox-label">
              {t("trader-gmbh:agree-assignment-1")}
              <a
                href={
                  process.env.REACT_APP_RIDE_AGB ||
                  "https://www.ride.capital/agb?utm_source=app&utm_medium=app"
                }
                target="_blank"
                rel="noopener noreferrer">
                {t("trader-gmbh:agree-assignment-2")}
              </a>
              <span>{t("trader-gmbh:agree-assignment-3")}</span>
            </span>
          }
          onChange={() => {
            setCheckboxIsSelected(!checkboxIsSelected);
          }}
        />
      </div>
      <RideButtonDeprecated
        className="order-admin-service-gs-upsell--button"
        variant={ButtonVariant.Primary}
        disabled={!checkboxIsSelected}
        onClick={() => {
          onSubmit();
          if (isShadowUser) {
            history.push(shadowUserSuccessPageUrl);
          } else {
            history.push(`/client/entities`);
          }
        }}
        size={ButtonSize.Big}
        data-testid="submit-order">
        {t("trader-gmbh:order-now")}
      </RideButtonDeprecated>
    </div>
  );
};

export default withTranslationReady(["admin-service", "trader-gmbh"])(OrderAdminServiceGSUpsell);
