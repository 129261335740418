import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { getLowTaxBrokerOrders } from "./graphql/getLowTaxBrokerOrders";
import { OrderCompanyStatusEnum } from "global-query-types";

export const GET_LOW_TAX_BROKER_ORDERS = gql`
  query getLowTaxBrokerOrders(
    $start: Int
    $limit: Int
    $status: OrderCompanyStatusEnum
    $query: String
    $isNewBrokerEnabled: Boolean
  ) {
    getLowTaxBrokerOrders(
      start: $start
      limit: $limit
      status: $status
      query: $query
      isNewBrokerEnabled: $isNewBrokerEnabled
    ) {
      items {
        order {
          id
          productType
          entityId
          extra
          status
          createdAt
          updatedAt
        }
        company {
          id
          name
          legalForm
        }
      }
      count
    }
  }
`;

export const GetLowTaxBrokerOrders = ({
  start,
  limit,
  status,
  query,
  isNewBrokerEnabled
}: {
  start?: number;
  limit?: number;
  status?: OrderCompanyStatusEnum;
  query?: string;
  isNewBrokerEnabled?: boolean;
}) =>
  useQuery<getLowTaxBrokerOrders>(GET_LOW_TAX_BROKER_ORDERS, {
    fetchPolicy: "no-cache",
    variables: { start, limit, status, query, isNewBrokerEnabled }
  });
