import BankAccountDeleteModal from "./BankAccountDeleteModal";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  DeleteBankAccount,
  DeleteBankAccountVariables
} from "lib/api/mutations/graphql/DeleteBankAccount";
import { DELETE_BANK_ACCOUNT } from "../../lib/api/mutations/DELETE_BANK_ACCOUNT";
import { MY_GMBHS_QUERY } from "../../components/client/MyGmbHs/Queries";

export interface BankAccountDeleteContainerProps {
  id: string;
  name: string | null;
  onRefresh: () => Promise<void>;
  children: (setVisibility: (state: boolean) => void) => React.ReactNode;
}

export const BankAccountDeleteModalContainer = ({
  id,
  name,
  onRefresh,
  children
}: BankAccountDeleteContainerProps) => {
  const [deleteBankAccount] = useMutation<DeleteBankAccount, DeleteBankAccountVariables>(
    DELETE_BANK_ACCOUNT
  );

  const handleDelete = async () => {
    await deleteBankAccount({
      variables: {
        id
      },
      refetchQueries: [{ query: MY_GMBHS_QUERY }]
    });

    await onRefresh();
  };
  return <BankAccountDeleteModal name={name} onDelete={handleDelete} children={children} />;
};
