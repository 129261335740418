import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { companyDetailsByCompanyId_companyById } from "../../../../graphql/companyDetailsByCompanyId";
import { CompanyType } from "../../../../../../global-query-types";
import { formatCapitalContribution } from "../../../../../../common/formatters/formatCapitalContribution";

export interface IProps {
  t: TFunction;
  companyData: companyDetailsByCompanyId_companyById;
}

export const CompanyTypeCapital = ({ t, companyData }: IProps) => {
  const userCompanyTypesTranslation = {
    [CompanyType.Operating]: t("user-company:operating-company"),
    [CompanyType.PersonalHolding]: t("user-company:personal-holding-company"),
    [CompanyType.ClientEntity]: t("user-company:personal-holding-company"),
    [CompanyType.Spv]: t("user-company:special-purpose-vehicle")
  };

  return (
    <div className="mt-5">
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <b>{t("user-company:company-types")}</b>
            </Col>
          </Row>
          <Row>
            <Col>{userCompanyTypesTranslation[companyData.type!]}</Col>
          </Row>
        </Col>
        <Col xs={6} md={3}>
          <Row>
            <Col>
              <b>{t("generic:capital-contribution")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="capital-contribution">
              {formatCapitalContribution(companyData.capitalContribution)}
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={3}>
          <Row>
            <Col>
              <b>{t("generic:trade-tax-free")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="trade-tax">
              {companyData.tradeTaxFree === true ? t("generic:yes") : t("generic:no")}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
