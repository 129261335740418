import { QueryResult, useMutation } from "react-apollo";
import { MeQuery } from "../../../../lib/api/queries/graphql/MeQuery";
import { GetMe } from "../../../../lib/api/queries/MeQuery";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import AdminServiceUpsellOffering from "./AdminServiceUpsellOffering";
import React from "react";
import { useParams } from "react-router";
import { FILL_OUT_ADMIN_SERVICE_ORDER } from "../../../../lib/api/mutations/FILL_OUT_ADMIN_SERVICE_ORDER";
import logger from "../../../../common/Logger";
import {
  declineAdminServiceOffer,
  declineAdminServiceOfferVariables
} from "../../../../lib/api/mutations/graphql/declineAdminServiceOffer";
import { DECLINE_ADMIN_SERVICE_OFFER } from "../../../../lib/api/mutations/DECLINE_ADMIN_SERVICE_OFFER";
import { getFlowTypePath } from "components/public/CompanyCreationFlowType/currentFlowType";
import { FeatureFlags, FlowTypeEnum } from "global-query-types";
import { FeatureFlagService } from "../../../../lib/services/FeatureFlagService/FeatureFlagService";

export const AdminServiceUpsellOfferingContainer = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { companyCreationId } = useParams<{ companyCreationId: string }>();

  const [fillOutAdminServiceOrder, fillOutAdminServiceOrderRequest] = useMutation(
    FILL_OUT_ADMIN_SERVICE_ORDER
  );
  const [declineAdminServiceOfferMutation] = useMutation<
    declineAdminServiceOffer,
    declineAdminServiceOfferVariables
  >(DECLINE_ADMIN_SERVICE_OFFER);
  const fillOutASOrder = async (): Promise<string> => {
    const { data } = await fillOutAdminServiceOrder({
      variables: {
        companyCreationId: companyCreationId,
        orderId: orderId
      }
    });
    const adminServiceOrderId = data?.fillOutAdminServiceOrder;
    return adminServiceOrderId;
  };
  const declineASOffer = async () => {
    try {
      logger.debug("call mutation (declineASOffer) to save AdminServiceOfferDeclined event ");
      await declineAdminServiceOfferMutation({
        variables: { companyCreationId, orderId }
      });
    } catch (error) {
      logger.error(error);
    }
  };
  const shadowUserRequest: QueryResult<MeQuery> = GetMe();
  const isShadowUser = shadowUserRequest.data?.me?.isShadow;
  const companyFoundationStatusPageUrl = `/${getFlowTypePath(
    FlowTypeEnum.GMBH
  )}/${companyCreationId}/status`;
  const myGmbhsUrl = `/client/entities`;
  const showHomepage = FeatureFlagService.instance.isEnabled(
    FeatureFlags.ReplaceCompanyListByCompanyDropdown
  );

  return (
    <RequestFeedbackHandler requests={[shadowUserRequest, fillOutAdminServiceOrderRequest]}>
      <AdminServiceUpsellOffering
        declineOfferRedirectUrlForShadowUser={
          companyCreationId ? companyFoundationStatusPageUrl : `/order/gmbh/complete`
        }
        declineOfferRedirectUrlForNonShadowUser={showHomepage ? "/" : myGmbhsUrl}
        isShadowUser={isShadowUser ?? true}
        fillOutASOrder={fillOutASOrder}
        declineASOffer={declineASOffer}
        isNewFlow={!!orderId}
      />
    </RequestFeedbackHandler>
  );
};
