import React from "react";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation, getI18n } from "react-i18next";

export interface ReviewBusinessPurposeProps {
  assetTypes: { [key: string]: boolean };
  noAdditionalOperatingActivities: boolean;
  additionalOperatingActivitiesDescription: string;
  editLink: string;
  className?: string;
}

const transformKey = (string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

export const formatAssetTypes = (assetTypes: any): string => {
  let parsedAssetTypes = "";
  if (assetTypes) {
    for (const assetTypeKey of Object.keys(assetTypes)) {
      const hasAssetType = assetTypes[assetTypeKey];
      if (hasAssetType) {
        if (parsedAssetTypes !== "") {
          parsedAssetTypes += ", ";
        }
        parsedAssetTypes += getI18n().t(
          `generic:company-setup:select-asset-types.${transformKey(assetTypeKey)}`
        );
      }
    }
    return parsedAssetTypes;
  } else {
    return "";
  }
};

export const ReviewBusinessPurpose = withTranslationReady(["generic", "review-entry"])(
  ({
    assetTypes,
    noAdditionalOperatingActivities,
    additionalOperatingActivitiesDescription,
    editLink,
    t,
    className = ""
  }: ReviewBusinessPurposeProps & WithTranslation) => {
    return (
      <CardContainer className={className}>
        <ReviewEntry
          name="business-purpose"
          fields={{
            assetTypes: [formatAssetTypes(assetTypes)],
            noAdditionalOperatingActivities: [
              noAdditionalOperatingActivities ? t("generic:no") : t("generic:yes")
            ],
            additionalOperatingActivitiesDescription: [additionalOperatingActivitiesDescription]
          }}
          editLink={editLink}
        />
      </CardContainer>
    );
  }
);
