import React from "react";
import {
  RideBadgeColorsEnum,
  RideBadgeIconEnum,
  RideBadgeSizeVariant,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus/RideBadgeStatus";

interface ServiceStatusBadgeProps {
  dataTestId: string;
  iconVariant: RideBadgeIconEnum;
  label: string;
  color?: RideBadgeColorsEnum;
  size?: RideBadgeSizeVariant;
}

export const GmbHServiceStatusBadge = ({
  dataTestId,
  iconVariant,
  label,
  color,
  size
}: ServiceStatusBadgeProps) => {
  return (
    <span className="gmbh-service-badge">
      <RideBadgeStatus
        dataTestId={dataTestId}
        color={color ?? RideBadgeColorsEnum.GMBH_BADGE_STATUS}
        variant={RideBadgeVariantsEnum.SOLID}
        sizeVariant={size ?? RideBadgeSizeVariant.SMALL}
        iconVariant={iconVariant}
        label={label}
      />
    </span>
  );
};

interface AddServiceBadgeProps {
  dataTestId: string;
  label: string;
  onClick: () => void;
}

export const GmbHAddServiceBadge = ({ dataTestId, label, onClick }: AddServiceBadgeProps) => {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <span className="gmbh-service-badge gmbh-add-service-badge" onClick={handleClick}>
      <RideBadgeStatus
        dataTestId={dataTestId}
        color={RideBadgeColorsEnum.DARK}
        variant={RideBadgeVariantsEnum.DASHED}
        sizeVariant={RideBadgeSizeVariant.SMALL}
        hasAddIconVariant={true}
        label={label}
      />
    </span>
  );
};
