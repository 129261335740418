import React from "react";
import { SecurityQuestions } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  QuestionNumber,
  SecurityQuestionType
} from "lib/models/client/LowTaxBroker/SecurityQuestionType";
import RideModal, {
  RideModalBody,
  RideModalControls
} from "uiLibrary/designSystem/RideModal/RideModal";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

const moduleName = "view-security-questions-modal";

export interface ViewSecurityQuestionsModalProps {
  securityQuestions: SecurityQuestions;
  onClose: () => void;
}

const ViewSecurityQuestionsModal = ({
  t,
  securityQuestions,
  onClose
}: ViewSecurityQuestionsModalProps & WithTranslation) => {
  return (
    <RideModal title={t("generic:ltb-security-questions.title")} onClose={onClose}>
      <RideModalBody>
        <div data-testid="view-security-questions-modal" className={moduleName}>
          <SecurityQuestionEntry
            securityQuestion={securityQuestions.first}
            questionNumber={QuestionNumber.First}
          />
          <SecurityQuestionEntry
            securityQuestion={securityQuestions.second}
            questionNumber={QuestionNumber.Second}
          />
          <SecurityQuestionEntry
            securityQuestion={securityQuestions.third}
            questionNumber={QuestionNumber.Third}
          />
        </div>
      </RideModalBody>
      <RideModalControls>
        <RideButtonDeprecated
          width={ButtonWidth.FULL}
          radius={ButtonRadius.Soft}
          data-testid={"close-button"}
          onClick={onClose}
          type={"button"}>
          {t("generic:close")}
        </RideButtonDeprecated>
      </RideModalControls>
    </RideModal>
  );
};

const SecurityQuestionEntry = withTranslationReady(["generic"])(
  ({
    t,
    securityQuestion,
    questionNumber
  }: {
    securityQuestion: SecurityQuestionType;
    questionNumber: QuestionNumber;
  } & WithTranslation) => {
    return (
      <div data-testid={`${questionNumber}-security-question`} className={`${moduleName}__entry`}>
        <span
          data-testid={`${questionNumber}-security-question-title`}
          className={`${moduleName}__entry-label`}>
          {t(`generic:ride-broker-onboarding.security-questions.${questionNumber}`)}
        </span>
        <span
          data-testid={`${questionNumber}-security-question-option`}
          className={`${moduleName}__question`}>
          {t(
            `generic:ride-broker-onboarding.security-questions.options.${securityQuestion.question}`
          )}
        </span>
        <span
          data-testid={`${questionNumber}-security-question-answer`}
          className={`${moduleName}__answer`}>
          {securityQuestion.answer}
        </span>
      </div>
    );
  }
);

export default withTranslationReady(["generic"])(ViewSecurityQuestionsModal);
