import React from "react";
import ChangeEmailScene from "./scenes/profile/ChangeEmailScene";
import ClientCompanyDetailPage from "components/Investments/UserCompany/CompanyDetailPage/ClientCompanyDetailPage";
import { ClientRoute } from "./ClientRoute";
import CompanyBusinessPurposeScene from "./scenes/company/CompanyBusinessPurposeScene";
import CompanyCommercialRegisterDateScene from "./scenes/company/CompanyCommercialRegisterDateScene";
import CompanyLeiScene from "./scenes/company/CompanyLeiScene";
import CompanyNotarizationDateScene from "./scenes/company/CompanyNotarizationDateScene";
import CompanyRegistrationScene from "./scenes/company/CompanyRegistrationScene";
import CompanyRegistryCourtScene from "./scenes/company/CompanyRegistryCourtScene";
import CompanyTaxNumberScene from "./scenes/company/CompanyTaxNumberScene";
import CompanyVatNumberScene from "./scenes/company/CompanyVatNumberScene";
import EditBrokerageAccount from "./scenes/BrokerageAccounts/EditBrokerageAccount";
import InvestmentDetailPage from "components/Investments/InvestmentDetailPage";
import Knowledge from "../components/client/knowledge/Knowledge";
import MyInvestmentsScene from "./scenes/investments/MyInvestmentsScene";
import MyProfileScene from "./scenes/profile/MyProfileScene";
import NewBrokerageAccount from "./scenes/BrokerageAccounts/NewBrokerageAccount";
import { ClubDealListScene } from "./scenes/investments/ClubDealListScene";
import { ClubDealScene } from "./scenes/investments/ClubDealScene";
import InvestmentScene from "./scenes/investments/InvestmentScene";
import { MyGmbHsContainer } from "components/client/MyGmbHs/MyGmbHsContainer";
import { Route, Switch } from "react-router";
import { UserRole } from "global-query-types";
import CompanyFiscalYearsScene from "./scenes/company/CompanyFiscalYears/CompanyFiscalYearsScene";
import ChangePasswordScene from "./scenes/profile/ChangePasswordScene";
import { GmbHAddScene } from "./scenes/GmbH/GmbHAddScene/GmbHAddScene";
import { GmbHAddNewScene } from "./scenes/GmbH/GmbHAddNewScene/GmbHAddNewScene";
import { GmbHAddExistingScene } from "./scenes/GmbH/GmbHAddExistingScene/GmbHAddExistingScene";
import EntityScene from "./scenes/EntityScene/EntityScene";
import BankAccountListScene from "./scenes/banking/BankAccountListScene";
import AddBankAccountScene from "./scenes/banking/AddBankAccountScene";
import AddExistingBankAccountScene from "./scenes/banking/AddExistingBankAccountScene";
import SelectBankAccountGmbHScene from "./scenes/banking/SelectBankAccountGmbHScene";
import AddNewBankAccountScene from "./scenes/banking/AddNewBankAccountScene";
import { BankAccountEditScene } from "./scenes/banking/BankAccountEditScene";
import ClientSignUpScene from "./scenes/ClientSignUp/ClientSignUpScene";
import BankAccountDetailsScene from "./scenes/banking/BankAccountDetailsScene";
import BankAccountWebFormScene from "./scenes/banking/BankAccountWebFormScene";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";
import PendingTasksScene from "./scenes/PendingTasks/PendingTasksScene";
import ChaptersOrderLayoutContainer from "./components/ChaptersOrderLayout/ChaptersOrderLayoutContainer";
import { useViewport } from "components/generic/Trader-GmbH/useViewport";
import { MobileViewErrorScene } from "./scenes/MobileViewError/MobileViewErrorScene";
import OrderApplicationSubmittedScene from "./scenes/OrderApplicationSubmitted/OrderApplicationSubmittedScene";
import ClientHomePageContainer from "./components/ClientHomePage/ClientHomePageContainer";
import { MigrateAdminServicePackageScene } from "client/scenes/MigrateAdminServicePackage/MigrateAdminServicePackageScene";
import { AdminServicePackageUpdateSuccessContainer } from "./components/MigrateAdminServicePackage/AdminServicePackageUpdateSuccess/AdminServicePackageUpdateSuccessContainer";
import EntireFiscalYearConfirmationScene from "./scenes/EntireFiscalYearConfirmation/EntireFiscalYearConfirmationScene";
import RideBrokerInternalServerError from "./components/RideBrokerInternalServerError/RideBrokerInternalServerError";
import CompanyAccountingFrameworkScene from "../taxAdvisor/scenes/CompanyAccountingFramework/CompanyAccountingFrameworkScene";
import { PtaMigrationFlowToRideSteuerberatungScene } from "./scenes/SteuragenterCustomerMigration/SteuragenterCustomerMigrationScene";
import { AdminServicePTAMigrationToRideSteuerberatungHelper } from "../lib/helpers/AdminServicePTAMigrationToRideSteuerberatungHelper";

interface ClientAppRoutesProps {
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy;
}

export function ClientAppRoutes({ passwordPolicy }: ClientAppRoutesProps) {
  const path = `/client`;
  const { width } = useViewport();
  const isMobile = width >= 1 && width <= 768;
  return (
    <Switch>
      <ClientRoute exact path={"/"} component={() => <></>} />
      <ClientRoute exact path={`${path}/home`} component={ClientHomePageContainer} />
      <ClientRoute exact path={`${path}/entities`} component={MyGmbHsContainer} />
      <ClientRoute exact path={`${path}/my-gmbhs/add`} component={GmbHAddScene} />
      <ClientRoute exact path={`${path}/my-gmbhs/add/new`} component={GmbHAddNewScene} />
      <ClientRoute exact path={`${path}/my-gmbhs/add/existing`} component={GmbHAddExistingScene} />

      <ClientRoute exact path={`${path}/my-profile`} component={MyProfileScene} />
      <ClientRoute exact path={`${path}/my-profile/change-email`} component={ChangeEmailScene} />
      <ClientRoute
        exact
        path={`${path}/my-profile/change-password`}
        component={ChangePasswordScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/accounting-framework`}
        component={CompanyAccountingFrameworkScene}
      />
      <ClientRoute exact path={`${path}/pending-tasks`} component={PendingTasksScene} />

      <ClientRoute exact path={`${path}/entities/:id/:mode`} component={EntityScene} />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/notarization-date`}
        component={CompanyNotarizationDateScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/commercial-register-date`}
        component={CompanyCommercialRegisterDateScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/tax-number`}
        component={CompanyTaxNumberScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/vat-number`}
        component={CompanyVatNumberScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/registry-court`}
        component={CompanyRegistryCourtScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/registration`}
        component={CompanyRegistrationScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/business-purpose`}
        component={CompanyBusinessPurposeScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/fiscal-year`}
        component={CompanyFiscalYearsScene}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:id/general-info/lei`}
        component={CompanyLeiScene}
      />
      <ClientRoute exact path={`${path}/investments`} component={MyInvestmentsScene} />
      <ClientRoute exact path={`${path}/investments/:id`} component={InvestmentScene} />
      <ClientRoute exact path={`${path}/club-deals`} component={ClubDealListScene} />
      <ClientRoute exact path={`${path}/club-deals/:id`} component={ClubDealScene} />
      <ClientRoute
        exact
        path={`${path}/entities/:companyId/securities-booking/new`}
        component={NewBrokerageAccount}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:companyId/securities-booking/edit/:id`}
        component={EditBrokerageAccount}
      />
      <ClientRoute
        exact
        path={`${path}/entities/:companyId/admin-service/migrate`}
        component={MigrateAdminServicePackageScene}
        overrideLayout
      />
      <ClientRoute
        exact
        path={`${path}/entities/:companyId/admin-service/migrate/success`}
        component={AdminServicePackageUpdateSuccessContainer}
        overrideLayout
      />
      <ClientRoute
        exact
        path={`${path}/entities/:companyId/${AdminServicePTAMigrationToRideSteuerberatungHelper.adminServiceFlowUrlSuffix}`}
        component={PtaMigrationFlowToRideSteuerberatungScene}
        overrideLayout
      />
      <ClientRoute
        exact
        path={`${path}/entities/:companyId/fiscal-years/:year/confirmation`}
        component={EntireFiscalYearConfirmationScene}
      />
      <ClientRoute exact path={`${path}/knowledge`} component={Knowledge} />
      <ClientRoute
        path={"/investment/detail/:companyId"}
        component={(props) => (
          <InvestmentDetailPage {...props} backUrl="/investments" role={UserRole.Client} />
        )}
      />
      <ClientRoute
        path={"/investment-overview/detail/:companyId"}
        component={(props) => (
          <InvestmentDetailPage {...props} backUrl="/ride-investments" role={UserRole.Client} />
        )}
      />
      <ClientRoute
        path={"/company/detail/:id"}
        component={(props) => <ClientCompanyDetailPage {...props} backUrl="/investments" />}
      />
      <ClientRoute exact path={`${path}/banking`} component={BankAccountListScene} />
      <ClientRoute exact path={`${path}/banking/add`} component={AddBankAccountScene} />
      <ClientRoute exact path={`${path}/banking/edit/:id`} component={BankAccountEditScene} />
      <ClientRoute
        exact
        path={`${path}/banking/add/existing`}
        component={AddExistingBankAccountScene}
      />
      <ClientRoute
        exact
        path={`${path}/banking/add/select-gmbh`}
        component={SelectBankAccountGmbHScene}
      />
      <ClientRoute
        exact
        path={`${path}/banking/add/:companyId/new`}
        component={AddNewBankAccountScene}
      />
      <ClientRoute
        exact
        path={`${path}/banking/:companyId/:bankAccountId/:iban`}
        component={BankAccountDetailsScene}
      />
      <ClientRoute
        exact
        path={`${path}/banking/webform/:companyId/:bankAccountId/:iban`}
        component={BankAccountWebFormScene}
      />
      <Route
        exact
        path={`${path}/order/generic/:chapter/:stepIndex/:orderId/:submissionId`}
        component={isMobile ? MobileViewErrorScene : ChaptersOrderLayoutContainer}
      />
      <Route
        exact
        path={`${path}/order-submitted/:orderId`}
        component={OrderApplicationSubmittedScene}
      />
      <Route
        exact
        path={`${path}/ride-broker/internal-server-error/:companyId`}
        component={RideBrokerInternalServerError}
      />
      <Route
        exact
        path={`${path}/order/generic/:chapter/:subChapterIndex/:stepIndex/:orderId/:submissionId`}
        component={isMobile ? MobileViewErrorScene : ChaptersOrderLayoutContainer}
      />
      <Route exact path={`${path}/signup/:id`} component={() => <ClientSignUpScene />} />
    </Switch>
  );
}
