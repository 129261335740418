import React from "react";
import { Image } from "react-bootstrap";

export const RideLogo = () => (
  <Image
    src="/assets/RIDE-Logo-Green.svg"
    className="ride-logo"
    alt="Ride Logo"
    data-testid="ride-logo"
  />
);

export const RideLogoBlack = () => (
  <svg
    className="ride-logo-black"
    data-testid="ride-logo"
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="24"
    viewBox="0 0 96 24"
    fill="none">
    <g clipPath="url(#clip0_952_2792)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000488281 24V16.9572H5.51809V24H0.000488281ZM94.8005 5.46784H82.6811V9.78185H93.2879V14.7882H82.6811V18.8886H94.8005V24H76.7435V0.356445H94.8005V5.46784ZM63.6869 16.8384C64.9925 15.5508 65.6597 13.9872 65.6597 12.108C65.6597 10.2678 64.9925 8.73545 63.6869 7.52165C62.3807 6.26945 60.7241 5.64305 58.7831 5.64305H55.1171V18.717H58.7831C60.7241 18.717 62.3807 18.0906 63.6869 16.8384ZM68.0939 3.65945C70.5281 5.85305 71.7275 8.66945 71.7275 12.108C71.7275 15.621 70.5281 18.4722 68.0939 20.6976C65.6597 22.8876 62.5583 24 58.7831 24H49.1561V0.356445H58.7831C62.5583 0.356445 65.6597 1.46945 68.0939 3.65945ZM37.1177 24V0.356445H42.6353V24H37.1177ZM27.2591 16.0446L33.3509 24.0109H26.2817L21.1637 17.1847H17.5097V24.0109H11.6249V11.9202H21.8609C24.1937 11.9202 25.9001 10.6374 25.9001 8.73365C25.9001 6.89645 24.1937 5.61365 21.8609 5.61365H21.2093V0.450049H21.9659C24.9263 0.450049 27.3257 1.21385 29.1017 2.70185C30.8777 4.19465 31.7819 6.20285 31.7819 8.73365C31.7819 11.955 30.1457 14.622 27.2591 16.0446Z"
        fill="#191B1F"
      />
    </g>
    <defs>
      <clipPath id="clip0_952_2792">
        <rect width="96" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
