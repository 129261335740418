import { CompanyCreationStep } from "../../../global-query-types";

export const companyCreation = {
  STEP_ONE: CompanyCreationStep.SHAREHOLDERS,
  STEP_TWO: CompanyCreationStep.SHARES,
  STEP_THREE: CompanyCreationStep.DIRECTOR,
  STEP_FOUR: CompanyCreationStep.COMPANY,
  STEP_FIVE: CompanyCreationStep.SHAREHOLDERS_DETAILS,
  STEP_SIX: CompanyCreationStep.ADDRESS,
  STEP_SEVEN: CompanyCreationStep.ORDER
};

export const bookingGmbH = {
  STEP_ONE: CompanyCreationStep.SHAREHOLDERS,
  STEP_TWO: CompanyCreationStep.SHARES,
  STEP_THREE: CompanyCreationStep.DIRECTOR,
  STEP_FOUR: CompanyCreationStep.COMPANY,
  STEP_FIVE: CompanyCreationStep.SHAREHOLDERS_DETAILS,
  STEP_SIX: CompanyCreationStep.ADDRESS,
  STEP_SEVEN: CompanyCreationStep.ASSETS,
  STEP_EIGHT: CompanyCreationStep.OPERATIONAL_ACTIVITIES,
  STEP_NINE: CompanyCreationStep.ORDER
};

export const adminService = {
  STEP_ONE: CompanyCreationStep.COMPANY,
  STEP_TWO: CompanyCreationStep.MANAGING_DIRECTOR,
  STEP_THREE: CompanyCreationStep.SELECT_PACKAGE,
  STEP_FOUR: CompanyCreationStep.ORDER
};

export const adminServiceNoPackageSelection = {
  STEP_ONE: CompanyCreationStep.COMPANY,
  STEP_TWO: CompanyCreationStep.MANAGING_DIRECTOR,
  STEP_THREE: CompanyCreationStep.ORDER
};

export const adminServiceOnboarding = {
  STEP_ONE: CompanyCreationStep.COMPANY,
  STEP_TWO: CompanyCreationStep.SHAREHOLDERS,
  STEP_THREE: CompanyCreationStep.SHAREHOLDERS_DETAILS,
  STEP_FOUR: CompanyCreationStep.DIRECTOR,
  STEP_FIVE: CompanyCreationStep.EMPLOYEE_MANAGING_DIRECTOR
};

export const arrayOfBookingSteps = [
  bookingGmbH.STEP_ONE,
  bookingGmbH.STEP_TWO,
  bookingGmbH.STEP_THREE,
  bookingGmbH.STEP_FOUR,
  bookingGmbH.STEP_FIVE,
  bookingGmbH.STEP_SIX,
  bookingGmbH.STEP_SEVEN,
  bookingGmbH.STEP_EIGHT,
  bookingGmbH.STEP_NINE
];

export const arrayOfSteps = [
  companyCreation.STEP_ONE,
  companyCreation.STEP_TWO,
  companyCreation.STEP_THREE,
  companyCreation.STEP_FOUR,
  companyCreation.STEP_FIVE,
  companyCreation.STEP_SIX,
  companyCreation.STEP_SEVEN
];

export const arrayOfStepsInAdminService = [
  adminService.STEP_ONE,
  adminService.STEP_TWO,
  adminService.STEP_THREE,
  adminService.STEP_FOUR
];

export const arrayOfStepsInAdminServiceOnboarding = [
  adminServiceOnboarding.STEP_ONE,
  adminServiceOnboarding.STEP_TWO,
  adminServiceOnboarding.STEP_THREE,
  adminServiceOnboarding.STEP_FOUR,
  adminServiceOnboarding.STEP_FIVE
];

export const getCompletedSteps = (currentStep, selectService) => {
  const getCurrentActiveStepIndex = selectService.indexOf(currentStep);
  const completedSteps = selectService.slice(0, getCurrentActiveStepIndex + 1);
  return completedSteps;
};
