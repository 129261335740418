import React from "react";
import { Redirect } from "react-router";

interface BookingRedirectProps {
  companyCreationId: string | undefined;
  pageId: string | undefined;
  postOrderStatus: string | undefined | null;
}

export const BookingRedirect = ({
  pageId,
  companyCreationId,
  postOrderStatus
}: BookingRedirectProps) => {
  if (postOrderStatus && companyCreationId) {
    return <Redirect to={`/booking/gmbh/${companyCreationId}/status`} />;
  }

  if (companyCreationId && pageId === companyCreationId) {
    return null;
  }

  if (companyCreationId) {
    return <Redirect to={`/booking/gmbh/${companyCreationId}`} />;
  }

  if (pageId === "start") {
    return null;
  }

  return <Redirect to={`/booking/gmbh/start`} />;
};
