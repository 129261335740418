import logger from "../../../common/Logger";

interface BackOffOptions<T> {
  retryIf: (result: T) => boolean;
  logPrefix?: string;
  firstTimeoutInMs?: number;
  backOffMultiplier?: number;
  maxRetries?: number;
}

export class BackOffService<T> {
  private readonly retryIf: (result: T) => boolean;
  private readonly logPrefix: string;
  private readonly backOffMultiplier: number;
  private readonly firstTimeoutInMs: number;
  private readonly maxRetries: number;

  constructor({
    retryIf,
    logPrefix = "generic back-off",
    backOffMultiplier = 2,
    firstTimeoutInMs = 1000,
    maxRetries = 7
  }: BackOffOptions<T>) {
    this.retryIf = retryIf;
    this.logPrefix = logPrefix;
    this.backOffMultiplier = backOffMultiplier;
    this.firstTimeoutInMs = firstTimeoutInMs;
    this.maxRetries = maxRetries;
  }

  async execute(
    action: () => Promise<T>,
    nextTimeout = this.firstTimeoutInMs,
    retriesLeft = this.maxRetries
  ): Promise<T> {
    const result = await action();

    if (this.retryIf(result) && retriesLeft > 0) {
      logger.warn(
        `[${this.logPrefix}] Going to back-off retry the call again after timeout=${nextTimeout}`
      );

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.execute(action, nextTimeout * this.backOffMultiplier, retriesLeft - 1)
            .then(resolve)
            .catch(reject);
        }, nextTimeout);
      });
    }

    return result;
  }
}
