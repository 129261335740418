import { minAgeThresholdDate } from "./constants";
import { isBefore } from "date-fns";

export function ageValidator(dateObj?: Date | null | number | string): boolean {
  if (!dateObj) {
    return false;
  }

  const birthDate = new Date(dateObj);
  return isBefore(birthDate, minAgeThresholdDate());
}
