import React from "react";
import { WepaServiceOrderFlow } from "./WepaServiceOrderFlow";
import { TFunction } from "react-i18next";
import { useMutation } from "react-apollo";
import {
  StartWepaService,
  StartWepaServiceVariables
} from "../../../lib/api/mutations/graphql/StartWepaService";
import { START_WEPA_SERVICE } from "../../../lib/api/mutations/START_WEPA_SERVICE";
import RequestFeedbackHandler from "../../generic/RequestFeedbackHandler";
import { ExecutionResult } from "graphql";
import { useParams } from "react-router";
import { GetWepaServiceByIdQuery } from "../../../lib/api/queries/GetWepaServiceById";
import {
  UpdateWepaDirectorDetails,
  UpdateWepaDirectorDetailsVariables
} from "../../../lib/api/mutations/graphql/UpdateWepaDirectorDetails";
import { UPDATE_WEPA_DIRECTOR_DETAILS } from "../../../lib/api/mutations/UPDATE_WEPA_DIRECTOR_DETAILS";
import { ShareholderPerson } from "../../../global-query-types";
import { COMPLETE_WEPA_SERVICE_ORDER } from "../../../lib/api/mutations/COMPLETE_WEPA_SERVICE_ORDER";
import {
  CompleteWepaServiceOrder,
  CompleteWepaServiceOrderVariables
} from "../../../lib/api/mutations/graphql/CompleteWepaServiceOrder";

interface WepaServiceOrderFlowContainerProps {
  t: TFunction;
  taxAdvisorCode: string | undefined;
}

function enforceWepaServiceExists<T>(id: T | undefined | null): asserts id is T {
  if (!id) {
    throw new Error(`submitWepaDirectorHandler: Expected wepa service id to be present`);
  }
}

export const WepaServiceOrderFlowContainer = ({
  t,
  taxAdvisorCode
}: WepaServiceOrderFlowContainerProps) => {
  const { id } = useParams<{ id?: string }>();
  const wepaServiceRequest = GetWepaServiceByIdQuery(id);

  const refetchWepaService = async (): Promise<void> => {
    await wepaServiceRequest.refetch();
  };

  const [startWepaService, startWepaServiceRequest] = useMutation<
    StartWepaService,
    StartWepaServiceVariables
  >(START_WEPA_SERVICE);

  const submitCompanyInfoHandler = (
    data: StartWepaServiceVariables
  ): Promise<ExecutionResult<StartWepaService>> => {
    return startWepaService({
      variables: { ...data }
    });
  };

  const [updateWepaDirector, updateWepaDirectorRequest] = useMutation<
    UpdateWepaDirectorDetails,
    UpdateWepaDirectorDetailsVariables
  >(UPDATE_WEPA_DIRECTOR_DETAILS);

  const submitWepaDirectorHandler = (
    director: ShareholderPerson
  ): Promise<ExecutionResult<UpdateWepaDirectorDetails>> => {
    enforceWepaServiceExists(id);
    return updateWepaDirector({ variables: { id, director } });
  };

  const [completeWepaServiceOrder, completeWepaServiceOrderRequest] = useMutation<
    CompleteWepaServiceOrder,
    CompleteWepaServiceOrderVariables
  >(COMPLETE_WEPA_SERVICE_ORDER);

  const completeWepaServiceOrderHandler = async (): Promise<void> => {
    enforceWepaServiceExists(id);
    await completeWepaServiceOrder({ variables: { id } });
  };

  return (
    <RequestFeedbackHandler
      requests={[
        startWepaServiceRequest,
        wepaServiceRequest,
        updateWepaDirectorRequest,
        completeWepaServiceOrderRequest
      ]}
      showChildrenWhileLoading>
      <WepaServiceOrderFlow
        t={t}
        submitCompanyInfoHandler={submitCompanyInfoHandler}
        submitWepaDirectorHandler={submitWepaDirectorHandler}
        completeWepaServiceOrderHandler={completeWepaServiceOrderHandler}
        refetchWepaService={refetchWepaService}
        wepaService={wepaServiceRequest.data?.getWepaServiceById}
        taxAdvisorCode={taxAdvisorCode}
      />
    </RequestFeedbackHandler>
  );
};
