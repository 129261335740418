import { useHistory } from "react-router-dom";
import RideIcon from "uiLibrary/components/RideIcon/RideIcon";
import { RideSize, RideStatus } from "lib/types/types";
import React from "react";

interface ReviewSectionTitleProps {
  title: string;
  dataTestId?: string;
  editLink?: string;
  className?: string;
  isCompleted?: boolean;
}

export const ReviewSectionTitle = ({
  title,
  editLink,
  dataTestId = "review-section-title",
  className,
  isCompleted = true
}: ReviewSectionTitleProps) => {
  const history = useHistory();

  return (
    <div className={`ltb-review-section-title ${className ?? ""}`}>
      <span data-testid={dataTestId}>{title}</span>
      {editLink && (
        <RideIcon
          name="edit-max"
          status={isCompleted ? undefined : RideStatus.MISSING_INFORMATION}
          size={RideSize.SMALL}
          onClick={() => history.push(editLink)}
          dataTestId="edit-button"
        />
      )}
    </div>
  );
};
