import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import React, { useImperativeHandle } from "react";
import { Col, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { StepComponentProps } from "../../../ChaptersOrderLayout.partials";
import * as Yup from "yup";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ClientSelect } from "client/components/ClientSelect/ClientSelect";
import { LegalPersonType } from "global-query-types";
import { deepClone } from "common/deepClone";
import { registerCourt } from "common/staticData/registerCourtOptions/registerCourt";

export enum LegalForm {
  AG = "AG",
  GmbH = "GmbH",
  GmbH_Co_KG = "GmbH & Co KG",
  KG = "KG",
  MbH = "MbH",
  UG = "UG (haftungsbeschkränkt)"
}

const LegalEntityFormSchema = (t) => {
  return Yup.object().shape({
    companyName: Yup.string().required(t("generic:validation-required")),
    legalForm: Yup.string().required(t("generic:validation-required")),
    registerCourt: Yup.string().required(t("generic:validation-required")),
    registration: Yup.string().required(t("generic:validation-required"))
  });
};

const LegalEntityForm = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef,
  "data-testid": dataTestId,
  headlineContent = <></>
}: WithTranslation & StepComponentProps & { headlineContent?: React.ReactElement }) => {
  const shareholderIndex = currentStep?.chapter?.index ?? 0;
  let shareholdersCopy = order.extra.shareholders ? deepClone(order.extra.shareholders) : {};
  const shareholder = shareholdersCopy[shareholderIndex] ?? {};
  const companyData = shareholder?.companyData ?? {};

  const initialValues = {
    companyName: companyData?.companyName ?? "",
    legalForm: companyData?.legalForm ?? LegalForm.GmbH,
    registerCourt: companyData?.registerCourt ?? "",
    registration: companyData?.registration ?? ""
  };

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    shareholder.shareholderType = LegalPersonType.Company;
    const { shouldReturnToParentChapter, ...data } = values;
    shareholder.companyData = data;
    shareholdersCopy[shareholderIndex] = shareholder;

    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        shareholders: shareholdersCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldReturnToParentChapter ?? false
    );
  };

  const legalFormOptions = Object.keys(LegalForm).map((key) => {
    return { value: key, label: LegalForm[key] };
  });

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: LegalEntityFormSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form" data-testid="legal-entity-form">
        <div className="legal-entity subchapter-order-layout__scrollable">
          {headlineContent}
          <div data-testid={dataTestId} className="legal-entity__inputs">
            <Row className={"legal-entity__input"}>
              <Col>
                <RideInput
                  name={"companyName"}
                  formik={formik}
                  label={t("generic:company-name")}
                  placeholder={t("generic:company-name")}
                  className={"order-form__input"}
                  autoComplete="on"
                />
              </Col>
            </Row>
            <Row className={"legal-entity__input"}>
              <Col>
                <ClientSelect
                  name={"legalForm"}
                  testIdPrefix={"legalForm"}
                  label={t("generic:legal-form")}
                  options={legalFormOptions}
                  isSearchable
                  isOnModal
                />
              </Col>
            </Row>
            <Row className={"legal-entity__input"}>
              <Col>
                <ClientSelect
                  name={"registerCourt"}
                  testIdPrefix={"registerCourt"}
                  label={t("generic:registerCourt")}
                  placeholder={t("generic:registerCourt")}
                  className={"order-form__input"}
                  options={registerCourt}
                  isSearchable
                  isOnModal
                />
              </Col>
            </Row>
            <Row className={"legal-entity__input"}>
              <Col>
                <RideInput
                  name={"registration"}
                  formik={formik}
                  label={t("generic:registration")}
                  placeholder={t("generic:registration")}
                  className={"order-form__input"}
                  autoComplete="on"
                />
              </Col>
            </Row>
          </div>
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(LegalEntityForm);
