import { WithTranslation } from "react-i18next";
import AdvertisementCampaignBanner from "../AdvertisementCampaignBanner/AdvertisementCampaignBanner";
import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

const AngelInvestFundCampaignBanner = ({ t }: WithTranslation) => (
  <AdvertisementCampaignBanner
    dataTestId="invest-fund-campaign-banner"
    title={t("generic:advertisement-campaign-banner.campaign.angel-invest-fund.title")}
    description={t("generic:advertisement-campaign-banner.campaign.angel-invest-fund.description")}
    moreInfoUrl={t("generic:advertisement-campaign-banner.campaign.angel-invest-fund.footer-url")}
  />
);

export default withTranslationReady(["generic"])(AngelInvestFundCampaignBanner);
