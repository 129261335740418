import React from "react";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { PendingTaskSwitcherProps } from "../PendingTaskSwitcher";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import {
  ButtonIntensity,
  RideButtonDeprecated
} from "../../../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { DownloadIcon } from "../../../../../../sharedComponents/icons/DownloadIcon";
import NavigationService from "../../../../../../lib/services/NavigationService/NavigationService";
import { Typography } from "../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import { CheckIcon } from "../../../../../../sharedComponents/icons/DesignSystemIcons/CheckIcon";
import { WithTranslation } from "react-i18next";

const DownloadAndAgreePendingTask = ({
  t,
  task,
  isTaskCompleted,
  completeTask,
  handleAgreeToIBKRForm
}: PendingTaskSwitcherProps & WithTranslation) => {
  const url = `${getEndpoint()}/api/ibkr-form/${task.formNumber}`;

  const downloadFile = () => {
    NavigationService.instance?.openLink(url);
  };

  const acceptDocumentTerms = async () => {
    await handleAgreeToIBKRForm?.(task.formNumber ?? "");
    await completeTask();
  };

  return (
    <div
      data-testid="legal-documents-confirmation-item"
      className="legal-documents-confirmation-item">
      <RideButtonDeprecated
        data-testid="document-download-button"
        className="legal-documents-confirmation-item__download-button"
        intensity={ButtonIntensity.Soft}
        type="button"
        onClick={downloadFile}>
        <div className="legal-documents-confirmation-item__download-button__content">
          <DownloadIcon />
          <div className="legal-documents-confirmation-item__download-button__content__text">
            {t("generic:download-filename", { filename: task.formName })}
          </div>
        </div>
      </RideButtonDeprecated>
      {isTaskCompleted ? (
        <div className="download-and-agree-pending-task__already-accepted">
          <CheckIcon className="download-and-agree-pending-task__check-icon" />
          <Typography
            category={"Paragraph"}
            size={100}
            weight={"Light"}
            data-testid={"document-accepted"}>
            {t(
              "generic:ride-broker-onboarding.documents.confirm-legal-documents.document-accepted",
              {
                documentName: task.formName
              }
            )}
          </Typography>
        </div>
      ) : (
        <button
          data-testid="accept-document-terms-button"
          className="ltb-overview__primary-action-btn"
          type="button"
          onClick={acceptDocumentTerms}>
          {t(
            "generic:ride-broker-onboarding.documents.confirm-legal-documents.i-understand-and-agree-with",
            { documentName: task.formName }
          )}
        </button>
      )}
    </div>
  );
};

export default withTranslationReady(["generic"])(DownloadAndAgreePendingTask);
