import gql from "graphql-tag";

export const CREATE_COMPANY_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM = gql`
  mutation CreateCompanyLeadForUnifiedShortOrderForm(
    $companyData: CreateCompanyLeadCompanyData!
    $companyAddress: CreateCompanyLeadAddressData
    $hasCorporateShareholders: Boolean!
    $contactEmail: String!
  ) {
    createCompanyLeadForUnifiedShortOrderForm(
      companyData: $companyData
      companyAddress: $companyAddress
      hasCorporateShareholders: $hasCorporateShareholders
      contactEmail: $contactEmail
    ) {
      feedback
    }
  }
`;
