import React from "react";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import {
  ButtonRadius,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { CopyableInformation } from "client/components/CopyableInformation/CopyableInformation";
import { OrderData } from "../../ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerData } from "../LowTaxBrokerOverview";
import NavigationService from "../../../../lib/services/NavigationService/NavigationService";
import { LowTaxBrokerOnboarding } from "lib/models/client/LowTaxBroker/Onboarding";

export interface SetupAccountCardProps {
  order: OrderData<LowTaxBrokerData> | null | undefined;
  cardIndex: number;
  status: OnboardingStepCardStatus;
  updateOrderOnboarding: (orderId: string, onboarding: LowTaxBrokerOnboarding) => Promise<void>;
}

const SetupAccountCard = ({
  t,
  order,
  status,
  cardIndex,
  updateOrderOnboarding
}: SetupAccountCardProps & WithTranslation) => {
  return (
    <OnboardingStepCard
      data-testid={"ltb-setup-account-card"}
      number={cardIndex}
      title={t("generic:low-tax-broker-overview.setup-account.title")}
      status={status}>
      <div data-testid={"open-the-ib-web-app-step"} className={"setup-account-card__step"}>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.first")}
        </span>
        <RideButtonDeprecated
          data-testid={"open-the-ib-web-app-button"}
          radius={ButtonRadius.Soft}
          onClick={() => {
            NavigationService.instance?.openLink(
              "https://www.interactivebrokers.co.uk/sso/Login?RL=1&partnerID=RIDE01&utm_source=app&utm_medium=app"
            );
          }}>
          {t(
            rideBrokerT(
              "generic:low-tax-broker-overview.setup-account.buttons.open-low-tax-broker-web-interface"
            )
          )}
        </RideButtonDeprecated>
      </div>
      <div data-testid={"ib-credentials-step"} className={"setup-account-card__step"}>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.second")}
        </span>
        <CopyableInformation
          data-testid="copyable-username"
          label={t("generic:username")}
          value={order?.extra?.ibCredentials?.username ?? ""}
          showValue={true}
        />
      </div>
      <div
        data-testid={"follow-our-guide-to-fill-the-tax-form-step"}
        className={"setup-account-card__step"}>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.third")}
        </span>
        <RideButtonDeprecated
          data-testid={"tax-form-step-by-step-guide-button"}
          radius={ButtonRadius.Soft}
          onClick={() => {
            NavigationService.instance?.openLink(
              "https://www.ride.capital/faq/wie-f%C3%BClle-ich-ein-w-8ben-e-formular-aus?utm_source=app&utm_medium=app"
            );
          }}>
          {t("generic:low-tax-broker-overview.setup-account.buttons.tax-form-step-by-step-guide")}
        </RideButtonDeprecated>
      </div>
      <div
        data-testid={"follow-our-guide-to-complete-the-last-regulatory-step"}
        className={"setup-account-card__step"}>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.fourth")}
        </span>
        <RideButtonDeprecated
          data-testid={"emir-step-by-step-guide-button"}
          radius={ButtonRadius.Soft}
          onClick={() => {
            NavigationService.instance?.openLink(
              "https://www.ride.capital/faq/kontoer%C3%B6ffnung-bei-ib?utm_source=app&utm_medium=app"
            );
          }}>
          {t("generic:low-tax-broker-overview.setup-account.buttons.emir-step-by-step-guide")}
        </RideButtonDeprecated>
      </div>
      <div data-testid={"change-password-step"} className={"setup-account-card__step"}>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.fifth")}
        </span>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.sixth")}
        </span>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.seventh")}
        </span>
        <span className={"setup-account-card__step-description"}>
          {t("generic:low-tax-broker-overview.setup-account.steps.eighth")}
        </span>
      </div>
      <div className={"setup-account-card__complete-btn-container"}>
        <button
          data-testid={"complete-account-setup-button"}
          className={"setup-account-card__complete-account-setup-btn"}
          onClick={async () =>
            await updateOrderOnboarding(order?.id ?? "", {
              ...order?.extra?.onboarding,
              hasCompletedVerifyIdStep: true,
              applicationReviewed: true,
              hasCompletedSetupAccountStep: true
            })
          }>
          {t("generic:low-tax-broker-overview.setup-account.buttons.complete-account-setup")}
        </button>
      </div>
    </OnboardingStepCard>
  );
};

export default withTranslationReady(["generic"])(SetupAccountCard);
