import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  AlertType,
  BarVariant,
  RideAlertBar
} from "uiLibrary/components/RideAlertBar/RideAlertBar";
import {
  AlertConfig,
  OrderData
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { AssetType } from "lib/models/client/LowTaxBroker/Assets";

export const CFDInfoBanner = withTranslationReady(["generic"])(({ t }: WithTranslation) => (
  <RideAlertBar
    data-testid="cfd-info-banner"
    type={AlertType.INFO}
    title={t("generic:ride-broker-onboarding.alerts.cfd.title")}
    message={t("generic:ride-broker-onboarding.alerts.cfd.message")}
    barVariant={BarVariant.Rectangle}
  />
));

export const CFDInfoBannerConfig: AlertConfig = {
  shouldShow: (order: OrderData<LowTaxBrokerOrderData>) => {
    return !!order.extra?.assetExperiences?.[AssetType.CFD];
  },
  component: CFDInfoBanner
};
