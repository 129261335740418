import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { AssetTypes } from "lib/types/types";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import {
  MultiSelectOption,
  RideMultiSelect
} from "uiLibrary/components/RideMultiSelect/RideMultiSelect";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import {
  canBeSelectedAsAreaOfInvestment,
  meetsMinimumLiquidNetWorthRequirement
} from "lib/validation/LowTaxBroker/tradingPermissionsHelper";

const AreasOfInvestmentStep = ({
  controls,
  order,
  saveData,
  currentStep,
  t
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const initialValues = {
    stocks: !!order.extra.assetExperiences?.stocks,
    bonds: !!order.extra.assetExperiences?.bonds,
    options: !!order.extra.assetExperiences?.options,
    futures: !!order.extra.assetExperiences?.futures,
    forex: !!order.extra.assetExperiences?.forex,
    cfd: !!order.extra.assetExperiences?.cfd,
    margin: !!order.extra.assetExperiences?.margin
  };

  const optionIsEnabled = (type: AssetTypes): boolean => {
    switch (type) {
      case AssetTypes.CFD:
        return meetsMinimumLiquidNetWorthRequirement(order);
      case AssetTypes.FUTURES:
        return (
          meetsMinimumLiquidNetWorthRequirement(order) &&
          canBeSelectedAsAreaOfInvestment(AssetTypes.FUTURES, order)
        );
      case AssetTypes.OPTIONS:
        return (
          meetsMinimumLiquidNetWorthRequirement(order) &&
          canBeSelectedAsAreaOfInvestment(AssetTypes.OPTIONS, order)
        );
      case AssetTypes.FOREX:
        return canBeSelectedAsAreaOfInvestment(AssetTypes.FOREX, order);
      case AssetTypes.MARGIN:
        return canBeSelectedAsAreaOfInvestment(AssetTypes.FUTURES, order);
      case AssetTypes.STOCKS:
        return canBeSelectedAsAreaOfInvestment(AssetTypes.STOCKS, order);
      default:
        return true;
    }
  };

  const optionIsAvailable = (type: AssetTypes): boolean => {
    switch (type) {
      case AssetTypes.CFD:
        return !order.extra.isNewBrokerEnabled;
      case AssetTypes.MARGIN:
        return !!order.extra.isNewBrokerEnabled;
      default:
        return true;
    }
  };

  const availableAssetTypes = Object.values(AssetTypes).filter(optionIsAvailable);

  const onSubmit = async (values) => {
    currentStep.complete();

    let assetExperiences = {};

    availableAssetTypes
      .filter((asset) => values[asset])
      .forEach(
        (asset) =>
          (assetExperiences[asset] = {
            knowledgeLevel: order.extra.assetExperiences?.[asset]?.knowledgeLevel ?? undefined,
            tradesLastThreeYears:
              order.extra.assetExperiences?.[asset]?.tradesLastThreeYears ?? undefined,
            yearsTrading: order.extra.assetExperiences?.[asset]?.yearsTrading ?? undefined
          })
      );

    await saveData(
      {
        ...order.extra,
        assetExperiences,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validate(values) {
      const selected = availableAssetTypes.filter((asset) => values[asset]);
      if (selected.length === 0) {
        return { error: t("generic:validation-required") };
      }
    }
  });

  const options: MultiSelectOption[] = availableAssetTypes.map((value) => ({
    label: t(`generic:ride-broker-onboarding.investment-options.${value}`),
    name: value,
    isReadonly: !optionIsEnabled(value)
  }));

  return (
    <div data-testid="areas-of-investment-step">
      <FormikProvider value={formik}>
        <Form>
          <div data-testid="areas-of-investment" className="areas-of-investment">
            <StepHeading text={t("generic:ride-broker-onboarding.areas-of-investment.title")} />
            <RideMultiSelect options={options} />
          </div>
          <InvalidFeedback hidden={!formik.errors["error"]} testPrefix="error">
            {formik.errors["error"]}
          </InvalidFeedback>
          {controls}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic"])(AreasOfInvestmentStep);
