import AdminServiceModal from "./AdminServiceModal";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_ADMIN_SERVICE_ADD_ON } from "../Mutations";
import {
  CreateAdminServiceAddOn,
  CreateAdminServiceAddOnVariables
} from "../graphql/CreateAdminServiceAddOn";
import { ExecutionResult } from "graphql";
import logger from "../../../../common/Logger";

interface Props {
  modalOpen: boolean;
  handleModalClose: () => void;
  companyId: string;
}

export const AdminServiceModalContainer = (props: Props) => {
  const [createAdminServiceAddOnMutation, createAdminServiceAddOnRequest] = useMutation<
    CreateAdminServiceAddOn,
    CreateAdminServiceAddOnVariables
  >(CREATE_ADMIN_SERVICE_ADD_ON);
  const { companyId } = props;

  const createAdminServiceAddOn = async (): Promise<ExecutionResult<CreateAdminServiceAddOn>> => {
    let result;
    try {
      result = await createAdminServiceAddOnMutation({ variables: { companyId } });
    } catch (err) {
      logger.error(err);
    }

    return result;
  };

  return (
    <AdminServiceModal
      createAdminServiceAddOn={createAdminServiceAddOn}
      createAdminServiceAddOnRequest={createAdminServiceAddOnRequest}
      {...props}
    />
  );
};
