import React from "react";
import { ApolloQueryResult } from "apollo-client";
import { TFunction } from "i18next";
import { Button } from "react-bootstrap";

import {
  isAdminServiceActivated,
  isAdminServiceComplete,
  isAdminServiceFolderStructure
} from "./AdminServiceSectionAddon";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const ActivateTaxService = ({
  t,
  company,
  activateTaxService,
  createAdminServiceFolderStructure,
  refetchCompany,
  handleAssignTaxAdvisorStoragePermissions
}: {
  t: TFunction;
  company: NomineeCompanyDetails_companyById;
  activateTaxService: (taxServiceId: string) => Promise<boolean>;
  createAdminServiceFolderStructure: (companyId: string) => Promise<void>;
  handleAssignTaxAdvisorStoragePermissions: () => Promise<void>;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}) => {
  const companyHasTaxService = !!company.myTaxService;
  const companyHasAdminServiceActivated = isAdminServiceActivated(company);
  const companyHasIncompleteAdminService = !isAdminServiceComplete(company);
  if (
    companyHasAdminServiceActivated || // TODO: This line is missing test coverage :)
    !companyHasTaxService ||
    companyHasIncompleteAdminService // TODO: This line is missing test coverage :)
  ) {
    return <></>;
  }

  const handleClick = async () => {
    if (!company.myTaxService?.id) {
      return;
    }

    const hasSucceeded = await activateTaxService(company.myTaxService?.id);

    if (!hasSucceeded) {
      return;
    }

    if (!isAdminServiceFolderStructure(company)) {
      await createAdminServiceFolderStructure(company.id);
    }

    await handleAssignTaxAdvisorStoragePermissions();

    await refetchCompany();
  };

  return (
    <Button onClick={handleClick} data-testid="activate-admin-service">
      {t("nominee-dashboard:admin-service.activate")}
    </Button>
  );
};
