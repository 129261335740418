import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { SelectedOption } from "sharedComponents/icons/SelectedOption";
import { NotSelectedOption } from "sharedComponents/icons/NotSelectedOption";
import {
  RideBadgeColorsEnum,
  RideBadgeSizeVariant,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import { LinkVariant, TextLink } from "../../../../../sharedComponents/TextLink/TextLink";

export interface BankSelectCardProps extends WithTranslation {
  name: string;
  iconPath: string;
  isSelectable?: boolean;
  isSelected?: boolean;
  isPartnerBank?: boolean;
  setupTimeEstimate?: string;
  costEstimate?: string;
  infoLink?: string;
  description?: string;
}

const BankSelectCard = ({
  name,
  iconPath,
  isSelectable,
  isSelected,
  setupTimeEstimate,
  costEstimate,
  isPartnerBank,
  infoLink,
  description,
  t
}: BankSelectCardProps) => {
  return (
    <div
      className={`bank-select-card ${isSelected ? "bank-select-card--selected" : ""}`}
      data-testid="bank-select-card">
      <div className="bank-select-card__content">
        <div className="bank-select-card__header">
          {isSelectable && (
            <div
              className="bank-select-card__select-status"
              data-testid={"bank-select-card-status"}>
              {isSelected && <SelectedOption />}
              {!isSelected && <NotSelectedOption />}
            </div>
          )}
          <div className="bank-select-card__title">
            <div className="bank-select-card__logo" data-testid="bank-select-card-icon">
              <img src={iconPath} alt={`${name} logo`} />
            </div>
            <div className="bank-select-card__name" data-testid="bank-select-card-name">
              {name}
            </div>
          </div>
        </div>
        {(setupTimeEstimate || costEstimate || isPartnerBank) && (
          <BankDetails
            setupTime={setupTimeEstimate}
            cost={costEstimate}
            isStatusBadgeShown={isPartnerBank}
          />
        )}
      </div>
      {isSelected && (
        <div className="bank-select-card__footer">
          <span>
            {description}
            <TextLink
              data-testid="bank-select-card-info-link"
              to={infoLink ?? "#"}
              target="_blank"
              variant={LinkVariant.primary}>
              {t("client:banking.footerLink")}
            </TextLink>
          </span>
        </div>
      )}
    </div>
  );
};

interface BankDetailsProps extends WithTranslation {
  setupTime?: string;
  cost?: string;
  isStatusBadgeShown?: boolean;
}

const BankDetails = withTranslationReady("client")(
  ({ setupTime, cost, isStatusBadgeShown, t }: BankDetailsProps) => {
    return (
      <div className="bank-details">
        <div className="bank-details__info">
          {setupTime && (
            <BankDetailInfo
              testIdAppendix="setupTime"
              value={setupTime}
              label={t(`client:banking.setupTime.label`)}
            />
          )}
          {cost && (
            <BankDetailInfo
              testIdAppendix="cost"
              value={cost}
              label={t(`client:banking.cost.label`)}
            />
          )}
        </div>
        {isStatusBadgeShown && (
          <div data-testid="bank-partner-status" className="bank-details__partner-status">
            <RideBadgeStatus
              variant={RideBadgeVariantsEnum.SOLID}
              sizeVariant={RideBadgeSizeVariant.SMALL}
              color={RideBadgeColorsEnum.DARK}
              label={t("client:banking.partnerBank")}
            />
          </div>
        )}
      </div>
    );
  }
);

const BankDetailInfo = ({
  value,
  label,
  testIdAppendix
}: {
  value: string;
  label: string;
  testIdAppendix: string;
}) => (
  <div className="bank-detail-info" data-testid={`estimate-field-${testIdAppendix}`}>
    <div className="bank-detail-info__label">{label}</div>
    <div
      className="bank-select-card__field-value"
      data-testid={`bank-select-card-${testIdAppendix}`}>
      {value}
    </div>
  </div>
);

export default withTranslationReady(["client"])(BankSelectCard);
