import React from "react";
import LoadingAlert from "../generic/LoadingAlert";
import { WithTranslation, withTranslation } from "react-i18next";
import ErrorAlertLegacy from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import Table from "../generic/Table";
import { FeatureFlags, UserRole } from "../../global-query-types";
import NewRealEstateForm from "./NewRealEstateForm";
import { GetRealEstates } from "./getRealEstates";
import { GetTokenizedRealEstates } from "./getTokenizedRealEstates";
import { formatCurrency } from "../../common/formatters";
import { NomineeRealEstateModal } from "../nominee/NomineeSpv/RealEstate/CreateRealEstate/NomineeRealEstateModal";
import { LinkVariant, TextLink } from "../../sharedComponents/TextLink/TextLink";
import { FeatureFlag } from "../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

interface RealEstateProps extends WithTranslation {
  role: UserRole;
}

const RealEstateTable = ({ t, role }: RealEstateProps) => {
  const { data: my, loading: myLoading, error: myError } = GetRealEstates();
  const {
    data: tokenized,
    loading: tokenizedLoading,
    error: tokenizedError
  } = GetTokenizedRealEstates();

  const loading = myLoading || tokenizedLoading;
  const error = myError || tokenizedError;

  if (error) return <ErrorAlertLegacy t={t} error={error.message} />;
  if (loading) return <LoadingAlert t={t} message="realEstate:loading-message" />;

  const realEstates = [...(my?.realEstates || []), ...(tokenized?.tokenizedRealEstates || [])];

  const columns = [
    {
      dataField: "label",
      text: t("realEstate:label"),
      formatter: (cell, row) => (
        <TextLink
          to={linkFor(role, row)}
          data-testid="real-estate-details"
          variant={LinkVariant.primary}>
          <span data-testid="real-estate-label">{cell}</span>
        </TextLink>
      )
    },
    {
      dataField: "propertyValue",
      text: t("realEstate:property-value"),
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cell, row) => (
        <span data-testid="real-estate-value">{formatCurrency(cell * getOwnershipShare(row))}</span>
      )
    }
  ];

  return (
    <>
      <FeatureFlag name={FeatureFlags.DeprecatedAssetManager}>
        <div className="clearfix mb-3">
          <div className="float-right">
            {role === UserRole.Nominee ? <NomineeRealEstateModal t={t} /> : <NewRealEstateForm />}
          </div>
        </div>
      </FeatureFlag>

      <Table data={realEstates} columns={columns} keyField="id" tableStyle="greenTable" />
    </>
  );
};

const linkFor = (role: UserRole, row) => {
  let prefix = "/";
  let id = row.id;
  let url;

  if (role === UserRole.Nominee) {
    prefix += "nominee-";
  }

  if (row.companyId) {
    id = row.companyId;
    url = prefix + "investment/detail";
  } else {
    url = "/admin/investment-club/investment-club-assets";
  }

  return `${url}/${id}`;
};

const getOwnershipShare = (row) => {
  const realEstateShare = row.owner?.memberships?.find((m) => !!m.share)?.share?.share;
  if (realEstateShare) {
    return realEstateShare / 100;
  }

  if (row.tokenBalance) {
    return row.tokenBalance.balance / row.tokenBalance.total;
  }

  return 0;
};

export default withTranslation(["generic", "realEstate", "errorCodes"])(RealEstateTable);
