import { OrderChaptersConfig } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import ShareholdersList from "client/components/ChaptersOrderLayout/sharedComponents/ShareholdersList/ShareholdersList";
import { OrderProductTypeEnum } from "global-query-types";
import { EntityGeneralInformation } from "client/components/ChaptersOrderLayout/sharedComponents/EntityGeneralInformation/EntityGeneralInformation";
import PersonCitizenshipInformationForm from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/PersonCitizenshipInformationForm/PersonCitizenshipInformationForm";
import PersonAddress from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/PersonAddress/PersonAddress";
import CompanyAddress from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyAddress/CompanyAddress";
import CompanyRepresentative from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentative/CompanyRepresentative";
import CompanyRepresentativeCitizenship from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentativeCitizenship/CompanyRepresentativeCitizenship";
import CompanyRepresentativeAddress from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentativeAddress/CompanyRepresentativeAddress";
import { ReviewEntity } from "client/components/ChaptersOrderLayout/sharedComponents/ReviewEntity/ReviewEntity";
import OwnershipDetails from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/OwnershipDetails/OwnershipDetails/OwnershipDetails";
import ManagingDirectorsList from "client/components/ChaptersOrderLayout/sharedComponents/ManagingDirectorsList/ManagingDirectorsList";
import SelectCompanyAddress from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyAddress/SelectCompanyAddress/SelectCompanyAddress";
import CompanyAddressForm from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyAddress/CompanyAddressForm/CompanyAddressForm";
import CompanyBasicInfoStep from "client/components/ChaptersOrderLayout/AddExistingCompany/CompanyInformation/CompanyBasicInfoStep/CompanyBasicInfoStep";
import ReviewAndSubmitAddExistingCompany from "client/components/ChaptersOrderLayout/AddExistingCompany/ReviewAndSubmit/ReviewAndSubmitAddExistingCompany/ReviewAndSubmitAddExistingCompany";
import CompanyMainActivitiesStep from "client/components/ChaptersOrderLayout/AddExistingCompany/CompanyInformation/CompanyMainActivitiesStep/CompanyMainActivitiesStep";

export const ADD_EXISTING_COMPANY_FORM: OrderChaptersConfig = {
  chapters: {
    "company-information": {
      icon: "business",
      title: "generic:ride-broker-onboarding.chapters.company-information"
    },
    "shareholder-details": {
      icon: "id-card",
      title: "generic:company-setup.chapters.shareholder-details"
    },
    "ownership-distribution": {
      icon: "structure",
      title: "generic:company-setup.chapters.ownership-distribution"
    },
    "managing-directors": {
      icon: "grid",
      title: "generic:company-setup.chapters.managing-directors"
    },
    "company-address": {
      icon: "location",
      title: "generic:company-setup.chapters.company-address"
    },
    "review-and-submit": {
      icon: "rocket",
      title: "generic:company-setup.chapters.review-and-submit"
    }
  },
  steps: {
    //company-information
    "company-basic-info": CompanyBasicInfoStep,
    "company-main-activities": CompanyMainActivitiesStep,
    //shareholder-details
    "shareholders-list": ShareholdersList(OrderProductTypeEnum.CompanyFoundation),
    //for each shareholder:
    "shareholder-general-information": EntityGeneralInformation("shareholder", true),
    "shareholder-citizenship-information": PersonCitizenshipInformationForm("shareholder"), //dynamic. If type is Person
    "shareholder-residential-address": PersonAddress("shareholder"), //dynamic. If type is Person
    "shareholder-company-address": CompanyAddress, //dynamic. If type is Company
    "shareholder-company-representative": CompanyRepresentative, //dynamic. If type is Company
    "shareholder-representative-citizenship": CompanyRepresentativeCitizenship, //dynamic. If type is Company
    "shareholder-representative-address": CompanyRepresentativeAddress, //dynamic. If type is Company
    "shareholder-review": ReviewEntity("shareholder"),

    //ownership-distribution
    "ownership-details": OwnershipDetails,

    //managing-directors
    "managing-directors-list": ManagingDirectorsList(),
    //for each new managing director
    "employee-general-information": EntityGeneralInformation("employee"),
    "employee-citizenship-information": PersonCitizenshipInformationForm("employee"),
    "employee-residential-address": PersonAddress("employee"),
    "employee-review": ReviewEntity("employee"),

    //company-address
    "select-company-address": SelectCompanyAddress,
    "company-address-form": CompanyAddressForm,

    //review-and-submit
    "review-and-submit": ReviewAndSubmitAddExistingCompany
  },
  contextualHelpSuffix: "add-existing-company-flow"
};
