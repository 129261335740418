import React from "react";
import { useHistory } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { CompanyDateEditContainer } from "../../components/Company/CompanyDateEdit/CompanyDateEditContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface CompanyCommercialRegisterDateSceneProps extends WithTranslation {}

const CompanyCommercialRegisterDateScene = ({ t }: CompanyCommercialRegisterDateSceneProps) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.commercialRegisterEntryDate")} />
      <ModalFormLayout title={t("client:company.edit.commercialRegisterEntryDate")} onBack={goBack}>
        <CompanyDateEditContainer
          fieldName={"commercialRegisterEntryDate"}
          section="registration"
        />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation(["client"])(CompanyCommercialRegisterDateScene);
