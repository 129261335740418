import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { GetMyPendingTasks } from "lib/api/queries/MyPendingTasks";
import PendingTasks from "./PendingTasks";

const PendingTasksContainer = () => {
  const getRequest = GetMyPendingTasks();
  return (
    <RequestFeedbackHandler requests={[getRequest]}>
      <PendingTasks data={getRequest.data?.myPendingTasks ?? []} />
    </RequestFeedbackHandler>
  );
};

export default PendingTasksContainer;
