import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  formatDate,
  formatFiscalYear,
  formatFoundationDate,
  formatValue
} from "../../../../common/formatters";
import Badge from "react-bootstrap/Badge";
import { BasicCompanyDetails } from "../../../../components/common/BasicCompanyDetails/BasicCompanyDetails";
import { ValueView } from "../../../../components/common/layout/ValueView";
import ClientTags from "../../../../components/common/ClientTags/ClientTags";
import { NomineeCompanyDetails_companyById } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

interface ViewCompanyDetailsProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
}

const ViewCompanyDetails = ({ company, t }: ViewCompanyDetailsProps) => {
  return (
    <Container data-testid="company-details">
      <Row>
        <Col>
          <ValueView
            name=""
            testId="client-tags-value"
            value={<ClientTags client={company} />}
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company:locked-status"
            testId="locked-status"
            value={
              company.locked ? (
                <Badge variant="success">{t("nominee-dashboard:company:locked")}</Badge>
              ) : (
                <Badge variant="danger">{t("nominee-dashboard:company:unlocked")}</Badge>
              )
            }
            t={t}
          />
        </Col>
      </Row>

      <BasicCompanyDetails company={company} t={t} />

      <Row>
        <Col xs={6}>
          <ValueView
            name="nominee-dashboard:company.company-tax-number"
            testId="company-tax-number"
            value={formatValue(company.taxNumber)}
            t={t}
          />
        </Col>

        <Col xs={6}>
          <ValueView
            name="nominee-dashboard:company.company-vat-number"
            testId="company-vat-number"
            value={formatValue(company.vatNumber)}
            t={t}
          />
        </Col>

        <Col xs={6}>
          <ValueView
            name="nominee-dashboard:company.economic-id"
            testId="economic-id"
            value={formatValue(company.economicId)}
            t={t}
          />
        </Col>

        <Col xs={6}>
          <ValueView
            name="nominee-dashboard:company.lei"
            testId="lei"
            value={formatValue(company.lei ?? "")}
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <ValueView
            name="nominee-dashboard:company.advisorNumber"
            testId="advisorNumber"
            value={formatValue(company.advisorNumber ?? "")}
            t={t}
          />
        </Col>

        <Col xs={6}>
          <ValueView
            name="nominee-dashboard:company.notarization-date"
            testId="notarization-date"
            value={formatDate(company.notarizationDate)}
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company.application-for-entry-date"
            testId="application-for-entry-date"
            value={formatDate(company.applicationForEntryDate)}
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="nominee-dashboard:company.commercial-register-entry-date"
            testId="commercial-register-entry-date"
            value={formatDate(company.commercialRegisterEntryDate)}
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="generic:employees"
            testId="employees"
            value={formatValue(company.employees)}
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:company.tax-advisor-client-number"
            testId="client-number"
            value={formatValue(company.clientNumber)}
            t={t}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company.liability"
            testId="liability"
            value={formatValue(company.liability)}
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:company.fiscal-year"
            testId="fiscal-year"
            value={formatFiscalYear(company.fiscalYear, "DD.MM")}
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:company.foundation-date"
            testId="foundation-date"
            value={formatFoundationDate(company.foundationDate)}
            t={t}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company.as-upgrade-required-from-date"
            testId="as-upgrade-required-from-date"
            value={formatDate(company.asUpgradeRequiredFrom)}
            t={t}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company.wepa-standalone-3pta-migration.since"
            testId="wepa-standalone-3pta-migration-since"
            value={formatDate(company.migrationRequiredFor3PTASince)}
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:company.wepa-standalone-3pta-migration.realized-at"
            testId="wepa-standalone-3pta-migration-realized-at"
            value={formatDate(company.migrationRequiredFor3PTARealizedAt)}
            t={t}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(ViewCompanyDetails);
