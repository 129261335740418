import React from "react";
import LowTaxBrokerDashboard from "./LowTaxBrokerDashboard";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import { useMutation } from "@apollo/react-hooks";
import { REQUEST_LOW_TAX_BROKER_PAYOUT } from "lib/api/mutations/REQUEST_LOW_TAX_BROKER_PAYOUT";
import {
  RequestLowTaxBrokerPayOut,
  RequestLowTaxBrokerPayOutVariables
} from "lib/api/mutations/graphql/RequestLowTaxBrokerPayOut";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { BrokerageAccountLTB } from "../LowTaxBrokerViewSwitcher/LowTaxBrokerViewSwitcher";
import { openAsyncExternalURL } from "../../../common/ExternalURLOpener";
import logger from "../../../common/Logger";
import { CREATE_IBKR_SSO_SESSION } from "../../../lib/api/mutations/CREATE_IBKR_SSO_SESSION";
import { GetLTBOrderForCompany } from "lib/api/queries/GetLowTaxBrokerOrderForCompany";
import { ErrorCodes } from "../../../global-query-types";
import { useHistory } from "react-router-dom";
import { GmbHDetails } from "../../../lib/models/client/GmbHDetails";

interface LowTaxBrokerDashboardContainerProps {
  gmbh: GmbHDetails;
  brokerageAccount: BrokerageAccountLTB | undefined;
}

export const LowTaxBrokerDashboardContainer = ({
  gmbh,
  brokerageAccount
}: LowTaxBrokerDashboardContainerProps) => {
  const { push } = useHistory();
  const getLowTaxBrokerOrderQuery = GetLTBOrderForCompany(gmbh.id);
  const order = getLowTaxBrokerOrderQuery?.data?.getLowTaxBrokerOrderForCompany;

  const [requestLTBPayOutMutation] = useMutation<
    RequestLowTaxBrokerPayOut,
    RequestLowTaxBrokerPayOutVariables
  >(REQUEST_LOW_TAX_BROKER_PAYOUT);

  const [createIBKRSSOSession, createIBKRSSOSessionRequest] = useMutation(CREATE_IBKR_SSO_SESSION, {
    fetchPolicy: "no-cache"
  });

  const handleCreateSSOAndRedirect = async () => {
    try {
      const promisedURL = createIBKRSSOSession({
        variables: {
          companyId: gmbh.id ?? ""
        }
      }).then((result) => result.data?.createIBKRSSOSession?.ssoURL);

      await openAsyncExternalURL(promisedURL);
    } catch (error: any) {
      logger.errorMessage(`Error while creating SSO session for company ${gmbh.id}`);
      logger.error(error);

      if (error.message.toString().includes(ErrorCodes.IBKR_COMMUNICATION_FAILURE)) {
        push(`/client/ride-broker/internal-server-error/${gmbh.id}`);
      }
    }
  };

  const requestPayout = async (amount: number) => {
    await requestLTBPayOutMutation({
      variables: { amount, brokerageAccountId: brokerageAccount?.id ?? "" }
    });
  };

  const companyByIdQuery = QueryCompanyById(gmbh.id);
  const company = companyByIdQuery?.data?.companyById;

  return (
    <RequestFeedbackHandler requests={[getLowTaxBrokerOrderQuery, createIBKRSSOSessionRequest]}>
      <LowTaxBrokerDashboard
        order={order}
        companyInfo={{
          name: company?.name ?? "your-company-name",
          legalForm: company?.legalForm ?? "your-company-legal-form"
        }}
        brokerageAccount={brokerageAccount}
        requestPayoutCallback={requestPayout}
        handleCreateSSOAndRedirect={handleCreateSSOAndRedirect}
        gmbh={gmbh}
      />
    </RequestFeedbackHandler>
  );
};
