class DateConverter {
  toIsoDateString(date?: any) {
    if (!date) date = new Date();

    // This converter is intended to be used with the bootstrap forms input, hence the date might be a string
    if (typeof date === "string") {
      // if (date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g)) return date; // Already an iso string
      date = new Date(date);
    }

    const dateString =
      date.getFullYear().toString().padStart(4, "0") +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");

    return dateString;
  }
}

export default DateConverter;
