import { useQuery } from "@apollo/react-hooks";
import { REAL_ESTATE_OWNERS_QUERY } from "./Queries";
import {
  RealEstateOwnersQuery,
  RealEstateOwnersQuery_availableRealEstateOwners
} from "./graphql/RealEstateOwnersQuery";

export const GetRealEstateOwners = () => {
  const { data, error, loading } = useQuery<
    RealEstateOwnersQuery,
    RealEstateOwnersQuery_availableRealEstateOwners
  >(REAL_ESTATE_OWNERS_QUERY);

  return { data, error, loading };
};
