import React from "react";
import AddThirdPartyNotaryAndCompanyForm from "./AddThirdPartyNotaryAndCompanyForm";
import { withTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { CREATE_THIRD_PARTY_TAX_SERVICE_COMPANY } from "components/nominee/ThirdPartyTaxAdvisorsList/Mutations";
import {
  createThirdPartyTaxServiceCompany,
  createThirdPartyTaxServiceCompanyVariables
} from "components/nominee/ThirdPartyTaxAdvisorsList/graphql/createThirdPartyTaxServiceCompany";
import { onAddNotaryCompanyProps } from "components/nominee/ThirdPartyTaxAdvisorsList/types";

export const AddThirdPartyNotaryAndCompanyFormContainer = ({
  refetch
}: {
  refetch: () => Promise<void>;
}) => {
  const [createThirdPartyTaxServiceCompanyMutation, createThirdPartyTaxServiceCompanyRequest] =
    useMutation<createThirdPartyTaxServiceCompany, createThirdPartyTaxServiceCompanyVariables>(
      CREATE_THIRD_PARTY_TAX_SERVICE_COMPANY
    );

  const onAddNotaryCompany = async (variables: onAddNotaryCompanyProps) => {
    await createThirdPartyTaxServiceCompanyMutation({
      variables
    });
  };

  return (
    <RequestFeedbackHandler requests={[createThirdPartyTaxServiceCompanyRequest]}>
      <AddThirdPartyNotaryAndCompanyForm
        onAddNotaryCompany={onAddNotaryCompany}
        refetch={refetch}
      />
    </RequestFeedbackHandler>
  );
};

export default withTranslation(["generic"])(AddThirdPartyNotaryAndCompanyFormContainer);
