import React from "react";
import { TFunction } from "i18next";
import { formatCompanyName, formatPersonName } from "../../common/formatters/CompanyNameFormatter";
import { formatDate } from "../../common/formatters";
import { LegalForm, RolesInGroup, SalutationType } from "../../global-query-types";
import { calculateMemberVoteRightsForRole } from "../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import { round } from "lodash";
import { RideCardBodySeparator } from "sharedComponents/RideCardBodySeparator/RideCardBodySeparator";

interface Props {
  members: Member[];
  className?: string;
  t: TFunction;
  showBirthDate?: boolean;
  showShares?: boolean;
  showVotes?: boolean;
  showAddress?: boolean;
  silentPartnerShares?: number;
}

export interface Member {
  role: RolesInGroup;
  share?: {
    share: number;
  } | null;
  person?: {
    salutation: SalutationType | null;
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: string | null;
    address?: {
      street: string;
      streetLine2?: string | null;
      postalCode: string;
      city: string;
    } | null;
  } | null;
  company?: {
    name?: string | null;
    legalForm?: LegalForm | null;
    businessAddress?: {
      street: string;
      streetLine2?: string | null;
      postalCode: string;
      city: string;
    } | null;
  } | null;
}

export const MembersList = ({
  members,
  t,
  showBirthDate,
  showShares,
  showVotes,
  silentPartnerShares,
  showAddress
}: Props) => {
  return (
    <div className="members-list">
      {members.map((member, index) => (
        <React.Fragment key={index}>
          <Member
            t={t}
            member={member}
            showBirthDate={showBirthDate}
            showShares={showShares}
            showAddress={showAddress}
            showVotes={showVotes}
            silentPartnerShares={silentPartnerShares}
            key={index}
          />
          {index !== members.length - 1 && <RideCardBodySeparator />}
        </React.Fragment>
      ))}
    </div>
  );
};

export const Member = ({
  t,
  member,
  showBirthDate,
  showShares,
  showVotes,
  showAddress,
  silentPartnerShares
}: {
  member: Member;
  t: TFunction;
  showBirthDate?: boolean;
  showShares?: boolean;
  showVotes?: boolean;
  showAddress?: boolean;
  silentPartnerShares?: number;
}) => {
  const shareValue: number = member.share?.share ?? 0;
  const voteRightsValue = calculateMemberVoteRightsForRole(
    member.role,
    shareValue,
    silentPartnerShares ?? 0
  );

  const salutation = member.person
    ? t(`generic:${member.role}.${member.person?.salutation ?? "unknown"}`)
    : "";

  const memberName = member.person
    ? `${salutation} ${formatPersonName(t, member.person ?? {})}`
    : formatCompanyName(t, member.company ?? {});

  const fullName = (
    <div className="member-full-name" data-testid="member-full-name">
      {memberName}
    </div>
  );

  const birthDate =
    member.person?.dateOfBirth && showBirthDate ? (
      <div className="member-birth-date" data-testid="member-birth-date">
        {formatDate(member.person?.dateOfBirth)}
      </div>
    ) : null;

  const shares = showShares ? (
    <div className="member-shares" data-testid="member-shares">
      {t("generic:shares")}:{" "}
      <span className="member-shares__value">
        <b>{shareValue}%</b>
      </span>
    </div>
  ) : null;

  const votes = showVotes ? (
    <div className="member-votes" data-testid="member-votes">
      {t("generic:votes")}:{" "}
      <span className="member-votes__value">
        <b>{round(voteRightsValue, 4)}</b>
      </span>
    </div>
  ) : null;

  const memberAddress = member.person?.address ?? member.company?.businessAddress;

  const address =
    memberAddress && showAddress ? (
      <div className="member-address" data-testid="member-address">
        {memberAddress.streetLine2} {memberAddress.street}, D-{memberAddress.postalCode}{" "}
        {memberAddress.city}
      </div>
    ) : null;

  return (
    <div className="member" data-testid="member">
      {fullName}
      {birthDate}
      {address}
      {shares}
      {votes}
    </div>
  );
};
