import {
  AccountingFrameworkEnum,
  BrokerageAccountValidityEnum,
  CompanyCreationStep,
  OrderCompanyStatusEnum,
  OrderProductTypeEnum,
  TaxAdvisorStatusEnum,
  TaxServiceTypeEnum
} from "global-query-types";
import logger from "common/Logger";
import { FoundingProcessConfigService } from "lib/services/FoundingProcessConfigService/FoundingProcessConfigService";
import { ProcessStep } from "common/types/ProcessStatesTypes";
import { Company } from "lib/types/types";
import { ClientTagsCompanyInput } from "lib/types/clients/categories";
import moment from "moment";

export enum AdminServiceStatusEnum {
  NOT_ACQUIRED = "NOT_ACQUIRED",
  ORDER_PENDING = "ORDER_PENDING",
  WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED"
}

export enum GrundungServiceStatusEnum {
  NOT_ACQUIRED = "NOT_ACQUIRED",
  ORDER_PENDING = "ORDER_PENDING",
  IN_FOUNDATION = "IN_FOUNDATION",
  FOUNDED = "FOUNDED"
}

export enum WepaStatusEnum {
  INACTIVE = "INACTIVE",
  FAILURE = "FAILURE",
  PROCESSING = "PROCESSING",
  ACTIVE = "ACTIVE"
}

export class GmbH {
  constructor(private company: Company, readonly _refetch?: () => Promise<void>) {}

  get id() {
    return this.company.id;
  }

  get name() {
    return this.company.name;
  }

  get companyCreationId() {
    return this.company.companyCreation?.id;
  }

  get orderStatus() {
    return this.company.orderStatus;
  }

  get legalForm() {
    return this.company.legalForm;
  }

  get isComplete() {
    const states = this.company.companyCreation?.processStates ?? {};
    return Object.keys(states).every((key) => states[key].selected === "COMPLETED");
  }

  get isFounded() {
    if (!this.company.companyCreation) return true;

    const states: { [key: string]: ProcessStep } =
      this.company.companyCreation?.processStates ?? {};
    const companyFoundedRef = FoundingProcessConfigService.instance.specialRefs.companyFounded;

    const companyFoundedEntry = (Object.entries(states) ?? []).find(
      (processState) => processState[0] === companyFoundedRef
    );

    if (!companyFoundedEntry) return false;

    return companyFoundedEntry[1].selected === "COMPLETED";
  }

  get hasSubmittedGSOrder() {
    const companyFoundationOrder = this.company.orders?.find(
      (o) => o.productType === OrderProductTypeEnum.CompanyFoundation
    );

    const orderIsNewGsOrder: boolean = !!companyFoundationOrder;

    if (orderIsNewGsOrder) {
      return companyFoundationOrder?.status === OrderCompanyStatusEnum.Completed;
    } else {
      const currentStep = this.company.companyCreation?.currentStep;

      if (!currentStep) {
        return false;
      }

      const stepsAfterOrder = [
        CompanyCreationStep.ORDERED,
        CompanyCreationStep.ONBOARDING,
        CompanyCreationStep.ONBOARDED,
        CompanyCreationStep.EMAIL_CONFIRMED
      ];

      return stepsAfterOrder.includes(currentStep);
    }
  }

  get isInFoundation() {
    return this.hasSubmittedGSOrder && !this.isFounded;
  }

  get grundungServiceStatus(): GrundungServiceStatusEnum {
    if (!this.company.companyCreation) {
      return GrundungServiceStatusEnum.NOT_ACQUIRED;
    }
    if (!this.hasSubmittedGSOrder) {
      return GrundungServiceStatusEnum.ORDER_PENDING;
    }
    if (!this.isFounded) {
      return GrundungServiceStatusEnum.IN_FOUNDATION;
    }
    return GrundungServiceStatusEnum.FOUNDED;
  }

  get adminService() {
    return this.company.adminService;
  }

  get adminServiceStatus(): AdminServiceStatusEnum {
    const adminService = this.company.adminService;
    const taxService = this.company.myTaxService;

    const isAdminServiceTaxService =
      taxService && (!taxService.type || taxService.type === TaxServiceTypeEnum.AdminService);

    if (!adminService) {
      return AdminServiceStatusEnum.NOT_ACQUIRED;
    }
    if (
      adminService.isArchived ||
      (adminService.canceledAt && moment.utc(adminService.canceledAt).isBefore(moment.utc()))
    ) {
      return AdminServiceStatusEnum.CANCELED;
    }
    if (isAdminServiceTaxService && taxService?.taxAdvisorStatus === TaxAdvisorStatusEnum.ACTIVE) {
      return AdminServiceStatusEnum.ACTIVE;
    }
    if (adminService.orderedAt) {
      return AdminServiceStatusEnum.WAITING_FOR_CONFIRMATION;
    }
    return AdminServiceStatusEnum.ORDER_PENDING;
  }

  get hasAdminService() {
    const status = this.adminServiceStatus;
    return (
      status !== AdminServiceStatusEnum.NOT_ACQUIRED && status !== AdminServiceStatusEnum.CANCELED
    );
  }

  get wepaStatus(): WepaStatusEnum {
    if (!this.company.brokerageAccounts || !this.company.brokerageAccounts.length) {
      return WepaStatusEnum.INACTIVE;
    }

    const hasBrokerageAccountWithValidityIn = (
      possibleValidities: BrokerageAccountValidityEnum[]
    ) => {
      if (!this.company.brokerageAccounts) {
        return false;
      }

      return !!this.company.brokerageAccounts.find((account) =>
        possibleValidities.includes(account.validity ?? BrokerageAccountValidityEnum.New)
      );
    };

    if (
      hasBrokerageAccountWithValidityIn([
        BrokerageAccountValidityEnum.Invalid,
        BrokerageAccountValidityEnum.Deleted,
        BrokerageAccountValidityEnum.Expired,
        BrokerageAccountValidityEnum.ReportInvalid
      ])
    ) {
      return WepaStatusEnum.FAILURE;
    }

    if (
      hasBrokerageAccountWithValidityIn([
        BrokerageAccountValidityEnum.Processing,
        BrokerageAccountValidityEnum.New
      ])
    ) {
      return WepaStatusEnum.PROCESSING;
    }

    return WepaStatusEnum.ACTIVE;
  }

  get isWepaClient() {
    const finishedWepaDirectOrder = this.company.orders?.find(
      (o) => o.productType === OrderProductTypeEnum.WepaDirect && o.status === "Completed"
    );

    return this.company?.myTaxService?.type === "WepaService" || !!finishedWepaDirectOrder;
  }

  get accountingFramework(): AccountingFrameworkEnum {
    return this.company.accountingFramework ?? AccountingFrameworkEnum.SKR04;
  }

  nameObj() {
    return {
      name: this.name,
      legalForm: this.legalForm
    };
  }

  type() {
    return this.company.companyCreation?.flowType;
  }

  processStates() {
    return this.company.companyCreation?.processStates ?? {};
  }

  async refetch() {
    if (this._refetch) {
      return this._refetch();
    } else {
      logger.debug(
        "Refetch was not provided, please provide it from Container where GmbH data is fetched"
      );
    }
  }

  clientTagsInput(): ClientTagsCompanyInput {
    return {
      wepaId: this.company.wepaId,
      companyCreation: this.company.companyCreation,
      adminService: this.company.adminService,
      wepaService: this.company.wepaService
    };
  }
}
