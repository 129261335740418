import { PrivateRoute } from "sharedComponents/PrivateRoute/PrivateRoute";
import { UserRole } from "global-query-types";

export const checkAuthorization = (meData) => meData?.me?.role === UserRole.Accountant;

export const AccountantRoute = ({ component, ...rest }) =>
  PrivateRoute({
    component,
    hasAuthorization: checkAuthorization,
    ...rest
  });

export default AccountantRoute;
