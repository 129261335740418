import React from "react";
import { useParams } from "react-router-dom";
import { GetMyTaxAdvisorCompanyWithClientsQuery } from "../../Queries";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import TaxAdvisorClientOverviewSection from "../TaxAdvisorClientOverviewSection/TaxAdvisorClientOverviewSection";
import { CompanyType } from "../../../global-query-types";
import { GetFormerTaxAdvisorClientCompanyQuery } from "../../../lib/api/queries/GetFormerTaxAdvisorClientCompanyQuery";

const TaxAdvisorFormerClientOverviewSectionContainer = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const getClientCompanyRequest = GetFormerTaxAdvisorClientCompanyQuery(companyId);
  const getTaxAdvisorCompanyRequest = GetMyTaxAdvisorCompanyWithClientsQuery(
    CompanyType.TaxService
  );

  return (
    <RequestFeedbackHandler requests={[getClientCompanyRequest, getTaxAdvisorCompanyRequest]}>
      <TaxAdvisorClientOverviewSection
        routeRef="former-clients"
        company={getClientCompanyRequest.data?.getFormerTaxAdvisorClientCompany}
        myTaxAdvisorCompany={getTaxAdvisorCompanyRequest.data?.getMyTaxAdvisorCompanyWithClients}
        allEditingLocked={true}
        useDracoonDirectly={true}
      />
    </RequestFeedbackHandler>
  );
};

export default TaxAdvisorFormerClientOverviewSectionContainer;
