import * as Yup from "yup";
import PasswordSchema from "./PasswordSchema";
import { TFunction } from "i18next";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";

export const UpdatePasswordSchema = (
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy | null | undefined,
  t: TFunction
) => {
  return Yup.object().shape({
    currentPassword: passwordPolicy
      ? PasswordSchema(passwordPolicy, t)
      : Yup.string().required(t("generic:validation-required")),
    password: passwordPolicy
      ? PasswordSchema(passwordPolicy, t)
      : Yup.string().required(t("generic:validation-required")),
    confirmPassword: Yup.string()
      .required(t("generic:validation-required"))
      .test("password-match", t("password:no_match"), function (value) {
        const { password } = this.parent;
        return password === value;
      })
  });
};
