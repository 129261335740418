import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  getWepaProcessingStatuses,
  getWepaProcessingStatusesVariables
} from "./graphql/getWepaProcessingStatuses";

export const GET_WEPA_PROCESSING_STATUSES = gql`
  query getWepaProcessingStatuses($companyWepaId: ID!) {
    getWepaProcessingStatuses(companyWepaId: $companyWepaId) {
      wepaId
      downloading {
        scheduled
        running
        completed
        failed
      }
      importing {
        scheduled
        running
        completed
        failed
      }
      generatingAnnual {
        scheduled
        running
        completed
        failed
      }
    }
  }
`;

export const GetWepaProcessingStatuses = (companyWepaId: string) =>
  useQuery<getWepaProcessingStatuses, getWepaProcessingStatusesVariables>(
    GET_WEPA_PROCESSING_STATUSES,
    {
      variables: { companyWepaId }
    }
  );
