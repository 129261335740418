import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { myPendingTasks } from "./graphql/myPendingTasks";

// language=GraphQL
const MY_PENDING_TASKS = gql`
  query myPendingTasks {
    myPendingTasks {
      company {
        id
        name
        legalForm
      }
      ref
    }
  }
`;

export const GetMyPendingTasks = () => useQuery<myPendingTasks>(MY_PENDING_TASKS);
