import React from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import CompanyHasMajorityShareholder from "./CompanyHasMajorityShareholder";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";

interface CompanyHasMajorityShareholderContainerProps extends StepComponentProps {}

const CompanyHasMajorityShareholderContainer = ({
  order,
  saveData,
  currentStep,
  controls
}: CompanyHasMajorityShareholderContainerProps) => {
  const selectedCompanyForLTB = QueryCompanyById(order.entityId ?? "").data?.companyById;

  const shareholders = selectedCompanyForLTB?.group.memberships ?? undefined;

  return (
    <CompanyHasMajorityShareholder
      order={order}
      saveData={saveData}
      currentStep={currentStep}
      controls={controls}
      shareholders={shareholders}
    />
  );
};

export default CompanyHasMajorityShareholderContainer;
