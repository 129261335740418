import gql from "graphql-tag";

export const GET_CLUB_DEAL_BY_ID = gql`
  query GetClubDealById($id: ID!) {
    getClubDealById(id: $id) {
      id
      minInvestmentAmount
      maxInvestmentAmount
      targetYield
      financedPercentage
      asset {
        id
        realEstate {
          id
          label
          image
          shortDescription
        }
      }
    }
  }
`;
