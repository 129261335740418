import React from "react";
import { useMutation } from "react-apollo";
import { useParams } from "react-router-dom";
import logger from "../../../common/Logger";
import Welcome from "./WelcomeForm";
import { CONFIRM_SHADOW_ACCOUNT } from "../../../lib/api/mutations/CONFIRM_SHADOW_ACCOUNT";
import { GET_ORDERS_BY_USER_ID } from "../../../lib/api/queries/GetOrdersByUserId";

export const WelcomeFormContainer = () => {
  const { token } = useParams<{ token: string }>();

  const [confirmShadowAccount, confirmShadowAccountRequest] = useMutation(CONFIRM_SHADOW_ACCOUNT);
  const [getOrdersByUserId, getOrdersByUserIdRequest] = useMutation(GET_ORDERS_BY_USER_ID);

  const confirmShadowAccountHandler = async (
    token,
    password
  ): Promise<{ updatedToken: string; userId: string } | undefined> => {
    let updatedToken;
    let userId;
    try {
      const confirmShadowAccountResponse = await confirmShadowAccount({
        variables: { verificationToken: token, password }
      });
      if (confirmShadowAccountResponse?.data.confirmShadowAccount?.token) {
        updatedToken = confirmShadowAccountResponse?.data.confirmShadowAccount.token;
        userId = confirmShadowAccountResponse?.data.confirmShadowAccount.id;
      } else {
        logger.errorMessage(`Account Password successfully created but no token returned`);
      }
    } catch (e) {
      logger.error(e);
    }

    return {
      updatedToken,
      userId
    };
  };

  const getNewClientDraftOrderHandler = async (
    userId: string
  ): Promise<{ orderId: string; submissionId: string } | undefined> => {
    let orderId;
    let submissionId;
    try {
      const orderResult = await getOrdersByUserId({
        variables: {
          id: userId
        }
      });

      if (orderResult?.data?.getOrdersByUserId) {
        orderId = orderResult.data.getOrdersByUserId.orders[0].id;
        submissionId = orderResult.data.getOrdersByUserId.orders[0].submissionId;
      }
      return { orderId, submissionId };
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <Welcome
      token={token}
      confirmShadowAccountHandler={confirmShadowAccountHandler}
      getNewClientFirstOrder={getNewClientDraftOrderHandler}
      requests={[confirmShadowAccountRequest, getOrdersByUserIdRequest]}
    />
  );
};
