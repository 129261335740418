import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import LoadingAlert from "./LoadingAlert";
import logger from "../../common/Logger";
import ErrorAlertLegacy from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { TFunction } from "i18next";
import { EnhancedMutation } from "../../common/EnhancedMutation";

interface DeleteWithConfirmationProps extends WithTranslation {
  title: string;
  successTitle: string;
  mutation: EnhancedMutation;
  variables: { [k: string]: any };
  onDone: () => void;
}

export const DeleteWithConfirmation = ({
  t,
  title,
  successTitle,
  mutation,
  variables,
  onDone
}: DeleteWithConfirmationProps) => {
  const [show, setShow] = useState(false);
  const handleShow = (e) => setShow(true);
  const handleHide = () => setShow(false);

  const [success, setSuccess] = useState(false);

  const [callMutation, { loading, error }] = useMutation(mutation.document, {
    variables,
    refetchQueries: mutation.refetches
  });

  const confirmDeletion = async () => {
    try {
      await callMutation();
      setSuccess(true);
    } catch (error) {
      logger.errorMessage(`Unable to delete an entity using ${mutation.document.loc?.source.body}`);
      logger.error(error);
    }
  };

  const handleDone = () => {
    handleHide();
    onDone();
  };

  return (
    <>
      <Button variant="outline-danger" data-testid="delete" onClick={handleShow}>
        {t("generic:delete")}
      </Button>

      <Modal
        show={show}
        onHide={handleHide}
        dialogClassName="modal-90w"
        aria-labelledby="delete-confirmation"
        data-testid="delete-modal">
        <Modal.Header closeButton>
          <Modal.Title id="delete-confirmation" data-testid="title">
            {success ? successTitle : title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <LoadingAlert t={t} loading={loading} />
            <ErrorAlertLegacy t={t} error={error?.message} />

            <Row className="justify-content-center">
              {success ? (
                <Done t={t} onDone={handleDone} />
              ) : (
                <ConfirmationForm t={t} handleHide={handleHide} confirmDeletion={confirmDeletion} />
              )}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

interface ConfirmationFormProps {
  t: TFunction;
  handleHide: () => void;
  confirmDeletion: () => Promise<void>;
}

const ConfirmationForm = ({ t, handleHide, confirmDeletion }: ConfirmationFormProps) => (
  <>
    <Col className="col-auto">
      <Button variant="outline-dark" data-testid="cancel" onClick={handleHide}>
        {t("generic:cancel")}
      </Button>
    </Col>

    <Col className="col-auto">
      <Button variant="danger" data-testid="confirm" onClick={confirmDeletion}>
        {t("generic:delete")}
      </Button>
    </Col>
  </>
);

interface DoneProps {
  t: TFunction;
  onDone: () => void;
}

const Done = ({ t, onDone }: DoneProps) => (
  <Col className="col-auto">
    <Button variant="primary" data-testid="ok" onClick={onDone}>
      {t("generic:ok")}
    </Button>
  </Col>
);

export default withTranslation(["generic"])(DeleteWithConfirmation);
