import gql from "graphql-tag";

export const GET_ORDERS_BY_USER_ID = gql`
  mutation getOrdersByUserId($id: ID!) {
    getOrdersByUserId(id: $id) {
      count
      orders {
        id
        submissionId
        productType
        status
        entityId
      }
    }
  }
`;
