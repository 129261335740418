import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { TaskStatesEnum } from "../../../../../global-query-types";
import { FiCheck } from "react-icons/fi";

export interface StepButtonProps extends ButtonProps {
  state: TaskStatesEnum | null;
  index: number;
  isOpen?: boolean;
}

export const StepButton = ({ state, index, isOpen = false, ...rest }: StepButtonProps) => {
  const hasIndex = state !== TaskStatesEnum.COMPLETED;
  const buttonText = `${index + 1}`;

  return (
    <Button
      data-testid="process-state-group-number"
      variant="outline-light"
      className={`step-button ${isOpen ? "step-button--open" : ""}`}
      {...rest}>
      {hasIndex && <span data-testid="index">{buttonText}</span>}
      {!hasIndex && <FiCheck size="25" data-testid="check-mark" />}
    </Button>
  );
};
