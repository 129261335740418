import React from "react";
import { TFunction, Trans, WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import { formatCurrency } from "common/formatters";
import { RideUrls } from "common/URLs";
import { ItemOrder, OrderSummary } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { Product } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormServerData";
import { EuroIcon } from "sharedComponents/icons/DesignSystemIcons/EuroIcon";
import { DiscountIcon } from "sharedComponents/icons/DesignSystemIcons/DiscountIcon";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

export const productName = (t: TFunction) => ({
  [Product.GS]: t("generic:product-card.gmbh-grundung.title"),
  [Product.AS]: t("generic:product-card.admin-service.title"),
  [Product.LTB]: t(rideBrokerT("generic:product-card.low-tax-broker.title"))
});

export const paymentPeriodicity = (t: TFunction) => ({
  [Product.GS]: t("generic:short-order-form.payment-periodicity.one-time"),
  [Product.AS]: t("generic:short-order-form.payment-periodicity.annual"),
  [Product.LTB]: t("generic:short-order-form.payment-periodicity.free-observation")
});

export interface OrderCostSummaryProps {
  orderSummary: OrderSummary;
  vat?: number;
}

export const OrderCostSummary = withTranslationReady(["generic"])(
  ({ t, orderSummary, vat }: OrderCostSummaryProps & WithTranslation) => {
    return (
      <div data-testid={"order-summary"} className={"short-order-form-order-summary"}>
        <Header t={t} />
        <CostsTable t={t} orderSummary={orderSummary} />
        {!!orderSummary.getTotalDiscount() && <DiscountInfo t={t} orderSummary={orderSummary} />}
        <DueAmount t={t} orderSummary={orderSummary} hasVAT={!!vat} />
        {!!vat && <VAT t={t} baseSum={orderSummary.getTotal()} vat={19} />}
      </div>
    );
  }
);

export const Header = ({ t }: { t: TFunction }) => (
  <div className={"short-order-form-order-summary__header"}>
    <EuroIcon data-testid={"order-summary-header-icon"} />
    <Typography
      data-testid={"order-summary-header-title"}
      category="Paragraph"
      size={100}
      weight={"Heavy"}>
      {t("generic:short-order-form.checkout.order-summary.title")}
    </Typography>
  </div>
);

const CostsTable = ({ t, orderSummary }: { t: TFunction; orderSummary: OrderSummary }) => {
  const tableHeader = [
    {
      name: "product-name",
      label: t("generic:short-order-form.checkout.order-summary.items-table.products")
    },
    {
      name: "payment-periodicity",
      label: t("generic:short-order-form.checkout.order-summary.items-table.payment-periodicity")
    },
    {
      name: "product-price",
      label: t("generic:short-order-form.checkout.order-summary.items-table.costs")
    }
  ];

  return (
    <div className={"short-order-form-order-summary__table"}>
      <div className={"short-order-form-order-summary__table-headers"}>
        {tableHeader.map((header) => (
          <TableHeader
            key={`order-summary-items-table-${header.name}`}
            name={header.name}
            label={header.label}
          />
        ))}
      </div>
      <span className={"short-order-form-order-summary__table-rows"}>
        {orderSummary.getItems().map((item) => (
          <CostEntry key={`order-summary-items-row-${item.product}`} t={t} item={item} />
        ))}
      </span>
    </div>
  );
};

export const TableHeader = ({ name, label }: { name: string; label: string }) => {
  return (
    <Typography
      className={`short-order-form-order-summary__table-header`}
      data-testid={`order-summary-items-table-${name}`}
      category="Paragraph"
      size={100}
      weight={"Heavy"}>
      {label}
    </Typography>
  );
};

const CostEntry = ({ t, item }: { t: TFunction; item: ItemOrder }) => {
  return (
    <React.Fragment key={`order-summary-items-row-${item.product}-${item.tier}`}>
      <div
        className={"short-order-form-order-summary__table-row"}
        data-testid={`order-summary-items-row-${item.product}`}>
        <Typography
          className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell`}
          data-testid={"order-summary-items-cell-product-name"}
          category="Paragraph"
          size={100}
          weight={"Heavy"}>
          {productName(t)[item.product]}
        </Typography>
        <Typography
          className={
            "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
          }
          data-testid={"order-summary-items-cell-payment-periodicity"}
          category="Paragraph"
          size={200}
          weight={"Light"}>
          {paymentPeriodicity(t)[item.product]}
        </Typography>
        <Typography
          className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell`}
          data-testid={"order-summary-items-cell-product-recurrent-price"}
          category="Paragraph"
          size={100}
          weight={"Heavy"}>
          {item.recurringPrice ? `${formatCurrency(item.recurringPrice, { fixed: 0 })}` : ""}
        </Typography>
      </div>
      {item.acquisitionPrice && (
        <div
          className={"short-order-form-order-summary__table-row"}
          data-testid={`order-summary-items-row-${item.product}-one-time-fee`}>
          <Typography
            className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell`}
            data-testid={"order-summary-items-cell-product-label-one-time-fee"}
            category="Paragraph"
            size={100}
            weight={"Heavy"}>
            {`${t(
              "generic:short-order-form.checkout.order-summary.items-table.product-label-for-one-time-fee"
            )}`}
          </Typography>
          <Typography
            className={
              "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
            }
            data-testid={"order-summary-items-cell-payment-periodicity-one-time-fee"}
            category="Paragraph"
            size={200}
            weight={"Light"}>
            {t("generic:short-order-form.payment-periodicity.one-time")}
          </Typography>
          <Typography
            className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell`}
            data-testid={"order-summary-items-cell-product-one-time-fee-value"}
            category="Paragraph"
            size={100}
            weight={"Heavy"}>
            {`${formatCurrency(item.acquisitionPrice, { fixed: 0 })}`}
          </Typography>
        </div>
      )}
      {item.product === Product.AS && !!item.numberOfAdditionalYears && (
        <div
          className={"short-order-form-order-summary__table-row"}
          data-testid={`order-summary-items-row-additional-years`}>
          <Typography
            className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell`}
            data-testid={"order-summary-items-cell-product-name"}
            category="Paragraph"
            size={100}
            weight={"Heavy"}>
            {t("generic:product-card.admin-service-additional-years.cost-summary.item-name", {
              years: item.numberOfAdditionalYears
            })}
          </Typography>
          <Typography
            className={
              "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
            }
            data-testid={"order-summary-items-cell-payment-periodicity"}
            category="Paragraph"
            size={200}
            weight={"Light"}>
            {t("generic:short-order-form.payment-periodicity.one-time")}
          </Typography>
          <Typography
            className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell`}
            data-testid={"order-summary-items-cell-product-recurrent-price"}
            category="Paragraph"
            size={100}
            weight={"Heavy"}>
            {item.recurringPrice
              ? `${formatCurrency(item.recurringPrice * item.numberOfAdditionalYears, {
                  fixed: 0
                })}`
              : ""}
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
};

const DiscountInfo = ({ t, orderSummary }: { t: TFunction; orderSummary: OrderSummary }) => {
  return (
    <div
      data-testid="order-summary-discount-row"
      className={"short-order-form-order-summary__discount-row"}>
      <div className={"short-order-form-order-summary__discount-label"}>
        <DiscountIcon />
        <Typography
          data-testid="checkout-step-discount-label"
          category="Paragraph"
          size={100}
          weight={"Light"}>
          {t("generic:order-form.discount")}
        </Typography>
      </div>
      <Typography
        className={
          "short-order-form-order-summary__discount-value short-order-form-order-summary__price-cell"
        }
        data-testid="checkout-step-discount-value"
        category="Paragraph"
        size={100}
        weight={"Light"}>
        {`-${formatCurrency(orderSummary.getTotalDiscount(), {
          fixed: 2
        })}`}
      </Typography>
    </div>
  );
};

const DueAmount = ({
  t,
  orderSummary,
  hasVAT
}: {
  t: TFunction;
  orderSummary: OrderSummary;
  hasVAT: boolean;
}) => {
  const orderedOnlyLTB = orderSummary.getItems().length === 1 && orderSummary.hasItem(Product.LTB);

  return (
    <div className="short-order-form-order-summary__due-amount">
      <span className="short-order-form-order-summary__due-amount-row">
        <Typography
          data-testid="checkout-step-due-amount-label"
          category="Headline"
          size={300}
          weight={"Heavy"}>
          {t(
            hasVAT
              ? "generic:short-order-form.checkout.order-summary.subtotal"
              : "generic:short-order-form.checkout.order-summary.due-amount"
          )}
        </Typography>
        <Typography
          className={`short-order-form-order-summary__price-cell ${
            orderedOnlyLTB ? "short-order-form-order-summary__due-amount-value-for-ltb" : ""
          }`}
          data-testid="checkout-step-due-amount-value"
          category="Headline"
          size={300}
          weight={"Heavy"}>
          {orderedOnlyLTB
            ? paymentPeriodicity(t)[Product.LTB]
            : `${formatCurrency(orderSummary.getTotal(), { fixed: 0 })}`}
        </Typography>
      </span>
      {orderSummary.getItems().length >= 2 && (
        <Typography
          className="short-order-form-order-summary__due-amount-observation"
          data-testid="checkout-step-due-amount-observation"
          category="Paragraph"
          size={300}
          weight={"Light"}>
          <Trans
            i18nKey="generic:short-order-form.checkout.order-summary.due-amount-observation"
            components={{
              pricesLink: (
                <TextLink to={RideUrls.Prices} variant={LinkVariant.secondary} target="_blank" />
              )
            }}
          />
        </Typography>
      )}
    </div>
  );
};

const VAT = ({ t, baseSum, vat }: { t: TFunction; baseSum: number; vat: number }) => {
  const vatAmount = baseSum * (vat / 100);
  return (
    <div className={"short-order-form-order-summary__vat"}>
      <div className={"short-order-form-order-summary__table"}>
        <Typography
          className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell`}
          data-testid={"vat-label"}
          category="Paragraph"
          size={100}
          weight={"Heavy"}>
          {t("generic:vat")}
        </Typography>
        <Typography
          className={
            "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
          }
          data-testid={"vat-value"}
          category="Paragraph"
          size={200}
          weight={"Light"}>
          {`${vat}%`}
        </Typography>
        <Typography
          className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell`}
          data-testid={"vat-amount"}
          category="Paragraph"
          size={100}
          weight={"Heavy"}>
          {formatCurrency(vatAmount)}
        </Typography>
      </div>

      <span className="short-order-form-order-summary__due-amount-row">
        <Typography
          data-testid="checkout-step-total-amount-label"
          category="Headline"
          size={300}
          weight={"Heavy"}>
          {t("generic:short-order-form.checkout.order-summary.total-amount")}
        </Typography>
        <Typography
          className={`short-order-form-order-summary__price-cell `}
          data-testid="checkout-step-total-amount-value"
          category="Headline"
          size={300}
          weight={"Heavy"}>
          {`${formatCurrency(baseSum + vatAmount, { fixed: 2 })}`}
        </Typography>
      </span>
    </div>
  );
};
