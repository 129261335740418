import React from "react";
import { useMutation } from "react-apollo";
import { UserService } from "lib/services/UserService/UserService";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import TwoFactorAuthenticationNotification from "./TwoFactorAuthenticationNotification";
import { UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT } from "lib/api/mutations/UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT";
import {
  UpdateAllowTwoFactorAuthPrompt,
  UpdateAllowTwoFactorAuthPromptVariables
} from "lib/api/mutations/graphql/UpdateAllowTwoFactorAuthPrompt";

const TwoFactorAuthenticationNotificationContainer = () => {
  const [updateAllowTwoFactorAuthPromptMutation] = useMutation<
    UpdateAllowTwoFactorAuthPrompt,
    UpdateAllowTwoFactorAuthPromptVariables
  >(UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT);

  const updateAllowTwoFactorAuth = async () => {
    const userId = UserService.instance?.meRequest?.data?.me?.id;

    if (userId) {
      await updateAllowTwoFactorAuthPromptMutation({
        variables: { userId }
      });
    }
  };

  return (
    <RequestFeedbackHandler requests={[]} noLoadingAnimation showChildrenWhileLoading>
      <TwoFactorAuthenticationNotification
        updateAllowTwoFactorAuthPrompt={updateAllowTwoFactorAuth}
      />
    </RequestFeedbackHandler>
  );
};

export default TwoFactorAuthenticationNotificationContainer;
