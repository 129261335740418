import * as Sentry from "@sentry/react";

enum LogLevel {
  FATAL = "FATAL",
  ERROR = "ERROR",
  WARN = "WARN",
  INFO = "INFO",
  DEBUG = "DEBUG",
  TRACE = "TRACE"
}

// TODO: Make global log level configurable

const createMessage = (level: LogLevel, message: string) => {
  const now = new Date();
  return (
    now.getUTCFullYear() +
    "-" +
    (now.getUTCMonth() + 1).toString().padStart(2, "0") +
    "-" +
    now.getUTCDate().toString().padStart(2, "0") +
    " " +
    now.getUTCHours() +
    ":" +
    now.getUTCMinutes() +
    ":" +
    now.getUTCSeconds() +
    "|" +
    level.toString() +
    "|" +
    message
  );
};

const writeToLog = (level: LogLevel, message: string) => {
  // TODO: THIS IS TEMPORARY AND WILL BE ENHANCE WITH UPLOADING LOGS TO THE SERVER
  if (level in [LogLevel.ERROR, LogLevel.FATAL]) {
    console.error(message);
  } else {
    console.log(message);
  }
};

class Logger {
  fatal(error: Error) {
    Sentry.captureException(error);
    writeToLog(
      LogLevel.FATAL,
      createMessage(LogLevel.FATAL, error.name + "|" + error.message + "\n" + error.stack)
    );
  }

  error(error: Error) {
    Sentry.captureException(error);
    writeToLog(
      LogLevel.ERROR,
      createMessage(LogLevel.ERROR, error.name + "|" + error.message + "\n" + error.stack)
    );
  }

  errorMessage(message: string) {
    writeToLog(LogLevel.ERROR, createMessage(LogLevel.ERROR, message));
  }

  warn(message: string) {
    Sentry.captureMessage(`WARN: ${message}`);
    writeToLog(LogLevel.WARN, createMessage(LogLevel.WARN, message));
  }

  info(message: string) {
    writeToLog(LogLevel.INFO, createMessage(LogLevel.INFO, message));
  }

  debug(message: string) {
    writeToLog(LogLevel.DEBUG, createMessage(LogLevel.DEBUG, message));
  }

  trace(message: string) {
    writeToLog(LogLevel.TRACE, createMessage(LogLevel.TRACE, message));
  }
}

const logger = new Logger();

export default logger;
