import React from "react";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { useParams } from "react-router";
import { useMutation } from "react-apollo";
import {
  UpdateCompanyById,
  UpdateCompanyByIdVariables
} from "lib/api/mutations/graphql/UpdateCompanyById";
import { UPDATE_COMPANY_BY_ID } from "../../../../lib/api/mutations/UPDATE_COMPANY_BY_ID";
import CompanyCommercialRegisterDateEdit from "./CompanyDateEdit";
import { QueryCompanyById } from "../../../../lib/api/queries/GetCompanyById";

interface CompanyCommercialRegisterDateEditContainerProps {
  fieldName: string;
  section: string;
}

export const CompanyDateEditContainer = ({
  fieldName,
  section
}: CompanyCommercialRegisterDateEditContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const companyRequest = QueryCompanyById(id);

  const [updateCompanyById, updateCompanyByIdRequests] = useMutation<
    UpdateCompanyById,
    UpdateCompanyByIdVariables
  >(UPDATE_COMPANY_BY_ID);
  const company = companyRequest.data?.companyById;
  const companyId = company?.id ?? "";

  const handleSave = async (value: string): Promise<void> => {
    await updateCompanyById({
      variables: {
        companyId,
        [fieldName]: value
      }
    });
  };

  return (
    <RequestFeedbackHandler requests={[companyRequest]} noLoadingAnimation={true}>
      {company && (
        <CompanyCommercialRegisterDateEdit
          requests={[updateCompanyByIdRequests]}
          onSave={handleSave}
          companyId={companyId}
          fieldName={fieldName}
          section={section}
          value={company[fieldName] ?? ""}
        />
      )}
    </RequestFeedbackHandler>
  );
};
