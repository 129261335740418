import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { Col, Row } from "react-bootstrap";
import RideInput from "../../../../../sharedComponents/FormControls/RideInput/RideInput";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { FormikProps } from "formik";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../../../uiLibrary/designSystem/RideButton/RideButton";

interface DiscountCodeSectionProps extends WithTranslation {
  formik: FormikProps<any>;
  verifyDiscountCode: (discountCode) => Promise<{ isValid: boolean; discountValue: number }>;
  setDiscountValue: (discountValue: number) => void;
  dataTestId?: string;
  useDesignSystem?: boolean;
}

export const DiscountCodeSection = withTranslationReady(["generic"])(
  ({
    formik,
    verifyDiscountCode,
    setDiscountValue,
    t,
    useDesignSystem = false,
    dataTestId = "discount-code-section"
  }: DiscountCodeSectionProps & WithTranslation) => {
    const [shouldDisableDiscountCodeInput, setShouldDisableDiscountCodeInput] = useState(false);
    const [isValidCode, setIsValidCode] = useState({});

    function checkForValidDiscountCode() {
      return isValidCode === false ? "order-form__input--error" : "order-form__input--success";
    }

    const verifyDiscountCodeHandler = async () => {
      const data = await verifyDiscountCode(formik.values["discountCode"]);
      let validation = data.isValid;
      if (validation) {
        setShouldDisableDiscountCodeInput(true);
        setIsValidCode(true);
        setDiscountValue(data.discountValue);
      } else {
        setShouldDisableDiscountCodeInput(false);
        setIsValidCode(false);
      }
    };

    const removeDiscountCodeHandler = () => {
      setShouldDisableDiscountCodeInput(false);
      setDiscountValue(0);
      formik.setFieldValue("discountCode", "");
    };

    return (
      <div data-testid={dataTestId} className={"order-form__discount-code"}>
        <Row
          className={`order-form__input-row ${useDesignSystem ? "order-form__input-row--v2" : ""}`}>
          <Col>
            <RideInput
              formik={formik}
              name="discountCode"
              label={t("generic:order-form.discount-code")}
              className={`order-form__input ${
                typeof isValidCode === "boolean" ? checkForValidDiscountCode() : ""
              }`}
              isDisabled={shouldDisableDiscountCodeInput}
            />
          </Col>
          {useDesignSystem ? (
            shouldDisableDiscountCodeInput ? (
              <RideButton
                type="button"
                size={RideButtonSize.BIG}
                variant={RideButtonVariant.SECONDARY}
                data-testid={"remove-discount-code-button-design-system"}
                onClick={removeDiscountCodeHandler}
                label={t("generic:order-form.remove-discount")}
              />
            ) : (
              <Col xs={"auto"}>
                <RideButton
                  type="button"
                  size={RideButtonSize.BIG}
                  variant={RideButtonVariant.SECONDARY}
                  data-testid={"verify-discount-code-button-design-system"}
                  onClick={verifyDiscountCodeHandler}
                  label={t("generic:order-form.apply-discount")}
                />
              </Col>
            )
          ) : shouldDisableDiscountCodeInput ? (
            <Col xs={"auto"}>
              <RideButtonDeprecated
                variant={ButtonVariant.Danger}
                data-testid="remove-discount-code-button"
                onClick={removeDiscountCodeHandler}>
                {t("generic:order-form.remove-discount")}
              </RideButtonDeprecated>
            </Col>
          ) : (
            <Col xs={"auto"}>
              <RideButtonDeprecated
                data-testid="verify-discount-code-button"
                type="button"
                onClick={verifyDiscountCodeHandler}>
                {t("generic:order-form.apply-discount")}
              </RideButtonDeprecated>
            </Col>
          )}
        </Row>
      </div>
    );
  }
);
