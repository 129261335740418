import React, { useEffect, useState } from "react";
import { WithTranslation } from "react-i18next";
import ButtonClose from "uiLibrary/v2/components/Buttons/ButtonClose/ButtonClose";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

interface SubchapterOrderLayoutProps extends WithTranslation {
  Content: React.ReactElement;
  title: string;
  onCloseModal: () => void;
  confirmCancelModalRef: React.MutableRefObject<{ trigger: () => Promise<void> } | undefined>;
  stepDisposition: number | undefined;
  totalSteps: number;
}

const SubchapterOrderLayout = ({
  title,
  Content,
  stepDisposition,
  totalSteps,
  onCloseModal,
  confirmCancelModalRef
}: SubchapterOrderLayoutProps) => {
  const [justAppeared, setJustAppeared] = useState(true);

  useEffect(() => {
    setJustAppeared(false);
  }, []);

  const contentClassName = [
    "subchapter-order-layout__content",
    justAppeared ? "subchapter-order-layout__content--just-appeared" : ""
  ].join(" ");

  const close = async () => {
    await confirmCancelModalRef.current?.trigger();
    onCloseModal();
  };

  return (
    <>
      <div className="subchapter-order-layout" data-testid="subchapter-modal">
        <div
          className="subchapter-order-layout__backdrop"
          data-testid="subchapter-modal-backdrop"
          onClick={close}
        />
        <div className={contentClassName} data-testid="subchapter-modal-content">
          <div
            className="subchapter-order-layout__progress-bar"
            data-testid="subchapter-progress-title">
            {`${title} ${stepDisposition ?? 0}/${totalSteps}`}
            <ButtonClose onClick={close} data-testid="subchapter-close-modal" />
          </div>
          {Content}
        </div>
      </div>
    </>
  );
};

export default withTranslationReady(["generic"])(SubchapterOrderLayout);
