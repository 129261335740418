import React from "react";
import { Container, Image } from "react-bootstrap";
import { AddIcon } from "../icons/add";
import { RideButtonDeprecated } from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface RideOverviewProps extends React.PropsWithChildren<{}> {
  imageSrc: string;
  title: string;
  subtitle?: string;
  sideContent?: React.ReactNode;
  onAdd?: () => void;
  buttonLabel?: string;
}

const RideOverview = ({
  title,
  subtitle,
  imageSrc,
  sideContent,
  onAdd,
  buttonLabel,
  children
}: RideOverviewProps) => {
  return (
    <div className="ride-overview">
      <div className="ride-overview__header">
        <Container className="ride-overview__header__container">
          <div className="ride-overview__header__column">
            <div className="ride-overview__header__title">{title}</div>
            {subtitle && <div className="ride-overview__header__subtitle">{subtitle}</div>}
            {onAdd && (
              <div className="ride-overview__header__button">
                <RideButtonDeprecated
                  data-testid="ride-overview-button"
                  className="ride-overview__header__button-override"
                  hasIcon={true}
                  onClick={onAdd}>
                  <AddIcon />
                  {buttonLabel}
                </RideButtonDeprecated>
              </div>
            )}
          </div>
          <Image className="ride-overview__header__icon" src={imageSrc} />
        </Container>
      </div>
      <Container className="ride-overview__content">
        {!!sideContent ? <div className="ride-overview__side">{sideContent}</div> : null}
        <div className="ride-overview__children">{children}</div>
      </Container>
    </div>
  );
};

export default RideOverview;
