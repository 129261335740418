import React from "react";
import InvestmentCard from "../../InvestmentCard/InvestmentCard";
import InvestmentCardGroup from "../InvestmentCardGroup/InvestmentCardGroup";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { myInvestments_myInvestments } from "../../../../../lib/api/queries/graphql/myInvestments";
import { toInvestmentCardPropsFromInvestment } from "../../../../../lib/dataAdapters/investment/investment";
import { FeatureFlag } from "../../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { AssetType, FeatureFlags } from "../../../../../global-query-types";

interface InvestmentsListProps extends WithTranslation {
  investments: myInvestments_myInvestments[];
  onSelectInvestment: (id: string) => void;
}

const InvestmentList = ({ investments, onSelectInvestment, t }: InvestmentsListProps) => {
  const cardPropsForInvestments = investments.map(toInvestmentCardPropsFromInvestment);

  const realEstateList = cardPropsForInvestments.filter(
    (investment) => investment.asset.type === AssetType.RealEstate
  );
  const companyList = cardPropsForInvestments.filter(
    (investment) => investment.asset.type === AssetType.Company
  );
  const brokerageAccountSetList = cardPropsForInvestments.filter(
    (investment) => investment.asset.type === AssetType.BrokerageAccountSet
  );

  return (
    <div className="investments-list" data-testid="investments-list">
      {realEstateList.length > 0 && (
        <InvestmentCardGroup
          title={t("client:investments.groups.realEstate")}
          iconSrc="/assets/investments/real-estate.svg">
          {realEstateList.map((investment, index) => (
            <InvestmentCard key={index} {...investment} onSelect={onSelectInvestment} />
          ))}
        </InvestmentCardGroup>
      )}

      {companyList.length > 0 && (
        <InvestmentCardGroup
          title={t("client:investments.groups.holdings")}
          iconSrc="/assets/investments/holding.svg">
          {companyList.map((investment, index) => (
            <InvestmentCard key={index} {...investment} onSelect={onSelectInvestment} />
          ))}
        </InvestmentCardGroup>
      )}

      <FeatureFlag name={FeatureFlags.TaxReporting}>
        {brokerageAccountSetList.length > 0 && (
          <InvestmentCardGroup
            title={t("client:investments.groups.securities")}
            iconSrc="/assets/investments/brokerageAccountSet.svg">
            {brokerageAccountSetList.map((props, index) => (
              <InvestmentCard
                key={index}
                {...props}
                onSelect={() => onSelectInvestment(props.id)}
              />
            ))}
          </InvestmentCardGroup>
        )}
      </FeatureFlag>
    </div>
  );
};

export default withTranslationReady("client")(InvestmentList);
