import { FolderIcon } from "../icons/FolderIcon";
import React from "react";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { ButtonExpand } from "../Buttons/ButtonExpand/ButtonExpand";
import { AddNewFileIcon } from "../icons/AddNewFileIcon";
import { FileSystemNodeProps } from "../FileSystemNode/FileSystemNode";

export interface FolderNodeProps
  extends Omit<FileSystemNodeProps, "entityId" | "entityType">,
    WithTranslation {
  openFileSelectPopUp?: () => void;
  onToggleExpand?: () => void;
  expanded?: boolean;
  className?: string;
}

const FolderNode = (props: FolderNodeProps) => {
  const { node, openFileSelectPopUp, readOnly, onToggleExpand, expanded, level, className, t } =
    props;
  const isEmpty = node.childNodes.length === 0;

  return (
    <div
      className={`${className} node-tree__folder-label node-tree__folder-label--level-${
        level ?? 0
      } ${isEmpty ? `node-tree__folder-label--empty` : ""}`}
      onClick={onToggleExpand}
      data-testid="node-folder">
      {!isEmpty ? <ButtonExpand expanded={expanded ?? false} /> : <div className="void-icon" />}
      <FolderIcon />
      {node.name}
      {isEmpty && <span className="node-tree__empty-label">({t("generic:empty")})</span>}

      {openFileSelectPopUp && !readOnly && (
        <div
          className="node-tree__upload"
          onClick={(e) => {
            e.stopPropagation();
            openFileSelectPopUp();
          }}>
          <AddNewFileIcon />
        </div>
      )}
    </div>
  );
};

export default withTranslationReady(["generic"])(FolderNode);
