import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "../../../components/generic/Table";
import { WithTranslation } from "react-i18next";
import { getAllWepaServices_getAllWepaServices } from "../../../lib/api/queries/graphql/getAllWepaServices";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import EntityLink from "../../../components/common/EntityLink/EntityLink";
import Timestamp from "../../../components/generic/Timestamp";
import { Link } from "react-router-dom";
import { WepaArchiveContainer } from "../WepaArchive/WepaArchiveContainer";

interface WepaListProps extends WithTranslation {
  wepaServices: getAllWepaServices_getAllWepaServices[] | null | undefined;
  refetch: () => any;
}

export const WepaList = ({ t, wepaServices, refetch }: WepaListProps) => {
  const columns = [
    {
      dataField: "company.contactPerson",
      text: t("wepa-service:list.person-name"),
      formatter: (contactPerson) => (
        <div data-testid="wepa-list-table-person-name-and-email">
          <div data-testid="person-name">
            <Link
              to={`/nominee-persons/${contactPerson.id}`}
              data-testid="person-link"
              className="text-reset text-underlined">
              {(contactPerson.firstName ?? "") + " " + (contactPerson.lastName ?? "")}
            </Link>
          </div>
          <div data-testid="person-email">{contactPerson.user?.email ?? ""}</div>
        </div>
      )
    },
    {
      dataField: "company",
      text: t("wepa-service:list.company-name"),
      formatter: (company) => <EntityLink membership={{ company }} testIdPrefix="wepa-list-table" />
    },
    {
      dataField: "createdAt",
      text: t("wepa-service:list.created-at"),
      formatter: (createdAt) => (
        <span data-testid="wepa-list-table-created-at">
          <Timestamp date={createdAt} />
        </span>
      )
    },
    {
      dataField: "currentStep",
      text: t("wepa-service:list.current-step"),
      formatter: (currentStep) => (
        <span data-testid="wepa-list-table-current-step">{currentStep}</span>
      )
    },
    {
      dataField: "actions",
      text: t("nominee-dashboard:company-creation.action"),
      formatter: (actions, wepaService) => (
        <WepaArchiveContainer t={t} wepaService={wepaService} refetch={refetch} />
      )
    }
  ];
  return (
    <Row data-testid="wepa-list-table">
      <Col>
        <Table keyField="id" tableStyle="greenTable" data={wepaServices || []} columns={columns} />
      </Col>
    </Row>
  );
};

export default withTranslationReady(["general", "wepa-service"])(WepaList);
