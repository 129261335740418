type HubSpotEvent = (string | (() => void))[];

export class HubSpotService {
  static instance: HubSpotService;

  get hubspotApi(): HubSpotEvent[] {
    window["_hsp"] = window["_hsp"] ?? [];
    return window["_hsp"];
  }

  register(eventName: string, callback: (params?: any) => void) {
    this.hubspotApi.push([eventName, callback]);
  }
}
