import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { PageLayout } from "sharedComponents/templates/PageLayout/PageLayout";
import { TaxAdvisorsListContainer } from "admin/components/TaxAdvisorsList/TaxAdvisorsListContainer";

const TaxAdvisorsScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("header:tax-advisors")}
      mainContent={<TaxAdvisorsListContainer t={t} />}
    />
  );
};

export default withTranslation(["header"])(TaxAdvisorsScene);
