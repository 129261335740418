import React from "react";

interface StatusIconProps {
  size?: number;
  cx?: number;
  cy?: number;
  r?: number;
  fill?: string;
  dataTestId?: string;
}

export const StatusIcon = ({
  size = 5,
  cx = 2.5,
  cy = 2.5,
  r = 2.5,
  fill = "#232323",
  dataTestId = "status-icon"
}: StatusIconProps) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={dataTestId}>
    <circle cx={cx} cy={cy} r={r} fill={fill} />
  </svg>
);
