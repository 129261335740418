import React, { useState } from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { FileEntityType, FileStorage } from "lib/ports/FileStorage";
import UploadFile from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/UploadFile/UploadFile";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import DoneButton, {
  SubchapterButtonArea
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/DoneButton/DoneButton";
import { AdminServiceOrderData } from "lib/models/client/AdminService/AdminServiceOrderData";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "../../../../../../lib/hooks/useRideFormik";
import {
  RideCheckbox,
  RideCheckboxVariant
} from "../../../../../../uiLibrary/components/RideCheckbox/RideCheckbox";

interface LastYearlyReportFileStepProps extends StepComponentProps<AdminServiceOrderData> {
  fileUploader: FileStorage;
}

const LastYearlyReportFileStep = ({
  t,
  order,
  currentStep,
  saveData,
  fileUploader
}: LastYearlyReportFileStepProps & WithTranslation) => {
  const [existingFile, setExistingFile] = useState(order.extra?.documents?.lastYearlyReport ?? {});

  const onSubmit = async (values) => {
    currentStep.complete();

    const lastYearlyReport = values["notApplicable"]
      ? { notApplicable: true }
      : { ...order.extra.documents?.lastYearlyReport };

    await saveData(
      {
        ...order.extra,
        documents: {
          ...order.extra.documents,
          lastYearlyReport
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );
  };

  const formik = useRideFormik({
    initialValues: {
      notApplicable: order.extra.documents?.lastYearlyReport?.notApplicable ?? undefined
    },
    onSubmit,
    validate(values) {
      return !existingFile.id && !values.notApplicable ? { error: "error" } : undefined;
    }
  });

  const uploadFile = async (file: File) => {
    const { fileId, checksum } = await fileUploader.uploadFileForEntity(
      FileEntityType.Order,
      order.id ?? "missing-id",
      file
    );

    let lastYearlyReportFile = {
      id: fileId,
      checksum,
      name: file.name,
      size: file.size
    };

    currentStep?.complete();
    formik.values["notApplicable"] = false;

    await saveData(
      {
        ...order.extra,
        documents: {
          ...order.extra.documents,
          lastYearlyReport: lastYearlyReportFile
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );

    setExistingFile(lastYearlyReportFile);
  };

  const deleteFile = async () => {
    currentStep.reOpen();
    await saveData(
      {
        ...order.extra,
        documents: {
          ...order.extra.documents,
          lastYearlyReport: undefined
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );

    setExistingFile({});
  };

  return (
    <FormikProvider value={formik}>
      <Form className="last-yearly-report__content subchapter-order-layout__form">
        <div className="subchapter-order-layout__scrollable">
          <UploadFile
            dataTestId={"last-yearly-report-file-uploader"}
            requirements={t("generic:admin-service-form.documents-step.upload-file.requirements")}
            uploadFile={uploadFile}
            existingFile={existingFile}
            deleteFile={deleteFile}
          />
          <RideCheckbox
            className={"last-yearly-report__not-applicable-checkbox"}
            data-testid={"not-applicable"}
            name={"notApplicable"}
            label={t("generic:admin-service-form.documents-step.upload-file.not-applicable")}
            variant={RideCheckboxVariant.Light}
          />
        </div>

        <SubchapterButtonArea>
          <DoneButton isDisabled={!existingFile.id && !formik.values["notApplicable"]} />
        </SubchapterButtonArea>
      </Form>
    </FormikProvider>
  );
};

export const LastYearlyReportFile = (fileUploader: FileStorage) =>
  withTranslationReady(["generic"])((props: StepComponentProps & WithTranslation) => (
    <LastYearlyReportFileStep {...props} fileUploader={fileUploader} />
  ));
