import { withTranslation, WithTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import TextLinkAsButton from "../../../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import { useHistory } from "react-router-dom";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { UserService } from "../../../../lib/services/UserService/UserService";
import { ApiFeedback } from "../../../../global-query-types";

interface ProfilePhoneCardProps extends WithTranslation {
  phoneNumber: string;
  hasTwoFactorAuth?: boolean;
  allowTwoFactorAuthPrompt?: boolean;
  updateAllowTwoFactorAuthPrompt: () => Promise<boolean | null | undefined>;
  resetTwoFactorAuth: () => Promise<ApiFeedback | null | undefined>;
}

const ProfilePhoneCard = ({
  t,
  phoneNumber,
  hasTwoFactorAuth,
  allowTwoFactorAuthPrompt,
  updateAllowTwoFactorAuthPrompt,
  resetTwoFactorAuth
}: ProfilePhoneCardProps) => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState = (setState: () => void) => {
    if (mountedRef.current) {
      setState();
    }
  };

  const [userHasTwoFactorAuth, setUserHasTwoFactorAuth] = useState<boolean>(
    hasTwoFactorAuth ?? false
  );

  const [allowedTwoFactorAuthPrompt, setAllowedTwoFactorAuthPrompt] = useState<boolean>(
    allowTwoFactorAuthPrompt ?? UserService.instance.currentUser?.allowTwoFactorAuthPrompt !== false
  );

  const onClickUpdateAllowTwoFactorAuthPrompt = async () => {
    const result = await updateAllowTwoFactorAuthPrompt();
    safeSetState(() => setAllowedTwoFactorAuthPrompt(result ?? true));
  };

  const onClickResetTwoFactorAuth = async () => {
    const result = await resetTwoFactorAuth();
    safeSetState(() => setUserHasTwoFactorAuth(result !== ApiFeedback.SUCCESS));
    safeSetState(() => setAllowedTwoFactorAuthPrompt(result === ApiFeedback.SUCCESS));
  };

  const history = useHistory();
  const handlePhoneNumberEdit = () => {
    history.push(`/2fa/edit`);
  };
  const handleTwoFAButtons = () => {
    if (!userHasTwoFactorAuth) {
      return (
        <RideButtonDeprecated
          data-testid={"profile-phone-number-card-two-fa-button"}
          variant={!allowedTwoFactorAuthPrompt ? ButtonVariant.Primary : ButtonVariant.Danger}
          size={ButtonSize.Small}
          className="float-right"
          onClick={onClickUpdateAllowTwoFactorAuthPrompt}>
          {!allowedTwoFactorAuthPrompt
            ? t("client:profile.phone.enableTwoFANotification")
            : t("client:profile.phone.disableTwoFANotification")}
        </RideButtonDeprecated>
      );
    } else {
      return (
        <RideButtonDeprecated
          data-testid={"profile-phone-number-card-reset-2fa-button"}
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          className="float-right"
          onClick={onClickResetTwoFactorAuth}>
          {t("client:profile.phone.resetTwoFA")}
        </RideButtonDeprecated>
      );
    }
  };

  return (
    <RideSectionCard
      title={t("client:profile.phone.title")}
      data-testid="profile-phone-number-card"
      fixed
      buttonSlot={handleTwoFAButtons()}>
      <div data-testid="profile-phone-card-phone-number">
        <div
          data-testid="profile-details-phone-card-phone-label"
          className="profile-details-phone-card__phone">
          {t("client:profile.phone.phoneNumberLabel")}
        </div>
        {phoneNumber}
        <div className="profile-details-phone-card__phone-edit">
          <TextLinkAsButton
            onClick={handlePhoneNumberEdit}
            data-testid="profile-phone-number-card-edit"
          />
        </div>
      </div>
    </RideSectionCard>
  );
};

export default withTranslation(["client"])(ProfilePhoneCard);
