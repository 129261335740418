import React from "react";
import { useMutation } from "react-apollo";
import { useHistory, useParams } from "react-router";
import {
  ConfirmEmailUpdate,
  ConfirmEmailUpdateVariables
} from "../../../lib/api/mutations/graphql/ConfirmEmailUpdate";
import { CONFIRM_EMAIL_UPDATE } from "../../../lib/api/mutations/CONFIRM_EMAIL_UPDATE";
import EmailUpdateConfirmation from "./EmailUpdateConfirmation";
import { useQuery } from "@apollo/react-hooks";
import { GET_EMAIL_BY_TOKEN } from "../../../lib/api/queries/GetEmailByToken";
import {
  GetEmailByToken,
  GetEmailByTokenVariables
} from "../../../lib/api/queries/graphql/GetEmailByToken";
import { storeToken } from "../../../common/Authentication/storeToken";
import { UserService } from "../../../lib/services/UserService/UserService";

export const EmailUpdateConfirmationContainer = () => {
  const history = useHistory();
  const { tokenId } = useParams<{ tokenId: string }>();

  const [confirmEmailUpdate, confirmEmailUpdateRequest] = useMutation<
    ConfirmEmailUpdate,
    ConfirmEmailUpdateVariables
  >(CONFIRM_EMAIL_UPDATE);

  const getEmailByToken = useQuery<GetEmailByToken, GetEmailByTokenVariables>(GET_EMAIL_BY_TOKEN, {
    variables: { changedEmailToken: tokenId }
  });

  if (!getEmailByToken.called && !getEmailByToken.loading) {
    return null;
  }

  const handleGoToHomepage = async () => {
    history.replace("/");
  };

  const handleSubmit = async (password) => {
    let result;

    try {
      result = await confirmEmailUpdate({
        variables: { changedEmailToken: tokenId, password }
      });
    } catch {
      return;
    }

    if (!result.data?.confirmEmailUpdate?.token) {
      return;
    }

    storeToken(result.data?.confirmEmailUpdate?.token, true);
    await UserService.instance?.meRequest?.refetch();
    history.replace("/client/my-profile?newEmail=1");
  };

  let newEmail = getEmailByToken.data?.getEmailByToken?.email;

  if (!newEmail && !getEmailByToken.error) {
    return null;
  }

  return (
    <EmailUpdateConfirmation
      passwordValidationRequest={confirmEmailUpdateRequest}
      tokenValidationRequest={getEmailByToken}
      newEmail={newEmail ?? ""}
      onSubmit={handleSubmit}
      onGoToHomePage={handleGoToHomepage}
    />
  );
};
