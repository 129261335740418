import React, { PropsWithChildren } from "react";
import ButtonClose from "uiLibrary/v2/components/Buttons/ButtonClose/ButtonClose";

export interface RideFormLayoutProps extends PropsWithChildren<{}> {
  title?: string;
  closePageHandler?: () => void;
  leftSidebarContent?: React.ReactElement;
  rightSidebarContent?: React.ReactElement;
}

const RideFormLayout = ({
  title,
  children,
  closePageHandler,
  rightSidebarContent,
  leftSidebarContent
}: RideFormLayoutProps) => {
  const module = "ride-form-layout";

  return (
    <div className={module}>
      <LayoutHeader module={module} title={title} closePageHandler={closePageHandler} />
      <div className={`${module}__body`}>
        {leftSidebarContent && (
          <div data-testid="left-sidebar-content" className={`${module}__chapters`}>
            {leftSidebarContent}
          </div>
        )}
        <div className={`${module}__content`}>{children}</div>
        {rightSidebarContent && (
          <div data-testid="right-sidebar-content" className={`${module}__contextual-help`}>
            {rightSidebarContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default RideFormLayout;

const LayoutHeader = ({
  module,
  title,
  closePageHandler
}: {
  module: string;
  title?: string;
  closePageHandler?: () => void;
}) => (
  <div className={`${module}__topbar`}>
    <div className={`${module}__logo`}>
      <div className={`${module}__logo-image`} />
    </div>
    {title && (
      <div data-testid="title" className={`${module}__title`}>
        {title}
      </div>
    )}
    {closePageHandler && (
      <div data-testid="close-page-button" className={`${module}__close`}>
        <ButtonClose onClick={closePageHandler} />
      </div>
    )}
  </div>
);
