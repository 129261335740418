import { formatCurrency } from "common/formatters";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { CircleWithArrowIcon } from "../../../../sharedComponents/icons/CircleWithArrowIcon";
import { CircleWithArrowIconInverted } from "../../../../sharedComponents/icons/CircleWithArrowIconInverted";
import { PublishType } from "../../../../global-query-types";

export interface ClubDealCardProps {
  id: string;
  name: string;
  image?: string;
  targetReturn: number;
  minInvestment: number;
  maxInvestment: number;
  alreadyFinanced: number;
  status?: PublishType | null;
  onClick?: () => void;
}

export interface ClubDealCardPropsWithTranslation extends ClubDealCardProps, WithTranslation {}

const ClubDealCard = (props: ClubDealCardPropsWithTranslation) => {
  const {
    name,
    image,
    targetReturn,
    minInvestment,
    maxInvestment,
    alreadyFinanced,
    status,
    onClick,
    t
  } = props;
  const imageStyle = image ? { backgroundImage: `url(${image})` } : {};

  const isCardEnabled = (
    alreadyFinanced: number,
    status: PublishType | null | undefined
  ): boolean => {
    return alreadyFinanced !== 100 && status !== PublishType.Completed;
  };

  const prepareCardClassname = (style: string): string => {
    const enabledCardStyle = style;
    const disabledCardStyle = `${style}--disabled`;
    if (isCardEnabled(alreadyFinanced, status)) return enabledCardStyle;
    return `${enabledCardStyle} ${disabledCardStyle} `;
  };

  return (
    <div
      onClick={onClick}
      className={prepareCardClassname("club-deal-card")}
      data-testid={`club-deal-card-${name}`}>
      <div className="club-deal-card__overlay">
        <div
          className={prepareCardClassname("club-deal-card__completion")}
          data-testid="funding-status">
          {alreadyFinanced === 100 && t("client:investments.clubDeal.fullyFunded")}
          {alreadyFinanced !== 100 &&
            t("client:investments.clubDeal.alreadyFinanced", {
              alreadyFinanced: alreadyFinanced.toFixed(0)
            })}
        </div>
      </div>
      <div
        className={`${prepareCardClassname("club-deal-card__image")} ${
          image ? "" : "club-deal-card__image--no-image"
        }`}
        data-testid="card-image"
        style={imageStyle}
      />

      <div className="club-deal-card__body" data-testid="card-body">
        <div className={prepareCardClassname("club-deal-card__name")} data-testid="card-name">
          {name}
        </div>
        <div className="club-deal-card__details">
          <div className={prepareCardClassname("club-deal-card__content")}>
            <div className="club-deal-card__field" data-testid="target-return">
              <span className="club-deal-card__field-name">
                {t("client:investments.clubDeal.targetReturn")}:
              </span>
              <span className="club-deal-card__field-value"> {targetReturn.toFixed(0)}% p.a.</span>
            </div>
            <div className="club-deal-card__field" data-testid="investment-range">
              <span className="club-deal-card__field-name">
                {t("client:investments.clubDeal.yourInvestment")}:
              </span>
              <span className="club-deal-card__field-value">
                {" "}
                {`${formatCurrency(minInvestment)}`} - {`${formatCurrency(maxInvestment)}`}
              </span>
            </div>
          </div>
          <div
            data-testid="card-arrow-icon"
            className={prepareCardClassname("club-deal-card__arrow-icon")}>
            <CircleWithArrowIcon className="default-icon" />
            <CircleWithArrowIconInverted className="hover-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady("client")(ClubDealCard);
