export const RideBaseUrl = "https://www.ride.capital";

export const RideUrls = {
  Impressum: `${RideBaseUrl}/impressum?utm_source=app&utm_medium=app`,
  PrivacyPolicy: `${RideBaseUrl}/datenschutz?utm_source=app&utm_medium=app`,
  TermsAndConditions: `${RideBaseUrl}/agb?utm_source=app&utm_medium=app`,
  DiscordCommunityInviteURL: "https://discord.gg/Xe37Vt88GV?utm_source=app&utm_medium=app",
  Prices: `${RideBaseUrl}/preise?utm_source=app&utm_medium=app`,
  Dracoon: `https://ride.dracoon.team?utm_source=ride_app&utm_medium=ride_app`
};
