import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { AddExistingShareholderContainer } from "../../../components/nominee/common/roles/AddExistingShareholderContainer";
import EditRoles from "../../../components/nominee/common/roles/EditRoles";
import RolesSectionViewMode from "./RolesSection.partials";
import { FeatureFlags, SearchTypeEnum } from "../../../global-query-types";
import {
  RoleEntry,
  shareholderListValidation
} from "../../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import { RolesSectionValidationSchema } from "../../../lib/validation/RolesSectionValidationSchema";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import RideTableActions from "../../../sharedComponents/RideTableActions/RideTableActions";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { useSortBehavior } from "../../../sharedComponents/useSimpleSortBehaviour/useSortBehavior";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";

interface RolesSectionProps extends WithTranslation {
  roles: RoleEntry[] | null;
  handleSubmit: (values: { roles: RoleEntry[] }) => Promise<void>;
  onAddExternalManagingDirector: () => Promise<void>;
  onAddEmptyCompanyShareholder: () => Promise<void>;
  onAddExistingShareholder: (
    type: SearchTypeEnum,
    entityId: string,
    companyId: string
  ) => Promise<void>;
  addHoldingCompany: React.ReactNode;
  companyId: string;
}

const RolesSection = ({
  t,
  roles,
  handleSubmit,
  onAddEmptyCompanyShareholder,
  onAddExternalManagingDirector,
  addHoldingCompany,
  companyId,
  onAddExistingShareholder
}: RolesSectionProps) => {
  const [data, setData] = useState(roles || []);
  const { sortedData, sortCallback } = useSortBehavior(data);
  const [editMode, setEditMode] = useState(false);
  const [validationWarningMessage, setValidationWarningMessage] = useState<null | string>(null);
  const [isAddExistingShareholderContainerClicked, addExistingShareholderContainerClicked] =
    useState(false);

  useEffect(() => {
    setData(roles || []);
  }, [roles]);

  const onCancelClick = () => {
    setEditMode(false);
  };

  const onEdit = () => {
    setEditMode(true);
  };

  const onSubmit = async (values) => {
    values.roles.forEach((role) => {
      role.votes = role.votes ?? 0;
    });
    await handleSubmit(values);
    setEditMode(false);
  };

  const onAddExistingShareholderClick = (isVisible) => {
    addExistingShareholderContainerClicked(isVisible);
  };

  const onAddExistingShareholderSubmit = async (
    type: SearchTypeEnum,
    entityId: string,
    companyId: string
  ) => {
    await onAddExistingShareholder(type, entityId, companyId);
    setEditMode(false);
    addExistingShareholderContainerClicked(false);
  };

  const formik = useFormik({
    initialValues: { roles: sortedData },
    onSubmit,
    enableReinitialize: true,
    validationSchema: RolesSectionValidationSchema,
    validate: (values) => {
      const message = shareholderListValidation(values.roles);

      setValidationWarningMessage(message ? t(message) : "");
    }
  });

  const columns = [
    { name: "name", label: t("nominee-dashboard:company.shareholder-entity") },
    { name: "isKycRequired", label: t("nominee-dashboard:company.status-kyc") },
    { name: "is-director", label: t("nominee-dashboard:company.is-director") },
    {
      name: "is-silent-partner",
      label: t("nominee-dashboard:company.is-silent-partner")
    },
    { name: "share", label: t("nominee-dashboard:company.shareholder-share") },
    { name: "votes", label: t("nominee-dashboard:company.shareholder-votes") }
  ];

  if (FeatureFlagService.instance.isEnabled(FeatureFlags.TransferCompanyOwnership)) {
    columns.push({ name: "", label: t("nominee-dashboard:company.is-owner") });
  }

  return (
    <div
      className={`roles-section ${
        validationWarningMessage && editMode ? "roles-section--with-warning" : ""
      } ${editMode ? "roles-section--edit" : ""}`}
      data-testid="roles-section">
      <FormikProvider value={formik}>
        <Form>
          <RideTableHeader
            columns={columns}
            sortCallback={sortCallback}
            isColumnsDisplay={!sortedData || sortedData.length === 0 ? false : true}
            headerAction={
              !editMode ? (
                <RideButtonDeprecated
                  data-testid="roles-edit-button"
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Small}
                  onClick={onEdit}
                  type="button">
                  {t("generic:edit")}
                </RideButtonDeprecated>
              ) : undefined
            }
            title={t("nominee-dashboard:company.roles")}
          />
          {editMode ? (
            <>
              <EditRoles roles={sortedData} formik={formik} />
              <div className="roles-section__extraActions">
                {!isAddExistingShareholderContainerClicked && (
                  <>
                    <RideButtonDeprecated
                      type="button"
                      className="mr-2 mb-2"
                      data-testid="roles-add-empty-company"
                      variant={ButtonVariant.Primary}
                      size={ButtonSize.Small}
                      onClick={onAddEmptyCompanyShareholder}>
                      <strong> {t("nominee-dashboard:roles.add-empty-company-shareholder")}</strong>
                    </RideButtonDeprecated>

                    <RideButtonDeprecated
                      type="button"
                      className="mr-2 mb-2"
                      data-testid="roles-add-external-managing-director"
                      variant={ButtonVariant.Primary}
                      size={ButtonSize.Small}
                      onClick={onAddExternalManagingDirector}>
                      <strong>{t("nominee-dashboard:roles.add-external-managing-director")}</strong>
                    </RideButtonDeprecated>
                  </>
                )}

                <AddExistingShareholderContainer
                  onAddExistingShareholderClick={onAddExistingShareholderClick}
                  companyId={companyId}
                  onAddExistingShareholder={onAddExistingShareholderSubmit}
                />

                {!isAddExistingShareholderContainerClicked && addHoldingCompany}
              </div>

              <RideTableActions
                onCancelClick={onCancelClick}
                validationWarningMessage={validationWarningMessage}
              />
            </>
          ) : (
            <RolesSectionViewMode roles={sortedData} />
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(RolesSection);
