import React from "react";
import { Form, FormikProps, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";

import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { StepHeading } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import {
  DropdownSelectOption,
  RideDropdownSelect
} from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { SecurityQuestionsSchema } from "lib/validation/SecurityQuestionsSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { QuestionNumber } from "lib/models/client/LowTaxBroker/SecurityQuestionType";
import { getSecurityQuestionOptions } from "./SecurityQuestionsStep.partials";

const SecurityQuestionsStep = ({
  currentStep,
  saveData,
  order,
  controls,
  t
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const initialValues = {
    "first-question": order.extra.securityQuestions?.first?.question,
    "first-answer": order.extra.securityQuestions?.first?.answer,
    "second-question": order.extra.securityQuestions?.second?.question,
    "second-answer": order.extra.securityQuestions?.second?.answer,
    "third-question": order.extra.securityQuestions?.third?.question,
    "third-answer": order.extra.securityQuestions?.third?.answer
  };

  async function onSubmit(values) {
    currentStep.complete();

    await saveData({
      ...order.extra,
      securityQuestions: {
        first: {
          question: values["first-question"],
          answer: values["first-answer"]
        },
        second: {
          question: values["second-question"],
          answer: values["second-answer"]
        },
        third: {
          question: values["third-question"],
          answer: values["third-answer"]
        }
      },
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: SecurityQuestionsSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.security-questions.title")} />
        <SecurityQuestionInput formik={formik} questionNumber={QuestionNumber.First} />
        <SecurityQuestionInput formik={formik} questionNumber={QuestionNumber.Second} />
        <SecurityQuestionInput formik={formik} questionNumber={QuestionNumber.Third} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SecurityQuestionsStep);

interface SecurityQuestionInputProps extends WithTranslation {
  formik: FormikProps<any>;
  questionNumber: QuestionNumber;
}

const SecurityQuestionInput = withTranslationReady(["generic"])(
  ({ t, formik, questionNumber: thisQuestionNumber }: SecurityQuestionInputProps) => {
    const baseOptions = getSecurityQuestionOptions(t);

    const options = removeOptions(
      baseOptions,
      getOtherSelectedQuestions(formik, thisQuestionNumber)
    );

    return (
      <div className="security-question">
        <RideDropdownSelect
          name={`${thisQuestionNumber}-question`}
          label={t(`generic:ride-broker-onboarding.security-questions.${thisQuestionNumber}`)}
          options={options}
          className="security-question__select"
          placeholder={t("generic:ride-broker-onboarding.security-questions.question-placeholder")}
          isSearchable
        />
        <RideTextInput name={`${thisQuestionNumber}-answer`} placeholder={t("generic:answer")} />
      </div>
    );
  }
);

const getOtherSelectedQuestions = (
  formik: FormikProps<any>,
  questionNumber: QuestionNumber
): string[] => {
  return Object.values(QuestionNumber)
    .filter((n) => n !== questionNumber)
    .map((otherQuestionNumber) => formik.values[`${otherQuestionNumber}-question`]);
};

const removeOptions = (
  options: DropdownSelectOption[],
  toRemove: string[]
): DropdownSelectOption[] => {
  return options.filter((option) => !toRemove.includes(option.value));
};
