import { UserRole } from "global-query-types";
import { PrivateRoute } from "sharedComponents/PrivateRoute/PrivateRoute";

export const checkAuthorization = (meData) => meData?.me?.role === UserRole.ThirdPartyTaxAdvisor;

export const ThirdPartyTaxAdvisorRoute = ({ component, ...rest }) =>
  PrivateRoute({
    component,
    hasAuthorization: checkAuthorization,
    ...rest
  });

export default ThirdPartyTaxAdvisorRoute;
