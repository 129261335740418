import { Col, Container, Row } from "react-bootstrap";
import ReturnLinkWithChevron from "../../../../sharedComponents/ReturnLinkWithChevron/ReturnLinkWithChevron";
import React from "react";
import ButtonEdit from "sharedComponents/Buttons/ButtonEdit/ButtonEdit";
import { RideCardOwnership } from "sharedComponents/RideCardOwnership/RideCardOwnership";
import BankIcon from "../BankIcon/BankIcon";
import { BankType } from "../../../../global-query-types";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";

export interface BankAccountDetailsHeaderSectionProps extends WithTranslation {
  bank: BankType;
  name: string;
  balance: string;
  iban: string;
  companyName: string;
}

const BankAccountDetailsHeaderSection = ({
  bank,
  name,
  balance,
  iban,
  companyName,
  t
}: BankAccountDetailsHeaderSectionProps) => {
  return (
    <Container data-testid="header-section" className={"bank-account-details"} fluid>
      <Container className="bank-account-details__container">
        <Row noGutters>
          <Col>
            <ReturnLinkWithChevron data-testid="back-link" to="/client/banking" />
          </Col>
        </Row>
        <div className="bank-account-details__first-line">
          <BankIcon bank={bank} className="bank-account-details__bank-icon" />
          <div
            data-testid="bank-account-name"
            className={"bank-account-details__bank-account-name"}>
            {name}
          </div>
          <ButtonEdit data-testid="bank-account-edit" onClick={() => {}} variant={"light"} />
        </div>
        <div className="bank-account-details__properties">
          <div data-testid="bank-account-balance" className="bank-account-details__property">
            <div data-testid="bank-account-balance-title">
              {t("generic:bank-account-details-header-section:titles:balance")}
            </div>
            <span className="bank-account-details__property-value bank-account-details__property-value--balance">
              {balance}
            </span>
          </div>
          <div data-testid="bank-account-iban" className="bank-account-details__property">
            <div data-testid="bank-account-iban-title">
              {t("generic:bank-account-details-header-section:titles:iban")}
            </div>
            <span className="bank-account-details__property-value">{iban}</span>
          </div>
          <div data-testid="bank-account-company-name" className="bank-account-details__property">
            <div data-testid="bank-account-company-name-title">
              {t("generic:bank-account-details-header-section:titles:company-name")}
            </div>
            <div className="bank-account-details__property-value--company-name">
              <RideCardOwnership ownership={[companyName]} />
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default withTranslationReady(["generic"])(BankAccountDetailsHeaderSection);
