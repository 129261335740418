import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import { InvoiceField } from "../InvoiceFields/InvoiceFields";
import { isAdminServiceUser, isWePaCompany } from "lib/types/clients/categories";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";

interface ServicesOrderedSectionProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
}

const ServicesOrderedSection = ({ t, company }: ServicesOrderedSectionProps) => {
  const hasCompletedWepaDirectOrder = !!company.orders.find(
    (o) => o.productType === "WepaDirect" && o.status === "Completed"
  );

  return (
    <RideSectionCard
      title={t("nominee-dashboard:company.invoice.services-ordered.title")}
      data-testid="services-ordered-section">
      <InvoiceField
        data-testid="service-ordered-admin-service"
        label={t("nominee-dashboard:company.invoice.services-ordered.admin-service")}
        value={isAdminServiceUser(company) ? t("generic:yes") : t("generic:no")}
      />
      <InvoiceField
        data-testid="service-ordered-wepa-service"
        label={t("nominee-dashboard:company.invoice.services-ordered.wepa-service")}
        value={
          isWePaCompany(company) || hasCompletedWepaDirectOrder ? t("generic:yes") : t("generic:no")
        }
      />
    </RideSectionCard>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(ServicesOrderedSection);
