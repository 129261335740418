import React, { useCallback, useEffect, useState } from "react";
import { PortfolioDataCompletenessTask } from "./PortfolioDataCompletenessTask";
import { GetWepaPortfolioWithDataAvailability } from "lib/api/queries/GetWepaPortfolioWithDataAvailability";
import { GetWepaFileImportById } from "lib/api/queries/GetWepaFileImportById";
import { GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany } from "lib/api/administration/queries/graphql/GetEndOfYearTasksByCompany";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";

interface Props {
  index: number;
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany;
}

export const PortfolioDataCompletenessTaskContainer = ({ index, task }: Props) => {
  const {
    portfolio,
    fileImport,
    isLoading,
    error: portfolioError,
    refreshFileImport
  } = usePortfolioDataCompleteness(task);
  const { uploadFile, isUploading, error: uploadError } = useUploadFile();

  async function onSelectFileUpload(file: File) {
    await uploadFile(task.companyId, portfolio?.broker!, task.id, file);
  }

  return (
    <PortfolioDataCompletenessTask
      index={index}
      isLoading={isLoading}
      isUploading={isUploading}
      uploadError={uploadError}
      portfolioError={portfolioError}
      task={task}
      portfolio={portfolio}
      importStatus={fileImport}
      onSelectFileUpload={onSelectFileUpload}
      onCloseTask={() => {}}
      onClosePortfolio={() => {}}
      onRefresh={refreshFileImport}
    />
  );
};

const usePortfolioDataCompleteness = (
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany
) => {
  const {
    data: portfolio,
    error: portfolioError,
    loading: loadingPortfolio
  } = GetWepaPortfolioWithDataAvailability({
    companyId: task.companyId,
    portfolioId: parseInt(task.referenceId!),
    fiscalYearStart: task.fiscalYearStart,
    fiscalYearEnd: task.fiscalYearEnd
  });

  const importId = task.extra?.uploadId as string | undefined;

  const {
    data: fileImport,
    error: fileImportError,
    loading: loadingFileImport,
    refetch
  } = GetWepaFileImportById({
    companyId: task.companyId,
    fileImportId: importId!,
    options: {
      skip: !importId
    }
  });

  const refreshFileImport = useCallback(
    () =>
      refetch({
        companyId: task.companyId,
        fileImportId: importId!
      }),
    [task.companyId, importId, refetch]
  );

  const importStatus = fileImport?.getWepaFileImportById?.status;
  const isFileImportDataLoaded = !!fileImport || !!fileImportError;
  const isLoadingFileStatus = importId && !isFileImportDataLoaded ? loadingFileImport : false;
  const isLoading = loadingPortfolio || isLoadingFileStatus;

  useEffect(
    function fileImportPolling() {
      const refreshIntervalByStatus = {
        PENDING_IMPORT: 60 * 1000,
        IMPORT_IN_PROGRESS: 10 * 1000,
        JOB_STALLED: 60 * 1000
      };
      const status = fileImport?.getWepaFileImportById?.status || "";
      const refreshInterval = refreshIntervalByStatus[status];

      if (refreshInterval) {
        const interval = setInterval(refreshFileImport, refreshInterval);
        return () => clearInterval(interval);
      }
    },
    [importStatus, fileImport, refreshFileImport]
  );

  return {
    portfolio: (portfolio && portfolio.getWepaPortfolio) || undefined,
    fileImport: (fileImport && fileImport.getWepaFileImportById) || undefined,
    isLoading,
    error: portfolioError || fileImportError,
    refreshFileImport
  };
};

const useUploadFile = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<{ httpStatus: number } | undefined>(undefined);

  async function uploadFile(companyId: string, broker: string, taskId: string, file: File) {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("taskId", taskId);

    setIsUploading(true);

    try {
      const response = await fetch(`${getEndpoint()}/wepa/import/${companyId}/${broker}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body: formData,
        mode: "cors",
        credentials: "include"
      });

      if (!response.ok) {
        setError({ httpStatus: response.status });
        return;
      }

      const responseData = (await response.json()) as { uploadId: string };

      return responseData;
    } catch {
      setError({ httpStatus: 0 });
    }

    setIsUploading(false);
  }

  return {
    isUploading,
    error,
    uploadFile
  };
};
