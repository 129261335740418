import React, { FC } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { GetAdvisedPersonQuery } from "../../Queries";
import TaxAdvisorPersonDetails from "./TaxAdvisorPersonDetails";

interface TaxAdvisorPersonDetailsContainerProps extends WithTranslation {}

const TaxAdvisorPersonDetailsContainer: FC<TaxAdvisorPersonDetailsContainerProps> = () => {
  const { id } = useParams<{ id: string }>();
  const getRequest = GetAdvisedPersonQuery(id);
  const data = getRequest.data?.getAdvisedPerson;

  return (
    <RequestFeedbackHandler requests={[getRequest]}>
      {data && <TaxAdvisorPersonDetails data={data} />}
    </RequestFeedbackHandler>
  );
};

export default withTranslation(["generic", "tax-service", "nominee-dashboard"])(
  TaxAdvisorPersonDetailsContainer
);
