import React from "react";
import { useHistory } from "react-router";
import { WithTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import CompanyTextFieldEditContainer from "../../components/Company/CompanyTextFieldEdit/CompanyTextFieldEditContainer";
import { vatNumberValidation } from "lib/validation/VatNumberSchema";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface CompanyTaxNumberSceneProps extends WithTranslation {}

const CompanyTaxNumberScene = ({ t }: CompanyTaxNumberSceneProps) => {
  const { goBack } = useHistory();
  const validation = vatNumberValidation(t);

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.vatNumber")} />
      <ModalFormLayout title={t("client:company.edit.vatNumber")} onBack={goBack}>
        <CompanyTextFieldEditContainer
          fieldName={"vatNumber"}
          section="numbers-and-ids"
          validation={validation}
        />
      </ModalFormLayout>
    </>
  );
};

export default withTranslationReady(["client"])(CompanyTaxNumberScene);
