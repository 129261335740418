import React from "react";
import GuestLayout from "sharedComponents/templates/GuestLayout/GuestLayout";
import { AdminServiceUpsellOfferingContainer } from "../../components/booking/AdminServiceUpsellOffering/AdminServiceUpsellOfferingContainer";

const AdminServiceUpsellOfferingScene = () => {
  return (
    <GuestLayout>
      <AdminServiceUpsellOfferingContainer />
    </GuestLayout>
  );
};

export default AdminServiceUpsellOfferingScene;
