import React from "react";
import { Col, Row } from "react-bootstrap";
import { companyDetailsByCompanyId_companyById } from "../../../../graphql/companyDetailsByCompanyId";
import { TFunction } from "i18next";
import { formatRegisterCourt } from "../../../../../../common/formatters/registerCourtFormatter";

export interface IProps {
  t: TFunction;
  companyData: companyDetailsByCompanyId_companyById;
}

export const CompanyLegalRegistration = ({ t, companyData }: IProps) => {
  return (
    <Row className="mt-5">
      <Col xs={6} md={3}>
        <Row>
          <Col>
            <b>{t("generic:registerCourt")}</b>
          </Col>
        </Row>
        <Row>
          <Col data-testid="register-court">{formatRegisterCourt(companyData.registerCourt)}</Col>
        </Row>
      </Col>
      <Col xs={6} md={3}>
        <Row>
          <Col>
            <b>{t("generic:registration")}</b>
          </Col>
        </Row>
        <Row>
          <Col data-testid="company-registration">{companyData.registration}</Col>
        </Row>
      </Col>
      <Col xs={6} md={3}>
        <Row>
          <Col>
            <b>{t("generic:taxNumber")}</b>
          </Col>
        </Row>
        <Row>
          <Col data-testid="taxnumber">{companyData.taxNumber}</Col>
        </Row>
      </Col>
      <Col xs={6} md={3}>
        <Row>
          <Col>
            <b>{t("generic:vatNumber")}</b>
          </Col>
        </Row>
        <Row>
          <Col data-testid="vatnumber">{companyData.vatNumber}</Col>
        </Row>
      </Col>
    </Row>
  );
};
