import { Order } from "./Order";
import { Step, StepCreateData } from "./Step";

interface SubChapterCreateData {
  index: number;
  steps: StepCreateData[];
  key: string;
  parentStepKey?: string;
}

export class Chapter {
  index?: number;
  order: Order;
  key: string;
  parent?: Chapter; // Secretly, this is an identifier of a subchapter 🤫
  parentStepKey?: string;
  steps: Step[] = [];
  subChapters: Chapter[] = [];

  constructor(key: string, data: any, order: Order) {
    this.key = key;
    this.order = order;
    this.index = data.index;
    this.parentStepKey = data.parentStepKey;
    this.steps = (data.steps ?? []).map((step: any) => new Step(step, this));
    this.subChapters = (data.subChapters ?? data["sub-chapters"] ?? []).map(
      (subChapter: any, index: number) => {
        const chapter = new Chapter(subChapter.key, subChapter, order);
        chapter.parent = this;

        if (typeof chapter.index !== "number") {
          chapter.index = index;
        }

        return chapter;
      }
    );
  }

  getStepByIndex(index: number): Step | undefined {
    return this.steps[index];
  }

  getStepByKey(key: string): Step | undefined {
    return this.steps.find((step) => step.key === key);
  }

  getSubChapterByKey(key: string): Chapter | undefined {
    return this.subChapters.find((subchapter) => subchapter.key === key);
  }

  getSubChapterByIndex(index: number): Chapter | undefined {
    return this.subChapters[index];
  }

  serialize() {
    const subChapters = this.subChapters.map((chapter) => chapter.serialize());
    return {
      index: this.index,
      parentStepKey: this.parentStepKey,
      key: this.key,
      steps: this.steps.map((step) => step.serialize()),
      subChapters: subChapters.length > 0 ? subChapters : undefined
    };
  }

  getStepAfter(step: Step): Step | undefined {
    const index = this.steps.indexOf(step);
    const nextStep = this.steps[index + 1];
    if (nextStep) return nextStep;

    if (this.parent) {
      return this.parentStepKey
        ? this.parent.getStepByKey(this.parentStepKey)
        : this.parent.getStepByIndex(0);
    }

    return this.getNextChapter()?.getStepByIndex(0);
  }

  removeStep(step: Step) {
    const index = this.steps.indexOf(step);
    this.steps.splice(index, 1);
  }

  insertStepAt(index: number, step: Step) {
    this.steps = [...this.steps.slice(0, index), step, ...this.steps.slice(index)];
  }

  insertStepAfter(step: Step, stepToInsert: Step) {
    const index = this.steps.indexOf(step);
    this.steps.splice(index + 1, 0, stepToInsert);
  }

  complete() {
    this.steps.forEach((step) => step.complete());
  }

  reOpen() {
    this.steps.forEach((step) => step.reOpen());
  }

  get completed() {
    return this.steps.every((step) => step.completed);
  }

  getNextChapter(): Chapter | undefined {
    return this.order.getChapterAfter(this);
  }

  getIndexOfStep(step: Step) {
    return this.steps.indexOf(step);
  }

  getStepIndexByKey(key: string) {
    return this.getIndexOfStep(this.getStepByKey(key)!);
  }

  get positionalIndex() {
    if (this.parent) {
      return this.parent.getIndexOfSubChapter(this);
    }

    return this.order.getIndexOfChapter(this);
  }

  getIndexOfSubChapter(chapter: Chapter) {
    return this.subChapters.indexOf(chapter);
  }

  addSubChapter(data: SubChapterCreateData) {
    const subChapter = new Chapter(data.key, data, this.order);
    this.subChapters.push(subChapter);
    subChapter.parent = this;
    return subChapter;
  }

  removeSubChapterByEntityIndex(entityIndex: number | string) {
    const subChapterIndex = this.subChapters.findIndex(
      (subChapter) => `${subChapter.index}` === `${entityIndex}`
    );
    this.subChapters.splice(subChapterIndex, 1);
  }

  removeSubChapterByEntityIndexAndParentStepKey(
    entityIndex: number | string,
    parentStepKey: string
  ) {
    const subChapterIndex = this.subChapters.findIndex(
      (subChapter) =>
        `${subChapter.index}` === `${entityIndex}` && subChapter.parentStepKey === parentStepKey
    );
    this.subChapters.splice(subChapterIndex, 1);
  }

  getSubChapterByEntityIndex(entityIndex: number | string) {
    return this.subChapters.find((subChapter) => `${subChapter.index}` === `${entityIndex}`);
  }

  replaceSubsequentSteps(currentStep: Step, newSteps: StepCreateData[]) {
    const numberOfSteps = this.steps.length;

    this.steps.splice(currentStep.index + 1, numberOfSteps - currentStep.index);
    const stepsToAdd = newSteps.map((step) => new Step(step, this));
    this.steps.push(...stepsToAdd);
  }
}
