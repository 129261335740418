import React, { PropsWithChildren } from "react";

export interface ClickableElementProps
  extends PropsWithChildren<React.HTMLAttributes<HTMLElement>> {}

export const ClickableElement = ({ children, className, ...rest }: ClickableElementProps) => {
  return (
    <div className={`clickable-element ${className ?? ""}`} {...rest}>
      {children}
    </div>
  );
};
