import React, { PropsWithChildren } from "react";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { formatValue } from "common/formatters";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import RideDatePicker from "sharedComponents/RideDatePicker/RideDatePicker";
import { DateFormatter } from "common/formatters/DateFormatter";
import { DATE_FORMAT } from "../../../lib/formatters/config";
import { WithTranslation } from "react-i18next";

interface SelectProps {
  name: string;
  onChange: (param) => void;
  defaultValue?: string | null;
}

interface OptionsProps extends SelectProps {
  options: Array<{ value: string; label: string }>;
}

interface FieldProps {
  "data-testid": string;
  label: string;
  value: any;
}

interface FieldSectionProps extends PropsWithChildren<{}> {
  label: string;
  "data-testid"?: string;
}

export const YesNoOptions = (t: WithTranslation["t"]) => [
  { label: t("generic:yes"), value: "Yes" },
  { label: t("generic:no"), value: "No" }
];

export const PeriodOptions = (t: WithTranslation["t"]) => [
  { label: t("generic:quarterly"), value: "Quarterly" },
  { label: t("generic:yearly"), value: "Yearly" }
];

export const ConditionTypeOptions = (t: WithTranslation["t"]) => [
  { label: t("generic:standard"), value: "Standard" },
  { label: t("generic:special"), value: "Special" }
];

const Select = ({ options, name, defaultValue, onChange }: OptionsProps) => {
  const defaultOption = options.find((option) => option.value === defaultValue);
  return (
    <div data-testid={`${name}-select-container`}>
      <ReactSelect
        name={name}
        defaultValue={defaultOption}
        onChange={(value) => onChange(value?.value)}
        options={options}
        isSearchable
      />
    </div>
  );
};

export const InvoiceField = ({ label, value, "data-testid": testId }: FieldProps) => (
  <Row data-testid={testId} className="labeled-string-field">
    <Col md={4} className="labeled-string-field__label">
      {label}
    </Col>
    <Col md={8} data-testid={`${testId}-value`} className="labeled-string-field__value">
      {value}
    </Col>
  </Row>
);

export const InvoiceFieldSection = ({
  label,
  children,
  "data-testid": testid = "field"
}: FieldSectionProps) => (
  <Row className="labeled-field-section" data-testid={`${testid}-label-section`}>
    <Col md={4} className="labeled-field-section__label">
      {label}
    </Col>
    <Col md={8}>{children}</Col>
  </Row>
);

export const InvoiceSelectField = ({
  name,
  label,
  options,
  isEdit,
  formik,
  onChange
}: {
  name: string;
  label: string;
  options: Array<{ value: string; label: string }>;
  isEdit: boolean;
  formik: any;
  onChange?: (value: string) => void;
}) => {
  const selectedOption = options.find((option) => option.value === formik.values[name]);
  return (
    <InvoiceField
      data-testid={name}
      label={label}
      value={
        isEdit ? (
          <Select
            name={name}
            options={options}
            defaultValue={formik.values[name]}
            onChange={(value) => {
              formik.setFieldValue(name, value);
              onChange?.(value);
            }}
          />
        ) : (
          formatValue(selectedOption?.label)
        )
      }
    />
  );
};

export const InvoiceTextField = ({
  name,
  label,
  isEdit,
  formik
}: {
  name: string;
  label: string;
  isEdit: boolean;
  formik: any;
}) => (
  <InvoiceField
    data-testid={name}
    label={label}
    value={isEdit ? <RideInput name={name} formik={formik} /> : formatValue(formik.values[name])}
  />
);

export const InvoiceDateField = ({
  name,
  label,
  isEdit,
  formik
}: {
  name: string;
  label: string;
  isEdit: boolean;
  formik: any;
}) => (
  <InvoiceField
    data-testid={name}
    label={label}
    value={
      isEdit ? (
        <RideDatePicker
          dateFormat={DATE_FORMAT}
          placeholder={DATE_FORMAT}
          name={name}
          selectedDate={formik.values[name]}
          onSelectDate={(value) => formik.setFieldValue(name, value)}
        />
      ) : (
        dateFormatter.format(formik.values[name])
      )
    }
  />
);

const dateFormatter = new DateFormatter();
