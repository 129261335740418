import React from "react";
import { useHistory } from "react-router";
import RideFooter from "../../../sharedComponents/RideFooter/RideFooter";
import { ModalLayout } from "../../../sharedComponents/templates/ModalLayout/ModalLayout";
import { ThirdPartyTaxAdvisorMigrateSuccessContainer } from "../../components/WepaReform/ThirdPartyTaxAdvisorMigrateSuccess/ThirdPartyTaxAdvisorMigrateSuccessContainer";

export const ThirdPartyTaxAdvisorMigrateSuccessScene = () => {
  const history = useHistory();

  const goToLandingPage = () => {
    history.push(`/`);
  };

  return (
    <ModalLayout onClose={goToLandingPage}>
      <div className={"migrate-3pta-modal"}>
        <ThirdPartyTaxAdvisorMigrateSuccessContainer />
      </div>
      <RideFooter />
    </ModalLayout>
  );
};
