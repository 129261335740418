import { useMutation } from "react-apollo";
import {
  ResumeCompanyCreationStep,
  ResumeCompanyCreationStepVariables
} from "../graphql/ResumeCompanyCreationStep";
import { RESUME_COMPANY_CREATION_STEP } from "../Mutations";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { storeToken } from "../../../../common/Authentication/storeToken";
import { AssertionError } from "assert";
import logger from "../../../../common/Logger";
import LoadingAlert from "../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { WithTranslation, withTranslation } from "react-i18next";
import { GET_ORDER_QUERY } from "../../../../lib/api/queries/GetOrder";
import { OrderOwnerType } from "../../../../global-query-types";
import { useApolloClient } from "@apollo/react-hooks";
import {
  GetOrderQuery,
  GetOrderQueryVariables
} from "../../../../lib/api/queries/graphql/GetOrderQuery";

export interface ResumeLinkHandlerProps extends WithTranslation {
  onLoggedIn: (
    data: ResumeCompanyCreationStep,
    orderId: string | null | undefined,
    orderSubmissionId: string | null | undefined
  ) => void;
}

const ResumeLinkHandler = ({ t, onLoggedIn }: ResumeLinkHandlerProps) => {
  const [resumeCompanyCreationStep, { error, loading }] = useMutation<
    ResumeCompanyCreationStep,
    ResumeCompanyCreationStepVariables
  >(RESUME_COMPANY_CREATION_STEP);

  const apolloClient = useApolloClient();

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    async function getOrder(data: ResumeCompanyCreationStep) {
      let orderId =
        data?.resumeCompanyCreationStep?.companyCreation?.company?.companyFoundationOrderId;
      let submissionId: string | undefined | null = undefined;
      if (data?.resumeCompanyCreationStep?.companyCreation.company && orderId) {
        try {
          const orderResult = await apolloClient.query<GetOrderQuery, GetOrderQueryVariables>({
            query: GET_ORDER_QUERY,
            variables: {
              id: orderId,
              ownerType: OrderOwnerType.Person
            }
          });

          submissionId = orderResult.data.getOrder?.submissionId;
        } catch (error) {
          logger.error(error);
          logger.trace(`Unable to fetch order with id ${orderId}`);
        }
        return { orderId, submissionId };
      }
    }

    async function resumeStep() {
      try {
        const { data } = await resumeCompanyCreationStep({
          variables: {
            resumeToken: token
          }
        });

        if (data?.resumeCompanyCreationStep) {
          const token = data.resumeCompanyCreationStep.authentication.token;
          if (token) {
            storeToken(token);
            const order = await getOrder(data);
            onLoggedIn(data, order?.orderId, order?.submissionId);
          } else {
            throw new AssertionError({
              message: "Company Creation step authorization not successful"
            });
          }
        } else {
          throw new AssertionError({
            message: "authorization not successful"
          });
        }
      } catch (error) {
        logger.error(error);
        logger.trace("Unable to authorize the resume link");
      }
    }

    resumeStep();
  }, [token, resumeCompanyCreationStep, onLoggedIn, apolloClient]);

  if (loading) return <LoadingAlert t={t} />;
  if (error) return <ErrorAlertLegacy t={t} error={error.message} />;

  return <></>;
};

export default withTranslation(["trader-gmbh", "errorCodes"])(ResumeLinkHandler);
