import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { CompanyById, CompanyByIdVariables } from "./graphql/CompanyById";

export const COMPANY_BY_ID = gql`
  query CompanyById($companyId: String!) {
    companyById(companyId: $companyId) {
      id
      name
      legalForm
      registration
      registerCourt
      lei
      leiIssueStatus
      leiOrderSource
      notarizationDate
      applicationForEntryDate
      commercialRegisterEntryDate
      economicId
      taxNumber
      vatNumber
      clientNumber
      thirdPartyTaxAdvisorClientNumber
      businessPurpose
      externalStorageNodes
      locked
      customerSignedAdminServiceContract
      asUpgradeRequiredFrom
      asUpgradeMandatory
      finApiAccount {
        id
        finApiBankConnections {
          id
        }
      }
      orderStatus
      companyFoundationOrderId
      companyCreation {
        id
        processStates
        flowType
        currentStep
      }
      brokerageAccounts {
        id
        accountNumber
        brokerBank
        brokerName
        tokenUpdatedAt
        token
        queryId
        validity
        errorCodes
        isTokenExist
      }
      myTaxService {
        id
        taxAdvisorStatus
        type
        advisor {
          id
          name
          legalForm
        }
      }
      adminService {
        id
        currentStep
        flowType
        isArchived
        orderedAt
        canceledAt
        tier
        invoicedBy
      }
      wepaId
      wepaService {
        id
      }
      brokerageSurvey {
        id
        answer
        reason
        otherReason
        selectedBrokerBank
        otherBrokerBank
        updatedAt
      }
      businessAddress {
        street
        streetLine2
        city
        postalCode
        country
      }
      group {
        memberships {
          role
          share {
            share
          }
          company {
            id
            name
            legalForm
          }
          person {
            id
            firstName
            lastName
            phoneNumber
            dateOfBirth
            citizenship
            placeOfBirth
            salutation
            personalTaxId
            address {
              street
              streetLine2
              city
              postalCode
              country
            }
            user {
              id
              email
            }
          }
        }
      }
      fiscalYear
      accountingFramework
      bankAccounts {
        id
        name
        bankProvider
        plan
        iban
      }
      orders {
        id
        productType
        status
        extra
        submissionId
        deletedAt
      }
      migrations {
        id
        realizedAt
        migrationName
        requestedSince
      }
    }
  }
`;

export const QueryCompanyById = (companyId: string | null | undefined) =>
  useQuery<CompanyById, CompanyByIdVariables>(COMPANY_BY_ID, {
    variables: { companyId: companyId ?? "" },
    fetchPolicy: "no-cache",
    skip: !companyId
  });
