import React from "react";
import { FeatureFlags } from "global-query-types";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import PtaMigrationFlowToRideSteuerberatungContainer from "../../components/AdminServicePTAMigrationToRideSteuerberatung/PtaMigrationFlowToRideSteuerberatung/PtaMigrationFlowToRideSteuerberatungContainer";
import { useParams } from "react-router-dom";

export const PtaMigrationFlowToRideSteuerberatungScene = () => {
  const { companyId } = useParams<{ companyId: string }>();

  return (
    <FeatureFlag name={FeatureFlags.AdminServicePTAMigrationToRideSteuerberatung}>
      <PtaMigrationFlowToRideSteuerberatungContainer companyId={companyId} />
    </FeatureFlag>
  );
};
