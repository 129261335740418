import React from "react";
import { UpdateIBKRCredentialVariables } from "lib/api/mutations/graphql/UpdateIBKRCredential";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { Form, FormikProvider, useFormik } from "formik";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { GetUserForPersonQuery_getPersonDetails_user } from "lib/api/queries/graphql/GetUserForPersonQuery";

export interface UpdateIBKREmailProps {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order;
  ownerUser: GetUserForPersonQuery_getPersonDetails_user | null | undefined;
  accountDetails: unknown | null | undefined;
  updateIBKRCredential: (variables: UpdateIBKRCredentialVariables) => Promise<unknown>;
  isLoading: boolean;
}

const UpdateIBKREmail = ({
  order,
  ownerUser,
  accountDetails,
  updateIBKRCredential,
  isLoading
}: UpdateIBKREmailProps & WithTranslation) => {
  async function requestEmailChange() {
    const result = await updateIBKRCredential({
      companyId: order.entityId ?? "",
      orderId: order.id
    });
    if (result) {
      console.log(result);
    }
  }

  async function validateToken(token: string) {
    const result = updateIBKRCredential({
      companyId: order.entityId ?? "",
      orderId: order.id,
      token
    });
    if (result) {
      console.log(result);
    }
  }

  const validateOldEmailAddressTokenFormik = useFormik({
    initialValues: {
      oldEmailToken: ""
    },
    onSubmit: async (values) => {
      await validateToken(values.oldEmailToken);
    }
  });

  const validateNewEmailAddressTokenFormik = useFormik({
    initialValues: {
      newEmailToken: ""
    },
    onSubmit: async (values) => {
      await validateToken(values.newEmailToken);
    }
  });

  if (!order.extra.isNewBrokerEnabled) {
    return null;
  }

  const newEmail = ownerUser?.email;
  const currentEmail = (accountDetails as any)?.result?.[0]?.Account?.emailAddress;

  return (
    <div data-testid="update-ibkr-email" className="update-ibkr-email">
      <Typography category={"Headline"} size={400} weight={"Heavy"}>
        Update email address in IB
      </Typography>
      <div className="update-ibkr-email__step">
        <Typography category={"Paragraph"} size={100} weight={"Heavy"}>
          Step 1: Request email address change
        </Typography>
        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          Start or restart process to change the email address of the IBKR account.
        </Typography>{" "}
        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          Current email address: {currentEmail ? <u>{currentEmail}</u> : "---"}
        </Typography>
        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          New email address: {newEmail ? <u>{newEmail}</u> : "---"}
        </Typography>
        <RideButton
          data-testid="request-email-address-change-button"
          label="Request email address change"
          size={RideButtonSize.BIG}
          variant={RideButtonVariant.PRIMARY}
          type="button"
          disabled={isLoading}
          onClick={requestEmailChange}
        />
      </div>

      <hr />

      <div className="update-ibkr-email__step">
        <Typography category={"Paragraph"} size={100} weight={"Heavy"}>
          Step 2: Validate token received at old email address
        </Typography>
        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          Enter the token received at{" "}
          {currentEmail ? <u>{currentEmail}</u> : "the current email address"}.
        </Typography>
        <FormikProvider value={validateOldEmailAddressTokenFormik}>
          <Form className="update-ibkr-email__controls">
            <RideTextInput name="oldEmailToken" placeholder="Enter token here" />
            <RideButton
              data-testid="validate-old-email-token-button"
              label="Validate token for old email address"
              size={RideButtonSize.BIG}
              variant={RideButtonVariant.PRIMARY}
              type="submit"
              disabled={isLoading || !validateOldEmailAddressTokenFormik.values.oldEmailToken}
            />
          </Form>
        </FormikProvider>
      </div>

      <hr />

      <div className="update-ibkr-email__step">
        <Typography category={"Paragraph"} size={100} weight={"Heavy"}>
          Step 3: Validate token received at new email address
        </Typography>
        <Typography category={"Paragraph"} size={100} weight={"Light"}>
          Enter the token received at {newEmail ? <u>{newEmail}</u> : "the new email address"}.
        </Typography>
        <FormikProvider value={validateNewEmailAddressTokenFormik}>
          <Form className="update-ibkr-email__controls">
            <RideTextInput name="newEmailToken" placeholder="Enter token here" />
            <RideButton
              data-testid="validate-new-email-token-button"
              label="Validate token for new email address"
              size={RideButtonSize.BIG}
              variant={RideButtonVariant.PRIMARY}
              type="submit"
              disabled={isLoading || !validateNewEmailAddressTokenFormik.values.newEmailToken}
            />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(UpdateIBKREmail);
