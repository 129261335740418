import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import GenericAddress from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/GenericAddress/GenericAddress";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import * as Yup from "yup";

const CompanyAddressForm = ({
  t,
  order,
  saveData,
  controls,
  currentStep
}: WithTranslation & StepComponentProps) => {
  const initialValues = {
    country: order.extra?.candidateCompanyAddress?.country || "DE",
    streetLine1: order.extra?.candidateCompanyAddress?.streetLine1 || "",
    streetLine2: order.extra?.candidateCompanyAddress?.streetLine2 || "",
    city: order.extra?.candidateCompanyAddress?.city || "",
    postalCode: order.extra?.candidateCompanyAddress?.postalCode || ""
  };

  const CompanyAddressSchema = (t) => {
    return Yup.object().shape({
      streetLine1: Yup.string().required(t("generic:validation-required")),
      city: Yup.string().required(t("generic:validation-required")),
      country: Yup.string().oneOf(["DE"], t("generic:validation-not-a-german-address")),
      postalCode: Yup.string().required(t("generic:validation-required"))
    });
  };

  const onSubmit = async (values) => {
    currentStep.complete();
    delete order.extra.candidateCompanyAddress;
    await saveData({
      ...order.extra,
      companyAddress: values,
      ...currentStep.chapter.order.serialize()
    });
  };

  const header = order.extra?.candidateCompanyAddress?.streetLine1
    ? "generic:company-setup.steps.company-address.company-address-form.confirm-address-title"
    : "generic:company-setup.steps.company-address.company-address-form.add-address-title";

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: CompanyAddressSchema(t)
  });

  return (
    <div data-testid="company-address-form">
      <FormikProvider value={formik}>
        <Form className="subchapter-order-layout__form">
          <StepHeading text={t(header)} />
          <GenericAddress
            formik={formik}
            prefix={`${currentStep?.disposition}`}
            title={t("generic:shareholder-details.company-address")}
            displaySectionTitle={false}
            disableFields={["country"]}
          />
          {{
            ...controls,
            props: {
              title:
                "generic:company-setup.steps.company-address.company-address-form.review-and-submit",
              ...controls.props
            }
          }}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic"])(CompanyAddressForm);
