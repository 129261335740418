import React from "react";
import { useMutation, useQuery } from "react-apollo";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { useParams } from "react-router";
import { GET_CLUB_DEAL_BY_ID } from "../../../../lib/api/queries/GetClubDealById";
import {
  GetClubDealById,
  GetClubDealByIdVariables
} from "../../../../lib/api/queries/graphql/GetClubDealById";
import { ClubDealDetails } from "./ClubDealDetails";
import { CREATE_CLUB_DEAL_INVESTMENT } from "../../../../lib/api/mutations/CREATE_CLUB_DEAL_INVESTMENT";
import {
  CreateClubDealInvestment,
  CreateClubDealInvestmentVariables
} from "../../../../lib/api/mutations/graphql/CreateClubDealInvestment";
import { useHistory } from "react-router-dom";

export const ClubDealContainer = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const clubDealRequest = useQuery<GetClubDealById, GetClubDealByIdVariables>(GET_CLUB_DEAL_BY_ID, {
    variables: {
      id
    }
  });

  const [createClubDealInvestmentMutation] = useMutation<
    CreateClubDealInvestment,
    CreateClubDealInvestmentVariables
  >(CREATE_CLUB_DEAL_INVESTMENT);

  const handleExpressInterest = async () => {
    const result = await createClubDealInvestmentMutation({
      variables: {
        id
      }
    });

    const investmentId = result.data?.createClubDealInvestment?.id;
    history.push(`/client/investments/${investmentId}`);
  };

  const clubDeal = clubDealRequest.data?.getClubDealById;

  return (
    <RequestFeedbackHandler requests={[clubDealRequest]} noLoadingAnimation={true}>
      {clubDeal && (
        <ClubDealDetails clubDeal={clubDeal} onExpressInterest={handleExpressInterest} />
      )}
    </RequestFeedbackHandler>
  );
};
