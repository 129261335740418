import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { LegalForm } from "global-query-types";
import { LegalFormSchema } from "lib/validation/LegalFormSchema";

interface ChooseCompanyLegalFormProps {}

const ChooseCompanyLegalForm = ({
  t,
  currentStep,
  order,
  saveData,
  controls
}: ChooseCompanyLegalFormProps & StepComponentProps & WithTranslation) => {
  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData({
      ...order.extra,
      legalForm: values.legalForm === "GmbH" ? LegalForm.GmbH : LegalForm.UG,
      ...currentStep.chapter.order.serialize()
    });
  };

  const formik = useRideFormik({
    initialValues: {
      legalForm: order.extra.legalForm ?? ""
    },
    onSubmit,
    validationSchema: LegalFormSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:order-company-foundation.steps.choose-company-legal-form.title")}
        />
        <div data-testid="choose-company-legal-form">
          <RideRadioSelect
            options={[
              {
                value: "GmbH",
                label: t("generic:order-company-foundation.steps.choose-company-legal-form.GmbH")
              },
              {
                value: "UG",
                label: t("generic:order-company-foundation.steps.choose-company-legal-form.UG")
              }
            ]}
            name="legalForm"
            variant={RadioSelectVariant.Heavy}
            columns={1}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ChooseCompanyLegalForm);
