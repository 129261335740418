import { OrderChaptersConfig } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import CurrentTaxAdvisorDetails from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/CurrentTaxAdvisorDetails/CurrentTaxAdvisorDetails";
import ReviewAndSubmitAdminServiceFormContainer from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/ReviewAndSubmitAdminServiceForm/ReviewAndSubmitAdminServiceFormContainer";
import AdminServiceFormDocuments from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/AdminServiceFormDocuments/AdminServiceFormDocuments";
import { IdDocumentVariant } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ShareholderIdFile/ShareholderIdFile";
import { getFileStorage } from "lib/ports/FileStorage";
import { ProofOfIdFile } from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/AdminServiceFormDocuments/ProofOfIdFileStep/ProofOfIdFileStep";
import { LastYearlyReportFile } from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/AdminServiceFormDocuments/LastYearlyReportFileStep/LastYearlyReportFileStep";
import SelectIdDocumentTypeForAdminServiceStep from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/AdminServiceFormDocuments/SelectIdDocumentTypeForAdminServiceStep/SelectIdDocumentTypeForAdminServiceStep";
import { FiscalYearStartContainer } from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/FiscalYearStart/FiscalYearStartContainer";
import { ShareholdersContextContainer } from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/ShareholdersContext/ShareholdersContextContainer";

export const ADMIN_SERVICE_ORDER_FORM: OrderChaptersConfig = {
  chapters: {
    documents: {
      icon: "document-lines",
      title: "generic:admin-service-form.chapters.documents"
    },
    "fiscal-year": {
      icon: "calendar",
      title: "generic:admin-service-form.chapters.fiscal-year"
    },
    "shareholder-details": {
      icon: "id-card",
      title: "generic:company-setup.chapters.shareholder-details"
    },
    "tax-advisor": {
      icon: "profile",
      title: "generic:admin-service-form.chapters.tax-advisor"
    },
    "review-and-submit": {
      icon: "rocket",
      title: "generic:admin-service-form.chapters.review-and-submit"
    }
  },
  steps: {
    "upload-documents": AdminServiceFormDocuments,

    "select-id-document-type": SelectIdDocumentTypeForAdminServiceStep,
    "shareholder-id-file": ProofOfIdFile(getFileStorage(), IdDocumentVariant.ID_FRONT),
    "shareholder-id-file-back": ProofOfIdFile(getFileStorage(), IdDocumentVariant.ID_BACK),
    "shareholder-passport-file": ProofOfIdFile(getFileStorage(), IdDocumentVariant.PASSPORT),

    "last-yearly-report": LastYearlyReportFile(getFileStorage()),

    "fiscal-year-start": FiscalYearStartContainer,
    "shareholders-context": ShareholdersContextContainer,
    "tax-advisor-details": CurrentTaxAdvisorDetails,
    "review-and-submit": ReviewAndSubmitAdminServiceFormContainer
  },
  contextualHelpSuffix: "admin-service"
};
