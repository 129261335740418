import gql from "graphql-tag";

export const CLUB_DEALS = gql`
  query clubDeals {
    clubDeals {
      id
      minInvestmentAmount
      maxInvestmentAmount
      targetYield
      financedPercentage
      asset {
        id
        realEstate {
          id
          label
          image
          owner {
            id
            memberships {
              id
              company {
                id
                status
              }
            }
          }
        }
      }
    }
  }
`;
