import RideBanner from "uiLibrary/v2/components/RideBanner/RideBanner";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { MyLowTaxBrokerOrder_myLowTaxBrokerOrder } from "../../../../lib/api/queries/graphql/MyLowTaxBrokerOrder";

interface TradeBannerProps extends WithTranslation {
  order: MyLowTaxBrokerOrder_myLowTaxBrokerOrder | null | undefined;
  handleCreateSSOAndRedirect?: () => Promise<void>;
}

const TradeBanner = ({ t, order, handleCreateSSOAndRedirect }: TradeBannerProps) => (
  <div data-testid={"trade-banner"}>
    {order?.extra.isNewBrokerEnabled ? (
      <RideBanner
        title={t("generic:low-tax-broker-dashboard.trade-banner.title")}
        message={t("generic:low-tax-broker-dashboard.trade-banner.message")}
        buttonAlternativeSlot={
          <button
            className="ride-banner__button"
            data-testid="ride-banner-button"
            onClick={handleCreateSSOAndRedirect}>
            <span className="ride-banner__button-text">
              {t(
                `generic:low-tax-broker-dashboard.trade-banner.button-label${
                  order.extra.isNewBrokerEnabled ? "-rb" : ""
                }`
              )}
            </span>
            <span className="ride-banner__button-icon" />
          </button>
        }
      />
    ) : (
      <RideBanner
        title={t("generic:low-tax-broker-dashboard.trade-banner.title")}
        message={t("generic:low-tax-broker-dashboard.trade-banner.message")}
        buttonTitle={t("generic:low-tax-broker-dashboard.trade-banner.button-label")}
        buttonLink={
          "https://www.interactivebrokers.co.uk/sso/Login?RL=1&partnerID=RIDE01&utm_source=app&utm_medium=app"
        }
      />
    )}
  </div>
);

export default withTranslationReady(["generic"])(TradeBanner);
