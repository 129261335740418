import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { useHistory } from "react-router";
import ProfileEmailEditContainer from "../../components/profile/ProfileEmailEdit/ProfileEmailEditContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

const ChangeEmailScene = ({ t }: WithTranslation) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:profile.edit-page-title.email")} />
      <ModalFormLayout title={t("client:profile.edit.email")} onBack={goBack}>
        <ProfileEmailEditContainer />
      </ModalFormLayout>
    </>
  );
};

export default withTranslationReady("client")(ChangeEmailScene);
