import { formatCurrency } from "common/formatters";
import React from "react";
import { ClickableAreaVariant, RideTile } from "../../../../sharedComponents/RideTile/RideTile";

export interface BankAccountTransactionCardProps {
  name: string;
  date: string;
  balance: string;
}

const BankAccountTransactionCard = ({ name, date, balance }: BankAccountTransactionCardProps) => {
  const isPositiveBalance = Number(balance) > 0;
  const transactionDate = (
    <span className="bank-account-transaction-card__date" data-testid="transaction-date">
      {date}
    </span>
  );
  const transactionBalance = (
    <span
      className={`bank-account-transaction-card__balance bank-account-transaction-card__balance--${
        isPositiveBalance ? "positive" : "negative"
      }`}
      data-testid="transaction-balance">
      {`${isPositiveBalance ? "+" : ""}${formatCurrency(balance)}`}
    </span>
  );

  return (
    <RideTile
      data-testid="bank-account-transaction-card"
      name={name}
      clickableAreaVariant={ClickableAreaVariant.none}
      subTitleSlot={transactionDate}
      detailsSlot={transactionBalance}
    />
  );
};

export default BankAccountTransactionCard;
