import React from "react";
import { GetInvestmentById_getInvestmentById } from "../../../../lib/api/queries/graphql/GetInvestmentById";
import InvestmentCardDetails from "../InvestmentCardDetails/InvestmentCardDetails";
import { toInvestmentCardPropsFromInvestment } from "../../../../lib/dataAdapters/investment/investment";
import { GetInvestmentStorageNodes_getInvestmentStorageNodes } from "../../../../lib/api/queries/graphql/GetInvestmentStorageNodes";
import { AssetType } from "../../../../global-query-types";
import { BrokerageAccountsSections } from "../BrokerageAccountsSections/BrokerageAccountsSections";
import { ClubDealSections } from "../ClubDealSections/ClubDealSections";

export interface InvestmentDetailsProps {
  investment: GetInvestmentById_getInvestmentById;
  files: GetInvestmentStorageNodes_getInvestmentStorageNodes[];
}

export const InvestmentDetails = ({ investment, files }: InvestmentDetailsProps) => {
  const { asset, ownership, state, share, lastChange, image, nlvPostTax, nlvPreTax } =
    toInvestmentCardPropsFromInvestment(investment);
  const memberships = investment.asset.realEstate?.owner?.memberships ?? [];
  const company = memberships[0]?.company;
  const { type } = asset;

  return (
    <>
      <InvestmentCardDetails
        type={type}
        ownership={ownership}
        state={state}
        share={share}
        lastChange={lastChange}
        nlvPreTax={nlvPreTax}
        nlvPostTax={nlvPostTax}
        image={image}
      />

      {type === AssetType.RealEstate && (
        <ClubDealSections investment={investment} state={state} company={company} files={files} />
      )}

      {type === AssetType.BrokerageAccountSet && (
        <BrokerageAccountsSections className={"mt-5"} investment={investment} />
      )}
    </>
  );
};
