import React from "react";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";
import { ReferralCodeUsagesTableContainer } from "../../components/ReferralCodeUsagesTable/ReferralCodeUsagesTableContainer";
import { useParams } from "react-router";

const ReferralCodeDetails = ({ t }: { t: TFunction }) => {
  const { code } = useParams<{ code: string }>();

  return <ReferralCodeUsagesTableContainer t={t} code={code} />;
};

export default withTranslation(["nominee-dashboard", "generic"])(ReferralCodeDetails);
