import React from "react";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";
import { companyName, CompanyName } from "../../../../../../../lib/formatters/companyFormatter";
import { CardContainer } from "../../../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";

export interface ReviewCompanyNameProps {
  company: CompanyName;
  editLink: string;
  className?: string;
}

export const ReviewCompanyName = ({
  company,
  editLink,
  className = ""
}: ReviewCompanyNameProps) => {
  return (
    <CardContainer className={className}>
      <ReviewEntry
        name="company-name"
        editLink={editLink}
        list={[<React.Fragment key={1}>{companyName(company)}</React.Fragment>]}
      />
    </CardContainer>
  );
};
