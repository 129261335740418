import React, { PropsWithChildren } from "react";

export interface TypographyProps extends PropsWithChildren<{}> {
  "data-testid"?: string;
  category: "Paragraph" | "Headline";
  size: 100 | 200 | 300 | 400;
  weight: "Heavy" | "Light";
  className?: string;
}

export const Typography = ({
  "data-testid": dataTestId,
  category,
  size,
  weight,
  className,
  children
}: TypographyProps) => (
  <span
    data-testid={dataTestId}
    className={`typography typography--${category.toLowerCase()}-${size} typography--${weight.toLowerCase()} ${
      className ?? ""
    }`}>
    {children}
  </span>
);
