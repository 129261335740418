import React, { useState } from "react";
import { useMutation } from "react-apollo";
import LostPassword, { LostPasswordPhase } from "./LostPassword";
import { RESET_MUTATION } from "lib/api/mutations/RESET_MUTATION";
import { ResetMutation, ResetMutationVariables } from "lib/api/mutations/graphql/ResetMutation";

interface LostPasswordContainerProps {
  isResetPasswordFlow: boolean;
}

export const LostPasswordContainer = ({ isResetPasswordFlow }: LostPasswordContainerProps) => {
  const [resetMutation, resetRequest] = useMutation<ResetMutation, ResetMutationVariables>(
    RESET_MUTATION
  );

  const [phase, setPhase] = useState(LostPasswordPhase.form);

  const handleReset = async (email: string) => {
    await resetMutation({
      variables: {
        email
      }
    });
    setPhase(LostPasswordPhase.success);
  };

  return (
    <LostPassword
      isResetPasswordFlow={isResetPasswordFlow}
      resetRequest={resetRequest}
      phase={phase}
      onSubmit={handleReset}
    />
  );
};
