import { WithTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { NomineeCompanyDetails_companyById_bankAccounts } from "lib/api/queries/graphql/NomineeCompanyDetails";
import EditableSection from "sharedComponents/EditableSection/EditableSection";
import BankAccountsTable from "../BankAccountsTable/BankAccountsTable";
import BankAccountsEditableTable from "../BankAccountsEditableTable/BankAccountsEditableTable";
import { Form, FormikProvider, useFormik } from "formik";
import { Button } from "react-bootstrap";
import { BankAccountPlan } from "../../../../global-query-types";

export interface CompanyBankAccountsSectionProps extends WithTranslation {
  bankAccounts: NomineeCompanyDetails_companyById_bankAccounts[];
  onSubmit: (bankAccountsData: NomineeCompanyDetails_companyById_bankAccounts[]) => Promise<void>;
  onAddEmptyBankAccount: () => Promise<void>;
  onRefresh: () => Promise<void>;
}

const CompanyBankAccountsSection = ({
  bankAccounts,
  t,
  onSubmit,
  onAddEmptyBankAccount,
  onRefresh
}: CompanyBankAccountsSectionProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleOnSubmit = async (values) => {
    const bankAccounts = values.bankAccounts.map((bankAccount) => ({
      ...bankAccount,
      plan:
        bankAccount.plan && Object.values(BankAccountPlan).includes(bankAccount.plan)
          ? bankAccount.plan
          : null
    }));

    await onSubmit(bankAccounts);
    setIsEditMode(false);
  };

  const formik = useFormik({
    initialValues: { bankAccounts },
    onSubmit: handleOnSubmit,
    enableReinitialize: true
  });

  return (
    <FormikProvider value={formik}>
      <Form data-testid="bank-accounts-form">
        <EditableSection
          title={t("nominee-dashboard:company.bank-accounts")}
          testIdPrefix="bank-accounts-"
          isEditMode={isEditMode}
          onEdit={() => setIsEditMode(true)}
          onView={() => setIsEditMode(false)}>
          {isEditMode ? (
            <>
              <BankAccountsEditableTable
                bankAccounts={bankAccounts}
                onRefresh={onRefresh}
                formik={formik}
              />
              <Button data-testid="add-new-bank-account" onClick={onAddEmptyBankAccount}>
                {t("nominee-dashboard:company.add-empty-bank-account")}
              </Button>
            </>
          ) : (
            <BankAccountsTable bankAccounts={bankAccounts} />
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["nominee-dashboard"])(CompanyBankAccountsSection);
