import { TFunction, Trans } from "react-i18next";
import React, { useState } from "react";
import { Typography } from "../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../../../../uiLibrary/designSystem/RideButton/RideButton";
import { QuestionIcon } from "../../../../../../sharedComponents/icons/DesignSystemIcons/QuestionIcon";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "../../../../../../uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import RideModal, {
  RideModalBody
} from "../../../../../../uiLibrary/designSystem/RideModal/RideModal";
import { QuestionModalProps } from "../../CompanyInformationStep";

const WhyAreWeAskingAboutShareholdersModal = withTranslationReady(["generic"])(
  ({ t, onClose }: QuestionModalProps) => {
    return (
      <RideModal
        data-testid="why-are-we-asking-about-shareholders-modal"
        title=""
        onClose={onClose}>
        <RideModalBody className="company-information-step__modal-body">
          <Typography category="Headline" size={100} weight={"Heavy"}>
            {t(
              "generic:short-order-form.company-information.corporate-shareholders.why-are-we-asking-about-shareholders-modal.title"
            )}
          </Typography>

          <Typography category="Paragraph" size={100} weight={"Light"}>
            <Trans
              i18nKey={
                "generic:short-order-form.company-information.corporate-shareholders.why-are-we-asking-about-shareholders-modal.content"
              }
            />
          </Typography>
        </RideModalBody>
      </RideModal>
    );
  }
);

const CorporateShareholdersQuestion = ({ t }: { t: TFunction }) => {
  const [whyAreWeAskingAboutShareholdersModal, setwhyAreWeAskingAboutShareholdersModal] =
    useState(false);

  return (
    <div
      data-testid="corporate-shareholders-question"
      className="company-information-step__corporate-shareholder-question">
      {whyAreWeAskingAboutShareholdersModal && (
        <WhyAreWeAskingAboutShareholdersModal
          onClose={() => setwhyAreWeAskingAboutShareholdersModal(false)}
        />
      )}
      <Typography
        category="Paragraph"
        size={100}
        weight={"Heavy"}
        data-testid="corporate-shareholders-title">
        {t("generic:short-order-form.company-information.corporate-shareholders.title")}
      </Typography>
      <Typography
        category="Paragraph"
        size={100}
        weight={"Light"}
        data-testid="corporate-shareholders-info"
        className={
          "company-information-step__corporate-shareholders-info company-information-step__corporate-shareholders-info--v2"
        }>
        {t("generic:short-order-form.company-information.corporate-shareholders.info")}
      </Typography>
      <RideButton
        data-testid="why-are-we-asking-about-shareholders-button"
        type="button"
        variant={RideButtonVariant.GHOST}
        size={RideButtonSize.BIG}
        leftIcon={<QuestionIcon size={20} />}
        onClick={() => setwhyAreWeAskingAboutShareholdersModal(true)}
        label={t(
          "generic:short-order-form.company-information.corporate-shareholders.why-are-we-asking-about-shareholders-modal.title"
        )}
      />
      <RideRadioSelect
        className={
          "company-information-step__options company-information-step__corporate-shareholders-options"
        }
        name={"hasCorporateShareholders"}
        options={[
          {
            value: "yes",
            label: t("generic:short-order-form.company-information.corporate-shareholders.yes")
          },
          {
            value: "no",
            label: t("generic:short-order-form.company-information.corporate-shareholders.no")
          }
        ]}
        variant={RadioSelectVariant.Light}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(CorporateShareholdersQuestion);
