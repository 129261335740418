import { Container } from "react-bootstrap";
import React from "react";
import GuestLayout from "../../../sharedComponents/templates/GuestLayout/GuestLayout";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { TFunction } from "i18next";
import Jumbotron from "react-bootstrap/Jumbotron";

const ResumeLinkSentScene = ({ t }: { t: TFunction }) => (
  <GuestLayout>
    <Container className="trader-gmbh mt-5">
      <Jumbotron fluid className="admin-service-post-order-banner py-5 py-md-5">
        <Container className="text-light d-flex justify-content-center">
          <h3 data-testid="post-order-banner">{t("trader-gmbh:resume-link-sent-headline")}</h3>
        </Container>
      </Jumbotron>
      <div className="d-flex justify-content-center">
        <img data-testid="email-icon" src={`/assets/sharedComponents/email-icon.svg`} alt="icon" />
      </div>
      <div className="order-status-description mt-3">
        <p>{t("trader-gmbh:resume-link-sent-description")}</p>
        <p>{t("trader-gmbh:resume-link-sent-description-2")}</p>
      </div>
    </Container>
  </GuestLayout>
);

export default withTranslationReady(["generic", "trader-gmbh"])(ResumeLinkSentScene);
