import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../../../common/i18n/withTranslationReady";
import { CardContainer } from "../../../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";

interface ReviewOrderConfigurationProps {
  name: string;
  userOptedForNewCompany: boolean;
  needsTaxAdvisor: boolean;
  editLink: string;
}

export const ReviewOrderConfiguration = withTranslationReady(["generic"])(
  ({
    t,
    editLink,
    needsTaxAdvisor,
    userOptedForNewCompany
  }: ReviewOrderConfigurationProps & WithTranslation) => {
    return (
      <CardContainer>
        <ReviewEntry
          name={"configure-order"}
          fields={{
            companyFoundation: [
              userOptedForNewCompany
                ? t("generic:order-company-foundation.steps.review-and-submit.need-a-new-company")
                : t("generic:order-company-foundation.steps.review-and-submit.bring-my-own-company")
            ],
            rideTaxAdvisor: [needsTaxAdvisor ? t("generic:yes") : t("generic:no")]
          }}
          editLink={editLink}
        />
      </CardContainer>
    );
  }
);
