import WepaOnboarding from "./WepaOnboarding";
import React, { useState } from "react";
import { usePaymentQuery } from "lib/hooks/payments/usePaymentQuery";
import {
  GetPaymentMethodForCompany,
  GetPaymentMethodForCompanyVariables
} from "../../../../lib/api/payment/queries/graphql/GetPaymentMethodForCompany";
import { GET_PAYMENT_METHOD_FOR_COMPANY } from "../../../../lib/api/payment/queries/GetPaymentMethodForCompany";
import { PaymentMethodType, PaymentPlatformAccount } from "../../../../payments-query-types";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import {
  FinalizeOrder,
  FinalizeOrderVariables
} from "../../../../lib/api/mutations/graphql/FinalizeOrder";
import { FINALIZE_ORDER } from "../../../../lib/api/mutations/FINALIZE_ORDER";
import { GmbHDetails } from "../../../../lib/models/client/GmbHDetails";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "../../../../lib/api/mutations/graphql/UpsertOrder";
import { getPaymentsContext } from "../../../../lib/api/payment/context";
import { usePaymentMutation } from "lib/hooks/payments/usePaymentMutation";
import {
  UpdatePaymentMethod,
  UpdatePaymentMethodVariables
} from "../../../../lib/api/payment/mutations/graphql/UpdatePaymentMethod";
import { UPDATE_PAYMENT_METHOD } from "../../../../lib/api/payment/mutations/UpdatePaymentMethod";
import {
  getStripeCheckoutSession,
  getStripeCheckoutSessionVariables
} from "../../../../lib/api/mutations/graphql/getStripeCheckoutSession";
import { GET_STRIPE_CHECKOUT_SESSION } from "../../../../lib/api/mutations/GET_STRIPE_CHECKOUT_SESSION";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { LocationHelperProps, withLocationHelper } from "../../../../common/LocationHelper";
import { useQueryParams } from "../../../../lib/hooks/useQueryParams";
import {
  ValidateStripeSession,
  ValidateStripeSessionVariables
} from "../../../../lib/api/payment/queries/graphql/ValidateStripeSession";
import { VALIDATE_STRIPE_SESSION } from "../../../../lib/api/payment/queries/ValidateStripeSession";
import logger from "../../../../common/Logger";
import { QueryCompanyById } from "../../../../lib/api/queries/GetCompanyById";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "../../../../uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { doNothing } from "../../../../common/stubs";

export interface WepaOnboardingContainerProps {
  gmbh: GmbHDetails;
  upsertOrder: (order: UpsertOrderVariables) => Promise<UpsertOrder_upsertOrder | null | undefined>;
}

const WepaOnboardingContainer = ({
  t,
  gmbh,
  upsertOrder,
  locationHelper
}: WepaOnboardingContainerProps & LocationHelperProps & WithTranslation) => {
  const queryParams = useQueryParams();
  const sessionId = queryParams.get("session_id");
  const client = useApolloClient();
  const [successModal, setSuccessModal] = useState(false);

  const showSuccessModal = () => {
    setSuccessModal(true);
  };

  const companyRequest = QueryCompanyById(gmbh.id);
  const refetchCompany = async () => {
    await companyRequest.refetch();
  };

  const getPaymentMethodForCompany = usePaymentQuery<
    GetPaymentMethodForCompany,
    GetPaymentMethodForCompanyVariables
  >(GET_PAYMENT_METHOD_FOR_COMPANY, {
    variables: {
      companyId: gmbh.id,
      paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
    }
  });

  const [updatePaymentMethodMutation, updatePaymentMethodRequest] = usePaymentMutation<
    UpdatePaymentMethod,
    UpdatePaymentMethodVariables
  >(UPDATE_PAYMENT_METHOD);

  const updatePaymentMethod = async (paymentMethod: PaymentMethodType) => {
    await updatePaymentMethodMutation({
      variables: {
        companyId: gmbh.id,
        paymentMethod,
        paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
      },
      refetchQueries: [
        {
          query: GET_PAYMENT_METHOD_FOR_COMPANY,
          variables: {
            companyId: gmbh.id,
            paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
          },
          ...getPaymentsContext()
        }
      ]
    });

    await refetchCompany();
  };

  const [finalizeWepaDirectOrder, finalizeWepaDirectOrderRequest] = useMutation<
    FinalizeOrder,
    FinalizeOrderVariables
  >(FINALIZE_ORDER);

  const finalizeOrder = async (order: FinalizeOrderVariables) => {
    await finalizeWepaDirectOrder({
      variables: order
    });
    showSuccessModal();
  };

  const paymentMethod = getPaymentMethodForCompany.data?.getPaymentMethodForCompany;

  const goToCheckoutSession = async () => {
    const currentUrl = new URL(document.URL);

    const response = await client.query<
      getStripeCheckoutSession,
      getStripeCheckoutSessionVariables
    >({
      query: GET_STRIPE_CHECKOUT_SESSION,
      variables: {
        companyId: gmbh.id,
        successUrl: currentUrl.toString(),
        cancelUrl: currentUrl.toString(),
        paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
      }
    });

    const checkoutSessionUrl = response.data.getStripeCheckoutSession?.redirectUrl;
    if (checkoutSessionUrl) {
      locationHelper.redirect(checkoutSessionUrl);
    }
  };

  const validateSession = async (sessionId: string) => {
    try {
      return await client.query<ValidateStripeSession, ValidateStripeSessionVariables>({
        query: VALIDATE_STRIPE_SESSION,
        variables: {
          sessionId,
          paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
        },
        ...getPaymentsContext()
      });
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <>
      <RequestFeedbackHandler
        requests={[updatePaymentMethodRequest, finalizeWepaDirectOrderRequest]}>
        <ConfirmationModal
          dataTestId={"wepa-onboarding-success"}
          title={t("generic:wepa-direct.confirmation-modal.title")}
          message={
            <Trans
              i18nKey={"generic:wepa-direct.confirmation-modal.message"}
              components={{ bold: <strong /> }}
            />
          }
          type={ConfirmationModalType.SUCCESS}
          confirmLabel={t("generic:wepa-direct.confirmation-modal.confirm-button")}
          visible={successModal}
          illustration={"success"}
          onConfirm={async () => {
            setSuccessModal(false);
            window.location.reload();
          }}
          onClose={doNothing}
        />
        <WepaOnboarding
          gmbh={gmbh}
          upsertOrder={upsertOrder}
          updatePaymentMethod={updatePaymentMethod}
          paymentMethod={paymentMethod}
          finalizeOrder={finalizeOrder}
          goToCheckoutSession={goToCheckoutSession}
          validateStripeSession={validateSession}
          sessionId={sessionId}
        />
      </RequestFeedbackHandler>
    </>
  );
};

export default withLocationHelper(withTranslationReady(["generic"])(WepaOnboardingContainer));
