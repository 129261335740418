import React, { useState } from "react";
import { RideDataGapBar } from "uiLibrary/v2/components/RideDataGapBar/RideDataGapBar";
import { DonutCompletionStatus, TaskCardStatusEnum } from "client/components/TaskCard/TaskCard";
import { Typography } from "../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { Trans, useTranslation } from "react-i18next";
import { LoadingBar } from "sharedComponents/LoadingBar/LoadingBar";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import {
  CardWrapper,
  FaqLink,
  ImportMetadata,
  RefreshRageButton,
  UploadButton
} from "./PortfolioDataCompletenessTask.partials";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { GetWepaFileImportById_getWepaFileImportById } from "lib/api/queries/graphql/GetWepaFileImportById";
import { GetWepaPortfolioWithDataAvailability_getWepaPortfolio } from "lib/api/queries/graphql/GetWepaPortfolioWithDataAvailability";
import { GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany } from "lib/api/administration/queries/graphql/GetEndOfYearTasksByCompany";
import { ApolloError } from "apollo-client";

export interface Props {
  index: number;
  isLoading?: boolean;
  isUploading?: boolean;
  uploadError?: { httpStatus: number };
  portfolioError?: ApolloError;
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany;
  portfolio?: GetWepaPortfolioWithDataAvailability_getWepaPortfolio;
  importStatus?: GetWepaFileImportById_getWepaFileImportById;
  onSelectFileUpload: (file: File) => void;
  onClosePortfolio: () => void;
  onCloseTask: () => void;
  onRefresh: () => void;
}

export const PortfolioDataCompletenessTask = ({
  index,
  isLoading,
  isUploading,
  uploadError,
  portfolioError,
  task,
  portfolio,
  importStatus,
  onSelectFileUpload,
  onClosePortfolio,
  onCloseTask,
  onRefresh
}: Props) => {
  const waitingForImport = !task.extra?.uploadId;
  const porfolioName = portfolio?.identifier || "";

  switch (true) {
    case !!portfolioError: {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-portfolio-error-state"
          index={index}
          status={TaskCardStatusEnum.ACTION_REQUIRED}
          portfolioName={porfolioName}>
          <TaskLoadingErrorState portfolioError={portfolioError} />
        </CardWrapper>
      );
    }
    case isLoading || !portfolio: {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-loading-state"
          index={index}
          status={TaskCardStatusEnum.ACTION_REQUIRED}
          portfolioName={porfolioName}>
          <LoadingBar />
        </CardWrapper>
      );
    }
    case !!uploadError: {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-validation-http-error-state"
          index={index}
          status={TaskCardStatusEnum.ACTION_REQUIRED}
          portfolioName={porfolioName}>
          <ValidationHttpErrorState
            uploadError={uploadError}
            onSelectFileUpload={onSelectFileUpload}
          />
        </CardWrapper>
      );
    }
    case waitingForImport: {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-data-gaps-state"
          index={index}
          status={TaskCardStatusEnum.ACTION_REQUIRED}
          portfolioName={porfolioName}>
          <DataGapsState
            portfolio={portfolio}
            task={task}
            onSelectFileUpload={onSelectFileUpload}
            onClosePortfolio={onClosePortfolio}
          />
        </CardWrapper>
      );
    }
    case isUploading || importStatus?.status === "VALIDATION_IN_PROGRESS": {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-validation-in-progress-state"
          index={index}
          status={TaskCardStatusEnum.IN_PROGRESS}
          portfolioName={porfolioName}>
          <ValidationInProgressState />;
        </CardWrapper>
      );
    }
    case importStatus?.status === "VALIDATION_FAILED": {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-validation-failed-state"
          index={index}
          status={TaskCardStatusEnum.IN_PROGRESS}
          portfolioName={porfolioName}>
          <ValidationFailedState
            importStatus={importStatus}
            onSelectFileUpload={onSelectFileUpload}
          />
        </CardWrapper>
      );
    }
    case importStatus?.status === "PENDING_IMPORT": {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-pending-import-state"
          index={index}
          status={TaskCardStatusEnum.IN_PROGRESS}
          portfolioName={porfolioName}>
          <PendingImportState importStatus={importStatus} onRefresh={onRefresh} />
        </CardWrapper>
      );
    }
    case importStatus?.status === "IMPORT_IN_PROGRESS": {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-import-in-progress-state"
          index={index}
          status={TaskCardStatusEnum.IN_PROGRESS}
          portfolioName={porfolioName}>
          <ImportInProgressState importStatus={importStatus} onRefresh={onRefresh} />
        </CardWrapper>
      );
    }
    case importStatus?.status === "JOB_STALLED": {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-job-stalled-state"
          index={index}
          status={TaskCardStatusEnum.IN_PROGRESS}
          portfolioName={porfolioName}>
          <JobStalledState importStatus={importStatus} onRefresh={onRefresh} />
        </CardWrapper>
      );
    }
    case importStatus?.status === "IMPORT_FAILED": {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-import-failed-state"
          index={index}
          status={TaskCardStatusEnum.IN_PROGRESS}
          portfolioName={porfolioName}>
          <ImportFailedState importStatus={importStatus} onSelectFileUpload={onSelectFileUpload} />
        </CardWrapper>
      );
    }
    case importStatus?.status === "IMPORT_COMPLETED": {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-import-completed-state"
          index={index}
          status={TaskCardStatusEnum.IN_PROGRESS}
          portfolioName={porfolioName}
          completion={DonutCompletionStatus.ONE_HUNDRED_PERCENT}>
          <ImportCompletedState onCloseTask={onCloseTask} />
        </CardWrapper>
      );
    }
    default: {
      return (
        <CardWrapper
          data-testid="portfolio-data-completeness-default-state"
          index={index}
          status={TaskCardStatusEnum.ACTION_REQUIRED}
          portfolioName={porfolioName}>
          <LoadingBar />
        </CardWrapper>
      );
    }
  }
};

const DataGapsState = ({
  portfolio,
  task,
  onSelectFileUpload,
  onClosePortfolio
}: Pick<Props, "portfolio" | "task" | "onSelectFileUpload" | "onClosePortfolio">) => {
  const { t } = useTranslation("generic");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isCloseable = canPortfolioBeClosed(task, portfolio!);

  const gaps =
    portfolio?.dataAvailability
      ?.filter((d) => !d.isAvailable)
      .map((d) => ({
        startingDate: new Date(d.dateFrom),
        endingDate: new Date(d.dateTo),
        startingValue: "",
        endingValue: ""
      })) || [];

  return (
    <>
      <div className="mb-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.data-gaps.message", {
            from: task.fiscalYearStart,
            to: task.fiscalYearEnd
          })}
        </Typography>
      </div>
      <RideDataGapBar
        startPeriod={new Date(task.fiscalYearStart)}
        endPeriod={new Date(task.fiscalYearEnd)}
        startValue=""
        endValue=""
        gaps={gaps}
      />
      <div className="d-flex flex-row-reverse justify-content-between mt-3 mb-3">
        <UploadButton onUpload={onSelectFileUpload} />
      </div>
      {isCloseable && (
        <>
          <div className="mt-3">
            <Typography category={"Paragraph"} size={200} weight={"Light"}>
              {t(
                "generic:client-tasks.portfolio-data-completeness.data-gaps.account-closed-prompt"
              )}
            </Typography>
            <RideButton
              variant={RideButtonVariant.GHOST}
              size={RideButtonSize.SMALL}
              label={t("generic:client-tasks.portfolio-data-completeness.data-gaps.close-account")}
              onClick={() => setIsModalOpen(true)}
            />
          </div>
          <ConfirmationModal
            title={t(
              "generic:client-tasks.portfolio-data-completeness.data-gaps.close-account-modal.title"
            )}
            message={t(
              "generic:client-tasks.portfolio-data-completeness.data-gaps.close-account-modal.message"
            )}
            type={ConfirmationModalType.DANGER}
            confirmLabel={t(
              "generic:client-tasks.portfolio-data-completeness.data-gaps.close-account-modal.confirm"
            )}
            visible={isModalOpen}
            onConfirm={onClosePortfolio}
            onClose={() => setIsModalOpen(false)}></ConfirmationModal>
        </>
      )}
    </>
  );
};

const TaskLoadingErrorState = ({ portfolioError }: Pick<Props, "portfolioError">) => {
  const { t } = useTranslation("generic");

  return (
    <>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.portfolio-error.issues-found")}
        </Typography>
        <div className="mb-2">
          <RideAlertBar
            type={AlertType.WARNING}
            message={
              <>
                <div>{portfolioError?.message}</div>
              </>
            }></RideAlertBar>
        </div>
      </div>
      <Typography category={"Paragraph"} size={200} weight={"Light"}>
        <Trans
          i18nKey={"generic:client-tasks.portfolio-data-completeness.portfolio-error.contact-us"}
          components={{ a: <FaqLink /> }}
        />
      </Typography>
    </>
  );
};

const ValidationInProgressState = () => {
  const { t } = useTranslation("generic");

  return (
    <>
      <LoadingBar />
      <Typography category={"Paragraph"} size={200} weight={"Light"}>
        {t("generic:client-tasks.portfolio-data-completeness.validation-in-progress.message")}
      </Typography>
    </>
  );
};

const ValidationHttpErrorState = ({
  uploadError,
  onSelectFileUpload
}: Pick<Props, "onSelectFileUpload" | "uploadError">) => {
  const { t } = useTranslation("generic");

  function getErrorContent(httpErrorCode: number) {
    const isMappedError = [400, 413, 500].includes(httpErrorCode);
    const code = isMappedError ? httpErrorCode : 500;
    return t(
      `generic:client-tasks.portfolio-data-completeness.validation-failed.errors.HTTP_ERROR_${code}.message`
    );
  }

  return (
    <>
      <div className="mb-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          <Trans
            i18nKey={"generic:client-tasks.portfolio-data-completeness.validation-failed.message"}
            components={{ a: <FaqLink /> }}
          />
        </Typography>
      </div>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.validation-failed.issues-found")}
        </Typography>
        <div className="mb-2">
          <RideAlertBar
            type={AlertType.INFO}
            message={
              <>
                <div>{getErrorContent(uploadError?.httpStatus!)}</div>
              </>
            }></RideAlertBar>
        </div>
      </div>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.validation-failed.upload-again")}
        </Typography>
        <div className="d-flex flex-row-reverse justify-content-between">
          <UploadButton onUpload={onSelectFileUpload} />
        </div>
      </div>
    </>
  );
};

const ValidationFailedState = ({
  importStatus,
  onSelectFileUpload
}: Pick<Props, "importStatus" | "onSelectFileUpload">) => {
  const { t } = useTranslation("generic");

  function getErrorContent(code: string) {
    const userMessage = t(
      `generic:client-tasks.portfolio-data-completeness.validation-failed.errors.${code}.message`
    );
    const faqUrl = t(
      `generic:client-tasks.portfolio-data-completeness.validation-failed.errors.${code}.faq`
    );
    return {
      userMessage: userMessage?.length > 0 ? userMessage : null,
      faqUrl: faqUrl?.length > 0 ? faqUrl : null
    };
  }

  return (
    <>
      <div className="mb-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          <Trans
            i18nKey={"generic:client-tasks.portfolio-data-completeness.validation-failed.message"}
            components={{ a: <FaqLink /> }}
          />
        </Typography>
      </div>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.validation-failed.issues-found")}
        </Typography>
        {(importStatus?.validationErrors || []).map(({ code, message }) => {
          const { userMessage, faqUrl } = getErrorContent(code!);

          return (
            <div key={code} className="mb-2">
              <RideAlertBar
                type={AlertType.INFO}
                message={
                  <>
                    <div>{userMessage ? userMessage : message}</div>
                    {faqUrl && (
                      <div>
                        <Trans
                          i18nKey={
                            "generic:client-tasks.portfolio-data-completeness.validation-failed.issue-faq"
                          }
                          values={{ href: faqUrl }}
                          components={{ a: <FaqLink /> }}
                        />
                      </div>
                    )}
                  </>
                }></RideAlertBar>
            </div>
          );
        })}
      </div>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.validation-failed.upload-again")}
        </Typography>
        <div className="d-flex flex-row-reverse justify-content-between">
          <UploadButton onUpload={onSelectFileUpload} />
        </div>
      </div>
    </>
  );
};

const PendingImportState = ({
  importStatus,
  onRefresh
}: Pick<Props, "importStatus" | "onRefresh">) => {
  const { t } = useTranslation("generic");

  return (
    <>
      <ImportMetadata importStatus={importStatus!} />
      <div className="mb-1">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          <Trans
            i18nKey={
              "generic:client-tasks.portfolio-data-completeness.pending-import.queue-position"
            }
            values={{ position_in_queue: importStatus?.positionInQueue }}
            components={{ b: <b /> }}
          />
        </Typography>
      </div>
      <div className="mb-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.pending-import.message")}
        </Typography>
      </div>
      <RefreshRageButton onClick={onRefresh} />
    </>
  );
};

const ImportInProgressState = ({
  importStatus,
  onRefresh
}: Pick<Props, "importStatus" | "onRefresh">) => {
  const { t } = useTranslation("generic");

  return (
    <>
      <LoadingBar />
      <ImportMetadata importStatus={importStatus!} />
      <div className="mb-1">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.import-in-progress.message")}
        </Typography>
      </div>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.import-in-progress.latest-status")}
        </Typography>
        <RideAlertBar
          type={AlertType.INFO}
          message={importStatus?.lastInformationLog?.message}></RideAlertBar>
      </div>
      <RefreshRageButton onClick={onRefresh} />
    </>
  );
};

const JobStalledState = ({
  importStatus,
  onRefresh
}: Pick<Props, "importStatus" | "onRefresh">) => {
  const { t } = useTranslation("generic");

  return (
    <>
      <ImportMetadata importStatus={importStatus!} />
      <div className="mb-1">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.job-stalled.message")}
        </Typography>
      </div>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.job-stalled.latest-status")}
        </Typography>
        <RideAlertBar
          type={AlertType.WARNING}
          message={
            <>
              <div>
                {t("generic:client-tasks.portfolio-data-completeness.job-stalled.last-updated-at", {
                  last_update: importStatus?.lastInformationLog?.occurredAt
                })}
              </div>
              <div>{importStatus?.lastInformationLog?.message}</div>
            </>
          }></RideAlertBar>
      </div>
      <Typography category={"Paragraph"} size={200} weight={"Light"}>
        {t("generic:client-tasks.portfolio-data-completeness.job-stalled.contact-us")}
      </Typography>
      <div className="mt-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          <RefreshRageButton onClick={onRefresh} />
        </Typography>
      </div>
    </>
  );
};

const ImportFailedState = ({
  importStatus,
  onSelectFileUpload
}: Pick<Props, "importStatus" | "onSelectFileUpload">) => {
  const { t } = useTranslation("generic");

  return (
    <>
      <div className="mb-1">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.import-failed.message")}
        </Typography>
      </div>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.import-failed.issues-found")}
        </Typography>
        {(importStatus?.importErrors || []).map(({ code, message }) => {
          return (
            <div key={code} className="mb-2">
              <RideAlertBar type={AlertType.WARNING} message={message} />
            </div>
          );
        })}
      </div>
      <Typography category={"Paragraph"} size={200} weight={"Light"}>
        <Trans
          i18nKey={"generic:client-tasks.portfolio-data-completeness.import-failed.contact-us"}
          components={{ a: <FaqLink /> }}
        />
      </Typography>
      <div className="my-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.import-failed.upload-again")}
        </Typography>
        <div className="d-flex flex-row-reverse justify-content-between">
          <UploadButton onUpload={onSelectFileUpload} />
        </div>
      </div>
    </>
  );
};

const ImportCompletedState = ({ onCloseTask }: Pick<Props, "onCloseTask">) => {
  const { t } = useTranslation("generic");

  return (
    <>
      <div className="mb-3">
        <Typography category={"Paragraph"} size={200} weight={"Light"}>
          {t("generic:client-tasks.portfolio-data-completeness.import-completed.message")}
        </Typography>
      </div>
      <div className="d-flex flex-row-reverse justify-content-between mt-3 mb-3">
        <RideButton
          variant={RideButtonVariant.SECONDARY}
          size={RideButtonSize.SMALL}
          label={t("generic:client-tasks.portfolio-data-completeness.import-completed.close-task")}
          onClick={onCloseTask}
        />
      </div>
    </>
  );
};

/**
 * Portfolio can be closed if the following conditions are met:
 * - Last isAvailable=true date range has isLast=true.
 * - There is an isAvailable=false date range right after it, until the end of the year.
 * - The fiscal year is not ongoing (date_to is in the past).
 */
export function canPortfolioBeClosed(
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany,
  portfolio: GetWepaPortfolioWithDataAvailability_getWepaPortfolio
) {
  if (!portfolio?.dataAvailability?.length) return false;

  const lastAvailableRange = portfolio.dataAvailability.find((d) => d.isAvailable && d.isLast);
  const unavailableRange = portfolio.dataAvailability.find(
    (d) => !d.isAvailable && new Date(d.dateFrom) >= new Date(lastAvailableRange?.dateTo)
  );

  const fiscalYearEnd = new Date(task.fiscalYearEnd as any);
  const now = new Date();

  return !!(
    lastAvailableRange &&
    unavailableRange &&
    new Date(unavailableRange.dateTo).getDate() === fiscalYearEnd.getDate() &&
    fiscalYearEnd < now
  );
}
