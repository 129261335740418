import React from "react";
import { getAllShelfCompanyOrders_getAllShelfCompanyOrders } from "../../../lib/api/queries/graphql/getAllShelfCompanyOrders";
import ShelfCompanyOrdersTable from "../ShelfCompanyOrdersTable/ShelfCompanyOrdersTable";

interface ShelfCompanyOrderProps {
  orders?: getAllShelfCompanyOrders_getAllShelfCompanyOrders | null;
}
const ShelfCompanyOrder = ({ orders }: ShelfCompanyOrderProps) => {
  const ordersList = orders?.orders ?? [];

  return <ShelfCompanyOrdersTable orders={ordersList} />;
};

export default ShelfCompanyOrder;
