import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  GetFormerClientAssignments,
  GetFormerClientAssignmentsVariables
} from "./graphql/GetFormerClientAssignments";

const GET_FORMER_CLIENT_ASSIGNMENTS = gql`
  query GetFormerClientAssignments(
    $clientId: String
    $advisorId: String
    $start: Int
    $limit: Int
  ) {
    getFormerClientAssignments(
      clientId: $clientId
      advisorId: $advisorId
      start: $start
      limit: $limit
    ) {
      count
      items {
        id
        type
        startFiscalYear
        endFiscalYear
        createdAt
        updatedAt
        client {
          id
          name
          legalForm
        }
        advisor {
          id
          name
          legalForm
        }
      }
    }
  }
`;

export const GetFormerClientAssignmentsQuery = (variables: GetFormerClientAssignmentsVariables) =>
  useQuery<GetFormerClientAssignments, GetFormerClientAssignmentsVariables>(
    GET_FORMER_CLIENT_ASSIGNMENTS,
    { variables }
  );
