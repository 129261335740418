import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const WepaIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="wepa-icon"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5283_57)">
      <path
        d="M16.5 20.3848L14.5575 18.4424L13.5 19.5L16.5 22.5L22.5 16.5L21.4424 15.4425L16.5 20.3848Z"
        fill="currentColor"
      />
      <path d="M12 12.75H6.75V14.25H12V12.75Z" fill="currentColor" />
      <path d="M15.75 9H6.75V10.5H15.75V9Z" fill="currentColor" />
      <path d="M15.75 5.25H6.75V6.75H15.75V5.25Z" fill="currentColor" />
      <path
        d="M12 22.5H4.5C3.67275 22.5 3 21.8271 3 21V3C3 2.1729 3.67275 1.5 4.5 1.5H18C18.8273 1.5 19.5 2.1729 19.5 3V14.25H18V3H4.5V21H12V22.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5283_57">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
