import React from "react";
import { RideUrls } from "common/URLs";
import { WithTranslation } from "react-i18next";
import { DiscordIcon } from "../icons/DiscordIcon";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideSticker, RideStickerBackgroundColorVariant } from "uiLibrary/components";

export interface DiscordStickerProps extends WithTranslation {
  testid: string;
  stickerColorVariant: RideStickerBackgroundColorVariant;
}

const DiscordSticker = ({ t, testid, stickerColorVariant }: DiscordStickerProps) => (
  <a
    href={RideUrls.DiscordCommunityInviteURL}
    target="_blank"
    className="ride-discord-sticker"
    rel="noopener noreferrer"
    data-testid={`${testid}-discord-sticker`}>
    <RideSticker
      text={t("generic:community-discord-text")}
      icon={<DiscordIcon className="ride-sticker__icon" />}
      stickerColorVariant={stickerColorVariant}
    />
  </a>
);

export default withTranslationReady(["generic"])(DiscordSticker);
