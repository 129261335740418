import { useQuery } from "@apollo/react-hooks";
import { OperationVariables } from "@apollo/react-common";
import { DocumentNode } from "graphql";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import { getAdministrationContext } from "lib/api/administration/context";

export const useAdministrationQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => {
  return useQuery(query, { ...options, ...getAdministrationContext() });
};
