import React from "react";
import TextInput from "../../TextInput";
import { FormikProps } from "formik";
import InputPhone from "../../InputPhone";
import ReactSelect from "react-select";
import { ErrorMessage } from "../../ErrorMessage";
import { dateFieldFor } from "../../commonFields";
import { minAgeThresholdDate } from "../../../../common/ageValidator/constants";
import { getPreSelectedDate } from "../../../../common/ageValidator/getPreSelectedDate";

export const textArrayFieldFor = (
  t,
  values,
  formik: FormikProps<any>,
  prefix,
  name,
  label,
  touched,
  errors,
  extra = {}
) => (
  <>
    <TextInput
      className={"text-input"}
      t={t}
      name={prefix + name}
      label={label}
      type="text"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={values[name]}
      errorComponent={extra["errorComponent"] ?? ErrorMessage}
      isValid={(touched ?? {})[name] && !(errors ?? {})[name]}
      isInvalid={(touched ?? {})[name] && !!(errors ?? {})[name]}
      extra={extra}
      {...extra}
    />
  </>
);

export const phoneFieldFor = (
  t,
  formik,
  value,
  prefix,
  defaultCountry,
  name,
  label,
  handleChange,
  extra = {}
) => (
  <>
    <InputPhone
      defaultCountry={defaultCountry}
      name={prefix + name}
      value={value}
      label={label}
      onChange={handleChange}
      disabled={extra["disabled"] ?? false}
    />
    <ErrorMessage name={prefix + name} t={t} extra={extra} />
  </>
);

export const dateOfBirthFieldFor = (
  t,
  formik,
  values,
  name,
  label,
  dateFormat,
  selectedDate,
  prefix,
  touched,
  errors,
  extra = {}
) => {
  const preSelectedDate = getPreSelectedDate(selectedDate, 40);

  return dateFieldFor(
    t,
    formik,
    formik.values,
    name,
    label,
    dateFormat,
    selectedDate,
    preSelectedDate,
    minAgeThresholdDate(),
    prefix,
    touched,
    errors,
    { ...extra }
  );
};

const selectStyles = {
  menu: (provided) => ({ ...provided, zIndex: 2 })
};

export const selectArrayFieldFor = (
  t,
  values,
  formik,
  name,
  label,
  options,
  defaultOptions,
  prefix,
  extra
) => (
  <div data-testid={`${prefix + name}-select-container`}>
    <label>{label}</label>
    <ReactSelect
      className="select-field"
      name={prefix + name}
      options={options}
      isSearchable={true}
      defaultValue={defaultOptions}
      value={options.find((option) => option.value === `${values[name]}`)}
      onChange={(selectedOption) => formik.handleChange(prefix + name)(selectedOption?.value)}
      onBlur={() => formik.setFieldTouched(prefix + name, true)}
      styles={selectStyles}
      {...extra}
    />
    <ErrorMessage name={prefix + name} t={t} extra={extra} />
  </div>
);
