import React from "react";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import { TaskStatesEnum } from "../../../../../global-query-types";
import { WithTranslation } from "react-i18next";
import TaskContainer from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainer";
import { ClickToCopy } from "../../../../../sharedComponents/ClickToCopy/ClickToCopy";

export interface IbanViewTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const IbanViewTask = ({ task, onChange, t }: IbanViewTaskProps) => {
  const iban: string =
    task.options?.data?.value || t(`company-founding-steps:tasks.${task.ref}.IBAN-placeholder`);

  return (
    <TaskContainer testid="iban-view" translationKey={task.ref} task={task} onChange={onChange}>
      <div className="iban-view-task" data-testid="iban-view-task">
        <div className="iban-view-task__value">
          {t(`company-founding-steps:tasks.${task.ref}.IBAN`)}
          <div className="iban-view-task__iban" data-testid="iban-view-value">
            {iban}
          </div>
        </div>

        <div className="iban-view-task__action">
          <ClickToCopy
            value={iban}
            label={t(`company-founding-steps:tasks.${task.ref}.copy-button`)}
            tooltipContent={t(`company-founding-steps:tasks.${task.ref}.confirmation`)}
          />
        </div>
      </div>
    </TaskContainer>
  );
};

export default withTranslationReady("client")(IbanViewTask);
