import React from "react";
import { WithTranslation } from "react-i18next";
import { TaskStatesEnum } from "../../../../../global-query-types";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import { textFieldFor } from "../../../../../components/generic/commonFields";
import * as Yup from "yup";
import { useFormik } from "formik";
import { taxNumberValidationWithTranslation } from "../../../../../lib/validation/TaxNumberSchema";
import { TaskContainerWithFormik } from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainerWithFormik";

interface EnterTaxNumberTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

export const EnterTaxNumberTask = ({ task, onChange, t }: EnterTaxNumberTaskProps) => {
  const isStateToBeDone = task.state === TaskStatesEnum.TO_BE_DONE;
  const nextState = isStateToBeDone ? TaskStatesEnum.COMPLETED : TaskStatesEnum.TO_BE_DONE;

  const validationSchema = isStateToBeDone
    ? Yup.object().shape({
        enterTaxNumber: taxNumberValidationWithTranslation(t)
      })
    : null;

  const getTaxComponentBasedOnState = () =>
    isStateToBeDone ? (
      textFieldFor(
        t,
        formik,
        "enterTaxNumber",
        t(`company-founding-steps:tasks.name.enterTaxNumber`),
        {
          className: "text-input form-control",
          placeholder: t(`company-founding-steps:tasks.tax-number-placeholder.enterTaxNumber`)
        }
      )
    ) : (
      <div data-testid="enterTaxNumber-completed" className="notary-appointment-task__completed">
        {task.options?.data?.value}
      </div>
    );

  const formik = useFormik({
    initialValues: {
      enterTaxNumber: task.options?.data?.value ?? ""
    },
    onSubmit: (values) =>
      onChange(nextState, {
        type: "text",
        data: {
          value: values["enterTaxNumber"]
        }
      }),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema
  });

  return (
    <TaskContainerWithFormik
      formik={formik}
      testid="enterTaxNumber"
      translationKey={task.ref}
      task={task}>
      <div className="enter-tax-number-task">{getTaxComponentBasedOnState()}</div>
    </TaskContainerWithFormik>
  );
};

export default withTranslationReady(["company-founding-steps", "generic"])(EnterTaxNumberTask);
