import TaskContainer, { TaskContainerProps } from "./TaskContainer";
import { Form, FormikContextType, FormikProvider } from "formik";
import React from "react";
import { WithTranslation } from "react-i18next";

interface TaskContainerWithFormikProps extends Omit<TaskContainerProps, keyof WithTranslation> {
  formik: FormikContextType<any>;
}

export const TaskContainerWithFormik = (props: TaskContainerWithFormikProps) => {
  return (
    <FormikProvider value={props.formik}>
      <Form>
        <TaskContainer {...props} />
      </Form>
    </FormikProvider>
  );
};
