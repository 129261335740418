import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { EntryDateField } from "../../../../../generic/commonFields";

export interface IProps {
  t: TFunction;
  formik;
}

export const EntryDateFields = ({ t, formik }: IProps) => {
  const entryDateFieldData = [
    {
      name: "notarizationDate",
      label: t("nominee-dashboard:company.notarization-date")
    },
    {
      name: "applicationForEntryDate",
      label: t("nominee-dashboard:company.application-for-entry-date")
    },
    {
      name: "commercialRegisterEntryDate",
      label: t("nominee-dashboard:company.commercial-register-entry-date")
    }
  ];

  return (
    <Row className="mt-5">
      {entryDateFieldData.map((elem, idx) => (
        <Col xs={6} md={4} key={idx}>
          <EntryDateField t={t} formik={formik} name={elem.name} label={elem.label} />
        </Col>
      ))}
    </Row>
  );
};
