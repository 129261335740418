import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided_client_brokerageAccounts,
  GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided_client_group_memberships
} from "../../graphql/GetMyTaxAdvisorCompanyWithClients";
import EntityLink from "../../../components/common/EntityLink/EntityLink";
import { TaxAdvisorStatusEnum, UserRole } from "../../../global-query-types";
import { formatCompanyName } from "../../../common/formatters/CompanyNameFormatter";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import { TFunction } from "i18next";
import { IconVariant, RideTile } from "../../../sharedComponents/RideTile/RideTile";
import { BrokerageAccountStatus } from "../../../sharedComponents/BrokerageAccountStatus/BrokerageAccountStatus";
import { Link, useHistory } from "react-router-dom";

interface TaxAdvisorClientCardLegacyProps extends WithTranslation {
  taxAdvisorStatus: TaxAdvisorStatusEnum | null;
  clientLegalForm: string | null | undefined;
  clientId: string | null | undefined;
  clientName: string | null | undefined;
  brokerageAccounts:
    | GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided_client_brokerageAccounts[];
  clientGroupMemberships:
    | GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided_client_group_memberships[]
    | null
    | undefined;
  endFiscalYear?: number;
}

export const TaxAdvisorStatusBadge = ({
  taxAdvisorStatus,
  endFiscalYear,
  t
}: {
  taxAdvisorStatus: TaxAdvisorStatusEnum;
  endFiscalYear?: number;
  t: TFunction;
}) => {
  let color: RideBadgeColorsEnum;
  let label: string;

  if (endFiscalYear !== undefined) {
    label = t("tax-service:tax-advisor.status-active-until", { fiscalYear: endFiscalYear });
    color = RideBadgeColorsEnum.SUCCESS;
  } else if (taxAdvisorStatus === TaxAdvisorStatusEnum.ACTIVE) {
    label = t("tax-service:tax-advisor.status-active");
    color = RideBadgeColorsEnum.SUCCESS;
  } else {
    label = t("tax-service:tax-advisor.status-assigned");
    color = RideBadgeColorsEnum.DANGER;
  }

  return <RideBadgeStatus color={color} label={label} variant={RideBadgeVariantsEnum.OUTLINED} />;
};

const Shareholders = ({
  memberships,
  t
}: {
  memberships: GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided_client_group_memberships[];
  t: TFunction;
}) => (
  <div>
    {memberships
      ?.filter((member) => member.role === "Shareholder")
      .map((membership, index) => (
        <span key={index}>
          {index !== 0 && ", "}
          {!membership.company ? (
            <EntityLink
              asUser={UserRole.TaxAdvisor}
              membership={membership}
              testIdPrefix="membership"
            />
          ) : (
            <span data-testid="membership-company">{formatCompanyName(t, membership.company)}</span>
          )}
        </span>
      ))}
  </div>
);

/**
 * @deprecated
 */
const TaxAdvisorClientCardLegacy = ({
  clientId,
  clientName = "",
  clientLegalForm = "",
  taxAdvisorStatus,
  clientGroupMemberships,
  brokerageAccounts,
  endFiscalYear,
  t
}: TaxAdvisorClientCardLegacyProps) => {
  const history = useHistory();

  const name = formatCompanyName(t, {
    name: clientName,
    legalForm: clientLegalForm
  });

  const subTitleSlot = (
    <>
      {taxAdvisorStatus && (
        <TaxAdvisorStatusBadge
          taxAdvisorStatus={taxAdvisorStatus}
          endFiscalYear={endFiscalYear}
          t={t}
        />
      )}
      {<BrokerageAccountStatus brokerageAccounts={brokerageAccounts} t={t} />}
    </>
  );

  const detailsSlot = (
    <div
      data-testid="card-shareholder"
      className="tax-advisor-client-card-legacy__list"
      onClick={(event) => event.stopPropagation()}>
      {clientGroupMemberships && clientGroupMemberships.length > 0 && (
        <>
          <div className="key">{t("tax-advisor:clients.card.shareholders")}</div>
          <Shareholders t={t} memberships={clientGroupMemberships} />
        </>
      )}
    </div>
  );

  const linkToClient = `/tax-advisor/${
    endFiscalYear ? "former-clients" : "client-entities"
  }/${clientId}`;

  const nameHeader = (
    <Link to={linkToClient} className="text-reset" data-testid="tax-advisor-client-card-header">
      {name}
    </Link>
  );

  return (
    <RideTile
      data-testid="tax-advisor-client-card"
      name={nameHeader}
      subTitleSlot={subTitleSlot}
      detailsSlot={detailsSlot}
      iconVariant={IconVariant.arrowIcon}
      onClick={() => {
        history.push(linkToClient);
      }}
    />
  );
};

export default withTranslationReady(["tax-advisor", "tax-service"])(TaxAdvisorClientCardLegacy);
