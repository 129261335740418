import React, { useState } from "react";
import RequestFeedbackHandler from "../../../../../components/generic/RequestFeedbackHandler";
import CompanyAssetsChoice from "./CompanyAssetsChoice";
import { useMutation } from "react-apollo";
import { UPDATE_COMPANY_ASSETS } from "../../../../../lib/api/mutations/UPDATE_COMPANY_ASSETS";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../../../components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import { CompanyAssets, FlowTypeEnum } from "../../../../../global-query-types";
import * as Sentry from "@sentry/react";

export const CompanyAssetsChoiceContainer = ({
  companyCreation,
  refetch,
  currentFlowType
}: {
  companyCreation: MyCompanyCreationQuery_myCompanyCreation | undefined;
  refetch: (companyCreationId: string) => Promise<void>;
  currentFlowType: FlowTypeEnum;
}) => {
  const [updateCompanyAssetsMutation, updateCompanyAssetsRequest] =
    useMutation(UPDATE_COMPANY_ASSETS);

  const [isLoading, setIsLoading] = useState(false);

  const onSave = async (assets: CompanyAssets[]) => {
    try {
      setIsLoading(true);
      await updateCompanyAssetsMutation({
        variables: {
          companyCreationId: companyCreation?.id,
          assets
        }
      });

      await refetch(companyCreation?.id || "");
    } catch (error) {
      Sentry.captureException(error);
    }

    setIsLoading(false);
  };

  const selectedAssets = companyCreation?.company?.assets || [];

  return (
    <RequestFeedbackHandler requests={[updateCompanyAssetsRequest]} forceLoading={isLoading}>
      <CompanyAssetsChoice onSave={onSave} selectedAssets={selectedAssets} />
    </RequestFeedbackHandler>
  );
};
