import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import ReactSelect from "react-select";
import { getAllCompanies_companies } from "../../../lib/api/queries/graphql/getAllCompanies";
import uniqBy from "lodash/uniqBy";
import {
  NomineeCompanyDetails_companyById,
  NomineeCompanyDetails_companyById_group_memberships_person_memberships_group_company
} from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface AddExistingHoldingCompanyProps extends WithTranslation {
  companies: NomineeCompanyDetails_companyById_group_memberships_person_memberships_group_company[];
  assetCompany: NomineeCompanyDetails_companyById | null;
  onSubmit: (holdingCompany: string) => {};
}

export const AddExistingHoldingCompany = ({
  companies,
  assetCompany,
  onSubmit,
  t
}: AddExistingHoldingCompanyProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = () => {
    setShowDropdown(true);
  };

  const holdingCompanyIds =
    assetCompany?.group?.memberships?.map((membership) => membership.company?.id) ?? [];

  const fillBlankCompanyName = (company: getAllCompanies_companies) => {
    if (company.name.trim().length === 0) return "Unnamed GmbH";
    return company.name;
  };

  const filteredCompanies = companies.filter(
    (company) => company.id !== assetCompany?.id && !holdingCompanyIds.includes(company.id)
  );

  const companyOptions = uniqBy(filteredCompanies, (company) => company.id).map((company) => {
    return { value: company.id, label: fillBlankCompanyName(company) };
  });

  const [companyId, setCompanyId] = useState<string>(companyOptions[0]?.value);

  const holdingCompanyValue = companyOptions.find((option) => {
    return option.value === companyId;
  });

  return (
    <div data-testid="add-existing-holding-company-container">
      {showDropdown ? (
        <div data-testid="holdingCompany-select-container">
          <ReactSelect
            id="holding-company"
            isSearchable={true}
            placeholder={t("nominee-dashboard:roles.placeholder")}
            name="holdingCompany"
            options={companyOptions}
            value={holdingCompanyValue}
            className="mb-4 mt-4"
            onChange={(selectedOption) => setCompanyId(selectedOption?.value ?? "")}
          />
          <RideButtonDeprecated
            variant={ButtonVariant.Primary}
            size={ButtonSize.Small}
            type="button"
            data-testid="existing-company-submit"
            onClick={() => onSubmit(companyId)}>
            {t("generic:add")}
          </RideButtonDeprecated>
        </div>
      ) : (
        <RideButtonDeprecated
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          type="button"
          onClick={handleClick}
          data-testid="add-existing-holding-company-button">
          <strong>{t("nominee-dashboard:roles.add-holding-company")}</strong>
        </RideButtonDeprecated>
      )}
    </div>
  );
};

export default withTranslationReady(["generic"])(AddExistingHoldingCompany);
