import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import CompanyTabbedNavigation, {
  CompanyTabbedNavigationValue
} from "client/components/CompanyTabbedNavigation/CompanyTabbedNavigation";
import { CompanyFoundInProgressHeader } from "../CompanyFoundInProgressHeader/CompanyFoundInProgressHeader";
import ClientTags from "components/common/ClientTags/ClientTags";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import ReturnLink from "../../../uiLibrary/v2/components/ReturnLink/ReturnLink";
import { getCompanyNameOrDefault } from "../../../lib/formatters/companyFormatter";

interface IProps {
  gmbh: GmbHDetails;
  goBackLink?: string;
  viewMode?: CompanyTabbedNavigationValue;
  handleViewModeChange?: (value) => void;
  showClientTags?: boolean;
  showTabbedNavigation?: boolean;
}

export const CompanyHeaderSection = ({
  gmbh,
  viewMode = CompanyTabbedNavigationValue.PRIMARY_DATA,
  handleViewModeChange = undefined,
  goBackLink,
  showClientTags = false,
  showTabbedNavigation = true
}: IProps) => {
  const moduleName = "company-header-section";

  return (
    <Container data-testid="header-section" className={moduleName} fluid>
      <Container className="company-header-section__container">
        {goBackLink && (
          <Row noGutters>
            <Col>
              <ReturnLink to={goBackLink} />
            </Col>
          </Row>
        )}

        <div className={`${moduleName}__company-info`}>
          <div className={`${moduleName}__company-name`}>
            <h1 data-testid="company-title" className={`${moduleName}__company-name__title`}>
              {getCompanyNameOrDefault(gmbh.name)}
            </h1>
          </div>
          <div className={`${moduleName}__company-status`}>
            {gmbh.isInFoundation && <CompanyFoundInProgressHeader gmbh={gmbh} />}
          </div>
        </div>

        {showClientTags && (
          <Row>
            <Col className={`${moduleName}__client-tags`}>
              <ClientTags client={gmbh.clientTagsInput()} />
            </Col>
          </Row>
        )}

        {showTabbedNavigation && (
          <div className="client-company__company-tabbed-navigation-container">
            <CompanyTabbedNavigation value={viewMode} onChange={handleViewModeChange} gmbh={gmbh} />
          </div>
        )}
      </Container>
    </Container>
  );
};
