import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { formatDateTime } from "common/formatters";
import { ValueView } from "components/common/layout/ValueView";
import ConfirmationPrompt from "components/generic/ConfirmationPrompt";
import UserFinApiAccountStatus from "../UserFinApiAccountStatus/UserFinApiAccountStatus";
import { FullPersonDetailsQuery_getPersonDetails_user } from "components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";

interface InviteClientProps extends WithTranslation {
  user?: FullPersonDetailsQuery_getPersonDetails_user | null;
  sendClientInvite: (id: string) => Promise<void>;
}

const InviteClient = ({ t, user, sendClientInvite }: InviteClientProps) => {
  const [confirm, setConfirm] = useState(false);
  const invitedAt = user?.invitedAt ?? "";
  const hasFinApiAccount = user?.hasFinApiAccount ?? false;
  const inviteConfirmationMessage = t("nominee-dashboard:company-creation.invite-confirmation");

  const handleClick = async () => {
    setConfirm(true);
  };

  const handleClose = () => setConfirm(false);

  const sendInviteConfirmation = async () => {
    await sendClientInvite(user?.id!);
  };

  const { buttonText, label } = invitedAt.length
    ? { buttonText: t("generic:re-invite"), label: t("generic:invite-sent-alert") }
    : { buttonText: t("generic:invite"), label: "" };

  return (
    <>
      {confirm ? (
        <ConfirmationPrompt
          agree={confirm}
          handleClose={handleClose}
          handleConfirmation={sendInviteConfirmation}
          message={inviteConfirmationMessage}
        />
      ) : (
        ""
      )}
      <Row>
        <Col xs="auto">
          <ValueView
            t={t}
            testId="client-invited-at"
            name="nominee-dashboard:company-creation.invite-status"
            value={formatDateTime(invitedAt)}
          />
        </Col>

        <Col>
          <Button
            data-testid="invite-btn"
            size="sm"
            onClick={handleClick}
            hidden={hasFinApiAccount}>
            {buttonText}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <UserFinApiAccountStatus hasFinApiAccount={user?.hasFinApiAccount ?? false} />
        </Col>
        <Col>
          <span
            className={`badge badge-warning`}
            data-testid="invite-sent-alert"
            hidden={hasFinApiAccount}>
            {label}
          </span>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(InviteClient);
