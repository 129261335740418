import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { FlowTypeEnum } from "global-query-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { ClientSelect } from "client/components/ClientSelect/ClientSelect";
import * as Yup from "yup";
import { deepClone } from "common/deepClone";
import { getCompanyNameSuffixesByFlowType } from "lib/formatters/config";

const gmbhOptions: { value: string; label: string }[] = getCompanyNameSuffixesByFlowType(
  FlowTypeEnum.GMBH
).map((suffix) => ({
  value: suffix,
  label: `${suffix} GmbH`
}));

const CompanyName = (props: WithTranslation & StepComponentProps) => {
  let currentCompanyName: string = props.order.extra?.companyData?.companyName ?? "";
  const hasCurrentNameWithValidGmbHOption = gmbhOptions.find((suffix) =>
    currentCompanyName.endsWith(suffix.value)
  );

  let currentGmbHOption = hasCurrentNameWithValidGmbHOption?.value ?? gmbhOptions[0].value;

  if (hasCurrentNameWithValidGmbHOption) {
    const index = currentCompanyName.lastIndexOf(currentGmbHOption);
    currentCompanyName = currentCompanyName.substring(0, index).trim();
  } else if (currentCompanyName) {
    currentGmbHOption = " ";
  }

  const initialValues = {
    companyName: currentCompanyName,
    gmbhOption: currentGmbHOption
  };

  const onSubmit = async (values: typeof initialValues) => {
    const companyDataCopy = deepClone(props.order.extra?.companyData ?? {});

    companyDataCopy.companyName = `${values.companyName} ${values.gmbhOption}`.trim();
    companyDataCopy.legalForm = "GmbH";

    props.currentStep.complete();
    await props.saveData(
      {
        ...props.order.extra,
        companyData: companyDataCopy,
        ...props.currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const CompanyNameSchema = (t) => {
    const gmbhRegex = /^((?!gmbh).)*$/;
    return Yup.object().shape({
      companyName: Yup.string()
        .required(t("generic:validation-required"))
        .test("gmbhName", t("generic:validation-gmbh-name"), (value) => {
          return !!(value && value.toLowerCase().match(gmbhRegex));
        })
    });
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: CompanyNameSchema(props.t)
  });

  return (
    <div data-testid="company-name">
      <FormikProvider value={formik}>
        <Form>
          <StepHeading text={props.t("generic:company-setup.steps.company-name.title")} />
          <Row>
            <Col>
              <RideTextInput
                name={"companyName"}
                label={props.t("generic:company-setup.steps.company-name.company-name")}
                className="company-name"
              />
            </Col>
            <Col className={"company-name__select"}>
              <ClientSelect
                options={gmbhOptions}
                name={"gmbhOption"}
                alignCaretRight={true}
                placeholder={props.t("generic:legal-form-suffix")}
              />
            </Col>
          </Row>
          {props.controls}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic"])(CompanyName);
