import { Observable } from "apollo-link";

// Wraps a promise into a obversable to make it awaitable for the apollo client implementation, based on https://github.com/apollographql/apollo-link/issues/646
export const promiseToObservable = (promise: Promise<any>) =>
  new Observable((subscriber: any) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });
