import { useMutation } from "react-apollo";
import logger from "../../common/Logger";
import { SET_ARCHIVE_STATUS } from "../../lib/api/mutations/SET_ARCHIVE_STATUS";
import {
  SetArchiveStatus,
  SetArchiveStatusVariables
} from "../../lib/api/mutations/graphql/SetArchiveStatus";

export const useDeleteAdminService = () => {
  const [setArchiveStatusMutation, setArchiveStatusRequest] = useMutation<
    SetArchiveStatus,
    SetArchiveStatusVariables
  >(SET_ARCHIVE_STATUS);

  const archiveAdminService = async (id: string) => {
    try {
      await setArchiveStatusMutation({
        variables: { companyCreationId: id }
      });
    } catch (error) {
      logger.errorMessage(`Unable to delete admin service:`);
      logger.error(error);
    }
  };

  return { archiveAdminService, setArchiveStatusRequest };
};
