import * as React from "react";
import { useQuery } from "react-apollo";
import { REAL_ESTATE_OWNERSHIP_QUERY_BY_REALESTATE_ID } from "./Queries";
import {
  RealEstateOwnershipQueryByRealEstateId,
  RealEstateOwnershipQueryByRealEstateIdVariables
} from "./graphql/RealEstateOwnershipQueryByRealEstateId";

export const GetRealEstateOwnershipInfoByRealEstateId = (realEstateId: string) => {
  const [firstRender, setFirstRender] = React.useState(true);

  const {
    loading: ownershipLoading,
    error: ownershipErrorLoading,
    data: OwnershipDetails
  } = useQuery<
    RealEstateOwnershipQueryByRealEstateId,
    RealEstateOwnershipQueryByRealEstateIdVariables
  >(REAL_ESTATE_OWNERSHIP_QUERY_BY_REALESTATE_ID, {
    variables: { realEstateId: realEstateId },
    fetchPolicy: firstRender ? "network-only" : undefined,
    onCompleted: () => setFirstRender(false)
  });

  return { ownershipLoading, ownershipErrorLoading, OwnershipDetails };
};
