import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  ActionableTask,
  ActionableTaskOptions,
  ProcessStates
} from "common/types/ProcessStatesTypes";
import { Form, FormikProvider, useFormik } from "formik";
import { TaskStatesEnum, TaskType, UserRole } from "global-query-types";
import { TFunction } from "i18next";
import { getCompanyCreationSteps_getCompanyCreationSteps } from "lib/api/queries/graphql/getCompanyCreationSteps";
import { uniq } from "lodash";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import EditableSection from "sharedComponents/EditableSection/EditableSection";
import {
  EnrichedData,
  SelectOption,
  TASK_INPUT_PREFIX,
  TASK_STATUS_PREFIX
} from "../ProcessStatesSection/ProcessStatesSection";
import ProcessOptionsService from "../ProcessStatesSection/lib/ProcessOptionsService";
import { ProcessStatesTableView, ProcessStatesTableEdit } from "./ProcessStatesTable.partials";

interface ProcessStatesTableProps extends WithTranslation {
  onSave: (
    processStates: ProcessStates,
    tasks: { ref: string; state: TaskStatesEnum; options: ActionableTaskOptions }[]
  ) => Promise<void>;
  processStepsWithTasks: getCompanyCreationSteps_getCompanyCreationSteps[];
  id: string;
  onPreview: () => void;
}

interface UpdatedValue {
  ref: string;
  data: any;
}

interface UpdatedTaskData {
  ref: string;
  state: TaskStatesEnum;
  options: ActionableTaskOptions;
}

const STEP_STATE_LIST = ["TO_BE_DONE", "IN_PROGRESS", "COMPLETED", "NOT_REQUIRED"];

const ProcessStatesTable = ({
  onPreview,
  onSave,
  processStepsWithTasks,
  id,
  t
}: ProcessStatesTableProps) => {
  const [editMode, setEditMode] = useState(false);
  const initialValues = {};

  const stateOptions = (states: string[], selected: string, t: TFunction): SelectOption[] => {
    return uniq([...states, selected]).map((state: string) => ({
      value: state,
      label: t(`company-founding-steps:states.${state}`)
    }));
  };

  if (ProcessOptionsService.instance.id !== id) {
    ProcessOptionsService.instance = new ProcessOptionsService();
    ProcessOptionsService.instance.id = id;
  }

  const data = processStepsWithTasks.reduce(
    (accumulator: EnrichedData[], processStep: getCompanyCreationSteps_getCompanyCreationSteps) => {
      for (const step of processStep?.steps ?? []) {
        const datum: EnrichedData = {
          name: processStep.ref ?? "",
          ref: step.ref ?? "",
          state: step.state ?? "",
          tasks:
            step.tasks?.map(
              (task): ActionableTask => ({
                ref: task.ref ?? "",
                state: task.state ?? TaskStatesEnum.NOT_REQUIRED,
                responsibleParty: task.responsibleParty ?? UserRole.Nominee,
                type: task.type ?? TaskType.GENERIC,
                updatedAt: task.updatedAt,
                options: task.options
              })
            ) ?? [],
          options: stateOptions(STEP_STATE_LIST, step.state ?? STEP_STATE_LIST[0], t)
        };
        accumulator.push(datum);
      }

      return accumulator;
    },
    []
  );

  data.forEach((step) => {
    initialValues[step.ref] = step.state;

    step.tasks.forEach((task) => {
      const value = task.options?.data?.["value"];

      initialValues[`${TASK_STATUS_PREFIX}${task.ref}`] = task.state;
      initialValues[`${TASK_INPUT_PREFIX}${task.ref}`] = value;

      if (!ProcessOptionsService.instance.values[`${TASK_INPUT_PREFIX}${task.ref}`]) {
        ProcessOptionsService.instance.values[`${TASK_INPUT_PREFIX}${task.ref}`] = value;
      }
    });
  });

  const onSubmit = (values: { [key: string]: any }) => {
    const updatedProcessStates: ProcessStates = {};
    const updatedTasks: { [key: string]: UpdatedTaskData } = {};

    const updatedValues: UpdatedValue[] = Object.entries(values)
      .map(([ref, data]) => ({ ref, data }))
      .filter(({ ref, data }) => initialValues[ref] !== data);

    updateTasks(updatedTasks, updatedValues, initialValues);

    updateProcessStates(updatedValues, updatedProcessStates);

    return onSave(updatedProcessStates, Object.values(updatedTasks));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      return onSubmit({ ...values, ...ProcessOptionsService.instance.values });
    },
    enableReinitialize: true
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditableSection
          onEdit={() => setEditMode(true)}
          isEditMode={editMode}
          onView={() => setEditMode(false)}
          enableSticky={true}
          title={t("nominee-dashboard:company.processSteps")}
          testIdPrefix={"processSteps-"}>
          {editMode ? (
            <ProcessStatesTableEdit data={data} />
          ) : (
            <>
              <Button data-testid="preview-mode-toggle" onClick={onPreview}>
                {t("nominee-dashboard:process-steps.preview-as-client")}
              </Button>
              <ProcessStatesTableView data={data} />
            </>
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

function updateProcessStates(updatedValues: UpdatedValue[], updatedProcessStates: ProcessStates) {
  const updatedStepStatusList = updatedValues.filter(
    ({ ref }) => !ref.startsWith(TASK_INPUT_PREFIX) && !ref.startsWith(TASK_STATUS_PREFIX)
  );

  updatedStepStatusList.forEach(({ ref, data }) => {
    updatedProcessStates[ref] = data;
  });
}

function updateTasks(
  updatedTasks: {
    [key: string]: UpdatedTaskData;
  },
  updatedValues: UpdatedValue[],
  initialValues: {
    [key: string]: any;
  }
) {
  const updatedTaskStatusList = updatedValues
    .filter(({ ref }) => ref.startsWith(TASK_STATUS_PREFIX))
    .map(({ ref, data }) => ({ ref: ref.slice(TASK_STATUS_PREFIX.length), data }));

  const updatedTaskDataList = updatedValues
    .filter(({ ref }) => ref.startsWith(TASK_INPUT_PREFIX))
    .map(({ ref, data }) => ({ ref: ref.slice(TASK_INPUT_PREFIX.length), data }));

  const updatedTaskRefList = updatedTaskStatusList
    .map((item) => item.ref)
    .concat(updatedTaskDataList.map((item) => item.ref));

  updatedTaskRefList.forEach((ref) => {
    updatedTasks[ref] = createTaskData(ref, initialValues);
  });

  updatedTaskStatusList.forEach(({ ref, data }) => {
    updatedTasks[ref].state = data as TaskStatesEnum;
  });

  updatedTaskDataList.forEach(({ ref, data }) => {
    updatedTasks[ref].options.data = { value: data };
    updatedTasks[ref].options.type = "text";
  });
}

function createTaskData(
  ref: string,
  initialValues: {
    [key: string]: any;
  }
): UpdatedTaskData {
  const options: { data: {}; type: null | string } = { data: {}, type: null };

  if (initialValues[`${TASK_INPUT_PREFIX}${ref}`]) {
    options.data["value"] = initialValues[`${TASK_INPUT_PREFIX}${ref}`];
    options.type = "text";
  }

  return {
    ref,
    state: initialValues[`${TASK_STATUS_PREFIX}${ref}`],
    options
  };
}

export default withTranslationReady(["nominee-dashboard", "company-founding-steps"])(
  ProcessStatesTable
);
