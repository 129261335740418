import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import WepaBookingsTable from "./WepaBookingsTable";
import { UserRole, WepaBookingStatusFlag } from "global-query-types";
import { GetWepaBookings } from "lib/api/queries/GetWepaBookings";
import { useMutation } from "@apollo/react-hooks";
import { SET_WEPA_BOOKING_FLAG } from "lib/api/mutations/SET_WEPA_BOOKING_FLAG";
import {
  setWepaBookingFlag,
  setWepaBookingFlagVariables
} from "lib/api/mutations/graphql/setWepaBookingFlag";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { MyTaxAdvisorCompany } from "taxAdvisor/types/MyTaxAdvisorCompany";

interface WepaBookingsTableContainerProps {
  asUser?: UserRole;
  gmbh: GmbHDetails;
  myTaxAdvisorCompany: MyTaxAdvisorCompany | null | undefined;
}

const WepaBookingsTableContainer = ({
  asUser = UserRole.Client,
  gmbh,
  myTaxAdvisorCompany
}: WepaBookingsTableContainerProps) => {
  const companyId = gmbh.id;
  const wepaBookingsRequest = GetWepaBookings({ companyId });
  const [callFinalizeBooking, finalizeBookingRequest] = useMutation<
    setWepaBookingFlag,
    setWepaBookingFlagVariables
  >(SET_WEPA_BOOKING_FLAG);

  const finalizeBooking = async (bookingId: number) => {
    await callFinalizeBooking({
      variables: {
        companyId,
        bookingId,
        flag: WepaBookingStatusFlag.partner_final
      }
    });
  };

  const refetch = async () => {
    await wepaBookingsRequest.refetch();
  };

  const bookings = wepaBookingsRequest.data?.getWepaBookings?.bookings ?? [];

  return (
    <RequestFeedbackHandler requests={[wepaBookingsRequest, finalizeBookingRequest]}>
      <WepaBookingsTable
        asUser={asUser}
        bookings={bookings}
        finalizeBooking={finalizeBooking}
        refetch={refetch}
        gmbh={gmbh}
        myTaxAdvisorCompany={myTaxAdvisorCompany}
        companyId={companyId}
      />
    </RequestFeedbackHandler>
  );
};

export default WepaBookingsTableContainer;
