import { ValueFormatter } from "./ValueFormatter";

export class CurrencyFormatter extends ValueFormatter {
  format(value, ...args) {
    if (value === undefined) {
      return "";
    }

    const locale = this.getLocale(args);
    let arg: any;
    if (Array.isArray(args) && args.length > 0) {
      arg = args[0];
    }
    const currency = arg?.currency || "EUR";
    const fixed = typeof arg?.fixed === "number" ? arg.fixed : 2;
    const options = {
      currency,
      style: "currency",
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed
    };
    const intl = new Intl.NumberFormat(locale, options);
    // whitespaces from formatter seem to be broken, to enable tests, we replace them with spaces
    return intl.format(value).replace(/\s/, " ");
  }
}

export class CurrencyThousandsFormatter {
  format(value, ...args) {
    const currencyFormatter = new CurrencyFormatter();
    const valueFormatter = new ValueFormatter();

    const zeroInCurrencyFormat = currencyFormatter.format(0, ...args);
    const number = valueFormatter.format(Math.floor(value / 1000));
    const thousands = `${number} T`;

    return zeroInCurrencyFormat?.replace(/[0,.]+/, thousands);
  }
}
