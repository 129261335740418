import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import React from "react";
import { WithTranslation } from "react-i18next";
import { FeatureFlags, TaskStatesEnum } from "../../../../../global-query-types";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { getEndpoint } from "../../../../../common/GraphqlClient/httpLink";
import { getToken } from "../../../../../common/Authentication/getToken";
import OpenBankAccountTaskContainer from "../OpenBankAccountTaskContainer/OpenBankAccountTaskContainer";
import { FeatureFlag } from "../../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { DisabledFeatureFlag } from "../../../../../sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";

interface OpenBankAccountFyrstTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const OpenBankAccountFyrstTask = ({ task, onChange, t }: OpenBankAccountFyrstTaskProps) => {
  const endpoint = getEndpoint();
  const token = getToken();

  return (
    <OpenBankAccountTaskContainer onChange={onChange} task={task} bankName="Fyrst">
      <>
        <div>{t("company-founding-steps:tasks.openBankAccountFyrst.firstStep")}</div>
        <div className={"open-bank-account-swan__button-container"}>
          <FeatureFlag name={FeatureFlags.FyrstOnBoardingLink}>
            <RideButtonDeprecated
              openExternalUrl={task.state === TaskStatesEnum.TO_BE_DONE}
              href={t("company-founding-steps:tasks.openBankAccountFyrst.openAccountLink")}
              data-testid="open-bank-account-fyrst-button"
              className={`open-bank-account-fyrst__button ${
                task.state === TaskStatesEnum.COMPLETED ? "button--secondary--disabled" : ""
              }`}>
              {t("company-founding-steps:tasks.openBankAccountFyrst.openAccount")}
            </RideButtonDeprecated>
          </FeatureFlag>
          <DisabledFeatureFlag name={FeatureFlags.FyrstOnBoardingLink}>
            <RideButtonDeprecated
              openExternalUrl={task.state === TaskStatesEnum.TO_BE_DONE}
              href={`${endpoint}/api/client/document-templates/company-creation/FYRST/${task.options?.data?.companyCreationId}?apiToken=${token}`}
              data-testid="open-bank-account-fyrst-button"
              className={`open-bank-account-fyrst__button ${
                task.state === TaskStatesEnum.COMPLETED ? "button--secondary--disabled" : ""
              }`}>
              {t("company-founding-steps:tasks.openBankAccountFyrst.openAccount")}
            </RideButtonDeprecated>
          </DisabledFeatureFlag>
        </div>
      </>
      {t("company-founding-steps:tasks.openBankAccountFyrst.secondStep")}
      {t("company-founding-steps:tasks.openBankAccountFyrst.thirdStep")}
    </OpenBankAccountTaskContainer>
  );
};

export default withTranslationReady(["company-founding-steps"])(OpenBankAccountFyrstTask);
