import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RideLogoMinimalist } from "../icons/RideLogoMinimalist";

const ClubDealLogo = ({ t }: WithTranslation) => {
  return (
    <div className="club-deal-logo" data-testid="club-deal-logo">
      <div className="club-deal-logo__icon">
        <RideLogoMinimalist />
      </div>
      <div className="club-deal-logo__description">{t("generic:clubDealLogoText")}</div>
    </div>
  );
};

export default withTranslationReady(["generic"])(ClubDealLogo);
