import React from "react";
import TwoFactorAuthenticationSetupForm from "../components/TwoFactorAuthenticationSetupForm/TwoFactorAuthenticationSetupForm";

interface TwoFASetupSceneProps {
  currentPath: "/2fa" | "/2fa/edit";
}

const TwoFactorAuthenticationSetupScene = ({ currentPath }: TwoFASetupSceneProps) => {
  return <TwoFactorAuthenticationSetupForm currentPath={currentPath} />;
};

export default TwoFactorAuthenticationSetupScene;
