import gql from "graphql-tag";

export const GET_STRIPE_CHECKOUT_SESSION = gql`
  query getStripeCheckoutSession(
    $companyId: String!
    $successUrl: String!
    $cancelUrl: String!
    $paymentPlatformAccount: PaymentPlatformAccount!
  ) {
    getStripeCheckoutSession(
      companyId: $companyId
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      paymentPlatformAccount: $paymentPlatformAccount
    ) {
      redirectUrl
    }
  }
`;
