import React from "react";
import { FeatureFlags } from "global-query-types";
import { ModalLayout } from "sharedComponents/templates/ModalLayout/ModalLayout";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { useHistory, useParams } from "react-router-dom";
import AdminServicePackageUpdateSuccess from "./AdminServicePackageUpdateSuccess";
import { CompanyTabbedNavigationValue } from "client/components/CompanyTabbedNavigation/CompanyTabbedNavigation";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

export const AdminServicePackageUpdateSuccessContainer = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const history = useHistory();

  const companyByIdQuery = QueryCompanyById(companyId);
  const company = companyByIdQuery.data?.companyById;

  const goToAdminServiceView = () => {
    history.push(`/client/entities/${companyId}/${CompanyTabbedNavigationValue.ADMIN_SERVICE}`);
  };

  return (
    <FeatureFlag name={FeatureFlags.GRO777MigrateAdminServicePackage}>
      <ModalLayout onClose={goToAdminServiceView}>
        <RequestFeedbackHandler requests={[companyByIdQuery]}>
          {company && (
            <AdminServicePackageUpdateSuccess
              gmbh={new GmbHDetails(company)}
              goToAdminServiceView={goToAdminServiceView}
            />
          )}
        </RequestFeedbackHandler>
      </ModalLayout>
    </FeatureFlag>
  );
};
