import React from "react";

export interface RideBannerProps {
  title?: string;
  message?: string;
  buttonTitle?: string;
  buttonLink?: string;
  buttonAlternativeSlot?: React.ReactNode;
}

const RideBanner = ({
  title,
  message,
  buttonTitle,
  buttonLink,
  buttonAlternativeSlot
}: RideBannerProps) => {
  return (
    <div className="ride-banner">
      <div className="ride-banner__background-wave"></div>
      <span className="ride-banner__title" data-testid="ride-banner-title">
        {title}
      </span>
      <span className="ride-banner__message" data-testid="ride-banner-message">
        {message}
      </span>
      {buttonAlternativeSlot ? (
        buttonAlternativeSlot
      ) : (
        <a
          className="ride-banner__button"
          data-testid="ride-banner-button"
          href={buttonLink}
          target="_blank"
          rel="noopener noreferrer">
          <span className="ride-banner__button-text">{buttonTitle}</span>
          <span className="ride-banner__button-icon" />
        </a>
      )}
    </div>
  );
};

export default RideBanner;
