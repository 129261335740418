import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "react-apollo";
import RequestFeedbackHandler from "./components/generic/RequestFeedbackHandler";
import AppConfigService from "lib/services/AppConfigService";
import { FoundingProcessConfig } from "./lib/api/queries/graphql/FoundingProcessConfig";
import { FOUNDING_PROCESS_CONFIG } from "./lib/api/queries/FoundingProcessConfig";
import { FoundingProcessConfigService } from "./lib/services/FoundingProcessConfigService/FoundingProcessConfigService";
import { APP_CONFIG_QUERY } from "lib/api/queries/AppConfig";
import { RouterSwitch } from "RouterSwitch";
import { UserService } from "lib/services/UserService/UserService";
import { FeatureFlagService } from "./lib/services/FeatureFlagService/FeatureFlagService";
import { DataLayer } from "./lib/services/GoogleTagManager/GoogleTagManager";
import { GetMe } from "lib/api/queries/MeQuery";
import { AppConfigQuery } from "lib/api/queries/graphql/AppConfigQuery";
import { toFeatureFlagsMap } from "lib/dataAdapters/toFeatureFlagMap/toFeatureFlagMap";

export const AppRoot = () => {
  const [appConfigCall, appConfigRequest] = useLazyQuery<AppConfigQuery>(APP_CONFIG_QUERY, {
    fetchPolicy: "no-cache"
  });
  const foundingProcessConfigRequest = useQuery<FoundingProcessConfig>(FOUNDING_PROCESS_CONFIG);
  const meRequest = GetMe();

  const appConfig = appConfigRequest.data?.appConfig;
  const foundingProcessConfig = foundingProcessConfigRequest.data?.foundingProcessConfig;
  const featureFlagsResponse = appConfig?.featureFlags ?? [];
  const featureFlags = toFeatureFlagsMap(featureFlagsResponse);
  FeatureFlagService.instance = new FeatureFlagService(featureFlags);

  if (appConfig) {
    AppConfigService.instance = new AppConfigService(appConfig);
  }

  if (foundingProcessConfig) {
    FoundingProcessConfigService.instance = new FoundingProcessConfigService(foundingProcessConfig);
  }

  UserService.instance = new UserService();
  UserService.instance.currentUser = meRequest.data?.me ?? undefined;
  UserService.instance.meRequest = meRequest;

  useEffect(() => {
    const sendGTMLoginEvent = () => {
      const me = meRequest.data?.me;
      if (me && me.id) {
        DataLayer.Login({
          userId: me.id,
          role: me.role,
          userData: {
            email: me.email,
            firstName: me.person?.firstName,
            lastName: me.person?.lastName,
            phoneNumber: me.person?.phoneNumber,
            birthDate: me.person?.dateOfBirth
          }
        });
      }
    };

    UserService.instance.currentUser = meRequest.data?.me ?? undefined;
    appConfigCall();

    sendGTMLoginEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meRequest.data]);

  return (
    <RequestFeedbackHandler
      requests={[appConfigRequest, foundingProcessConfigRequest, meRequest]}
      noLoadingAnimation={true}
      hideErrors={true}>
      {appConfig && <RouterSwitch appConfig={appConfig} />}
    </RequestFeedbackHandler>
  );
};
