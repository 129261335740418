import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import React from "react";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import {
  AllNotaryPersonsQuery_allNotaryPersons_address,
  AllNotaryPersonsQuery_allNotaryPersons_notaryGroups
} from "./graphql/AllNotaryPersonsQuery";
import { TFunction } from "i18next";
import RideCardTable from "../../../sharedComponents/RideCardTable/RideCardTable";
import { RideHeaderColumn } from "../../../lib/types/types";

interface NotaryListTableProps {
  columns: RideHeaderColumn[];
  sortCallback: (column: RideHeaderColumn) => void;
  t: TFunction;
  sortedData: {
    fullName: string;
    __typename: "Person";
    id: string;
    firstName: string | null;
    lastName: string | null;
    address: AllNotaryPersonsQuery_allNotaryPersons_address | null;
    notaryGroups: AllNotaryPersonsQuery_allNotaryPersons_notaryGroups[] | null;
    isPartner: boolean | null;
  }[];
}

export const NotaryListTable = ({ columns, sortCallback, sortedData, t }: NotaryListTableProps) => (
  <RideCardTable title={t("nominee-dashboard:notary.heading")} data-testid="notary-list-table">
    <RideTableHeader columns={columns} sortCallback={sortCallback} />
    {sortedData.map((person, index) => (
      <RideTableRow key={`${person.id}-${index}`} colCount={columns.length}>
        <NotaryFullNameCell person={person} />
        <NotaryCityCell person={person} />
        <NotaryIsPartnerCell person={person} t={t} />
      </RideTableRow>
    ))}
  </RideCardTable>
);

const NotaryFullNameCell = ({ person }) => (
  <RideTableCell
    value={person.fullName}
    dataTestId="person-name"
    variant={RideTableCellVariant.primaryLink}
    link={`/nominee-persons/${person.id}`}
    classes="mobile-full"
  />
);

const NotaryCityCell = ({ person }) => (
  <RideTableCell
    value={person.address?.city}
    variant={RideTableCellVariant.secondaryText}
    classes="mobile-left"
    dataTestId="person-city"
  />
);

const NotaryIsPartnerCell = ({ person, t }) => (
  <RideTableCell
    variant={RideTableCellVariant.badge}
    dataTestId="person-badge"
    classes="mobile-right"
    badge={
      person.isPartner ? (
        <RideBadgeStatus
          color={RideBadgeColorsEnum.SUCCESS}
          variant={RideBadgeVariantsEnum.OUTLINED}
          label={t("nominee-dashboard:person:partner")}
        />
      ) : (
        <RideBadgeStatus
          color={RideBadgeColorsEnum.DANGER}
          variant={RideBadgeVariantsEnum.OUTLINED}
          label={t("nominee-dashboard:person:no-partner")}
        />
      )
    }
  />
);
