import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TFunction, WithTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { MyGmbHsQuery, MyGmbHsQuery_companies } from "./graphql/MyGmbHsQuery";
import { GmbHTile } from "client/components/GmbHTile/GmbHTile";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { AdminServiceModalContainer } from "../company/components/AdminServiceModalContainer";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AddIcon } from "sharedComponents/icons/add";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { SuccessAnimatedModal } from "../../../client/components/SuccessAnimatedModal/SuccessAnimatedModal";
import { MyGmbHsBannersContainer } from "../../../client/components/MyGmbHsBanners/MyGmbHsBannersContainer";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../global-query-types";
import { AddGmbHModalContainer } from "../../../client/components/AddGmbHModal/AddGmbHModalContainer";

interface Props extends WithTranslation {
  myGmbHs: MyGmbHsQuery | undefined;
}

const MyGmbHs = ({ t, myGmbHs }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCompanyId, setModalCompanyId] = useState("");
  const [showAddGmbHModal, setShowAddGmbHModal] = useState(false);

  const history = useHistory();

  if (!myGmbHs) {
    return null;
  }

  const handleActivateWepa = (companyId: string) => () => {
    history.push(`/client/entities/${companyId}/securities-booking/new`);
  };

  const handleModalOpen = (companyId: string) => () => {
    setModalCompanyId(companyId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalCompanyId("");
    setModalOpen(false);
  };

  const handleGmbHModalOpen = () => {
    const newAddGmbHModal = FeatureFlagService.instance.isEnabled(FeatureFlags.NewAddGmbHModal);
    if (newAddGmbHModal) {
      setShowAddGmbHModal(true);
    } else {
      history.push("/client/my-gmbhs/add");
    }
  };

  const onCompanySelect = (gmbhURL: string) => {
    history.push(gmbhURL);
  };

  const companies = myGmbHs?.companies ?? [];

  const thereAreNoCompanies = companies.length === 0;

  return (
    <>
      {showAddGmbHModal && (
        <AddGmbHModalContainer
          onClose={() => {
            setShowAddGmbHModal(false);
          }}
        />
      )}
      <PageMetaData title={t("client:my-gmbhs.page-title")} />
      <AdminServiceModalContainer
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        companyId={modalCompanyId}
      />
      <SuccessAnimatedModal
        expectedQueryKey="ltb-order-completed"
        title={t("generic:ltb-order-completed.title")}
        message={t("generic:ltb-order-completed.message")}
        urlAfterClose={`/client/entities`}
      />
      <MyGmbhsHeader t={t} handleGmbHModalOpen={handleGmbHModalOpen} />
      <div className="my-gmbhs">
        {/* TODO: Didn't had the time but this is a work around that is only affecting the design on mobile. We need to refactor this to CSS */}
        <WorkAroundToMakeDesignWorkOnMobile companies={companies} />
        <Container className="px-md-0">
          <GmbhsList
            companies={companies}
            handleModalOpen={handleModalOpen}
            handleActivateWePa={handleActivateWepa}
            onCompanySelect={onCompanySelect}
            t={t}
          />
          {thereAreNoCompanies && <NoGmbhs t={t} />}
        </Container>
      </div>
    </>
  );
};

export default withTranslationReady(["client", "company-founding-steps"])(MyGmbHs);

const MyGmbhsHeader = ({
  t,
  handleGmbHModalOpen
}: {
  t: TFunction;
  handleGmbHModalOpen: () => void;
}) => (
  <Container fluid>
    <Row className="my-gmbhs__header">
      <Col className="px-0">
        <Container className="px-md-0">
          <Row>
            <Col>
              <h1 data-testid="my-gmbhs-title">{t("client:my-gmbhs.title")}</h1>
            </Col>
            <Col xs="auto" className="align-self-center text-right">
              <RideButtonDeprecated onClick={handleGmbHModalOpen} hasIcon={true}>
                <AddIcon />
                <span data-testid="add-company-link">{t("client:my-gmbhs.add-company")}</span>
              </RideButtonDeprecated>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  </Container>
);

const NoGmbhs = ({ t }: { t: TFunction }) => (
  <Row>
    <Col className="text-center py-5 py-md-7 my-gmbhs__empty">
      <span data-testid="empty-list-message">{t("client:my-gmbhs.empty-list-message")}</span>
      <br />
      <Link data-testid="add-company-link-2" className="text-underlined" to="/client/my-gmbhs/add">
        {t("client:my-gmbhs.create-one")}
      </Link>
    </Col>
  </Row>
);

const WorkAroundToMakeDesignWorkOnMobile = ({
  companies
}: {
  companies: MyGmbHsQuery_companies[];
}) => (
  <>
    {companies.length > 0 && (
      <Container fluid>
        <Row className="my-gmbhs__spacer">
          <Col>&nbsp;</Col>
        </Row>
      </Container>
    )}
  </>
);

const GmbhsList = ({
  companies,
  handleModalOpen,
  handleActivateWePa,
  onCompanySelect,
  t
}: {
  companies: MyGmbHsQuery_companies[];
  t: TFunction;
  handleModalOpen: (companyId: string) => () => void;
  handleActivateWePa: (companyId: string) => () => void;
  onCompanySelect: (gmbhURL: string) => void;
}) => (
  <>
    <div className="my-gmbhs__gmbhs">
      <MyGmbHsBannersContainer />
      {companies.map((company) => (
        <GmbHTile
          t={t}
          key={company.id}
          company={company}
          className="my-gmbhs__gmbh"
          onCompanySelect={onCompanySelect}
          handleAddAdminServiceClick={handleModalOpen(company.id)}
          handleActivateWepaClick={handleActivateWePa(company.id)}
        />
      ))}
    </div>
  </>
);
