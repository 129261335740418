import * as Yup from "yup";

export const SourcesOfWealthStepSchema = (t) => {
  return Yup.object().shape({
    "other-sources-description": Yup.string()
      .notRequired()
      .when("others", {
        is: true,
        then: Yup.string().required(t("generic:validation-required")),
        otherwise: Yup.string().notRequired()
      })
  });
};
