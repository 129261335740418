import React from "react";
import {
  CompletionDonutIcon,
  DonutNumber,
  DonutSparkleIcon
} from "sharedComponents/icons/DesignSystemIcons/CompletionDonutIcon";

export enum DonutCompletionStatus {
  ZERO_PERCENT = "ZERO_PERCENT",
  SIXTEEN_PERCENT = "SIXTEEN_PERCENT",
  SIXTY_PERCENT = "SIXTY_PERCENT",
  ONE_HUNDRED_PERCENT = "ONE_HUNDRED_PERCENT"
}

export interface CompletionDonutProps {
  completion: DonutCompletionStatus;
  index?: number;
  "data-testid"?: string;
  className?: string;
  disabled?: boolean;
}

const CompletionDonut = ({
  "data-testid": dataTestId,
  completion,
  index,
  className,
  disabled
}: CompletionDonutProps) => {
  return (
    <div data-testid={dataTestId} className={className} data-disabled={disabled}>
      <CompletionDonutIcon
        className={`completion-donut`}
        completion={disabled ? DonutCompletionStatus.ZERO_PERCENT : completion}>
        {index !== undefined ? (
          <DonutNumber
            className={disabled ? "completion-donut__number--disabled" : "completion-donut__number"}
            num={index}
          />
        ) : (
          <DonutSparkleIcon
            className={
              disabled
                ? "completion-donut__sparkle--disabled"
                : `completion-donut__sparkle--${completion}`
            }
          />
        )}
      </CompletionDonutIcon>
    </div>
  );
};

export default CompletionDonut;
