import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const CheckIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="check-icon"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 18L3 11.25L4.0605 10.1895L9.75 15.8782L19.9395 5.6895L21 6.75L9.75 18Z"
      fill="currentColor"
    />
  </svg>
);
