import {
  ExternalContentNamespace,
  ExternalContentProvider,
  useExternalContentProvider
} from "../../ports/ExternalContentProvider";

export interface PolicyExceptionService {
  isExceptionApplicable(policyExceptionName: string, companyId: string): Promise<boolean>;
}

export class ExternalContentPolicyExceptionService implements PolicyExceptionService {
  private static _instance: ExternalContentPolicyExceptionService;

  static instance() {
    if (!ExternalContentPolicyExceptionService._instance) {
      ExternalContentPolicyExceptionService._instance = new ExternalContentPolicyExceptionService(
        useExternalContentProvider()
      );
    }
    return ExternalContentPolicyExceptionService._instance;
  }

  constructor(private readonly externalContentProvider: ExternalContentProvider) {}

  async isExceptionApplicable(policyExceptionName: string, companyId: string) {
    const rules = await this.externalContentProvider.getFullNamespace(
      ExternalContentNamespace.POLICY_EXCEPTION_RULES
    );
    const exceptions = await this.externalContentProvider.getFullNamespace(
      ExternalContentNamespace.POLICY_EXCEPTIONS
    );

    const matchingRule = rules.find((rule) => rule.name === policyExceptionName);

    const matchingException = exceptions.find(
      (exception) =>
        exception.policy_exception_rule?.some(({ id }) => id === matchingRule?.id) &&
        exception.company_id === companyId
    );

    return !!matchingException;
  }
}
