import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { RideLogoMinimalist } from "sharedComponents/icons/RideLogoMinimalist";
import { UnifiedShortOrderFormStepProps } from "client/components/UnifiedShortOrderForm/UnifiedShortOrderForm.partials";

interface ThankYouStepProps extends UnifiedShortOrderFormStepProps {}

const ThankYouStep = ({ t, initialValues }: ThankYouStepProps & WithTranslation) => {
  const className = "thank-you-step-content";
  return (
    <div className={className}>
      <RideLogoMinimalist width={"80"} height={"80"} fill={"#1f2921"} />
      <Typography
        category="Headline"
        size={100}
        weight={"Heavy"}
        data-testid={"title"}
        className={`${className}__title`}>
        {t("generic:short-order-form.thank-you.title")}
      </Typography>
      <Typography
        category="Headline"
        size={300}
        weight={"Light"}
        data-testid={"content"}
        className={`${className}__content`}>
        <Trans
          i18nKey={"generic:short-order-form.thank-you.content"}
          values={{ email: initialValues.email }}
        />
      </Typography>
    </div>
  );
};

export default withTranslationReady(["generic"])(ThankYouStep);
