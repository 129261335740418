import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { AdminServiceOrderData } from "lib/models/client/AdminService/AdminServiceOrderData";
import { ReviewFieldList } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewFieldList/ReviewFieldList";

export interface ShareholderDetailsProps {
  shareholders: AdminServiceOrderData["shareholders"];
}

const ShareholderDetails = ({ t, shareholders }: ShareholderDetailsProps & WithTranslation) => {
  return (
    <div data-testid="shareholders-details" className="shareholder-details">
      <h2 data-testid="roles-section-title">
        {t("generic:admin-service-form.shareholders-context.title")}
      </h2>
      <div data-testid="shareholders-details-title">
        {Object.entries(shareholders ?? {}).map(([index, values]) => {
          return (
            <ReviewCard
              key={`shareholder-${index}`}
              dataTestId={"roles-section__shareholders-details"}
              className="shareholder-details__card">
              <ReviewSectionTitle
                dataTestId={`shareholders-details-${index}-name`}
                title={values.name}
              />
              <ReviewFieldList>
                <ReviewField
                  data-testid={`shareholders-details-${index}-pep`}
                  label={t(
                    "generic:admin-service-form.shareholders-context.politically-exposed-question"
                  )}
                  value={values.pep === "yes" ? t("generic:yes") : t("generic:no")}
                />
                {values.pep === "yes" && (
                  <ReviewField
                    data-testid={`shareholders-details-${index}-pepContext`}
                    label={t("generic:pep-context")}
                    value={values.pepContext}
                  />
                )}
                <ReviewField
                  data-testid={`shareholders-details-${index}-sourceOfWealth`}
                  label={t("generic:admin-service-form.shareholders-context.source-of-wealth")}
                  value={values.sourceOfWealth}
                />
              </ReviewFieldList>
            </ReviewCard>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(ShareholderDetails);
