import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { MyLowTaxBrokerOrder_myLowTaxBrokerOrder } from "lib/api/queries/graphql/MyLowTaxBrokerOrder";
import { companyName } from "lib/formatters/companyFormatter";
import PayIn from "./PayIn/PayIn";
import PayOut from "./PayOut/PayOut";
import ViewSecurityQuestions from "./ViewSecurityQuestions/ViewSecurityQuestions";
import TraderSupport from "./TraderSupport/TraderSupport";
import Help from "client/components/LowTaxBrokerDashboard/Help/Help";
import TradeBanner from "client/components/LowTaxBrokerDashboard/TradeBanner/TradeBanner";
import { BrokerageAccountLTB } from "../LowTaxBrokerViewSwitcher/LowTaxBrokerViewSwitcher";
import { CopyableInformation } from "../CopyableInformation/CopyableInformation";
import { IBCredentials } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags, OrderCompanyStatusEnum, OrderProductTypeEnum } from "global-query-types";
import WepaDirectBanner from "./WepaDirectBanner/WepaDirectBanner";
import { GmbHDetails } from "../../../lib/models/client/GmbHDetails";
import { AdminServiceStatusEnum } from "../../../lib/models/client/GmbH";

interface CompanyInfo {
  name: string;
  legalForm: string;
}

interface LowTaxBrokerDashboardProps extends WithTranslation {
  order: MyLowTaxBrokerOrder_myLowTaxBrokerOrder | null | undefined;
  companyInfo: CompanyInfo | null | undefined;
  brokerageAccount: BrokerageAccountLTB | undefined;
  requestPayoutCallback: (amount: number) => Promise<void>;
  handleCreateSSOAndRedirect?: () => Promise<void>;
  gmbh: GmbHDetails;
}

const LowTaxBrokerDashboard = ({
  t,
  order,
  companyInfo,
  brokerageAccount,
  requestPayoutCallback,
  handleCreateSSOAndRedirect,
  gmbh
}: LowTaxBrokerDashboardProps) => {
  const showIbkrAccessInformationForLTB = FeatureFlagService.instance.isEnabled(
    FeatureFlags.LTB768ShowIbkrAccessInformationForLTBInLowTaxBrokerDashboard
  );

  const showIbkrAccessInformation =
    order?.extra.isNewBrokerEnabled || showIbkrAccessInformationForLTB;

  const orderedAdminService =
    gmbh.adminService &&
    (gmbh.adminServiceStatus === AdminServiceStatusEnum.WAITING_FOR_CONFIRMATION ||
      gmbh.adminServiceStatus === AdminServiceStatusEnum.ACTIVE ||
      gmbh.adminServiceStatus === AdminServiceStatusEnum.CANCELED);

  const asOrderInProgress = gmbh.orders.find(
    (o) =>
      o.productType === OrderProductTypeEnum.AdminService &&
      o.status === OrderCompanyStatusEnum.InProgress
  );

  const showWepaDirectBanner =
    FeatureFlagService.instance.isEnabled(FeatureFlags.WepaDirect) &&
    !asOrderInProgress &&
    !(orderedAdminService || gmbh.wepaService);

  return (
    <div data-testid="low-tax-broker-dashboard" className="low-tax-broker-dashboard">
      <TradeBanner order={order} handleCreateSSOAndRedirect={handleCreateSSOAndRedirect} />
      {showWepaDirectBanner && <WepaDirectBanner companyId={gmbh.id} />}
      {showIbkrAccessInformation && (
        <IBKRAccessInfo
          username={order?.extra.ibCredentials?.username}
          accountNumber={order?.extra.ibCredentials?.accountNumber}
        />
      )}

      {!order?.extra.isNewBrokerEnabled && (
        <Section
          dataTestId="manage-money"
          title={t("generic:low-tax-broker-dashboard.manage-money-title")}>
          <PayIn
            companyName={companyName(companyInfo)}
            brokerageAccountNumber={brokerageAccount?.accountNumber}
          />
          <PayOut
            companyName={companyName(companyInfo)}
            iban={
              order?.extra?.companyBankAccount?.iban ??
              t("generic:low-tax-broker-dashboard.iban-placeholder")
            }
            requestPayoutCallback={requestPayoutCallback}
          />
        </Section>
      )}
      {order?.extra.isNewBrokerEnabled ? (
        <Section
          dataTestId="account-security"
          title={t("generic:low-tax-broker-dashboard.account-help-title")}>
          <TraderSupport />
          <Help />
        </Section>
      ) : (
        <Section
          dataTestId="account-security"
          title={t("generic:low-tax-broker-dashboard.account-security-title")}>
          <ViewSecurityQuestions securityQuestions={order?.extra.securityQuestions} />
          <TraderSupport />
          <Help />
        </Section>
      )}
    </div>
  );
};

export default withTranslationReady(["generic"])(LowTaxBrokerDashboard);

interface SectionProps extends PropsWithChildren<{}> {
  dataTestId: string;
  title: string;
}

const Section = ({ dataTestId, title, children }: SectionProps) => (
  <div data-testid={dataTestId} className="low-tax-broker-dashboard__section">
    <span data-testid={"title"}>{title}</span>
    {children}
  </div>
);

const IBKRAccessInfo = withTranslationReady(["generic"])(
  ({ username, accountNumber, t }: IBCredentials & WithTranslation) => (
    <Section dataTestId="ltb-dashboard-ibkr-access-info" title={t("generic:access-information")}>
      <div className="ltb-dashboard-action-card ltb-dashboard-action-card__ibkr-access-info">
        <div>
          <CopyableInformation
            data-testid={"ibkr-username"}
            label={t("generic:username")}
            value={username ?? ""}
            showValue={true}
          />
          <CopyableInformation
            data-testid={"ibkr-account-number"}
            label={t("generic:account-number")}
            value={accountNumber ?? ""}
            showValue={true}
          />
        </div>
      </div>
    </Section>
  )
);
