import React from "react";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { UpdateWepaServiceVariables } from "lib/api/mutations/graphql/UpdateWepaService";
import RideDatePicker from "sharedComponents/RideDatePicker/RideDatePicker";
import { ValueView } from "components/common/layout/ValueView";
import { formatDate } from "common/formatters";
import EditableSectionWithFormik from "sharedComponents/EditableSectionWithFormik/EditableSectionWithFormik";
import { useFormik } from "formik";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { CreateWepaServiceForWepaStandaloneVariables } from "lib/api/mutations/graphql/CreateWepaServiceForWepaStandalone";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

export interface WepaServiceDetailsProps {
  gmbh: GmbHDetails;
  refetch: () => Promise<any>;
  updateWepaService: (data: UpdateWepaServiceVariables) => Promise<void>;
  createWepaServiceForWepaStandalone: (
    data: CreateWepaServiceForWepaStandaloneVariables
  ) => Promise<void>;
}

const WepaServiceDetails = ({
  gmbh,
  refetch,
  updateWepaService,
  createWepaServiceForWepaStandalone
}: WepaServiceDetailsProps) => {
  const wepaService = gmbh.wepaService;

  if (!wepaService?.id) {
    return (
      <CreateWepaService
        gmbh={gmbh}
        refetch={refetch}
        createWepaServiceForWepaStandalone={createWepaServiceForWepaStandalone}
      />
    );
  }

  return (
    <EditWepaService
      wepaService={wepaService}
      updateWepaService={updateWepaService}
      refetch={refetch}
    />
  );
};

interface CreateWepaServiceProps {
  gmbh: GmbHDetails;
  refetch: () => Promise<any>;
  createWepaServiceForWepaStandalone: (
    data: CreateWepaServiceForWepaStandaloneVariables
  ) => Promise<void>;
}

const CreateWepaService = withTranslationReady("generic")(
  ({
    t,
    gmbh,
    refetch,
    createWepaServiceForWepaStandalone
  }: CreateWepaServiceProps & WithTranslation) => {
    const taxAdvisor = gmbh.taxAdvisor;

    if (!taxAdvisor || !gmbh.isWepaClient) {
      return (
        <>
          <h2 data-testid="wepa-bookings-section-title">
            {t("nominee-dashboard:wepa-service.details")}
          </h2>
          <Typography category={"Paragraph"} size={100} weight={"Light"}>
            Customer needs to have a 3PTA assigned in order to create Wepa Service.
          </Typography>
        </>
      );
    }

    const createWepaService = async () => {
      await createWepaServiceForWepaStandalone({
        clientCompanyId: gmbh.id,
        thirdPartyTaxAdvisorCompanyId: taxAdvisor.id
      });
      await refetch();
    };

    return (
      <>
        <h2 data-testid="wepa-bookings-section-title">
          {t("nominee-dashboard:wepa-service.details")}
        </h2>
        <RideButton
          data-testid={"start-wepa-service-button"}
          variant={RideButtonVariant.PRIMARY}
          size={RideButtonSize.BIG}
          label={"Start Wepa Service"}
          onClick={createWepaService}
        />
      </>
    );
  }
);

interface EditWepaServiceProps {
  wepaService: {
    id?: string;
    canceledAt?: any;
  };
  updateWepaService: (data: UpdateWepaServiceVariables) => Promise<void>;
  refetch: () => Promise<any>;
}

const EditWepaService = withTranslationReady("nominee-dashboard")(
  ({ t, wepaService, updateWepaService, refetch }: EditWepaServiceProps & WithTranslation) => {
    const handleSubmit = async (data: UpdateWepaServiceVariables) => {
      await updateWepaService(data);
      await refetch();
    };

    const initialValues: UpdateWepaServiceVariables = {
      id: wepaService.id!,
      canceledAt: wepaService.canceledAt
    };

    const formik = useFormik({
      initialValues,
      onSubmit: handleSubmit,
      enableReinitialize: true
    });

    return (
      <EditableSectionWithFormik
        formik={formik}
        title={t("nominee-dashboard:wepa-service.details")}
        testId="wepaServiceDetails">
        {({ isEditMode }) =>
          isEditMode ? (
            <RideDatePicker
              name={"canceledAt"}
              label={t("nominee-dashboard:wepa-service.activatedAt")}
              selectedDate={formik.values["canceledAt"]}
              onSelectDate={(value) => formik.setFieldValue("canceledAt", value)}
            />
          ) : (
            <ValueView
              name="canceledAt"
              value={formatDate(wepaService.canceledAt)}
              testId="canceledAt"
              t={t}
            />
          )
        }
      </EditableSectionWithFormik>
    );
  }
);

export default WepaServiceDetails;
