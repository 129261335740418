import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ActionableTask, ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import TaskContainer from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainer";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { TaskStatesEnum } from "global-query-types";
import { IbanValidation } from "lib/validation/IbanSchema";
import { InvalidFeedback } from "../../../../../uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";

interface ProvideIBANTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const ProvideIBANTask = ({ task, onChange, t }: ProvideIBANTaskProps) => {
  const [value, setValue] = useState(task.options?.data.value);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const inputProps = {
    value: value,
    onChange: (event) => {
      setShowErrorMessage(false);
      setValue(event.target.value);
    }
  };

  const handleOnSubmit = async (state: TaskStatesEnum) => {
    const ibanIsValid = IbanValidation.isValidSync(value ?? "");
    setShowErrorMessage(!ibanIsValid);
    if (ibanIsValid) {
      await onChange(state, {
        type: "iban",
        data: {
          value: state === TaskStatesEnum.TO_BE_DONE ? "" : value ?? ""
        }
      });
    }
  };

  return (
    <TaskContainer
      task={task}
      testid="provideIBAN"
      translationKey={task.ref}
      onChange={(state) => handleOnSubmit(state)}>
      <div>
        <RideInput
          name="provideIBAN-iban"
          isDisabled={task.state === TaskStatesEnum.COMPLETED}
          inputProps={inputProps}
        />

        <InvalidFeedback hidden={!showErrorMessage} testPrefix="provideIBAN">
          {t("generic:validation-iban")}
        </InvalidFeedback>
      </div>
    </TaskContainer>
  );
};

export default withTranslationReady(["generic", "company-founding-steps"])(ProvideIBANTask);
