import { getToken } from "common/Authentication/getToken";
import React, { useMemo } from "react";

interface Props {}

export const RideImmoIframe = (_: Props) => {
  const iframeUrl = useMemo(() => {
    const baseUrl =
      localStorage.getItem("immourl") ||
      "https://develop.d7y8606syai2m.amplifyapp.com/app/dashboard";

    const token = getToken();
    if (!!token) {
      const paramsWithToken = new URLSearchParams();
      paramsWithToken.append("token", token);

      return `${baseUrl}?${paramsWithToken.toString()}`;
    } else {
      return baseUrl;
    }
  }, []);

  return (
    <iframe
      title="RIDE Immo"
      src={iframeUrl}
      style={{
        height: "90vh",
        border: 0
      }}></iframe>
  );
};
