import React from "react";
import { useHistory } from "react-router-dom";
import { ResumeCompanyCreationStep } from "../graphql/ResumeCompanyCreationStep";
import { getFlowTypePath } from "../../CompanyCreationFlowType/currentFlowType";
import ResumeLinkHandler from "./ResumeLinkHandler";
import { FeatureFlags } from "../../../../global-query-types";
import { FeatureFlagService } from "../../../../lib/services/FeatureFlagService/FeatureFlagService";

export const ShadowLogin = () => {
  const history = useHistory();

  const resumeLinkNewFlow = FeatureFlagService.instance.isEnabled(
    FeatureFlags.resumeLinkForGSFlowV2
  );

  const onLoggedIn = (
    data: ResumeCompanyCreationStep,
    orderId?: string | null,
    submissionId?: string | null
  ) => {
    const companyCreationId = data.resumeCompanyCreationStep!.companyCreation?.id;
    const postOrderFlowType = getFlowTypePath(
      data.resumeCompanyCreationStep!.companyCreation.flowType!
    );
    const hasOrderId =
      data.resumeCompanyCreationStep!.companyCreation?.company?.companyFoundationOrderId;
    const isOrderCompleted =
      data.resumeCompanyCreationStep!.companyCreation?.company?.orderStatus === "COMPLETED";

    if (resumeLinkNewFlow && hasOrderId) {
      if (isOrderCompleted || !submissionId) history.push(`/`);
      else {
        history.push(`/client/order/generic/business-purpose/0/${orderId}/${submissionId}`);
      }
    } else {
      history.push(`/${postOrderFlowType}/${companyCreationId}`);
    }
  };

  return <ResumeLinkHandler onLoggedIn={onLoggedIn} />;
};
