import React from "react";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  getPendingTaskType,
  IBKRDocumentType,
  PendingTask,
  PendingTaskType,
  ProofOfAddressType,
  ProofOfIdentityType
} from "../../../../../lib/types/PendingTask";
import { CardContainer } from "../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import LoginToCompletePendingTask from "./LoginToCompletePendingTask/LoginToCompletePendingTask";
import DownloadAndAgreePendingTask from "./DownloadAndAgreePendingTask/DownloadAndAgreePendingTask";
import DownloadFillAndUploadPendingTask from "./DownloadFillAndUploadPendingTask/DownloadFillAndUploadPendingTask";
import bytes from "bytes";
import { FileType } from "lib/ports/FileStorage";
import UploadDocumentPendingTask from "client/components/LowTaxBrokerOverview/ApplicationReviewWithPendingTasksCard/PendingTaskSwitcher/UploadDocumentPendingTask/UploadDocumentPendingTask";
import ExternalURLProcessTask from "./ExternalURLProcessTask/ExternalURLProcessTask";

export type IBKRFileSubmission = {
  file: File;
  formNumber: string;
  expirationDate?: string | undefined;
  documentType?: IBKRDocumentType;
  proofOfAddressType?: ProofOfAddressType;
  proofOfIdentityType?: ProofOfIdentityType;
};

export interface PendingTaskSwitcherProps {
  task: PendingTask;
  completeTask: () => Promise<void>;
  isTaskCompleted: boolean;
  handleCreateSSOAndRedirect?: () => Promise<void>;
  handleAgreeToIBKRForm?: (formNumber: string) => Promise<void>;
  handleSubmitFile?: ({
    file,
    formNumber,
    expirationDate,
    documentType
  }: IBKRFileSubmission) => Promise<void>;
  fileUploadConstraints?: { maxFileSizeInBytes: number; allowedFileTypes: FileType[] };
}

export const fileUploadConstraints = {
  maxFileSizeInBytes: bytes("10 MB"),
  allowedFileTypes: [FileType.JPG, FileType.JPEG, FileType.PDF, FileType.PNG]
};

const PendingTaskSwitcher = (props: PendingTaskSwitcherProps & WithTranslation) => {
  const taskType = getPendingTaskType(props.task);

  if (taskType === PendingTaskType.LOGIN_TO_COMPLETE) {
    return <LoginToCompletePendingTask {...props} />;
  }

  if (taskType === PendingTaskType.DOWNLOAD_AND_AGREE) {
    return <DownloadAndAgreePendingTask {...props} />;
  }

  if (taskType === PendingTaskType.DOWNLOAD_FILL_AND_UPLOAD) {
    return <DownloadFillAndUploadPendingTask {...props} />;
  }

  if (taskType === PendingTaskType.UPLOAD_REQUESTED_DOCUMENT) {
    return <UploadDocumentPendingTask {...props} />;
  }

  if (taskType === PendingTaskType.EXTERNAL_URL_PROCESS) {
    return <ExternalURLProcessTask {...props} />;
  }

  return (
    <CardContainer>
      <div className="p-4" data-testid="pending-task">
        {props.task.formName} ({taskType})
      </div>
    </CardContainer>
  );
};

export default withTranslationReady(["generic"])(PendingTaskSwitcher);
