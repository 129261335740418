import React from "react";
import BookingGmbHFlow from "./BookingGmbHFlow";
import { useParams } from "react-router";
import {
  GetMyCompanyCreation,
  GetResumeMyCompanyCreationStep
} from "../../../../components/public/CompanyCreation/Queries";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { FlowTypeEnum } from "../../../../global-query-types";

export const BookingGmbHFlowContainer = () => {
  const { id } = useParams<{ id: string }>();

  const companyCreationId = id === "start" ? undefined : id;
  const myCompanyCreationStepRequest = GetResumeMyCompanyCreationStep(
    companyCreationId,
    FlowTypeEnum.GMBH
  );

  const companyCreationRequest = GetMyCompanyCreation(companyCreationId);
  let companyCreation = companyCreationRequest.data?.myCompanyCreation ?? undefined;

  return (
    <RequestFeedbackHandler requests={[companyCreationRequest, myCompanyCreationStepRequest]}>
      <BookingGmbHFlow refetch={companyCreationRequest.refetch} companyCreation={companyCreation} />
    </RequestFeedbackHandler>
  );
};
