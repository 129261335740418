import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideOverview from "sharedComponents/RideOverview/RideOverview";
import PendingTasksContainer from "sharedComponents/PendingTasks/PendingTasksContainer";
import { PageMetaData } from "../../../sharedComponents/PageMetaData/PageMetaData";

const PendingTasksScene = ({ t }: WithTranslation) => {
  return (
    <RideOverview title={t("client:pending-tasks.title")} imageSrc={""}>
      <div className="mt-48">
        <PageMetaData title={t("client:pending-tasks.title")} />
        <PendingTasksContainer />
      </div>
    </RideOverview>
  );
};

export default withTranslationReady(["generic", "client"])(PendingTasksScene);
