import React from "react";
import { IconProps } from "./IconProps";

export const IconSuccess = ({ className, size = 100 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="50" fill="#82D6E8" />
    <path
      d="M68.5725 37.1431L43.0368 62.8574L31.4297 51.169"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
