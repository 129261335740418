import { Link } from "react-router-dom";
import React from "react";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

interface DangerousHTML {
  __html: string;
  index: number;
}

interface FeatureModalContentProps extends WithTranslation {
  dangerouslySetFeatures: DangerousHTML[];
  productSheet?: string;
  productSheetText?: string;
  price: string;
  priceType: string;
  ctaAction: (() => void) | string;
  ctaLabel?: string | null;
}

export const FeatureModalContent = withTranslationReady(["client"])(
  ({
    dangerouslySetFeatures,
    productSheet,
    productSheetText,
    price,
    priceType,
    ctaAction,
    ctaLabel,
    t
  }: FeatureModalContentProps) => (
    <>
      <ul className="admin-service-modal__feature-list" data-testid="modal-feature-list">
        {dangerouslySetFeatures?.map((feature) => (
          <li
            key={feature.index}
            className="admin-service-modal__feature"
            dangerouslySetInnerHTML={feature}></li>
        ))}
      </ul>
      <div>
        {productSheet && (
          <a
            className="admin-service-modal__product-sheet"
            data-testid="modal-product-sheet"
            href={productSheet}
            target="_blank"
            rel="noopener noreferrer">
            {productSheetText}
          </a>
        )}

        <div className="admin-service-modal__footer">
          <div className="admin-service-modal__price-details">
            <div className="admin-service-modal__price" data-testid="modal-price">
              {price}
            </div>
            <div data-testid="modal-price-type" className="admin-service-modal__price-type">
              {priceType}
            </div>
          </div>
          <div className="admin-service-modal__cta-container">
            {typeof ctaAction === "function" && (
              <RideButtonDeprecated
                data-testid="modal-cta"
                size={ButtonSize.Big}
                variant={ButtonVariant.Primary}
                onClick={ctaAction}>
                {ctaLabel ?? t("client:company.admin-service.modal.cta")}
              </RideButtonDeprecated>
            )}
            {typeof ctaAction === "string" && (
              <Link data-testid="modal-cta" className="admin-service-modal__cta" to={ctaAction}>
                {ctaLabel ?? t("client:company.admin-service.modal.cta")}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
);
