import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { CompanyType } from "../../../global-query-types";
import {
  GetCompaniesWithASContractStatus,
  GetCompaniesWithASContractStatusVariables
} from "./graphql/GetCompaniesWithASContractStatus";

export const GET_COMPANIES_WITH_AS_CONTRACT_STATUS = gql`
  query GetCompaniesWithASContractStatus($type: CompanyType) {
    companies(type: $type) {
      id
      name
      legalForm
      customerSignedAdminServiceContract
      asUpgradeRequiredFrom
      asUpgradeMandatory
    }
  }
`;

export const QueryGetCompaniesWithASContractStatus = () =>
  useQuery<GetCompaniesWithASContractStatus, GetCompaniesWithASContractStatusVariables>(
    GET_COMPANIES_WITH_AS_CONTRACT_STATUS,
    {
      variables: { type: CompanyType.ClientEntity },
      fetchPolicy: "no-cache"
    }
  );
