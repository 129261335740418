import React from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import InvestmentsTable from "./InvestmentsTable";
import { useQuery } from "react-apollo";
import { ALL_INVESTMENTS } from "../../../lib/api/queries/AllInvestments";
import {
  AllInvestments,
  AllInvestmentsVariables
} from "../../../lib/api/queries/graphql/AllInvestments";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { RidePaginationWithSearch } from "../../../uiLibrary/designSystem/RidePagination/RidePaginationWithSearch";

export const InvestmentsTableContainer = () => {
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let history = useHistory();

  const limit = 15;
  const start = parseInt(queryParams.get("start") ?? "0");

  const setStart = (value) => {
    queryParams.set("start", value);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const query = queryParams.get("query");
  const setQuery = (value) => {
    queryParams.set("query", value);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const allInvestmentsResponse = useQuery<AllInvestments, AllInvestmentsVariables>(
    ALL_INVESTMENTS,
    {
      variables: {
        query,
        start,
        limit
      }
    }
  );

  const allInvestments = allInvestmentsResponse.data?.allInvestments;

  return (
    <RequestFeedbackHandler requests={[allInvestmentsResponse]}>
      <RidePaginationWithSearch
        setQuery={setQuery}
        setStart={setStart}
        start={start}
        query={query}
        count={allInvestments?.count ?? 0}
        limit={limit}>
        <InvestmentsTable investments={allInvestments?.investments ?? []} />
      </RidePaginationWithSearch>
    </RequestFeedbackHandler>
  );
};
