import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WepaStatusEnum } from "lib/models/client/GmbH";
import { RideBadgeIconEnum } from "uiLibrary/components/RideBadgeStatus/RideBadgeStatus";
import { GmbHAddServiceBadge, GmbHServiceStatusBadge } from "../GmbHServiceBadge/GmbHServiceBadge";

interface GmbHWepaBadgeProps extends WithTranslation {
  status: WepaStatusEnum;
  canActivate: boolean;
  onClick: () => void;
}

const GmbHWepaBadge = ({ t, status, canActivate, onClick }: GmbHWepaBadgeProps) => {
  if (status === WepaStatusEnum.INACTIVE) {
    return canActivate ? (
      <GmbHAddServiceBadge
        dataTestId="gmbh-activate-wepa-badge"
        label={t("generic:gmbh-badge-status.wepa.activate")}
        onClick={onClick}
      />
    ) : null;
  }

  const { iconVariant, labelKey } = wepaBadgeProps(status);

  return (
    <GmbHServiceStatusBadge
      dataTestId="gmbh-wepa-badge"
      iconVariant={iconVariant}
      label={t(labelKey)}
    />
  );
};

export default withTranslationReady(["generic"])(GmbHWepaBadge);

interface ServiceStatusBadgeProps {
  iconVariant: RideBadgeIconEnum;
  labelKey: string;
}

const wepaBadgeProps = (
  status: Exclude<WepaStatusEnum, WepaStatusEnum.INACTIVE>
): ServiceStatusBadgeProps => {
  switch (status) {
    case WepaStatusEnum.ACTIVE:
      return {
        iconVariant: RideBadgeIconEnum.GREEN,
        labelKey: "generic:gmbh-badge-status.wepa.active"
      };
    case WepaStatusEnum.FAILURE:
      return {
        iconVariant: RideBadgeIconEnum.RED,
        labelKey: "generic:gmbh-badge-status.wepa.failed"
      };
    case WepaStatusEnum.PROCESSING:
      return {
        iconVariant: RideBadgeIconEnum.ORANGE,
        labelKey: "generic:gmbh-badge-status.wepa.set-up"
      };
  }
};
