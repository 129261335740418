import React from "react";
import {
  RealEstateLabelField,
  RealEstateMyShareField,
  RealEstatePropertyValueField
} from "../../../../generic/real-estate-form/fields";
import { FormikProps, FormikProvider, useFormik } from "formik";
import { initialValues } from "./realEstateHelper";
import { Col, Container, Row } from "react-bootstrap";
import OwnershipField from "../../../../Investments/Ownership/OwnershipField";
import { RealEstateFormSchema } from "../../../../../lib/validation/RealEstateFormSchema";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RequestFeedbackForm from "sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";

export interface NomineeRealEstateFormProps extends WithTranslation {
  submitHandler: (values: any) => Promise<void>;
}

const NomineeRealEstateForm = ({ t, submitHandler }: NomineeRealEstateFormProps) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => submitHandler(values),
    validationSchema: RealEstateFormSchema(),
    validateOnChange: true
  });

  return (
    <FormikProvider value={formik}>
      <RequestFeedbackForm submitLabel={t("generic:save")} requests={[]}>
        <Container>
          <Row>
            <Col xs={6} xl={4}>
              <RealEstateLabelField t={t} formik={formik} />
            </Col>
            <Col xs={6} xl={4}>
              <RealEstatePropertyValueField t={t} formik={formik} />
            </Col>
            <Col xs={6} xl={4}>
              <RealEstateMyShareField t={t} formik={formik} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <OwnershipField
                formik={formik as unknown as FormikProps<{ ownerId: string }>}
                currentCompanyId={null}
              />
            </Col>
          </Row>
        </Container>
      </RequestFeedbackForm>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(NomineeRealEstateForm);
