import React, { useEffect } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FlowTypeEnum } from "../../../../global-query-types";
import logger from "../../../../common/Logger";
import GuestHeaderDeprecated from "../../../../guest/components/GuestHeaderDeprecated/GuestHeaderDeprecated";
import AdminServicePostOrderBanner from "../../../../components/generic/Trader-GmbH/AdminServicePostOrderBanner";
import ErrorAlertLegacy from "../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import LoadingAlert from "../../../../components/generic/LoadingAlert";
import { CONFIRM_EMAIL_LINK } from "../../../../lib/api/mutations/CONFIRM_EMAIL_LINK";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface ConfirmAccountProps extends WithTranslation {
  t: TFunction;
  flowType: FlowTypeEnum;
}

const ConfirmAccount = ({ t, flowType }: ConfirmAccountProps) => {
  const [confirmEmailLink, { loading: verifyEmailLoading, error: verifyEmailError }] =
    useMutation(CONFIRM_EMAIL_LINK);

  const { token } = useParams<{ token: string }>();

  const loading = verifyEmailLoading;
  const error = verifyEmailError;

  useEffect(() => {
    confirmEmailLink({ variables: { verificationToken: token } }).catch((error) => {
      logger.error(error);
      logger.trace("Unable to confirm email");
    });
    // NOTE: this is disabled so that this useEffect ever only runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingAlert t={t} loading={loading} />;

  if (error) return <ErrorAlertLegacy t={t} error={error?.message} />;

  return (
    <div className="trader-gmbh">
      <PageMetaData title={t("generic:confirm-account-page-title")} />
      <GuestHeaderDeprecated />
      <AdminServicePostOrderBanner />
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <IoIosCheckmarkCircle size="100" color="#82d6e8" />
          </Col>
        </Row>
        <Row className="mt-3 d-flex justify-content-center">
          <div className="order-status-description ml-3" data-testid="email-confirmed">
            {t("trader-gmbh:email-confirmation-description")}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation(["trader-gmbh", "errorCodes"])(ConfirmAccount);
