import React from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import ClientEntityTable from "./ClientEntityTable";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { AllCompanies, AllCompaniesVariables } from "../../../lib/api/queries/graphql/AllCompanies";
import { ALL_COMPANIES } from "lib/api/queries/AllCompanies";
import { useQuery } from "react-apollo";
import { CompanyType } from "../../../global-query-types";
import { RidePaginationWithSearch } from "../../../uiLibrary/designSystem/RidePagination/RidePaginationWithSearch";

export const ClientEntityTableContainer = () => {
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let history = useHistory();

  const limit = 15;
  const start = parseInt(queryParams.get("start") ?? "0");

  const setStart = (value) => {
    queryParams.set("start", value);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const query = queryParams.get("query");
  const setQuery = (value) => {
    queryParams.set("query", value);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const allCompaniesResponse = useQuery<AllCompanies, AllCompaniesVariables>(ALL_COMPANIES, {
    variables: {
      type: CompanyType.ClientEntity,
      query,
      start,
      limit
    }
  });
  let allClientEntities = allCompaniesResponse.data?.allCompanies;

  return (
    <RequestFeedbackHandler requests={[allCompaniesResponse]}>
      <RidePaginationWithSearch
        setQuery={setQuery}
        setStart={setStart}
        start={start}
        query={query}
        count={allClientEntities?.count ?? 0}
        limit={limit}>
        <ClientEntityTable clientEntities={allClientEntities?.companies ?? []} />
      </RidePaginationWithSearch>
    </RequestFeedbackHandler>
  );
};
