import React, { useContext } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { FaChevronDown } from "react-icons/fa";

export type AccordionElementProps = {
  headerText: string;
  eventKey: string;
  disabled?: boolean;
  buttonText?: string;
  onToggle: (key) => void;
};

export type Props = React.PropsWithChildren<AccordionElementProps>;

export const AccordionElement: React.FC<Props> = ({
  headerText,
  eventKey,
  buttonText,
  disabled = true,
  children,
  onToggle
}) => {
  const currentEventKey = useContext(AccordionContext);

  return (
    <OrderFlowStep disabled={disabled} eventKey={eventKey}>
      {disabled ? (
        <Card.Header className="text-secondary pt-0 bg-white flow-step__header">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                variant="outline-light"
                className="circular-steps"
                data-testid={`step-${eventKey}-toggle`}>
                {buttonText}
              </Button>
              {headerText}
            </div>
          </div>
        </Card.Header>
      ) : (
        <>
          <OrderFlowHeader
            onClick={() => onToggle(eventKey)}
            buttonText={buttonText}
            headerText={headerText}
            currentEventKey={currentEventKey}
            eventKey={eventKey}
          />
          <Accordion.Collapse eventKey={eventKey}>
            <OrderFlowBody eventKey={eventKey}>{children}</OrderFlowBody>
          </Accordion.Collapse>
        </>
      )}
    </OrderFlowStep>
  );
};

export const OrderFlowStep = ({ disabled, eventKey, ...rest }) => (
  <div
    {...rest}
    className={`flow-step ${disabled ? "" : "flow-step--active"}`}
    data-testid={eventKey}
  />
);

export const OrderFlowHeader = ({
  buttonText,
  headerText,
  currentEventKey,
  eventKey,
  isCell = false,
  ...rest
}) => {
  const cellClass = `${isCell ? "flow-step__header-cell" : "flow-step__header-inline"}`;
  const headerClass = `${isCell ? "flow-step__header--cells" : ""}`;

  return (
    <Card.Header {...rest} className={`pt-0 bg-white flow-step__header ${headerClass}`}>
      <Button variant="outline-light" className={`circular-steps ${cellClass}`}>
        {buttonText}
      </Button>
      <span className={cellClass}>{headerText}</span>
      {currentEventKey === eventKey ? "" : <FaChevronDown />}
    </Card.Header>
  );
};

export const OrderFlowBody = ({ eventKey, ...rest }) => (
  <Card.Body {...rest} data-testid={`step-${eventKey}`} className="flow-step__body" />
);
