import React from "react";
import PtaMigrationFlowToRideSteuerberatung from "./PtaMigrationFlowToRideSteuerberatung";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { ModalLayout } from "sharedComponents/templates/ModalLayout/ModalLayout";
import {
  AdminServicePTAMigrationToRideSteuerberatungHelper,
  AdminServicePTAMigrationToRideSteuerberatungOrderData,
  AdminServicePTAMigrationToRideSteuerberatungSteps,
  getNewAdminServicePTAMigrationToRideSteuerberatungOrderStructure,
  SteueragentenMigrationOrderDetails
} from "lib/helpers/AdminServicePTAMigrationToRideSteuerberatungHelper";
import PtaMigrationFlowToRideSteuerberatungSuccess from "../PtaMigrationFlowToRideSteuerberatungSuccess/PtaMigrationFlowToRideSteuerberatungSuccess";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { CompanyMigrationType, OrderProductTypeEnum } from "global-query-types";
import { UpsertOrder, UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { GetMe } from "lib/api/queries/MeQuery";
import { useHistory } from "react-router-dom";
import { OrderData } from "../../ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { FinalizeOrder, FinalizeOrderVariables } from "lib/api/mutations/graphql/FinalizeOrder";
import { FINALIZE_ORDER } from "lib/api/mutations/FINALIZE_ORDER";
import { PaymentMethodType, PaymentPlatformAccount } from "payments-query-types";
import { GetOrder } from "lib/api/queries/GetOrder";
import { usePaymentQuery } from "../../../../lib/hooks/payments/usePaymentQuery";
import {
  GetPaymentMethodForCompany,
  GetPaymentMethodForCompanyVariables
} from "../../../../lib/api/payment/queries/graphql/GetPaymentMethodForCompany";
import { GET_PAYMENT_METHOD_FOR_COMPANY } from "../../../../lib/api/payment/queries/GetPaymentMethodForCompany";
import {
  ValidateStripeSession,
  ValidateStripeSessionVariables
} from "lib/api/payment/queries/graphql/ValidateStripeSession";
import { VALIDATE_STRIPE_SESSION } from "lib/api/payment/queries/ValidateStripeSession";
import { getPaymentsContext } from "lib/api/payment/context";
import logger from "common/Logger";
import { LocationHelperProps, withLocationHelper } from "../../../../common/LocationHelper";
import {
  getStripeCheckoutSession,
  getStripeCheckoutSessionVariables
} from "lib/api/mutations/graphql/getStripeCheckoutSession";
import { GET_STRIPE_CHECKOUT_SESSION } from "lib/api/mutations/GET_STRIPE_CHECKOUT_SESSION";
import { usePaymentMutation } from "lib/hooks/payments/usePaymentMutation";
import {
  UpdatePaymentMethod,
  UpdatePaymentMethodVariables
} from "lib/api/payment/mutations/graphql/UpdatePaymentMethod";
import { UPDATE_PAYMENT_METHOD } from "lib/api/payment/mutations/UpdatePaymentMethod";
import { useQueryParams } from "lib/hooks/useQueryParams";
import { getCompanyMigrationRequired } from "lib/helpers/companyMigrationStatusHelper";

export interface PtaMigrationFlowToRideSteuerberatungContainerProps {
  companyId: string;
}

export const PtaMigrationFlowToRideSteuerberatungContainer = ({
  companyId,
  locationHelper
}: PtaMigrationFlowToRideSteuerberatungContainerProps & LocationHelperProps) => {
  const queryParams = useQueryParams();
  const [upsertOrderMutation] = useMutation<UpsertOrder, UpsertOrderVariables>(UPSERT_ORDER);
  const [finalizeSteueragentenMigrationOrder, finalizeSteueragentenMigrationOrderRequest] =
    useMutation<FinalizeOrder, FinalizeOrderVariables>(FINALIZE_ORDER);
  const sessionId = queryParams.get("session_id");

  const history = useHistory();
  const loggedUser = GetMe();
  const loggedInPerson = loggedUser.data?.me?.person;
  const client = useApolloClient();

  const companyByIdQuery = QueryCompanyById(companyId);
  const company = companyByIdQuery.data?.companyById;

  const migration = getCompanyMigrationRequired(
    company,
    CompanyMigrationType.PTAMigrationToRideSteuerberatung
  );

  if (company && !migration?.id) {
    logger.warn(
      "Attempt to go though AdminServicePTAMigrationToRideSteuerberatung flow without the requirements needed"
    );
    history.goBack();
  }

  let orderToFetch = company?.orders.find(
    (order) =>
      order.productType === OrderProductTypeEnum.AdminServicePTAMigrationToRideSteuerberatung
  ) as OrderData<AdminServicePTAMigrationToRideSteuerberatungOrderData> | undefined;

  const getOrderQuery = GetOrder(orderToFetch?.id, orderToFetch?.submissionId);
  const currentOrder = getOrderQuery.data?.getOrder;

  const [showSuccess, setShowSuccess] = React.useState(false);

  const [updatePaymentMethodMutation, updatePaymentMethodRequest] = usePaymentMutation<
    UpdatePaymentMethod,
    UpdatePaymentMethodVariables
  >(UPDATE_PAYMENT_METHOD);

  const onConfirmSuccessHandler = async () => {
    await companyByIdQuery.refetch();
    history.push(new GmbHDetails(company!).adminServiceUrl);
  };

  const onCloseHandler = () => {
    history.goBack();
  };

  const validateStripeSession = async (sessionId: string) => {
    try {
      return await client.query<ValidateStripeSession, ValidateStripeSessionVariables>({
        query: VALIDATE_STRIPE_SESSION,
        variables: {
          sessionId,
          paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
        },
        ...getPaymentsContext()
      });
    } catch (e) {
      logger.error(e);
    }
  };

  const goToCheckoutSession = async () => {
    const currentUrl = new URL(document.URL);

    const response = await client.query<
      getStripeCheckoutSession,
      getStripeCheckoutSessionVariables
    >({
      query: GET_STRIPE_CHECKOUT_SESSION,
      variables: {
        companyId: company?.id ?? "",
        successUrl: currentUrl.toString(),
        cancelUrl: currentUrl.toString(),
        paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
      }
    });

    const checkoutSessionUrl = response.data.getStripeCheckoutSession?.redirectUrl;
    if (checkoutSessionUrl) {
      locationHelper.redirect(checkoutSessionUrl);
    }
  };
  const getPaymentMethodForCompany = usePaymentQuery<
    GetPaymentMethodForCompany,
    GetPaymentMethodForCompanyVariables
  >(GET_PAYMENT_METHOD_FOR_COMPANY, {
    variables: {
      companyId: company?.id ?? "",
      paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
    }
  });

  const rideSteuerberatungPaymentMethod =
    getPaymentMethodForCompany.data?.getPaymentMethodForCompany ?? null;

  const upsertOrder = async (data: UpsertOrderVariables) => {
    await upsertOrderMutation({
      variables: data
    });
    await companyByIdQuery.refetch();
    await getOrderQuery.refetch();
  };

  function validateUpdateOrder() {
    if (!currentOrder?.id) {
      console.log("There is no order");
    }
  }

  const updateOrder = async (data: UpsertOrderVariables) => {
    validateUpdateOrder();
    await upsertOrder(data);
  };

  async function completeChoosePackageStepHandler(tier: AdminServiceTier) {
    const hasOngoingOrder: boolean = !!currentOrder?.id;
    let extra: AdminServicePTAMigrationToRideSteuerberatungOrderData = {
      ...currentOrder?.extra,
      tier,
      stepCompletion: {
        ...currentOrder?.extra.stepCompletion,
        choosePackageAt: new Date(Date.now()).toISOString()
      }
    };

    if (hasOngoingOrder) {
      await updateOrder({
        id: currentOrder?.id,
        extra
      });
    } else {
      const initialStructure = getNewAdminServicePTAMigrationToRideSteuerberatungOrderStructure(
        company,
        loggedInPerson,
        migration?.id
      );
      if (initialStructure?.entityId) {
        await upsertOrder({
          ...initialStructure,
          extra: {
            ...initialStructure?.extra,
            ...extra
          }
        });
      } else {
        logger.debug("error creating order");
      }
    }
    AdminServicePTAMigrationToRideSteuerberatungHelper.StepCompleted({
      step_name: AdminServicePTAMigrationToRideSteuerberatungSteps.choosePackage
    });
  }

  const updatePaymentMethod = async (paymentMethod: PaymentMethodType) => {
    await updatePaymentMethodMutation({
      variables: {
        companyId: company?.id ?? "",
        paymentMethod,
        paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
      },
      refetchQueries: [
        {
          query: GET_PAYMENT_METHOD_FOR_COMPANY,
          variables: {
            companyId: company?.id ?? "",
            paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
          },
          ...getPaymentsContext()
        }
      ]
    });
  };

  async function completeContractSignatureStep(downloadAt: string, agreedAt: string) {
    let extra: AdminServicePTAMigrationToRideSteuerberatungOrderData = {
      ...currentOrder?.extra,
      contractSignedAt: agreedAt,
      stepCompletion: {
        ...currentOrder?.extra.stepCompletion,
        downloadContractAt: downloadAt,
        agreeToTermsAndConditionsAt: agreedAt //or now
      }
    };

    await updateOrder({
      id: currentOrder?.id,
      extra
    });
    AdminServicePTAMigrationToRideSteuerberatungHelper.StepCompleted({
      step_name: AdminServicePTAMigrationToRideSteuerberatungSteps.agreeToTermsAndConditions
    });
  }

  async function completeSetupPaymentStepHandler(
    paymentMethod: PaymentMethodType,
    sessionId?: string
  ) {
    let extra: AdminServicePTAMigrationToRideSteuerberatungOrderData = {
      ...currentOrder?.extra,
      paymentMethod,
      sessionId,
      stepCompletion: {
        ...currentOrder?.extra.stepCompletion,
        setupPaymentAt: new Date(Date.now()).toISOString()
      }
    };

    await updateOrder({
      id: currentOrder?.id,
      extra
    });
    AdminServicePTAMigrationToRideSteuerberatungHelper.StepCompleted({
      step_name: AdminServicePTAMigrationToRideSteuerberatungSteps.setupPayment
    });
  }

  async function completeReviewStepHandler() {
    let extra: AdminServicePTAMigrationToRideSteuerberatungOrderData = {
      ...currentOrder?.extra,
      stepCompletion: {
        ...currentOrder?.extra.stepCompletion,
        reviewAt: new Date(Date.now()).toISOString()
      }
    };

    await updateOrder({
      id: currentOrder?.id,
      extra
    });
    AdminServicePTAMigrationToRideSteuerberatungHelper.StepCompleted({
      step_name: AdminServicePTAMigrationToRideSteuerberatungSteps.review
    });

    await finalizeSteueragentenMigrationOrder({
      variables: {
        id: currentOrder?.id ?? "",
        submissionId: currentOrder?.submissionId ?? ""
      }
    });
    //TODO: flow_completed

    setShowSuccess(true);
  }

  return (
    <ModalLayout onClose={onCloseHandler}>
      <div className="pta-migration-flow-to-ride-container">
        <RequestFeedbackHandler
          requests={[
            getOrderQuery,
            companyByIdQuery,
            finalizeSteueragentenMigrationOrderRequest,
            updatePaymentMethodRequest
          ]}
        />
        {showSuccess ? (
          <PtaMigrationFlowToRideSteuerberatungSuccess onConfirm={onConfirmSuccessHandler} />
        ) : (
          company && (
            <PtaMigrationFlowToRideSteuerberatung
              gmbh={new GmbHDetails(company)}
              order={currentOrder as SteueragentenMigrationOrderDetails}
              completeChoosePackageStep={completeChoosePackageStepHandler}
              completeContractSignatureStep={completeContractSignatureStep}
              completeSetupPaymentStep={completeSetupPaymentStepHandler}
              completeReviewStep={completeReviewStepHandler}
              validateStripeSession={validateStripeSession}
              goToCheckoutSession={goToCheckoutSession}
              sessionId={sessionId}
              paymentMethod={rideSteuerberatungPaymentMethod}
              updatePaymentMethod={updatePaymentMethod}
            />
          )
        )}
      </div>
    </ModalLayout>
  );
};

export default withLocationHelper(PtaMigrationFlowToRideSteuerberatungContainer);
