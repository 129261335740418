import React from "react";
import { Container } from "react-bootstrap";
import { TFunction } from "i18next";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../CompanyCreation/graphql/MyCompanyCreationQuery";
import { getOrderPackageAndPrice } from "../../../../../lib/formatters/config";
import { Order } from "../../../../../client/components/CompanyCreation/Steps/Order/Order";
import { FlowTypeEnum } from "../../../../../global-query-types";

interface PackageTypeFormProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation;
  refetchAndResetStep: (id: string) => Promise<void>;
  currentFlowType: FlowTypeEnum;
}

export const CompleteOrderForm = ({
  t,
  myCompanyCreationDetails,
  refetchAndResetStep,
  currentFlowType
}: PackageTypeFormProps) => {
  const currentPackageType = myCompanyCreationDetails?.packageType;

  const selectedPackage = getOrderPackageAndPrice(currentPackageType!)!;

  const render = () => {
    return (
      <Container>
        <Order
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          refetchAndResetStep={refetchAndResetStep}
          currentFlowType={currentFlowType}
          selectedPackage={selectedPackage}
        />
      </Container>
    );
  };

  return render();
};
