import React, { PropsWithChildren } from "react";
import { ButtonProps } from "react-bootstrap";

export interface ButtonCloseProps extends PropsWithChildren<ButtonProps> {
  onClick: () => void;
  "data-testid"?: string;
  classNames?: string;
}

const ButtonClose = ({
  onClick,
  "data-testid": dataTestId = "button-close",
  classNames
}: ButtonCloseProps) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <div
      data-testid={dataTestId}
      className={`v2-button-close ${classNames ?? ""}`}
      onClick={handleClick}></div>
  );
};

export default ButtonClose;
