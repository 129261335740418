import React from "react";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { CompanyById_companyById_group_memberships } from "lib/api/queries/graphql/CompanyById";
import { personName } from "lib/formatters/personFormatter";
import * as Yup from "yup";
import { AdminServiceOrderData } from "lib/models/client/AdminService/AdminServiceOrderData";

export interface ShareholdersContextProps extends StepComponentProps<AdminServiceOrderData> {
  memberships: CompanyById_companyById_group_memberships[];
}

const ShareholdersContextStep = ({
  t,
  order,
  controls,
  saveData,
  currentStep,
  memberships
}: ShareholdersContextProps & WithTranslation) => {
  const shareholders = memberships.filter(
    (membership) => membership.role === "Shareholder" && membership.person
  );

  const buildInitialValues = (
    shareholders: CompanyById_companyById_group_memberships[],
    orderShareholders: AdminServiceOrderData["shareholders"]
  ) => {
    const initialValues = {};

    for (let i = 0; i < shareholders.length; i++) {
      const orderShareholder = Object.values(orderShareholders ?? {}).find(
        (shareholder) => shareholder.personId === shareholders[i].person?.id
      );

      initialValues[`shareholder-${i}-pep`] = orderShareholder?.pep;
      initialValues[`shareholder-${i}-pep-context`] = orderShareholder?.pepContext;
      initialValues[`shareholder-${i}-source-of-wealth`] = orderShareholder?.sourceOfWealth;
    }

    return initialValues;
  };

  const formik = useRideFormik({
    initialValues: buildInitialValues(shareholders, order.extra?.shareholders),
    onSubmit: async (values) => {
      currentStep.complete();

      const shareholdersDict = {};

      shareholders.forEach((shareholder, index) => {
        shareholdersDict[index.toString()] = {
          personId: shareholder.person?.id,
          name: personName(shareholder.person),
          pep: values[`shareholder-${index}-pep`],
          pepContext: values[`shareholder-${index}-pep-context`],
          sourceOfWealth: values[`shareholder-${index}-source-of-wealth`]
        };
      });

      await saveData({
        ...currentStep.chapter.order.serialize(),
        shareholders: shareholdersDict
      });
    },
    validationSchema: ShareholdersContextSchema(t, shareholders.length)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:admin-service-form.shareholders-context.title")} />
        {shareholders.map((shareholder, index) => (
          <div
            key={shareholder.person?.id}
            className={"my-5"}
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Typography
              data-testid={`shareholder-${index}-name`}
              category={"Headline"}
              size={300}
              weight={"Heavy"}>
              {personName(shareholder.person)}
            </Typography>
            <RideRadioSelect
              variant={RadioSelectVariant.Light}
              name={`shareholder-${index}-pep`}
              label={t(
                "generic:admin-service-form.shareholders-context.politically-exposed-question"
              )}
              options={[
                { label: t("generic:yes"), value: "yes" },
                { label: t("generic:no"), value: "no" }
              ]}
            />
            {formik.values[`shareholder-${index}-pep`] === "yes" && (
              <RideTextInput
                name={`shareholder-${index}-pep-context`}
                label={t("generic:pep-context")}
              />
            )}
            <RideTextInput
              name={`shareholder-${index}-source-of-wealth`}
              label={t("generic:admin-service-form.shareholders-context.source-of-wealth")}
            />
          </div>
        ))}
        {controls}
      </Form>
    </FormikProvider>
  );
};

const ShareholdersContextSchema = (t: TFunction, numberOfShareholders: number) => {
  let schema = Yup.object();

  for (let i = 0; i < numberOfShareholders; i++) {
    schema = schema.concat(ShareholderSchema(t, i));
  }

  return schema;
};

const ShareholderSchema = (t: TFunction, index: number) =>
  Yup.object().shape({
    [`shareholder-${index}-pep`]: Yup.string().required(t("generic:validation-required")),
    [`shareholder-${index}-source-of-wealth`]: Yup.string().required(
      t("generic:validation-required")
    ),
    [`shareholder-${index}-pep-context`]: Yup.string().when(`shareholder-${index}-pep`, {
      is: "yes",
      then: Yup.string().required(t("generic:validation-required"))
    })
  });

export default withTranslationReady("generic")(ShareholdersContextStep);
