import React from "react";
import { useQuery } from "react-apollo";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { GET_INVESTMENT_BY_ID } from "../../../../lib/api/queries/GetInvestmentById";
import {
  GetInvestmentById,
  GetInvestmentByIdVariables
} from "../../../../lib/api/queries/graphql/GetInvestmentById";
import { useParams } from "react-router";
import { InvestmentDetails } from "./InvestmentDetails";
import { GET_INVESTMENT_STORAGE_NODES } from "../../../../lib/api/queries/GetInvestmentStorageNodes";
import {
  GetInvestmentStorageNodes,
  GetInvestmentStorageNodesVariables
} from "../../../../lib/api/queries/graphql/GetInvestmentStorageNodes";
import {
  InvestmentCardPropsWithId,
  toInvestmentCardPropsFromInvestment
} from "../../../../lib/dataAdapters/investment/investment";

export interface InvestmentDetailsContainerProps {
  onInvestmentLoad: (investment: InvestmentCardPropsWithId) => void;
}

export const InvestmentDetailsContainer = ({
  onInvestmentLoad
}: InvestmentDetailsContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const getInvestmentStorageNodes = useQuery<
    GetInvestmentStorageNodes,
    GetInvestmentStorageNodesVariables
  >(GET_INVESTMENT_STORAGE_NODES, {
    variables: {
      id
    }
  });
  const getInvestmentRequest = useQuery<GetInvestmentById, GetInvestmentByIdVariables>(
    GET_INVESTMENT_BY_ID,
    {
      variables: {
        id
      }
    }
  );

  const files = getInvestmentStorageNodes.data?.getInvestmentStorageNodes ?? [];
  const investment = getInvestmentRequest.data?.getInvestmentById;
  if (investment) {
    const investmentCardProps = toInvestmentCardPropsFromInvestment(investment);
    onInvestmentLoad(investmentCardProps);
  }

  return (
    <RequestFeedbackHandler requests={[getInvestmentRequest]} noLoadingAnimation={true}>
      {investment && <InvestmentDetails investment={investment} files={files} />}
    </RequestFeedbackHandler>
  );
};
