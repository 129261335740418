import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import logger from "../../../common/Logger";
import { uploadFile } from "../../../common/uploadFile";
import {
  presignRealEstateGalleryUpload,
  presignRealEstateGalleryUploadVariables
} from "./graphql/presignRealEstateGalleryUpload";

export const PRESIGN_REALESTATE_GALLERY_UPLOAD = gql`
  mutation presignRealEstateGalleryUpload($realEstateId: String!, $type: String!) {
    presignRealEstateGalleryUpload(realEstateId: $realEstateId, type: $type) {
      url
      key
    }
  }
`;

interface UploadToS3Props extends WithTranslation {
  files: File[];
  submitHandler: () => void;
  realEstateId: string;
  onUploadStarted: () => void;
  isDisabled: boolean;
}

export const UploadToS3: React.FC<UploadToS3Props> = (props) => {
  const { submitHandler, onUploadStarted, files, realEstateId, isDisabled, t } = props;
  const [isLoading, setLoading] = useState(false);

  const [getPresignPostUrl] = useMutation<
    presignRealEstateGalleryUpload,
    presignRealEstateGalleryUploadVariables
  >(PRESIGN_REALESTATE_GALLERY_UPLOAD);

  const handleClick = async () => {
    setLoading(true);
    onUploadStarted();

    const status = files.map(async (file: File) => {
      try {
        const { data } = await getPresignPostUrl({
          variables: {
            realEstateId: realEstateId,
            type: file.type
          }
        });

        if (data?.presignRealEstateGalleryUpload) {
          const formData = new FormData();
          formData.append("file", file);

          const uploadUrl = data.presignRealEstateGalleryUpload.url!;
          const S3Response = await uploadFile("PUT", uploadUrl, file);
          return S3Response.status;
        }
      } catch (error) {
        logger.trace("getting presign urls not successful");
        logger.error(error);
        throw error;
      }
    });

    const resp = await Promise.all(status);

    if (resp.every((status) => status === 200)) {
      setLoading(false);
      submitHandler();
    } else {
      // TODO: handle failures
    }
  };

  return (
    <Button
      data-testid="confirm-upload"
      variant="primary"
      className="float-sm-right"
      disabled={isLoading || isDisabled}
      onClick={!isLoading ? handleClick : () => null}>
      {isLoading ? t("generic:in-progress") : t("generic:upload")}
    </Button>
  );
};

export default withTranslation(["generic"])(UploadToS3);
