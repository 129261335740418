import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import LoadingAlert from "../generic/LoadingAlert";
import ErrorAlertLegacy from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { useCompanyRealEstateDetails } from "./useCompanyRealEstateDetails";
import InvestmentsDetails from "./InvestmentsDetails";

interface InvestmentDetailPageProps extends WithTranslation, RouteComponentProps {
  companyId: string;
  backUrl: string;
}

export const InvestmentDetailPage: React.FunctionComponent<InvestmentDetailPageProps> = (props) => {
  const { t, backUrl } = props;
  let companyId = props.match.params["companyId"];

  const { companyInfo, realEstateInfo, loading, errorReason } =
    useCompanyRealEstateDetails(companyId);

  if (loading) return <LoadingAlert t={t} message={t("generic:loading-message")} />;

  if (errorReason) return <ErrorAlertLegacy t={t} error={errorReason.message} />;

  if (!realEstateInfo! || !companyInfo!) return <p>{t("generic:no-data-error")}</p>;

  return (
    <InvestmentsDetails
      detailInvestmentRealEstateData={realEstateInfo!}
      investmentDetail={companyInfo!}
      detailRealEstateData={null}
      backUrl={backUrl}
    />
  );
};
export default withTranslation(["realEstate", "wallet", "errorCodes", "generic"])(
  InvestmentDetailPage
);
