import React, { useEffect, useState } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  FeatureFlags,
  OrderCompanyStatusEnum,
  OrderEntityType,
  OrderProductTypeEnum
} from "global-query-types";
import { PaymentMethodType } from "payments-query-types";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { RideStatus } from "lib/types/types";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "lib/api/mutations/graphql/UpsertOrder";
import {
  mapPaymentMethodFromPaymentsModule,
  mapPaymentMethodToPaymentsModule,
  PaymentMethod
} from "lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import RideModal, {
  RideModalBody,
  RideModalControls
} from "uiLibrary/designSystem/RideModal/RideModal";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { OnboardingStepCardStatus } from "../OnboardingStepCard/OnboardingStepCard";
import ChoosePackage from "client/components/AdminServiceTiersOnboarding/ChoosePackage/ChoosePackage";
import SelectAdditionalFiscalYears from "client/components/AdminServiceTiersOnboarding/SelectAdditionalFiscalYears/SelectAdditionalFiscalYears";
import ContractSignatureCard, {
  ContractSignatureCardFlow
} from "../MigrateAdminServicePackage/ContractSignatureCard/ContractSignatureCard";
import SetupPaymentCard from "../MigrateAdminServicePackage/SetupPaymentCard/SetupPaymentCard";
import ChangePaymentMethodPopup from "../ChangePaymentMethodPopup/ChangePaymentMethodPopup";
import ReviewStep from "./ReviewStep/ReviewStep";
import { getCurrentYear } from "lib/dateTime/getCurrentYear";
import { GrundungServiceStatusEnum } from "lib/models/client/GmbH";
import { FinalizeOrderVariables } from "lib/api/mutations/graphql/FinalizeOrder";
import _, { pick } from "lodash";
import PreliminaryTask from "./PreliminaryTask/PreliminaryTask";
import AdminServiceOrderFormTask from "./AdminServiceOrderFormTask/AdminServiceOrderFormTask";
import { CompanyById_companyById_orders } from "lib/api/queries/graphql/CompanyById";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import { checkForPreliminaryTask } from "common/checkForPreliminaryTask";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { getBlockedTiersForAdminService } from "common/getBlockedTiersForAdminService";
import { shortOrderFormProductPrices } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { AdminServiceOrderData } from "lib/models/client/AdminService/AdminServiceOrderData";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { WithStripePayment } from "../WepaSection/WepaOnboarding/WepaOnboarding";

interface AdminServiceTiersOnboardingProps extends WithTranslation {
  gmbh: GmbHDetails;
  upsertOrder: (order: UpsertOrderVariables) => Promise<UpsertOrder_upsertOrder | null | undefined>;
  finalizeOrder: (order: FinalizeOrderVariables) => Promise<void>;
  bankAccountTaskCompleted?: boolean;
}

const AdminServiceTiersOnboarding = ({
  t,
  gmbh,
  sessionId,
  upsertOrder,
  bankAccountTaskCompleted,
  finalizeOrder,
  paymentMethod,
  goToCheckoutSession,
  updatePaymentMethod,
  validateStripeSession
}: AdminServiceTiersOnboardingProps & WithStripePayment) => {
  const [editingStep, setEditingStep] = useState<string>();
  const [showContactCustomerSupportModal, setShowContactCustomerSupportModal] = useState(false);
  const history = useHistory();

  const className = "admin-service-tiers-onboarding";

  const asOrderInProgressOrBooked = gmbh.orders.find(
    (order) =>
      order.productType === OrderProductTypeEnum.AdminService &&
      (order.status === OrderCompanyStatusEnum.InProgress ||
        order.status === OrderCompanyStatusEnum.Booked)
  ) as OrderData<AdminServiceOrderData> | undefined;

  const renameVvToRideSteuerberatung = FeatureFlagService.instance.isEnabled(
    FeatureFlags.RenameVvSteuerberatungToRideSteuerberatung
  );

  const getContractUrl = (tier, adminServiceId): string => {
    const documentName = renameVvToRideSteuerberatung
      ? `AS%20${_.capitalize(tier)}%20RIDE%20Steuerberatung`
      : `AS%20${_.capitalize(tier)}`;
    return `${getEndpoint()}/api/client/document-templates/admin-service/${documentName}/${adminServiceId}?apiToken=${getToken()}&format=pdf`;
  };

  const preliminaryTask = checkForPreliminaryTask(gmbh, bankAccountTaskCompleted);

  useEffect(() => {
    if (sessionId && asOrderInProgressOrBooked?.id) {
      validateStripeSession(sessionId).then((result) => {
        if (result) {
          updatePaymentMethodHandler(PaymentMethod.Sepa).then(() => {});
        }
      });

      history.replace(`/client/entities/${gmbh.id}/admin-service`);
    }
    // eslint-disable-next-line
  }, [asOrderInProgressOrBooked?.id]);

  const selectTier = async (tier: AdminServiceTier) => {
    setEditingStep(undefined);
    await updateTier(tier);
  };

  const upsertAdminServiceOrder = async (): Promise<void> => {
    let order: CompanyById_companyById_orders | UpsertOrder_upsertOrder | null | undefined;

    if (!asOrderInProgressOrBooked) {
      order = await upsertOrder({
        status: OrderCompanyStatusEnum.InProgress,
        productType: OrderProductTypeEnum.AdminService,
        entityType: OrderEntityType.Company,
        entityId: gmbh.id
      });
    } else {
      order = asOrderInProgressOrBooked as CompanyById_companyById_orders | undefined;
    }

    history.push(`/client/order/generic/documents/0/${order?.id}/${order?.submissionId}`);
  };

  const updateTier = async (tier: AdminServiceTier) => {
    await upsertOrder({
      id: asOrderInProgressOrBooked?.id,
      extra: {
        adminService: {
          ...asOrderInProgressOrBooked?.extra?.adminService,
          tier
        },
        ...(asOrderInProgressOrBooked?.extra?.adminService?.tier !== tier && {
          contractAcceptedAt: null
        })
      }
    });
  };

  const selectFiscalYearsHandler = async (numberOfAdditionalYears: number) => {
    setEditingStep(undefined);

    await upsertOrder({
      id: asOrderInProgressOrBooked?.id,
      extra: {
        adminService: {
          ...asOrderInProgressOrBooked?.extra?.adminService,
          numberOfAdditionalYears
        }
      }
    });
  };

  const agreeToTermsAndConditions = async () => {
    setEditingStep(undefined);

    await upsertOrder({
      id: asOrderInProgressOrBooked?.id,
      extra: {
        contractAcceptedAt: new Date(Date.now()).toISOString(),
        useRideSteuerberatungContract: renameVvToRideSteuerberatung
      }
    });
  };

  const updatePaymentMethodHandler = async (option: PaymentMethod) => {
    setEditingStep(undefined);

    const paymentMethod = mapPaymentMethodToPaymentsModule(option);
    await updatePaymentMethod(paymentMethod);

    await upsertOrder({
      id: asOrderInProgressOrBooked?.id ?? "",
      extra: {
        paymentMethod
      }
    });

    if (sessionId) {
      await upsertOrder({
        id: asOrderInProgressOrBooked?.id,
        extra: {
          stripeSessionId: sessionId
        }
      });
    }
  };

  const setupPaymentMethodHandler = async (option: PaymentMethod) => {
    if (option === PaymentMethod.Sepa) {
      goToCheckoutSession();
    } else {
      await updatePaymentMethodHandler(option);
    }
  };

  const onEditPaymentMethodHandler = () => {
    if (paymentMethod === PaymentMethodType.Sepa) {
      setShowContactCustomerSupportModal(true);
    } else {
      onEditHandler("setupPaymentMethod");
    }
  };

  const completeReviewStep = async () => {
    if (!asOrderInProgressOrBooked) {
      return;
    }

    await finalizeOrder(pick(asOrderInProgressOrBooked, ["id", "submissionId"]));

    history.push(`/client/entities/${gmbh.id}/admin-service?onboarding-completed=true`);
  };

  const getStepStatus = (onboardingKey: string): OnboardingStepCardStatus => {
    const { adminService, contractAcceptedAt } = asOrderInProgressOrBooked?.extra ?? {};

    switch (onboardingKey) {
      case "orderFormTask":
        if (preliminaryTask.showTask) {
          return RideStatus.LOCKED;
        } else {
          return asOrderInProgressOrBooked?.status === OrderCompanyStatusEnum.Booked
            ? RideStatus.COMPLETED
            : RideStatus.PENDING;
        }
      case "tierSelection":
        if (asOrderInProgressOrBooked?.status !== OrderCompanyStatusEnum.Booked) {
          return RideStatus.LOCKED;
        } else {
          return adminService?.tier ? RideStatus.COMPLETED : RideStatus.PENDING;
        }
      case "additionalFiscalYears":
        if (
          preliminaryTask.showTask ||
          asOrderInProgressOrBooked?.status !== OrderCompanyStatusEnum.Booked
        ) {
          return RideStatus.LOCKED;
        } else if (
          editingStep !== "additionalFiscalYears" &&
          (adminService?.numberOfAdditionalYears || adminService?.numberOfAdditionalYears === 0)
        ) {
          return RideStatus.COMPLETED;
        } else if (getStepStatus("tierSelection") === RideStatus.COMPLETED) {
          return RideStatus.PENDING;
        } else {
          return RideStatus.LOCKED;
        }
      case "termsAndConditions":
        if (
          preliminaryTask.showTask ||
          asOrderInProgressOrBooked?.status !== OrderCompanyStatusEnum.Booked
        ) {
          return RideStatus.LOCKED;
        } else if (contractAcceptedAt) {
          return RideStatus.COMPLETED;
        } else if (getStepStatus("additionalFiscalYears") === RideStatus.COMPLETED) {
          return RideStatus.PENDING;
        } else {
          return RideStatus.LOCKED;
        }
      case "setupPaymentMethod":
        if (paymentMethod) {
          return RideStatus.COMPLETED;
        } else if (getStepStatus("termsAndConditions") === RideStatus.COMPLETED) {
          return RideStatus.PENDING;
        } else {
          return RideStatus.LOCKED;
        }
      case "reviewStep":
        if (
          getStepStatus("tierSelection") === RideStatus.COMPLETED &&
          getStepStatus("additionalFiscalYears") === RideStatus.COMPLETED &&
          getStepStatus("termsAndConditions") === RideStatus.COMPLETED &&
          getStepStatus("setupPaymentMethod") === RideStatus.COMPLETED &&
          !editingStep
        ) {
          return RideStatus.PENDING;
        } else {
          return RideStatus.LOCKED;
        }
      default:
        return RideStatus.PENDING;
    }
  };

  const onEditHandler = (stepOnboardingKey: string) => {
    setEditingStep(stepOnboardingKey);
  };

  const initialTier = asOrderInProgressOrBooked?.extra?.adminService?.tier ?? undefined;

  const preSelectedFiscalYears =
    asOrderInProgressOrBooked?.extra?.adminService?.numberOfAdditionalYears ?? undefined;

  const iban = gmbh.bankAccounts?.[0]?.iban;

  const paymentMethodToTranslationKey = paymentMethod === "Sepa" ? "sepa" : "manual-bank-transfer";

  const considerUFOCompanyQuestions = FeatureFlagService.instance.isEnabled(
    FeatureFlags.CompanyCategoryQuestionShortOrderForm
  );

  const orderingBroker = gmbh.orders.some(
    (order) =>
      order.productType === "BrokerageAccount" && order.status !== OrderCompanyStatusEnum.Canceled
  );

  const ufoOrder = gmbh.orders.find((o) => o.productType === "UnifiedShortOrderForm");
  const disabledAdminServiceTiers = getBlockedTiersForAdminService(
    {
      orderingBroker,
      companyOwnsRealEstate: ufoOrder?.extra.companyOwnsRealEstate,
      companyDoesNotFallIntoCategories: ufoOrder?.extra.companyDoesNotFallIntoCategories
    },
    considerUFOCompanyQuestions
  );

  return (
    <div data-testid="admin-service-tiers-onboarding" className={className}>
      <div className={`${className}__header`}>
        {showContactCustomerSupportModal && (
          <RideModal
            title={
              paymentMethod
                ? t(
                    `generic:payment-method-card.method-choice.${paymentMethodToTranslationKey}.name`
                  )
                : ""
            }
            onClose={() => setShowContactCustomerSupportModal(false)}>
            <RideModalBody>
              <ChangePaymentMethodPopup />
            </RideModalBody>
            <RideModalControls>
              <RideButton
                variant={RideButtonVariant.PRIMARY}
                size={RideButtonSize.BIG}
                label={t("generic:close")}
                onClick={() => setShowContactCustomerSupportModal(false)}
              />
            </RideModalControls>
          </RideModal>
        )}
        <Typography
          category={"Headline"}
          weight={"Heavy"}
          size={200}
          data-testid="admin-service-tiers-onboarding-title">
          <Trans i18nKey={t(`generic:admin-service-tiers-onboarding.title`)} />
        </Typography>
        <Typography
          category={"Paragraph"}
          weight={"Light"}
          size={100}
          data-testid="admin-service-tiers-onboarding-subtitle">
          <Trans i18nKey={t(`generic:admin-service-tiers-onboarding.subtitle`)} />
        </Typography>
      </div>
      <Typography
        className={`${className}__next-steps`}
        category={"Headline"}
        size={300}
        weight={"Heavy"}
        data-testid="admin-service-tiers-onboarding-next-steps">
        {t("generic:admin-service-tiers-onboarding.next-steps")}
      </Typography>
      {preliminaryTask.showTask && <PreliminaryTask companyId={gmbh.id} task={preliminaryTask} />}
      <AdminServiceOrderFormTask
        stepNumber={1}
        status={getStepStatus("orderFormTask")}
        upsertOrder={upsertAdminServiceOrder}
      />
      <ChoosePackage
        stepNumber={2}
        updateTier={selectTier}
        status={getStepStatus("tierSelection")}
        preSelectedTier={initialTier}
        forceOpening={editingStep === "tierSelection"}
        toggleEditing={onEditHandler}
        disabledOptions={disabledAdminServiceTiers}
      />
      <SelectAdditionalFiscalYears
        stepNumber={3}
        status={getStepStatus("additionalFiscalYears")}
        forceOpening={editingStep === "additionalFiscalYears"}
        toggleEditing={onEditHandler}
        selectFiscalYears={selectFiscalYearsHandler}
        numberOfFiscalYearsPreSelected={preSelectedFiscalYears}
        tier={asOrderInProgressOrBooked?.extra?.adminService?.tier}
      />
      <ContractSignatureCard
        stepNumber={4}
        successPageUrl={""}
        downloadSignedContractUrl={""}
        status={getStepStatus("termsAndConditions")}
        selectedTier={initialTier}
        contractUrl={getContractUrl(
          asOrderInProgressOrBooked?.extra?.adminService?.tier?.toUpperCase(),
          gmbh.adminService?.id
        )}
        paymentMethod={PaymentMethod.ManualBankTransfer}
        onAgreeToTermsAndConditions={agreeToTermsAndConditions}
        flow={ContractSignatureCardFlow.ADMIN_SERVICE_ONBOARDING}
      />
      <SetupPaymentCard
        stepNumber={5}
        companyName={gmbh.name}
        iban={iban}
        price={initialTier && shortOrderFormProductPrices.adminService[initialTier]}
        status={getStepStatus("setupPaymentMethod")}
        setupPayment={setupPaymentMethodHandler}
        forceOpening={editingStep === "setupPaymentMethod"}
        toggleEditing={onEditPaymentMethodHandler}
        initialPaymentMethod={
          paymentMethod ? mapPaymentMethodFromPaymentsModule(paymentMethod) : undefined
        }
        footnote={t("generic:payment-method-card.payment-date-info-as-onboarding")}
        showYearlyPrice={false}
      />
      <ReviewStep
        iban={iban}
        tier={initialTier}
        companyName={gmbh.name}
        status={getStepStatus("reviewStep")}
        paymentMethod={paymentMethod ?? undefined}
        startingFiscalYear={preSelectedFiscalYears && getCurrentYear() - preSelectedFiscalYears}
        hasCompanyFoundingService={
          gmbh.grundungServiceStatus !== GrundungServiceStatusEnum.NOT_ACQUIRED
        }
        onSubmit={completeReviewStep}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(AdminServiceTiersOnboarding);
