import React, { useState } from "react";
import { SectionWrapper } from "admin/components/LowTaxBrokerOrderDetails/SectionWrapper/SectionWrapper";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { BrokerBankEnum, BrokerNameEnum, FeatureFlags } from "global-query-types";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { UpsertBrokerageAccountVariables } from "lib/api/mutations/graphql/UpsertBrokerageAccount";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { CompanyBrokerageAccounts_companyBrokerageAccounts } from "lib/api/queries/graphql/CompanyBrokerageAccounts";

interface FundAccountSectionProps extends WithTranslation {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order;
  ltbBrokerageAccount: CompanyBrokerageAccounts_companyBrokerageAccounts | null | undefined;
  upsertBrokerageAccount: (data: UpsertBrokerageAccountVariables) => Promise<void>;
}

const FundAccountSection = ({
  upsertBrokerageAccount,
  order,
  ltbBrokerageAccount,
  t
}: FundAccountSectionProps) => {
  const [modalConfirmAccountFundedShown, setModalConfirmAccountFundedShown] = useState(false);

  const showModalConfirmAccountFunded = () => {
    setModalConfirmAccountFundedShown(true);
  };
  const hideModalConfirmAccountFunded = () => {
    setModalConfirmAccountFundedShown(false);
  };

  const brokerageAccountData = {
    companyId: order?.entityId ?? "",
    brokerBank: BrokerBankEnum.LTB,
    brokerName: BrokerNameEnum.LTB,
    accountNumber: order?.extra?.ibCredentials?.accountNumber ?? ""
  };

  if (order?.extra?.isNewBrokerEnabled) {
    brokerageAccountData.brokerBank = BrokerBankEnum.LTB2;
    brokerageAccountData.brokerName = BrokerNameEnum.LTB2;
  }

  return (
    <SectionWrapper title={t("generic:broker-order-details.fund-account")}>
      <FeatureFlag name={FeatureFlags.LTBMarkAccountFundedADB}>
        <ConfirmationModal
          dataTestId={"confirm-action-account-funded"}
          title={t("generic:confirm-action")}
          message={t("generic:confirm-action-message")}
          type={ConfirmationModalType.REGULAR}
          confirmLabel={t("generic:confirm")}
          visible={modalConfirmAccountFundedShown}
          onConfirm={async () => {
            if (order?.entityId) {
              await upsertBrokerageAccount(brokerageAccountData);
            }
          }}
          onClose={hideModalConfirmAccountFunded}
        />

        <RideButtonDeprecated
          data-testid="flag-account-as-funded-button"
          className={"fund-account__flag-account-as-funded"}
          onClick={showModalConfirmAccountFunded}
          disabled={!!ltbBrokerageAccount}>
          {t("generic:low-tax-broker-order.flag-account-as-funded")}
        </RideButtonDeprecated>
      </FeatureFlag>
    </SectionWrapper>
  );
};

export default withTranslationReady(["generic"])(FundAccountSection);
