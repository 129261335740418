import React from "react";
import { AuthMessage } from "../shared/AuthMessage/AuthMessage";
import { ButtonVariant } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { Steps } from "../TwoFactorAuthenticationSetupForm/TwoFactorAuthenticationSetupForm";

interface WarningFormProps extends WithTranslation {
  goToStep: (step: Steps) => void;
}

const WarningForm = ({ t, goToStep }: WarningFormProps) => {
  const onSubmit = async () => {
    goToStep(Steps.PHONE);
  };

  return (
    <>
      <AuthMessage
        onSubmit={onSubmit}
        message={t("auth:warning.title")}
        submitText={t("auth:warning.button")}
        image="/assets/sharedComponents/alert-icon.svg"
        buttonVariant={ButtonVariant.Primary}
        className="warningForm"
        data-testid="warningForm"
        infoText={<div className="successForm__info">{t("auth:warning.text")}</div>}
      />
    </>
  );
};

export default withTranslationReady(["auth"])(WarningForm);
