import { FiscalYearDataCompleteness } from "client/components/FiscalYearDataUploadConfirmation/FiscalYearDataUploadConfirmation";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideHeaderColumn } from "lib/types/types";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RideBadgeColorsEnum, RideBadgeStatus } from "uiLibrary/components/RideBadgeStatus";
import RideCardTable from "sharedComponents/RideCardTable/RideCardTable";
import RideTableCell, {
  RideTableCellAlignment,
  RideTableCellVariant
} from "sharedComponents/RideTableCell/RideTableCell";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";

export interface FiscalYearDataUploadStatusRecord
  extends Omit<FiscalYearDataCompleteness, "dueDate"> {}

interface FiscalYearDataUploadStatusProps extends WithTranslation {
  fiscalYears: FiscalYearDataUploadStatusRecord[];
}

const FiscalYearDataUploadStatus = ({ t, fiscalYears }: FiscalYearDataUploadStatusProps) => {
  const columns: RideHeaderColumn[] = [
    {
      name: "",
      label: t("tax-advisor:data-completeness-table.columns.fiscal-year")
    },
    {
      name: "",
      label: t("tax-advisor:data-completeness-table.columns.confirmed"),
      alignment: RideTableCellAlignment.RIGHT
    }
  ];

  return (
    <RideCardTable
      className="my-5"
      data-testid="fiscal-year-data-upload-status"
      title={t("tax-advisor:data-completeness-table.title")}>
      <RideTableHeader columns={columns} />
      {fiscalYears.map((row: { id: string; fiscalYear: string; status: string }) => (
        <RideTableRow colCount={columns.length} key={row.id}>
          <RideTableCell value={row.fiscalYear} />
          <RideTableCell
            variant={
              row.status === "NOT_POSSIBLE_YET"
                ? RideTableCellVariant.secondaryText
                : RideTableCellVariant.badge
            }
            badge={
              <RideBadgeStatus
                color={
                  row.status === "CONFIRMED"
                    ? RideBadgeColorsEnum.SUCCESS
                    : RideBadgeColorsEnum.WARNING
                }
                label={t(`tax-advisor:data-completeness-table.statusEnum.${row.status}`)}
              />
            }
            alignment={RideTableCellAlignment.RIGHT}
            value={t(`tax-advisor:data-completeness-table.statusEnum.${row.status}`)}
          />
        </RideTableRow>
      ))}
    </RideCardTable>
  );
};

export default withTranslationReady(["tax-advisor"])(FiscalYearDataUploadStatus);
