import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TaskStatesEnum } from "../../../../../global-query-types";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import OpenBankAccountTaskContainer from "../OpenBankAccountTaskContainer/OpenBankAccountTaskContainer";

interface OpenBankAccountFinomTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const OpenBankAccountFinomTask = ({ task, onChange, t }: OpenBankAccountFinomTaskProps) => {
  const onBoardingLink = t("company-founding-steps:tasks.openBankAccountFinom.openAccountLink");

  return (
    <OpenBankAccountTaskContainer onChange={onChange} task={task} bankName="Finom">
      <div>
        {t("company-founding-steps:tasks.openBankAccountFinom.firstStep")}
        <div className={"open-bank-account-finom__button-container"}>
          <RideButtonDeprecated
            openExternalUrl={task.state === TaskStatesEnum.TO_BE_DONE}
            href={onBoardingLink}
            data-testid="open-bank-account-finom-button"
            className={`open-bank-account-finom__button${
              task.state === TaskStatesEnum.COMPLETED ? " button--secondary--disabled" : ""
            }`}>
            {t("company-founding-steps:tasks.openBankAccountFinom.openAccount")}
          </RideButtonDeprecated>
        </div>
      </div>
      {t("company-founding-steps:tasks.openBankAccountFinom.secondStep")}
      {t("company-founding-steps:tasks.openBankAccountFinom.thirdStep")}
    </OpenBankAccountTaskContainer>
  );
};

export default withTranslation("company-founding-steps")(OpenBankAccountFinomTask);
