import React from "react";
import { OwnershipStructure } from "sharedComponents/icons/OwnershipStructure";

export interface RideCardOwnershipProps {
  ownership: string[];
}

export const RideCardOwnership = ({ ownership }: RideCardOwnershipProps) => {
  const lastItem = ownership.length - 1;
  return (
    <div className="ride-card-ownership" data-testid="card-label-ownership">
      <OwnershipStructure className="ride-card-ownership__label-structure-icon" />
      <div className="ride-card-ownership__label-ownership-hierarchy">
        {ownership.map((name, index) => (
          <span key={index} className={`ownership ownership-${index}`}>
            {name}
            {index !== lastItem && ` < `}
          </span>
        ))}
      </div>
    </div>
  );
};
