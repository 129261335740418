import React, { PropsWithChildren } from "react";
import { ModalLayout } from "../../../../sharedComponents/templates/ModalLayout/ModalLayout";

interface BlankPageProps extends PropsWithChildren<{}> {
  onBack?: () => void;
  onClose?: () => void;
}

export const InvitePage = ({ onBack, onClose, children }: BlankPageProps) => {
  return (
    <ModalLayout
      className="invite-page"
      onClose={onClose ?? (() => {})}
      onBack={onBack}
      children={children}
    />
  );
};
