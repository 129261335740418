import gql from "graphql-tag";

export const START_WEPA_SERVICE = gql`
  mutation StartWepaService(
    $companyInfo: OrdererCompanyData!
    $businessAddress: CompanyAddress!
    $taxAdvisorCode: String!
  ) {
    startWepaService(
      companyInfo: $companyInfo
      businessAddress: $businessAddress
      taxAdvisorCode: $taxAdvisorCode
    ) {
      wepaService {
        id
      }
      authentication {
        token
        feedback
      }
    }
  }
`;
