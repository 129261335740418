import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { GermanDate } from "../../../../common/serializers/GermanDate";
import { TFunction } from "i18next";
import { RideLogoMinimalist } from "../../../../sharedComponents/icons/RideLogoMinimalist";
import { AssetType, InvestmentState } from "../../../../global-query-types";
import { formatCurrency, formatPercentage } from "../../../../common/formatters";
import { IconVariant, RideTile } from "../../../../sharedComponents/RideTile/RideTile";
import { RideCardOwnership } from "../../../../sharedComponents/RideCardOwnership/RideCardOwnership";
import ChainOwnershipForState from "../../../../sharedComponents/ChainOwnershipForState/ChainOwnershipForState";

export interface InvestmentCardProps {
  id: string;
  name: string;
  state: InvestmentState;
  type: AssetType;
  share: number;
  nlvPreTax?: number;
  lastChange?: string;
  nlvPostTax?: number;
  image?: string;
  ownership?: string[];
  isClubDeal?: boolean;
}

type infoLine = { title: string; value: string; testId: string };

export interface InvestmentCardPropsWithTranslation extends InvestmentCardProps, WithTranslation {
  tasks?: string[];
  onSelect: (id: string) => void;
}

const InvestmentCard = (props: InvestmentCardPropsWithTranslation) => {
  const {
    id,
    image,
    lastChange,
    name,
    onSelect,
    ownership,
    share,
    state,
    nlvPreTax = 0,
    type,
    nlvPostTax,
    tasks = [],
    t
  } = props;

  const imageStyle: { [key: string]: string } = {};

  const isBrokerageAccountSetInvestment = type === AssetType.BrokerageAccountSet;

  if (image) {
    imageStyle.backgroundImage = `url(${image})`;
  }

  const imageSlot = (
    <div
      data-testid="card-image"
      style={imageStyle}
      className={`investments-card__image investments-card__image--${type}`}
    />
  );

  const subTitleSlot = isBrokerageAccountSetInvestment ? (
    <RideCardOwnership ownership={ownership ?? []} />
  ) : (
    <ChainOwnershipForState state={state} ownership={ownership ?? []} />
  );

  let partialValue = nlvPreTax * (share / 100);
  const investmentInfo: infoLine[] = [];
  if (isBrokerageAccountSetInvestment) {
    partialValue = nlvPreTax;
    investmentInfo.push({
      title: t("client:investments.card.afterTaxes"),
      value: formatCurrency(nlvPostTax) ?? "",
      testId: "card-value-after-taxes"
    });
  } else {
    investmentInfo.push({
      title: t("client:investments.card.nlvPreTax"),
      value: formatCurrency(nlvPreTax) ?? "",
      testId: "card-total-value"
    });
    investmentInfo.push({
      title: t("client:investments.card.share"),
      value: `${formatPercentage(share / 100, {
        precision: getSharePrecision(share, 2)
      })}`,
      testId: "card-share"
    });
  }

  const detailsSlot =
    state !== InvestmentState.RideClubInProcess ? (
      <CardDetails
        title={formatCurrency(partialValue) ?? ""}
        subtitle={
          !!lastChange
            ? `${t("client:investments.card.lastChange")} ${getFormattedLastChange(lastChange)}`
            : undefined
        }
        infoLines={investmentInfo}
      />
    ) : undefined;

  const footerSlot =
    state === InvestmentState.RideClubInProcess ? (
      <RideClubProgress t={t} tasks={tasks} />
    ) : state === InvestmentState.RideClubCompleted ? (
      <RideClub t={t} />
    ) : undefined;

  return (
    <RideTile
      data-testid="investments-card"
      name={name}
      subTitleSlot={subTitleSlot}
      imageSlot={imageSlot}
      detailsSlot={detailsSlot}
      footerSlot={footerSlot}
      iconVariant={IconVariant.arrowIcon}
      onClick={() => onSelect(id)}
    />
  );
};

const CardDetailsInfoLine = ({
  title,
  value,
  testId
}: {
  title: string;
  value: string;
  testId: string;
}) => {
  return (
    <div data-testid={testId} className="card-details-info-line">
      <div className="card-details-info-line__title">{title}&nbsp;</div>
      {value}
    </div>
  );
};

const CardDetails = ({
  title,
  subtitle,
  infoLines
}: {
  title: string;
  subtitle: string | undefined;
  infoLines: infoLine[];
}) => {
  const infos = infoLines.map((item) => {
    return (
      <CardDetailsInfoLine
        key={item.testId}
        title={item.title}
        value={item.value}
        testId={item.testId ?? ""}
      />
    );
  });

  return (
    <div className="card-details">
      <div className="card-details__header">
        <div data-testid="card-value" className="card-details__title">
          {title}
        </div>
        {subtitle && (
          <div data-testid="card-last-change" className="card-details__subtitle">
            {subtitle}
          </div>
        )}
      </div>
      {infos}
    </div>
  );
};

const RideClubProgress = ({ t, tasks }: { t: TFunction; tasks: string[] }) => {
  return (
    <div className="investments-card__ride-club" data-testid="ride-club">
      <div data-testid="card-ride-club" className="investments-card__ride-club-header">
        <RideLogoMinimalist />
        {t("generic:clubDealLogoText")}
      </div>
      {tasks.length > 0 && (
        <div data-testid="card-tasks-list" className="investments-card__ride-club-header">
          {t("client:investments.card.tasksTitle")}
          <ul className="investments-card__ride-club-list">
            {tasks.map((task) => (
              <li key={task} data-testid="card-task">
                {task}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const RideClub = ({ t }: { t: TFunction }) => {
  return (
    <div
      data-testid="card-ride-club"
      className="investments-card__ride-club-header investments-card__ride-club-header--completed">
      <RideLogoMinimalist />
      {t("generic:clubDealLogoText")}
    </div>
  );
};

const getSharePrecision = (share: number | undefined, minimumNumberOfPrecision: number): number => {
  let sharePrecision = 0;
  if (share && share.toString().includes(".")) {
    const decimalPlaces = share.toString().split(".")[1];

    sharePrecision = 1;
    for (let index = 0; index < decimalPlaces.length; ++index) {
      if (decimalPlaces.charAt(index) === "0") {
        ++sharePrecision;
      } else {
        break;
      }
    }
  }
  return sharePrecision > minimumNumberOfPrecision ? sharePrecision : minimumNumberOfPrecision;
};

const getFormattedLastChange = (lastChange) => {
  const dateFormatter = new GermanDate(undefined, "");
  return dateFormatter.serialize(lastChange);
};

export default withTranslationReady(["client", "generic"])(InvestmentCard);
