import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { LegalFormField } from "../../../../../generic/company-form/fields";
import { LegalForm } from "../../../../../../global-query-types";
import { CompanyNameField } from "../../../../../public/CompanyCreation/fields";

export interface IProps {
  t: TFunction;
  formik;
  legalForms: Array<LegalForm>;
}

export const CompanyTitleInput = ({ t, formik, legalForms }: IProps) => {
  const translatedLegalForms = legalForms.map((legalForm) => {
    return {
      title: t(`generic:legalForm.${legalForm}`),
      value: legalForm
    };
  });

  return (
    <Row>
      <Col xs={6} md={4}>
        <CompanyNameField
          t={t}
          formik={formik}
          name="name"
          label={t("generic:company-name")}
          required={true}
          ignoreErrors={true}
        />
      </Col>
      <Col xs={6} md={4}>
        <LegalFormField t={t} formik={formik} legalForms={translatedLegalForms} />
      </Col>
    </Row>
  );
};
