import gql from "graphql-tag";

export const UPDATE_COMPANY_BY_ID = gql`
  mutation UpdateCompanyById(
    $companyId: String!
    $name: String
    $projectName: String
    $legalForm: LegalForm
    $capitalContribution: Float
    $street: String
    $city: String
    $postalCode: String
    $type: CompanyType
    $tradeTaxFree: Boolean
    $registerCourt: String
    $registration: String
    $companyValue: Float
    $taxNumber: String
    $vatNumber: String
    $notarizationDate: String
    $commercialRegisterEntryDate: String
    $lei: String
    $businessPurpose: String
    $businessPurposeShort: String
    $fiscalYear: String
    $thirdPartyTaxAdvisorClientNumber: String
    $clientNumber: String
    $status: PublishType
    $tokenized: Boolean
    $tokenizedShares: Int
    $boxLink: String
    $advisorNumber: String
    $accountingFramework: AccountingFrameworkEnum
    $migrationRequiredFor3PTARealisedAt: DateTime
  ) {
    updateCompanyById(
      companyId: $companyId
      name: $name
      projectName: $projectName
      legalForm: $legalForm
      capitalContribution: $capitalContribution
      street: $street
      city: $city
      postalCode: $postalCode
      type: $type
      tradeTaxFree: $tradeTaxFree
      registration: $registration
      registerCourt: $registerCourt
      companyValue: $companyValue
      taxNumber: $taxNumber
      vatNumber: $vatNumber
      notarizationDate: $notarizationDate
      commercialRegisterEntryDate: $commercialRegisterEntryDate
      lei: $lei
      businessPurpose: $businessPurpose
      businessPurposeShort: $businessPurposeShort
      fiscalYear: $fiscalYear
      thirdPartyTaxAdvisorClientNumber: $thirdPartyTaxAdvisorClientNumber
      clientNumber: $clientNumber
      status: $status
      tokenized: $tokenized
      tokenizedShares: $tokenizedShares
      boxLink: $boxLink
      advisorNumber: $advisorNumber
      accountingFramework: $accountingFramework
      migrationRequiredFor3PTARealisedAt: $migrationRequiredFor3PTARealisedAt
    ) {
      feedback
    }
  }
`;
