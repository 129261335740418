import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { useHistory } from "react-router-dom";
import { PreliminaryTaskReturn } from "common/checkForPreliminaryTask";

export interface PreliminaryTaskProps extends WithTranslation {
  companyId: string;
  task: PreliminaryTaskReturn;
}

const PreliminaryTask = ({ t, companyId, task }: PreliminaryTaskProps) => {
  const history = useHistory();
  const isExistingCompany = !!task.addExistingCompanyOrder;

  const flow = isExistingCompany ? "existing-company" : "grundung-service";

  return (
    <div className={`ltb-overview-card `} data-testid={"preliminary-task-card"}>
      <div className="ltb-overview-card__header">
        <span className={`ltb-overview-card__title `}>
          <span data-testid={`header`}>{t(`generic:preliminary-task.${flow}.header`)}</span>
        </span>
      </div>
      <div className="ltb-overview-card__content">
        <div className={"preliminary-task-content"}>
          <Typography category={"Headline"} size={300} weight={"Heavy"}>
            <div data-testid={"title"}>{t(`generic:preliminary-task.${flow}.title`)}</div>
          </Typography>
          <Typography category={"Paragraph"} size={200} weight={"Light"}>
            <div data-testid={"description"}>
              {t(`generic:preliminary-task.${flow}.description`)}
            </div>
          </Typography>
        </div>
      </div>
      <RideButton
        data-testid={"cta"}
        className={"preliminary-task-content__cta"}
        label={t(`generic:preliminary-task.${flow}.button-label`)}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        type={"button"}
        onClick={() =>
          history.push(
            isExistingCompany
              ? `/client/order/generic/company-information/0/${task.addExistingCompanyOrder?.id}/${task.addExistingCompanyOrder?.submissionId}`
              : `/client/entities/${companyId}/general-info`
          )
        }
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(PreliminaryTask);
