import React, { useState } from "react";
import { FileUploadService } from "../../lib/services/FileUploadService/FileUploadService";
import { StorageNodesEntityType, StorageNodeType } from "../../global-query-types";
import { DragDropHandler } from "../DragDropHandler/DragDropHandler";
import logger from "../../common/Logger";
import { DragDropProvider } from "../../lib/providers/DragDropProvider";
import {
  LocalStorageNode,
  RemoteStorageNode,
  toRemoteStorageNode
} from "../../lib/dataAdapters/toRemoteStorageNode";
import OpenFileSelectionHandler from "sharedComponents/OpenFileSelectionHandler/OpenFileSelectionHandler";
import DragDropSplashScreenPopUp from "sharedComponents/DragDropSplashScreenPopUp/DragDropPopUpSplashScreen";

export enum UploadNodeState {
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  FAILED = "FAILED"
}

export interface UploadWrapperChildrenProps {
  fileNodes: RemoteStorageNode[];
  onReset: () => void;
  openFileSelectPopUp: () => void;
  dragging?: boolean;
}

interface UploadWrapperProps {
  children: (props: UploadWrapperChildrenProps) => React.ReactElement;
  entityId: string;
  destinationNodeId: string;
  uploadService: FileUploadService;
  entityType: StorageNodesEntityType;
  destinationNodeName?: string | null;
  onUploaded?: (storageNodes: RemoteStorageNode[]) => Promise<void>;
  multiple?: boolean;
  noDragAndDrop?: boolean;
}

const UploadWrapper = ({
  uploadService,
  destinationNodeId,
  entityType,
  entityId,
  onUploaded,
  children,
  multiple = false,
  noDragAndDrop = false,
  destinationNodeName
}: UploadWrapperProps) => {
  const [fileNodes, setFileNodes] = useState<RemoteStorageNode[]>([]);

  const getShallowNodeForFileAndState = (
    file: File,
    uploadingState: UploadNodeState
  ): RemoteStorageNode => ({
    nodeId: "",
    name: file.name,
    type: StorageNodeType.FILE,
    childNodes: [],
    parentId: destinationNodeId,
    uploadingState
  });

  const upload = async (file: File): Promise<RemoteStorageNode | undefined> => {
    await uploadService.addToQueue(destinationNodeId, entityType, entityId, file);
    const node: LocalStorageNode | null = await uploadService.waitOne();

    if (node) {
      return {
        ...toRemoteStorageNode(node, destinationNodeId),
        uploadingState: UploadNodeState.UPLOADED
      };
    }
  };

  const updateUploadedFileNode = (file: File, node: RemoteStorageNode) => (prevNodes) => {
    return prevNodes.map((prevNode) => {
      return prevNode.name === file.name ? node : prevNode;
    });
  };

  const getUploadedAndFailedNodes = (files: File[], uploadedNodes: RemoteStorageNode[]) =>
    files.map((file, index) => {
      const uploadedNode = uploadedNodes[index];
      return uploadedNode ?? getShallowNodeForFileAndState(file, UploadNodeState.FAILED);
    });

  const getUploadingFileNodes = (files: File[]): RemoteStorageNode[] =>
    files.map((file) => getShallowNodeForFileAndState(file, UploadNodeState.UPLOADING));

  const onSelect = async (files: File[]) => {
    setFileNodes(getUploadingFileNodes(files));

    let successfullyUploadedNoted: RemoteStorageNode[] = [];
    try {
      for (const file of files) {
        const node = await upload(file);
        if (node) {
          successfullyUploadedNoted.push(node);
          setFileNodes(updateUploadedFileNode(file, node));
        }
      }
    } catch (e) {
      setFileNodes(getUploadedAndFailedNodes(files, successfullyUploadedNoted));
      logger.error(e);
    } finally {
      await onUploaded?.(successfullyUploadedNoted);
    }
  };

  const onReset = () => {
    setFileNodes([]);
  };

  const selectionHandlerWithChildrenProps = (
    childrenProps: Omit<UploadWrapperChildrenProps, "openFileSelectPopUp">
  ) => (
    <OpenFileSelectionHandler
      canUploadMultipleFiles={multiple}
      onSelect={onSelect}
      testid="local-file-select">
      {({ openFileSelectPopUp }) =>
        children({
          ...childrenProps,
          openFileSelectPopUp
        })
      }
    </OpenFileSelectionHandler>
  );

  return noDragAndDrop ? (
    selectionHandlerWithChildrenProps({ fileNodes, onReset })
  ) : (
    <DragDropProvider>
      <DragDropHandler onDrop={onSelect}>
        {({ dragging }) => (
          <>
            {dragging && <DragDropSplashScreenPopUp folderName={destinationNodeName} />}
            {selectionHandlerWithChildrenProps({ fileNodes, onReset, dragging })}
          </>
        )}
      </DragDropHandler>
    </DragDropProvider>
  );
};

export default UploadWrapper;
