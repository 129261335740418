import RideCardTable from "../RideCardTable/RideCardTable";
import { RideTableHeader } from "../RideTableHeader/RideTableHeader";
import { RideTableRow } from "../RideTableRow/RideTableRow";
import RideTableCell, { RideTableCellVariant } from "../RideTableCell/RideTableCell";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RoleEntry } from "../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { TFunction } from "i18next";
import { RideBadgeColorsEnum, RideBadgeStatus } from "uiLibrary/components/RideBadgeStatus";
import { formatBool, formatPercentage } from "../../common/formatters";
import { companyName } from "../../lib/formatters/companyFormatter";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { FeatureFlag } from "../FeatureFlags/FeatureFlag/FeatureFlag";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { FeatureFlags } from "global-query-types";

interface CommonRoleCardProps extends WithTranslation {
  roles: RoleEntry[];
  dataTestId?: string;
  hideVotingRights?: boolean;
}

type RoleCellProps = { role: RoleEntry; t: TFunction };

const RoleBadge = ({ role, t }: RoleCellProps) => {
  let label = "--";
  if (role.share && role.share > 0) label = t("client:company.roles.shareholder");
  if (role.isManagingDirector) label = t("client:company.roles.director");
  return <RideBadgeStatus color={RideBadgeColorsEnum.SUCCESS} label={label} />;
};

const ShareBadge = ({ role }: RoleCellProps) => {
  return (
    <RideBadgeStatus
      color={RideBadgeColorsEnum.DARK}
      label={formatPercentage((role.share ?? 0) / 100)}
    />
  );
};

const VoteBadge = ({ role }: RoleCellProps) => {
  return (
    <RideBadgeStatus
      color={RideBadgeColorsEnum.DARK}
      label={formatPercentage((role.votes ?? 0) / 100)}
    />
  );
};

const SilentPartnerBadge = ({ role }: RoleCellProps) => {
  return (
    <RideBadgeStatus color={RideBadgeColorsEnum.DARK} label={formatBool(role.isSilentPartner)} />
  );
};

const EntityCell = ({ role }: RoleCellProps) =>
  role.company ? (
    <RideTableCell
      variant={RideTableCellVariant.primaryLink}
      classes="mobile-full"
      link={`/tax-advisor/client-entities/${role.company?.id}`}
      value={companyName(role.company)}
    />
  ) : (
    <RideTableCell
      variant={RideTableCellVariant.primaryLink}
      classes="mobile-full"
      link={`/tax-advisor/persons/${role.person?.id}`}
      value={personNameWithFallback(role.person)}
    />
  );

const RowContentsByRole = ({ role, t, showSilentPartnerAndVotingRights, hideVotingRights }) => {
  return (
    <>
      <EntityCell role={role} t={t} />
      <RideTableCell
        variant={RideTableCellVariant.badge}
        badge={<RoleBadge role={role} t={t} />}
        classes="mobile-left"
      />
      <RideTableCell
        classes="mobile-right"
        variant={RideTableCellVariant.badge}
        badge={<ShareBadge role={role} t={t} />}
      />
      {showSilentPartnerAndVotingRights && (
        <>
          <RideTableCell
            classes="mobile-right"
            variant={RideTableCellVariant.badge}
            badge={<SilentPartnerBadge role={role} t={t} />}
          />
          {!hideVotingRights && (
            <RideTableCell
              classes="mobile-right"
              variant={RideTableCellVariant.badge}
              badge={<VoteBadge role={role} t={t} />}
            />
          )}
        </>
      )}
    </>
  );
};

const CommonRoleCard = ({
  t,
  roles,
  hideVotingRights,
  dataTestId = "gmbh-role-details"
}: CommonRoleCardProps) => {
  const columns = [
    { name: "entity", label: t("client:company.roles.entity") },
    { name: "role", label: t("client:company.roles.role") },
    { name: "shares", label: t("client:company.roles.shares") }
  ];

  const columnsWithSilentPartnersAndVotingRights = [
    ...columns,
    { name: "silent-partner", label: t("client:company.roles.silentPartner") }
  ];

  if (!hideVotingRights) {
    columnsWithSilentPartnersAndVotingRights.push({
      name: "votes",
      label: t("client:company.roles.votes")
    });
  }

  return (
    <RideCardTable
      title={t("client:company:roles.title")}
      data-testid={dataTestId}
      className="shadow-none my-5">
      <FeatureFlag name={FeatureFlags.showPartnerTypeAndVotesForTAAnd3PTA}>
        <RideTableHeader columns={columnsWithSilentPartnersAndVotingRights} />
        {roles.map((role, index) => (
          <RideTableRow
            key={`${role.id}-${index}`}
            colCount={columnsWithSilentPartnersAndVotingRights.length}>
            <RowContentsByRole
              role={role}
              t={t}
              showSilentPartnerAndVotingRights={true}
              hideVotingRights={hideVotingRights}
            />
          </RideTableRow>
        ))}
      </FeatureFlag>

      <DisabledFeatureFlag name={FeatureFlags.showPartnerTypeAndVotesForTAAnd3PTA}>
        <RideTableHeader columns={columns} />
        {roles.map((role, index) => (
          <RideTableRow key={`${role.id}-${index}`} colCount={columns.length}>
            <RowContentsByRole
              role={role}
              t={t}
              showSilentPartnerAndVotingRights={false}
              hideVotingRights={hideVotingRights}
            />
          </RideTableRow>
        ))}
      </DisabledFeatureFlag>
    </RideCardTable>
  );
};

export default withTranslationReady(["client", "company-founding-steps", "generic"])(
  CommonRoleCard
);
