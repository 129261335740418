import React from "react";
import { companyRolesFromMemberships } from "lib/dataAdapters/membershipCategorization/membershipCategorization";
import CommonRoleCard from "sharedComponents/CommonRoleCard/CommonRoleCard";
import { GmbHDetails } from "lib/models/client/GmbHDetails";

export interface CompanyRoleCardProps {
  gmbh: GmbHDetails;
}

const CompanyRoleCard = ({ gmbh }: CompanyRoleCardProps) => {
  return <CommonRoleCard roles={companyRolesFromMemberships(gmbh.members)} />;
};

export default CompanyRoleCard;
