import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import OnboardingStepCard, {
  EditableOnboardingStepCardProps,
  OnboardingStepCardStatus
} from "../../OnboardingStepCard/OnboardingStepCard";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { PaymentMethod } from "lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { ChoosePaymentMethod } from "sharedComponents/ChoosePaymentMethod/ChoosePaymentMethod";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

export interface SetupPaymentCardProps extends EditableOnboardingStepCardProps {
  stepNumber: number;
  status: OnboardingStepCardStatus;
  iban: string | undefined | null;
  companyName: string | undefined | null;
  price: number | undefined | null;
  setupPayment: (option: PaymentMethod) => void;
  initialPaymentMethod?: PaymentMethod;
  footnote?: string;
  pricingInformation?: string | undefined;
  showYearlyPrice?: boolean;
}

const SetupPaymentCard = ({
  t,
  status,
  companyName,
  stepNumber,
  iban,
  price,
  setupPayment,
  forceOpening,
  pricingInformation = undefined,
  toggleEditing = null,
  initialPaymentMethod,
  footnote,
  showYearlyPrice
}: SetupPaymentCardProps & WithTranslation) => {
  const formik = useRideFormik({
    initialValues: {
      paymentMethod: initialPaymentMethod ?? PaymentMethod.Sepa
    },
    onSubmit: (values) => {
      if (values.paymentMethod) {
        setupPayment(values.paymentMethod);
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <OnboardingStepCard
          data-testid={"setup-payment-card"}
          number={stepNumber}
          status={status}
          title={t("generic:payment-method-card.title")}
          forceShowContent={forceOpening}
          toggleEditing={toggleEditing ? () => toggleEditing?.("setupPaymentMethod") : null}>
          <ChoosePaymentMethod
            formik={formik}
            price={price}
            pricingInformation={pricingInformation}
            iban={iban}
            companyName={companyName}
            showYearlyPrice={showYearlyPrice}
          />
          <RideButton
            type={"submit"}
            data-testid={"setup-payment-button"}
            label={t("generic:payment-method-card.button")}
            variant={RideButtonVariant.PRIMARY}
            size={RideButtonSize.BIG}
          />
          {footnote && (
            <Typography
              data-testid={"payment-date-info"}
              category={"Paragraph"}
              size={200}
              weight={"Light"}
              className={"setup-payment-card__payment-date-info"}>
              {footnote}
            </Typography>
          )}
        </OnboardingStepCard>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SetupPaymentCard);
