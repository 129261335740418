import React from "react";
import RideProgressBar from "../../../uiLibrary/components/RideProgressBar/RideProgressBar";

export interface ChaptersProgressBarProps {
  title: string;
  currentStep: number;
  totalSteps: number;
}

const ChaptersProgressBar = ({ title, currentStep, totalSteps }: ChaptersProgressBarProps) => {
  const percentageValue = Math.round((currentStep / totalSteps) * 100);
  return (
    <div data-testid="chapters-progress-bar" className="chapters-progress-bar">
      <RideProgressBar percentage={percentageValue} />
      <div className="chapters-progress-bar__info">
        <div data-testid="progress-chapter-title">{title}</div>
        &nbsp;
        <div data-testid="chapter-steps">
          {currentStep}/{totalSteps}
        </div>
      </div>
    </div>
  );
};

export default ChaptersProgressBar;
