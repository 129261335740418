import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import NavigationService from "lib/services/NavigationService/NavigationService";
import { LTBDashboardActionCard } from "client/components/LowTaxBrokerDashboard/sharedComponents/LTBDashboardActionCard/LTBDashboardActionCard";
import React from "react";

const Help = ({ t }: WithTranslation) => {
  return (
    <div data-testid={"help"}>
      <LTBDashboardActionCard
        dataTestId={"help-card"}
        title={t("generic:low-tax-broker-dashboard.help.title")}
        description={t("generic:low-tax-broker-dashboard.help.description")}
        buttonLabel={t("generic:low-tax-broker-dashboard.help.button")}
        buttonClickHandler={() => {
          NavigationService.instance?.openLink(
            "https://www.ride.capital/faq/ride-broker?utm_source=app&utm_medium=app"
          );
        }}
        disabled={false}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(Help);
