import React, { PropsWithChildren } from "react";

export const Highlight = ({
  "data-testid": dataTestId,
  children
}: PropsWithChildren<{ "data-testid"?: string }>) => {
  return (
    <mark data-testid={dataTestId} className="ride-highlight">
      {children}
    </mark>
  );
};
