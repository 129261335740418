import React from "react";
import { GetAllShelfCompanyOrders } from "../../../lib/api/queries/GetAllShelfCompanyOrders";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import ShelfCompanyOrder from "./ShelfCompanyOrder";

const ShelfCompanyOrderContainer = () => {
  const request = GetAllShelfCompanyOrders();

  return (
    <RequestFeedbackHandler requests={[request]}>
      <ShelfCompanyOrder orders={request.data?.getAllShelfCompanyOrders} />
    </RequestFeedbackHandler>
  );
};

export default ShelfCompanyOrderContainer;
