import React from "react";
import { LowTaxBrokerShareholder } from "lib/models/client/LowTaxBroker/Shareholder";
import { personName, personNameWithFallback } from "lib/formatters/personFormatter";
import { ReviewGeneralInformation } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewGeneralInformation/ReviewGeneralInformation";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { ReviewCitizenshipInformation } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewCitizenshipInformation/ReviewCitizenshipInformation";
import { ReviewAddress } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewAddress/ReviewAddress";
import { ReviewStatusInformation } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewStatusInformation/ReviewStatusInformation";
import { FeatureFlagService } from "../../../../../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../../../../../../global-query-types";
import ReviewEmployerInformationSection from "../ReviewEmployerInformationSection/ReviewEmployerInformationSection";
import { EmploymentOptionEnum } from "../../../../../../../../lib/models/client/LowTaxBroker/EmploymentOption";

interface ReviewShareholderProps {
  isNewBroker: boolean;
  shareholder: LowTaxBrokerShareholder;
}

export const ReviewShareholder = ({ isNewBroker, shareholder }: ReviewShareholderProps) => {
  const addMiddleNameFeatureToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddMiddleNameToLTBForm
  );
  const formatAddressForRideBroker =
    isNewBroker && FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerWithoutHouseNumber);

  const fullName = personName(shareholder.personData, addMiddleNameFeatureToggle);

  const needsEmploymentDetails =
    isNewBroker &&
    (shareholder.employmentDetails?.type === EmploymentOptionEnum.EMPLOYED ||
      shareholder.employmentDetails?.type === EmploymentOptionEnum.SELFEMPLOYED);

  return (
    <div className="review-shareholder">
      <ReviewSectionTitle
        dataTestId="review-shareholder-title"
        title={
          fullName ?? personNameWithFallback(shareholder.personData, addMiddleNameFeatureToggle)
        }
      />
      <hr />
      <ReviewGeneralInformation
        name={fullName}
        email={shareholder.personData?.email}
        phoneNumber={shareholder.personData?.phoneNumber}
      />
      <hr />
      <ReviewCitizenshipInformation
        gender={shareholder.personCitizenship?.gender}
        citizenship={shareholder.personCitizenship?.citizenship}
        secondCitizenship={shareholder.personCitizenship?.secondCitizenship}
        dateOfBirth={shareholder.personCitizenship?.birthDate}
        cityOfBirth={shareholder.personCitizenship?.birthCity}
        countryOfBirth={shareholder.personCitizenship?.birthCountry}
        usCitizenOrTaxResident={
          shareholder.personCitizenship?.notUSCitizenOrTaxResident === undefined
            ? undefined
            : !shareholder.personCitizenship.notUSCitizenOrTaxResident
        }
      />
      <hr />
      <ReviewAddress
        address={shareholder.personAddress}
        isRideBroker={formatAddressForRideBroker}
      />
      <hr />
      <ReviewStatusInformation
        isNewBroker={isNewBroker}
        taxJurisdiction={shareholder.statusInformation?.taxJurisdiction}
        taxId={shareholder.statusInformation?.taxId}
        politicallyExposed={shareholder.statusInformation?.politicallyExposed}
        politicallyExposedContext={shareholder.statusInformation?.politicallyExposedContext}
        employmentStatus={shareholder.employmentDetails?.type}
      />
      {needsEmploymentDetails && (
        <>
          <hr />
          <ReviewEmployerInformationSection
            employerInformation={shareholder.employmentDetails ?? {}}
            countryMismatch={
              !!(
                shareholder.personAddress?.country &&
                shareholder.employmentDetails?.employerAddress?.country &&
                shareholder.personAddress?.country !==
                  shareholder.employmentDetails?.employerAddress?.country
              )
            }
          />
        </>
      )}
    </div>
  );
};
