import gql from "graphql-tag";

export const GET_PAYMENT_METHOD_FOR_COMPANY = gql`
  query GetPaymentMethodForCompany(
    $companyId: String!
    $paymentPlatformAccount: PaymentPlatformAccount!
  ) {
    getPaymentMethodForCompany(
      companyId: $companyId
      paymentPlatformAccount: $paymentPlatformAccount
    )
  }
`;
