import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getOrderDetails, getOrderDetailsVariables } from "./graphql/getOrderDetails";

export const GET_ORDER_DETAILS = gql`
  query getOrderDetails($orderId: ID!) {
    getOrderDetails(orderId: $orderId) {
      order {
        id
        productType
        entityId
        extra
        status
        createdAt
        updatedAt
      }
      reservedCompany {
        id
        fullName
      }
    }
  }
`;

export const GetOrderDetails = (variables: getOrderDetailsVariables) =>
  useQuery<getOrderDetails, getOrderDetailsVariables>(GET_ORDER_DETAILS, {
    variables,
    fetchPolicy: "no-cache"
  });
