import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { RideCardBodySeparator } from "../../../../sharedComponents/RideCardBodySeparator/RideCardBodySeparator";
import { YearItem } from "../../../../sharedComponents/YearItem/YearItem";
import { formatCurrency } from "../../../../common/formatters";

export interface AnnualProfitProps extends WithTranslation {
  yearlyData: { [key: number]: { before: number; after: number } };
  total: { before?: number; after?: number; yearsLabel?: string };
  className?: String;
}

const AnnualProfit = ({ yearlyData, total, className, t }: AnnualProfitProps) => {
  let items = Object.keys(yearlyData)
    .sort()
    .map((year) => ({
      year,
      before: yearlyData[year].before,
      after: yearlyData[year].after
    }));

  return (
    <RideSectionCard
      title={t("client:investments.annual-profit.title")}
      className={`annual-profit ${className ?? ""}`}
      data-testid="annual-profit">
      <div className="annual-profit__title">{t("client:investments.annual-profit.year-list")}</div>
      {items.map((item, key) => (
        <YearItem
          year={item.year}
          before={formatCurrency(item.before) ?? "---"}
          after={formatCurrency(item.after) ?? "---"}
          key={key}
          unit=""
        />
      ))}

      {items.length === 0 && <div data-testid="annual-profit-no-year">---</div>}

      <RideCardBodySeparator />

      <div className="annual-profit__title">{t("client:investments.annual-profit.total")}</div>
      {total?.yearsLabel && (
        <div data-testid="annual-profit-total-years" className="annual-profit__year">
          {total?.yearsLabel}
        </div>
      )}
      <div data-testid="annual-profit-total-value" className="annual-profit__value">
        {total?.before !== undefined ? <strong>{formatCurrency(total?.before)}</strong> : "---"} (
        {total?.after !== undefined ? formatCurrency(total?.after) : "---"})
      </div>
    </RideSectionCard>
  );
};

export default withTranslationReady(["client"])(AnnualProfit);
