import React, { useState } from "react";
import { Button, Col, Container, Figure, Modal, Row, Spinner } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { WithTranslation, withTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_GALLERY_PHOTO_BY_KEY } from "./mutations";
import logger from "../../../common/Logger";
import {
  RenderImageUploadButton,
  RenderImageUploadFunctionality
} from "./ImageUploadFunctionality";

interface PhotoUploadModalProps extends WithTranslation {
  imageData: string[];
  realEstateId: string;
  refetchGallery: () => void;
  overlay: boolean;
}

const MB = Number(1024 * 1024);

export const PhotoUploadModal: React.FC<PhotoUploadModalProps> = (props) => {
  const { realEstateId, refetchGallery, overlay, t, imageData } = props;

  const [show, setShow] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleOnDrop = async (acceptedFiles) => {
    setFiles(
      files.concat(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isSizeExceed: file.size > 2 * MB
          })
        )
      )
    );
  };

  const cancelHandler = (event: any) => {
    setFiles([]);
    handleClose();
  };

  const removeFile = (index: number) => {
    let selectedFiles = files.filter((item, _index) => {
      return _index !== index;
    });
    setFiles(selectedFiles);
  };

  //Remove Photos from S3
  const removeFromS3 = (index: number) => {
    refetchGallery();
  };

  const previewS3 = imageData.map((url: string, idx) => (
    <RenderUploadedImage
      key={idx}
      idx={idx}
      realEstateId={realEstateId}
      url={url}
      removeFromList={removeFromS3}
    />
  ));

  const previewThumbs = files.map((file: any, idx) => (
    <Figure key={idx}>
      <Figure.Image
        width={100}
        height={100}
        alt="100x100"
        thumbnail
        src={file.preview}
        data-testid="preview-thumb"
      />
      <Figure.Caption>
        <FaTimes
          color="red"
          data-testid="delete-thumb"
          onClick={() => removeFile(idx)}
          className="clickable"
        />
        {file.isSizeExceed ? (
          <small data-testid="size-exceeded" style={{ color: "red" }}>
            {t("generic:size-exceeded")}
          </small>
        ) : (
          ""
        )}
      </Figure.Caption>
    </Figure>
  ));

  const isFileSizeExceed = files.map((file: any) => file.isSizeExceed);
  const anyFileSizeExceeded = isFileSizeExceed.includes(true);

  const currentCount = Number(previewS3.length) + Number(previewThumbs.length);
  const availableCount = 25 - currentCount;
  const tooManyPhotos = availableCount < 0;
  const canUploadMore = availableCount > 0;

  const submitHandler = () => {
    //Success from S3 response
    setFiles([]);
    setUploading(false);
    refetchGallery();
    handleClose();
  };

  if (!overlay) {
    return (
      <>
        <Row>
          <Col>{t("generic:upload-photos")}</Col>
        </Row>
        <RenderImageUploadFunctionality
          t={t}
          uploading={uploading}
          previewS3={previewS3}
          previewThumbs={previewThumbs}
          tooManyPhotos={tooManyPhotos}
          availableCount={availableCount}
          canUploadMore={canUploadMore}
          handleOnDrop={handleOnDrop}
        />

        <RenderImageUploadButton
          t={t}
          files={files}
          submitHandler={submitHandler}
          realEstateId={realEstateId}
          setUploading={setUploading}
          tooManyPhotos={tooManyPhotos}
          anyFileSizeExceeded={anyFileSizeExceeded}
          cancelHandler={undefined}
          overlay={overlay}
        />
      </>
    );
  }

  return (
    <>
      <Button
        size={"lg"}
        variant="outline-primary"
        onClick={handleShow}
        data-testid="upload-button">
        {t("realEstate:upload-photos")}
      </Button>

      <Modal size={"lg"} show={show} onHide={handleClose} data-testid="upload-form">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">{t("generic:upload-photos")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderImageUploadFunctionality
            t={t}
            uploading={uploading}
            previewS3={previewS3}
            previewThumbs={previewThumbs}
            tooManyPhotos={tooManyPhotos}
            availableCount={availableCount}
            canUploadMore={canUploadMore}
            handleOnDrop={handleOnDrop}
          />
        </Modal.Body>

        <Modal.Footer>
          <Container>
            <RenderImageUploadButton
              t={t}
              files={files}
              submitHandler={submitHandler}
              realEstateId={realEstateId}
              setUploading={setUploading}
              tooManyPhotos={tooManyPhotos}
              anyFileSizeExceeded={anyFileSizeExceeded}
              cancelHandler={cancelHandler}
              overlay={overlay}
            />
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const RenderUploadedImage = ({ idx, realEstateId, url, removeFromList }) => {
  const [deleteGalleryPhoto] = useMutation(DELETE_GALLERY_PHOTO_BY_KEY);
  const [deleting, setDeleting] = useState(false);

  const fileName = getFileNameFromUrl(url);

  const onDelete = async (idx) => {
    setDeleting(true);
    try {
      await deleteGalleryPhoto({ variables: { realEstateId, fileName } });
      removeFromList(idx);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Figure>
      <Figure.Image
        width={100}
        height={100}
        alt="100x100"
        thumbnail
        src={url}
        data-testid="uploaded-image"
      />
      <Figure.Caption>
        <FaTimes
          color="red"
          onClick={() => onDelete(idx)}
          className="clickable"
          data-testid="delete-upload"
        />
        {deleting && <Spinner data-testid="deleting-spinner" animation="grow" />}
      </Figure.Caption>
    </Figure>
  );
};

const getFileNameFromUrl = (url) => {
  let fullKey;
  try {
    fullKey = new URL(url).pathname;
  } catch (error) {
    logger.error(error);
    fullKey = url || "";
  }
  const keyParts = fullKey.split("/");
  return keyParts[keyParts.length - 1];
};

export default withTranslation(["generic", "realEstate"])(PhotoUploadModal);
