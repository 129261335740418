import React from "react";
import { InvestmentsListContainer } from "../../components/investments/InvestmentList/InvestmentListContainer";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import ClubBanner from "../../components/investments/ClubBanner/ClubBanner";
import RideOverview from "../../../sharedComponents/RideOverview/RideOverview";
import { useHistory } from "react-router-dom";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { FeatureFlags } from "global-query-types";

interface MyInvestmentsSceneProps extends WithTranslation {}

const MyInvestmentsScene = ({ t }: MyInvestmentsSceneProps) => {
  const history = useHistory();

  const navigateToClubDeals = () => {
    history.push(`/client/club-deals`);
  };

  return (
    <FeatureFlag name={FeatureFlags.ClientInvestments}>
      <PageMetaData title={t("client:investments.page-title")} />
      <RideOverview
        title={t("client:investments.header.title")}
        subtitle={t("client:investments.header.subtitle")}
        sideContent={<ClubBanner onClick={navigateToClubDeals} />}
        buttonLabel={t("client:investments.header.addButton")}
        imageSrc={"/assets/investments/header-icon.svg"}>
        <InvestmentsListContainer />
      </RideOverview>
    </FeatureFlag>
  );
};

export default withTranslationReady("client")(MyInvestmentsScene);
