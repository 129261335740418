import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getFinanceAdsCodes } from "./graphql/getFinanceAdsCodes";

export const GET_FINANCE_ADS_CODES = gql`
  query getFinanceAdsCodes {
    getFinanceAdsCodes {
      financeAdsCodes {
        financeAdsCode
        email
      }
    }
  }
`;

export const GetFinanceAdsCodes = () =>
  useQuery<getFinanceAdsCodes>(GET_FINANCE_ADS_CODES, {
    variables: {},
    fetchPolicy: "no-cache"
  });
