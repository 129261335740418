import { LTBDashboardActionCard } from "client/components/LowTaxBrokerDashboard/sharedComponents/LTBDashboardActionCard/LTBDashboardActionCard";
import React, { useState } from "react";
import PayOutModal from "client/components/LowTaxBrokerDashboard/PayOut/PayOutModal/PayOutModal";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";

export interface PayOutProps {
  companyName: string;
  iban: string;
  requestPayoutCallback: (amount: number) => Promise<void>;
}

const PayOut = ({ t, companyName, iban, requestPayoutCallback }: PayOutProps & WithTranslation) => {
  const [showModal, setShowModal] = useState(false);
  const isEnabled = FeatureFlagService.instance.isEnabled(FeatureFlags.LTBDashboardPayOut);

  return (
    <div data-testid={"pay-out"}>
      {showModal && (
        <PayOutModal
          requestPayout={requestPayoutCallback}
          onClose={() => setShowModal(false)}
          companyName={companyName}
          iban={iban}
        />
      )}
      <LTBDashboardActionCard
        dataTestId={"pay-out-card"}
        title={t("generic:low-tax-broker-dashboard.pay-out.title")}
        description={t("generic:low-tax-broker-dashboard.pay-out.description")}
        buttonLabel={t("generic:low-tax-broker-dashboard.pay-out.button")}
        buttonClickHandler={() => setShowModal(true)}
        disabled={!isEnabled}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(PayOut);
