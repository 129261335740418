import React from "react";
import GuestLayout from "../../../sharedComponents/templates/GuestLayout/GuestLayout";
import OrderLeiConfirmation from "../../components/OrderLeiConfirmation/OrderLeiConfirmation";

export const OrderLeiConfirmationScene = () => {
  return (
    <GuestLayout>
      <div className="order-confirmation-scene">
        <OrderLeiConfirmation />
      </div>
    </GuestLayout>
  );
};
