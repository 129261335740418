import { ReviewBrokerSetup } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/ReviewBrokerSetup/ReviewBrokerSetup";
import { ReviewCompanyInformation } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/ReviewCompanyInformation/ReviewCompanyInformation";
import { ReviewBrokerCompliance } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/ReviewBrokerCompliance/ReviewBrokerCompliance";
import React from "react";
import OrderFilesTableContainer, {
  OrderFile,
  SupportedFilePaths
} from "admin/components/OrderFilesTable/OrderFilesTableContainer";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags, OrderCompanyStatusEnum } from "global-query-types";
import { SectionWrapper } from "admin/components/LowTaxBrokerOrderDetails/SectionWrapper/SectionWrapper";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { companyName } from "lib/formatters/companyFormatter";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { TypeOfDocument } from "admin/components/OrderFilesTable/OrderFilesTable";
import { UploadFileResponse } from "lib/ports/FileStorage";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { WithTranslation } from "react-i18next";
import { TradeCompany } from "lib/models/client/LowTaxBroker/TradeCompany";
import { withTranslationReady } from "common/i18n/withTranslationReady";

interface CompleteApplicationSectionProps extends WithTranslation, TradeCompany {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order;
  isLoading: boolean;
  uploadFile: (file: File, orderId: string) => Promise<UploadFileResponse>;
  sendToFxFlat: (orderId: string) => Promise<void>;
  upsertHandler: (data: UpsertOrderVariables) => Promise<void>;
  getSummary: () => Promise<void>;
}

const ApplicationSection = ({
  order,
  tradeCompany,
  getSummary,
  isLoading,
  sendToFxFlat,
  upsertHandler,
  uploadFile,
  t
}: CompleteApplicationSectionProps) => {
  let orderFiles: OrderFile[] = [];

  const companyFilesKeys = [
    "commercialRegisterFile",
    "shareholderAgreementFile",
    "signatoryListFile",
    "proofOfAddressFile"
  ];

  const companyName: string = tradeCompany.getTradeCompanyName() ?? t("generic:no-trade-company");

  buildShareholderDocuments(order, orderFiles);
  buildEmployeeDocuments(order, orderFiles);
  buildCompanyDocuments(order, orderFiles, companyFilesKeys, companyName);
  buildHoldingDocuments(order, orderFiles, companyFilesKeys);

  return (
    <SectionWrapper title={t("generic:broker-order-details.application")}>
      <ReviewApplication order={order} tradeCompany={tradeCompany} />
      {order.extra?.isNewBrokerEnabled ? null : (
        <>
          <div className={"complete-application-buttons"}>
            <RideButtonDeprecated
              data-testid="preview-summary-button"
              disabled={!order.extra.orderSummaryPdf?.id}
              onClick={() => getSummary()}>
              {t("generic:low-tax-broker-order.preview-summary")}
            </RideButtonDeprecated>

            <RideButtonDeprecated
              data-testid={"send-to-broker-partner-button"}
              disabled={
                hasMissingOrIncompleteFiles(orderFiles) ||
                order.status !== OrderCompanyStatusEnum.Booked
              }
              isLoading={isLoading}
              onClick={() => sendToFxFlat(order.id)}>
              {t("generic:low-tax-broker-order.send-to-broker-partner")}
            </RideButtonDeprecated>
          </div>
          <OrderFilesTableContainer
            order={order}
            files={orderFiles}
            upsertHandler={upsertHandler}
            uploadFile={uploadFile}
            isLoading={isLoading}
          />
        </>
      )}
    </SectionWrapper>
  );
};

const ReviewApplication = ({ order, tradeCompany }) => {
  const { consentToHandlePersonalData, declareTaxInTheUS, securityQuestions } = order.extra;

  return (
    <>
      <ReviewBrokerSetup
        tradeCompany={tradeCompany}
        setupData={{
          consentToHandlePersonalData,
          declareTaxInTheUS,
          securityQuestions
        }}
        isNewBrokerEnabled={order.extra?.isNewBrokerEnabled ?? false}
      />
      <ReviewCompanyInformation
        isNewBrokerEnabled={order.extra.isNewBrokerEnabled ?? false}
        companyInformation={order.extra}
      />
      <ReviewBrokerCompliance
        isNewBrokerEnabled={order.extra.isNewBrokerEnabled ?? false}
        complianceData={order.extra}
      />
    </>
  );
};

export default withTranslationReady(["generic"])(ApplicationSection);

const hasMissingOrIncompleteFiles = (orderFiles: OrderFile[]) => {
  const additionalDocumentInformationToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.IDDocumentAdditionalInformationForOps
  );

  if (additionalDocumentInformationToggle) {
    return orderFiles.some((f) => f.required && !f.readyToSend);
  } else {
    return orderFiles.some((f) => f.required && !f.fileId);
  }
};

function buildCompanyDocuments(
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order,
  orderFiles: OrderFile[],
  companyFilesKeys: string[],
  entityName: string
) {
  companyFilesKeys.forEach((companyFileKey) => {
    orderFiles.push({
      path: "companyDocuments",
      entityName,
      readyToSend: order.extra.companyDocuments?.[companyFileKey]?.readyToSend,
      fileKey: companyFileKey,
      fileName: order.extra.companyDocuments?.[companyFileKey]?.name,
      fileSize: order.extra.companyDocuments?.[companyFileKey]?.size,
      fileId: order.extra.companyDocuments?.[companyFileKey]?.id,
      required: companyFileKey !== "transparencyRegisterFile",
      typeOfDocument:
        companyFileKey === "proofOfAddressFile"
          ? order.extra.companyDocuments?.[companyFileKey]?.typeOfDocument
          : undefined,
      issuingDate:
        companyFileKey === "proofOfAddressFile"
          ? order.extra.companyDocuments?.[companyFileKey]?.issuingDate
          : undefined
    });
  });
  return companyFilesKeys;
}

function buildHoldingDocuments(
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order,
  orderFiles: OrderFile[],
  companyFilesKeys: string[]
) {
  for (const holdingKey of Object.keys(order.extra.holdingCompanies ?? {})) {
    const entity = order.extra.holdingCompanies[holdingKey];
    const entityName = companyName(entity.companyInfo);
    for (const fileKey of companyFilesKeys) {
      orderFiles.push({
        path: "holdingCompanies",
        subPath: holdingKey,
        entityName,
        fileKey,
        readyToSend: entity[fileKey]?.readyToSend,
        fileId: entity[fileKey]?.id,
        fileName: entity[fileKey]?.name,
        fileSize: entity[fileKey]?.size,
        required: fileKey !== "transparencyRegisterFile",
        typeOfDocument:
          fileKey === "proofOfAddressFile" ? entity[fileKey]?.typeOfDocument : undefined,
        issuingDate: fileKey === "proofOfAddressFile" ? entity[fileKey]?.issuingDate : undefined
      });
    }
  }
}

function buildEmployeeDocuments(
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order,
  orderFiles: OrderFile[]
) {
  buildNaturalPersonDocuments(orderFiles, order.extra.employees, "employees");
}

function buildShareholderDocuments(
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order,
  orderFiles: OrderFile[]
) {
  buildNaturalPersonDocuments(orderFiles, order.extra.shareholders, "shareholders");
}

function buildNaturalPersonDocuments(
  orderFiles: OrderFile[],
  naturalPersons: any,
  path: "shareholders" | "employees"
) {
  for (const key of Object.keys(naturalPersons ?? {})) {
    const entity = naturalPersons[key];
    const entityName = personNameWithFallback(entity.personData);

    buildNaturalPersonProofOfIdFile(orderFiles, path, entityName, key, entity);

    buildNaturalPersonProofOfAddressFile(orderFiles, path, entityName, key, entity);
  }
}

function buildNaturalPersonProofOfIdFile(
  orderFiles: OrderFile[],
  path: SupportedFilePaths,
  entityName: string,
  key: string,
  entity: any
) {
  {
    const { id, name, size, readyToSend, typeOfDocument, validThrough, issuingCountry } =
      entity.proofOfIDFile ?? {};

    orderFiles.push({
      path,
      entityName,
      readyToSend,
      validThrough,
      typeOfDocument,
      issuingCountry,
      subPath: key,
      fileKey: "proofOfIDFile",
      fileId: id,
      required: true,
      fileName: name,
      fileSize: size
    });
  }

  {
    const { id, name, size, readyToSend } = entity.proofOfIDFile?.back ?? {};

    orderFiles.push({
      path,
      entityName,
      readyToSend,
      subPath: key,
      back: true,
      fileKey: "proofOfIDFile",
      fileId: id,
      required: entity.proofOfIDFile?.typeOfDocument === TypeOfDocument.ID,
      fileName: name,
      fileSize: size
    });
  }
}

function buildNaturalPersonProofOfAddressFile(
  orderFiles: OrderFile[],
  path: SupportedFilePaths,
  entityName: string,
  key: string,
  entity: any
) {
  const { id, name, size, readyToSend, typeOfDocument, issuingDate } =
    entity.proofOfAddressFile ?? {};

  orderFiles.push({
    path: path,
    entityName,
    subPath: key,
    required: true,
    fileKey: "proofOfAddressFile",
    readyToSend,
    issuingDate,
    typeOfDocument,
    fileId: id,
    fileName: name,
    fileSize: size
  });
}
