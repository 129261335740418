import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { CompanyById_companyById } from "lib/api/queries/graphql/CompanyById";

interface FiscalYearStartProps extends StepComponentProps {
  company: CompanyById_companyById | null | undefined;
}

const FiscalYearStart = ({
  t,
  order,
  controls,
  saveData,
  currentStep,
  company
}: FiscalYearStartProps & WithTranslation) => {
  async function onSubmit(values) {
    currentStep.complete();

    await saveData({
      ...currentStep.chapter.order.serialize(),
      companyData: {
        ...order.extra?.companyData,
        fiscalYearStart: `${values.day}.${values.month}`
      }
    });
  }

  const [day, month] =
    company?.fiscalYear?.split(".") ?? order.extra?.companyData?.fiscalYearStart?.split(".") ?? [];

  const formik = useRideFormik({
    initialValues: {
      month,
      day
    },
    onSubmit,
    validationSchema: FiscalYearStartSchema(t)
  });

  const daysInMonth = numberOfDaysForMonth(formik.values.month);

  const monthOptions = getNumericOptions(12);
  const dayOptions = getNumericOptions(daysInMonth);

  formik.values.day =
    Number(formik.values.day) > daysInMonth ? daysInMonth.toString() : formik.values.day;

  return (
    <FormikProvider value={formik}>
      <Form className="fiscal-year-start">
        <StepHeading text={t("generic:admin-service-form.fiscal-year-start.title")} />
        <div className="fiscal-year-start__fields">
          <RideDropdownSelect
            label={t("generic:month")}
            name={"month"}
            options={monthOptions}
            disabled={!!company?.fiscalYear}
            isSearchable
          />
          <RideDropdownSelect
            label={t("generic:day")}
            name={"day"}
            options={dayOptions}
            disabled={!!company?.fiscalYear}
            isSearchable
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

const twoDigits = (num: number) => num.toString().padStart(2, "0");

export const getNumericOptions = (length: number) =>
  Array.from({ length }, (_, i) => {
    const formattedNumber = twoDigits(i + 1);
    return { value: formattedNumber, label: formattedNumber };
  });

export const numberOfDaysForMonth = (month: string) => {
  switch (month) {
    case "02":
      return 28;
    case "04":
    case "06":
    case "09":
    case "11":
      return 30;
    default:
      return 31;
  }
};

export const FiscalYearStartSchema = (t) =>
  Yup.object().shape({
    month: Yup.string().required(t("generic:validation-required")),
    day: Yup.string().required(t("generic:validation-required"))
  });

export default withTranslationReady("generic")(FiscalYearStart);
