import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import RideCardValuePair from "sharedComponents/RideCardValuePair/RideCardValuePair";
import { ClickToCopy } from "../../../sharedComponents/ClickToCopy/ClickToCopy";
import { RideCardBodySeparator } from "../../../sharedComponents/RideCardBodySeparator/RideCardBodySeparator";

export interface KeyValueCardData {
  key: string;
  label: string;
  value: string;
}

export interface KeyValueCardProps {
  title: string;
  data: KeyValueCardData[];
  className?: string;
}

const KeyValueCard = ({ title, data, className = "" }: KeyValueCardProps) => {
  return (
    <div className={className} data-testid="key-value-card">
      <RideSectionCard title={title}>
        {data.map(({ key, value, label }, index) => {
          const shouldApplyBodySeparator = index + 1 < data.length;
          return (
            <div key={index}>
              <RideCardValuePair data-testid={key} name={label}>
                {value !== "" ? <ClickToCopy label={value} value={value} /> : <>&nbsp;</>}
                {/*I need to test this*/}
              </RideCardValuePair>
              {shouldApplyBodySeparator ? <RideCardBodySeparator /> : null}
            </div>
          );
        })}
      </RideSectionCard>
    </div>
  );
};

export default KeyValueCard;
