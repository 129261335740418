import React from "react";
import { LinkVariant, TextLink } from "../TextLink/TextLink";
import { ClickableElement } from "../ClickableElement/ClickableElement";
import { CgSoftwareDownload } from "react-icons/cg";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import TextInput from "../../components/generic/TextInput";
import { RideCheckbox } from "../FormControls/RideCheckbox/RideCheckbox";
import { FormikProps } from "formik";
import { AlertMessage, AlertType } from "../AlertMessage/AlertMessage";

export enum RideTableCellAlignment {
  RIGHT = "right",
  LEFT = "left",
  CENTER = "center"
}

export enum RideTableCellVariant {
  text = "TEXT",
  secondaryText = "SECONDARY_TEXT",
  primaryLink = "PRIMARY_LINK",
  secondaryLinks = "SECONDARY_LINKS",
  primaryLinkDecorated = "PRIMARY_LINK_DECORATED",
  secondaryLinksDecorated = "SECONDARY_LINKS_DECORATED",
  secondaryLinkDecorated = "SECONDARY_LINK_DECORATED",
  badge = "BADGE",
  document = "DOCUMENT",
  noAction = "NO_ACTION",
  actionButton = "ACTION_BUTTON",
  actionDone = "ACTION_DONE",
  actionDownload = "ACTION_DOWNLOAD",
  input = "INPUT",
  checkbox = "CHECKBOX"
}

export interface RideDownloadCell {
  text?: string;
  clickHandler?: () => void;
  href?: string;
}

export interface RideCellLink {
  text: string;
  href: string;
}

export interface RideTableCellProps extends WithTranslation {
  value?: React.ReactNode;
  alignment?: RideTableCellAlignment;
  variant?: RideTableCellVariant;
  link?: string;
  links?: RideCellLink[];
  badge?: React.ReactNode;
  file?: RideDownloadCell;
  action?: any;
  classes?: string;
  dataTestId?: string;
  name?: string;
  checked?: boolean;
  isDisabled?: boolean;
  formik?: FormikProps<any>;
  inputValue?: string;
  reason?: string;
  inputType?: "text" | "number";
}

const RideTableCell = ({
  checked = false,
  value = "---",
  alignment = RideTableCellAlignment.LEFT,
  variant = RideTableCellVariant.text,
  link = "#",
  links = [],
  badge,
  file,
  action,
  classes,
  t,
  dataTestId = "ride-table-cell",
  name = "",
  inputValue = "",
  inputType = "text",
  isDisabled
}: RideTableCellProps) => {
  return (
    <div
      data-testid={dataTestId}
      className={`ride-table-cell ride-table-cell--aligned-${alignment} ${classes ?? ""}`}>
      {variant === RideTableCellVariant.text && value}
      {variant === RideTableCellVariant.secondaryText && (
        <span
          data-testid={`${dataTestId}-secondary-text`}
          className="ride-table-cell-secondary-text">
          {value}
        </span>
      )}
      {variant === RideTableCellVariant.primaryLink && (
        <TextLink
          to={link}
          variant={LinkVariant.primary}
          data-testid={`${dataTestId}-primary-link`}>
          {value}
        </TextLink>
      )}
      {variant === RideTableCellVariant.secondaryLinks &&
        links?.map((link, index) => (
          <TextLink
            key={index}
            to={link.href}
            variant={LinkVariant.secondary}
            data-testid={`${dataTestId}-secondary-link`}>
            {link.text}
            {links.length > 1 && index !== links?.length - 1 ? ", " : null}
          </TextLink>
        ))}
      {variant === RideTableCellVariant.primaryLinkDecorated && (
        <TextLink
          to={link}
          variant={LinkVariant.primary}
          data-testid={`${dataTestId}-primary-link-decorated`}
          className={"ride-link--DECORATED"}>
          {value}
        </TextLink>
      )}
      {variant === RideTableCellVariant.secondaryLinkDecorated && (
        <TextLink
          to={link}
          variant={LinkVariant.secondary}
          data-testid={`${dataTestId}-secondary-link-decorated`}
          className={"ride-link--DECORATED"}>
          {value}
        </TextLink>
      )}
      {variant === RideTableCellVariant.secondaryLinksDecorated &&
        links?.map((link, index) => (
          <TextLink
            key={index}
            to={link.href}
            variant={LinkVariant.secondary}
            data-testid={`${dataTestId}-secondary-links-decorated`}
            className={"ride-link--DECORATED"}>
            {link.text}
            {links.length > 1 && index !== links?.length - 1 ? ", " : null}
          </TextLink>
        ))}
      {variant === RideTableCellVariant.badge && badge}
      {variant === RideTableCellVariant.document && file && (
        <div data-testid={`${dataTestId}-document`} className="ride-table-cell-document">
          {file.href ? (
            <TextLink
              to={file.href}
              variant={LinkVariant.secondary}
              target="_blank"
              data-testid={`${dataTestId}-document-link`}>
              <CgSoftwareDownload className="ride-table-cell-document-icon" />
              {file.text}
            </TextLink>
          ) : (
            <ClickableElement
              data-testid={`${dataTestId}-document-button`}
              onClick={file.clickHandler}>
              <CgSoftwareDownload className="ride-table-cell-document-icon" />
              <span className="ride-table-cell-document-text">{file.text}</span>
            </ClickableElement>
          )}
        </div>
      )}
      {variant === RideTableCellVariant.noAction && (
        <div data-testid={`${dataTestId}-no-action`} className="ride-table-cell-no-action">
          {t("generic:not-possible-yet")}
        </div>
      )}
      {variant === RideTableCellVariant.actionButton && (
        <div data-testid={`${dataTestId}-action`}>
          {!!isDisabled ? (
            <>
              <RideButtonDeprecated
                data-testid={`${dataTestId}-action-button`}
                variant={ButtonVariant.Secondary}
                size={ButtonSize.Small}
                onClick={action}
                disabled>
                {value}
              </RideButtonDeprecated>
              <div>&nbsp;</div>
              <AlertMessage
                type={AlertType.warning}
                data-testid="fiscal-year-data-upload-confirmation-warning">
                {t("client:company.confirm-accounting-year.warning")}
              </AlertMessage>
            </>
          ) : (
            <RideButtonDeprecated
              data-testid={`${dataTestId}-action-button`}
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
              onClick={action}>
              {value}
            </RideButtonDeprecated>
          )}
        </div>
      )}
      {variant === RideTableCellVariant.actionDone && (
        <img
          src="/assets/sharedComponents/green-checkmark.svg"
          alt={t("generic:already-complete")}
          className="ride-table-cell-action-done"
          data-testid={`${dataTestId}-action-done`}
        />
      )}
      {variant === RideTableCellVariant.actionDownload && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          data-testid={`${dataTestId}-action-download`}>
          <CgSoftwareDownload className="ride-table-cell-action-download-icon" />
        </a>
      )}
      {variant === RideTableCellVariant.input && (
        <TextInput t={t} type={inputType} name={name} value={inputValue} onChange={action} />
      )}
      {variant === RideTableCellVariant.checkbox && (
        <RideCheckbox data-testid={dataTestId} checked={checked} onChange={action} name={name} />
      )}
    </div>
  );
};

export default withTranslationReady(["generic"])(RideTableCell);
