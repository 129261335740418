import React from "react";
import PhoneForm from "./PhoneForm";
import { Steps } from "../TwoFactorAuthenticationSetupForm/TwoFactorAuthenticationSetupForm";
import { useMutation } from "react-apollo";
import { UPDATE_PHONE_NUMBER } from "../../../lib/api/mutations/UPDATE_PHONE_NUMBER";
import {
  UpdatePhoneNumber,
  UpdatePhoneNumberVariables
} from "../../../lib/api/mutations/graphql/UpdatePhoneNumber";
import { GetMe } from "../../../lib/api/queries/MeQuery";
import { SendSmsForTwoFactorAuth } from "../../../lib/api/mutations/graphql/SendSmsForTwoFactorAuth";
import { SEND_SMS_FOR_TWO_FACTOR_AUTH } from "../../../lib/api/mutations/SEND_SMS_FOR_TWO_FACTOR_AUTH";

interface PhoneFormContainerProps {
  goToStep: (step: Steps) => void;
  onPhoneChange: (phoneNumber: string) => void;
  onSmsSent: (nextSmsAvailableAt) => void;
  isEdit: boolean;
}

const PhoneFormContainer = ({
  goToStep,
  onPhoneChange,
  onSmsSent,
  isEdit
}: PhoneFormContainerProps) => {
  const [updatePhoneNumberMutation] = useMutation<UpdatePhoneNumber, UpdatePhoneNumberVariables>(
    UPDATE_PHONE_NUMBER
  );

  const [sendSmsForTwoFactorAuthMutation] = useMutation<SendSmsForTwoFactorAuth>(
    SEND_SMS_FOR_TWO_FACTOR_AUTH
  );

  const meRequest = GetMe();

  const onSubmit = async (phone: string) => {
    onPhoneChange(phone);

    try {
      await updatePhoneNumberMutation({
        variables: {
          newPhoneNumber: phone
        }
      });

      const result = await sendSmsForTwoFactorAuthMutation();

      onSmsSent(result.data?.sendSmsForTwoFactorAuth?.nextSmsAvailableAt);
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }
      return e;
    }

    goToStep(Steps.CODE);
  };
  return (
    <PhoneForm
      onSubmit={onSubmit}
      isEdit={isEdit}
      initialValue={meRequest?.data?.me?.person?.phoneNumber ?? undefined}
    />
  );
};

export default PhoneFormContainer;
