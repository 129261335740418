import React from "react";
import { useHistory } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import FiscalYearSectionContainer from "../../../components/FiscalYearSection/FiscalYearSectionContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface CompanyFiscalYearsSceneProps extends WithTranslation {}

const CompanyFiscalYearsScene = ({ t }: CompanyFiscalYearsSceneProps) => {
  const { goBack } = useHistory();
  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.fiscalYear")} />
      <ModalFormLayout title={t("client:company.edit.fiscalYear")} onBack={goBack}>
        <FiscalYearSectionContainer />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation(["client"])(CompanyFiscalYearsScene);
