import gql from "graphql-tag";

export const CREATE_ADMIN_SERVICE_ADD_ON = gql`
  mutation CreateAdminServiceAddOn($companyId: ID!) {
    createAdminServiceAddOn(companyId: $companyId) {
      id
    }
  }
`;

export const SET_PROCESS_TASK_STATE = gql`
  mutation SetProcessTaskState(
    $companyCreationId: ID!
    $ref: String!
    $state: TaskStatesEnum!
    $options: InputProcessTaskOptions
  ) {
    setProcessTaskState(
      companyCreationId: $companyCreationId
      ref: $ref
      state: $state
      options: $options
    ) {
      feedback
    }
  }
`;
