import React, { PropsWithChildren } from "react";
import RideFooter from "sharedComponents/RideFooter/RideFooter";
import { Container } from "react-bootstrap";
import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import TwoFactorAuthenticationNotificationContainer from "auth/components/TwoFactorAuthenticationNotification/TwoFactorAuthenticationNotificationContainer";

export interface AdminLayoutProps extends PropsWithChildren<any>, WithTranslation {
  fluid: boolean;
}

const AdminLayout = ({ t, children, fluid }: AdminLayoutProps) => (
  <>
    <PageMetaData title={t("nominee-dashboard:tab-page-title")} />
    <AdminHeader />
    <Container fluid={fluid} data-testid="admin-layout" className="pb-4">
      {children}
    </Container>
    <RideFooter showLanguages={true} />
    <FeatureFlag name={FeatureFlags.TwoFactorAuthenticationSetup}>
      <TwoFactorAuthenticationNotificationContainer />
    </FeatureFlag>
  </>
);

export default withTranslationReady(["nominee-dashboard"])(AdminLayout);
