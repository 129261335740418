import React from "react";
import { GetThirdPartyTaxAdvisorsAndPartners } from "components/nominee/ThirdPartyTaxAdvisorsList/Queries";
import { TaxAdvisorSection } from "admin/components/TaxAdvisorSection/TaxAdvisorSection";

export const ThirdPartyTaxAdvisorCustomersListContainer = () => {
  const getThirdPartyTaxAdvisorsAndPartnersRequest = GetThirdPartyTaxAdvisorsAndPartners();

  const taxAdvisorCompanies =
    getThirdPartyTaxAdvisorsAndPartnersRequest.data?.ThirdPartyTaxService?.companies;

  return (
    <>
      {taxAdvisorCompanies?.map((taxAdvisorCompany) => (
        <TaxAdvisorSection taxAdvisorCompany={taxAdvisorCompany} />
      ))}
    </>
  );
};
