import React from "react";

const experts = ["Mert", "Lisa", "Lars"];

const moduleName = "expert-avatars";

export const ExpertAvatars = () => {
  return (
    <div className={moduleName}>
      {experts.map((name) => (
        <img
          key={name}
          src={`/assets/members/${name}.svg`}
          alt=""
          className={`${moduleName}__avatar`}
        />
      ))}
    </div>
  );
};
