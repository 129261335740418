import React from "react";
import { CheckboxField } from "../CheckboxField";

export const PersonalDetailAgreement = ({
  localError,
  AgreementComponent,
  prefix,
  formik,
  values
}) => (
  <div>
    <div
      className={
        "mt-4 agreement-checkbox " +
        (localError && !values["agreement"] ? "agreement-checkbox-error" : "")
      }>
      <CheckboxField
        name={prefix + "agreement"}
        label={AgreementComponent()}
        state={values["agreement"]}
        handleClick={(e) => {
          formik.setFieldValue(prefix + "agreement", e.target.checked);
        }}
      />
      <div className="mt-3">
        {localError && !values["agreement"] ? (
          <div className="invalid-feedback d-block" data-testid="agreement-invalid-feedback">
            {localError}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
);
