import {
  Product,
  UnifiedShortOrderFormServerData
} from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormServerData";
import {
  OrderEventPayload,
  OrderEventProductType
} from "lib/services/GoogleTagManager/GoogleTagManager";

export const toOrderGtmEvent = ({
  selectedProducts,
  orderSummary,
  personData
}: Pick<
  UnifiedShortOrderFormServerData,
  "selectedProducts" | "orderSummary" | "personData"
>): OrderEventPayload => {
  const pricesByProduct = {
    [Product.AS]: orderSummary.adminServicePrice,
    [Product.GS]: orderSummary.grundungServicePrice,
    [Product.LTB]: orderSummary.lowTaxBrokerPrice
  };

  const productToOrderedProduct = {
    [Product.LTB]: OrderEventProductType.RIDE_BROKER,
    [Product.AS]: OrderEventProductType.AS,
    [Product.GS]: OrderEventProductType.GS
  };

  let result: OrderEventPayload = {
    order_summary: {
      one_time_fee: orderSummary.oneTimeFee,
      discount_value: orderSummary.discountValue,
      total_price: orderSummary.totalPrice
    },
    ordered_products: {}
  };

  selectedProducts.forEach(({ product, tier }) => {
    const mappedProduct = productToOrderedProduct[product];
    result.ordered_products[mappedProduct] = {
      product: mappedProduct,
      tier,
      quantity: 1,
      price: pricesByProduct[product]!
    };
  });

  if (personData) {
    result = {
      ...result,
      email: personData.email,
      firstName: personData.firstName,
      lastName: personData.lastName,
      phoneNumber: personData.phoneNumber
    };
  }

  return result;
};
