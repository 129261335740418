import React, { PropsWithChildren } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface RideTooltipProps extends PropsWithChildren<any> {
  name: string;
  content: string;
  show?: boolean;
}

export const RideTooltip = ({ name, content, children, show }: RideTooltipProps) => {
  const tooltip = (
    <Tooltip data-testid="tooltip" id={`tooltip-${name}`}>
      {content}
    </Tooltip>
  );

  return content === "" ? (
    children
  ) : (
    <OverlayTrigger placement="bottom" overlay={tooltip} show={show}>
      {children}
    </OverlayTrigger>
  );
};
