import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { MapContainer } from "../generic/MapContainer";
import { RealEstateQueryById_realEstatesById } from "./graphql/RealEstateQueryById";
import { RealEstateQueryByCompanyId_realEstateByCompanyId } from "./graphql/RealEstateQueryByCompanyId";
import { companyDetailsByCompanyId_companyById } from "./graphql/companyDetailsByCompanyId";
import { formatCompanyName } from "../../common/formatters/CompanyNameFormatter";
import { Link } from "react-router-dom";
import PhotoUploadModal from "../generic/UploadToS3/PhotoUploadModal";
import RenderPhotosFromS3 from "../generic/UploadToS3/RenderPhotosFromS3";
import { RealEstateGallery } from "./graphql/RealEstateGallery";
import UpdateRealEstate from "./UpdateRealEstate";
import { defaultCountry } from "../../common/defaultCountry";
import { translateRealEstateType } from "../../common/translateRealEstateType";
import { formatCapitalContribution } from "../../common/formatters/formatCapitalContribution";
import { noPhotoLocation } from "./NewRealEstateForm";

interface DetailPageViewProps extends WithTranslation {
  detailRealEstateData: RealEstateQueryById_realEstatesById | null;
  detailInvestmentRealEstateData: RealEstateQueryByCompanyId_realEstateByCompanyId | null;
  investmentDetail: companyDetailsByCompanyId_companyById | null;
  gallery: RealEstateGallery | undefined;
  refetchGallery: () => void;
  backUrl: string;
}

export const DetailPageView: React.FunctionComponent<DetailPageViewProps> = (props) => {
  const {
    t,
    detailRealEstateData,
    detailInvestmentRealEstateData,
    investmentDetail,
    gallery,
    refetchGallery,
    backUrl
  } = props;

  const company = investmentDetail;

  const detail =
    detailRealEstateData !== null ? detailRealEstateData : detailInvestmentRealEstateData;

  if (detail === null) return <>{t("generic:no-data-error")}</>;

  const galleryUrls = gallery?.realEstateGallery || [];

  return (
    <>
      <Container>
        {renderTitle(detail)}
        {renderDescription(detail)}

        <Row>
          <Col xs={6}>{renderImage(detail, galleryUrls)}</Col>
          <Col xs={6}>
            {renderRealEstateDetails(t, detail)}
            {renderCompanyDetails(t, company)}
            {renderActions(t, detail, backUrl, company, galleryUrls, refetchGallery)}
          </Col>
        </Row>

        {renderMap(detail)}
        {renderDataRoom(t, company)}
      </Container>
    </>
  );
};

const renderTitle = (detail) => (
  <Row className="justify-content-md-center">
    <Col md="auto">
      <h3>{detail?.label}</h3>
    </Col>
  </Row>
);

const renderDescription = (detail) => (
  <Row>
    <Col md="auto">
      <p>{detail?.shortDescription}</p>
    </Col>
  </Row>
);

const renderImage = (detailRecords, gallery: string[]) =>
  gallery.length ? (
    <RenderPhotosFromS3 imageData={gallery} />
  ) : (
    <Image fluid src={`${detailRecords?.image}` || noPhotoLocation} thumbnail />
  );

const renderRealEstateDetails = (t, detail) => {
  const realEstateType = translateRealEstateType(t, detail);

  const address = detail?.realEstateAddress;

  return (
    <dl>
      <dt>{t("realEstate:type")}</dt>
      <dd>{realEstateType}</dd>

      {address && address.street && address.postalCode && address.city && (
        <>
          <dt>{t("generic:address")}</dt>
          <dd>
            {address.street}&nbsp;{address.postalCode}&nbsp;{address.city}
          </dd>
          <dd>{defaultCountry}</dd>
        </>
      )}
    </dl>
  );
};

const renderCompanyDetails = (t, company) =>
  company && (
    <dl>
      <dt>{t("generic:spv")}</dt>
      <dd>{formatCompanyName(t, company)}</dd>

      <dt>{t("generic:capital-contribution")}</dt>
      <dd>{formatCapitalContribution(company.capitalContribution)}</dd>

      <dt>{t("generic:tokenizedShares")}</dt>
      <dd>{company.tokenizedShares}</dd>
    </dl>
  );

const renderActions = (
  t,
  detail,
  backUrl,
  company,
  gallery: string[],
  refetchGallery: () => void
) => (
  <Row>
    <Col md={"auto"}>
      <Link to={backUrl} className="btn btn-link">
        {t("generic:back")}
      </Link>

      {!company && (
        <>
          <UpdateRealEstate detailRecords={detail} />

          <PhotoUploadModal
            imageData={gallery}
            realEstateId={detail.id}
            refetchGallery={refetchGallery}
            overlay={true}
          />
        </>
      )}
    </Col>
  </Row>
);

const renderMap = (detail) => (
  <Row className="mt-3">
    <Col>
      <MapContainer
        markerName={detail!.label || ""}
        initLocation={{
          lat: detail!.latitude,
          lng: detail!.longitude
        }}
      />
    </Col>
  </Row>
);

const renderDataRoom = (t, company) =>
  company && (
    <Row className="mt-5">
      <Col>
        <h2>{t("generic:data_room")}</h2>
        <p className="space-bottom-20">{t("generic:data_room_description")}</p>
        <div className="embed-responsive embed-responsive-21by9 my-5">
          <iframe
            src={company.boxLink!}
            className="embed-responsive-item"
            frameBorder="0"
            width="100%"
            height="100%"
            title="databox"
          />
        </div>
      </Col>
    </Row>
  );

export default withTranslation(["realEstate", "errorCodes", "generic"])(DetailPageView);
