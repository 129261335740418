import React, { PropsWithChildren } from "react";
import { Lock } from "sharedComponents/icons/Lock";
import { RideTooltip } from "sharedComponents/RideTooltip/RideTooltip";
import TextLinkAsButton from "../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";

type RideCardValuePairAlignment = "Row" | "Column";

export interface RideCardValuePairProps extends PropsWithChildren<{}> {
  name?: string;
  value?: string | undefined | null;
  "data-testid"?: string;
  onEdit?: () => void;
  alignment?: RideCardValuePairAlignment;
  isLocked?: boolean;
  lockTooltipContent?: string;
}

const RideCardValuePair = (props: RideCardValuePairProps) => {
  const {
    name,
    alignment = "Row",
    value,
    children,
    isLocked,
    lockTooltipContent,
    onEdit,
    "data-testid": dataTestId = "ride-card-value-pair"
  } = props;

  const isColumn = alignment === "Column";

  return (
    <div
      className={`ride-card-value-pair ride-card-value-pair--${alignment.toLowerCase()}`}
      data-testid={`${dataTestId}`}>
      {!!name ? (
        <div className="ride-card-value-pair__name" data-testid={`${dataTestId}-name`}>
          <strong>{name}:</strong>
        </div>
      ) : null}
      <div className="ride-card-value-pair__value" data-testid={`${dataTestId}-content`}>
        {value}
        {children}
        {!isColumn && (
          <RideCardValuePairEditButton
            name={name}
            isLocked={isLocked}
            dataTestId={dataTestId}
            lockTooltipContent={lockTooltipContent}
            onEdit={onEdit}
            className=""
          />
        )}
      </div>
      {isColumn && (
        <RideCardValuePairEditButton
          name={name}
          isLocked={isLocked}
          dataTestId={dataTestId}
          lockTooltipContent={lockTooltipContent}
          onEdit={onEdit}
          className="ride-card-value-pair__edit--column"
        />
      )}
    </div>
  );
};

const LockWithTooltip = ({ name, tooltipContent }: { name: string; tooltipContent?: string }) => {
  return (
    <RideTooltip name={name} content={tooltipContent ?? ""}>
      <span>
        <Lock />
      </span>
    </RideTooltip>
  );
};

const RideCardValuePairEditButton = ({
  onEdit,
  isLocked,
  lockTooltipContent,
  name,
  dataTestId,
  className
}) => {
  return (
    <div className={`ride-card-value-pair__edit ${className}`}>
      {isLocked ? (
        <LockWithTooltip
          name={name ?? "ride-card-value-pair"}
          tooltipContent={lockTooltipContent}
        />
      ) : (
        onEdit && <TextLinkAsButton onClick={onEdit} data-testid={`${dataTestId}-edit`} />
      )}
    </div>
  );
};

export default RideCardValuePair;
