import React, { useState } from "react";

interface Props {
  title: string;
  children: React.ReactNode;
  fixed?: boolean;
  className?: string;
  "data-testid"?: string;
  buttonSlot?: JSX.Element;
}

export const RideSectionCard = (props: Props) => {
  const { title, fixed, children, className, buttonSlot } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div
      className={`card ride-card-bis ${isCollapsed ? "ride-card-bis--collapsed" : ""} ${
        className ?? ""
      }`}
      data-testid={`${props["data-testid"] || "ride-card"}`}>
      <div className="ride-card-bis__header card-header" data-testid="ride-card-header">
        {!fixed && (
          <span
            className="caret float-right d-md-none"
            data-testid="ride-card-caret"
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        )}
        <span data-testid="ride-card-title">{title}</span>
        {buttonSlot}
      </div>
      <div className="ride-card-bis__body" data-testid="ride-card-body">
        {children}
      </div>
    </div>
  );
};
