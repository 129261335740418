import {
  ExternalCompanyDataByName,
  ExternalCompanyDataByName_externalCompanyDataByName_companies,
  ExternalCompanyDataByNameVariables
} from "../../../lib/api/queries/graphql/ExternalCompanyDataByName";
import ExternalCompanySearchInput from "./ExternalCompanySearchInput";
import React from "react";
import { EXTERNAL_COMPANY_DATA_BY_NAME } from "../../../lib/api/queries/ExternalCompanyDataByName";
import { useApolloClient } from "@apollo/react-hooks";

export interface ExternalCompanySearchInputContainerProps {
  inputName?: string;
  onSelectCompany?: (
    company: ExternalCompanyDataByName_externalCompanyDataByName_companies
  ) => void;
}

export const ExternalCompanySearchInputContainer = ({
  inputName,
  onSelectCompany
}: ExternalCompanySearchInputContainerProps) => {
  const apolloClient = useApolloClient();
  const searchExternalResult = async (name: string) =>
    await apolloClient.query<ExternalCompanyDataByName, ExternalCompanyDataByNameVariables>({
      query: EXTERNAL_COMPANY_DATA_BY_NAME,
      variables: {
        query: name
      }
    });

  return (
    <ExternalCompanySearchInput
      onSelectCompany={onSelectCompany}
      inputName={inputName}
      searchExternalResult={searchExternalResult}
    />
  );
};
