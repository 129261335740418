import { WithTranslation } from "react-i18next";
import { TaskStatesEnum } from "../../../../../global-query-types";
import React from "react";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import TaskContainer from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainer";
import { formatDate } from "../../../../../common/formatters";
import { formatGermanTime } from "../../../../../common/formatters/GermanTimeFormatter";
import { Col, Row } from "react-bootstrap";

interface AttendNotaryMeetingTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

export const AttendNotaryMeetingTask = ({ task, onChange, t }: AttendNotaryMeetingTaskProps) => {
  const strDate = task.options?.data?.date;
  const date = strDate ? new Date(strDate) : null;
  const dayOfWeek = date ? t(`generic:days:day-${date?.getDay()}`) : "";

  return (
    <TaskContainer
      testid="attend-notary-meeting"
      onChange={onChange}
      translationKey={task.ref}
      task={task}>
      <div className="attend-notary-meeting">
        <div data-testid="date-section" className="attend-notary-meeting__date">
          <div data-testid="date-section-title" className="attend-notary-meeting__title">
            {t(`company-founding-steps:tasks.${task.ref}.date-title`)}
          </div>
          <div data-testid="date-section-day">
            {date ? `${dayOfWeek}, ${formatDate(date)}` : "---"}
          </div>
          <div data-testid="date-section-time">
            {t(`company-founding-steps:tasks.${task.ref}.time-title`)}:&nbsp;
            {date ? formatGermanTime(date) : "---"}
          </div>
        </div>
        <div data-testid="contact-section" className="attend-notary-meeting__contact">
          <div data-testid="contact-section-title" className="attend-notary-meeting__title">
            {t(`company-founding-steps:tasks.${task.ref}.address-title`)}
          </div>
          <div data-testid="contact-section-name" className="attend-notary-meeting__contact-name">
            {task.options?.data?.name ?? "---"}
          </div>
          <div data-testid="contact-section-address">
            {!task.options?.data?.address && "---"}
            {task.options?.data?.address?.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <Row noGutters>
            <Col
              xs={12}
              md={6}
              data-testid="contact-section-phone-group"
              className="attend-notary-meeting__contact-group">
              {t(`company-founding-steps:tasks.${task.ref}.address-phone`)}:
              <div className="attend-notary-meeting__contact-group-value">
                {task.options?.data?.phone ?? "---"}
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              data-testid="contact-section-email-group"
              className="attend-notary-meeting__contact-group">
              {t(`company-founding-steps:tasks.${task.ref}.address-email`)}:
              <div className="attend-notary-meeting__contact-group-value">
                {task.options?.data?.email ?? "---"}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </TaskContainer>
  );
};

export default withTranslationReady(["company-founding-steps", "generic"])(AttendNotaryMeetingTask);
