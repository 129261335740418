import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import { FeatureFlags, FlowTypeEnum } from "global-query-types";
import { WithTranslation, withTranslation } from "react-i18next";
import BookingGmbHSteps from "../BookingGmbHSteps/BookingGmbHSteps";
import {
  arrayOfBookingSteps,
  getCompletedSteps
} from "../../../../components/public/CompanyCreation/getCompletedSteps";
import { ApolloQueryResult } from "apollo-client";
import AdvertisementCampaignBanner from "client/components/AdvertisementCampaignBanner/AdvertisementCampaignBanner";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

interface BookingGmbHFlowProps extends WithTranslation {
  companyCreation: MyCompanyCreationQuery_myCompanyCreation | undefined;
  refetch: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
}

const BookingGmbHFlow = ({ companyCreation, refetch, t }: BookingGmbHFlowProps) => {
  const flowType = FlowTypeEnum.GMBH;

  const [showCreationSteps, setShowCreationSteps] = useState(!companyCreation);
  const handleStart = () => {
    setShowCreationSteps(!showCreationSteps);
  };

  const completedSteps = getCompletedSteps(companyCreation?.currentStep, arrayOfBookingSteps);

  return (
    <Container className="booking-gmbh p-0">
      <Row noGutters>
        <Col data-testid="banner-text" className="booking-gmbh__message">
          {t("trader-gmbh:trader-gmbh.banner-text")}
        </Col>
      </Row>
      <FeatureFlag name={FeatureFlags.showReferralCampaignBanner}>
        {companyCreation?.company?.discountCodeUse && (
          <AdvertisementCampaignBanner
            title={t("generic:referralBannerOrderGmbH", {
              referralValue: companyCreation.company.discountCodeUse.discountCode?.referralValue
            })}
            description=""
          />
        )}
      </FeatureFlag>
      <Row noGutters>
        <Col className="booking-gmbh__start-now-col">
          <Button
            data-testid="start-now"
            hidden={!showCreationSteps}
            onClick={handleStart}
            className="booking-gmbh__start-now">
            {t("generic:start-now")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <BookingGmbHSteps
            completedSteps={completedSteps}
            showCreationSteps={showCreationSteps}
            myCompanyCreationDetails={companyCreation}
            refetchMyCompanyCreation={refetch}
            currentFlowType={flowType}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation(["generic", "trader-gmbh", "error-codes"])(BookingGmbHFlow);
