import React, { useState } from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import NewBrokerOrdersList from "./NewBrokerOrdersList";
import { GetLowTaxBrokerOrders } from "lib/api/queries/GetLowTaxBrokerOrders";
import LowTaxBrokerOrdersFilters, {
  LowTaxBrokerOrdersFiltersData
} from "admin/components/LowTaxBrokerOrdersList/LowTaxBrokerOrdersFilters/LowTaxBrokerOrdersFilters";
import { RidePaginationWithSearch } from "../../../uiLibrary/designSystem/RidePagination/RidePaginationWithSearch";

const NewBrokerOrdersListContainer = () => {
  const pageSize = 15;
  const [start, setStart] = useState(0);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState<LowTaxBrokerOrdersFiltersData>({});

  const request = GetLowTaxBrokerOrders({
    start,
    limit: pageSize,
    ...filters,
    isNewBrokerEnabled: true,
    query
  });
  const ordersWithCompany = request.data?.getLowTaxBrokerOrders?.items;

  return (
    <>
      <LowTaxBrokerOrdersFilters
        onFilterChange={(filters) => {
          setStart(0);
          setFilters(filters);
        }}
      />
      <RidePaginationWithSearch
        count={request.data?.getLowTaxBrokerOrders?.count ?? 0}
        start={start}
        limit={pageSize}
        setStart={setStart}
        setQuery={setQuery}>
        <RequestFeedbackHandler requests={[request]}>
          <NewBrokerOrdersList ordersWithCompany={ordersWithCompany ?? []} />
        </RequestFeedbackHandler>
      </RidePaginationWithSearch>
    </>
  );
};

export default NewBrokerOrdersListContainer;
