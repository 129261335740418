import React, { PropsWithChildren } from "react";
import { StepHeading } from "../../ChaptersOrderLayout.partials";

export const DocumentsListHeading = ({ children, title }: PropsWithChildren<any>) => {
  return (
    <div className={"documents-list"}>
      <StepHeading text={title} />
      {children}
    </div>
  );
};
