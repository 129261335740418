import { useMutation } from "@apollo/react-hooks";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import React from "react";
import { ApolloQueryResult } from "apollo-client";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById,
  NomineeCompanyDetails_companyById_bankAccounts
} from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import CompanyBankAccountsSection from "./CompanyBankAccountsSection";
import { UPSERT_BANK_ACCOUNT } from "../../../../lib/api/mutations/UPSERT_BANK_ACCOUNT";
import {
  UpsertBankAccount,
  UpsertBankAccountVariables
} from "lib/api/mutations/graphql/UpsertBankAccount";
import { BankType } from "global-query-types";

export interface CompanyBankAccountsSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

export const CompanyBankAccountsSectionContainer = ({
  company,
  refetchCompany
}: CompanyBankAccountsSectionContainerProps) => {
  const [upsertBankAccount, updateRequest] = useMutation<
    UpsertBankAccount,
    UpsertBankAccountVariables
  >(UPSERT_BANK_ACCOUNT);

  const onSubmit = async (bankAccounts: NomineeCompanyDetails_companyById_bankAccounts[]) => {
    for (const bankAccount of bankAccounts) {
      await upsertBankAccount({
        variables: {
          id: bankAccount.id,
          bankProvider: bankAccount.bankProvider,
          iban: bankAccount.iban,
          name: bankAccount.name,
          plan: bankAccount.plan,
          companyId: company.id
        }
      });
    }
    await refetchCompany();
  };

  const onAddEmptyBankAccount = async () => {
    await upsertBankAccount({
      variables: {
        bankProvider: BankType.Swan,
        companyId: company.id
      }
    });
    await refetchCompany();
  };

  return (
    <RequestFeedbackHandler requests={[updateRequest]}>
      <CompanyBankAccountsSection
        bankAccounts={company.bankAccounts ?? []}
        onSubmit={onSubmit}
        onRefresh={async () => {
          await refetchCompany();
        }}
        onAddEmptyBankAccount={onAddEmptyBankAccount}
      />
    </RequestFeedbackHandler>
  );
};
