import React from "react";
import { AddGmbHModal } from "./AddGmbHModal";
import { useMutation } from "@apollo/react-hooks";
import { UPSERT_ORDER } from "../../../lib/api/mutations/UPSERT_ORDER";
import {
  UpsertOrder,
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "../../../lib/api/mutations/graphql/UpsertOrder";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

interface AddGmbHModalContainerProps {
  onClose: () => void;
}

export const AddGmbHModalContainer = ({ onClose }: AddGmbHModalContainerProps) => {
  const [upsertOrderCall, upsertOrderRequest] = useMutation<UpsertOrder, UpsertOrderVariables>(
    UPSERT_ORDER
  );

  const upsertOrderHandler = async (
    orderData: UpsertOrderVariables
  ): Promise<UpsertOrder_upsertOrder | null | undefined> => {
    const result = await upsertOrderCall({ variables: orderData });
    return result.data?.upsertOrder;
  };

  return (
    <RequestFeedbackHandler requests={[upsertOrderRequest]}>
      <AddGmbHModal onClose={onClose} upsertOrder={upsertOrderHandler} />
    </RequestFeedbackHandler>
  );
};
