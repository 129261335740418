import { ClickableElement } from "../../ClickableElement/ClickableElement";
import { CaretDownIcon } from "../../icons/CaretDownIcon";
import React from "react";

interface ButtonExpandProps {
  expanded: boolean;
  onClick?: () => void;
}

export const ButtonExpand = ({ expanded, onClick }: ButtonExpandProps) => (
  <ClickableElement
    className={`button-expand button-expand--${expanded ? "expanded" : "collapsed"}`}
    data-testid="button-expand"
    onClick={onClick}
    aria-expanded={expanded}>
    <CaretDownIcon />
  </ClickableElement>
);
