import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import TaxAdvisorClientsWrapper from "taxAdvisor/components/TaxAdvisorClientsWrapper/TaxAdvisorClientsWrapper";
import { useQuery } from "react-apollo";
import { GET_MY_TAX_ADVISOR_CLIENTS_LEGACY } from "../../../lib/api/queries/GetMyTaxAdvisorClientsLegacy";
import { GetMyTaxAdvisorClientsLegacy } from "../../../lib/api/queries/graphql/GetMyTaxAdvisorClientsLegacy";

/**
 * @deprecated
 */
const TaxAdvisorClientsWrapperContainer = () => {
  const getRequest = useQuery<GetMyTaxAdvisorClientsLegacy>(GET_MY_TAX_ADVISOR_CLIENTS_LEGACY);

  const taxServices = getRequest.data?.getMyTaxAdvisorClients?.items;

  return (
    <RequestFeedbackHandler requests={[getRequest]}>
      {taxServices && <TaxAdvisorClientsWrapper taxServices={taxServices} />}
    </RequestFeedbackHandler>
  );
};

export default TaxAdvisorClientsWrapperContainer;
