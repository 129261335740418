import React from "react";
import { useMutation } from "react-apollo";
import ResetPassword, { ResetPasswordPhase } from "./ResetPassword";
import { CHANGE_PASSWORD_AND_LOGIN } from "lib/api/mutations/CHANGE_PASSWORD_AND_LOGIN";
import { useParams } from "react-router";
import {
  ChangePasswordAndLogin,
  ChangePasswordAndLoginVariables
} from "../../../lib/api/mutations/graphql/ChangePasswordAndLogin";

export const ResetPasswordContainer = () => {
  const [resetMutation, resetRequest] = useMutation<
    ChangePasswordAndLogin,
    ChangePasswordAndLoginVariables
  >(CHANGE_PASSWORD_AND_LOGIN);
  const { id: resetToken } = useParams<{ id: string }>();

  const handleReset = async (password: string) => {
    const result = await resetMutation({
      variables: {
        resetToken,
        password
      }
    });

    return result.data?.changePasswordAndLogin?.token ?? "";
  };

  return (
    <ResetPassword
      requests={[resetRequest]}
      phase={ResetPasswordPhase.form}
      onSubmit={handleReset}
    />
  );
};
