import React from "react";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "react-bootstrap";
import { AlertMessage, AlertType } from "../../../../../sharedComponents/AlertMessage/AlertMessage";
import { TFunction } from "i18next";
import { getLinksByCompanyId_getLinksByCompanyId_links } from "../../../../../lib/api/queries/graphql/getLinksByCompanyId";
import { TaskStatesEnum } from "../../../../../global-query-types";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface DocuSignTaskProps extends WithTranslation {
  task: ActionableTask;
  links: getLinksByCompanyId_getLinksByCompanyId_links[];
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const ToBeDoneDocuSignTask = ({
  task,
  link,
  t,
  onChange
}: {
  task: ActionableTask;
  link: string;
  t: TFunction;
  onChange: (state: TaskStatesEnum) => Promise<void>;
}) => {
  const onClick = async () => await onChange(TaskStatesEnum.IN_PROGRESS);

  return (
    <Container
      className="rounded-sm p-45 tasks-list__modal-container"
      data-testid={"docu-sign-container"}>
      <Row className="d-flex justify-content-between">
        <DocuSignHeader taskRef={task.ref} t={t} />
        <DocuSignIcon />

        <Col md={{ span: 12, order: 3, offset: 0 }} xs={{ span: 12, order: 2 }}>
          <p data-testid="docu-sign-task-description" className="tasks-list__description mb-4">
            {t(`company-founding-steps:tasks.description.${task.ref}`)}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <AlertMessage type={AlertType.warning} data-testid="docu-sign-task-warning">
            {t(`company-founding-steps:tasks.docu-sign.warning`)}
          </AlertMessage>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="">
          <div className="tasks-list__button text-center text-md-left mt-5 text-break d-flex justify-content-center justify-content-sm-start mb-4">
            <RideButtonDeprecated
              openExternalUrl
              href={link.length === 0 ? "#" : link}
              data-testid="docu-sign-task-button"
              onClick={onClick}
              size={ButtonSize.Big}
              variant={ButtonVariant.Primary}>
              {t(`company-founding-steps:tasks.docu-sign.button`)}
            </RideButtonDeprecated>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const InProgressDocuSignTask = ({
  task,
  link,
  t
}: {
  task: ActionableTask;
  link: string;
  t: TFunction;
}) => {
  return (
    <Container
      className="rounded-sm p-45 tasks-list__modal-container"
      data-testid="docu-sign-container">
      <Row className="d-flex justify-content-between">
        <DocuSignHeader taskRef={task.ref} t={t} />
        <DocuSignIcon />
        <DocuSignMessageWithIcon task={task} t={t} />
      </Row>

      <div className="text-center text-sm-right">
        <Button
          href={link.length === 0 ? "#" : link}
          target="_blank"
          data-testid="docu-sign-task-link"
          className="task-container__button-completed pr-sm-0"
          variant="link">
          {t(`company-founding-steps:tasks.docu-sign.start-again`)}
        </Button>
      </div>
    </Container>
  );
};

const CompletedDocuSignTask = ({ task, t }: { task: ActionableTask; t: TFunction }) => {
  return (
    <Container
      className="rounded-sm p-45 tasks-list__modal-container"
      data-testid={"docu-sign-container"}>
      <Row className="d-flex justify-content-between">
        <DocuSignHeader taskRef={task.ref} t={t} />
        <DocuSignIcon />
        <DocuSignMessageWithIcon task={task} t={t} />
      </Row>
    </Container>
  );
};

const DOCUMENT_TYPE_REF_MAP = {
  MoneyLaunderingQuestionnaire: "gwgFormSigning",
  InstructionLetter: "instructionLetterSign"
};
export const DocuSignTask = ({ task, links, t, onChange }: DocuSignTaskProps) => {
  const link =
    links.find(
      (link: getLinksByCompanyId_getLinksByCompanyId_links) =>
        DOCUMENT_TYPE_REF_MAP[link.notaryDocumentType] === task.ref
    )?.link ?? "";

  if (task.state === "TO_BE_DONE") {
    return <ToBeDoneDocuSignTask onChange={onChange} task={task} link={link} t={t} />;
  }

  if (task.state === "IN_PROGRESS") {
    return <InProgressDocuSignTask task={task} link={link} t={t} />;
  }

  return <CompletedDocuSignTask task={task} t={t} />;
};

const DocuSignHeader = ({ taskRef, t }: { taskRef: string; t: TFunction }) => {
  return (
    <Col sm={{ span: 9, order: 1, offset: 0 }} xs={{ span: 12, order: 1 }}>
      <h1 className="tasks-list__modal-header" data-testid={"docu-sign-task-title"}>
        {t(`company-founding-steps:tasks.name.${taskRef}`)}
      </h1>
    </Col>
  );
};

const DocuSignIcon = () => {
  return (
    <Col
      sm={{ span: 3, order: 2, offset: 0 }}
      xs={{ span: 12, order: 3, offset: 0 }}
      className="d-flex justify-content-center">
      <div
        className={`tasks-list__icon tasks-list__icon--sign mb-4 mb-sm-0`}
        data-testid="docu-sign-task-sign-icon"
      />
    </Col>
  );
};

const DocuSignMessageWithIcon = ({ t, task }: { t: TFunction; task: ActionableTask }) => {
  const lowerCaseState = task.state.toLowerCase();
  return (
    <Col xs={{ order: 3 }} className="d-flex flex-column flex-sm-row align-items-center">
      <div
        className={`tasks-list__icon tasks-list__icon--small tasks-list__icon--${lowerCaseState} m-1`}
        data-testid={`docu-sign-task-${lowerCaseState}-icon`}
      />
      <div
        data-testid="docu-sign-task-state-message"
        className="align-self-center ml-2 tasks-list__state-message tasks-list__state-message--in-progress">
        {t(`company-founding-steps:tasks-states.${task.state}`)}
      </div>
    </Col>
  );
};

export default withTranslation(["company-founding-steps"])(DocuSignTask);
