import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ModalFormLayout from "sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import MyTaxAdvisorCompanyEditContainer from "sharedComponents/MyTaxAdvisorCompanyEditContainer/MyTaxAdvisorCompanyEditContainer";
import { PageMetaData } from "../../../sharedComponents/PageMetaData/PageMetaData";

interface TaxAdvisorNumberSceneProps extends WithTranslation {}

const TaxAdvisorAdvisorNumberScene = ({ t }: TaxAdvisorNumberSceneProps) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.fiscalYear")} />
      <ModalFormLayout title={t("company.edit.advisorNumber")} onBack={goBack}>
        <MyTaxAdvisorCompanyEditContainer fieldName="advisorNumber" />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation("client")(TaxAdvisorAdvisorNumberScene);
