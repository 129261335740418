import gql from "graphql-tag";

export const CREATE_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM = gql`
  mutation CreateLeadForUnifiedShortOrderForm(
    $personData: PersonData!
    $allowsContact: Boolean!
    $privacyPolicyCheckbox: Boolean!
    $userReportedSource: String
    $hubspotCookie: String
  ) {
    createLeadForUnifiedShortOrderForm(
      personData: $personData
      allowsContact: $allowsContact
      privacyPolicyCheckbox: $privacyPolicyCheckbox
      userReportedSource: $userReportedSource
      hubspotCookie: $hubspotCookie
    ) {
      feedback
    }
  }
`;
