import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { FiAtSign } from "react-icons/fi";
import { PhoneIcon } from "sharedComponents/icons/PhoneIcon";
import { RideNavbar } from "sharedComponents/RideNavbar/RideNavbar";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import GuestHeader from "../GuestHeader/GuestHeader";

const GuestHeaderDeprecated = ({ t }: WithTranslation) => {
  const useGuestHeaderAsDesignSystem: boolean = FeatureFlagService.instance.isEnabled(
    FeatureFlags.useGuestHeaderAsDesignSystem
  );

  return useGuestHeaderAsDesignSystem ? (
    <GuestHeader />
  ) : (
    <RideNavbar>
      <NavbarContacts t={t} />
    </RideNavbar>
  );
};

const NavbarContacts = ({ t }: { t: TFunction }) => {
  return (
    <>
      <a
        href={t("generic:contact.email.href")}
        target="_blank"
        rel="noopener noreferrer"
        className="guest-header-deprecated__contact-button"
        data-testid="email-button">
        <FiAtSign size="20" />
        <span
          data-testid="email-button-label"
          className={"guest-header-deprecated__contact-button-text"}>
          {t("generic:contact.email.email")}
        </span>
      </a>
      <a
        href={t("generic:contact.phone.href")}
        target="_blank"
        rel="noopener noreferrer"
        className="guest-header-deprecated__contact-button"
        data-testid="phone-button">
        <PhoneIcon />
        <span
          data-testid="phone-button-label"
          className={"guest-header-deprecated__contact-button-text"}>
          {t("generic:contact.phone.number")}
        </span>
      </a>
    </>
  );
};

export default withTranslation(["generic"])(GuestHeaderDeprecated);
