import React from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { GetNomineeCompanyDetailsById } from "../../../lib/api/queries/GetNomineeCompanyDetailsById";
import CompanyDetails from "./CompanyDetails";

interface CompanyDetailsContainerProps {
  companyId: string;
}

export const CompanyDetailsContainerOld = ({ companyId }: CompanyDetailsContainerProps) => {
  const getRequest = GetNomineeCompanyDetailsById(companyId);

  return (
    <RequestFeedbackHandler requests={[getRequest]}>
      <CompanyDetails company={getRequest.data?.companyById ?? null} refetch={getRequest.refetch} />
    </RequestFeedbackHandler>
  );
};
