import React from "react";
import { ApolloQueryResult } from "apollo-client";
import { TFunction } from "i18next";
import { canAddAdminServiceAddon } from "./AdminServiceSectionAddon";
import ResumeLinkContainer from "../../../../../admin/components/ResumeLink/ResumeLinkContainer";
import { Button } from "react-bootstrap";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const CreateAdminService = ({
  t,
  company,
  createAdminServiceFlow,
  refetchCompany
}: {
  t: TFunction;
  company: NomineeCompanyDetails_companyById;
  createAdminServiceFlow: (companyId: string) => Promise<void>;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}) => {
  if (!canAddAdminServiceAddon(company)) {
    return <></>;
  }

  const handleClick = async () => {
    await createAdminServiceFlow(company.id);
    await refetchCompany();
  };

  return company.adminService ? (
    <ResumeLinkContainer
      t={t}
      id={company.adminService.id}
      flowType={company.adminService.flowType!}
      currentStep={company.adminService.currentStep!}
    />
  ) : (
    <Button onClick={handleClick} data-testid="create-admin-service-button">
      {t("nominee-dashboard:admin-service.create-flow")}
    </Button>
  );
};
