import React from "react";
import { DocumentProps, GmbHDetails } from "lib/models/client/GmbHDetails";
import { WithTranslation, withTranslation } from "react-i18next";
import { NodeTreeCardContainer } from "client/components/Files/NodeTreeCard/NodeTreeCardContainer";
import { UploadDocumentRowContainer } from "components/client/company/components/UploadDocumentRowContainer";
import { StorageConfigStructure } from "components/client/company/components/GetStorageConfig";

export interface DocumentSectionProps extends WithTranslation {
  gmbh: GmbHDetails;
  nodeList: DocumentProps[];
  storageConfig?: StorageConfigStructure[];
  testid?: string;
}

const DocumentSection = ({
  testid = "",
  nodeList,
  gmbh,
  storageConfig,
  t
}: DocumentSectionProps) => (
  <div data-testid={`${testid}-documents-section`}>
    {storageConfig && <UploadDocumentRowContainer gmbh={gmbh} storageConfig={storageConfig} />}
    {nodeList.map((node, index) => (
      <div className="my-5" key={index}>
        <NodeTreeCardContainer
          title={t(`client:company.documents.${node.name}`)}
          nodeId={node.id}
          entityId={gmbh.id}
        />
      </div>
    ))}
  </div>
);

export default withTranslation("client")(DocumentSection);
