import React from "react";
import { TFunction } from "i18next";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { companyName } from "lib/formatters/companyFormatter";
import { formatValue } from "common/formatters";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";

export interface CompanyTaxAdvisorCardProps {
  t: TFunction;
  gmbh: GmbHDetails;
}

export const CompanyTaxAdvisorCard = ({ t, gmbh }: CompanyTaxAdvisorCardProps) => (
  <RideSectionCard
    data-testid="gmbh-tax-advisor-card"
    title={t("tax-advisor:clientCompany.directTaxAdvisor")}>
    <div data-testid="tax-advisor-name">{formatValue(companyName(gmbh.taxAdvisor))}</div>
  </RideSectionCard>
);
