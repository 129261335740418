import { useQuery } from "@apollo/react-hooks";
import { AllDiscountCodesQuery } from "./graphql/AllDiscountCodesQuery";
import gql from "graphql-tag";

export const ALL_DISCOUNT_CODES = gql`
  query AllDiscountCodesQuery {
    allDiscountCodes {
      id
      code
      referrerValue
      referralValue
      blacklisted
      discountCodeAllowedService
      referrerPerson {
        id
        firstName
        lastName
      }
      uses {
        id
        status
        confirmedAt
        usedAt
        flowType
        usedByCompany {
          id
          name
          legalForm
        }
        usedByPerson {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const AllDiscountCodes = () => useQuery<AllDiscountCodesQuery>(ALL_DISCOUNT_CODES);
