import React from "react";
import ShelfCompanyOrderDetailsLayout from "./ShelfCompanyOrderDetailsLayout";
import { GetOrderDetails } from "../../../lib/api/queries/GetOrderDetails";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

interface ShelfCompanyOrderDetailsLayoutContainerProps {
  orderId: string;
  onRefresh: () => Promise<void>;
}
export const ShelfCompanyOrderDetailsLayoutContainer = ({
  orderId,
  onRefresh
}: ShelfCompanyOrderDetailsLayoutContainerProps) => {
  const orderRequest = GetOrderDetails({ orderId });
  const order = orderRequest.data?.getOrderDetails?.order;

  return (
    <RequestFeedbackHandler requests={[orderRequest]}>
      <ShelfCompanyOrderDetailsLayout
        order={order ?? null}
        orderCompany={orderRequest.data?.getOrderDetails?.reservedCompany}
        onRefresh={async () => onRefresh()}
      />
    </RequestFeedbackHandler>
  );
};
