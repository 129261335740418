import { toClubDealCardProps } from "lib/dataAdapters/toClubDealCardProps/toClubDealCardProps";
import { clubDeals_clubDeals } from "lib/api/queries/graphql/clubDeals";
import React from "react";
import AvailableClubDealsList from "./AvailableClubDealsList/AvailableClubDealsList";
import UnavailableClubDealsList from "./UnavailableClubDealsList/UnavailableClubDealsList";
import { PublishType } from "../../../../global-query-types";

interface InvestmentOffersProps {
  clubDeals: clubDeals_clubDeals[];
  onSelect: (id: string) => void;
}

export const ClubDealsList = ({ clubDeals, onSelect }: InvestmentOffersProps) => {
  const clubDealsProps = clubDeals.map(toClubDealCardProps);

  const availableOffers = clubDealsProps.filter((x) => x.status === PublishType.Published);
  const unavailableOffers = clubDealsProps.filter((x) => x.status === PublishType.Completed);

  return (
    <>
      <AvailableClubDealsList onSelect={onSelect} clubDeals={availableOffers} />
      <UnavailableClubDealsList clubDeals={unavailableOffers} />
    </>
  );
};
