import React from "react";
import { StatusIcon } from "../../../sharedComponents/icons/StatusIcon";
import { AddIcon } from "../../../sharedComponents/icons/add";

export enum RideBadgeColorsEnum {
  ACCENT = "ACCENT",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  INFO = "INFO",
  DANGER = "DANGER",
  DARK = "DARK",
  LIGHT_GREY = "LIGHT_GREY",
  RIDE_GREEN_OUTLINED = "RIDE_GREEN_OUTLINED",
  GMBH_BADGE_STATUS = "GMBH_BADGE_STATUS"
}

export enum RideBadgeVariantsEnum {
  SOLID = "SOLID",
  OUTLINED = "OUTLINED",
  INVERT_SOLID = "INVERT-SOLID",
  DASHED = "DASHED"
}

export enum RideBadgeSizeVariant {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM"
}

export enum RideBadgeIconEnum {
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  LIGHT_BLUE = "LIGHT_BLUE",
  GREY = "GREY",
  RED = "RED",
  PURPLE = "PURPLE"
}

interface RideBadgeStatusProps {
  label?: string;
  color: RideBadgeColorsEnum;
  variant?: RideBadgeVariantsEnum;
  dataTestId?: string;
  sizeVariant?: RideBadgeSizeVariant;
  iconVariant?: RideBadgeIconEnum;
  hasAddIconVariant?: boolean;
}

export const RideBadgeStatus = ({
  label = "",
  dataTestId = "ride-badge-status",
  color = RideBadgeColorsEnum.SUCCESS,
  variant = RideBadgeVariantsEnum.OUTLINED,
  sizeVariant = RideBadgeSizeVariant.MEDIUM,
  iconVariant,
  hasAddIconVariant = false
}: RideBadgeStatusProps) => {
  return (
    <span
      data-testid={dataTestId}
      className={`ride-badge-status ${
        sizeVariant === RideBadgeSizeVariant.SMALL ? "ride-badge-status__small" : ""
      } ride-badge-status__${color.toLowerCase()}__${variant.toLowerCase()}`}>
      {iconVariant && (
        <span
          data-testid={`${dataTestId}__icon-variant`}
          className={`ride-badge-status__icon ride-badge-status__icon__${iconVariant.toLowerCase()}`}>
          <StatusIcon />
        </span>
      )}
      {hasAddIconVariant && (
        <span className={"ride-badge-status__add-icon"}>
          <AddIcon />
        </span>
      )}
      {label}
    </span>
  );
};
