import { CompanyTestType } from "../../../global-query-types";
import {
  NomineeCompanyDetails_companyById_adminServiceInvoiceData,
  NomineeCompanyDetails_companyById_orders,
  NomineeCompanyDetails_companyById_wepaServiceInvoiceData
} from "../../api/queries/graphql/NomineeCompanyDetails";

export interface ClientTagsPersonInput {
  ownedWallet?: { userWallet: { id: string } | null } | null;
  userWallet?:
    | {
        id: string;
        tanganyWallets?: any[] | null;
      }[]
    | null;
}

export interface ClientTagsCompanyInput {
  wepaId?: number | null;
  adminService?: { id?: string | null; tier?: string | null } | null;
  companyCreation?: { id?: string | null } | null;
  wepaService?: { id?: string | null } | null;
  testCompany?: CompanyTestType | null;
  orders?: Omit<NomineeCompanyDetails_companyById_orders, "__typename">[] | null;
  adminServiceInvoiceData?: Omit<
    NomineeCompanyDetails_companyById_adminServiceInvoiceData,
    "__typename"
  > | null;
  wepaServiceInvoiceData?: Omit<
    NomineeCompanyDetails_companyById_wepaServiceInvoiceData,
    "__typename"
  > | null;
}

export const isWePaCompany = (client?: ClientTagsCompanyInput | null): boolean => {
  return !!(client?.wepaId || client?.wepaService?.id);
};

export const isAdminServiceUser = (client?: ClientTagsCompanyInput | null): boolean => {
  return !!(client ?? {})["adminService"];
};

export const isCreationServiceUser = (client?: ClientTagsCompanyInput | null): boolean => {
  return !!(client ?? {})["companyCreation"];
};

export const isShelfCompany = (client?: ClientTagsCompanyInput): boolean => {
  return (client ?? {})["testCompany"] === CompanyTestType.Shelf;
};

export const isTestCompany = (client?: ClientTagsCompanyInput): boolean => {
  return (client ?? {})["testCompany"] === CompanyTestType.Test;
};

export const isEmployeesCompany = (client?: ClientTagsCompanyInput): boolean => {
  return (client ?? {})["testCompany"] === CompanyTestType.Employees;
};

export const isREInvestor = (client?: ClientTagsPersonInput | null): boolean => {
  const userWallet = client?.userWallet?.[0];
  const personHasWallets: boolean = (userWallet?.tanganyWallets?.length ?? 0) > 0;
  const companyHasWallet: boolean = !!client?.ownedWallet;
  return personHasWallets || companyHasWallet;
};
