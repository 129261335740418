import React from "react";
import {
  ClickToCopy,
  ClickToCopyColorVariant as CopyableInformationVariant
} from "sharedComponents/ClickToCopy/ClickToCopy";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";

export interface CopyableInformationProps {
  "data-testid"?: string;
  label: string;
  value: string;
  showValue: boolean;
  variant?: CopyableInformationVariant;
  showCopyButton?: boolean;
}

const moduleName = "copyable-information";

const CopyableInformationWithoutTranslations = ({
  t,
  "data-testid": dataTestId = moduleName,
  label,
  value,
  showCopyButton,
  showValue,
  variant
}: CopyableInformationProps & WithTranslation) => {
  const appliedVariant = variant ?? CopyableInformationVariant.black;

  return (
    <div data-testid={dataTestId} className={`${moduleName} ${moduleName}--${appliedVariant}`}>
      <div data-testid={`${dataTestId}-text`} className={`${moduleName}__text`}>
        <span data-testid={`${dataTestId}-label`} className={`${moduleName}__label`}>
          {label}
          {showValue && `: `}
        </span>
        {showValue && (
          <span data-testid={`${dataTestId}-value`} className={`${moduleName}__value`}>
            {value}
          </span>
        )}
      </div>
      <div className={`${moduleName}__copy-action ml-2`}>
        <ClickToCopy
          data-testid={`${dataTestId}-copy-button`}
          value={value}
          label={showCopyButton ? t("generic:copy-to-clipboard:copy") : undefined}
          showOutline={showCopyButton}
          clickToCopyColorVariant={appliedVariant}
          tooltipContent={t(`generic:low-tax-broker-dashboard.tooltips.copy-to-clipboard`, {
            data: label
          })}
        />
      </div>
    </div>
  );
};

const CopyableInformation = withTranslationReady(["generic"])(
  CopyableInformationWithoutTranslations
);

export { CopyableInformation, CopyableInformationVariant };
