import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const ArrowRight = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid={"arrow-right"}
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 4.5L12.4275 5.54475L18.1125 11.25H3V12.75H18.1125L12.4275 18.4298L13.5 19.5L21 12L13.5 4.5Z"
      fill="currentColor"
    />
  </svg>
);
