import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetEvents as GetEventsType, GetEventsVariables } from "./graphql/GetEvents";

const GET_EVENTS = gql`
  query GetEvents($entityType: EventEntityType!, $entityId: String!) {
    getEvents(entityType: $entityType, entityId: $entityId) {
      id
      actor {
        id
        person {
          id
          firstName
          lastName
        }
      }
      name
      entityType
      entityId
      createdAt
      extra
    }
  }
`;

export const GetEvents = (variables: GetEventsVariables) =>
  useQuery<GetEventsType, GetEventsVariables>(GET_EVENTS, { variables });
