import { selectFieldFor, textFieldFor } from "../commonFields";
import { RealEstateAssetType } from "../../../global-query-types";

export const RealEstateTypeField = ({ t, formik }) =>
  selectFieldFor(t, formik, "realEstateAssetType", "realEstate:type", [
    { title: t("realEstate:land-plot"), value: RealEstateAssetType.LandPlot },
    { title: t("realEstate:redevelopment"), value: RealEstateAssetType.Redevelopment },
    { title: t("realEstate:income-producing"), value: RealEstateAssetType.IncomeProducing }
  ]);

export const RealEstateLabelField = ({ t, formik }) =>
  textFieldFor(t, formik, "label", "realEstate:label", { required: true });

export const RealEstatePropertyValueField = ({ t, formik }) =>
  textFieldFor(t, formik, "propertyValue", "realEstate:property-value", {
    type: "number",
    suffix: "€",
    required: true
  });

export const RealEstateMyShareField = ({ t, formik }) =>
  textFieldFor(t, formik, "percentageOwnership", "realEstate:my-share", {
    type: "number",
    suffix: "%",
    required: true
  });

export const ShortDescriptionField = ({ t, formik }) =>
  textFieldFor(t, formik, "shortDescription", "realEstate:short-description", {
    as: "textarea",
    rows: "5"
  });

export const PhotoLinkField = ({ t, formik }) =>
  textFieldFor(t, formik, "image", "realEstate:photo");

export const LatitudeField = ({ t, formik }) =>
  textFieldFor(t, formik, "latitude", "realEstate:latitude", { type: "number" });

export const LongitudeField = ({ t, formik }) =>
  textFieldFor(t, formik, "longitude", "realEstate:longitude", { type: "number" });
