import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { formatValue } from "common/formatters";
import RequestFeedbackHandler, {
  RequestFeedbackInfo
} from "components/generic/RequestFeedbackHandler";
import RolesSectionOld from "components/nominee/common/roles/RolesSection.old";
import {
  companyRolesFromMemberships,
  RoleEntry
} from "lib/dataAdapters/membershipCategorization/membershipCategorization";
import PersonSectionContainer from "admin/components/PersonSection/PersonSectionContainer";
import { PersonDocumentsSectionContainer } from "admin/components/PersonDocumentsSection/PersonDocumentsSectionContainer";
import { SignupTokenSectionContainer } from "admin/components/SignupTokenSection/SignupTokenSectionContainer";
import { KYCStatusSectionContainer } from "components/nominee/common/KYCStatus/KYCStatusSectionContainer";
import GetUserAddress from "admin/components/GetUserAddress/GetUserAddress";
import { NomineeCreateWalletContainer } from "components/nominee/common/NomineeCreateWalletContainer";
import { FullPersonDetailsQuery_getPersonDetails } from "components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import { NomineeUpdateMembershipVariables } from "components/nominee/NomineeCompany/graphql/NomineeUpdateMembership";
import { NotaryLinksSectionContainer } from "components/nominee/NomineePerson/NotaryLinksSection/NotaryLinksSectionContainer";
import { TwoFactorAuthenticationUserSetupContainer } from "admin/components/TwoFactorAuthenticationUserSetup/TwoFactorAuthenticationUserSetupContainer";

interface Props extends WithTranslation {
  areVotesDisabled: boolean;
  person: FullPersonDetailsQuery_getPersonDetails;
  refetch: () => Promise<void>;
  updateMembership: (data: NomineeUpdateMembershipVariables) => Promise<void>;
  createEmptyCompany: (personId: string) => Promise<void>;
  createEmptyCompanyRequest: RequestFeedbackInfo;
}

const NomineePersonScene = ({
  t,
  person,
  areVotesDisabled,
  updateMembership,
  createEmptyCompany,
  refetch,
  createEmptyCompanyRequest
}: Props) => {
  const { firstName, lastName, user } = person;
  const { email, confirmedAt, role } = user ?? {};
  const memberships = person.memberships ?? [];
  const roles = companyRolesFromMemberships(memberships);
  const personKycEntry = person.kycEntries?.find((kycEntry) => kycEntry.type === "PERSON");
  const personalWallet = person.userWallet?.[0]?.tanganyWallets?.find(
    (wallet) => !wallet.ownerCompany
  );

  const onAddEmptyCompany = async () => {
    await createEmptyCompany(person.id);
    await refetch();
  };

  const handleSubmit = async (values: { roles: RoleEntry[] }) => {
    for (const role of values.roles) {
      if (!role.company) continue;

      await updateMembership({
        companyId: role.company.id,
        memberPersonId: role.person?.id,
        memberCompanyId: undefined,
        isManagingDirector: role.isManagingDirector ?? undefined,
        share: role.share ?? 0,
        votes: role.votes ?? 0
      });
    }

    await refetch();
  };

  return (
    <Container>
      <Row data-testid="person-detail-section">
        <Col>
          <h2>
            {formatValue(firstName)} {formatValue(lastName)}
          </h2>
        </Col>
      </Row>
      {!!person?.user ? (
        <Row>
          <Col>
            <dl>
              <dt>{t("generic:email")}</dt>
              <dd>
                <span data-testid="user-email">{formatValue(email)}</span>
                <span data-testid="user-confirmed">
                  {!!confirmedAt ? (
                    <Badge variant="success" className="p-2 ml-3">
                      Confirmed
                    </Badge>
                  ) : (
                    <Badge variant="secondary" className="p-2 ml-3">
                      Unconfirmed
                    </Badge>
                  )}
                </span>
              </dd>
            </dl>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <PersonSectionContainer t={t} person={person} refetch={refetch} />

      <hr />
      <SignupTokenSectionContainer user={person?.user} refetch={refetch} />

      <hr />
      <TwoFactorAuthenticationUserSetupContainer user={person?.user} />

      <hr />
      <RolesSectionOld
        roles={roles}
        handleSubmit={handleSubmit}
        areVotesDisabled={areVotesDisabled}
        extraEditingActions={
          <RequestFeedbackHandler requests={[createEmptyCompanyRequest]}>
            <Button onClick={onAddEmptyCompany} data-testid="roles-add-empty-company">
              Add empty company
            </Button>
          </RequestFeedbackHandler>
        }
      />

      <hr />
      <PersonDocumentsSectionContainer entity={person!} refetch={refetch} t={t} />

      <hr />
      <KYCStatusSectionContainer kycEntry={personKycEntry} refetch={refetch} />

      <hr />
      <Row data-testid="personal-wallet-section">
        <Col>
          <h2>{t("nominee-dashboard:person.personal-ride-wallet")}</h2>

          <GetUserAddress wallet={personalWallet} />
          <NomineeCreateWalletContainer
            personId={person?.id}
            existingWallet={personalWallet}
            refetch={refetch}
          />
        </Col>
      </Row>

      {role === "Notary" && (
        <Row data-testid="notary-links-section">
          <Col>
            <NotaryLinksSectionContainer person={person} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default withTranslation(["trader-gmbh", "generic", "nominee-dashboard"])(NomineePersonScene);
