import i18next from "i18next";
import bytesFormatter from "bytes";

export const getNumberFormatter = () => {
  let locale = i18next.language;
  if (!locale) {
    locale = "de-DE";
  }
  return new Intl.NumberFormat(locale);
};

export const getSeparator = (separatorType: "group" | "decimal"): string => {
  const defaultSeparator = separatorType === "group" ? "." : ",";
  const numberWithGroupAndDecimalSeparator = 1000.1;

  return (
    getNumberFormatter()
      .formatToParts(numberWithGroupAndDecimalSeparator)
      .find((part) => part.type === separatorType)?.value ?? defaultSeparator
  );
};

export const formatBytes = (bytes: number): string => {
  const thousandsSeparator = getSeparator("group");
  const decimalSeparator = getSeparator("decimal");

  return bytesFormatter(bytes, {
    thousandsSeparator: "@",
    unitSeparator: " "
  })
    .replace(".", decimalSeparator)
    .replaceAll("@", thousandsSeparator);
};
