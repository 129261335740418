import React from "react";
import { LegalPersonType } from "global-query-types";
import {
  RideBadgeColorsEnum,
  RideBadgeSizeVariant,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import { RideBadgeIconEnum } from "uiLibrary/components/RideBadgeStatus/RideBadgeStatus";
import RideIcon, { RideIconModifier } from "uiLibrary/components/RideIcon/RideIcon";
import { RideSize, RideStatus } from "lib/types/types";
import {
  ButtonHeight,
  ButtonRadius,
  ButtonVariant,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

export interface LegalEntityCardProps extends WithTranslation {
  type: LegalPersonType;
  status: RideStatus;
  onPreview: () => void;
  onDelete: { (): void } | null;
  onEdit: () => void;
  text: string;
  editText: string;
}

const LegalEntityCard = ({
  type,
  status,
  onPreview,
  onDelete,
  onEdit,
  text,
  editText,
  t
}: LegalEntityCardProps) => {
  return (
    <div data-testid="legal-entity-card" className="legal-entity-card">
      <div className="legal-entity-card--wrapper">
        <RideBadgeStatus
          color={RideBadgeColorsEnum.GMBH_BADGE_STATUS}
          variant={RideBadgeVariantsEnum.SOLID}
          sizeVariant={RideBadgeSizeVariant.SMALL}
          label={t(`generic:legal-entity-card.status.${status}`)}
          iconVariant={
            status === RideStatus.COMPLETED ? RideBadgeIconEnum.GREEN : RideBadgeIconEnum.ORANGE
          }
        />
        <RideIcon
          onClick={onPreview}
          dataTestId="legal-entity-card-preview"
          name="visible-big"
          size={RideSize.SMALL}
          modifier={RideIconModifier.CLICKABLE_CONTROL}
        />
        {onDelete && (
          <RideIcon
            dataTestId="legal-entity-card-delete"
            onClick={onDelete}
            name="delete-max"
            size={RideSize.SMALL}
            modifier={RideIconModifier.CLICKABLE_CONTROL}
          />
        )}
      </div>
      <div>
        <div data-testid="legal-entity-card-label" className="legal-entity-card--label">
          {t(`generic:legal-entity-card.type.${type}`)}
        </div>
        <div data-testid="legal-entity-card-text" className="legal-entity-card--text">
          {text}
        </div>
      </div>

      <RideButtonDeprecated
        data-testid={"legal-entity-card-edit-button"}
        className="legal-entity-card--button"
        onClick={onEdit}
        variant={ButtonVariant.Secondary}
        type="button"
        radius={ButtonRadius.Soft}
        width={ButtonWidth.FULL}
        height={ButtonHeight.LARGE}>
        {editText}
      </RideButtonDeprecated>
    </div>
  );
};

export default withTranslationReady(["generic"])(LegalEntityCard);
