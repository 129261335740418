import React from "react";
import { WithTranslation } from "react-i18next";
import { BankAccountDeleteModalContainer } from "../../../../sharedComponents/BankAccountDeleteModal/BankAccountDeleteModalContainer";
import { getBankAccountById_getBankAccountById } from "../../../../lib/api/queries/graphql/getBankAccountById";
import BankAccountForm from "../BankAccountForm/BankAccountForm";
import { FormikProvider, useFormik } from "formik";
import RequestFeedbackForm from "../../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { BankType } from "../../../../global-query-types";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import { ClientSelectOption } from "../../ClientSelect/ClientSelect";
import { ButtonTrash } from "sharedComponents/Buttons/ButtonTrash/ButtonTrash";

export interface EditBankAccount extends WithTranslation {
  bankAccount: getBankAccountById_getBankAccountById;
  onRefresh: () => Promise<void>;
  onEdit: (vars: {
    bankProvider: BankType;
    companyId: string;
    iban: string;
    name: string;
    id: string;
  }) => Promise<void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
  companyOptions: ClientSelectOption[];
}

const EditBankAccount = ({
  companyOptions,
  bankAccount,
  onEdit,
  onRefresh,
  requests,
  t
}: EditBankAccount) => {
  const formik = useFormik({
    initialValues: {
      iban: bankAccount.iban,
      name: bankAccount.name,
      companyId: bankAccount.company?.id,
      bank: bankAccount.bankProvider
    },
    onSubmit: async (values) => {
      await onEdit({
        name: values.name ?? "",
        bankProvider: values.bank,
        companyId: values.companyId ?? "",
        iban: values.iban ?? "",
        id: bankAccount.id
      });
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <div className="edit-bank-account admin-service-modal__wrapper">
      <div
        className="admin-service-modal__title d-flex justify-content-center"
        data-testid="modal-title-existing">
        <span className="text-black">{t("client:banking.editBankAccount.title")}</span>
      </div>
      <div className="mt-35">
        <div className="ride-form ride-form--label rounded-sm px-md-55 pt-md-5 pb-md-55">
          <FormikProvider value={formik}>
            <RequestFeedbackForm
              requests={requests}
              submitLabel={t("client:banking.editBankAccount.submitLabel")}>
              <BankAccountForm formik={formik} companyOptions={companyOptions} />
            </RequestFeedbackForm>
          </FormikProvider>

          <div className="edit-bank-account__delete mt-3">
            <BankAccountDeleteModalContainer
              id={bankAccount.id}
              name={bankAccount.name}
              onRefresh={onRefresh}
              children={(setVisibility) => {
                return (
                  <ButtonTrash
                    data-testid="bank-account-delete"
                    onClick={() => setVisibility(true)}>
                    {t("client:banking.editBankAccount.delete")}
                  </ButtonTrash>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady(["client"])(EditBankAccount);
