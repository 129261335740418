import { RemoteStorageNode } from "./toRemoteStorageNode";
import { StorageNodeType } from "../../global-query-types";

export const sortRemoteStorageNodeByFilesFirst = <T extends RemoteStorageNode>(nodes: T[]): T[] =>
  nodes.sort((a, b) => {
    const aIsFolder = a.type === StorageNodeType.FOLDER;
    const bIsFolder = b.type === StorageNodeType.FOLDER;

    if (aIsFolder === bIsFolder) {
      return 0;
    }

    return aIsFolder ? 1 : -1;
  });
