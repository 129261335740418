import { TFunction } from "i18next";
import React from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { ActionableTask } from "../../../../common/types/ProcessStatesTypes";
import TextLinkAsButton from "../../../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";

interface TaskProps {
  task: ActionableTask;
  t: TFunction;
  handleModalOpen: (task: ActionableTask) => void;
}

export const TaskToComplete = ({ task, t, handleModalOpen }: TaskProps) => {
  return (
    <ListGroup.Item
      className={`tasks-list__client-task tasks-list__client-task--${task.state}`}
      data-testid="task-to-complete"
      onClick={() => handleModalOpen(task)}>
      <Row
        noGutters
        className={`flex-wrap flex-md-nowrap tasks-list__client-task__container tasks-list__client-task__container--${task.state}`}>
        <Col xs={12} md={6} data-testid="task-title" className="tasks-list__client-task__title">
          {t(`company-founding-steps:tasks.name.${task.ref}`)}
        </Col>
        <Col
          xs={12}
          md={6}
          data-testid="task-state"
          className={`flex-grow-1 flex-md-grow-0 tasks-list__state tasks-list__state--${task.state}`}>
          {t(`company-founding-steps:tasks-states.${task.state}`)}
        </Col>
      </Row>

      <Button className="tasks-list__arrow" variant="link" data-testid={`task-arrow-${task.ref}`} />
    </ListGroup.Item>
  );
};

export const TaskToBeDone = ({ task, t, handleModalOpen }: TaskProps) => {
  return (
    <ListGroup.Item
      className={`tasks-list__client-task tasks-list__client-task--${task.state}`}
      data-testid="task-to-be-done"
      onClick={() => handleModalOpen(task)}>
      <Row
        noGutters
        className={`flex-wrap flex-md-nowrap tasks-list__client-task__container tasks-list__client-task__container--${task.state}`}>
        <Col xs={12} md={6} data-testid="task-title" className="tasks-list__client-task__title">
          {t(`company-founding-steps:tasks.name.${task.ref}`)}
        </Col>
        <Col xs={12} md={6}>
          <Row className="mt-n1" noGutters>
            <Col
              xs={8}
              data-testid="task-state"
              className={`flex-grow-1 flex-md-grow-0 tasks-list__state tasks-list__state--${task.state}`}>
              {t(`company-founding-steps:tasks-states.${task.state}`)}
            </Col>
            <Col>
              <div className="tasks-list__action">
                <TextLinkAsButton data-testid="task-change" className="tasks-list__edit-link">
                  {t("company-founding-steps:state-change")}
                </TextLinkAsButton>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export const TaskInProgress = ({ task, t, handleModalOpen }: TaskProps) => (
  <ListGroup.Item
    className={`tasks-list__client-task tasks-list__client-task--${task.state}`}
    data-testid="task-in-progress"
    onClick={() => handleModalOpen(task)}>
    <Row
      noGutters
      className={`flex-wrap flex-md-nowrap tasks-list__client-task__container tasks-list__client-task__container--${task.state}`}>
      <Col xs={12} md={6} data-testid="task-title" className="tasks-list__client-task__title">
        {t(`company-founding-steps:tasks.name.${task.ref}`)}
      </Col>
      <Col
        xs={12}
        md={6}
        data-testid="task-state"
        className={`flex-grow-1 flex-md-grow-0 mt-n1 mt-md-0 tasks-list__state tasks-list__state--${task.state}`}>
        {t(`company-founding-steps:tasks-states.${task.state}`)}
      </Col>
    </Row>
  </ListGroup.Item>
);

export const OtherTask = ({ task, t }: { task: ActionableTask; t: TFunction }) => {
  return (
    <ListGroup.Item
      className={`tasks-list__client-task tasks-list__client-task--${task.state}`}
      data-testid="other-task">
      <Row
        noGutters
        className={`flex-wrap flex-md-nowrap tasks-list__client-task__container tasks-list__client-task__container--${task.state}`}>
        <Col xs={12} md={6} data-testid="task-title" className="tasks-list__client-task__title">
          {t(`company-founding-steps:tasks.name.${task.ref}`)}
        </Col>
        <Col
          xs={12}
          md={6}
          data-testid="task-state"
          className={`flex-grow-1 flex-md-grow-0 mt-n1 mt-md-0 tasks-list__state tasks-list__state--${task.state}`}>
          {t(`company-founding-steps:tasks-states.${task.state}`)}
        </Col>
      </Row>
    </ListGroup.Item>
  );
};
