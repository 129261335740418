import React, { useState } from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { CompanyAssets } from "../../../global-query-types";

export interface CompanyAssetsTileProps extends WithTranslation {
  option: CompanyAssets;
  onClick?: (option: CompanyAssets, state: boolean) => void;
  initialState?: boolean;
}

const CompanyAssetsTile = ({
  option,
  onClick,
  initialState = false,
  t
}: CompanyAssetsTileProps) => {
  const [selected, setSelected] = useState(initialState);

  const handleClick = () => {
    setSelected(!selected);
    onClick?.(option, !selected);
  };

  return (
    <div
      className={`company_assets_tile ${selected ? "company_assets_tile--selected" : ""}`}
      data-testid="company-assets-tile"
      onClick={handleClick}>
      <div
        className={`company_assets_tile__icon company_assets_tile__icon--${option}`}
        data-testid="tile-icon"
      />
      <div className="company_assets_tile__label" data-testid="tile-label">
        {t(`shared-components:tiles.businessPurpose.${option}`)}
      </div>
    </div>
  );
};

export default withTranslationReady(["shared-components"])(CompanyAssetsTile);
