import React, { useState } from "react";
import { LegalForm } from "global-query-types";
import { debounce } from "lodash";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { companyName } from "lib/formatters/companyFormatter";
import { InputAutoComplete, RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { useRideField } from "lib/hooks/useRideField";
import { WithTranslation } from "react-i18next";
import { ExternalCompanyDataByName_externalCompanyDataByName_companies } from "lib/api/queries/graphql/ExternalCompanyDataByName";
import { RideDropdown, RideDropdownItem } from "uiLibrary/designSystem/RideDropdown/RideDropdown";
import { ApolloQueryResult } from "apollo-client/core/types";

export interface ExternalCompanySearchInputProps {
  inputName?: string;
  onSelectCompany?: (
    company: ExternalCompanyDataByName_externalCompanyDataByName_companies
  ) => void;
  searchExternalResult: (name: string) => Promise<ApolloQueryResult<any>>;
}

interface SearchResultDropdownProps {
  companies: ExternalCompanyDataByName_externalCompanyDataByName_companies[];
  onSelectItem: (company: ExternalCompanyDataByName_externalCompanyDataByName_companies) => void;
  inputName: string;
}

const ExternalCompanySearchInput = ({
  t,
  inputName = "company-search",
  onSelectCompany,
  searchExternalResult
}: ExternalCompanySearchInputProps & WithTranslation) => {
  const [canShowResult, setCanShowResult] = useState(false);
  const [companies, setCompanies] = useState<
    ExternalCompanyDataByName_externalCompanyDataByName_companies[]
  >([]);

  const onInputHandler = (e) => {
    queryCompaniesByName(e.target.value);
  };

  const queryCompaniesByName = debounce(async (name: string) => {
    const externalResult = await searchExternalResult(name);

    if (externalResult.data) {
      setCompanies(externalResult.data?.externalCompanyDataByName?.companies ?? []);
      setCanShowResult(true);
    }
  }, 200);

  return (
    <div data-testid="ride-company-search" className={"external-company-search-input"}>
      <RideTextInput
        name={inputName}
        placeholder={t("generic:company-search-placeholder")}
        onInput={onInputHandler}
        onFocus={() => setCanShowResult(true)}
        onBlur={() => setCanShowResult(false)}
        autoComplete={InputAutoComplete.Off}
      />
      {canShowResult && companies && companies.length > 0 && (
        <SearchResultDropdown
          inputName={inputName}
          companies={companies}
          onSelectItem={(company) => {
            onSelectCompany?.(company);
            setCanShowResult(false);
          }}
        />
      )}
    </div>
  );
};

const SearchResultDropdown = ({
  companies,
  onSelectItem,
  inputName
}: SearchResultDropdownProps) => {
  const field = useRideField(inputName);

  return (
    <RideDropdown
      className={"external-company-search-input__dropdown"}
      data-testid={`ride-company-search-result`}>
      {companies.map((company) => {
        const { companyName, registration } = company.companyData;
        return (
          <RideDropdownItem
            key={registration}
            onSelect={() => {
              onSelectItem(company);
              field.setValue(companyName);
            }}
            label={formatCompanyName(
              company.companyData.companyName,
              company.companyData.legalForm,
              company.companyData.registration
            )}
          />
        );
      })}
    </RideDropdown>
  );
};

function formatCompanyName(
  name: string,
  legalForm: LegalForm | null,
  registration: string
): string {
  return `${companyName({
    name,
    legalForm
  })} (${registration})`;
}

export default withTranslationReady(["generic"])(ExternalCompanySearchInput);
