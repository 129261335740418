import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const CompanyFoundingIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="company-founding-icon"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5283_55)">
      <path
        d="M22.2803 14.4697L20.0303 12.2197C19.9606 12.1501 19.878 12.0948 19.787 12.0571C19.696 12.0194 19.5985 12 19.5 12C19.4015 12 19.304 12.0194 19.213 12.0571C19.122 12.0948 19.0394 12.1501 18.9698 12.2197L12 19.1894V22.5H15.3106L22.2803 15.5303C22.3499 15.4606 22.4052 15.378 22.4429 15.287C22.4806 15.196 22.5 15.0985 22.5 15C22.5 14.9015 22.4806 14.804 22.4429 14.713C22.4052 14.622 22.3499 14.5394 22.2803 14.4697ZM14.6894 21H13.5V19.8106L17.25 16.0606L18.4394 17.25L14.6894 21ZM19.5 16.1894L18.3106 15L19.5 13.8106L20.6894 15L19.5 16.1894Z"
        fill="currentColor"
      />
      <path d="M13.5 12H6V13.5H13.5V12Z" fill="currentColor" />
      <path d="M15 7.5H6V9H15V7.5Z" fill="currentColor" />
      <path
        d="M19.5 3C19.4995 2.60232 19.3414 2.22105 19.0602 1.93984C18.779 1.65864 18.3977 1.50046 18 1.5H3C2.60232 1.50046 2.22105 1.65864 1.93985 1.93984C1.65864 2.22105 1.50046 2.60232 1.5 3V12.75C1.49838 14.2458 1.90413 15.7137 2.6737 16.9964C3.44326 18.279 4.54759 19.3278 5.86816 20.0303L9.75 22.1001V20.4L6.57421 18.7068C5.49372 18.132 4.59014 17.2738 3.96046 16.2244C3.33077 15.175 2.99874 13.9739 3 12.75V3H18V9.75H19.5V3Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5283_55">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
