import { Col, Row } from "react-bootstrap";
import React from "react";
import SPVCompanies from "../../../components/nominee/NomineeSpv/SPVCompanies";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

const InvestmentClubSpvs = ({ t }: WithTranslation) => {
  return (
    <>
      <Row noGutters>
        <Col>
          <h2>{t("nominee-dashboard:investment-club-spvs.heading")}</h2>
        </Col>
      </Row>

      <Row noGutters>
        <Col>
          <SPVCompanies />
        </Col>
      </Row>
    </>
  );
};

export default withTranslationReady(["nominee-dashboard"])(InvestmentClubSpvs);
