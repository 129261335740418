import React from "react";
import { FeatureModalContent } from "../../../../components/client/company/components/FeatureModalContent";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

interface AddNewGmbHProps extends WithTranslation {
  onOrder: () => void;
}

const AddNewGmbH = ({ t, onOrder }: AddNewGmbHProps) => {
  const dangerouslySetFeatures = [
    {
      __html: t(`addNewGmbH:features.${1}`),
      index: 1
    },
    {
      __html: t(`addNewGmbH:features.${2}`),
      index: 2
    },
    {
      __html: t(`addNewGmbH:features.${3}`),
      index: 3
    },
    {
      __html: t(`addNewGmbH:features.${4}`),
      index: 4
    },
    {
      __html: t(`addNewGmbH:features.${5}`),
      index: 5
    },
    {
      __html: t(`addNewGmbH:features.${6}`),
      index: 6
    },
    {
      __html: t(`addNewGmbH:features.${7}`),
      index: 7
    }
  ];

  return (
    <div className="add-new-gmbh">
      <div className="add-new-gmbh__title" data-testid={`modal-title-new`}>
        {t("addNewGmbH:title")}
      </div>

      <FeatureModalContent
        dangerouslySetFeatures={dangerouslySetFeatures}
        price={t("addNewGmbH:price")}
        priceType={t("addNewGmbH:priceType")}
        ctaAction={onOrder}
      />
    </div>
  );
};

export default withTranslationReady(["client", "addNewGmbH"])(AddNewGmbH);
