import React, { ReactNode } from "react";
import { Field } from "formik";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";

interface RideCheckboxProps {
  name: string;
  label: ReactNode;
  variant?: RideCheckboxVariant;
  isReadonly?: boolean;
  renderValidationErrors?: boolean;
  onClick?: () => Promise<void>;
  className?: string;
}

export enum RideCheckboxVariant {
  Heavy = "heavy",
  Light = "light"
}

export const RideCheckbox = ({
  name,
  label,
  variant = RideCheckboxVariant.Heavy,
  isReadonly,
  renderValidationErrors = true,
  onClick,
  className
}: RideCheckboxProps) => {
  return (
    <>
      <label
        data-testid={`${name}-checkbox`}
        className={`ride-checkbox-v2 ride-checkbox-v2--${variant} ${
          isReadonly ? "ride-checkbox-v2--readonly" : ""
        } ${className ?? ""}`}
        onClick={onClick}>
        <Field type="checkbox" name={name} disabled={isReadonly} />
        <span data-testid={`${name}-label`} className="label">
          {label}
        </span>
      </label>
      {renderValidationErrors && <ErrorMessageWithT name={name} extra={{}} />}
    </>
  );
};
