import React from "react";
import ClubDealDetailsCard from "../ClubDealDetailsCard/ClubDealDetailsCard";
import { GetClubDealById_getClubDealById } from "../../../../lib/api/queries/graphql/GetClubDealById";

export interface ClubDealDetailsProps {
  clubDeal: GetClubDealById_getClubDealById;
  onExpressInterest: () => Promise<void>;
}

export const ClubDealDetails = ({ clubDeal, onExpressInterest }: ClubDealDetailsProps) => {
  return (
    <div className="club-deal-details">
      <ClubDealDetailsCard
        image={clubDeal.asset?.realEstate?.image}
        name={clubDeal.asset?.realEstate?.label ?? ""}
        alreadyFinanced={clubDeal?.financedPercentage ?? 0}
        description={clubDeal?.asset?.realEstate?.shortDescription ?? ""}
        onExpressInterest={onExpressInterest}
        maxInvestment={clubDeal?.maxInvestmentAmount ?? 0}
        minInvestment={clubDeal?.minInvestmentAmount ?? 0}
        targetYield={clubDeal?.targetYield ?? 0}
      />
    </div>
  );
};
