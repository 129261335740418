import * as React from "react";
import { useQuery } from "react-apollo";
import { TOKENIZED_DETAILS_BY_COMPANY_ID } from "./Queries";
import {
  tokenizedDetailsByCompanyId,
  tokenizedDetailsByCompanyIdVariables
} from "./graphql/tokenizedDetailsByCompanyId";

export const GetTokenizedInfoByCompanyId = (companyId: string) => {
  const [firstRender, setFirstRender] = React.useState(true);

  const tokenizedInvestmentDetails = useQuery<
    tokenizedDetailsByCompanyId,
    tokenizedDetailsByCompanyIdVariables
  >(TOKENIZED_DETAILS_BY_COMPANY_ID, {
    variables: { companyId: companyId },
    fetchPolicy: firstRender ? "network-only" : undefined,
    onCompleted: () => setFirstRender(false)
  });
  return tokenizedInvestmentDetails;
};
