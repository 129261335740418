import React from "react";
import { withTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { CREATE_WEPA_SUB_CONTRACT } from "components/nominee/ThirdPartyTaxAdvisorsList/Mutations";
import {
  createWepaSubContract,
  createWepaSubContractVariables
} from "components/nominee/ThirdPartyTaxAdvisorsList/graphql/createWepaSubContract";
import { getThirdPartyTaxAdvisorsAndPartners } from "components/nominee/ThirdPartyTaxAdvisorsList/graphql/getThirdPartyTaxAdvisorsAndPartners";
import ThirdPartyTaxAdvisorCompaniesList from "./ThirdPartyTaxAdvisorCompaniesList";

export const ThirdPartyTaxAdvisorCompaniesListContainer = ({
  refetch,
  data
}: {
  refetch: () => Promise<void>;
  data: getThirdPartyTaxAdvisorsAndPartners | undefined;
}) => {
  const [createWepaSubContractMutation, createWepaSubContractRequest] = useMutation<
    createWepaSubContract,
    createWepaSubContractVariables
  >(CREATE_WEPA_SUB_CONTRACT);

  const onPartnerAssignment = async ({
    thirdPartyTaxCompanyId,
    partnerTaxCompanyId
  }: {
    thirdPartyTaxCompanyId: string;
    partnerTaxCompanyId: string;
  }) => {
    await createWepaSubContractMutation({
      variables: { thirdPartyTaxCompanyId, partnerTaxCompanyId }
    });
  };

  return (
    <RequestFeedbackHandler requests={[createWepaSubContractRequest]}>
      <ThirdPartyTaxAdvisorCompaniesList
        thirdPartyTaxAdvisorCompanies={data?.ThirdPartyTaxService?.companies}
        partnerTaxAdvisorCompanies={data?.PartnerTaxCompany?.companies}
        onPartnerAssignment={onPartnerAssignment}
        refetch={refetch}
      />
    </RequestFeedbackHandler>
  );
};

export default withTranslation(["generic"])(ThirdPartyTaxAdvisorCompaniesListContainer);
