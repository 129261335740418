import { FolderSelectOption } from "client/components/Files/FolderSelectField/FolderSelectField";
import { StorageConfigStructure } from "components/client/company/components/GetStorageConfig";

export function storageStructureToFolderOptions(
  nodes: StorageConfigStructure[],
  path = ""
): FolderSelectOption[] {
  let result: FolderSelectOption[] = [];
  for (const node of nodes) {
    const canCreate = node.permissions.client?.create === true;
    const label = path ? `${path}/${node.name}` : node.name;
    result = [
      ...result,
      {
        value: node.ref,
        label,
        isDisabled: !canCreate
      },
      ...storageStructureToFolderOptions(node.subFolders, label)
    ];
  }
  return result;
}
