import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LegalPersonType } from "global-query-types";
import React, { useState } from "react";
import LabeledRadioButton from "sharedComponents/LabeledRadioButton/LabeledRadioButton";
import LegalEntityForm from "./LegalEntityForm/LegalEntityForm";
import NaturalPersonForm from "./NaturalPersonForm/NaturalPersonForm";
import { Step } from "lib/models/client/order/Step";
import { Chapter } from "lib/models/client/order/Chapter";
import ShareholderDetailsSectionTitle from "../ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

interface EntityGeneralInformationProps {
  entityKey: "shareholder" | "employee";
  canSelectShareholderType: boolean;
}

const EntityGeneralInformationComponent = (
  props: StepComponentProps & WithTranslation & EntityGeneralInformationProps
) => {
  const { order, currentStep, entityKey, canSelectShareholderType } = props;

  const initialEntityType =
    order.extra[`${entityKey}s`]?.[currentStep?.chapter?.index ?? 0]?.[`${entityKey}Type`] ??
    LegalPersonType.Person;

  const [entityType, setEntityType] = useState(initialEntityType);

  const updateType = async (value: string | number) => {
    function legalEntitySteps(chapter: Chapter) {
      return [
        new Step({ key: "shareholder-general-information" }, chapter),
        new Step({ key: "shareholder-company-address" }, chapter),
        new Step({ key: "shareholder-company-representative" }, chapter),
        new Step({ key: "shareholder-representative-citizenship" }, chapter),
        new Step({ key: "shareholder-representative-address" }, chapter),
        new Step({ key: "shareholder-review", completed: true }, chapter)
      ];
    }

    function naturalPersonSteps(chapter: Chapter) {
      return [
        new Step({ key: "shareholder-general-information" }, chapter),
        new Step({ key: "shareholder-citizenship-information" }, chapter),
        new Step({ key: "shareholder-residential-address" }, chapter),
        new Step({ key: "shareholder-review", completed: true }, chapter)
      ];
    }

    if (entityType !== LegalPersonType[value]) {
      const chapter = props.currentStep.chapter;

      chapter.steps =
        LegalPersonType[value] === LegalPersonType.Company
          ? legalEntitySteps(chapter)
          : naturalPersonSteps(chapter);

      await props.saveData(
        {
          ...props.order.extra,
          ...chapter.order.serialize()
        },
        true
      );
    }

    setEntityType(LegalPersonType[value]);
  };

  const headlineContent = (entityType: string) => (
    <div className="entity-general-information">
      <ShareholderDetailsSectionTitle
        text={props.t(
          `client:shareholder-details.${
            entityType === LegalPersonType.Person ? "person-" : ""
          }general-information.title`
        )}
        prefix={`${currentStep?.disposition}`}
        isCompleted={currentStep.completed}
      />

      {entityKey === "shareholder" && canSelectShareholderType && (
        <LabeledRadioButton
          className="entity-general-information__legal-person-switcher"
          text={props.t("client:shareholder-details.general-information.shareholder-type")}
          name="shareholderType"
          options={[
            {
              id: LegalPersonType.Person.toString(),
              label: props.t("client:shareholder-details.general-information.natural-person")
            },
            {
              id: LegalPersonType.Company.toString(),
              label: props.t("client:shareholder-details.general-information.legal-entity")
            }
          ]}
          onChange={updateType}
          initialValue={entityType}
        />
      )}
    </div>
  );

  return entityType === LegalPersonType.Person ? (
    <NaturalPersonForm
      {...props}
      headlineContent={headlineContent(entityType)}
      entityKey={entityKey}
    />
  ) : (
    <LegalEntityForm {...props} headlineContent={headlineContent(entityType)} />
  );
};

export const EntityGeneralInformation = (
  entityKey: "shareholder" | "employee",
  canSelectShareholderType: boolean = false
) =>
  withTranslationReady(["generic", "client"])((props: StepComponentProps & WithTranslation) => (
    <EntityGeneralInformationComponent
      {...props}
      entityKey={entityKey}
      canSelectShareholderType={canSelectShareholderType}
    />
  ));
