import React, { FC } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import GuestHeaderDeprecated from "../../../guest/components/GuestHeaderDeprecated/GuestHeaderDeprecated";
import AdminServicePostOrderBanner from "../../generic/Trader-GmbH/AdminServicePostOrderBanner";
import { FlowTypeEnum } from "../../../global-query-types";
import { VirtualPageView } from "../../../common/VirtualPageView";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { OrderConfirmationPage } from "client/components/OrderConfirmationPage/OrderConfirmationPage";

interface AdminServiceStatusPageLayoutProps extends WithTranslation {
  flowType: FlowTypeEnum;
}

export const AdminServiceStatusPageLayout: FC<AdminServiceStatusPageLayoutProps> = ({
  t,
  flowType
}) => {
  return (
    <div className="trader-gmbh">
      <PageMetaData title={t("admin-service:page-title")} />
      <GuestHeaderDeprecated />
      <AdminServicePostOrderBanner />
      <OrderConfirmationPage t={t} currentFlowType={flowType} />
      <VirtualPageView path={undefined} />
    </div>
  );
};

export default withTranslation([
  "trader-gmbh",
  "admin-service",
  "generic",
  "errorCodes",
  "company-creation"
])(AdminServiceStatusPageLayout);
