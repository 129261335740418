import React from "react";
import { FullPersonDetailsQuery_getPersonDetails_notaryLinks } from "../graphql/FullPersonDetailsQuery";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";

interface NotaryLinkListProps {
  links: FullPersonDetailsQuery_getPersonDetails_notaryLinks[];
  t: TFunction;
}

export const NotaryLinksComponent = ({ links, t }: NotaryLinkListProps) => {
  const sortedLinks = links.sort((linkA, linkB) =>
    linkA.notaryDocumentType > linkB.notaryDocumentType ? 1 : -1
  );

  return (
    <>
      {links.length === 0 ? (
        <div data-testid="empty-links-placeholder">
          {t("nominee-dashboard:person.notary-links.empty-placeholder")}
        </div>
      ) : (
        <div data-testid="view-notary-links">
          <Row>
            {sortedLinks.map((notaryLink) => (
              <Col xs={6} md={3} data-testid="notary-link" key={notaryLink.notaryDocumentType}>
                <Row>
                  <Col data-testid="notary-link-type">
                    <b>
                      {t(`nominee-dashboard:person.notary-links.${notaryLink.notaryDocumentType}`)}
                    </b>
                  </Col>
                </Row>
                <Row>
                  <Col data-testid="notary-link-url">{notaryLink.link}</Col>
                </Row>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};
