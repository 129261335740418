import React from "react";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "../../../OnboardingStepCard/OnboardingStepCard";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { RideDropdownSelect } from "../../../../../uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import {
  FiscalYearStartSchema,
  getNumericOptions,
  numberOfDaysForMonth
} from "../../../ChaptersOrderLayout/AdminServiceOnboardingForm/FiscalYearStart/FiscalYearStart";
import { useRideFormik } from "../../../../../lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import { GmbHDetails } from "../../../../../lib/models/client/GmbHDetails";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../../../uiLibrary/designSystem/RideButton/RideButton";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { TFunction } from "i18next";

interface FiscalYearStartingDateProps {
  stepNumber: number;
  status: OnboardingStepCardStatus;
  gmbh: GmbHDetails;
  saveFiscalYearStartingDate: (fiscalYear: string) => void;
}

const FiscalYearStartingDate = ({
  t,
  stepNumber,
  gmbh,
  status,
  saveFiscalYearStartingDate
}: FiscalYearStartingDateProps & WithTranslation) => {
  const [day, month] = gmbh?.fiscalYear?.split(".") ?? [];

  const formik = useRideFormik({
    initialValues: {
      month,
      day
    },
    onSubmit: async (values) => {
      await saveFiscalYearStartingDate(`${values.day}.${values.month}`);
    },
    validationSchema: FiscalYearStartSchema(t)
  });

  const daysInMonth = numberOfDaysForMonth(formik.values.month);

  const monthOptions = getMonthOptions(t);
  const dayOptions = getNumericOptions(daysInMonth);

  formik.values.day =
    Number(formik.values.day) > daysInMonth ? daysInMonth.toString() : formik.values.day;

  return (
    <OnboardingStepCard
      data-testid={`select-starting-fiscal-year-date`}
      number={stepNumber}
      status={status}
      title={t("generic:wepa-direct.steps.fiscal-year-starting-date.title")}>
      <FormikProvider value={formik}>
        <Form>
          <div className="fiscal-year-starting-date">
            <Typography category={"Headline"} size={300} weight={"Heavy"}>
              {t("generic:wepa-direct.steps.fiscal-year-starting-date.description")}
            </Typography>
            <div className="fiscal-year-starting-date__fields">
              <RideDropdownSelect
                label={t("generic:day")}
                name={"day"}
                options={dayOptions}
                disabled={!!gmbh?.fiscalYear}
                isSearchable
              />
              <RideDropdownSelect
                label={t("generic:month")}
                name={"month"}
                options={monthOptions}
                disabled={!!gmbh?.fiscalYear}
                isSearchable
              />
            </div>
            <RideButton
              type={"submit"}
              className={"fiscal-year-starting-date__button"}
              data-testid={"select-and-continue"}
              label={t("generic:select-additional-fiscal-years.select-and-continue")}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
            />
          </div>
        </Form>
      </FormikProvider>
    </OnboardingStepCard>
  );
};

const twoDigits = (num: number) => num.toString().padStart(2, "0");

export const getMonthOptions = (t: TFunction) =>
  Array.from({ length: 12 }, (_, i) => {
    const formattedNumber = twoDigits(i + 1);
    return { value: formattedNumber, label: t(monthDigitToName[formattedNumber]) };
  });

export const monthDigitToName = {
  "01": "generic:january",
  "02": "generic:february",
  "03": "generic:march",
  "04": "generic:april",
  "05": "generic:may",
  "06": "generic:june",
  "07": "generic:july",
  "08": "generic:august",
  "09": "generic:september",
  "10": "generic:october",
  "11": "generic:november",
  "12": "generic:december"
};

export default withTranslationReady(["generic"])(FiscalYearStartingDate);
