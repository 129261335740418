import { useEffect, useState } from "react";
import { create } from "ipfs-http-client";
import isIPFS from "is-ipfs";

export interface IpfsConfig {
  protocol: string;
  host: string;
  port: number;
}

let ipfs: any = null;
let ipfsVersion = "";
let ipfsMessage = "";

export default function IPFSConfig(config: IpfsConfig) {
  const [isIpfsReady, setIpfsReady] = useState(Boolean(ipfs));
  const [ipfsError, setIpfsError] = useState("");

  useEffect(() => {
    async function initIpfs() {
      if (ipfs !== null) return;
      ipfsMessage = "Checking IPFS gateway...";

      try {
        ipfs = create(config);
        const version = await ipfs.version();
        ipfsVersion = version.version;
        ipfsMessage = `Connected to IPFS`;
      } catch (error) {
        let { message } = error;
        setIpfsError(`IPFS connection error: ${message}`);
        setIpfsReady(false);
        return;
      }
      setIpfsReady(Boolean(await ipfs.version()));
    }

    initIpfs();
  }, [config]);

  useEffect(() => {
    return function cleanup() {
      if (ipfs) {
        setIpfsReady(false);
        ipfs = null;
        ipfsMessage = "";
        ipfsVersion = "";
        setIpfsError("");
      }
    };
  }, []);
  return { ipfs, ipfsVersion, isIpfsReady, ipfsError, ipfsMessage, isIPFS };
}
