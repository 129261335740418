import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import LoadingAlert from "../../generic/LoadingAlert";
import { GetSPVCompaniesWithTokenizedInvestment } from "./GetSPVCompaniesWithTokenizedInvestment";
import { Formik } from "formik";
import {
  AllowedAmountField,
  SourceWalletSelectField,
  TargetWalletField,
  TokenizedSPVSelectField
} from "./fields";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { useMutation } from "@apollo/react-hooks";
import { APPROVE_TOKEN_TRANSFER } from "./Mutations";
import logger from "../../../common/Logger";
import SuccessAlert from "../../generic/SuccessAlert";
import { ApproveTokenSchema } from "../../../lib/validation/ApproveTokenSchema";

export interface WalletInfo {
  walletProviderId: string | null;
  ownerName: string;
}

interface ApproveFormProps extends WithTranslation {
  wallets: WalletInfo[];
}

const ApproveForm = ({ t, wallets }: ApproveFormProps) => {
  const [show, setShow] = useState(false);
  const showForm = () => setShow(true);
  const hideForm = () => setShow(false);

  const [success, setSuccess] = useState(false);

  const { loading: loadingSPVs, error: errorSPVs, data } = GetSPVCompaniesWithTokenizedInvestment();

  const [approveTokenTransfer, { loading: loadingApprove, error: errorApprove }] =
    useMutation(APPROVE_TOKEN_TRANSFER);

  const loading = loadingSPVs || loadingApprove;
  const error = errorSPVs || errorApprove;

  const tokenizedSPVOptions =
    data?.nomineeCompanies
      ?.filter((c) => !!c.ownedTokenizedInvestment)
      ?.map((c) => ({ title: c.projectName ?? "???", value: c.id })) ?? [];

  const walletOptions = wallets.map((wallet) => ({
    title: wallet.ownerName,
    value: wallet.walletProviderId
  }));

  const initialValues = {
    tokenizedSPV: tokenizedSPVOptions[0]?.value ?? "",
    walletProviderId: walletOptions[0]?.value ?? "",
    targetWallet: "",
    allowedAmount: 0
  };

  const onSubmit = async (values) => {
    try {
      await approveTokenTransfer({ variables: values });
      setSuccess(true);
      setTimeout(hideForm, 250);
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <>
      <Button onClick={showForm} data-testid="approve-button">
        {t("nominee-dashboard:approve")}
      </Button>

      <Modal show={show} onHide={hideForm} data-testid="approve-modal">
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {t("nominee-dashboard:approve")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingAlert t={t} loading={loading} />
          <ErrorAlertLegacy t={t} error={error?.message} />
          <SuccessAlert t={t} success={success} message="nominee-dashboard:approval-success" />

          {data?.nomineeCompanies ? (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={ApproveTokenSchema()}>
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <TokenizedSPVSelectField formik={formik} t={t} options={tokenizedSPVOptions} />
                  <SourceWalletSelectField formik={formik} t={t} options={walletOptions} />
                  <TargetWalletField formik={formik} t={t} />
                  <AllowedAmountField formik={formik} t={t} />

                  <Button className="float-sm-right" type="submit" data-testid="submit">
                    {t("nominee-dashboard:approve-submit")}
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(["nominee-dashboard", "generic"])(ApproveForm);
