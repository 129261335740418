import React from "react";
import { escapeRegExp } from "lodash";
import { Highlight } from "../../uiLibrary/designSystem/Highlight/Highlight";

export interface TextHighlighterProps {
  term: string;
  children: string;
  limit?: number;
}

export const TextHighlighter = ({ children, term, limit = Infinity }: TextHighlighterProps) => {
  if (!term) {
    return <>{children}</>;
  }

  const escapedTerm = escapeRegExp(term);
  const regex = new RegExp(`(${escapedTerm})`, "gi");
  const content = children as string;

  let count = 0;

  return (
    <>
      {content.split(regex).map((part, index) => {
        if (part.match(regex) && count < limit) {
          count++;
          return (
            <Highlight data-testid="search-term-highlight" key={index}>
              {part}
            </Highlight>
          );
        } else {
          return part;
        }
      })}
    </>
  );
};
