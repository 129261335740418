import { emailValidationWithTranslation } from "./EmailSchema";
import { validateUserEmail } from "../api/queries/validateUserEmail";
import { EmailValidity } from "../../global-query-types";
import { TestContext, TestFunction } from "yup";
import { asyncDebounce } from "../timing/asyncDebounce";

const testEmailFunction = async (email: string | undefined, context: TestContext) => {
  if (!email) return true;

  const result = await validateUserEmail(email);

  return result.validity !== EmailValidity.ALREADY_EXISTS;
};

const debouncedTestEmailFunction = asyncDebounce(testEmailFunction, 250);

export const EmailValidatorWithBackend = (t) =>
  emailValidationWithTranslation(t).test(
    "backendValidation",
    t("errorCodes:generic.email-already-exists"),
    debouncedTestEmailFunction as TestFunction<string | undefined, Record<string, any>>
  );
