import React from "react";
import { QueryResult } from "react-apollo";
import BackToMyGmbHsButton from "./BackToMyGmbHsButton";
import { MeQuery } from "lib/api/queries/graphql/MeQuery";
import { GetMe } from "lib/api/queries/MeQuery";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { useApolloClient } from "@apollo/react-hooks";
import { useParams } from "react-router";
import { GetMyCompanyCreationSummarized } from "lib/api/queries/MyCompanyCreationSummarized";

export const BackToMyGmbHsButtonContainer = () => {
  const shadowUserRequest: QueryResult<MeQuery> = GetMe();
  const isShadowUser = shadowUserRequest.data?.me?.isShadow;
  const client = useApolloClient();

  const action = async () => {
    await client.cache.reset();
  };

  const { id } = useParams<{ id?: string }>();

  const query = GetMyCompanyCreationSummarized(id);
  const company =
    query.data?.myCompanyCreation?.adminCompany ?? query.data?.myCompanyCreation?.company;

  return (
    <RequestFeedbackHandler requests={[shadowUserRequest, query]}>
      <BackToMyGmbHsButton
        isShadowUser={isShadowUser ?? false}
        action={action}
        companyId={company?.id}
      />
    </RequestFeedbackHandler>
  );
};
