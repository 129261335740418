import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideFormLayout from "../RideFormLayout/RideFormLayout";
import {
  ButtonHeight,
  ButtonRadius,
  ButtonVariant,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { useHistory } from "react-router-dom";

interface OrderApplicationSubmittedProps extends WithTranslation {
  downloadPdfSummary: () => Promise<void>;
  companyId?: string | null;
}

const OrderApplicationSubmitted = ({
  t,
  downloadPdfSummary,
  companyId
}: OrderApplicationSubmittedProps) => {
  const history = useHistory();
  const module = "order-application-submitted";

  const redirectToLTBSuccessPage = () => {
    history.push(`/client/entities/${companyId}/invest`);
  };

  return (
    <RideFormLayout>
      <div className={module}>
        <div className={`${module}__title`} data-testid="page-title">
          {t("generic:order-application-submitted.title")}
        </div>
        <div className={`${module}__description`} data-testid="page-description">
          {t("generic:order-application-submitted.description")}
        </div>
        <RideButtonDeprecated
          disabled={false}
          className={`${module}__complete-account-setup-button`}
          data-testid="complete-account-setup-button"
          variant={ButtonVariant.PrimaryPastel}
          width={ButtonWidth.FULL}
          height={ButtonHeight.LARGE}
          radius={ButtonRadius.Soft}
          onClick={redirectToLTBSuccessPage}
          isLoaderActive={false}>
          {t("generic:order-application-submitted.complete-button")}
        </RideButtonDeprecated>
      </div>
    </RideFormLayout>
  );
};

export default withTranslationReady(["generic"])(OrderApplicationSubmitted);
