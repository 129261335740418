import { useMutation } from "@apollo/react-hooks";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import React from "react";
import { ApolloQueryResult } from "apollo-client";
import CompanyDetailsSection from "./CompanyDetailsSection";
import { NOMINEE_UPDATE_COMPANY } from "../../../../lib/api/mutations/NOMINEE_UPDATE_COMPANY";
import {
  NomineeUpdateCompany,
  NomineeUpdateCompanyVariables
} from "../../../../lib/api/mutations/graphql/NomineeUpdateCompany";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export interface CompanyDetailsSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

export const CompanyDetailsSectionContainer = ({
  company,
  refetchCompany
}: CompanyDetailsSectionContainerProps) => {
  const [nomineeUpdateCompany, updateRequest] = useMutation<
    NomineeUpdateCompany,
    NomineeUpdateCompanyVariables
  >(NOMINEE_UPDATE_COMPANY);

  const updateCompany = async (variables) => {
    await nomineeUpdateCompany({
      variables
    });

    await refetchCompany();
  };

  return (
    <RequestFeedbackHandler requests={[updateRequest]}>
      <CompanyDetailsSection company={company} onSubmit={updateCompany} />
    </RequestFeedbackHandler>
  );
};
