import { parseCookies } from "../../parsers/parseCookies";
import { getURLParams } from "../../../components/nominee/common/urlParamHelpers";
import { Location } from "history";
import logger from "../../../common/Logger";

export interface UtmParams {
  /** FinanceAds tracking id */
  s_id?: string;
  campaign?: string;
  source?: string;
  term?: string;
  medium?: string;
  content?: string;
}

export class UtmService {
  private rideSId = "s_id";
  private rideUtmCampaign = "utm_campaign";
  private rideUtmContent = "utm_content";
  private rideUtmMedium = "utm_medium";
  private rideUtmSource = "utm_source";
  private rideUtmTerm = "utm_term";

  getUtmParams(location: Location, cookies: string): UtmParams {
    return this.parseUtmParams(location, cookies);
  }

  private parseUtmParams(location: Location, cookies: string): UtmParams {
    const urlUtmParams = getURLParams(location);
    const cookieUtmParams = parseCookies(cookies);

    this.diffLogger(urlUtmParams, cookieUtmParams);

    return {
      s_id: this.tryGetValue(urlUtmParams, cookieUtmParams, this.rideSId),
      campaign: this.tryGetValue(urlUtmParams, cookieUtmParams, this.rideUtmCampaign),
      content: this.tryGetValue(urlUtmParams, cookieUtmParams, this.rideUtmContent),
      medium: this.tryGetValue(urlUtmParams, cookieUtmParams, this.rideUtmMedium),
      source: this.tryGetValue(urlUtmParams, cookieUtmParams, this.rideUtmSource),
      term: this.tryGetValue(urlUtmParams, cookieUtmParams, this.rideUtmTerm)
    };
  }

  private diffLogger(urlUtmParams: Map<string, string>, cookieUtmParams: Map<string, string>) {
    try {
      const arrayOfParams: string[] = [
        this.rideSId,
        this.rideUtmCampaign,
        this.rideUtmContent,
        this.rideUtmMedium,
        this.rideUtmSource,
        this.rideUtmTerm
      ];
      const isDifferent = arrayOfParams.some(
        (paramKey) => urlUtmParams.get(paramKey) !== cookieUtmParams.get(`ride_${paramKey}`)
      );
      if (isDifferent)
        logger.info(
          `UtmService: Utm params different in url and cookies. Url: ${urlUtmParams}, Cookie ${cookieUtmParams}`
        );
    } catch (e) {
      logger.error(e);
    }
  }

  private tryGetValue(
    locationParams: Map<string, string>,
    cookieParams: Map<string, string>,
    key: string
  ): string | undefined {
    return locationParams.has(key) ? locationParams.get(key) : cookieParams.get(`ride_${key}`);
  }
}
