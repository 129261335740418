import React from "react";
import { LowTaxBrokerOverview } from "client/components/LowTaxBrokerOverview/LowTaxBrokerOverview";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import {
  CreateLowTaxBrokerOrder,
  CreateLowTaxBrokerOrderVariables
} from "lib/api/mutations/graphql/CreateLowTaxBrokerOrder";
import { CREATE_LOW_TAX_BROKER_ORDER } from "lib/api/mutations/CREATE_LOW_TAX_BROKER_ORDER";
import {
  CancelLowTaxBrokerOrder,
  CancelLowTaxBrokerOrderVariables
} from "lib/api/mutations/graphql/CancelLowTaxBrokerOrder";
import { CANCEL_LOW_TAX_BROKER_ORDER } from "lib/api/mutations/CANCEL_LOW_TAX_BROKER_ORDER";
import { UpsertOrder, UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import {
  GET_LOW_TAX_BROKER_ORDER_FOR_COMPANY,
  GetLTBOrderForCompany
} from "lib/api/queries/GetLowTaxBrokerOrderForCompany";
import { OrderProductTypeEnum } from "global-query-types";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { CREATE_IBKR_SSO_SESSION } from "../../../lib/api/mutations/CREATE_IBKR_SSO_SESSION";
import { openAsyncExternalURL } from "../../../common/ExternalURLOpener";
import logger from "../../../common/Logger";
import { AGREE_TO_IBKR_FORM } from "../../../lib/api/mutations/AGREE_TO_IBKR_FORM";
import { getEndpoint } from "../../../common/GraphqlClient/httpLink";
import { getToken } from "../../../common/Authentication/getToken";
import { IBKRFileSubmission } from "./ApplicationReviewWithPendingTasksCard/PendingTaskSwitcher/PendingTaskSwitcher";
import { UPSERT_BROKERAGE_ACCOUNT } from "lib/api/mutations/UPSERT_BROKERAGE_ACCOUNT";
import { UpsertBrokerageAccountVariables } from "lib/api/mutations/graphql/UpsertBrokerageAccount";

interface LowTaxBrokerOverviewContainerProps {
  companyId: string | null | undefined;
}

export const LowTaxBrokerOverviewContainer = ({
  companyId
}: LowTaxBrokerOverviewContainerProps) => {
  const { push } = useHistory();

  const lowTaxBrokerForCompanyQuery = GetLTBOrderForCompany(companyId);
  const order = lowTaxBrokerForCompanyQuery.data?.getLowTaxBrokerOrderForCompany;

  const companyByIdQuery = QueryCompanyById(companyId);
  const company = companyByIdQuery?.data?.companyById;

  const addExistingCompanyOrder = company?.orders.find(
    (order) => order.productType === OrderProductTypeEnum.AddExistingCompany
  );

  const refetchGetLowTaxBrokerOrderForCompany = {
    query: GET_LOW_TAX_BROKER_ORDER_FOR_COMPANY,
    variables: {
      companyId
    }
  };

  const [createLowTaxBrokerOrderCall] = useMutation<
    CreateLowTaxBrokerOrder,
    CreateLowTaxBrokerOrderVariables
  >(CREATE_LOW_TAX_BROKER_ORDER, {
    refetchQueries: [refetchGetLowTaxBrokerOrderForCompany],
    variables: {
      companyId: companyId ?? ""
    },
    fetchPolicy: "no-cache"
  });

  const [cancelLowTaxBrokerOrderCall] = useMutation<
    CancelLowTaxBrokerOrder,
    CancelLowTaxBrokerOrderVariables
  >(CANCEL_LOW_TAX_BROKER_ORDER, {
    refetchQueries: [refetchGetLowTaxBrokerOrderForCompany],
    fetchPolicy: "no-cache"
  });

  const [updateLowTaxBrokerOrder] = useMutation<UpsertOrder, UpsertOrderVariables>(UPSERT_ORDER, {
    refetchQueries: [refetchGetLowTaxBrokerOrderForCompany]
  });

  const [upsertBrokerageAccountMutation, upsertBrokerageAccountRequest] =
    useMutation(UPSERT_BROKERAGE_ACCOUNT);

  const [createIBKRSSOSession, createIBKRSSOSessionRequest] = useMutation(CREATE_IBKR_SSO_SESSION, {
    fetchPolicy: "no-cache"
  });

  const createOrder = async () => {
    const result = await createLowTaxBrokerOrderCall();

    if (!result.data?.createLowTaxBrokerOrder) {
      throw new Error("ORDER_NOT_FOUND");
    }

    return result.data?.createLowTaxBrokerOrder;
  };

  const cancelOrder = async (orderId: string) => {
    await cancelLowTaxBrokerOrderCall({
      variables: {
        id: orderId
      }
    });
  };

  const updateOrder = async (order: UpsertOrderVariables) => {
    await updateLowTaxBrokerOrder({
      variables: {
        ...order
      }
    });
  };

  const upsertBrokerageAccount = async (variables: UpsertBrokerageAccountVariables) => {
    await upsertBrokerageAccountMutation({ variables });
  };

  const goToOrderForm = (order: OrderData<LowTaxBrokerOrderData>) => {
    push(`/client/order/generic/broker-setup/0/${order?.id}/${order?.submissionId}`);
  };

  const handleCreateSSOAndRedirect = async () => {
    try {
      const promisedURL = createIBKRSSOSession({
        variables: {
          companyId: companyId ?? ""
        }
      }).then((result) => result.data?.createIBKRSSOSession?.ssoURL);

      await openAsyncExternalURL(promisedURL);
    } catch (error: any) {
      logger.errorMessage(`Error while creating SSO session for company ${companyId}`);
      logger.error(error);
    }
  };

  const [agreeToIBKRForm, agreeToIBKRFormRequest] = useMutation(AGREE_TO_IBKR_FORM, {
    fetchPolicy: "no-cache"
  });

  const handleAgreeToIBKRForm = async (formNumber: string) => {
    await agreeToIBKRForm({ variables: { orderId: order?.id ?? "", formNumber } });
  };

  const handleSubmitFile = async (fileSubmission: IBKRFileSubmission) => {
    const formData = new FormData();
    formData.append("file", fileSubmission.file);
    if (fileSubmission.documentType) formData.append("documentType", fileSubmission.documentType);
    if (fileSubmission.proofOfIdentityType)
      formData.append("proofOfIdentityType", fileSubmission.proofOfIdentityType);
    if (fileSubmission.proofOfAddressType)
      formData.append("proofOfAddressType", fileSubmission.proofOfAddressType);
    if (fileSubmission.expirationDate)
      formData.append("expirationDate", fileSubmission.expirationDate);

    const result = await fetch(
      `${getEndpoint()}/api/client/submit-ibkr-document/${order?.id}/${fileSubmission.formNumber}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        body: formData
      }
    );

    if (!result.ok) {
      const body = await result.json();
      throw new Error(body.error?.message ?? "Error while submitting the document");
    }
  };

  return (
    <RequestFeedbackHandler
      requests={[
        lowTaxBrokerForCompanyQuery,
        companyByIdQuery,
        createIBKRSSOSessionRequest,
        agreeToIBKRFormRequest,
        upsertBrokerageAccountRequest
      ]}>
      <LowTaxBrokerOverview
        addExistingCompanyOrder={addExistingCompanyOrder}
        order={order}
        company={company}
        goToOrderForm={goToOrderForm}
        createOrder={createOrder}
        cancelOrder={cancelOrder}
        updateOrder={updateOrder}
        upsertBrokerageAccount={upsertBrokerageAccount}
        handleCreateSSOAndRedirect={handleCreateSSOAndRedirect}
        handleAgreeToIBKRForm={handleAgreeToIBKRForm}
        handleSubmitFile={handleSubmitFile}
      />
    </RequestFeedbackHandler>
  );
};
