import gql from "graphql-tag";

export const CREATE_CLUB_DEAL = gql`
  mutation CreateClubDeal(
    $realEstateId: ID!
    $targetYield: Int
    $minInvestmentAmount: Int
    $maxInvestmentAmount: Int
    $financedPercentage: Int
  ) {
    createClubDeal(
      realEstateId: $realEstateId
      targetYield: $targetYield
      minInvestmentAmount: $minInvestmentAmount
      maxInvestmentAmount: $maxInvestmentAmount
      financedPercentage: $financedPercentage
    ) {
      id
    }
  }
`;
