import React from "react";
import { useParams } from "react-router";
import { StepComponentProps } from "../../../ChaptersOrderLayout.partials";
import ReviewAndSubmitLowTaxBroker from "./ReviewAndSubmitLowTaxBroker";
import RequestFeedbackHandler from "../../../../../../components/generic/RequestFeedbackHandler";
import { GetLowTaxBrokerOrder } from "lib/api/queries/GetLowTaxBrokerOrder";
import { LeanCompanyDetails } from "lib/models/LeanCompanyDetails";

const ReviewAndSubmitLowTaxBrokerContainer = (props: StepComponentProps) => {
  const { orderId } = useParams<{ orderId: string }>(); //props.order.id
  const orderRequest = GetLowTaxBrokerOrder({ orderId });
  const tradeCompany = new LeanCompanyDetails(orderRequest.data?.getLowTaxBrokerOrder?.company);

  return (
    <RequestFeedbackHandler requests={[orderRequest]}>
      <ReviewAndSubmitLowTaxBroker tradeCompany={tradeCompany} {...props} />
    </RequestFeedbackHandler>
  );
};

export default ReviewAndSubmitLowTaxBrokerContainer;
