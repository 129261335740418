import React from "react";
import { RouteComponentProps } from "react-router";
import { AuthLayout } from "sharedComponents/templates/AuthLayout/AuthLayout";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LostPasswordContainer } from "auth/components/LostPassword/LostPasswordContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface ResetPasswordSceneProps extends WithTranslation, RouteComponentProps {}

const ResetPasswordScene = ({ t }: ResetPasswordSceneProps) => {
  return (
    <>
      <PageMetaData title={t("auth:reset-password-not-logged-in.tab-title")} />
      <AuthLayout>
        <LostPasswordContainer isResetPasswordFlow={true} />
      </AuthLayout>
    </>
  );
};

export default withTranslationReady(["auth"])(ResetPasswordScene);
