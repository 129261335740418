import React, { PropsWithChildren } from "react";

export interface RideDropdownProps extends PropsWithChildren<{}> {
  "data-testid"?: string;
  className?: string;
}

export const RideDropdown = ({
  "data-testid": dataTestId,
  className,
  children
}: RideDropdownProps) => {
  return (
    <div data-testid={dataTestId} className={`ride-dropdown elevation-300 ${className ?? ""}`}>
      {children}
    </div>
  );
};

export { RideDropdownItem, RideDropdownItemVariant } from "./RideDropdownItem/RideDropdownItem";
