import React from "react";
import { IconProps } from "../../components/common/icons/IconProps";

export const CheckboxIndeterminateSelected = ({ size = 18 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill="#48FFAF" stroke="currentColor" />
    <path d="M4.5 9 L14 9" stroke="#303436" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
