import React, { ReactNode, useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import ProductCard, { ProductCardCheckboxInput } from "client/components/ProductCard/ProductCard";
import { AdminServiceIcon } from "sharedComponents/icons/DesignSystemIcons/AdminServiceIcon";
import { shortOrderFormProductPrices } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { ProductSectionCard } from "client/components/ProductSectionCard/ProductSectionCard";
import { RideBadge } from "uiLibrary/components/RideBadge/RideBadge";
import { formatCurrency } from "common/formatters";
import { useField } from "formik";
import { useRideField } from "lib/hooks/useRideField";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import AdminServiceTiersModal from "client/components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceTiersModal/AdminServiceTiersModal";
import {
  AdminServiceDisabledTiers,
  getBlockedTiersForAdminService
} from "../../../../../common/getBlockedTiersForAdminService";

export interface AdminServiceProductCardProps extends WithTranslation {
  inputName: string;
  disabledOptions?: AdminServiceDisabledTiers;
}

export interface AdminServiceTierProductCardProps extends WithTranslation {
  showTitle?: boolean;
  input?: ReactNode;
  handleLearnMore?: () => void;
  disabled?: boolean;
  tooltipText?: string;
}

interface TierSelectionInputProps {
  inputName: string;
  value: AdminServiceTier;
  disabledOptions?: AdminServiceTier[];
}

const AdminServiceProductCard = ({
  t,
  inputName,
  disabledOptions
}: AdminServiceProductCardProps) => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const field = useRideField(inputName);
  const companyDoesNotFallIntoCategoriesField = useRideField("companyDoesNotFallIntoCategories");
  const companyOwnsRealEstateField = useRideField("companyOwnsRealEstate");
  const tradesCryptoField = useRideField("tradesCrypto");
  const hasFiveOrMoreEmployeesField = useRideField("hasFiveOrMoreEmployees");
  const companyIssuesMoreThan50OutgoingInvoicesYearlyField = useRideField(
    "companyIssuesMoreThan50OutgoingInvoicesYearly"
  );
  const companyKeepsPhysicalInventoryField = useRideField("companyKeepsPhysicalInventory");

  const blockedTiersForAdminService = getBlockedTiersForAdminService(
    {
      companyDoesNotFallIntoCategories: companyDoesNotFallIntoCategoriesField.value,
      companyOwnsRealEstate: companyOwnsRealEstateField.value,
      tradesCrypto: tradesCryptoField.value,
      hasFiveOrMoreEmployees: hasFiveOrMoreEmployeesField.value,
      companyIssuesMoreThan50OutgoingInvoicesYearly:
        companyIssuesMoreThan50OutgoingInvoicesYearlyField.value,
      companyKeepsPhysicalInventory: companyKeepsPhysicalInventoryField.value
    },
    true
  );

  const blockAS = blockedTiersForAdminService.disabledTiers.length === 3;

  const showInnerTooltip =
    companyDoesNotFallIntoCategoriesField.value !== "no" &&
    companyOwnsRealEstateField.value !== "yes-more-than-15" &&
    tradesCryptoField.value !== "yes" &&
    hasFiveOrMoreEmployeesField.value !== "yes" &&
    companyIssuesMoreThan50OutgoingInvoicesYearlyField.value !== "yes" &&
    companyKeepsPhysicalInventoryField.value !== "yes";

  return (
    <>
      {showLearnMoreModal && (
        <AdminServiceTiersModal
          setAdminServiceTier={field.setValue}
          disabledOptions={disabledOptions?.disabledTiers}
          onClose={() => {
            setShowLearnMoreModal(false);
          }}
        />
      )}
      <ProductSectionCard
        dataTestId="admin-service-options"
        title={t("generic:product-card.admin-service.title")}
        description={t("generic:product-card.admin-service.description")}
        icon={<AdminServiceIcon size={32} />}
        tooltipText={
          blockAS ? t("generic:product-card.admin-service.tooltip-as-disabled") : undefined
        }>
        <div className={`admin-service-product-card__tiers`}>
          <AdminServiceLiteProductCard
            input={
              <TierSelectionInput
                inputName={inputName}
                disabledOptions={disabledOptions?.disabledTiers}
                value={AdminServiceTier.Lite}
              />
            }
            handleLearnMore={() => setShowLearnMoreModal(true)}
            tooltipText={
              field.value !== AdminServiceTier.Lite &&
              showInnerTooltip &&
              disabledOptions?.disabledTiers.includes(AdminServiceTier.Lite)
                ? t(disabledOptions?.tooltipTextKey ?? "")
                : undefined
            }
          />
          <AdminServiceStandardProductCard
            input={
              <TierSelectionInput
                inputName={inputName}
                disabledOptions={disabledOptions?.disabledTiers}
                value={AdminServiceTier.Standard}
              />
            }
            handleLearnMore={() => setShowLearnMoreModal(true)}
            tooltipText={
              showInnerTooltip && disabledOptions?.disabledTiers.includes(AdminServiceTier.Standard)
                ? t(disabledOptions?.tooltipTextKey ?? "")
                : undefined
            }
          />
          <AdminServiceProProductCard
            input={
              <TierSelectionInput
                inputName={inputName}
                disabledOptions={disabledOptions?.disabledTiers}
                value={AdminServiceTier.Pro}
              />
            }
            handleLearnMore={() => setShowLearnMoreModal(true)}
          />
        </div>
      </ProductSectionCard>
    </>
  );
};

const TierSelectionInput = ({ inputName, value, disabledOptions }: TierSelectionInputProps) => {
  const [adminServiceTierField, , { setValue: setAdminServiceTierField }] = useField(inputName);

  const onChangeField = (checkboxValue: boolean, tierValue: string) => {
    if (checkboxValue) {
      setAdminServiceTierField(tierValue);
    } else {
      setAdminServiceTierField(undefined);
    }
  };

  return (
    <ProductCardCheckboxInput
      data-testid={`admin-service-${value}-checkbox`}
      name={`admin-service-${value}`}
      checked={adminServiceTierField.value === value}
      onChange={(e) => {
        onChangeField(e.target.checked, value);
      }}
      disabled={disabledOptions?.includes(value)}
    />
  );
};

const AdminServiceLiteProductCardBadge: ReactNode = <RideBadge variant={"OCRE"} label="Lite" />;
const AdminServiceStandardProductCardBadge: ReactNode = (
  <RideBadge variant={"PURPLE"} label="Standard" />
);
const AdminServiceProProductCardBadge: ReactNode = <RideBadge variant={"GREEN"} label="Pro" />;

export function getAdminServiceProductCardBadge(tier: AdminServiceTier | undefined): ReactNode {
  if (tier === AdminServiceTier.Lite) return AdminServiceLiteProductCardBadge;
  if (tier === AdminServiceTier.Standard) return AdminServiceStandardProductCardBadge;
  if (tier === AdminServiceTier.Pro) return AdminServiceProProductCardBadge;
  return <></>;
}
export const AdminServiceLiteProductCard = withTranslationReady(["generic"])(
  ({
    showTitle = false,
    input,
    handleLearnMore,
    disabled = false,
    tooltipText,
    t
  }: AdminServiceTierProductCardProps) => {
    return (
      <ProductCard
        dataTestId={"admin-service-lite"}
        title={showTitle ? t("generic:admin-service") : ""}
        description={t("generic:product-card.admin-service.options.lite.description")}
        price={t("generic:product-card.pricePeriodicity.yearly", {
          price: formatCurrency(shortOrderFormProductPrices.adminService.lite, { fixed: 0 })
        })}
        icon={AdminServiceLiteProductCardBadge}
        input={input}
        handleLearnMore={handleLearnMore ?? undefined}
        disabled={disabled}
        tooltipText={tooltipText}
      />
    );
  }
);

export const AdminServiceStandardProductCard = withTranslationReady(["generic"])(
  ({
    showTitle = false,
    input,
    handleLearnMore,
    disabled = false,
    tooltipText,
    t
  }: AdminServiceTierProductCardProps) => {
    return (
      <ProductCard
        dataTestId={"admin-service-standard"}
        title={showTitle ? t("generic:admin-service") : ""}
        description={t("generic:product-card.admin-service.options.standard.description")}
        price={t("generic:product-card.pricePeriodicity.yearly", {
          price: formatCurrency(shortOrderFormProductPrices.adminService.standard, { fixed: 0 })
        })}
        icon={AdminServiceStandardProductCardBadge}
        input={input}
        handleLearnMore={handleLearnMore ?? undefined}
        disabled={disabled}
        tooltipText={tooltipText}
      />
    );
  }
);

export const AdminServiceProProductCard = withTranslationReady(["generic"])(
  ({
    showTitle = false,
    input,
    handleLearnMore,
    disabled = false,
    t
  }: AdminServiceTierProductCardProps) => {
    return (
      <ProductCard
        dataTestId={"admin-service-pro"}
        title={showTitle ? t("generic:admin-service") : ""}
        description={t("generic:product-card.admin-service.options.pro.description")}
        price={t("generic:product-card.pricePeriodicity.yearly", {
          price: formatCurrency(shortOrderFormProductPrices.adminService.pro, { fixed: 0 })
        })}
        icon={AdminServiceProProductCardBadge}
        input={input}
        handleLearnMore={handleLearnMore ?? undefined}
        disabled={disabled}
      />
    );
  }
);

export default withTranslationReady(["generic"])(AdminServiceProductCard);
