import React from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { AuditLogContainer, AuditLogParams } from "../../components/AuditLog/AuditLogContainer";
import { useParams } from "react-router-dom";

const AuditLogScene = ({ t }: WithTranslation) => {
  const params = useParams<AuditLogParams>();

  return (
    <>
      <Row>
        <Col>
          <h2 data-testid="scene-header">{t("header:audit-log", params)}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <AuditLogContainer {...params} />
        </Col>
      </Row>
    </>
  );
};

export default withTranslationReady("header")(AuditLogScene);
