import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import { UPDATE_NOTARY_TYPES } from "./Mutations";
import { updateNotaryTypes, updateNotaryTypesVariables } from "./graphql/updateNotaryTypes";
import RequestFeedbackHandler from "../../generic/RequestFeedbackHandler";
import NotaryTypeAssignment from "./NotaryTypeAssignment";
import { AllNotaryPersonsQuery_allNotaryPersons } from "./graphql/AllNotaryPersonsQuery";

interface NotaryTypeAssignmentContainerProps extends WithTranslation {
  persons: AllNotaryPersonsQuery_allNotaryPersons[];
  refetch: () => Promise<void>;
}

export interface UpdateNotary {
  singleShareholder?: "";
  multipleShareholders?: "";
  companyHolding?: "";
}

export const NotaryTypeAssignmentContainer = ({
  persons,
  refetch
}: NotaryTypeAssignmentContainerProps) => {
  const [updateNotaryTypesMutation, updateNotaryTypesRequest] = useMutation<
    updateNotaryTypes,
    updateNotaryTypesVariables
  >(UPDATE_NOTARY_TYPES);

  const onSubmit = async (variables: UpdateNotary) => {
    await updateNotaryTypesMutation({ variables });
  };

  return (
    <RequestFeedbackHandler requests={[updateNotaryTypesRequest]}>
      <div data-testid="notary-type-assignment-container">
        <NotaryTypeAssignment refetch={refetch} persons={persons} onSubmit={onSubmit} />
      </div>
    </RequestFeedbackHandler>
  );
};

export default withTranslation(["generic"])(NotaryTypeAssignmentContainer);
