import * as Yup from "yup";
import { SourceOfWealthType } from "../models/client/LowTaxBroker/SourcesOfWealth";

export const SourcesOfWealthSpreadStepSchema = (t, sourcesOfWealth: SourceOfWealthType[]) => {
  const shape = {};

  for (const source of sourcesOfWealth) {
    shape[source] = Yup.number()
      .required(t("generic:validation-required"))
      .moreThan(0, t("generic:validation-percentage-greater-than-0"))
      .max(100, t("generic:validation-percentage-less-than-100"));
  }

  return Yup.object().shape(shape);
};
