import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Field, FormikContextType } from "formik";
import React, { PropsWithChildren, ReactNode } from "react";
import { WithTranslation } from "react-i18next";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import PhoneInput from "react-phone-input-2";

export enum InputState {
  DEFAULT = "DEFAULT",
  ACCEPTED = "ACCEPTED",
  ERROR = "ERROR"
}

export interface InputProps extends PropsWithChildren<{}> {
  name: string;
  label?: string;
  labelDescription?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
  state?: InputState;
  appendComponent?: ReactNode;
  inputProps?: any;
  formik?: FormikContextType<any>;
  shouldShowErrorFeedback?: boolean;
  asTextArea?: boolean;
  onFormikChange?: (event: React.ChangeEvent<any>) => void;
  onKeyDown?: (event: React.ChangeEvent<any>) => void;
  autoComplete?: "on" | "off";
  onCountryChange?: (countryCode: string) => void;
}

export const fieldPropsGenerator = (props: InputProps) => ({
  className: `ride-input__input ${props.className}`,
  type: props.type ?? "text",
  disabled: props.isDisabled,
  placeholder: props.placeholder,
  id: `${props.name}-input`,
  name: props.name,
  as: props.asTextArea ? "textarea" : "input",
  autoComplete: props.autoComplete ?? "on"
});

export const phoneFieldPropsGenerator = (props: InputProps) => ({
  type: props.type ?? "tel",
  disabled: props.isDisabled,
  placeholder: props.placeholder,
  id: `${props.name}-input`,
  name: props.name,
  as: props.asTextArea ? "textarea" : "input",
  autoComplete: props.autoComplete ?? "on"
});

const RideInput = (props: InputProps & WithTranslation) => {
  const {
    label,
    labelDescription,
    name,
    isOptional,
    state = InputState.DEFAULT,
    className,
    children,
    appendComponent,
    inputProps,
    formik,
    asTextArea,
    t,
    shouldShowErrorFeedback = true,
    onKeyDown = null,
    onCountryChange
  } = props;

  const hasLabel = typeof label === "string";
  const hasLabelDescription = typeof labelDescription === "string";
  const labelClass =
    hasLabel || isOptional ? "ride-input--with-label" : "ride-input--without-label";

  let inputField;

  if (formik && props.type !== "tel") {
    const fieldProps = asTextArea
      ? { ...props, ...inputProps, className: "ride-input__input--as-textarea" }
      : { ...props, ...inputProps };

    inputField = (
      <Field
        onKeyDown={onKeyDown ?? null}
        data-testid={`${name}-input`}
        {...inputProps}
        {...fieldPropsGenerator(fieldProps)}
      />
    );
  }

  if (formik && props.type === "tel") {
    const value = formik?.values[name] ?? "";
    inputField = (
      <PhoneInput
        country={"de"}
        inputProps={{
          "data-testid": `${name}-input`,
          ...inputProps,
          ...phoneFieldPropsGenerator(props)
        }}
        inputClass={`ride-input__input ${className}`}
        value={value}
        autoFormat
        copyNumbersOnly={false}
        specialLabel={""}
        onChange={(value, country) => {
          onCountryChange?.((country as any).countryCode?.toUpperCase());
          formik?.setFieldValue(name, value);
          formik?.setFieldTouched(name);
        }}
        onBlur={() => {
          formik?.setFieldTouched(name);
        }}
        isValid={value?.length > 7 && value?.length < 16}
      />
    );
  }

  if (!formik && !asTextArea) {
    inputField = (
      <input data-testid={`${name}-input`} {...inputProps} {...fieldPropsGenerator(props)} />
    );
  }

  if (!formik && asTextArea) {
    inputField = (
      <textarea data-testid={`${name}-input`} {...inputProps} {...fieldPropsGenerator(props)} />
    );
  }

  const hasError = formik?.touched[name] && !!formik?.errors[name];

  const errorFeedback = (
    <InvalidFeedback testPrefix={name} hidden={!hasError}>
      {formik?.errors[name]}
    </InvalidFeedback>
  );

  const internalState = hasError ? InputState.ERROR : state;

  return (
    <div
      className={`ride-input ride-input--${internalState} ${labelClass} ${className ?? ""}`}
      data-testid={`${name}-ride-input`}>
      {isOptional && (
        <span className="ride-input__optional" data-testid={`${name}-optional-label`}>
          {t("generic:optional")}
        </span>
      )}
      {hasLabel && (
        <label
          htmlFor={`${name}-input`}
          className="ride-input__label"
          data-testid={`${name}-label`}>
          {label}
        </label>
      )}
      {hasLabelDescription && (
        <div className="ride-input__label-description" data-testid={`${name}-label-description`}>
          {labelDescription}
        </div>
      )}

      {appendComponent && (
        <div className="ride-input__wrapper">
          {inputField}
          {appendComponent}
        </div>
      )}
      {!appendComponent && inputField}
      {shouldShowErrorFeedback ? errorFeedback : null}
      {children}
    </div>
  );
};

export default withTranslationReady(["generic"])(RideInput);
