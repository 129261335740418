import React from "react";
import { Col, Row } from "react-bootstrap";
import { getThirdPartyTaxAdvisorsAndPartners } from "components/nominee/ThirdPartyTaxAdvisorsList/graphql/getThirdPartyTaxAdvisorsAndPartners";
import AddThirdPartyNotaryAndCompanyContainer from "../AddThirdPartyNotaryAndCompanyForm/AddThirdPartyNotaryAndCompanyFormContainer";
import ThirdPartyTaxAdvisorsCompanyListContainer from "../ThirdPartyTaxAdvisorCompaniesList/ThirdPartyTaxAdvisorCompaniesListContainer";

export const ThirdPartyTaxAdvisorsList = ({
  refetch,
  data
}: {
  refetch: () => Promise<void>;
  data: getThirdPartyTaxAdvisorsAndPartners | undefined;
}) => {
  return (
    <>
      <Row>
        <Col>
          <AddThirdPartyNotaryAndCompanyContainer refetch={refetch} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ThirdPartyTaxAdvisorsCompanyListContainer refetch={refetch} data={data} />
        </Col>
      </Row>
    </>
  );
};
