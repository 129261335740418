import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ValueFormatter } from "../../../../common/formatters/ValueFormatter";
import { GermanDate } from "../../../../common/serializers/GermanDate";
import { AssetType, InvestmentState } from "../../../../global-query-types";
import { formatCurrency } from "../../../../common/formatters";
import ChainOwnershipForState from "../../../../sharedComponents/ChainOwnershipForState/ChainOwnershipForState";
import { RideCardOwnership } from "../../../../sharedComponents/RideCardOwnership/RideCardOwnership";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";

export interface InvestmentCardDetailsProps {
  state: InvestmentState;
  type: AssetType;
  nlvPreTax?: number;
  lastChange?: string;
  share?: number;
  image?: string;
  ownership?: string[];
  className?: string;
  nlvPostTax?: number;
}

export interface InvestmentCardDetailsPropsWithTranslation
  extends InvestmentCardDetailsProps,
    WithTranslation {}

const InvestmentCardDetails = (props: InvestmentCardDetailsPropsWithTranslation) => {
  const valueFormatter = new ValueFormatter();
  const dateFormatter = new GermanDate(undefined, "");
  const { nlvPreTax = 0, share, state, lastChange, type, image, ownership, nlvPostTax, t } = props;

  const imageStyle: { [key: string]: string } = {};

  if (image) {
    imageStyle.backgroundImage = `url(${image})`;
  }

  const value = valueFormatter.format(nlvPreTax * ((share ?? 100) / 100));
  const formattedTotalValue = valueFormatter.format(nlvPreTax);
  const formattedLastChange = dateFormatter.serialize(lastChange);
  const displayValues = state !== InvestmentState.RideClubInProcess;

  const isBrokerageAccountSet = type === AssetType.BrokerageAccountSet;

  const displayedOwnership = isBrokerageAccountSet ? (
    <RideCardOwnership ownership={ownership ?? []} />
  ) : (
    <ChainOwnershipForState state={state} ownership={ownership ?? []} />
  );

  return (
    <CardContainer
      className={`investment-card-details ${props.className ?? ""}`}
      data-testid="investment-card-details">
      <div
        data-testid="card-image"
        style={imageStyle}
        className={`investment-card-details__image investment-card-details__image--${type} ${
          image ? "" : "investment-card-details__image--default"
        }`}
      />
      <div className="investment-card-details__content">
        {displayedOwnership}

        {displayValues && (
          <div className="investment-card-details__section">
            <div className="investment-card-details__section-title" data-testid="section-title">
              {isBrokerageAccountSet
                ? t(`client:investments.detailsCard.nlvPreTax`)
                : t(`client:investments.detailsCard.myInvestment-${state}`)}
            </div>
            <div className="investment-card-details__my-investment-value" data-testid="card-value">
              {formatCurrency(value)}
            </div>
            {lastChange && (
              <div
                className="investment-card-details__my-investment-date"
                data-testid="card-last-change">
                {t("client:investments.detailsCard.lastChange")}
                &nbsp;
                {formattedLastChange}
              </div>
            )}
          </div>
        )}

        {Boolean(share) && displayValues && !isBrokerageAccountSet && (
          <>
            <InvestmentCardDetailsSection
              title={t(`client:investments.detailsCard.wholeInvestment`)}
              value={formatCurrency(formattedTotalValue) ?? "---"}
              testId="card-total-value"
            />
            <InvestmentCardDetailsSection
              title={t("client:investments.detailsCard.myShare")}
              value={`${share}%`}
              testId="card-share"
            />
          </>
        )}

        {!isBrokerageAccountSet && (
          <InvestmentCardDetailsSection
            title={t("client:investments.detailsCard.assetClass")}
            value={t(`client:investments.detailsCard.type.${type}`)}
            testId="details-card-type"
          />
        )}

        {isBrokerageAccountSet && (
          <InvestmentCardDetailsSection
            title={t("client:investments.detailsCard.nlvPostTax")}
            value={formatCurrency(nlvPostTax) ?? ""}
            testId="card-value-after-taxes"
          />
        )}
      </div>
    </CardContainer>
  );
};

const InvestmentCardDetailsSection = ({
  title,
  value,
  testId
}: {
  title: string;
  value: string;
  testId: string;
}) => {
  return (
    <div className="investment-card-details__section">
      <div className="investment-card-details__section-title">{title}</div>
      <div className="investment-card-details__section-value" data-testid={testId}>
        {value}
      </div>
    </div>
  );
};

export default withTranslationReady("client")(InvestmentCardDetails);
