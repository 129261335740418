import { GmbH } from "lib/models/client/GmbH";
import { Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import UploadModal from "client/components/Files/UploadModal/UploadModal";
import { StorageConfigStructure } from "./GetStorageConfig";
import { FileUploadService } from "lib/services/FileUploadService/FileUploadService";
import OpenFileSelectionHandler from "sharedComponents/OpenFileSelectionHandler/OpenFileSelectionHandler";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AddIcon } from "sharedComponents/icons/add";

interface Props extends WithTranslation {
  gmbh: GmbH;
  storageConfig: StorageConfigStructure[];
  externalStorageNodes: { [ref: string]: string };
  uploadService: FileUploadService;
}

const UploadDocumentRow = ({
  gmbh,
  storageConfig,
  externalStorageNodes,
  uploadService,
  t
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const hideModal = () => {
    setModalVisible(false);
    setFiles([]);
  };
  const revealModal = () => setModalVisible(true);
  const [files, setFiles] = useState<File[]>([]);

  const onSelect = (fileList) => {
    if (fileList.length === 0) return;
    setFiles(fileList);
    revealModal();
  };

  return (
    <Row className="mt-4 mb-3 mt-md-55 mr-0 upload-document" data-testid="upload-document-row">
      <UploadModal
        show={modalVisible}
        onHide={hideModal}
        files={files}
        config={storageConfig}
        externalStorageNodes={externalStorageNodes}
        uploadService={uploadService}
        entityId={gmbh.id}
      />

      <Col>
        <Container>
          <Row noGutters className="align-items-center">
            <Col xs="auto">
              <OpenFileSelectionHandler
                testid="local-file-select"
                canUploadMultipleFiles={true}
                onSelect={onSelect}>
                {({ openFileSelectPopUp }) => (
                  <RideButtonDeprecated
                    hasIcon={true}
                    onClick={openFileSelectPopUp}
                    data-testid="select-file-button">
                    <AddIcon />
                    {t("client:documents.upload")}
                  </RideButtonDeprecated>
                )}
              </OpenFileSelectionHandler>
            </Col>
            <Col className="ml-15 ml-md-3 upload-document__text">
              {t("client:documents.or-drag")}
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default withTranslation(["client"])(UploadDocumentRow);
