import NoResultsPlaceholder from "../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import KYCStatusLabel from "../../../components/nominee/common/roles/KYCStatusLabel";
import { formatPercentage } from "../../../common/formatters";
import React from "react";
import { RoleEntry } from "../../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import { WithTranslation, withTranslation } from "react-i18next";
import { FeatureFlags } from "../../../global-query-types";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";

interface ViewRolesProps extends WithTranslation {
  roles: RoleEntry[] | null;
}

const RolesSectionViewMode = ({ t, roles }: ViewRolesProps) => {
  const getPersonLink = (role) => {
    const id = role.company ? role.company.id : role.person.id;
    const baseUrl = role.company ? "/nominee-companies/" : "/nominee-persons/";
    return `${baseUrl}${id}`;
  };

  const getPersonValue = (role) => {
    const fullName = role.company
      ? `${role.company.name} ${t(`generic:legalForm.${role.company.legalForm}`)}`
      : `${role.person?.firstName} ${role.person?.lastName}`;
    return fullName;
  };

  const columnCount = FeatureFlagService.instance.isEnabled(FeatureFlags.TransferCompanyOwnership)
    ? 7
    : 6;

  return (
    <div data-testid="view-roles-table">
      {!roles ||
        (roles.length === 0 && <NoResultsPlaceholder text={t("generic:no-data-available")} />)}
      {roles &&
        roles.map((role) => {
          return (
            <RideTableRow colCount={columnCount} key={role.id}>
              <RideTableCell
                variant={RideTableCellVariant.primaryLink}
                link={getPersonLink(role)}
                value={getPersonValue(role)}
                dataTestId="shareholder-person"
                classes="mobile-full"
              />
              <RideTableCell
                variant={RideTableCellVariant.badge}
                dataTestId="shareholder-status"
                classes="mobile-full"
                badge={
                  <KYCStatusLabel
                    isKycRequired={role.isKycRequired}
                    isKycSucceeded={role.isKycSucceeded}
                  />
                }
              />
              <RideTableCell
                variant={RideTableCellVariant.secondaryText}
                dataTestId="shareholder-director"
                value={
                  role.person?.id
                    ? role.isManagingDirector
                      ? t("generic:yes")
                      : t("generic:no")
                    : t("generic:not-applicable")
                }
              />
              <RideTableCell
                dataTestId="shareholder-silent-partner"
                variant={RideTableCellVariant.secondaryText}
                value={role.isSilentPartner ? t("generic:yes") : t("generic:no")}
              />
              <RideTableCell
                dataTestId="shareholder-share"
                variant={RideTableCellVariant.secondaryText}
                value={role.share ? formatPercentage(role.share / 100) : "---"}
              />
              <RideTableCell
                dataTestId="shareholder-votes"
                variant={RideTableCellVariant.secondaryText}
                value={role.votes ? formatPercentage(role.votes / 100) : "---"}
              />
              <FeatureFlag name={FeatureFlags.TransferCompanyOwnership}>
                <RideTableCell
                  dataTestId="shareholder-ownership"
                  variant={RideTableCellVariant.secondaryText}
                  value={"---"}
                />
              </FeatureFlag>
            </RideTableRow>
          );
        })}
    </div>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(RolesSectionViewMode);
