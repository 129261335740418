import { withTranslationReady } from "common/i18n/withTranslationReady";
import NavigationService from "lib/services/NavigationService/NavigationService";
import { LTBDashboardActionCard } from "client/components/LowTaxBrokerDashboard/sharedComponents/LTBDashboardActionCard/LTBDashboardActionCard";
import React from "react";
import { WithTranslation } from "react-i18next";

const TraderSupport = ({ t }: WithTranslation) => {
  return (
    <div data-testid={"trader-support"}>
      <LTBDashboardActionCard
        dataTestId={"trader-support-card"}
        title={t("generic:low-tax-broker-dashboard.trader-support.title")}
        description={t("generic:low-tax-broker-dashboard.trader-support.description")}
        buttonLabel={t("generic:low-tax-broker-dashboard.trader-support.button")}
        buttonClickHandler={() => {
          NavigationService.instance?.openLink(
            "https://www.ride.capital/faq/wie-sind-die-handelszeiten-des-ride-low-tax-broker-und-wie-ist-ride-erreichbar?utm_source=app&utm_medium=app"
          );
        }}
        disabled={false}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(TraderSupport);
