import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { RideHeaderColumn } from "../../../../lib/types/types";
import { RideTableHeader } from "../../../../sharedComponents/RideTableHeader/RideTableHeader";
import { SectionWrapper } from "../SectionWrapper/SectionWrapper";
import { WithTranslation } from "react-i18next";
import { RideTableRow } from "../../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell from "../../../../sharedComponents/RideTableCell/RideTableCell";
import { PendingTask } from "../../../../lib/types/PendingTask";

interface PendingTasksTableProps extends WithTranslation {
  pendingTasks: PendingTask[];
}

const requiredFor = (pendingTask: any) => {
  if (pendingTask.isRequiredForApproval) {
    return "For approval";
  }
  if (pendingTask.isRequiredForTrading) {
    return "For trading";
  }
  return "Nothing";
};

const PendingTasksTable = ({ t, pendingTasks }: PendingTasksTableProps) => {
  const columns: RideHeaderColumn[] = [
    t("generic:pending-tasks.columns.task-number"),
    t("generic:pending-tasks.columns.form-number"),
    t("generic:pending-tasks.columns.name"),
    t("generic:pending-tasks.columns.state"),
    t("generic:pending-tasks.columns.required-for"),
    t("generic:pending-tasks.columns.in-client-portal")
  ].map((label) => ({
    label,
    name: label.toLowerCase()
  }));

  return (
    <SectionWrapper title={t("generic:broker-order-details.pending-tasks")}>
      <div data-testid="pending-tasks-table">
        <RideTableHeader columns={columns} />
        {pendingTasks.map((pendingTask, index) => (
          <RideTableRow colCount={columns.length} key={index}>
            <RideTableCell value={pendingTask.taskNumber} />
            <RideTableCell value={pendingTask.formNumber} />
            <RideTableCell value={pendingTask.formName} />
            <RideTableCell value={`${pendingTask.action}: ${pendingTask.state ?? ""}`} />
            <RideTableCell value={requiredFor(pendingTask)} />
            <RideTableCell value={pendingTask.isOnlineTask} />
          </RideTableRow>
        ))}
      </div>
    </SectionWrapper>
  );
};

export default withTranslationReady(["generic"])(PendingTasksTable);
