import React from "react";

export interface RidePaperProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  variant?: "default" | "accent" | "disabled";
  onClick?: () => void;
  testId?: string;
}

export const RidePaper = ({
  children,
  title,
  subtitle,
  variant = "default",
  onClick,
  testId = ""
}: RidePaperProps) => {
  const isClickable = onClick ? "clickable" : "";
  const dataTestId = testId ? `${testId}-ride-paper` : "ride-paper";

  return (
    <div
      onClick={onClick}
      data-testid={dataTestId}
      className={`ride-paper ride-paper--${variant} ${isClickable}`}>
      {title && (
        <div className="ride-paper__title" data-testid="ride-paper-title">
          {title}
        </div>
      )}
      {subtitle && (
        <div className="ride-paper__subtitle" data-testid="ride-paper-subtitle">
          {subtitle}
        </div>
      )}
      {children}
    </div>
  );
};
