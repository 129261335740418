import React from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import ShareholdersContextStep from "./ShareholdersContext";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

export const ShareholdersContextContainer = (props: StepComponentProps) => {
  const companyById = QueryCompanyById(props.order.entityId);

  return (
    <RequestFeedbackHandler requests={[companyById]}>
      <ShareholdersContextStep
        {...props}
        memberships={companyById.data?.companyById?.group.memberships ?? []}
      />
    </RequestFeedbackHandler>
  );
};
