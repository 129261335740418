import React from "react";
import { Link } from "react-router-dom";
import { UserRole } from "../../../global-query-types";
import CompanyNameOrUnnamed from "../CompanyNameOrUnnamed";
import PersonName from "../PersonName/PersonName";
import {
  CompanyNameParams,
  PersonNameParams
} from "../../../common/formatters/CompanyNameFormatter";

interface EntityLinkMembershipCompany extends CompanyNameParams {
  id: string;
}

interface EntityLinkMembershipPerson extends PersonNameParams {
  id: string | null;
}

export enum EntityLinkColorVariant {
  GREEN = "GREEN",
  GREY = "GREY"
}

export interface EntityLinkProps {
  membership: {
    company?: EntityLinkMembershipCompany | null;
    person?: EntityLinkMembershipPerson | null;
  };
  asUser?: UserRole;
  testIdPrefix?: string;
  prefix?: string;
  colorVariant?: EntityLinkColorVariant;
}

const EntityLink = ({
  membership,
  testIdPrefix,
  prefix,
  asUser = UserRole.Nominee,
  colorVariant = EntityLinkColorVariant.GREEN
}: EntityLinkProps) => {
  const companyBaseUrl =
    asUser === UserRole.Nominee ? "/nominee-companies/" : "/tax-advisor/client-entities/";
  const personBaseUrl = asUser === UserRole.Nominee ? "/nominee-persons/" : "/tax-advisor/persons/";

  return (
    <div style={{ color: "red" }}>
      {prefix}
      {membership.company ? (
        <CompanyLink
          testIdPrefix={testIdPrefix}
          company={membership.company}
          baseUrl={companyBaseUrl}
          colorVariant={colorVariant}
        />
      ) : (
        <PersonLink
          testIdPrefix={testIdPrefix}
          person={membership.person}
          baseUrl={personBaseUrl}
          colorVariant={colorVariant}
        />
      )}
    </div>
  );
};

const CompanyLink = ({ company, baseUrl, testIdPrefix, colorVariant }) => (
  <Link
    className={`entity-link--${colorVariant}`}
    to={`${baseUrl}${company.id}`}
    data-testid={`${testIdPrefix}-company-link`}>
    <CompanyNameOrUnnamed company={company} />
  </Link>
);

const PersonLink = ({ person, baseUrl, testIdPrefix, colorVariant }) => (
  <Link
    className={`entity-link--${colorVariant}`}
    to={`${baseUrl}${person?.id}`}
    data-testid={`${testIdPrefix}-person-link`}>
    <PersonName person={person} />
  </Link>
);

export default EntityLink;
