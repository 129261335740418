import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RidePaper } from "uiLibrary/components";
import { formatCurrency, formatValue } from "common/formatters";
import { CompanyWithWepaTransactionSummary_getWepaTransactionSummary } from "lib/api/queries/graphql/CompanyWithWepaTransactionSummary";
import PieChartWithNumber, {
  PieChartWithNumberData
} from "../../../sharedComponents/RidePieChart/PieChartWithNumber";

export type WepaTransactionSummaryType =
  | Omit<CompanyWithWepaTransactionSummary_getWepaTransactionSummary, "__typename">
  | null
  | undefined;

export interface TradingTransactionHistoryProps {
  data: WepaTransactionSummaryType;
}

export class PieChartWithNumberDataMapper {
  static FromTransactionsByCurrency(transactionsByCurrency) {
    return transactionsByCurrency.map((t) => {
      return {
        name: t.currency,
        value: t.transactionCount
      } as PieChartWithNumberData;
    });
  }

  static FromFinancialProduct(financialProducts) {
    return financialProducts.map((t) => {
      return {
        name: t.product,
        value: t.transactionCount
      } as PieChartWithNumberData;
    });
  }
}

const TradingTransactionHistory = ({
  t,
  data
}: TradingTransactionHistoryProps & WithTranslation) => {
  const transactionsByCurrencyChartData: PieChartWithNumberData[] = data?.transactionsByCurrency
    ? PieChartWithNumberDataMapper.FromTransactionsByCurrency(data?.transactionsByCurrency)
    : [];

  const financialProductChartData: PieChartWithNumberData[] = data?.financialProducts
    ? PieChartWithNumberDataMapper.FromFinancialProduct(data?.financialProducts)
    : [];

  return (
    <div data-testid="trading-transaction-history" className="trading-transaction-history">
      <div className="trading-transaction-history__data">
        <RidePaper
          testId={"net-liquidation-value"}
          title={formatCurrency(data?.netLiquidationValue)}
          subtitle={t("nominee-dashboard:trading-transaction-history.net-liquidation-value")}
        />
      </div>

      <div className="trading-transaction-history__data">
        <RidePaper
          testId="number-of-transactions"
          title={formatValue(data?.totalNumberOfTransactions)}
          subtitle={t("nominee-dashboard:trading-transaction-history.number-of-transactions")}
        />
      </div>

      <div className="trading-transaction-history__data">
        <RidePaper
          testId="transactions-by-currency"
          title={t("nominee-dashboard:trading-transaction-history.currency")}>
          <PieChartWithNumber data={transactionsByCurrencyChartData} />
        </RidePaper>
      </div>

      <div className="trading-transaction-history__data">
        <RidePaper
          testId="financial-products"
          title={t("nominee-dashboard:trading-transaction-history.financial-product")}>
          <PieChartWithNumber data={financialProductChartData} />
        </RidePaper>
      </div>
    </div>
  );
};

export default withTranslationReady(["nominee-dashboard"])(TradingTransactionHistory);
