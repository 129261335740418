import React, { useEffect, useState } from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { RideStatus } from "lib/types/types";
import ReviewStep from "./steps/ReviewStep/ReviewStep";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import {
  AdminServiceItem,
  shortOrderFormProductPrices
} from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import SetupPaymentCard from "../../MigrateAdminServicePackage/SetupPaymentCard/SetupPaymentCard";
import ChoosePackageCard from "client/components/MigrateAdminServicePackage/ChoosePackageCard/ChoosePackageCard";
import ContractSignatureCard, {
  ContractSignatureCardFlow
} from "client/components/MigrateAdminServicePackage/ContractSignatureCard/ContractSignatureCard";
import {
  mapPaymentMethodToPaymentsModule,
  PaymentMethod
} from "lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { OnboardingStepCardStatus } from "client/components/OnboardingStepCard/OnboardingStepCard";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import {
  AdminServicePTAMigrationToRideSteuerberatungHelper,
  AdminServicePTAMigrationToRideSteuerberatungSteps,
  SteueragentenMigrationOrderDetails
} from "lib/helpers/AdminServicePTAMigrationToRideSteuerberatungHelper";
import { capitalize } from "lodash";
import { PaymentMethodType } from "payments-query-types";
import { calculateStatus } from "common/OnboardingStepCardHelper";
import { useHistory } from "react-router-dom";
import { WithStripePayment } from "../../WepaSection/WepaOnboarding/WepaOnboarding";

const useStepsStatus = (
  order: SteueragentenMigrationOrderDetails,
  hasRideSteuerberatungSepaPaymentBefore: boolean
) => {
  const [stepsStatus, setStepsStatus] = React.useState<
    { [key in AdminServicePTAMigrationToRideSteuerberatungSteps]: OnboardingStepCardStatus }
  >({
    choosePackage: RideStatus.PENDING,
    downloadContract: RideStatus.PENDING,
    agreeToTermsAndConditions: RideStatus.PENDING,
    setupPayment: RideStatus.PENDING,
    review: RideStatus.PENDING
  });

  React.useEffect(() => {
    const choosePackageStatus = calculateStatus({
      completed: !!order?.extra?.stepCompletion?.choosePackageAt,
      previousStepStatus: RideStatus.COMPLETED
    });

    const agreeToTermsAndConditionsStatus = calculateStatus({
      completed: !!order?.extra?.stepCompletion?.agreeToTermsAndConditionsAt,
      previousStepStatus: choosePackageStatus
    });

    const setupPaymentStatus = !!hasRideSteuerberatungSepaPaymentBefore
      ? RideStatus.COMPLETED
      : calculateStatus({
          completed: !!order?.extra?.paymentMethod,
          previousStepStatus: agreeToTermsAndConditionsStatus
        });

    const reviewStatus = calculateStatus({
      completed: false,
      previousStepStatus: !!hasRideSteuerberatungSepaPaymentBefore
        ? agreeToTermsAndConditionsStatus
        : setupPaymentStatus
    });

    setStepsStatus({
      choosePackage: choosePackageStatus,
      downloadContract: RideStatus.PENDING,
      agreeToTermsAndConditions: agreeToTermsAndConditionsStatus,
      setupPayment: setupPaymentStatus,
      review: reviewStatus
    });
    // eslint-disable-next-line
  }, [order, hasRideSteuerberatungSepaPaymentBefore]);

  return stepsStatus;
};

const getContractUrl = (tier: AdminServiceTier, adminServiceId: string): string => {
  const documentName = `AS%20${capitalize(tier)}%20RIDE%20Steuerberatung`;
  return `${getEndpoint()}/api/client/document-templates/admin-service/${documentName}/${adminServiceId}?apiToken=${getToken()}&format=pdf`;
};

interface PtaMigrationFlowToRideSteuerberatungProps extends WithTranslation {
  gmbh: GmbHDetails;
  order: SteueragentenMigrationOrderDetails;
  completeChoosePackageStep: (tier: AdminServiceTier) => Promise<void>;
  completeContractSignatureStep: (downloadAt: string, agreedAt: string) => Promise<void>;
  completeSetupPaymentStep: (payment: PaymentMethodType, sessionId?: string) => Promise<void>;
  completeReviewStep: () => Promise<void>;
}

const PtaMigrationFlowToRideSteuerberatung = ({
  t,
  gmbh,
  order,
  paymentMethod,
  completeChoosePackageStep,
  completeContractSignatureStep,
  completeSetupPaymentStep,
  completeReviewStep,
  validateStripeSession,
  goToCheckoutSession,
  updatePaymentMethod,
  sessionId
}: PtaMigrationFlowToRideSteuerberatungProps & WithStripePayment) => {
  console.log({ order });
  const [localSelectedTier, setLocalSelectedTier] = useState<AdminServiceTier | undefined>(
    (order?.extra?.tier || gmbh.adminService?.tier) as AdminServiceTier
  );
  const [downloadContractAt, setDownloadContractAt] = useState("");
  const hadRideSteuerberatungSepaPayment: boolean = paymentMethod === PaymentMethodType.Sepa;
  const history = useHistory();

  React.useEffect(() => {
    if (order?.extra?.tier) {
      setLocalSelectedTier(order?.extra?.tier as AdminServiceTier);
    }
  }, [gmbh, order]);

  const bankAccount = gmbh.bankAccounts?.[0];

  const stepsStatus = useStepsStatus(order, hadRideSteuerberatungSepaPayment);

  useEffect(() => {
    if (!!order && hadRideSteuerberatungSepaPayment && !order.extra?.paymentMethod) {
      completeSetupPaymentStep(PaymentMethodType.Sepa).then();
    }
    // eslint-disable-next-line
  }, [order]);

  const [triggeredValidate, setTriggeredValidate] = useState(false);
  useEffect(() => {
    if (sessionId && order && !triggeredValidate) {
      validateStripeSession(sessionId)
        .then((result) => {
          if (result) {
            const payment = PaymentMethodType.Sepa;
            updatePaymentMethod(payment).then();
            completeSetupPaymentStep(payment, sessionId).then();
          }
        })
        .catch((error) => {
          console.log(`Could not validate stripe session ID ${sessionId}`, { error });
        });

      history.replace(`/client/entities/${gmbh.id}/admin-service/tax-advisor-migrate`);
      setTriggeredValidate(true);
    }
    // eslint-disable-next-line
  }, [sessionId, order, triggeredValidate]);

  const selectTierHandler = async (tier: AdminServiceTier) => {
    setLocalSelectedTier(tier);
    await completeChoosePackageStep(tier);
  };

  const updatePaymentMethodHandler = async (paymentMethod: PaymentMethod) => {
    const payment: PaymentMethodType = mapPaymentMethodToPaymentsModule(paymentMethod);

    if (paymentMethod === PaymentMethod.ManualBankTransfer) {
      await updatePaymentMethod(payment);
      await completeSetupPaymentStep(payment);
    } else {
      goToCheckoutSession();
    }
  };

  const onDownloadContract = () => {
    setDownloadContractAt(new Date(Date.now()).toISOString());
    AdminServicePTAMigrationToRideSteuerberatungHelper.StepCompleted({
      step_name: AdminServicePTAMigrationToRideSteuerberatungSteps.downloadContract
    });
  };
  const onSignContractHandler = async () => {
    await completeContractSignatureStep(downloadContractAt, new Date(Date.now()).toISOString());
  };

  return (
    <div data-testid="pta-migration-flow-to-ride-steuerberatung">
      <ChoosePackageCard
        stepNumber={1}
        defaultTier={localSelectedTier}
        selectPackage={selectTierHandler}
        status={stepsStatus.choosePackage}
        isTradingCompany={false}
      />
      <ContractSignatureCard
        stepNumber={2}
        successPageUrl={""}
        downloadSignedContractUrl={""}
        onDownloadContract={onDownloadContract}
        status={stepsStatus.agreeToTermsAndConditions}
        selectedTier={localSelectedTier}
        contractUrl={
          localSelectedTier ? getContractUrl(localSelectedTier, gmbh.adminService?.id!) : ""
        }
        paymentMethod={undefined}
        onAgreeToTermsAndConditions={onSignContractHandler}
        flow={ContractSignatureCardFlow.ADMIN_SERVICE_ONBOARDING}
      />
      <SetupPaymentCard
        stepNumber={3}
        companyName={gmbh.name}
        iban={bankAccount?.iban ?? undefined}
        price={localSelectedTier && shortOrderFormProductPrices.adminService[localSelectedTier]}
        status={stepsStatus.setupPayment}
        setupPayment={updatePaymentMethodHandler}
        showYearlyPrice={true}
        footnote={t(
          "generic:payment-method-card.payment-date-info-as-ride-steuerberatung-migration"
        )}
      />
      <ReviewStep
        companyName={gmbh.name}
        paymentMethod={order?.extra?.paymentMethod}
        ASItemOrdered={
          localSelectedTier &&
          AdminServiceItem({
            tier: localSelectedTier,
            hasCompanyFoundingService: false
          })
        }
        fiscalYear={gmbh.fiscalYear ?? ""}
        onConfirm={completeReviewStep}
        status={stepsStatus.review}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(PtaMigrationFlowToRideSteuerberatung);
