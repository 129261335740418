import { setContext } from "apollo-link-context";
import { getToken } from "../Authentication/getToken";

export const enhanceHeaders = (headers) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      Authorization: token?.length ? `Bearer ${token}` : ""
    }
  };
};

export const authLink = setContext((_, { headers }) => {
  return enhanceHeaders(headers);
});
