import gql from "graphql-tag";

export const ALL_COMPANIES = gql`
  query AllCompanies($type: CompanyType, $limit: Int, $start: Int, $query: String) {
    allCompanies(type: $type, limit: $limit, start: $start, query: $query) {
      count
      companies {
        id
        name
        legalForm
        contactPerson {
          id
          firstName
          lastName
        }
        deletedAt
        createdAt
        businessAddress {
          city
        }
        brokerageAccounts {
          id
        }
        taxNumber
      }
    }
  }
`;
