import React, { PropsWithChildren } from "react";

export interface ButtonTrashProps extends PropsWithChildren<{}> {
  onClick?: (event) => void;
  type?: "button" | "submit" | "reset" | undefined;
}

export const ButtonTrash = ({ children, ...rest }: ButtonTrashProps) => {
  return (
    <button className={`button-trash`} {...rest}>
      {children}
    </button>
  );
};
