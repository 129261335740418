import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getThirdPartyTaxAdvisorsAndPartners } from "./graphql/getThirdPartyTaxAdvisorsAndPartners";

const GET_THIRD_PARTY_TAX_ADVISORS_AND_PARTNERS = gql`
  query getThirdPartyTaxAdvisorsAndPartners {
    ThirdPartyTaxService: allCompanies(type: ThirdPartyTaxService) {
      companies {
        id
        name
        legalForm
        myWepaSubContracting {
          type
          partner {
            name
            id
          }
        }
        taxServicesProvided {
          id
          taxAdvisorStatus
          updatedAt
          client {
            id
            name
            legalForm
            deletedAt
            adminService {
              id
            }
            companyCreation {
              id
            }
            wepaId
            brokerageAccounts {
              id
              brokerName
              validity
              errorCodes
            }
            netLiquidationValues {
              id
              type
              year
              quarter
              tag
              valueCents
              valueCurrency
            }
          }
        }
        contactPerson {
          user {
            taxAdvisorCode
          }
          lastName
          firstName
        }
      }
    }
    PartnerTaxCompany: allCompanies(type: TaxService) {
      companies {
        id
        name
      }
    }
  }
`;

export const GetThirdPartyTaxAdvisorsAndPartners = () =>
  useQuery<getThirdPartyTaxAdvisorsAndPartners>(GET_THIRD_PARTY_TAX_ADVISORS_AND_PARTNERS);
