import React from "react";
import { Col } from "react-bootstrap";
import { FlowTypeEnum } from "../../../../global-query-types";
import { getAdminServiceNextSteps, traderGmbHConfig } from "../../../../lib/formatters/config";
import { TFunction } from "i18next";
import wepaServiceConfig from "../../../../config/wepa-service.json";

export const OrderNextSteps = ({
  t,
  currentFlowType
}: {
  t: TFunction;
  currentFlowType: FlowTypeEnum | undefined;
}) => {
  let nextSteps = traderGmbHConfig.orderListItems;
  if (currentFlowType === FlowTypeEnum.ADMIN_SERVICE) {
    nextSteps = getAdminServiceNextSteps();
  }
  if (currentFlowType === FlowTypeEnum.WEPA_SERVICE) {
    nextSteps = wepaServiceConfig.nextSteps;
  }

  return (
    <Col className="align-next-steps">
      <h3 className="px-1" data-testid="next-steps-header">
        {t("generic:next-steps")}
      </h3>
      <br />
      {nextSteps.map((item, index) => (
        <ul className="next-steps-items d-flex pl-35" key={index}>
          <li data-testid="next-steps-line">{t(item)}</li>
        </ul>
      ))}
    </Col>
  );
};
