import React, { PropsWithChildren } from "react";
import { formatValue } from "../../common/formatters";

export interface RideCardTextPairProps extends PropsWithChildren<{}> {
  firstText?: string | null;
  secondText?: string | null;
  dataTestId?: string;
}

const RideCardTextPair = ({
  firstText = "",
  secondText = "",
  dataTestId = "card-text-pair"
}: RideCardTextPairProps) => {
  return (
    <div className="ride-card-text-pair" data-testid={dataTestId}>
      <div data-testid={`${dataTestId}-first-text`} className="ride-card-text-pair__first-text">
        {firstText}
      </div>
      <div data-testid={`${dataTestId}-second-text`} className="ride-card-text-pair__second-text">
        {formatValue(secondText)}
      </div>
    </div>
  );
};

export default RideCardTextPair;
