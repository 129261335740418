import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalLayout,
  ModalLayoutVariant
} from "sharedComponents/templates/ModalLayout/ModalLayout";
import { AddExistingGmbHContainer } from "client/components/GmbH/AddExistingGmbH/AddExistingGmbHContainer";
import { FeatureFlagService } from "../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../../global-query-types";

export const GmbHAddExistingScene = () => {
  const history = useHistory();

  const handleClose = () => {
    history.replace("/");
  };

  const addGmbHNewModal = FeatureFlagService.instance.isEnabled(FeatureFlags.NewAddGmbHModal);
  const handleBack = () => {
    if (addGmbHNewModal) {
      history.goBack();
    } else {
      history.replace(`/client/my-gmbhs/add`);
    }
  };

  return (
    <ModalLayout onClose={handleClose} onBack={handleBack} variant={ModalLayoutVariant.white}>
      <AddExistingGmbHContainer />
    </ModalLayout>
  );
};
