import React from "react";
import { WithTranslation } from "react-i18next";
import { PageLayout } from "sharedComponents/templates/PageLayout/PageLayout";
import WepaDirectOrdersListContainer from "../components/WepaDirectOrdersList/WepaDirectOrdersListContainer";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";

const WepaDirectOrdersListScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("generic:wepa-direct.orders-list-page.title")}
      mainContent={<WepaDirectOrdersListContainer />}
    />
  );
};

export default withTranslationReady(["generic"])(WepaDirectOrdersListScene);
