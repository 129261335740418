import React from "react";
import { useParams } from "react-router";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { AdminPackageSelectorContainer } from "../../components/AdminPackageSelector/AdminPackageSelectorContainer";
import { AdminBreadcrumbContainer } from "../../components/AdminBreadcrumb/AdminBreadcrumbContainer";
import { WithTranslation } from "react-i18next";

const AdminPackageSelectorScene = ({ t }: WithTranslation) => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <AdminBreadcrumbContainer
        companyId={id}
        title={t("nominee-dashboard:company.package-selector")}
      />
      <AdminPackageSelectorContainer companyId={id} />
    </>
  );
};

export default withTranslationReady(["nominee-dashboard"])(AdminPackageSelectorScene);
