import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { IBCredentials } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { Form, FormikProvider, useFormik } from "formik";
import {
  ButtonVariant,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import * as Yup from "yup";
import { RideModalDeprecated } from "sharedComponents/RideModalDeprecated/RideModalDeprecated";
import { getOrderDetails_getOrderDetails_order } from "lib/api/queries/graphql/getOrderDetails";

export interface SendIBCredentialsFormProps {
  order: getOrderDetails_getOrderDetails_order;
  ibCredentials: IBCredentials;
  onSubmit: ({ orderId, password }: { orderId: string; password: string }) => void;
  isLoading: boolean;
}

const SendIBCredentialsForm = ({
  t,
  order,
  ibCredentials,
  onSubmit,
  isLoading
}: SendIBCredentialsFormProps & WithTranslation) => {
  const newBroker = order.extra?.isNewBrokerEnabled ?? false;

  const formik = useFormik({
    initialValues: {
      password: ""
    },
    onSubmit: async () => {
      onSubmit({
        orderId: order.id,
        password: formik.values["password"]
      });
    },
    validationSchema: SendIBCredentialsFormSchema(t)
  });

  return (
    <RideModalDeprecated
      data-testid={"send-ib-information-modal"}
      title={t("generic:send-ib-credentials-form.title")}
      buttons={[
        {
          "data-testid": "send-email-button",
          variant: ButtonVariant.Primary,
          type: "submit",
          children: t("generic:send"),
          keepModalOpened: false,
          onClick: async () => {
            await formik.submitForm();
          },
          isLoading: isLoading
        }
      ]}
      dialogBody={
        <FormikProvider value={formik}>
          <Form className={"send-ib-credentials-form"}>
            <div data-testid="modal-body">
              <div>
                <span
                  className="send-ib-credentials-form__label"
                  data-testid="account-number-label">{`${t("generic:account-number")}:`}</span>
                <span data-testid="account-number-value">{ibCredentials?.accountNumber ?? ""}</span>
              </div>
              <div>
                <span
                  className="send-ib-credentials-form__label"
                  data-testid="username-label">{`${t("generic:username")}:`}</span>
                <span data-testid="username-value">{ibCredentials?.username ?? ""}</span>
              </div>
              <div className="send-ib-credentials-form__input">
                <RideInput
                  onKeyDown={(event) => {
                    if (event["key"] === "Enter") event.preventDefault();
                  }}
                  type={"password"}
                  name="password"
                  label={t("generic:password")}
                  formik={formik}
                />
              </div>
            </div>
          </Form>
        </FormikProvider>
      }>
      {(setVisibility) =>
        newBroker ? undefined : (
          <RideButtonDeprecated
            width={ButtonWidth.FULL}
            disabled={
              !(
                order?.extra?.ibCredentials?.accountNumber && order?.extra?.ibCredentials?.username
              ) || order.extra?.isNewBrokerEnabled
            }
            data-testid="send-password-button"
            onClick={() => setVisibility(true)}>
            {order?.extra?.onboarding?.applicationReviewed
              ? t("generic:low-tax-broker-order.resend-password")
              : t("generic:low-tax-broker-order.send-password")}
          </RideButtonDeprecated>
        )
      }
    </RideModalDeprecated>
  );
};

const SendIBCredentialsFormSchema = (t) => {
  return Yup.object().shape({
    password: Yup.string().required(t("generic:validation-required"))
  });
};

export default withTranslationReady(["generic"])(SendIBCredentialsForm);
