import * as Yup from "yup";
import { LegalForm } from "../../global-query-types";
import { ensureVatValidation } from "../../common/vatValidator";
import { cityRegex, postalCode } from "./common";

export const AddSPVCompanySchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("generic:validation-required"),
    projectName: Yup.string().required("generic:validation-required"),
    legalForm: Yup.mixed<LegalForm>().oneOf(Object.values(LegalForm)),
    capitalContribution: Yup.number()
      .positive("generic:must-be-positive-number")
      .required("generic:validation-required"),
    street: Yup.string().required("generic:validation-required"),
    tokenized: Yup.string(),
    tokenizedShares: Yup.number().when("tokenized", {
      is: "YES",
      then: Yup.number()
        .min(1, "spv-company:non-zero")
        .max(Yup.ref("capitalContribution"), "spv-company:tokenizedShares-limit")
        .required("generic:validation-required"),
      otherwise: Yup.number()
        .min(0, "spv-company:non-tokenized-share")
        .max(0, "spv-company:non-tokenized-share")
        .required("generic:validation-required")
    }),
    registration: Yup.string().required("generic:validation-required"),
    registerCourt: Yup.string().required("generic:validation-required"),
    vatNumber: Yup.string().test("vatValidator", "generic:invalid-vatNumber", ensureVatValidation),
    taxNumber: Yup.string(),
    city: Yup.string()
      .matches(cityRegex, { message: "generic:validation-only-letters-spaces-dash" })
      .required("generic:validation-required"),
    boxLink: Yup.string().url("generic:expected-url").required("generic:validation-required"),
    postalCode: Yup.string()
      .matches(postalCode, { message: "generic:validation-only-numbers" })
      .required("generic:validation-required")
  });
};
