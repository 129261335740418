export interface RegisterCourtOption {
  value: string;
  label: string;
}

export const registerCourt = [
  { value: "Aachen", label: "Aachen" },
  { value: "Altenb", label: "Altenburg" },
  { value: "Amberg", label: "Amberg" },
  { value: "Ansbac", label: "Ansbach" },
  { value: "Apolda", label: "Apolda" },
  { value: "Arnsbe", label: "Arnsberg" },
  { value: "Arnsta", label: "Arnstadt" },
  { value: "ArnZwI", label: "Arnstadt Zweigstelle Ilmenau" },
  { value: "Aschaf", label: "Aschaffenburg" },
  { value: "Augsbu", label: "Augsburg" },
  { value: "Aurich", label: "Aurich" },
  { value: "Bad He", label: "Bad Hersfeld" },
  { value: "Bad Ho", label: "Bad Homburg v.d.H." },
  { value: "Bad Kr", label: "Bad Kreuznach" },
  { value: "Bad Oe", label: "Bad Oeynhausen" },
  { value: "Bad Sa", label: "Bad Salzungen" },
  { value: "Bamber", label: "Bamberg" },
  { value: "Bayreu", label: "Bayreuth" },
  { value: "Berlin", label: "Charlottenburg" },
  { value: "Bielef", label: "Bielefeld" },
  { value: "Bochum", label: "Bochum" },
  { value: "Bonn", label: "Bonn" },
  { value: "Brauns", label: "Braunschweig" },
  { value: "Bremen", label: "Bremen" },
  { value: "Chemni", label: "Chemnitz" },
  { value: "Coburg", label: "Coburg" },
  { value: "Coesfe", label: "Coesfeld" },
  { value: "Cottbu", label: "Cottbus" },
  { value: "Darmst", label: "Darmstadt" },
  { value: "Deggen", label: "Deggendorf" },
  { value: "Dortmu", label: "Dortmund" },
  { value: "Dresde", label: "Dresden" },
  { value: "Duisbu", label: "Duisburg" },
  { value: "Düren", label: "Düren" },
  { value: "Düssel", label: "Düsseldorf" },
  { value: "Eisena", label: "Eisenach" },
  { value: "Erfurt", label: "Erfurt" },
  { value: "Eschwe", label: "Eschwege" },
  { value: "Essen", label: "Essen" },
  { value: "Flensb", label: "Flensburg" },
  { value: "Frankf", label: "Frankfurt am Main" },
  { value: "Franko", label: "Frankfurt/Oder" },
  { value: "Freibu", label: "Freiburg" },
  { value: "Friedb", label: "Friedberg" },
  { value: "Fritzl", label: "Fritzlar" },
  { value: "Fulda", label: "Fulda" },
  { value: "Fürth", label: "Fürth" },
  { value: "Gelsen", label: "Gelsenkirchen" },
  { value: "Gera", label: "Gera" },
  { value: "Gießen", label: "Gießen" },
  { value: "Gotha", label: "Gotha" },
  { value: "Göttin", label: "Göttingen" },
  { value: "Greiz", label: "Greiz" },
  { value: "Güters", label: "Gütersloh" },
  { value: "Hagen ", label: "Hagen" },
  { value: "Hambur", label: "Hamburg" },
  { value: "Hamm", label: "Hamm" },
  { value: "Hanau", label: "Hanau" },
  { value: "Hannov", label: "Hannover" },
  { value: "Heilba", label: "Heilbad Heiligenstadt" },
  { value: "Hildbu", label: "Hildburghausen" },
  { value: "Hildes", label: "Hildesheim" },
  { value: "Hof", label: "Hof" },
  { value: "Hombur", label: "Homburg" },
  { value: "Ingols", label: "Ingolstadt" },
  { value: "Iserlo", label: "Iserlohn" },
  { value: "Jena", label: "Jena" },
  { value: "Kaiser", label: "Kaiserslautern" },
  { value: "Kassel", label: "Kassel" },
  { value: "Kempte", label: "Kempten (Allgäu)" },
  { value: "Kiel", label: "Kiel" },
  { value: "Kleve", label: "Kleve" },
  { value: "Koblen", label: "Koblenz" },
  { value: "Köln", label: "Köln" },
  { value: "Königs", label: "Königstein" },
  { value: "Korbac", label: "Korbach" },
  { value: "Krefel", label: "Krefeld" },
  { value: "Landau", label: "Landau" },
  { value: "Landsh", label: "Landshut" },
  { value: "Langen", label: "Langenfeld" },
  { value: "Lebach", label: "Lebach" },
  { value: "Leipzi", label: "Leipzig" },
  { value: "Lemgo", label: "Lemgo" },
  { value: "Limbur", label: "Limburg" },
  { value: "Lübeck", label: "Lübeck" },
  { value: "Ludwig", label: "Ludwigshafen a.Rhein (Ludwigshafen)" },
  { value: "Lünebu", label: "Lüneburg" },
  { value: "Mainz", label: "Mainz" },
  { value: "Mannhe", label: "Mannheim" },
  { value: "Marbur", label: "Marburg" },
  { value: "Meinin", label: "Meiningen" },
  { value: "Memmin", label: "Memmingen" },
  { value: "Merzig", label: "Merzig" },
  { value: "Mönche", label: "Mönchengladbach" },
  { value: "Montab", label: "Montabaur" },
  { value: "Mühlha", label: "Mühlhausen" },
  { value: "Münche", label: "München" },
  { value: "Münste", label: "Münster" },
  { value: "Neubra", label: "Neubrandenburg" },
  { value: "Neunki", label: "Neunkirchen" },
  { value: "Neurup", label: "Neuruppin" },
  { value: "Neuss", label: "Neuss" },
  { value: "Nordha", label: "Nordhausen" },
  { value: "Nürnbe", label: "Nürnberg" },
  { value: "Offenb", label: "Offenbach am Main" },
  { value: "Oldenb", label: "Oldenburg (Oldenburg)" },
  { value: "Osnabr", label: "Osnabrück" },
  { value: "Ottwei", label: "Ottweiler" },
  { value: "Paderb", label: "Paderborn" },
  { value: "Passau", label: "Passau" },
  { value: "Pinneb", label: "Pinneberg" },
  { value: "Pößnec", label: "Pößneck" },
  { value: "PößZwBL", label: "Pößneck Zweigstelle Bad Lobenstein" },
  { value: "Potsda", label: "Potsdam" },
  { value: "Reckli", label: "Recklinghausen" },
  { value: "Regens", label: "Regensburg" },
  { value: "Rostoc", label: "Rostock" },
  { value: "RudZwS", label: "Rudolstadt" },
  { value: "Rudols", label: "Rudolstadt Zweigstelle Saalfeld" },
  { value: "Saarbr", label: "Saarbrücken" },
  { value: "Saarlo", label: "Saarlouis" },
  { value: "Schwei", label: "Schweinfurt" },
  { value: "Schwer", label: "Schwerin" },
  { value: "Siegbu", label: "Siegburg" },
  { value: "Siegen", label: "Siegen" },
  { value: "Sömmer", label: "Sömmerda" },
  { value: "Sonder", label: "Sondershausen" },
  { value: "Sonneb", label: "Sonneberg" },
  { value: "Stadth", label: "Stadthagen" },
  { value: "Stadtr", label: "Stadtroda" },
  { value: "Steinf", label: "Steinfurt" },
  { value: "Stenda", label: "Stendal" },
  { value: "St.Ingber", label: "St. Ingbert (St Ingbert)" },
  { value: "Strals", label: "Stralsund" },
  { value: "Straub", label: "Straubing" },
  { value: "Stuttg", label: "Stuttgart" },
  { value: "St.We", label: "St. Wendel (St Wendel)" },
  { value: "Suhl", label: "Suhl" },
  { value: "Tosted", label: "Tostedt" },
  { value: "Trauns", label: "Traunstein" },
  { value: "Ulm", label: "Ulm" },
  { value: "Völkli", label: "Völklingen" },
  { value: "Walsro", label: "Walsrode" },
  { value: "Weiden", label: "Weiden i. d. OPf." },
  { value: "Weimar", label: "Weimar" },
  { value: "Wetzla", label: "Wetzlar" },
  { value: "Wiesba", label: "Wiesbaden" },
  { value: "Wittli", label: "Wittlich" },
  { value: "Wupper", label: "Wuppertal" },
  { value: "Würzbu", label: "Würzburg" },
  { value: "Zweibr", label: "Zweibrücken" }
];
