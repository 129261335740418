import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import { TaskStatesEnum } from "../../../../../../global-query-types";
import { ActionableTask } from "../../../../../../common/types/ProcessStatesTypes";
import { Container, Image } from "react-bootstrap";
import { TFunction } from "i18next";
import { FiCheck } from "react-icons/fi";
import { DescriptionWithEmails } from "../../../../../../sharedComponents/DescriptionWithEmails/DescriptionWithEmails";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export interface TaskContainerProps extends PropsWithChildren<WithTranslation> {
  task: ActionableTask;
  testid: string;
  translationKey: string;
  disabled?: boolean;
  onChange?: (state: TaskStatesEnum) => Promise<void>;
  iconPath?: string;
  useLargeIcon?: boolean;
}

const TaskContainer = ({
  task,
  testid,
  translationKey,
  disabled,
  children,
  onChange,
  iconPath,
  useLargeIcon,
  t
}: TaskContainerProps) => (
  <Container className="rounded-sm task-container" data-testid={`${testid}-container`}>
    <div className="task-container__header">
      <h1 className="task-container__title" data-testid={`${testid}-title`}>
        {t(`company-founding-steps:tasks.name.${task.ref}`)}
      </h1>

      {iconPath && (
        <div className="task-container__icon-wrapper">
          <Image
            data-testid={`${testid}-icon`}
            src={iconPath}
            className={`task-container__icon ${useLargeIcon ? `task-container__icon--large` : ""}`}
          />
        </div>
      )}
    </div>

    <div data-testid={`${testid}-message`} className="task-container__description">
      <DescriptionWithEmails content={t(`company-founding-steps:tasks.description.${task.ref}`)} />
    </div>

    {children}

    <div className="task-container__buttons">
      {task.state === TaskStatesEnum.COMPLETED && (
        <CompletedButtons
          translationKey={translationKey}
          disabled={disabled}
          testid={testid}
          onChange={onChange}
          t={t}
        />
      )}
      {task.state === TaskStatesEnum.TO_BE_DONE && (
        <ToBeDoneButtons
          translationKey={translationKey}
          disabled={disabled}
          testid={testid}
          onChange={onChange}
          t={t}
        />
      )}
    </div>
  </Container>
);

interface ButtonsProps {
  onChange?: (state: TaskStatesEnum) => Promise<void>;
  testid: string;
  disabled?: boolean;
  translationKey: string;
  t: TFunction;
}

const CompletedButtons = ({ onChange, translationKey, disabled, testid, t }: ButtonsProps) => {
  const type = onChange ? "button" : "submit";
  const onClick = onChange ? () => onChange?.(TaskStatesEnum.TO_BE_DONE) : undefined;

  return (
    <>
      <div className="task-container__completed-label">
        <FiCheck size="42" className="d-inline d-md-none" />
        <FiCheck size="33" className="d-none d-md-inline-block" />
        <div className="d-block mt-n3 mt-md-0 d-md-inline-block ml-md-2">
          {t(`company-founding-steps:tasks-states.COMPLETED`)}
        </div>
      </div>

      <div className="task-container__button-completed-container">
        <RideButtonDeprecated
          data-testid={`${testid}-mark-as-not-done`}
          type={type}
          variant={ButtonVariant.Grey}
          disabled={disabled}
          onClick={onClick}>
          {t(`company-founding-steps:tasks.not-done-btn.${translationKey}`)}
        </RideButtonDeprecated>
      </div>
    </>
  );
};

const ToBeDoneButtons = ({ onChange, testid, translationKey, disabled, t }: ButtonsProps) => {
  const type = onChange ? "button" : "submit";
  const onClick = onChange ? () => onChange?.(TaskStatesEnum.COMPLETED) : undefined;

  return (
    <RideButtonDeprecated
      data-testid={`${testid}-task-done`}
      variant={ButtonVariant.Primary}
      size={ButtonSize.Big}
      type={type}
      disabled={disabled}
      onClick={onClick}>
      {t(`company-founding-steps:tasks.done-btn.${translationKey}`)}
    </RideButtonDeprecated>
  );
};

export default withTranslationReady(["company-founding-steps"])(TaskContainer);
