import React, { useEffect, useRef } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  ButtonHeight,
  ButtonIntensity,
  ButtonRadius,
  ButtonTextVariant,
  ButtonVariant,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AnimationFactory } from "../../animations/AnimationFactory";

export enum ConfirmationModalType {
  SUCCESS = "success",
  REGULAR = "regular",
  DANGER = "danger"
}

export interface ConfirmationModalProps {
  dataTestId?: string;
  illustration?: string;
  animation?: AnimationFactory;
  title: string;
  message: string | React.ReactElement;
  type: ConfirmationModalType;
  confirmLabel: string;
  visible: boolean;
  onConfirm: () => Promise<void> | void;
  onClose: () => void;
}

export const ConfirmationModal = ({
  dataTestId = "confirm-action-modal",
  illustration,
  animation,
  title,
  message,
  type,
  confirmLabel,
  visible,
  onConfirm,
  onClose
}: ConfirmationModalProps) => {
  const backgroundRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    animation?.animate(backgroundRef.current);
  }, [animation, backgroundRef, visible]);

  const hide = () => {
    onClose();
  };

  const confirm = async () => {
    await onConfirm();
    onClose();
  };

  return visible ? (
    <div
      className="confirm-action-modal__background"
      data-testid="confirm-action-modal-background"
      ref={backgroundRef}>
      <div
        data-testid={dataTestId}
        className={`confirm-action-modal ${illustration ? "confirm-action-modal--centered" : ""}`}>
        {illustration && <div className={`confirm-action-modal__illustration--${illustration}`} />}

        <div
          data-testid="confirm-action-modal-title"
          className={
            illustration
              ? "confirm-action-modal__title confirm-action-modal__title--with-illustration"
              : "confirm-action-modal__title"
          }>
          {title}
        </div>
        <div data-testid="confirm-action-modal-message" className="confirm-action-modal__message">
          {message}
        </div>
        <div className="confirm-action-modal__button-container">
          {type !== ConfirmationModalType.SUCCESS && <CancelButton onClick={hide} />}
          <ConfirmButton type={type} onClick={confirm} label={confirmLabel} />
        </div>
      </div>
    </div>
  ) : null;
};

interface CancelButtonProps extends WithTranslation {
  onClick: () => void;
}

interface ConfirmButtonProps {
  type: ConfirmationModalType;
  label: string;
  onClick: () => void;
}

const CancelButton = withTranslation(["generic"])(({ t, onClick }: CancelButtonProps) => {
  return (
    <RideButtonDeprecated
      data-testid="confirm-action-modal-button-cancel"
      type="button"
      variant={ButtonVariant.Secondary}
      intensity={ButtonIntensity.Soft}
      radius={ButtonRadius.Soft}
      width={ButtonWidth.FULL}
      height={ButtonHeight.LARGE}
      textVariant={ButtonTextVariant.V2}
      onClick={onClick}>
      {t("generic:cancel")}
    </RideButtonDeprecated>
  );
});

const ConfirmButton = withTranslation(["generic"])(
  ({ label, type, onClick }: ConfirmButtonProps) => {
    const { variant, intensity, textVariant } = getPropsForActionType(type);

    return (
      <RideButtonDeprecated
        data-testid={`confirm-action-modal-button-${type}`}
        type="button"
        variant={variant}
        intensity={intensity}
        radius={ButtonRadius.Soft}
        width={ButtonWidth.FULL}
        height={ButtonHeight.LARGE}
        textVariant={textVariant}
        onClick={onClick}>
        {label}
      </RideButtonDeprecated>
    );
  }
);

const getPropsForActionType = (
  type: ConfirmationModalType
): { variant: ButtonVariant; intensity: ButtonIntensity; textVariant: ButtonTextVariant } => {
  switch (type) {
    case ConfirmationModalType.REGULAR:
      return {
        variant: ButtonVariant.Primary,
        intensity: ButtonIntensity.Strong,
        textVariant: ButtonTextVariant.V2
      };
    case ConfirmationModalType.DANGER:
      return {
        variant: ButtonVariant.Danger,
        intensity: ButtonIntensity.Soft,
        textVariant: ButtonTextVariant.V2
      };
    case ConfirmationModalType.SUCCESS:
      return {
        variant: ButtonVariant.Primary,
        intensity: ButtonIntensity.Strong,
        textVariant: ButtonTextVariant.V2Medium
      };
  }
};
