import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { NOMINEE_SETUP_WALLET } from "./Mutations";
import RequestFeedbackHandler from "../../generic/RequestFeedbackHandler";
import NomineeCreateWallet, { ExistingWallet, PersonIdOrCompanyId } from "./NomineeCreateWallet";
import { NomineeSetupWallet, NomineeSetupWalletVariables } from "./graphql/NomineeSetupWallet";

interface Props extends PersonIdOrCompanyId {
  existingWallet: ExistingWallet;
  refetch: () => Promise<any>;
}

export const NomineeCreateWalletContainer = (props: Props) => {
  const [callSetupWallet, setupWalletRequest] = useMutation<
    NomineeSetupWallet,
    NomineeSetupWalletVariables
  >(NOMINEE_SETUP_WALLET);

  const setupWallet = async ({ companyId, personId }: PersonIdOrCompanyId) => {
    await callSetupWallet({ variables: { companyId, personId } });
  };

  return (
    <RequestFeedbackHandler requests={[setupWalletRequest]}>
      <NomineeCreateWallet {...props} setupWallet={setupWallet} />
    </RequestFeedbackHandler>
  );
};
