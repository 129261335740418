import React, { PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AddIcon } from "../../../sharedComponents/icons/add";

export interface ModalFormProps extends PropsWithChildren<{}> {
  buttonLabel: string;
  title: string;
  buttonVariant: ButtonVariant;
  buttonSize: ButtonSize;
  isAdd?: boolean;
  isOpen: boolean;
  requestModalState: (isOpen: boolean) => void;
  "data-testid"?: string;
}

export const ModalForm = (props: ModalFormProps) => {
  const {
    buttonLabel,
    buttonVariant,
    buttonSize,
    isAdd,
    title,
    isOpen,
    requestModalState,
    children
  } = props;

  const hideModal = () => requestModalState(false);
  const showModal = () => requestModalState(true);

  return (
    <>
      <RideButtonDeprecated
        hasIcon={isAdd}
        size={buttonSize}
        data-testid={`${props["data-testid"] ?? ""}open-modal-button`}
        variant={buttonVariant}
        onClick={showModal}>
        {isAdd && <AddIcon />}
        {buttonLabel}
      </RideButtonDeprecated>

      <Modal data-testid="modal" size="xl" show={isOpen} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title data-testid="modal-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};
