import { ValueFormatter } from "./ValueFormatter";

export class PercentageFormatter extends ValueFormatter {
  format(value, ...args) {
    let maximumFractionDigits = 2;

    if (!value && value !== 0) return super.format(value, ...args);
    if (Array.isArray(args) && args.length > 0 && args[0]["precision"])
      maximumFractionDigits = args[0]["precision"];

    const locale = this.getLocale(args);
    const options = {
      style: "percent",
      maximumFractionDigits
    };
    const intl = new Intl.NumberFormat(locale, options);
    return intl.format(value);
  }
}
