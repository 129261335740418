import {
  FieldHookConfig,
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField
} from "formik";

type RideFieldProps<Val> = FieldInputProps<Val> & FieldMetaProps<Val> & FieldHelperProps<Val>;

export const useRideField = <Val = any>(
  propsOrFieldName: string | FieldHookConfig<Val>
): RideFieldProps<Val> => {
  const [field, meta, helpers] = useField(propsOrFieldName);

  return {
    ...helpers,
    ...meta,
    ...field
  };
};
