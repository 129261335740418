import React from "react";
import { useParams } from "react-router-dom";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { GetWebForm } from "../../../../lib/api/queries/GetWebForm";
import BankAccountWebForm from "./BankAccountWebForm";

const BankAccountWebFormContainer = () => {
  const { companyId, iban, bankAccountId } =
    useParams<{ companyId: string; iban: string; bankAccountId: string }>();
  const getRequest = GetWebForm(iban ?? "", companyId, bankAccountId);
  const url = getRequest.data?.getWebForm?.url ?? undefined;

  return (
    <RequestFeedbackHandler requests={[getRequest]}>
      <BankAccountWebForm
        url={url}
        bankAccountId={bankAccountId}
        companyId={companyId}
        iban={iban}
      />
    </RequestFeedbackHandler>
  );
};

export default BankAccountWebFormContainer;
