import React from "react";
import AdvertisementCampaignBanner from "client/components/AdvertisementCampaignBanner/AdvertisementCampaignBanner";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";

interface LTBContinueOrderBanner {
  companyId?: string | null;
}

const LTBContinueOrderBanner = ({ t, companyId }: LTBContinueOrderBanner & WithTranslation) => {
  return (
    <AdvertisementCampaignBanner
      dataTestId="ltb-continue-order-banner"
      title={t(rideBrokerT("generic:advertisement-campaign-banner.continue-ltb-order.title"))}
      description={t(
        rideBrokerT("generic:advertisement-campaign-banner.continue-ltb-order.description")
      )}
      moreInfoText={t("generic:advertisement-campaign-banner.continue-ltb-order.footer-text")}
      moreInfoUrl={`/client/entities/${companyId}/invest`}
      openInCurrentTab={true}
    />
  );
};

export default withTranslationReady(["generic"])(LTBContinueOrderBanner);
