import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { deepClone } from "common/deepClone";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LegalPersonType, OrderProductTypeEnum } from "global-query-types";
import { ShareholderPersonalInformationSchema } from "lib/validation/ShareholderPersonalInformationSchema";
import React, { useImperativeHandle } from "react";
import { WithTranslation } from "react-i18next";
import { NaturalPersonInputs } from "./NaturalPersonForm.partials";
import { CompanyFoundingOrder } from "lib/models/client/CompanyFounding/CompanyFoundingOrder";
import { splitPhoneNumberIntoComponents } from "../../../../../../lib/parsers/splitPhoneNumberIntoComponents";

const NaturalPersonForm = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef,
  "data-testid": dataTestId,
  entityKey,
  headlineContent = <></>
}: WithTranslation &
  StepComponentProps & {
    headlineContent?: React.ReactElement;
    entityKey: "shareholder" | "employee";
  }) => {
  const companyFounding = new CompanyFoundingOrder(order);

  const entityIndex = currentStep?.chapter?.index ?? 0;
  let dictionaryCopy = order.extra[`${entityKey}s`] ? deepClone(order.extra[`${entityKey}s`]) : {};

  const entity = order.extra[`${entityKey}s`]?.[entityIndex] ?? {};
  const personData = entity.personData ?? {};

  const initialValues = {
    firstName: personData?.firstName ?? "",
    middleName: personData?.middleName ?? "",
    lastName: personData?.lastName ?? "",
    phoneNumber: personData?.phoneNumber ?? "",
    email: personData?.email ?? "",
    authorizedToShareShareholderInfo: personData?.authorizedToShareShareholderInfo ?? false
  };

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    currentStep.complete();

    if (entityKey === "shareholder") {
      entity.shareholderType = LegalPersonType.Person;
    }

    const { phoneNumberWithoutCountry, phoneNumber, phoneCountry } = splitPhoneNumberIntoComponents(
      values.phoneNumber
    );

    entity.personData = {
      ...values,
      phoneNumber,
      phoneCountry,
      phoneNumberWithoutCountry
    };
    dictionaryCopy[entityIndex] = entity;

    await saveData(
      {
        ...order.extra,
        [`${entityKey}s`]: dictionaryCopy,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const otherEmails = companyFounding.getEmails({ key: entityKey, index: entityIndex });
  const isLTBFlow = order.productType === "BrokerageAccount";

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: ShareholderPersonalInformationSchema(t, otherEmails, isLTBFlow)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form" data-testid="natural-person-form">
        <div className="natural-person subchapter-order-layout__scrollable">
          {headlineContent}
          <NaturalPersonInputs
            formik={formik}
            dataTestId={dataTestId}
            disableEmail={
              order.productType === OrderProductTypeEnum.BrokerageAccount &&
              entity.thisShareholderRepresentsTheUserOrdering
            }
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic", "errorCodes"])(NaturalPersonForm);
