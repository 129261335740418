import { useQuery } from "react-apollo";
import { COMPANY_DETAILS_BY_COMPANY_ID } from "./Queries";
import {
  companyDetailsByCompanyId,
  companyDetailsByCompanyIdVariables
} from "./graphql/companyDetailsByCompanyId";

export const GetCompanyInfoByCompanyId = (companyId: string) => {
  const companyDetails = useQuery<companyDetailsByCompanyId, companyDetailsByCompanyIdVariables>(
    COMPANY_DETAILS_BY_COMPANY_ID,
    {
      variables: { companyId: companyId }
    }
  );
  return companyDetails;
};
