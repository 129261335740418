import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { Trans, WithTranslation } from "react-i18next";
import React from "react";
import { Form, FormikProvider } from "formik";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import { useRideFormik } from "lib/hooks/useRideFormik";
import {
  MultiSelectOption,
  RideMultiSelect
} from "uiLibrary/components/RideMultiSelect/RideMultiSelect";
import { InvestmentObjectiveType } from "lib/models/client/LowTaxBroker/InvestmentObjective";

const InvestmentObjectivesStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const validCombinations = [
    [
      InvestmentObjectiveType.GROWTH,
      InvestmentObjectiveType.HEDGING,
      InvestmentObjectiveType.SPECULATION,
      InvestmentObjectiveType.TRADING_PROFITS
    ],
    [
      InvestmentObjectiveType.GROWTH,
      InvestmentObjectiveType.HEDGING,
      InvestmentObjectiveType.SPECULATION
    ],
    [InvestmentObjectiveType.GROWTH, InvestmentObjectiveType.SPECULATION],
    [InvestmentObjectiveType.GROWTH, InvestmentObjectiveType.TRADING_PROFITS],
    [InvestmentObjectiveType.HEDGING, InvestmentObjectiveType.TRADING_PROFITS],
    [InvestmentObjectiveType.HEDGING, InvestmentObjectiveType.SPECULATION],
    [
      InvestmentObjectiveType.HEDGING,
      InvestmentObjectiveType.SPECULATION,
      InvestmentObjectiveType.TRADING_PROFITS
    ],
    [
      InvestmentObjectiveType.INCOME,
      InvestmentObjectiveType.GROWTH,
      InvestmentObjectiveType.PRESERVATION_OF_CAPITAL
    ],
    [
      InvestmentObjectiveType.INCOME,
      InvestmentObjectiveType.GROWTH,
      InvestmentObjectiveType.HEDGING,
      InvestmentObjectiveType.PRESERVATION_OF_CAPITAL
    ]
  ];

  const formik = useRideFormik({
    initialValues: {
      ...order.extra.investmentObjectives
    },
    onSubmit: async (values) => {
      currentStep.complete();

      await saveData({
        ...order.extra,
        ...currentStep.chapter.order.serialize(),
        investmentObjectives: values
      });
    },
    validate(values) {
      let selectedInvestmentObjectives = Object.keys(values).filter(
        (investmentObjective) => !!values[investmentObjective]
      );

      if (selectedInvestmentObjectives.length === 0) {
        return { error: t("generic:validation-required") };
      } else if (selectedInvestmentObjectives.length === 1) {
        return;
      }

      let validCombination = validCombinations.some(
        (combination) =>
          combination.length === selectedInvestmentObjectives.length &&
          combination.every((investmentObjective) =>
            selectedInvestmentObjectives.includes(investmentObjective)
          )
      );

      if (!validCombination) {
        return { error: t("generic:invalid-investment-objectives-combination") };
      }
    }
  });

  const options: MultiSelectOption[] = Object.values(InvestmentObjectiveType).map(
    (investmentObjective) => ({
      label: (
        <Trans
          i18nKey={`generic:ride-broker-onboarding.investment-objectives.options.${investmentObjective}`}
        />
      ),
      name: investmentObjective
    })
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.investment-objectives.title")} />
        <RideMultiSelect options={options} columns={1} />
        <InvalidFeedback hidden={!formik.errors["error"]} testPrefix="error">
          {formik.errors["error"]}
        </InvalidFeedback>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady("generic")(InvestmentObjectivesStep);
