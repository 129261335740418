import React from "react";
import { TFunction } from "i18next";
import { AlertMessage, AlertType } from "../../../sharedComponents/AlertMessage/AlertMessage";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { useHistory } from "react-router-dom";

export const ThirdPartyTaxAdvisorInviteViaEmailSuccess = ({ t }: { t: TFunction }) => {
  const history = useHistory();

  const closeButtonHandler = () => {
    history.push("/third-party-tax-advisor/clients");
  };
  return (
    <>
      <AlertMessage type={AlertType.success}>{t("invite-clients:email.success-text")}</AlertMessage>
      <div className="d-flex align-items-center mt-25 mt-md-55 flex-wrap justify-content-md-between">
        <div className="mb-25 mb-md-0">
          <TextLink
            variant={LinkVariant.secondary}
            to={"/third-party-tax-advisor/invite-clients/email/"}>
            {t("invite-clients:email.invite-another")}
          </TextLink>
        </div>
        <RideButtonDeprecated
          data-testid="invite-client-submit-button"
          variant={ButtonVariant.Primary}
          size={ButtonSize.Big}
          onClick={closeButtonHandler}>
          {t("invite-clients:email.close")}
        </RideButtonDeprecated>
      </div>
    </>
  );
};
