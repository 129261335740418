import { TFunction } from "i18next";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatValue } from "../../../common/formatters";
import { formatCompanyAddress } from "../../../common/formatters/formatCompanyAddress";
import { GetTaxAdvisorClientCompany_getTaxAdvisorClientCompany } from "../../../lib/api/queries/graphql/GetTaxAdvisorClientCompany";
import { ValueView } from "../layout/ValueView";
import { NomineeCompanyDetails_companyById } from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { formatRegisterCourt } from "../../../common/formatters/registerCourtFormatter";

interface CompanyDetailsSectionProps {
  t: TFunction;
  company:
    | NomineeCompanyDetails_companyById
    | GetTaxAdvisorClientCompany_getTaxAdvisorClientCompany;
}

export const BasicCompanyDetails = ({ company, t }: CompanyDetailsSectionProps) => {
  const address = company.businessAddress;

  return (
    <>
      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company.name"
            testId="name"
            value={company.name || t("generic:unnamed")}
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="nominee-dashboard:company.legal-form"
            testId="legal-form"
            value={t(`generic:legalForm.${company.legalForm}`)}
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company.register-court"
            testId="register-court"
            value={formatValue(formatRegisterCourt(company.registerCourt!))}
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="nominee-dashboard:company.registration"
            testId="registration"
            value={company.registration ?? ""}
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:company.business-address"
            testId="address"
            value={formatCompanyAddress({
              t,
              name: "",
              careOf: address.careOf,
              street: address.streetLine2
                ? `${address.streetLine2} ${address.street}`
                : address.street,
              postalCode: address.postalCode,
              city: address.city,
              countryCode: address.country
            })}
            t={t}
          />
        </Col>
      </Row>
    </>
  );
};
