import { FlowTypeEnum } from "../../../global-query-types";

export const flowType = {
  GMBH: FlowTypeEnum.GMBH,
  FOUNDER: FlowTypeEnum.FOUNDER_HOLDING,
  INVESTOR: FlowTypeEnum.INVESTOR_HOLDING,
  TRADER: FlowTypeEnum.TRADER_HOLDING,
  ADMIN_SERVICE: FlowTypeEnum.ADMIN_SERVICE,
  ADMIN_SERVICE_ONBOARDING: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
  REAL_ESTATE: FlowTypeEnum.REAL_ESTATE_GMBH,
  HOLDING: FlowTypeEnum.HOLDING_GMBH
};

export const getFlowTypePath = (flowType: FlowTypeEnum) => {
  if (flowType === "INVESTOR_HOLDING") {
    return "investor-gmbh";
  }
  if (flowType === "TRADER_HOLDING") {
    return "trader-gmbh";
  }
  if (flowType === "FOUNDER_HOLDING") {
    return "mini-holding";
  }
  if (flowType === "ADMIN_SERVICE") {
    return "admin-service";
  }
  if (flowType === "ADMIN_SERVICE_ONBOARDING") {
    return "admin-service/onboarding";
  }
  if (flowType === "REAL_ESTATE_GMBH") {
    return "real-estate-gmbh";
  }
  if (flowType === "HOLDING_GMBH") {
    return "holding-gmbh";
  }
  if (flowType === "GMBH") {
    return "booking/gmbh";
  }
};
