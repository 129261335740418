import * as Yup from "yup";

export const EmailValidation = Yup.string()
  .email("generic:validation-email")
  .required("generic:validation-required");

export const emailValidationWithTranslation = (t) =>
  Yup.string().email(t("generic:validation-email")).required(t("generic:validation-required"));

export const EmailValidationNonRequired = Yup.string().email("generic:validation-email");

export const EmailSchema = Yup.object().shape({
  email: EmailValidation
});

export default EmailSchema;
