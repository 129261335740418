import React from "react";
import { debounce, DebounceSettings } from "lodash";

// Adapted from https://stackoverflow.com/a/37312154
export const debounceEventHandler = <Event extends React.SyntheticEvent>(
  handler: (event: Event) => void,
  wait: number,
  options?: DebounceSettings
) => {
  const debounced = debounce(handler, wait, options);
  return (event: Event) => {
    event.persist();
    return debounced(event);
  };
};
