import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const AdminServiceIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="admin-service-icon"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5283_56)">
      <path
        d="M20.25 18.75H15.75C15.1533 18.75 14.581 18.9871 14.159 19.409C13.7371 19.831 13.5 20.4033 13.5 21V22.5H15V21C15 20.8011 15.079 20.6103 15.2197 20.4697C15.3603 20.329 15.5511 20.25 15.75 20.25H20.25C20.4489 20.25 20.6397 20.329 20.7803 20.4697C20.921 20.6103 21 20.8011 21 21V22.5H22.5V21C22.5 20.4033 22.2629 19.831 21.841 19.409C21.419 18.9871 20.8467 18.75 20.25 18.75Z"
        fill="currentColor"
      />
      <path
        d="M15 15C15 15.5933 15.1759 16.1734 15.5056 16.6667C15.8352 17.1601 16.3038 17.5446 16.8519 17.7716C17.4001 17.9987 18.0033 18.0581 18.5853 17.9424C19.1672 17.8266 19.7018 17.5409 20.1213 17.1213C20.5409 16.7018 20.8266 16.1672 20.9424 15.5853C21.0581 15.0033 20.9987 14.4001 20.7716 13.852C20.5446 13.3038 20.1601 12.8352 19.6667 12.5056C19.1734 12.1759 18.5933 12 18 12C17.2044 12 16.4413 12.3161 15.8787 12.8787C15.3161 13.4413 15 14.2044 15 15ZM19.5 15C19.5 15.2967 19.412 15.5867 19.2472 15.8334C19.0824 16.08 18.8481 16.2723 18.574 16.3858C18.2999 16.4994 17.9983 16.5291 17.7074 16.4712C17.4164 16.4133 17.1491 16.2704 16.9393 16.0607C16.7296 15.8509 16.5867 15.5836 16.5288 15.2926C16.4709 15.0017 16.5006 14.7001 16.6142 14.426C16.7277 14.1519 16.92 13.9176 17.1666 13.7528C17.4133 13.588 17.7033 13.5 18 13.5C18.3978 13.5 18.7794 13.658 19.0607 13.9393C19.342 14.2206 19.5 14.6022 19.5 15Z"
        fill="currentColor"
      />
      <path
        d="M4.5 15.75V15H3V15.75C3 17.1424 3.55312 18.4777 4.53769 19.4623C5.52225 20.4469 6.85761 21 8.25 21H10.5V19.5H8.25C7.25544 19.5 6.30161 19.1049 5.59835 18.4017C4.89509 17.6984 4.5 16.7446 4.5 15.75Z"
        fill="currentColor"
      />
      <path d="M19.5 7.5H14.25V9H19.5V7.5Z" fill="currentColor" />
      <path d="M21.75 4.5H14.25V6H21.75V4.5Z" fill="currentColor" />
      <path d="M21.75 1.5H14.25V3H21.75V1.5Z" fill="currentColor" />
      <path
        d="M8.25 8.25H3.75C3.15326 8.25 2.58097 8.48705 2.15901 8.90901C1.73705 9.33097 1.5 9.90326 1.5 10.5V12H3V10.5C3 10.3011 3.07902 10.1103 3.21967 9.96967C3.36032 9.82902 3.55109 9.75 3.75 9.75H8.25C8.44891 9.75 8.63968 9.82902 8.78033 9.96967C8.92098 10.1103 9 10.3011 9 10.5V12H10.5V10.5C10.5 9.90326 10.2629 9.33097 9.84099 8.90901C9.41903 8.48705 8.84674 8.25 8.25 8.25Z"
        fill="currentColor"
      />
      <path
        d="M6 7.5C6.59334 7.5 7.17336 7.32405 7.66671 6.99441C8.16006 6.66477 8.54458 6.19623 8.77164 5.64805C8.9987 5.09987 9.05811 4.49667 8.94236 3.91473C8.8266 3.33279 8.54088 2.79824 8.12132 2.37868C7.70176 1.95912 7.16721 1.6734 6.58527 1.55765C6.00333 1.44189 5.40013 1.5013 4.85195 1.72836C4.30377 1.95543 3.83524 2.33994 3.50559 2.83329C3.17595 3.32664 3 3.90666 3 4.5C3 5.29565 3.31607 6.05871 3.87868 6.62132C4.44129 7.18393 5.20435 7.5 6 7.5ZM6 3C6.29667 3 6.58668 3.08797 6.83335 3.2528C7.08003 3.41762 7.27229 3.65189 7.38582 3.92598C7.49935 4.20007 7.52906 4.50167 7.47118 4.79264C7.4133 5.08361 7.27044 5.35088 7.06066 5.56066C6.85088 5.77044 6.58361 5.9133 6.29264 5.97118C6.00166 6.02906 5.70006 5.99935 5.42597 5.88582C5.15189 5.77229 4.91762 5.58003 4.7528 5.33336C4.58797 5.08668 4.5 4.79667 4.5 4.5C4.5 4.10218 4.65804 3.72065 4.93934 3.43934C5.22064 3.15804 5.60218 3 6 3Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5283_56">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
