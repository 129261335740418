import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import ClubDealLogo from "sharedComponents/ClubDealLogo/ClubDealLogo";
import ClubDealCard, { ClubDealCardProps } from "../../ClubDealCard/ClubDealCard";

export interface AvailableClubDealsListProps extends WithTranslation {
  clubDeals: ClubDealCardProps[];
  onSelect: (id: string) => void;
}

const AvailableClubDealsList = ({ t, clubDeals, onSelect }: AvailableClubDealsListProps) => {
  return (
    <div className="available-club-deals" data-testid="available-club-deals">
      <div className="available-club-deals__wrapper">
        <div className="available-club-deals__header">
          <ClubDealLogo />
          <div className="available-club-deals__title-header" data-testid="club-deals-logo">
            {t("client:investments.clubDeals.dealsList.investWithRide")}
          </div>
          <div className="available-club-deals__top-description">
            {clubDeals.length > 0 ? (
              <>
                {t("client:investments.clubDeals.dealsList.topDescription")}&nbsp;
                <TextLink variant={LinkVariant.primary} to="#">
                  {t("client:investments.clubDeals.dealsList.learnMore")}
                </TextLink>
              </>
            ) : (
              t("client:investments.clubDeals.dealsList.topDescriptionEmpty")
            )}
          </div>
        </div>
        <div className="available-club-deals__list">
          {clubDeals.map((clubDeal, index) => (
            <ClubDealCard key={index} onClick={() => onSelect(clubDeal.id)} {...clubDeal} />
          ))}
        </div>
        <div className="available-club-deals__bottom-description">
          {t("client:investments.clubDeals.dealsList.bottomDescription")}
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady("client")(AvailableClubDealsList);
