import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideModal, {
  RideModalBody,
  RideModalControls
} from "uiLibrary/designSystem/RideModal/RideModal";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  validateIban,
  validateIban_validateIban,
  validateIbanVariables
} from "lib/api/queries/graphql/validateIban";
import { VALIDATE_IBAN } from "lib/api/queries/validateIban";
import { useApolloClient } from "@apollo/react-hooks";
import { CompanyBankAccount } from "lib/models/client/LowTaxBroker/CompanyBankAccount";

interface UpdateIbanModalProps extends WithTranslation {
  iban?: string | null;
  onSubmit: (companyBankAccount: CompanyBankAccount) => Promise<void>;
  onClose: () => void;
}

const UpdateIbanModal = ({ t, iban, onSubmit, onClose }: UpdateIbanModalProps) => {
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  const formik = useFormik({
    initialValues: {
      iban
    },
    onSubmit: async (values) => {
      setLoading(true);
      const iban = values.iban ?? "";

      let ibanValidationResult: validateIban_validateIban | null;
      const validateIbanResult = await apolloClient.query<validateIban, validateIbanVariables>({
        query: VALIDATE_IBAN,
        variables: {
          iban
        }
      });

      ibanValidationResult = validateIbanResult?.data?.validateIban;

      if (ibanValidationResult && ibanValidationResult.isValid) {
        await onSubmit({
          iban,
          swiftBic: ibanValidationResult.bic ?? undefined,
          creditInstitutionName: ibanValidationResult.bankName ?? undefined
        });
        onClose();
        setLoading(false);
        return;
      } else {
        formik.setFieldError("iban", t("generic:invalid-iban"));
        setLoading(false);
      }
    },
    validationSchema: IbanEditableSectionValidationSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <RideModal
          data-testid="update-iban-modal"
          title={t("generic:update-bank-account")}
          onClose={onClose}>
          <RideModalBody>
            <RideInput name="iban" label={t("generic:iban")} formik={formik} />
          </RideModalBody>
          <RideModalControls>
            <RideButtonDeprecated
              width={ButtonWidth.FULL}
              radius={ButtonRadius.Soft}
              data-testid={"save-button"}
              type={"submit"}
              disabled={loading}>
              {t("generic:save")}
            </RideButtonDeprecated>
          </RideModalControls>
        </RideModal>
      </Form>
    </FormikProvider>
  );
};

const IbanEditableSectionValidationSchema = (t) => {
  return Yup.object().shape({
    iban: Yup.string().required(t("generic:validation-required"))
  });
};

export default withTranslationReady(["generic"])(UpdateIbanModal);
