import React from "react";
import RideOverview from "../../../sharedComponents/RideOverview/RideOverview";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { ProfileDetailsContainer } from "client/components/profile/ProfileDetails/ProfileDetailsContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

const MyProfileScene = ({ t }: WithTranslation) => {
  return (
    <>
      <PageMetaData title={t("client:profile.page-title")} />
      <RideOverview
        title={t("client:profile.title")}
        subtitle={t("client:profile.subtitle")}
        imageSrc={"/assets/client/profile/profile-header-icon.svg"}>
        <ProfileDetailsContainer />
      </RideOverview>
    </>
  );
};

export default withTranslationReady("client")(MyProfileScene);
