import React, { useState } from "react";
import {
  NO_OWNER_SELECTED,
  OwnerSelectField,
  OwnershipType,
  OwnershipTypeRadioField
} from "./fields";
import { OptionItem } from "../../generic/Select";
import { FormikProps } from "formik";
import { GetRealEstateOwners } from "./GetRealEstateOwners";
import { withTranslation, WithTranslation } from "react-i18next";
import { RealEstateOwnersQuery } from "./graphql/RealEstateOwnersQuery";
import { TFunction } from "i18next";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";

interface OwnershipFieldProps extends WithTranslation {
  formik: FormikProps<{ ownerId: string }>;
  currentCompanyId: string | null;
}

const OwnershipField: React.FunctionComponent<OwnershipFieldProps> = (props) => {
  const { t, formik, currentCompanyId } = props;
  const { data: ownerData } = GetRealEstateOwners();

  const initialOwnershipType = formik.values.ownerId
    ? OwnershipType.throughMyCompany
    : OwnershipType.personally;

  const [ownershipType, setOwnershipType] = useState(initialOwnershipType);

  const ownershipTypeOptions = [
    { label: t("realEstate:personally") },
    { label: t("realEstate:owner-via-my-company") }
  ];

  const noOwnerShouldBeSelected = ownershipType === OwnershipType.personally;
  const ownershipOptions = convertOwnerDataToOptions(t, ownerData).filter(
    (company) => company.value !== currentCompanyId
  );

  const handleOwnershipTypeChange = (event) => {
    const newOwnershipType: OwnershipType = +event.target["value"];
    setOwnershipType(newOwnershipType);

    const defaultOwnerId = newOwnershipType === OwnershipType.personally ? "" : NO_OWNER_SELECTED;
    formik.setFieldValue("ownerId", defaultOwnerId);
  };

  const handleSelectTouch = () => {
    if (ownershipType === OwnershipType.throughMyCompany) {
      return;
    }

    handleOwnershipTypeChange({ target: { value: OwnershipType.throughMyCompany } });
  };

  return (
    <Row>
      <Col xs={12} md={6}>
        <OwnershipTypeRadioField
          label={t("realEstate:property-i-own")}
          required={true}
          value={ownershipType}
          options={ownershipTypeOptions}
          handleChange={handleOwnershipTypeChange}
        />
      </Col>

      <Col xs={12} md={6}>
        <OwnerSelectField
          t={t}
          formik={formik}
          options={ownershipOptions}
          noOwnerShouldBeSelected={noOwnerShouldBeSelected}
          handleTouch={handleSelectTouch}
        />
      </Col>
    </Row>
  );
};

export const convertOwnerDataToOptions = (t: TFunction, data?: RealEstateOwnersQuery) => {
  const defaultOptions: OptionItem = {
    title: t("realEstate:select-company"),
    value: NO_OWNER_SELECTED,
    disabled: true
  };

  const ownerOptions: OptionItem[] =
    data?.availableRealEstateOwners
      ?.map(
        (owner) =>
          ({
            title: owner.title,
            value: owner.companyId
          } as OptionItem)
      )
      ?.filter((option) => !!option.value) || [];

  return [defaultOptions, ...ownerOptions];
};

export const OwnershipFieldValidationSchema = {
  ownerId: Yup.string().test(
    "ownerValidator",
    "generic:owner-company-not-selected",
    (value) => value !== NO_OWNER_SELECTED
  )
};

export default withTranslation(["realEstate"])(OwnershipField);
