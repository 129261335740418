import React from "react";
import ProfilePasswordEdit from "./ProfilePasswordEdit";
import { useMutation } from "react-apollo";
import { CHANGE_PASSWORD } from "../../../../lib/api/mutations/CHANGE_PASSWORD";
import {
  ChangePassword,
  ChangePasswordVariables
} from "../../../../lib/api/mutations/graphql/ChangePassword";
import logger from "../../../../common/Logger";
import { Login, LoginVariables } from "../../../../lib/api/mutations/graphql/Login";
import { LOGIN } from "../../../../lib/api/mutations/LOGIN";
import { UserService } from "../../../../lib/services/UserService/UserService";

export const ProfilePasswordEditContainer = () => {
  const [loginMutation] = useMutation<Login, LoginVariables>(LOGIN);

  const [changePassword, changePasswordRequest] = useMutation<
    ChangePassword,
    ChangePasswordVariables
  >(CHANGE_PASSWORD);
  const email = UserService.instance.currentUser?.email ?? "";

  const handleSubmit = async (currentPassword, newPassword) => {
    try {
      await changePassword({ variables: { currentPassword, newPassword } });

      const result = await loginMutation({
        variables: {
          email,
          password: newPassword
        }
      });

      return result.data?.login?.token;
    } catch (error) {
      logger.error(error);
    }
  };

  return <ProfilePasswordEdit onSubmit={handleSubmit} requests={[changePasswordRequest]} />;
};
