import React from "react";
import { useQuery } from "react-apollo";
import { MY_INVESTMENTS } from "../../../../lib/api/queries/MyInvestments";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { myInvestments } from "../../../../lib/api/queries/graphql/myInvestments";
import InvestmentsListWrapper from "./InvestmentListWrapper";
import { useHistory } from "react-router-dom";

export const InvestmentsListContainer = () => {
  const history = useHistory();
  const myInvestmentsRequest = useQuery<myInvestments>(MY_INVESTMENTS);

  const investments = myInvestmentsRequest.data?.myInvestments ?? [];

  const handleSelect = (id: string) => {
    history.push(`/client/investments/${id}`);
  };

  return (
    <RequestFeedbackHandler requests={[myInvestmentsRequest]} noLoadingAnimation={true}>
      <InvestmentsListWrapper onSelectInvestment={handleSelect} investments={investments} />
    </RequestFeedbackHandler>
  );
};
