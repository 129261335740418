import { CompanyById_companyById } from "../../../lib/api/queries/graphql/CompanyById";
import React from "react";
import LeiNumberIssueStatusSection from "./LeiNumberSection.partials";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

export interface LeiNumberSectionProps extends WithTranslation {
  company: CompanyById_companyById;
}

export const LeiNumberSection = withTranslationReady(["lei-number"])(
  ({ t, company }: LeiNumberSectionProps) => {
    switch (company.leiOrderSource) {
      case "LEI_ADMIN":
        return (
          <LeiNumberIssueStatusSection
            leiPartnerLogoPath="/assets/lei-partners/lei-admin-cooperation-logo.svg"
            leiSupportContactEmail={t(`lei-number:email@.href.LEI_ADMIN`)}
            leiSupportContactEmailLabel={t(`lei-number:email@.LEI_ADMIN`)}
            company={company}
          />
        );
      case "LEI_ZENTRALE":
        return (
          <LeiNumberIssueStatusSection
            leiPartnerLogoPath="/assets/lei-partners/lei-zentrale-cooperation-logo.svg"
            leiSupportContactEmail={t(`lei-number:email@.href.LEI_ZENTRALE`)}
            leiSupportContactEmailLabel={t(`lei-number:email@.LEI_ZENTRALE`)}
            company={company}
          />
        );
      case "LEI_KAUF":
        return (
          <LeiNumberIssueStatusSection
            leiSupportContactEmail={t(`lei-number:email@.href.LEI_KAUF`)}
            leiSupportContactEmailLabel={t(`lei-number:email@.LEI_KAUF`)}
            company={company}
          />
        );
      default:
        return null;
    }
  }
);
