import React, { useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextInput from "../../components/generic/TextInput";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import { MagnifyingGlassIcon } from "../icons/MagnifyingGlassIcon";

interface RideSearchProps extends WithTranslation {
  query: string;
  onQueryChange: (string) => void;
  className?: string;
  children?: React.ReactNode;
  placeholder?: string;
}

const RideSearch = ({
  children,
  t,
  query,
  onQueryChange,
  className = "",
  placeholder = t("nominee-dashboard:company-creation.search-placeholder")
}: RideSearchProps) => {
  const [value, setValue] = useState(query);
  const debouncedOnQueryChange = useMemo(() => _.debounce(onQueryChange, 500), [onQueryChange]);

  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedOnQueryChange(newValue);
  };

  return (
    <>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <div className="ride-search-wrapper">
            <MagnifyingGlassIcon className="ride-search-icon" />
            <TextInput
              t={t}
              name="query"
              type="text"
              className={className}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
      <div className="mt-4">{children}</div>
    </>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(RideSearch);
