import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LinkVariant } from "../../../sharedComponents/TextLink/TextLink";

export interface TextLinkAsButtonProps extends WithTranslation {
  onClick?: () => void;
  "data-testid"?: string;
  className?: string;
  children?: string;
  variant?: LinkVariant;
}

const TextLinkAsButton = (props: TextLinkAsButtonProps) => {
  const {
    onClick,
    "data-testid": dataTestId = "edit-button",
    className = "",
    variant = LinkVariant.secondary,
    t,
    children
  } = props;

  return (
    <button
      data-testid={dataTestId}
      className={`text-link-as-button text-link--${variant} ${className}`}
      onClick={onClick}
      type="button">
      {children ?? t(`generic:edit-field`)}
    </button>
  );
};

export default withTranslation(["generic"])(TextLinkAsButton);
