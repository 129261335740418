import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { ReviewShareholderSection } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholderSection/ReviewShareholderSection";

export interface ReviewStatusInformationProps {
  isNewBroker: boolean;
  taxJurisdiction: string | undefined;
  taxId: string | undefined;
  politicallyExposed: boolean | undefined;
  politicallyExposedContext: string | undefined;
  employmentStatus: string | undefined;
}

export const ReviewStatusInformation = withTranslationReady(["generic"])(
  ({
    taxJurisdiction,
    taxId,
    politicallyExposed,
    politicallyExposedContext,
    employmentStatus,
    isNewBroker,
    t
  }: ReviewStatusInformationProps & WithTranslation) => {
    return (
      <ReviewShareholderSection
        title={t("generic:entity-details.shareholder.status-information.title")}>
        <ReviewField
          label={t("generic:tax-jurisdiction")}
          value={taxJurisdiction && getCountryNameByCode(taxJurisdiction)}
        />
        <ReviewField label={t("generic:tax-id")} value={taxId} />
        <ReviewField
          label={t("generic:ride-broker-onboarding.review.shareholders.politically-exposed-person")}
          value={
            politicallyExposed === undefined
              ? undefined
              : politicallyExposed
              ? t("generic:yes")
              : t("generic:no")
          }
        />
        {politicallyExposed && (
          <ReviewField
            label={t(
              "generic:ride-broker-onboarding.review.shareholders.politically-exposed-person-context"
            )}
            value={politicallyExposedContext}
          />
        )}
        {isNewBroker && (
          <ReviewField
            name="employment-status"
            label={t("generic:ride-broker-onboarding.employment-options.label")}
            value={
              employmentStatus &&
              t(`generic:ride-broker-onboarding.employment-options.${employmentStatus}`)
            }
          />
        )}
      </ReviewShareholderSection>
    );
  }
);
