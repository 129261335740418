import React from "react";
import { FeatureFlags } from "global-query-types";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { MigrateAdminServicePackageContainer } from "client/components/MigrateAdminServicePackage/MigrateAdminServicePackageContainer";
import { useParams } from "react-router-dom";

export const MigrateAdminServicePackageScene = () => {
  const { companyId } = useParams<{ companyId: string }>();

  return (
    <FeatureFlag name={FeatureFlags.GRO777MigrateAdminServicePackage}>
      <MigrateAdminServicePackageContainer companyId={companyId} />
    </FeatureFlag>
  );
};
