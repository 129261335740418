import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BrokerageAccountFormContainer } from "client/components/BrokerageAccountForm/BrokerageAccountFormContainer";
import { ActionPage } from "client/components/templates/ActionPage/ActionPage";
import { useHistory } from "react-router";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface EditBrokerageAccountProps extends WithTranslation {}

const EditBrokerageAccount = ({ t }: EditBrokerageAccountProps) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:brokerage-account.edit-page-title")} />
      <ActionPage title={t("client:brokerage-account.title-edit")} onBack={goBack}>
        <BrokerageAccountFormContainer />
      </ActionPage>
    </>
  );
};

export default withTranslation(["generic", "client"])(EditBrokerageAccount);
