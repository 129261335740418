import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getCompanyCreationSteps } from "./graphql/getCompanyCreationSteps";

const GET_COMPANY_CREATION_STEPS = gql`
  query getCompanyCreationSteps($companyCreationId: ID!) {
    getCompanyCreationSteps(companyCreationId: $companyCreationId) {
      ref
      state
      steps {
        ref
        name
        state
        tasks {
          ref
          state
          options {
            type
            data
          }
          type
          responsibleParty
          updatedAt
        }
      }
    }
  }
`;
export const GetCompanyCreationSteps = (id: string) =>
  useQuery<getCompanyCreationSteps>(GET_COMPANY_CREATION_STEPS, {
    variables: { companyCreationId: id }
  });
