export const getSubmitPath = (pathname: string, companyId: string, section: string) => {
  if (pathname.indexOf("/third-party-tax-advisor") > -1) {
    return `/third-party-tax-advisor/clients/${companyId}#${section}`;
  }

  if (pathname.indexOf("/tax-advisor") > -1) {
    return `/tax-advisor/client-entities/${companyId}#${section}`;
  }

  return `/client/entities/${companyId}/general-info#${section}`;
};
