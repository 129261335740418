import { TFunction } from "i18next";
import {
  KeyValueCardData,
  KeyValueCardProps
} from "../../../admin/components/KeyValueCard/KeyValueCard";

export const shelfCompanyFormToKeyValueCardProps = (form: any, t: TFunction): KeyValueCardProps => {
  const shelfCompanyBusinessPurposeFields = getFormBusinessPurposeFields(
    form.business.differentBusinessPurpose,
    t
  );

  const shelfCompanyInfo = [
    {
      key: "companyName",
      value: form.business.companyName,
      label: t("generic:vg-order-details.order-details-card.company.company-name")
    },
    {
      key: "street",
      value: form.business.street,
      label: t("generic:vg-order-details.order-details-card.company.street-and-house-number")
    },
    {
      key: "postalCode",
      value: form.business.postalCode,
      label: t("generic:vg-order-details.order-details-card.company.postal-code")
    },
    {
      key: "city",
      value: form.business.city,
      label: t("generic:vg-order-details.order-details-card.company.city")
    },
    ...shelfCompanyBusinessPurposeFields
  ];
  const MAX_SHAREHOLDERS = 4;
  let shareholdersInfo: KeyValueCardData[] = [];

  for (let i = 0; i < MAX_SHAREHOLDERS; i++) {
    const shareholderParsed = buildShareholderInfo(form.shareholders?.[i], i + 1, t);
    const emptyRowKeyValue = { key: "", value: "", label: "" };
    shareholdersInfo = shareholdersInfo.concat([...shareholderParsed, emptyRowKeyValue]);
  }

  return {
    title: t("generic:vg-order-details.order-details-card.title"),
    data: [...shareholdersInfo, ...shelfCompanyInfo]
  };
};

const getFormBusinessPurposeFields = (differentBusinessPurpose: string, t) => {
  const userChangedPurpose = differentBusinessPurpose !== "";
  let fields;

  if (userChangedPurpose) {
    fields = [
      {
        key: "isPurposeChanged",
        value: t("generic:yes"),
        label: t("generic:vg-order-details.order-details-card.company.is-purpose-changed")
      },
      {
        key: "differentBusinessPurpose",
        value: differentBusinessPurpose,
        label: t("generic:vg-order-details.order-details-card.company.purpose-changed")
      }
    ];
  } else {
    fields = [
      {
        key: "isPurposeChanged",
        value: t("generic:no"),
        label: t("generic:vg-order-details.order-details-card.company.is-purpose-changed")
      }
    ];
  }

  return fields;
};

const buildShareholderInfo = (shareholder: any, order: number, t): KeyValueCardData[] => {
  let shareholderTitle: KeyValueCardData = {
    key: "shareholderType",
    value: t("generic:vg-order-details.order-details-card.shareholder-type.no-shareholder"),
    label: `${order}. ${t("generic:vg-order-details.order-details-card.shareholder.title")}`
  };

  if (shareholder == null) {
    return [shareholderTitle];
  }

  const shareholderIsLegalEntity = shareholder.company !== undefined;

  const shareholderPersonInfo = [
    {
      key: "salutation",
      value: shareholder.person.salutation,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.salutation")
    },
    {
      key: "firstName",
      value: shareholder.person.firstName,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.first-name")
    },
    {
      key: "lastName",
      value: shareholder.person.lastName,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.last-name")
    },
    {
      key: "street",
      value: shareholder.person.street,
      label: t(
        "generic:vg-order-details.order-details-card.shareholder.fields.street-and-house-number"
      )
    },
    {
      key: "postalCode",
      value: shareholder.person.postalCode,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.postal-code")
    },
    {
      key: "city",
      value: shareholder.person.city,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.city")
    },
    {
      key: "dateOfBirth",
      value: shareholder.person.dateOfBirth,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.birth-date")
    },
    {
      key: "placeOfBirth",
      value: shareholder.person.placeOfBirth,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.birth-place")
    },
    {
      key: "citizenship",
      value: shareholder.person.citizenship,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.nationality")
    },
    {
      key: "email",
      value: shareholder.person.email,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.email")
    },

    {
      key: "phoneNumber",
      value: shareholder.person.phoneNumber,
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.phone-number")
    },
    {
      key: "isDirector",
      value: shareholder.person.isDirector ? t("generic:yes") : t("generic:no"),
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.is-director")
    },
    {
      key: "share",
      value: shareholder.share.toString(),
      label: t("generic:vg-order-details.order-details-card.shareholder.fields.shares")
    }
  ];

  let shareholderLegalEntityInfo: KeyValueCardData[] = [];

  if (shareholderIsLegalEntity) {
    shareholderTitle.value = t(
      "generic:vg-order-details.order-details-card.shareholder-type.legal-entity"
    );

    shareholderLegalEntityInfo = [
      {
        key: "companyName",
        value: shareholder.company.companyName,
        label: t("generic:vg-order-details.order-details-card.shareholder.fields.company-name")
      },
      {
        key: "registerCourt",
        value: shareholder.company.registerCourt,
        label: t("generic:vg-order-details.order-details-card.shareholder.fields.register-court")
      },
      {
        key: "registration",
        value: shareholder.company.registration,
        label: t("generic:vg-order-details.order-details-card.shareholder.fields.registration")
      },
      {
        key: "street",
        value: shareholder.company.street,
        label: t(
          "generic:vg-order-details.order-details-card.shareholder.fields.company-street-and-house-number"
        )
      },
      {
        key: "postalCode",
        value: shareholder.company.postalCode,
        label: t(
          "generic:vg-order-details.order-details-card.shareholder.fields.company-postal-code"
        )
      },
      {
        key: "city",
        value: shareholder.company.city,
        label: t("generic:vg-order-details.order-details-card.shareholder.fields.company-city")
      }
    ];
  } else {
    shareholderTitle.value = t(
      "generic:vg-order-details.order-details-card.shareholder-type.person"
    );
  }
  return [shareholderTitle, ...shareholderPersonInfo, ...shareholderLegalEntityInfo];
};
