import * as React from "react";

export const SelectedOption = (props?) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="selected-option"
      {...props}>
      <rect x={0.5} y={0.5} width={23} height={23} rx={11.5} fill="#303436" stroke="#303436" />
      <g filter="url(#selected_svg__filter0_d)">
        <rect x={6} y={6} width={12} height={12} rx={6} fill="#48FFAF" />
        <rect x={6.5} y={6.5} width={11} height={11} rx={5.5} stroke="#fff" strokeOpacity={0.1} />
      </g>
      <defs>
        <filter
          id="selected_svg__filter0_d"
          x={-14}
          y={0}
          width={92}
          height={92}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={8} />
          <feGaussianBlur stdDeviation={20} />
          <feColorMatrix values="0 0 0 0 0.207843 0 0 0 0 0.14902 0 0 0 0 0.270588 0 0 0 0.1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
