import { PendingTaskSwitcherProps } from "../PendingTaskSwitcher";
import React from "react";
import { CardContainer } from "../../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

const LoginToCompletePendingTask = ({
  task,
  t,
  handleCreateSSOAndRedirect
}: PendingTaskSwitcherProps & WithTranslation) => {
  return (
    <CardContainer className="v2-card-container--alternative">
      <div className="p-4" data-testid="pending-task">
        <p className="font-weight-bold">{task.formName}</p>
        <p className="text-muted">{t("generic:pending-tasks.login-to-complete")}</p>

        <div className="ltb-overview__buttons">
          <button
            data-testid="login-to-complete-pending-task"
            className="ltb-overview__primary-action-btn"
            onClick={handleCreateSSOAndRedirect}>
            {t("generic:pending-tasks.login-to-complete-button")}
            <span className="ride-banner__button-icon"></span>
          </button>
        </div>
      </div>
    </CardContainer>
  );
};

export default withTranslationReady(["generic"])(LoginToCompletePendingTask);
