import React from "react";
import { ArrowLeft } from "sharedComponents/icons/ArrowLeft";

interface BackButtonProps {
  onClick?: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="back-button-obsolete"
      data-testid="back-button"
      type="button">
      <ArrowLeft className="back-button__arrow" />
    </button>
  );
};

export default BackButton;
