import gql from "graphql-tag";

export const LOGIN_AS_USER = gql`
  mutation LoginAsUser($userId: ID!) {
    loginAsUser(userId: $userId) {
      token
      feedback
    }
  }
`;
