import React, { useMemo } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Col, Row } from "react-bootstrap";
import AddNotaryForm from "./AddNotaryForm";
import { AllNotaryPersonsQuery_allNotaryPersons } from "./graphql/AllNotaryPersonsQuery";
import { NotaryLinkItem } from "../../../global-query-types";
import NotaryTypeAssignmentContainer from "./NotaryTypeAssignmentContainer";
import { useSimpleSortBehaviour } from "../../../sharedComponents/useSimpleSortBehaviour/useSimpleSortBehaviour";
import { NotaryListTable } from "./NotaryListTable";
import { PageLayout } from "../../../sharedComponents/templates/PageLayout/PageLayout";
import { RideHeaderColumn } from "../../../lib/types/types";

interface NotaryListProps extends WithTranslation {
  persons: AllNotaryPersonsQuery_allNotaryPersons[];
  onAddNotary: (
    email: string,
    firstName: string,
    lastName: string,
    links: NotaryLinkItem[]
  ) => Promise<void>;
  refetch: () => Promise<void>;
}

export const NotaryList = ({ persons, onAddNotary, refetch, t }: NotaryListProps) => {
  const handleSubmit = async (email, firstName, lastName, links) => {
    await onAddNotary(email, firstName, lastName, links);
    await refetch();
  };
  const columns: RideHeaderColumn[] = [
    {
      label: t("nominee-dashboard:notary.name"),
      name: "fullName"
    },
    {
      label: t("nominee-dashboard:notary.city"),
      name: "address.city"
    },
    {
      label: t("nominee-dashboard:person:partner-status"),
      name: "isPartner"
    }
  ];

  const enrichedPersons = useMemo(
    () =>
      persons.map((person) => ({
        ...person,
        fullName: `${person.firstName} ${person.lastName}`
      })),
    [persons]
  );

  const { sortedData, sortCallback } = useSimpleSortBehaviour(enrichedPersons);

  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("header:notaries")}
      headerContent={<AddNotaryForm onSubmit={handleSubmit} />}
      mainContent={
        <>
          <NotaryTypeAssignmentContainer refetch={refetch} persons={persons} />
          <Row>
            <Col>
              <NotaryListTable
                columns={columns}
                sortCallback={sortCallback}
                sortedData={sortedData}
                t={t}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(NotaryList);
