import bytes from "bytes";
import { RideFileStorage } from "lib/adapters/RideFileStorage/FileStorage";

export enum FileEntityType {
  Order = "Order"
}

export enum FileType {
  PDF = "application/pdf",
  JPG = "image/jpg",
  PNG = "image/png",
  JPEG = "image/jpeg"
}

export interface UploadFileResponse {
  fileId: string;
  checksum: string;
}

export interface FileStorage {
  uploadFileForEntity(
    entityType: FileEntityType,
    entityId: string,
    file: File
  ): Promise<UploadFileResponse>;

  getFileForEntity(entityType: FileEntityType, entityId: string, fileId: string): Promise<Blob>;
}

let instance: FileStorage;

export const getFileStorage = (): FileStorage => {
  if (!instance) {
    instance = new RideFileStorage({
      maxFileSizeInBytes: bytes("15 MB"),
      allowedFileTypes: [FileType.JPG, FileType.PDF, FileType.PNG, FileType.JPEG]
    });
  }

  return instance;
};
