import React from "react";
import { ReviewShareholderSection } from "../ReviewShareholderSection/ReviewShareholderSection";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { ReviewField } from "../../../../sharedComponents/ReviewField/ReviewField";
import { ShareholderEmploymentDetails } from "lib/models/client/LowTaxBroker/Shareholder";
import { Multiline } from "../ReviewAddress/ReviewAddress";
import { formatAddress } from "lib/formatters/addressFormatter";
import { AddressData } from "lib/models/client/LowTaxBroker/AddressData";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

interface ReviewEmployerInformationSectionProps {
  employerInformation: ShareholderEmploymentDetails;
  countryMismatch: boolean;
}

const ReviewEmployerInformationSection = withTranslationReady(["generic"])(
  ({
    t,
    employerInformation,
    countryMismatch
  }: ReviewEmployerInformationSectionProps & WithTranslation) => {
    return (
      <div data-testid={"employer-information-section"}>
        <ReviewShareholderSection
          title={t("generic:review-entry.shareholder-employer-information")}>
          <ReviewField
            name={"employer"}
            label={t("generic:employer-name")}
            value={employerInformation && employerInformation.employer}
          />
          <ReviewField
            name={"business-type"}
            label={t("generic:ride-broker-onboarding.employer-information.business-type.label")}
            value={employerInformation && employerInformation.employerBusiness}
          />
          <ReviewField
            name={"occupation"}
            label={t("generic:ride-broker-onboarding.employer-information.occupation.label")}
            value={employerInformation && employerInformation.occupation}
          />
          <ReviewField
            name={"address"}
            label={t("generic:address")}
            value={
              employerInformation &&
              employerInformation.employerAddress && (
                <Multiline>
                  {formatAddress(
                    employerInformation.employerAddress as AddressData,
                    FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerWithoutHouseNumber)
                  )}
                </Multiline>
              )
            }
          />
          {countryMismatch && (
            <ReviewField
              name={"country-mismatch"}
              label={t(
                "generic:ride-broker-onboarding.employer-information.country-mismatch.label"
              )}
              value={employerInformation && employerInformation.countryMismatch}
            />
          )}
        </ReviewShareholderSection>
      </div>
    );
  }
);

export default ReviewEmployerInformationSection;
