export const defaultPercentageOwnership = 100;

export const initialValues = {
  label: "",
  propertyValue: 0.0,
  percentageOwnership: defaultPercentageOwnership,
  ownerId: ""
};

export const exampleCompanyId = "company-id";
export const exampleCompanyId2 = "company-id2";

export const exampleRealEstateOwnersData = [
  {
    companyId: exampleCompanyId2,
    title: "owner company 2 GmbH"
  },
  {
    companyId: exampleCompanyId,
    title: "owner company 1 GmbH"
  },
  {
    companyId: null,
    title: "Jane Smith"
  }
];
