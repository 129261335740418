import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalLayout,
  ModalLayoutVariant
} from "../../../sharedComponents/templates/ModalLayout/ModalLayout";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { AddExistingBankAccountContainer } from "../../components/Banking/AddExistingBankAccount/AddExistingBankAccountContainer";
import { FeatureFlags } from "global-query-types";

const AddExistingBankAccountScene = () => {
  const history = useHistory();

  const handleClose = () => {
    history.replace(`/client/banking`);
  };

  const handleBack = () => {
    history.replace(`/client/banking/add`);
  };

  return (
    <FeatureFlag name={FeatureFlags.BankAccountsList}>
      <ModalLayout onClose={handleClose} onBack={handleBack} variant={ModalLayoutVariant.white}>
        <AddExistingBankAccountContainer />
      </ModalLayout>
    </FeatureFlag>
  );
};

export default AddExistingBankAccountScene;
