import { radiosFieldFor, textAreaFieldFor } from "../commonFields";
import { KYCStatuses } from "../../nominee/common/KYCStatus/KYCStatusSection";

export const KYCStatusRadioField = ({ options, handleChange, currentSelectedIndex }) =>
  radiosFieldFor("", "status", "", options, handleChange, currentSelectedIndex, false);

export const ShortIncompleteReasonField = ({ t, formik, rowCount }) => {
  const disabled = formik.values.status !== KYCStatuses.INCOMPLETE;
  return textAreaFieldFor(
    formik,
    "incompleteReason",
    "",
    t("nominee-dashboard:kyc-status.incomplete-reason-placeholder"),
    rowCount,
    disabled
  );
};
