export const uploadFile = async (method: string, url: string, file: File) => {
  return await fetch(url, {
    method,
    body: file
  });
};

export const uploadFileViaBackend = async (method: string, url: string, file: File) => {
  const endpoint = process.env.REACT_APP_API_ENDPOINT as string;
  const urlParam = encodeURIComponent(url);
  return await fetch(`${endpoint}/api/client/storage/upload/${urlParam}`, {
    method,
    body: file
  });
};
