import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { GetNotaryAppointments } from "./graphql/GetNotaryAppointments";
import { AllNotaryPersonsQuery } from "./graphql/AllNotaryPersonsQuery";

export const ALL_NOTARY_PERSONS = gql`
  query AllNotaryPersonsQuery {
    allNotaryPersons {
      id
      firstName
      lastName
      address {
        city
      }
      notaryGroups {
        notaryType
      }
      isPartner
    }
  }
`;

export const AllNotaryPersons = () => useQuery<AllNotaryPersonsQuery>(ALL_NOTARY_PERSONS);

export const ALL_NOTARY_APPOINTMENTS = gql`
  query GetNotaryAppointments($after: String) {
    getNotaryAppointments(after: $after) {
      id
      date
      notaryType
      notary {
        id
        firstName
        lastName
      }
      company {
        id
        name
        legalForm
      }
    }
  }
`;

export const AllNotaryAppointments = (after: string) =>
  useQuery<GetNotaryAppointments>(ALL_NOTARY_APPOINTMENTS, {
    variables: {
      after
    }
  });
