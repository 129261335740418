import { AdminServiceTier } from "../lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";

export interface AdminServiceRelevantQuestions {
  companyDoesNotFallIntoCategories?: string;
  companyOwnsRealEstate?: "yes-up-to-15" | "yes-more-than-15" | "no" | undefined;
  tradesCrypto?: string;
  hasFiveOrMoreEmployees?: string;
  companyIssuesMoreThan50OutgoingInvoicesYearly?: string;
  companyKeepsPhysicalInventory?: string;
  orderingBroker?: boolean;
}

export interface AdminServiceDisabledTiers {
  disabledTiers: AdminServiceTier[];
  tooltipTextKey?: string;
}

export function getBlockedTiersForAdminService(
  args: AdminServiceRelevantQuestions,
  considerAdminServiceQuestions: boolean
): AdminServiceDisabledTiers {
  let disabledTiers: AdminServiceTier[] = [];
  let tooltipTextKey: string = "";

  if (args.orderingBroker) {
    disabledTiers.push(AdminServiceTier.Lite);
    tooltipTextKey = "generic:product-card.admin-service.tooltip";
  }

  if (considerAdminServiceQuestions) {
    if (args.companyOwnsRealEstate === "yes-up-to-15") {
      disabledTiers.push(AdminServiceTier.Lite, AdminServiceTier.Standard);
      tooltipTextKey = "generic:product-card.admin-service.tooltip-up-to-15-real-estates";
    }

    if (
      args.companyOwnsRealEstate === "yes-more-than-15" ||
      args.companyDoesNotFallIntoCategories === "no" ||
      args.hasFiveOrMoreEmployees === "yes" ||
      args.companyIssuesMoreThan50OutgoingInvoicesYearly === "yes" ||
      args.companyKeepsPhysicalInventory === "yes" ||
      args.tradesCrypto === "yes"
    ) {
      disabledTiers.push(AdminServiceTier.Lite, AdminServiceTier.Standard, AdminServiceTier.Pro);
      tooltipTextKey = "generic:product-card.admin-service.tooltip-as-disabled";
    }
  }

  return { disabledTiers: Array.from(new Set(disabledTiers)), tooltipTextKey };
}
