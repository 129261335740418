import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Trans, WithTranslation } from "react-i18next";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { BlankPage, WidthVariantEnum } from "../templates/BlankPage/BlankPage";
import { CardContainer } from "../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { RideCheckbox } from "../../../sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import {
  ButtonRadius,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "lib/api/mutations/graphql/UpsertOrder";
import { OrderCompanyStatusEnum, OrderProductTypeEnum, OrderOwnerType } from "global-query-types";
import { PersonalInformationSchema } from "../../../lib/validation/PersonalInformationSchema";
import logger from "../../../common/Logger";
import {
  FinalizeOrder_finalizeOrder,
  FinalizeOrderVariables
} from "../../../lib/api/mutations/graphql/FinalizeOrder";
import { useHistory } from "react-router-dom";
import { RideUrls } from "../../../common/URLs";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { storeToken } from "common/Authentication/storeToken";
import {
  PriceItem,
  PriceOperation,
  PriceSection
} from "../ChaptersOrderLayout/sharedComponents/PriceSection/PriceSection";
import { DiscountCodeSection } from "../ChaptersOrderLayout/sharedComponents/DiscountCodeSection/DiscountCodeSection";

export interface OrderCompanyFoundingProps extends WithTranslation {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  discountCode: string;
  upsertOrder: (
    variables: UpsertOrderVariables
  ) => Promise<UpsertOrder_upsertOrder | null | undefined>;
  finalizeOrder: (
    variables: FinalizeOrderVariables
  ) => Promise<FinalizeOrder_finalizeOrder | null | undefined>;
  verifyDiscountCode: (discountCode) => Promise<{ isValid: boolean; discountValue: number }>;
}

const OrderCompanyFounding = ({
  t,
  firstName,
  lastName,
  email,
  phoneNumber,
  discountCode,
  upsertOrder,
  finalizeOrder,
  verifyDiscountCode
}: OrderCompanyFoundingProps) => {
  const [discountValue, setDiscountValue] = useState(0.0);
  const productPrice = 2299;
  const { push } = useHistory();

  const initialValues = {
    firstName,
    lastName,
    email,
    phoneNumber,
    discountCode,
    termsAndConditionsCheckbox: false,
    actingIndependentCheckbox: false
  };

  const onSubmit = async (values) => {
    try {
      const result = await upsertOrder({
        status: OrderCompanyStatusEnum.InProgress,
        ownerType: OrderOwnerType.Guest,
        productType: OrderProductTypeEnum.UserAccountViaCompanyFoundation,
        extra: values
      });

      if (!result) return;

      const finalizeOrderResult = await finalizeOrder({
        id: result.id,
        submissionId: result.submissionId,
        ownerType: OrderOwnerType.Guest
      });

      if (finalizeOrderResult?.token) {
        const { token } = finalizeOrderResult;
        storeToken(token);
        push(`/company-foundation/offer/admin-service/${result.id}/${result.submissionId}`);
      } else {
        push(`/order/gmbh/complete/${result.id}/${result.submissionId}`);
      }
    } catch (error) {
      logger.errorMessage(`Could not create or update order`);
      logger.error(error as Error);
    }
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: PersonalInformationSchema(t)
  });

  const prices: PriceItem[] = [
    {
      value: productPrice,
      key: t("generic:order-form.subtotal"),
      operation: PriceOperation.BASE
    },
    {
      value: discountValue,
      key: t("generic:order-form.discount"),
      operation: PriceOperation.SUBTRACT
    }
  ];

  return (
    <BlankPage
      title={t("generic:order-form.title")}
      subTitle={t("generic:order-form.sub-title")}
      variant={WidthVariantEnum.NARROW}>
      <FormikProvider value={formik}>
        <Form className={`order-form`}>
          <PersonalInformation formik={formik} t={t} />
          <OrderOverview t={t} />
          <DiscountCodeSection
            formik={formik}
            verifyDiscountCode={verifyDiscountCode}
            setDiscountValue={setDiscountValue}
          />
          <hr className={"order-form__divider"} />
          <PriceSection prices={prices} />
          <div className={"order-form__checkboxes"}>
            <RideCheckbox
              formik={formik}
              name="termsAndConditionsCheckbox"
              data-testid="terms-and-conditions"
              label={
                <Trans
                  i18nKey="generic:order-form.checkboxes.terms-and-conditions"
                  components={{
                    terms: (
                      <TextLink
                        to={RideUrls.TermsAndConditions}
                        variant={LinkVariant.primary}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                    privacy: (
                      <TextLink
                        to={RideUrls.PrivacyPolicy}
                        variant={LinkVariant.primary}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    )
                  }}
                />
              }
              className={"order-form__checkbox"}
            />
            <RideCheckbox
              formik={formik}
              name="actingIndependentCheckbox"
              data-testid="user-is-acting-independent"
              label={t("generic:order-form.checkboxes.user-is-acting-independently")}
              className={"order-form__checkbox"}
            />
          </div>
          <div className={"order-form__submit"}>
            <RideButtonDeprecated
              className={"order-form__submit-button"}
              disabled={
                !formik.values["actingIndependentCheckbox"] ||
                !formik.values["termsAndConditionsCheckbox"]
              }
              radius={ButtonRadius.Soft}
              data-testid="order-button"
              variant={ButtonVariant.Primary}
              type="submit">
              {t("generic:order-form.order-button")}
            </RideButtonDeprecated>
          </div>
        </Form>
      </FormikProvider>
    </BlankPage>
  );
};

const PersonalInformation = ({ formik, t }) => (
  <>
    <div className={"order-form__section-title"}>{t("generic:order-form.section-1")}</div>

    <div className="order-form__inputs">
      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="firstName"
            label={t("generic:first-name")}
            placeholder={t("generic:first-name")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="lastName"
            label={t("generic:last-name")}
            placeholder={t("generic:last-name")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="phoneNumber"
            className={"order-form__input"}
            label={t("generic:order-form.phone")}
            type={"tel"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="email"
            type="email"
            label={t("generic:order-form.email")}
            placeholder={t("generic:email")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>
    </div>
  </>
);

const OrderOverview = ({ t }) => (
  <>
    <div className={"order-form__section-title"}>{t("generic:order-form.section-2")}</div>

    <CardContainer className="order-form__summary">
      <div className="order-form__summary-header">
        <div className="order-form__summary-icon" />
        <Col className="order-form__summary-title">
          <div>{t("generic:order-form.summary-title")}</div>
          <div className="order-form__summary-time-frame">{t("generic:order-form.time-frame")}</div>
        </Col>
      </div>

      <div className="order-form__summary-body">
        <ul className="order-form__summary-items">
          <li className="order-form__summary-item">{t("generic:order-form.items.1")}</li>
          <li className="order-form__summary-item">{t("generic:order-form.items.2")}</li>
          <li className="order-form__summary-item">{t("generic:order-form.items.3")}</li>
          <li className="order-form__summary-item">{t("generic:order-form.items.4")}</li>
          <li className="order-form__summary-item">{t("generic:order-form.items.5")}</li>
          <li className="order-form__summary-item">{t("generic:order-form.items.6")}</li>
          <li className="order-form__summary-item">{t("generic:order-form.items.7")}</li>
        </ul>
      </div>

      <div className="order-form__summary-footer">
        <div className="order-form__summary-price">{t("generic:order-form.price")}</div>
        <div className="order-form__summary-small-print">{t("generic:order-form.small-print")}</div>
      </div>
    </CardContainer>
  </>
);

export default withTranslationReady(["generic"])(OrderCompanyFounding);
