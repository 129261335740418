import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LegalPersonType } from "global-query-types";
import React from "react";
import { WithTranslation } from "react-i18next";
import PercentageInputCard from "client/components/ChaptersOrderLayout/sharedComponents/PercentageInputCard/PercentageInputCard";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { deepClone } from "common/deepClone";
import { companyName } from "lib/formatters/companyFormatter";
import { personNameWithFallback } from "lib/formatters/personFormatter";

const OwnershipDetails = (props: WithTranslation & StepComponentProps) => {
  const shareholders = props.order.extra.shareholders ?? {};
  const module = "ownership-details";

  const formatShareholderType = (shareholders: any, index: string): string => {
    const type = String(shareholders[index].shareholderType).toLowerCase();
    return props.t(`generic:company-setup.steps.ownership-details.type-${type}`);
  };

  const formatShareholderName = (shareholders: any, index: string): string => {
    return shareholders[index].shareholderType === LegalPersonType.Company
      ? companyName({
          name: shareholders[index].companyData.companyName,
          legalForm: shareholders[index].companyData.legalForm
        })
      : personNameWithFallback(shareholders[index].personData);
  };

  let initialValues = {};

  const formik = useRideFormik({
    initialValues,
    onSubmit: (values) => onSubmit(values)
  });

  const shareholdersKeys = Object.keys(shareholders);
  if (shareholdersKeys.length === 1) {
    initialValues[`shareholder_0`] = 100;
  } else {
    shareholdersKeys.map((index) => {
      initialValues[`shareholder_${index}`] = shareholders[index].shares;
      return initialValues[`shareholder_${index}`];
    });
  }

  let totalShares = 0;
  shareholdersKeys.forEach((index) => {
    totalShares += parseFloat(formik.values[`shareholder_${index}`] || 0);
  });

  let shouldSaveData = true;
  shareholdersKeys.forEach((index) => {
    if (
      formik.values[`shareholder_${index}`] <= 0 ||
      formik.values[`shareholder_${index}`] === "" ||
      formik.values[`shareholder_${index}`] === undefined ||
      totalShares !== 100
    )
      shouldSaveData = false;
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const values = formik.values;
    const shareholdersCopy = deepClone(shareholders);

    shareholdersKeys.forEach((index) => {
      shareholdersCopy[index].shares = values[`shareholder_${index}`];
    });

    if (shouldSaveData) props.currentStep.complete();

    await props.saveData(
      {
        ...props.order.extra,
        shareholders: shareholdersCopy,
        ...props.currentStep.chapter.order.serialize()
      },
      false,
      shouldSaveData
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form className={module} onSubmit={onSubmit}>
        <StepHeading text={props.t("generic:company-setup.steps.ownership-details.title")} />
        <div data-testid={module}>
          {shareholdersKeys.map((index) => (
            <PercentageInputCard
              key={index}
              header={formatShareholderType(shareholders, index)}
              label={formatShareholderName(shareholders, index)}
              name={`shareholder_${index}`}
            />
          ))}
        </div>
        <div
          data-testid={"ownership-distribution-card-total"}
          className={`ownership-details__total ${
            totalShares > 100 ? "ownership-details__total--error" : ""
          } ${
            totalShares === 100 ? "ownership-details__total--success" : ""
          }`}>{`${totalShares}% ${props.t("generic:out-of")} 100%`}</div>
        {props.controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(OwnershipDetails);
