import React from "react";
import { Route, Switch } from "react-router";
import { NomineePersonSceneContainer } from "admin/scenes/NomineePerson/NomineePersonSceneContainer";
import { FeatureFlags, UserRole } from "global-query-types";
import AdminInvestmentsScene from "./scenes/AdminInvestmentsScene/AdminInvestmentsScene";
import AdminServiceScene from "./scenes/AdminService/AdminServiceListScene";
import WepaServiceScene from "./scenes/WepaService/WepaServiceScene";
import ClientEntitiesScene from "./scenes/ClientEntities/ClientEntitiesScene";
import FoundationServiceScene from "./scenes/FoundationService/FoundationServiceScene";
import FoundationServiceStepsScene from "./scenes/FoundationServiceTasks/FoundationServiceTasksScene";
import InvestmentClubAssets from "./scenes/InvestmentClubAssets/InvestmentClubAssets";
import InvestmentClubDeals from "./scenes/InvestmentClubDeals/InvestmentClubDeals";
import InvestmentClubSpvs from "./scenes/InvestmentClubSpvs/InvestmentClubSpvs";
import InvestmentDetailPage from "components/Investments/InvestmentDetailPage";
import NomineeDashboard from "components/nominee/NomineeDashboard/NomineeDashboard";
import NotariesScene from "./scenes/Notaries/NotariesScene";
import PartnersInviteScene from "./scenes/PartnersInvite/PartnersInviteScene";
import { PrivilegedRoute } from "./PrivilegedRoute";
import RealEstateDetailPage from "components/Investments/RealEstateDetailPage";
import ReferralCodeDetails from "./scenes/ReferralCodeDetails/ReferralCodeDetails";
import ReferralOverview from "./scenes/ReferralOverview/ReferralOverview";
import TasksScene from "./scenes/Tasks/TasksScene";
import TaxAdvisorsScene from "./scenes/TaxAdvisors/TaxAdvisorsScene";
import ThirdPartyTaxAdvisorsScene from "./scenes/ThirdPartyTaxAdvisors/ThirdPartyTaxAdvisorsScene";
import UserDetailPage from "components/nominee/NomineeUser/UserDetailPage";
import UsersAndCompaniesScene from "./scenes/UsersAndCompanies/UsersAndCompaniesScene";
import ThirdPartyTaxAdvisorsCustomersScene from "./scenes/ThirdPartyTaxAdvisorsCustomers/ThirdPartyTaxAdvisorsCustomersScene";
import AuditLogScene from "./scenes/AuditLog/AuditLogScene";
import NomineeSignUpScene from "./scenes/NomineeSignUp/NomineeSignUpScene";
import CompanyDetailsScene from "./scenes/CompanyDetails/CompanyDetailsScene";
import ShelfCompanyOrderScene from "./scenes/ShelfCompanyOrder/ShelfCompanyOrderScene";
import ShelfCompanyOrderDetailsScene from "./scenes/ShelfCompanyOrderDetails/ShelfCompanyOrderDetailsScene";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";
import TradingTransactionHistoryCompaniesListScene from "admin/scenes/TradingTransactionHistoryCompaniesList/TradingTransactionHistoryCompaniesListScene";
import TradingTransactionHistoryScene from "./scenes/TradingTransactionHistory/TradingTransactionHistoryScene";
import FinanceAdsCodesScene from "./scenes/FinanceAdsCodes/FinanceAdsCodesScene";
import LowTaxBrokerOrdersListScene from "./scenes/LowTaxBrokerOrdersList/LowTaxBrokerOrdersListScene";
import LowTaxBrokerOrderDetailsScene from "./scenes/LowTaxBrokerOrderDetails/LowTaxBrokerOrderDetailsScene";
import AdminFiscalYearScene from "./scenes/AdminFiscalYear/AdminFiscalYearScene";
import FormerClientAssignmentsScene from "./scenes/FormerClientAssignments/FormerClientAssignmentsScene";
import NewBrokerOrdersListScene from "./scenes/NewBrokerOrdersList/NewBrokerOrdersListScene";
import AdminPackageSelectorScene from "./scenes/AdminPackageSelector/AdminPackageSelectorScene";
import RideBrokerOrderDetailsScene from "./scenes/RideBrokerOrderDetails/RideBrokerOrderDetailsScene";
import WepaDirectOrdersListScene from "./scenes/WepaDirectOrdersListScene";
import { FeatureFlagService } from "../lib/services/FeatureFlagService/FeatureFlagService";
import { AccountantListContainer } from "./components/AccountantsList/AccountantListContainer";

interface NomineeAppRoutesProps {
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy;
}

export function NomineeAppRoutes({ passwordPolicy }: NomineeAppRoutesProps) {
  const path = `/admin`;
  const showAccountantRoleRoutes = FeatureFlagService.instance.isEnabled(
    FeatureFlags.WP100_AccountantRole
  );
  // TODO: Add again NotaryCalendarScene check commit
  return (
    <Switch>
      <PrivilegedRoute exact path={"/nominee-dashboard"} component={NomineeDashboard} />
      <PrivilegedRoute
        exact
        path={`${path}/users-companies`}
        fluid
        component={UsersAndCompaniesScene}
      />
      <PrivilegedRoute exact path={`${path}/entities`} fluid component={ClientEntitiesScene} />
      <PrivilegedRoute exact path={`${path}/investments`} fluid component={AdminInvestmentsScene} />
      <PrivilegedRoute exact path={`${path}/client-services/tasks/`} fluid component={TasksScene} />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/foundation-service`}
        fluid
        component={FoundationServiceScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/foundation-service-tasks`}
        fluid
        component={FoundationServiceStepsScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/admin-service`}
        fluid
        component={AdminServiceScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/wepa-service`}
        fluid
        component={WepaServiceScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/shelf-companies/orders`}
        fluid
        component={ShelfCompanyOrderScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/low-tax-broker/orders`}
        fluid
        component={LowTaxBrokerOrdersListScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/new-broker/orders`}
        fluid
        component={NewBrokerOrdersListScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/wepa-direct/orders`}
        fluid
        component={WepaDirectOrdersListScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/shelf-companies/orders/:orderId`}
        fluid
        component={ShelfCompanyOrderDetailsScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/low-tax-broker/orders/:orderId`}
        fluid
        component={LowTaxBrokerOrderDetailsScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/ride-broker/orders/:orderId`}
        fluid
        component={RideBrokerOrderDetailsScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/trading/transaction-history`}
        fluid
        component={TradingTransactionHistoryCompaniesListScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/client-services/trading/transaction-history/:companyId`}
        fluid
        component={TradingTransactionHistoryScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/investment-club/investment-club-spvs`}
        fluid
        component={InvestmentClubSpvs}
      />
      <PrivilegedRoute
        exact
        path={`${path}/investment-club/investment-club-assets`}
        fluid
        component={InvestmentClubAssets}
      />
      <PrivilegedRoute
        exact
        path={`${path}/investment-club/investment-club-deals`}
        fluid
        component={InvestmentClubDeals}
      />
      <PrivilegedRoute exact path={`${path}/referral-program`} fluid component={ReferralOverview} />
      <PrivilegedRoute
        exact
        path={`${path}/partners/tax-advisors`}
        fluid
        component={TaxAdvisorsScene}
      />
      {showAccountantRoleRoutes && (
        <PrivilegedRoute
          exact
          path={`${path}/partners/accountants`}
          fluid
          component={AccountantListContainer}
        />
      )}
      <PrivilegedRoute
        exact
        path={`${path}/partners/third-party-tax-advisors`}
        fluid
        component={ThirdPartyTaxAdvisorsScene}
      />
      <PrivilegedRoute
        exact
        path={"/admin/partners/third-party-tax-advisors-customers"}
        fluid
        component={ThirdPartyTaxAdvisorsCustomersScene}
      />
      <PrivilegedRoute exact path={`${path}/partners/notaries`} fluid component={NotariesScene} />
      <PrivilegedRoute
        exact
        fluid
        path={`${path}/partners/invite`}
        component={PartnersInviteScene}
      />
      <PrivilegedRoute
        exact
        path={"/nominee-persons/:id"}
        component={(props) => <NomineePersonSceneContainer {...props} areVotesDisabled={true} />}
      />
      <PrivilegedRoute exact path={"/nominee-companies/:id"} component={CompanyDetailsScene} />
      <PrivilegedRoute
        exact
        path={`${path}/companies/:id/fiscal-years`}
        component={AdminFiscalYearScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/former-client-assignments`}
        component={FormerClientAssignmentsScene}
      />
      <PrivilegedRoute
        exact
        path={`${path}/companies/:id/package-selector`}
        component={AdminPackageSelectorScene}
      />
      <PrivilegedRoute
        exact
        path={"/nominee/referral-codes/:code"}
        component={ReferralCodeDetails}
      />
      <PrivilegedRoute
        exact
        path={`${path}/investment-club/investment-club-assets/:id`}
        component={(props) => (
          <RealEstateDetailPage
            {...props}
            backUrl={`${path}/investment-club/investment-club-assets`}
            role={UserRole.Nominee}
          />
        )}
      />
      <PrivilegedRoute
        exact
        path={"/nominee-investment/detail/:companyId"}
        component={(props) => (
          <InvestmentDetailPage
            backUrl={`${path}/investment-club/investment-club-spvs`}
            {...props}
            role={UserRole.Nominee}
          />
        )}
      />
      <PrivilegedRoute
        exact
        path={"/user/detail/:id"}
        component={(props) => <UserDetailPage backUrl={`${path}/users-companies`} {...props} />}
      />
      <PrivilegedRoute
        exact
        path={`${path}/audit-log/:entityType/:entityId`}
        component={AuditLogScene}
      />
      <PrivilegedRoute exact fluid path={`${path}/finance-ads`} component={FinanceAdsCodesScene} />
      <Route exact path={`${path}/signup/:id`} component={() => <NomineeSignUpScene />} />
    </Switch>
  );
}
