import React from "react";
import { Modal } from "react-bootstrap";
import localStore from "common/LocalStore";
import { useHistory } from "react-router-dom";
import { WithTranslation } from "react-i18next";
import TextLinkAsButton from "uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import { UserService } from "lib/services/UserService/UserService";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import TwoFactorAuthenticationLogo from "sharedComponents/icons/TwoFactorAuthenticationLogo";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface TwoFactorAuthenticationNotificationProps extends WithTranslation {
  updateAllowTwoFactorAuthPrompt: () => Promise<void>;
}

const ONE_DAY = 60 * 60 * 24;

export const reset2faNotification = () => {
  localStore.setExpiringValue("show-2fa-modal", true);
};

const TwoFactorAuthenticationNotification = ({
  t,
  updateAllowTwoFactorAuthPrompt
}: TwoFactorAuthenticationNotificationProps) => {
  const [show, setShow] = React.useState(
    UserService.instance?.currentUser?.allowTwoFactorAuthPrompt !== false &&
      !!localStore.getExpiringValue("show-2fa-modal")
  );
  const history = useHistory();

  const persistNotificationShowed = () => {
    localStore.setExpiringValue("show-2fa-modal", false, ONE_DAY);
  };

  const rememberLater = () => {
    persistNotificationShowed();
    setShow(false);
  };

  const doNotAskMeAgain = async () => {
    await updateAllowTwoFactorAuthPrompt();
    setShow(false);
  };

  const goTo2faSetup = () => {
    persistNotificationShowed();
    setShow(false);
    history.push("/2fa");
  };

  return (
    <Modal
      show={show}
      dialogClassName="two-fa-notification__dialog"
      data-testid="two-factor-authentication-notification">
      <div className="two-fa-notification">
        <div className="two-fa-notification__logo">
          <TwoFactorAuthenticationLogo />
        </div>
        <div className="two-fa-notification__title">{t("auth:two-fa-notification.title")}</div>
        <div className="two-fa-notification__text">{t("auth:two-fa-notification.text")}</div>
        <div className="two-fa-notification__setupButton">
          <RideButtonDeprecated variant={ButtonVariant.Primary} onClick={goTo2faSetup}>
            {t("auth:two-fa-notification.setupButton")}
          </RideButtonDeprecated>
        </div>
        <div className="two-fa-notification__rememberLater">
          <TextLinkAsButton onClick={rememberLater}>
            {t("auth:two-fa-notification.rememberLater")}
          </TextLinkAsButton>
        </div>
        <div className="two-fa-notification__doNotAskMeAgain">
          <TextLinkAsButton onClick={doNotAskMeAgain} data-testid="do-not-ask-me-again">
            {t("auth:two-fa-notification.doNotAskMeAgain")}
          </TextLinkAsButton>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslationReady(["auth"])(TwoFactorAuthenticationNotification);
