import * as Yup from "yup";
import { EmailValidationNonRequired } from "./EmailSchema";

export const AdminServiceEmployeeManagingDirectorsSchema = () => {
  return Yup.object().shape({
    externalDirectors: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
          firstName: Yup.string().required("generic:validation-required"),
          lastName: Yup.string().required("generic:validation-required"),
          email: EmailValidationNonRequired,
          phoneNumber: Yup.string().required("generic:validation-required")
        })
      )
      .max(5)
  });
};
