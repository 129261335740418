import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import {
  GetFormerTaxAdvisorClientCompany,
  GetFormerTaxAdvisorClientCompanyVariables
} from "./graphql/GetFormerTaxAdvisorClientCompany";

const GET_FORMER_TAX_ADVISOR_CLIENT_COMPANY = gql`
  query GetFormerTaxAdvisorClientCompany($clientId: String!) {
    getFormerTaxAdvisorClientCompany(clientId: $clientId) {
      id
      name
      legalForm
      registerCourt
      registration
      taxNumber
      economicId
      vatNumber
      clientNumber
      thirdPartyTaxAdvisorClientNumber
      lei
      businessPurpose
      businessPurposeShort
      notarizationDate
      applicationForEntryDate
      commercialRegisterEntryDate
      employees
      liability
      locked
      externalStorageNodes
      businessAddress {
        careOf
        street
        streetLine2
        city
        postalCode
        country
      }
      adminService {
        id
      }
      wepaService {
        id
      }
      myTaxService {
        id
        type
        advisor {
          id
          name
          legalForm
        }
      }
      ownedWallet {
        id
        userWallet {
          id
        }
      }
      wepaId
      companyCreation {
        id
      }
      group {
        memberships {
          person {
            id
            firstName
            lastName
          }
          company {
            id
            name
            legalForm
          }
          role
          share {
            share
          }
        }
      }
      fiscalYear
      accountingFramework
      bankAccounts {
        id
        name
        bankProvider
        plan
        iban
      }
    }
  }
`;

export const GetFormerTaxAdvisorClientCompanyQuery = (clientId: string) =>
  useQuery<GetFormerTaxAdvisorClientCompany, GetFormerTaxAdvisorClientCompanyVariables>(
    GET_FORMER_TAX_ADVISOR_CLIENT_COMPANY,
    {
      variables: { clientId },
      fetchPolicy: "no-cache"
    }
  );
