import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { AlertMessage, AlertType } from "sharedComponents/AlertMessage/AlertMessage";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import TextLinkAsButton from "../../../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import { useHistory } from "react-router";
import ProfilePasswordCard from "../ProfilePasswordCard/ProfilePasswordCard";
import ProfileReferralCodeCard from "../ProfileReferralCodeCard/ProfileReferralCodeCard";
import { MyDiscountCode_myDiscountCode } from "components/public/CompanyCreation/graphql/MyDiscountCode";
import ProfilePhoneCard from "../ProfilePhoneCard/ProfilePhoneCard";
import { formatValue } from "../../../../common/formatters";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { ApiFeedback, FeatureFlags } from "global-query-types";

interface ProfileDetailsProps extends WithTranslation {
  email: string;
  changedEmail?: string | null;
  showUpdateEmailMessage?: boolean;
  passwordChangedAt: string | null;
  referralCode: MyDiscountCode_myDiscountCode | null;
  phoneNumber: string;
  hasTwoFactorAuth: boolean;
  updateAllowTwoFactorAuthPrompt: () => Promise<boolean | null | undefined>;
  resetTwoFactorAuth: () => Promise<ApiFeedback | null | undefined>;
  allowTwoFactorAuthPrompt: boolean;
}

const ProfileDetails = ({
  email,
  showUpdateEmailMessage,
  changedEmail,
  passwordChangedAt,
  referralCode,
  phoneNumber,
  hasTwoFactorAuth,
  updateAllowTwoFactorAuthPrompt,
  resetTwoFactorAuth,
  allowTwoFactorAuthPrompt,
  t
}: ProfileDetailsProps) => {
  const history = useHistory();

  const handleEmailEdit = () => {
    history.push(`/client/my-profile/change-email`);
  };

  const handlePasswordEdit = () => {
    history.push(`/client/my-profile/change-password`);
  };

  return (
    <div className="profile-details">
      <RideSectionCard
        title={t("client:profile.details.emailCard.emailAddress")}
        data-testid="profile-email"
        fixed>
        <div className="profile-details__email-container">
          <div className="profile-details__email">
            {email}
            <div className="profile-details__email-edit">
              <TextLinkAsButton onClick={handleEmailEdit} data-testid="profile-change-email-edit" />
            </div>
          </div>
          {changedEmail && (
            <AlertMessage type={AlertType.warning} data-testid="profile-email-info-message">
              {t("client:profile.details.emailCard.infoMessage", {
                currentEmail: email,
                newEmail: changedEmail
              })}
            </AlertMessage>
          )}
          {showUpdateEmailMessage && (
            <AlertMessage type={AlertType.success} data-testid="profile-email-updated-message">
              {t("client:profile.details.emailCard.updatedMessage")}
            </AlertMessage>
          )}
        </div>
      </RideSectionCard>

      <ProfilePasswordCard
        data-testid="profile-password"
        lastUpdate={passwordChangedAt}
        onEdit={handlePasswordEdit}
      />
      <FeatureFlag name={FeatureFlags.TwoFactorAuthenticationSetup}>
        <ProfilePhoneCard
          phoneNumber={formatValue(phoneNumber)}
          hasTwoFactorAuth={hasTwoFactorAuth}
          updateAllowTwoFactorAuthPrompt={updateAllowTwoFactorAuthPrompt}
          resetTwoFactorAuth={resetTwoFactorAuth}
          allowTwoFactorAuthPrompt={allowTwoFactorAuthPrompt}
        />
      </FeatureFlag>
      {referralCode && <ProfileReferralCodeCard referralCode={referralCode} />}
    </div>
  );
};

export default withTranslationReady("generic")(ProfileDetails);
