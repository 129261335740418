import React from "react";
import ResumeLinkHandler from "../../public/CompanyCreation/ShadowLogin/ResumeLinkHandler";
import { ResumeCompanyCreationStep } from "../../public/CompanyCreation/graphql/ResumeCompanyCreationStep";
import { useHistory } from "react-router-dom";

export const MyGmbHMagicLink = () => {
  const history = useHistory();

  const onLoggedIn = (data: ResumeCompanyCreationStep) => {
    const companyCreation = data.resumeCompanyCreationStep?.companyCreation;
    const id = companyCreation?.company?.id ?? companyCreation?.adminCompany?.id;
    history.push(`/client-data/entities/${id}/general-info`);
  };

  return <ResumeLinkHandler onLoggedIn={onLoggedIn} />;
};
