import React from "react";
import { CompanyCardProps } from "../../../lib/types/types";
import RideCardTable from "../../RideCardTable/RideCardTable";
import WepaBookingsTableContainer from "../../WepaBookingsTable/WepaBookingsTableContainer";
import { UserRole } from "../../../global-query-types";
import { MyTaxAdvisorCompany } from "../../../taxAdvisor/types/MyTaxAdvisorCompany";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

interface WepaBookingsCardProps extends CompanyCardProps {
  onClick?: () => void;
  asUser: UserRole;
  myTaxAdvisorCompany?: MyTaxAdvisorCompany | null;
}

const WepaBookingsCard = ({
  t,
  gmbh,
  myTaxAdvisorCompany,
  asUser
}: WepaBookingsCardProps & WithTranslation) => {
  return (
    <RideCardTable
      title={t("client:company:wepa-bookings")}
      data-testid="gmbh-wepa-bookings-details">
      <WepaBookingsTableContainer
        asUser={asUser}
        gmbh={gmbh}
        myTaxAdvisorCompany={myTaxAdvisorCompany}
      />
    </RideCardTable>
  );
};

export default withTranslationReady(["generic"])(WepaBookingsCard);
