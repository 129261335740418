import React from "react";
import { useParams } from "react-router";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { BookingRedirect } from "./BookingRedirect";
import { GetResumeMyCompanyCreationStep } from "../../../../components/public/CompanyCreation/Queries";
import { FlowTypeEnum } from "../../../../global-query-types";

export const BookingRedirectContainer = () => {
  const { id } = useParams<{ id: string }>();

  const companyCreationId = id === "start" ? undefined : id;
  const myCompanyCreationStepRequest = GetResumeMyCompanyCreationStep(
    companyCreationId,
    FlowTypeEnum.GMBH
  );

  const result = myCompanyCreationStepRequest?.data?.resumeMyCompanyCreationStep;

  return (
    <RequestFeedbackHandler requests={[myCompanyCreationStepRequest]}>
      <BookingRedirect
        pageId={id}
        companyCreationId={result?.id}
        postOrderStatus={result?.postOrderStatus}
      />
    </RequestFeedbackHandler>
  );
};
