import * as Yup from "yup";

export const ConfirmLegalDocumentsSchema = (t, legalDocumentsNames: string[]) => {
  const shape = {};

  for (const name of legalDocumentsNames) {
    shape[`legal-documents-${name}-confirmation-item`] = Yup.boolean().isTrue(
      t("generic:validation-required")
    );
  }

  return Yup.object().shape(shape);
};
