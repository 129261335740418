import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Trans, WithTranslation } from "react-i18next";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import React from "react";

const CompanyCategoriesQuestion = ({ t }: WithTranslation) => {
  return (
    <div data-testid={"company-category-question"} className={"company-categories"}>
      <Typography
        category={"Headline"}
        size={300}
        weight={"Heavy"}
        data-testid="company-category-question-title">
        {t("generic:short-order-form.company-categories-question.title")}
      </Typography>
      <Typography
        category="Paragraph"
        size={100}
        weight={"Light"}
        data-testid="company-category-question-description"
        className={`company-categories__description`}>
        <Trans
          i18nKey={t("generic:short-order-form.company-categories-question.description")}
          components={{ bold: <strong /> }}
        />
      </Typography>
      <Typography
        category="Paragraph"
        size={100}
        weight={"Light"}
        className={"company-categories__categories"}>
        <Trans i18nKey={t("generic:short-order-form.company-categories-question.categories")} />
      </Typography>
      <Typography
        category="Paragraph"
        size={100}
        weight={"Light"}
        data-testid="company-category-question-declaration"
        className={`company-categories__description`}>
        {t("generic:short-order-form.company-categories-question.declaration")}
      </Typography>
      <RideRadioSelect
        className="company-information-step__question-yes-no"
        name={"companyDoesNotFallIntoCategories"}
        options={[
          {
            label: `${t("generic:yes")}.`,
            value: "yes"
          },
          {
            label: `${t("generic:no")}.`,
            value: "no"
          }
        ]}
        variant={RadioSelectVariant.Light}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(CompanyCategoriesQuestion);
