import { useQuery } from "@apollo/react-hooks";
import { OrderOwnerType } from "global-query-types";
import gql from "graphql-tag";
import { GetOrderQuery, GetOrderQueryVariables } from "./graphql/GetOrderQuery";

export const GET_ORDER_QUERY = gql`
  query GetOrderQuery($id: ID, $submissionId: String, $ownerType: OrderOwnerType) {
    getOrder(id: $id, submissionId: $submissionId, ownerType: $ownerType) {
      id
      extra
      status
      ownerId
      entityId
      createdAt
      ownerType
      updatedAt
      entityType
      submissionId
      transferredAt
      productType
    }
  }
`;

export const GetOrder = (
  id: string | undefined,
  submissionId: string | undefined,
  ownerType?: OrderOwnerType
) => {
  const variables: GetOrderQueryVariables = { id: "", submissionId: "", ownerType };

  if (id) variables.id = id;
  if (submissionId) variables.submissionId = submissionId;

  return useQuery<GetOrderQuery, GetOrderQueryVariables>(GET_ORDER_QUERY, {
    variables,
    skip: !id
  });
};
