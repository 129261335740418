import { TFunction } from "i18next";
import React from "react";
import { useMutation } from "react-apollo";
import { ArchiveWithConfirmation } from "../../../sharedComponents/ArchiveWithConfirmation/ArchiveWithConfirmation";
import { SET_ARCHIVE_STATUS } from "../../../lib/api/mutations/SET_ARCHIVE_STATUS";
import {
  SetArchiveStatus,
  SetArchiveStatusVariables
} from "../../../lib/api/mutations/graphql/SetArchiveStatus";

interface ArchiveServiceProps {
  id: string;
  t: TFunction;
  refetchCompanyCreation;
}

export const ArchiveServiceContainer = ({ t, id, refetchCompanyCreation }: ArchiveServiceProps) => {
  const [setArchiveStatusMutation] = useMutation<SetArchiveStatus, SetArchiveStatusVariables>(
    SET_ARCHIVE_STATUS
  );

  const setArchiveStatus = async (companyCreationId: string) => {
    await setArchiveStatusMutation({
      variables: { companyCreationId }
    });
    await refetchCompanyCreation();
  };

  return <ArchiveService t={t} id={id} setArchiveStatus={setArchiveStatus} />;
};

export const ArchiveService = ({
  t,
  id,
  setArchiveStatus
}: {
  t: TFunction;
  id: string;
  setArchiveStatus: (id: string) => Promise<void>;
}) => (
  <ArchiveWithConfirmation
    title={t("nominee-dashboard:archive-confirmation")}
    t={t}
    onConfirm={() => setArchiveStatus(id)}
  />
);
