import * as Yup from "yup";
import { OwnershipFieldValidationSchema } from "../../components/Investments/Ownership/OwnershipField";
import { cityRegex, postalCode } from "./common";

export const RealEstateFormSchema = () => {
  return Yup.object().shape({
    label: Yup.string().required("generic:validation-required"),
    propertyValue: Yup.number()
      .positive("generic:must-be-positive-number")
      .required("generic:validation-required"),
    realEstateAssetType: Yup.string(),
    shortDescription: Yup.string(),
    image: Yup.string(),
    street: Yup.string(),
    city: Yup.string().matches(cityRegex, {
      message: "generic:validation-only-letters-spaces-dash"
    }),
    postalCode: Yup.string().matches(postalCode, { message: "generic:validation-only-numbers" }),
    latitude: Yup.number(),
    longitude: Yup.number(),
    percentageOwnership: Yup.number()
      .min(1, "generic:percentage-ownership-min-1")
      .max(100, "generic:percentage-ownership-max-100")
      .required("generic:validation-required"),
    ...OwnershipFieldValidationSchema
  });
};
