import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { getBankAccountById, getBankAccountByIdVariables } from "./graphql/getBankAccountById";

const GET_BANK_ACCOUNT_BY_ID = gql`
  query getBankAccountById($id: ID!) {
    getBankAccountById(id: $id) {
      id
      name
      bankProvider
      iban
      plan
      company {
        id
      }
    }
  }
`;

export const GetBankAccountById = (id: string) =>
  useQuery<getBankAccountById, getBankAccountByIdVariables>(GET_BANK_ACCOUNT_BY_ID, {
    variables: {
      id
    }
  });
