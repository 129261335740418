import React, { useEffect, useState } from "react";
import { FileUploadService } from "../../lib/services/FileUploadService/FileUploadService";
import UploadWrapper, { UploadNodeState } from "../UploadWrapper/UploadWrapper";
import FolderNode from "./FolderNode";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { FileSystemNodeList } from "../FileSystemNode/FileSystemNodeList";
import { FileSystemNodeProps } from "../FileSystemNode/FileSystemNode";
import { WithTranslation } from "react-i18next";

export interface FolderNodeWithUploadProps extends FileSystemNodeProps, WithTranslation {
  openFileSelectPopUp?: () => void;
  onToggleExpand?: () => void;
  expanded?: boolean;
  className?: string;
}

const FolderNodeWithUpload = (props: FolderNodeWithUploadProps) => {
  const [currentNode, setCurrentNode] = useState(props.node);

  useEffect(() => {
    setCurrentNode(props.node);
  }, [props.node]);

  const uploadService = FileUploadService.getInstance();

  return (
    <UploadWrapper
      entityId={props.entityId}
      destinationNodeId={props.node.nodeId}
      destinationNodeName={props.node.name}
      uploadService={uploadService}
      entityType={props.entityType}
      onUploaded={async (uploadedNodes) => {
        await props.refetch?.();
      }}
      multiple>
      {({ fileNodes, openFileSelectPopUp, dragging }) => {
        const uploadingOrFailedNodes = fileNodes.filter(
          (node) => node.uploadingState !== UploadNodeState.UPLOADED
        );

        const childNodes = [...currentNode.childNodes, ...uploadingOrFailedNodes];
        return (
          <>
            <FolderNode
              {...props}
              className={`${props.className ?? ""} ${
                dragging ? "node-tree__folder-label--hover-with-file" : ""
              }`}
              openFileSelectPopUp={openFileSelectPopUp}
              node={{
                ...currentNode,
                childNodes
              }}
            />
            <FileSystemNodeList
              {...props}
              childNodes={childNodes}
              expanded={props.expanded ?? false}
            />
          </>
        );
      }}
    </UploadWrapper>
  );
};

export default withTranslationReady(["general"])(FolderNodeWithUpload);
