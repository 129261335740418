import React from "react";
import { IconProps } from "../../components/common/icons/IconProps";

export const EyeOn = ({ size = 26 }: IconProps) => (
  <svg
    data-testid="icon-eye-on"
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 13C2 13 6 5 13 5C20 5 24 13 24 13C24 13 20 21 13 21C6 21 2 13 2 13Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 16C14.6569 16 16 14.6569 16 13C16 11.3431 14.6569 10 13 10C11.3431 10 10 11.3431 10 13C10 14.6569 11.3431 16 13 16Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
