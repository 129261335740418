import { getCurrentYear, getCurrentMonth } from "./getCurrentYear";

export function getPreviousFiscalYears() {
  const currentYear = getCurrentYear();
  const currentMonth = getCurrentMonth();

  if (currentMonth >= 5) {
    return [currentYear - 1];
  }

  return [currentYear - 1, currentYear - 2];
}
