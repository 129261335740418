import React from "react";
import { ClientSelect, ClientSelectOption } from "../../ClientSelect/ClientSelect";
import { FormikProvider, useFormik } from "formik";
import RequestFeedbackForm from "../../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { useHistory, useLocation } from "react-router";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import { getSubmitPath } from "../../../../lib/routing/getSubmitPath";

interface CompanySelectFieldEditProps extends WithTranslation {
  options: ClientSelectOption[];
  value: string;
  companyId: string;
  section: string;
  fieldName: string;
  onSave?: (value: string) => Promise<void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
}

const CompanySelectFieldEdit = ({
  value,
  options,
  onSave,
  companyId,
  section,
  fieldName,
  requests,
  t
}: CompanySelectFieldEditProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const formik = useFormik({
    initialValues: {
      value
    },
    onSubmit: async (values) => {
      await onSave?.(values["value"]);
      history.push(getSubmitPath(pathname, companyId, section));
    }
  });

  return (
    <div className="client-field-edit">
      <FormikProvider value={formik}>
        <RequestFeedbackForm requests={requests} submitLabel={t("generic:save")}>
          <ClientSelect
            name="value"
            testIdPrefix="value-select"
            label={t(`client:company.field.${fieldName}`)}
            options={options}
          />
        </RequestFeedbackForm>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady("generic")(CompanySelectFieldEdit);
