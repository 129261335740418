import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import RideOverview from "../../../sharedComponents/RideOverview/RideOverview";
import TaxAdvisorFormerClientsContainer from "../../components/TaxAdvisorFormerClients/TaxAdvisorFormerClientsContainer";

interface TaxAdvisorClientsSceneProps extends WithTranslation {}

const TaxAdvisorClientsScene = ({ t }: TaxAdvisorClientsSceneProps) => {
  return (
    <RideOverview
      imageSrc={"/assets/third-party-tax-advisor/header-icon.svg"}
      title={t("tax-advisor:former-clients.overview.title")}
      subtitle={t("tax-advisor:former-clients.overview.subtitle")}>
      <TaxAdvisorFormerClientsContainer />
    </RideOverview>
  );
};

export default withTranslationReady("tax-advisor")(TaxAdvisorClientsScene);
