import React, { useState } from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { FiAtSign, FiShoppingCart } from "react-icons/fi";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../../../components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import { useMutation } from "react-apollo";
import { COMPLETE_COMPANY_CREATION_ORDER } from "../../../../../components/public/CompanyCreation/Mutations";
import logger from "../../../../../common/Logger";
import { useHistory } from "react-router-dom";
import LoadingAlert from "../../../../../components/generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { CheckboxField } from "../../../../../components/generic/CheckboxField";
import { getFlowTypePath } from "../../../../../components/public/CompanyCreationFlowType/currentFlowType";
import { FeatureFlags, FlowTypeEnum } from "../../../../../global-query-types";
import { OrderNextSteps } from "../../../../../components/generic/Trader-GmbH/companyCreation/OrderNextSteps";
import { OrderAssignment } from "../../../../../components/generic/Trader-GmbH/companyCreation/OrderAssignment";
import { OrderAGBAgreement } from "../../../../../components/generic/Trader-GmbH/companyCreation/OrderAGBAgreement";
import { OrderProduct } from "../../../../../components/public/AdminService/Steps/CompleteOrder/OrderProduct";
import { ApplyDiscountCodeContainer } from "../../../../../components/forms/DiscountCode/ApplyDiscountCodeContainer";
import { PhoneIcon } from "../../../../../sharedComponents/icons/PhoneIcon";
import { DataLayer } from "../../../../../lib/services/GoogleTagManager/GoogleTagManager";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { withTranslation } from "react-i18next";
import { FeatureFlagService } from "../../../../../lib/services/FeatureFlagService/FeatureFlagService";

interface OrderProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation | undefined;
  refetchAndResetStep: (id: string) => Promise<void>;
  currentFlowType?: FlowTypeEnum;
  selectedPackage?;
}

const referralCodeExceptions = [
  "elias",
  "konewka",
  "schultze",
  "real-estate-lp",
  "investor-lp",
  "trader-lp-gen"
];

export const Order = ({
  t,
  myCompanyCreationDetails,
  refetchAndResetStep,
  currentFlowType,
  selectedPackage
}: OrderProps) => {
  let history = useHistory();
  const company = myCompanyCreationDetails?.company || myCompanyCreationDetails?.adminCompany;

  const [completeCompanyCreationOrder, { error, loading }] = useMutation(
    COMPLETE_COMPANY_CREATION_ORDER
  );

  const [agreed, setAgreed] = useState(false);
  const [localError, setLocalError] = useState("");

  const AGBAgreement = () => <OrderAGBAgreement t={t} />;

  const handleSubmit = async () => {
    setLocalError("");
    if (!agreed) {
      setLocalError(t("trader-gmbh:not-agreed-error"));
      return;
    }

    try {
      await completeCompanyCreationOrder({
        variables: {
          companyCreationId: myCompanyCreationDetails?.id ?? ""
        }
      });

      const postOrderFlowType = getFlowTypePath(currentFlowType!);

      DataLayer.CompleteOrder({ flowType: postOrderFlowType });

      await refetchAndResetStep(myCompanyCreationDetails?.id ?? "");

      if (
        currentFlowType === FlowTypeEnum.GMBH &&
        FeatureFlagService.instance.isEnabled(FeatureFlags.AdminServiceUpsellOffering)
      ) {
        history.push(`/offer/admin-service/${myCompanyCreationDetails?.id}`);
      } else {
        history.push(`/${postOrderFlowType}/${myCompanyCreationDetails?.id ?? ""}/status`);
      }
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to complete the company creation order:`);
      logger.error(error);
    }
  };

  const hasLegacyReferralCode = referralCodeExceptions.includes(
    myCompanyCreationDetails?.referral ?? ""
  );
  const isCoBrandingReferral = !myCompanyCreationDetails?.referral || hasLegacyReferralCode;
  const isExistingCodeUseOfSameOrderType =
    company?.discountCodeUse?.flowType === myCompanyCreationDetails?.flowType;
  const canUseDiscountCode =
    isCoBrandingReferral && (!company?.discountCodeUse || isExistingCodeUseOfSameOrderType);

  return (
    <>
      <div className="step-bg">
        <OrderProduct currentFlowType={currentFlowType} selectedPackage={selectedPackage} t={t} />
        <div className="md-45 px-md-6 pb-md-45px-0 pb-0">
          <FeatureFlag name={FeatureFlags.RemoveChoosePackageFromASOrder}>
            {currentFlowType === FlowTypeEnum.ADMIN_SERVICE && <PaperAssetsList t={t} />}
          </FeatureFlag>
          <OrderAssignment t={t} currentFlowType={currentFlowType} />
          <div className="align-agreement">
            {canUseDiscountCode && myCompanyCreationDetails?.id && (
              <ApplyDiscountCodeContainer
                t={t}
                discountCode={company?.discountCodeUse?.discountCode}
                companyCreationId={myCompanyCreationDetails.id}
                refetchAndResetStep={refetchAndResetStep}
              />
            )}

            <div
              className={
                "mt-4 agreement-checkbox " + (localError ? "agreement-checkbox-error" : "")
              }>
              <CheckboxField
                name={"accept-agreement-complete-order"}
                label={AGBAgreement()}
                state={agreed}
                handleClick={(e) => setAgreed(e.target.checked)}
              />
            </div>
            <div className="mt-3">
              {localError ? (
                <div className="invalid-feedback d-block" data-testid="agreement-invalid-feedback">
                  {localError}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mt-4">
              <Row>
                <Col xs={12} md={7}>
                  <Button
                    variant="outline-light"
                    className="order-btn"
                    block
                    onClick={handleSubmit}
                    data-testid="order-now">
                    <span className="mr-2">
                      <FiShoppingCart size={24} />
                    </span>
                    {t("trader-gmbh:order-now")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          {loading || error?.message ? (
            <Row className="mt-3">
              <Col>
                <LoadingAlert t={t} loading={loading} />
                <ErrorAlertLegacy t={t} error={error?.message} />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="next-steps-card mt-5 px-md-6 px-3 py-35">
        <Row className="mt-md-5">
          <OrderNextSteps t={t} currentFlowType={currentFlowType} />
        </Row>
        <Row className="align-next-steps-btn mt-3">
          <Col xs={12} md={6} lg={5} className="mt-3">
            <a
              href={t(`trader-gmbh:email@.href.${currentFlowType}`)}
              className="btn btn-outline-light contact-btn-blk"
              data-testid="contact-email">
              <span className="mr-2">
                <FiAtSign size="20" />
              </span>
              {t(`trader-gmbh:email@.${currentFlowType}`)}
            </a>
          </Col>
          <Col xs={12} md={6} lg={5} className="mt-3">
            <a
              href={t(`generic:contact.phone.href`)}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-light contact-btn-blk"
              data-testid="contact-phone">
              <span className="mr-2">
                <PhoneIcon />
              </span>
              {t("generic:contact.phone.number")}
            </a>
            <br />
          </Col>
        </Row>
      </div>
    </>
  );
};

export const PaperAssetsList = ({ t }) => {
  return (
    <div data-testid="paper-assets-package-list">
      <ListGroup variant="flush" className="step-7-agreement d-flex px-0" as="ul">
        <ListGroup.Item as="li">
          {t("admin-service:paper-assets-package-list:item1")}
        </ListGroup.Item>
        <ListGroup.Item as="li">
          {t("admin-service:paper-assets-package-list:item2")}
        </ListGroup.Item>
        <ListGroup.Item as="li">
          {t("admin-service:paper-assets-package-list:item3")}
        </ListGroup.Item>
        <ListGroup.Item as="li">
          {t("admin-service:paper-assets-package-list:item4")}
        </ListGroup.Item>
        <ListGroup.Item as="li">
          {t("admin-service:paper-assets-package-list:item5")}
        </ListGroup.Item>
        <ListGroup.Item as="li">
          {t("admin-service:paper-assets-package-list:item6")}
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default withTranslation(["admin-service"])(Order);
