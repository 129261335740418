import { ValueView } from "components/common/layout/ValueView";
import {
  BoxLinkField,
  CapitalContributionField,
  ProjectNameField,
  PublishedStatusField,
  TokenizedSharesField,
  TokenizedStatusField,
  TradeTaxFreeStatusField
} from "components/generic/company-form/fields";
import { Form, FormikProvider, useFormik } from "formik";
import { PublishType } from "global-query-types";
import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import EditableSection from "sharedComponents/EditableSection/EditableSection";
import { UpdateSPVDetailsSchema } from "lib/validation/UpdateSPVDetailsSchema";

export interface IUpdateSPVDetails {
  name: string;
  projectName: string;
  capitalContribution: number;
  tradeTaxFree: string;
  tokenized: string;
  status: PublishType;
  tokenizedShares: number;
  boxLink: string;
}

export interface SPVCompanyDetailsSectionProps extends WithTranslation {
  onSubmit: (values: IUpdateSPVDetails) => Promise<void>;
  company: NomineeCompanyDetails_companyById;
}

const SPVCompanyDetailsSection = ({ onSubmit, t, company }: SPVCompanyDetailsSectionProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onView = () => {
    setIsEditMode(false);
  };

  const initialData: IUpdateSPVDetails = {
    name: company.name,
    projectName: company.projectName ?? "",
    capitalContribution: company.capitalContribution,
    tradeTaxFree: convertFromBooleanToString(company.tradeTaxFree),
    tokenized: convertFromBooleanToString(company.tokenized),
    status: company.status!,
    tokenizedShares: company.tokenizedShares ?? 0.0,
    boxLink: company.boxLink ?? ""
  };

  const formik = useFormik({
    initialValues: initialData,
    validateOnChange: true,
    validationSchema: UpdateSPVDetailsSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
    enableReinitialize: true
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditableSection
          testIdPrefix="spv-company-details-"
          title={`SPV Company Details`}
          isEditMode={isEditMode}
          onEdit={onEdit}
          onView={onView}>
          {isEditMode ? (
            <SPVCompanyFieldsEdit t={t} formik={formik} />
          ) : (
            <SPVCompanyFieldsView t={t} company={company} />
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["generic", "nominee-dashboard", "spv-company"])(
  SPVCompanyDetailsSection
);

function convertFromBooleanToString(booleanField: boolean | null) {
  return booleanField === true ? "YES" : "NO";
}

const SPVCompanyFieldsEdit = ({ t, formik }): any => {
  return (
    <>
      <Row className="show-grid">
        <Col md={12} lg={6}>
          <ProjectNameField t={t} formik={formik} />
        </Col>
      </Row>
      <Row className="show-grid">
        <Col md={6} lg={4}>
          <TokenizedSharesField t={t} formik={formik} />
        </Col>
        <Col md={6} lg={4}>
          <TokenizedStatusField t={t} formik={formik} />
        </Col>
      </Row>
      <Row className="show-grid">
        <Col md={6} lg={4}>
          <TradeTaxFreeStatusField t={t} formik={formik} />
        </Col>
        <Col md={6} lg={4}>
          <CapitalContributionField t={t} formik={formik} />
        </Col>
        <Col md={6} lg={4}>
          <PublishedStatusField t={t} formik={formik} />
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={12}>
          <BoxLinkField t={t} formik={formik} />
        </Col>
      </Row>
    </>
  );
};

const SPVCompanyFieldsView = ({
  t,
  company
}: {
  t: any;
  company: NomineeCompanyDetails_companyById;
}): any => {
  return (
    <>
      <Row className="show-grid">
        <Col md={12} lg={6}>
          <ValueView
            name={t("generic:projectName")}
            testId="view-project-name"
            value={company.projectName}
            t={t}
          />
        </Col>
      </Row>
      <Row className="show-grid">
        <Col md={6} lg={4}>
          <ValueView
            name={t("generic:tokenizedShares")}
            testId="view-tokenizedShares"
            value={company.tokenizedShares}
            t={t}
          />
        </Col>
        <Col md={6} lg={4}>
          <ValueView
            name={t("generic:tokenized")}
            testId="view-tokenized"
            value={convertFromBooleanToString(company.tokenized)}
            t={t}
          />
        </Col>
      </Row>
      <Row className="show-grid">
        <Col md={6} lg={4}>
          <ValueView
            name={t("generic:trade-tax-free")}
            testId="view-trade-tax-free"
            value={convertFromBooleanToString(company.tradeTaxFree)}
            t={t}
          />
        </Col>
        <Col md={6} lg={4}>
          <ValueView
            name={t("generic:capital-contribution")}
            testId="view-capital-contribution"
            value={company.capitalContribution}
            t={t}
          />
        </Col>
        <Col md={6} lg={4}>
          <ValueView
            name={t("generic:publish-status")}
            testId="view-publish-status"
            value={company.status}
            t={t}
          />
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={12}>
          <ValueView
            name={t("generic:boxLink")}
            testId="view-box-link"
            value={company.boxLink}
            t={t}
          />
        </Col>
      </Row>
    </>
  );
};
