import { FeatureFlags } from "global-query-types";
import React, { PropsWithChildren } from "react";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";

interface DisabledFeatureFlagProps extends PropsWithChildren<any> {
  name: FeatureFlags;
}

export const DisabledFeatureFlag = ({ name, children }: DisabledFeatureFlagProps) => {
  const shouldShowDisabledFeatureFlag = !FeatureFlagService.instance.isEnabled(name);
  return <>{shouldShowDisabledFeatureFlag && children}</>;
};
