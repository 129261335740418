import React from "react";
import { Field, getIn } from "formik";
import { ReportValidationError } from "./ReportValidationError";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { TFunction } from "i18next";

type ErrorMessageProps = { name: string; extra: any; ignoreTouch?: boolean; className?: string };

export const ErrorMessage = ({
  t,
  name,
  extra,
  ignoreTouch,
  className
}: { t: TFunction } & ErrorMessageProps) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = ignoreTouch || getIn(form.touched, name);

      return touch && error ? (
        <div
          className={`invalid-feedback d-block mt-2 ${className ?? ""}`}
          data-testid={`${name}-invalid-feedback`}>
          {t(error)}
          {extra["form"] ? (
            <ReportValidationError form={extra["form"]} field={name} error={error} />
          ) : (
            ""
          )}
        </div>
      ) : null;
    }}
  </Field>
);

export const ErrorMessageWithT = withTranslationReady("generic")(
  ErrorMessage as React.ComponentType<WithTranslation & ErrorMessageProps>
);
