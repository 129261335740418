import React from "react";
import { useMutation } from "react-apollo";
import logger from "../../../common/Logger";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import ReferralCodeDetails, { AllowedDiscountCodeService } from "./ReferralCodeForm";
import { AllDiscountCodesQuery_allDiscountCodes } from "lib/api/queries/graphql/AllDiscountCodesQuery";
import { ALL_DISCOUNT_CODES } from "lib/api/queries/AllDiscountCodes";
import { NOMINEE_UPDATE_DISCOUNT_CODE } from "lib/api/mutations/NOMINEE_UPDATE_DISCOUNT_CODE";
import {
  nomineeUpdateDiscountCode,
  nomineeUpdateDiscountCodeVariables
} from "lib/api/mutations/graphql/nomineeUpdateDiscountCode";

export const ReferralCodeFormContainer = ({
  referralCode
}: {
  referralCode: AllDiscountCodesQuery_allDiscountCodes;
}) => {
  const [callUpdateNomineeDiscountCodeMutation, updateNomineeDiscountCodeRequest] = useMutation<
    nomineeUpdateDiscountCode,
    nomineeUpdateDiscountCodeVariables
  >(NOMINEE_UPDATE_DISCOUNT_CODE, {
    refetchQueries: [{ query: ALL_DISCOUNT_CODES }]
  });

  const discountCodeId = referralCode.id ?? "";

  const updateDiscountCode = async (
    code: string,
    referralValue: number,
    referrerValue: number,
    discountCodeAllowedService: AllowedDiscountCodeService
  ) => {
    try {
      await callUpdateNomineeDiscountCodeMutation({
        variables: {
          id: discountCodeId,
          code,
          referralValue,
          referrerValue,
          discountCodeAllowedService
        }
      });
    } catch (error) {
      logger.errorMessage(`Unable to update discountCode`);
      logger.error(error);
    }
  };

  return (
    <RequestFeedbackHandler requests={[updateNomineeDiscountCodeRequest]}>
      <ReferralCodeDetails referralCode={referralCode} updateDiscountCode={updateDiscountCode} />
    </RequestFeedbackHandler>
  );
};
