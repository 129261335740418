import React from "react";
import { WithTranslation } from "react-i18next";
import { Panel } from "../../../sharedComponents/Panel/Panel";
import { AuthLayout } from "../../../sharedComponents/templates/AuthLayout/AuthLayout";
import { useHistory, useRouteMatch } from "react-router-dom";
import SignUpSection from "../SignUpSection/SignUpSection";
import { UserRole } from "../../../global-query-types";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

interface SignupProps extends WithTranslation {
  asUser?: UserRole;
  handleSignUp: (password: string, signupToken: string) => Promise<boolean>;
}

const Signup = ({ t, asUser = UserRole.Client, handleSignUp }: SignupProps) => {
  const match = useRouteMatch();
  const history = useHistory();

  const signupToken = match.params["id"];
  if (!signupToken) {
    history.push("/");
    return null;
  }

  const handleSubmit = async (password) => {
    const successfulSignup = await handleSignUp(password, signupToken);
    if (successfulSignup) history.push("/");
  };

  return (
    <AuthLayout title={t("auth:welcome.title")} subtitle={""}>
      <Panel>
        <SignUpSection signupToken={signupToken} handleSubmit={handleSubmit} asUser={asUser} />
      </Panel>
    </AuthLayout>
  );
};

export default withTranslationReady(["auth", "sign-up", "generic", "errorCodes"])(Signup);
