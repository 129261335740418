import React from "react";
import { IconProps } from "../../components/common/icons/IconProps";

export const EyeOff = ({ size = 26 }: IconProps) => (
  <svg
    data-testid="icon-eye-off"
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9 5.24002C11.5883 5.0789 12.2931 4.99836 13 5.00003C20 5.00003 24 13 24 13C23.393 14.1356 22.6691 15.2048 21.84 16.19M15.12 15.12C14.8454 15.4148 14.5141 15.6512 14.1462 15.8151C13.7782 15.9791 13.3809 16.0673 12.9781 16.0744C12.5753 16.0815 12.1752 16.0074 11.8016 15.8565C11.4281 15.7056 11.0887 15.4811 10.8038 15.1962C10.519 14.9113 10.2944 14.572 10.1435 14.1984C9.99262 13.8249 9.91853 13.4247 9.92563 13.0219C9.93274 12.6191 10.0209 12.2219 10.1849 11.8539C10.3488 11.4859 10.5853 11.1547 10.88 10.88M18.94 18.94C17.2306 20.243 15.1491 20.9649 13 21C6 21 2 13 2 13C3.24389 10.6819 4.96914 8.65663 7.06 7.06003L18.94 18.94Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 2L24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
