import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { TFunction } from "i18next";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "../../graphql/MyCompanyCreationQuery";
import { SharePercentageArrayField, TraderSharesFormKey } from "../../fields";
import logger from "../../../../../common/Logger";
import { AssertionError } from "assert";
import { useMutation } from "react-apollo";
import { UPDATE_SHAREHOLDERS_SHARES } from "../../Mutations";
import {
  UpdateShareholdersShares,
  UpdateShareholdersSharesVariables
} from "../../graphql/UpdateShareholdersShares";
import { ApolloQueryResult } from "apollo-client";
import { FlowTypeEnum, RolesInGroup } from "../../../../../global-query-types";
import { ErrorMessage } from "../../../../generic/ErrorMessage";
import { CompanyCreationSharesSchema } from "../../../../../lib/validation/CompanyCreationSharesSchema";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { DataLayer } from "../../../../../lib/services/GoogleTagManager/GoogleTagManager";

interface CompanyCreationSharesFormProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation | undefined;
  resetActiveStep: (string) => void;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
}

export const CompanyCreationSharesForm = ({
  t,
  myCompanyCreationDetails,
  resetActiveStep,
  refetchMyCompanyCreation,
  currentFlowType
}: CompanyCreationSharesFormProps) => {
  const [updateShares, { error: errorShares, loading: loadingShares }] = useMutation<
    UpdateShareholdersShares,
    UpdateShareholdersSharesVariables
  >(UPDATE_SHAREHOLDERS_SHARES);

  const error = errorShares;
  const loading = loadingShares;

  const [formError, setFormError] = useState("");

  const getCompanyCreationId = () =>
    (myCompanyCreationDetails && myCompanyCreationDetails.id) || "";

  const shareholdersInfo = myCompanyCreationDetails?.company?.group.memberships ?? [];

  const shares = shareholdersInfo
    .filter((elem) => elem.role === RolesInGroup.Shareholder)
    .map((elem) => {
      let fullName = "";
      let isDisabled = false;

      if (elem.person) {
        fullName = `${elem.person.firstName} ${elem.person.lastName}`;
      }

      if (elem.company) {
        fullName = `${elem.company.name} ${elem.company.legalForm}`;
        isDisabled = true;
      }

      return {
        id: elem.person?.id ?? "",
        share: elem.share?.share ?? 0,
        fullName,
        isDisabled
      };
    });

  const canSubmit = shares.every((share) => !share.isDisabled);

  const handleSubmit = async (values) => {
    let finalSharesDetails = values.shares.map(({ firstName, lastName, ...finalObj }) => finalObj);

    setFormError("");

    const totalShares = finalSharesDetails.map((rec) => rec.share).reduce((a, b) => a! + b!, 0);

    if (totalShares !== 100) {
      setFormError(t("trader-gmbh:shares-percentage-sum"));

      DataLayer.FormValidationError({
        formName: TraderSharesFormKey,
        fieldName: "share",
        errorMessage: t("trader-gmbh:shares-percentage-sum")
      });

      return;
    }

    const companyCreationId = getCompanyCreationId();
    const allShareholders = finalSharesDetails.map((shareholder) => ({
      id: shareholder.id,
      share: shareholder.share
    }));

    if (companyCreationId.length) {
      try {
        await updateShares({
          variables: {
            companyCreationId: companyCreationId,
            allShareholders
          }
        });

        const { data } = await refetchMyCompanyCreation({
          id: companyCreationId
        });

        resetActiveStep(data.myCompanyCreation?.currentStep);

        return;
      } catch (error) {
        logger.error(error);
        throw new AssertionError({ message: "update shares not successful" });
      }
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col className="steps-form-description">
            {t("trader-gmbh:shares-form-description")}
            <br />
            {t("trader-gmbh:shares-form-description-2")}
          </Col>
        </Row>
        <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
          <Container>
            <Row>
              <Col>
                <Formik
                  initialValues={{ shares }}
                  onSubmit={handleSubmit}
                  validationSchema={CompanyCreationSharesSchema}
                  validateOnChange={true}
                  enableReinitialize={true}>
                  {(formik) => (
                    <Form noValidate onSubmit={formik.handleSubmit}>
                      <div className="mt-md-3">
                        <FieldArray
                          name="shares"
                          render={() => (
                            <div className="p-md-35 p-0">
                              {formik.values.shares &&
                                formik.values.shares.length > 0 &&
                                formik.values.shares.map((fields, index) => {
                                  return (
                                    <div key={index} data-testid="shares-form">
                                      <div className="row shares-inline">
                                        <div
                                          data-testid="shareholders-name"
                                          className="col col-md-4">
                                          {fields.fullName}
                                        </div>
                                        <div className="col col-auto">
                                          <SharePercentageArrayField
                                            t={t}
                                            formik={formik}
                                            values={formik.values.shares[index] ?? 0}
                                            disabled={fields.isDisabled}
                                            touched={(formik.touched.shares ?? [])[index]}
                                            errors={(formik.errors.shares ?? [])[index]}
                                            prefix={`shares.${index}.`}
                                            localError={formError}
                                            extra={{ errorComponent: () => "" }}
                                          />
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        t={t}
                                        name={`shares.${index}.share`}
                                        extra={{ form: TraderSharesFormKey }}
                                      />
                                    </div>
                                  );
                                })}
                              {formError ? (
                                <div
                                  className="invalid-feedback d-block"
                                  data-testid="percentage-invalid-feedback">
                                  {formError}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        />
                        {loading || error?.message ? (
                          <Row className="mt-3">
                            <Col>
                              <LoadingAlert t={t} loading={loading} />
                              <ErrorAlertLegacy t={t} error={error?.message} />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        <Row className="mt-3">
                          <Col>
                            <div className="d-flex justify-content-end">
                              <Button
                                type="submit"
                                data-testid="submit"
                                disabled={!canSubmit}
                                className="step-submit-btn"
                                variant="outline-light">
                                {t("generic:save")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
