import React, { useState } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideIcon from "uiLibrary/components/RideIcon/RideIcon";
import { RideSize } from "lib/types/types";
import { StatusIcon } from "sharedComponents/icons/StatusIcon";

interface WarningWithTooltipProps extends WithTranslation {
  chapterKey: string;
  dataTestId: string;
}

export const MissingInformationWarning = withTranslationReady(["generic"])(
  ({ t }: WithTranslation) => {
    return (
      <div
        className="review-and-submit-ltb__missing-information-warning"
        data-testid="missing-information-warning">
        <RideIcon name="warning" size={RideSize.SMALL} />
        <div className="review-and-submit-ltb__missing-information-warning__content">
          <span>
            {t(
              "generic:ride-broker-onboarding.review.missing-information-warning.before-edit-button"
            )}
          </span>
          <RideIcon name="edit-max" size={RideSize.SMALL} dataTestId="edit-button" />
          <span>
            {t(
              "generic:ride-broker-onboarding.review.missing-information-warning.after-edit-button"
            )}
          </span>
        </div>
      </div>
    );
  }
);

export const MissingOrderingUserWarning = withTranslationReady(["generic"])(
  ({ t, currentUserEmail }: WithTranslation & { currentUserEmail: string }) => {
    return (
      <div
        className="review-and-submit-ltb__missing-information-warning"
        data-testid="missing-ordering-user-warning">
        <RideIcon name="warning" size={RideSize.SMALL} />
        <span>
          {`${t(
            "generic:ride-broker-onboarding.review.missing-shareholder-or-director-representing-ordering-user.prefix"
          )} ${currentUserEmail}. ${t(
            "generic:ride-broker-onboarding.review.missing-shareholder-or-director-representing-ordering-user.suffix"
          )}`}
        </span>
      </div>
    );
  }
);

export const WarningWithTooltip = withTranslationReady(["generic"])(
  ({ chapterKey, dataTestId }: WarningWithTooltipProps) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <div className="review-and-submit-ltb__warning-with-tooltip" data-testid={dataTestId}>
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          className="review-and-submit-ltb__warning-with-tooltip__warning-icon">
          <RideIcon name="warning" size={RideSize.SMALL} />
        </div>
        {showTooltip && (
          <div className="review-and-submit-ltb__warning-with-tooltip__tooltip">
            <Trans i18nKey={`generic:ride-broker-onboarding.review.tooltips.${chapterKey}`} />
          </div>
        )}
      </div>
    );
  }
);

interface MissingValueProps extends WithTranslation {
  placeholderValue?: string;
  isMandatory: boolean;
}

export const MissingValue = withTranslationReady(["generic"])(
  ({ t, placeholderValue, isMandatory }: MissingValueProps) => {
    const value = placeholderValue ?? t("generic:ride-broker-onboarding.review.missing-value");
    return (
      <div data-testid={"missing-value"}>
        {!isMandatory && value}
        {isMandatory && (
          <div
            data-testid="missing-mandatory-value"
            className="ltb-review-field__missing-mandatory-information">
            <span>
              <StatusIcon />
            </span>{" "}
            {value}
          </div>
        )}
      </div>
    );
  }
);
