import React from "react";
import { IconProps } from "./IconProps";

export const ShoppingCartIcon = ({ size = 24, className }: IconProps) => {
  return (
    <svg
      data-testid="shopping-cart-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5289_25)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.365 5.25H22C22.114 5.2472 22.2272 5.27045 22.3309 5.31799C22.4347 5.36553 22.5261 5.4361 22.5985 5.52433C22.6708 5.61256 22.722 5.71613 22.7483 5.82715C22.7745 5.93817 22.7751 6.05372 22.75 6.165L21.25 12.915C21.2119 13.0842 21.1163 13.2349 20.9796 13.3416C20.8429 13.4483 20.6734 13.5042 20.5 13.5H7L7.615 16.5H20.5V18H7C6.82454 18.0035 6.6534 17.9454 6.51636 17.8358C6.37932 17.7262 6.28506 17.572 6.25 17.4L3.385 3H1V1.5H4C4.17546 1.49646 4.3466 1.55456 4.48364 1.6642C4.62068 1.77383 4.71494 1.92804 4.75 2.1L5.365 5.25ZM6.715 12H19.9L21.0625 6.75H5.665L6.715 12ZM8.5 22.5C9.32843 22.5 10 21.8284 10 21C10 20.1716 9.32843 19.5 8.5 19.5C7.67157 19.5 7 20.1716 7 21C7 21.8284 7.67157 22.5 8.5 22.5ZM19 22.5C19.8284 22.5 20.5 21.8284 20.5 21C20.5 20.1716 19.8284 19.5 19 19.5C18.1716 19.5 17.5 20.1716 17.5 21C17.5 21.8284 18.1716 22.5 19 22.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5289_25">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
