import LowTaxBrokerOrdersListContainer from "admin/components/LowTaxBrokerOrdersList/LowTaxBrokerOrdersListContainer";
import React from "react";
import { WithTranslation } from "react-i18next";
import { PageLayout } from "sharedComponents/templates/PageLayout/PageLayout";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

const LowTaxBrokerOrdersListScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("generic:ltb-order-list.title")}
      mainContent={<LowTaxBrokerOrdersListContainer />}
    />
  );
};

export default withTranslationReady(["generic"])(LowTaxBrokerOrdersListScene);
