import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  EmailArrayField,
  FirstNameArrayField,
  LastNameArrayField,
  PhoneField,
  SalutationField,
  TitleArrayField
} from "../../../CompanyCreation/fields";
import { SalutationValueEnum } from "../../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import { CheckboxField } from "../../../../generic/CheckboxField";

export const PersonalInfoFields = ({
  t,
  formik,
  agreed,
  setAgreed,
  localError,
  currentFlowType,
  values,
  touched,
  errors,
  prefix = "",
  disabled = false
}) => {
  const AdminServiceAgreement = () => (
    <span className="admin-service-agreement">
      <span>{t("admin-service:agreement-text")}</span>
    </span>
  );

  const salutationTypeOptions = [{ label: t("trader-gmbh:mr") }, { label: t("trader-gmbh:ms") }];

  const handleSalutationTypeChange = (event) => {
    const newSalutationType: SalutationValueEnum = +event.target["value"];
    formik.setFieldValue("salutation", newSalutationType);
  };

  const handlePhoneNumberChange = (value) => {
    const newPhoneNumber = `${value}`;
    formik.setFieldValue("phoneNumber", newPhoneNumber);
  };

  return (
    <>
      <div className="flex-column" data-testid="personal-fields">
        <div className="mt-md-5 px-md-5">
          <Row>
            <Col className="col-auto ml-1">
              <div>
                <div
                  className={
                    "mt-4 agreement-checkbox " + (localError ? "agreement-checkbox-error" : "")
                  }>
                  <CheckboxField
                    name={"accept-agreement-personal-info"}
                    label={AdminServiceAgreement()}
                    state={agreed}
                    handleClick={(e) => setAgreed(e.target.checked)}
                    disabled={disabled}
                  />
                </div>
                <div className="mt-3">
                  {localError ? (
                    <div
                      className="invalid-feedback d-block"
                      data-testid="agreement-invalid-feedback">
                      {localError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <SalutationField
                t={t}
                prefix={prefix}
                values={values}
                options={salutationTypeOptions}
                handleChange={handleSalutationTypeChange}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row className="mt-35">
            <Col xs={"12"} md={"6"}>
              <TitleArrayField
                t={t}
                values={values}
                formik={formik}
                prefix={prefix}
                touched={touched}
                currentFlowType={currentFlowType}
                errors={errors}
                disabled={disabled}
              />
            </Col>
            <Col xs={"12"} md={"6"}>
              <FirstNameArrayField
                t={t}
                values={values}
                formik={formik}
                prefix={prefix}
                touched={touched}
                errors={errors}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col xs={"12"} md={"6"}>
              <LastNameArrayField
                t={t}
                values={values}
                formik={formik}
                prefix={prefix}
                touched={touched}
                errors={errors}
                disabled={disabled}
              />
            </Col>
            <Col xs={"12"} md={"6"}>
              <EmailArrayField
                t={t}
                values={values}
                formik={formik}
                prefix={prefix}
                touched={touched}
                errors={errors}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col xs={12} md={6}>
              <PhoneField
                t={t}
                formik={formik}
                values={values}
                prefix={prefix}
                defaultCountry={"de"}
                handleChange={handlePhoneNumberChange}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="legal-text">
              {t("trader-gmbh:privacy-notice")}{" "}
              <a
                href="https://ride.capital/datenschutz?utm_source=app&utm_medium=app"
                target="_blank"
                rel="noreferrer noopener nofollow">
                {t("trader-gmbh:privacy-notice-link")}
              </a>{" "}
              {t("trader-gmbh:privacy-notice-suffix")}
            </Col>
          </Row>
        </div>
      </div>

      <Row className="mt-3">
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              data-testid="submit"
              className="step-submit-btn"
              variant="outline-light">
              {t("generic:save")}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
