import React, { PropsWithChildren } from "react";
import { Container } from "react-bootstrap";
import ReturnLinkWithChevron from "../../../../sharedComponents/ReturnLinkWithChevron/ReturnLinkWithChevron";

interface BlankPageProps extends PropsWithChildren<{}> {
  title: string;
  onBack?: () => void;
}

export const ActionPage = ({ title, onBack, children }: BlankPageProps) => {
  return (
    <div className="action-page">
      {onBack && (
        <Container>
          <ReturnLinkWithChevron
            onClick={onBack}
            backgroundVariant="light"
            data-testid="action-page-back-button"
          />
        </Container>
      )}
      <Container>
        <h1 className="action-page--title" data-testid="action-page-title">
          {title}
        </h1>
        <div className="action-page--content" data-testid="action-page-content">
          {children}
        </div>
      </Container>
    </div>
  );
};
