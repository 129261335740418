import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalLayout,
  ModalLayoutVariant
} from "sharedComponents/templates/ModalLayout/ModalLayout";
import AddGmbHOptions from "../../../components/GmbH/AddGmbHOptions/AddGmbHOptions";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

export const GmbHAddScene = () => {
  const history = useHistory();

  const handleClose = () => {
    const shouldShowCompanyList = !FeatureFlagService.instance.isEnabled(
      FeatureFlags.ReplaceCompanyListByCompanyDropdown
    );

    if (shouldShowCompanyList) {
      history.replace(`/client/entities`);
    } else {
      history.goBack();
    }
  };

  const handleAddNew = () => {
    history.push(`/client/my-gmbhs/add/new`);
  };

  const handleAddExisting = () => {
    history.push(`/client/my-gmbhs/add/existing`);
  };

  return (
    <ModalLayout onClose={handleClose} variant={ModalLayoutVariant.dark}>
      <AddGmbHOptions onAddNew={handleAddNew} onAddExisting={handleAddExisting} />
    </ModalLayout>
  );
};
