import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { formatDate, formatValue } from "../../common/formatters";
import { getCountryNameByCode } from "../../lib/dataAdapters/countryUtils/countryUtils";
import { defaultCountry, defaultCountryCode } from "../../common/defaultCountry";
import { withTranslation, WithTranslation } from "react-i18next";
import { FullPersonDetailsQuery_getPersonDetails } from "../../components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import { TFunction } from "i18next";
import { GetAdvisedPerson_getAdvisedPerson } from "../../taxAdvisor/graphql/GetAdvisedPerson";
import { ValueView } from "../../components/common/layout/ValueView";
import { UserRole } from "../../global-query-types";
import ClientTags from "../../components/common/ClientTags/ClientTags";

interface ViewPersonDetailsProps extends WithTranslation {
  person: FullPersonDetailsQuery_getPersonDetails | GetAdvisedPerson_getAdvisedPerson | null;
}

const PersonDetails = ({ t, person }: ViewPersonDetailsProps) => {
  if (!person) {
    return <MissingPersonData t={t} />;
  }

  const {
    firstName,
    lastName,
    phoneNumber,
    salutation,
    title,
    address,
    dateOfBirth,
    birthName,
    citizenship,
    placeOfBirth,
    documentType,
    documentNumber,
    issuingAuthority,
    personalTaxId,
    taxNumber,
    taxOffice,
    locked,
    isPartner,
    user
  } = person;

  const { street, streetLine2, postalCode, city, country } = address ?? {};

  return (
    <>
      <Row>
        <Col>
          <ValueView
            name=""
            value={<ClientTags client={person} />}
            testId="client-tags-value"
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:person:locked-status"
            testId="locked-status"
            value={
              locked ? (
                <Badge variant="success">{t("nominee-dashboard:person:locked")}</Badge>
              ) : (
                <Badge variant="danger">{t("nominee-dashboard:person:unlocked")}</Badge>
              )
            }
            t={t}
          />
        </Col>
      </Row>

      {user?.role === UserRole.Notary && (
        <Row className="mt-5">
          <Col>
            <ValueView
              name="nominee-dashboard:person:partner-status"
              testId="partner-status"
              value={
                isPartner ? (
                  <Badge variant="success" data-testid="partner-status-success">
                    {t("nominee-dashboard:person:partner")}
                  </Badge>
                ) : (
                  <Badge variant="danger" data-testid="partner-status-danger">
                    {t("nominee-dashboard:person:no-partner")}
                  </Badge>
                )
              }
              t={t}
            />
          </Col>
        </Row>
      )}

      <Row className="mt-5">
        <Col>
          <ValueView
            name="nominee-dashboard:person.phone-number"
            value={formatValue(phoneNumber)}
            testId="phone-number"
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:person.salutation"
            value={formatValue(salutation)}
            testId="salutation"
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="nominee-dashboard:person.title"
            value={formatValue(title)}
            testId="title"
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:person.first-name"
            value={formatValue(firstName)}
            testId="first-name"
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="nominee-dashboard:person.last-name"
            value={formatValue(lastName)}
            testId="last-name"
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="nominee-dashboard:person.birthName"
            value={formatValue(birthName)}
            testId="birth-name"
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView name="generic:street" value={formatValue(street)} testId="street" t={t} />
        </Col>

        <Col>
          <ValueView
            name="generic:street-line-2"
            value={formatValue(streetLine2)}
            testId="streetLine2"
            t={t}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:person.city"
            value={formatValue(city)}
            testId="city"
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="generic:postal-code"
            value={formatValue(postalCode)}
            testId="postal-code"
            t={t}
          />
        </Col>

        <Col>
          <ValueView
            name="generic:country"
            value={getCountryNameByCode(country ?? defaultCountryCode) ?? defaultCountry}
            testId="country"
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:person.place-of-birth"
            value={formatValue(placeOfBirth)}
            testId="place-of-birth"
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="generic:birth-date"
            value={formatDate(dateOfBirth)}
            testId="date-of-birth"
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:person.citizenship"
            value={getCountryNameByCode(citizenship ?? defaultCountryCode) ?? defaultCountry}
            testId="citizenship"
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:person.personal-tax-id"
            value={formatValue(personalTaxId)}
            testId="personal-tax-id"
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:person.tax-number"
            value={formatValue(taxNumber)}
            testId="tax-number"
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:person.tax-office"
            value={formatValue(taxOffice)}
            testId="tax-office"
            t={t}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ValueView
            name="nominee-dashboard:person.document-type"
            value={formatValue(documentType)}
            testId="document-type"
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:person.document-number"
            value={formatValue(documentNumber)}
            testId="document-number"
            t={t}
          />
        </Col>
        <Col>
          <ValueView
            name="nominee-dashboard:person.issuing-authority"
            value={formatValue(issuingAuthority)}
            testId="issuing-authority"
            t={t}
          />
        </Col>
      </Row>
    </>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(PersonDetails);

const MissingPersonData = ({ t }: { t: TFunction }) => (
  <div className="alert alert-info" data-testid="missing-person-data-message">
    {t("generic:person.missing-details")}
  </div>
);
