import RideOverview from "sharedComponents/RideOverview/RideOverview";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { AccountantClientsContainer } from "accountant/components/AccountantClients/AccountantClientsContainer";

const TaxAdvisorClientsScene = ({ t }: WithTranslation) => {
  return (
    <RideOverview
      imageSrc={"/assets/third-party-tax-advisor/header-icon.svg"}
      title={t("tax-advisor:clients.overview.title")}
      subtitle={t("tax-advisor:clients.overview.subtitle")}>
      <AccountantClientsContainer />
    </RideOverview>
  );
};

export default withTranslationReady("tax-advisor")(TaxAdvisorClientsScene);
