import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { GetUserWhitelistedStatus } from "components/nominee/NomineeUser/GetUserInfo";
import { Badge, Col, Row, Spinner } from "react-bootstrap";
import { MdNotInterested } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { NomineeCompanyDetails_companyById_ownedWallet } from "lib/api/queries/graphql/NomineeCompanyDetails";
import { FullPersonDetailsQuery_getPersonDetails_userWallet_tanganyWallets } from "components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";

type walletTypeWithProviderId = Pick<
  | NomineeCompanyDetails_companyById_ownedWallet
  | FullPersonDetailsQuery_getPersonDetails_userWallet_tanganyWallets,
  "walletProviderId"
>;

interface GetUserAddressProps extends WithTranslation {
  wallet: walletTypeWithProviderId | null | undefined;
}

const GetUserAddress = ({ t, wallet }: GetUserAddressProps) => {
  if (!wallet || !wallet.walletProviderId) return null;

  const userWalletAddressStatus = GetUserWhitelistedStatus(wallet.walletProviderId);

  if (userWalletAddressStatus.loading) return <Spinner animation="grow" variant="primary" />;
  const walletStatus = userWalletAddressStatus.data && userWalletAddressStatus.data!.isWhitelisted!;

  if (walletStatus?.walletAddress) {
    return (
      <Row data-testid="wallet-row">
        <Col data-testid="wallet-address">
          <small>{walletStatus!.walletAddress!}</small>
          {walletStatus!.status! ? (
            <IoMdCheckmark color="green" />
          ) : (
            <MdNotInterested color="red" />
          )}{" "}
          <Badge
            onClick={() => userWalletAddressStatus.refetch()}
            variant="primary"
            style={{ cursor: "pointer" }}
            pill>
            {t("generic:reload")}
          </Badge>
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
};

export default withTranslation(["generic"])(GetUserAddress);
