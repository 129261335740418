import React, { useState } from "react";
import FolderNode from "../FolderNode/FolderNode";
import FolderNodeWithUpload from "../FolderNode/FolderNodeWithUpload";
import { StorageNodesEntityType, StorageNodeType } from "../../global-query-types";
import { FileNodeContainer } from "../File/FileNodeContainer";
import { RemoteStorageNode } from "../../lib/dataAdapters/toRemoteStorageNode";
import { FileSystemNodeList } from "./FileSystemNodeList";

export interface FileSystemNodeProps {
  node: RemoteStorageNode;
  entityType: StorageNodesEntityType;
  entityId: string;
  onDelete?: (node: RemoteStorageNode) => Promise<void>;
  level?: number;
  refetch?: () => Promise<void>;
  readOnly?: boolean;
}

const FileSystemNode = (props: FileSystemNodeProps) => {
  const [expanded, setExpanded] = useState(false);

  const onExpand = () => {
    setExpanded(!expanded);
  };

  const { level = 0 } = props;

  if (props.node.type === StorageNodeType.FOLDER) {
    return (
      <div
        className={`node-tree__folder ${level === 0 ? "ground-level" : ""}`}
        data-testid="folder">
        {props.readOnly ? (
          <>
            <FolderNode {...props} onToggleExpand={onExpand} expanded={expanded} />{" "}
            <FileSystemNodeList {...props} childNodes={props.node.childNodes} expanded={expanded} />
          </>
        ) : (
          <FolderNodeWithUpload {...props} onToggleExpand={onExpand} expanded={expanded} />
        )}
      </div>
    );
  }

  if (props.node.type === StorageNodeType.FILE) {
    return (
      <div className={`node-tree__file ${level === 0 ? "ground-level" : ""}`} data-testid="file">
        <FileNodeContainer {...props} />
      </div>
    );
  }

  return null;
};

export default FileSystemNode;
