import React from "react";
import { getPackageTypeTitle } from "../../../../../lib/formatters/config";

export const PackageInformation = ({ selectedPackageTypeDetails, currentPackageType, t }) => {
  return (
    <>
      <div className="flex-column">
        <div className="px-md-45">
          <h6>{t(getPackageTypeTitle(currentPackageType))}</h6>
          {selectedPackageTypeDetails.map((item, index) => (
            <ul className="next-steps-items d-flex pl-35" key={index}>
              <li>{t(item)}</li>
            </ul>
          ))}
        </div>
      </div>
    </>
  );
};
