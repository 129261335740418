import React from "react";
import { Image } from "react-bootstrap";
import { LinkVariant, TextLink } from "../TextLink/TextLink";

export interface LinkProp {
  label: string;
  href: string;
}

interface NoResultsPlaceholderProps {
  text: string;
  links?: LinkProp[];
  className?: "result-card";
}

const NoResultsPlaceholder = ({ text, className, links = [] }: NoResultsPlaceholderProps) => {
  return (
    <div
      className={`no-results-placeholder ${className ?? ""}`}
      data-testid="no-results-placeholder">
      <Image
        className="no-results-placeholder__image"
        src="/assets/investments/no-investments.svg"
        data-testid="no-results-placeholder-icon"
      />
      <div
        className="no-results-placeholder__description"
        data-testid="no-results-placeholder-text">
        {text}
      </div>
      {links.map(({ href, label }, index) => (
        <TextLink
          to={href}
          key={index}
          variant={LinkVariant.primary}
          data-testid="no-results-placeholder-link">
          {label}
        </TextLink>
      ))}
    </div>
  );
};

export default NoResultsPlaceholder;
