import React from "react";

export const CircleWithArrowIcon = ({
  size = 26,
  className = "",
  circleStroke = "#48FFAF",
  circleFill = "#48FFAF",
  arrowColor = "#303436"
}: {
  size?: number;
  className?: string;
  circleStroke?: string;
  circleFill?: string;
  arrowColor?: string;
}) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12.5" fill={circleFill} stroke={circleStroke} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 7L11.95 8.05L16.15 12.25H7V13.75H16.15L11.95 17.95L13 19L19 13L13 7Z"
      fill={arrowColor}
    />
  </svg>
);
