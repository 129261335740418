import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { TFunction } from "react-i18next";
import { AccordionElement } from "../CompanyCreation/Steps/AccordionElement";
import { WepaCompanyForm } from "./steps/WepaCompanyForm";
import {
  StartWepaService,
  StartWepaServiceVariables
} from "../../../lib/api/mutations/graphql/StartWepaService";
import { ExecutionResult } from "graphql";
import { GetWepaServiceById_getWepaServiceById } from "../../../lib/api/queries/graphql/GetWepaServiceById";
import { WepaDirectorForm } from "./steps/WepaDirectorForm";
import { ShareholderPerson } from "../../../global-query-types";
import { UpdateWepaDirectorDetails } from "../../../lib/api/mutations/graphql/UpdateWepaDirectorDetails";
import { AccountCreationForm } from "./steps/AccountCreationForm";

interface WepaServiceOrderFlowProps {
  t: TFunction;
  wepaService: GetWepaServiceById_getWepaServiceById | null | undefined;
  submitCompanyInfoHandler: (
    data: StartWepaServiceVariables
  ) => Promise<ExecutionResult<StartWepaService>>;
  submitWepaDirectorHandler: (
    director: ShareholderPerson
  ) => Promise<ExecutionResult<UpdateWepaDirectorDetails>>;
  completeWepaServiceOrderHandler: () => Promise<void>;
  refetchWepaService: () => Promise<void>;
  taxAdvisorCode: string | undefined;
}

export const WepaServiceOrderFlow = ({
  t,
  submitCompanyInfoHandler,
  refetchWepaService,
  submitWepaDirectorHandler,
  completeWepaServiceOrderHandler,
  wepaService,
  taxAdvisorCode
}: WepaServiceOrderFlowProps) => {
  const [isOrderStarted, setIsOrderStarted] = useState(false);
  const [currentStep, setCurrentStep] = useState("");
  const wepaServiceCurrentStep = wepaService?.currentStep;

  const handleClick = () => {
    setIsOrderStarted(true);
    setCurrentStep("COMPANY");
  };

  useEffect(() => {
    if (!wepaService?.id) return;

    setIsOrderStarted(true);
    setCurrentStep(wepaService.currentStep);
  }, [wepaService, wepaServiceCurrentStep]);

  const toggleActiveKey = () => {};

  return (
    <Container className="mb-5">
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Button
            data-testid="start-button"
            block
            onClick={handleClick}
            hidden={isOrderStarted}
            className="start-now-btn">
            {t("generic:start-now")}
          </Button>
        </Col>
      </Row>
      <Row data-testid="wepa-order-steps">
        <Col xs={12} className="d-flex pl-5">
          <Accordion activeKey={currentStep} data-testid="accordion-steps" className="mt-5">
            <AccordionElement
              headerText={t(`wepa-service:steps-title.COMPANY`)}
              buttonText={"1"}
              eventKey={"COMPANY"}
              onToggle={toggleActiveKey}
              disabled={currentStep !== "COMPANY"}>
              <WepaCompanyForm
                t={t}
                submitCompanyInfoHandler={submitCompanyInfoHandler}
                taxAdvisorCode={taxAdvisorCode}
              />
            </AccordionElement>
            <AccordionElement
              headerText={t(`wepa-service:steps-title.DIRECTOR`)}
              buttonText={"2"}
              eventKey={"DIRECTOR"}
              onToggle={toggleActiveKey}
              disabled={currentStep !== "DIRECTOR"}>
              <WepaDirectorForm
                t={t}
                person={
                  // NOTE: wepaService?.company?.contactPerson should be always defined if this point is reached
                  wepaService?.company?.contactPerson!
                }
                submitWepaDirectorHandler={submitWepaDirectorHandler}
                refetchWepaService={refetchWepaService}
              />
            </AccordionElement>
            <AccordionElement
              headerText={t(`wepa-service:steps-title.ACCOUNT_CREATION`)}
              buttonText={"3"}
              eventKey={"CREATE_ACCOUNT"}
              onToggle={toggleActiveKey}
              disabled={currentStep !== "CREATE_ACCOUNT"}>
              <AccountCreationForm
                t={t}
                completeWepaServiceOrderHandler={completeWepaServiceOrderHandler}
                wepaService={wepaService!}
              />
            </AccordionElement>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};
