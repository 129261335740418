import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";

export interface BankAccountDeleteModalProps extends WithTranslation {
  name: string | null;
  onDelete: () => Promise<void>;
  children: (setVisibility: (state: boolean) => void) => React.ReactNode;
}

const BankAccountDeleteModal = ({ name, onDelete, children, t }: BankAccountDeleteModalProps) => {
  return (
    <ConfirmationModal
      cancelLabel={t(`nominee-dashboard:company.bank-accounts-table.action-cancel`)}
      confirmLabel={t(`nominee-dashboard:company.bank-accounts-table.action-delete`)}
      title={t("nominee-dashboard:company.bank-accounts-table.delete-title")}
      dialogBody={
        <div data-testid="bank-accounts-table-account-name">
          {t(`nominee-dashboard:company.bank-accounts-table.delete-message`)}
          {name}
        </div>
      }
      onConfirm={async () => onDelete()}>
      {(setVisibility) => children(setVisibility)}
    </ConfirmationModal>
  );
};

export default withTranslationReady(["nominee-dashboard", "client"])(BankAccountDeleteModal);
