import React from "react";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { ReviewFieldList } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewFieldList/ReviewFieldList";
import { CompanyData } from "lib/models/client/CompanyData";
import { CompanyMainActivities } from "lib/models/client/AddExistingCompany/CompanyMainActivities";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

export interface ReviewCompanyInformationProps {
  companyData: Partial<CompanyData>;
  editLink: string;
  className?: string;
}

const ReviewCompanyInformation = ({
  t,
  companyData,
  editLink,
  className = ""
}: ReviewCompanyInformationProps & WithTranslation) => {
  const { companyName, registerCourt, registration, leiNumber, companyMainActivities, taxNumber } =
    companyData;

  const showCompanyActivities = FeatureFlagService.instance.isEnabled(
    FeatureFlags.CompanyMainActivitiesForAddExistingCompanyForm
  );

  const getOtherContext = (activity: string) =>
    activity === CompanyMainActivities.OTHER ? ` (${companyMainActivities?.otherContext})` : "";

  return (
    <ReviewCard className={className} dataTestId="company-information">
      <ReviewSectionTitle
        title={t("generic:ride-broker-onboarding.chapters.company-information")}
        editLink={editLink}
        isCompleted={true}
      />
      <ReviewFieldList>
        <ReviewField
          name="company-name"
          label={t("generic:add-existing-company.company-details.company-basic-info.company-name")}
          value={companyName}
        />
        <ReviewField
          name="register-court"
          label={t(
            "generic:add-existing-company.company-details.company-basic-info.register-court"
          )}
          value={registerCourt}
        />
        <ReviewField
          name="registration"
          label={t("generic:add-existing-company.company-details.company-basic-info.hrb")}
          value={registration}
        />
        <ReviewField
          name="lei-number"
          label={t("generic:add-existing-company.company-details.company-basic-info.lei")}
          value={leiNumber}
        />
        <ReviewField
          name="tax-number"
          label={t("generic:add-existing-company.company-details.company-basic-info.tax-number")}
          value={taxNumber}
        />
        {showCompanyActivities && (
          <ReviewField
            name="companyMainActivities"
            label={t(
              "generic:add-existing-company.company-details.company-main-activities.review-title"
            )}
            value={
              companyMainActivities && (
                <ul>
                  {Object.entries(companyMainActivities).map(
                    ([activity, value]) =>
                      value &&
                      activity !== "otherContext" && (
                        <li data-testid={`companyMainActivities-${activity}`} key={activity}>
                          {t(
                            `generic:add-existing-company.company-details.company-main-activities.options.${activity}`
                          ) + getOtherContext(activity)}
                        </li>
                      )
                  )}
                </ul>
              )
            }
          />
        )}
      </ReviewFieldList>
    </ReviewCard>
  );
};

export default withTranslationReady(["generic"])(ReviewCompanyInformation);
