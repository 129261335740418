import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { useRideFormik } from "lib/hooks/useRideFormik";
import EditableSectionWithFormik from "sharedComponents/EditableSectionWithFormik/EditableSectionWithFormik";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { formatValue } from "common/formatters";
import React from "react";

interface IBFirstLoginCredentialsProps {
  accountNumber?: string;
  disableEditButton: boolean;
  username?: string;
  onSave: (accountNumber, username) => Promise<void>;
}

const IBFirstLoginCredentials = ({
  t,
  accountNumber,
  disableEditButton,
  username,
  onSave
}: IBFirstLoginCredentialsProps & WithTranslation) => {
  const formik = useRideFormik({
    initialValues: {
      accountNumber: accountNumber,
      username: username
    },
    onSubmit: async () => {
      await onSave(formik.values["accountNumber"], formik.values["username"]);
    }
  });
  return (
    <EditableSectionWithFormik
      disableEditButton={disableEditButton}
      title={t("generic:low-tax-broker-order.ib-credentials.title")}
      formik={formik}
      testId="ib-credentials">
      {({ isEditMode }) => (
        <div className={"low-tax-broker-order-details__ib-credentials"}>
          <div>
            <span data-testid={"ib-credentials-account-number"}>{`${t(
              "generic:account-number"
            )}: `}</span>
            <span data-testid={"ib-credentials-account-number-value"}>
              {isEditMode ? (
                <RideInput name={"accountNumber"} formik={formik} />
              ) : (
                formatValue(formik.values["accountNumber"])
              )}
            </span>
          </div>
          <div>
            <span data-testid={"ib-credentials-username"}>{`${t("generic:username")}: `}</span>
            <span data-testid={"ib-credentials-user-value"}>
              {isEditMode ? (
                <RideInput name={"username"} formik={formik} />
              ) : (
                formatValue(formik.values["username"])
              )}
            </span>
          </div>
        </div>
      )}
    </EditableSectionWithFormik>
  );
};

export default withTranslationReady(["generic"])(IBFirstLoginCredentials);
