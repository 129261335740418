import React from "react";
import { useParams } from "react-router-dom";
import { GetMyTaxAdvisorCompanyQuery } from "../../Queries";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import TaxAdvisorClientOverviewSection from "./TaxAdvisorClientOverviewSection";
import { CompanyType } from "../../../global-query-types";
import { GetTaxAdvisorClientCompanyQuery } from "../../../lib/api/queries/GetTaxAdvisorClientCompanyQuery";

const TaxAdvisorClientOverviewSectionContainer = () => {
  const { companyId, ref } = useParams<{ companyId: string; ref?: string }>();
  const getClientCompanyRequest = GetTaxAdvisorClientCompanyQuery(companyId);
  const getTaxAdvisorCompanyRequest = GetMyTaxAdvisorCompanyQuery(CompanyType.TaxService);

  return (
    <RequestFeedbackHandler requests={[getClientCompanyRequest, getTaxAdvisorCompanyRequest]}>
      <TaxAdvisorClientOverviewSection
        routeRef={ref}
        company={getClientCompanyRequest.data?.getTaxAdvisorClientCompany}
        myTaxAdvisorCompany={getTaxAdvisorCompanyRequest.data?.getMyTaxAdvisorCompanyWithClients}
      />
    </RequestFeedbackHandler>
  );
};

export default TaxAdvisorClientOverviewSectionContainer;
