import React from "react";
import OrderCompanyFounding from "client/components/OrderCompanyFounding/OrderCompanyFounding";
import { GetOrder } from "lib/api/queries/GetOrder";
import { OrderOwnerType } from "global-query-types";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { UPSERT_ORDER } from "../../../lib/api/mutations/UPSERT_ORDER";
import { UpsertOrder, UpsertOrderVariables } from "../../../lib/api/mutations/graphql/UpsertOrder";
import {
  FinalizeOrder,
  FinalizeOrderVariables
} from "../../../lib/api/mutations/graphql/FinalizeOrder";
import { FINALIZE_ORDER } from "../../../lib/api/mutations/FINALIZE_ORDER";
import {
  VerifyDiscountCode,
  VerifyDiscountCodeVariables
} from "lib/api/mutations/graphql/VerifyDiscountCode";
import { VERIFY_DISCOUNT_CODE } from "../../../lib/api/mutations/VERIFY_DISCOUNT_CODE";

const OrderCompanyFoundingContainer = () => {
  const { orderId, submissionId } = useParams<{ orderId?: string; submissionId?: string }>();

  const myOrderRequest = GetOrder(orderId, submissionId, OrderOwnerType.Guest);
  const myOrder = myOrderRequest.data?.getOrder;

  const [callUpsertOrder, upsertOrderRequest] = useMutation<UpsertOrder, UpsertOrderVariables>(
    UPSERT_ORDER
  );

  const [callFinalizeOrder, finalizeOrderRequest] = useMutation<
    FinalizeOrder,
    FinalizeOrderVariables
  >(FINALIZE_ORDER);

  const [callVerifyDiscountCode] = useMutation<VerifyDiscountCode, VerifyDiscountCodeVariables>(
    VERIFY_DISCOUNT_CODE
  );

  const upsertOrder = async (variables: UpsertOrderVariables) => {
    const result = await callUpsertOrder({ variables });
    return result.data?.upsertOrder;
  };

  const finalizeOrder = async (variables: FinalizeOrderVariables) => {
    const result = await callFinalizeOrder({ variables });
    return result.data?.finalizeOrder;
  };

  const verifyDiscountCode = async (
    discountCode: string
  ): Promise<{ isValid: boolean; discountValue: number }> => {
    const request = await callVerifyDiscountCode({ variables: { discountCode } });
    return request.data?.verifyDiscountCode ?? { isValid: false, discountValue: 0 };
  };

  return (
    <RequestFeedbackHandler requests={[upsertOrderRequest, finalizeOrderRequest, myOrderRequest]}>
      <OrderCompanyFounding
        firstName={myOrder?.extra?.firstName ?? ""}
        lastName={myOrder?.extra?.lastName ?? ""}
        email={myOrder?.extra?.email ?? ""}
        phoneNumber={myOrder?.extra?.phoneNumber ?? ""}
        discountCode={myOrder?.extra?.discountCode ?? ""}
        upsertOrder={upsertOrder}
        finalizeOrder={finalizeOrder}
        verifyDiscountCode={verifyDiscountCode}
      />
    </RequestFeedbackHandler>
  );
};

export default OrderCompanyFoundingContainer;
