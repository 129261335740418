import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideRadioSelect } from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import * as Yup from "yup";
import { requiredString } from "../../../../../../../lib/validation/requiredString";

const DeclareTaxInTheUSStep = ({
  order,
  saveData,
  controls,
  currentStep,
  t
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const stepsToBeRemoved = [
    "broker-company-choice",
    "declare-tax-in-the-us",
    "consent-to-handle-personal-data",
    "consent-to-trading-on-own-account",
    "security-questions",
    "broker-setup-complete"
  ];

  const initialValues = {
    declareTaxInTheUS: order.extra.declareTaxInTheUS
  };

  async function onSubmit(values) {
    currentStep.complete();

    if (values.declareTaxInTheUS === "yes") {
      currentStep.insertAfter({ key: "broker-setup-failed" });

      stepsToBeRemoved.forEach((step) => {
        const nextStep = currentStep.chapter.getStepByKey(step);
        nextStep?.remove();
      });
    }

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize(),
      declareTaxInTheUS: values.declareTaxInTheUS
    });
  }

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      declareTaxInTheUS: requiredString(t)
    })
  });
  const module = "declare-tax-in-the-us";

  return (
    <FormikProvider value={formik}>
      <Form className={module}>
        <div data-testid={module}>
          <USTaxDeclaration />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

const USTaxDeclaration = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  let options = [
    { value: "yes", label: t("generic:yes") },
    { value: "no", label: t("generic:no") }
  ];

  return (
    <div data-testid="declare-tax-in-the-us-step" className="fxflat-declare-tax-in-the-us-step">
      <StepHeading text={t("generic:ride-broker-onboarding.declare-tax-in-the-us.title")} />
      <RideRadioSelect options={options} name="declareTaxInTheUS" />
    </div>
  );
});
export default withTranslationReady("generic")(DeclareTaxInTheUSStep);
