import { RideLink } from "../../../sharedComponents/RideLink/RideLink";
import React from "react";

export interface AdminBreadcrumbLink {
  url?: string;
  title: string;
  className?: string;
}

export interface AdminBreadcrumbProps {
  title?: string;
  links: AdminBreadcrumbLink[];
  separator?: string;
}

const renderLink = (link: AdminBreadcrumbLink, uniqueKey: number, separator: string = "/") => {
  return link.url ? (
    <span key={uniqueKey}>
      {uniqueKey > 0 ? <>&nbsp;{separator}&nbsp;</> : null}
      <RideLink
        data-testid="ride-admin-breadcrumb-link"
        to={link.url}
        className={link.className}
        key={uniqueKey}>
        {link.title}
      </RideLink>
    </span>
  ) : (
    <span key={uniqueKey} data-testid="ride-admin-breadcrumb-link" className={link.className}>
      {uniqueKey > 0 ? <>&nbsp;{separator}&nbsp;</> : null}
      {link.title}
    </span>
  );
};

export const AdminBreadcrumb = (props: AdminBreadcrumbProps) => {
  return (
    <section
      data-testid="ride-admin-breadcrumb"
      className={props.title ? `ride-admin-breadcrumb-title` : `ride-admin-breadcrumb`}>
      {props.title ? <h2 data-testid="ride-admin-breadcrumb-title">{props.title}</h2> : null}
      {props.links.map((link, index) => renderLink(link, index, props.separator))}
    </section>
  );
};
