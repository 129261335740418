import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import {
  BadgeColor,
  ReviewTable
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewTable/ReviewTable";
import { formatCurrency } from "common/formatters";
import { WarningWithTooltip } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ReviewAndSubmitLowTaxBroker/ReviewAndSubmitLowTaxBroker.partials";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { FeatureFlags } from "global-query-types";
import { ReviewFieldList } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewFieldList/ReviewFieldList";

type BrokerComplianceData = Pick<
  LowTaxBrokerOrderData,
  | "intendedInitialInvestment"
  | "intendedInitialInvestmentContext"
  | "investedLivelihoodCapitalDisclaimerAccepted"
  | "assetExperiences"
  | "sourcesOfWealth"
  | "currentLiquidNetWorth"
  | "estimatedAnnualIncome"
  | "estimatedTotalAssets"
  | "investmentObjectives"
>;

export interface ReviewBrokerComplianceProps {
  complianceData: BrokerComplianceData;
  editLink?: string;
  isCompleted?: boolean;
  isNewBrokerEnabled: boolean;
}

export const ReviewBrokerCompliance = withTranslationReady(["generic"])(
  ({
    t,
    complianceData,
    editLink,
    isCompleted,
    isNewBrokerEnabled
  }: ReviewBrokerComplianceProps & WithTranslation) => {
    const {
      intendedInitialInvestment,
      investedLivelihoodCapitalDisclaimerAccepted,
      intendedInitialInvestmentContext,
      assetExperiences,
      currentLiquidNetWorth,
      estimatedAnnualIncome,
      estimatedTotalAssets,
      sourcesOfWealth
    } = complianceData;

    const assetExperienceList = (assetExperiences && Object.entries(assetExperiences)) ?? [];

    const sourcesOfWealthList = (sourcesOfWealth && Object.entries(sourcesOfWealth)) ?? [];

    return (
      <ReviewCard>
        <ReviewSectionTitle
          dataTestId="broker-compliance-title"
          title={t("generic:review-entry.low-tax-broker.broker-compliance")}
          editLink={editLink}
          isCompleted={isCompleted}
        />
        <ReviewFieldList>
          <DisabledFeatureFlag name={FeatureFlags.removeIntendedInitialInvestmentStep}>
            <ReviewField
              name="intendedInitialInvestment"
              label={t("generic:review-entry.low-tax-broker.intendedInitialInvestment")}
              value={intendedInitialInvestment && formatCurrency(intendedInitialInvestment)}
            />
            {intendedInitialInvestmentContext && (
              <ReviewField
                name="intendedInitialInvestmentContext"
                label={t("generic:review-entry.low-tax-broker.intendedInitialInvestmentContext")}
                value={intendedInitialInvestmentContext}
              />
            )}
          </DisabledFeatureFlag>
          {!isNewBrokerEnabled && (
            <ReviewField
              name="investedCapitalTypeRiskAcknowledged"
              label={t("generic:review-entry.low-tax-broker.investedCapitalTypeRiskAcknowledged")}
              value={
                investedLivelihoodCapitalDisclaimerAccepted === undefined
                  ? undefined
                  : investedLivelihoodCapitalDisclaimerAccepted
                  ? t("generic:review-entry.low-tax-broker.agreed")
                  : t("generic:review-entry.low-tax-broker.not-agreed")
              }
            />
          )}
          {isNewBrokerEnabled && (
            <ReviewField
              name="investmentObjectives"
              label={t("generic:review-entry.low-tax-broker.investment-objectives")}
              value={
                complianceData.investmentObjectives && (
                  <ul>
                    {Object.entries(complianceData.investmentObjectives).map(
                      ([objective, value]) =>
                        value && (
                          <li data-testid={`investmentObjective-${objective}`} key={objective}>
                            {t(`generic:investment-objectives.${objective}`)}
                          </li>
                        )
                    )}
                  </ul>
                )
              }
            />
          )}
          <ReviewTable
            columns={[
              t("generic:review-entry.low-tax-broker.investment-areas"),
              t("generic:review-entry.low-tax-broker.years-trading"),
              t("generic:review-entry.low-tax-broker.trades-per-year"),
              t("generic:review-entry.low-tax-broker.experience")
            ]}
            dataTestId="investmentAreas">
            {assetExperienceList.length === 0 && (
              <ReviewTable.Entry key="no-investment-chosen">
                <ReviewTable.Text content={undefined} />
              </ReviewTable.Entry>
            )}
            {assetExperienceList.map(([asset, experience]) => (
              <ReviewTable.Entry key={asset}>
                <ReviewTable.Text content={t(`generic:review-entry.low-tax-broker.${asset}`)} />
                {experience.yearsTrading !== undefined ? (
                  <ReviewTable.Badge
                    dataTestId="yearsTrading"
                    content={experience.yearsTrading.toString()}
                  />
                ) : (
                  <WarningWithTooltip
                    chapterKey={"compliance"}
                    dataTestId={`warning-with-tooltip_${asset}-yearsTrading`}
                  />
                )}
                {experience.tradesLastThreeYears !== undefined ? (
                  <ReviewTable.Badge
                    dataTestId="tradesLastThreeYears"
                    content={experience.tradesLastThreeYears.toString()}
                  />
                ) : (
                  <WarningWithTooltip
                    chapterKey={"compliance"}
                    dataTestId={`warning-with-tooltip_${asset}-tradesLastThreeYears`}
                  />
                )}
                {experience.knowledgeLevel ? (
                  <ReviewTable.Badge
                    content={t(`generic:review-entry.low-tax-broker.${experience.knowledgeLevel}`)}
                    color={BadgeColor.Purple}
                    dataTestId="knowledgeLevel"
                  />
                ) : (
                  <WarningWithTooltip
                    chapterKey={"compliance"}
                    dataTestId={`warning-with-tooltip_${asset}-knowledgeLevel`}
                  />
                )}
              </ReviewTable.Entry>
            ))}
          </ReviewTable>
          <ReviewField
            name="currentLiquidNetWorth"
            label={t("generic:review-entry.low-tax-broker.currentLiquidNetWorth")}
            value={currentLiquidNetWorth && formatCurrency(currentLiquidNetWorth)}
          />
          <ReviewField
            label={t("generic:review-entry.low-tax-broker.estimatedAnnualIncome")}
            value={estimatedAnnualIncome && formatCurrency(estimatedAnnualIncome)}
          />
          <ReviewField
            label={t("generic:review-entry.low-tax-broker.estimatedTotalAssets")}
            value={estimatedTotalAssets && formatCurrency(estimatedTotalAssets)}
          />
          <ReviewTable
            columns={[
              t("generic:review-entry.low-tax-broker.source-of-wealth"),
              t("generic:review-entry.low-tax-broker.percentage-of-total-wealth")
            ]}
            dataTestId="sourcesOfWealth">
            {sourcesOfWealthList.length === 0 && (
              <ReviewTable.Entry key="no-source-of-wealth-chosen">
                <ReviewTable.Text content={undefined} />
              </ReviewTable.Entry>
            )}
            {sourcesOfWealthList.map(([sourceOfWealth, { spread }]) => (
              <ReviewTable.Entry key={sourceOfWealth}>
                <ReviewTable.Text
                  content={t(`generic:review-entry.low-tax-broker.${sourceOfWealth}`)}
                />
                <ReviewTable.Badge content={`${spread}%`} color={BadgeColor.Green} />
              </ReviewTable.Entry>
            ))}
          </ReviewTable>
        </ReviewFieldList>
      </ReviewCard>
    );
  }
);
