import React from "react";
import { GetEvents_getEvents } from "../../../lib/api/queries/graphql/GetEvents";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { formatDateTime } from "../../../common/formatters";
import _ from "lodash";
import { formatPersonName } from "../../../common/formatters/CompanyNameFormatter";

interface AuditLogTableProps {
  events: GetEvents_getEvents[];
  t: TFunction;
}

const AuditLogTable = ({ events, t }: AuditLogTableProps) => {
  const columns = [
    { name: "createdAt", label: t("nominee-dashboard:audit-log.createdAt") },
    { name: "name", label: t("nominee-dashboard:audit-log.name") },
    { name: "person", label: t("nominee-dashboard:audit-log.person") },
    { name: "extra", label: t("nominee-dashboard:audit-log.extra") }
  ];
  const orderedEvents = _.sortBy(events, ["createdAt"]).reverse();

  return (
    <>
      <RideTableHeader columns={columns} />

      {orderedEvents.map((event) => (
        <RideTableRow colCount={columns.length} key={event.id}>
          <RideTableCell
            variant={RideTableCellVariant.text}
            dataTestId="createdAt"
            value={formatDateTime(event.createdAt)}
          />
          <RideTableCell variant={RideTableCellVariant.text} dataTestId="name" value={event.name} />
          <RideTableCell
            variant={RideTableCellVariant.primaryLink}
            dataTestId="person"
            value={formatPersonName(t, event.actor?.person ?? {})}
            link={`/nominee-persons/${event.actor?.person?.id}`}
          />
          <RideTableCell
            variant={RideTableCellVariant.text}
            dataTestId="extra"
            value={Object.entries(event.extra ?? {}).map(([key, value]) => (
              <div key={key}>
                {key} = {JSON.stringify(value)}
              </div>
            ))}
          />
        </RideTableRow>
      ))}
    </>
  );
};

export default withTranslation("nominee-dashboard")(AuditLogTable);
