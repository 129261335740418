import React from "react";
import { GetAllWepaServices } from "../../../lib/api/queries/getAllWepaServices";
import WepaList from "./WepaList";

export const WepaListContainer = () => {
  const getTaxServiceCompanies = GetAllWepaServices();

  return (
    <WepaList
      wepaServices={getTaxServiceCompanies.data?.getAllWepaServices}
      refetch={getTaxServiceCompanies.refetch}
    />
  );
};
