import React from "react";
import LoginForm from "./LoginForm";
import { useMutation } from "react-apollo";
import { LOGIN } from "lib/api/mutations/LOGIN";
import { Login, LoginVariables } from "lib/api/mutations/graphql/Login";
import { SendSmsForTwoFactorAuth } from "lib/api/mutations/graphql/SendSmsForTwoFactorAuth";
import { SEND_SMS_FOR_TWO_FACTOR_AUTH } from "lib/api/mutations/SEND_SMS_FOR_TWO_FACTOR_AUTH";
import localStore from "../../../common/LocalStore";

export const LoginFormContainer = ({ redirectUrl }: { redirectUrl?: string }) => {
  const [loginMutation, loginRequest] = useMutation<Login, LoginVariables>(LOGIN);

  const [sendSmsForTwoFactorAuthMutation] = useMutation<SendSmsForTwoFactorAuth>(
    SEND_SMS_FOR_TWO_FACTOR_AUTH
  );

  const handleLogin = async (email: string, password: string) => {
    const result = await loginMutation({
      variables: {
        email,
        password
      }
    });

    return result.data?.login;
  };

  const handleSendSmsForTwoFactorAuth = async () => {
    const result = await sendSmsForTwoFactorAuthMutation();

    if (result.data?.sendSmsForTwoFactorAuth?.nextSmsAvailableAt) {
      localStore.setExpiringValue(
        "nextSmsAvailableAt",
        result.data.sendSmsForTwoFactorAuth.nextSmsAvailableAt,
        60
      );
    }
  };

  return (
    <LoginForm
      onSubmit={handleLogin}
      requests={[loginRequest]}
      sendVerificationCode={handleSendSmsForTwoFactorAuth}
      redirectUrl={redirectUrl}
    />
  );
};
