import * as Yup from "yup";
import { requiredString } from "./requiredString";

export const AddressSchema = (t) => {
  return Yup.object().shape({
    country: Yup.string().required(t("generic:validation-required")),
    streetLine1: Yup.string().required(t("generic:validation-required")),
    city: Yup.string().required(t("generic:validation-required")),
    postalCode: Yup.string().required(t("generic:validation-required"))
  });
};

export const LowTaxBrokerAddressSchema = (t) => {
  return Yup.object({
    stateCode: requiredString(t),
    city: requiredString(t),
    streetName: requiredString(t),
    buildingNumber: requiredString(t),
    postalCode: requiredString(t)
  });
};

export const RideBrokerAddressSchema = (t) => {
  return Yup.object().shape({
    country: Yup.string().required(t("generic:validation-required")),
    streetLine1: Yup.string().required(t("generic:validation-required")),
    city: Yup.string().required(t("generic:validation-required")),
    postalCode: Yup.string().required(t("generic:validation-required")),
    stateCode: requiredString(t)
  });
};
