export enum InvestmentObjectiveType {
  PRESERVATION_OF_CAPITAL = "preservation-of-capital",
  INCOME = "income",
  GROWTH = "growth",
  TRADING_PROFITS = "trading-profits",
  SPECULATION = "speculation",
  HEDGING = "hedging"
}

export type InvestmentObjectives = Partial<Record<InvestmentObjectiveType, boolean>>;
