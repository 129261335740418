import { Trans, WithTranslation } from "react-i18next";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";

const moduleName = "trustpilot-info";

const TrustpilotInfo = ({ t }: WithTranslation) => {
  return (
    <div className={moduleName}>
      <img src="/assets/trustpilot-rating.svg" alt="" />
      <span className={`${moduleName}__review-count`}>
        <Trans i18nKey="generic:book-a-call-card.trustpilot-reviews" values={{ reviewCount: 92 }} />
      </span>
      <img src="/assets/trustpilot-logo.svg" alt="" />
    </div>
  );
};

export default withTranslationReady(["generic"])(TrustpilotInfo);
