import { TFunction } from "i18next";
import { RideLogoMinimalist } from "../icons/RideLogoMinimalist";
import React from "react";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";

const RideClubLabel = ({ t }: { t: TFunction }) => {
  return (
    <div data-testid="card-ride-club" className="ride-club-label">
      <RideLogoMinimalist />
      {t("generic:clubDealLogoText")}
    </div>
  );
};

export default withTranslationReady(["generic"])(RideClubLabel);
