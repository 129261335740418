import gql from "graphql-tag";

export const CONFIRM_EMAIL_UPDATE = gql`
  mutation ConfirmEmailUpdate($changedEmailToken: String!, $password: String!) {
    confirmEmailUpdate(changedEmailToken: $changedEmailToken, password: $password) {
      feedback
      token
    }
  }
`;
