import * as Yup from "yup";
import { WalletAddressSchema } from "./WalletAddressSchema";

export const ApproveTokenSchema = () =>
  Yup.object({
    tokenizedSPV: Yup.string().required("generic:validation-required"),
    walletProviderId: Yup.string().required("generic:validation-required"),
    targetWallet: WalletAddressSchema(),
    allowedAmount: Yup.number()
      .required("generic:validation-required")
      .positive("generic:must-be-positive-number")
  });
