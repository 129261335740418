import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ActionableTask, ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import { BankType, FeatureFlags, TaskStatesEnum } from "global-query-types";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import TaskContainer from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainer";
import BankSelectionOptions, { BankOption } from "../BankSelectionOptions/BankSelectionOptions";
import BankSelectCard from "client/components/Company/tasks/BankSelectCard/BankSelectCard";
import { FeatureFlagService } from "../../../../../lib/services/FeatureFlagService/FeatureFlagService";

interface BankSelectionTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (
    state: TaskStatesEnum,
    options?: ActionableTaskOptions,
    nextTaskToRenderRef?: string
  ) => Promise<void>;
  shouldEnableSwan: boolean;
}

export const getClientBankOptions = () => {
  const canShowFinomOption = FeatureFlagService.instance.isEnabled(
    FeatureFlags.GROOfferFinomBankAccount
  );

  if (canShowFinomOption) {
    return [
      { name: BankType.Finom, isSelected: true, isPartnerBank: false },
      { name: BankType.Fyrst, isSelected: false, isPartnerBank: false },
      { name: BankType.Own, isSelected: false, isPartnerBank: false }
    ];
  } else {
    return [
      { name: BankType.Penta, isSelected: true, isPartnerBank: false },
      { name: BankType.Fyrst, isSelected: false, isPartnerBank: false },
      { name: BankType.Swan, isSelected: false, isPartnerBank: false },
      { name: BankType.Own, isSelected: false, isPartnerBank: false }
    ];
  }
};

const BankSelectionTask = ({ task, onChange, t, shouldEnableSwan }: BankSelectionTaskProps) => {
  const bankOptionsList = getClientBankOptions();

  const [banks, setBanks] = useState<BankOption[]>(bankOptionsList);

  const storedSelectedBank: BankOption | undefined = banks.find(
    (option) => option.name === task.options?.data.value
  );
  const selectedBankOption: BankOption | undefined = banks.find((option) => option.isSelected);

  const onSelect = (bankName: string) => {
    const _bankOptions = [...banks];
    _bankOptions.forEach((bankOption, index) => {
      _bankOptions[index] = { ...bankOption, isSelected: bankOption.name === bankName };
    });
    setBanks(_bankOptions);
  };

  const handleChange = async (state) => {
    const bankOptionName: BankType | string =
      selectedBankOption?.name && task.state === TaskStatesEnum.TO_BE_DONE
        ? selectedBankOption.name
        : "";

    const nextTaskToRenderRef: string | undefined =
      bankOptionName !== "" ? `openBankAccount${bankOptionName}` : undefined;

    await onChange(
      state,
      {
        type: "BankType",
        data: {
          value: bankOptionName
        }
      },
      nextTaskToRenderRef
    );
  };

  return (
    <TaskContainer
      testid="bank-selection"
      translationKey={task.ref}
      task={task}
      onChange={handleChange}>
      {task.state === TaskStatesEnum.COMPLETED ? (
        <>
          <div data-testid="selected-bank" />
          <BankSelectCard
            name={t(`client:banking.banks.${storedSelectedBank?.name}`)}
            iconPath={`/assets/client/banks/${storedSelectedBank?.name}.svg`}
            setupTimeEstimate={t(`client:banking.setupTime.${storedSelectedBank?.name}`)}
            costEstimate={t(`client:banking.cost.${storedSelectedBank?.name}`)}
            infoLink={t(`client:banking.infoLink.${storedSelectedBank?.name}`)}
            isSelectable={false}
            isPartnerBank={storedSelectedBank?.isPartnerBank}
          />
        </>
      ) : (
        <BankSelectionOptions banks={banks} onSelect={onSelect} />
      )}
    </TaskContainer>
  );
};

export default withTranslationReady(["company-founding-steps", "client"])(BankSelectionTask);
