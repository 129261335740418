import { CompanyCreationStep, FlowTypeEnum } from "../../../../../global-query-types";
import { NomineeCompanyDetails_companyById } from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";

const companyCreationStep: (CompanyCreationStep | null | undefined)[] = [
  CompanyCreationStep.EMAIL_CONFIRMED,
  CompanyCreationStep.ORDERED
];

export const companyCreationPostOrderedSteps: (CompanyCreationStep | null | undefined)[] =
  companyCreationStep;

export const assignActivateAdminService = (company: NomineeCompanyDetails_companyById): boolean => {
  const oldAS =
    companyCreationStep.includes(company.adminService?.currentStep) ||
    company.adminService?.flowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING;
  // TODO: This is okay for now because we don't have the onboarding steps for AS done YET. BUUUUT, let's fix it ASAP when we do (We'll probably have this in november/2023).
  const newASWithTiers =
    company.adminService?.tier !== null && company.adminService?.tier !== undefined;
  return company.adminService !== null && (oldAS || newASWithTiers);
};

export const isAdminService = (company: NomineeCompanyDetails_companyById) =>
  company.adminService && !company.companyCreation;

export const isCompanyCreationPostOrdered = (company: NomineeCompanyDetails_companyById) =>
  companyCreationPostOrderedSteps.includes(company.companyCreation?.currentStep);

export const isAdminServiceFolderStructure = (company: NomineeCompanyDetails_companyById) =>
  !!JSON.parse(company.externalStorageNodes ?? "{}")._adminServiceStructure;

export const isAdminServiceActivated = (company: NomineeCompanyDetails_companyById) =>
  company.myTaxService?.taxAdvisorStatus === "ACTIVE" && isAdminServiceFolderStructure(company);

export const isAdminServiceComplete = (company: NomineeCompanyDetails_companyById) =>
  assignActivateAdminService(company);

export const canAssignTaxService = (company: NomineeCompanyDetails_companyById) =>
  assignActivateAdminService(company);

export const canAddAdminServiceAddon = (company: NomineeCompanyDetails_companyById) =>
  !isAdminServiceComplete(company) && isCompanyCreationPostOrdered(company);
