import React from "react";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { BlankPage, WidthVariantEnum } from "../templates/BlankPage/BlankPage";
import { CardContainer } from "../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { Col } from "react-bootstrap";
import { InboxIcon } from "sharedComponents/icons/InboxIcon";

interface OrderLeiConfirmationProps extends WithTranslation {}

const OrderLeiConfirmation = ({ t }: OrderLeiConfirmationProps) => {
  return (
    <BlankPage variant={WidthVariantEnum.NARROW}>
      <div className="order-confirmation" data-testid={`order-confirmation`}>
        <div className="order-confirmation__title-image">
          <img
            data-testid={"order-confirmation-title-image"}
            src="/assets/sharedComponents/new-email-icon.svg"
            alt="icon"
          />
        </div>
        <div className="order-confirmation__title" data-testid="order-confirmation-title">
          {t("generic:order-confirmation.title")}
        </div>
        <div className="order-confirmation__subtitle" data-testid="order-confirmation-subtitle">
          {t("generic:order-confirmation.sub-title")}
        </div>
        <NextSteps t={t} />
      </div>
    </BlankPage>
  );
};

const NextSteps = ({ t }: { t: TFunction }) => (
  <CardContainer className="order-confirmation__summary">
    <div className="order-confirmation__summary-header">
      <h3>{t("generic:order-confirmation.what-is-next")}</h3>
    </div>
    <div className="order-confirmation__summary-confirm-email">
      <InboxIcon className="order-confirmation__summary-confirm-email-icon" />
      <Col className="order-confirmation__summary-confirm-email-text">
        <h6>{t("generic:order-confirmation.confirm-email-title")}</h6>
        {t("generic:order-confirmation.confirm-email-subtitle")}
      </Col>
    </div>
    <div className="order-confirmation__summary-track-status">
      <div className="order-confirmation__summary-track-status-icon" />
      <Col className="order-confirmation__summary-track-status-text">
        <h6>{t("generic:order-confirmation.track-status-title")}</h6>
        {t("generic:order-confirmation.track-status-subtitle")}
      </Col>
    </div>
    <div className="order-confirmation__summary-status-complete">
      <div className="order-confirmation__summary-status-complete-icon" />
      <Col className="order-confirmation__summary-status-complete-text">
        <h6>{t("generic:order-confirmation.status-complete-title")}</h6>
        {t("generic:order-confirmation.status-complete-subtitle")}
      </Col>
    </div>
  </CardContainer>
);

export default withTranslationReady(["generic"])(OrderLeiConfirmation);
