import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import CompanyTextFieldEditContainer from "../../../client/components/Company/CompanyTextFieldEdit/CompanyTextFieldEditContainer";

interface CompanyClientNumberSceneProps extends WithTranslation {}

const CompanyClientNumberScene = ({ t }: CompanyClientNumberSceneProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const goBackUrl = pathname.replace("/client-number", "");
  const goBack = () => push(goBackUrl);

  return (
    <ModalFormLayout title={t("client:company.edit.clientNumber")} onBack={goBack}>
      <CompanyTextFieldEditContainer subtitle fieldName={"clientNumber"} section="client-number" />
    </ModalFormLayout>
  );
};

export default withTranslation(["generic", "client"])(CompanyClientNumberScene);
