import React from "react";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { PageMetaData } from "../../../sharedComponents/PageMetaData/PageMetaData";
import RideOverview from "../../../sharedComponents/RideOverview/RideOverview";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { BankAccountsListContainer } from "../../components/BankAccountsList/BankAccountListContainer";
import { useHistory } from "react-router-dom";
import { FeatureFlags } from "global-query-types";

const BankAccountListScene = ({ t }: WithTranslation) => {
  const history = useHistory();

  const handleAddBankAccountModalOpen = () => {
    history.push("/client/banking/add");
  };

  return (
    <FeatureFlag name={FeatureFlags.BankAccountsList}>
      <PageMetaData title={t("client:banking.page-title")} />
      <RideOverview
        title={t("client:banking.header.title")}
        subtitle={t("client:banking.header.subtitle")}
        imageSrc={"/assets/banking/header-icon.svg"}
        onAdd={handleAddBankAccountModalOpen}
        buttonLabel={t("client:banking.addBankAccount.title")}>
        <BankAccountsListContainer />
      </RideOverview>
    </FeatureFlag>
  );
};

export default withTranslationReady("client")(BankAccountListScene);
