import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  AddressCityField,
  AddressPostalCodeField,
  AddressStreetField
} from "../../../../components/generic/commonFields";
import {
  CompanyRegistrationField,
  LegalFormField,
  RegistryCourtField
} from "../../../../components/generic/company-form/fields";

import { holdingCompanyLegalForms } from "../../../../common/staticData/LegalForms";
import { OptionItem } from "../../../../components/generic/Select";
import { CompanyNameField } from "../../../../components/public/CompanyCreation/fields";

export const AddExistingGmbHForm = ({ t, formik }) => {
  const legalFormOptions: OptionItem[] = holdingCompanyLegalForms.map((legalForm) => ({
    title: t(`generic:legalForm.${legalForm}`),
    value: legalForm
  }));

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <CompanyNameField t={t} formik={formik} name="name" label={t("generic:company-name")} />
        </Col>
        <Col xs={12} md={6}>
          <LegalFormField
            t={t}
            formik={formik}
            legalForms={legalFormOptions}
            className={"valid-text-input"}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12} md={6}>
          <RegistryCourtField t={t} formik={formik} placeholder="" />
        </Col>
        <Col xs={12} md={6} className="pt-4 pt-md-0">
          <CompanyRegistrationField t={t} formik={formik} className={"text-input"} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12} md={6}>
          <AddressStreetField t={t} formik={formik} className={"text-input"} />
        </Col>
        <Col>
          <Row>
            <Col>
              <AddressPostalCodeField t={t} formik={formik} className={"text-input"} />
            </Col>
            <Col>
              <AddressCityField t={t} formik={formik} className={"text-input"} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
