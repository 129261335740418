import {
  ClientSelect,
  ClientSelectOption
} from "../../client/components/ClientSelect/ClientSelect";
import React from "react";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { FormikProps } from "formik";
import moment from "moment";

const startOfFiscalYearQuarters = ["01.04", "01.07", "01.10"];

export const startOfFiscalYearQuartersOptionsAsISO: ClientSelectOption[] =
  startOfFiscalYearQuarters.map((label) => ({
    label,
    value: moment(label, "DD.MM").toISOString()
  }));

const startOfFiscalYearQuartersOptions: ClientSelectOption[] = startOfFiscalYearQuarters.map(
  (label) => ({
    label,
    value: label
  })
);

interface FiscalYearQuarterSelectProps extends WithTranslation {
  formik: FormikProps<any>;
  name?: string;
  changeHandler?: (value: string) => void;
}

const FiscalYearQuarterSelect = ({
  name = "fiscalYearQuarter",
  t,
  changeHandler
}: FiscalYearQuarterSelectProps) => (
  <ClientSelect
    name={name}
    options={startOfFiscalYearQuartersOptions}
    label={t("generic:from")}
    testIdPrefix="fiscal-year-quarter-select"
    changeHandler={changeHandler}
  />
);

export default withTranslationReady(["generic"])(FiscalYearQuarterSelect);
