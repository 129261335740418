import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { RideThemes } from "../../components/common/layout/RideThemes";
import { RideSidebar } from "../RideSidebar/RideSidebar";
import { RideHamburger } from "../../components/common/layout/RideHamburger";
import NavigationService from "../../lib/services/NavigationService/NavigationService";
import { RideLogoBlack } from "../RideLogo/RideLogo";

interface RideNavbarProps extends PropsWithChildren<{}> {
  logo?: ReactElement;
  sidebar?: ReactElement<RideSidebar>;
  collapse?: boolean;
  theme?: RideThemes;
  onSelect?: () => void;
  activeKey?: string | number;
}

export const RideNavbar = ({
  children,
  logo,
  sidebar,
  collapse = true,
  theme = RideThemes.DARK,
  onSelect = () => {},
  activeKey
}: RideNavbarProps) => {
  const navigation = NavigationService.instance!;

  const [sidebarVisibility, setSidebarVisibility] = useState(!collapse);
  const [isSmallView, setIsSmallView] = useState(window.innerWidth < 768);
  const [activePage, setActivePage] = useState(activeKey ?? navigation.activePage);

  const toggleSidebar = () => setSidebarVisibility(!sidebarVisibility);
  const evalWindowWidth = () => setIsSmallView(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener("resize", evalWindowWidth);
    return () => window.removeEventListener("resize", evalWindowWidth);
  }, []);

  useEffect(() => {
    if (activePage !== navigation.activePage) {
      setSidebarVisibility(false);
    }
    setActivePage(navigation.activePage);
  }, [navigation.activePage, activePage]);

  if (!logo) {
    logo = <RideLogoBlack />;
  }
  const module = "ride-navbar";

  return (
    <>
      {isSmallView && (
        <div
          className={`ride-sidebar__wrapper ${
            sidebarVisibility ? "" : "ride-sidebar__wrapper--slideout"
          }`}
          data-testid="sidebar-wrapper">
          {sidebar}
        </div>
      )}
      <Navbar
        data-testid="ride-navbar"
        className={`${module} ${isSmallView && sidebarVisibility ? "sticky-top" : ""} ${
          theme === RideThemes.DARK ? "shadow-none" : ""
        }`}
        bg={theme}
        variant={theme}>
        <div className={`${module}__container`}>
          <Navbar.Brand>{logo}</Navbar.Brand>
          {isSmallView && sidebar ? (
            <Nav className={`${module}__nav-hamburger`}>
              <RideHamburger onClick={toggleSidebar} isActive={sidebarVisibility} />
            </Nav>
          ) : (
            <>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end navbar-nav-default">
                <Nav activeKey={activePage} onSelect={onSelect}>
                  {children}
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </div>
      </Navbar>
    </>
  );
};
