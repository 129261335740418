import gql from "graphql-tag";

export const FINISH_FILE_UPLOAD = gql`
  mutation FinishFileUpload($uploadId: String!, $partNumber: Int!, $eTag: String!) {
    finishFileUpload(uploadId: $uploadId, partNumber: $partNumber, eTag: $eTag) {
      status
      statusText
    }
  }
`;
