import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import {
  CapitalContributionField,
  CompanyTypeField,
  TradeTaxFreeStatusField
} from "../../../../../generic/company-form/fields";

export interface IProps {
  t: TFunction;
  formik;
  companyTypes;
}

export const CompanyTypeCapital = ({ t, formik, companyTypes }: IProps) => {
  return (
    <div className="mt-5">
      <Row>
        <Col xs={12} md={6}>
          <CompanyTypeField t={t} formik={formik} companyTypes={companyTypes} />
        </Col>
        <Col xs={6} md={3}>
          <CapitalContributionField t={t} formik={formik} />
        </Col>
        <Col xs={6} md={3}>
          <TradeTaxFreeStatusField t={t} formik={formik} />
        </Col>
      </Row>
    </div>
  );
};
