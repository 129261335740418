import { Moment } from "moment";

export interface DateSerializer {
  doubleDigit(n: number): string;

  serialize(value: string | Moment | Date): string;

  toString(): string;
}

export class DateSerializer {
  protected value: string;
  protected defaultValue: string;

  constructor(defaultValue: string, value: string | Moment | Date | null) {
    this.defaultValue = defaultValue;
    this.value = !value ? defaultValue : this.serialize(value);
  }

  static doubleDigit(n: number): string {
    return n < 10 ? `0${n}` : `${n}`;
  }

  serialize(value: string): string {
    return value;
  }

  toString() {
    return this.value;
  }
}
