import React, { FormEvent } from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import DocumentCard from "../../../sharedComponents/DocumentCard/DocumentCard";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { Chapter } from "lib/models/client/order/Chapter";
import { useHistory } from "react-router-dom";
import { DocumentsListHeading } from "../../../../sharedComponents/DocumentsListHeading/DocumentsListHeading";

const CompanyDocumentsStep = ({
  t,
  order,
  currentStep,
  saveData,
  controls
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const history = useHistory();

  let companyDocumentsSubchapter = currentStep.getAllStepSubchapters()[0]; //should have only one
  const proofOfAddressKeyStep: string = "company-proof-of-address";

  if (companyDocumentsSubchapter === undefined) {
    companyDocumentsSubchapter = currentStep.chapter.addSubChapter({
      key: `edit-company-documents`,
      index: 0,
      parentStepKey: currentStep.key,
      steps: [{ key: proofOfAddressKeyStep }]
    });
  }
  order.extra.chapters[currentStep.chapter.key].subChapters = currentStep.chapter.subChapters;

  const hasProofOfAddress = () => {
    return !!order.extra.companyDocuments?.proofOfAddressFile?.id;
  };

  const onEditDocumentCard = (subchapter: Chapter, stepKey: string) => {
    const step = subchapter.getStepByKey(stepKey);
    const positionalIndex = subchapter.positionalIndex;

    history.push(
      `/client/order/generic/documents/${positionalIndex}/${step?.index}/${order.id}/${order.submissionId}`
    );
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (hasProofOfAddress()) {
      currentStep.complete();
    }

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  };

  return (
    <DocumentsListHeading
      title={t("generic:ride-broker-onboarding.documents.company-documents-list.title")}>
      <div className={"documents-list__content"} key={`company-documents-list-proof-of-address`}>
        <DocumentCard
          data-testid={`company-documents-list-proof-of-address-card`}
          text={t(
            "generic:ride-broker-onboarding.documents.company-documents-list.proof-of-address"
          )}
          numberOfAttachments={hasProofOfAddress() ? 1 : 0}
          onEdit={() => onEditDocumentCard(companyDocumentsSubchapter, proofOfAddressKeyStep)}
          key={`proof-of-address`}
        />
      </div>

      <form onSubmit={onSubmit}>
        {{
          ...controls,
          props: {
            ...controls.props,
            disabled: !hasProofOfAddress()
          }
        }}
      </form>
    </DocumentsListHeading>
  );
};

export default withTranslationReady(["generic"])(CompanyDocumentsStep);
