import React from "react";
import ContinueOrderBanner from "../ContinueOrderBanner/ContinueOrderBanner";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { ContinueCompanyOrderBannerProps } from "../ContinueCompanyFoundingOrderBanner/ContinueCompanyFoundingOrderBanner";
import { OrderProductTypeEnum } from "../../../global-query-types";
import { Container } from "react-bootstrap";

const ContinueAddExistingCompanyOrderBanner = ({
  t,
  gmbh
}: ContinueCompanyOrderBannerProps & WithTranslation) => {
  const addExistingCompanyOrder = gmbh.orders.find(
    (o) => o.productType === OrderProductTypeEnum.AddExistingCompany
  );

  return (
    <Container>
      <ContinueOrderBanner
        dataTestId={"continue-add-existing-order-banner"}
        ctaLink={`/client/order/generic/company-information/0/${addExistingCompanyOrder?.id}/${addExistingCompanyOrder?.submissionId}`}
        message={t("generic:add-existing-company.banner-message")}
        continueButtonLabel={t("generic:add-existing-company.continue-order")}
      />
    </Container>
  );
};

export default withTranslationReady(["generic"])(ContinueAddExistingCompanyOrderBanner);
