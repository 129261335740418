import React from "react";
import { LinkVariant } from "../TextLink/TextLink";
import TextLinkAsButton from "../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AlertMessage, AlertType } from "../AlertMessage/AlertMessage";

interface RideTableActionsProps extends WithTranslation {
  onCancelClick: () => void;
  validationWarningMessage?: string | null;
}

const RideTableActions = ({
  t,
  onCancelClick,
  validationWarningMessage = null
}: RideTableActionsProps) => {
  return (
    <div
      className={`ride-table-actions-sticky ${
        validationWarningMessage ? "ride-table-actions-sticky--with-warning" : ""
      }`}>
      <div data-testid="ride-table-actions-container" className="ride-table-actions-container">
        {validationWarningMessage && (
          <AlertMessage type={AlertType.warning} data-testid="ride-table-actions-warning">
            {validationWarningMessage}
          </AlertMessage>
        )}

        <div data-testid="ride-actions-panel" className="ride-table-actions-panel">
          <TextLinkAsButton
            onClick={onCancelClick}
            data-testid="ride-table-actions-cancel-button"
            variant={LinkVariant.dark}>
            {t("generic:cancel")}
          </TextLinkAsButton>

          <RideButtonDeprecated
            data-testid="ride-table-actions-submit-button"
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Medium}
            type="submit">
            <strong>{t("generic:save")}</strong>
          </RideButtonDeprecated>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(RideTableActions);
