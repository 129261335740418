import React, { useState } from "react";
import { KycEntryTypeEnum } from "../../../../global-query-types";
import { Form, FormikProvider, useFormik } from "formik";
import { formatDateTime } from "../../../../common/formatters";
import EditableSection from "../../../../sharedComponents/EditableSection/EditableSection";
import { Alert, Col, Row } from "react-bootstrap";
import { EditKYCStatus } from "./EditKYCStatus";
import { EditKYCStatusCompanyInfo } from "../../NomineeCompany/components/EditKYCStatusCompanyInfo";
import { EditKYCStatusPersonInfo } from "../../NomineePerson/KYCStatusSection/EditKYCStatusPersonInfo";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  FullPersonDetailsQuery,
  FullPersonDetailsQuery_getPersonDetails_kycEntries
} from "../../NomineePerson/graphql/FullPersonDetailsQuery";
import { ApolloQueryResult } from "apollo-client";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById_kycEntries
} from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const KYCStatuses = {
  PENDING: 0,
  STARTED: 1,
  COMPLETED: 2,
  INCOMPLETE: 3
};

interface KYCStatusSectionProps extends WithTranslation {
  kycEntry:
    | NomineeCompanyDetails_companyById_kycEntries
    | FullPersonDetailsQuery_getPersonDetails_kycEntries
    | undefined;
  refetch: () => Promise<ApolloQueryResult<NomineeCompanyDetails | FullPersonDetailsQuery>>;
  updateKycEntry: (status: string, incompleteReason: string) => Promise<void>;
}

const KYCStatusSection = ({ t, kycEntry, refetch, updateKycEntry }: KYCStatusSectionProps) => {
  const kycStatus = kycEntry?.status ?? "PENDING";
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(KYCStatuses[kycStatus]);

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onView = () => {
    formik.setValues(initialValues);
    setSelectedIndex(initialValues.status);
    setIsEditMode(false);
  };

  const initialValues = {
    status: KYCStatuses[kycStatus],
    incompleteReason: kycEntry?.incompleteReason
  };

  const onSubmit = async (values) => {
    const status = Object.keys(KYCStatuses)[values.status];
    const incompleteReason = values.incompleteReason ?? "";

    await updateKycEntry(status, incompleteReason);
    await refetch();
    setIsEditMode(false);
  };

  const handleRadioChange = (event) => {
    const radioValue = parseInt(event.target.value);
    setSelectedIndex(radioValue);
    formik.setFieldValue("status", radioValue);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  });

  const statusMessage = () => {
    let baseMessage = t(`nominee-dashboard:kyc-status.${kycStatus?.toLowerCase()}`);
    if (kycStatus === "COMPLETED") {
      return baseMessage + formatDateTime(kycEntry?.completedAt);
    }
    if (kycStatus === "INCOMPLETE") {
      return baseMessage + kycEntry?.incompleteReason;
    }
    return baseMessage;
  };

  const statusVariant = kycStatus === "COMPLETED" ? "success" : "danger";

  return (
    <div data-testid="kyc-status-section">
      <FormikProvider value={formik}>
        <Form data-testid="edit-kyc-status">
          <EditableSection
            title={t("nominee-dashboard:status-kyc")}
            isEditMode={isEditMode}
            onEdit={onEdit}
            onView={onView}
            testIdPrefix="kyc-status-">
            <Row>
              <Col>
                {isEditMode ? (
                  <EditKYCStatus
                    formik={formik}
                    selectedIndex={selectedIndex}
                    handleRadioChange={handleRadioChange}
                    KYCStatuses={KYCStatuses}
                    t={t}
                    InfoComponent={
                      kycEntry?.type === KycEntryTypeEnum.COMPANY
                        ? EditKYCStatusCompanyInfo
                        : EditKYCStatusPersonInfo
                    }
                  />
                ) : (
                  <div data-testid="kyc-status">
                    <Alert variant={statusVariant}>{statusMessage()}</Alert>
                  </div>
                )}
              </Col>
            </Row>
          </EditableSection>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslation(["trader-gmbh", "generic", "nominee-dashboard"])(KYCStatusSection);
