import { Col, Row } from "react-bootstrap";
import { textFieldFor } from "../../../../../generic/commonFields";
import React from "react";
import { TFunction } from "react-i18next";
import { FormikProps } from "formik";

interface IProps {
  t: TFunction;
  formik: FormikProps<any>;
}

export const LiabilityInput = ({ t, formik }: IProps) => {
  return (
    <Row className="mt-5">
      <Col>{textFieldFor(t, formik, "liability", t("nominee-dashboard:company.liability"))}</Col>
    </Row>
  );
};
