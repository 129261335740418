import moment, { Moment } from "moment-timezone";
import { DateSerializer } from "./DateSerializer";

export class GermanDate extends DateSerializer {
  constructor(value: string | Moment | Date | undefined, defaultValue: string) {
    super(defaultValue, value ?? null);
  }

  serialize(value: string | Moment | Date | null | undefined): string {
    let date: Moment;

    if (!value) {
      return "";
    }

    if (typeof value === "string") {
      if (value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/)) {
        return value;
      }

      if (!moment(value, "YYYY-MM-DD").isValid()) {
        return this.defaultValue;
      }

      date = moment(value).tz("Europe/Berlin");
    } else if (value instanceof Date) {
      date = moment(value).tz("Europe/Berlin");
    } else {
      date = value;
    }

    return date.format("DD.MM.YYYY");
  }

  toNumberString() {
    return this.value.split(".").join("");
  }
}
