import React, { PropsWithChildren, useEffect, useState } from "react";
import { TFunction } from "i18next";
import { WithTranslation } from "react-i18next";
import { FeatureFlags, LegalPersonType } from "global-query-types";
import logger from "common/Logger";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { AlertMessage, AlertType } from "sharedComponents/AlertMessage/AlertMessage";
import { GetOrderQuery_getOrder } from "lib/api/queries/graphql/GetOrderQuery";
import { companyName } from "lib/formatters/companyFormatter";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { Step } from "lib/models/client/order/Step";
import {
  EXTERNAL_CONTENT_FALLBACK,
  ExternalContentNamespace,
  ExternalContentProvider
} from "lib/ports/ExternalContentProvider";
import {
  ButtonHeight,
  ButtonRadius,
  ButtonVariant,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import BackButton from "uiLibrary/components/Buttons/BackButton/BackButton";
import ChaptersContextualHelp from "../ChaptersContextualHelp/ChaptersContextualHelp";
import { ChaptersProgressBarProps } from "../ChaptersProgressBar/ChaptersProgressBar";
import { LeanCompanyDetails } from "lib/models/LeanCompanyDetails";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";

export type WithChapters = { chapters: any };
export type OrderData<T> = Omit<GetOrderQuery_getOrder, "extra"> & { extra: T };

export type AlertConfig = {
  shouldShow: (order: OrderData<any>) => boolean;
  component: React.ComponentType;
};
export interface WithCompany {
  company: LeanCompanyDetails;
}

export interface StepComponentProps<T = any> {
  order: OrderData<T & WithChapters>;
  saveData: (
    data: OrderData<T & WithChapters>["extra"],
    shouldNotPush?: boolean,
    shouldSave?: boolean
  ) => Promise<void>;
  controls: React.ReactElement;
  currentStep: Step;
  "data-testid"?: string;
  confirmCancelModalRef?: React.MutableRefObject<{ trigger: () => Promise<void> } | undefined>;
}

export interface OrderChaptersConfig {
  chapters: { [key: string]: { title: string; icon: string } };
  steps: { [key: string]: React.ComponentType<StepComponentProps> };
  alerts?: {
    [key: string]: AlertConfig;
  };
  contextualHelpSuffix: string;
}

export const ChaptersContextualHelpWrapper = ({
  stepKey,
  useExternalContentProvider,
  suffix
}: {
  stepKey: string;
  useExternalContentProvider: () => ExternalContentProvider;
  suffix?: string;
}) => {
  const [contextualHelp, setContextualHelp] = useState(EXTERNAL_CONTENT_FALLBACK);

  const externalContentProvider = useExternalContentProvider();

  useEffect(() => {
    externalContentProvider
      .getExternalContentByKey(ExternalContentNamespace.STEPS_CONTEXTUAL_HELP, stepKey, suffix)
      .then(setContextualHelp)
      .catch((e) => logger.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepKey]);

  return <ChaptersContextualHelp content={contextualHelp} />;
};

export interface NextButtonProps {
  order: GetOrderQuery_getOrder;
  saveData: (data: any) => Promise<void>;
  onClick?: () => void;
  title?: string;
  disabled?: boolean;
  isLoaderActive?: boolean;
}

export const NextButton = ({
  order,
  saveData,
  onClick,
  title = "Next",
  disabled,
  isLoaderActive
}: NextButtonProps) => {
  if (!onClick) {
    onClick = () => {
      saveData({
        ...order.extra
      });
    };
  }

  return (
    <RideButtonDeprecated
      disabled={disabled}
      className="chapters-order-layout__next-button"
      data-testid="next-button"
      variant={ButtonVariant.Primary}
      width={ButtonWidth.FULL}
      height={ButtonHeight.LARGE}
      radius={ButtonRadius.Soft}
      onClick={onClick}
      isLoaderActive={isLoaderActive}>
      {title}
    </RideButtonDeprecated>
  );
};

export interface ChaptersOrderNakedLayoutProps extends PropsWithChildren<{}> {
  className: string;
  title: string;
  onCancel: () => void;
  progressBarProps?: ChaptersProgressBarProps;
  chaptersSidebar: React.ReactElement;
  contextualHelp: React.ReactElement;
}

interface StepNavigationControlsProps extends WithTranslation {
  order: GetOrderQuery_getOrder;
  saveData: (data: any) => Promise<void>;
  stepIndex: number;
  changeStep: (increment: number) => void;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  isLoaderActive?: boolean;
  title?: string;
}

export const StepNavigationControls = withTranslationReady("generic")(
  ({
    changeStep,
    order,
    saveData,
    stepIndex,
    t,
    onClick = () => {},
    className,
    disabled,
    isLoaderActive,
    title
  }: StepNavigationControlsProps) => {
    return (
      <div className={`chapters-order-layout__step-navigation-controls ${className}`}>
        {stepIndex > 0 ? (
          <BackButton
            onClick={() => {
              changeStep(-1);
            }}
          />
        ) : null}
        <NextButton
          disabled={disabled}
          order={order}
          saveData={saveData}
          title={title ?? t("generic:chapters-layout.next")}
          onClick={onClick}
          isLoaderActive={isLoaderActive}
        />
      </div>
    );
  }
);

interface StepHeadingProps {
  text: string;
  dataTestId?: string;
}

export const StepHeading = ({ text, dataTestId }: StepHeadingProps) => (
  <span
    className="chapters-order-layout__step-heading"
    data-testid={dataTestId ?? "chapters-order-layout-step-heading"}>
    {text}
  </span>
);

export const AlertInvalidConfig = () => (
  <AlertMessage type={AlertType.error} data-testid="error-alert">
    Invalid chapter configuration: missing step
  </AlertMessage>
);

export const getEntityName = (
  t: TFunction,
  shareholder: any,
  index: number,
  entityKey: "shareholder" | "employee" = "shareholder"
): string => {
  const companyData = shareholder.companyData ?? {};
  const isLegalEntity = shareholder.shareholderType === LegalPersonType.Company;
  if (isLegalEntity && companyData.companyName) {
    return companyName({
      name: companyData.companyName,
      legalForm: companyData.legalForm
    });
  }

  const personData = shareholder.personData ?? {};
  const isPerson =
    entityKey === "employee" || shareholder.shareholderType === LegalPersonType.Person;
  if (isPerson && (personData.firstName || personData.lastName)) {
    if (FeatureFlagService.instance.isEnabled(FeatureFlags.AddMiddleNameToLTBForm)) {
      return personNameWithFallback(personData, true);
    } else {
      return personNameWithFallback(personData, false);
    }
  }

  return `${t(`generic:${entityKey}`)} ${index + 1}`; //TODO: default name is not being match with name used in review (personNameWithFallback)
};

export const errorCodeToTitleKeyMap = {
  EMAIL_ACCOUNT_ALREADY_EXISTS: "errorCodes:generic.email-already-exists",
  "Network error: Failed to fetch": "generic:error-modal.network-error-message",
  GMBH_ORDER_REUSED_EMAIL: "errorCodes:order-form.order-email-reused",
  GMBH_ORDER_CURRENT_USER_NOT_SHAREHOLDER: "errorCodes:order-form.current-user-not-shareholder",
  GMBH_ORDER_NO_MANAGING_DIRECTOR: "errorCodes:order-form.managing-director-missing",
  GMBH_ORDER_EMPLOYEE_IS_NOT_MANAGING_DIRECTOR:
    "errorCodes:order-form.employee-is-not-managing-director",
  GMBH_ORDER_ADDRESS_INFORMATION_MISSING: "errorCodes:order-form.gmbh-address-missing",
  INVALID_GMBH_ORDER_STRUCTURE: "errorCodes:order-form.invalid-gmbh-order-structure",
  GMBH_ORDER_SHAREHOLDER_TYPE_NOT_SUPPORTED: "errorCodes:order-form.invalid-shareholder-type",
  GMBH_ORDER_COMPANY_REGISTER_COURT_MISSING: "errorCodes:order-form.gmbh-register-court-missing",
  GMBH_ORDER_COMPANY_REGISTRATION_MISSING:
    "errorCodes:order-form.gmbh-company-registration-missing",
  COMPANY_NAME_MISSING: "errorCodes:order-form.company-name-missing",
  COMPANY_LEGAL_FORM_NOT_SUPPORTED: "errorCodes:order-form.company-legal-form-not-supported",
  GMBH_ORDER_ASSET_NOT_INFORMED: "errorCodes:order-form.gmbh-asset-not-informed",
  COMPANY_LEGAL_FORM_MISSING: "errorCodes:order-form.gmbh-legal-form-missing",
  LOW_TAX_BROKER_CURRENT_USER_NOT_SHAREHOLDER: "errorCodes:order-form.current-user-not-shareholder",
  LOW_TAX_BROKER_CURRENT_USER_EMAIL_IS_IN_MORE_THAN_1_SHAREHOLDER:
    "errorCodes:order-form.shareholder-email-needs-to-be-unique"
};
