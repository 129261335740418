import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getNextSortDirection, SortDirection } from "./SortDirection";
import { RideHeaderColumn } from "../../lib/types/types";

interface SortField {
  name: string;
  direction: SortDirection;
}

/**
 *
 * 🔥 IMPORTANT 🔥
 *
 * @param data variable should be a stable reference, because we are comparing it by reference to determine if it has changed
 *
 * 🚫 Doing things like 🚫
 *   const data = [];
 *   for (let i = 0; i < 10; i++) {
 *     data.push({ id: i });
 *   }
 *   const { sortedData, sortCallback } = useSimpleSortBehaviour(data);
 *
 * 😱 Is going to mess everything up in an infinite useEffect/setState loop. 😱
 * Because data is a new reference every time, and the useEffect will fire every time.
 *
 * 💡 If you insist on this style, then wrap the creation of the data in a useMemo, so that it is only created once. 💡
 *
 * Sincerely,
 * Alex
 *
 * 🤦‍ (how Alex feels about this, and about using emojis in the documentation) 🤦‍
 *
 **/
export const useSimpleSortBehaviour = <T extends any>(data: T[]) => {
  const [sortedData, setSortedData] = useState([...data]);
  const [sortField, setSortField] = useState<SortField | null>(null);

  const sortData = (
    data: T[],
    direction: SortDirection.ASCENDING | SortDirection.DESCENDING | SortDirection.INACTIVE,
    name: string
  ) => {
    let sortedData = data;
    if (direction !== SortDirection.INACTIVE) {
      sortedData = _.sortBy(data, [name]);
    }
    if (direction === SortDirection.DESCENDING) {
      sortedData = sortedData.reverse();
    }
    return sortedData;
  };

  const sortCallback = useCallback(
    (column: Pick<RideHeaderColumn, "name">) => {
      const name = column.name;

      let direction: SortDirection | undefined;
      if (name !== sortField?.name) {
        direction = SortDirection.ASCENDING;
      } else {
        direction = getNextSortDirection(sortField?.direction);
      }

      const sortedData = sortData(data, direction, name);

      setSortedData(sortedData);
      setSortField({ name, direction });
    },
    [data, sortField, setSortField, setSortedData]
  );

  useEffect(() => {
    if (sortField) {
      const sortedData = sortData(data, sortField.direction, sortField.name);
      setSortedData(sortedData);
    } else {
      setSortedData([...data]);
    }
    // NOTE: only depending on data because we must fire this ONLY if data has changed, but not any other state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { sortedData, sortCallback };
};
