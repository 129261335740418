import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import { formatBytes } from "lib/formatters/numberFormatter";
import { refreshToken } from "common/Authentication/refreshToken";
import { FileEntityType, FileStorage, FileType, UploadFileResponse } from "lib/ports/FileStorage";

export class InvalidFileTypeError extends Error {
  constructor(public allowedFileTypes: FileType[], public actualFileType: FileType) {
    super(
      `Invalid file type '${actualFileType}'. Allowed file types: ${allowedFileTypes
        .map((t) => `'${t}'`)
        .join(", ")}`
    );
  }
}

export class FileTooLargeError extends Error {
  constructor(public maxSizeInBytes: number, public actualSizeInBytes: number) {
    super(
      `File too large: ${formatBytes(
        actualSizeInBytes
      )} (${actualSizeInBytes} bytes). Maximum allowed file size: ${formatBytes(
        maxSizeInBytes
      )} (${maxSizeInBytes} bytes)`
    );
  }
}

export class NotSuccessfulResponseError extends Error {
  constructor(status: number, statusText: string) {
    super(`The server responded with a status ${status}: ${statusText}`);
  }
}

interface RideStorageFileUploaderOptions {
  maxFileSizeInBytes?: number;
  allowedFileTypes?: FileType[];
}

export class RideFileStorage implements FileStorage {
  private readonly maxFileSizeInBytes?: number;
  private readonly allowedFileTypes?: FileType[];

  constructor(options: RideStorageFileUploaderOptions) {
    this.maxFileSizeInBytes = options.maxFileSizeInBytes;
    this.allowedFileTypes = options.allowedFileTypes;
  }

  async uploadFileForEntity(
    entityType: FileEntityType,
    entityId: string,
    file: File
  ): Promise<UploadFileResponse> {
    if (this.maxFileSizeInBytes && file.size > this.maxFileSizeInBytes) {
      throw new FileTooLargeError(this.maxFileSizeInBytes, file.size);
    }

    if (this.allowedFileTypes && !this.allowedFileTypes.includes(file.type as FileType)) {
      throw new InvalidFileTypeError(this.allowedFileTypes, file.type as FileType);
    }

    const formData = new FormData();
    formData.append("file", file);

    let response = await this.uploadFile(entityType, entityId, formData);

    if (!response.ok) {
      await refreshToken();
      response = await this.uploadFile(entityType, entityId, formData);

      if (!response.ok) {
        throw new NotSuccessfulResponseError(response.status, response.statusText);
      }
    }

    const { fileId, checksum } = await response.json();

    return { fileId, checksum };
  }

  private async uploadFile(entityType, entityId, formData): Promise<Response> {
    return await fetch(`${getEndpoint()}/api/file/${entityType}/${entityId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      body: formData
    });
  }

  async getFileForEntity(
    entityType: FileEntityType,
    entityId: string,
    fileId: string
  ): Promise<Blob> {
    const response = await fetch(`${getEndpoint()}/api/file/${entityType}/${entityId}/${fileId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      mode: "cors",
      credentials: "include"
    });
    return response.blob();
  }
}
