import { EditIcon } from "../../icons/EditIcon";
import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

type ButtonEditVariant = "light" | "dark";

interface ButtonEditProps extends WithTranslation {
  onClick: () => void;
  variant: ButtonEditVariant;
  "data-testid": string;
  className?: string;
}

const ButtonEdit = (args: ButtonEditProps) => {
  return (
    <button
      data-testid={args["data-testid"]}
      className={`button-edit button-edit--${args.variant} ${args.className ?? ""}`}
      onClick={args.onClick}>
      <span className="button-edit__text">{args.t(`generic:edit-field`)}</span>
      <EditIcon />
    </button>
  );
};

export default withTranslationReady("generic")(ButtonEdit);
