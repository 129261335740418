import { WithTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import EditCompanyDetails from "../EditCompanyDetails/EditCompanyDetails";
import ViewCompanyDetails from "./ViewCompanyDetails";
import { NomineeCompanyDetailsSchema } from "lib/validation/NomineeCompanyDetailsSchema";
import EditableSection from "sharedComponents/EditableSection/EditableSection";
import { nomineeLegalForms } from "common/staticData/LegalForms";
import { NomineeUpdateCompanyVariables } from "lib/api/mutations/graphql/NomineeUpdateCompany";
import { deepClone } from "common/deepClone";
import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import { DocumentTemplateInlineDropdown } from "../../DocumentTemplateDropdown/DocumentTemplateDropdown";
import { formatFiscalYear, transformFiscalYearToMoment } from "common/formatters";
import { Button } from "react-bootstrap";
import { getWepaManagerOauthRedirectionUrl } from "common/Authentication/getWepaOauthRedirection";
import { isWePaCompany } from "lib/types/clients/categories";

export interface CompanyDetailsSectionProps extends WithTranslation {
  onSubmit: (companyData: NomineeUpdateCompanyVariables) => Promise<void>;
  company: NomineeCompanyDetails_companyById;
}

const CompanyDetailsSection = ({ onSubmit, company, t }: CompanyDetailsSectionProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleCompanyUpdate = async (values) => {
    await onSubmit({
      id: values.id,
      name: values.name,
      legalForm: values.legalForm,
      locked: values.locked,
      street: values.street,
      postalCode: values.postalCode,
      city: values.city,
      streetLine2: values.streetLine2,
      careOf: values.careOf,
      country: values.country,
      registerCourt: values.registerCourt,
      registration: values.registration,
      taxNumber: values.taxNumber,
      vatNumber: values.vatNumber,
      advisorNumber: values.advisorNumber,
      economicId: values.economicId,
      notarizationDate: values.notarizationDate,
      applicationForEntryDate: values.applicationForEntryDate,
      commercialRegisterEntryDate: values.commercialRegisterEntryDate,
      foundationDate: values.foundationDate,
      lei: values.lei,
      businessPurpose: values.businessPurpose,
      businessPurposeShort: values.businessPurposeShort,
      employees: values.employees,
      clientNumber: values.clientNumber,
      liability: values.liability,
      fiscalYear: values.fiscalYear === null ? null : formatFiscalYear(values.fiscalYear),
      testCompany: values.testCompany === "" ? null : values.testCompany
    });
    setIsEditMode(false);
  };

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onView = () => {
    setIsEditMode(false);
  };

  const companyWithoutBusinessPurpose = (company) => {
    const companyData = deepClone(company);
    companyData.fiscalYear = transformFiscalYearToMoment(company.fiscalYear).toISOString();

    delete companyData.businessPurpose;
    delete companyData.businessPurposeShort;

    return companyData;
  };

  const initialValues = {
    ...companyWithoutBusinessPurpose(company),
    ...company.businessAddress
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: true,
    validationSchema: NomineeCompanyDetailsSchema,
    onSubmit: handleCompanyUpdate,
    enableReinitialize: true
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditableSection
          testIdPrefix="company-details-"
          title={`${company.name} ${company.legalForm}`}
          isEditMode={isEditMode}
          onEdit={onEdit}
          onView={onView}>
          {isEditMode ? (
            <EditCompanyDetails formik={formik} legalForms={nomineeLegalForms} company={company} />
          ) : (
            <>
              <div className="d-flex justify-content-end mt-n4">
                <DocumentTemplateInlineDropdown
                  dataTestId="company-creation-generate-document-dropdown"
                  companyCreationId={company.companyCreation?.id ?? ""}
                  type="company-creation"
                  label={t("nominee-dashboard:company.company-creation-documents")}
                />
                <DocumentTemplateInlineDropdown
                  dataTestId="admin-service-generate-document-dropdown"
                  companyCreationId={company.adminService?.id ?? ""}
                  type="admin-service"
                  label={t("nominee-dashboard:company.admin-service-documents")}
                />
                {isWePaCompany(company) && (
                  <Button
                    variant="primary"
                    className="float-right"
                    data-testid={`open-in-wepa-manager-button`}
                    onClick={() => {
                      window.open(getWepaManagerOauthRedirectionUrl(company.wepaId));
                    }}>
                    {t("nominee-dashboard:company:open-in-wepa-manager")}
                  </Button>
                )}
              </div>
              <ViewCompanyDetails company={company} />
            </>
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(CompanyDetailsSection);
