import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { RideDatePickerHeader } from "./RideDatePickerHeader";

export interface RideDatePickerProps {
  className?: string;
  headerClassName?: string;
  dateFormat?: string;
  placeholder?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  onSelectDate?: (date: string) => void;
  selectedDate?: string | null;
}

const RideDatePicker = ({
  className = "",
  headerClassName = "ride-date-picker__header",
  dateFormat = "yyyy/MM/dd",
  placeholder = "yyyy/MM/dd",
  label = "",
  name = "datepicker",
  disabled = false,
  onSelectDate,
  selectedDate
}: RideDatePickerProps) => {
  const selectDateHandler = (date: any) => {
    onSelectDate?.(moment(date).toISOString());
  };

  const transformedDate = selectedDate ? moment(selectedDate).toDate() : null;

  return (
    <div data-testid="ride-date-picker">
      <label className={`ride-date-picker-label ${className}-label d-block`}>{label}</label>
      <div data-testid={`${name}-date-container`} className="d-block">
        <DatePicker
          className={`ride-date-picker ${className}`}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          disabled={disabled}
          selected={transformedDate}
          onChange={selectDateHandler}
          name={name}
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <RideDatePickerHeader
              date={date}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prefix={headerClassName}
            />
          )}
        />
      </div>
    </div>
  );
};

export default RideDatePicker;
