import React, { FormEvent, useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { getEntityName, StepComponentProps, StepHeading } from "../../ChaptersOrderLayout.partials";
import ButtonCard from "uiLibrary/components/Buttons/ButtonCard/ButtonCard";
import LegalEntityCard from "client/components/ChaptersOrderLayout/sharedComponents/LegalEntityCard/LegalEntityCard";
import { RideStatus } from "lib/types/types";
import { LegalPersonType, OrderProductTypeEnum } from "global-query-types";
import { useHistory } from "react-router-dom";
import { Chapter } from "lib/models/client/order/Chapter";
import * as Sentry from "@sentry/react";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { Shareholder } from "lib/models/client/CompanyFounding/Shareholder";
import { getNextDictionaryIndex } from "lib/models/client/CompanyFounding/getNextDictionaryIndex";
import { Step } from "lib/models/client/order/Step";
import { deepClone } from "common/deepClone";
import { GetMe } from "lib/api/queries/MeQuery";
import { OrderExtraService } from "lib/services/OrderExtraService/OrderExtraService";

export interface ShareholderListProps extends StepComponentProps, WithTranslation {
  flow: OrderProductTypeEnum;
}

const ShareholderListComponent = ({
  t,
  flow,
  order,
  currentStep,
  controls,
  saveData
}: ShareholderListProps) => {
  const brokerageAccountSteps = [
    { key: "shareholder-general-information" },
    { key: "shareholder-citizenship-information" },
    { key: "shareholder-residential-address" },
    { key: "majority-shareholder-status-information" },
    { key: "shareholder-review", completed: true }
  ];
  const companyFoundationSteps = [
    { key: "shareholder-general-information" },
    { key: "shareholder-citizenship-information" },
    { key: "shareholder-residential-address" },
    { key: "shareholder-review", completed: true }
  ];

  const existingSubchapters = currentStep.getAllSubchapters();
  const { push } = useHistory();
  const [showConfirmDeleteShareholderModal, setShowConfirmDeleteShareholderModal] = useState(false);
  const [subchapterToDelete, setSubchapterToDelete] = useState<Chapter>();
  const reviewStepKey = "shareholder-review";
  const firstStepKey = "shareholder-general-information";

  let translationKeysPrefix = "";
  switch (flow) {
    case OrderProductTypeEnum.CompanyFoundation:
      translationKeysPrefix = "generic:company-setup.steps.shareholders-list";
      break;
    case OrderProductTypeEnum.BrokerageAccount:
      translationKeysPrefix =
        !order.extra.isNewBrokerEnabled && order?.extra?.hasMajorityShareholder === "yes"
          ? "generic:ride-broker-onboarding.majority-shareholders-list"
          : "generic:ride-broker-onboarding.shareholders-list";
  }

  const deleteShareholder = async () => {
    currentStep.chapter.removeSubChapterByEntityIndex(subchapterToDelete?.index!);

    const documentsChapter = currentStep.chapter.order.getChapter("documents");
    documentsChapter?.removeSubChapterByEntityIndexAndParentStepKey(
      subchapterToDelete?.index!,
      "personal-documents-list"
    );

    const shareholdersCopy = deepClone(order.extra.shareholders);
    delete shareholdersCopy[subchapterToDelete!.index!];
    shareholderListChangeInvalidation(currentStep);

    await saveData(
      { ...order.extra, shareholders: shareholdersCopy, ...currentStep.chapter.order.serialize() },
      true
    );
  };

  const onEdit = (shareholderSubChapter: Chapter) => {
    const editStep = shareholderSubChapter.getStepByKey(firstStepKey);
    const positionalIndex = shareholderSubChapter.positionalIndex;
    if (!editStep) {
      Sentry.captureMessage(
        `There is no step 'edit-shareholder' in the order with id ${order.id} for shareholder ${shareholderSubChapter.index} in the position ${positionalIndex} `
      );
      return;
    }
    push(
      `/client/order/generic/shareholder-details/${positionalIndex}/${editStep?.index}/${order.id}/${order.submissionId}`
    );
  };

  const onPreview = (shareholderSubChapter: Chapter) => {
    const reviewStep = shareholderSubChapter.getStepByKey(reviewStepKey);
    const positionalIndex = shareholderSubChapter.positionalIndex;
    if (!reviewStep) {
      Sentry.captureMessage(
        `There is no step 'shareholder-review' in the order with id ${order.id} for shareholder ${shareholderSubChapter.index} in the position ${positionalIndex}`
      );
      return;
    }
    push(
      `/client/order/generic/shareholder-details/${positionalIndex}/${reviewStep?.index}/${order.id}/${order.submissionId}`
    );
    return;
  };

  const createShareholderCard = (index: number) => {
    currentStep.chapter.addSubChapter({
      key: "edit-shareholder-data",
      index,
      parentStepKey: "shareholders-list",
      steps:
        order.productType === OrderProductTypeEnum.BrokerageAccount
          ? brokerageAccountSteps
          : companyFoundationSteps
    });
  };

  const onAdd = async () => {
    const nextIndex = getNextDictionaryIndex(order.extra.shareholders);

    createShareholderCard(nextIndex);

    shareholderListChangeInvalidation(currentStep);

    await saveData(
      {
        ...order.extra,
        ...currentStep.chapter.order.serialize(),
        shareholders: {
          ...order.extra.shareholders,
          [nextIndex]: Shareholder({
            shareholderType: LegalPersonType.Person,
            personData: {
              firstName: undefined,
              lastName: undefined,
              email: undefined,
              phoneNumber: undefined,
              authorizedToShareShareholderInfo: false
            }
          })
        }
      },
      true
    );
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (currentStep.chapter.subChapters.every((s) => s.completed)) {
      currentStep.complete();
    }

    await OrderExtraService.normalizeShareholdersData(order.extra, currentStep);

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  };

  const meQueryRequest = GetMe();
  const myEmail = meQueryRequest.data?.me?.email ?? "";

  const ordererShareholderSubchapter = existingSubchapters.find((subchapter) => {
    const shareholder = order.extra.shareholders?.[subchapter!.index!];
    const shareholderIsNotDeletableByUser =
      myEmail.toLowerCase() === shareholder?.personData?.email?.toLowerCase();

    return shareholderIsNotDeletableByUser;
  });

  return (
    <>
      <ConfirmationModal
        title={t("generic:confirm-action-modal-delete.title")}
        message={t("generic:confirm-action-modal-delete.messages.delete-shareholder")}
        type={ConfirmationModalType.DANGER}
        confirmLabel={t("generic:delete")}
        visible={showConfirmDeleteShareholderModal}
        onConfirm={async () => {
          await deleteShareholder();
        }}
        onClose={() => setShowConfirmDeleteShareholderModal(false)}
      />

      <div className="shareholders-list" data-testid="shareholders-list">
        <StepHeading
          dataTestId="shareholder-list-title"
          text={t(`${translationKeysPrefix}.title`)}
        />
        <div className="shareholders-list__content">
          {existingSubchapters &&
            existingSubchapters.map((subchapter) => {
              const shareholder = order.extra.shareholders?.[subchapter!.index!];
              const isTheShareholderThatRepresentsTheOrderer =
                subchapter === ordererShareholderSubchapter;
              const canDelete =
                order.productType !== "BrokerageAccount" ||
                (order.productType === "BrokerageAccount" &&
                  !isTheShareholderThatRepresentsTheOrderer);

              return (
                shareholder && (
                  <LegalEntityCard
                    key={subchapter.index}
                    status={subchapter.completed ? RideStatus.COMPLETED : RideStatus.PENDING}
                    editText={t(`${translationKeysPrefix}.edit-shareholder`)}
                    text={getEntityName(t, shareholder, subchapter!.index!)}
                    onEdit={() => onEdit(subchapter)}
                    onDelete={
                      canDelete
                        ? () => {
                            setSubchapterToDelete(subchapter);
                            setShowConfirmDeleteShareholderModal(true);
                          }
                        : null
                    }
                    onPreview={() => onPreview(subchapter)}
                    type={shareholder.shareholderType}
                  />
                )
              );
            })}
          <ButtonCard text={t(`${translationKeysPrefix}.add-shareholder`)} onPress={onAdd} />
        </div>
        <form onSubmit={onSubmit}>
          {{
            ...controls,
            props: {
              ...controls.props,
              disabled: !existingSubchapters.filter((c) => c.completed).length
            }
          }}
        </form>
      </div>
    </>
  );
};

const shareholderListChangeInvalidation = (currentStep: Step) => {
  const order = currentStep.chapter.order;
  order.getChapter("shareholder-details")?.getStepByKey("shareholders-list")?.reOpen();
  order.getChapter("shareholder-details")?.getStepByKey("review-and-adjust-shares")?.reOpen();
  order.getChapter("managing-directors")?.getStepByIndex(0)?.reOpen();
  order.getChapter("ownership-distribution")?.getStepByIndex(0)?.reOpen();
  order.getChapter("documents")?.getStepByIndex(0)?.reOpen();
};

const ShareholderList =
  (flow: OrderProductTypeEnum) => (props: StepComponentProps & WithTranslation) =>
    <ShareholderListComponent {...props} flow={flow} />;

export default (flow: OrderProductTypeEnum) =>
  withTranslationReady(["generic"])(ShareholderList(flow));
