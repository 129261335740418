import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { CompanyMyShareField, CompanyValueField } from "../../../../../generic/company-form/fields";
import OwnershipField from "../../../../Ownership/OwnershipField";
import { OwnerSinceField } from "../../../../../generic/commonFields";

export interface IProps {
  t: TFunction;
  formik;
  companyData;
  selectedDate;
  setSelectedDate;
}

export const CompanyMembershipShare = ({
  t,
  formik,
  companyData,
  selectedDate,
  setSelectedDate
}: IProps) => {
  return (
    <div className="mt-5">
      <Row>
        <Col xs={12} md={4}>
          <CompanyValueField t={t} formik={formik} />
        </Col>
        <Col xs={12} md={4}>
          <CompanyMyShareField t={t} formik={formik} />
        </Col>
        <Col xs={12} md={4}>
          <OwnerSinceField t={t} value={selectedDate} onChange={setSelectedDate} />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12}>
          <OwnershipField currentCompanyId={companyData.id} formik={formik} />
        </Col>
      </Row>
    </div>
  );
};
