import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import ProfileDetails from "./ProfileDetails";
import { useLocation } from "react-router";
import { GetMyDiscountCode } from "components/public/CompanyCreation/Queries";
import { GetMe } from "lib/api/queries/MeQuery";
import { UserService } from "../../../../lib/services/UserService/UserService";
import { UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT } from "../../../../lib/api/mutations/UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT";
import { useMutation } from "react-apollo";
import {
  resetTwoFactorAuthentication,
  resetTwoFactorAuthenticationVariables
} from "../../../../lib/api/mutations/graphql/resetTwoFactorAuthentication";
import { RESET_TWO_FACTOR_AUTHENTICATION } from "../../../../lib/api/mutations/RESET_TWO_FACTOR_AUTHENTICATION";
import {
  UpdateAllowTwoFactorAuthPrompt,
  UpdateAllowTwoFactorAuthPromptVariables
} from "../../../../lib/api/mutations/graphql/UpdateAllowTwoFactorAuthPrompt";

export const ProfileDetailsContainer = () => {
  const meQueryRequest = GetMe();
  const location = useLocation();
  const [updateAllowTwoFactorAuthPromptMutation] = useMutation<
    UpdateAllowTwoFactorAuthPrompt,
    UpdateAllowTwoFactorAuthPromptVariables
  >(UPDATE_ALLOW_TWO_FACTOR_AUTH_PROMPT);
  const [resetTwoFactorAuthenticationMutation] = useMutation<
    resetTwoFactorAuthentication,
    resetTwoFactorAuthenticationVariables
  >(RESET_TWO_FACTOR_AUTHENTICATION);

  const showUpdateEmailMessage = location.search.indexOf("newEmail=1") !== -1;

  const email = meQueryRequest.data?.me?.email ?? "";
  const changedEmail = meQueryRequest.data?.me?.changedEmail;
  const passwordChangedAt = meQueryRequest.data?.me?.passwordChangedAt;

  const phoneNumber = meQueryRequest.data?.me?.person?.phoneNumber ?? "";

  const myDiscountCode = GetMyDiscountCode();
  const referralCode = myDiscountCode.data?.myDiscountCode;
  const hasTwoFactorAuth = meQueryRequest.data?.me?.hasTwoFactorAuth ?? false;
  const allowTwoFactorAuthPrompt = meQueryRequest.data?.me?.allowTwoFactorAuthPrompt ?? true;

  const updateAllowTwoFactorAuth = async () => {
    const userId = UserService.instance?.meRequest?.data?.me?.id;

    if (userId) {
      const result = await updateAllowTwoFactorAuthPromptMutation({
        variables: { userId }
      });
      return result.data?.updateAllowTwoFactorAuthPrompt ?? false;
    }
  };

  const resetTwoFactorAuth = async () => {
    const userId = UserService.instance?.meRequest?.data?.me?.id;

    if (userId) {
      const result = await resetTwoFactorAuthenticationMutation({
        variables: { userId }
      });

      await UserService.instance?.meRequest?.refetch();
      return result.data?.resetTwoFactorAuthentication?.feedback;
    }

    return null;
  };

  return (
    <RequestFeedbackHandler requests={[meQueryRequest]} noLoadingAnimation={true}>
      <ProfileDetails
        email={email}
        changedEmail={changedEmail}
        showUpdateEmailMessage={showUpdateEmailMessage}
        passwordChangedAt={passwordChangedAt}
        referralCode={referralCode ?? null}
        phoneNumber={phoneNumber}
        hasTwoFactorAuth={hasTwoFactorAuth}
        updateAllowTwoFactorAuthPrompt={updateAllowTwoFactorAuth}
        resetTwoFactorAuth={resetTwoFactorAuth}
        allowTwoFactorAuthPrompt={allowTwoFactorAuthPrompt}
      />
    </RequestFeedbackHandler>
  );
};
