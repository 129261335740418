import localStore from "../LocalStore";

const LANGUAGE_CODE = "languageCode";

export default class LocalLanguageCodeProvider {
  getLanguageCode() {
    return localStore.getExpiringValue(LANGUAGE_CODE);
  }

  setLanguageCode(languageCode) {
    localStore.setExpiringValue(LANGUAGE_CODE, languageCode);
  }
}
