import RideInput from "../../../../sharedComponents/FormControls/RideInput/RideInput";
import { ClientSelect, ClientSelectOption } from "../../ClientSelect/ClientSelect";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { BankType, FeatureFlags } from "global-query-types";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";

interface BankAccountFormProps extends WithTranslation {
  formik: any;
  companyOptions: ClientSelectOption[];
}

export const BankAccountForm = (props: BankAccountFormProps) => {
  const { t } = props;

  const banks = Object.values(BankType).map((bankName) => ({
    label: t(`client:banking.banks.${bankName}`),
    value: bankName
  }));

  return <BankAccountFormContents {...props} bankOptions={banks} />;
};

interface BankAccountFormContentsProps extends BankAccountFormProps {
  bankOptions: ClientSelectOption[];
}
const BankAccountFormContents = ({
  formik,
  bankOptions,
  companyOptions,
  t
}: BankAccountFormContentsProps) => {
  const bankingPageInCompanyNavigation = FeatureFlagService.instance.isEnabled(
    FeatureFlags.BankingPageInCompanyNavigation
  );

  return (
    <div
      data-testid="bank-account-form"
      className="bank-account-form__form bank-account-form row m-4 m-md-0">
      <div className={"col-md-6 bank-account-form__input"}>
        <ClientSelect
          name="bank"
          testIdPrefix="bank-select"
          label={t("client:banking.addBankAccount.existing.bank")}
          options={bankOptions}
        />
      </div>
      <div className={"col-md-6 bank-account-form__input"}>
        <RideInput
          formik={formik}
          label={t("client:banking.addBankAccount.existing.nameLabel")}
          isOptional={false}
          asTextArea={false}
          name="name"
        />
      </div>
      {!bankingPageInCompanyNavigation && (
        <div className={"col-md-6 bank-account-form__input"}>
          <ClientSelect
            name="companyId"
            testIdPrefix="companyId-select"
            label={t("client:banking.addBankAccount.existing.companyLabel")}
            options={companyOptions}
          />
        </div>
      )}
      <div
        className={`${
          bankingPageInCompanyNavigation ? "col-md-12" : "col-md-6"
        } bank-account-form__input`}>
        <RideInput
          formik={formik}
          label={t("client:banking.addBankAccount.existing.ibanLabel")}
          isOptional={false}
          asTextArea={false}
          name="iban"
        />
      </div>
    </div>
  );
};

export default withTranslationReady(["client"])(BankAccountForm);
