import React, { useState } from "react";
import { GetCompanyCreations } from "../../../lib/api/queries/GetCompanyCreations";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import CompanyCreationList from "./CompanyCreationList";
import RideSearch from "../../../sharedComponents/RideSearch/RideSearch";
import { CompanyCreationTypeEnum } from "../../../global-query-types";
import { RidePagination } from "../../../uiLibrary/designSystem/RidePagination/RidePagination";
import { useMutation } from "react-apollo";
import {
  PrefillGSOrder,
  PrefillGSOrderVariables
} from "../../../lib/api/mutations/graphql/PrefillGSOrder";
import { PREFILL_GS_ORDER } from "../../../lib/api/mutations/PREFILL_GS_ORDER";
import logger from "../../../common/Logger";
import GeneratePrefilledGsOrder from "../GeneratePrefilledGsOrder/GeneratePrefilledGsOrder";

export const CompanyCreationListContainer = () => {
  const pageSize = 15;
  const [start, setStart] = useState(0);
  const [query, setQuery] = useState("");

  const getCompanyCreationsRequest = GetCompanyCreations(
    CompanyCreationTypeEnum.COMPANY_FOUNDING,
    query,
    {
      start,
      limit: pageSize
    }
  );

  const [prefillGSOrderMutation, prefillGSOrderRequest] = useMutation<
    PrefillGSOrder,
    PrefillGSOrderVariables
  >(PREFILL_GS_ORDER);

  const handlePrefillOrder = async () => {
    if (query) {
      try {
        await prefillGSOrderMutation({
          variables: {
            email: query
          }
        });

        await getCompanyCreationsRequest.refetch();
        setQuery(""); // 👀 I am sorry
        setQuery(query); // 👀 I am even more sorry
      } catch (e) {
        logger.error(e);
      }
    }
  };

  const companyCreationsCount = getCompanyCreationsRequest.data?.companyCreations?.count ?? 0;
  return (
    <RideSearch query={query} onQueryChange={setQuery} className="company-creation-list-search">
      <RequestFeedbackHandler requests={[prefillGSOrderRequest]}>
        <GeneratePrefilledGsOrder
          handlePrefillOrder={handlePrefillOrder}
          existingOrdersQuantity={companyCreationsCount}
        />
      </RequestFeedbackHandler>
      <RidePagination
        count={companyCreationsCount}
        start={start}
        pageSize={pageSize}
        setStart={setStart}>
        <RequestFeedbackHandler requests={[getCompanyCreationsRequest]}>
          <CompanyCreationList
            refetch={getCompanyCreationsRequest.refetch}
            list={getCompanyCreationsRequest.data?.companyCreations?.items ?? []}
          />
        </RequestFeedbackHandler>
      </RidePagination>
    </RideSearch>
  );
};
