import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Col, Container, Modal, Row } from "react-bootstrap";
import logger from "../../common/Logger";
import { TFunction } from "i18next";
import { MdDeleteForever } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { IconDelete } from "../common/icons/IconDelete";

interface DeletePromptProps extends WithTranslation {
  title: string;
  onConfirm: () => Promise<void>;
  nodeId?: string | null;
  toggleDeleteIcon?: boolean;
  fileName?: string;
  disabled?: boolean;
}

// 👀 used only in the admin dashboard
// it can be removed from the `ExternalStorageFile`
export const DeletePrompt = ({
  t,
  title,
  onConfirm,
  nodeId,
  toggleDeleteIcon,
  fileName,
  disabled = false
}: DeletePromptProps) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!disabled);
  };

  const handleHide = () => {
    setShow(false);
  };

  const confirmDeletion = async () => {
    try {
      await onConfirm();
      handleHide();
    } catch (error) {
      logger.errorMessage(`Unable to delete an entity`);
      logger.error(error);
    }
  };

  return (
    <>
      {toggleDeleteIcon ? (
        <div onClick={handleShow} data-testid={`storage-node-delete-${nodeId}`}>
          <IconDelete
            className={`storage-node__icon storage-node__icon--delete ${
              disabled ? "storage-node__icon--disabled" : ""
            }`}
          />
        </div>
      ) : (
        <MdDeleteForever
          size="20"
          color="red"
          cursor="pointer"
          data-testid={`storage-node-delete-${nodeId}`}
          onClick={handleShow}
        />
      )}

      <DeleteModal
        show={show}
        onHide={handleHide}
        title={title}
        nodeId={nodeId}
        fileName={fileName}
        toggleDeleteIcon={toggleDeleteIcon}
        t={t}
        confirmDeletion={confirmDeletion}
      />
    </>
  );
};

export interface DeleteModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  nodeId: string | null | undefined;
  fileName?: string;
  toggleDeleteIcon?: boolean;
  t: TFunction;
  confirmDeletion: () => Promise<void>;
}

// Used in the client app
export const DeleteModal = ({
  show,
  onHide,
  title,
  nodeId,
  fileName,
  toggleDeleteIcon,
  t,
  confirmDeletion
}: DeleteModalProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="pr-0"
      dialogClassName="modal-90w"
      aria-labelledby="delete-confirmation"
      data-testid="delete-modal">
      <Modal.Header closeButton>
        <Modal.Title id="delete-confirmation" data-testid="delete-modal-title">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {toggleDeleteIcon && (
            <Row className="justify-content-center text-center">
              <Container data-testid="delete-description">
                <h3>{t("generic:delete-prompt-text")}</h3>
              </Container>
              <span className="p-4 mb-3" data-testid={`delete-${nodeId}`}>
                {fileName}
              </span>
            </Row>
          )}
          <Row className="justify-content-center mb-5">
            <Col className="col-auto">
              <Button className="ride-button--dark__outline" data-testid="cancel" onClick={onHide}>
                {toggleDeleteIcon ? t("generic:retain") : t("generic:cancel")}
              </Button>
            </Col>

            <Col className="col-auto">
              <Button
                className="ride-button--danger__outline"
                data-testid="confirm"
                onClick={confirmDeletion}>
                <IoIosClose size="25" className={"ride-button--cross-icon"} /> {t("generic:delete")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation(["generic"])(DeletePrompt);
