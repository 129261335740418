import React, { useState } from "react";
import NodeTree from "../../../../sharedComponents/NodeTree/NodeTree";
import {
  LocalStorageNode,
  RemoteStorageNode,
  toRemoteStorageNode
} from "../../../../lib/dataAdapters/toRemoteStorageNode";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { DeleteModal } from "../../../../components/generic/DeletePrompt";
import { StorageNodesEntityType } from "global-query-types";

export interface NodeTreeCardProps extends WithTranslation {
  localNodes: LocalStorageNode[];
  parentId: string;
  title: string;
  entityType: StorageNodesEntityType;
  entityId: string;
  "data-testid"?: string;
  onDelete?: (node: RemoteStorageNode) => Promise<void>;
  refetch?: () => Promise<void>;
  readOnly?: boolean;
}

const NodeTreeCard = (props: NodeTreeCardProps) => {
  const {
    title,
    localNodes,
    parentId,
    onDelete,
    entityType,
    entityId,
    refetch,
    t,
    "data-testid": testId = "file-tree-card",
    readOnly
  } = props;

  const [nodeToDelete, setNodeToDelete] = useState<RemoteStorageNode | null>(null);

  let nodes: RemoteStorageNode[] =
    localNodes.map((node: LocalStorageNode) => toRemoteStorageNode(node, parentId)) ?? [];

  const onDeleteNode = async (node: RemoteStorageNode) => {
    setNodeToDelete(node);
  };

  const handleDeleteConfirmation = async () => {
    if (!nodeToDelete) {
      return;
    }

    await onDelete?.(nodeToDelete);
    await hideDeleteModal();
  };

  const hideDeleteModal = async () => {
    setNodeToDelete(null);
  };

  return (
    <RideSectionCard data-testid={testId} title={title} className="shadow-none node-tree-card">
      <NodeTree
        onDelete={onDeleteNode}
        nodes={nodes}
        entityType={entityType}
        entityId={entityId}
        refetch={refetch}
        readOnly={readOnly}
      />
      {nodeToDelete && (
        <DeleteModal
          title={""}
          nodeId={nodeToDelete.nodeId}
          toggleDeleteIcon={true}
          fileName={nodeToDelete.name ?? ""}
          onHide={hideDeleteModal}
          confirmDeletion={handleDeleteConfirmation}
          show={!!nodeToDelete}
          t={t}
        />
      )}
    </RideSectionCard>
  );
};

export default withTranslationReady("client")(NodeTreeCard);
