import React from "react";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";
import { LegalPersonType } from "global-query-types";
import { Shareholder } from "lib/models/client/CompanyFounding/Shareholder";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { companyName } from "lib/formatters/companyFormatter";

export interface ReviewOwnershipDataProps {
  shareholders: Shareholder[];
  editLink: string;
  className?: string;
}

const formatShareholderName = (shareholder: Shareholder): string => {
  return shareholder.shareholderType === LegalPersonType.Person
    ? personNameWithFallback(shareholder.personData)
    : companyName({
        name: shareholder.companyData?.companyName,
        legalForm: shareholder.companyData?.legalForm
      });
};

export const ReviewOwnershipData = ({
  shareholders,
  editLink,
  className = ""
}: ReviewOwnershipDataProps) => {
  return (
    <CardContainer className={className}>
      <ReviewEntry
        name="ownership-distribution"
        editLink={editLink}
        list={shareholders.map((shareholder, index) => (
          <React.Fragment key={index}>
            {formatShareholderName(shareholder)}
            <div className={"ownership-share"}>{shareholder.shares}%</div>
          </React.Fragment>
        ))}
      />
    </CardContainer>
  );
};
