import React, { useState } from "react";
import { ClickableElement } from "../ClickableElement/ClickableElement";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { RideTableCellAlignment } from "../RideTableCell/RideTableCell";
import { SortDirection } from "../useSimpleSortBehaviour/SortDirection";
import { RideHeaderColumn } from "../../lib/types/types";

interface RideTableHeaderProps {
  columns: RideHeaderColumn[];
  sortCallback?: (column: RideHeaderColumn, sortDirection?: SortDirection) => void;
  title?: string;
  isColumnsDisplay?: boolean;
  headerAction?: JSX.Element;
}

const RideTableHeaderColumn = ({
  column,
  onChangeColumnSorting,
  direction,
  sortingEnabled
}: {
  sortingEnabled: boolean;
  column: RideHeaderColumn;
  direction: {
    direction: SortDirection;
    column: RideHeaderColumn | null;
  };
  onChangeColumnSorting: (column: RideHeaderColumn, sortDirection: SortDirection) => void;
}) => {
  const buttonClickHandler = (newDirection) => {
    if (!onChangeColumnSorting) return;
    onChangeColumnSorting(column, newDirection);
  };

  return (
    <div
      data-testid="ride-table-header-column-label"
      className={`ride-table-header__column-label ride-table-header__column-label--aligned-${
        column.alignment ?? RideTableCellAlignment.LEFT
      }`}>
      <div
        data-testid={`table-column-label-${column.label}`}
        className="ride-table-header-column__label-text">
        {column.label}
      </div>
      {sortingEnabled && (
        <div data-testid="ride-table-header-sort" className="ride-table-header__sort">
          <ClickableElement onClick={() => buttonClickHandler(SortDirection.ASCENDING)}>
            <AiOutlineArrowUp
              data-testid="ride-table-header-sort-up"
              className={`
                ride-table-header__sort-icon ${
                  direction.direction === SortDirection.ASCENDING &&
                  direction.column?.name === column.name
                    ? "ride-table-header__sort-icon--active"
                    : ""
                }
              `}
            />
          </ClickableElement>
          <ClickableElement onClick={() => buttonClickHandler(SortDirection.DESCENDING)}>
            <AiOutlineArrowDown
              data-testid="ride-table-header-sort-down"
              className={`
                ride-table-header__sort-icon ${
                  direction.direction === SortDirection.DESCENDING &&
                  direction.column?.name === column.name
                    ? "ride-table-header__sort-icon--active"
                    : ""
                }
              `}
            />
          </ClickableElement>
        </div>
      )}
    </div>
  );
};

export const RideTableHeader = ({
  columns,
  sortCallback,
  title,
  isColumnsDisplay = true,
  headerAction
}: RideTableHeaderProps) => {
  const [direction, setDirection] = useState<{
    direction: SortDirection;
    column: RideHeaderColumn | null;
  }>({ column: null, direction: SortDirection.INACTIVE });

  const changeColumnDirectionHandler = (column, direction) => {
    setDirection({
      column: column,
      direction: direction
    });

    if (sortCallback) {
      sortCallback(column, direction);
    }
  };

  return (
    <div data-testid="ride-table-header" className="ride-table-header">
      {title && (
        <div data-testid="ride-table-header-title" className="ride-table-header__title">
          <div
            data-testid="ride-table-header-title__text"
            className="ride-table-header__title-text">
            {title}
          </div>
          {headerAction && (
            <div
              data-testid="ride-table-header-title__action"
              className="ride-table-header__title-action">
              {headerAction}
            </div>
          )}
        </div>
      )}
      {isColumnsDisplay && (
        <div
          data-testid="ride-table-header-columns"
          className="ride-table-header__columns"
          data-columns={columns.length}>
          {columns.map((column, index) => (
            <RideTableHeaderColumn
              sortingEnabled={!!sortCallback}
              onChangeColumnSorting={changeColumnDirectionHandler}
              direction={direction}
              key={index}
              column={column}
            />
          ))}
        </div>
      )}
    </div>
  );
};
