import React, { useState } from "react";
import { RideRadioButton } from "../RideRadioButton/RideRadioButton";
import { ErrorMessageWithT } from "../../components/generic/ErrorMessage";

export interface LabeledRadioButtonProps {
  text: string;
  options: { id: string; label: string }[];
  onChange?: (id: string | number) => void;
  name?: string;
  initialValue?: string | null;
  className?: string;
  hasFormik?: boolean;
}

const LabeledRadioButton = ({
  text,
  name,
  options,
  onChange,
  className = "",
  initialValue = null,
  hasFormik = false
}: LabeledRadioButtonProps) => {
  const defaultRadioValue = (): string | number | null => {
    if (initialValue !== null && initialValue !== undefined) return initialValue;
    if (options.length === 0) return null;
    return options[0].id;
  };

  const [selectedRadio, setSelectedRadio] = useState<null | string | number>(defaultRadioValue());

  const callback = (id: string | number) => {
    onChange?.(id);
    setSelectedRadio(id);
  };

  return (
    <div
      data-testid={name ? `${name}-labeled-radio-button` : "labeled-radio-button"}
      className={`labeled-radio-button ${className}`}>
      <div data-testid="labeled-radio-button-text" className="labeled-radio-button-text">
        {text}
      </div>
      <div
        data-testid="labeled-radio-button-radios-container"
        className="labeled-radio-button-radios-container">
        {options.length > 0 &&
          options.map((radio, index) => {
            return (
              <div className="labeled-radio-button-radio-item" key={index}>
                <RideRadioButton
                  name={radio.id}
                  label={radio.label}
                  selected={selectedRadio === radio.id}
                  key={`${index}${radio.id}`}
                  id={radio.id}
                  callback={callback}
                />
              </div>
            );
          })}
      </div>
      {name && hasFormik && <ErrorMessageWithT name={name} extra={{}} />}
    </div>
  );
};

export default LabeledRadioButton;
