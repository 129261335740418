import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  RealEstateLabelField,
  RealEstateMyShareField,
  RealEstatePropertyValueField
} from "../generic/real-estate-form/fields";
import OwnershipField from "./Ownership/OwnershipField";
import { FormikProps } from "formik";

export const RealEstateFormFields = ({ t, formik }) => (
  <>
    <Row className="show-grid">
      <Col xs={6} xl={4}>
        <RealEstateLabelField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={4}>
        <RealEstatePropertyValueField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={4}>
        <RealEstateMyShareField t={t} formik={formik} />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12}>
        <OwnershipField
          currentCompanyId={null}
          formik={formik as FormikProps<{ ownerId: string }>}
        />
      </Col>
    </Row>
  </>
);
