import React from "react";
import { useMutation } from "react-apollo";
import {
  CreateRealEstateMutation,
  CreateRealEstateMutationVariables
} from "../../../../Investments/graphql/CreateRealEstateMutation";
import logger from "../../../../../common/Logger";
import { REAL_ESTATE_QUERY } from "../../../../Investments/Queries";
import { GetMe } from "lib/api/queries/MeQuery";
import { CREATE_REAL_ESTATE_MUTATION } from "lib/api/mutations/CREATE_REAL_ESTATE_MUTATION";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import NomineeRealEstateForm from "./NomineeRealEstateForm";

export const NomineeRealEstateFormContainer = ({ hideForm }) => {
  const [callCreateRealEstateMutation, createRealEstateMutationRequest] = useMutation<
    CreateRealEstateMutation,
    CreateRealEstateMutationVariables
  >(CREATE_REAL_ESTATE_MUTATION, { refetchQueries: [{ query: REAL_ESTATE_QUERY }] });

  const userId = GetMe().data!.me!.person!.user!.id;

  const submitHandler = async (values) => {
    try {
      await callCreateRealEstateMutation({
        variables: {
          label: values.label,
          propertyValue: values.propertyValue,
          shares: values.percentageOwnership,
          ownerCompanyId: values.ownerId,
          ownerUserId: userId
        } as CreateRealEstateMutationVariables
      });
      setTimeout(hideForm, 300);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <RequestFeedbackHandler requests={[createRealEstateMutationRequest]}>
      <NomineeRealEstateForm submitHandler={submitHandler} />
    </RequestFeedbackHandler>
  );
};
