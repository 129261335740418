import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import { useHistory } from "react-router";
import { CompanyEditDateFieldForm } from "../shared/CompanyEditDateFieldForm";

interface CompanyCommercialRegisterDateEditProps extends WithTranslation {
  value: string;
  companyId: string;
  fieldName: string;
  section: string;
  onSave?: (value: string) => Promise<void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
}

export const CompanyDateEdit = ({
  t,
  onSave,
  value,
  companyId,
  requests,
  fieldName,
  section
}: CompanyCommercialRegisterDateEditProps) => {
  const history = useHistory();

  const handleSave = async (value) => {
    await onSave?.(value);
    history.push(`/client/entities/${companyId}/general-info#${section}`);
  };

  return (
    <CompanyEditDateFieldForm
      t={t}
      value={value}
      fieldName={fieldName}
      requests={requests}
      onSave={handleSave}
    />
  );
};

export default withTranslation(["generic", "client"])(CompanyDateEdit);
