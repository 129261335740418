import gql from "graphql-tag";

export const UPSERT_BROKERAGE_ACCOUNT = gql`
  mutation UpsertBrokerageAccount(
    $companyId: ID!
    $brokerBank: BrokerBankEnum!
    $brokerName: BrokerNameEnum!
    $token: String
    $queryId: String
    $brokerageAccountId: ID
    $accountNumber: String
  ) {
    upsertBrokerageAccount(
      companyId: $companyId
      brokerBank: $brokerBank
      brokerName: $brokerName
      token: $token
      queryId: $queryId
      brokerageAccountId: $brokerageAccountId
      accountNumber: $accountNumber
    ) {
      feedback
    }
  }
`;

export const DELETE_BROKERAGE_ACCOUNT = gql`
  mutation deleteBrokerageAccount($companyId: ID!, $brokerageAccountId: ID!) {
    deleteBrokerageAccount(companyId: $companyId, brokerageAccountId: $brokerageAccountId) {
      feedback
    }
  }
`;
