import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalLayout,
  ModalLayoutVariant
} from "../../../sharedComponents/templates/ModalLayout/ModalLayout";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import SelectGmbhContainer from "../../components/Banking/SelectGmbh/SelectGmbhContainer";
import { FeatureFlags } from "global-query-types";

const SelectBankAccountGmbHScene = () => {
  const history = useHistory();

  const handleClose = () => {
    history.replace(`/client/banking`);
  };

  return (
    <FeatureFlag name={FeatureFlags.BankAccountsList}>
      <ModalLayout onClose={handleClose} onBack={history.goBack} variant={ModalLayoutVariant.light}>
        <SelectGmbhContainer />
      </ModalLayout>
    </FeatureFlag>
  );
};

export default SelectBankAccountGmbHScene;
