import * as Yup from "yup";

/**
 * X = Standard digit in tax number
 * Tax number with or without slash
 * First segment has to be from 2 to 4 digits, an optional slash could be at the end (XXFF)
 * Second segment has to be from 3 to 4 digits, a mandatory slash at the end (XBBB|BBBB)
 * Third segment has to be from 4 to 5 digits including P as check (UUUUP|UUUP)
 * @link https://de.wikipedia.org/wiki/Steuernummer
 */
const REGEX_TAX = /(^\d{2,4}\/?\d{3,4}\/\d{4,5}$)|(^\d{9,13}$)/g;

export const TaxNumber = Yup.string().required("generic:validation-required").matches(REGEX_TAX);

/// Steuernummer (tax number) for companies
export const taxNumberValidationWithTranslation = (t) =>
  Yup.string()
    .required(t("generic:validation-required"))
    .matches(REGEX_TAX, t("generic:invalid-taxNumber"));

export const taxNumberValidationNonRequired = Yup.string().matches(REGEX_TAX);

export const TaxNumberSchema = Yup.object().shape({
  taxNumber: TaxNumber
});

export default TaxNumberSchema;
