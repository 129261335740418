import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { PaymentMethod } from "lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { Trans, WithTranslation } from "react-i18next";
import { ActionableTask, ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import { TaskStatesEnum } from "global-query-types";
import { TaskContainerWithFormik } from "components/client/company/components/tasks/TaskContainer/TaskContainerWithFormik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { shortOrderFormProductPrices } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { CompanyById_companyById } from "lib/api/queries/graphql/CompanyById";
import ChangePaymentMethodPopup from "../../../ChangePaymentMethodPopup/ChangePaymentMethodPopup";
import { ChoosePaymentMethod } from "../../../../../sharedComponents/ChoosePaymentMethod/ChoosePaymentMethod";

interface ChoosePaymentMethodTaskProps {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
  company: CompanyById_companyById;
  goToCheckoutSession: () => void;
}

const ChoosePaymentMethodTask = ({
  t,
  task,
  onChange,
  company,
  goToCheckoutSession
}: ChoosePaymentMethodTaskProps & WithTranslation) => {
  const formik = useRideFormik({
    initialValues: {
      paymentMethod: "sepa"
    },
    onSubmit: async () => {
      if (task.state === TaskStatesEnum.COMPLETED) {
        await onChange(TaskStatesEnum.TO_BE_DONE);
        return;
      }

      const selectedSEPA = formik.values["paymentMethod"] === PaymentMethod.Sepa;

      const state = selectedSEPA ? TaskStatesEnum.TO_BE_DONE : TaskStatesEnum.COMPLETED;

      await onChange(state, {
        type: "PaymentMethod",
        data: { value: formik.values["paymentMethod"] }
      });

      if (selectedSEPA) {
        goToCheckoutSession();
      }
    }
  });

  const disableChangeState =
    task.state === TaskStatesEnum.COMPLETED && task.options?.data?.value === PaymentMethod.Sepa;

  const iban = company.bankAccounts?.[0]?.iban ?? "";

  const TaskCompletedView = (
    <>
      <div data-testid={"selected-payment-method"}>
        <Trans
          i18nKey="company-founding-steps:tasks.choosePaymentMethod.you-selected"
          values={{
            paymentMethod: t(
              `generic:payment-method-card.method-choice.${
                task.options?.data?.value === PaymentMethod.Sepa ? "sepa" : "manual"
              }.name`
            )
          }}
        />
      </div>
      {task.options?.data.value === PaymentMethod.Sepa && <ChangePaymentMethodPopup />}
    </>
  );

  const TaskToBeDoneView = (
    <ChoosePaymentMethod
      formik={formik}
      price={shortOrderFormProductPrices.gmbhFounding}
      iban={iban}
      companyName={company.name}
    />
  );

  return (
    <TaskContainerWithFormik
      formik={formik}
      testid="choose-payment-method"
      translationKey={task.ref}
      disabled={disableChangeState}
      task={task}>
      {task.state === TaskStatesEnum.COMPLETED ? TaskCompletedView : TaskToBeDoneView}
    </TaskContainerWithFormik>
  );
};

export default withTranslationReady(["generic", "company-founding-steps"])(ChoosePaymentMethodTask);
