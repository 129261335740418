import React from "react";
import { GetMyGmbHs } from "components/client/MyGmbHs/Queries";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { useParams } from "react-router";
import CompanyDropdown, { CompanyDropdownItemData } from "./CompanyDropdown";
import { GmbH } from "lib/models/client/GmbH";
import { getCompanyNameOrDefault } from "lib/formatters/companyFormatter";

interface CompanyDropdownContainerProps {
  openTheAddCompanyModal: () => void;
}

const CompanyDropdownContainer = ({ openTheAddCompanyModal }: CompanyDropdownContainerProps) => {
  const { id } = useParams<{ id?: string }>();

  const myGmbHsRequest = GetMyGmbHs();

  const companies = myGmbHsRequest.data?.companies;
  const userCompanies: CompanyDropdownItemData[] = (companies ?? []).map((c) => {
    let gmbh = new GmbH(c);
    return {
      id: c.id,
      name: getCompanyNameOrDefault(c.name),
      status: gmbh.grundungServiceStatus,
      selected: c.id === id,
      orders: c.orders
    };
  });
  return (
    <RequestFeedbackHandler requests={[myGmbHsRequest]} noLoadingAnimation={true}>
      <CompanyDropdown companies={userCompanies} openTheAddCompanyModal={openTheAddCompanyModal} />
    </RequestFeedbackHandler>
  );
};

export default CompanyDropdownContainer;
