import {
  BrokerageAccountValidityEnum,
  BrokerNameEnum,
  NetLiquidationValueTag,
  NetLiquidationValueType
} from "global-query-types";
import { formatCurrency } from "common/formatters";
import React from "react";

export interface BrokerageAccount {
  id: string;
  brokerName: BrokerNameEnum | null;
  validity: BrokerageAccountValidityEnum | null;
  errorCodes: any | null;
}

export interface NetLiquidationValue {
  id: string;
  type: NetLiquidationValueType;
  year: number;
  quarter: number;
  tag: NetLiquidationValueTag | null;
  valueCents: number;
  valueCurrency: string;
}

const BAD_TOKEN_STATUSES: BrokerageAccountValidityEnum[] = [
  BrokerageAccountValidityEnum.ReportInvalid,
  BrokerageAccountValidityEnum.Expired,
  BrokerageAccountValidityEnum.Invalid
];

interface BrokerageAccountStatusPillProps {
  account: BrokerageAccount;
}

export const BrokerageAccountStatusPill = ({ account }: BrokerageAccountStatusPillProps) => {
  const validity = account.validity ?? BrokerageAccountValidityEnum.New;
  let badgeClass = "badge-secondary";
  if (validity === BrokerageAccountValidityEnum.Valid) {
    badgeClass = "badge-success";
  }
  if (BAD_TOKEN_STATUSES.includes(validity)) {
    badgeClass = "badge-danger";
  }

  const errorCodes = account.errorCodes ?? [];

  return (
    <div
      className={`badge badge-pill ${badgeClass} tax-advisor-section__brokerage-accounts-pill`}
      data-testid="brokerage-account-pill">
      {account.brokerName}:{validity}
      {errorCodes.map((errorCode) => {
        return <div key={errorCode}>{errorCode}</div>;
      })}
    </div>
  );
};

interface NLVProps {
  nlvs?: NetLiquidationValue[] | null;
  tag: NetLiquidationValueTag;
}

export const NLV = ({ nlvs, tag }: NLVProps) => {
  const nlv = (nlvs ?? []).find((nlv) => nlv.tag === tag);
  if (!nlv) return <>---</>;

  return (
    <span>
      {nlv.year} Q{nlv.quarter}:{" "}
      {formatCurrency(nlv.valueCents / 100, { currency: nlv.valueCurrency })}
    </span>
  );
};
