import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { RegistryCourtField } from "../../../../../generic/company-form/fields";
import { CompanyRegistrationField } from "../../../../../generic/commonFields";

export interface IProps {
  t: TFunction;
  formik;
  className?: string;
  inputClassName?: string;
}

export const CompanyLegalRegistration = ({ t, formik, className, inputClassName }: IProps) => {
  return (
    <Row className={className}>
      <Col xs={6}>
        <RegistryCourtField t={t} formik={formik} />
      </Col>
      <Col xs={6}>
        <CompanyRegistrationField t={t} formik={formik} className={inputClassName} />
      </Col>
    </Row>
  );
};
