import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import CompanyAssetsTile from "../../../../../sharedComponents/Tiles/CompanyAssetsTile/CompanyAssetsTile";
import { InvalidFeedback } from "../../../../../uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import { CompanyAssets } from "../../../../../global-query-types";

export interface CompanyAssetsChoiceProps extends WithTranslation {
  onSave: (selected: CompanyAssets[]) => void;
  selectedAssets: CompanyAssets[];
}

type OptionStates = {
  [key in CompanyAssets]: boolean;
};

const CompanyAssetsChoice = ({ t, onSave, selectedAssets }: CompanyAssetsChoiceProps) => {
  const evalTileInitialState = (asset: CompanyAssets): boolean => {
    return selectedAssets.indexOf(asset) !== -1;
  };

  const [optionsStates, setOptionsStates] = useState<OptionStates>({
    [CompanyAssets.REAL_ESTATE]: evalTileInitialState(CompanyAssets.REAL_ESTATE),
    [CompanyAssets.DERIVATIVES]: evalTileInitialState(CompanyAssets.DERIVATIVES),
    [CompanyAssets.HOLDING]: evalTileInitialState(CompanyAssets.HOLDING),
    [CompanyAssets.SHARES_AND_FUNDS]: evalTileInitialState(CompanyAssets.SHARES_AND_FUNDS)
  });
  const [validationErrorHidden, setValidationErrorHidden] = useState<boolean>(true);

  const handleSelect = (option: CompanyAssets, state: boolean) => {
    setOptionsStates({ ...optionsStates, [option]: state });

    if (state) {
      setValidationErrorHidden(true);
    }
  };

  const handleSave = () => {
    const selectedOptions: CompanyAssets[] = [];

    for (const option in optionsStates) {
      if (optionsStates[option]) {
        selectedOptions.push(option as CompanyAssets);
      }
    }

    if (selectedOptions.length) {
      onSave(selectedOptions);
    } else {
      setValidationErrorHidden(false);
    }
  };

  return (
    <div className="company_assets_choice">
      <div className="company_assets_choice__description" data-testid="step-description">
        <div className="steps-form-description">{t("trader-gmbh:assets-choice-description-1")}</div>
        <div className="steps-form-description">{t("trader-gmbh:assets-choice-description-2")}</div>
      </div>

      <div className="company_assets_choice__card">
        <div className="company_assets_choice__list">
          <span className="company_assets_choice__list__item">
            <CompanyAssetsTile
              option={CompanyAssets.REAL_ESTATE}
              onClick={handleSelect}
              initialState={optionsStates[CompanyAssets.REAL_ESTATE]}
            />
          </span>
          <span className="company_assets_choice__list__item">
            <CompanyAssetsTile
              option={CompanyAssets.HOLDING}
              onClick={handleSelect}
              initialState={optionsStates[CompanyAssets.HOLDING]}
            />
          </span>
          <span className="company_assets_choice__list__item">
            <CompanyAssetsTile
              option={CompanyAssets.SHARES_AND_FUNDS}
              onClick={handleSelect}
              initialState={optionsStates[CompanyAssets.SHARES_AND_FUNDS]}
            />
          </span>
          <span className="company_assets_choice__list__item">
            <CompanyAssetsTile
              option={CompanyAssets.DERIVATIVES}
              onClick={handleSelect}
              initialState={optionsStates[CompanyAssets.DERIVATIVES]}
            />
          </span>
        </div>

        <div className="company_assets_choice__validation-message">
          <InvalidFeedback hidden={validationErrorHidden} testPrefix="assets-choice">
            {t("generic:validation-required")}
          </InvalidFeedback>
        </div>

        <div className="company_assets_choice__save">
          <Button
            data-testid="save-button"
            onClick={handleSave}
            className="step-submit-btn"
            variant="outline-light">
            {t("generic:save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic", "trader-gmbh"])(CompanyAssetsChoice);
