import { LegalForm } from "global-query-types";

export const supportedLegalForms = {
  GmbH: "GmbH",
  UG: "UG",
  // eslint-disable-next-line
  ["UG (haftungsbeschränkt)"]: "UG",
  // eslint-disable-next-line
  ["GmbH & Co. KG"]: "GmbH_Co_KG",
  MbH: "MbH",
  KG: "KG",
  AG: "AG",
  EG: "EG",
  Stiftung: "Stiftung",
  PartG: "PartG",
  // eslint-disable-next-line
  ["PartG mbB"]: "PartGmbB"
};

export const parseFullCompanyName = (fullName: string) => {
  const cleanName = fullName.trim().replaceAll(/\s{2,}/g, " ");

  for (const suffix of Object.keys(supportedLegalForms)) {
    const legalForm = supportedLegalForms[suffix];

    if (cleanName.toLocaleLowerCase().endsWith(suffix.toLowerCase())) {
      const index = cleanName.toLocaleLowerCase().lastIndexOf(suffix.toLowerCase());
      return {
        name: cleanName.substring(0, index).trim(),
        legalForm
      };
    }
  }

  return {
    name: cleanName,
    legalForm: "GmbH"
  };
};

export const removeLegalForm = (fullName: string, legalForm: string) => {
  const cleanName = fullName.trim().replaceAll(/\s{2,}/g, " ");

  if (cleanName.toLocaleLowerCase().endsWith(legalForm.toLowerCase())) {
    const index = cleanName.toLocaleLowerCase().lastIndexOf(legalForm.toLowerCase());
    return cleanName.substring(0, index).trim();
  }

  return cleanName;
};

export const getLegalFormValue = (legalForm: string): LegalForm | undefined => {
  for (const [supportedLegalForm, value] of Object.entries(supportedLegalForms)) {
    if (legalForm.toLowerCase() === supportedLegalForm.toLowerCase()) {
      return value as LegalForm;
    }
  }
  return undefined;
};
