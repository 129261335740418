import React, { useEffect, useRef, useState } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import CodeInput from "../CodeInput/CodeInput";
import TextLinkAsButton from "../../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { Panel } from "../../../sharedComponents/Panel/Panel";
import { useLocation } from "react-router";
import logger from "../../../common/Logger";
import { FormikProvider, Form, useFormik } from "formik";
import { ErrorAlert } from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import moment from "moment";

interface LoginCodeFormProps extends WithTranslation {
  sendVerificationCode: () => Promise<Date | undefined | null>;
  onVerifyClick: (code: string) => Promise<boolean | undefined | null>;
  nextSmsRequestAvailableAt?: Date;
}

const LoginCodeForm = ({
  t,
  onVerifyClick,
  sendVerificationCode,
  nextSmsRequestAvailableAt
}: LoginCodeFormProps) => {
  const calculateTimeLeftInSeconds = () => {
    if (!smsRequestAvailableAt) {
      return 0;
    }

    const now = moment.now();
    let result = Math.round((smsRequestAvailableAt.getTime() - now) / 1000);

    return result > 0 ? result : 0;
  };

  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [smsRequestAvailableAt, setSmsRequestAvailableAt] = useState(
    nextSmsRequestAvailableAt ?? moment().add(60, "second").toDate()
  );
  const [timeLeft, setTimeLeft] = useState(
    nextSmsRequestAvailableAt ? calculateTimeLeftInSeconds() : 60
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeftInSeconds());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState = (setState: () => void) => {
    if (mountedRef.current) {
      setState();
    }
  };

  const formik = useFormik({
    initialValues: {
      code: ["", "", "", "", "", ""]
    },
    onSubmit: async ({ code }) => {
      safeSetState(() => setIsLoading(true));

      const verificationCode = code.join("");

      if (verificationCode.length !== 6) {
        safeSetState(() => setSubmitError(t("auth:login-code.invalid-code")));
        safeSetState(() => setIsLoading(false));
        return;
      }
      const result = await onVerifyClick(verificationCode);
      safeSetState(() => setIsLoading(false));

      if (result === false) {
        safeSetState(() => setSubmitError(t("auth:login-code.verify-error")));
        return;
      }
    },
    validateOnChange: true
  });

  const onChange = (value, index) => {
    safeSetState(() => setSubmitError(null));
    const values = formik.values.code;
    values[index] = value;
    formik.setValues({ code: values });
  };
  const onClickReSendCode = async () => {
    const nextSmsRequestAvailableAt = await sendVerificationCode();
    safeSetState(() =>
      setSmsRequestAvailableAt(nextSmsRequestAvailableAt ?? moment().add(60, "second").toDate())
    );
  };

  const location = useLocation<{ censoredPhoneNumber: string }>();
  const censoredPhoneNumber = location.state?.censoredPhoneNumber;

  if (!censoredPhoneNumber) {
    logger.errorMessage("No phone number found for user");
    return null;
  }

  return (
    <div data-testid="login-code-form" className="loginCodeForm">
      <Panel>
        <FormikProvider value={formik}>
          <Form>
            <div className="loginCodeForm__panel-content">
              {submitError !== null && <ErrorAlert error={submitError} t={t} />}
              <div data-testid="login-code-form-description" className="loginCodeForm__description">
                <Trans
                  i18nKey={"auth:login-code.description"}
                  values={{ phoneNumber: censoredPhoneNumber }}
                />
              </div>
              <div data-testid="login-code-form-input-label" className="loginCodeForm__inputLabel">
                {t("auth:login-code.input-label")}
              </div>
              <CodeInput
                inputChange={onChange}
                data-testid="login-code-form-code-input"
                className="loginCodeForm__codeInput"
                isCodeValid={!submitError}
              />
              {timeLeft <= 0 ? (
                <TextLinkAsButton
                  data-testid="login-code-form-re-send-code"
                  onClick={onClickReSendCode}
                  className="loginCodeForm__reSendCodeLink">
                  {t("auth:login-code:re-send-code")}
                </TextLinkAsButton>
              ) : (
                <div data-testid="login-code-form-countdown" className="loginCodeForm__countdown">
                  <Trans
                    i18nKey={"auth:login-code.countdown-text"}
                    values={{ remainingTime: timeLeft }}
                  />
                </div>
              )}
              <div className="loginCodeForm__continueButton">
                <RideButtonDeprecated
                  data-testid="login-code-continue-button"
                  variant={ButtonVariant.Primary}
                  isLoading={isLoading}
                  type="submit">
                  {t("auth:login-code:continue-button")}
                </RideButtonDeprecated>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Panel>
    </div>
  );
};

export default withTranslationReady(["auth"])(LoginCodeForm);
