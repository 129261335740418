import React, { useState } from "react";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import {
  StorageNodesEntityType,
  StorageNodeType,
  TaskStatesEnum
} from "../../../../../global-query-types";
import TaskContainer from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainer";
import UploadToFolder from "../../../../../sharedComponents/UploadToFolder/UploadToFolder";
import { FileUploadService } from "../../../../../lib/services/FileUploadService/FileUploadService";
import { FileUploadedContainer } from "../../../../../sharedComponents/FileUploaded/FileUploadedContainer";
import { RemoteStorageNode } from "../../../../../lib/dataAdapters/toRemoteStorageNode";

interface UploadProofCapitalPaymentProps {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
  onCloseModal: () => void;
}

const UploadAccountStatementTask = ({
  task,
  onChange,
  onCloseModal
}: UploadProofCapitalPaymentProps) => {
  const toFileLocalStorageNode = (optionsData): RemoteStorageNode => ({
    name: optionsData.uploadedFileName,
    nodeId: optionsData.uploadedFileNodeId,
    type: StorageNodeType.FILE,
    childNodes: [],
    parentId: optionsData.destinationNodeId
  });

  const [uploadedStorageNode, setUploadedStorageNode] = useState<RemoteStorageNode | null>(
    task.options?.data.uploadedFileName ? toFileLocalStorageNode(task.options?.data) : null
  );
  const uploadService = FileUploadService.getInstance();

  const onUploaded = async (storageNode: RemoteStorageNode) => {
    await onChange(TaskStatesEnum.TO_BE_DONE, {
      type: "CompanyCreationUpload",
      data: {
        ...task.options?.data,
        uploadedFileName: storageNode.name ?? "",
        uploadedFileNodeId: storageNode.nodeId ?? ""
      }
    });
    setUploadedStorageNode(storageNode);
  };

  const onDelete = async () => {
    await onChange(TaskStatesEnum.TO_BE_DONE, {
      type: "CompanyCreationUpload",
      data: {
        ...task.options?.data,
        uploadedFileName: "",
        uploadedFileNodeId: ""
      }
    });
    setUploadedStorageNode(null);
    if (task.state === TaskStatesEnum.COMPLETED) onCloseModal();
  };

  const handleStateChange = async (state) => {
    onCloseModal();
    await onChange(state);
  };

  const entityId = task.options?.data.companyId ?? "";
  const entityType = StorageNodesEntityType.Company;
  const destinationNodeId = task.options?.data.destinationNodeId ?? "";

  return (
    <TaskContainer
      testid="uploadAccountStatement"
      translationKey={task.ref}
      task={task}
      onChange={handleStateChange}
      iconPath="/assets/client/upload.svg"
      disabled={!uploadedStorageNode}
      useLargeIcon>
      {!uploadedStorageNode && task.state === TaskStatesEnum.TO_BE_DONE && (
        <UploadToFolder
          entityId={entityId}
          entityType={entityType}
          destinationNodeId={destinationNodeId}
          uploadService={uploadService}
          onUploaded={onUploaded}
        />
      )}

      {uploadedStorageNode && (
        <FileUploadedContainer
          folderId={destinationNodeId}
          fileId={uploadedStorageNode.nodeId ?? ""}
          name={uploadedStorageNode.name ?? ""}
          entityId={entityId}
          entityType={entityType}
          onDelete={onDelete}
        />
      )}
    </TaskContainer>
  );
};

export default UploadAccountStatementTask;
