import React from "react";
import UploadDocumentRow from "./UploadDocumentRow";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { StorageConfigStructure } from "./GetStorageConfig";
import { FileUploadService } from "lib/services/FileUploadService/FileUploadService";

export const UploadDocumentRowContainer = ({
  gmbh,
  storageConfig
}: {
  gmbh: GmbHDetails;
  storageConfig: StorageConfigStructure[];
}) => {
  const uploadService = FileUploadService.getInstance();

  return (
    <UploadDocumentRow
      gmbh={gmbh}
      storageConfig={storageConfig}
      externalStorageNodes={gmbh.storage}
      uploadService={uploadService}
    />
  );
};
