import React from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import ThirdPartyTaxAdvisorClientsWrapper from "./ThirdPartyTaxAdvisorClientsWrapper";
import { GetMyTaxAdvisorCompanyWithClientsQuery } from "../../../taxAdvisor/Queries";
import { CompanyType } from "../../../global-query-types";

export const ThirdPartyTaxAdvisorClientsContainer = () => {
  const getRequest = GetMyTaxAdvisorCompanyWithClientsQuery(CompanyType.ThirdPartyTaxService);
  const myTaxAdvisorCompanyWithClients = getRequest.data?.getMyTaxAdvisorCompanyWithClients;
  const taxServiceProvided = myTaxAdvisorCompanyWithClients?.taxServicesProvided;

  return (
    <RequestFeedbackHandler requests={[getRequest]} noLoadingAnimation={true}>
      <ThirdPartyTaxAdvisorClientsWrapper
        taxServiceProvided={taxServiceProvided}
        myTaxAdvisorCompanyWithClients={myTaxAdvisorCompanyWithClients}
      />
    </RequestFeedbackHandler>
  );
};
