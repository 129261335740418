import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import OnboardingStepCard, {
  EditableOnboardingStepCardProps,
  OnboardingStepCardStatus
} from "../../OnboardingStepCard/OnboardingStepCard";
import { WithTranslation } from "react-i18next";
import { OrderCostSummary } from "../../UnifiedShortOrderForm/CheckoutStep/OrderCostSummary/OrderCostSummary";
import {
  AdminServiceItem,
  OrderSummary
} from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { PaymentMethodType } from "payments-query-types";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

export interface ReviewStepProps extends EditableOnboardingStepCardProps {
  iban: string | null | undefined;
  status: OnboardingStepCardStatus;
  tier: AdminServiceTier | undefined;
  companyName: string;
  paymentMethod?: PaymentMethodType;
  startingFiscalYear?: number;
  hasCompanyFoundingService: boolean;
  onSubmit: () => Promise<void>;
}

const ReviewStep = ({
  t,
  status,
  iban,
  companyName,
  forceOpening,
  toggleEditing,
  paymentMethod,
  tier,
  startingFiscalYear,
  hasCompanyFoundingService,
  onSubmit
}: ReviewStepProps & WithTranslation) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submit = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  };

  const className = "admin-service-onboarding-review-step";

  const orderSummary = new OrderSummary([
    AdminServiceItem({
      tier: tier as any,
      startingFiscalYear,
      hasCompanyFoundingService
    })
  ]);

  return (
    <OnboardingStepCard
      data-testid={"review-step"}
      status={status}
      number={6}
      forceShowContent={forceOpening}
      toggleEditing={toggleEditing ? () => toggleEditing?.("reviewStep") : null}
      title={t("generic:review-order")}>
      <div className={className}>
        <Typography
          category={"Headline"}
          size={300}
          weight={"Heavy"}
          data-testid={"chosen-payment-method"}>
          {t("generic:your-payment-method")}:{" "}
          {t(
            `generic:payment-method-card.method-choice.${
              paymentMethod === PaymentMethodType.Sepa ? "sepa" : "manual"
            }.name`
          )}
        </Typography>

        <div className={`${className}__company-information`}>
          <div className={`${className}__company-information-field`}>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              data-testid={"company-name-label"}>
              {`${t(`generic:company-name`)}: `}
            </Typography>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Light"}
              data-testid={"company-name"}>
              {companyName}
            </Typography>
          </div>
          {iban && (
            <div className={`${className}__company-information-field`}>
              <Typography
                category={"Paragraph"}
                size={100}
                weight={"Heavy"}
                data-testid={"iban-label"}>
                {`${t(`generic:iban`)}: `}
              </Typography>
              <Typography category={"Paragraph"} size={100} weight={"Light"} data-testid={"iban"}>
                {iban}
              </Typography>
            </div>
          )}
        </div>

        <OrderCostSummary orderSummary={orderSummary} vat={19} />
      </div>

      <RideButton
        data-testid={"agree-and-pay-button"}
        label={t("generic:admin-service-tiers-onboarding.steps.review.agree-and-pay-button")}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        onClick={submit}
        disabled={isSubmitting}
      />

      <Typography
        data-testid="payment-disclaimer"
        category={"Paragraph"}
        size={200}
        weight={"Light"}
        className={`${className}__payment-disclaimer`}>
        {t("generic:payment-method-card.payment-date-info-as-onboarding")}
      </Typography>
    </OnboardingStepCard>
  );
};

export default withTranslationReady(["generic"])(ReviewStep);
