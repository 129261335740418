import { DiscountCodeStatus } from "../../../global-query-types";
import {
  AllDiscountCodesQuery_allDiscountCodes,
  AllDiscountCodesQuery_allDiscountCodes_uses
} from "../../../lib/api/queries/graphql/AllDiscountCodesQuery";
import { getMonth, getYear } from "date-fns";
import { isEqual, sumBy } from "lodash";

interface DiscountCodeUsageStatus {
  usageStatus: AllDiscountCodesQuery_allDiscountCodes_uses[];
  usageAmount: number;
}

export const consolidateReferralDataByMonth = (
  discountCodes: AllDiscountCodesQuery_allDiscountCodes[],
  month?: number,
  year?: number
) => {
  const discountCodeUsageDetails = discountCodes.map((discountCode) => {
    const discountCodeConfirmedStatus: DiscountCodeUsageStatus = {
      usageStatus:
        discountCode.uses?.filter((rec) => rec.status === DiscountCodeStatus.CONFIRMED) ?? [],
      usageAmount: discountCode.referrerValue ?? 0
    };

    if ((month || month === 0) && year) {
      const filterByMonthAndYear = { usageMonth: month, usageYear: year };

      const { usageCount, amount } = getCodeUsageByMonthAndYear(
        discountCodeConfirmedStatus,
        filterByMonthAndYear
      );

      return { ...discountCode, usageCount, amount };
    }

    const { usageCount, amount } = getCodeUsageByMonthAndYear(discountCodeConfirmedStatus);

    return { ...discountCode, usageCount, amount };
  });

  return discountCodeUsageDetails;
};

const getCodeUsageByMonthAndYear = (
  discountCodeUse: DiscountCodeUsageStatus,
  filterByMonthAndYear?: { usageMonth: number; usageYear: number }
) => {
  const codeUsage = discountCodeUse.usageStatus.map((rec) => {
    const dateObj = new Date(rec.confirmedAt);
    const usageMonth = getMonth(dateObj);
    const usageYear = getYear(dateObj);

    return { month: { usageMonth, usageYear }, amount: discountCodeUse.usageAmount! };
  });

  let usageCount = 0;
  let amount = 0;

  if (filterByMonthAndYear === undefined) {
    return {
      usageCount: codeUsage.length,
      amount: codeUsage.length > 0 ? sumBy(codeUsage, "amount") : 0
    };
  }

  for (const object of codeUsage) {
    if (isEqual(object.month, filterByMonthAndYear)) {
      usageCount++;
      amount += sumBy([object], "amount");
    }
  }

  return { usageCount, amount };
};
