import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { getLinksByCompanyId, getLinksByCompanyIdVariables } from "./graphql/getLinksByCompanyId";

export const GET_LINKS_BY_COMPANY_ID = gql`
  query getLinksByCompanyId($companyId: ID!) {
    getLinksByCompanyId(companyId: $companyId) {
      links {
        id
        link
        notaryDocumentType
      }
    }
  }
`;
export const GetLinksByCompanyId = (companyId: string) =>
  useQuery<getLinksByCompanyId, getLinksByCompanyIdVariables>(GET_LINKS_BY_COMPANY_ID, {
    variables: { companyId }
  });
