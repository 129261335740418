import gql from "graphql-tag";

export const GET_ACCOUNTING_SERVICES_BY_CLIENT = gql`
  query GetAccountingServicesByClient($clientCompanyId: ID!) {
    getAccountingServicesByClient(clientCompanyId: $clientCompanyId) {
      id
      accountant {
        id
        email
        person {
          id
          firstName
          lastName
        }
      }
      createdAt
      updatedAt
    }
  }
`;
