import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RideNavbar } from "../../../sharedComponents/RideNavbar/RideNavbar";
import { MenuLink } from "../../../sharedComponents/MenuLink/MenuLink";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavigationService from "../../../lib/services/NavigationService/NavigationService";
import { RideSidebar } from "../../../sharedComponents/RideSidebar/RideSidebar";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "../../../global-query-types";

export const AdminUrls = {
  USERS_AND_COMPANIES: "/admin/users-companies",
  CLIENT_ENTITIES: "/admin/entities",
  INVESTMENTS: "/admin/investments",
  TASKS: "/admin/client-services/tasks/",
  FOUNDATION_SERVICE: "/admin/client-services/foundation-service",
  FOUNDATION_SERVICE_TASKS: "/admin/client-services/foundation-service-tasks",
  ADMIN_SERVICE: "/admin/client-services/admin-service",
  WEPA_SERVICE: "/admin/client-services/wepa-service",
  WEPA_DIRECT: "/admin/client-services/wepa-direct/orders",
  VG_ORDERS: "/admin/client-services/shelf-companies/orders",
  LOW_TAX_BROKER_ORDERS: "/admin/client-services/low-tax-broker/orders",
  NEW_BROKER_ORDERS: "/admin/client-services/new-broker/orders",
  TRADING_TRANSACTION_HISTORY: "/admin/client-services/trading/transaction-history",
  INVESTMENT_CLUB_DEALS: "/admin/investment-club/investment-club-deals",
  INVESTMENT_CLUB_SPVS: "/admin/investment-club/investment-club-spvs",
  INVESTMENT_CLUB_ASSETS: "/admin/investment-club/investment-club-assets",
  REFERRAL_PROGRAM: "/admin/referral-program",
  ACCOUNTANTS: "/admin/partners/accountants",
  TAX_ADVISORS: "/admin/partners/tax-advisors",
  THIRD_PARTY_TAX_ADVISORS: "/admin/partners/third-party-tax-advisors",
  NOTARIES: "/admin/partners/notaries",
  INVITE: "/admin/partners/invite",
  THIRD_PARTY_TAX_ADVISORS_CUSTOMERS: "/admin/partners/third-party-tax-advisors-customers"
};

const AdminHeader = ({ t }: WithTranslation) => {
  const menu = [
    <MenuLink
      key="users-and-companies"
      eventKey={AdminUrls.USERS_AND_COMPANIES}
      path={AdminUrls.USERS_AND_COMPANIES}>
      {t("users-and-companies")}
    </MenuLink>,
    <MenuLink
      key="client-entities"
      eventKey={AdminUrls.CLIENT_ENTITIES}
      path={AdminUrls.CLIENT_ENTITIES}>
      {t("client-entities")}
    </MenuLink>,
    <MenuLink
      key="admin-investments-scene"
      eventKey={AdminUrls.INVESTMENTS}
      path={AdminUrls.INVESTMENTS}>
      {t("client-investments")}
    </MenuLink>,
    <NavDropdown
      id="client-services"
      key="client-services"
      title={t("client-services")}
      alignRight
      className="ride-navbar-dropdown">
      <NavDropdown.Item eventKey={AdminUrls.TASKS} to={AdminUrls.TASKS} as={Link}>
        {t("tasks")}
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey={AdminUrls.FOUNDATION_SERVICE}
        to={AdminUrls.FOUNDATION_SERVICE}
        as={Link}>
        {t("foundation-service")}
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey={AdminUrls.FOUNDATION_SERVICE_TASKS}
        to={AdminUrls.FOUNDATION_SERVICE_TASKS}
        as={Link}>
        {t("foundation-service-tasks")}
      </NavDropdown.Item>
      <NavDropdown.Item eventKey={AdminUrls.ADMIN_SERVICE} to={AdminUrls.ADMIN_SERVICE} as={Link}>
        {t("admin-service")}
      </NavDropdown.Item>
      <FeatureFlag name={FeatureFlags.WepaDirect}>
        <NavDropdown.Item eventKey={AdminUrls.WEPA_DIRECT} to={AdminUrls.WEPA_DIRECT} as={Link}>
          {t("wepa-direct")}
        </NavDropdown.Item>
      </FeatureFlag>
      <NavDropdown.Item eventKey={AdminUrls.WEPA_SERVICE} to={AdminUrls.WEPA_SERVICE} as={Link}>
        {t("wepa-service")}
      </NavDropdown.Item>
      <FeatureFlag name={FeatureFlags.showShelfCompanyOrders}>
        <NavDropdown.Item eventKey={AdminUrls.VG_ORDERS} to={AdminUrls.VG_ORDERS} as={Link}>
          {t("shelf-companies-orders")}
        </NavDropdown.Item>
      </FeatureFlag>
      <NavDropdown.Item
        eventKey={AdminUrls.LOW_TAX_BROKER_ORDERS}
        to={AdminUrls.LOW_TAX_BROKER_ORDERS}
        as={Link}>
        {t("low-tax-broker-orders")}
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey={AdminUrls.NEW_BROKER_ORDERS}
        to={AdminUrls.NEW_BROKER_ORDERS}
        as={Link}>
        {t("new-broker-orders")}
      </NavDropdown.Item>
      <FeatureFlag name={FeatureFlags.TradingTransactionHistory}>
        <NavDropdown.Item
          eventKey={AdminUrls.TRADING_TRANSACTION_HISTORY}
          to={AdminUrls.TRADING_TRANSACTION_HISTORY}
          as={Link}>
          {t("trading-transaction-history")}
        </NavDropdown.Item>
      </FeatureFlag>
    </NavDropdown>,
    <NavDropdown
      className="ride-navbar-dropdown"
      id="investment-club-dropdown"
      key="investment-club"
      title={t("nominee-investment-club")}
      alignRight>
      <NavDropdown.Item
        eventKey={AdminUrls.INVESTMENT_CLUB_DEALS}
        to={AdminUrls.INVESTMENT_CLUB_DEALS}
        as={Link}>
        {t("investment-club-deals")}
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey={AdminUrls.INVESTMENT_CLUB_SPVS}
        to={AdminUrls.INVESTMENT_CLUB_SPVS}
        as={Link}>
        {t("investment-club-spvs")}
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey={AdminUrls.INVESTMENT_CLUB_ASSETS}
        to={AdminUrls.INVESTMENT_CLUB_ASSETS}
        as={Link}>
        {t("investment-club-assets")}
      </NavDropdown.Item>
    </NavDropdown>,
    <MenuLink
      key="referral-program"
      eventKey={AdminUrls.REFERRAL_PROGRAM}
      path={AdminUrls.REFERRAL_PROGRAM}>
      {t("referral-program")}
    </MenuLink>,
    <NavDropdown
      id="partners"
      key="partners"
      title={t("partners")}
      alignRight
      className="ride-navbar-dropdown">
      <NavDropdown.Item eventKey={AdminUrls.TAX_ADVISORS} to={AdminUrls.TAX_ADVISORS} as={Link}>
        {t("tax-advisors")}
      </NavDropdown.Item>
      <FeatureFlag name={FeatureFlags.WP100_AccountantRole}>
        <NavDropdown.Item eventKey={AdminUrls.ACCOUNTANTS} to={AdminUrls.ACCOUNTANTS} as={Link}>
          {t("generic:accountant.accountant-list-nav-item")}
        </NavDropdown.Item>
      </FeatureFlag>
      <NavDropdown.Item
        eventKey={AdminUrls.THIRD_PARTY_TAX_ADVISORS}
        to={AdminUrls.THIRD_PARTY_TAX_ADVISORS}
        as={Link}>
        {t("third-party-tax-advisors")}
      </NavDropdown.Item>
      <NavDropdown.Item
        eventKey={AdminUrls.THIRD_PARTY_TAX_ADVISORS_CUSTOMERS}
        to={AdminUrls.THIRD_PARTY_TAX_ADVISORS_CUSTOMERS}
        as={Link}>
        {t("my-third-party-tax-advisors")}
      </NavDropdown.Item>
      <NavDropdown.Item eventKey={AdminUrls.NOTARIES} to={AdminUrls.NOTARIES} as={Link}>
        {t("notaries")}
      </NavDropdown.Item>
      <NavDropdown.Item eventKey={AdminUrls.INVITE} to={AdminUrls.INVITE} as={Link}>
        {t("invite")}
      </NavDropdown.Item>
    </NavDropdown>,
    <MenuLink key="logout" path="/logout">
      {t("profile.logout")}
    </MenuLink>
  ];

  NavigationService.instance = new NavigationService(AdminUrls);

  return (
    <RideNavbar sidebar={<RideSidebar menu={menu} activeKey="client-entities" />}>
      {menu}
    </RideNavbar>
  );
};

export default withTranslation("header")(AdminHeader);
