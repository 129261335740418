import { PrivateRoute } from "sharedComponents/PrivateRoute/PrivateRoute";
import { MeQuery } from "lib/api/queries/graphql/MeQuery";
import { UserRole } from "global-query-types";

export const checkAuthorization = (meData: MeQuery) => meData?.me?.role === UserRole.Client;

export const ClientRoute = ({ component, path, ...rest }) =>
  PrivateRoute({
    component,
    hasAuthorization: (meData?: MeQuery) => meData && checkAuthorization(meData),
    path,
    ...rest
  });
