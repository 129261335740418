import { withTranslationReady } from "../../../../../../../common/i18n/withTranslationReady";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "../../../../../../../lib/hooks/useRideFormik";
import React from "react";
import { StepComponentProps, StepHeading } from "../../../../ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "../../../../../../../lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { WithTranslation } from "react-i18next";
import { SourceOfWealthType } from "../../../../../../../lib/models/client/LowTaxBroker/SourcesOfWealth";
import {
  MultiSelectOption,
  RideMultiSelect
} from "../../../../../../../uiLibrary/components/RideMultiSelect/RideMultiSelect";
import { InvalidFeedback } from "../../../../../../../uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";

const SourcesOfWealthUsedForFundsStep = ({
  t,
  controls,
  currentStep,
  saveData,
  order
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  let sourcesAvailable = Object.values(SourceOfWealthType).filter(
    (name) => order.extra?.sourcesOfWealth?.[name]
  );

  const options: MultiSelectOption[] = sourcesAvailable.map((value) => ({
    label: t(`generic:ride-broker-onboarding.sources-of-wealth.${value}`),
    name: value
  }));

  const initialValues = {};
  Object.keys(order.extra.sourcesOfWealth ?? {}).forEach((sourceOfWealth) => {
    initialValues[sourceOfWealth] = order.extra.sourcesOfWealth?.[sourceOfWealth].usedForFunds;
  });

  const formik = useRideFormik({
    initialValues,
    onSubmit: async (values) => {
      let sourcesOfWealthData = {};

      Object.keys(order.extra.sourcesOfWealth ?? {}).forEach((source) => {
        sourcesOfWealthData[source] = {
          ...order.extra.sourcesOfWealth?.[source],
          usedForFunds: !!values[source]
        };
      });

      currentStep.complete();
      await saveData({
        ...order.extra,
        ...currentStep.chapter.order.serialize(),
        sourcesOfWealth: sourcesOfWealthData
      });
    },
    validate(values) {
      let selectedSourcesOfWealth = Object.keys(values).filter(
        (sourceOfWealth) => !!values[sourceOfWealth]
      );
      if (selectedSourcesOfWealth.length === 0) {
        return { error: t("generic:validation-required") };
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:ride-broker-onboarding.sources-of-wealth-used-for-funds.title")}
        />
        <RideMultiSelect options={options} columns={1} />
        <InvalidFeedback hidden={!formik.errors["error"]} testPrefix="error">
          {formik.errors["error"]}
        </InvalidFeedback>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SourcesOfWealthUsedForFundsStep);
