import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepHeading, StepComponentProps } from "../../../ChaptersOrderLayout.partials";
import ReviewShareholders from "../ShareholderDetails/ReviewShareholders/ReviewShareholders";
import { ReviewOwnershipData } from "../OwnershipDetails/ReviewOwnershipData/ReviewOwnershipData";
import { ReviewManagingDirectors } from "../ManagingDirectors/ReviewManagingDirectors/ReviewManagingDirectors";
import { PersonName } from "lib/formatters/personFormatter";
import { ReviewCompanyName } from "../CompanyName/ReviewCompanyName/ReviewCompanyName";
import { CompanyName } from "lib/formatters/companyFormatter";
import { ReviewCompanyAddress } from "../CompanyAddress/ReviewCompanyAddress/ReviewCompanyAddress";
import { ReviewBusinessPurpose } from "../BusinessPurpose/ReviewBusinessPurpose/ReviewBusinessPurpose";

const ReviewAndSubmitCompanyFounding = ({
  order,
  controls,
  currentStep,
  saveData,
  t
}: WithTranslation & StepComponentProps) => {
  let initialValues = {};

  const onSubmit = async () => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        ...currentStep.chapter.order.serialize()
      },
      undefined,
      false
    );
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit
  });

  const {
    employees,
    assetTypes,
    companyData,
    shareholders,
    companyAddress,
    noAdditionalOperatingActivities,
    additionalOperatingActivitiesDescription
  } = order.extra;

  const shareholdersDirectors: PersonName[] = [];
  if (shareholders) {
    for (const shareholderKey of Object.keys(shareholders)) {
      const shareholder = shareholders[shareholderKey];
      if (shareholder.isManagingDirector) {
        shareholdersDirectors.push({
          firstName: shareholder.personData.firstName,
          lastName: shareholder.personData.lastName
        });
      } else if (shareholder.isRepresentativeManagingDirector) {
        shareholdersDirectors.push({
          firstName: shareholder.companyRepresentative.firstName,
          lastName: shareholder.companyRepresentative.lastName
        });
      }
    }
  }

  const employeeDirectors: PersonName[] = [];

  if (employees) {
    for (const employeeKey of Object.keys(employees)) {
      const employee = employees[employeeKey];
      if (employee.isManagingDirector) {
        employeeDirectors.push({
          firstName: employee.personData.firstName,
          lastName: employee.personData.lastName
        });
      }
    }
  }

  const shareholdersList = Object.keys(shareholders).map((key) => shareholders[key]);

  const companyName: CompanyName = {
    name: companyData?.companyName ?? "",
    legalForm: companyData?.legalForm ?? ""
  };

  const managingDirectors = [...shareholdersDirectors, ...employeeDirectors];

  const getEditLink = (chapter: string) => {
    return `/client/order/generic/${chapter}/0/${order.id}/${order.submissionId}`;
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="review-and-submit">
          <StepHeading text={t("generic:company-setup.steps.review-and-submit.title")} />
          <ReviewBusinessPurpose
            editLink={getEditLink("business-purpose")}
            additionalOperatingActivitiesDescription={
              additionalOperatingActivitiesDescription ?? ""
            }
            assetTypes={assetTypes ?? {}}
            noAdditionalOperatingActivities={noAdditionalOperatingActivities}
            className="review-and-submit__card"
          />
          <ReviewShareholders className="review-and-submit__card" order={order} />
          <ReviewOwnershipData
            className="review-and-submit__card"
            editLink={getEditLink("ownership-distribution")}
            shareholders={shareholdersList}
          />
          <ReviewManagingDirectors
            className="review-and-submit__card"
            editLink={getEditLink("managing-directors")}
            directors={managingDirectors}
          />
          <ReviewCompanyName
            className="review-and-submit__card"
            editLink={getEditLink("company-name")}
            company={companyName}
          />
          <ReviewCompanyAddress
            className="review-and-submit__card"
            editLink={getEditLink("company-address")}
            address={companyAddress ?? {}}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ReviewAndSubmitCompanyFounding);
