import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { textFieldFor } from "../../generic/commonFields";
import { NotaryDocumentType, NotaryLinkItem } from "../../../global-query-types";
import { CreateNotarySchema } from "../../../lib/validation/CreateNotarySchema";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface NotaryListProps extends WithTranslation {
  onSubmit: (
    email: string,
    firstName: string,
    lastName: string,
    links: NotaryLinkItem[]
  ) => Promise<void>;
}

export const AddNotaryForm = ({ onSubmit, t }: NotaryListProps) => {
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    moneyLaunderingQuestionnaire: "",
    instructionLetter: ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateNotarySchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const links = [
        {
          notaryDocumentType: NotaryDocumentType.MoneyLaunderingQuestionnaire,
          link: values.moneyLaunderingQuestionnaire
        },
        {
          notaryDocumentType: NotaryDocumentType.InstructionLetter,
          link: values.instructionLetter
        }
      ];
      await onSubmit(values.email, values.firstName, values.lastName, links);
    }
  });

  return (
    <div data-testid="add-notary-form">
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} md={3}>
            {textFieldFor(t, formik, "email", "nominee-dashboard:person.email", {
              placeholder: "notary@ride.capital"
            })}
          </Col>
          <Col xs={12} md={3}>
            {textFieldFor(t, formik, "firstName", "nominee-dashboard:person.first-name", {
              placeholder: "First name"
            })}
          </Col>
          <Col xs={12} md={3}>
            {textFieldFor(t, formik, "lastName", "nominee-dashboard:person.last-name", {
              placeholder: "Last name"
            })}
          </Col>
          <Col xs={12} md={3}>
            {textFieldFor(
              t,
              formik,
              "moneyLaunderingQuestionnaire",
              "nominee-dashboard:person.money-laundering-questionnaire",
              {
                placeholder: "This can be empty"
              }
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            {textFieldFor(
              t,
              formik,
              "instructionLetter",
              "nominee-dashboard:person.instruction-letter",
              {
                placeholder: "This can be empty"
              }
            )}
          </Col>
          <Col xs={12} md={3} />
          <Col xs={12} md={3} />
          <Col xs={12} md={3} />
        </Row>
        <Row className="ml-1">
          <RideButtonDeprecated
            variant={ButtonVariant.Primary}
            size={ButtonSize.Medium}
            data-testid="submit">
            {t("generic:add")}
          </RideButtonDeprecated>
        </Row>
      </Form>
    </div>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(AddNotaryForm);
