import React from "react";
import PersonalInformationStep, {
  PersonalInformationStepProps
} from "client/components/UnifiedShortOrderForm/PersonalInformationStep/PersonalInformationStep";
import { useMutation } from "@apollo/react-hooks";
import {
  CreateLeadForUnifiedShortOrderForm,
  CreateLeadForUnifiedShortOrderFormVariables
} from "lib/api/mutations/graphql/CreateLeadForUnifiedShortOrderForm";
import { CREATE_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM } from "lib/api/mutations/CREATE_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM";
import { doNothing } from "common/stubs";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { getHubspotutk } from "../../../../testTools/helpers/getHubspotutk";

export const PersonalInformationStepContainer = ({
  initialValues,
  goToNextStep,
  persistData
}: Omit<PersonalInformationStepProps, "createLeadHandler">) => {
  const [createLeadMutation, createLeadMutationRequest] = useMutation<
    CreateLeadForUnifiedShortOrderForm,
    CreateLeadForUnifiedShortOrderFormVariables
  >(CREATE_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM);

  const createLeadMutationHandler = async ({
    personData,
    allowsContact,
    privacyPolicyCheckbox,
    userReportedSource
  }: Omit<CreateLeadForUnifiedShortOrderFormVariables, "hubspotCookie">) => {
    await createLeadMutation({
      variables: {
        personData,
        allowsContact,
        privacyPolicyCheckbox,
        userReportedSource,
        hubspotCookie: getHubspotutk()
      }
    });
  };

  return (
    <>
      <RequestFeedbackHandler requests={[createLeadMutationRequest]} />
      <PersonalInformationStep
        createLeadHandler={createLeadMutationHandler}
        goToNextStep={goToNextStep}
        persistData={persistData}
        initialValues={initialValues}
        goToPreviousStep={doNothing}
      />
    </>
  );
};
