import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { ConfirmTaxAdvisorNecessitySchema } from "lib/validation/ConfirmTaxAdvisorNecessitySchema";

const ConfirmTaxAdvisorNecessity = ({
  t,
  currentStep,
  saveData,
  order,
  controls
}: StepComponentProps & WithTranslation) => {
  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData({
      ...order.extra,
      needsTaxAdvisor: values.needsTaxAdvisor === "confirm-necessity",
      ...currentStep.chapter.order.serialize()
    });
  };

  const valueMapping = {
    true: "confirm-necessity",
    false: "decline-necessity"
  };

  const formik = useRideFormik({
    initialValues: {
      needsTaxAdvisor: valueMapping[order.extra.needsTaxAdvisor] ?? ""
    },
    onSubmit,
    validationSchema: ConfirmTaxAdvisorNecessitySchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:order-company-foundation.steps.confirm-tax-advisor-necessity.title")}
        />
        <div data-testid="confirm-tax-advisor-necessity">
          <RideRadioSelect
            options={[
              {
                value: "confirm-necessity",
                label: t(
                  "generic:order-company-foundation.steps.confirm-tax-advisor-necessity.confirm-necessity"
                )
              },
              {
                value: "decline-necessity",
                label: t(
                  "generic:order-company-foundation.steps.confirm-tax-advisor-necessity.decline-necessity"
                )
              }
            ]}
            name="needsTaxAdvisor"
            variant={RadioSelectVariant.Heavy}
            columns={1}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ConfirmTaxAdvisorNecessity);
