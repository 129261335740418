import * as Yup from "yup";
import { ensureVatValidation } from "../../common/vatValidator";

export const vatNumberValidation = (t) =>
  Yup.string().test("vatValidator", t("generic:invalid-vatNumber"), ensureVatValidation);

export const VatNumberSchema = () => {
  return Yup.object().shape({
    vatNumber: Yup.string().test("vatValidator", "generic:invalid-vatNumber", ensureVatValidation)
  });
};

export default VatNumberSchema;
