import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { FileUploadArea } from "../FileUploadArea/FileUploadArea";
import { FileInfo } from "lib/models/client/LowTaxBroker/FileInfo";

export interface FileError {
  description: string;
  fileName: string;
}

interface UploadFileProps {
  requirements: string;
  uploadFile: (file: File) => Promise<void>;
  existingFile: FileInfo | undefined;
  deleteFile: () => Promise<void>;
  dataTestId?: string | null;
}

const moduleName = "upload-file";

const UploadFile = ({
  requirements,
  uploadFile,
  existingFile,
  deleteFile,
  dataTestId
}: UploadFileProps) => {
  return (
    <div className={moduleName} data-testid={dataTestId ?? moduleName}>
      <RequirementsSection requirements={requirements} />
      <FileUploadArea uploadFile={uploadFile} existingFile={existingFile} deleteFile={deleteFile} />
    </div>
  );
};

export default UploadFile;

interface RequirementsSectionProps extends WithTranslation {
  requirements: string;
  useTransComponent?: boolean | null;
  transComponents?: { [key: string]: React.ReactElement } | null;
}

export const RequirementsSection = withTranslationReady(["generic"])(
  ({ t, requirements }: RequirementsSectionProps) => {
    return (
      <div data-testid="requirements-section">
        <SectionTitle
          text={t("generic:ride-broker-onboarding.documents.upload-file.requirements.title")}
        />
        <p data-testid={"requirement-message"} className={`${moduleName}__requirements`}>
          {requirements}
        </p>
      </div>
    );
  }
);

const SectionTitle = ({ text }: { text: string }) => (
  <h6 className={`${moduleName}__section-title`}>{text}</h6>
);
