import React from "react";
import { TFunction, Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { formatCurrency } from "common/formatters";
import { Form, FormikProvider, useFormik } from "formik";
import OnboardingStepCard, {
  EditableOnboardingStepCardProps,
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { useRideField } from "lib/hooks/useRideField";
import { shortOrderFormProductPrices } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { getCurrentYear } from "lib/dateTime/getCurrentYear";
import { getPreviousFiscalYears } from "lib/dateTime/getOfferedFiscalYears";
import { FiscalYearCard } from "../../UnifiedShortOrderForm/ProductSelectionStep/AdminServiceAdditionalYears/FiscalYearCard/FiscalYearCard";

const moduleName = "select-additional-fiscal-years";

export interface SelectAdditionalFiscalYearsProps extends EditableOnboardingStepCardProps {
  stepNumber: number;
  status: OnboardingStepCardStatus;
  selectFiscalYears: (numberOfAdditionalYears: number) => Promise<void>;
  numberOfFiscalYearsPreSelected?: number;
  tier?: AdminServiceTier;
}

const SelectAdditionalFiscalYears = ({
  t,
  stepNumber,
  tier,
  status,
  forceOpening,
  toggleEditing,
  selectFiscalYears,
  numberOfFiscalYearsPreSelected
}: SelectAdditionalFiscalYearsProps & WithTranslation) => {
  const fiscalYearInputName = `startingFiscalYear`;
  const currentYear = getCurrentYear();
  const previousFiscalYears = getPreviousFiscalYears();

  const formik = useFormik({
    initialValues: {
      startingFiscalYear: getCurrentYear() - (numberOfFiscalYearsPreSelected ?? 0)
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const selectedStartingYear = values[fiscalYearInputName];
      await selectFiscalYears(previousFiscalYears.indexOf(selectedStartingYear) + 1);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <OnboardingStepCard
          data-testid={moduleName}
          number={stepNumber}
          status={status}
          forceShowContent={forceOpening}
          toggleEditing={toggleEditing ? () => toggleEditing?.("additionalFiscalYears") : null}
          title={t("generic:select-additional-fiscal-years.title")}>
          <div className={`${moduleName}__content`}>
            <div className={`${moduleName}__text`}>
              <Typography
                data-testid={`${moduleName}-question`}
                category={"Paragraph"}
                size={100}
                weight={"Heavy"}>
                <Trans
                  i18nKey={"generic:select-additional-fiscal-years.question"}
                  values={{ currentYear }}
                />
              </Typography>
              <Typography
                data-testid={`${moduleName}-question-explanation`}
                category={"Paragraph"}
                size={100}
                weight={"Light"}>
                {t("generic:select-additional-fiscal-years.question-explanation")}
              </Typography>
            </div>
            <Typography
              data-testid={`${moduleName}-pricing`}
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              className={`${moduleName}__initial-price-button`}>
              <Trans i18nKey={"generic:select-additional-fiscal-years.initial-price"} />
            </Typography>
            <div className={`${moduleName}__fiscal-years`}>
              {previousFiscalYears.map((year) => (
                <FiscalYearCard
                  key={year}
                  year={year}
                  inputName={fiscalYearInputName}
                  classNames={`${moduleName}__fiscal-year-card`}
                />
              ))}
            </div>
            <SelectedFiscalYearDetails
              previousFiscalYears={[...previousFiscalYears]}
              t={t}
              inputName={fiscalYearInputName}
              tier={tier}
            />
            <RideAlertBar
              type={AlertType.INFO}
              message={t("generic:select-additional-fiscal-years.alert-information")}
            />
          </div>
          <div className={`${moduleName}__actions`}>
            <RideButton
              type={"button"}
              onClick={async () => {
                await selectFiscalYears(0);
                await formik.setValues({ [fiscalYearInputName]: currentYear });
              }}
              data-testid={"no-additional-years"}
              label={t("generic:select-additional-fiscal-years.no-additional-years")}
              variant={RideButtonVariant.GHOST}
              size={RideButtonSize.BIG}
            />
            <RideButton
              type={"submit"}
              data-testid={"select-and-continue"}
              label={t("generic:select-additional-fiscal-years.select-and-continue")}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
            />
          </div>
        </OnboardingStepCard>
      </Form>
    </FormikProvider>
  );
};

interface SelectedFiscalYearDetailsProps {
  t: TFunction;
  previousFiscalYears: number[];
  inputName: string;
  tier?: AdminServiceTier;
}

const SelectedFiscalYearDetails = ({
  t,
  previousFiscalYears,
  inputName,
  tier
}: SelectedFiscalYearDetailsProps) => {
  const currentYear = getCurrentYear();
  const startingFiscalYear = useRideField(inputName);

  const selectedFiscalYears = previousFiscalYears.filter(
    (fiscalYear) => fiscalYear >= startingFiscalYear.value
  );

  const fiscalYearsDisplayed = [currentYear, ...selectedFiscalYears];
  const value = tier
    ? shortOrderFormProductPrices.adminService[tier]
    : shortOrderFormProductPrices.adminService.standard;

  return selectedFiscalYears.length > 0 ? (
    <div className={`${moduleName}__price-summary`} data-testid={"fiscal-years-price-summary"}>
      {fiscalYearsDisplayed.map((fiscalYear) => {
        return (
          <div
            data-testid={`fiscal-year-${fiscalYear}-details`}
            key={fiscalYear}
            className={`${moduleName}__fiscal-year-price`}>
            <Typography
              data-testid={`fiscal-year-${fiscalYear}-details-description`}
              category={"Paragraph"}
              size={200}
              weight={"Light"}>
              {`${t("generic:admin-service")} ${fiscalYear}`}
            </Typography>
            <Typography
              data-testid={`fiscal-year-${fiscalYear}-details-price`}
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}>
              {formatCurrency(value, { fixed: 0 })}
            </Typography>
          </div>
        );
      })}
      <div data-testid="fiscal-years-total-price" className={`${moduleName}__total-price`}>
        <Typography category={"Paragraph"} size={200} weight={"Heavy"}>
          {t("generic:admin-service")}
        </Typography>
        <Typography category={"Paragraph"} size={200} weight={"Heavy"}>{`${t(
          "generic:total"
        )}: ${formatCurrency(value * fiscalYearsDisplayed.length, { fixed: 0 })}`}</Typography>
      </div>
    </div>
  ) : null;
};

export default withTranslationReady(["generic"])(SelectAdditionalFiscalYears);
