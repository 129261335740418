import * as Yup from "yup";
import { TFunction } from "react-i18next";

export const EntityStatusInformationSchema = (t: TFunction, requireEmploymentStatus: boolean) => {
  return Yup.object().shape({
    "tax-jurisdiction": Yup.string().required(t("generic:validation-required")),
    "tax-id": Yup.string().required(t("generic:validation-required")),
    "employment-status": requireEmploymentStatus
      ? Yup.string().required(t("generic:validation-required"))
      : Yup.string(),
    pep: Yup.string().required(t("generic:validation-required")),
    "pep-context": Yup.string().when("pep", {
      is: "yes",
      then: Yup.string().required(t("generic:validation-required"))
    })
  });
};
