import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import CompanyTextFieldEditContainer from "../../../client/components/Company/CompanyTextFieldEdit/CompanyTextFieldEditContainer";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { useHistory } from "react-router";

interface CompanyThirdPartyTaxAdvisorClientNumberSceneProps extends WithTranslation {}

const CompanyThirdPartyTaxAdvisorClientNumberScene = ({
  t
}: CompanyThirdPartyTaxAdvisorClientNumberSceneProps) => {
  const { goBack } = useHistory();

  return (
    <ModalFormLayout
      title={t("client:company.edit.thirdPartyTaxAdvisorClientNumber")}
      onBack={goBack}>
      <CompanyTextFieldEditContainer
        subtitle
        fieldName="thirdPartyTaxAdvisorClientNumber"
        section="third-party-tax-advisor-client-number"
      />
    </ModalFormLayout>
  );
};

export default withTranslationReady(["client"])(CompanyThirdPartyTaxAdvisorClientNumberScene);
