import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { RideRadioSelect } from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { Form, FormikProvider, useFormik } from "formik";
import { CompanyHasMajorityShareholderSchema } from "lib/validation/CompanyHasMajorityShareholderSchema";
import { CompanyById_companyById_group_memberships } from "lib/api/queries/graphql/CompanyById";

interface CompanyHasMajorityShareholderProps extends StepComponentProps, WithTranslation {
  shareholders?: CompanyById_companyById_group_memberships[];
}

const CompanyHasMajorityShareholder = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  shareholders: companyShareholders
}: CompanyHasMajorityShareholderProps) => {
  async function onSubmit(values) {
    if (order?.extra?.hasMajorityShareholder !== values.hasMajorityShareholder) {
      const reviewAndAdjustSharesStep = currentStep.chapter.getStepByKey(
        "review-and-adjust-shares"
      );

      reviewAndAdjustSharesStep?.reOpen();
    }

    currentStep.complete();

    await saveData({
      ...order.extra,
      hasMajorityShareholder: values.hasMajorityShareholder,
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useFormik({
    initialValues: {
      hasMajorityShareholder: order?.extra?.hasMajorityShareholder
    },
    onSubmit,
    validationSchema: CompanyHasMajorityShareholderSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:ride-broker-onboarding.company-has-majority-shareholder.title")}
        />
        <RideRadioSelect
          name="hasMajorityShareholder"
          options={[
            { value: "yes", label: t("generic:yes") },
            { value: "no", label: t("generic:no") }
          ]}
        />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(CompanyHasMajorityShareholder);
