import React from "react";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { RideCurrencyInput } from "uiLibrary/components/RideCurrencyInput/RideCurrencyInput";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import * as Yup from "yup";
import { formatCurrency } from "common/formatters";

const EstimatedTotalAssetsStep = ({
  t,
  order,
  currentStep,
  saveData,
  controls
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  async function onSubmit(values) {
    currentStep.complete();

    await saveData({
      ...order.extra,
      estimatedTotalAssets: values["estimated-total-assets"],
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {
      "estimated-total-assets": order.extra.estimatedTotalAssets
    },
    onSubmit,
    validationSchema: Yup.object({
      "estimated-total-assets": EstimatedTotalAssetsSchema(
        t,
        Number(order.extra.currentLiquidNetWorth ?? 0)
      )
    })
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.estimated-total-assets.title")} />
        <RideCurrencyInput
          name="estimated-total-assets"
          label={t("generic:estimated-total-assets")}
        />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(EstimatedTotalAssetsStep);

const EstimatedTotalAssetsSchema = (t: TFunction, liquidNetWorth: number) =>
  Yup.number()
    .required(t("generic:validation-required"))
    .min(
      liquidNetWorth,
      t(
        "generic:ride-broker-onboarding.estimated-total-assets.must-be-greater-than-liquid-net-worth",
        {
          liquidNetWorth: formatCurrency(liquidNetWorth)
        }
      )
    );
