import { TFunction } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/generic/Table";
import {
  AllDiscountCodesQuery_allDiscountCodes,
  AllDiscountCodesQuery_allDiscountCodes_referrerPerson,
  AllDiscountCodesQuery_allDiscountCodes_uses
} from "../../../lib/api/queries/graphql/AllDiscountCodesQuery";
import { formatCurrency, formatValue } from "../../../common/formatters";
import { consolidateReferralDataByMonth } from "./consolidateReferralDataByMonth";
import { withTranslation, WithTranslation } from "react-i18next";
import { AllDiscountCodes } from "../../../lib/api/queries/AllDiscountCodes";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";

interface DiscountCodeUsageDetails {
  usageCount: number;
  amount: number;
  __typename: "DiscountCodeDetails";
  id: string | null;
  code: string | null;
  referrerPerson: AllDiscountCodesQuery_allDiscountCodes_referrerPerson | null;
  uses: AllDiscountCodesQuery_allDiscountCodes_uses[] | null;
}

interface ListAllReferralCodesProps extends WithTranslation {
  viewAll: boolean;
  month?: number | undefined;
  year?: number | undefined;
}

export const ReferralCodesTable = ({
  viewAll,
  t,
  tReady,
  month,
  year
}: ListAllReferralCodesProps) => {
  const enrichedData = AllDiscountCodes()?.data?.allDiscountCodes ?? [];

  return (
    <ListAllReferralCodeComponent
      t={t}
      tReady={tReady}
      discountCodes={enrichedData}
      displayAllData={viewAll}
      month={month}
      year={year}
    />
  );
};

export const ListAllReferralCodeComponent = ({
  t,
  tReady,
  discountCodes,
  displayAllData,
  month,
  year
}: {
  t: TFunction;
  tReady: boolean;
  discountCodes: AllDiscountCodesQuery_allDiscountCodes[];
  displayAllData: boolean;
  month?: number | undefined;
  year?: number | undefined;
}) => {
  if (!tReady) return null;
  let discountCodesData: DiscountCodeUsageDetails[] = [];

  const columns = [
    {
      dataField: "code",
      text: t("nominee-dashboard:referral-code.code"),
      sort: true,
      formatter: (value, row) => (
        <Link
          key={value}
          to={`/nominee/referral-codes/${value}`}
          className="text-reset text-underlined"
          data-testid="referral-code">
          <div className="d-inline-block mr-1">{value}</div>
          {row.blacklisted ? (
            <RideBadgeStatus
              color={RideBadgeColorsEnum.DARK}
              label={t("nominee-dashboard:blacklisted")}
              variant={RideBadgeVariantsEnum.SOLID}
            />
          ) : null}
        </Link>
      )
    },
    {
      dataField: "personName",
      text: t("nominee-dashboard:referral-code.person"),
      sort: true,
      formatter: (_, row) => (
        <Link
          to={row.referrerPerson?.id ? `/nominee-persons/${row.referrerPerson?.id}` : "#"}
          className="text-reset text-underlined"
          data-testid="person-link">
          {`${formatValue(row.referrerPerson?.firstName)} ${formatValue(
            row.referrerPerson?.lastName
          )}`}
        </Link>
      )
    },
    {
      dataField: "usageCount",
      text: t("nominee-dashboard:referral-code.usage-count"),
      sort: true,
      formatter: (usageCount) => <span data-testid="usage-count">{usageCount}</span>
    },
    {
      dataField: "amount",
      text: t("nominee-dashboard:referral-code.amount"),
      sort: true,
      formatter: (amount) => (
        <span data-testid="amount">{formatCurrency(amount, { fixed: 0 })}</span>
      )
    }
  ];

  if (displayAllData) {
    discountCodesData = consolidateReferralDataByMonth(discountCodes);
  } else {
    discountCodesData = consolidateReferralDataByMonth(discountCodes, month, year).filter(
      (rec) => rec.uses && rec.usageCount
    );
  }

  return <Table data={discountCodesData} columns={columns} keyField="id" tableStyle="greenTable" />;
};

export default withTranslation(["nominee-dashboard", "generic"])(ReferralCodesTable);
