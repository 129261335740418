import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  ButtonProps,
  RideButtonDeprecated
} from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { WarningIcon } from "../icons/WarningIcon";

export enum RideModalSize {
  LARGE = "large",
  SMALL = "small"
}

export enum RideModalVariant {
  ROUNDED = "rounded"
}

export interface RideModalProps {
  children?: (setVisibility: (state: boolean) => void) => React.ReactNode;
  dialogBody?: ((setVisibility: (state: boolean) => void) => React.ReactNode) | React.ReactNode;
  title?: string;
  buttons?: ButtonProps[];
  hideClosingButton?: boolean;
  size?: RideModalSize;
  variant?: RideModalVariant;
  visible?: boolean;
  hasWarningIcon?: boolean;
  "data-testid"?: string;
}

export const RideModalDeprecated = ({
  children,
  dialogBody,
  title,
  buttons,
  hideClosingButton = false,
  size,
  variant = undefined,
  hasWarningIcon = false,
  visible = false,
  "data-testid": testid = "ride-modal"
}: RideModalProps) => {
  const [show, setShow] = useState<boolean>(visible);

  const onHide = () => {
    setShow(false);
  };

  const borderVariant = variant ? `ride-modal-deprecated--${variant}` : "";

  return (
    <div data-testid={testid}>
      <Modal
        data-testid={"ride-modal-dialog"}
        show={show}
        onHide={onHide}
        dialogClassName={`ride-modal-deprecated__dialog--${size} ${borderVariant}`}>
        <Modal.Header
          className="ride-modal-deprecated__header"
          data-testid="ride-modal-header"
          closeButton={!hideClosingButton}>
          {hasWarningIcon ? (
            <div
              data-testid={"ride-modal-header-icon"}
              className={"ride-modal-deprecated__header-icon"}>
              <WarningIcon />
            </div>
          ) : (
            ""
          )}
          <Modal.Title
            className={`ride-modal-deprecated__title ${
              hideClosingButton ? "" : "ride-modal-deprecated__title--with-close"
            }`}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ride-modal-deprecated__body">
          {dialogBody}
          <div className="ride-modal-deprecated__modal-buttons">
            {buttons?.map((buttonProps, index) => (
              <div key={index} className="ride-modal-deprecated__button">
                <RideButtonDeprecated
                  {...buttonProps}
                  onClick={async (e) => {
                    await buttonProps.onClick?.(e);
                    if (!buttonProps.keepModalOpened) onHide();
                  }}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      {children?.(setShow)}
    </div>
  );
};
