import React from "react";
import AddNewGmbH from "./AddNewGmbH";

interface AddNewGmbHContainerProps {
  onOrder: () => void;
}

export const AddNewGmbHContainer = ({ onOrder }: AddNewGmbHContainerProps) => {
  return <AddNewGmbH onOrder={onOrder} />;
};
