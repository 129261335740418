import React from "react";
import { useHistory } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import CompanyTextFieldEditContainer from "../../components/Company/CompanyTextFieldEdit/CompanyTextFieldEditContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface CompanyRegistrationSceneProps extends WithTranslation {}

const CompanyRegistrationScene = ({ t }: CompanyRegistrationSceneProps) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.registration")} />
      <ModalFormLayout title={t("client:company.edit.registration")} onBack={goBack}>
        <CompanyTextFieldEditContainer fieldName={"registration"} section="registration" />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation(["client"])(CompanyRegistrationScene);
