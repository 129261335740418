import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { GetMyTaxAdvisorCompanyWithTaxAdvisorClientsQuery } from "../../Queries";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import TaxAdvisorThirdPartyTaxAdvisorCustomers from "./TaxAdvisorThirdPartyTaxAdvisorCustomers";
import NoResultsPlaceholder from "../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";

interface TaxAdvisorThirdPartyTaxAdvisorCustomersContainerProps extends WithTranslation {}

const TaxAdvisorThirdPartyTaxAdvisorCustomersContainer = ({
  t
}: TaxAdvisorThirdPartyTaxAdvisorCustomersContainerProps) => {
  const getMyTaxAdvisorCompanyWithTaxAdvisorClientsRequest =
    GetMyTaxAdvisorCompanyWithTaxAdvisorClientsQuery();

  const taxAdvisorCompanies =
    getMyTaxAdvisorCompanyWithTaxAdvisorClientsRequest.data
      ?.getMyTaxAdvisorCompanyWithTaxAdvisorClients?.companies;

  return (
    <RequestFeedbackHandler
      requests={[getMyTaxAdvisorCompanyWithTaxAdvisorClientsRequest]}
      noLoadingAnimation>
      {taxAdvisorCompanies ? (
        <TaxAdvisorThirdPartyTaxAdvisorCustomers companies={taxAdvisorCompanies} />
      ) : (
        <NoResultsPlaceholder text={t("thirdPartyPartners.noResults")} />
      )}
    </RequestFeedbackHandler>
  );
};

export default withTranslation("tax-advisor")(TaxAdvisorThirdPartyTaxAdvisorCustomersContainer);
