import gql from "graphql-tag";

export const GET_AVAILABLE_SHELF_COMPANIES = gql`
  query getAvailableShelfCompanies {
    getAvailableShelfCompanies {
      companies {
        id
        name
        legalForm
        createdAt
        taxNumber
        businessAddress {
          city
        }
        brokerageAccounts {
          id
        }
      }
    }
  }
`;
