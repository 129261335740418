import React from "react";
import { Redirect, useParams } from "react-router";
import GuestHeaderDeprecated from "../../../guest/components/GuestHeaderDeprecated/GuestHeaderDeprecated";
import { getFlowTypePath } from "../../public/CompanyCreationFlowType/currentFlowType";
import { GetResumeMyCompanyCreationStep } from "../../public/CompanyCreation/Queries";
import { ResumeMyCompanyCreationStep } from "../../public/CompanyCreation/graphql/ResumeMyCompanyCreationStep";
import RequestFeedbackHandler from "../RequestFeedbackHandler";
import { isAuthenticated } from "../../../common/Authentication/isAuthenticated";

const HeaderContainer = () => {
  const { id } = useParams<{ id: string }>();
  const getRequest = GetResumeMyCompanyCreationStep(id);

  return (
    <RequestFeedbackHandler requests={[getRequest]}>
      <Header data={getRequest.data} />
    </RequestFeedbackHandler>
  );
};

const Header = ({ data }: { data?: ResumeMyCompanyCreationStep }) => {
  if (data?.resumeMyCompanyCreationStep?.flowType && isAuthenticated()) {
    const flowPrefix = getFlowTypePath(data.resumeMyCompanyCreationStep.flowType);

    return (
      <>
        <Redirect to={`/${flowPrefix}/${data.resumeMyCompanyCreationStep.id}`} />
        <GuestHeaderDeprecated />
      </>
    );
  }
  return <GuestHeaderDeprecated />;
};

export default HeaderContainer;
