import gql from "graphql-tag";

export const GET_MY_TAX_ADVISOR_CLIENTS = gql`
  query GetMyTaxAdvisorClients($searchTerm: String, $start: Int, $limit: Int) {
    getMyTaxAdvisorClients(searchTerm: $searchTerm, start: $start, limit: $limit) {
      items {
        id
        taxAdvisorStatus
        client {
          id
          clientNumber
          thirdPartyTaxAdvisorClientNumber
          fiscalYear
          name
          legalForm
          brokerageAccounts {
            id
          }
        }
      }
      count
    }
  }
`;
