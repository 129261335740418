import React, { PropsWithChildren } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReturnLinkWithChevron from "../../ReturnLinkWithChevron/ReturnLinkWithChevron";
import ButtonClose from "../../Buttons/ButtonClose/ButtonClose";

export enum ModalLayoutVariant {
  light = "light",
  dark = "dark",
  white = "white"
}

interface ModalLayoutProps extends PropsWithChildren<{}> {
  onClose?: () => void;
  onBack?: () => void;
  className?: string;
  variant?: ModalLayoutVariant;
}

export const ModalLayout = ({
  children,
  onClose,
  onBack,
  className,
  variant = ModalLayoutVariant.light
}: ModalLayoutProps) => {
  return (
    <div className={`${className ?? ""} modal-layout modal-layout--${variant}`}>
      <Container>
        <Row>
          <Col>
            {onBack && (
              <Container className="modal-layout__back">
                <ReturnLinkWithChevron
                  onClick={onBack}
                  backgroundVariant="light"
                  data-testid="button-back"
                />
              </Container>
            )}
          </Col>
          <Col>
            {onClose && (
              <div className="modal-layout__close">
                <ButtonClose onClick={onClose!} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <div className={`${className}__children`} data-testid={`${className}__children`}>
        {children}
      </div>
    </div>
  );
};
