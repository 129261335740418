import { TFunction } from "react-i18next";
import { Typography } from "../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import { RideDropdownSelect } from "../../../../../../uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import React from "react";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import { LegalForm } from "../../../../../../global-query-types";

const supportedLegalForms = [LegalForm.GmbH, LegalForm.UG, LegalForm.AG];

const SelectYourCompanyLegalFormQuestion = ({ t }: { t: TFunction }) => {
  return (
    <div
      data-testid="select-legal-form"
      className={
        "company-information-step__select-legal-form company-information-step__select-legal-form--v2"
      }>
      <Typography
        category="Paragraph"
        size={100}
        weight={"Heavy"}
        data-testid="please-select-your-company-legal-form-title">
        {t(
          "generic:short-order-form.company-information.please-select-your-company-legal-form.title"
        )}
      </Typography>
      <RideDropdownSelect
        name="companyLegalForm"
        isClearable={true}
        options={supportedLegalForms.map((legalForm) => ({
          label: legalForm,
          value: legalForm
        }))}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(SelectYourCompanyLegalFormQuestion);
