import React, { useRef } from "react";
import RideInput from "../../../sharedComponents/FormControls/RideInput/RideInput";

interface CodeInputProps {
  inputChange: (value: string, index: string) => void;
  className?: string;
  isCodeValid?: boolean;
}

const CodeInput = ({ inputChange, className = "", isCodeValid = true }: CodeInputProps) => {
  const refs = [
    useRef<HTMLElement>(null),
    useRef<HTMLElement>(null),
    useRef<HTMLElement>(null),
    useRef<HTMLElement>(null),
    useRef<HTMLElement>(null),
    useRef<HTMLElement>(null)
  ];

  const changeHandler = (event) => {
    const fieldIndex = event.target.name.split("-")[2];
    const fieldIndexNum = parseInt(fieldIndex);
    let value = event.target.value;

    if (value.length > 1) {
      value = value.slice(0, 1);
      event.target.value = value;
    }

    inputChange(value, fieldIndex);

    if (value) {
      const nextRef = refs[fieldIndexNum + 1];
      nextRef && nextRef.current!.focus();
    }
  };

  const focusHandler = (event) => {
    event.target.value = "";
  };

  const inputProps = {
    onChange: changeHandler,
    onFocus: focusHandler
  };

  return (
    <div className={`codeInput ${className}`} data-testid="code-inputs">
      {refs.map((ref, index) => {
        return (
          <React.Fragment key={`code-input-${index}`}>
            <RideInput
              className={`codeInput__input ${!isCodeValid ? "codeInput__input--error" : ""}`}
              name={`code-input-${index}`}
              data-testid="code-input"
              inputProps={{ ...inputProps, ref: ref }}
              type="number"
              autoComplete="off"
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CodeInput;
