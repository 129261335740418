import gql from "graphql-tag";

export const UPSERT_BANK_ACCOUNT = gql`
  mutation UpsertBankAccount(
    $companyId: ID!
    $bankProvider: BankType!
    $id: ID
    $name: String
    $iban: String
    $plan: BankAccountPlan
  ) {
    upsertBankAccount(
      companyId: $companyId
      bankProvider: $bankProvider
      id: $id
      name: $name
      iban: $iban
      plan: $plan
    ) {
      id
      name
      bankProvider
      iban
      plan
    }
  }
`;
