import React from "react";
import MigrateAdminServicePackage from "./MigrateAdminServicePackage";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import { UpsertOrder, UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { ModalLayout } from "sharedComponents/templates/ModalLayout/ModalLayout";
import RideFooter from "sharedComponents/RideFooter/RideFooter";
import {
  getCheckoutSessionUrl,
  getCheckoutSessionUrlVariables
} from "../../../lib/api/queries/graphql/getCheckoutSessionUrl";
import { GET_CHECKOUT_SESSION_URL } from "../../../lib/api/queries/getCheckoutSessionUrl";
import logger from "../../../common/Logger";

import { useQueryParams } from "../../../lib/hooks/useQueryParams";
import localStore from "common/LocalStore";
import { useHistory } from "react-router";
import { PaymentPlatformAccount } from "../../../global-query-types";

export const MigrateAdminServicePackageContainer = ({ companyId }: { companyId: string }) => {
  const companyByIdQuery = QueryCompanyById(companyId);
  const company = companyByIdQuery.data?.companyById;
  const sessionId = useQueryParams().get("session_id");

  const history = useHistory();

  const showCloseButton = company && !company.asUpgradeMandatory;

  const onClose = () => {
    localStore.setSessionValue("as-migrate-flow-snoozed", true);
    history.push(`/client/entities/${companyId}/admin-service`);
  };

  const [upsertOrderMutation] = useMutation<UpsertOrder, UpsertOrderVariables>(UPSERT_ORDER);

  const upsertOrder = async (data: UpsertOrderVariables) => {
    await upsertOrderMutation({
      variables: data
    });
    await companyByIdQuery.refetch();
  };

  const apolloClient = useApolloClient();
  const getRedirectUrlHandler = async (companyId) => {
    try {
      const result = await apolloClient.query<
        getCheckoutSessionUrl,
        getCheckoutSessionUrlVariables
      >({
        query: GET_CHECKOUT_SESSION_URL,
        variables: { companyId, paymentPlatformAccount: PaymentPlatformAccount.RIDE }
      });

      return result.data.getCheckoutSessionUrl;
    } catch (error) {
      if (error instanceof Error && error.message.includes("COMPANY_NOT_FOUND")) {
        logger.errorMessage(`Company was not found`);
        logger.error(error);
      }
    }
  };

  return (
    <ModalLayout onClose={showCloseButton ? onClose : undefined}>
      <RequestFeedbackHandler requests={[companyByIdQuery]}>
        {company && (
          <MigrateAdminServicePackage
            getRedirectUrlHandler={getRedirectUrlHandler}
            gmbh={new GmbHDetails(company)}
            upsertOrder={upsertOrder}
            sessionId={sessionId}
          />
        )}
      </RequestFeedbackHandler>
      <RideFooter />
    </ModalLayout>
  );
};
