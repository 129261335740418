import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  MainContentVariant,
  PageLayout
} from "../../../sharedComponents/templates/PageLayout/PageLayout";
import { InviteUserFormContainer } from "../../components/InviteUserForm/InviteUserFormContainer";

const PartnersInviteScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("header:invite")}
      mainContent={<InviteUserFormContainer />}
      dataTestId="partners-invite"
      mainContentVariant={MainContentVariant.WHITE}
    />
  );
};

export default withTranslation("header")(PartnersInviteScene);
