import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { IpfsDocumentIdsQuery } from "./graphql/IpfsDocumentIdsQuery";
import IPFSQueryByDocumentId from "./IPFSQueryByDocumentId";
import { Button, Table } from "react-bootstrap";

export const IPFS_DOCUMENT_IDS_QUERY = gql`
  query IpfsDocumentIdsQuery($companyId: String!) {
    getIPFSCIDByCompanyId(companyId: $companyId) {
      id
      fileName
    }
  }
`;

interface documentQueryByCompanyIdProps extends WithTranslation {
  companyId: string;
}

export const DocumentQueryByCompanyId: React.FunctionComponent<documentQueryByCompanyIdProps> = (
  props
) => {
  const { t, companyId } = props;
  const [firstRender, setFirstRender] = useState(true);
  const [showLink, setShowLink] = useState(false);
  const [docId, setDocId] = useState("");

  const { data } = useQuery<IpfsDocumentIdsQuery>(IPFS_DOCUMENT_IDS_QUERY, {
    variables: { companyId: companyId },
    fetchPolicy: firstRender ? "network-only" : undefined,
    onCompleted: () => setFirstRender(false)
  });

  const showDocumentInfo = (docId) => {
    setDocId(docId);
    setShowLink(!showLink);
  };

  const ipfsDocumentIds = data! && data!.getIPFSCIDByCompanyId!;
  if (!ipfsDocumentIds) return <>{t("spv-company:no-document-found")}</>;

  return (
    <>
      <Table striped bordered hover size="sm">
        <tbody>
          {ipfsDocumentIds! &&
            ipfsDocumentIds!.map((document, idx) => (
              <tr key={idx}>
                <td>
                  <Button size="sm" onClick={() => showDocumentInfo(document.id)} variant="link">
                    {document.fileName}
                  </Button>
                </td>
                <td>{docId === document.id ? <IPFSQueryByDocumentId documentId={docId} /> : ""}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default withTranslation(["spv-company"])(DocumentQueryByCompanyId);
