import { ApolloQueryResult } from "apollo-client";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import logger from "../../../../../common/Logger";
import { OptionItem, Select } from "../../../../generic/Select";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { GetTaxAdvisorCompaniesMinimal_taxAdvisorCompanies } from "../../../../../lib/api/queries/graphql/GetTaxAdvisorCompaniesMinimal";

interface TaxServiceSectionProps {
  t: TFunction;
  taxAdvisorCompaniesList: GetTaxAdvisorCompaniesMinimal_taxAdvisorCompanies[];
  handleAssignTaxServiceCompanyChange: (id: string) => Promise<void>;
  company: NomineeCompanyDetails_companyById;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

export const AssignTaxService = ({
  t,
  taxAdvisorCompaniesList,
  handleAssignTaxServiceCompanyChange,
  company,
  refetchCompany
}: TaxServiceSectionProps) => {
  const defaultOption: OptionItem = {
    title: "UNASSIGN-TAX-SERVICE",
    value: null
  };

  const myTaxServiceCompany = company?.myTaxService?.advisor;

  const [value, setValue] = useState(myTaxServiceCompany?.id ?? defaultOption.value);

  useEffect(() => {
    if (company) {
      setValue(company.myTaxService?.advisor?.id ?? null);
    }
  }, [company]);

  const taxServiceCompaniesOptions =
    taxAdvisorCompaniesList?.map((item) => ({
      title: `${item.name} ${item.legalForm}`,
      value: item.id || null
    })) || [];

  const listAllTaxServiceCompanies = [defaultOption].concat(taxServiceCompaniesOptions);

  const onChange = async (taxServiceCompanyId: string) => {
    try {
      await handleAssignTaxServiceCompanyChange(taxServiceCompanyId);
      await refetchCompany();
    } catch (error) {
      logger.errorMessage(
        `Unable to assign tax service company entity using ${taxServiceCompanyId}`
      );
      logger.error(error);
    }
  };

  return (
    <Select
      t={t}
      name="taxServiceCompanies"
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      options={listAllTaxServiceCompanies}
    />
  );
};
