import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Button, Col, Container, Row } from "react-bootstrap";
import { RadioOptionElement, Radios } from "../../../../generic/Radios";
import { TFunction } from "i18next";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../CompanyCreation/graphql/MyCompanyCreationQuery";
import { OptionItem } from "../../../../generic/Select";
import { PackageInformation } from "./PackageInformation";
import { getPackageDetailsByPackageType } from "../../../../../lib/formatters/config";
import { FlowTypeEnum, PackageTypeEnum } from "../../../../../global-query-types";
import { useMutation } from "react-apollo";
import { SELECT_ORDERER_PACKAGE_TYPE } from "../../../CompanyCreation/Mutations";
import {
  UpdateOrdererPackage,
  UpdateOrdererPackageVariables
} from "../../../CompanyCreation/graphql/UpdateOrdererPackage";
import logger from "../../../../../common/Logger";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";

interface PackageTypeFormProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation;
  refetchAndResetStep: (id: string) => Promise<void>;
  currentFlowType: FlowTypeEnum;
}

export enum PackageTypeValueEnum {
  PAPER_ASSETS,
  REAL_ESTATE
}

const availablePackageTypes = [
  { name: PackageTypeEnum.PAPER_ASSETS, disabled: false },
  { name: PackageTypeEnum.REAL_ESTATE, disabled: true }
];

export const PackageTypeForm = ({
  t,
  myCompanyCreationDetails,
  refetchAndResetStep,
  currentFlowType
}: PackageTypeFormProps) => {
  const [updateOrdererPackage, { error, loading }] = useMutation<
    UpdateOrdererPackage,
    UpdateOrdererPackageVariables
  >(SELECT_ORDERER_PACKAGE_TYPE);

  const initialPackageTypeIndex = PackageTypeValueEnum[myCompanyCreationDetails?.packageType!] ?? 0;
  const [selectedIndex, setSelectedIndex] = useState(initialPackageTypeIndex);

  const packageTypes = availablePackageTypes.map(
    (item) =>
      ({
        title: t(`admin-service:package-type.${item.name}`),
        value: item.name,
        disabled: item.disabled
      } as OptionItem)
  );

  const submitHandler = async (values) => {
    try {
      const apiPackageType = PackageTypeEnum[PackageTypeValueEnum[values.packageType]];

      await updateOrdererPackage({
        variables: {
          companyCreationId: myCompanyCreationDetails?.id,
          selectPackage: apiPackageType
        }
      });

      await refetchAndResetStep(myCompanyCreationDetails.id);
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to Update Admin Service Package:`);
      logger.error(error);
    }
  };

  const handleAddressChange = (event) => {
    const newPackageType: PackageTypeValueEnum = +event.target["value"];
    setSelectedIndex(newPackageType);
    formik.setFieldValue("packageType", newPackageType);
  };

  const formik = useFormik({
    initialValues: {
      packageType: initialPackageTypeIndex
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: submitHandler
  });

  const render = () => {
    return (
      <FormikProvider value={formik}>
        <div>
          <Row>
            <Col className="steps-form-description">
              {t("admin-service:packageType-form-description")}
            </Col>
          </Row>
          <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
            <Container>
              <Row>
                <Col>{renderForm()}</Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <LoadingAlert t={t} loading={loading} />
                  <ErrorAlertLegacy t={t} error={error?.message} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </FormikProvider>
    );
  };

  const renderForm = () => {
    const presetOptions: RadioOptionElement[] = packageTypes.map(
      (item) =>
        ({
          label: item.title,
          disabled: item.disabled
        } as RadioOptionElement)
    );

    const currentPackageType = PackageTypeEnum[packageTypes[selectedIndex].value!];
    const selectedPackageDetails = getPackageDetailsByPackageType(currentPackageType)!;

    return (
      <Form>
        <Radios
          className="admin-service-radio"
          name="packageType"
          selectedIndex={selectedIndex}
          type="radio"
          options={presetOptions}
          handleChange={handleAddressChange}
          children={
            <PackageInformation
              currentPackageType={currentPackageType}
              selectedPackageTypeDetails={selectedPackageDetails}
              t={t}
            />
          }
        />
        <Row className="mt-3">
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                data-testid="submit"
                className="step-submit-btn"
                variant="outline-light">
                {t("generic:save")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  return render();
};
