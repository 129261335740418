import { FlowTypeEnum } from "../../../../global-query-types";
import { FC } from "react";
import AdminService from "./AdminService";
import { GetMe } from "../../../../lib/api/queries/MeQuery";
import React from "react";

interface AdminServiceContainerProps {
  flowTypeOnboarding: FlowTypeEnum;
}

const AdminServiceContainer: FC<AdminServiceContainerProps> = ({ flowTypeOnboarding }) => {
  const meRequest = GetMe();
  return <AdminService flowTypeOnboarding={flowTypeOnboarding} meRequest={meRequest} />;
};

export default AdminServiceContainer;
