import React, { PropsWithChildren } from "react";
import { ButtonProps } from "react-bootstrap";
import { CloseIcon } from "sharedComponents/icons/CloseIcon";

export interface ButtonCloseProps extends PropsWithChildren<ButtonProps> {
  onClick: () => void;
}

const ButtonClose = ({ onClick }: ButtonCloseProps) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <div data-testid="button-close" className="button-close" onClick={handleClick}>
      <CloseIcon />
    </div>
  );
};

export default ButtonClose;
