import { ActionableTask } from "common/types/ProcessStatesTypes";
import {
  BankAccountPlan,
  BankType,
  BrokerBankEnum,
  BrokerNameEnum,
  CompanyCreationStep,
  FlowTypeEnum,
  InvoiceSource,
  LegalForm,
  OrderCompanyStatusEnum,
  OrderProductTypeEnum,
  RolesInGroup,
  SalutationType,
  TaskStatesEnum,
  TaskType,
  TaxAdvisorStatusEnum,
  TaxServiceTypeEnum,
  UserRole
} from "global-query-types";
import { StorageConfig } from "./components/GetStorageConfig";
import { getCompanyCreationSteps_getCompanyCreationSteps } from "lib/api/queries/graphql/getCompanyCreationSteps";
import {
  CompanyById_companyById,
  CompanyById_companyById_companyCreation,
  CompanyById_companyById_myTaxService
} from "lib/api/queries/graphql/CompanyById";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";

export const exampleCompanyOldGSFlowInProgress: CompanyById_companyById = {
  __typename: "Company",
  id: "ckiiylr0f0000r5s18blg3osn",
  name: "Peter Family Company",
  legalForm: LegalForm.GmbH,
  registration: "HRB 113246",
  registerCourt: "ArnZwI",
  notarizationDate: "2020-07-12T00:00:00.000Z",
  applicationForEntryDate: "2021-07-12T00:00:00.000Z",
  commercialRegisterEntryDate: "2022-07-12T00:00:00.000Z",
  economicId: "5678",
  taxNumber: "1233456",
  lei: "99999",
  leiOrderSource: null,
  leiIssueStatus: null,
  vatNumber: "67890",
  clientNumber: "123456",
  thirdPartyTaxAdvisorClientNumber: "1234567890",
  businessPurpose: "My purpose",
  externalStorageNodes: null,
  adminService: null,
  locked: false,
  asUpgradeRequiredFrom: null,
  asUpgradeMandatory: null,
  customerSignedAdminServiceContract: null,
  companyCreation: {
    __typename: "CompanyCreation",
    id: "123",
    processStates: {
      order_companyOrder: { selected: "IN_PROGRESS" }
    },
    currentStep: CompanyCreationStep.SHAREHOLDERS,
    flowType: FlowTypeEnum.FOUNDER_HOLDING
  },
  brokerageAccounts: [
    {
      __typename: "BrokerageAccount",
      brokerBank: BrokerBankEnum.IB,
      brokerName: BrokerNameEnum.FXFlat,
      id: "brokerage-account-id",
      tokenUpdatedAt: "2020-08-31T11:00:00.000Z", // TODO: Change it to 2020-08-31T00:00:00.000Z after update Node version 16+
      token: "some-token",
      queryId: "query-id",
      errorCodes: null,
      accountNumber: null,
      validity: null,
      isTokenExist: null
    }
  ],
  brokerageSurvey: null,
  wepaService: null,
  myTaxService: null,
  businessAddress: {
    __typename: "Address",
    street: "musterstr 123",
    streetLine2: "Haus 3",
    city: "musterstadt",
    postalCode: "12345",
    country: "Germany"
  },
  group: {
    __typename: "Group",
    memberships: [
      {
        __typename: "Membership",
        share: { __typename: "MembershipShare", share: 20 },
        company: null,

        role: RolesInGroup.Director,
        person: {
          __typename: "Person",
          id: "ckhx94c7cip5q0714wyifp899",
          firstName: "Marco",
          lastName: "Mondini",
          citizenship: "Germany",
          personalTaxId: "123456789",
          phoneNumber: "536452341",
          placeOfBirth: "Berlin",
          user: {
            __typename: "User",
            id: "ckhx94c7cip5q0714wyifp899",
            email: "user@ride.capital"
          },
          address: {
            __typename: "Address",
            street: "musterstr 7",
            streetLine2: "Haus 3",
            city: "musterstadt",
            postalCode: "12345",
            country: "Germany"
          },
          dateOfBirth: "1979-06-24T00:00:00.000Z",
          salutation: SalutationType.Mr
        }
      },
      {
        __typename: "Membership",
        share: { __typename: "MembershipShare", share: 80 },
        company: null,
        role: RolesInGroup.Shareholder,
        person: {
          __typename: "Person",
          id: "ckhx94c7cip5q0714wyif2399",
          firstName: "Bogdan",
          lastName: "Szabo",
          citizenship: "Germany",
          personalTaxId: "6745684523",
          phoneNumber: "695768564",
          placeOfBirth: "Munich",
          user: {
            __typename: "User",
            id: "dkhx94c7cip5q0711234wyifp899",
            email: "bogdan.szabo@ride.capital"
          },
          address: {
            __typename: "Address",
            street: "musterstr 200",
            streetLine2: "Haus 3",
            city: "musterstadt",
            postalCode: "12345",
            country: "Germany"
          },
          dateOfBirth: null,
          salutation: SalutationType.Mr
        }
      }
    ]
  },
  fiscalYear: null,
  accountingFramework: null,
  bankAccounts: [
    {
      __typename: "BankAccount",
      bankProvider: BankType.Fyrst,
      iban: "DE123123WEWE123123123",
      id: "bankaccount-id",
      plan: BankAccountPlan.Fyrst_Base,
      name: "my bank account"
    }
  ],
  finApiAccount: null,
  orderStatus: null,
  companyFoundationOrderId: null,
  orders: [],
  migrations: [],
  wepaId: null
};

export const exampleCompanyWithSilentPartners: CompanyById_companyById = {
  __typename: "Company",
  id: "ckiiylr0f0000r5s18blg3osn",
  name: "Peter Family Company",
  legalForm: LegalForm.GmbH,
  registration: "HRB 113246",
  registerCourt: "ArnZwI",
  notarizationDate: "2020-07-12T00:00:00.000Z",
  applicationForEntryDate: "2021-07-12T00:00:00.000Z",
  commercialRegisterEntryDate: "2022-07-12T00:00:00.000Z",
  economicId: "5678",
  taxNumber: "1233456",
  lei: "99999",
  leiOrderSource: null,
  leiIssueStatus: null,
  vatNumber: "67890",
  clientNumber: "123456",
  thirdPartyTaxAdvisorClientNumber: "1234567890",
  businessPurpose: "My purpose",
  externalStorageNodes: null,
  adminService: null,
  locked: false,
  asUpgradeRequiredFrom: null,
  asUpgradeMandatory: null,
  customerSignedAdminServiceContract: null,
  companyCreation: {
    __typename: "CompanyCreation",
    id: "123",
    processStates: {
      order_companyOrder: { selected: "IN_PROGRESS" }
    },
    currentStep: CompanyCreationStep.SHAREHOLDERS,
    flowType: FlowTypeEnum.FOUNDER_HOLDING
  },
  brokerageAccounts: [
    {
      __typename: "BrokerageAccount",
      brokerBank: BrokerBankEnum.IB,
      brokerName: BrokerNameEnum.FXFlat,
      id: "brokerage-account-id",
      tokenUpdatedAt: "2020-08-31T11:00:00.000Z", // TODO: Change it to 2020-08-31T00:00:00.000Z after update Node version 16+
      token: "some-token",
      queryId: "query-id",
      errorCodes: null,
      accountNumber: null,
      validity: null,
      isTokenExist: null
    }
  ],
  brokerageSurvey: null,
  wepaService: null,
  myTaxService: null,
  businessAddress: {
    __typename: "Address",
    street: "musterstr 123",
    streetLine2: "Haus 3",
    city: "musterstadt",
    postalCode: "12345",
    country: "Germany"
  },
  group: {
    __typename: "Group",
    memberships: [
      {
        __typename: "Membership",
        share: { __typename: "MembershipShare", share: 20 },
        company: null,
        role: RolesInGroup.Director,
        person: {
          __typename: "Person",
          id: "ckhx94c7cip5q0714wyifp899",
          firstName: "Marco",
          lastName: "Mondini",
          citizenship: "Germany",
          personalTaxId: "945698345",
          phoneNumber: "92472348756",
          placeOfBirth: "Munich",
          user: {
            __typename: "User",
            id: "user-mondini-id",
            email: "marco.mondini@ride.capital"
          },
          address: {
            __typename: "Address",
            street: "musterstr 7",
            streetLine2: "Haus 3",
            city: "musterstadt",
            postalCode: "12345",
            country: "Germany"
          },
          dateOfBirth: "1979-06-24T00:00:00.000Z",
          salutation: SalutationType.Mr
        }
      },
      {
        __typename: "Membership",
        share: { __typename: "MembershipShare", share: 10 },
        company: null,
        role: RolesInGroup.SilentPartner,
        person: {
          __typename: "Person",
          id: "ckhx94c7cip5q0714wyif2399",
          firstName: "Bogdan",
          lastName: "Szabo",
          citizenship: "Germany",
          personalTaxId: "64745684523",
          phoneNumber: "6925768564",
          placeOfBirth: "Munich",
          user: {
            __typename: "User",
            id: "bogdan-user-id",
            email: "some-email-for-bogdan@ride.capital"
          },
          address: {
            __typename: "Address",
            street: "musterstr 200",
            streetLine2: "Haus 3",
            city: "musterstadt",
            postalCode: "12345",
            country: "Germany"
          },
          dateOfBirth: null,
          salutation: SalutationType.Mr
        }
      },
      {
        __typename: "Membership",
        share: { __typename: "MembershipShare", share: 5 },
        company: null,
        role: RolesInGroup.SilentPartner,
        person: {
          __typename: "Person",
          id: "ckhx94c7cip5q0714wyif2399",
          firstName: "Rick",
          lastName: "Sanchez",
          citizenship: "Germany",
          personalTaxId: "61745684523",
          phoneNumber: "6395768564",
          placeOfBirth: "Munich",
          user: {
            __typename: "User",
            id: "rick-sanchez-user-id",
            email: "rick.sanchez@ride.capital"
          },
          address: {
            __typename: "Address",
            street: "musterstr 201",
            streetLine2: "Haus 3",
            city: "musterstadt",
            postalCode: "12345",
            country: "Germany"
          },
          dateOfBirth: null,
          salutation: SalutationType.Mr
        }
      }
    ]
  },
  fiscalYear: null,
  accountingFramework: null,
  bankAccounts: [],
  finApiAccount: null,
  orderStatus: null,
  companyFoundationOrderId: null,
  orders: [],
  migrations: [],
  wepaId: null
};

export const companyWithOnlyGrundungService = (): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    companyCreation: {
      __typename: "CompanyCreation",
      id: "123",
      processStates: {
        someRef: { selected: "COMPLETED", states: [] }
      },
      currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
      flowType: FlowTypeEnum.FOUNDER_HOLDING
    } as CompanyById_companyById_companyCreation,
    adminService: null,
    myTaxService: null
  };
};

export const companyWithOnlyGrundungServiceOnPending = (): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    companyCreation: {
      __typename: "CompanyCreation",
      id: "123",
      processStates: {
        someRef: { selected: "IN_PROGRESS", states: [] }
      },
      currentStep: CompanyCreationStep.ORDER,
      flowType: FlowTypeEnum.FOUNDER_HOLDING
    } as CompanyById_companyById_companyCreation,
    adminService: null,
    myTaxService: null
  };
};

export const companyWithOnlyLowTaxBroker = (
  status: OrderCompanyStatusEnum = OrderCompanyStatusEnum.Completed,
  deletedAt: any = null
): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    companyCreation: null,
    brokerageAccounts: [
      {
        __typename: "BrokerageAccount",
        id: "ltb-id",
        accountNumber: "123456789",
        brokerBank: BrokerBankEnum.LTB,
        brokerName: BrokerNameEnum.LTB,
        errorCodes: null,
        isTokenExist: true,
        queryId: "query-id",
        token: "some-token",
        tokenUpdatedAt: "2020-08-31T11:00:00.000Z",
        validity: null
      }
    ],
    orders: [
      {
        id: "ltb-order",
        submissionId: "submission-id",
        productType: OrderProductTypeEnum.BrokerageAccount,
        __typename: "OrderDetails",
        extra: {},
        status: status,
        deletedAt: deletedAt
      }
    ]
  };
};

export const companyWithASAndBrokerOrders = (
  asOrderStatus: OrderCompanyStatusEnum = OrderCompanyStatusEnum.Completed,
  brokerOrderStatus: OrderCompanyStatusEnum = OrderCompanyStatusEnum.Completed,
  deletedAt: any = null
): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    companyCreation: null,
    brokerageAccounts: [
      {
        __typename: "BrokerageAccount",
        id: "ltb-id",
        accountNumber: "123456789",
        brokerBank: BrokerBankEnum.LTB,
        brokerName: BrokerNameEnum.LTB,
        errorCodes: null,
        isTokenExist: true,
        queryId: "query-id",
        token: "some-token",
        tokenUpdatedAt: "2020-08-31T11:00:00.000Z",
        validity: null
      }
    ],
    orders: [
      {
        id: "ltb-order",
        submissionId: "submission-id",
        productType: OrderProductTypeEnum.BrokerageAccount,
        __typename: "OrderDetails",
        extra: {},
        status: brokerOrderStatus,
        deletedAt: deletedAt
      },
      {
        id: "as-order",
        submissionId: "as-submission-id",
        productType: OrderProductTypeEnum.AdminService,
        __typename: "OrderDetails",
        extra: {},
        status: asOrderStatus,
        deletedAt: deletedAt
      }
    ]
  };
};

export const companyWithLTBInProgress = () => {
  let company = companyWithOnlyLowTaxBroker();
  company.orders[0].status = OrderCompanyStatusEnum.InProgress;
  company.brokerageAccounts = [];
  return company;
};

export const companyWithLTBCanceled = () => {
  let company = companyWithOnlyLowTaxBroker();
  company.orders[0].status = OrderCompanyStatusEnum.Canceled;
  company.brokerageAccounts = [];
  return company;
};

export const companyWithOnlyAdminServiceActivated = (): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: {
      __typename: "CompanyCreation",
      id: "123",
      currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
      flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
      isArchived: false,
      canceledAt: null,
      orderedAt: "2020-02-01T00:00:00.000Z",
      tier: null,
      invoicedBy: InvoiceSource.Ride
    },
    companyCreation: null,
    myTaxService: {
      __typename: "TaxService",
      id: "tax-service-id",
      type: null,
      taxAdvisorStatus: TaxAdvisorStatusEnum.ACTIVE,
      advisor: {
        __typename: "Company",
        id: "tax-advisor-id",
        name: "Tax Advisor",
        legalForm: LegalForm.GmbH
      }
    }
  };
};

export const companyWithOnlyAdminServiceWithAssignedTaxAdvisorStatus =
  (): CompanyById_companyById => {
    return {
      ...companyWithOnlyAdminServiceActivated(),
      myTaxService: {
        ...companyWithOnlyAdminServiceActivated().myTaxService,
        taxAdvisorStatus: TaxAdvisorStatusEnum.ASSIGNED
      } as CompanyById_companyById_myTaxService
    };
  };

export const companyWithOnlyAdminServiceOnPending = (
  isArchived = false,
  orderedAt: string | null = null
): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: {
      __typename: "CompanyCreation",
      id: "123",
      currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
      flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
      isArchived: isArchived,
      canceledAt: null,
      orderedAt: orderedAt,
      tier: null,
      invoicedBy: InvoiceSource.Ride
    },
    companyCreation: null,
    myTaxService: null
  };
};

export const companyWithOnlyAdminServiceWaitingForConfirmation = (
  isArchived = false,
  orderedAt: string = "2023-01-01T00:00:00.000Z"
): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: {
      __typename: "CompanyCreation",
      id: "123",
      currentStep: CompanyCreationStep.ORDERED,
      flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
      isArchived: isArchived,
      canceledAt: null,
      orderedAt: orderedAt,
      tier: null,
      invoicedBy: InvoiceSource.Ride
    },
    companyCreation: null,
    myTaxService: null
  };
};

export const companyWithAdminServiceTierSelected = (
  tier: AdminServiceTier
): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: {
      __typename: "CompanyCreation",
      id: "123",
      currentStep: CompanyCreationStep.ORDERED,
      flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
      isArchived: null,
      canceledAt: null,
      orderedAt: null,
      tier: tier,
      invoicedBy: InvoiceSource.Ride
    },
    companyCreation: null,
    myTaxService: null,
    orders: [
      {
        productType: OrderProductTypeEnum.AdminService,
        status: OrderCompanyStatusEnum.InProgress,
        id: "order-id",
        extra: {
          onboarding: { tierSelection: true }
        },
        __typename: "OrderDetails",
        deletedAt: null,
        submissionId: "submission-id"
      }
    ]
  };
};

export const companyWithAdminServiceTierActive = (
  tier: AdminServiceTier
): CompanyById_companyById => {
  return {
    ...companyWithAdminServiceTierSelected(tier),
    myTaxService: companyWithOnlyAdminServiceActivated().myTaxService
  };
};

export const companyWithOnlyAdminServiceDidntSignContractAndIsRequiredToSign =
  (): CompanyById_companyById => {
    return {
      ...exampleCompanyOldGSFlowInProgress,
      asUpgradeRequiredFrom: "2020-02-10T00:00:00.000Z",
      customerSignedAdminServiceContract: null,
      adminService: {
        __typename: "CompanyCreation",
        id: "123",
        currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
        flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
        isArchived: false,
        canceledAt: null,
        orderedAt: "2020-02-01T00:00:00.000Z",
        tier: null,
        invoicedBy: InvoiceSource.Ride
      },
      companyCreation: null,
      myTaxService: {
        __typename: "TaxService",
        id: "tax-service-id",
        type: null,
        taxAdvisorStatus: TaxAdvisorStatusEnum.ACTIVE,
        advisor: {
          __typename: "Company",
          id: "tax-advisor-id",
          name: "Tax Advisor",
          legalForm: LegalForm.GmbH
        }
      }
    };
  };

export const companyWithOnlyWepaService = (
  taxAdvisorStatus: TaxAdvisorStatusEnum = TaxAdvisorStatusEnum.ACTIVE
): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: null,
    companyCreation: null,
    wepaService: {
      __typename: "WepaService",
      id: "wepa-service-id"
    },
    myTaxService: {
      __typename: "TaxService",
      id: "tax-service-id-1",
      type: TaxServiceTypeEnum.WepaService,
      taxAdvisorStatus: taxAdvisorStatus,
      advisor: {
        __typename: "Company",
        id: "tax-advisor-1",
        legalForm: LegalForm.GmbH,
        name: "tax advisor"
      }
    }
  };
};

export const companyWithOnlyWepaId = (): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: null,
    companyCreation: null,
    wepaService: null,
    myTaxService: null,
    wepaId: 238746237
  };
};

export const companyWithAdminServiceAndWepaService = (): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: {
      __typename: "CompanyCreation",
      id: "123",
      currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
      flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
      isArchived: false,
      canceledAt: null,
      orderedAt: "2020-02-01T00:00:00.000Z",
      tier: null,
      invoicedBy: InvoiceSource.Ride
    },
    companyCreation: null,
    myTaxService: {
      __typename: "TaxService",
      id: "tax-service-id",
      type: TaxServiceTypeEnum.WepaService,
      taxAdvisorStatus: TaxAdvisorStatusEnum.ACTIVE,
      advisor: {
        __typename: "Company",
        id: "tax-advisor-id",
        name: "Tax Advisor",
        legalForm: LegalForm.GmbH
      }
    }
  };
};

export const companyWithGrundungAndAdminService = (isArchived = false): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: {
      __typename: "CompanyCreation",
      id: "123",
      currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
      flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
      isArchived: isArchived,
      canceledAt: null,
      orderedAt: "2020-02-01T00:00:00.000Z",
      tier: "standard",
      invoicedBy: InvoiceSource.Ride
    },
    companyCreation: {
      __typename: "CompanyCreation",
      id: "123",
      processStates: {
        someRef: { selected: "COMPLETED", states: [] }
      },
      currentStep: "ORDERED",
      flowType: FlowTypeEnum.FOUNDER_HOLDING
    } as CompanyById_companyById_companyCreation,
    myTaxService: {
      __typename: "TaxService",
      id: "tax-service-id",
      type: null,
      taxAdvisorStatus: TaxAdvisorStatusEnum.ACTIVE,
      advisor: {
        __typename: "Company",
        id: "tax-advisor-id",
        name: "Tax Advisor",
        legalForm: LegalForm.GmbH
      }
    }
  };
};

export const companyWithGrundungAndAdminAndWepaService = (): CompanyById_companyById => {
  return {
    ...exampleCompanyOldGSFlowInProgress,
    adminService: {
      __typename: "CompanyCreation",
      id: "123",
      currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
      flowType: FlowTypeEnum.ADMIN_SERVICE_ONBOARDING,
      isArchived: false,
      canceledAt: null,
      orderedAt: "2020-02-01T00:00:00.000Z",
      tier: null,
      invoicedBy: InvoiceSource.Ride
    },
    companyCreation: {
      __typename: "CompanyCreation",
      id: "123",
      processStates: {
        someRef: { selected: "COMPLETED", states: [] }
      },
      currentStep: CompanyCreationStep.EMAIL_CONFIRMED,
      flowType: FlowTypeEnum.FOUNDER_HOLDING
    } as CompanyById_companyById_companyCreation,
    myTaxService: {
      __typename: "TaxService",
      id: "tax-service-id",
      type: TaxServiceTypeEnum.WepaService,
      taxAdvisorStatus: TaxAdvisorStatusEnum.ACTIVE,
      advisor: {
        __typename: "Company",
        id: "tax-advisor-id",
        name: "Tax Advisor",
        legalForm: LegalForm.GmbH
      }
    }
  };
};
const standardReadPermissions = {
  admin: false,
  create: false,
  read: true,
  rename: false,
  overwrite: false
};

const standardUploadOnlyPermissions = {
  admin: false,
  create: true,
  read: true,
  rename: false,
  overwrite: false
};

export const exampleStorageConfig: StorageConfig = {
  company: {
    ref: "companyRoot",
    permissions: { client: standardReadPermissions },
    basicStructure: [
      {
        ref: "not-relevant",
        name: "not relevant",
        permissions: { client: standardUploadOnlyPermissions },
        subFolders: []
      }
    ],
    adminServiceStructure: [
      {
        ref: "sub-folder-1",
        name: "Sub folder 1",
        permissions: { client: standardUploadOnlyPermissions },
        subFolders: []
      },
      {
        ref: "sub-folder-2",
        name: "Sub folder 2",
        permissions: { client: standardUploadOnlyPermissions },
        subFolders: [
          {
            ref: "sub-sub-folder-3",
            name: "Sub sub folder 3",
            permissions: { client: standardUploadOnlyPermissions },
            subFolders: []
          },
          {
            ref: "sub-sub-folder-4",
            name: "Sub sub folder 4",
            permissions: { client: standardReadPermissions },
            subFolders: []
          },
          {
            ref: "sub-sub-folder-5",
            name: "Sub sub folder 5",
            permissions: { client: standardUploadOnlyPermissions },
            subFolders: []
          }
        ]
      },
      {
        ref: "sub-folder-6",
        name: "Sub folder 6",
        permissions: { client: standardReadPermissions },
        subFolders: [
          {
            ref: "sub-sub-folder-7",
            name: "Sub sub folder 7",
            permissions: { client: standardReadPermissions },
            subFolders: [
              {
                ref: "sub-sub-sub-folder-8",
                name: "Sub sub sub folder 8",
                permissions: { client: standardUploadOnlyPermissions },
                subFolders: []
              }
            ]
          }
        ]
      }
    ],
    wepaStructure: []
  },
  person: {
    ref: "personRoot",
    permissions: { client: standardReadPermissions },
    basicStructure: [
      {
        ref: "not-relevant 2",
        name: "not relevant 2",
        permissions: { client: standardUploadOnlyPermissions },
        subFolders: []
      }
    ]
  }
};

export const exampleTaskData: ActionableTask = {
  ref: "notary_appointment",
  state: TaskStatesEnum.COMPLETED,
  responsibleParty: UserRole.Client,
  updatedAt: new Date(Date.parse("2020-02-01T00:00:00.000Z")),
  type: TaskType.GENERIC
};

export const aNomineeTask: ActionableTask = {
  ref: "appointment_request",
  state: TaskStatesEnum.TO_BE_DONE,
  responsibleParty: UserRole.Nominee,
  updatedAt: new Date(Date.parse("2020-02-01T00:00:00.000Z")),
  type: TaskType.GENERIC
};

export const inProgressExampleTaskData: ActionableTask = {
  ref: "appointment_request",
  state: TaskStatesEnum.NOT_POSSIBLE_YET,
  responsibleParty: UserRole.Client,
  updatedAt: new Date(Date.parse("2020-02-01T00:00:00.000Z")),
  type: TaskType.GENERIC
};

export const aClientTask: ActionableTask = {
  ref: "appointment_request",
  state: TaskStatesEnum.TO_BE_DONE,
  responsibleParty: UserRole.Client,
  updatedAt: new Date(Date.parse("2020-02-01T00:00:00.000Z")),
  type: TaskType.GENERIC
};

export const processSteps: getCompanyCreationSteps_getCompanyCreationSteps[] = [
  {
    __typename: "ProcessGroup",
    ref: "order",
    state: TaskStatesEnum.IN_PROGRESS,
    steps: [
      {
        __typename: "ProcessStep",
        ref: "companyOrder",
        name: "companyOrder",
        state: "closed",
        tasks: [
          {
            __typename: "ProcessTask",
            ref: "orderFormFill",
            state: TaskStatesEnum.COMPLETED,
            options: null,
            responsibleParty: UserRole.Client,
            updatedAt: null,
            type: TaskType.GENERIC
          },
          {
            __typename: "ProcessTask",
            ref: "rideAccountSetup",
            state: TaskStatesEnum.COMPLETED,
            options: null,
            responsibleParty: UserRole.Nominee,
            updatedAt: null,
            type: TaskType.GENERIC
          }
        ]
      }
    ]
  }
];

export const anotherProcessSteps: getCompanyCreationSteps_getCompanyCreationSteps[] = [
  {
    __typename: "ProcessGroup",
    ref: "order",
    state: TaskStatesEnum.IN_PROGRESS,
    steps: [
      {
        __typename: "ProcessStep",
        ref: "companyOrder",
        name: "companyOrder",
        state: "closed",
        tasks: [
          {
            __typename: "ProcessTask",
            ref: "orderFormFill",
            state: TaskStatesEnum.TO_BE_DONE,
            options: null,
            responsibleParty: UserRole.Client,
            updatedAt: null,
            type: TaskType.GENERIC
          },
          {
            __typename: "ProcessTask",
            ref: "rideAccountSetup",
            state: TaskStatesEnum.TO_BE_DONE,
            options: null,
            responsibleParty: UserRole.Nominee,
            updatedAt: null,
            type: TaskType.GENERIC
          }
        ]
      }
    ]
  }
];

export const notRequiredAndOpenStateSteps: getCompanyCreationSteps_getCompanyCreationSteps[] = [
  {
    __typename: "ProcessGroup",
    ref: "order",
    state: TaskStatesEnum.IN_PROGRESS,
    steps: [
      {
        __typename: "ProcessStep",
        ref: "companyOrder",
        name: "companyOrder",
        state: "notRequired",
        tasks: []
      },
      {
        __typename: "ProcessStep",
        ref: "companyOrder",
        name: "companyOrder",
        state: "open",
        tasks: []
      }
    ]
  }
];

export const notRequiredAndClosedStateSteps: getCompanyCreationSteps_getCompanyCreationSteps[] = [
  {
    __typename: "ProcessGroup",
    ref: "order",
    state: TaskStatesEnum.COMPLETED,
    steps: [
      {
        __typename: "ProcessStep",
        ref: "companyOrder",
        name: "companyOrder",
        state: "notRequired",
        tasks: []
      },
      {
        __typename: "ProcessStep",
        ref: "companyOrder",
        name: "companyOrder",
        state: "closed",
        tasks: []
      }
    ]
  }
];

export const exampleGSTask: ActionableTask = {
  ref: "some-ref",
  state: TaskStatesEnum.TO_BE_DONE,
  options: {
    type: "",
    data: {}
  },
  responsibleParty: UserRole.Client,
  updatedAt: new Date(),
  type: TaskType.GENERIC
};
