import { GetNomineeCompanyDetailsById } from "../../../lib/api/queries/GetNomineeCompanyDetailsById";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { UserRole } from "../../../global-query-types";
import React from "react";
import AdminFiscalYear from "./AdminFiscalYear";

interface AdminFiscalYearSceneContainerProps {
  companyId: string;
}
export const AdminFiscalYearContainer = ({ companyId }: AdminFiscalYearSceneContainerProps) => {
  const companyRequest = GetNomineeCompanyDetailsById(companyId);
  const company = companyRequest.data?.companyById;

  return (
    <RequestFeedbackHandler requests={[companyRequest]}>
      {company && <AdminFiscalYear company={company} role={UserRole.Nominee} />}
    </RequestFeedbackHandler>
  );
};
