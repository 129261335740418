import * as Yup from "yup";
import { LegalForm } from "../../global-query-types";
import { ensureVatValidation } from "../../common/vatValidator";
import { OwnershipFieldValidationSchema } from "../../components/Investments/Ownership/OwnershipField";
import { cityRegex, postalCode } from "./common";

export const AddUserCompanySchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("generic:validation-required"),
    legalForm: Yup.mixed<LegalForm>().oneOf(Object.values(LegalForm)),
    companyValue: Yup.number()
      .positive("generic:must-be-positive-number")
      .required("generic:validation-required"),

    capitalContribution: Yup.number().min(0, "generic:must-be-positive-number"),
    street: Yup.string(),
    city: Yup.string().matches(cityRegex, {
      message: "generic:validation-only-letters-spaces-dash"
    }),
    postalCode: Yup.string().matches(postalCode, { message: "generic:validation-only-numbers" }),
    registration: Yup.string(),
    registerCourt: Yup.string(),
    vatNumber: Yup.string().test("vatValidator", "generic:invalid-vatNumber", ensureVatValidation),
    taxNumber: Yup.string(),
    percentageOwnership: Yup.number()
      .min(1, "generic:percentage-ownership-min-1")
      .max(100, "generic:percentage-ownership-max-100")
      .required("generic:validation-required"),
    ...OwnershipFieldValidationSchema
  });
};
