import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideCurrencyInput } from "uiLibrary/components/RideCurrencyInput/RideCurrencyInput";
import RideIcon from "uiLibrary/components/RideIcon/RideIcon";
import { useField } from "formik";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { RideModalBody, RideModalControls } from "uiLibrary/designSystem/RideModal/RideModal";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export interface PayOutInputStepProps {
  companyName: string;
  iban: string;
  onClickNext: () => void;
}

const PayOutInputStep = ({
  t,
  companyName,
  iban,
  onClickNext
}: PayOutInputStepProps & WithTranslation) => {
  const moduleName = "pay-out-input-step";

  const [amountField] = useField({
    name: "amount",
    validate: (value) => {
      if (value === undefined || value === "") {
        return t("generic:validation-required") as string;
      }
      if (Number.parseFloat(value) <= 0) {
        return t("generic:must-be-positive-number") as string;
      }
    }
  });

  return (
    <>
      <RideModalBody>
        <div className={moduleName} data-testid={moduleName}>
          <div className={`${moduleName}__transfer-view`}>
            <span data-testid="ltb-account-label" className={`${moduleName}__account-text`}>
              {t("generic:low-tax-broker-dashboard.pay-out-modal.input.low-tax-broker-account")}
            </span>
            <RideIcon dataTestId="icon" name="arrow-right" />
            <div className={`${moduleName}__company-info`}>
              <span data-testid="company-name" className={`${moduleName}__company-name`}>
                {companyName}
              </span>
              <span data-testid="iban" className={`${moduleName}__company-iban`}>
                {iban}
              </span>
            </div>
          </div>
          <RideCurrencyInput name="amount" />
          {!!amountField.value && (
            <RideAlertBar
              className={`${moduleName}__alert`}
              type={AlertType.INFO}
              title={t("generic:low-tax-broker-dashboard.pay-out-modal.input.alert-title")}
              message={t("generic:low-tax-broker-dashboard.pay-out-modal.input.alert-message")}
            />
          )}
        </div>
      </RideModalBody>
      <RideModalControls>
        <RideButtonDeprecated
          width={ButtonWidth.FULL}
          radius={ButtonRadius.Soft}
          data-testid={"next-button"}
          disabled={!amountField.value}
          onClick={onClickNext}
          type={"button"}>
          {t("generic:next")}
        </RideButtonDeprecated>
      </RideModalControls>
    </>
  );
};

export default withTranslationReady(["generic"])(PayOutInputStep);
