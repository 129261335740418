import React from "react";

export const RideLogoMinimalist = ({ width = "15", height = "11", fill = "currentColor" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2614 7.14853L15 10.7995H11.822L9.51994 7.67051H7.87564V10.7995H5.22775V5.25818H9.83179C10.8807 5.25818 11.649 4.67131 11.649 3.79839C11.649 2.95496 10.8807 2.3681 9.83179 2.3681H9.53979V0H9.87998C11.2124 0 12.2897 0.350938 13.0892 1.03217C13.8887 1.71635 14.2941 2.63646 14.2941 3.79544C14.2941 5.27292 13.5598 6.49678 12.2614 7.14853ZM0 11V7.77078H2.48063V11H0Z"
      fill={fill}
    />
  </svg>
);
