import { TFunction } from "i18next";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { QueryResult } from "react-apollo";
import {
  MyDiscountCode,
  MyDiscountCode_myDiscountCode
} from "../../../components/public/CompanyCreation/graphql/MyDiscountCode";
import { GetMyDiscountCode } from "../../../components/public/CompanyCreation/Queries";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { formatCurrency } from "../../../common/formatters";
import { ClickToCopyDeprecated } from "client/components/ClickToCopyDeprecated/ClickToCopyDeprecated";

export const DisplayDiscountCodeContainer = ({ t }: { t: TFunction }) => {
  const getMyDiscountCodeRequest: QueryResult<MyDiscountCode> = GetMyDiscountCode();
  const discountCode = getMyDiscountCodeRequest?.data?.myDiscountCode;

  return (
    <RequestFeedbackHandler requests={[getMyDiscountCodeRequest]}>
      <DisplayDiscountCode t={t} discountCode={discountCode} />
    </RequestFeedbackHandler>
  );
};

export const DisplayDiscountCode = ({
  t,
  discountCode
}: {
  t: TFunction;
  discountCode?: MyDiscountCode_myDiscountCode | null;
}) => {
  if (!discountCode) return null;

  const { code, referrerValue } = discountCode;

  const referrerAmount = formatCurrency(referrerValue);

  return (
    <Row className="mt-5" data-testid="display-discount-code">
      <Col>
        <p data-testid="discount-code-description">
          {t("generic:company-creation.discount-code-description", { referrerAmount })}
        </p>
        <h3>
          <span data-testid="discount-code">
            <ClickToCopyDeprecated textToCopy={code} t={t} />
          </span>
        </h3>
      </Col>
    </Row>
  );
};
