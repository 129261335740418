import { TFunction } from "react-i18next";
import { Typography } from "../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "../../../../../../uiLibrary/components/RideRadioSelect/RideRadioSelect";
import React from "react";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";

const CryptoTradingQuestion = ({ t }: { t: TFunction }) => {
  return (
    <div data-testid={"crypto-trading-question"} className="trades-crypto">
      <Typography
        data-testid={"crypto-trading-title"}
        category="Headline"
        size={300}
        weight={"Heavy"}>
        {t("generic:short-order-form.company-information.crypto-trading.title")}
      </Typography>
      <Typography
        category="Paragraph"
        size={100}
        weight={"Light"}
        data-testid="crypto-trading-info"
        className="trades-crypto__description">
        {t("generic:short-order-form.company-information.crypto-trading.info")}
      </Typography>
      <RideRadioSelect
        className="company-information-step__question-yes-no"
        name={"tradesCrypto"}
        options={[
          {
            label: `${t("generic:yes")}.`,
            value: "yes"
          },
          {
            label: `${t("generic:no")}.`,
            value: "no"
          }
        ]}
        variant={RadioSelectVariant.Light}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(CryptoTradingQuestion);
