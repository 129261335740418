import gql from "graphql-tag";

export const CREATE_CLUB_DEAL_INVESTMENT = gql`
  mutation CreateClubDealInvestment($id: ID!) {
    createClubDealInvestment(id: $id) {
      feedback
      id
    }
  }
`;
