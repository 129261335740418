import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { CompanyCreationTypeEnum } from "../../../global-query-types";
import { PaginationArgs } from "../../../common/PaginationArgs";
import {
  CompanyCreationsQuery,
  CompanyCreationsQueryVariables
} from "./graphql/CompanyCreationsQuery";

export const COMPANY_CREATIONS_QUERY = gql`
  query CompanyCreationsQuery(
    $type: CompanyCreationTypeEnum
    $isArchived: Boolean
    $query: String
    $start: Int
    $limit: Int
  ) {
    companyCreations(
      type: $type
      isArchived: $isArchived
      query: $query
      start: $start
      limit: $limit
    ) {
      count
      items {
        id
        packageType
        referral
        isArchived
        type
        company {
          id
          name
          legalForm
          discountCodeUse {
            id
            discountCode {
              id
              code
            }
            status
          }
          group {
            type
            memberships {
              role
              person {
                id
                firstName
                lastName

                user {
                  id
                  invitedAt
                  hasFinApiAccount
                }
              }
              company {
                id
                name
                legalForm
              }
            }
          }
        }
        adminCompany {
          id
          name
          legalForm
          discountCodeUse {
            id
            discountCode {
              id
              code
            }
            status
          }
          group {
            type
            memberships {
              role
              person {
                id
                firstName
                lastName

                user {
                  id
                  invitedAt
                  hasFinApiAccount
                }
              }
            }
          }
        }
        createdAt
        updatedAt
        orderedAt
        currentStep
        furthestStep
        postOrderStatus
        flowType
      }
    }
  }
`;

export const GetCompanyCreations = (
  type: CompanyCreationTypeEnum,
  query: string,
  { limit, start }: PaginationArgs
) =>
  useQuery<CompanyCreationsQuery, CompanyCreationsQueryVariables>(COMPANY_CREATIONS_QUERY, {
    variables: {
      type,
      isArchived: false,
      start,
      limit,
      query: query || undefined
    },
    fetchPolicy: "no-cache"
  });
