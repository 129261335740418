import * as Yup from "yup";
import { EmailValidation } from "./EmailSchema";

export const CreateNotarySchema = Yup.object().shape({
  email: EmailValidation,
  firstName: Yup.string().required("generic:validation-required"),
  lastName: Yup.string().required("generic:validation-required"),
  moneyLaunderingQuestionnaire: Yup.string().url("generic:expected-url"),
  instructionLetter: Yup.string().url("generic:expected-url")
});
