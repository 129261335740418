import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ReviewDocumentEntry } from "../ReviewDocuments.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { StepComponentProps } from "../../../../../ChaptersOrderLayout.partials";

const ReviewCompanyDocuments = ({
  t,
  order
}: Pick<StepComponentProps<LowTaxBrokerOrderData>, "order"> & WithTranslation) => {
  return (
    <div className="review-documents" data-testid="review-company-documents">
      <div className="review-documents__subsection-title">
        {t("generic:ride-broker-onboarding.review.documents.company-documents")}
      </div>
      <div className="review-documents__list">
        <ReviewDocumentEntry
          name={t(
            "generic:ride-broker-onboarding.documents.company-documents-list.proof-of-address"
          )}
          uploaded={!!order.extra.companyDocuments?.proofOfAddressFile?.id}
        />
      </div>
    </div>
  );
};
export default withTranslationReady(["generic"])(ReviewCompanyDocuments);
