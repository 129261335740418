import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import * as QueryTypes from "./graphql/GetWepaPortfolioWithDataAvailability";

export const GET_WEPA_PORTFOLIO_WITH_DATA_AVAILABILITY = gql`
  query GetWepaPortfolioWithDataAvailability(
    $companyId: ID!
    $portfolioId: Int!
    $fiscalYearStart: String!
    $fiscalYearEnd: String!
  ) {
    getWepaPortfolio(
      companyId: $companyId
      portfolioId: $portfolioId
      fiscalYearStart: $fiscalYearStart
      fiscalYearEnd: $fiscalYearEnd
    ) {
      id
      broker
      closedOn
      identifier

      dataAvailability {
        dateFrom
        dateTo
        isAvailable
        isFirst
        isLast
      }
    }
  }
`;

export const GetWepaPortfolioWithDataAvailability = ({
  companyId,
  portfolioId,
  fiscalYearStart,
  fiscalYearEnd
}) =>
  useQuery<
    QueryTypes.GetWepaPortfolioWithDataAvailability,
    QueryTypes.GetWepaPortfolioWithDataAvailabilityVariables
  >(GET_WEPA_PORTFOLIO_WITH_DATA_AVAILABILITY, {
    fetchPolicy: "no-cache",
    variables: { companyId, portfolioId, fiscalYearStart, fiscalYearEnd }
  });
