import React, { useCallback, useEffect, useState } from "react";
import { ThirdPartyTaxAdvisorsList } from "./ThirdPartyTaxAdvisorsList";
import {
  getThirdPartyTaxAdvisorsAndPartners,
  getThirdPartyTaxAdvisorsAndPartners_PartnerTaxCompany_companies,
  getThirdPartyTaxAdvisorsAndPartners_ThirdPartyTaxService_companies
} from "../../../components/nominee/ThirdPartyTaxAdvisorsList/graphql/getThirdPartyTaxAdvisorsAndPartners";
import { getEndpoint } from "../../../common/GraphqlClient/httpLink";
import { getToken } from "../../../common/Authentication/getToken";
import LoadingAlert from "../../../components/generic/LoadingAlert";
import { TFunction } from "i18next";

export interface CompaniesFilter {
  role: "TaxAdvisor" | "ThirdPartyTaxAdvisor";
  type: "TaxService" | "ThirdPartyTaxService";
}

const useGetCompaniesWithDetails = (filter: CompaniesFilter) => {
  let [data, setData] = useState<
    getThirdPartyTaxAdvisorsAndPartners_ThirdPartyTaxService_companies[]
  >([]);
  let [loading, setLoading] = useState(false);
  const fetchPartnerTaxCompany = useCallback(async () => {
    const queryString = Object.keys(filter)
      .map((key) => key + "=" + filter[key])
      .join("&");
    const response = await fetch(
      `${getEndpoint()}/api/companies${queryString ? `?${queryString}` : ""}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        mode: "cors",
        credentials: "include"
      }
    );
    return response;
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    fetchPartnerTaxCompany()
      .then((response) => response.json())
      .then((json) => {
        let jsonList = json as getThirdPartyTaxAdvisorsAndPartners_ThirdPartyTaxService_companies[];
        let partnerTaxCompany = jsonList?.map(
          (json) =>
            ({
              ...json,
              __typename: "Company"
            } as getThirdPartyTaxAdvisorsAndPartners_ThirdPartyTaxService_companies)
        );
        return partnerTaxCompany;
      })
      .then((partnerTaxCompany) => {
        setData(partnerTaxCompany);
        setLoading(false);
      });
  }, [fetchPartnerTaxCompany, filter]);

  return { data, loading };
};

const useCompanyNames = () => {
  let [data, setData] = useState<getThirdPartyTaxAdvisorsAndPartners_PartnerTaxCompany_companies[]>(
    []
  );
  let [loading, setLoading] = useState(false);

  const fetchCompanyNames = useCallback(async () => {
    const response = await fetch(`${getEndpoint()}/api/tax-advisor/company/names`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      mode: "cors",
      credentials: "include"
    });
    return response;
  }, []);

  useEffect(() => {
    fetchCompanyNames()
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let jsonList = json as { id: string; name: string }[];
        let partnerTaxCompany = jsonList?.map(
          (json) =>
            ({
              __typename: "Company",
              name: json.name,
              id: json.id
            } as getThirdPartyTaxAdvisorsAndPartners_PartnerTaxCompany_companies)
        );
        return partnerTaxCompany;
      })
      .then((partnerTaxCompany) => {
        setData(partnerTaxCompany);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setData([]);
      });
  }, [fetchCompanyNames]);

  return { data, loading };
};

export interface ThirdPartyTaxAdvisorsListContainerProps {
  t: TFunction;
}

export const ThirdPartyTaxAdvisorsListContainer = ({
  t
}: ThirdPartyTaxAdvisorsListContainerProps) => {
  let [loading, setLoading] = useState(true);

  let [data, setData] = useState<getThirdPartyTaxAdvisorsAndPartners | undefined>();
  let [filter, setFilter] = useState<CompaniesFilter>({
    type: "ThirdPartyTaxService",
    role: "ThirdPartyTaxAdvisor"
  });
  const refetch = async () => {
    setFilter({ ...filter });
  };

  const { data: thirdPartyTaxAdvisorCompanies, loading: thirdPartyTaxAdvisorCompaniesLoading } =
    useGetCompaniesWithDetails(filter);
  const { data: companyNames, loading: companyNamesLoading } = useCompanyNames();

  useEffect(() => {
    setData({
      ThirdPartyTaxService: { companies: thirdPartyTaxAdvisorCompanies, __typename: "CompanyList" },
      PartnerTaxCompany: { companies: companyNames, __typename: "CompanyList" }
    });
  }, [thirdPartyTaxAdvisorCompanies, companyNames]);

  useEffect(() => {
    if (!thirdPartyTaxAdvisorCompaniesLoading && !companyNamesLoading) {
      setLoading(false);
    }
  }, [thirdPartyTaxAdvisorCompaniesLoading, companyNamesLoading]);

  return (
    <>
      {loading ? (
        <LoadingAlert t={t} noLoadingAnimation={false} />
      ) : (
        <ThirdPartyTaxAdvisorsList refetch={refetch} data={data} />
      )}
    </>
  );
};
