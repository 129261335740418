import React, { useState } from "react";
import { EChartsOption } from "echarts";
import { PieDataItemOption, PieSeriesOption } from "echarts/types/src/chart/pie/PieSeries";
import { GaugeDataItemOption, GaugeSeriesOption } from "echarts/types/src/chart/gauge/GaugeSeries";
import ChartWrapper from "../ChartWrapper/ChartWrapper";

export interface PieChartWithNumberData {
  value: number;
  name: string;
}

export interface PieChartWithNumberProps {
  data?: PieChartWithNumberData[] | undefined;
}

const getEChartsOption: (data: PieChartWithNumberData[], width: number) => EChartsOption = (
  data: PieChartWithNumberData[],
  width
) => {
  const getPieSeries: (data: PieChartWithNumberData[]) => PieSeriesOption = (
    data: PieDataItemOption[] = []
  ) =>
    ({
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2
      },
      label: {
        width: 100,
        fontSize: 10,
        show: true,
        position: "left"
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 12,
          fontWeight: "bold"
        }
      },
      labelLine: {
        show: true
      },
      data
    } as PieSeriesOption);

  const getGaugeSeries: (data: PieChartWithNumberData[], width: number) => GaugeSeriesOption = (
    data: PieDataItemOption[] = [],
    width
  ) => {
    let total =
      data.reduce((acc, curr) => {
        const number = Number(curr.value);

        return acc + number;
      }, 0) ?? 0;

    const digit = total.toString().length;

    const calculateFontSize = (width: number) => {
      if (width <= 300) {
        return 40;
      }

      return 80;
    };

    const calculateDigitScalingFactor = (digit: number) => {
      if (digit > 8) {
        return digit * 4.5;
      }

      return digit * 6.5;
    };

    return {
      type: "gauge",
      detail: {
        valueAnimation: true,
        fontSize: calculateFontSize(width) - calculateDigitScalingFactor(digit),
        offsetCenter: ["0%", "0%"]
      },
      data: [{ value: total, name: "Total" } as GaugeDataItemOption],
      progress: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      anchor: {
        show: false
      },
      title: {
        show: false
      },
      pointer: {
        show: false
      }
    } as GaugeSeriesOption;
  };

  return {
    tooltip: {
      trigger: "item"
    },
    legend: {
      top: "5%"
    },
    series: [getPieSeries(data), getGaugeSeries(data, width)]
  };
};

const PieChartWithNumber = ({ data = [] }: PieChartWithNumberProps) => {
  const [eChartsOption, setEChartsOption] = useState<EChartsOption>(getEChartsOption(data, 0));

  const onResize: (width: number) => void = (width) => {
    setEChartsOption(getEChartsOption(data, width));
  };
  return (
    <ChartWrapper
      option={eChartsOption}
      onResize={onResize}
      style={{
        height: "500px",
        maxWidth: "800px",
        minWidth: "200px"
      }}
    />
  );
};

export default PieChartWithNumber;
