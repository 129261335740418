import React from "react";
import { Link } from "react-router-dom";

interface RealEstateLinkProps {
  realEstate?: { id: string; label: string } | null;
}

export const RealEstateLink = ({ realEstate }: RealEstateLinkProps) => {
  if (!realEstate) return <></>;
  const { id, label } = realEstate;
  return (
    <Link
      to={`/admin/investment-club/investment-club-assets/${id}`}
      data-testid={`real-estate-link`}>
      {label}
    </Link>
  );
};
