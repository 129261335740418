import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const UploadIcon = ({ size = 24, className }: IconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M1.75 6.5L2.63125 7.38125L7.375 2.64375L7.375 14H8.625L8.625 2.64375L13.3687 7.38125L14.25 6.5L8 0.25L1.75 6.5Z"
      fill="currentColor"
    />
    <path
      d="M14.25 14V16.5H1.75L1.75 14H0.499998L0.499998 16.5C0.499998 16.8315 0.631694 17.1495 0.866115 17.3839C1.10054 17.6183 1.41848 17.75 1.75 17.75H14.25C14.5815 17.75 14.8995 17.6183 15.1339 17.3839C15.3683 17.1495 15.5 16.8315 15.5 16.5V14H14.25Z"
      fill="currentColor"
    />
  </svg>
);
