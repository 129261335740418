import React from "react";
import GuestLayout from "../../../sharedComponents/templates/GuestLayout/GuestLayout";
import { OrderLeiContainer } from "../../components/OrderLei/OrderLeiContainer";

const OrderLeiScene = () => (
  <GuestLayout>
    <div className="order-form-scene">
      <OrderLeiContainer />
    </div>
  </GuestLayout>
);

export default OrderLeiScene;
