import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BrokerageAccountFormContainer } from "client/components/BrokerageAccountForm/BrokerageAccountFormContainer";
import { ActionPage } from "client/components/templates/ActionPage/ActionPage";
import { useHistory } from "react-router";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface NewBrokerageAccountProps extends WithTranslation {}

const NewBrokerageAccount = ({ t }: NewBrokerageAccountProps) => {
  const history = useHistory();

  const onBackHandler = () => {
    const newUrl = history.location.pathname.split("/").slice(0, -1).join("/");
    history.push(newUrl);
  };

  return (
    <>
      <PageMetaData title={t("client:brokerage-account.page-title")} />
      <ActionPage title={t("client:brokerage-account.title-new")} onBack={onBackHandler}>
        <BrokerageAccountFormContainer />
      </ActionPage>
    </>
  );
};

export default withTranslation(["generic", "client"])(NewBrokerageAccount);
