import { TFunction } from "i18next";
import React from "react";
import { FlowTypeEnum } from "../../../../../global-query-types";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../CompanyCreation/graphql/MyCompanyCreationQuery";
import { CompanyForm } from "../../Steps/CompanyInfo/CompanyForm";

interface AdminServiceOnboardingCompanyFormProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation;
  refetchAndResetStep: (id: string) => Promise<void>;
  currentFlowType: FlowTypeEnum;
}

export const OnboardingCompanyForm = ({
  t,
  myCompanyCreationDetails,
  refetchAndResetStep,
  currentFlowType
}: AdminServiceOnboardingCompanyFormProps) => {
  return (
    <CompanyForm
      t={t}
      myCompanyCreationDetails={myCompanyCreationDetails}
      refetchAndResetStep={refetchAndResetStep}
      currentFlowType={currentFlowType}
    />
  );
};
