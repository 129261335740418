import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  GetTaxAdvisorCompaniesByName,
  GetTaxAdvisorCompaniesByNameVariables
} from "./graphql/GetTaxAdvisorCompaniesByName";

const GET_TAX_ADVISOR_COMPANIES_BY_NAME = gql`
  query GetTaxAdvisorCompaniesByName($companyName: String!) {
    getTaxAdvisorCompaniesByName(companyName: $companyName) {
      id
      name
      legalForm
      businessAddress {
        city
        country
        street
        postalCode
        careOf
      }
    }
  }
`;

export const GetTaxAdvisorCompaniesByNameQuery = (companyName: string) =>
  useQuery<GetTaxAdvisorCompaniesByName, GetTaxAdvisorCompaniesByNameVariables>(
    GET_TAX_ADVISOR_COMPANIES_BY_NAME,
    {
      variables: {
        companyName
      }
    }
  );
