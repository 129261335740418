import { UserRole } from "global-query-types";
import { PrivateRoute } from "sharedComponents/PrivateRoute/PrivateRoute";

export const checkAuthorization = (meData) =>
  meData?.me.role === UserRole.Nominee || meData?.me?.role === UserRole.Admin;

export const PrivilegedRoute = ({ component, ...rest }) =>
  PrivateRoute({
    component,
    hasAuthorization: checkAuthorization,
    ...rest
  });

export default PrivilegedRoute;
