import React from "react";
import { StatusIcon } from "../../../sharedComponents/icons/StatusIcon";

export interface RideBadgeProps {
  label?: string;
  dataTestId?: string;
  variant?: string | null;
}

export enum RideBadgeVariant {
  PURPLE = "PURPLE",
  OCRE = "OCRE",
  GREEN = "GREEN",
  RED = "RED"
}

export const RideBadge = ({
  label = "",
  dataTestId = "ride-badge-v2",
  variant = null
}: RideBadgeProps) => {
  return (
    <div data-testid={dataTestId} className={`ride-badge-v2 ride-badge-v2--${variant}`}>
      <div
        data-testid={`${dataTestId}__icon-variant`}
        className={`ride-badge-v2__icon ride-badge-v2__icon--${variant}`}>
        <StatusIcon r={4} cx={4} cy={4} size={8} />
      </div>
      <span data-testid={`${dataTestId}-label`}>{label}</span>
    </div>
  );
};
