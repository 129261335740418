import * as React from "react";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ViewKycList from "./ViewKycList";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import RideSearch from "../../../sharedComponents/RideSearch/RideSearch";
import { AllKycEntries } from "../../../lib/api/queries/AllKycEntries";
import { RidePagination } from "../../../uiLibrary/designSystem/RidePagination/RidePagination";

interface IProps extends WithTranslation {}

export const ViewKycListContainer: React.FunctionComponent<IProps> = () => {
  const pageSize = 15;
  const [start, setStart] = useState(0);
  const [query, setQuery] = useState("");

  const kycListRequest = AllKycEntries(query, {
    start,
    limit: pageSize
  });

  return (
    <RideSearch query={query} onQueryChange={setQuery}>
      <RidePagination
        count={kycListRequest.data?.allKycEntries?.count ?? 0}
        start={start}
        pageSize={pageSize}
        setStart={setStart}>
        <RequestFeedbackHandler requests={[kycListRequest]} noLoadingAnimation={true}>
          <ViewKycList kycEntries={kycListRequest.data?.allKycEntries?.kycEntries ?? []} />
        </RequestFeedbackHandler>
      </RidePagination>
    </RideSearch>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(ViewKycListContainer);
