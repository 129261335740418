import React from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import ViewKycListContainer from "../../components/ViewKycList/ViewKycListContainer";

const UsersAndCompaniesScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:users-and-companies")}</h2>
        </Col>
      </Row>
      <ViewKycListContainer />
    </>
  );
};

export default withTranslation("header")(UsersAndCompaniesScene);
