import { OrderAdminServiceGSUpsellContainer } from "client/components/OrderAdminServiceGSUpsell/OrderAdminServiceGSUpsellContainer";
import React from "react";
import GuestLayout from "sharedComponents/templates/GuestLayout/GuestLayout";

interface OrderAdminServiceGSUpsellSceneProps {
  isNewFlow: boolean;
}

const OrderAdminServiceGSUpsellScene = ({ isNewFlow }: OrderAdminServiceGSUpsellSceneProps) => {
  return (
    <GuestLayout>
      <OrderAdminServiceGSUpsellContainer isNewFlow={isNewFlow} />;
    </GuestLayout>
  );
};

export default OrderAdminServiceGSUpsellScene;
