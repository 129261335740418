import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import * as QueryTypes from "./graphql/GetWepaFileImportById";

export const GET_WEPA_FILE_IMPORT_BY_ID = gql`
  query GetWepaFileImportById($companyId: String!, $fileImportId: String!) {
    getWepaFileImportById(companyId: $companyId, fileImportId: $fileImportId) {
      id
      companyId
      name
      status
      positionInQueue
      detectedAccounts
      dateFrom
      dateTo
      succeededAt
      failedAt
      createdAt
      updatedAt
      importErrors {
        code
        message
      }
      lastInformationLog {
        code
        processed
        total
        message
        occurredAt
      }
      validationErrors {
        code
        message
      }
    }
  }
`;

export const GetWepaFileImportById = ({
  companyId,
  fileImportId,
  options
}: {
  companyId: string;
  fileImportId: string;
  options: { skip: boolean };
}) =>
  useQuery<QueryTypes.GetWepaFileImportById, QueryTypes.GetWepaFileImportByIdVariables>(
    GET_WEPA_FILE_IMPORT_BY_ID,
    {
      fetchPolicy: "no-cache",
      variables: { companyId, fileImportId },
      skip: options.skip
    }
  );
