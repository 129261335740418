import React, { ReactElement, useState } from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { WithTranslation } from "react-i18next";
import RideModal from "uiLibrary/designSystem/RideModal/RideModal";
import PayOutInputStep from "./PayOutInputStep/PayOutInputStep";
import PayOutReviewStep from "./PayOutReviewStep/PayOutReviewStep";
import PayOutSuccessStep from "./PayOutSuccessStep/PayOutSuccessStep";

export interface PayOutModalProps {
  companyName: string;
  iban: string;
  requestPayout: (payoutAmount: number) => Promise<void>;
  onClose: () => void;
}
const PayOutModal = ({
  t,
  companyName,
  iban,
  requestPayout,
  onClose
}: PayOutModalProps & WithTranslation) => {
  const formik = useRideFormik({ initialValues: {}, onSubmit: () => {} });
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  return (
    <div data-testid={"pay-out-modal"}>
      <FormikProvider value={formik}>
        <Form>
          <RideModal
            title={t("generic:low-tax-broker-dashboard.pay-out-modal.title")}
            onClose={onClose}>
            <StepSwitch
              current={step}
              steps={[
                <PayOutInputStep companyName={companyName} iban={iban} onClickNext={nextStep} />,
                <PayOutReviewStep
                  companyName={companyName}
                  iban={iban}
                  value={formik.values["amount"]}
                  onClickRequestPayout={async () => {
                    await requestPayout(+formik.values["amount"]);
                    nextStep();
                  }}
                  onClickBack={previousStep}
                />,
                <PayOutSuccessStep onClickClose={onClose} />
              ]}
            />
          </RideModal>
        </Form>
      </FormikProvider>
    </div>
  );
};

interface StepSwitchProps {
  steps: ReactElement[];
  current: number;
}

const StepSwitch = ({ steps, current }: StepSwitchProps) => <>{steps[current]}</>;

export default withTranslationReady(["generic"])(PayOutModal);
