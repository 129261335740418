import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { PaperAssetsList } from "../../CompanyCreation/Steps/Order/Order";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { OrderProduct } from "components/public/AdminService/Steps/CompleteOrder/OrderProduct";
import { FlowTypeEnum, PackageTypeEnum } from "global-query-types";
import { getOrderPackageAndPrice } from "lib/formatters/config";
import RideOutlinedCheckbox from "sharedComponents/FormControls/RideOutlinedCheckbox/RideOutlinedCheckbox";
import logger from "common/Logger";
import { useHistory } from "react-router-dom";
import {
  RideModalDeprecated,
  RideModalVariant
} from "sharedComponents/RideModalDeprecated/RideModalDeprecated";

interface AdminServiceUpsellOfferingProps extends WithTranslation {
  isShadowUser: boolean;
  fillOutASOrder: () => any;
  declineASOffer: () => void;
  declineOfferRedirectUrlForShadowUser: string;
  declineOfferRedirectUrlForNonShadowUser: string;
  isNewFlow: boolean;
}
const AdminServiceUpsellOffering = ({
  t,
  isShadowUser,
  fillOutASOrder,
  declineASOffer,
  declineOfferRedirectUrlForShadowUser,
  declineOfferRedirectUrlForNonShadowUser,
  isNewFlow
}: AdminServiceUpsellOfferingProps) => {
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const handleOrder = async () => {
    if (isChecked) {
      await createASOrder();
    } else {
      await optOutASOffer();
    }
  };
  const createASOrder = async () => {
    try {
      const adminServiceOrderId = await fillOutASOrder();
      const prefix = isNewFlow ? "/company-foundation" : "";
      history.push(`${prefix}/confirm/admin-service/${adminServiceOrderId}`);
    } catch (error) {
      logger.errorMessage(`Unable to complete the Admin Service order:`);
      logger.error(error);
    }
  };
  const optOutASOffer = async () => {
    await declineASOffer();
    if (isShadowUser) {
      history.push(declineOfferRedirectUrlForShadowUser);
    } else {
      history.push(declineOfferRedirectUrlForNonShadowUser);
    }
  };
  return (
    <div className="container">
      <div className={"admin-service-order--success"}>
        <img
          data-testid={"success-icon"}
          alt={"success-icon"}
          src={"/assets/sharedComponents/success-icon.svg"}
        />
        <div data-testid={"title"}>
          <h3>{t("client:add-gmbh.prompt-success.message")}</h3>
        </div>
        <div data-testid={"checkbox-admin-service"} className={"admin-service-order--checkbox"}>
          <RideModalDeprecated
            title={t("client:add-gmbh:admin-service-opt-out-confirmation:modalTitle")}
            buttons={[
              {
                variant: ButtonVariant.Secondary,
                children: t(
                  "client:add-gmbh:admin-service-opt-out-confirmation:modalConfirmationButton"
                ),
                onClick: async () => {
                  await setIsChecked(true);
                }
              },
              {
                variant: ButtonVariant.Danger,
                children: t("client:add-gmbh:admin-service-opt-out-confirmation:modalCancelButton")
              }
            ]}
            variant={RideModalVariant.ROUNDED}
            dialogBody={
              <>
                <p>{t("client:add-gmbh:admin-service-opt-out-confirmation:modalBody")}</p>
                <ul>
                  <li>
                    {t("client:add-gmbh:admin-service-opt-out-confirmation:modalItemList:item1")}
                  </li>
                  <li>
                    {t("client:add-gmbh:admin-service-opt-out-confirmation:modalItemList:item2")}
                  </li>
                  <li>
                    {t("client:add-gmbh:admin-service-opt-out-confirmation:modalItemList:item3")}
                  </li>
                  <li>
                    {t("client:add-gmbh:admin-service-opt-out-confirmation:modalItemList:item4")}
                  </li>
                </ul>
              </>
            }
            hasWarningIcon={true}>
            {(setVisibility) => (
              <RideOutlinedCheckbox
                name={"get-admin-service"}
                checked={isChecked}
                label={"Admin-Service"}
                onChange={(value) => {
                  setIsChecked(value);
                  if (!value) {
                    setVisibility(true);
                  }
                }}
              />
            )}
          </RideModalDeprecated>
        </div>
      </div>
      <div className={"admin-service-order--offer"}>
        <OrderProduct
          currentFlowType={FlowTypeEnum.ADMIN_SERVICE}
          selectedPackage={getOrderPackageAndPrice(PackageTypeEnum.PAPER_ASSETS)}
          t={t}
        />
        <div className={"admin-service-order--package-list trader-gmbh"}>
          <PaperAssetsList t={t} />
        </div>
        <div className={"admin-service-order--button"}>
          <RideButtonDeprecated variant={ButtonVariant.Primary} onClick={handleOrder}>
            {t("generic:next")}
          </RideButtonDeprecated>
        </div>
      </div>
    </div>
  );
};
export default withTranslation(["client", "admin-service", "generic"])(AdminServiceUpsellOffering);
