import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CompanyCreationListContainer } from "../../components/CompanyCreationList/CompanyCreationListContainer";
import { PageLayout } from "../../../sharedComponents/templates/PageLayout/PageLayout";

const FoundationServiceScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("header:foundation-service")}
      mainContent={<CompanyCreationListContainer />}
    />
  );
};

export default withTranslation("header")(FoundationServiceScene);
