import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import RideModal, { RideModalBody } from "uiLibrary/designSystem/RideModal/RideModal";
import { RideTable } from "uiLibrary/v2/components/RideTable/RideTable";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

interface WepaOnboardingPricingModalProps {
  onClose: () => void;
}

const WepaOnboardingPricingModal = ({
  t,
  onClose
}: WepaOnboardingPricingModalProps & WithTranslation) => {
  const oneTimeFeeEntries = [
    [
      t("generic:wepa-onboarding.pricing-modal.tables.one-time-costs.setup-fee"),
      t("generic:wepa-onboarding.pricing-modal.tables.one-time-costs.setup-fee-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.one-time-costs.processing-of-depot-stocks"),
      t(
        "generic:wepa-onboarding.pricing-modal.tables.one-time-costs.processing-of-depot-stocks-price"
      )
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.one-time-costs.consultation-with-pta"),
      t("generic:wepa-onboarding.pricing-modal.tables.one-time-costs.consultation-with-pta-price")
    ]
  ];

  const runningCostsEntries = [
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.0-25k"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.0-25k-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.25k-50k"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.25k-50k-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.50k-100k"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.50k-100k-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.100k-250k"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.100k-250k-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.250k-500k"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.250k-500k-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.500k-1m"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.500k-1m-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.1m-5m"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.1m-5m-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.5m-10m"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.5m-10m-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.10m-20m"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.10m-20m-price")
    ],
    [
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.from-20m"),
      t("generic:wepa-onboarding.pricing-modal.tables.running-costs.from-20m-price")
    ]
  ];

  return (
    <RideModal
      data-testid={"wepa-onboarding-pricing-modal"}
      title={t("generic:wepa-onboarding.pricing-modal.title")}
      onClose={onClose}>
      <RideModalBody className={"wepa-onboarding-pricing-modal-content"}>
        <RideTable
          title={t("generic:wepa-onboarding.pricing-modal.tables.one-time-costs.title")}
          headers={[t("generic:description"), t("generic:price")]}
          rows={oneTimeFeeEntries}
        />
        <RideTable
          title={t("generic:wepa-onboarding.pricing-modal.tables.running-costs.title")}
          headers={[t("generic:depot-volume"), t("generic:price")]}
          rows={runningCostsEntries}
        />
        <Typography
          data-testid={"ride-modal-footnote"}
          category={"Paragraph"}
          size={100}
          weight={"Light"}>
          {t("generic:wepa-onboarding.pricing-modal.footnote")}
        </Typography>
      </RideModalBody>
    </RideModal>
  );
};

export default withTranslationReady(["generic"])(WepaOnboardingPricingModal);
