import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { ErrorAlert } from "sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { BrokerBankEnum, FeatureFlags, OrderCompanyStatusEnum } from "global-query-types";
import { UploadFileResponse } from "lib/ports/FileStorage";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { TradeCompany } from "lib/models/client/LowTaxBroker/TradeCompany";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { UpsertBrokerageAccountVariables } from "lib/api/mutations/graphql/UpsertBrokerageAccount";
import { CompanyBrokerageAccounts_companyBrokerageAccounts } from "lib/api/queries/graphql/CompanyBrokerageAccounts";
import LowTaxBrokerOverviewStatuses from "./LowTaxBrokerOverviewStatuses/LowTaxBrokerOverviewStatuses";
import { getLowTaxBrokerOverviewStatuses } from "lib/dataAdapters/lowTaxBrokerOverviewStatuses/lowTaxBrokerOverviewStatuses";
import ApplicationSection from "admin/components/LowTaxBrokerOrderDetails/ApplicationSection/ApplicationSection";
import FundAccountSection from "admin/components/LowTaxBrokerOrderDetails/FundAccountSection/FundAccountSection";
import CredentialsSection from "admin/components/LowTaxBrokerOrderDetails/CredentialsSection/CredentialsSection";
import DevToolsSection from "admin/components/LowTaxBrokerOrderDetails/DevToolsSection/DevToolsSection";
import DashboardSection from "admin/components/LowTaxBrokerOrderDetails/DashboardSection/DashboardSection";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { AddExistingCompanyOrderData } from "lib/models/client/AddExistingCompany/AddExistingCompanyOrderData";
import PendingTasksTable from "./PendingTasksTable/PendingTasksTable";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { ConvertLTBOrderToRideBrokerVariables } from "lib/api/mutations/graphql/ConvertLTBOrderToRideBroker";
import { UpdateIBKRCredentialVariables } from "lib/api/mutations/graphql/UpdateIBKRCredential";
import { GetUserForPersonQuery_getPersonDetails_user } from "lib/api/queries/graphql/GetUserForPersonQuery";

export interface LowTaxBrokerOrderDetailsProps extends TradeCompany {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order | null | undefined;
  addExistingCompanyOrder:
    | Pick<OrderData<AddExistingCompanyOrderData>, "status">
    | null
    | undefined;
  ownerUser: GetUserForPersonQuery_getPersonDetails_user | null | undefined;
  accountDetails: unknown | null | undefined;
  isLoading: boolean;
  updateIBKRCredential: (variables: UpdateIBKRCredentialVariables) => Promise<unknown>;
  uploadFile: (file: File, orderId: string) => Promise<UploadFileResponse>;
  sendToFxFlat: (orderId: string) => Promise<void>;
  upsertHandler: (data: UpsertOrderVariables) => Promise<void>;
  upsertBrokerageAccount: (data: UpsertBrokerageAccountVariables) => Promise<void>;
  getSummary: () => Promise<void>;
  softDeleteOrder: (orderId: string) => Promise<void>;
  generateLowTaxBrokerMaskedJson: (orderId: string) => Promise<void>;
  sendIBPasswordByEmail: ({
    orderId,
    password
  }: {
    orderId: string;
    password: string;
  }) => Promise<void>;
  brokerageAccounts: CompanyBrokerageAccounts_companyBrokerageAccounts[] | null | undefined;
  convertLTBOrderToRideBroker: (
    variables: ConvertLTBOrderToRideBrokerVariables
  ) => Promise<void | undefined>;
}

const LowTaxBrokerOrderDetails = ({
  t,
  order,
  addExistingCompanyOrder,
  ownerUser,
  accountDetails,
  tradeCompany,
  upsertHandler,
  upsertBrokerageAccount,
  updateIBKRCredential,
  uploadFile,
  getSummary,
  isLoading,
  sendToFxFlat,
  softDeleteOrder,
  generateLowTaxBrokerMaskedJson,
  sendIBPasswordByEmail,
  brokerageAccounts,
  convertLTBOrderToRideBroker
}: LowTaxBrokerOrderDetailsProps & WithTranslation) => {
  const [showConfirmConvertToRideBrokerModal, setshowConfirmConvertToRideBrokerModal] =
    useState(false);

  if (!order) {
    return <ErrorAlert t={t} error={"Order not found"} />;
  }

  if (!order.extra) {
    order.extra = {};
  }

  const overviewStatuses = getLowTaxBrokerOverviewStatuses(order, addExistingCompanyOrder);

  const referenceBrokerBank = order.extra.isNewBrokerEnabled
    ? BrokerBankEnum.LTB2
    : BrokerBankEnum.LTB;

  const ltbBrokerageAccount = brokerageAccounts?.find(
    (brokerageAccount) => brokerageAccount.brokerBank === referenceBrokerBank
  );

  const canSeeDashboard = !!ltbBrokerageAccount;

  const showConvertLTBToRideBrokerSection =
    order.extra.isNewBrokerEnabled === false || order.extra.isNewBrokerEnabled === undefined;

  return (
    <div className="low-tax-broker-order-details">
      <div className="low-tax-broker-order-details__dashboard-status">
        <div data-testid="dashboard-status-label">Can see the LTB Dashboard:</div>
        <div data-testid="dashboard-status-value">{canSeeDashboard ? "✅" : "⛔️"}</div>
      </div>

      {showConvertLTBToRideBrokerSection && (
        <div className="low-tax-broker-order-details__convert-order-section">
          <div
            className="low-tax-broker-order-details__convert-order-title"
            data-testid="convert-to-ride-broker-title">
            Click here to convert this LTB order to RIDE Broker:
          </div>
          <RideButton
            label="Convert to Ride Broker"
            className="low-tax-broker-order-details__convert-order-button"
            variant={RideButtonVariant.PRIMARY}
            disabled={order.status === OrderCompanyStatusEnum.Canceled}
            onClick={() => setshowConfirmConvertToRideBrokerModal(true)}
            size={RideButtonSize.SMALL}
            data-testid="convert-to-ride-broker-button"
          />
        </div>
      )}
      <ConfirmationModal
        title="Confirm Conversion to Ride Broker"
        confirmLabel="Convert to Ride Broker"
        onConfirm={() => convertLTBOrderToRideBroker({ orderId: order.id })}
        dataTestId="confirm-convert-to-ride-broker"
        onClose={() => setshowConfirmConvertToRideBrokerModal(false)}
        visible={showConfirmConvertToRideBrokerModal}
        message={
          "I confirm that I have gotten consent by phone from the owner of the account to convert this order from LTB to Ride Broker."
        }
        type={ConfirmationModalType.REGULAR}
      />

      <div className="review-and-submit-ltb__entries">
        {order.extra?.isNewBrokerEnabled ? null : (
          <FeatureFlag name={FeatureFlags.LTBOverviewStatuses}>
            <LowTaxBrokerOverviewStatuses overviewStatuses={overviewStatuses} />
          </FeatureFlag>
        )}

        {order.extra?.isNewBrokerEnabled ? null : (
          <DashboardSection order={order} upsertHandler={upsertHandler} />
        )}

        <ApplicationSection
          order={order}
          tradeCompany={tradeCompany}
          getSummary={getSummary}
          isLoading={isLoading}
          upsertHandler={upsertHandler}
          uploadFile={uploadFile}
          sendToFxFlat={sendToFxFlat}
        />

        <PendingTasksTable pendingTasks={order.extra?.pendingTasks ?? []} />

        <CredentialsSection
          isLoading={isLoading}
          order={order}
          ownerUser={ownerUser}
          accountDetails={accountDetails}
          upsertHandler={upsertHandler}
          sendIBPasswordByEmail={sendIBPasswordByEmail}
          updateIBKRCredential={updateIBKRCredential}
        />

        <FundAccountSection
          order={order}
          upsertBrokerageAccount={upsertBrokerageAccount}
          ltbBrokerageAccount={ltbBrokerageAccount}
        />

        <DevToolsSection
          order={order}
          softDeleteOrder={softDeleteOrder}
          generateLowTaxBrokerMaskedJson={generateLowTaxBrokerMaskedJson}
          upsertHandler={upsertHandler}
        />
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(LowTaxBrokerOrderDetails);
