import React from "react";
import { WithTranslation } from "react-i18next";
import { ThirdPartyTaxAdvisorUrls } from "./ThirdPartyTaxAdvisorUrls";
import { RideNavbar } from "../../../sharedComponents/RideNavbar/RideNavbar";
import { MenuLink } from "../../../sharedComponents/MenuLink/MenuLink";
import { RideSidebar } from "../../../sharedComponents/RideSidebar/RideSidebar";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import NavigationService from "../../../lib/services/NavigationService/NavigationService";

const ThirdPartyTaxAdvisorHeader = ({ t }: WithTranslation) => {
  const menu = [
    <MenuLink
      key="third-party-tax-advisors"
      eventKey={ThirdPartyTaxAdvisorUrls.MY_CLIENTS}
      path={ThirdPartyTaxAdvisorUrls.MY_CLIENTS}>
      {t("header:third-party-tax-advisor.clients")}
    </MenuLink>,
    <MenuLink key="logout" path="/logout">
      {t("header:profile.logout")}
    </MenuLink>
  ];

  NavigationService.instance = new NavigationService(ThirdPartyTaxAdvisorUrls);

  return (
    <div data-testid="third-party-tax-advisor-header">
      <RideNavbar sidebar={<RideSidebar menu={menu} activeKey="third-party-tax-advisors" />}>
        {menu}
      </RideNavbar>
    </div>
  );
};

export default withTranslationReady("header")(ThirdPartyTaxAdvisorHeader);
