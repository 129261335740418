import { GetOrderQuery_getOrder } from "lib/api/queries/graphql/GetOrderQuery";
import { Chapter } from "./Chapter";

export class Order {
  private chapters: { [key: string]: Chapter } = {};

  constructor(data: GetOrderQuery_getOrder) {
    for (const [key, chapter] of Object.entries(data.extra.chapters ?? {})) {
      this.chapters[key] = new Chapter(key, chapter, this);
    }
  }

  getChapter(key: string): Chapter | undefined {
    return this.chapters[key];
  }

  serialize() {
    const chapters: any = {};
    for (const [key, chapter] of Object.entries(this.chapters)) {
      chapters[key] = chapter.serialize();
    }

    return { chapters };
  }

  getChapterAfter(chapter: Chapter): Chapter | undefined {
    const chapterKeys = Object.keys(this.chapters);
    const index = chapterKeys.indexOf(chapter.key);

    const nextKey = chapterKeys[index + 1];
    if (!nextKey) return;

    return this.getChapter(nextKey);
  }

  getIndexOfChapter(chapter: Chapter) {
    const chapterKeys = Object.keys(this.chapters);
    return chapterKeys.indexOf(chapter.key);
  }
}
