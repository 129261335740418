import * as Yup from "yup";
import { LegalForm } from "../../global-query-types";
import { cityRegex, postalCode } from "./common";

export const WepaServiceCompanyInfoSchema = () => () =>
  Yup.object().shape({
    companyName: Yup.string().required("generic:validation-required"),
    legalForm: Yup.mixed<LegalForm>().oneOf(Object.values(LegalForm)),
    registration: Yup.string().required("generic:validation-required"),
    registerCourt: Yup.string().required("generic:validation-required"),
    street: Yup.string().required("generic:validation-required"),
    city: Yup.string()
      .matches(cityRegex, { message: "generic:validation-only-letters-spaces-dash" })
      .required("generic:validation-required"),
    postalCode: Yup.string()
      .matches(postalCode, { message: "generic:validation-only-numbers" })
      .required("generic:validation-required")
  });
