import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import React from "react";
import { useMutation } from "react-apollo";
import StripeIntegrationSection from "./StripeIntegrationSection";
import {
  createPaymentPlatformCompany,
  createPaymentPlatformCompanyVariables
} from "../../../lib/api/mutations/graphql/createPaymentPlatformCompany";
import { CREATE_PAYMENT_PLATFORM_COMPANY } from "../../../lib/api/mutations/CREATE_PAYMENT_PLATFORM_COMPANY";

interface StripeIntegrationSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
}

export const StripeIntegrationSectionContainer = ({
  company
}: StripeIntegrationSectionContainerProps) => {
  const [syncCompanyCreationToStripeMutation] = useMutation<
    createPaymentPlatformCompany,
    createPaymentPlatformCompanyVariables
  >(CREATE_PAYMENT_PLATFORM_COMPANY);

  const createCompanyInStripe = async (variables: createPaymentPlatformCompanyVariables) => {
    await syncCompanyCreationToStripeMutation({ variables });
  };

  return (
    <StripeIntegrationSection company={company} createCompanyInStripe={createCompanyInStripe} />
  );
};
