import React from "react";

export interface RideProgressBarProps {
  percentage: number;
}

const RideProgressBar = ({ percentage }: RideProgressBarProps) => {
  const normalizePercentage = () => {
    return percentage < 0 ? 0 : percentage;
  };

  return (
    <div className={`ride-progress-bar`}>
      <div
        className={`ride-progress-bar--progress`}
        style={{ "--percentage": `${normalizePercentage()}%` } as React.CSSProperties}
      />
    </div>
  );
};

export default RideProgressBar;
