import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { GetWepaServiceById, GetWepaServiceByIdVariables } from "./graphql/GetWepaServiceById";

const GET_WEPA_SERVICE_BY_ID = gql`
  query GetWepaServiceById($id: ID!) {
    getWepaServiceById(id: $id) {
      id
      currentStep
      company {
        id
        contactPerson {
          id
          firstName
          lastName
          salutation
          phoneNumber
          title
        }
      }
    }
  }
`;

export const GetWepaServiceByIdQuery = (id?: string) =>
  useQuery<GetWepaServiceById, GetWepaServiceByIdVariables>(GET_WEPA_SERVICE_BY_ID, {
    skip: !id,
    variables: {
      id: id!
    }
  });
