import React from "react";
import { WithTranslation } from "react-i18next";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import ShareholderDetailsSectionTitle from "client/components/ChaptersOrderLayout/sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { EntityStatusInformationSchema } from "lib/validation/EntityStatusInformationSchema";
import {
  DropdownSelectOption,
  RideDropdownSelect
} from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { EmploymentOptionEnum } from "lib/models/client/LowTaxBroker/EmploymentOption";

interface EntityStatusInformationProps {
  entityKey: "shareholder" | "employee";
}

const POLITICALLY_EXPOSED_PERSON = "yes";
const NOT_POLITICALLY_EXPOSED_PERSON = "no";

const EntityStatusInformationStep = ({
  t,
  currentStep,
  order,
  controls,
  saveData,
  entityKey
}: EntityStatusInformationProps & StepComponentProps & WithTranslation) => {
  const index = currentStep?.chapter?.index ?? 0;
  const entitiesDictKey = `${entityKey}s`;
  const entitiesDict = order.extra?.[entitiesDictKey];
  const entity =
    entityKey === "shareholder" ? order.extra?.shareholders[index] : order.extra?.employees[index];
  const statusInformation = entity?.statusInformation;
  const employmentDetails = entity?.employmentDetails;

  const showEmploymentStatusDropdown = !!order.extra?.isNewBrokerEnabled;

  async function onSubmit(values) {
    currentStep.complete();

    const needsEmploymentDetails =
      values["employment-status"] === EmploymentOptionEnum.EMPLOYED ||
      values["employment-status"] === EmploymentOptionEnum.SELFEMPLOYED;

    if (needsEmploymentDetails) {
      if (currentStep.getNextStep()?.key !== `${entityKey}-employer-information`) {
        currentStep.insertAfter({ key: `${entityKey}-employer-information` });
      }
    } else {
      if (currentStep.getNextStep()?.key === `${entityKey}-employer-information`) {
        currentStep.getNextStep()?.remove();
      }
    }

    await saveData(
      {
        ...order.extra,
        [entitiesDictKey]: {
          ...entitiesDict,
          [index]: {
            ...entitiesDict[index],
            statusInformation: {
              ...entitiesDict[index].statusInformation,
              taxJurisdiction: values["tax-jurisdiction"],
              taxId: values["tax-id"],
              politicallyExposed: values.pep === POLITICALLY_EXPOSED_PERSON,
              politicallyExposedContext: values["pep-context"]
            },
            ...(showEmploymentStatusDropdown && {
              employmentDetails: {
                ...(needsEmploymentDetails && entitiesDict[index].employmentDetails),
                type: values["employment-status"]
              }
            })
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  }

  const pepValueToString = (value: boolean): string | undefined => {
    if (value === undefined) return value;
    if (value) return POLITICALLY_EXPOSED_PERSON;
    return NOT_POLITICALLY_EXPOSED_PERSON;
  };

  const formik = useRideFormik({
    initialValues: {
      "tax-jurisdiction": statusInformation?.taxJurisdiction,
      "tax-id": statusInformation?.taxId,
      "employment-status": employmentDetails?.type,
      pep: pepValueToString(statusInformation?.politicallyExposed),
      "pep-context": statusInformation?.politicallyExposedContext
    },
    onSubmit,
    validationSchema: EntityStatusInformationSchema(t, showEmploymentStatusDropdown),
    validateOnBlur: true
  });

  const isCompleted = !!(
    formik.values["tax-jurisdiction"] &&
    formik.values["tax-id"] &&
    (showEmploymentStatusDropdown ? formik.values["employment-status"] : true) &&
    formik.values["pep"] &&
    (formik.values["pep"] === POLITICALLY_EXPOSED_PERSON ? formik.values["pep-context"] : true)
  );

  const title =
    entityKey === "shareholder"
      ? "generic:entity-details.shareholder.status-information.title"
      : "generic:entity-details.managing-directors.status-information.title";

  const employmentOptions: DropdownSelectOption[] = Object.values(EmploymentOptionEnum).map(
    (employmentOption) => ({
      label: t(`generic:ride-broker-onboarding.employment-options.${employmentOption}`),
      value: employmentOption
    })
  );

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="entity-details-status-information subchapter-order-layout__scrollable">
          <ShareholderDetailsSectionTitle
            prefix={`${currentStep?.disposition}`}
            text={t(title)}
            isCompleted={isCompleted}
          />
          <CountrySelect
            name="tax-jurisdiction"
            label={t("generic:tax-jurisdiction")}
            className="tax-jurisdiction-select"
          />
          <RideTextInput
            name="tax-id"
            label={t("generic:tax-id")}
            placeholder={t("generic:tax-id-placeholder")}
            className="tax-id-input"
          />
          {showEmploymentStatusDropdown && (
            <RideDropdownSelect
              name={"employment-status"}
              options={employmentOptions}
              label={t("generic:ride-broker-onboarding.employment-options.label")}
              className="employment-status-select"
              isSearchable
              isClearable
            />
          )}
          <RideRadioSelect
            name="pep"
            label={t("generic:pep")}
            options={[
              { label: t("generic:yes"), value: POLITICALLY_EXPOSED_PERSON },
              { label: t("generic:no"), value: NOT_POLITICALLY_EXPOSED_PERSON }
            ]}
            variant={RadioSelectVariant.Light}
            className="pep-select"
          />
          {formik.values.pep === POLITICALLY_EXPOSED_PERSON && (
            <RideTextInput
              name="pep-context"
              placeholder={t("generic:pep-context-placeholder")}
              asTextArea
              className="pep-context-input"
            />
          )}
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export const EntityStatusInformation = (entityKey: "shareholder" | "employee") =>
  withTranslationReady(["generic"])((props: StepComponentProps & WithTranslation) => (
    <EntityStatusInformationStep {...props} entityKey={entityKey} />
  ));
