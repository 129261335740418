import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import * as Yup from "yup";

const ConfirmTaxAdvisorExistenceSchema = (t) => {
  return Yup.object().shape({
    hasTaxAdvisor: Yup.string().required(t("generic:validation-required"))
  });
};

const ConfirmTaxAdvisorExistence = ({
  t,
  currentStep,
  saveData,
  order,
  controls
}: StepComponentProps & WithTranslation) => {
  const onSubmit = async (values) => {
    currentStep.complete();

    if (needsTaxAdvisor(values)) {
      currentStep.chapter.replaceSubsequentSteps(currentStep, [
        { key: "select-financial-statements-years" }
      ]);

      await saveData({
        ...order.extra,
        needsTaxAdvisor: needsTaxAdvisor(values),
        hasTaxAdvisor: false,
        ...currentStep.chapter.order.serialize()
      });
    } else if (hasTaxAdvisor(values)) {
      currentStep.chapter.replaceSubsequentSteps(currentStep, [
        { key: "confirm-ride-tax-advisor-switch" },
        { key: "select-financial-statements-years" }
      ]);

      await saveData({
        ...order.extra,
        needsTaxAdvisor: false,
        hasTaxAdvisor: hasTaxAdvisor(values),
        ...currentStep.chapter.order.serialize()
      });
    }
  };

  const needsTaxAdvisor = (values) => {
    return values.hasTaxAdvisor === "confirm-necessity";
  };

  const hasTaxAdvisor = (values) => {
    return values.hasTaxAdvisor === "confirm-existence";
  };

  const valueMapping = {
    true: "confirm-existence",
    false: "confirm-necessity"
  };

  const formik = useRideFormik({
    initialValues: {
      hasTaxAdvisor: valueMapping[order.extra.hasTaxAdvisor] ?? ""
    },
    onSubmit,
    validationSchema: ConfirmTaxAdvisorExistenceSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:order-company-foundation.steps.confirm-tax-advisor-existence.title")}
        />
        <div data-testid="confirm-tax-advisor-existence">
          <RideRadioSelect
            options={[
              {
                value: "confirm-necessity",
                label: t(
                  "generic:order-company-foundation.steps.confirm-tax-advisor-existence.confirm-necessity"
                )
              },
              {
                value: "confirm-existence",
                label: t(
                  "generic:order-company-foundation.steps.confirm-tax-advisor-existence.confirm-existence"
                )
              }
            ]}
            name="hasTaxAdvisor"
            variant={RadioSelectVariant.Heavy}
            columns={1}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ConfirmTaxAdvisorExistence);
