import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import Table from "../../../components/generic/Table";
import EntityLink from "components/common/EntityLink/EntityLink";
import { LegalForm } from "../../../global-query-types";

interface ClientEntityTableProps extends WithTranslation {
  clientEntities: any[];
}

interface ClientEntityTableRow {
  id: string;
  name: string;
  legalForm: LegalForm;
  deletedAt: any | null;
  contactPerson: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

type ClientEntityData = ClientEntityTableRow[];

const ClientEntityTable = ({ clientEntities, t }: ClientEntityTableProps) => {
  const clientEntityData: ClientEntityData = clientEntities;

  const columns = [
    {
      dataField: "id",
      text: t("nominee-dashboard:client-entities.id"),
      formatter: (_, clientEntity: ClientEntityTableRow) => {
        return <span data-testid="company-id">{clientEntity.id}</span>;
      }
    },
    {
      dataField: "name",
      text: t("nominee-dashboard:client-entities.company"),
      formatter: (_, clientEntity: ClientEntityTableRow) => {
        return <EntityLink membership={{ company: clientEntity }} testIdPrefix="client-entity" />;
      }
    },
    {
      dataField: "contactPerson",
      text: t("nominee-dashboard:client-entities.client"),
      formatter: (_, clientEntity: ClientEntityTableRow) => {
        return (
          <EntityLink
            membership={{ person: clientEntity.contactPerson }}
            testIdPrefix="client-entity"
          />
        );
      }
    },
    {
      dataField: "deleted",
      text: t("nominee-dashboard:client-entities.deleted"),
      formatter: (_, clientEntity: ClientEntityTableRow) => {
        return (
          <span data-testid="client-entity-is-deleted">
            {clientEntity.deletedAt ? "Yes" : "No"}
          </span>
        );
      }
    }
  ];

  return <Table data={clientEntityData} columns={columns} tableStyle="greenTable" keyField="id" />;
};

export default withTranslationReady(["nominee-dashboard"])(ClientEntityTable);
