import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { ArrowRight } from "sharedComponents/icons/DesignSystemIcons/ArrowRight";
import localStore from "common/LocalStore";

interface ThirdPartyTaxAdvisorMigrateSuccessProps {
  backToMyClients: () => void;
  stripeSessionId?: string | null;
}

const ThirdPartyTaxAdvisorMigrateSuccess = ({
  t,
  backToMyClients,
  stripeSessionId
}: ThirdPartyTaxAdvisorMigrateSuccessProps & WithTranslation) => {
  const module = "migrate-3pta-success";

  if (stripeSessionId && localStore.getValue("token-remember-set-for-stripe")) {
    localStore.setValue("token-remember", false);
    localStore.removeValue("token-remember-set-for-stripe");
  }

  return (
    <div className={module} data-testid={module}>
      <div className={`${module}__header`}>
        <Typography
          data-testid={`${module}-title`}
          category={"Headline"}
          size={200}
          weight={"Heavy"}>
          {t("generic:migrate-3pta.success.title")}
        </Typography>
        <Typography
          data-testid={`${module}-description`}
          category={"Paragraph"}
          size={100}
          weight={"Light"}>
          {t("generic:migrate-3pta.success.description")}
        </Typography>
      </div>
      <RideButton
        onClick={backToMyClients}
        data-testid={`${module}-back-to-my-clients-button`}
        className={`${module}__button`}
        label={t("generic:migrate-3pta.success.back-to-my-clients-button")}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        rightIcon={<ArrowRight />}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(ThirdPartyTaxAdvisorMigrateSuccess);
