import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { getI18n, WithTranslation } from "react-i18next";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { formatValue } from "common/formatters";
import { CircleWithArrowIcon } from "sharedComponents/icons/CircleWithArrowIcon";
import { useHistory } from "react-router-dom";
import { ClickableElement } from "sharedComponents/ClickableElement/ClickableElement";
import { TextHighlighter } from "sharedComponents/TextHighlighter/TextHighlighter";
import { useSearchTerm } from "./TaxAdvisorClientCard.partials";
import { GetMyTaxAdvisorClients_getMyTaxAdvisorClients_items } from "../../../lib/api/queries/graphql/GetMyTaxAdvisorClients";
import { TaxAdvisorStatusBadge } from "../TaxAdvisorClientCardLegacy/TaxAdvisorClientCardLegacy";
import { BrokerageAccountStatus } from "../../../sharedComponents/BrokerageAccountStatus/BrokerageAccountStatus";
import { UserService } from "../../../lib/services/UserService/UserService";
import { UserRole } from "../../../global-query-types";

type TaxService = GetMyTaxAdvisorClients_getMyTaxAdvisorClients_items;

export interface TaxAdvisorClientCardProps {
  taxService: TaxService;
  index?: number;
}

const TaxAdvisorClientCard = ({
  t,
  taxService,
  index
}: TaxAdvisorClientCardProps & WithTranslation) => {
  const history = useHistory();

  const currentUser = UserService.instance?.currentUser;
  const pathPrefix = currentUser?.role === UserRole.Accountant ? "/accountant" : "/tax-advisor";

  const onClick = () => {
    history.push(`${pathPrefix}/client-entities/${taxService.client?.id}`);
  };

  const { searchTerm } = useSearchTerm();

  const { name, legalForm } = taxService.client ?? {};

  const companyName = name ? name : getI18n().t(`generic:unnamed`);
  const companyLegalForm = legalForm ? getI18n().t(`generic:legalForm.${legalForm}`) : "---";

  const min = (a, b) => (a < b ? a : b);
  const slideDelay = `${min(index ?? 0, 10) * 25}ms`;

  return (
    <ClickableElement
      onClick={onClick}
      className="tax-advisor-client-card__wrapper"
      style={{ "--slide-delay": slideDelay } as React.CSSProperties}>
      <CardContainer data-testid="tax-advisor-client-card" className="tax-advisor-client-card">
        <div className="tax-advisor-client-card__left-block">
          <div className="tax-advisor-client-card__client-info">
            <Typography category="Headline" size={200} weight="Heavy" data-testid="client-name">
              <TextHighlighter term={searchTerm} limit={1}>
                {companyName}
              </TextHighlighter>{" "}
              {companyLegalForm}
            </Typography>
            <div className="tax-advisor-client-card__status-badges">
              {taxService.taxAdvisorStatus && (
                <TaxAdvisorStatusBadge t={t} taxAdvisorStatus={taxService.taxAdvisorStatus} />
              )}
              {taxService.client?.brokerageAccounts && (
                <BrokerageAccountStatus
                  brokerageAccounts={taxService.client.brokerageAccounts}
                  t={t}
                />
              )}
            </div>
          </div>
          <Typography
            category="Paragraph"
            size={300}
            weight="Light"
            data-testid="client-number"
            className="tax-advisor-client-card__client-number">
            {t("client:company.client-number")}:{" "}
            <TextHighlighter term={searchTerm} limit={1}>
              {formatValue(taxService.client?.clientNumber)}
            </TextHighlighter>
          </Typography>
        </div>
        <CircleWithArrowIcon className="tax-advisor-client-card__arrow" />
      </CardContainer>
    </ClickableElement>
  );
};

export default withTranslationReady(["generic", "client", "tax-service"])(TaxAdvisorClientCard);
