import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import Table from "components/generic/Table";
import {
  getThirdPartyTaxAdvisorsAndPartners_PartnerTaxCompany_companies,
  getThirdPartyTaxAdvisorsAndPartners_ThirdPartyTaxService_companies
} from "components/nominee/ThirdPartyTaxAdvisorsList/graphql/getThirdPartyTaxAdvisorsAndPartners";

interface ThirdPartyTaxAdvisorsCompanyListProps extends WithTranslation {
  partnerTaxAdvisorCompanies:
    | getThirdPartyTaxAdvisorsAndPartners_PartnerTaxCompany_companies[]
    | null
    | undefined;
  thirdPartyTaxAdvisorCompanies:
    | getThirdPartyTaxAdvisorsAndPartners_ThirdPartyTaxService_companies[]
    | null
    | undefined;
  refetch: () => Promise<void>;

  onPartnerAssignment({
    thirdPartyTaxCompanyId,
    partnerTaxCompanyId
  }: {
    thirdPartyTaxCompanyId: string;
    partnerTaxCompanyId: string;
  }): Promise<void>;
}

const BASE_URL = process.env.REACT_APP_TAX_ADVISOR_URL as string;

const ThirdPartyTaxAdvisorCompaniesList = ({
  t,
  partnerTaxAdvisorCompanies,
  thirdPartyTaxAdvisorCompanies,
  onPartnerAssignment,
  refetch
}: ThirdPartyTaxAdvisorsCompanyListProps) => {
  const partnerCompaniesOptions = partnerTaxAdvisorCompanies?.map((partnerCompany) => {
    return {
      label: partnerCompany.name,
      value: partnerCompany.id
    };
  });

  const getTaxAdvisorLink = (taxAdvisorCode: string) => BASE_URL + "/" + taxAdvisorCode;

  const columns = [
    {
      dataField: "name",
      text: t("third-party-tax-advisor:company"),
      sort: true,
      formatter: (name) => <span data-testid="third-party-tax-advisor-company_name">{name}</span>
    },
    {
      dataField: "fullName",
      text: t("third-party-tax-advisor:fullName"),
      formatter: (fullName) => (
        <span data-testid="third-party-tax-advisor-company_fullName">{fullName}</span>
      )
    },
    {
      dataField: "taxAdvisorCode",
      text: t("third-party-tax-advisor:taxAdvisorCode"),
      formatter: (taxAdvisorCode) => (
        <span data-testid="third-party-tax-advisor-company_taxAdvisorCode">
          {getTaxAdvisorLink(taxAdvisorCode)}
        </span>
      )
    },
    {
      dataField: "myWepaSubContracting",
      text: t("third-party-tax-advisor:partnerCompany"),
      formatter: (myWepaSubContracting, thirdPartyCompany) => (
        <span data-testid="third-party-tax-advisor-company_partnerCompany">
          <ReactSelect
            value={
              partnerCompaniesOptions?.find(
                (company) => company.value === myWepaSubContracting?.partner.id
              ) ?? null
            }
            onChange={(option) => updateThirdPartyPartnerCompany(thirdPartyCompany, option)}
            options={partnerCompaniesOptions}
            formatOptionLabel={(option) => (
              <span data-testid={`third-party-tax-advisor-company_option_${option.value}`}>
                {option.label}
              </span>
            )}
          />
        </span>
      )
    }
  ];

  const updateThirdPartyPartnerCompany = async (thirdPartyCompany, option) => {
    await onPartnerAssignment({
      thirdPartyTaxCompanyId: thirdPartyCompany.id,
      partnerTaxCompanyId: option.value
    });
    await refetch();
  };

  const enrichedData = thirdPartyTaxAdvisorCompanies?.length
    ? thirdPartyTaxAdvisorCompanies.map((company) => {
        return {
          ...company,
          taxAdvisorCode: company.contactPerson?.user?.taxAdvisorCode ?? "",
          fullName: `${company.contactPerson?.firstName} ${company.contactPerson?.lastName!}`
        };
      })
    : [];

  return (
    <Row>
      <Col className="mt-4">
        <Table data={enrichedData} columns={columns} tableStyle="greenTable" keyField="id" />
      </Col>
    </Row>
  );
};

export default withTranslation(["generic", "third-party-tax-advisor"])(
  ThirdPartyTaxAdvisorCompaniesList
);
