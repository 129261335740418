import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import RideIcon from "uiLibrary/components/RideIcon/RideIcon";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { RideModalBody, RideModalControls } from "uiLibrary/designSystem/RideModal/RideModal";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface PayOutSuccessStep extends WithTranslation {
  onClickClose: () => void;
}

const PayOutSuccessStep = ({ t, onClickClose }: PayOutSuccessStep) => {
  const moduleName = "pay-out-success-step";
  return (
    <>
      <RideModalBody>
        <div className={moduleName} data-testid={moduleName}>
          <RideIcon dataTestId={"success-icon"} name="check-mark" />
          <span data-testid={"heading"} className={`${moduleName}__heading`}>
            {t("generic:low-tax-broker-dashboard.pay-out-modal.success.heading")}
          </span>
          <RideAlertBar
            className={`${moduleName}__alert`}
            type={AlertType.SUCCESS}
            message={t("generic:low-tax-broker-dashboard.pay-out-modal.success.message")}
          />
        </div>
      </RideModalBody>
      <RideModalControls>
        <RideButtonDeprecated
          width={ButtonWidth.FULL}
          radius={ButtonRadius.Soft}
          data-testid={"close-button"}
          onClick={onClickClose}
          type={"button"}>
          {t("generic:close")}
        </RideButtonDeprecated>
      </RideModalControls>
    </>
  );
};

export default withTranslation(["generic"])(PayOutSuccessStep);
