import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "../../../global-query-types";
import { RideButtonDeprecated } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface GeneratePrefilledGsOrderProps extends WithTranslation {
  handlePrefillOrder: () => {};
  existingOrdersQuantity: number;
}

const GeneratePrefilledGsOrder = ({
  t,
  handlePrefillOrder,
  existingOrdersQuantity
}: GeneratePrefilledGsOrderProps) => {
  const hasOrders = existingOrdersQuantity > 0;
  return (
    <FeatureFlag name={FeatureFlags.GenerateOrderForEmailButtonOnGSPage}>
      <div data-testid="generate-prefilled-gs-order">
        {!hasOrders && (
          <RideButtonDeprecated
            onClick={handlePrefillOrder}
            data-testid={"generate-prefilled-gs-order-button"}>
            {t("nominee-dashboard:company-creation.generate-order-from-email")}
          </RideButtonDeprecated>
        )}
      </div>
    </FeatureFlag>
  );
};

export default withTranslationReady(["generic"])(GeneratePrefilledGsOrder);
