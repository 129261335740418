import React, { useState } from "react";
import {
  ButtonSize,
  ButtonVariant
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { BrokersList } from "./BrokersList/BrokersList";
import { BrokersDropdown } from "./BrokersDropdown/BrokersDropdown";
import { ClientSelectOption } from "../ClientSelect/ClientSelect";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import {
  RideModalDeprecated,
  RideModalSize
} from "sharedComponents/RideModalDeprecated/RideModalDeprecated";
import { RideRadioButton } from "sharedComponents/RideRadioButton/RideRadioButton";

interface UserBrokerSurveyProps extends WithTranslation {
  gmbh: GmbHDetails;
  onSubmit: (variables: SelectedAnswerInterface) => Promise<void>;
}

export interface SelectedAnswerInterface {
  answer: null | string;
  reason: null | string;
  otherReason: null | string;
  selectedBrokerBank: null | string;
  otherBrokerBank: null | string;
}

const UserBrokerSurvey = ({ t, onSubmit, gmbh }: UserBrokerSurveyProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswerInterface>({
    answer: null,
    reason: null,
    otherReason: null,
    selectedBrokerBank: null,
    otherBrokerBank: null
  });
  const [selectedOption, setSelectedOption] = useState<null | number>(null);

  const brokers: ClientSelectOption[] = [
    {
      label: "DAB (BNP Paribas Tochter)",
      value: "dab"
    },
    {
      label: "Flatex",
      value: "flatex"
    },
    {
      label: "Naga",
      value: "naga"
    },
    {
      label: "S Broker",
      value: "sBroker"
    },
    {
      label: "Onvista Bank",
      value: "onvistaBank"
    },
    {
      label: "Sino AG",
      value: "sinoAG"
    },
    {
      label: "x-Trade Brokers (XTB)",
      value: "xtb"
    },
    {
      label: "eToro",
      value: "eToro"
    },
    {
      label: "comdirect",
      value: "comdirect"
    },
    {
      label: "DeGiro",
      value: "deGiro"
    },
    {
      label: "Consors",
      value: "consors"
    },
    {
      label: "IG",
      value: "ig"
    },
    {
      label: "Deutsche Bank",
      value: "deutscheBank"
    },
    {
      label: t("brokerSurvey:anotherBroker"),
      value: "anotherBroker"
    }
  ];

  const reasons: ClientSelectOption[] = [
    {
      label: t("brokerSurvey:gmbhWillOnlyBeUsedForRealEstate"),
      value: "gmbhWillOnlyBeUsedForRealEstate"
    },
    {
      label: t("brokerSurvey:gmbhWillOnlyBeUsedForCompanyParticipation"),
      value: "gmbhWillOnlyBeUsedForCompanyParticipation"
    },
    {
      label: t("brokerSurvey:anotherReason"),
      value: "anotherReason"
    }
  ];

  const selectOnChangeHandler = ({
    selectedDropdown,
    comment
  }: {
    selectedDropdown: ClientSelectOption;
    comment: string | null;
  }) => {
    if (selectedAnswer.answer === "notOnTheListOfBrokersRideProvides") {
      setSelectedAnswer({
        ...selectedAnswer,
        selectedBrokerBank: selectedDropdown.value,
        otherBrokerBank: comment
      });
    } else {
      setSelectedAnswer({
        ...selectedAnswer,
        reason: selectedDropdown.value,
        otherReason: comment
      });
    }
  };

  const selectOption = (selectedIndex) => {
    setSelectedOption(selectedIndex);
    setSelectedAnswer({
      answer: optionsData[selectedIndex].id,
      selectedBrokerBank: null,
      reason: null,
      otherReason: null,
      otherBrokerBank: null
    });
  };

  const optionsData = [
    {
      label: "brokerSurvey:stillNeedToGetOne",
      component: <BrokersList t={t} />,
      id: "stillNeedToGetOne"
    },
    {
      label: "brokerSurvey:notOnTheListOfBrokersRideProvides",
      component: (
        <BrokersDropdown
          dropdownOptions={brokers}
          subtitle={t("brokerSurvey:which-one-does-he-have")}
          textAreaSubtitle={t("brokerSurvey:specifyDifferentProvider")}
          selectOnChangeHandler={selectOnChangeHandler}
        />
      ),
      id: "notOnTheListOfBrokersRideProvides"
    },
    {
      label: "brokerSurvey:dontNeedOne",
      component: (
        <BrokersDropdown
          dropdownOptions={reasons}
          subtitle={t("brokerSurvey:why")}
          textAreaSubtitle={t("brokerSurvey:specifyAnotherReason")}
          selectOnChangeHandler={selectOnChangeHandler}
        />
      ),
      id: "dontNeedOne"
    },
    { label: "brokerSurvey:doItLater", id: "doItLater" }
  ];

  const surveyContent = (
    <div className="user-broker-survey__radios">
      {optionsData.map((option, index) => (
        <React.Fragment key={index}>
          <RideRadioButton
            selected={index === selectedOption}
            className="user-broker-survey__radio"
            label={t(option.label)}
            callback={(id) => {
              selectOption(id);
            }}
            key={index}
            id={index}
          />
          {index === selectedOption && option.component}
        </React.Fragment>
      ))}
    </div>
  );

  return gmbh.displayBrokerageSurvey ? (
    <RideModalDeprecated
      visible
      title={t("brokerSurvey:title")}
      size={RideModalSize.LARGE}
      data-testid="modal"
      hideClosingButton
      dialogBody={surveyContent}
      buttons={[
        {
          disabled: selectedOption === null,
          "data-testid": "close-btn",
          size: ButtonSize.Big,
          variant: ButtonVariant.Primary,
          onClick: () => onSubmit(selectedAnswer),
          type: "submit",
          children: t("generic:save")
        }
      ]}
    />
  ) : null;
};

export default withTranslationReady(["generic", "brokerSurvey"])(UserBrokerSurvey);
