import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { FeatureFlags } from "global-query-types";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";

import RideOverview from "../../../sharedComponents/RideOverview/RideOverview";
import TaxAdvisorClientsWrapperContainer from "taxAdvisor/components/TaxAdvisorClientsWrapper/TaxAdvisorClientsWrapperContainer";
import { TaxAdvisorClientsContainer } from "taxAdvisor/components/TaxAdvisorClients/TaxAdvisorClientsContainer";

interface TaxAdvisorClientsSceneProps extends WithTranslation {}

const TaxAdvisorClientsScene = ({ t }: TaxAdvisorClientsSceneProps) => {
  const showClientsWithSearch = FeatureFlagService.instance.isEnabled(
    FeatureFlags.WP6TaxAdvisorClientSearch
  );

  return (
    <RideOverview
      imageSrc={"/assets/third-party-tax-advisor/header-icon.svg"}
      title={t("tax-advisor:clients.overview.title")}
      subtitle={t("tax-advisor:clients.overview.subtitle")}>
      {showClientsWithSearch ? (
        <TaxAdvisorClientsContainer />
      ) : (
        <TaxAdvisorClientsWrapperContainer />
      )}
    </RideOverview>
  );
};

export default withTranslationReady("tax-advisor")(TaxAdvisorClientsScene);
