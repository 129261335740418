import React, { useState } from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { FileEntityType, FileStorage } from "lib/ports/FileStorage";
import UploadFile from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/UploadFile/UploadFile";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import DoneButton, {
  SubchapterButtonArea
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/DoneButton/DoneButton";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { FeatureFlags } from "global-query-types";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { proofOfAddressTypeOptions } from "admin/components/OrderFilesTable/OrderFilesTable";
import { RideDatePicker } from "uiLibrary/v2/components/RideDatePicker/RideDatePicker";
import { Form, FormikProvider } from "formik";
import { ShareholderProofOfAddressSchema } from "lib/validation/ShareholderProofOfAddressSchema";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { FileUploadArea } from "../../../sharedComponents/FileUploadArea/FileUploadArea";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import {
  AlertType,
  BarVariant,
  RideAlertBar
} from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { useHistory } from "react-router-dom";

interface ShareholderProofOfAddressStepProps extends StepComponentProps<LowTaxBrokerOrderData> {
  fileUploader: FileStorage;
}

const ShareholderProofOfAddressStep = ({
  t,
  order,
  currentStep,
  saveData,
  fileUploader
}: ShareholderProofOfAddressStepProps & WithTranslation) => {
  const history = useHistory();

  const index = currentStep?.chapter?.index ?? 0;
  const [existingFile, setExistingFile] = useState(
    order.extra.shareholders?.[index]?.proofOfAddressFile ?? {}
  );

  const additionalIdDocumentInformationToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.IDDocumentAdditionalInformationForClients
  );

  const onSubmit = async () => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        shareholders: {
          ...order.extra.shareholders,
          [index]: {
            ...order.extra.shareholders?.[index],
            proofOfAddressFile: {
              issuingDate:
                formik.values["issuingDate"] ??
                order.extra.shareholders?.[index].proofOfAddressFile?.issuingDate,
              typeOfDocument:
                formik.values["typeOfDocument"] ??
                order.extra.shareholders?.[index].proofOfAddressFile?.typeOfDocument,
              id: existingFile.id ?? "",
              checksum: existingFile.checksum ?? "",
              name: existingFile.name ?? "",
              size: existingFile.size ?? 0
            }
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );

    history.goBack();
  };

  const formik = useRideFormik({
    initialValues: {
      typeOfDocument:
        order.extra.shareholders?.[index]?.proofOfAddressFile?.typeOfDocument ?? undefined,
      issuingDate: order.extra.shareholders?.[index]?.proofOfAddressFile?.issuingDate ?? undefined
    },
    onSubmit,
    validationSchema: ShareholderProofOfAddressSchema(t)
  });

  const uploadFile = async (file: File) => {
    const { fileId, checksum } = await fileUploader.uploadFileForEntity(
      FileEntityType.Order,
      order.id ?? "missing-id",
      file
    );

    let proofOfAddressFile = {
      id: fileId,
      checksum,
      name: file.name,
      size: file.size
    };

    if (!additionalIdDocumentInformationToggle) {
      currentStep.complete();

      await saveData(
        {
          ...order.extra,
          shareholders: {
            ...order.extra.shareholders,
            [index]: {
              ...order.extra.shareholders?.[index],
              proofOfAddressFile
            }
          },
          ...currentStep.chapter.order.serialize()
        },
        true
      );
    } else {
      proofOfAddressFile["issuingDate"] =
        formik.values["issuingDate"] ??
        order.extra.shareholders?.[index].proofOfAddressFile?.issuingDate;

      proofOfAddressFile["typeOfDocument"] =
        formik.values["typeOfDocument"] ??
        order.extra.shareholders?.[index].proofOfAddressFile?.typeOfDocument;
    }
    await setExistingFile(proofOfAddressFile);
  };

  const deleteFile = async () => {
    currentStep.reOpen();

    if (!additionalIdDocumentInformationToggle) {
      await saveData(
        {
          ...order.extra,
          shareholders: {
            ...order.extra.shareholders,
            [index]: {
              ...order.extra.shareholders?.[index],
              proofOfAddressFile: undefined
            }
          },
          ...currentStep.chapter.order.serialize()
        },
        true
      );
    }

    let proofOfAddressInfoWithoutFile = {
      issuingDate:
        formik.values["issuingDate"] ??
        order.extra.shareholders?.[index].proofOfAddressFile?.issuingDate,
      typeOfDocument:
        formik.values["typeOfDocument"] ??
        order.extra.shareholders?.[index].proofOfAddressFile?.typeOfDocument
    };

    await setExistingFile(proofOfAddressInfoWithoutFile);
  };

  const moduleName = "shareholder-proof-of-address";
  return (
    <>
      <FeatureFlag name={FeatureFlags.IDDocumentAdditionalInformationForClients}>
        <FormikProvider value={formik}>
          <Form className="subchapter-order-layout__form">
            <div
              className={`subchapter-order-layout__scrollable ${moduleName}`}
              data-testid={"modal"}>
              <RideDropdownSelect
                className={"shareholder-proof-of-address__type-of-document"}
                name="typeOfDocument"
                options={proofOfAddressTypeOptions(t)}
                label={t("generic:low-tax-broker-order.file-section.document-type")}
                isSearchable={true}
              />
              <RideDatePicker
                label={t("generic:low-tax-broker-order.file-section.issue-date")}
                name="issuingDate"
              />
              <RideAlertBar
                barVariant={BarVariant.Rounded}
                className={"shareholder-proof-of-address__requirement"}
                type={AlertType.INFO}
                title={t("generic:important-requirement")}
                message={
                  <Trans
                    i18nKey={
                      "generic:ride-broker-onboarding.documents.upload-file.requirements.personal-bank-statement-requirement"
                    }
                    components={{ Underscore: <u>personal</u> }}
                  />
                }
              />
              <div className={`${moduleName}__example-image`} data-testid={"example-file"} />
              <FileUploadArea
                uploadFile={uploadFile}
                existingFile={existingFile}
                deleteFile={deleteFile}
                className={"shareholder-proof-of-address__file-upload-area"}
              />
            </div>
            <SubchapterButtonArea>
              <DoneButton isDisabled={!existingFile.id} type={"submit"} onClick={() => {}} />
            </SubchapterButtonArea>
          </Form>
        </FormikProvider>
      </FeatureFlag>

      <DisabledFeatureFlag name={FeatureFlags.IDDocumentAdditionalInformationForClients}>
        <div className="subchapter-order-layout__form">
          <div className="subchapter-order-layout__scrollable" data-testid={"modal"}>
            <UploadFile
              requirements={t(
                "generic:ride-broker-onboarding.documents.upload-file.requirements.shareholder-proof-of-address"
              )}
              uploadFile={uploadFile}
              existingFile={existingFile}
              deleteFile={deleteFile}
            />
          </div>
          <SubchapterButtonArea>
            <DoneButton isDisabled={!existingFile} />
          </SubchapterButtonArea>
        </div>
      </DisabledFeatureFlag>
    </>
  );
};

export const ShareholderProofOfAddress = (fileUploader: FileStorage) =>
  withTranslationReady(["generic"])((props: StepComponentProps & WithTranslation) => (
    <ShareholderProofOfAddressStep {...props} fileUploader={fileUploader} />
  ));
