import { CREATE_FILE_UPLOAD_URL } from "lib/api/mutations/CREATE_FILE_UPLOAD_URL_MUTATION";
import { useMutation } from "react-apollo";
import { uploadFileViaBackend } from "common/uploadFile";
import { FINISH_FILE_UPLOAD } from "lib/api/mutations/FINISH_FILE_UPLOAD";
import { StorageNodesEntityType, StorageNodeType } from "../../global-query-types";
import {
  FileUploadService,
  FinishFileUploadState,
  UploadURL
} from "lib/services/FileUploadService/FileUploadService";
import {
  FinishFileUpload,
  FinishFileUploadVariables
} from "lib/api/mutations/graphql/FinishFileUpload";
import {
  CreateFileUploadURL,
  CreateFileUploadURLVariables
} from "lib/api/mutations/graphql/CreateFileUploadURL";

export const FileUploadServiceContainer = () => {
  const [createFileUploadURLMutation] = useMutation<
    CreateFileUploadURL,
    CreateFileUploadURLVariables
  >(CREATE_FILE_UPLOAD_URL);

  const createFileUploadURL = async (
    nodeId: string,
    fileName: string,
    fileSize: number,
    entityType: StorageNodesEntityType,
    entityId: string
  ): Promise<UploadURL> => {
    const response = await createFileUploadURLMutation({
      variables: { nodeId, fileName, fileSize, entityType, entityId }
    });
    return {
      uploadId: response.data?.createFileUploadURL.uploadId ?? "#no-data-received",
      s3_url: {
        partNumber: response.data?.createFileUploadURL.s3_url?.partNumber ?? 0,
        url: response.data?.createFileUploadURL.s3_url?.url ?? "#no-data-received"
      }
    };
  };

  const [finishFileUploadMutation] = useMutation<FinishFileUpload, FinishFileUploadVariables>(
    FINISH_FILE_UPLOAD
  );

  const finishFileUpload = async (
    name: string,
    uploadId: string,
    partNumber: number,
    eTag: string,
    nodeId: string
  ): Promise<FinishFileUploadState> => {
    const response = await finishFileUploadMutation({
      variables: { eTag, partNumber, uploadId }
    });
    return {
      __typename: "StorageNode",
      name: name ?? "#no-data-received",
      type: StorageNodeType.FILE,
      nodeId: nodeId ?? "#no-data-received",
      status: response.data?.finishFileUpload.status ?? null,
      statusText: response.data?.finishFileUpload.statusText ?? "#no-data-received"
    };
  };

  const handleFileUpload = async (url: string, file: File) => {
    const response = await uploadFileViaBackend("PUT", url, file);
    return getEtag(response);
  };

  FileUploadService.getInstance({
    createFileUploadURL,
    finishFileUpload,
    uploadFile: handleFileUpload
  });

  return null;
};

const getEtag = (response: Response): string => {
  const eTag = response.headers.get("Etag") ?? "";
  return JSON.parse(eTag);
};
