import React from "react";
import FiscalYearDataUploadStatus from "./FiscalYearDataUploadStatus";
import { GetFiscalYearsByCompanyIdQuery } from "lib/api/queries/GetFiscalYearsByCompanyId";
import { toFiscalYearDataCompletenessList } from "lib/dataAdapters/toFiscalYearDataCompletenessList/toFiscalYearDataCompletenessList";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { FiscalYearDataCompleteness } from "client/components/FiscalYearDataUploadConfirmation/FiscalYearDataUploadConfirmation";

interface FiscalYearDataUploadStatusContainerProps {
  gmbh: GmbHDetails;
}

export const FiscalYearDataUploadStatusContainer = ({
  gmbh
}: FiscalYearDataUploadStatusContainerProps) => {
  const fiscalYearsByCompanyIdRequest = GetFiscalYearsByCompanyIdQuery(gmbh.id);
  const fiscalYearsRecords = fiscalYearsByCompanyIdRequest.data?.getFiscalYearsByCompanyId ?? [];
  const fiscalYearsParsedRecords: FiscalYearDataCompleteness[] = toFiscalYearDataCompletenessList(
    gmbh.fiscalYear,
    fiscalYearsRecords
  );

  return <FiscalYearDataUploadStatus fiscalYears={fiscalYearsParsedRecords} />;
};
