import React from "react";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { MissingValue } from "../../ReviewAndSubmitLowTaxBroker/ReviewAndSubmitLowTaxBroker.partials";

export interface ReviewManagingDirectorsProps {
  managingDirectors: ManagingDirector[];
  editLink?: string;
  isCompleted?: boolean;
}

interface ManagingDirector {
  name?: string;
}

export const ReviewManagingDirectors = withTranslationReady(["generic"])(
  ({
    t,
    managingDirectors,
    editLink,
    isCompleted
  }: ReviewManagingDirectorsProps & WithTranslation) => {
    return (
      <ReviewCard>
        <ReviewSectionTitle
          title={t("generic:ride-broker-onboarding.review.managing-directors")}
          editLink={editLink}
          isCompleted={isCompleted}
        />
        <div className="ltb-review-managing-directors__list">
          {managingDirectors.map((managingDirector) => (
            <ReviewManagingDirector
              managingDirector={managingDirector}
              key={managingDirector.name}
            />
          ))}
        </div>
      </ReviewCard>
    );
  }
);

const ReviewManagingDirector = ({ managingDirector }: { managingDirector: ManagingDirector }) => (
  <div className="ltb-review-managing-directors__entry">
    {managingDirector.name ?? <MissingValue isMandatory={true} />}
  </div>
);
