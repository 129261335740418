import React from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { OrderOwnerType } from "global-query-types";
import logger from "common/Logger";
import { LocationHelperProps, withLocationHelper } from "common/LocationHelper";
import { useQueryParams } from "lib/hooks/useQueryParams";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { UpsertOrder, UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import { GetMe } from "lib/api/queries/MeQuery";
import { GET_STRIPE_CHECKOUT_SESSION } from "lib/api/mutations/GET_STRIPE_CHECKOUT_SESSION";
import { VALIDATE_STRIPE_SESSION } from "lib/api/payment/queries/ValidateStripeSession";
import { GET_PAYMENT_METHOD_FOR_COMPANY } from "lib/api/payment/queries/GetPaymentMethodForCompany";
import {
  getStripeCheckoutSession,
  getStripeCheckoutSessionVariables
} from "lib/api/mutations/graphql/getStripeCheckoutSession";
import {
  GetPaymentMethodForCompany,
  GetPaymentMethodForCompanyVariables
} from "lib/api/payment/queries/graphql/GetPaymentMethodForCompany";
import {
  ValidateStripeSession,
  ValidateStripeSessionVariables
} from "lib/api/payment/queries/graphql/ValidateStripeSession";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import AdminServiceTiersOnboarding from "./AdminServiceTiersOnboarding";
import { UPDATE_PAYMENT_METHOD } from "lib/api/payment/mutations/UpdatePaymentMethod";
import {
  UpdatePaymentMethod,
  UpdatePaymentMethodVariables
} from "lib/api/payment/mutations/graphql/UpdatePaymentMethod";
import { usePaymentQuery } from "lib/hooks/payments/usePaymentQuery";
import { usePaymentMutation } from "lib/hooks/payments/usePaymentMutation";
import { getPaymentsContext } from "lib/api/payment/context";
import { PaymentMethodType, PaymentPlatformAccount } from "payments-query-types";
import { FinalizeOrder, FinalizeOrderVariables } from "lib/api/mutations/graphql/FinalizeOrder";
import { FINALIZE_ORDER } from "lib/api/mutations/FINALIZE_ORDER";
import { GetCompanyCreationSteps } from "../../../lib/api/queries/GetCompanyCreationSteps";

export interface AdminServiceTiersOnboardingContainerProps {
  gmbh: GmbHDetails;
  refetchCompany: () => Promise<void>;
}
const AdminServiceTiersOnboardingContainer = ({
  gmbh,
  refetchCompany,
  locationHelper
}: AdminServiceTiersOnboardingContainerProps & LocationHelperProps) => {
  const queryParams = useQueryParams();
  const sessionId = queryParams.get("session_id");
  const client = useApolloClient();

  const loggedUser = GetMe();
  const loggedInPersonId = loggedUser.data?.me?.person?.id;

  const [upsertAdminServiceOrder, upsertAdminServiceOrderRequest] = useMutation<
    UpsertOrder,
    UpsertOrderVariables
  >(UPSERT_ORDER);

  const [finalizeAdminServiceOrder, finalizeAdminServiceOrderRequest] = useMutation<
    FinalizeOrder,
    FinalizeOrderVariables
  >(FINALIZE_ORDER);

  const processStepsRequest = GetCompanyCreationSteps(gmbh.companyCreationId ?? "");
  const processSteps = processStepsRequest.data?.getCompanyCreationSteps;
  const bankAccountTaskGroupCompleted = !!processSteps?.find(
    (t) => t.ref === "bankAccount" && t.state === "COMPLETED"
  );

  const getPaymentMethodForCompany = usePaymentQuery<
    GetPaymentMethodForCompany,
    GetPaymentMethodForCompanyVariables
  >(GET_PAYMENT_METHOD_FOR_COMPANY, {
    variables: {
      companyId: gmbh.id,
      paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
    }
  });

  const paymentMethod = getPaymentMethodForCompany.data?.getPaymentMethodForCompany;

  const [updatePaymentMethodMutation, updatePaymentMethodRequest] = usePaymentMutation<
    UpdatePaymentMethod,
    UpdatePaymentMethodVariables
  >(UPDATE_PAYMENT_METHOD);

  const validateSession = async (sessionId: string) => {
    try {
      return await client.query<ValidateStripeSession, ValidateStripeSessionVariables>({
        query: VALIDATE_STRIPE_SESSION,
        variables: {
          sessionId,
          paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
        },
        ...getPaymentsContext()
      });
    } catch (e) {
      logger.error(e);
    }
  };

  const goToCheckoutSession = async () => {
    const currentUrl = new URL(document.URL);

    const response = await client.query<
      getStripeCheckoutSession,
      getStripeCheckoutSessionVariables
    >({
      query: GET_STRIPE_CHECKOUT_SESSION,
      variables: {
        companyId: gmbh.id,
        successUrl: currentUrl.toString(),
        cancelUrl: currentUrl.toString(),
        paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
      }
    });

    const checkoutSessionUrl = response.data.getStripeCheckoutSession?.redirectUrl;
    if (checkoutSessionUrl) {
      locationHelper.redirect(checkoutSessionUrl);
    }
  };

  const upsertOrder = async (orderData: UpsertOrderVariables) => {
    const order = await upsertAdminServiceOrder({
      variables: {
        ...orderData,
        ownerId: loggedInPersonId,
        ownerType: OrderOwnerType.Person
      }
    });
    await refetchCompany();
    return order.data?.upsertOrder;
  };

  const finalizeOrder = async (order: FinalizeOrderVariables) => {
    await finalizeAdminServiceOrder({
      variables: order
    });
    await refetchCompany();
  };

  const updatePaymentMethod = async (paymentMethod: PaymentMethodType) => {
    await updatePaymentMethodMutation({
      variables: {
        companyId: gmbh.id,
        paymentMethod,
        paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
      },
      refetchQueries: [
        {
          query: GET_PAYMENT_METHOD_FOR_COMPANY,
          variables: {
            companyId: gmbh.id,
            paymentPlatformAccount: PaymentPlatformAccount.vvSteuerberatung
          },
          ...getPaymentsContext()
        }
      ]
    });
  };

  return (
    <>
      <RequestFeedbackHandler requests={[updatePaymentMethodRequest]} />
      <RequestFeedbackHandler
        requests={[upsertAdminServiceOrderRequest, finalizeAdminServiceOrderRequest]}>
        <AdminServiceTiersOnboarding
          gmbh={gmbh}
          sessionId={sessionId}
          upsertOrder={upsertOrder}
          updatePaymentMethod={updatePaymentMethod}
          goToCheckoutSession={goToCheckoutSession}
          validateStripeSession={validateSession}
          paymentMethod={paymentMethod}
          finalizeOrder={finalizeOrder}
          bankAccountTaskCompleted={bankAccountTaskGroupCompleted}
        />
      </RequestFeedbackHandler>
    </>
  );
};

export default withLocationHelper(AdminServiceTiersOnboardingContainer);
