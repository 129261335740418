import React, { PropsWithChildren } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReturnLinkWithChevron from "../../../../sharedComponents/ReturnLinkWithChevron/ReturnLinkWithChevron";
import ButtonEdit from "../../../../sharedComponents/Buttons/ButtonEdit/ButtonEdit";

interface InvestmentDetailsLayoutProps extends PropsWithChildren<{}> {
  title: string;
  onEdit?: () => void;
}

export const InvestmentDetailsLayout = (props: InvestmentDetailsLayoutProps) => {
  return (
    <>
      <Container fluid className="investment-details-layout__header">
        <Row>
          <Col>
            <BackNavigation />
          </Col>
        </Row>
        <Row>
          <Col>
            <Headline {...props} />
          </Col>
        </Row>
      </Container>
      <Container className="investment-details-layout__children">
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </Container>
    </>
  );
};

const BackNavigation = () => {
  return (
    <Container className="p-0">
      <Row>
        <Col>
          <ReturnLinkWithChevron
            data-testid="investment-details-back-button"
            to="/client/investments"
          />
        </Col>
      </Row>
    </Container>
  );
};

const Headline = ({ title, onEdit }: InvestmentDetailsLayoutProps) => {
  return (
    <Container className="investment-details-layout-headline">
      <h1
        className="investment-details-layout-headline__title"
        data-testid="investment-details-title">
        {title}
      </h1>
      {onEdit && (
        <ButtonEdit
          className="investment-details-layout-headline__edit"
          data-testid="investment-details-edit"
          variant="dark"
          onClick={onEdit}
        />
      )}
    </Container>
  );
};
