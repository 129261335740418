import { GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId } from "../../api/queries/graphql/GetFiscalYearsByCompanyId";
import {
  FiscalYearDataCompleteness,
  FiscalYearDataCompletenessStatusEnum
} from "../../../client/components/FiscalYearDataUploadConfirmation/FiscalYearDataUploadConfirmation";

export const toFiscalYearDataCompleteness = (
  record: GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId
): FiscalYearDataCompleteness => {
  const { id } = record;
  const fiscalYearStartingDate = record.company.fiscalYear;
  const fiscalYearStartingYear = record.year;

  if (fiscalYearStartingDate === null) {
    return {
      id,
      dueDate: "---",
      fiscalYear: "---",
      status: FiscalYearDataCompletenessStatusEnum.CONFIRMED
    };
  }

  const fiscalYearEndingDate = getFiscalYearEndingDate(
    fiscalYearStartingDate,
    fiscalYearStartingYear
  );

  const dueDate = record.dataUploadCompleted
    ? "---"
    : getDueDate(fiscalYearStartingDate, fiscalYearStartingYear);

  const status = getStatus(record);

  const fiscalYear = `${fiscalYearStartingDate}.${fiscalYearStartingYear} - ${fiscalYearEndingDate}`;

  return {
    id,
    dueDate,
    status,
    fiscalYear
  };
};

const getFiscalYearEndingDate = (
  fiscalYearStartingDate: string,
  fiscalYearStartingYear: string
): string => {
  const fiscalYearDate = parseFiscalYearDate(fiscalYearStartingDate, fiscalYearStartingYear);

  fiscalYearDate.setDate(fiscalYearDate.getDate() - 1);
  fiscalYearDate.setFullYear(fiscalYearDate.getUTCFullYear() + 1);

  return parseDate(fiscalYearDate);
};

const getDueDate = (fiscalYearStartingDate: string, fiscalYearStartingYear: string): string => {
  const fiscalYearDate = parseFiscalYearDate(fiscalYearStartingDate, fiscalYearStartingYear);

  fiscalYearDate.setMonth(fiscalYearDate.getMonth() + 12);
  fiscalYearDate.setDate(fiscalYearDate.getDate() + 14);

  return parseDate(fiscalYearDate);
};

const getStatus = (
  record: GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId
): FiscalYearDataCompletenessStatusEnum => {
  if (record.id === "current-fiscal-year") {
    return FiscalYearDataCompletenessStatusEnum.NOT_POSSIBLE_YET;
  } else if (record.dataUploadCompleted) {
    return FiscalYearDataCompletenessStatusEnum.CONFIRMED;
  } else {
    return FiscalYearDataCompletenessStatusEnum.TO_BE_CONFIRMED;
  }
};

export const parseFiscalYearDate = (dayAndMonth: string, year?: string): Date => {
  const fiscalYearDateAndMonth = dayAndMonth.split(".");
  const anyYear = "2000";
  return new Date(
    Number.parseInt(year || anyYear),
    Number.parseInt(fiscalYearDateAndMonth[1]) - 1,
    Number.parseInt(fiscalYearDateAndMonth[0])
  );
};

const parseDate = (fiscalYearDate: Date): string => {
  const month = fiscalYearDate.getMonth() + 1;
  const day = fiscalYearDate.getDate();
  return `${addLeadingZeroIfNeeded(day)}.${addLeadingZeroIfNeeded(
    month
  )}.${fiscalYearDate.getUTCFullYear()}`;
};

const addLeadingZeroIfNeeded = (num: number): string => {
  return `${num < 10 ? "0" : ""}${num}`;
};
