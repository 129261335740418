import { FormikProps } from "formik";
import TextInput from "./TextInput";
import React, { ChangeEventHandler } from "react";
import { OptionItem, Select } from "./Select";
import { RadioOptionElement, Radios } from "./Radios";
import { MdDateRange } from "react-icons/md";
import DateField from "./DateField";
import { TFunction } from "i18next";
import ReactSelect from "react-select";
import {
  countryListWithFlag,
  defaultCountryOptions
} from "../../lib/dataAdapters/countryUtils/countryUtils";
import TextAreaInput from "./TextAreaInput";
import { ErrorMessage } from "./ErrorMessage";
import RideDatePicker from "../../sharedComponents/RideDatePicker/RideDatePicker";

export const textFieldFor = (t, formik: FormikProps<any>, name, label, extra = {}) => (
  <TextInput
    t={t}
    name={name}
    label={label}
    type="text"
    data-testid={`${name}-input`}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values[name] ?? ""}
    error={formik.errors[name]?.toString()}
    isValid={formik.touched[name] && !formik.errors[name]}
    isInvalid={formik.touched[name] && !!formik.errors[name]}
    {...extra}
  />
);

export const passwordFieldFor = (t, formik: FormikProps<any>, name, label, extra = {}) => (
  <TextInput
    name={name}
    t={t}
    label={label}
    type="password"
    data-testid={`${name}-input`}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values[name]}
    error={formik.errors[name]?.toString()}
    isValid={formik.touched[name] && !formik.errors[name]}
    isInvalid={formik.touched[name] && !!formik.errors[name]}
    {...extra}
  />
);

export const selectFieldFor = (
  t,
  formik: FormikProps<any>,
  name,
  label,
  options: OptionItem[],
  extra: any = {}
) => (
  <Select
    t={t}
    name={name}
    label={label}
    data-testid={`${name}-select`}
    value={formik.values[name]}
    onChange={formik.handleChange}
    options={options}
    error={formik.errors[name]?.toString()}
    isValid={formik.touched[name] && !formik.errors[name]}
    isInvalid={formik.touched[name] && !!formik.errors[name]}
    {...extra}
  />
);

export const radiosFieldFor = (
  label,
  name,
  required,
  options: RadioOptionElement[],
  handleChange: ChangeEventHandler,
  currentSelectedIndex,
  inline?: boolean,
  disabled?: boolean
) => (
  <Radios
    label={label}
    name={name}
    required={required}
    selectedIndex={currentSelectedIndex}
    type={"radio"}
    options={options}
    handleChange={handleChange}
    inline={inline}
    disabled={disabled}
  />
);

interface TextFieldProps {
  t: TFunction;
  formik: any;
  className?: string;
}

export const AddressStreetField = ({ t, formik, className }: TextFieldProps) =>
  textFieldFor(t, formik, "street", "generic:street", { className });

export const AddressCityField = ({ t, formik, className }: TextFieldProps) =>
  textFieldFor(t, formik, "city", "generic:city", { className });

export const AddressPostalCodeField = ({ t, formik, className }: TextFieldProps) =>
  textFieldFor(t, formik, "postalCode", "generic:postal-code", { className });

export const AddressCareOfField = ({ t, formik, className }: TextFieldProps) =>
  textFieldFor(t, formik, "careOf", "generic:c/o", { className });

export const AddressLineTwoField = ({ t, formik, className }: TextFieldProps) =>
  textFieldFor(t, formik, "streetLine2", "generic:street-line-2", { className });

export const CompanyRegistrationField = ({ t, formik, className }: TextFieldProps) =>
  textFieldFor(t, formik, "registration", "generic:registration", { className });

export const EntryDateField = ({ t, formik, name, label }) => {
  const dateFormat: string = "dd/MM/yyyy";
  const selectedDate = formik.values[name] ? new Date(formik.values[name]) : null;
  return dateFieldFor(
    t,
    formik,
    formik.values,
    name,
    label,
    dateFormat,
    selectedDate,
    undefined,
    undefined,
    "",
    formik.touched,
    formik.errors,
    { isClearable: true }
  );
};

export const AddressCountryField = ({
  t,
  formik,
  className
}: {
  t: TFunction;
  formik?: any;
  className?: string;
}) =>
  formik ? (
    <div data-testid={`country-select-container`}>
      <label>{t("generic:country")}</label>

      <ReactSelect
        className={`select-field ${className ?? ""}`}
        name="country"
        options={countryListWithFlag}
        isSearchable={true}
        defaultValue={defaultCountryOptions}
        value={countryListWithFlag.find((option) => option.value === formik.values["country"])}
        onChange={(selectedOption) => formik.handleChange("country")(selectedOption?.value)}
        onBlur={() => formik.setFieldTouched("country", true)}
      />
    </div>
  ) : (
    <TextInput
      name="country"
      t={t}
      label="generic:country"
      type="text"
      value={defaultCountryOptions.value}
      readOnly={true}
    />
  );

interface OwnerSinceFieldProps {
  t: TFunction;
  value?: any;
  onChange?: (any) => void;
  formik?: FormikProps<{ ownerSince: any }>;
}

export const OwnerSinceField = ({ t, value, onChange, formik }: OwnerSinceFieldProps) => {
  const dateFormat: string = "dd/MM/yyyy";

  const formikAwareOnChange = (value) => {
    formik?.setFieldTouched("ownerSince", value);
    formik?.setFieldValue("ownerSince", value, true);
    onChange && onChange(value);
  };

  return (
    <>
      <MdDateRange />
      <DateField
        t={t}
        name="ownerSince"
        data-testid="ownerSince-input"
        label={t("generic:owner-since") + ":"}
        dateFormat={dateFormat}
        maxDate={Date.now()}
        onChange={formikAwareOnChange}
        selected={formik ? formik.values["ownerSince"] : value}
      />
    </>
  );
};

export const textAreaFieldFor = (
  formik: FormikProps<any>,
  name,
  label,
  placeholder,
  rowCount,
  disabled,
  extra = {}
) => (
  <TextAreaInput
    name={name}
    label={label}
    placeholder={placeholder}
    value={formik.values[name] ?? ""}
    data-testid={`${name}-input`}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    rowCount={rowCount}
    disabled={disabled ?? false}
    {...extra}
  />
);

export const dateFieldFor = (
  t,
  formik,
  values,
  name,
  label,
  dateFormat,
  selectedDate,
  preSelectedDate,
  maxDate,
  prefix,
  touched,
  errors,
  extra = {}
) => {
  const formikOnChange = (newDateValue) => {
    formik?.setFieldTouched(prefix + name, newDateValue);
    formik?.setFieldValue(prefix + name, newDateValue, true);
  };

  return (
    <>
      <DateField
        t={t}
        name={prefix + name}
        data-testid={`${prefix + name}-input`}
        label={label}
        dateFormat={dateFormat}
        maxDate={maxDate}
        openToDate={preSelectedDate}
        onChange={formikOnChange}
        selected={selectedDate}
        isValid={(touched ?? {})[name] && !(errors ?? {})[name]}
        isInvalid={(touched ?? {})[name] && !!(errors ?? {})[name]}
        isClearable={extra["isClearable"]}
        {...extra}
      />
      <ErrorMessage name={prefix + name} t={t} extra={extra} />
    </>
  );
};

export const EmailField = ({ t, formik }) => textFieldFor(t, formik, "email", "generic:email");

export const DiscountCodeField = ({ t, formik }) =>
  textFieldFor(t, formik, "discountCode", "", {
    placeholder: t("trader-gmbh:discount-code.enter-referral-code"),
    className: "text-input referral-code-input"
  });

export const datePickerField = (dateFormat, placeholder, label, classname, formik, fieldName) => {
  const changeHandler = (date: string) => {
    formik.handleChange(fieldName)(date);
  };
  return (
    <RideDatePicker
      name={fieldName}
      dateFormat={dateFormat}
      placeholder={placeholder}
      label={label}
      className={classname}
      selectedDate={formik.values.fiscalYear}
      onSelectDate={changeHandler}
    />
  );
};
