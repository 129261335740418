import gql from "graphql-tag";

export const CREATE_WEPA_SERVICE_FOR_WEPA_STANDALONE = gql`
  mutation CreateWepaServiceForWepaStandalone(
    $clientCompanyId: ID!
    $thirdPartyTaxAdvisorCompanyId: ID!
  ) {
    createWepaServiceForWepaStandalone(
      clientCompanyId: $clientCompanyId
      thirdPartyTaxAdvisorCompanyId: $thirdPartyTaxAdvisorCompanyId
    ) {
      feedback
    }
  }
`;
