import { DocumentStatusBadge } from "../../../sharedComponents/DocumentCard/DocumentStatusBadge/DocumentStatusBadge";
import { RideStatus } from "lib/types/types";
import React from "react";

interface ReviewDocumentEntryProps {
  name: string;
  uploaded: boolean;
}

export const ReviewDocumentEntry = ({ name, uploaded }: ReviewDocumentEntryProps) => {
  return (
    <div className="review-documents__entry">
      <span className="review-documents__document-name">{name}</span>
      <DocumentStatusBadge status={uploaded ? RideStatus.COMPLETED : RideStatus.PENDING} />
    </div>
  );
};
