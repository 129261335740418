import { FullScreenModal } from "../../../../components/client/components/FullScreenModal";
import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { StorageConfigStructure } from "../../../../components/client/company/components/GetStorageConfig";
import { TFunction } from "i18next";
import { FileUploadService } from "../../../../lib/services/FileUploadService/FileUploadService";
import { StorageNodesEntityType } from "../../../../global-query-types";
import { ErrorAlert } from "../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { FolderSelectOption } from "../FolderSelectField/FolderSelectField";
import { storageStructureToFolderOptions } from "lib/dataAdapters/FolderOptions/FolderOptions";
import MultipleFoldersSelection from "client/components/Files/MultipleFoldersSelection/MultipleFoldersSelection";
import { FilePlacementProps } from "../UploadFileList/UploadFileList";
import UploadControls from "../UploadControls/UploadControls";

interface Props extends WithTranslation {
  show: boolean;
  onHide: () => void;
  files: File[];
  config: StorageConfigStructure[];
  externalStorageNodes: { [ref: string]: string };
  uploadService: FileUploadService;
  entityId: string;
}

const forbiddenNames = [
  "CON",
  "PRN",
  "AUX",
  "NUL",
  "COM1",
  "COM2",
  "COM3",
  "COM4",
  "COM5",
  "COM6",
  "COM7",
  "COM8",
  "COM9",
  "LPT1",
  "LPT2",
  "LPT3",
  "LPT4",
  "LPT5",
  "LPT6",
  "LPT7",
  "LPT8",
  "LPT9"
];

const UploadModal = ({
  show,
  t,
  onHide: onExternalHide,
  files,
  config,
  externalStorageNodes,
  uploadService,
  entityId
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [validationErrors, setValidationErrors] = useState<(string | undefined)[]>(
    Array.from({ length: files.length }, () => undefined)
  );
  const [selected, setSelected] = useState<(string | undefined)[]>(
    Array.from({ length: files.length }, () => undefined)
  );
  const options = storageStructureToFolderOptions(config);

  const onHide = () => {
    setError(undefined);
    setSelected([]);
    setValidationErrors([]);
    onExternalHide();
  };

  const onChangeSome = (indexes: number[], value?: FolderSelectOption) => {
    const shallowCopy = [...validationErrors];
    const selectedShallowCopy = [...selected];

    indexes.forEach((index) => {
      selectedShallowCopy[index] = externalStorageNodes[value?.value ?? ""];
      shallowCopy[index] = undefined;
    });

    setSelected(selectedShallowCopy);
    setValidationErrors(shallowCopy);
    setError(undefined);
  };

  const getFileDescription = (file: File, t: TFunction): string | undefined => {
    let newFileName = generateNewFileName(file);

    return newFileName !== file.name
      ? `"${file.name}" ${t("client:documents.modal.rename.start")} "${newFileName}" ${t(
          "client:documents.modal.rename.end"
        )}`
      : undefined;
  };

  const generateNewFileName = (file: File): string => {
    let newFileName = "";
    const regex = new RegExp(/^-|[<>:|?*/]|\.$/gm);

    const isFileNameWrong = regex.test(file.name);

    if (isFileNameWrong) {
      newFileName = file.name.replace(regex, "_");
    }

    const parts = file.name.split(".");
    const fileName = parts.shift() ?? "";
    const isFileNameForbidden = forbiddenNames.includes(fileName.toUpperCase());

    if (isFileNameForbidden) {
      let extension = `.${parts.join(".")}`;
      if (extension === ".") extension = "";
      newFileName = `Ride${fileName}${extension}`;
    }
    return newFileName.length > 0 ? newFileName : file.name;
  };

  const uploadFiles = async () => {
    let allValid: boolean = true;
    const validationErrorsCopy = [...validationErrors];

    files.forEach((file, index) => {
      const nodeId = selected[index];
      if (!nodeId) {
        allValid = false;
        validationErrorsCopy[index] = t("client:documents.modal.no-folder-selected");
      }
    });

    setValidationErrors(validationErrorsCopy);

    if (!allValid) return;
    setIsLoading(true);

    try {
      await Promise.all(
        files.map(async (file, index) => {
          const nodeId = selected[index];
          if (!nodeId) return;

          const blob = file.slice(0, file.size, file.type);
          let newFile: File = new File([blob], generateNewFileName(file), { type: file.type });

          await uploadService.addToQueue(nodeId, StorageNodesEntityType.Company, entityId, newFile);
        })
      );

      await uploadService.waitAll();
      onHide();
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
  };

  const fileToFileUploadEntry = (file: File): FilePlacementProps => {
    const fileDescription = getFileDescription(file, t);

    return {
      name: fileDescription ?? file.name,
      destinationFolderValue: "",
      isSelected: false
    };
  };

  return (
    <FullScreenModal show={show} name={"upload-modal"} onHide={onHide}>
      <FullScreenModal.Title title={t("client:documents.modal.title")} />
      <FullScreenModal.Body>
        <MultipleFoldersSelection
          files={files.map(fileToFileUploadEntry)}
          validationErrors={validationErrors}
          options={options}
          onFolderSelectSome={onChangeSome}
        />

        <div className={`upload-modal__error ${error ? "upload-modal__error--active" : ""}`}>
          <ErrorAlert t={t} error={error} />
        </div>

        <UploadControls onSubmit={uploadFiles} onCancel={onHide} isLoading={isLoading} />
      </FullScreenModal.Body>
    </FullScreenModal>
  );
};

export default withTranslation(["client"])(UploadModal);
