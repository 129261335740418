import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LegalForm } from "../../global-query-types";
import { formatCompanyName } from "../../common/formatters/CompanyNameFormatter";

export interface CompanyNameOrUnnamedProps extends WithTranslation {
  company: { name?: string; legalForm?: LegalForm };
}

const CompanyNameOrUnnamed = ({ t, company }: CompanyNameOrUnnamedProps) => (
  <span data-testid="company-full-name">{formatCompanyName(t, company)}</span>
);

export default withTranslation(["generic"])(CompanyNameOrUnnamed);
