import React, { useState } from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { RideHeaderColumn } from "../../../lib/types/types";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { formatDateTime } from "../../../common/formatters";
import { RideBadgeStatus, RideBadgeVariantsEnum } from "../../../uiLibrary/components";
import { getOrderStatusToRideBadge } from "../NewBrokerOrdersList/NewBrokerOrdersList";
import { getWepaDirectOrders_getWepaDirectOrders_items } from "../../../lib/api/queries/graphql/getWepaDirectOrders";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "../../../uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";

interface WepaDirectOrdersListProps extends WithTranslation {
  ordersWithCompany: getWepaDirectOrders_getWepaDirectOrders_items[];
  archiveWepaOrder: (orderId: string) => Promise<void>;
}
const WepaDirectOrdersList = ({
  t,
  ordersWithCompany,
  archiveWepaOrder
}: WepaDirectOrdersListProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [archiveOrderId, setArchiveOrderId] = useState<string>("");

  const columns: RideHeaderColumn[] = [
    t("generic:new-broker-order-list.columns.order"),
    t("generic:new-broker-order-list.columns.date"),
    t("generic:new-broker-order-list.columns.ride-status"),
    t("generic:archive")
  ].map((label) => ({
    label,
    name: label.toLowerCase()
  }));

  return (
    <>
      <ConfirmationModal
        dataTestId={"confirm-archive-modal"}
        title={t("generic:archive-wepa-direct-order-title")}
        message={t("generic:archive-wepa-direct-order-confirmation")}
        type={ConfirmationModalType.DANGER}
        confirmLabel={t("generic:archive")}
        visible={showConfirmationModal}
        onConfirm={async () => {
          await archiveWepaOrder(archiveOrderId);
        }}
        onClose={() => {
          setArchiveOrderId("");
          setShowConfirmationModal(false);
        }}
      />
      <div
        data-testid="wepa-direct-orders-list-table"
        className={"low-tax-broker-orders-list-table"}>
        <RideTableHeader columns={columns} />
        {ordersWithCompany.map((ordersWithCompany, index) => {
          const order = ordersWithCompany.order ?? {
            id: "test-this",
            status: null,
            createdAt: null,
            extra: {} as any
          }; //TODO: look resolver (getWepaDirectOrders) to order not be null
          const tradeCompany = ordersWithCompany.company;

          return (
            <RideTableRow
              colCount={columns.length}
              key={index}
              className={"low-tax-broker-orders-list-table__row"}>
              <RideTableCell
                link={`/nominee-companies/${tradeCompany?.id}`}
                value={tradeCompany?.name || `non-existent-company-name ${order.id}`} //TODO: test, use formatter to companyName
                variant={RideTableCellVariant.primaryLinkDecorated}
                dataTestId={`${order.id}-trading-company-name`}
              />
              <RideTableCell
                value={formatDateTime(new Date(order.createdAt))}
                variant={RideTableCellVariant.secondaryText}
              />
              <RideTableCell
                value={
                  <RideBadgeStatus
                    label={getOrderStatusToRideBadge(order.status ?? "").label}
                    color={getOrderStatusToRideBadge(order.status ?? "").color}
                    variant={RideBadgeVariantsEnum.OUTLINED}
                  />
                }
              />
              <RideTableCell
                dataTestId={"archive"}
                variant={RideTableCellVariant.actionButton}
                value={t("generic:archive")}
                action={() => {
                  setArchiveOrderId(order.id);
                  setShowConfirmationModal(true);
                }}
              />
            </RideTableRow>
          );
        })}
      </div>
    </>
  );
};

export default withTranslationReady(["generic"])(WepaDirectOrdersList);
