import { Col, Container, Modal, Row } from "react-bootstrap";
import React, { PropsWithChildren } from "react";

type Props = {
  show: boolean;
  name: string;
  onHide: () => void;
  darkMode?: boolean;
  className?: string;
};

export const FullScreenModal = ({
  show,
  name,
  onHide,
  children,
  darkMode,
  className = ""
}: PropsWithChildren<Props>) => {
  const modifierClassName = darkMode ? "full-screen-modal--dark" : "";

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={false}
      dialogClassName={`full-screen-modal ${modifierClassName} ${className}`}
      aria-labelledby={name}
      data-testid={name}>
      <Modal.Header closeButton>
        <Modal.Title id={name} data-testid={`${name}-title`} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

FullScreenModal.Title = ({ title }: { title: React.ReactElement }) => (
  <div className="full-screen-modal__title">{title}</div>
);

FullScreenModal.Body = ({ children }: PropsWithChildren<{}>) => (
  <div className="full-screen-modal__body">{children}</div>
);

FullScreenModal.Controls = ({ children }: PropsWithChildren<{}>) => (
  <div className="full-screen-modal__controls">{children}</div>
);
