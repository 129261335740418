import React, { PropsWithChildren } from "react";
import { DonutCompletionStatus } from "../../../client/components/CompletionDonut/CompletionDonut";
import { IconProps } from "./IconProps";

export interface CompletionDonutIconProps extends IconProps, PropsWithChildren<{}> {
  completion: DonutCompletionStatus;
}
export const CompletionDonutIcon = (props: CompletionDonutIconProps) => {
  switch (props.completion) {
    case DonutCompletionStatus.ZERO_PERCENT:
      return <IconZeroPercent {...props} />;
    case DonutCompletionStatus.SIXTEEN_PERCENT:
      return <IconSixteenPercent {...props} />;
    case DonutCompletionStatus.SIXTY_PERCENT:
      return <IconSixtyPercent {...props} />;
    case DonutCompletionStatus.ONE_HUNDRED_PERCENT:
      return <IconOneHundredPercent {...props} />;
  }
};

export const DonutSparkleIcon = ({ className }: { className?: string }) => {
  return (
    <>
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5 22.5961L31.4423 20.25L29.0962 19.1923L31.4423 18.1346L32.5 15.7885L33.5577 18.1346L35.9038 19.1923L33.5577 20.25L32.5 22.5961ZM32.5 36.2115L31.4423 33.8654L29.0962 32.8077L31.4423 31.75L32.5 29.4039L33.5577 31.75L35.9038 32.8077L33.5577 33.8654L32.5 36.2115Z"
        fill="currentColor"
      />
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8846 32.7884L20.7692 28.1154L16.0962 26L20.7692 23.8847L22.8846 19.2116L25 23.8847L29.673 26L25 28.1154L22.8846 32.7884ZM23.8846 27L22.8846 29.15L21.8846 27L19.7346 26L21.8846 25L22.8846 22.85L23.8846 25L26.0346 26L23.8846 27Z"
        fill="currentColor"
      />
    </>
  );
};

export const DonutNumber = ({ className, num }: { className?: string; num: number }) => {
  return (
    <text
      x="50%"
      y="53%"
      textAnchor="middle"
      dominantBaseline="middle"
      className={`${className} typography typography--headline-200 typography--heavy`}
      fill="currentColor">
      {num}
    </text>
  );
};

export const IconZeroPercent = ({ size, className, children }: CompletionDonutIconProps) => {
  return (
    <svg
      data-testid={"completion-donut-icon-zero"}
      className={className}
      width={size ?? "52"}
      height={size ?? "52"}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26ZM5.2 26C5.2 37.4875 14.5125 46.8 26 46.8C37.4875 46.8 46.8 37.4875 46.8 26C46.8 14.5125 37.4875 5.2 26 5.2C14.5125 5.2 5.2 14.5125 5.2 26Z"
        fill="#E6EBE7"
      />
      {children}
    </svg>
  );
};

export const IconSixteenPercent = ({ size, className, children }: CompletionDonutIconProps) => {
  return (
    <svg
      data-testid={"completion-donut-icon-sixteen"}
      className={className}
      width={size ?? "52"}
      height={size ?? "52"}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.2693 0.352919C36.8905 1.45511 42.8288 5.07524 46.8418 10.4558C50.8547 15.8364 52.6313 22.5605 51.7997 29.221C50.9682 35.8816 47.5929 41.9624 42.3803 46.1912C37.1677 50.42 30.5216 52.469 23.8327 51.9095C17.1438 51.35 10.9304 48.2253 6.49282 43.1892C2.05518 38.1532 -0.26287 31.5961 0.0237077 24.8899C0.310285 18.1838 3.17929 11.8483 8.03037 7.20924C12.8815 2.57014 19.3387 -0.0131028 26.0509 4.99158e-05L26.0408 5.20004C20.671 5.18952 15.5052 7.25611 11.6243 10.9674C7.74343 14.6787 5.44823 19.747 5.21897 25.1119C4.9897 30.4769 6.84414 35.7226 10.3943 39.7514C13.9444 43.7802 18.915 46.28 24.2661 46.7276C29.6172 47.1752 34.9341 45.536 39.1042 42.153C43.2744 38.7699 45.9745 33.9052 46.6398 28.5768C47.305 23.2484 45.8838 17.8691 42.6734 13.5647C39.4631 9.26019 34.7124 6.36408 29.4155 5.48233L30.2693 0.352919Z"
        fill="#E6EBE7"
      />
      <path
        d="M26 0C30.8304 5.76017e-08 35.5653 1.34562 39.6737 3.88597C43.7821 6.42632 47.1016 10.0609 49.26 14.3823L44.608 16.7058C42.8813 13.2488 40.2257 10.3411 36.9389 8.30877C33.6522 6.2765 29.8643 5.2 26 5.2V0Z"
        fill="#0E8127"
      />
      {children}
    </svg>
  );
};

export const IconSixtyPercent = ({ size, className, children }: CompletionDonutIconProps) => {
  return (
    <svg
      data-testid={"completion-donut-icon-sixty"}
      className={className}
      width={size ?? "52"}
      height={size ?? "52"}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52 26C52 31.1423 50.4751 36.1691 47.6182 40.4448C44.7613 44.7205 40.7006 48.053 35.9498 50.0209C31.1989 51.9887 25.9712 52.5036 20.9277 51.5004C15.8841 50.4972 11.2514 48.0209 7.61522 44.3848C3.97906 40.7486 1.5028 36.1159 0.499583 31.0723C-0.503633 26.0288 0.0112537 20.8011 1.97913 16.0502C3.94701 11.2994 7.2795 7.23871 11.5552 4.38179C15.8309 1.52487 20.8577 -6.13215e-08 26 0V5.2C21.8861 5.2 17.8647 6.4199 14.4441 8.70543C11.0236 10.991 8.35761 14.2395 6.7833 18.0402C5.209 21.8409 4.79709 26.0231 5.59967 30.0579C6.40224 34.0927 8.38325 37.7989 11.2922 40.7078C14.2011 43.6168 17.9073 45.5978 21.9421 46.4003C25.9769 47.2029 30.1591 46.791 33.9598 45.2167C37.7605 43.6424 41.009 40.9764 43.2946 37.5559C45.5801 34.1353 46.8 30.1139 46.8 26H52Z"
        fill="#E6EBE7"
      />
      <path
        d="M26 0C30.1031 4.89286e-08 34.1479 0.971075 37.8038 2.83383C41.4596 4.69658 44.6227 7.39813 47.0344 10.7176C49.4462 14.037 51.038 17.8801 51.6799 21.9327C52.3218 25.9853 51.9954 30.1322 50.7275 34.0344C49.4596 37.9367 47.2861 41.4835 44.3848 44.3848C41.4835 47.2861 37.9367 49.4595 34.0344 50.7275C30.1322 51.9954 25.9853 52.3218 21.9327 51.6799C17.8802 51.038 14.037 49.4462 10.7176 47.0344L13.7741 42.8276C16.4296 44.7569 19.5041 46.0304 22.7462 46.5439C25.9882 47.0574 29.3058 46.7963 32.4276 45.782C35.5494 44.7676 38.3868 43.0289 40.7078 40.7078C43.0289 38.3868 44.7676 35.5494 45.782 32.4276C46.7963 29.3057 47.0574 25.9882 46.5439 22.7462C46.0304 19.5041 44.7569 16.4296 42.8276 13.7741C40.8982 11.1185 38.3677 8.95727 35.443 7.46706C32.5183 5.97686 29.2825 5.2 26 5.2V0Z"
        fill="#0E8127"
      />
      {children}
    </svg>
  );
};

export const IconOneHundredPercent = ({ size, className, children }: CompletionDonutIconProps) => {
  return (
    <svg
      data-testid={"completion-donut-icon-one-hundred"}
      className={className}
      width={size ?? "52"}
      height={size ?? "52"}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52 26C52 31.1423 50.4751 36.1691 47.6182 40.4448C44.7613 44.7205 40.7006 48.053 35.9498 50.0209C31.1989 51.9887 25.9712 52.5036 20.9277 51.5004C15.8841 50.4972 11.2514 48.0209 7.61522 44.3848C3.97906 40.7486 1.5028 36.1159 0.499583 31.0723C-0.503633 26.0288 0.0112537 20.8011 1.97913 16.0502C3.94701 11.2994 7.2795 7.23871 11.5552 4.38179C15.8309 1.52487 20.8577 -6.13215e-08 26 0V3.9C21.629 3.9 17.3562 5.19614 13.7219 7.62452C10.0876 10.0529 7.25496 13.5044 5.58226 17.5427C3.90957 21.5809 3.47191 26.0245 4.32465 30.3115C5.17738 34.5985 7.2822 38.5363 10.3729 41.6271C13.4637 44.7178 17.4015 46.8226 21.6885 47.6754C25.9755 48.5281 30.4191 48.0904 34.4573 46.4177C38.4956 44.745 41.9471 41.9124 44.3755 38.2781C46.8039 34.6438 48.1 30.371 48.1 26H52Z"
        fill="#E6EBE7"
      />
      <path
        d="M52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26ZM3.9 26C3.9 38.2055 13.7945 48.1 26 48.1C38.2055 48.1 48.1 38.2055 48.1 26C48.1 13.7945 38.2055 3.9 26 3.9C13.7945 3.9 3.9 13.7945 3.9 26Z"
        fill="#0E8127"
      />
      {children}
    </svg>
  );
};
