import React from "react";
import { Trans } from "react-i18next";
import { Container } from "react-bootstrap";
import ContinueOrderBanner from "client/components/ContinueOrderBanner/ContinueOrderBanner";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { OrderProductTypeEnum } from "global-query-types";

export interface ContinueCompanyOrderBannerProps {
  gmbh: GmbHDetails;
}

const ContinueCompanyFoundingOrderBanner = ({ gmbh }: ContinueCompanyOrderBannerProps) => {
  const companyFoundationOrder = gmbh.orders.find(
    (o) => o.productType === OrderProductTypeEnum.CompanyFoundation
  );

  const linkToContinueOrder = companyFoundationOrder
    ? `/client/order/generic/business-purpose/0/${companyFoundationOrder.id}/${companyFoundationOrder.submissionId}`
    : `/booking/gmbh/${gmbh.companyCreationId}`;

  return (
    <Container>
      <ContinueOrderBanner
        dataTestId={"continue-grundung-service-banner"}
        ctaLink={linkToContinueOrder}
        message={
          <Trans
            i18nKey={"generic:continue-grundung-service-banner-message"}
            components={{ bold: <strong /> }}
          />
        }
      />
    </Container>
  );
};

export default ContinueCompanyFoundingOrderBanner;
