import BankSelectCard from "../BankSelectCard/BankSelectCard";
import React from "react";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { BankType } from "../../../../../global-query-types";

export interface BankOption {
  name: BankType;
  isSelected: boolean;
  isPartnerBank: boolean;
}

export interface BankSelectionOptionsProps extends WithTranslation {
  banks: BankOption[];
  onSelect: (bankName: string) => void;
}

const BankSelectionOptions = ({ banks, t, onSelect }: BankSelectionOptionsProps) => {
  return (
    <div className="bank-selection-options" data-testid="bank-selection-options">
      {banks.map(({ name, isSelected, isPartnerBank }, index) => {
        return (
          <div
            className="bank-selection-options__bank-option"
            key={index}
            onClick={() => onSelect(name)}>
            <BankSelectCard
              isSelectable={true}
              isSelected={isSelected}
              name={t(`client:banking.banks.${name}`)}
              iconPath={`/assets/client/banks/${name}.svg`}
              setupTimeEstimate={t(`client:banking.setupTime.${name}`)}
              costEstimate={t(`client:banking.cost.${name}`)}
              infoLink={t(`client:banking.infoLink.${name}`)}
              isPartnerBank={isPartnerBank}
              description={t(`client:banking.description.${name}`)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default withTranslationReady("client")(BankSelectionOptions);
