export const parseGermanStreet = (street: string) => {
  const result = {
    streetName: "",
    buildingNumber: "",
    unit: "",
    buildingIdentifier: "",
    floor: ""
  };

  // Regular expressions for different address components
  const streetNumberRegex = /\b(\d\w*)\b/;
  const unitRegex = /(Einheit|Eigentumwohnung|Wohnung|Apt)\s*(\w+)/i;
  const buildingIdentifierRegex = /(Haus|Anbau|Seitenflügel|Eingang)\s*([\w\s]+)/i;
  const floorRegex = /(\d+\.\s*Stockwerk|\bEG\b)/i;

  // Extract unit if present
  const unitMatch = street.match(unitRegex);
  if (unitMatch) {
    result.unit = unitMatch[0];
    street = street.replace(unitRegex, "").trim();
  }

  // Extract building identifier if present
  const buildingIdentifierMatch = street.match(buildingIdentifierRegex);
  if (buildingIdentifierMatch) {
    result.buildingIdentifier = buildingIdentifierMatch[0];
    street = street.replace(buildingIdentifierRegex, "").trim();
  }

  // Extract floor if present
  const floorMatch = street.match(floorRegex);
  if (floorMatch) {
    result.floor = floorMatch[0];
    street = street.replace(floorRegex, "").trim();
  }

  // Extract street number
  const streetNumberMatch = street.match(streetNumberRegex);
  if (streetNumberMatch) {
    result.buildingNumber = streetNumberMatch[1];
    street = street.replace(streetNumberRegex, "").trim();
  }

  while (street.endsWith(",") || street.startsWith(",")) {
    street = street.replace(/(,$|^,)/, "").trim();
  }

  // Whatever is left is the street name
  result.streetName = street;

  return result;
};
