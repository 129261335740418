import { ClientTaskSwitcher } from "./ClientTaskSwitcher";
import React from "react";
import { GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany } from "lib/api/administration/queries/graphql/GetEndOfYearTasksByCompany";

interface Props {
  tasks: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany[];
}

export const ClientTasks = ({ tasks }: Props) => {
  return (
    <div className="mt-4">
      {tasks.map((task, index) => {
        return (
          <div key={task.id} className="my-3">
            <ClientTaskSwitcher index={index + 1} task={task} />
          </div>
        );
      })}
    </div>
  );
};
