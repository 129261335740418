import * as Yup from "yup";
import { WalletAddressSchema } from "./WalletAddressSchema";
import { isValidEthereumAddress } from "./isValidEthereumAddress";

export const UpsertTokenizedInvestmentSchema = () => {
  return Yup.object().shape({
    equityTokenAddress: Yup.string()
      .required("generic:validation-required")
      .test(
        "contractValidAddress",
        "spv-company:invalid-token-address",
        (value?: string | null) => {
          if (!value) {
            return false;
          }

          return isValidEthereumAddress(value);
        }
      ),
    spvWalletAddress: WalletAddressSchema()
  });
};
