import React, { PropsWithChildren, ReactNode } from "react";
import BackButton from "uiLibrary/v2/components/Buttons/BackButton/BackButton";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { ArrowRight } from "sharedComponents/icons/DesignSystemIcons/ArrowRight";

interface StepLayout extends PropsWithChildren<{}> {
  "data-testid"?: string;
  className?: string;
}

export const StepLayout = ({ "data-testid": dataTestId, className, children }: StepLayout) => {
  return (
    <div data-testid={dataTestId} className={`short-order-form-step-layout ${className ?? ""}`}>
      {children}
    </div>
  );
};

export const StepContent = ({ children }: PropsWithChildren<{}>) => (
  <div className="short-order-form-step-layout__content">{children}</div>
);

export const StepHeader = ({
  "data-testid": dataTestId,
  title,
  subtitle,
  backButtonHandler
}: {
  "data-testid": string;
  title: string;
  subtitle: string | ReactNode;
  backButtonHandler?: () => void;
}) => {
  return (
    <div
      className={`short-order-form-step-layout__header ${
        backButtonHandler ? "" : "short-order-form-step-layout__header--without-controls"
      }`}>
      {backButtonHandler && (
        <BackButton
          onClick={backButtonHandler}
          data-testid="go-to-previous-step-button"
          classNames={"short-order-form-step-layout__controls"}
        />
      )}
      <Typography
        category="Headline"
        size={100}
        weight={"Heavy"}
        data-testid={`${dataTestId}-title`}
        className="short-order-form-step-layout__title">
        {title}
      </Typography>
      {subtitle && (
        <Typography
          category="Paragraph"
          size={100}
          weight={"Light"}
          data-testid={`${dataTestId}-subtitle`}
          className="short-order-form-step-layout__subtitle">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export const StepContinueButton = ({
  "data-testid": dataTestId = "continue-button",
  label
}: {
  "data-testid"?: string;
  label: string;
}) => {
  return (
    <div className="short-order-form-step-layout__continue-button-section">
      <RideButton
        type={"submit"}
        data-testid={dataTestId}
        className="short-order-form-step-layout__continue-button"
        rightIcon={<ArrowRight size={20} />}
        label={label}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
      />
    </div>
  );
};
