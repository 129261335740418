import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Container } from "react-bootstrap";

interface IBannerProps {
  bannerHeading;
  bannerText;
  size?: "large" | "small";
}

export const Banner = ({ bannerHeading, bannerText, size = "large" }: IBannerProps) => {
  return (
    <Jumbotron
      fluid
      data-testid="banner"
      className={`banner ${size === "large" ? "py-4 py-md-6" : "m-0"}`}>
      <Container className="text-light">
        <h3 data-testid="banner-heading">{bannerHeading}</h3>

        <p data-testid="banner-text" className="mb-0">
          {bannerText}
        </p>
      </Container>
    </Jumbotron>
  );
};
