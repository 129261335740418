import React from "react";
import ReactSelect from "react-select";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";
import { useField } from "formik";

export interface RideDropdownSelectProps {
  name: string;
  label?: string;
  options: DropdownSelectOption[];
  placeholder?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  className?: string;
  isOnModal?: boolean;
}

export interface DropdownSelectOption {
  label: string;
  value: string;
}

export const RideDropdownSelect = ({
  name,
  label,
  options,
  className,
  disabled,
  placeholder,
  isClearable = false,
  isSearchable = false,
  isOnModal = false
}: RideDropdownSelectProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div
      data-testid={`${name}-select-container`}
      className={`ride-dropdown-select ${
        meta.touched && meta.error ? "ride-dropdown-select--error" : ""
      } ${className ?? ""}`}
      aria-disabled={disabled}>
      {label && (
        <label data-testid={`${name}-label`} className="ride-dropdown-select__label">
          {label}
        </label>
      )}
      <ReactSelect
        name={name}
        options={options}
        components={{
          IndicatorSeparator: null
        }}
        className="ride-dropdown-select__select"
        classNamePrefix="ride-dropdown-select__select"
        value={options.find((option) => option.value === field.value)}
        onBlur={() => helpers.setTouched(true)}
        onChange={(option) => helpers.setValue(option?.value)}
        placeholder={placeholder}
        isDisabled={disabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isOnModal={isOnModal}
        styles={isOnModal ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : undefined}
        menuPortalTarget={isOnModal ? document.body : undefined}
      />
      <ErrorMessageWithT name={name} extra={{}} />
    </div>
  );
};
