import React from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { AdminServiceOrderData } from "lib/models/client/AdminService/AdminServiceOrderData";
import FiscalYearStart from "client/components/ChaptersOrderLayout/AdminServiceOnboardingForm/FiscalYearStart/FiscalYearStart";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

export const FiscalYearStartContainer = (props: StepComponentProps<AdminServiceOrderData>) => {
  const queryCompanyById = QueryCompanyById(props.order.entityId);

  return (
    <RequestFeedbackHandler requests={[queryCompanyById]}>
      <FiscalYearStart {...props} company={queryCompanyById.data?.companyById} />
    </RequestFeedbackHandler>
  );
};
