import React from "react";
import { TFunction } from "i18next";
import { Form } from "react-bootstrap";
import { InvalidFeedback } from "../../uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";

interface DateTimeFieldProps {
  t: TFunction;
  name: string;
  children: React.ReactNode;
  testid: string;
  error?: string;
  label?: string;
  className?: string;
}

export const FormField = ({
  children,
  testid,
  label,
  error,
  name,
  className,
  t
}: DateTimeFieldProps) => {
  return (
    <Form.Group data-testid={testid} className={className ?? ""}>
      {label && <Form.Label className="mr-3">{t(label)}</Form.Label>}

      <div>{children}</div>

      {error && (
        <InvalidFeedback hidden={false} testPrefix={name}>
          {t(error)}
        </InvalidFeedback>
      )}
    </Form.Group>
  );
};

export default FormField;
