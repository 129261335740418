import { ShareholderEntity } from "./Shareholder";
import { EmployeeEntity } from "./Employee";
import { filterNotNull } from "../../../types/filterNotNull";
import { Entity } from "./Entity";
import { AddressData } from "../AddressData";
import { PersonData } from "./PersonData";
import { CompanyData } from "../CompanyData";

export interface CompanyFoundingOrderData {
  extra: any;
}

export class CompanyFoundingOrder {
  private data: any;

  constructor({ extra }: CompanyFoundingOrderData) {
    this.data = extra;
  }

  getShareholder(index: number | string): ShareholderEntity | undefined {
    const shareholder = this.data.shareholders?.[index];
    if (!shareholder) return;

    return new ShareholderEntity(index, shareholder);
  }

  getEmployee(index: number | string): EmployeeEntity | undefined {
    const employee = this.data.employees?.[index];
    if (!employee) return;

    return new EmployeeEntity(index, employee);
  }

  getEmails(without: Entity | undefined = undefined): string[] {
    const entities = this.getEntities();

    return filterNotNull(
      entities
        .filter((e) => e.key !== without?.key || `${e.index}` !== `${without?.index}`)
        .map((e) => e.email)
    );
  }

  getEntities(): Entity[] {
    const shareholders = filterNotNull(
      Object.keys(this.data.shareholders ?? {}).map((index) => this.getShareholder(index))
    );

    const employees = filterNotNull(
      Object.keys(this.data.employees ?? {}).map((index) => this.getEmployee(index))
    );

    return ([] as Entity[]).concat(shareholders).concat(employees);
  }

  getAddresses(): { address: AddressData; entity: PersonData | CompanyData | undefined }[] {
    const entities = this.getEntities();
    let addressesData: { address: AddressData; entity: PersonData }[] = [];

    entities.forEach((entity) => {
      if (entity.personAddress) {
        addressesData.push({
          address: entity.personAddress,
          entity: entity["personData"]
        });
      }
      if (entity.companyAddress) {
        addressesData.push({
          address: entity.companyAddress,
          entity: entity["companyData"]
        });
      }
    });

    return addressesData;
  }

  isSameAddress(address: AddressData, other: AddressData) {
    return (
      address.city === other.city &&
      address.country === other.country &&
      address.postalCode === other.postalCode &&
      address.streetLine1 === other.streetLine1 &&
      (address.streetLine2.includes(other.streetLine2) ||
        other.streetLine2.includes(address.streetLine2))
    );
  }
}
