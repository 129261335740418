import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  MyCompanyCreationSummarized,
  MyCompanyCreationSummarizedVariables
} from "lib/api/queries/graphql/MyCompanyCreationSummarized";

export const MY_COMPANY_CREATION_SUMMARIZED_QUERY = gql`
  query MyCompanyCreationSummarized($id: ID!) {
    myCompanyCreation(id: $id) {
      id
      company {
        id
      }
      adminCompany {
        id
      }
    }
  }
`;

export const GetMyCompanyCreationSummarized = (companyCreationId?: string) =>
  useQuery<MyCompanyCreationSummarized, MyCompanyCreationSummarizedVariables>(
    MY_COMPANY_CREATION_SUMMARIZED_QUERY,
    {
      variables: {
        id: companyCreationId ?? ""
      },
      skip: !companyCreationId
    }
  );
