import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import LoadingAlert from "../generic/LoadingAlert";
import { ErrorAlert } from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { MutationResult, QueryResult } from "react-apollo";
import { ApolloError } from "apollo-client";

export type RequestFeedbackInfo = { loading: boolean; error?: ApolloError };

export interface RequestFeedbackHandlerProps extends WithTranslation {
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
  forceLoading?: boolean;
  children?: any;
  noLoadingAnimation?: boolean;
  hideErrors?: boolean;
  showChildrenWhileLoading?: boolean;
}

const RequestFeedbackHandler = ({
  t,
  requests,
  children,
  forceLoading,
  noLoadingAnimation = false,
  showChildrenWhileLoading = false,
  hideErrors = false
}: RequestFeedbackHandlerProps) => {
  const showLoading = forceLoading || requests.find((a) => a.loading);
  const errorList = requests
    .filter((request) => request.error)
    .map((request, index) => <ErrorAlert t={t} error={request.error?.message} key={index} />);

  return (
    <>
      {!hideErrors && errorList}
      {showLoading && !showChildrenWhileLoading ? (
        <LoadingAlert t={t} noLoadingAnimation={noLoadingAnimation} />
      ) : (
        children
      )}
    </>
  );
};

export default withTranslation(["generic", "errorCodes"])(RequestFeedbackHandler);
