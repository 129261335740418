import React from "react";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface RideHamburgerProps {
  isActive: boolean;
  onClick?: () => void;
}

export const RideHamburger = ({ isActive, onClick }: RideHamburgerProps) => (
  <RideButtonDeprecated
    onClick={onClick}
    className={`hamburger hamburger--spin ${isActive ? "is-active" : ""}`}
    data-testid="hamburger-button">
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </RideButtonDeprecated>
);
