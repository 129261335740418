import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalLayout,
  ModalLayoutVariant
} from "../../../sharedComponents/templates/ModalLayout/ModalLayout";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { EditBankAccountContainer } from "../../components/Banking/EditBankAccount/EditBankAccountContainer";
import { useApolloClient } from "@apollo/react-hooks";
import { FeatureFlags } from "global-query-types";

export const BankAccountEditScene = () => {
  const history = useHistory();
  const client = useApolloClient();

  const handleClose = () => {
    client.reFetchObservableQueries();
    history.replace(`/client/banking`);
  };

  return (
    <FeatureFlag name={FeatureFlags.BankAccountsList}>
      <ModalLayout onClose={handleClose} variant={ModalLayoutVariant.white}>
        <EditBankAccountContainer onRefresh={async () => handleClose()} />
      </ModalLayout>
    </FeatureFlag>
  );
};
