import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { BrokerBankEnum } from "global-query-types";
import {
  CompanyBrokerageAccounts,
  CompanyBrokerageAccountsVariables
} from "lib/api/queries/graphql/CompanyBrokerageAccounts";

export const GET_COMPANY_BROKERAGE_ACCOUNTS = gql`
  query CompanyBrokerageAccounts($companyId: ID!, $brokerBank: BrokerBankEnum) {
    companyBrokerageAccounts(companyId: $companyId, brokerBank: $brokerBank) {
      id
      brokerBank
      tokenUpdatedAt
      queryId
      accountNumber
    }
  }
`;

export const GetCompanyBrokerageAccounts = (
  companyId?: string | null,
  brokerBank?: BrokerBankEnum
) =>
  useQuery<CompanyBrokerageAccounts, CompanyBrokerageAccountsVariables>(
    GET_COMPANY_BROKERAGE_ACCOUNTS,
    {
      variables: {
        companyId: companyId as string,
        brokerBank
      },
      skip: !companyId,
      fetchPolicy: "no-cache"
    }
  );
