import React from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import LoadingAlert from "../LoadingAlert";
import Dropzone from "../DropZone";
import { ConfirmPhotoUpload } from "./ConfirmPhotoUpload";

export const RenderImageUploadFunctionality = ({
  t,
  uploading,
  previewS3,
  previewThumbs,
  tooManyPhotos,
  availableCount,
  canUploadMore,
  handleOnDrop
}) => (
  <Container>
    <Row>
      <Col>
        <LoadingAlert t={t} loading={uploading} message="generic:in-progress" />
      </Col>
    </Row>
    <br />
    {!uploading && (
      <>
        <Row>
          <Container>
            <Col>{previewS3}</Col>
          </Container>
        </Row>
        {t("generic:preview")}
        <Row>
          <Container>
            <Col>{previewThumbs}</Col>
          </Container>
        </Row>
        <br />
        <Row>
          <Col>
            <Badge
              variant={tooManyPhotos ? "danger" : "primary"}
              data-testid="photos-left"
              className="float-sm-right">
              {availableCount + "/" + 25}
            </Badge>

            {canUploadMore && (
              <Dropzone
                handleOnDrop={handleOnDrop}
                multiple={true}
                dragLabel={t("generic:drop-the-files-here")}
                dropLabel={t("generic:drop-or-click")}
              />
            )}
          </Col>
        </Row>
      </>
    )}
  </Container>
);

export const RenderImageUploadButton = ({
  t,
  files,
  submitHandler,
  realEstateId,
  setUploading,
  tooManyPhotos,
  anyFileSizeExceeded,
  cancelHandler,
  overlay
}) => (
  <Row>
    <Col>
      <ConfirmPhotoUpload
        files={files}
        submitHandler={submitHandler}
        realEstateId={realEstateId}
        onUploadStarted={() => setUploading(true)}
        isDisabled={tooManyPhotos || anyFileSizeExceeded}
      />
      {cancelHandler ? (
        <Button
          variant="link"
          onClick={cancelHandler}
          className="btn-secondary float-sm-right mr-2">
          {overlay ? t("generic:cancel") : t("generic:reset")}
        </Button>
      ) : (
        ""
      )}
    </Col>
  </Row>
);
