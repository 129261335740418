import { TFunction } from "i18next";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import {
  AccountingFrameworkEnum,
  BankAccountPlan,
  BankType,
  BrokerageAccountValidityEnum,
  BrokerBankEnum,
  BrokerNameEnum,
  CompanyCreationStep,
  CompanyOrderStatus,
  FlowTypeEnum,
  InvoiceSource,
  LegalForm,
  TaxAdvisorStatusEnum,
  TaxServiceTypeEnum
} from "global-query-types";
import { RideTableCellAlignment } from "sharedComponents/RideTableCell/RideTableCell";
import React from "react";
import {
  CompanyById_companyById_migrations,
  CompanyById_companyById_orders
} from "../api/queries/graphql/CompanyById";
import { NomineeCompanyDetails_companyById_orders } from "../api/queries/graphql/NomineeCompanyDetails";

export interface CompanyCardProps {
  t: TFunction;
  gmbh: GmbHDetails;
  handleEditField?: (field: string) => (() => void) | undefined;
}

export interface BrokerageAccount {
  id: string;
  accountNumber: string | null;
  brokerBank: BrokerBankEnum;
  brokerName: BrokerNameEnum | null;
  tokenUpdatedAt: any;
  queryId: string | null;
  validity: BrokerageAccountValidityEnum | null;
  errorCodes: any | null;
  isTokenExist: boolean | null;
}

export interface BankAccount {
  id: string;
  bankProvider: BankType;
  plan?: BankAccountPlan | null;
  iban?: string | null;
  name?: string | null;
}

export interface Company {
  id: string;
  name: string;
  legalForm?: LegalForm;
  registerCourt?: string | null;
  registration?: string | null;
  lei?: string | null;
  leiOrderSource?: string | null;
  leiIssueStatus?: string | null;
  notarizationDate?: any | string | null;
  applicationForEntryDate?: any | string;
  commercialRegisterEntryDate?: any | string;
  economicId?: string | null;
  taxNumber?: string | null;
  vatNumber?: string | null;
  clientNumber?: string | null;
  thirdPartyTaxAdvisorClientNumber?: string | null;
  fiscalYear?: string | null;
  asUpgradeRequiredFrom?: any | string;
  customerSignedAdminServiceContract?: boolean | null;
  finApiAccount?: {
    id: string;
    finApiBankConnections:
      | {
          id: string;
        }[]
      | null;
  } | null;
  businessPurpose?: string | null;
  businessPurposeShort?: string | null;
  externalStorageNodes?: string | null;
  locked?: boolean | null;
  businessAddress?: {
    street?: string;
    streetLine2?: string | null;
    city?: string;
    postalCode?: string;
  };
  group?: {
    memberships?: any;
  };
  companyCreation?: {
    id?: string;
    flowType?: FlowTypeEnum | null;
    currentStep?: CompanyCreationStep | null;
    processStates?: any | null;
  } | null;
  adminService?: {
    id?: string;
    flowType?: FlowTypeEnum | null;
    currentStep?: CompanyCreationStep | null;
    isArchived?: boolean | null;
    canceledAt?: any | null;
    orderedAt?: any | null;
    tier?: string | null;
    invoicedBy?: InvoiceSource;
  } | null;
  wepaService?: {
    id?: string;
    canceledAt?: any;
  } | null;
  brokerageSurvey?: {
    id?: string;
    answer?: string;
    reason?: string | null;
    otherReason?: string | null;
    selectedBrokerBank?: string | null;
    otherBrokerBank?: string | null;
    updatedAt?: any | null;
  } | null;
  wepaId?: number | null;
  myTaxService?: {
    id?: string;
    type?: TaxServiceTypeEnum | null;
    taxAdvisorStatus?: TaxAdvisorStatusEnum | null;
    advisor?: Company | null;
  } | null;
  brokerageAccounts?: BrokerageAccount[] | null;
  bankAccounts?: BankAccount[] | null;
  accountingFramework?: AccountingFrameworkEnum | null;
  orderStatus?: CompanyOrderStatus | null;
  companyFoundationOrderId?: string | null;
  orders?: CompanyById_companyById_orders[] | NomineeCompanyDetails_companyById_orders[] | null;
  migrations?: CompanyById_companyById_migrations[];
}

export interface Person {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  user?: {
    id: string;
    email: string;
  } | null;
}

export type RideHeaderColumn = {
  name: string;
  label: string;
  alignment?: RideTableCellAlignment;
  component?: (date: any) => React.ReactElement;
};

export enum RideSize {
  EXTRA_SMALL = "EXTRA_SMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE"
}

export enum RideStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  LOCKED = "LOCKED",
  MISSING_INFORMATION = "MISSING_INFORMATION" //check if it will be a status or not
}

export enum AssetTypes {
  STOCKS = "stocks",
  OPTIONS = "options",
  BONDS = "bonds",
  FUTURES = "futures",
  FOREX = "forex",
  CFD = "cfd",
  MARGIN = "margin"
}
