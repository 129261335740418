import React from "react";
import { TFunction } from "i18next";
import { FormikProps } from "formik";
import { Col, Row } from "react-bootstrap";
import {
  AddressCareOfField,
  CityField,
  CountryField,
  PostcodeField,
  StreetAndNumberField
} from "../../fields";

interface AddressFieldsProps {
  t: TFunction;
  formik: FormikProps<any>;
}

export const AddressFields = ({ t, formik }: AddressFieldsProps) => {
  return (
    <div>
      <Row className="mt-2">
        <Col xs={"12"} md={"6"}>
          <AddressCareOfField t={t} formik={formik} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={"12"} md={"6"}>
          <StreetAndNumberField t={t} formik={formik} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="col-auto">
          <PostcodeField t={t} formik={formik} />
        </Col>
        <Col className="col-auto">
          <CityField t={t} formik={formik} />
        </Col>
      </Row>
      <Row>
        <Col xs={"12"} md={"6"}>
          <CountryField t={t} formik={formik} />
        </Col>
      </Row>
    </div>
  );
};
