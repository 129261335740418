import React from "react";
import { Redirect } from "react-router";
import { GET_DEFAULT_COMPANY } from "lib/api/queries/GetDefaultCompany";
import { useQuery } from "react-apollo";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

const ClientHomePageContainer = () => {
  const defaultCompany = useQuery(GET_DEFAULT_COMPANY);

  let companyId = defaultCompany.data?.getDefaultCompany?.id;
  let pathname = companyId ? `/client/entities/${companyId}/home` : "/client/entities";

  return (
    <RequestFeedbackHandler requests={[defaultCompany]}>
      <Redirect
        to={{
          pathname
        }}
      />
    </RequestFeedbackHandler>
  );
};

export default ClientHomePageContainer;
