import { NomineeCompanyDetails_companyById } from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { RideButtonDeprecated } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";

interface StripeIntegrationSectionProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
  createCompanyInStripe: (values: { id: string }) => void;
}

const StripeIntegrationSection = ({
  company,
  createCompanyInStripe,
  t
}: StripeIntegrationSectionProps) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  return (
    <>
      <Row data-testid="stripe-integration-section">
        <Col>
          <h2>{t("nominee-dashboard:company.stripe-integration")}</h2>
          <p>{t("nominee-dashboard:company.stripe-integration-description")}</p>

          <RideButtonDeprecated
            onClick={() => {
              createCompanyInStripe({ id: company.id });
              setIsButtonClicked(true);
            }}
            disabled={isButtonClicked}
            data-testid="ride-button-stripe-create-company">
            {`${t("nominee-dashboard:company.stripe-integration-create-company")}`}
          </RideButtonDeprecated>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          {isButtonClicked ? (
            <TextLink
              to={`https://dashboard.stripe.com/test/search?query=${encodeURI(company.name)}`}
              target={"_blank"}
              variant={LinkVariant.primary}
              data-testid="ride-link-stripe">
              {t("nominee-dashboard:company.stripe-integration-link")}
            </TextLink>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default withTranslationReady(["generic"])(StripeIntegrationSection);
