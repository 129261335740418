import React from "react";

export class LocationHelper {
  redirect(url) {
    window.location = url;
  }
}

export interface LocationHelperProps {
  locationHelper: LocationHelper;
}

const locationHelper = new LocationHelper();

export const withLocationHelper = (Component) => (props) =>
  <Component {...props} locationHelper={locationHelper} />;
