import { Step } from "lib/models/client/order/Step";

export class OrderExtraService {
  static async normalizeShareholdersData(orderExtra: any, currentStep: Step) {
    if (orderExtra.shareholders) {
      Object.keys(orderExtra.shareholders).forEach((shareholderIndex, index) => {
        if (shareholderIndex !== index.toString()) {
          orderExtra.shareholders[index] = orderExtra.shareholders[shareholderIndex];
          delete orderExtra.shareholders[shareholderIndex];

          const subChapter = orderExtra.chapters["shareholder-details"].subChapters.find(
            (subChapter) => subChapter.index.toString() === shareholderIndex.toString()
          );

          subChapter.index = index;
        }
      });
    }

    currentStep.chapter.subChapters = [];
    for (let subchapter of orderExtra.chapters["shareholder-details"].subChapters) {
      currentStep.chapter.addSubChapter(subchapter);
    }

    return orderExtra;
  }

  static async normalizeManagingDirectorsData(orderExtra: any, currentStep: Step) {
    if (orderExtra.employees) {
      Object.keys(orderExtra.employees).forEach((employeesIndex, index) => {
        if (employeesIndex !== index.toString()) {
          orderExtra.employees[index] = orderExtra.employees[employeesIndex];
          delete orderExtra.employees[employeesIndex];

          const subChapter = orderExtra.chapters["managing-directors"].subChapters.find(
            (subChapter) => subChapter.index.toString() === employeesIndex.toString()
          );

          subChapter.index = index;
        }
      });
    }

    if (orderExtra.chapters["managing-directors"].subChapters) {
      currentStep.chapter.subChapters = [];
      for (let subchapter of orderExtra.chapters["managing-directors"].subChapters) {
        currentStep.chapter.addSubChapter(subchapter);
      }
    }

    return orderExtra;
  }
}
