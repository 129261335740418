import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { getUserCompanyTypes, getUserCompanyTypes___type } from "./graphql/getUserCompanyTypes";

export const GET_USER_COMPANY_TYPES = gql`
  query getUserCompanyTypes {
    __type(name: "CompanyType") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const GetCompanyTypes = () => {
  const { data: availableCompanyTypes, error: errorLoadingTtypes } = useQuery<
    getUserCompanyTypes,
    getUserCompanyTypes___type
  >(GET_USER_COMPANY_TYPES);

  return { availableCompanyTypes, errorLoadingTtypes };
};
