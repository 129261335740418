import React from "react";

export interface RideLoadingProps {
  "data-testid": string;
  className?: string;
}

const RideLoading = ({ "data-testid": testid, className }: RideLoadingProps) => {
  return <div className={`ride-loading ${className ?? ""}`} data-testid={testid} />;
};

export default RideLoading;
