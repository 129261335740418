import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";
import React from "react";

export const EuroIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="euro-icon"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M12.75 19.5C10.0378 19.5 8.0004 17.777 7.1658 15H14.25V13.5H6.84908C6.78325 13.0027 6.75016 12.5016 6.75 12C6.75 11.7525 6.7575 11.5019 6.76942 11.25H14.25V9.75H6.92782C7.38352 7.01437 8.8575 4.5 12.75 4.5C15.64 4.5 16.8986 5.73487 18.0961 7.86697L19.4039 7.13303C18.1987 4.9863 16.5584 3 12.75 3C7.9836 3 5.25 6.2805 5.25 12C5.25 17.299 8.33407 21 12.75 21C16.5584 21 18.1987 19.0137 19.4039 16.867L18.0961 16.133C16.8986 18.2651 15.64 19.5 12.75 19.5Z"
      fill="currentColor"
    />
  </svg>
);
