import React from "react";

export const CloseIcon = ({ size = 35 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7096 9.33268L25.668 7.29102L17.5013 15.4577L9.33464 7.29102L7.29297 9.33268L15.4596 17.4993L7.29297 25.666L9.33464 27.7077L17.5013 19.541L25.668 27.7077L27.7096 25.666L19.543 17.4993L27.7096 9.33268Z"
      fill="currentColor"
    />
  </svg>
);
