import React from "react";
import { TFunction } from "i18next";
import { Button } from "react-bootstrap";

interface ShareHolderTypeSelectorProps {
  t: TFunction;
  onChange: (type: ShareHolderType) => void;
  value: ShareHolderType;
  className?: string;
  disabled?: boolean;
}

export enum ShareHolderType {
  PERSONAL = "PERSONAL",
  HOLDING = "HOLDING"
}

export const ShareHolderTypeSelector = ({
  t,
  value,
  onChange,
  className = "",
  disabled
}: ShareHolderTypeSelectorProps) => {
  const personalVariant = value === ShareHolderType.PERSONAL ? "success" : "outline-dark";
  const holdingVariant = value === ShareHolderType.HOLDING ? "success" : "outline-dark";

  return (
    <div className={className}>
      <Button
        onClick={() => {
          onChange(ShareHolderType.PERSONAL);
        }}
        className="border-dark rounded-pill-left shadow-sm font-weight-bold"
        type="button"
        variant={personalVariant}
        data-testid="btn-personal"
        disabled={disabled}>
        {t("trader-gmbh:shareholder-type:personal")}
      </Button>
      <Button
        onClick={() => {
          onChange(ShareHolderType.HOLDING);
        }}
        className="border-dark rounded-pill-right shadow-sm font-weight-bold"
        type="button"
        variant={holdingVariant}
        data-testid="btn-holding"
        disabled={disabled}>
        {t("trader-gmbh:shareholder-type:via-holding")}
      </Button>
    </div>
  );
};
