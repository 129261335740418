import React from "react";

export const DragDropSplashScreenPopUpIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="28.5" fill="white" stroke="white" strokeWidth="3" />
    <path
      d="M29.5 35C29.5 35 34 32.4115 34 28.5V23.9558L29.5 22L25 23.9558V28.5C25 32.354 29.5 35 29.5 35Z"
      fill="#48FFAF"
      stroke="#303436"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.761 41H40.1161C45.0349 41 49 36.9454 49 31.9155C49 26.8857 45.0349 22.8311 40.1161 22.8311H37.9077C35.9503 15.1324 28.2208 10.4618 20.6419 12.4635C13.063 14.4652 8.49561 22.3692 10.4531 30.1192C11.758 35.303 15.7232 39.255 20.6419 40.5381"
      stroke="#303436"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.1375 42.3274L29.5429 37.7373L25 42.3274"
      stroke="#303436"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5439 37.7373V48.0005"
      stroke="#303436"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.1375 42.3274L29.5429 37.7373L25 42.3274"
      stroke="#303436"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
