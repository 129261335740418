import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Container } from "react-bootstrap";
import ReturnLinkWithChevron from "../../ReturnLinkWithChevron/ReturnLinkWithChevron";

interface GuestLayoutProps extends PropsWithChildren<WithTranslation> {
  title?: string;
  onBack?: () => void;
}

const ModalFormLayout = ({ children, title, onBack }: GuestLayoutProps) => {
  return (
    <div className="modal-form-layout">
      {onBack && (
        <Container className="modal-form-layout__back d-md-none">
          <ReturnLinkWithChevron
            onClick={onBack}
            backgroundVariant="light"
            data-testid="action-page-back-button"
          />
        </Container>
      )}

      <Container
        className="modal-form-layout__container"
        data-testid="modal-form-layout__container">
        <div className="modal-form-layout__back d-none d-md-block">
          <div>
            {onBack && (
              <ReturnLinkWithChevron
                onClick={onBack}
                backgroundVariant="light"
                data-testid="action-page-back-button"
              />
            )}
          </div>
        </div>
        <h1 data-testid="modal-form-layout-title" className="modal-form-layout__title">
          {title}
        </h1>
        <div className="modal-form-layout__children">{children}</div>
      </Container>
    </div>
  );
};

export default withTranslationReady(["generic"])(ModalFormLayout);
