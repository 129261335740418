import React, { FunctionComponent } from "react";
import { useFormik } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";
import { EditEmailSchema } from "../../../lib/validation/EditEmailSchema";
import TextInput from "../TextInput";
import { Button } from "react-bootstrap";
import SuccessAlert from "../SuccessAlert";
import LoadingAlert from "../LoadingAlert";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";

interface EmailUpdateFormModelProps extends WithTranslation {
  email: string | undefined;
  cancelForm: () => void;
  submitForm: (finalForm) => void | Promise<void>;
  success: boolean;
  loading: boolean;
  error: string;
}

export const EmailUpdateFormModel: FunctionComponent<EmailUpdateFormModelProps> = (props) => {
  const { t, submitForm, success, loading, error, email } = props;
  const formik = useFormik({
    initialValues: {
      email: email || ""
    },
    validateOnChange: true,
    validationSchema: EditEmailSchema,
    onSubmit: (values: any) => {
      submitForm(values);
    }
  });

  return (
    <>
      <SuccessAlert t={t} success={success} message={t("generic:success_heading")} />
      <LoadingAlert t={t} loading={loading} />
      <ErrorAlertLegacy t={t} error={error} />
      <form noValidate onSubmit={formik.handleSubmit}>
        <TextInput
          label={"generic:email"}
          name="email"
          t={t}
          type="text"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
          isValid={formik.touched.email && !formik.errors.email}
          isInvalid={formik.errors.email != null}
        />

        <Button
          className="float-sm-right"
          type="submit"
          disabled={!formik.isValid}
          data-testid="submit">
          {t("personal-info:edit-save-button")}
        </Button>
      </form>
    </>
  );
};

export default withTranslation(["personal-info", "generic", "errorCodes"])(EmailUpdateFormModel);
