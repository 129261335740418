import { StorageNodeType } from "../../global-query-types";
import { UploadNodeState } from "../../sharedComponents/UploadWrapper/UploadWrapper";

export interface RemoteStorageNode {
  nodeId: string;
  name: string | null;
  type: StorageNodeType | null;
  childNodes: RemoteStorageNode[];
  parentId: string;
  uploadingState?: UploadNodeState;
}

export interface LocalStorageNode {
  __typename: "StorageNode";
  nodeId: string | null;
  name: string | null;
  type: StorageNodeType | null;
  childNodes?: LocalStorageNode[] | null;
}

export const toRemoteStorageNode = (
  node: LocalStorageNode,
  parentId: string
): RemoteStorageNode => {
  const nodeId = node.nodeId ?? "";
  const childNodes = node.childNodes?.map((a) => toRemoteStorageNode(a, nodeId)) ?? [];

  return {
    nodeId,
    childNodes,
    name: node.name ?? null,
    type: node.type ?? null,
    parentId
  };
};
