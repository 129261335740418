import gql from "graphql-tag";
import { OrderCompanyStatusEnum } from "../../../global-query-types";
import { useQuery } from "react-apollo";
import { getWepaDirectOrders } from "./graphql/getWepaDirectOrders";

export const GET_WEPA_DIRECT_ORDERS = gql`
  query getWepaDirectOrders(
    $start: Int
    $limit: Int
    $status: OrderCompanyStatusEnum
    $query: String
  ) {
    getWepaDirectOrders(start: $start, limit: $limit, status: $status, query: $query) {
      items {
        order {
          id
          productType
          entityId
          extra
          status
          createdAt
          updatedAt
        }
        company {
          id
          name
          legalForm
        }
      }
      count
    }
  }
`;

export const GetWepaDirectOrders = ({
  start,
  limit,
  status,
  query
}: {
  start?: number;
  limit?: number;
  status?: OrderCompanyStatusEnum;
  query?: string;
}) =>
  useQuery<getWepaDirectOrders>(GET_WEPA_DIRECT_ORDERS, {
    fetchPolicy: "no-cache",
    variables: { start, limit, status, query }
  });
