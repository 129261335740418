import React, { PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";
import {
  RideModalDeprecated,
  RideModalProps
} from "sharedComponents/RideModalDeprecated/RideModalDeprecated";
import { ButtonVariant } from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";

export interface NewConfirmationModalProps
  extends Pick<RideModalProps, "children" | "title" | "dialogBody" | "size">,
    WithTranslation {
  onConfirm: () => Promise<void>;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmButtonVariant?: ButtonVariant;
}

export const NewConfirmationModal = ({
  title,
  dialogBody,
  onConfirm,
  cancelLabel,
  confirmLabel,
  confirmButtonVariant = ButtonVariant.Danger,
  size,
  children,
  t
}: NewConfirmationModalProps) => {
  const buttons = [
    {
      children: cancelLabel ?? t("generic:cancel"),
      variant: ButtonVariant.Secondary,
      "data-testid": "confirmation-modal-cancel-button"
    },
    {
      children: confirmLabel ?? t("generic:confirm"),
      variant: confirmButtonVariant,
      "data-testid": "confirmation-modal-confirm-button",
      onClick: onConfirm
    }
  ];

  return (
    <RideModalDeprecated size={size} title={title} buttons={buttons} dialogBody={dialogBody}>
      {(setVisibility) => children?.(setVisibility)}
    </RideModalDeprecated>
  );
};

export default withTranslationReady(["generic"])(NewConfirmationModal);

export interface ConfirmationModalProps extends PropsWithChildren<{}> {
  title: string;
  show: boolean;
  onHide?: () => void;
  buttons: React.ReactNode[];
  showCloseButton?: boolean;
  dataTestId?: string;
  dialogClassName?: string;
}

export const ConfirmationModal = ({
  title,
  children,
  onHide,
  show,
  buttons,
  showCloseButton = true,
  dataTestId = "confirmation-modal",
  dialogClassName,
  ...rest
}: ConfirmationModalProps) => {
  return (
    <Modal
      data-testid={dataTestId}
      show={show}
      onHide={onHide}
      dialogClassName={`${dialogClassName || ""}`}
      className="confirmation-modal"
      {...rest}>
      <Modal.Header
        className="confirmation-modal__header"
        data-testid="confirmation-modal-header"
        closeButton={showCloseButton}>
        <Modal.Title
          className={`confirmation-modal__title ${
            showCloseButton ? "confirmation-modal__title-with-close" : ""
          }`}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirmation-modal__body">
        {children}
        <div className="confirmation-modal__modal-buttons">
          {buttons.map((button, index) => (
            <div key={index} className="confirmation-modal__button">
              {button}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
