import * as Yup from "yup";

export const CompanyCreationSharesSchema = () => {
  return Yup.object().shape({
    shares: Yup.array().of(
      Yup.object().shape({
        share: Yup.number()
          .min(1, "trader-gmbh:percentage-shares-min-1")
          .max(100, "trader-gmbh:percentage-shares-max-100")
          .required("generic:validation-required")
      })
    )
  });
};
