import React from "react";
import RequestFeedbackHandler from "../../../generic/RequestFeedbackHandler";
import KYCStatusSection from "./KYCStatusSection";
import { useMutation } from "react-apollo";
import { NOMINEE_UPDATE_KYC_ENTRY } from "../Mutations";
import { FullPersonDetailsQuery_getPersonDetails_kycEntries } from "../../NomineePerson/graphql/FullPersonDetailsQuery";
import { NomineeCompanyDetails_companyById_kycEntries } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const KYCStatusSectionContainer = ({
  kycEntry,
  refetch
}: {
  kycEntry:
    | NomineeCompanyDetails_companyById_kycEntries
    | FullPersonDetailsQuery_getPersonDetails_kycEntries
    | undefined;
  refetch: () => Promise<any>;
}) => {
  const [updateNomineeKycEntry, updateNomineeKycEntryRequest] =
    useMutation(NOMINEE_UPDATE_KYC_ENTRY);

  const updateKycEntry = async (status, incompleteReason: string) => {
    await updateNomineeKycEntry({
      variables: {
        id: kycEntry?.id,
        status,
        incompleteReason
      }
    });
  };

  return (
    <RequestFeedbackHandler requests={[updateNomineeKycEntryRequest]}>
      <KYCStatusSection refetch={refetch} kycEntry={kycEntry} updateKycEntry={updateKycEntry} />
    </RequestFeedbackHandler>
  );
};
