import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  GetFiscalYearsByCompanyId,
  GetFiscalYearsByCompanyIdVariables
} from "./graphql/GetFiscalYearsByCompanyId";

const GET_FISCAL_YEARS_BY_COMPANY_ID = gql`
  query GetFiscalYearsByCompanyId($companyId: ID!) {
    getFiscalYearsByCompanyId(companyId: $companyId) {
      id
      year
      dataUploadCompleted
      company {
        id
        fiscalYear
      }
    }
  }
`;

export const GetFiscalYearsByCompanyIdQuery = (companyId: string) =>
  useQuery<GetFiscalYearsByCompanyId, GetFiscalYearsByCompanyIdVariables>(
    GET_FISCAL_YEARS_BY_COMPANY_ID,
    { variables: { companyId } }
  );
