import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { PendingTaskSwitcherProps } from "../PendingTaskSwitcher";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  ButtonIntensity,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { DownloadIcon } from "sharedComponents/icons/DownloadIcon";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import NavigationService from "lib/services/NavigationService/NavigationService";
import { FileUploadArea } from "../../../../ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/FileUploadArea/FileUploadArea";
import { FileTooLargeError, InvalidFileTypeError } from "lib/adapters/RideFileStorage/FileStorage";
import { FileType } from "lib/ports/FileStorage";
import { CheckIcon } from "sharedComponents/icons/DesignSystemIcons/CheckIcon";

const DownloadFillAndUploadPendingTask = ({
  t,
  task,
  completeTask,
  handleSubmitFile,
  isTaskCompleted,
  fileUploadConstraints
}: PendingTaskSwitcherProps & WithTranslation) => {
  const [file, setFile] = useState<File | undefined>();

  const { maxFileSizeInBytes = 0, allowedFileTypes = [] } = fileUploadConstraints ?? {};

  const uploadFile = (file: File) => {
    if (file.size > maxFileSizeInBytes) {
      throw new FileTooLargeError(maxFileSizeInBytes, file.size);
    }

    if (!allowedFileTypes.includes(file.type as FileType)) {
      throw new InvalidFileTypeError(allowedFileTypes, file.type as FileType);
    }

    setFile(file);
  };

  const submitFile = async () => {
    if (file) {
      await handleSubmitFile?.({ file, formNumber: task?.formNumber ?? "" });
      await completeTask();
    }
  };

  const downloadDocument = () => {
    NavigationService.instance?.openLink(`${getEndpoint()}/api/ibkr-form/${task.formNumber}`);
  };

  return (
    <CardContainer className="v2-card-container p-4">
      <div
        className="download-fill-and-upload-pending-task__download-section"
        data-testid={"download-fill-upload"}>
        <Typography
          data-testid={"download-section-title"}
          category={"Paragraph"}
          size={200}
          weight={"Heavy"}>
          {t("generic:download-filename", { filename: task.formName })}
        </Typography>
        <Typography
          data-testid={"download-section-description"}
          category={"Paragraph"}
          size={200}
          weight={"Light"}>
          {t("generic:download-fill-upload-description")}
        </Typography>
        <RideButtonDeprecated
          data-testid={"download-document-button"}
          className="legal-documents-confirmation-item__download-button"
          intensity={ButtonIntensity.Soft}
          onClick={downloadDocument}>
          {t("generic:download")} <DownloadIcon />
        </RideButtonDeprecated>
      </div>
      <hr />
      {isTaskCompleted ? (
        <div className="download-and-agree-pending-task__already-accepted">
          <CheckIcon className="download-and-agree-pending-task__check-icon" />
          <Typography
            category={"Paragraph"}
            size={100}
            weight={"Light"}
            data-testid={"document-submitted"}>
            {t("generic:document-submitted", {
              documentName: task.formName
            })}
          </Typography>
        </div>
      ) : (
        <div className="download-fill-and-upload-pending-task__upload-section">
          <Typography
            className="download-fill-and-upload-pending-task__upload-section-title"
            data-testid={"upload-section-title"}
            category={"Paragraph"}
            size={200}
            weight={"Heavy"}>
            {t("generic:upload-filled-filename", { filename: task.formName })}
          </Typography>
          <FileUploadArea
            uploadFile={async (file) => uploadFile(file)}
            browserFileInsideDraggableArea={true}
          />
          <button
            data-testid="submit-document"
            className="ltb-overview__primary-action-btn download-fill-and-upload-pending-task__submit"
            type="button"
            onClick={submitFile}
            disabled={!file}>
            {t("generic:submit-document")}
          </button>
        </div>
      )}
    </CardContainer>
  );
};

export default withTranslationReady(["generic"])(DownloadFillAndUploadPendingTask);
