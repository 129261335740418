import React from "react";
import { TaskStatesEnum } from "../../../../../global-query-types";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import {
  ButtonRadius,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { rideBrokerT, withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { DescriptionWithEmails } from "../../../../../sharedComponents/DescriptionWithEmails/DescriptionWithEmails";

interface LTBActionableTaskProps {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const LTBActionableTask = ({ t, task, onChange }: LTBActionableTaskProps & WithTranslation) => {
  const moduleName = "LTB-actionable-task";
  const history = useHistory();
  return (
    <Container className="rounded-sm task-container">
      <div className="task-container__header">
        <h1 className="task-container__title">
          {t(`company-founding-steps:tasks.name.brokerDepotCreated`)}
        </h1>
      </div>

      <div className="task-container__description">
        <DescriptionWithEmails
          content={t(`company-founding-steps:tasks.description.brokerDepotCreatedLTBUpsell`)}
        />
      </div>

      <div className={`${moduleName}__buttons`}>
        <RideButtonDeprecated
          variant={ButtonVariant.Grey}
          onClick={async () => {
            await onChange(TaskStatesEnum.COMPLETED, undefined);
          }}
          data-testid={"skip"}
          radius={ButtonRadius.Medium}>
          {t(`company-founding-steps:tasks.done-btn.skip-ltb`)}
        </RideButtonDeprecated>
        <RideButtonDeprecated
          variant={ButtonVariant.PrimaryPastel}
          onClick={async () => {
            history.push("/client/low-tax-broker");
          }}
          data-testid={"open-ltb"}
          radius={ButtonRadius.Medium}>
          {t(rideBrokerT(`company-founding-steps:tasks.done-btn.open-ltb`))}
        </RideButtonDeprecated>
      </div>
    </Container>
  );
};
export default withTranslationReady(["generic"])(LTBActionableTask);
