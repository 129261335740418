import { withTranslationReady } from "common/i18n/withTranslationReady";
import { FeatureFlags } from "global-query-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { RideImmoIframe } from "./RealEstateIframe";

const moduleName = "real-estate-section";
const realEstateAppBaseUrl = process.env.REACT_APP_REAL_ESTATE_APP_URL as string;

interface Props extends WithTranslation {}

export const RealEstateTab = withTranslationReady(["generic"])(({ t }: Props) => {
  function openValuationFlow() {
    window.open(realEstateAppBaseUrl + "/app/projects/new", "_blank");
  }

  function onClickLearnMoreOnTaxes() {
    window.open("https://www.ride.capital/gmbh/gruenden/immobilien", "_blank");
  }

  function onClickLearnMoreOnAdmin() {
    window.open("https://www.ride.capital/immobilien/steuerlich-verwalten", "_blank");
  }

  return (
    <>
      <RideImmoIframe />
      <div className={`${moduleName} my-5 container`} data-testid={"real-estate-section"}>
        <Row className="my-4">
          <Col>
            <CardContainer
              className={`${moduleName}__real-estate-highlight d-flex flex-column p-4`}>
              <Typography category="Paragraph" size={100} weight="Heavy" className="mb-3">
                {t("generic:real-estate-section:valuation-value-proposition:title")}
              </Typography>
              <Typography category="Paragraph" size={200} weight="Light">
                {t("generic:real-estate-section:valuation-value-proposition:explanation")}
              </Typography>
              <Row className="mt-4">
                <Col className="d-flex justify-content-end align-items-center">
                  <TextLink
                    variant={LinkVariant.primary}
                    to={"#"}
                    target={"_blank"}
                    rel={"noopener noreferrer"}>
                    {t("generic:real-estate-section:valuation-value-proposition:button")}
                  </TextLink>
                  <FeatureFlag name={FeatureFlags.RE52RealEstateAppAccess}>
                    <RideButton
                      className="ml-3"
                      label={t("generic:real-estate-section:get-valuation-button")}
                      variant={RideButtonVariant.PRIMARY}
                      size={RideButtonSize.SMALL}
                      onClick={openValuationFlow}
                    />
                  </FeatureFlag>
                </Col>
              </Row>
            </CardContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardContainer
              className={`${moduleName}__real-estate-card d-flex flex-column justify-content-between p-4`}>
              <Row className="px-3">
                <Typography category="Paragraph" size={100} weight="Heavy" className="mb-3">
                  {t("generic:real-estate-section:taxes-value-proposition:title")}
                </Typography>
                <Typography category="Paragraph" size={200} weight="Light">
                  {t("generic:real-estate-section:taxes-value-proposition:explanation")}
                </Typography>
              </Row>
              <Row className="px-3">
                <Col className="d-flex justify-content-end">
                  <RideButton
                    label={t("generic:real-estate-section:taxes-value-proposition:button")}
                    variant={RideButtonVariant.PRIMARY}
                    size={RideButtonSize.SMALL}
                    onClick={onClickLearnMoreOnTaxes}
                  />
                </Col>
              </Row>
            </CardContainer>
          </Col>
          <Col>
            <CardContainer
              className={`${moduleName}__real-estate-card d-flex flex-column justify-content-between p-4`}>
              <Row className="px-3">
                <Typography category="Paragraph" size={100} weight="Heavy" className="mb-3">
                  {t("generic:real-estate-section:admin-value-proposition:title")}
                </Typography>
                <Typography category="Paragraph" size={200} weight="Light">
                  {t("generic:real-estate-section:admin-value-proposition:explanation")}
                </Typography>
              </Row>
              <Row className="px-3">
                <Col className="d-flex justify-content-end">
                  <RideButton
                    label={t("generic:real-estate-section:admin-value-proposition:button")}
                    variant={RideButtonVariant.PRIMARY}
                    size={RideButtonSize.SMALL}
                    onClick={onClickLearnMoreOnAdmin}
                  />
                </Col>
              </Row>
            </CardContainer>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <div className="d-flex flex-column">
              <Typography category="Paragraph" size={200} weight="Heavy">
                {t("generic:real-estate-section:user-research:title")}
              </Typography>
              <Typography category="Paragraph" size={200} weight="Light">
                {t("generic:real-estate-section:user-research:description")}
              </Typography>
            </div>
            <div className="d-flex flex-column mt-4">
              <Typography category="Paragraph" size={200} weight="Heavy">
                {t("generic:real-estate-section:user-research:invite-title")}
              </Typography>
              <Typography category="Paragraph" size={200} weight="Light">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("generic:real-estate-section:user-research:invite-text")
                  }}></span>
              </Typography>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
});
