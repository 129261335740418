import React from "react";
import RequestFeedbackHandler from "../../generic/RequestFeedbackHandler";
import KnowledgeDocumentsSection from "./sections/KnowledgeDocumentsSection";
import { GetClientCommitted, GetKnowledgeDocuments } from "./Queries";

export const KnowledgeDocumentsContainer = ({ t }) => {
  const knowledgeDocumentsRequest = GetKnowledgeDocuments();
  const clientCommittedRequest = GetClientCommitted();

  const knowledgeDocuments = knowledgeDocumentsRequest.data?.getKnowledgeDocuments ?? [];
  const clientCommitted = clientCommittedRequest.data?.clientCommitted?.committed ?? false;

  return (
    <RequestFeedbackHandler requests={[knowledgeDocumentsRequest, clientCommittedRequest]}>
      <KnowledgeDocumentsSection
        knowledgeDocumentsData={knowledgeDocuments}
        clientCommitted={clientCommitted}
      />
    </RequestFeedbackHandler>
  );
};
