import React from "react";
import { formatCurrency } from "common/formatters";
import { ValueView } from "components/common/layout/ValueView";
import { TFunction } from "i18next";
import {
  AllowedDiscountCodeService,
  AllowedServiceExpansionEnum
} from "../ReferralCodeForm/ReferralCodeForm";
import { Col, Row } from "react-bootstrap";
import { forOwn } from "lodash";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";

const ReferralCodeFormViewMode = ({
  t,
  code,
  referralValue,
  referrerValue,
  allowedReferralCodeServices
}) => {
  return (
    <div>
      <ReferralCodeSection
        t={t}
        code={code}
        allowedReferralCodeServices={allowedReferralCodeServices}
      />
      <ValueView
        t={t}
        testId="referrer-value"
        name="nominee-dashboard:referral-code.referrer-amount"
        value={formatCurrency(referrerValue, { fixed: 0 })}
      />
      <ValueView
        t={t}
        testId="referral-value"
        name="nominee-dashboard:referral-code.referral-amount"
        value={formatCurrency(referralValue, { fixed: 0 })}
      />
    </div>
  );
};

const ReferralCodeSection = ({
  t,
  code,
  allowedReferralCodeServices
}: {
  t: TFunction;
  code: string;
  allowedReferralCodeServices: AllowedDiscountCodeService;
}) => {
  return (
    <div>
      <Row className="mb-2">
        <Col md={3}>
          <ValueView
            t={t}
            testId="referral-code"
            name="nominee-dashboard:referral-code.code"
            value={code}
          />
        </Col>

        <Col md={9}>
          <ValueView
            t={t}
            testId="referral-code-allowed-service"
            name="nominee-dashboard:referral-code.allowed-services"
            value={
              <AllowedServiceStatus
                t={t}
                allowedReferralCodeServices={allowedReferralCodeServices}
              />
            }
          />
        </Col>
      </Row>
    </div>
  );
};

const AllowedServiceStatus = ({
  t,
  allowedReferralCodeServices
}: {
  t: TFunction;
  allowedReferralCodeServices: AllowedDiscountCodeService;
}) => {
  let label: string;
  let type: RideBadgeColorsEnum;
  let allowedServices: JSX.Element[] = [];

  forOwn(allowedReferralCodeServices, (value, service) => {
    const isServiceAssigned: boolean = allowedReferralCodeServices[service];
    label = t(`generic:${AllowedServiceExpansionEnum[service]}`);
    type = isServiceAssigned ? RideBadgeColorsEnum.SUCCESS : RideBadgeColorsEnum.DANGER;

    allowedServices = [
      ...allowedServices,
      <span key={allowedServices.length}>
        <RideBadgeStatus
          dataTestId={`allowed-service-${service}`}
          color={type}
          variant={RideBadgeVariantsEnum.OUTLINED}
          label={label}
        />
      </span>
    ];
  });

  return <>{allowedServices}</>;
};

export default ReferralCodeFormViewMode;
