import React, { ReactNode, useEffect } from "react";
import { DragDropContextProps, useDragDrop } from "../../lib/providers/DragDropProvider";

interface DragDropHandlerProps {
  children: (props: DragDropContextProps) => ReactNode;
  onDrop?: (files: File[]) => void;
}

export const DragDropHandler = ({ children, onDrop }: DragDropHandlerProps) => {
  const { droppedFiles, clearDroppedFiles, ...props } = useDragDrop();

  useEffect(() => {
    if (droppedFiles?.length) {
      onDrop?.(Array.from(droppedFiles));
      clearDroppedFiles();
    }
    // NOTE: this is disabled so that this useEffect ever only runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedFiles]);

  return <>{children({ ...props, droppedFiles, clearDroppedFiles })}</>;
};
