import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import RideModal, { RideModalBody } from "uiLibrary/designSystem/RideModal/RideModal";
import AddGmbHOptions from "../GmbH/AddGmbHOptions/AddGmbHOptions";
import React from "react";
import { useHistory } from "react-router-dom";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import {
  FeatureFlags,
  OrderEntityType,
  OrderOwnerType,
  OrderProductTypeEnum
} from "global-query-types";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "lib/api/mutations/graphql/UpsertOrder";
import { UserService } from "lib/services/UserService/UserService";

export const AddGmbHModal = withTranslationReady(["client"])(
  ({
    t,
    onClose,
    upsertOrder
  }: {
    onClose: () => void;
    upsertOrder: (
      orderData: UpsertOrderVariables
    ) => Promise<UpsertOrder_upsertOrder | null | undefined>;
  } & WithTranslation) => {
    const history = useHistory();
    const addExistingCompanyNewForm = FeatureFlagService.instance.isEnabled(
      FeatureFlags.AddExistingCompanyNewForm
    );

    const user = UserService.instance.currentUser;

    return (
      <RideModal
        title={t("client:add-gmbh.title")}
        onClose={onClose}
        data-testid={"add-company-modal"}>
        <RideModalBody>
          <AddGmbHOptions
            onAddNew={() => {
              onClose();
              history.push(`/client/my-gmbhs/add/new`);
            }}
            onAddExisting={async () => {
              onClose();
              if (addExistingCompanyNewForm) {
                const order = await upsertOrder({
                  productType: OrderProductTypeEnum.AddExistingCompany,
                  ownerType: OrderOwnerType.Person,
                  ownerId: user?.person?.id,
                  entityType: OrderEntityType.Company
                });

                history.push(
                  `/client/order/generic/company-information/0/${order?.id}/${order?.submissionId}`
                );
              } else {
                history.push(`/client/my-gmbhs/add/existing`);
              }
            }}
          />
        </RideModalBody>
      </RideModal>
    );
  }
);
