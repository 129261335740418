import React, { useState } from "react";
import {
  ActionableTask,
  ActionableTaskOptions,
  ProcessStates
} from "common/types/ProcessStatesTypes";
import { TaskStatesEnum } from "global-query-types";
import { getCompanyCreationSteps_getCompanyCreationSteps } from "lib/api/queries/graphql/getCompanyCreationSteps";
import ProcessStatesTable from "../ProcessStatesTable/ProcessStatesTable";
import { ProcessStatesPreview } from "./ProcessStatesSection.partials";

interface ProcessStepsProps {
  companyCreationId: string;
  processStepsWithTasks: getCompanyCreationSteps_getCompanyCreationSteps[];
  onSave: (
    processStates: ProcessStates,
    tasks: { ref: string; state: TaskStatesEnum; options: ActionableTaskOptions }[]
  ) => Promise<void>;
}

export interface EnrichedData {
  name: string;
  state: string;
  options: SelectOption[];
  tasks: ActionableTask[];
  ref: string;
}

export interface SelectOption {
  label: string;
  value: string;
}

export const TASK_INPUT_PREFIX = "task_";
export const TASK_STATUS_PREFIX = "status-task_";

export const ProcessStatesSection = ({
  processStepsWithTasks,
  onSave,
  companyCreationId
}: ProcessStepsProps) => {
  const [previewMode, setPreviewMode] = useState(false);

  const onPreview = () => {
    setPreviewMode(true);
  };

  const onExitPreview = () => {
    setPreviewMode(false);
  };

  return (
    <>
      {previewMode ? (
        <ProcessStatesPreview onExit={onExitPreview} companyCreationId={companyCreationId} />
      ) : (
        <ProcessStatesTable
          onSave={onSave}
          onPreview={onPreview}
          processStepsWithTasks={processStepsWithTasks}
          id={companyCreationId}
        />
      )}
    </>
  );
};
