import { useLocation } from "react-router";
import { useEffect } from "react";
import { DataLayer } from "../lib/services/GoogleTagManager/GoogleTagManager";

export const VirtualPageView = ({ path }: { path: string | undefined }) => {
  const { pathname } = useLocation();
  const pathToReport = path ?? pathname;

  useEffect(() => {
    DataLayer.VirtualPageView({ path: pathToReport });
  }, [pathToReport]);

  return null;
};
