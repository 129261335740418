import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "lib/api/queries/graphql/NomineeCompanyDetails";
import { AssignPartnerTaxAdvisorContainer } from "../AssignPartnerTaxAdvisor/AssignPartnerTaxAdvisorContainer";
import { AssignThirdPartyTaxAdvisorContainer } from "../AssignThirdPartyTaxAdvisor/AssignThirdPartyTaxAdvisorContainer";
import { ApolloQueryResult } from "apollo-client";
import { GetTaxServiceDetails } from "lib/api/queries/GetTaxServiceDetails";

export interface AdminServiceSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
  refetch: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

export const TaxServiceSectionContainer = ({
  company,
  refetch
}: AdminServiceSectionContainerProps) => {
  console.log("ENT-35: company.myTaxService?.advisor?.id", company.myTaxService?.advisor?.id ?? "");
  const getRequest = GetTaxServiceDetails(company.myTaxService?.advisor?.id ?? "");

  const itsRefetch = async () => {
    /*const { data } = */ await refetch();
    // const advisorId = data.companyById?.myTaxService?.advisor?.id ?? "";
    // await getRequest.refetch({ advisorId: advisorId });
  };

  const ptaCompanyId = getRequest.data?.getTaxServiceDetails?.partnerTaxAdvisorId ?? "";
  const subContractCompanyId = getRequest.data?.getTaxServiceDetails?.thirdPartyTaxAdvisorId ?? "";

  console.log("ENT-35: ptaCompanyId", ptaCompanyId);
  console.log("ENT-35: subContractCompanyId", subContractCompanyId);

  return (
    <RequestFeedbackHandler requests={[getRequest]} hideErrors>
      <AssignPartnerTaxAdvisorContainer
        companyId={company.id}
        ptaCompanyId={ptaCompanyId}
        subContractCompanyId={subContractCompanyId}
        refetch={itsRefetch}
      />
      <AssignThirdPartyTaxAdvisorContainer
        thirdPartyTaxAdvisorCompanyId={subContractCompanyId}
        companyId={company.id}
        ptaCompanyId={ptaCompanyId}
        refetch={itsRefetch}
      />
    </RequestFeedbackHandler>
  );
};
