import React from "react";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import BankAccountWebFormContainer from "../../components/Banking/BankAccountWebForm/BankAccountWebFormContainer";

const BankAccountWebFormScene = () => (
  <>
    <PageMetaData title={""} />
    <BankAccountWebFormContainer />
  </>
);

export default BankAccountWebFormScene;
