import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { IconAlert } from "../../../../components/common/icons/IconAlert";
import { IconSuccess } from "../../../../components/common/icons/IconSuccess";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export enum FinalResultType {
  SUCCESS = "SUCCESS",
  ALERT = "ALERT"
}

export interface AddGmbHResultProps extends WithTranslation {
  promptType: FinalResultType;
  resetAndCloseModal: () => void;
}

export const AddGmbHResult = ({ promptType, resetAndCloseModal, t }: AddGmbHResultProps) => {
  const handleModalClose = () => {
    resetAndCloseModal();
  };

  const { message, icon, name } =
    promptType === FinalResultType.SUCCESS
      ? {
          message: t("client:add-gmbh.prompt-success.message"),
          icon: <IconSuccess />,
          name: "success"
        }
      : {
          message: (
            <>
              <div>{t("client:add-gmbh.prompt-alert.message-1")}</div>
              <div>{t("client:add-gmbh.prompt-alert.message-2")}</div>
            </>
          ),
          icon: <IconAlert />,
          name: "alert"
        };

  return (
    <Container fluid className="ride-form rounded-sm p-55" data-testid={`${name}-modal`}>
      <Row>
        <Col className="d-flex justify-content-center" data-testid={`${name}-icon`}>
          {icon}
        </Col>
      </Row>
      <Row className="d-flex justify-content-center text-center mt-4">
        <div
          className="text-capitalize ride-form--prompt-modal-text-size"
          data-testid={`${name}-message`}>
          {message}
        </div>
      </Row>
      <Row className="d-flex justify-content-center text-center mt-5">
        <RideButtonDeprecated
          size={ButtonSize.Big}
          variant={ButtonVariant.Primary}
          data-testid={`${name}-btn`}
          onClick={handleModalClose}>
          {t("client:add-gmbh.back-to")}
        </RideButtonDeprecated>
      </Row>
    </Container>
  );
};

export default withTranslation(["client"])(AddGmbHResult);
