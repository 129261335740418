import React from "react";
import { AuthMessage } from "../shared/AuthMessage/AuthMessage";
import { ButtonVariant } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { useHistory } from "react-router-dom";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Trans, WithTranslation } from "react-i18next";
import { formatValue } from "../../../common/formatters";

interface SuccessFormProps extends WithTranslation {
  phoneNumber: string;
}

const SuccessForm = ({ t, phoneNumber }: SuccessFormProps) => {
  const history = useHistory();

  const onSubmit = async () => {
    history.replace("/");
  };

  return (
    <div className="successForm">
      <AuthMessage
        onSubmit={onSubmit}
        message={t("auth:success.title")}
        submitText={t("auth:success.button")}
        image="/assets/sharedComponents/success-icon.svg"
        buttonVariant={ButtonVariant.Secondary}
        className="successForm"
        data-testid="successForm"
        infoText={
          <div className="successForm__info">
            <Trans
              i18nKey={"auth:success.text"}
              values={{ phoneNumber: formatValue(phoneNumber) }}
            />
          </div>
        }
      />
    </div>
  );
};

export default withTranslationReady(["auth"])(SuccessForm);
