import React from "react";
import { AuthMessage } from "../AuthMessage/AuthMessage";

interface AuthSuccessMessageProps {
  onSubmit: () => Promise<void>;
  message: string;
  submitText: string;
  "data-testid"?: string;
}

export const AuthSuccessMessage = ({
  onSubmit,
  message,
  submitText,
  "data-testid": testid
}: AuthSuccessMessageProps) => {
  return (
    <AuthMessage
      message={message}
      onSubmit={onSubmit}
      submitText={submitText}
      data-testid={testid}
      image="/assets/sharedComponents/success-icon.svg"
    />
  );
};
