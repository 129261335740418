import React from "react";
import { useHistory } from "react-router-dom";
import { AddNewBankAccountContainer } from "../../components/Banking/AddNewBankAccount/AddNewBankAccountContainer";
import {
  ModalLayout,
  ModalLayoutVariant
} from "../../../sharedComponents/templates/ModalLayout/ModalLayout";

const BankAccountAddScene = () => {
  const history = useHistory();

  const handleClose = () => {
    history.replace(`/client/banking`);
  };

  const handleOnBack = () => {
    history.replace(`/client/banking/add/select-gmbh`);
  };

  return (
    <ModalLayout onClose={handleClose} onBack={handleOnBack} variant={ModalLayoutVariant.light}>
      <AddNewBankAccountContainer />
    </ModalLayout>
  );
};

export default BankAccountAddScene;
