import * as Yup from "yup";

export const NotaryLinksSchema = Yup.object().shape({
  MoneyLaunderingQuestionnaire: Yup.string()
    .required("nominee-dashboard:person.money-laundering-questionnaire-required")
    .url("generic:expected-url"),
  InstructionLetter: Yup.string()
    .required("nominee-dashboard:person.instruction-letter-required")
    .url("generic:expected-url")
});
