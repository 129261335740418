import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { StorageNodes, StorageNodesVariables } from "./graphql/StorageNodes";
import { StorageConfig } from "./graphql/StorageConfig";

export const GET_STORAGE_NODES = gql`
  query StorageNodes(
    $id: ID!
    $entityType: StorageNodesEntityType!
    $entityId: ID!
    $nested: Boolean
  ) {
    getStorageNodes(parentId: $id, entityType: $entityType, entityId: $entityId, nested: $nested) {
      nodeId
      name
      type
      childNodes {
        nodeId
        name
        type
        childNodes {
          nodeId
          name
          type
          childNodes {
            nodeId
            name
            type
            childNodes {
              nodeId
              name
              type
            }
          }
        }
      }
    }
  }
`;

export const GetStorageNodesById = (variables: StorageNodesVariables) =>
  useQuery<StorageNodes, StorageNodesVariables>(GET_STORAGE_NODES, {
    variables
  });

export const GET_STORAGE_CONFIG = gql`
  query StorageConfig {
    getStorageConfig {
      config
    }
  }
`;

export const GetStorageConfig = () => useQuery<StorageConfig>(GET_STORAGE_CONFIG);
