import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import EmailUpdateFormModel from "../../../generic/email-update-form/EmailUpdateFormModel";
import { formatValue } from "../../../../common/formatters";
import { useMutation } from "react-apollo";
import { EMAIL_UPDATE_BY_NOMINEE_WITHOUT_VERIFICATION } from "../Mutations";
import {
  emailUpdateByNomineeWithoutVerification,
  emailUpdateByNomineeWithoutVerificationVariables
} from "../graphql/emailUpdateByNomineeWithoutVerification";
import { AssertionError } from "assert";
import { USER_INFO_BY_PERSON_ID } from "../Queries";

interface IProps extends WithTranslation {
  email: string | undefined;
  userId: string | undefined;
  personId: string;
}

export const EmailUpdateForm: React.FunctionComponent<IProps> = (props) => {
  const { t, userId, email, personId } = props;
  const [show, setShow] = React.useState(false);
  const handleShow = () => setShow(true);
  const [success, setSuccess] = React.useState(false);

  const [callUpdateEmail, { error: errorUpdateEmail, loading: loadingUpdateEmail }] = useMutation<
    emailUpdateByNomineeWithoutVerification,
    emailUpdateByNomineeWithoutVerificationVariables
  >(EMAIL_UPDATE_BY_NOMINEE_WITHOUT_VERIFICATION, {
    refetchQueries: [{ query: USER_INFO_BY_PERSON_ID, variables: { personId: personId } }]
  });

  let errorReason = errorUpdateEmail?.message;

  const handleSubmit = async (values) => {
    try {
      const { data }: any = await callUpdateEmail({
        variables: {
          newEmail: values.email!,
          userId: userId!
        }
      });

      if (data) {
        setSuccess(true);
        setTimeout(() => handleClose(), 3000);
      } else {
        setSuccess(false);
      }
    } catch (error) {
      throw new AssertionError({ message: "Email update not successful" });
    }
  };
  const handleClose = () => {
    setSuccess(false);
    setShow(false);
  };

  return (
    <>
      <Button variant="link" style={{ float: "right" }} onClick={handleShow}>
        {t("generic:edit")}
      </Button>
      <dl>
        <dt>{t("generic:email")}</dt>
        <dd data-testid={"email"}>{formatValue(email)}</dd>
      </dl>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {t("nominee-dashboard:change-user-email")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailUpdateFormModel
            email={email}
            cancelForm={handleClose}
            submitForm={handleSubmit}
            success={success}
            loading={loadingUpdateEmail}
            error={errorReason || ""}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(["nominee-dashboard", "generic"])(EmailUpdateForm);
