import { useState } from "react";
import { getFlowTypePath } from "../CompanyCreationFlowType/currentFlowType";
import { DataLayer } from "../../../lib/services/GoogleTagManager/GoogleTagManager";

export const RefetchAndResetActiveStep = ({
  lastStep,
  refetchMyCompanyCreation,
  currentFlowType
}) => {
  const [activeKey, setActiveKey] = useState(lastStep);
  const flowType = getFlowTypePath(currentFlowType);

  const resetActiveStep = (step) => {
    DataLayer.VirtualPageViewWithinFlow({
      stepName: step,
      flowType
    });

    setActiveKey(step ?? lastStep);
  };

  const toggleActiveKey = (step) => {
    setActiveKey(step === activeKey ? "" : step);

    if (step !== activeKey) {
      DataLayer.VirtualPageViewWithinFlow({
        stepName: step,
        flowType
      });
    }
  };

  const refetchAndResetStep = async (companyCreationId) => {
    const { data } = await refetchMyCompanyCreation({ id: companyCreationId });
    resetActiveStep(data.myCompanyCreation?.currentStep);
  };

  return { resetActiveStep, toggleActiveKey, refetchAndResetStep, activeKey };
};
