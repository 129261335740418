import React from "react";
import BankAccountsList from "./BankAccountsList";
import { GetMyGmbHs } from "../../../components/client/MyGmbHs/Queries";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

export const BankAccountsListContainer = () => {
  const myGmbHsRequest = GetMyGmbHs();
  const companies = myGmbHsRequest.data?.companies ?? [];

  return (
    <RequestFeedbackHandler requests={[myGmbHsRequest]}>
      <BankAccountsList companies={companies} />
    </RequestFeedbackHandler>
  );
};
