import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ExternalCompanyDataByName_externalCompanyDataByName_companies_companyData } from "lib/api/queries/graphql/ExternalCompanyDataByName";
import { companyName } from "lib/formatters/companyFormatter";
import { CloseIcon } from "sharedComponents/icons/DesignSystemIcons/CloseIcon";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { BankIcon } from "sharedComponents/icons/DesignSystemIcons/BankIcon";
import { WithoutTypenames } from "../../../testTools/helpers/typename";

export interface SelectedExternalCompanyCardProps {
  externalCompanyData: WithoutTypenames<ExternalCompanyDataByName_externalCompanyDataByName_companies_companyData>;
  onClose: () => void;
}

const SelectedExternalCompanyCard = ({
  t,
  externalCompanyData,
  onClose
}: SelectedExternalCompanyCardProps & WithTranslation) => {
  return (
    <div data-testid="selected-external-company-card" className="selected-external-company-card">
      <div className="selected-external-company-card__company-identification">
        <div className="selected-external-company-card__header">
          <Typography
            category={"Headline"}
            size={300}
            weight={"Heavy"}
            data-testid={"company-name"}
            className="selected-external-company-card__company-name">
            {companyName({
              name: externalCompanyData.companyName,
              legalForm: externalCompanyData.legalForm
            })}
          </Typography>
          <div
            data-testid={"close-button"}
            className="selected-external-company-card__close-button"
            onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <Typography
          category={"Paragraph"}
          size={100}
          weight={"Light"}
          data-testid={"registration"}
          className="selected-external-company-card__registration">
          {externalCompanyData.registration}
        </Typography>
      </div>
      <div className="selected-external-company-card__footer">
        <div className="selected-external-company-card__register-court-label">
          <BankIcon size={16} />
          <Typography category={"Paragraph"} size={100} weight={"Heavy"}>
            {t("generic:registerCourt")}
          </Typography>
        </div>
        <div className="selected-external-company-card__register-court">
          <Typography
            category={"Paragraph"}
            size={200}
            weight={"Light"}
            data-testid={"register-court"}>
            {t("generic:district-court-of", { registerCourt: externalCompanyData.registerCourt })}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(SelectedExternalCompanyCard);
