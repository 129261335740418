import moment from "moment/moment";
import { ClickableElement } from "../ClickableElement/ClickableElement";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import React from "react";

export const RideDatePickerHeader = ({ date, decreaseMonth, increaseMonth, prefix }) => {
  const currentMonth = moment(date).format("MMMM");
  return (
    <div className={prefix} data-testid={prefix}>
      <ClickableElement
        onClick={decreaseMonth}
        className={`${prefix}__prevButton`}
        data-testid={`${prefix}-prevButton`}>
        <FaChevronLeft className={`${prefix}__icon`} />
      </ClickableElement>
      <div className={`${prefix}__currentMonth`} data-testid={`${prefix}-currentMonth`}>
        {currentMonth}
      </div>
      <ClickableElement
        onClick={increaseMonth}
        className={`${prefix}__nextButton`}
        data-testid={`${prefix}-nextButton`}>
        <FaChevronRight className={`${prefix}__icon`} />
      </ClickableElement>
    </div>
  );
};
