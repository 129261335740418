import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../../../common/i18n/withTranslationReady";
import { CardContainer } from "../../../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";

interface ReviewContactDetailsProps {
  name: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  editLink: string;
  className?: string;
}

export const ReviewContactDetails = withTranslationReady(["generic"])(
  ({
    name,
    fullName,
    email,
    phoneNumber,
    editLink,
    t,
    className
  }: ReviewContactDetailsProps & WithTranslation) => {
    const contactFields: React.ReactElement[] = [];

    contactFields.push(
      <KeyValueTag dataTestId={"contact-name"} label={t(`generic:name`)} value={fullName} />
    );

    contactFields.push(
      <KeyValueTag
        dataTestId={"contact-phone-number"}
        label={t(`generic:phone-number`)}
        value={phoneNumber}
      />
    );
    contactFields.push(
      <KeyValueTag dataTestId={"contact-email"} label={t("generic:email")} value={email} />
    );

    return (
      <CardContainer className={className ?? ""}>
        <ReviewEntry name={name} editLink={editLink} list={contactFields} />
      </CardContainer>
    );
  }
);

interface KeyValueTagProps {
  label: string;
  value: string;
  dataTestId?: string;
}
export const KeyValueTag = ({ label, value, dataTestId }: KeyValueTagProps) => {
  return (
    <div className={"key-value-tag"}>
      <div className={"key-value-tag__key"}>{label}</div>
      <div className={"key-value-tag__value"} data-testid={dataTestId}>
        {value}
      </div>
    </div>
  );
};
