import React from "react";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

export interface RideDropdownItemProps {
  label: string;
  sublabel?: string;
  type?: RideDropdownItemVariant;
  onSelect?: () => void;
}

export enum RideDropdownItemVariant {
  Basic = "Basic",
  Danger = "Danger"
}

export const RideDropdownItem = ({ label, sublabel, type, onSelect }: RideDropdownItemProps) => {
  return (
    <div
      key={label}
      data-testid="ride-dropdown-item"
      className={`ride-dropdown-item ride-dropdown-item--${type ?? RideDropdownItemVariant.Basic}`}
      onMouseDown={onSelect}>
      <Typography
        category={"Paragraph"}
        size={100}
        weight={"Light"}
        data-testid="ride-dropdown-item-label">
        {label}
      </Typography>
      {sublabel && (
        <Typography
          category={"Paragraph"}
          size={300}
          weight={"Light"}
          className={`ride-dropdown-item__sublabel ride-dropdown-item__sublabel--${
            type ?? RideDropdownItemVariant.Basic
          }`}
          data-testid="ride-dropdown-item-sublabel">
          {sublabel}
        </Typography>
      )}
    </div>
  );
};
