import React from "react";

export const InboxIcon = ({ size = 24, className }: { size?: number; className: string }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    data-testid="inbox-icon"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.92654 5.01948L2.5625 12H6.27924C7.1401 12 7.90438 12.5509 8.17661 13.3675L8.72076 15H15.382L16.3292 13.1056C16.668 12.428 17.3605 12 18.118 12H21.4375L18.0735 5.01948C17.7192 4.38972 17.0529 4 16.3303 4H7.66969C6.94715 4 6.28078 4.38972 5.92654 5.01948Z"
      fill="black"
    />
    <path
      d="M2 19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V14H18.118L17.1708 15.8944C16.832 16.572 16.1395 17 15.382 17H8.72076C7.8599 17 7.09562 16.4491 6.82339 15.6325L6.27924 14H2V19Z"
      fill="black"
    />
  </svg>
);
