import gql from "graphql-tag";
import {
  sendEmailInviteByThirdPartyTaxAdvisor,
  sendEmailInviteByThirdPartyTaxAdvisorVariables
} from "./graphql/sendEmailInviteByThirdPartyTaxAdvisor";
import { useMutation } from "@apollo/react-hooks";

export const SEND_EMAIL_INVITE_BY_THIRD_PARTY_TAX_ADVISOR = gql`
  mutation sendEmailInviteByThirdPartyTaxAdvisor($email: String!) {
    sendEmailInviteByThirdPartyTaxAdvisor(email: $email) {
      feedback
    }
  }
`;
export const SendEmailInviteByThirdPartyTaxAdvisor = () =>
  useMutation<
    sendEmailInviteByThirdPartyTaxAdvisor,
    sendEmailInviteByThirdPartyTaxAdvisorVariables
  >(SEND_EMAIL_INVITE_BY_THIRD_PARTY_TAX_ADVISOR);
