import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import React, { useImperativeHandle } from "react";
import { WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { deepClone } from "common/deepClone";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import GenericAddress from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/GenericAddress/GenericAddress";

const CompanyAddressSchema = (t) => {
  return Yup.object().shape({
    country: Yup.string().required(t("generic:validation-required")),
    streetLine1: Yup.string().required(t("generic:validation-required")),
    city: Yup.string().required(t("generic:validation-required")),
    postalCode: Yup.string().required(t("generic:validation-required"))
  });
};

const CompanyRepresentativeAddress = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef
}: WithTranslation & StepComponentProps) => {
  const shareholderIndex = currentStep?.chapter?.index ?? 0;
  let shareholdersCopy = order.extra.shareholders ? deepClone(order.extra.shareholders) : {};
  const shareholder = shareholdersCopy[shareholderIndex] ?? {};
  const representativeAddress = shareholder.representativeAddress ?? {};

  const initialValues = {
    country: representativeAddress.country ?? "DE",
    streetLine1: representativeAddress.streetLine1 ?? "",
    streetLine2: representativeAddress.streetLine2 ?? "",
    city: representativeAddress.city ?? "",
    postalCode: representativeAddress.postalCode ?? ""
  };

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    currentStep.complete();

    const { shouldReturnToParentChapter, ...data } = values;
    shareholder.representativeAddress = data;

    await saveData(
      {
        ...order.extra,
        shareholders: shareholdersCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldReturnToParentChapter ?? false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: CompanyAddressSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="company-address-form subchapter-order-layout__scrollable">
          <GenericAddress
            formik={formik}
            prefix={`${currentStep?.disposition}`}
            title={t("generic:shareholder-details.representative-address")}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(CompanyRepresentativeAddress);
