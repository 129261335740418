import React from "react";
import { useHistory } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { CompanySelectFieldEditContainer } from "../../../client/components/Company/CompanySelectFieldEdit/CompanySelectFieldEditContainer";
import { AccountingFrameworkEnum } from "../../../global-query-types";

interface CompanyAccountingFrameworkSceneProps extends WithTranslation {}

const CompanyAccountingFrameworkScene = ({ t }: CompanyAccountingFrameworkSceneProps) => {
  const history = useHistory();
  const goBack = () => history.goBack();

  const options = Object.keys(AccountingFrameworkEnum).map((value) => ({ label: value, value }));

  return (
    <ModalFormLayout title={t("client:company.edit.accountingFramework")} onBack={goBack}>
      <CompanySelectFieldEditContainer
        fieldName="accountingFramework"
        section="accounting-framework"
        options={options}
      />
    </ModalFormLayout>
  );
};

export default withTranslation(["generic", "client"])(CompanyAccountingFrameworkScene);
