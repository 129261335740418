import { HubDBExternalContentProvider } from "../adapters/HubDBExternalContentProvider/HubDBExternalContentProvider";

export enum ExternalContentNamespace {
  STEPS_CONTEXTUAL_HELP = "STEPS_CONTEXTUAL_HELP",
  POLICY_EXCEPTION_RULES = "POLICY_EXCEPTION_RULES",
  POLICY_EXCEPTIONS = "POLICY_EXCEPTIONS"
}

export interface ExternalContentItem {
  name: string;
  en_content: string;
  de_content: string;
}

export interface ExternalContentProvider {
  getExternalContentByKey(
    namespace: ExternalContentNamespace,
    key: string,
    suffix?: string
  ): Promise<ExternalContentItem>;

  getFullNamespace(namespace: ExternalContentNamespace): Promise<any[]>;
}

export const EXTERNAL_CONTENT_FALLBACK: ExternalContentItem = {
  name: "",
  en_content: "",
  de_content: ""
};

let instance: ExternalContentProvider;

export const useExternalContentProvider = (): ExternalContentProvider => {
  if (!instance) {
    instance = new HubDBExternalContentProvider(
      process.env.REACT_APP_HUBDB_BASE_URL ?? "",
      process.env.REACT_APP_HUBDB_PORTAL_ID ?? "",
      {
        [ExternalContentNamespace.STEPS_CONTEXTUAL_HELP]:
          process.env.REACT_APP_HUBDB_CONTEXTUAL_HELP_TABLE_ID ?? "",
        [ExternalContentNamespace.POLICY_EXCEPTION_RULES]:
          process.env.REACT_APP_HUBDB_POLICY_EXCEPTION_RULES_TABLE_ID ?? "",
        [ExternalContentNamespace.POLICY_EXCEPTIONS]:
          process.env.REACT_APP_HUBDB_POLICY_EXCEPTIONS_TABLE_ID ?? ""
      }
    );
  }

  return instance;
};
