export interface NavigationPages {
  [key: string]: string;
}

export default class NavigationService {
  static instance: NavigationService | undefined;

  readonly pages: NavigationPages;

  constructor(pages: NavigationPages) {
    this.pages = pages;
  }

  get baseUrl(): string {
    return `${window.location.protocol}//${window.location.host}`;
  }

  get activePage(): string {
    const urls = Object.values(this.pages);
    const matchingUrl = (url): boolean => url === window.location.pathname;

    return urls.find(matchingUrl) ?? "";
  }

  openLink = (url: string, target: string = "_blank") => {
    window.open(url, target);
  };
}
