import * as React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";

interface TwoFactorAuthenticationLogoProps extends WithTranslation {}

const TwoFactorAuthenticationLogo = ({ t }: TwoFactorAuthenticationLogoProps) => {
  return (
    <svg
      role="img"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>{t("auth:two-fa-notification.logo-alt-text")}</title>
      <rect width="100" height="100" fill="white" />
      <rect x="30.5" y="13.5" width="40" height="73" rx="7.5" stroke="#303436" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.2448 55.5483L58 64H50.1608L44.4825 56.7567H40.4266V64H33.8951V51.1723H45.2517C47.8392 51.1723 49.7343 49.8138 49.7343 47.793C49.7343 45.8405 47.8392 44.482 45.2517 44.482H44.5315V39H45.3706C48.6573 39 51.3147 39.8124 53.2867 41.3894C55.2587 42.9732 56.2587 45.1032 56.2587 47.7862C56.2587 51.2064 54.4476 54.0396 51.2448 55.5483ZM21 64V58.2697H27.1189V64H21Z"
        fill="url(#paint0_linear_14642_40543)"
      />
      <path
        d="M70 72C70 72 78 68.2 78 62.5V55.85L70 53L62 55.85V62.5C62 68.2 70 72 70 72Z"
        fill="white"
        stroke="#303436"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M46 19H55" stroke="#303436" />
      <path d="M38.5 29L38.5 34" stroke="#303436" />
      <path d="M40.5469 30.0664L36.4513 32.9345" stroke="#303436" />
      <path d="M40.6641 32.75L36.3339 30.25" stroke="#303436" />
      <path d="M46.168 29L46.168 34" stroke="#303436" />
      <path d="M48.2148 30.0664L44.1193 32.9345" stroke="#303436" />
      <path d="M48.332 32.75L44.0019 30.25" stroke="#303436" />
      <path d="M54.168 29L54.168 34" stroke="#303436" />
      <path d="M56.2148 30.0664L52.1193 32.9345" stroke="#303436" />
      <path d="M56.332 32.75L52.0019 30.25" stroke="#303436" />
      <path d="M62.168 29L62.168 34" stroke="#303436" />
      <path d="M64.2148 30.0664L60.1193 32.9345" stroke="#303436" />
      <path d="M64.332 32.75L60.0019 30.25" stroke="#303436" />
      <circle cx="50.5" cy="76.5" r="2" stroke="#303436" />
      <path
        d="M72.3333 61.666H67.6667C67.2985 61.666 67 61.9645 67 62.3327V64.666C67 65.0342 67.2985 65.3327 67.6667 65.3327H72.3333C72.7015 65.3327 73 65.0342 73 64.666V62.3327C73 61.9645 72.7015 61.666 72.3333 61.666Z"
        stroke="#303436"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.332 61.666V60.3327C68.332 59.8907 68.5076 59.4667 68.8202 59.1542C69.1327 58.8416 69.5567 58.666 69.9987 58.666C70.4407 58.666 70.8646 58.8416 71.1772 59.1542C71.4898 59.4667 71.6654 59.8907 71.6654 60.3327V61.666"
        stroke="#303436"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14642_40543"
          x1="24.7395"
          y1="33.7812"
          x2="61.9448"
          y2="39.8613"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#48FFAF" />
          <stop offset="1" stopColor="#82D6E8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default withTranslationReady(["auth"])(TwoFactorAuthenticationLogo);
