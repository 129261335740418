import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { PaymentMethodType } from "payments-query-types";

export interface MigrateAdminServicePackageData {
  tier?: AdminServiceTier | null;
  paymentMethod?: PaymentMethod;
  completedPayment?: boolean;
}

export enum PaymentMethod {
  Sepa = "sepa",
  ManualBankTransfer = "manual-bank-transfer"
}

export const mapPaymentMethodFromPaymentsModule = (
  paymentMethodType: PaymentMethodType
): PaymentMethod => {
  return PaymentMethod[paymentMethodType];
};

export const mapPaymentMethodToPaymentsModule = (
  paymentMethod: PaymentMethod
): PaymentMethodType => {
  return paymentMethod === PaymentMethod.Sepa
    ? PaymentMethodType.Sepa
    : PaymentMethodType.ManualBankTransfer;
};
