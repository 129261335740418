import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { WepaIcon } from "sharedComponents/icons/DesignSystemIcons/WepaIcon";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import ProductCard from "client/components/ProductCard/ProductCard";
import WepaOnboardingPricingModal from "client/components/WepaSection/WepaOnboarding/WepaOnboardingPricingModal/WepaOnboardingPricingModal";

const WepaProductCard = ({ t }: WithTranslation) => {
  const [showPricingModal, setShowPricingModal] = useState(false);

  return (
    <>
      {showPricingModal && (
        <WepaOnboardingPricingModal onClose={() => setShowPricingModal(false)} />
      )}
      <ProductCard
        dataTestId={"wepa-product-card"}
        title={t("generic:wepa-onboarding.automated-security-bookings-card.title")}
        icon={<WepaIcon />}
        description={t("generic:wepa-onboarding.automated-security-bookings-card.description")}
        price={t("generic:wepa-onboarding.automated-security-bookings-card.price")}
        handleLearnMore={() => setShowPricingModal(true)}
      />
    </>
  );
};

export default withTranslationReady("generic")(WepaProductCard);
