import { Form, FormikProvider, useFormik } from "formik";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { DiscountCodeSchema } from "../../../../lib/validation/DiscountCodeSchema";
import { DiscountCodeField } from "../../../generic/commonFields";
import EditableSection from "../../../../sharedComponents/EditableSection/EditableSection";
import { NomineeCompanyDetails_companyById } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { formatCurrency } from "common/formatters";

interface ReferralCodeSectionProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
  applyReferralCode: (discountCode: string) => Promise<void>;
}

export const ReferralCodeSection = ({
  t,
  company,
  applyReferralCode
}: ReferralCodeSectionProps) => {
  const [editMode, setEditMode] = useState(false);

  const title = t("nominee-dashboard:company.referral-code");
  const testIdPrefix = "referralCode-";
  const discountCode = company.discountCodeUse?.discountCode?.code ?? "";

  const onEdit = () => {
    setEditMode(true);
  };

  const onView = () => {
    setEditMode(false);
  };

  const formik = useFormik({
    initialValues: { discountCode },
    enableReinitialize: true,
    validationSchema: DiscountCodeSchema,
    onSubmit: async (values) => {
      await applyReferralCode(values.discountCode);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditableSection
          onEdit={onEdit}
          isEditMode={editMode}
          onView={onView}
          testIdPrefix={testIdPrefix}
          title={title}>
          {editMode ? (
            <DiscountCodeField t={t} formik={formik} />
          ) : (
            <ViewReferralCode company={company} t={t} />
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

const ViewReferralCode = ({
  company,
  t
}: {
  company: NomineeCompanyDetails_companyById;
  t: TFunction;
}) => {
  const referralCode = company.discountCodeUse?.discountCode?.code ?? "";
  const status = company.discountCodeUse?.status;
  const amount = company.discountCodeUse?.discountCode?.referrerValue ?? 0;
  const hasCompanyDiscountCode = company.discountCodeUse;

  if (!hasCompanyDiscountCode) {
    return (
      <Alert variant="info" data-testid="noReferralCode-alert">
        {t("nominee-dashboard:company.no-referral-code-usage")}
      </Alert>
    );
  }

  return (
    <>
      <div data-testid="referralCode-details">
        <Container>
          <Row>
            <b>{t("nominee-dashboard:referral-code.code")}</b>
            <Col>{referralCode}</Col>
            <b>{t("nominee-dashboard:referral-code.status")}</b>
            <Col>{status}</Col>
            <b>{t("nominee-dashboard:referral-code.amount")}</b>
            <Col>{formatCurrency(amount, { fixed: 0 })}</Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslationReady(["nominee-dashboard", "trader-gmbh", "generic"])(
  ReferralCodeSection
);
