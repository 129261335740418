import React from "react";
import AdminPackageSelector from "./AdminPackageSelector";
import { QueryCompanyById } from "../../../lib/api/queries/GetCompanyById";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

import {
  resetAdminService,
  resetAdminServiceVariables
} from "lib/api/mutations/graphql/resetAdminService";
import { useMutation } from "react-apollo";
import { RESET_ADMIN_SERVICE } from "../../../lib/api/mutations/RESET_ADMIN_SERVICE";
import logger from "../../../common/Logger";

interface AdminPackageSelectorContainerProps {
  companyId: string;
}

export const AdminPackageSelectorContainer = ({
  companyId
}: AdminPackageSelectorContainerProps) => {
  const companyByIdQuery = QueryCompanyById(companyId);
  const company = companyByIdQuery?.data?.companyById;

  const [resetAdminServiceMutation, resetAdminServiceRequest] = useMutation<
    resetAdminService,
    resetAdminServiceVariables
  >(RESET_ADMIN_SERVICE);

  const onClick = async (companyId: string | undefined) => {
    try {
      if (companyId) {
        await resetAdminServiceMutation({
          variables: { companyId }
        });

        await companyByIdQuery.refetch();
      }
    } catch (error) {
      logger.errorMessage(`Unable to reset admin service package`);
      logger.error(error);
    }
  };

  return (
    <RequestFeedbackHandler requests={[companyByIdQuery, resetAdminServiceRequest]}>
      <AdminPackageSelector company={company} onClickResetPackage={onClick} />
    </RequestFeedbackHandler>
  );
};
