import React, { useState } from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { GetMyCompaniesSummarized } from "lib/api/queries/MyCompaniesSummarized";
import { ChooseHoldingCompanyStep } from "./ChooseHoldingCompanyStep";
import { MyCompaniesSummarized_companies } from "../../../../../../../lib/api/queries/graphql/MyCompaniesSummarized";
import { NomineeOnlyPersonIdControlContainer } from "./ChooseHoldingCompanyStep.partials";

export const ChooseHoldingCompanyStepContainer = (props: StepComponentProps) => {
  const [personId, setPersonId] = useState(props.order.ownerId);

  const myCompaniesSummarized = GetMyCompaniesSummarized(personId);
  const companies: MyCompaniesSummarized_companies[] = myCompaniesSummarized.data?.companies ?? [];

  return (
    <>
      <NomineeOnlyPersonIdControlContainer personId={personId} setPersonId={setPersonId} />
      <ChooseHoldingCompanyStep rawCompanies={companies} {...props} />
    </>
  );
};
