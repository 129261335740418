import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Container } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";

interface IBannerProps extends WithTranslation {}

const AdminServicePostOrderBanner = ({ t }: IBannerProps) => {
  return (
    <Jumbotron fluid data-testid="banner" className="admin-service-post-order-banner py-5 py-md-5">
      <Container className="text-light d-flex justify-content-center">
        <h3 data-testid="banner-heading">{t("admin-service:postOrder-status-banner-heading")}</h3>
      </Container>
    </Jumbotron>
  );
};

export default withTranslation(["admin-service"])(AdminServicePostOrderBanner);
