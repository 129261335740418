import { useQueryParams } from "../../../../lib/hooks/useQueryParams";
import React, { useEffect } from "react";

interface ValidatePaymentMethodHandlerProps {
  companyId: string;
  completePaymentMethodTask: (companyId: string, sessionId: string) => Promise<void>;
}

export const ValidatePaymentMethodHandler = ({
  companyId,
  completePaymentMethodTask
}: ValidatePaymentMethodHandlerProps) => {
  const queryParams = useQueryParams();
  const checkoutSource = queryParams.get("checkout_source");
  const sessionId = queryParams.get("session_id");

  useEffect(() => {
    if (checkoutSource === "gs-task" && sessionId && companyId) {
      completePaymentMethodTask(companyId, sessionId);
    }
    // eslint-disable-next-line
  }, [sessionId, checkoutSource]);

  return <></>;
};
