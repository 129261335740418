import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  NextButton,
  StepComponentProps
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import ChapterSummary from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ChapterSummary/ChapterSummary";
import { doNothing } from "common/stubs";
import { GetOrderQuery_getOrder } from "lib/api/queries/graphql/GetOrderQuery";
import { useHistory } from "react-router-dom";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

const BrokerSetupFailed = ({
  t,
  currentStep,
  order,
  saveData
}: WithTranslation & StepComponentProps) => {
  const history = useHistory();

  const backToDashboard = () => {
    const shouldGoToRelevantCompanyPage = FeatureFlagService.instance.isEnabled(
      FeatureFlags.ReplaceCompanyListByCompanyDropdown
    );

    const url = shouldGoToRelevantCompanyPage ? `/client/entities/${order.entityId}/invest` : "/";

    history.push(url);
  };

  const onSubmit = async () => {
    currentStep.complete();

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  };

  const formik = useRideFormik({
    initialValues: {},
    onSubmit: onSubmit
  });

  const module = "broker-setup-failed";

  return (
    <FormikProvider value={formik}>
      <Form className={module}>
        <div data-testid={module}>
          <ChapterSummary
            title={t(
              "generic:ride-broker-onboarding.broker-setup-complete.unable-to-provide-service"
            )}
            message={t(
              "generic:ride-broker-onboarding.broker-setup-complete.unable-to-provide-service-summary"
            )}
          />
        </div>
        <div className="chapters-order-layout__step-navigation-controls">
          <NextButton
            order={{} as GetOrderQuery_getOrder}
            title={t("generic:chapters-layout.back-to-dashboard")}
            saveData={doNothing}
            onClick={backToDashboard}
          />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady("generic")(BrokerSetupFailed);
