import * as Yup from "yup";
import { cityRegex, postalCode } from "./common";

export const CompanyCreationAddressSchema = () => {
  return Yup.object().shape({
    street: Yup.string().required("generic:validation-required"),
    city: Yup.string()
      .matches(cityRegex, { message: "generic:validation-only-letters-spaces-dash" })
      .required("generic:validation-required"),
    postalCode: Yup.string()
      .matches(postalCode, { message: "generic:validation-only-numbers" })
      .required("generic:validation-required")
  });
};
