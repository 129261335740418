import * as Yup from "yup";
import iban from "iban";

const ibanValidator =
  (isRequired = true) =>
  (value?: string | null): boolean => {
    if (!value) {
      return !isRequired;
    }

    return iban.isValid(value);
  };

export const IbanValidation = Yup.string()
  .required("generic:validation-required")
  .test("iban", "generic:validation-iban", ibanValidator());

export const IbanValidationNonRequired = Yup.string()
  .nullable()
  .notRequired()
  .test("iban", "generic:validation-iban", ibanValidator(false));

const IbanSchema = Yup.object().shape({
  iban: IbanValidation
});

export default IbanSchema;
