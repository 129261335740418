import { ErrorMessage, Field, useField } from "formik";
import React, { HTMLProps } from "react";

export interface ClientInputProps extends HTMLProps<any> {
  name: string;
  type: string;
  label?: string;
  testId?: string;
}

export const ClientInput = ({ name, type, label, testId, ...rest }: ClientInputProps) => {
  const { error, touched } = useField(name)[1];
  const errorClassName = error && touched ? "client-input__input--error" : "";
  testId = testId ?? `${name}-input`;
  return (
    <div {...rest}>
      {label && (
        <label
          className="client-input__label d-block"
          htmlFor={name}
          data-testid={testId ? `${testId}-label` : "client-input-label"}>
          {label}
        </label>
      )}
      <Field
        type={type}
        name={name}
        id={name}
        className={`client-input__input w-100 font-weight-bold ${errorClassName}`}
        data-testid={testId ? testId : "client-input-field"}
        disabled={rest.disabled ?? false}
      />
      {touched && (
        <ErrorMessage
          component="div"
          name={name}
          className="client-input__error mt-2"
          data-testid={testId ? `${testId}-error` : "client-input-error"}
        />
      )}
    </div>
  );
};
