import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../../../common/i18n/withTranslationReady";
import React, { useState } from "react";
import { companyName } from "../../../../../../../lib/formatters/companyFormatter";
import { personName } from "../../../../../../../lib/formatters/personFormatter";
import { v4 as uuidv4 } from "uuid";
import { RideTableHeader } from "../../../../../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "../../../../../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../../../../../sharedComponents/RideTableCell/RideTableCell";
import { OptionItem, Select } from "../../../../../../../components/generic/Select";
import { CheckboxField } from "../../../../../../../components/generic/CheckboxField";
import RideInput from "../../../../../../../sharedComponents/FormControls/RideInput/RideInput";
import { Form } from "react-bootstrap";

export interface AssignHoldingShareholdersDirectorsStepProps
  extends StepComponentProps,
    WithTranslation {}

export const AssignHoldingShareholdersDirectorsStep = withTranslationReady(["generic"])(
  ({ order, t, controls, currentStep, saveData }: AssignHoldingShareholdersDirectorsStepProps) => {
    const holdingCompanies = order.extra?.holdingCompanies ?? {};
    const tradingCompanyInfo =
      (order.extra?.companies ?? []).find((c) => c.id === order.entityId) ?? {};
    const tradingCompany = {
      entityId: order.entityId,
      companyInfo: {
        name: tradingCompanyInfo.name,
        legalForm: tradingCompanyInfo.legalForm
      }
    };
    const tradingCompanyEntity = companyToEntity(tradingCompany);

    const shareholders: any[] = order.extra?.shareholders ?? {};
    const employees: any[] = order.extra?.employees ?? {};

    const companies: any[] = [tradingCompany, ...Object.values(holdingCompanies)];
    const persons: any[] = [...Object.values(shareholders), ...Object.values(employees)];

    const companyEntities = companies.map(companyToEntity);
    const personEntities = persons.map(personToEntity);
    const allEntities = companyEntities.concat(personEntities);

    const companyOptions = companyEntities.map(entityToOption);
    const personOptions = personEntities.map(entityToOption);
    const allOptions = companyOptions.concat(personOptions);

    const emptyOption: OptionItem = {
      value: null,
      title: "---",
      disabled: false
    };

    const defaultShareholderMemberships = Object.values(shareholders).map((shareholder) => ({
      id: uuidv4(),
      company: tradingCompanyEntity,
      entity: personToEntity(shareholder),
      isShareholder: true,
      isManagingDirector: shareholder.isManagingDirector,
      shares: shareholder.shares
    }));

    const defaultDirectorMemberships = Object.values(employees).map((employee) => ({
      id: uuidv4(),
      company: tradingCompanyEntity,
      entity: personToEntity(employee),
      isShareholder: false,
      isManagingDirector: employee.isManagingDirector,
      shares: 0
    }));

    const defaultMemberships = defaultShareholderMemberships.concat(defaultDirectorMemberships);

    const initialMemberships = order.extra?.complexMemberships ?? defaultMemberships;

    const [memberships, setMemberships] = useState(initialMemberships);

    const updateMembership = (id: string, update: any) => {
      setMemberships(
        memberships.map((membership) => {
          if (membership.id !== id) return membership;
          return {
            ...membership,
            ...update
          };
        })
      );
    };

    const addMembership = () => {
      setMemberships([
        ...memberships,
        {
          id: uuidv4(),
          company: null,
          entity: null,
          isShareholder: false,
          isManagingDirector: false,
          shares: 0
        }
      ]);
    };

    const deleteMembership = (id: string) => {
      setMemberships(memberships.filter((membership) => membership.id !== id));
    };

    const onSubmit = async () => {
      currentStep.complete();
      await saveData({
        ...currentStep.chapter.order.serialize(),
        complexMemberships: memberships
      });
    };

    const columns = [
      { name: "company", label: "Company" },
      { name: "entity", label: "Entity (Type)" },
      { name: "shareholder", label: "Is Shareholder?" },
      { name: "director", label: "Is Director?" },
      { name: "shares", label: "Shares (%)" },
      { name: "actions", label: "Actions" }
    ];

    return (
      <div
        className="complex-memberships-table"
        style={{
          position: "absolute",
          left: "300px",
          width: "1400px",
          zoom: "70%",
          zIndex: 100,
          background: "white",
          border: "1px solid black"
        }}>
        <RideTableHeader columns={columns} />

        {memberships.map((membership) => {
          return (
            <RideTableRow colCount={columns.length} key={membership.id}>
              <RideTableCell
                value={
                  <Select
                    t={t}
                    options={[emptyOption, ...companyOptions]}
                    name="company"
                    value={membership.company?.id ?? "---"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;

                      const company = companyEntities.find((c) => c.id === value);
                      if (!company) return;

                      updateMembership(membership.id, { company });
                    }}
                  />
                }
              />

              <RideTableCell
                value={
                  <Select
                    t={t}
                    options={[emptyOption, ...allOptions]}
                    name="entity"
                    value={membership.entity?.id ?? "---"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;

                      const entity = allEntities.find((c) => c.id === value);
                      if (!entity) return;

                      updateMembership(membership.id, { entity });
                    }}
                  />
                }
              />

              <RideTableCell
                value={
                  <CheckboxField
                    name="shareholder"
                    label=""
                    state={membership.isShareholder}
                    handleClick={() => {
                      updateMembership(membership.id, { isShareholder: !membership.isShareholder });
                    }}
                  />
                }
              />

              <RideTableCell
                value={
                  membership.entity?.type === "Legal" ? (
                    <span data-testid="director">N/A</span>
                  ) : (
                    <CheckboxField
                      name="director"
                      label=""
                      state={membership.isManagingDirector}
                      handleClick={() => {
                        updateMembership(membership.id, {
                          isManagingDirector: !membership.isManagingDirector
                        });
                      }}
                    />
                  )
                }
              />

              <RideTableCell
                value={
                  membership.isShareholder ? (
                    <RideInput
                      name="shares"
                      type="number"
                      inputProps={{
                        value: membership.shares,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value;
                          updateMembership(membership.id, { shares: parseFloat(value) });
                        }
                      }}
                    />
                  ) : (
                    <span data-testid="shares-input">N/A</span>
                  )
                }
              />

              <RideTableCell
                dataTestId="delete-membership"
                variant={RideTableCellVariant.actionButton}
                value="Delete"
                action={() => {
                  deleteMembership(membership.id);
                }}
              />
            </RideTableRow>
          );
        })}

        <RideTableRow colCount={1}>
          <RideTableCell
            dataTestId="new-membership"
            variant={RideTableCellVariant.actionButton}
            value="New membership"
            action={addMembership}
          />
        </RideTableRow>

        <Form
          onSubmit={(e) => {
            onSubmit();
            e.preventDefault();
            return false;
          }}>
          {controls}
        </Form>
      </div>
    );
  }
);

type Entity = { name: string; id: any; type: "Legal" | "Natural" };

const companyToEntity = (company: any): Entity => ({
  id: company.entityId,
  name: companyName(company.companyInfo),
  type: "Legal"
});

const personToEntity = (person: any): Entity => ({
  id: person.personData?.email,
  name: personName(person.personData) ?? "",
  type: "Natural"
});

const entityToOption = (entity: Entity): OptionItem => ({
  value: entity.id,
  title: `${entity.name} (${entity.type})`,
  disabled: false
});
