import { useRideField } from "../../../../../../lib/hooks/useRideField";
import moment from "moment/moment";
import { ProductCardCheckboxInput } from "../../../../ProductCard/ProductCard";
import { Typography } from "../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import React from "react";

interface FiscalYearCardProps {
  year: number;
  inputName: string;
  classNames?: string;
  fiscalYearStartingDate?: string;
}

const className = `admin-service-additional-years`;

export const FiscalYearCard = ({
  year,
  inputName,
  classNames,
  fiscalYearStartingDate
}: FiscalYearCardProps) => {
  const startingFiscalYear = useRideField(inputName);

  const month = fiscalYearStartingDate ? fiscalYearStartingDate.split(".")[1] : "0";
  const day = fiscalYearStartingDate?.split(".")[0] ?? "0";
  const startingFullDate = moment(new Date(year, Number.parseInt(month) - 1, Number.parseInt(day)));
  const endingFullDate = moment(startingFullDate).add(1, "year").subtract(1, "day");

  const dateInterval = `${startingFullDate.format("DD.MM.YYYY")} - ${endingFullDate.format(
    "DD.MM.YYYY"
  )}`;

  const handleChange = () => {
    const thisIsTheSelectedStartingFiscalYear = startingFiscalYear.value === year;

    if (thisIsTheSelectedStartingFiscalYear) {
      startingFiscalYear.setValue(year + 1);
    } else {
      startingFiscalYear.setValue(year);
    }
  };

  return (
    <label
      data-testid={"select-fiscal-year-card"}
      className={`${className}__fiscal-year-card ${classNames ?? ""}`}>
      <div className={`${className}__fiscal-year-card__checkbox`}>
        <ProductCardCheckboxInput
          data-testid={`${year}-checkbox`}
          checked={startingFiscalYear.value <= year}
          onChange={handleChange}
        />
      </div>
      <Typography
        category={"Paragraph"}
        size={100}
        weight={"Heavy"}
        data-testid={`select-fiscal-year-card-label`}>
        {year.toString()}
      </Typography>
      {fiscalYearStartingDate && (
        <Typography
          category={"Paragraph"}
          size={200}
          weight={"Light"}
          data-testid={"fiscal-year-interval"}>
          {dateInterval}
        </Typography>
      )}
    </label>
  );
};
