import React, { useState } from "react";
import { TFunction } from "i18next";
import moment from "moment-timezone";
import { DocumentType, SalutationType } from "global-query-types";
import { defaultCountryCode } from "common/defaultCountry";
import PersonDetails from "sharedComponents/PersonDetails/PersonDetails";
import { FullPersonDetailsQuery_getPersonDetails } from "components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import EditableSection from "sharedComponents/EditableSection/EditableSection";
import { Form, FormikProvider, useFormik } from "formik";
import { PersonDetailsValidationSchema } from "lib/validation/PersonDetailsValidationSchema";
import { Col, Container, Row } from "react-bootstrap";
import IsPartnerCheckbox from "components/nominee/common/IsPartnerCheckbox";
import LockSwitchCheckbox from "components/nominee/common/LockSwitchCheckbox";
import {
  BirthNameField,
  BirthPlaceField,
  CitizenshipField,
  DateOfBirthField,
  DocumentNumberField,
  DocumentTypeSelectField,
  FirstNameField,
  IssuingAuthorityField,
  LastNameField,
  PersonalTaxIdField,
  PhoneField,
  SalutationSelectField,
  TaxNumberField,
  TaxOfficeSelect,
  TitleField
} from "components/nominee/common/PersonFields";
import { EmailField } from "components/generic/commonFields";
import {
  CityField,
  CountryField,
  PostcodeField,
  StreetAndNumberField,
  StreetLine2Field
} from "components/public/CompanyCreation/fields";
import { NomineeUpdatePersonVariables } from "lib/api/mutations/graphql/NomineeUpdatePerson";
import { emailUpdateByNomineeWithoutVerificationVariables } from "components/nominee/NomineeUser/graphql/emailUpdateByNomineeWithoutVerification";
import logger from "../../../common/Logger";

export interface PersonSectionProps {
  t: TFunction;
  person?: FullPersonDetailsQuery_getPersonDetails | null;
  refetch: () => Promise<void>;
  updatePerson: (variables: NomineeUpdatePersonVariables) => Promise<void>;
  updateEmail: (variables: emailUpdateByNomineeWithoutVerificationVariables) => Promise<void>;
}

export const PersonSection = ({
  t,
  person,
  refetch,
  updatePerson,
  updateEmail
}: PersonSectionProps) => {
  const fullName = `${person?.firstName} ${person?.lastName}`;
  const [editMode, setEditMode] = useState(false);
  const salutationOptions = [SalutationType.Mr, SalutationType.Ms];
  const documentOptions = [DocumentType.IDCARD];

  const onEdit = () => {
    setEditMode(true);
  };

  const onView = () => {
    setEditMode(false);
  };

  const handlePersonUpdate = async (values) => {
    const selectedDate = moment(values.dateOfBirth).format("YYYY-MM-DD");

    try {
      await updatePerson({
        id: person!.id,
        citizenship: values.citizenship,
        city: values.city,
        country: values.country,
        dateOfBirth: selectedDate,
        documentNumber: values.documentNumber,
        documentType: values.documentType,
        firstName: values.firstName,
        issuingAuthority: values.issuingAuthority,
        lastName: values.lastName,
        personalTaxId: values.personalTaxId,
        phoneNumber: values.phoneNumber,
        placeOfBirth: values.placeOfBirth,
        birthName: values.birthName,
        postalCode: values.postalCode,
        salutation: values.salutation,
        street: values.street,
        streetLine2: values.streetLine2,
        taxNumber: values.taxNumber,
        taxOffice: values.taxOffice,
        title: values.title,
        locked: values.locked,
        isPartner: values.isPartner
      });
    } catch (error) {
      if (error instanceof Error && error.message.includes("STRIPE_UPDATE_ERROR")) {
        logger.errorMessage(`Could not update user information in Stripe`);
        logger.error(error);
      } else {
        throw error;
      }
    }

    if (person?.user) {
      try {
        await updateEmail({ newEmail: values.email, userId: person.user.id });
      } catch (error) {
        if (error instanceof Error && error.message.includes("STRIPE_UPDATE_ERROR")) {
          logger.errorMessage(`Could not update user email in Stripe`);
          logger.error(error);
        } else {
          throw error;
        }
      }
    }

    setEditMode(false);
    await refetch();
  };

  const { years, months, date } = moment(person?.dateOfBirth).tz("Europe/Berlin").toObject();
  const dateOfBirth = new Date(years, months, date);
  const initialData = {
    citizenship: person?.citizenship ?? defaultCountryCode,
    city: person?.address ? person.address.city : "",
    country: person?.address ? person.address.country : defaultCountryCode,
    dateOfBirth: person?.dateOfBirth ? dateOfBirth : "",
    documentNumber: person?.documentNumber ?? "",
    documentType: person?.documentType ?? DocumentType.IDCARD,
    email: person?.user ? person.user.email : "",
    firstName: person?.firstName,
    issuingAuthority: person?.issuingAuthority ?? "",
    lastName: person?.lastName,
    personalTaxId: person?.personalTaxId ?? "",
    placeOfBirth: person?.placeOfBirth ?? "",
    phoneNumber: person?.phoneNumber ?? "",
    birthName: person?.birthName ?? "",
    postalCode: person?.address ? person.address.postalCode : "",
    salutation: person?.salutation,
    street: person?.address ? person.address.street : "",
    streetLine2: person?.address ? person.address.streetLine2 : "",
    taxNumber: person?.taxNumber ?? "",
    taxOffice: person?.taxOffice ?? "",
    title: person?.title ?? "",
    locked: person?.locked ?? false,
    isPartner: person?.isPartner ?? false
  };

  const formik = useFormik({
    initialValues: { ...initialData },
    onSubmit: handlePersonUpdate,
    enableReinitialize: true,
    validationSchema: PersonDetailsValidationSchema
  });

  if (!person) return null;

  return (
    <FormikProvider value={formik}>
      <Form data-testid="person-edit-form">
        <EditableSection
          title={fullName}
          testIdPrefix="person-section-"
          isEditMode={editMode}
          onEdit={onEdit}
          onView={onView}>
          {editMode ? (
            <Container data-testid="edit-person-details">
              <Row className="mb-4">
                <Col>
                  <IsPartnerCheckbox formik={formik} />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col>
                  <LockSwitchCheckbox formik={formik} />
                </Col>
              </Row>

              <Row>
                <Col xs={6} md={4}>
                  <PhoneField t={t} formik={formik} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={6} md={4}>
                  <SalutationSelectField
                    t={t}
                    formik={formik}
                    salutationOptions={salutationOptions}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <TitleField t={t} formik={formik} />
                </Col>
                <Col xs={12} md={4}>
                  {!!person.user ? <EmailField t={t} formik={formik} /> : ""}
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4}>
                  <FirstNameField t={t} formik={formik} />
                </Col>
                <Col xs={12} md={4}>
                  <LastNameField t={t} formik={formik} />
                </Col>
                <Col xs={12} md={4}>
                  <BirthNameField t={t} formik={formik} />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <StreetAndNumberField t={t} formik={formik} />
                </Col>
                <Col xs={12} md={6}>
                  <StreetLine2Field t={t} formik={formik} />
                </Col>
              </Row>

              <Row>
                <Col xs={6} md={3}>
                  <PostcodeField t={t} formik={formik} />
                </Col>
                <Col xs={6} md={3}>
                  <CityField t={t} formik={formik} />
                </Col>
                <Col xs={"12"} md={"6"}>
                  <CountryField t={t} formik={formik} />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={6} md={4}>
                  <DateOfBirthField t={t} formik={formik} />
                </Col>
                <Col xs={6} md={4}>
                  <BirthPlaceField t={t} formik={formik} />
                </Col>
                <Col xs={6} md={4}>
                  <CitizenshipField t={t} formik={formik} />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={6} md={4}>
                  <PersonalTaxIdField t={t} formik={formik} />
                </Col>
                <Col xs={6} md={4}>
                  <TaxNumberField t={t} formik={formik} />
                </Col>
                <Col xs={12} md={4}>
                  <TaxOfficeSelect t={t} formik={formik} />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={6} md={4}>
                  <DocumentTypeSelectField
                    t={t}
                    formik={formik}
                    documentOptions={documentOptions}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <DocumentNumberField t={t} formik={formik} />
                </Col>
                <Col xs={12} md={4}>
                  <IssuingAuthorityField t={t} formik={formik} />
                </Col>
              </Row>
            </Container>
          ) : (
            <PersonDetails person={person} />
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};
