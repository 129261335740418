import { EnrichedData } from "../ProcessStatesSection/ProcessStatesSection";
import { ProcessStatesEnum } from "../../../../common/types/ProcessStatesTypes";
import ProcessTasksLists from "../../../../components/nominee/NomineeCompany/components/ProcessTasksLists";
import Table from "../../../../components/generic/Table";
import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { useFormikContext } from "formik";
import { ClientSelect } from "../../../../client/components/ClientSelect/ClientSelect";

interface ProcessStatesTableViewProps extends WithTranslation {
  data: EnrichedData[];
}

export const ProcessStatesTableView = withTranslationReady([
  "nominee-dashboard",
  "company-founding-steps"
])(({ t, data }: ProcessStatesTableViewProps) => {
  const columns = [
    {
      dataField: "name",
      text: t("nominee-dashboard:company.step"),
      sort: false,
      formatter: (value, row: EnrichedData) => {
        const closedOrNotRequiredStates =
          row.state === ProcessStatesEnum.COMPLETED || row.state === ProcessStatesEnum.NOT_REQUIRED;
        return (
          <>
            <span data-testid="processSteps-groupsName" className="font-weight-bold text-primary">
              {t(`company-founding-steps:groups.${value}`)}
            </span>
            {!closedOrNotRequiredStates && <ProcessTasksLists tasks={row.tasks} />}
          </>
        );
      }
    },
    {
      dataField: "state",
      text: t("nominee-dashboard:company.state"),
      sort: false,
      formatter: (value) => (
        <div data-testid={"processSteps-groupsState"} className="pr-2">
          {t(`company-founding-steps:states.${value}`)}
        </div>
      )
    }
  ];

  return (
    <div data-testid="processSteps-stepTable">
      <Table data={data} columns={columns} tableStyle="greenTable" keyField="ref" />
    </div>
  );
});

interface ProcessStatesTableEditProps extends WithTranslation {
  data: EnrichedData[];
}

export const ProcessStatesTableEdit = withTranslationReady([
  "nominee-dashboard",
  "company-founding-steps"
])(({ data, t }: ProcessStatesTableEditProps) => {
  const formik = useFormikContext() as any;

  const columns = [
    {
      dataField: "name",
      text: t("nominee-dashboard:company.step"),
      sort: false,
      formatter: (value, row) => (
        <>
          <span data-testid="processSteps-stepName" className="font-weight-bold text-primary">
            {t(`company-founding-steps:steps.${value}`)}
          </span>
          <ProcessTasksLists tasks={row.tasks} isEditMode={true} />
        </>
      )
    },
    {
      dataField: "state",
      text: t("nominee-dashboard:company.step"),
      sort: false,
      formatter: (value, row) => {
        return (
          <div style={{ minWidth: "150px" }}>
            <ClientSelect name={row.ref} options={row.options} />
          </div>
        );
      }
    }
  ];

  const newData = data.map((a) => ({
    ...a,
    state: formik.values[a.ref]
  }));

  return (
    <div data-testid={"processSteps-editForm"}>
      <Table data={newData} columns={columns} tableStyle="greenTable" keyField="ref" />
    </div>
  );
});
