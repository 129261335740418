import { ActionableTaskOptions, ProcessStates } from "../../../../common/types/ProcessStatesTypes";
import React from "react";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { useMutation } from "react-apollo";
import { SET_PROCESS_STATES } from "../../../../components/nominee/NomineeCompany/Mutations";
import {
  setProcessStates,
  setProcessStatesVariables
} from "../../../../components/nominee/NomineeCompany/graphql/setProcessStates";
import { TaskStatesEnum } from "../../../../global-query-types";
import {
  SetProcessTaskState,
  SetProcessTaskStateVariables
} from "components/client/company/graphql/SetProcessTaskState";
import { SET_PROCESS_TASK_STATE } from "components/client/company/Mutations";
import { GetCompanyCreationSteps } from "../../../../lib/api/queries/GetCompanyCreationSteps";
import { ProcessStatesSection } from "./ProcessStatesSection";

export const ProcessStatesSectionContainer = ({
  companyCreationId
}: {
  companyCreationId: string;
}) => {
  const processStepsWithTasksRequest = GetCompanyCreationSteps(companyCreationId);

  const [setProcessStatesMutation, setProcessStatesRequest] = useMutation<
    setProcessStates,
    setProcessStatesVariables
  >(SET_PROCESS_STATES);

  const [setTaskStateMutation, setTaskStateRequest] = useMutation<
    SetProcessTaskState,
    SetProcessTaskStateVariables
  >(SET_PROCESS_TASK_STATE);

  const handleSave = async (
    processStates: ProcessStates,
    tasks: { ref: string; state: TaskStatesEnum; options: ActionableTaskOptions }[]
  ) => {
    await setProcessStatesMutation({
      variables: {
        id: companyCreationId,
        processStates
      }
    });

    await Promise.all(
      tasks.map((task) => {
        return setTaskStateMutation({
          variables: {
            companyCreationId,
            ref: task.ref,
            state: task.state,
            options: task.options
          }
        });
      })
    );

    await processStepsWithTasksRequest.refetch();
  };

  const processStepsWithTasks = processStepsWithTasksRequest.data?.getCompanyCreationSteps ?? [];

  return (
    <RequestFeedbackHandler
      requests={[processStepsWithTasksRequest, setTaskStateRequest, setProcessStatesRequest]}>
      <ProcessStatesSection
        companyCreationId={companyCreationId}
        processStepsWithTasks={processStepsWithTasks}
        onSave={handleSave}
      />
    </RequestFeedbackHandler>
  );
};
