import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { EntireFiscalYearConfirmationContainer } from "../../components/EntireFiscalYearConfirmation/EntireFiscalYearConfirmationContainer";

const EntireFiscalYearConfirmationScene = ({ t }: WithTranslation) => {
  return <EntireFiscalYearConfirmationContainer />;
};

export default withTranslationReady(["generic"])(EntireFiscalYearConfirmationScene);
