import React, { useState } from "react";
import { SectionWrapper } from "admin/components/LowTaxBrokerOrderDetails/SectionWrapper/SectionWrapper";
import UpdateIbanModal from "admin/components/LowTaxBrokerOrderDetails/DashboardSection/UpdateIbanModal/UpdateIbanModal";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { WithTranslation } from "react-i18next";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";

interface DashboardSectionProps extends WithTranslation {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order;
  upsertHandler: (data: UpsertOrderVariables) => Promise<void>;
}

const DashboardSection = ({ order, upsertHandler, t }: DashboardSectionProps) => {
  const [showUpdateIbanModal, setShowUpdateIbanModal] = useState(false);

  return (
    <SectionWrapper title={t("generic:broker-order-details.dashboard")}>
      {showUpdateIbanModal && (
        <UpdateIbanModal
          iban={order?.extra?.companyBankAccount?.iban ?? ""}
          onClose={() => setShowUpdateIbanModal(false)}
          onSubmit={async ({ iban, swiftBic, creditInstitutionName }) =>
            await upsertHandler({
              id: order?.id,
              extra: {
                companyBankAccount: {
                  ...order?.extra?.companyBankAccount,
                  iban,
                  swiftBic,
                  creditInstitutionName
                }
              }
            })
          }
        />
      )}

      {FeatureFlagService.instance.isEnabled(FeatureFlags.OpsUpdateLTBIbanInLTBOrderDetails) && (
        <RideSectionCard
          title={t("generic:update-bank-account")}
          data-testid="iban-edit-card"
          buttonSlot={
            <RideButton
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.SMALL}
              data-testid="edit-iban-btn"
              label={t("generic:edit")}
              onClick={() => setShowUpdateIbanModal(true)}
              className="float-right"
            />
          }>
          <div className="low-tax-broker-order-details__iban-edit-card__content">
            <SectionField
              data-testid={"iban"}
              label={t("generic:ride-broker-onboarding.company-bank-account.iban")}
              value={order?.extra?.companyBankAccount?.iban ?? t("generic:iban-not-yet-provided")}
            />
            <SectionField
              data-testid={"swift-bic"}
              label={t("generic:review-entry.low-tax-broker.swiftBic")}
              value={order?.extra?.companyBankAccount?.swiftBic ?? "---"}
            />
            <SectionField
              data-testid={"credit-institution-name"}
              label={t("generic:review-entry.low-tax-broker.creditInstitutionName")}
              value={order?.extra?.companyBankAccount?.creditInstitutionName ?? "---"}
            />
          </div>
        </RideSectionCard>
      )}
    </SectionWrapper>
  );
};

const SectionField = ({
  "data-testid": dataTestId,
  label,
  value
}: {
  "data-testid": string;
  label: string;
  value: string;
}) => {
  return (
    <div className="low-tax-broker-order-details__iban-edit-card__field">
      <Typography category="Paragraph" size={100} weight="Heavy">
        {label}:
      </Typography>
      <Typography
        category="Paragraph"
        size={100}
        weight="Light"
        data-testid={`${dataTestId}-value`}>
        {value}
      </Typography>
    </div>
  );
};

export default withTranslationReady(["generic"])(DashboardSection);
