import { getI18n } from "react-i18next";

export interface CompanyName {
  name?: string | null;
  legalForm?: string | null;
}

export const companyName = (company: CompanyName | undefined | null): string => {
  if (!company) return "";

  const { name, legalForm } = company;
  const companyName = name ? name : getI18n().t(`generic:unnamed`);
  const companyLegalForm = legalForm ? getI18n().t(`generic:legalForm.${legalForm}`) : "---";

  return `${companyName} ${companyLegalForm}`;
};

export const getCompanyNameOrDefault = (companyName: string) => {
  if (companyName === "") {
    return getI18n().t("client:gmbh-tile.company-name-placeholder");
  }
  return companyName;
};
