import React, { PropsWithChildren } from "react";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../components/generic/RequestFeedbackHandler";
import { ErrorAlert } from "../../ErrorAlert/ErrorAlertLegacy";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Form } from "formik";

export interface RequestFeedbackFormProps extends WithTranslation, PropsWithChildren<{}> {
  submitLabel: string;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
  buttonClassName?: string;
}

const RequestFeedbackForm = ({
  children,
  submitLabel,
  requests = [],
  t,
  buttonClassName
}: RequestFeedbackFormProps) => {
  const errorAlerts = requests
    .filter((requests) => !requests.loading)
    .map((request, index) => <ErrorAlert t={t} error={request.error?.message} key={index} />);

  const isLoading: boolean = !!requests.find((request) => request.loading);

  return (
    <Form>
      {errorAlerts}

      {children}

      <RideButtonDeprecated
        className={`${buttonClassName} requestFeedbackForm__submit`}
        isLoading={isLoading}
        size={ButtonSize.Big}
        variant={ButtonVariant.Primary}
        type="submit"
        data-testid="submit">
        {submitLabel}
      </RideButtonDeprecated>
    </Form>
  );
};

export default withTranslationReady(["error-codes"])(RequestFeedbackForm);
