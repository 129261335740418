import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import LegalDocumentsConfirmationItem from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/LegalDocumentsConfirmationItem/LegalDocumentsConfirmationItem";
import { Form, FormikProvider, useFormik } from "formik";
import { ConfirmLegalDocumentsSchema } from "lib/validation/ConfirmLegalDocumentsSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import legalDocuments from "../../../../../../config/legalDocuments.json";

export interface LTBLegalDocument {
  name: string;
  url: string;
}

const ConfirmLegalDocuments = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: WithTranslation & StepComponentProps<LowTaxBrokerOrderData>) => {
  let documentsList: LTBLegalDocument[] = order.extra.isNewBrokerEnabled
    ? legalDocuments.docsV2
    : legalDocuments.docs;

  const initialValues = {};

  documentsList.forEach((doc) => {
    Object.assign(initialValues, {
      [`legal-documents-${doc.name}-confirmation-item`]:
        order.extra.legalDocuments?.[doc.name] ?? false
    });
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      currentStep.complete();

      const legalDocuments = {};

      documentsList.forEach((doc) => {
        Object.assign(legalDocuments, {
          [doc.name]: formik.values[`legal-documents-${doc.name}-confirmation-item`]
        });
      });

      await saveData(
        {
          ...order.extra,
          legalDocuments,
          ...currentStep.chapter.order.serialize()
        },
        false
      );
    },
    validationSchema: ConfirmLegalDocumentsSchema(
      t,
      documentsList.map((doc) => doc.name)
    )
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:ride-broker-onboarding.documents.confirm-legal-documents.title")}
        />
        <div className="confirm-legal-documents">
          {documentsList.map((doc, index) => (
            <div
              key={`confirm-legal-documents__item-${index}`}
              data-testid={`legal-documents-${doc.name}`}
              className="confirm-legal-documents__item">
              <LegalDocumentsConfirmationItem
                key={doc.name}
                documentKey={doc.name}
                documentName={t(
                  `generic:ride-broker-onboarding.documents.confirm-legal-documents.legal-documents.${doc.name}.name`
                )}
                documentContext={t(
                  `generic:ride-broker-onboarding.documents.confirm-legal-documents.legal-documents.${doc.name}.context`
                )}
                documentUrl={doc.url}
              />
            </div>
          ))}
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ConfirmLegalDocuments);
