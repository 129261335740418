import React, { ReactNode } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { MissingValue } from "../../chapters/ReviewAndSubmitLowTaxBroker/ReviewAndSubmitLowTaxBroker.partials";

export interface ReviewFieldProps {
  label: string;
  value?: ReactNode;
  name?: string;
  isMandatory?: boolean;
  valueWhenMissing?: string;
  "data-testid"?: string;
}

export const ReviewField = withTranslationReady(["generic"])(
  ({
    label,
    value,
    name,
    isMandatory = true,
    valueWhenMissing,
    "data-testid": dataTestId
  }: ReviewFieldProps & WithTranslation) => {
    return (
      <div data-testid={dataTestId ?? (name && `${name}-review`)} className="ltb-review-field">
        <div data-testid={name && `${name}-label`} className="ltb-review-field__label">
          {label}
        </div>
        <div data-testid={name && `${name}-value`} className={`ltb-review-field__value`}>
          {value ?? <MissingValue isMandatory={isMandatory} placeholderValue={valueWhenMissing} />}
        </div>
      </div>
    );
  }
);
