export type PendingTask = {
  isRequiredForTrading?: string;
  isOnlineTask?: string;
  formNumber?: string;
  formName?: string;
  action?: string;
  isRequiredForApproval?: string;
  taskNumber?: string;
  state?: string;

  // TODO: we're not exactly sure how it will be formatted in JSON form (we know only as URL in XML form)
  //       so we've decided to support all three possibilities
  url?: string;
  URL?: string;
  Url?: string;
};

export enum PendingTaskType {
  LOGIN_TO_COMPLETE = "LOGIN_TO_COMPLETE",
  NO_ACTION_NEEDED = "NO_ACTION_NEEDED",
  DOWNLOAD_AND_AGREE = "DOWNLOAD_AND_AGREE",
  DOWNLOAD_FILL_AND_UPLOAD = "DOWNLOAD_FILL_AND_UPLOAD",
  UPLOAD_REQUESTED_DOCUMENT = "UPLOAD_REQUESTED_DOCUMENT",
  EXTERNAL_URL_PROCESS = "EXTERNAL_URL_PROCESS"
}

export enum IBKRDocumentType {
  ALIEN_ID_CARD = "Alien ID Card",
  BANK_STATEMENT = "Bank Statement",
  BROKERAGE_STATEMENT = "Brokerage Statement",
  CHECK = "Check",
  COMPANY_OWNERSHIP = "Company Ownership",
  CURRENT_LEASE = "Current Lease",
  DIVORCE_SETTLEMENT = "Divorce Settlement",
  DRIVER_LICENSE = "Driver License",
  EMPLOYER_CONFIRMATION = "Employer Confirmation",
  ENTITLEMENT_PAYMENTS = "Entitlement to Payments",
  EVIDENCE_OWNERSHIP_PROPERTY = "Evidence of Ownership of Property",
  GOVERNMENT_ISSUED_LETTERS = "Government Issued Letters",
  HOMEOWNER_INSURANCE_POLICY_BILL = "Homeowner Insurance Policy Bill",
  HOMEOWNER_INSURANCE_POLICY_DOCUMENT = "Homeowner Insurance Policy Document",
  LETTER = "Letter",
  NATIONAL_ID_CARD = "National ID Card",
  OTHER = "Other Document",
  OWNERSHIP = "Ownership",
  PASSPORT = "Passport",
  PAY_SLIP = "Pay Slip",
  PROOF_SALE = "Proof of Sale",
  PROOF_OF_WINNINGS = "Proof of Winnings",
  RENTER_INSURANCE_POLICY_BILL = "Renter Insurance Policy bill",
  RENTER_INSURANCE_POLICY_DOCUMENT = "Renter Insurance Policy Document",
  SECURITY_SYSTEM_BILL = "Security System Bill",
  SEVERANCE = "Severance",
  TAX_RETURN = "Tax Return",
  UTILITY_BILL = "Utility Bill",
  WILL = "Will"
}

export enum ProofOfIdentityType {
  DRIVER_LICENSE = "Driver License",
  PASSPORT = "Passport",
  ALIEN_ID_CARD = "Alien ID Card",
  NATIONAL_ID_CARD = "National ID Card"
}

export enum ProofOfAddressType {
  BANK_STATEMENT = "Bank Statement",
  BROKERAGE_STATEMENT = "Brokerage Statement",
  HOME_OWNER_INSURANCE_POLICY_BILL = "Homeowner Insurance Policy Bill",
  HOME_OWNER_INSURANCE_POLICY_DOCUMENT = "Homeowner Insurance Policy Document",
  RENTER_INSURANCE_POLICY_BILL = "Renter Insurance Policy bill",
  RENTER_INSURANCE_POLICY_DOCUMENT = "Renter Insurance Policy Document",
  SECURITY_SYSTEM_BILL = "Security System Bill",
  GOVERNMENT_ISSUED_LETTERS = "Government Issued Letters",
  UTILITY_BILL = "Utility Bill",
  CURRENT_LEASE = "Current Lease",
  EVIDENCE_OWNERSHIP_PROPERTY = "Evidence of Ownership of Property",
  DRIVER_LICENSE = "Driver License",
  OTHER = "Other Document"
}

export const getPendingTaskType = (task: PendingTask): PendingTaskType => {
  if (task.isOnlineTask === "true") {
    return PendingTaskType.LOGIN_TO_COMPLETE;
  }

  const taskURL = getPendingTaskURL(task);
  if (taskURL) {
    return PendingTaskType.EXTERNAL_URL_PROCESS;
  }

  if (task.action === "to send") {
    if (task.state === "To Be Completed") {
      return PendingTaskType.LOGIN_TO_COMPLETE;
    } else {
      return PendingTaskType.UPLOAD_REQUESTED_DOCUMENT;
    }
  }

  if (
    task.action === "to sign" &&
    formNumbersForDownloadFillAndUpload.includes(task.formNumber ?? "")
  ) {
    return PendingTaskType.DOWNLOAD_FILL_AND_UPLOAD;
  }

  if (task.action === "to sign") {
    return PendingTaskType.DOWNLOAD_AND_AGREE;
  }

  return PendingTaskType.NO_ACTION_NEEDED;
};

export const getPendingTaskURL = (task: PendingTask): string | undefined => {
  if (task.url) {
    return task.url;
  }

  if (task.URL) {
    return task.URL;
  }

  if (task.Url) {
    return task.Url;
  }

  return undefined;
};

export enum ParameterName {
  PROOF_OF_ADDRESS = `proofOfAddressType`,
  PROOF_OF_IDENTITY = "proofOfIdentityType",
  DOCUMENT_TYPE = "documentType"
}

export type IBKRPendingTaskExtraRequirements = {
  name: string;
  documentTypes: IBKRDocumentType[];
  parameterName: ParameterName;
  requiresExpirationDate: boolean;
};

export const getUploadDocumentExtraRequirements = (
  formNumber: string
): IBKRPendingTaskExtraRequirements | undefined => {
  return {
    "8001": {
      name: "Proof of Identity and Date of Birth",
      documentTypes: [
        IBKRDocumentType.DRIVER_LICENSE,
        IBKRDocumentType.PASSPORT,
        IBKRDocumentType.ALIEN_ID_CARD,
        IBKRDocumentType.NATIONAL_ID_CARD
      ],
      parameterName: ParameterName.PROOF_OF_IDENTITY,
      requiresExpirationDate: true
    },
    "8002": {
      name: "Proof of Address",
      documentTypes: [
        IBKRDocumentType.BANK_STATEMENT,
        IBKRDocumentType.BROKERAGE_STATEMENT,
        IBKRDocumentType.HOMEOWNER_INSURANCE_POLICY_BILL,
        IBKRDocumentType.HOMEOWNER_INSURANCE_POLICY_DOCUMENT,
        IBKRDocumentType.RENTER_INSURANCE_POLICY_BILL,
        IBKRDocumentType.RENTER_INSURANCE_POLICY_DOCUMENT,
        IBKRDocumentType.SECURITY_SYSTEM_BILL,
        IBKRDocumentType.GOVERNMENT_ISSUED_LETTERS,
        IBKRDocumentType.UTILITY_BILL,
        IBKRDocumentType.CURRENT_LEASE,
        IBKRDocumentType.EVIDENCE_OWNERSHIP_PROPERTY,
        IBKRDocumentType.DRIVER_LICENSE,
        IBKRDocumentType.OTHER
      ],
      parameterName: ParameterName.PROOF_OF_ADDRESS,
      requiresExpirationDate: false
    },
    "8053": {
      name: "Proof of Identity and Date of Birth",
      documentTypes: [
        IBKRDocumentType.DRIVER_LICENSE,
        IBKRDocumentType.PASSPORT,
        IBKRDocumentType.ALIEN_ID_CARD,
        IBKRDocumentType.NATIONAL_ID_CARD
      ],
      parameterName: ParameterName.PROOF_OF_IDENTITY,
      requiresExpirationDate: true
    },
    "8057": {
      name: "Proof of Identity and Date of Birth",
      documentTypes: [
        IBKRDocumentType.DRIVER_LICENSE,
        IBKRDocumentType.PASSPORT,
        IBKRDocumentType.ALIEN_ID_CARD,
        IBKRDocumentType.NATIONAL_ID_CARD
      ],
      parameterName: ParameterName.PROOF_OF_IDENTITY,
      requiresExpirationDate: true
    },
    "8205": {
      name: "Selfie Verification",
      documentTypes: [IBKRDocumentType.NATIONAL_ID_CARD, IBKRDocumentType.PASSPORT],
      parameterName: ParameterName.PROOF_OF_IDENTITY,
      requiresExpirationDate: true
    },
    "8541": {
      name: "PROOF OF SOW-IND-Allowance",
      documentTypes: [
        IBKRDocumentType.BANK_STATEMENT,
        IBKRDocumentType.PAY_SLIP,
        IBKRDocumentType.EMPLOYER_CONFIRMATION,
        IBKRDocumentType.DIVORCE_SETTLEMENT,
        IBKRDocumentType.COMPANY_OWNERSHIP
      ],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8542": {
      name: "PROOF OF SOW-IND-Disability",
      documentTypes: [
        IBKRDocumentType.BANK_STATEMENT,
        IBKRDocumentType.ENTITLEMENT_PAYMENTS,
        IBKRDocumentType.SEVERANCE
      ],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8543": {
      name: "PROOF OF SOW-IND-Income",
      documentTypes: [
        IBKRDocumentType.PAY_SLIP,
        IBKRDocumentType.BANK_STATEMENT,
        IBKRDocumentType.EMPLOYER_CONFIRMATION
      ],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8544": {
      name: "PROOF OF SOW-IND-Inheritance",
      documentTypes: [
        IBKRDocumentType.LETTER,
        IBKRDocumentType.BANK_STATEMENT,
        IBKRDocumentType.CHECK,
        IBKRDocumentType.WILL,
        IBKRDocumentType.BROKERAGE_STATEMENT
      ],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8545": {
      name: "PROOF OF SOW-IND-Interest",
      documentTypes: [IBKRDocumentType.BROKERAGE_STATEMENT, IBKRDocumentType.TAX_RETURN],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8546": {
      name: "PROOF OF SOW-IND-MarketProfit",
      documentTypes: [
        IBKRDocumentType.OWNERSHIP,
        IBKRDocumentType.BROKERAGE_STATEMENT,
        IBKRDocumentType.TAX_RETURN
      ],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8547": {
      name: "PROOF OF SOW-IND-Other",
      documentTypes: [
        IBKRDocumentType.PROOF_OF_WINNINGS,
        IBKRDocumentType.BANK_STATEMENT,
        IBKRDocumentType.TAX_RETURN,
        IBKRDocumentType.BROKERAGE_STATEMENT
      ],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8548": {
      name: "PROOF OF SOW-IND-Pension",
      documentTypes: [IBKRDocumentType.BANK_STATEMENT, IBKRDocumentType.PAY_SLIP],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8549": {
      name: "PROOF OF SOW-IND-Property",
      documentTypes: [IBKRDocumentType.PROOF_SALE, IBKRDocumentType.CURRENT_LEASE],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    },
    "8004": {
      name: "Proof of Principal Place of Business Address",
      documentTypes: [
        IBKRDocumentType.BANK_STATEMENT,
        IBKRDocumentType.BROKERAGE_STATEMENT,
        IBKRDocumentType.SECURITY_SYSTEM_BILL,
        IBKRDocumentType.GOVERNMENT_ISSUED_LETTERS,
        IBKRDocumentType.UTILITY_BILL,
        IBKRDocumentType.CURRENT_LEASE,
        IBKRDocumentType.EVIDENCE_OWNERSHIP_PROPERTY,
        IBKRDocumentType.OTHER
      ],
      parameterName: ParameterName.DOCUMENT_TYPE,
      requiresExpirationDate: false
    }
  }[formNumber];
};

export const formNumbersForDownloadFillAndUpload = [
  "Form3035",
  "Form3036",
  "Form3041",
  "Form3222",
  "Form4046",
  "Form4065",
  "Form4091",
  "Form4312",
  "Form4434",
  "Form4442",
  "Form4462",
  "Form4502",
  "Form4503",
  "Form4512",
  "Form4514",
  "Form4567",
  "Form4674",
  "Form4676",
  "Form4772",
  "Form4778",
  "Form4779",
  "Form5001",
  "Form5002",
  "Form5006",
  "Form5017",
  "Form5060",
  "Form5060_zh-Hans",
  "Form6102",
  "Form6105",
  "Form6112",
  "Form6120",
  "Form6121",
  "Form6122",
  "Form6123",
  "Form6124",
  "Form6125",
  "Form6126",
  "Form7001",
  "Form8043",
  "Form9470"
];
