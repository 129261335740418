import {
  BankAccountPlan,
  BankType,
  CompanyMigrationType,
  FeatureFlags,
  OrderCompanyStatusEnum,
  OrderEntityType,
  OrderOwnerType,
  OrderProductTypeEnum
} from "global-query-types";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { companyWithAdminServiceTierActive } from "components/client/company/exampleTestData";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { PaymentMethodType } from "../../payments-query-types";
import { getOrderDetails_getOrderDetails_order } from "../api/queries/graphql/getOrderDetails";
import { isCompanyMigrationRequired } from "./companyMigrationStatusHelper";
import { Company } from "lib/types/types";
import { CompanyById_companyById_bankAccounts } from "../api/queries/graphql/CompanyById";
import { MeQuery_me_person } from "../api/queries/graphql/MeQuery";
import { DataLayer, UserPrivateInformation } from "../services/GoogleTagManager/GoogleTagManager";
import { RIDE_FLOWS } from "../../flows";

export type SteueragentenMigrationOrderDetails = Omit<
  getOrderDetails_getOrderDetails_order,
  "extra"
> & {
  extra: AdminServicePTAMigrationToRideSteuerberatungOrderData;
};

export class AdminServicePTAMigrationToRideSteuerberatungHelper {
  static adminServiceFlowUrlSuffix = "admin-service/tax-advisor-migrate";

  static StepCompleted({
    userId,
    step_name,
    ...rest
  }: {
    step_name: AdminServicePTAMigrationToRideSteuerberatungSteps;
    userId?: string | undefined;
  } & UserPrivateInformation) {
    DataLayer.StepCompleted({
      flow: RIDE_FLOWS.ADMIN_SERVICE_PTA_MIGRATION_TO_RIDE_STEUERBERATUNG,
      step_name,
      ...rest
    });
  }
}

//Be consistent with wealth-design-backend-service/packages/server/src/services/orders/models/AdminServicePTAMigrationToRideSteuerberatung/AdminServicePTAMigrationToRideSteuerberatungOrderData.ts
export interface AdminServicePTAMigrationToRideSteuerberatungOrderData {
  companyMigrationId: string;
  previousInfo: {
    //All this should be stored in events related when the companyMigration was created.
    //And also in the conclusion of the flow [to see in how was the state at that moment (when they do the flow not necessary has the info when we requested them to do something) before they finalize and all the consequences of the conclusion of the flow (related events: TaxAdvisorChanged, TaxAdvisorAssigned, AdminServiceArchived(?), AdminServiceOrderCompletedThroughAdminServicePTAMigrationToRideSteuerberatung*)
    adminService: {
      id: string;
      tier: AdminServiceTier | null;
    };
    taxService: {
      id: string;
      advisorId: string;
      advisorName?: string;
    };
  };
  tier?: AdminServiceTier;
  contractSignedAt?: Date | string;
  paymentMethod?: PaymentMethodType;
  stepCompletion?: {
    choosePackageAt?: string;
    downloadContractAt?: string;
    agreeToTermsAndConditionsAt?: string;
    setupPaymentAt?: string;
    reviewAt?: string;
  };
}

export enum AdminServicePTAMigrationToRideSteuerberatungSteps {
  choosePackage = "choosePackage",
  downloadContract = "downloadContract",
  agreeToTermsAndConditions = "agreeToTermsAndConditions",
  setupPayment = "setupPayment",
  review = "review"
}

export function showAdminServicePTAMigrationToRideSteuerberatungFlow(company: Company): boolean {
  const hasFeatureFlag = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AdminServicePTAMigrationToRideSteuerberatung
  );

  return (
    hasFeatureFlag &&
    isCompanyMigrationRequired(company, CompanyMigrationType.PTAMigrationToRideSteuerberatung)
  );
}

//TODO: update here the example company with the right constraints
export const getExampleCompanyWithSteueragentenToRideSteuerberatungMigration = (
  tier: AdminServiceTier = AdminServiceTier.Standard
) => {
  return {
    ...companyWithAdminServiceTierActive(tier),
    fiscalYear: "01.05",
    bankAccounts: [
      {
        __typename: "BankAccount",
        bankProvider: BankType.Fyrst,
        iban: "DE94500105174919656143",
        id: "bankaccount-id",
        plan: BankAccountPlan.Fyrst_Base,
        name: "my bank account"
      }
    ] as CompanyById_companyById_bankAccounts[],
    migrations: [
      {
        migrationName: CompanyMigrationType.PTAMigrationToRideSteuerberatung,
        realizedAt: null,
        requestedSince: new Date("2024-06-15")
      } as any
    ]
  };
};

export function getNewAdminServicePTAMigrationToRideSteuerberatungOrderStructure(
  company?: Company | null,
  person?: MeQuery_me_person | null,
  migrationId?: string
) {
  if (!company) return;
  if (!person?.id) return;
  if (!migrationId) return;

  return {
    productType: OrderProductTypeEnum.AdminServicePTAMigrationToRideSteuerberatung,
    ownerType: OrderOwnerType.Person,
    ownerId: person.id,
    entityType: OrderEntityType.Company,
    entityId: company.id,
    status: OrderCompanyStatusEnum.InProgress,
    extra: {
      companyMigrationId: migrationId,
      previousInfo: {
        adminService: {
          id: company.adminService?.id,
          tier: company.adminService?.tier
        },
        taxService: {
          id: company.myTaxService?.id,
          advisorId: company.myTaxService?.advisor?.id,
          advisorName: company.myTaxService?.advisor?.name
        }
      }
    } as AdminServicePTAMigrationToRideSteuerberatungOrderData
  };
}
