import React, { PropsWithChildren } from "react";
import { LinkProps } from "react-router-dom";
import { RideLink } from "../RideLink/RideLink";

export enum LinkVariant {
  primary = "PRIMARY",
  secondary = "SECONDARY",
  danger = "DANGER",
  dark = "DARK",
  info = "INFO"
}

export interface TextLinkProps extends PropsWithChildren<LinkProps> {
  variant: LinkVariant;
}

export const TextLink = ({
  children,
  variant = LinkVariant.primary,
  to,
  onClick,
  className = "",
  ...rest
}: TextLinkProps) => {
  return (
    <RideLink
      to={to}
      onClick={onClick}
      className={`text-link text-link--${variant} ${className}`}
      {...rest}>
      {children}
    </RideLink>
  );
};
