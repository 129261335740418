import React from "react";
import { Typography } from "../../../designSystem/styles/Typography/Typography";

interface DataGap {
  startingDate: Date;
  endingDate: Date;
  startingValue?: string;
  endingValue?: string;
}

export interface RideDataGapBarProps {
  startPeriod: Date;
  endPeriod: Date;
  startValue?: string;
  endValue?: string;
  gaps: DataGap[];
}

export const RideDataGapBar = ({
  startPeriod,
  endPeriod,
  startValue,
  endValue,
  gaps
}: RideDataGapBarProps) => {
  const formatDateToGermanWithoutYear = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth() returns 0 for January, so add 1
    return `${day}.${month}`;
  };

  const calculateStartAndWidth = (startDate: Date, endDate: Date) => {
    function getDayOfYear(date: Date) {
      const start = new Date(date.getFullYear(), 0, 0);
      const diff =
        date.getTime() -
        start.getTime() +
        (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60000;

      return Math.floor(diff / 86400000); // 86400000 ms in a day
    }

    const startDay = getDayOfYear(startDate);
    const endDay = getDayOfYear(endDate);

    const startPercentage = (startDay / 365) * 100;
    const widthPercentage = ((endDay - startDay + 1) / 365) * 100;

    return {
      start: startPercentage,
      width: widthPercentage
    };
  };

  const startPeriodDate = formatDateToGermanWithoutYear(startPeriod);
  const endPeriodDate = formatDateToGermanWithoutYear(endPeriod);

  return (
    <div className="ride-data-gap-bar-wrapper">
      <div className="ride-data-gap-bar">
        <div
          className={"ride-data-gap-bar__value ride-data-gap-bar__value--top"}
          style={{
            left: `0%`
          }}>
          <Typography category={"Paragraph"} size={300} weight={"Light"}>
            {startValue}
          </Typography>
        </div>
        <div
          className="ride-data-gap-bar__value ride-data-gap-bar__value--bottom"
          style={{
            left: `0%`
          }}>
          <Typography category={"Paragraph"} size={300} weight={"Light"}>
            {startPeriodDate}
          </Typography>
        </div>
        {gaps.map((gap, index) => {
          const { start, width } = calculateStartAndWidth(gap.startingDate, gap.endingDate);
          const gapStartingDate = formatDateToGermanWithoutYear(gap.startingDate);
          const gapEndingDate = formatDateToGermanWithoutYear(gap.endingDate);

          return (
            <div key={`ride-data-gap-${index}`}>
              {startPeriodDate !== gapStartingDate && (
                <div
                  key={`ride-data-gap__gap-starting-value-${index}`}
                  className={"ride-data-gap-bar__value ride-data-gap-bar__value--top"}
                  style={{
                    left: `${start}%`
                  }}>
                  <Typography category={"Paragraph"} size={300} weight={"Light"}>
                    {gap.startingValue}
                  </Typography>
                </div>
              )}
              <div
                key={`ride-data-gap-${index}`}
                data-testid={`ride-data-gap-${index}`}
                className="ride-data-gap-bar__gap"
                style={{
                  left: `${gapStartingDate === "1.1" ? 0 : start}%`,
                  width: `${width}%`
                }}
              />
              {gapEndingDate !== endPeriodDate && (
                <div
                  key={`ride-data-gap__gap-ending-value-${index}`}
                  className={"ride-data-gap-bar__value ride-data-gap-bar__value--top"}
                  style={{
                    left: `${start + width}%`
                  }}>
                  <Typography category={"Paragraph"} size={300} weight={"Light"}>
                    {gap.endingValue}
                  </Typography>
                </div>
              )}
              {startPeriodDate !== gapStartingDate && (
                <div
                  key={`ride-data-gap__gap-starting-date-${index}`}
                  className="ride-data-gap-bar__value ride-data-gap-bar__value--bottom"
                  style={{
                    left: `${start}%`
                  }}>
                  <Typography category={"Paragraph"} size={300} weight={"Light"}>
                    {gapStartingDate}
                  </Typography>
                </div>
              )}
              {gapEndingDate !== endPeriodDate && (
                <div
                  key={`ride-data-gap__gap-ending-date-${index}`}
                  className="ride-data-gap-bar__value ride-data-gap-bar__value--bottom"
                  style={{
                    left: `${start + width}%`
                  }}>
                  <Typography category={"Paragraph"} size={300} weight={"Light"}>
                    {formatDateToGermanWithoutYear(gap.endingDate)}
                  </Typography>
                </div>
              )}
            </div>
          );
        })}
        <div
          className={"ride-data-gap-bar__value ride-data-gap-bar__value--top"}
          style={{
            left: `100%`
          }}>
          <Typography category={"Paragraph"} size={300} weight={"Light"}>
            {endValue}
          </Typography>
        </div>
        <div
          className="ride-data-gap-bar__value ride-data-gap-bar__value--bottom"
          style={{
            left: `100%`
          }}>
          <Typography category={"Paragraph"} size={300} weight={"Light"}>
            {formatDateToGermanWithoutYear(endPeriod)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
