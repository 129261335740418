import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import { RideMultiSelect } from "uiLibrary/components/RideMultiSelect/RideMultiSelect";
import moment from "moment";
import * as Yup from "yup";

interface SelectFinancialStatementsYearsProps {}

const SelectFinancialStatementsYears = ({
  t,
  order,
  controls,
  currentStep,
  saveData
}: SelectFinancialStatementsYearsProps & StepComponentProps & WithTranslation) => {
  const currentYear = moment(order.createdAt).year();
  const onSubmit = async (values) => {
    currentStep.complete();
    await saveData({
      ...order.extra,
      fiscalYears: values,
      ...currentStep.chapter.order.serialize()
    });
  };

  const SelectFinancialStatementYearsSchema = (t) => {
    return Yup.object().shape({
      [`year-${currentYear}-future`]: Yup.boolean().isTrue(
        t(
          "generic:order-company-foundation.steps.select-financial-statements-years.current-fiscal-year-required"
        )
      )
    });
  };

  const formik = useRideFormik({
    onSubmit,
    initialValues: order.extra.fiscalYears ?? {
      [`year-${currentYear}-future`]: true,
      [`year-${currentYear - 1}`]: false,
      [`year-${currentYear - 2}`]: false,
      [`year-${currentYear - 3}`]: false
    },
    validationSchema: SelectFinancialStatementYearsSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:order-company-foundation.steps.select-financial-statements-years.title")}
        />
        <div data-testid="select-financial-statements-years">
          <RideMultiSelect
            dataTestid={"financial-statements-years-multi-select"}
            columns={1}
            options={[
              {
                label: `${currentYear} ${t(
                  "generic:order-company-foundation.steps.select-financial-statements-years.and-future"
                )}`,
                name: `year-${currentYear}-future`
              },
              { label: `${currentYear - 1}`, name: `year-${currentYear - 1}` },
              { label: `${currentYear - 2}`, name: `year-${currentYear - 2}` },
              { label: `${currentYear - 3}`, name: `year-${currentYear - 3}` }
            ]}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SelectFinancialStatementsYears);
