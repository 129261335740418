import React, { useEffect } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { IntendedInitialInvestmentContextSchema } from "lib/validation/IntendedInitialInvestmentContextSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";

const IntendedInitialInvestmentContextStep = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  useEffect(() => {
    if (FeatureFlagService.instance.isEnabled(FeatureFlags.removeIntendedInitialInvestmentStep)) {
      currentStep.complete();

      saveData({ ...order.extra, ...currentStep.chapter.order.serialize() }, false).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    intendedInitialInvestmentContext: order.extra.intendedInitialInvestmentContext ?? ""
  };

  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        intendedInitialInvestmentContext: values.intendedInitialInvestmentContext,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validationSchema: IntendedInitialInvestmentContextSchema(t)
  });

  return (
    <DisabledFeatureFlag name={FeatureFlags.removeIntendedInitialInvestmentStep}>
      <div data-testid="intended-initial-investment-context-step">
        <FormikProvider value={formik}>
          <Form>
            <IntendedInitialInvestmentContext />
            {controls}
          </Form>
        </FormikProvider>
      </div>
    </DisabledFeatureFlag>
  );
};

const IntendedInitialInvestmentContext = withTranslationReady(["generic"])(
  ({ t }: WithTranslation) => {
    return (
      <div data-testid="intended-initial-investment-context">
        <StepHeading
          text={t("generic:ride-broker-onboarding.intended-initial-investment-context.title")}
        />
        <RideTextInput
          name="intendedInitialInvestmentContext"
          label={t("generic:ride-broker-onboarding.intended-initial-investment-context.label")}
          asTextArea
        />
      </div>
    );
  }
);

export default withTranslationReady(["generic"])(IntendedInitialInvestmentContextStep);
