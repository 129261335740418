import React, { useState } from "react";
import CodeFormContainer from "../../components/CodeForm/CodeFormContainer";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { WithTranslation, withTranslation } from "react-i18next";
import PhoneFormContainer from "../../components/PhoneForm/PhoneFormContainer";
import SuccessForm from "../../components/SuccessForm/SuccessForm";
import WarningForm from "../../components/WarningForm/WarningForm";
import { useHistory } from "react-router-dom";

interface TwoFASetupSceneProps extends WithTranslation {
  currentPath: "/2fa" | "/2fa/edit";
}

export enum Steps {
  PHONE = "PHONE",
  CODE = "CODE",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING"
}

const TwoFactorAuthenticationSetupForm = ({ t, currentPath }: TwoFASetupSceneProps) => {
  const { goBack } = useHistory();
  const [activeStep, setActiveStep] = useState(Steps.PHONE);
  const [phoneState, setPhoneState] = useState("");
  const [nextSmsAvailableAtIsoValue, setNextSmsAvailableAtIsoValue] = useState("");
  const isEdit = currentPath === "/2fa/edit";

  const goBackClick = () => {
    if (activeStep === Steps.PHONE) {
      goBack();
      return;
    }
    setActiveStep(Steps.PHONE);
  };

  const goToStep = (step: Steps) => {
    setActiveStep(step);
  };

  const onPhoneChange = (phoneNumber: string) => {
    setPhoneState(phoneNumber);
  };

  const onSmsSent = (nextSmsAvailableAt: string) => {
    setNextSmsAvailableAtIsoValue(nextSmsAvailableAt);
  };

  return (
    <ModalFormLayout
      onBack={goBackClick}
      title={t(`auth:code-form.${isEdit ? "edit-title" : "title"}`)}>
      {activeStep === Steps.PHONE && (
        <PhoneFormContainer
          goToStep={goToStep}
          onPhoneChange={onPhoneChange}
          isEdit={isEdit}
          onSmsSent={onSmsSent}
        />
      )}
      {activeStep === Steps.CODE && (
        <CodeFormContainer
          goToStep={goToStep}
          phoneNumber={phoneState}
          nextSmsAvailableAtIsoValue={nextSmsAvailableAtIsoValue}
        />
      )}
      {activeStep === Steps.SUCCESS && <SuccessForm phoneNumber={phoneState} />}
      {activeStep === Steps.WARNING && <WarningForm goToStep={goToStep} />}
    </ModalFormLayout>
  );
};

export default withTranslation(["auth"])(TwoFactorAuthenticationSetupForm);
