import React from "react";
import { useMutation } from "react-apollo";
import { NOMINEE_UPDATE_PERSON } from "lib/api/mutations/NOMINEE_UPDATE_PERSON";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { PersonSection, PersonSectionProps } from "./PersonSection";
import { EMAIL_UPDATE_BY_NOMINEE_WITHOUT_VERIFICATION } from "components/nominee/NomineeUser/Mutations";
import { emailUpdateByNomineeWithoutVerificationVariables } from "components/nominee/NomineeUser/graphql/emailUpdateByNomineeWithoutVerification";
import { NomineeUpdatePersonVariables } from "lib/api/mutations/graphql/NomineeUpdatePerson";

interface PersonSectionContainerProps
  extends Omit<PersonSectionProps, "updatePerson" | "updateEmail"> {}

const PersonSectionContainer = ({ ...props }: PersonSectionContainerProps) => {
  const [nomineeUpdatePerson, updatePersonInfoRequest] = useMutation(NOMINEE_UPDATE_PERSON);
  const [nomineeUpdateEmail, updateEmailRequest] = useMutation(
    EMAIL_UPDATE_BY_NOMINEE_WITHOUT_VERIFICATION
  );

  const updatePerson = async (variables: NomineeUpdatePersonVariables) => {
    await nomineeUpdatePerson({ variables });
  };

  const updateEmail = async (variables: emailUpdateByNomineeWithoutVerificationVariables) => {
    await nomineeUpdateEmail({ variables });
  };

  return (
    <RequestFeedbackHandler requests={[updatePersonInfoRequest, updateEmailRequest]}>
      <PersonSection {...props} updatePerson={updatePerson} updateEmail={updateEmail} />
    </RequestFeedbackHandler>
  );
};

export default PersonSectionContainer;
