import React from "react";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import { TaskStatesEnum, TaskType } from "../../../../../global-query-types";
import CustomTask from "../../../../../client/components/Company/tasks/CustomTask/CustomTask";
import { FullScreenModal } from "../../../components/FullScreenModal";
import GenericTask from "./GenericTask";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

interface TaskModalProps extends WithTranslation {
  isVisible: boolean;
  task: ActionableTask;
  onHide: () => void;
  onStateChange: (
    ref: string,
    state: TaskStatesEnum,
    options?: ActionableTaskOptions,
    nextTaskToRenderRef?: string
  ) => Promise<void>;
}

export const TaskModal = ({ isVisible, task, onHide, onStateChange }: TaskModalProps) => {
  const handleStateChange = (
    nextState: TaskStatesEnum,
    options?: ActionableTaskOptions,
    nextTaskToRenderRef?: string
  ) => onStateChange(task.ref, nextState, options, nextTaskToRenderRef);

  return (
    <FullScreenModal
      show={isVisible}
      onHide={onHide}
      name="task-modal"
      className="tasks-list__modal">
      {task.type === TaskType.GENERIC ? (
        <GenericTask task={task} onChange={handleStateChange} onCloseModal={onHide} />
      ) : (
        <CustomTask task={task} onChange={handleStateChange} onCloseModal={onHide} />
      )}
    </FullScreenModal>
  );
};

export default withTranslationReady(["company-founding-steps"])(TaskModal);
