import * as Yup from "yup";
import { EmailValidation, emailValidationWithTranslation } from "./EmailSchema";

export const LoginSchema = Yup.object().shape({
  email: EmailValidation,
  password: Yup.string().required("generic:validation-required")
});

export const loginSchemaWithTranslation = (t) =>
  Yup.object().shape({
    email: emailValidationWithTranslation(t),
    password: Yup.string().required(t("generic:validation-required"))
  });

export const passwordSchemaWithTranslation = (t) =>
  Yup.object().shape({
    password: Yup.string().required(t("generic:validation-required"))
  });
