import React, { ChangeEventHandler } from "react";
import { Form } from "react-bootstrap";
import { disabledTransformation } from "../../common/disabledTransformation";

export interface RadioOptionElement {
  label: string;
  disabled?: boolean;
}

interface RadioProps {
  className?: any;
  inline?: Boolean;
  name: string;
  label?: string;
  required?: boolean;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  selectedIndex: number;
  type: any;
  isValid?: boolean;
  isInvalid?: boolean;
  error?: string;
  options: RadioOptionElement[];
  children?: React.ReactChild;
  disabled?: boolean;
}

export const Radios: React.FunctionComponent<RadioProps> = (props) => {
  let {
    className,
    inline = true,
    label,
    required,
    options,
    children,
    name,
    selectedIndex,
    type,
    handleChange,
    disabled: globalDisabled = false
  } = props;

  return (
    <div data-testid={`${name}-radios`}>
      <label>
        {label}
        {required ? "*" : ""}
      </label>
      {options.map(({ label, disabled: itemDisabled = false }, i) => {
        const checked = i === selectedIndex;
        const disabled = globalDisabled || itemDisabled;

        return (
          <span key={i}>
            <Form.Check
              className={className}
              inline={Boolean(inline)}
              custom
              key={`${name}-${i}-${checked}`}
              label={label}
              type={type}
              name={name}
              value={i}
              id={`${name}-${i}`}
              onChange={disabledTransformation(disabled, handleChange)}
              checked={checked}
              disabled={disabled}
            />
            {checked ? <>{children}</> : ""}
          </span>
        );
      })}
    </div>
  );
};
