import React from "react";
import { WithTranslation } from "react-i18next";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideHeaderColumn } from "../../../lib/types/types";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { formatDateTime } from "common/formatters";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface ShelfCompanyDetailsTableProps extends WithTranslation {
  companies: ShelfCompanyDetails[];
  handleAssign: (companyId: string) => Promise<void>;
}

export interface ShelfCompanyDetails {
  id: string;
  name: string;
  city: string;
  date: Date;
  taxNumber: boolean;
  brokerage: boolean;
}

const ShelfCompanyDetailsTable = ({
  t,
  companies,
  handleAssign
}: ShelfCompanyDetailsTableProps) => {
  const columns: RideHeaderColumn[] = [
    t("nominee-dashboard:shelf-company.vg-orders.gmbh"),
    t("nominee-dashboard:shelf-company.vg-orders.date"),
    t("nominee-dashboard:shelf-company.vg-orders.city"),
    t("nominee-dashboard:shelf-company.vg-orders.taxNumber"),
    t("nominee-dashboard:shelf-company.vg-orders.brokerage"),
    t("nominee-dashboard:shelf-company.vg-orders.action")
  ].map((label) => ({
    label,
    name: label.toLowerCase()
  }));

  return (
    <div data-testid="shelf-company-details-table" className={"shelf-company-details-table"}>
      <RideTableHeader columns={columns} />
      {companies.map((company, index) => (
        <RideTableRow
          colCount={columns.length}
          key={index}
          className={"shelf-company-details-table__row"}>
          <RideTableCell
            value={company.name}
            variant={RideTableCellVariant.secondaryLinkDecorated}
            link={`/nominee-companies/${company.id}`}
          />
          <RideTableCell
            value={formatDateTime(company.date)}
            variant={RideTableCellVariant.secondaryText}
          />
          <RideTableCell value={company.city} variant={RideTableCellVariant.secondaryText} />
          <RideTableCell
            value={company.taxNumber ? t("generic:yes") : t("generic:no")}
            variant={RideTableCellVariant.secondaryText}
          />
          <RideTableCell
            value={company.brokerage ? t("generic:yes") : t("generic:no")}
            variant={RideTableCellVariant.secondaryText}
          />
          <RideTableCell
            value={
              <RideButtonDeprecated
                data-testid={"reserve-button"}
                size={ButtonSize.Small}
                variant={ButtonVariant.Secondary}
                onClick={() => {
                  handleAssign(company.id);
                }}>
                {t("nominee-dashboard:shelf-company.vg-orders.reserve")}
              </RideButtonDeprecated>
            }
          />
        </RideTableRow>
      ))}
    </div>
  );
};

export default withTranslationReady(["nominee-dashboard", "generic"])(ShelfCompanyDetailsTable);
