import { Location } from "history";
import * as queryString from "query-string";

export const setURLParams = (params: Map<string, string>): Location => {
  const nonEmptyEntries = Array.from(params).filter(([_, value]) => !!value);
  const search = queryString.stringify(Object.fromEntries(nonEmptyEntries));

  return {
    search: search ? `?${search}` : "",
    pathname: "",
    hash: "",
    state: ""
  };
};

export const getURLParams = (location: Location): Map<string, string> => {
  const params = new Map();

  const query = queryString.parse(location.search);

  for (const [key, value] of Object.entries(query)) {
    if (typeof value === "string") {
      params.set(key, value);
    } else if (value) {
      params.set(key, value[0]);
    }
  }

  return params;
};
