import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Table from "components/generic/Table";
import { Link } from "react-router-dom";
import Timestamp from "components/generic/Timestamp";
import GetUserAddress from "../GetUserAddress/GetUserAddress";
import {
  AllKycEntriesQuery_allKycEntries_kycEntries,
  AllKycEntriesQuery_allKycEntries_kycEntries_person_userWallet_tanganyWallets
} from "lib/api/queries/graphql/AllKycEntriesQuery";
import ClientTags from "components/common/ClientTags/ClientTags";

interface ViewKycListProps extends WithTranslation {
  kycEntries: AllKycEntriesQuery_allKycEntries_kycEntries[];
}

export const ViewKycList = ({ t, kycEntries }: ViewKycListProps) => {
  const presentEmail = (kyc: AllKycEntriesQuery_allKycEntries_kycEntries) => {
    if (!kyc.user) {
      return t("generic:no-account");
    }

    if (!kyc.user.confirmedAt) {
      return t("generic:unconfirmed");
    }

    return kyc.user.email;
  };

  const enrichedData = kycEntries.map((kyc: AllKycEntriesQuery_allKycEntries_kycEntries, index) => {
    let wallet:
      | AllKycEntriesQuery_allKycEntries_kycEntries_person_userWallet_tanganyWallets
      | undefined
      | null = null;

    if (kyc.person?.userWallet?.length) {
      wallet = kyc.person.userWallet[0].tanganyWallets?.find((wallet) => !wallet.ownerCompany);
    }

    if (kyc.company) {
      wallet = kyc.company.ownedWallet;
    }

    return {
      id: index,
      kyc,
      person: kyc.person,
      email: presentEmail(kyc),
      company: kyc.company,
      user: kyc.user,
      fullName: `${kyc.person?.firstName} ${kyc.person?.lastName}`,
      wallet,
      companyName: kyc.company ? `${kyc.company.name} ${kyc.company.legalForm}` : ""
    };
  });

  const defaultSorted = [
    {
      dataField: "kyc.startedAt",
      order: "asc"
    }
  ];

  const columns = [
    {
      dataField: "fullName",
      text: t("nominee-dashboard:kyc.person-user"),
      sort: true,
      formatter: (_, row) =>
        row.person ? (
          <div data-testid="person-name-and-email">
            <div data-testid="person-name">
              <Link
                to={`/nominee-persons/${row.person.id}`}
                data-testid="person-link"
                className="text-reset text-underlined">
                {row.fullName}
              </Link>
            </div>
            <div data-testid="person-email">{row.email}</div>
          </div>
        ) : (
          ""
        )
    },
    {
      dataField: "companyName",
      text: t("nominee-dashboard:kyc.company"),
      sort: true,
      formatter: (companyName, row) => (
        <>
          {row.company && (
            <>
              <Link
                to={`/nominee-companies/${row.company.id}`}
                className="text-reset text-underlined"
                data-testid="company-link">
                {companyName}
              </Link>
              <ClientTags client={row.company} />
            </>
          )}
        </>
      )
    },
    {
      dataField: "kyc.createdAt",
      text: t("nominee-dashboard:kyc.created-at"),
      sort: true,
      formatter: (createdAt) => (
        <span data-testid="created-at">
          <Timestamp date={createdAt} />
        </span>
      )
    },
    {
      dataField: "kyc.startedAt",
      text: t("nominee-dashboard:kyc.kyc-started"),
      sort: true,
      formatter: (startedAt) => (
        <span data-testid="started-at">
          <Timestamp date={startedAt} />
        </span>
      )
    },
    {
      dataField: "kyc.completedAt",
      text: t("nominee-dashboard:kyc.kyc-completed"),
      sort: true,
      formatter: (completedAt) => (
        <span data-testid="completed-at">
          <Timestamp date={completedAt} />
        </span>
      )
    },
    {
      dataField: "whitelisted",
      text: t("nominee-dashboard:kyc.whitelisted"),
      sort: true,
      formatter: () => ""
    },
    {
      dataField: "wallet",
      text: t("generic:wallet-info"),
      formatter: (wallet) => (wallet ? <GetUserAddress wallet={wallet} /> : "")
    }
  ];

  return (
    <Table
      data={enrichedData}
      columns={columns}
      defaultSorted={defaultSorted}
      tableStyle="greenTable"
      keyField="id"
    />
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(ViewKycList);
