import React from "react";
import { Route, Switch } from "react-router";
import { TaxAdvisorRoute } from "./TaxAdvisorRoute";
import TaxAdvisorPersonDetailsContainer from "./components/TaxAdvisorPersonDetails/TaxAdvisorPersonDetailsContainer";
import ThirdPartyTaxAdvisorsCustomersPage from "./scenes/ThirdPartyTaxAdvisorCustomers/ThirdPartyTaxAdvisorCustomersPage";
import TaxAdvisorClientsScene from "./scenes/./TaxAdvisorClients/TaxAdvisorClientsScene";
import TaxAdvisorClientOverviewScene from "./scenes/TaxAdvisorClientOverview/TaxAdvisorClientOverviewScene";
import CompanyTaxNumberScene from "../client/scenes/company/CompanyTaxNumberScene";
import CompanyVatNumberScene from "../client/scenes/company/CompanyVatNumberScene";
import CompanyLeiScene from "../client/scenes/company/CompanyLeiScene";
import CompanyClientNumberScene from "./scenes/CompanyClientNumberScene/CompanyClientNumberScene";
import CompanyFiscalYearsScene from "../client/scenes/company/CompanyFiscalYears/CompanyFiscalYearsScene";
import TaxAdvisorNumberScene from "./scenes/TaxAdvisorAdvisorNumberScene/TaxAdvisorAdvisorNumberScene";
import TaxAdvisorSignUpScene from "./scenes/TaxAdvisorSignUp/TaxAdvisorSignUpScene";
import CompanyAccountingFrameworkScene from "./scenes/CompanyAccountingFramework/CompanyAccountingFrameworkScene";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";
import TaxAdvisorFormerClientsScene from "./scenes/TaxAdvisorFormerClients/TaxAdvisorFormerClientsScene";
import TaxAdvisorFormerClientOverviewScene from "./scenes/TaxAdvisorFormerClientOverview/TaxAdvisorFormerClientOverviewScene";
import TaxAdvisorFormerThirdPartyClientsScene from "./scenes/TaxAdvisorFormerThirdPartyClients/TaxAdvisorFormerThirdPartyClientsScene";

interface TaxAdvisorRoutesProps {
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy;
}

export function TaxAdvisorRoutes({ passwordPolicy }: TaxAdvisorRoutesProps) {
  const path = `/tax-advisor/`;

  return (
    <Switch>
      <TaxAdvisorRoute exact path={`${path}client-entities`} component={TaxAdvisorClientsScene} />
      <TaxAdvisorRoute
        exact
        path={`${path}former-clients`}
        component={TaxAdvisorFormerClientsScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}former-third-party-clients`}
        component={TaxAdvisorFormerThirdPartyClientsScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}persons/:id`}
        component={TaxAdvisorPersonDetailsContainer}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}third-party-tax-advisors-customers`}
        component={ThirdPartyTaxAdvisorsCustomersPage}
      />

      <TaxAdvisorRoute
        exact
        path={`${path}client-entities/:id/tax-number`}
        component={CompanyTaxNumberScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}client-entities/:id/vat-number`}
        component={CompanyVatNumberScene}
      />
      <TaxAdvisorRoute exact path={`${path}client-entities/:id/lei`} component={CompanyLeiScene} />
      <TaxAdvisorRoute
        exact
        path={`${path}client-entities/:id/client-number`}
        component={CompanyClientNumberScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}my-company/advisor-number/:clientId`}
        component={TaxAdvisorNumberScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}client-entities/:id/fiscal-year`}
        component={CompanyFiscalYearsScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}client-entities/:id/accounting-framework`}
        component={CompanyAccountingFrameworkScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}client-entities/:companyId/:ref?`}
        component={TaxAdvisorClientOverviewScene}
      />
      <TaxAdvisorRoute
        exact
        path={`${path}former-clients/:companyId/:ref?`}
        component={TaxAdvisorFormerClientOverviewScene}
      />
      <Route exact path={`${path}signup/:id`} component={() => <TaxAdvisorSignUpScene />} />
    </Switch>
  );
}
