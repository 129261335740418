import React from "react";
import { Col, Row } from "react-bootstrap";
import { companyDetailsByCompanyId_companyById } from "../../../../graphql/companyDetailsByCompanyId";
import { TFunction } from "i18next";
import { formatCurrency, formatDate, formatPercentage } from "../../../../../../common/formatters";

export interface IProps {
  t: TFunction;
  companyData: companyDetailsByCompanyId_companyById;
  companyOwner: string;
  ownerSinceDateValue: Date;
  companyShare: number;
}

export const CompanyMembershipShare = ({
  t,
  companyData,
  companyOwner,
  ownerSinceDateValue,
  companyShare
}: IProps) => {
  return (
    <div className="mt-5">
      <Row>
        <Col xs={6} md={4}>
          <Row>
            <Col>
              <b>{t("user-company:company-value")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="company-value">{formatCurrency(companyData.companyValue)}</Col>
          </Row>
        </Col>
        <Col xs={6} md={4}>
          <Row>
            <Col>
              <b>{t("realEstate:my-share")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="my-share">{formatPercentage(companyShare / 100 ?? 0)}</Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={6} md={4}>
          <Row>
            <Col>
              <b>{t("realEstate:owner-via-my-company")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="owner-via">{companyOwner}</Col>
          </Row>
        </Col>
        <Col xs={6} md={4}>
          <Row>
            <Col>
              <b>{t("generic:owner-since")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="owner-since">{formatDate(ownerSinceDateValue)}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
