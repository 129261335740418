import React, { useState } from "react";
import { TFunction } from "i18next";
import { NomineeCompanyDetails_companyById_myTaxService } from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { ValueView } from "../../../../common/layout/ValueView";
import { formatDateTime } from "../../../../../common/formatters";
import { Form, FormikProvider, useFormik } from "formik";
import RideDatePicker from "../../../../../sharedComponents/RideDatePicker/RideDatePicker";
import EditableSection from "../../../../../sharedComponents/EditableSection/EditableSection";

interface ActivatedTaxServiceSectionProps {
  t: TFunction;
  taxService: NomineeCompanyDetails_companyById_myTaxService;
  updateTaxServiceActivatedAt: (taxServiceId: string, activatedAt: string) => Promise<void>;
}

export const ActivatedTaxServiceSection = ({
  t,
  taxService,
  updateTaxServiceActivatedAt
}: ActivatedTaxServiceSectionProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const onEdit = () => setIsEditMode(true);
  const onView = () => setIsEditMode(false);

  const handleSubmit = async ({ activatedAt }: { activatedAt: string }) => {
    await updateTaxServiceActivatedAt(taxService.id, activatedAt);
  };

  const initialValues = {
    activatedAt: taxService.activatedAt
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  const activatedAtProps = formik.getFieldProps("activatedAt");

  return (
    <div data-testid="activated-tax-service-section">
      <FormikProvider value={formik}>
        <Form>
          <EditableSection
            testIdPrefix="activatedAt-"
            title={""}
            isEditMode={isEditMode}
            onEdit={onEdit}
            onView={onView}>
            {isEditMode ? (
              <RideDatePicker
                name={activatedAtProps.name}
                label={t("nominee-dashboard:admin-service.activatedAt")}
                selectedDate={activatedAtProps.value}
                onSelectDate={(value) => formik.setFieldValue("activatedAt", value)}
              />
            ) : (
              <>
                <ValueView
                  name="activatedAt"
                  value={formatDateTime(taxService.activatedAt)}
                  testId="activatedAt"
                  t={t}
                />
                <ValueView
                  name="updatedAt"
                  value={formatDateTime(taxService.updatedAt)}
                  testId="updatedAt"
                  t={t}
                />
              </>
            )}
          </EditableSection>
        </Form>
      </FormikProvider>
    </div>
  );
};
