import React, { useEffect, useRef, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import RideIcon from "uiLibrary/components/RideIcon/RideIcon";
import { formatCurrency } from "common/formatters";
import { RideModalBody, RideModalControls } from "uiLibrary/designSystem/RideModal/RideModal";
import BackButton from "uiLibrary/components/Buttons/BackButton/BackButton";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export interface PayOutReviewStepProps {
  companyName: string;
  iban: string;
  value: number;
  onClickRequestPayout: () => Promise<void>;
  onClickBack: () => void;
}

const PayOutReviewStep = ({
  t,
  companyName,
  iban,
  value,
  onClickRequestPayout,
  onClickBack
}: PayOutReviewStepProps & WithTranslation) => {
  const mountedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const requestPayout = async () => {
    setIsLoading(true);

    await onClickRequestPayout();

    if (mountedRef.current) {
      setIsLoading(false);
    }
  };

  const moduleName = "pay-out-review-step";
  return (
    <>
      <RideModalBody>
        <div data-testid={moduleName} className={moduleName}>
          <div className={`${moduleName}__heading-view`}>
            <RideIcon dataTestId={"icon"} name="bank" />
            <span data-testid={"heading"} className={`${moduleName}__heading`}>
              {t("generic:low-tax-broker-dashboard.pay-out-modal.review.heading")}
            </span>
          </div>

          <div className={`${moduleName}__transfer-info`}>
            <div className={`${moduleName}__company-info`}>
              <span data-testid={"company-name"} className={`${moduleName}__company-name`}>
                {companyName}
              </span>
              <span data-testid={"iban"} className={`${moduleName}__company-iban`}>
                {iban}
              </span>
            </div>

            <span data-testid={"amount"} className={`${moduleName}__amount`}>
              {formatCurrency(value)}
            </span>
          </div>
        </div>
      </RideModalBody>
      <RideModalControls>
        <BackButton onClick={onClickBack} />
        <RideButtonDeprecated
          width={ButtonWidth.FULL}
          radius={ButtonRadius.Soft}
          data-testid={"request-payout-button"}
          type={"button"}
          isLoaderActive={isLoading}
          onClick={requestPayout}>
          {t("generic:request-payout")}
        </RideButtonDeprecated>
      </RideModalControls>
    </>
  );
};

export default withTranslation(["generic"])(PayOutReviewStep);
