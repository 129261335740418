import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { GetCompanyInfoByCompanyId } from "../../getCompanyInfo";
import ErrorAlertLegacy from "../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import LoadingAlert from "../../../generic/LoadingAlert";
import { CompanyViewLayout } from "./CompanyViewLayout";
import { CompanyEditLayout } from "./CompanyEditLayout";

interface DetailPageMatchProps {
  id: string;
}

interface ClientCompanyDetailPageProps
  extends WithTranslation,
    RouteComponentProps<DetailPageMatchProps> {
  backUrl: string;
}

const ClientCompanyDetailPage = ({ t, backUrl, match, history }: ClientCompanyDetailPageProps) => {
  const companyId = match.params.id;

  const { loading, data, error } = GetCompanyInfoByCompanyId(companyId);

  const [editing, setEditing] = useState(false);
  const toggleEditLayout = () => setEditing(!editing);

  if (loading) return <LoadingAlert t={t} message="generic:loading-message" />;
  if (error) return <ErrorAlertLegacy t={t} error={error?.message} />;

  const companyData = data?.companyById!;
  return (
    <>
      {editing ? (
        <CompanyEditLayout companyData={companyData} toggleEditLayout={toggleEditLayout} t={t} />
      ) : (
        <CompanyViewLayout
          t={t}
          companyData={companyData}
          backUrl={backUrl}
          toggleEditLayout={toggleEditLayout}
          history={history}
        />
      )}
    </>
  );
};

export default withTranslation(["generic", "user-company", "realEstate", "errorCodes"])(
  ClientCompanyDetailPage
);
