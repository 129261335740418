import gql from "graphql-tag";
import {
  ValidateUserEmail,
  ValidateUserEmail_validateUserEmail,
  ValidateUserEmailVariables
} from "./graphql/ValidateUserEmail";
import { EmailValidity } from "../../../global-query-types";
import { genericQuery } from "./genericQuery";

export const VALIDATE_USER_EMAIL = gql`
  query ValidateUserEmail($email: String!) {
    validateUserEmail(email: $email) {
      validity
    }
  }
`;

export const validateUserEmail = async (
  email: string
): Promise<ValidateUserEmail_validateUserEmail> => {
  const response = await genericQuery<ValidateUserEmail, ValidateUserEmailVariables>(
    "validateUserEmail",
    {
      query: VALIDATE_USER_EMAIL,
      variables: { email }
    }
  );

  if (response.type === "error") {
    return { __typename: "EmailValidityResponse", validity: EmailValidity.VALID };
  }

  return response.data;
};
