import React from "react";
import { WithTranslation } from "react-i18next";
import NoResultsPlaceholder from "../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided } from "../../graphql/GetMyTaxAdvisorCompanyWithClients";
import TaxAdvisorClientCardLegacy from "../TaxAdvisorClientCardLegacy/TaxAdvisorClientCardLegacy";

export interface TaxAdvisorClientAssignment
  extends Omit<
    GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients_taxServicesProvided,
    "__typename"
  > {
  endFiscalYear?: number;
}

interface TaxAdvisorClientsWrapperProps extends WithTranslation {
  taxServices: TaxAdvisorClientAssignment[];
}

const TaxAdvisorClientsWrapper = ({ taxServices = [], t }: TaxAdvisorClientsWrapperProps) => {
  const filtered = taxServices.filter((taxService) => taxService.client?.deletedAt === null);

  return (
    <div className="tax-advisor-clients-wrapper">
      {!filtered.length ? (
        <NoResultsPlaceholder text={t("tax-advisor:clients.noClientsLegacy")} />
      ) : (
        <div data-testid="tax-advisor-clients-list">
          {filtered.map((taxService) =>
            taxService.client !== null ? (
              <TaxAdvisorClientCardLegacy
                key={taxService.id}
                taxAdvisorStatus={taxService.taxAdvisorStatus}
                clientId={taxService.client?.id}
                clientName={taxService.client.name}
                clientLegalForm={taxService.client.legalForm}
                clientGroupMemberships={taxService.client.group.memberships}
                brokerageAccounts={taxService.client.brokerageAccounts ?? []}
                endFiscalYear={taxService.endFiscalYear}
              />
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslationReady("tax-advisor")(TaxAdvisorClientsWrapper);
