import { Col, Row } from "react-bootstrap";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ThirdPartyTaxAdvisorsListContainer } from "admin/components/ThirdPartyTaxAdvisorsList/ThirdPartyTaxAdvisorsListContainer";

const ThirdPartyTaxAdvisorsScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:third-party-tax-advisors")}</h2>
        </Col>
      </Row>

      <ThirdPartyTaxAdvisorsListContainer t={t} />
    </>
  );
};

export default withTranslation(["header"])(ThirdPartyTaxAdvisorsScene);
