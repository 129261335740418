import gql from "graphql-tag";

export const TOKENIZED_DETAILS_BY_COMPANY_ID = gql`
  query tokenizedDetailsByCompanyId($companyId: String!) {
    getTokenizedDetailByCompanyId(companyId: $companyId) {
      id
      assetName
      equityTokenAddress
      spvWalletAddress
      image
      companyId
    }
  }
`;

export const CREATE_TOKENIZED_INVESTMENT_MUTATION = gql`
  mutation createTokenizedInvestmentMutation(
    $companyId: String!
    $assetName: String!
    $assetImage: String!
    $equityTokenAddress: String!
    $spvWalletAddress: String!
  ) {
    createTokenizedInvestment(
      companyId: $companyId
      assetName: $assetName
      assetImage: $assetImage
      equityTokenAddress: $equityTokenAddress
      spvWalletAddress: $spvWalletAddress
    ) {
      id
      companyId
      assetName
      image
      equityTokenAddress
      spvWalletAddress
    }
  }
`;
