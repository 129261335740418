import React from "react";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import ChapterSummary from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ChapterSummary/ChapterSummary";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";

const BrokerComplianceComplete = ({
  currentStep,
  order,
  saveData,
  controls,
  t
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const title = t("generic:ride-broker-onboarding.broker-compliance-complete.title");

  const summary = t("generic:ride-broker-onboarding.chapter-summary.message");

  async function onSubmit() {
    currentStep.complete();

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {},
    onSubmit: onSubmit
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div data-testid="broker-compliance-complete">
          <ChapterSummary title={title} message={summary} />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(BrokerComplianceComplete);
