import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import React, { useState } from "react";
import { ReviewOrderConfiguration } from "../../ConfigureOrder/ReviewOrderConfiguration/ReviewOrderConfiguration";
import { ReviewContactDetails } from "../../ContactDetails/ReviewContactDetails/ReviewContactDetails";
import {
  PriceItem,
  PriceOperation,
  PriceSection
} from "../../../../sharedComponents/PriceSection/PriceSection";
import { DiscountCodeSection } from "../../../../sharedComponents/DiscountCodeSection/DiscountCodeSection";
import { useRideFormik } from "../../../../../../../lib/hooks/useRideFormik";
import { FormikProvider } from "formik";
import { RideCheckbox } from "../../../../../../../sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import { LinkVariant, TextLink } from "../../../../../../../sharedComponents/TextLink/TextLink";
import { useHistory } from "react-router-dom";

interface ShortOrderReview extends StepComponentProps, WithTranslation {
  verifyDiscountHandler: (discountCode) => Promise<{ isValid: boolean; discountValue: number }>;
}
const ShortOrderReview = ({
  t,
  order,
  controls,
  verifyDiscountHandler,
  saveData,
  currentStep
}: ShortOrderReview) => {
  const { push } = useHistory();
  const [discountValue, setDiscountValue] = useState(0.0);
  const userOptedForNewCompany = order.extra.userOptedForNewCompany;
  const needsTaxAdvisor = order.extra.needsTaxAdvisor;
  const isWSFlow = !userOptedForNewCompany && !needsTaxAdvisor;
  const switchToRidesTA = order.extra.switchToRideTaxAdvisor;

  const initialValues = {
    discountCode: "",
    agbCheckbox: false,
    actingIndependentCheckbox: false
  };

  const contactDetails = {
    name: order.extra.contactDetails?.firstName
      ? `${order.extra.contactDetails.firstName} ${order.extra.contactDetails.lastName}`
      : "",
    phoneNumber: order.extra.contactDetails?.phoneNumber ?? "",
    email: order.extra.contactDetails?.email ?? ""
  };
  const taxAdvisorContactDetails = {
    name: order.extra.advisorContactDetails?.fullName ?? "",
    phoneNumber: order.extra.advisorContactDetails?.phoneNumber ?? "",
    email: order.extra.advisorContactDetails?.email ?? ""
  };

  const verifyDiscountCode = async (
    discountCode: string
  ): Promise<{ isValid: boolean; discountValue: number }> => {
    const discount = await verifyDiscountHandler(discountCode);
    return { isValid: discount.isValid ?? false, discountValue: discount.discountValue ?? 0 };
  };

  const onSubmitHandler = async () => {
    currentStep.complete();
    let data = {
      ...order.extra,
      discountCode: formik.values.discountCode,
      termsAndConditionsCheckbox: formik.values.agbCheckbox,
      ...currentStep.chapter.order.serialize()
    };

    if (!isWSFlow) data.independentActingCheckbox = formik.values.actingIndependentCheckbox;

    if (userOptedForNewCompany && !needsTaxAdvisor) {
      await saveData(data, true);
      push(`/company-foundation/offer/admin-service/${order.id}/${order.submissionId}`);
    } else {
      await saveData(data);
    }
  };

  const formik = useRideFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: onSubmitHandler
  });

  let additionalFiscalYears = 0;
  if (order.extra.fiscalYears) {
    const keys = Object.keys(order.extra.fiscalYears);
    keys.forEach((key, index) => {
      if (index > 0 && order.extra.fiscalYears[key] === true) additionalFiscalYears++;
    });
  }

  let prices: { [key: string]: PriceItem[] } = {
    GS: [
      { key: "One time", operation: PriceOperation.BASE, value: 2299.0 },
      { key: "Discount", operation: PriceOperation.SUBTRACT, value: discountValue },
      { key: "Total one time", operation: PriceOperation.ADD, value: 2299.0 - discountValue },
      { key: "Yearly recurring", operation: PriceOperation.ADD, value: 0.0 }
    ],
    AS: [
      {
        key: "One time",
        operation: PriceOperation.BASE,
        value: 500.0 + additionalFiscalYears * 1299.0
      },
      { key: "Discount", operation: PriceOperation.SUBTRACT, value: discountValue },
      {
        key: "Total one time",
        operation: PriceOperation.ADD,
        value: 500.0 + additionalFiscalYears * 1299.0 - discountValue
      },
      { key: "Yearly recurring", operation: PriceOperation.ADD, value: 1299.0 }
    ],
    ASGS: [
      { key: "One time", operation: PriceOperation.BASE, value: 2299.0 },
      { key: "Discount", operation: PriceOperation.SUBTRACT, value: discountValue },
      { key: "Total one time", operation: PriceOperation.ADD, value: 2299.0 - discountValue },
      { key: "Yearly recurring", operation: PriceOperation.ADD, value: 1299.0 }
    ]
  };

  const getSelectedProduct = (): PriceItem[] => {
    if (needsTaxAdvisor) {
      if (userOptedForNewCompany) return prices["ASGS"];
      else return prices["AS"];
    } else return prices["GS"];
  };

  const requiredCheckboxesUnchecked = (): boolean => {
    let result = false;

    if (isWSFlow) {
      if (!formik.values["agbCheckbox"]) {
        result = true;
      }
    } else {
      if (!formik.values["agbCheckbox"] || !formik.values["actingIndependentCheckbox"]) {
        result = true;
      }
    }

    return result;
  };

  let navigationControls = {
    ...controls,
    props: {
      ...controls.props,
      title: isWSFlow ? t("generic:send-request") : t("generic:submit-order"),
      disabled: requiredCheckboxesUnchecked(),
      onClick: onSubmitHandler
    }
  };

  return (
    <>
      <StepHeading
        text={
          isWSFlow
            ? t("generic:order-company-foundation.steps.review-and-submit.title-wepa-flow")
            : t("generic:order-company-foundation.steps.review-and-submit.title")
        }
      />
      <div>
        <FormikProvider value={formik}>
          <ReviewOrderConfiguration
            editLink={`/client/order/generic/configure-order/0/${order.id}/${order.submissionId}`}
            userOptedForNewCompany={userOptedForNewCompany}
            needsTaxAdvisor={needsTaxAdvisor}
            name={"configure-order"}
          />
          <ReviewContactDetails
            className={"short-order-review__contact-details"}
            editLink={`/client/order/generic/contact-details/0/${order.id}/${order.submissionId}`}
            email={contactDetails.email}
            fullName={contactDetails.name}
            phoneNumber={contactDetails.phoneNumber}
            name={"contact-details"}
          />

          {isWSFlow && (
            <>
              {!switchToRidesTA && (
                <ReviewContactDetails
                  className={"short-order-review__contact-details"}
                  fullName={taxAdvisorContactDetails.name}
                  phoneNumber={taxAdvisorContactDetails.phoneNumber}
                  email={taxAdvisorContactDetails.email}
                  editLink={`/client/order/generic/tax-advisor-contact-details/0/${order.id}/${order.submissionId}`}
                  name={"tax-advisor-contact-details"}
                />
              )}
              <RideCheckbox
                formik={formik}
                name={"agbCheckbox"}
                data-testid={"agb"}
                label={
                  <Trans
                    i18nKey={"generic:order-form.checkboxes.agb"}
                    components={{
                      agbLink: (
                        <TextLink
                          variant={LinkVariant.primary}
                          to={"https://www.ride.capital/agb?utm_source=app&utm_medium=app"}
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        />
                      )
                    }}
                  />
                }
                className={"order-form__checkbox"}
              />
            </>
          )}

          {!isWSFlow && (
            <>
              <DiscountCodeSection
                formik={formik}
                verifyDiscountCode={verifyDiscountCode}
                setDiscountValue={setDiscountValue}
              />
              <PriceSection prices={getSelectedProduct()} displayTotal={false} />
              <RideCheckbox
                formik={formik}
                name={"agbCheckbox"}
                data-testid={"agb"}
                label={
                  <Trans
                    i18nKey={"generic:order-form.checkboxes.agb"}
                    components={{
                      agbLink: (
                        <TextLink
                          variant={LinkVariant.primary}
                          to={"https://www.ride.capital/agb?utm_source=app&utm_medium=app"}
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        />
                      )
                    }}
                  />
                }
                className={"order-form__checkbox"}
              />
              <RideCheckbox
                formik={formik}
                name="actingIndependentCheckbox"
                data-testid="independent-acting"
                label={t("generic:order-form.checkboxes.user-is-acting-independently")}
                className={"order-form__checkbox"}
              />
            </>
          )}
        </FormikProvider>
        {navigationControls}
      </div>
    </>
  );
};

export default withTranslationReady(["generic"])(ShortOrderReview);
