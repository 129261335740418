import React, { HTMLProps } from "react";
import { ErrorMessage, FormikProps } from "formik";
import ReactSelect from "react-select";
import { TFunction } from "i18next";

export interface NotarySelectProps extends HTMLProps<any> {
  name: string;
  options: NotarySelectOption[];
  label?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  testId?: string;
  placeholder?: string;
  t: TFunction;
  formik: FormikProps<any>;
}

export interface NotarySelectOption {
  label: string;
  value: string;
}

export const NotarySelectField = ({
  name,
  options,
  label,
  isSearchable = false,
  isClearable = false,
  formik,
  testId,
  placeholder,
  t,
  ...rest
}: NotarySelectProps) => {
  const containerErrorClass = formik.errors[name] ? "client-select__container--error" : "";
  testId = testId ? testId : name;

  return (
    <div {...rest} data-testid={`${testId}-container`}>
      {label && (
        <label
          data-testid={`${testId}-select-label`}
          htmlFor={name}
          className="client-select__label d-block">
          {t(label)}
        </label>
      )}
      <div data-testid={`${testId}-select-container`}>
        <ReactSelect
          name={name}
          id={name}
          options={options}
          isSearchable={isSearchable}
          isClearable={isClearable}
          onChange={(option) => formik.setFieldValue(name, option)}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          error={formik.errors[name]?.toString()}
          defaultValue={formik.initialValues[name]}
          classNamePrefix="client-select"
          placeholder={placeholder}
          className={`client-select__container ${containerErrorClass}`}
        />
      </div>
      {formik.errors[name] && (
        <ErrorMessage
          className="client-input__error mt-2"
          component="div"
          name={name}
          data-testid={`${testId}-error`}
        />
      )}
    </div>
  );
};
