import { RealEstateAssetType } from "../global-query-types";

export const translateRealEstateType = (t, detail) => {
  const realEstateTypeTranslations = {
    [RealEstateAssetType.IncomeProducing]: t("realEstate:income-producing"),
    [RealEstateAssetType.LandPlot]: t("realEstate:land-plot"),
    [RealEstateAssetType.Redevelopment]: t("realEstate:redevelopment")
  };

  return realEstateTypeTranslations[detail!.realEstateAssetType!] || "";
};
