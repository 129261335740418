import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  ConditionTypeOptions,
  InvoiceDateField,
  InvoiceFieldSection,
  PeriodOptions,
  InvoiceSelectField,
  InvoiceTextField,
  YesNoOptions
} from "../InvoiceFields/InvoiceFields";
import { FormikProps, useFormik } from "formik";
import EditableSectionWithFormik from "../../../sharedComponents/EditableSectionWithFormik/EditableSectionWithFormik";

export interface ServiceInvoiceData {
  setupFeeType: string | null;
  setupFeeValue: string | null;
  contractSignDate: any | null;
  servicePeriodStartingDate: any | null;
  servicePeriodEndingDate: any | null;
  invoicePeriodType: string | null;
  invoiceableStatus: string | null;
  additionalNotes: string | null;
  conditionType: string | null;
  conditionTypeSpecial: string | null;
  terminationStatus: string | null;
  terminationEndingDate: any | null;
}

interface ServiceInvoiceSectionProps extends WithTranslation {
  title: string;
  service: "admin" | "wepa";
  serviceInvoiceData: ServiceInvoiceData | null;
  onSubmit: (variables: ServiceInvoiceData) => Promise<void>;
}

const ServiceInvoiceSection = ({
  t,
  title,
  service,
  serviceInvoiceData,
  onSubmit
}: ServiceInvoiceSectionProps) => {
  const formik = useFormik({
    initialValues: {
      setupFeeType: serviceInvoiceData?.setupFeeType ?? null,
      setupFeeValue: serviceInvoiceData?.setupFeeValue ?? null,
      invoicePeriodType: serviceInvoiceData?.invoicePeriodType ?? null,
      invoiceableStatus: serviceInvoiceData?.invoiceableStatus ?? null,
      additionalNotes: serviceInvoiceData?.additionalNotes ?? null,
      conditionType: serviceInvoiceData?.conditionType ?? null,
      conditionTypeSpecial: serviceInvoiceData?.conditionTypeSpecial ?? null,
      terminationStatus: serviceInvoiceData?.terminationStatus ?? null,
      contractSignDate: serviceInvoiceData?.contractSignDate ?? null,
      servicePeriodStartingDate: serviceInvoiceData?.servicePeriodStartingDate ?? null,
      servicePeriodEndingDate: serviceInvoiceData?.servicePeriodEndingDate ?? null,
      terminationEndingDate: serviceInvoiceData?.terminationEndingDate ?? null
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await onSubmit({
        ...values
      });
    }
  });

  return (
    <EditableSectionWithFormik
      formik={formik}
      title={title}
      testId={`${service}-service-invoice-section`}
      children={({ isEditMode }) => {
        return <ServiceInvoiceSectionStructure isEdit={isEditMode} t={t} formik={formik} />;
      }}
    />
  );
};

interface ServiceInvoiceSectionStructureProps {
  t: (key: string) => string;
  isEdit: boolean;
  formik: FormikProps<any>;
}

const ServiceInvoiceSectionStructure = ({
  t,
  isEdit,
  formik
}: ServiceInvoiceSectionStructureProps) => {
  return (
    <>
      <InvoiceFieldSection label={t("nominee-dashboard:company.invoice.conditions.invoiceable")}>
        <InvoiceSelectField
          name="invoiceableStatus"
          label={t("nominee-dashboard:company.invoice.conditions.invoiceableStatus")}
          options={YesNoOptions(t)}
          isEdit={isEdit}
          formik={formik}
        />
      </InvoiceFieldSection>

      <InvoiceFieldSection label={t("nominee-dashboard:company.invoice.conditions.setup-fee")}>
        <InvoiceSelectField
          name="setupFeeType"
          label={t("nominee-dashboard:company.invoice.conditions.setupFeeType")}
          options={YesNoOptions(t)}
          isEdit={isEdit}
          formik={formik}
          onChange={(value) => {
            if (value === "No") {
              formik.setFieldValue("setupFeeValue", null);
            }
          }}
        />

        <InvoiceTextField
          name="setupFeeValue"
          label={t("nominee-dashboard:company.invoice.conditions.setupFeeValue-label")}
          isEdit={isEdit && formik.values["setupFeeType"] === "Yes"}
          formik={formik}
        />
      </InvoiceFieldSection>

      <InvoiceFieldSection
        label={t("nominee-dashboard:company.invoice.conditions.contractSignDate")}>
        <InvoiceDateField
          name="contractSignDate"
          label={t("nominee-dashboard:company.invoice.conditions.contractSignDate-date")}
          isEdit={isEdit}
          formik={formik}
        />
      </InvoiceFieldSection>

      <InvoiceFieldSection label={t("nominee-dashboard:company.invoice.conditions.service-period")}>
        <InvoiceDateField
          name="servicePeriodStartingDate"
          label={t("nominee-dashboard:company.invoice.conditions.servicePeriodStartingDate")}
          isEdit={isEdit}
          formik={formik}
        />

        <InvoiceDateField
          name="servicePeriodEndingDate"
          label={t("nominee-dashboard:company.invoice.conditions.servicePeriodEndingDate")}
          isEdit={isEdit}
          formik={formik}
        />
      </InvoiceFieldSection>

      <InvoiceFieldSection
        label={t("nominee-dashboard:company.invoice.conditions.invoice-period-label")}>
        <InvoiceSelectField
          name="invoicePeriodType"
          label={t("nominee-dashboard:company.invoice.conditions.invoicePeriodType")}
          options={PeriodOptions(t)}
          isEdit={isEdit}
          formik={formik}
        />
      </InvoiceFieldSection>

      <InvoiceFieldSection label={t("nominee-dashboard:company.invoice.conditions.condition")}>
        <InvoiceSelectField
          name="conditionType"
          label={t("nominee-dashboard:company.invoice.conditions.conditionType")}
          options={ConditionTypeOptions(t)}
          isEdit={isEdit}
          formik={formik}
          onChange={(value) => {
            if (value === "Standard") formik.setFieldValue("conditionTypeSpecial", null);
          }}
        />

        <InvoiceTextField
          name="conditionTypeSpecial"
          label={t("nominee-dashboard:company.invoice.conditions.conditionSpecial")}
          isEdit={isEdit && formik.values["conditionType"] === "Special"}
          formik={formik}
        />
      </InvoiceFieldSection>

      <InvoiceTextField
        name="additionalNotes"
        label={t("nominee-dashboard:company.invoice.conditions.additionalNotes")}
        isEdit={isEdit}
        formik={formik}
      />

      <InvoiceFieldSection label={t("nominee-dashboard:company.invoice.conditions.termination")}>
        <InvoiceSelectField
          name="terminationStatus"
          label={t("nominee-dashboard:company.invoice.conditions.terminationStatus")}
          options={YesNoOptions(t)}
          isEdit={isEdit}
          formik={formik}
          onChange={(value) => {
            if (value === "No") formik.setFieldValue("terminationEndingDate", null);
          }}
        />

        <InvoiceDateField
          name="terminationEndingDate"
          label={t("nominee-dashboard:company.invoice.conditions.terminationEndingDate")}
          isEdit={isEdit && formik.values["terminationStatus"] === "Yes"}
          formik={formik}
        />
      </InvoiceFieldSection>
    </>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(ServiceInvoiceSection);
