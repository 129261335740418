import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const UserIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="user-icon"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.5C10.9616 1.5 9.94662 1.80791 9.08326 2.38478C8.2199 2.96166 7.54699 3.7816 7.14963 4.74091C6.75227 5.70022 6.64831 6.75582 6.85088 7.77422C7.05345 8.79262 7.55346 9.72809 8.28769 10.4623C9.02192 11.1965 9.95738 11.6965 10.9758 11.8991C11.9942 12.1017 13.0498 11.9977 14.0091 11.6004C14.9684 11.203 15.7883 10.5301 16.3652 9.66674C16.9421 8.80338 17.25 7.78835 17.25 6.75C17.25 5.35761 16.6969 4.02225 15.7123 3.03769C14.7277 2.05312 13.3924 1.5 12 1.5ZM14.0834 3.63199C13.4667 3.21993 12.7417 3 12 3C11.0054 3 10.0516 3.39509 9.34835 4.09835C8.64509 4.80161 8.25 5.75544 8.25 6.75C8.25 7.49168 8.46993 8.2167 8.88199 8.83339C9.29404 9.45007 9.87971 9.93072 10.5649 10.2145C11.2502 10.4984 12.0042 10.5726 12.7316 10.4279C13.459 10.2833 14.1272 9.9261 14.6517 9.40165C15.1761 8.8772 15.5333 8.20902 15.6779 7.48159C15.8226 6.75416 15.7484 6.00016 15.4645 5.31494C15.1807 4.62971 14.7001 4.04404 14.0834 3.63199Z"
      fill="currentColor"
    />
    <path
      d="M19.5 22.5H18V18.75C18 18.2575 17.903 17.7699 17.7145 17.3149C17.5261 16.86 17.2499 16.4466 16.9016 16.0984C16.5534 15.7501 16.14 15.4739 15.6851 15.2855C15.2301 15.097 14.7425 15 14.25 15H9.75C8.75544 15 7.80161 15.3951 7.09835 16.0984C6.39509 16.8016 6 17.7554 6 18.75V22.5H4.5V18.75C4.5 17.3576 5.05312 16.0223 6.03769 15.0377C7.02226 14.0531 8.35761 13.5 9.75 13.5H14.25C15.6424 13.5 16.9777 14.0531 17.9623 15.0377C18.9469 16.0223 19.5 17.3576 19.5 18.75V22.5Z"
      fill="currentColor"
    />
  </svg>
);
