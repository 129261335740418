import React from "react";
import { ApolloError, ApolloQueryResult } from "apollo-client";
import { TFunction } from "i18next";
import { FlowTypeEnum, RolesInGroup, SalutationType } from "../../../../../global-query-types";
import { MutationFunctionOptions, useMutation } from "react-apollo";
import logger from "../../../../../common/Logger";
import { AssertionError } from "assert";
import { Col, Container, Row } from "react-bootstrap";
import { ExternalDirectorFormFields } from "./ExternalDirectorFormFields";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "../../../CompanyCreation/graphql/MyCompanyCreationQuery";
import {
  UpdateEmployeeManagingDirector,
  UpdateEmployeeManagingDirectorVariables
} from "../../../CompanyCreation/graphql/UpdateEmployeeManagingDirector";
import { SalutationValueEnum } from "../../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import { UPDATE_EMPLOYEE_MANAGING_DIRECTOR } from "../../../CompanyCreation/Mutations";
import { getFlowTypePath } from "../../../CompanyCreationFlowType/currentFlowType";
import { ExecutionResult } from "graphql";

const toExternalDirectorDetails = (elem) => {
  return {
    id: elem.person?.id,
    title: elem.person?.title,
    firstName: elem.person?.firstName,
    lastName: elem.person?.lastName,
    phoneNumber: elem.person?.phoneNumber ?? "",
    salutationExtMD: SalutationValueEnum[elem.person?.salutation!],
    email: elem.person?.user?.email ?? ""
  };
};

const readExternalDirectorDetails = (myCompanyCreationDetails) => {
  const directorsInfo =
    (myCompanyCreationDetails && myCompanyCreationDetails.company?.group.memberships) ?? [];

  const directorPersonId = directorsInfo
    ?.filter((m) => m.role === RolesInGroup.Director)
    .map((elem) => elem.person.id);

  const shareholderPersonId = directorsInfo
    ?.filter((m) => m.role === RolesInGroup.Shareholder && !!m.person?.id)
    .map((elem) => elem.person.id);

  const externalDirectorPersonId = directorPersonId.filter(
    (id) => !shareholderPersonId.includes(id)
  );

  return (
    myCompanyCreationDetails &&
    myCompanyCreationDetails.company?.group?.memberships
      ?.filter((elem) => !!elem.person?.id && externalDirectorPersonId.includes(elem.person.id))
      ?.map(toExternalDirectorDetails)
  );
};

const convertValuesToDirector = (values) => ({
  id: values.id,
  title: values.title,
  email: values.email,
  firstName: values.firstName,
  lastName: values.lastName,
  phoneNumber: values.phoneNumber,
  salutation: SalutationType[SalutationValueEnum[values.salutationExtMD]]
});

const convertValuesToDirectorList = (values) => {
  return values.externalDirectors.map(convertValuesToDirector);
};

interface AdminServiceExternalDirectorFormContainerProps {
  t: TFunction;
  myCompanyCreationDetails?: MyCompanyCreationQuery_myCompanyCreation;
  resetActiveStep: (string) => void;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
  currentStep?: string;
}

export const AdminServiceExternalDirectorFormContainer = ({
  t,
  myCompanyCreationDetails,
  resetActiveStep,
  refetchMyCompanyCreation,
  currentFlowType,
  currentStep = "EMPLOYEE_MANAGING_DIRECTOR"
}: AdminServiceExternalDirectorFormContainerProps) => {
  const [updateEmployeeManagingDirector, { error, loading }] = useMutation<
    UpdateEmployeeManagingDirector,
    UpdateEmployeeManagingDirectorVariables
  >(UPDATE_EMPLOYEE_MANAGING_DIRECTOR);

  return (
    <AdminServiceExternalDirectorForm
      t={t}
      myCompanyCreationDetails={myCompanyCreationDetails}
      resetActiveStep={resetActiveStep}
      refetchMyCompanyCreation={refetchMyCompanyCreation}
      currentFlowType={currentFlowType}
      currentStep={currentStep}
      updateEmployeeManagingDirector={updateEmployeeManagingDirector}
      loading={loading}
      error={error}
    />
  );
};

interface AdminServiceExternalDirectorFormProps
  extends AdminServiceExternalDirectorFormContainerProps {
  updateEmployeeManagingDirector: (
    options?: MutationFunctionOptions<
      UpdateEmployeeManagingDirector,
      UpdateEmployeeManagingDirectorVariables
    >
  ) => Promise<ExecutionResult<UpdateEmployeeManagingDirector>>;
  loading: boolean;
  error?: ApolloError;
}

export const AdminServiceExternalDirectorForm = ({
  t,
  myCompanyCreationDetails,
  resetActiveStep,
  refetchMyCompanyCreation,
  currentFlowType,
  currentStep,
  updateEmployeeManagingDirector,
  loading,
  error
}: AdminServiceExternalDirectorFormProps) => {
  const companyCreationId = myCompanyCreationDetails?.id;
  const flowTypePath = getFlowTypePath(currentFlowType);

  const handleSubmit = async (values) => {
    if (!companyCreationId) {
      return;
    }

    try {
      const directors = convertValuesToDirectorList(values);

      await updateEmployeeManagingDirector({
        variables: {
          companyCreationId: companyCreationId,
          directors: directors,
          flowType: currentFlowType
        }
      });

      const { data } = await refetchMyCompanyCreation({
        id: companyCreationId
      });

      resetActiveStep(data.myCompanyCreation?.currentStep);
    } catch (error) {
      console.error(error);
      logger.error(error);
      throw new AssertionError({ message: "update External managing director not successful" });
    }
  };

  const directorList = readExternalDirectorDetails(myCompanyCreationDetails) ?? [];

  const stepOneFields = {
    salutationExtMD: SalutationValueEnum.Mr,
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
  };

  return (
    <div>
      <Row>
        <Col className="steps-form-description" data-testid="shareholder-form-description">
          {t("trader-gmbh:shareholder-form-description-admin-service-onboarding-ext-director")}
        </Col>
      </Row>
      <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
        <Container>
          <Row>
            <Col>
              <ExternalDirectorFormFields
                t={t}
                handleSubmit={handleSubmit}
                initialExternalDirectors={directorList}
                flowType={flowTypePath}
                error={error}
                loading={loading}
                currentStep={currentStep}
                stepOneFields={stepOneFields}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
