import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { DragDropProvider } from "../../../../../../lib/providers/DragDropProvider";
import { DragDropHandler } from "../../../../../../sharedComponents/DragDropHandler/DragDropHandler";
import { PlaceholderImage } from "./FileUploadArea";

interface FileUploadSectionProps extends WithTranslation {
  selectFile: (file: File) => Promise<void>;
  placeholderImageType?: PlaceholderImage | null;
  browserFileInsideDraggableArea?: boolean;
}

const moduleName = "upload-file-area";

export const FileUploadSection = withTranslationReady(["generic"])(
  ({
    t,
    selectFile,
    placeholderImageType,
    browserFileInsideDraggableArea
  }: FileUploadSectionProps) => {
    const [id] = useState(uniqueId("file-"));
    const inputRef = useRef<HTMLInputElement | null>(null);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
      const file = event.currentTarget?.files?.[0];

      if (file) {
        await selectFile(file);
      }

      if (inputRef.current) {
        inputRef.current.value = "";
      }
    };

    useEffect(() => {
      let listener = (e) => {
        e.preventDefault();
      };

      document.addEventListener("dragover", listener);
      document.addEventListener("drop", listener);

      return () => {
        document.removeEventListener("drop", listener);
        document.removeEventListener("dragover", listener);
      };
    }, []);

    const onDrop = async (files) => {
      await selectFile(files[0]);
    };

    return (
      <div data-testid="file-upload-section">
        <div
          className={`${moduleName}__file-upload-box ${
            browserFileInsideDraggableArea
              ? `${moduleName}__file-upload-box--inside-drag-and-drop`
              : ""
          }`}>
          <span className={`${moduleName}__drag-and-drop-info`}>
            {t("generic:ride-broker-onboarding.documents.upload-file.drag-and-drop-to-upload")}
          </span>
          {browserFileInsideDraggableArea && (
            <label
              htmlFor={id}
              data-testid="browse-files-button"
              className={`${moduleName}__browse-files-button ${
                browserFileInsideDraggableArea
                  ? `${moduleName}__browse-files-button--inside-drag-and-drop`
                  : ""
              }`}>
              {t("generic:browse-files")}
            </label>
          )}
          {placeholderImageType === PlaceholderImage.PASSPORT && (
            <div
              className={`${moduleName}__passport-example-image`}
              data-testid="passport-example-image"
            />
          )}
          {placeholderImageType === PlaceholderImage.ID_FRONT && (
            <div
              className={`${moduleName}__id-front-example-image`}
              data-testid="eu-id-front-example-image"
            />
          )}
          {placeholderImageType === PlaceholderImage.ID_BACK && (
            <div
              className={`${moduleName}__id-back-example-image`}
              data-testid="eu-id-back-example-image"
            />
          )}
          {placeholderImageType && (
            <div
              className={`${moduleName}__example-label-image`}
              data-testid="example-label-image"
            />
          )}

          <DragDropProvider>
            <DragDropHandler onDrop={onDrop}>
              {() => <div className={`${moduleName}__file-input`}></div>}
            </DragDropHandler>
          </DragDropProvider>
          <input
            id={id}
            ref={inputRef}
            name="file"
            type="file"
            data-testid="file-input"
            onChange={onChange}
            hidden
          />
        </div>
        {!browserFileInsideDraggableArea && (
          <label
            htmlFor={id}
            data-testid="browse-files-button"
            className={`${moduleName}__browse-files-button ${
              browserFileInsideDraggableArea
                ? `${moduleName}__browse-files-button--inside-drag-and-drop`
                : ""
            }`}>
            {t("generic:browse-files")}
          </label>
        )}
      </div>
    );
  }
);

export const UploadedFileSection = withTranslationReady(["generic"])(
  ({ t, children }: WithTranslation & PropsWithChildren<{}>) => {
    return (
      <div data-testid="uploaded-file-section" className={`${moduleName}__uploaded-file-section`}>
        <h6 className={`${moduleName}__section-title`}>
          {t("generic:ride-broker-onboarding.documents.upload-file.uploaded-file-title")}
        </h6>
        {children}
      </div>
    );
  }
);
