import { ClientSelectOption } from "../../ClientSelect/ClientSelect";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import TextAreaInput from "../../../../components/generic/TextAreaInput";

interface BrokersDropdownInterface {
  dropdownOptions: ClientSelectOption[];
  subtitle: string;
  textAreaSubtitle: string;
  selectOnChangeHandler: ({
    selectedDropdown,
    comment
  }: {
    selectedDropdown: ClientSelectOption;
    comment: string | null;
  }) => void;
}

export const BrokersDropdown = ({
  dropdownOptions,
  subtitle,
  textAreaSubtitle,
  selectOnChangeHandler
}: BrokersDropdownInterface) => {
  const [selectedBroker, setSelectedBroker] = useState(dropdownOptions[0]);
  const [textAreaValue, setTextAreaValue] = useState("");

  const selectChangeHandler = (option) => {
    setSelectedBroker(option ?? selectedBroker);
    selectOnChangeHandler({
      selectedDropdown: option,
      comment: selectedBroker.value === "anotherReason" ? textAreaValue : null
    });
  };
  const textAreaChangeHandler = (e) => {
    setTextAreaValue(e.target.value);
    selectOnChangeHandler({
      selectedDropdown: selectedBroker,
      comment: e.target.value
    });
  };

  useEffect(() => {
    selectChangeHandler(dropdownOptions[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="brokers-list-dropdown-container" data-testid="brokers-list-dropdown">
      <div className="brokers-list-dropdown-subtitle">{subtitle}</div>
      <div className="brokers-list-dropdown" data-testid="brokers-select-container">
        <ReactSelect
          testId="brokers-select"
          name="brokers"
          id="brokers"
          options={dropdownOptions}
          value={selectedBroker}
          onChange={(option) => selectChangeHandler(option)}
        />
      </div>
      {(selectedBroker.value === "anotherReason" || selectedBroker.value === "anotherBroker") && (
        <div data-testid="brokers-list-dropdown-text-area">
          <div className="brokers-list-dropdown-text-area-subtitle">{textAreaSubtitle}</div>
          <div className="brokers-list-dropdown-text-area">
            <TextAreaInput
              extra={{ className: "brokers-list-different-provider" }}
              name="text-area"
              placeholder="Type here"
              value={textAreaValue}
              onChange={textAreaChangeHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
};
