import React from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import TaxAdvisorClientsWrapper, {
  TaxAdvisorClientAssignment
} from "../TaxAdvisorClientsWrapper/TaxAdvisorClientsWrapper";
import { GetMyFormerClientAssignmentsQuery } from "lib/api/queries/GetMyFormerClientAssignmentsQuery";
import { FormerClientAssignmentType, GroupType, TaxAdvisorStatusEnum } from "global-query-types";

const TaxAdvisorFormerThirdPartyClientsContainer = () => {
  const clientsRequest = GetMyFormerClientAssignmentsQuery(
    FormerClientAssignmentType.WepaStandalone
  );

  const taxServices: TaxAdvisorClientAssignment[] = (
    clientsRequest.data?.getMyFormerClientAssignments ?? []
  ).map((item) => ({
    __typename: "TaxService",
    id: item.id,
    taxAdvisorStatus: TaxAdvisorStatusEnum.ACTIVE,
    endFiscalYear: item.endFiscalYear,
    client: item.client
      ? {
          __typename: "Company",
          id: item.client.id,
          clientNumber: item.client.clientNumber,
          brokerageAccounts: [
            { __typename: "BrokerageAccount", id: "irrelevant-for-former-clients" }
          ],
          deletedAt: null,
          fiscalYear: item.client.fiscalYear,
          name: item.client.name,
          legalForm: item.client.legalForm,
          thirdPartyTaxAdvisorClientNumber: null,
          group: {
            __typename: "Group",
            type: GroupType.Company,
            memberships: []
          }
        }
      : null
  }));

  return (
    <RequestFeedbackHandler requests={[clientsRequest]}>
      {taxServices && <TaxAdvisorClientsWrapper taxServices={taxServices} />}
    </RequestFeedbackHandler>
  );
};

export default TaxAdvisorFormerThirdPartyClientsContainer;
