import React, { useMemo } from "react";
import RideCardTable from "../../../sharedComponents/RideCardTable/RideCardTable";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { formatCompanyName } from "../../../common/formatters/CompanyNameFormatter";
import {
  GetMyTaxAdvisorCompanyWithTaxAdvisorClients_getMyTaxAdvisorCompanyWithTaxAdvisorClients_companies,
  GetMyTaxAdvisorCompanyWithTaxAdvisorClients_getMyTaxAdvisorCompanyWithTaxAdvisorClients_companies_taxServicesProvided_client
} from "../../graphql/GetMyTaxAdvisorCompanyWithTaxAdvisorClients";
import NoResultsPlaceholder from "../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";
import { useSimpleSortBehaviour } from "../../../sharedComponents/useSimpleSortBehaviour/useSimpleSortBehaviour";

interface TaxAdvisorThirdPartyTaxAdvisorCustomersProps extends WithTranslation {
  companies: GetMyTaxAdvisorCompanyWithTaxAdvisorClients_getMyTaxAdvisorCompanyWithTaxAdvisorClients_companies[];
}

type ThirdPartyCustomerRow = {
  company: GetMyTaxAdvisorCompanyWithTaxAdvisorClients_getMyTaxAdvisorCompanyWithTaxAdvisorClients_companies;
  client: GetMyTaxAdvisorCompanyWithTaxAdvisorClients_getMyTaxAdvisorCompanyWithTaxAdvisorClients_companies_taxServicesProvided_client;
};

const TaxAdvisorThirdPartyTaxAdvisorCustomers = ({
  companies,
  t
}: TaxAdvisorThirdPartyTaxAdvisorCustomersProps) => {
  const columns = [
    {
      name: "client",
      label: t("tax-advisor:thirdPartyPartners.table.clientCompany")
    },
    {
      name: "thirdPartyTaxAdvisor",
      label: t("tax-advisor:thirdPartyPartners.table.thirdPartyTaxAdvisors")
    }
  ];

  const rows: Array<ThirdPartyCustomerRow> = useMemo(() => {
    const result: Array<ThirdPartyCustomerRow> = [];
    companies.forEach((company) =>
      company.taxServicesProvided?.forEach((taxService) => {
        if (taxService.client && taxService.client.deletedAt === null) {
          result.push({
            company: company,
            client: taxService.client
          });
        }
      })
    );
    return result;
  }, [companies]);

  const { sortedData, sortCallback } = useSimpleSortBehaviour(rows);

  if (rows.length === 0)
    return <NoResultsPlaceholder text={t("tax-advisor:thirdPartyPartners.noResults")} />;

  return (
    <RideCardTable
      className="shadow-none mt-5"
      title={t("tax-advisor:thirdPartyPartners.table.title")}>
      <RideTableHeader isColumnsDisplay={true} columns={columns} sortCallback={sortCallback} />
      {sortedData.map((row, index) => (
        <RideTableRow key={`${row.company.id}-${index}-${row.client.id}`} colCount={columns.length}>
          <RideTableCell
            variant={RideTableCellVariant.primaryLink}
            link={`/tax-advisor/client-entities/${row.client.id}/third-party-tax-advisors-customers`}
            value={formatCompanyName(t, row.client)}
            classes="mobile-full"
          />
          <RideTableCell
            variant={RideTableCellVariant.text}
            value={formatCompanyName(t, row.company)}
            classes="mobile-full secondary-text-color"
          />
        </RideTableRow>
      ))}
    </RideCardTable>
  );
};

export default withTranslationReady("tax-advisor")(TaxAdvisorThirdPartyTaxAdvisorCustomers);
