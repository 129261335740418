import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { Container } from "react-bootstrap";

const WepaIsBeingPrepared = ({ t }: WithTranslation) => {
  return (
    <Container className="my-5">
      <div data-testid="wepa-service-in-progress">
        <RideAlertBar
          type={AlertType.INFO}
          message={t("generic:company-overview.wepa-section.wepa-service-is-being-prepared")}
        />
      </div>
    </Container>
  );
};

export default withTranslationReady(["generic"])(WepaIsBeingPrepared);
