import React from "react";
import { Field } from "formik";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";

interface RideRadioSelectProps {
  name: string;
  options: SelectOption[];
  label?: string;
  variant?: RadioSelectVariant;
  columns?: 1 | 2;
  className?: string;
}

export interface SelectOption {
  label: string;
  value: string;
}

export enum RadioSelectVariant {
  Heavy = "heavy",
  Light = "light"
}

export const RideRadioSelect = ({
  name,
  options,
  label,
  className,
  variant = RadioSelectVariant.Heavy,
  columns = 2
}: RideRadioSelectProps) => (
  <div
    data-testid={`${name}-radio-select`}
    className={`ride-radio-select ride-radio-select--${variant} ride-radio-select--${columns}-col ${className}`}>
    {label && (
      <label data-testid={`${name}-label`} className="ride-radio-select__label">
        {label}
      </label>
    )}
    <fieldset className="ride-radio-select__options-container">
      {options.map((option, i) => (
        <RadioOption key={i} name={name} option={option} />
      ))}
    </fieldset>
    <ErrorMessageWithT name={name} extra={{}} />
  </div>
);

interface RadioOptionProps {
  name: string;
  option: SelectOption;
}

const RadioOption = ({ name, option }: RadioOptionProps) => (
  <label data-testid={`${option.value}-radio-option`} className="ride-radio-select__option">
    <Field type="radio" name={name} value={option.value} />
    <span data-testid={`${option.value}-option-label`} className="ride-radio-select__option__label">
      {option.label}
    </span>
  </label>
);
