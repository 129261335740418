import { getAvailableShelfCompanies_getAvailableShelfCompanies_companies } from "../api/queries/graphql/getAvailableShelfCompanies";

export const companyToShelfCompany = (
  company: getAvailableShelfCompanies_getAvailableShelfCompanies_companies
) => {
  return {
    id: company.id,
    name: company.name,
    city: company.businessAddress.city,
    date: company.createdAt,
    brokerage: !!company.brokerageAccounts?.length,
    taxNumber: !!company.taxNumber,
    reserveAction: () => null
  };
};
