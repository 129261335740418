import AdminServiceProductCard from "client/components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceProductCard/AdminServiceProductCard";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { RideCheckbox } from "sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { Trans, WithTranslation } from "react-i18next";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { RideUrls } from "common/URLs";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import OnboardingStepCard, {
  EditableOnboardingStepCardProps,
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { AdminServiceDisabledTiers } from "../../../../common/getBlockedTiersForAdminService";

export interface ChoosePackageProps extends EditableOnboardingStepCardProps {
  stepNumber: number;
  preSelectedTier?: AdminServiceTier;
  updateTier: (tier: AdminServiceTier) => Promise<void>;
  status: OnboardingStepCardStatus;
  disabledOptions?: AdminServiceDisabledTiers;
}

const ChoosePackage = ({
  t,
  stepNumber,
  updateTier,
  status,
  disabledOptions,
  preSelectedTier,
  forceOpening,
  toggleEditing
}: ChoosePackageProps & WithTranslation) => {
  const formik = useFormik({
    initialValues: {
      termsAndConditionsCheckbox: false,
      adminService: preSelectedTier ?? undefined
    },
    onSubmit: async (values) => {
      if (!values["adminService"]) return;
      await updateTier(values["adminService"]);
    },
    validationSchema: ChoosePackageStepValidationSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <OnboardingStepCard
          number={stepNumber}
          data-testid={"choose-package"}
          title={t("generic:admin-service-tiers-onboarding.choose-package")}
          status={status}
          forceShowContent={forceOpening}
          toggleEditing={toggleEditing ? () => toggleEditing?.("tierSelection") : null}>
          <div className="as-onboarding-choose-package">
            <AdminServiceProductCard inputName={"adminService"} disabledOptions={disabledOptions} />
            <RideCheckbox
              name="termsAndConditionsCheckbox"
              formik={formik}
              shouldShowErrorFeedback={true}
              label={
                <Typography category="Paragraph" size={100} weight={"Light"}>
                  <Trans
                    i18nKey="generic:admin-service-tiers-onboarding.terms-and-conditions"
                    components={{
                      termsAndConditionsLink: (
                        <TextLink
                          to={RideUrls.TermsAndConditions}
                          variant={LinkVariant.secondary}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      )
                    }}
                  />
                </Typography>
              }
            />
            <RideButton
              type={"submit"}
              className={`admin-service-tiers-onboarding__order-with-costs`}
              data-testid={"admin-service-tiers-onboarding-select-package"}
              label={t("generic:admin-service-tiers-onboarding.select-package")}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
            />
          </div>
        </OnboardingStepCard>
      </Form>
    </FormikProvider>
  );
};

const ChoosePackageStepValidationSchema = (t) => {
  return Yup.object().shape({
    termsAndConditionsCheckbox: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};

export default withTranslationReady("generic")(ChoosePackage);
