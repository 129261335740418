import React from "react";
import { useParams } from "react-router-dom";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { GetMyTaxAdvisorCompanyWithClientsQuery } from "../../../taxAdvisor/Queries";
import ThirdPartyTaxAdvisorClientOverviewSection from "./ThirdPartyTaxAdvisorClientOverviewSection";
import { CompanyType } from "../../../global-query-types";
import { GetTaxAdvisorClientCompanyQuery } from "../../../lib/api/queries/GetTaxAdvisorClientCompanyQuery";

const ThirdPartyTaxAdvisorClientOverviewSectionContainer = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const getClientCompanyRequest = GetTaxAdvisorClientCompanyQuery(companyId);
  const getTaxAdvisorCompanyRequest = GetMyTaxAdvisorCompanyWithClientsQuery(
    CompanyType.ThirdPartyTaxService
  );

  const goBackLink = "/third-party-tax-advisor/clients";

  return (
    <RequestFeedbackHandler requests={[getClientCompanyRequest, getTaxAdvisorCompanyRequest]}>
      <ThirdPartyTaxAdvisorClientOverviewSection
        company={getClientCompanyRequest.data?.getTaxAdvisorClientCompany}
        myTaxAdvisorCompany={getTaxAdvisorCompanyRequest.data?.getMyTaxAdvisorCompanyWithClients}
        goBackLink={goBackLink}
      />
    </RequestFeedbackHandler>
  );
};

export default ThirdPartyTaxAdvisorClientOverviewSectionContainer;
