import { BankAccountPlan, BankType } from "../../global-query-types";
import { ClientSelectOption } from "../../client/components/ClientSelect/ClientSelect";
import { TFunction } from "react-i18next";

export const listBankAccountPlanOptions = (t: TFunction): ClientSelectOption[] =>
  ["", ...Object.keys(BankAccountPlan)].map((plan) => ({
    label: !!plan ? t(`client:banking.bank-account-plans.${plan}`) : "None",
    value: plan
  }));

export const listBankProviderOptions = (t: TFunction): ClientSelectOption[] =>
  Object.values(BankType).map((bankName) => ({
    label: t(`client:banking.banks.${bankName}`),
    value: bankName
  }));
