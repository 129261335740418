import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface ChapterSummaryProps extends WithTranslation {
  title: string;
  message?: string;
}

const ChapterSummary = ({ t, title, message }: ChapterSummaryProps) => {
  return (
    <div data-testid="chapter-summary" className="chapter-summary">
      <span data-testid="chapter-summary-title" className="chapter-summary__title">
        {title}
      </span>
      <span data-testid="chapter-summary-message" className="chapter-summary__message">
        {message ?? t("generic:ride-broker-onboarding.chapter-summary.message")}
      </span>
    </div>
  );
};

export default withTranslation(["generic"])(ChapterSummary);
