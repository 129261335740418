import React from "react";
import { AllNotaryPersons } from "./Queries";
import RequestFeedbackHandler from "../../generic/RequestFeedbackHandler";
import NotaryList from "./NotaryList";
import { useMutation } from "react-apollo";
import { CREATE_NOTARY } from "./Mutations";
import { NotaryLinkItem } from "../../../global-query-types";
import { CreateNotary, CreateNotaryVariables } from "./graphql/CreateNotary";
import { withTranslation, WithTranslation } from "react-i18next";

export const NotaryListContainer = ({ t }: WithTranslation) => {
  const [createNotaryMutation, createNotaryRequest] = useMutation<
    CreateNotary,
    CreateNotaryVariables
  >(CREATE_NOTARY);

  const allNotaryPersonsRequest = AllNotaryPersons();
  const handleAddNotary = async (
    email: string,
    firstName: string,
    lastName: string,
    links: NotaryLinkItem[]
  ) => {
    const variables = { email, firstName, lastName, links };
    await createNotaryMutation({ variables });
  };

  const refetch = async () => {
    await allNotaryPersonsRequest.refetch();
  };

  return (
    <RequestFeedbackHandler requests={[allNotaryPersonsRequest, createNotaryRequest]}>
      <NotaryList
        refetch={refetch}
        persons={allNotaryPersonsRequest.data?.allNotaryPersons ?? []}
        onAddNotary={handleAddNotary}
      />
    </RequestFeedbackHandler>
  );
};

export default withTranslation("header")(NotaryListContainer);
