import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  StepHeading,
  StepComponentProps
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { RideReadonlyText } from "uiLibrary/components/RideReadonlyText/RideReadonlyText";
import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { TypeOfInvestedCapitalDisclaimerSchema } from "lib/validation/TypeOfInvestedCapitalDisclaimerSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";

const TypeOfInvestedCapitalDisclaimerStep = ({
  t,
  order,
  currentStep,
  saveData,
  controls
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  async function onSubmit(values) {
    currentStep.complete();

    await saveData({
      ...order.extra,
      investedLivelihoodCapitalDisclaimerAccepted: values["investedCapitalTypeRiskAcknowledged"],
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {
      investedCapitalTypeRiskAcknowledged: order.extra?.investedLivelihoodCapitalDisclaimerAccepted
    },
    onSubmit,
    validationSchema: TypeOfInvestedCapitalDisclaimerSchema(t),
    validateOnBlur: true
  });

  return (
    <FormikProvider value={formik}>
      <Form className="type-of-invested-capital-disclaimer">
        <StepHeading
          text={t("generic:ride-broker-onboarding.type-of-invested-capital-disclaimer.title")}
        />
        <RideReadonlyText
          text={t("generic:ride-broker-onboarding.type-of-invested-capital-disclaimer.statement")}
        />
        <RideCheckbox
          name="investedCapitalTypeRiskAcknowledged"
          label={t(
            "generic:ride-broker-onboarding.type-of-invested-capital-disclaimer.accept-investedCapitalTypeRiskAcknowledged"
          )}
          className="type-of-invested-capital-disclaimer__accept-checkbox"
        />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(TypeOfInvestedCapitalDisclaimerStep);
