import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { AssetType } from "lib/models/client/LowTaxBroker/Assets";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const AssetsAvailableToTrade = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: WithTranslation & StepComponentProps) => {
  const history = useHistory();

  let assetExperiences: { [key: string]: string } = {};
  let limitedOptionsFuturesOrCFDs = false;

  Object.keys(order?.extra?.assetExperiences).forEach((key) => {
    let asset = order?.extra?.assetExperiences[key];
    limitedOptionsFuturesOrCFDs =
      (key === AssetType.OPTIONS || key === AssetType.FUTURES || key === AssetType.CFD) &&
      asset.knowledgeLevel === "limited";
    if (!limitedOptionsFuturesOrCFDs) {
      assetExperiences[key] = asset;
    }
  });

  const onSubmit = async () => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        assetExperiences,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  return (
    <div>
      <StepHeading
        dataTestId="assets-available-to-trade--title"
        text={t("generic:ride-broker-onboarding.assets-available-to-trade.title")}
      />
      {limitedOptionsFuturesOrCFDs && _.isEmpty(assetExperiences) && (
        <div
          data-testid="trading-limitations-information"
          className="trading-limitations-information">
          <div
            data-testid="trading-limitations-information-title"
            className="trading-limitations-information__title">
            {t(
              "generic:ride-broker-onboarding.assets-available-to-trade.trading-limitations.title"
            )}
          </div>
          <div data-testid="trading-limitations-information-message">
            {t(
              "generic:ride-broker-onboarding.assets-available-to-trade.trading-limitations.message"
            )}
          </div>
          <RideButtonDeprecated
            data-testid="trading-limitations-information-button"
            className="trading-limitations-information__button"
            variant={ButtonVariant.Primary}
            onClick={() =>
              history.replace(
                `/client/order/generic/compliance/2/${order.id}/${order.submissionId}`
              )
            }>
            {t(
              "generic:ride-broker-onboarding.assets-available-to-trade.trading-limitations.button"
            )}
          </RideButtonDeprecated>
        </div>
      )}
      {!_.isEmpty(assetExperiences) &&
        Object.keys(assetExperiences).map((asset) => (
          <span
            data-testid={`assets-available-to-trade--${asset}`}
            key={asset}
            className="asset__entry">
            {t(`generic:review-entry.low-tax-broker.${asset}`)}
          </span>
        ))}
      {{
        ...controls,
        props: { ...controls.props, onClick: onSubmit, disabled: _.isEmpty(assetExperiences) }
      }}
    </div>
  );
};

export default withTranslationReady(["generic"])(AssetsAvailableToTrade);
