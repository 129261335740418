import React from "react";
import { WithTranslation } from "react-i18next";
import { LegalForm } from "global-query-types";
import { companyName } from "lib/formatters/companyFormatter";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ClickableAreaVariant, IconVariant, RideTile } from "../RideTile/RideTile";
import { useHistory } from "react-router-dom";

export interface PendingTask {
  company: {
    id: string;
    name: string;
    legalForm: LegalForm;
  };
  ref: string;
}

interface PendingTaskProps extends WithTranslation {
  data: PendingTask[];
}

const PendingTasks = ({ t, data }: PendingTaskProps) => {
  return (
    <div data-testid="pending-tasks">
      {data.length ? (
        <>
          <span className="pending-tasks__count">
            {t("generic:to-do")} ({data.length})
          </span>
          {data.map((task) => (
            <TaskItem t={t} task={task} key={`${task.company.id}_${task.ref}`} />
          ))}
        </>
      ) : (
        <div className="pending-tasks__all_done">{t("generic:all-done")}</div>
      )}
    </div>
  );
};

export default withTranslationReady(["generic", "company-founding-steps"])(PendingTasks);

const TaskItem = ({ t, task }: { t: (key: string) => string; task: PendingTask }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/client/entities/${task.company.id}/home`);
  };

  return (
    <RideTile
      data-testid="task-item"
      className="pending-tasks__item"
      name={t(`company-founding-steps:tasks.name.${task.ref}`)}
      subTitleSlot={<span>{companyName(task.company)}</span>}
      iconVariant={IconVariant.arrowIcon}
      clickableAreaVariant={ClickableAreaVariant.entireTile}
      onClick={handleClick}
    />
  );
};
