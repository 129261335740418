import React from "react";
import { Typography } from "../../../uiLibrary/designSystem/styles/Typography/Typography";
import { getAllAccountants_getAllAccountants } from "../../../components/nominee/Accountant/graphql/getAllAccountants";
import _ from "lodash";

interface AccountantListProps {
  accountants: getAllAccountants_getAllAccountants[] | null | undefined;
}
export const AccountantList = ({ accountants }: AccountantListProps) => {
  const [accountantsConfirmed, accountantsPending] = _.partition(accountants, function (u) {
    return !!u.confirmedAt;
  });
  return (
    <div>
      <div>
        <Typography category={"Headline"} size={300} weight={"Heavy"}>
          Accountant List
        </Typography>
      </div>
      {accountants?.length === 0 && <>No Accountants</>}
      {accountantsConfirmed.length > 0 && (
        <>
          <Typography category={"Paragraph"} size={300} weight={"Heavy"}>
            Accountants activated in the platform
          </Typography>
          {accountantsConfirmed?.map((accountant, index) => {
            return (
              <div>
                {accountant.person?.firstName} {accountant.person?.lastName} - {accountant.email} :
                Activated at {accountant.confirmedAt}
              </div>
            );
          })}{" "}
        </>
      )}

      {accountantsPending.length > 0 && (
        <>
          <Typography category={"Paragraph"} size={300} weight={"Heavy"}>
            Accountants missing confirm their e-mails
          </Typography>
          {accountantsPending?.map((accountant, index) => {
            return <div>{accountant.email}</div>;
          })}
        </>
      )}
    </div>
  );
};
