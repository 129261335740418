import { CompanyById_companyById_orders } from "../lib/api/queries/graphql/CompanyById";
import { GmbHDetails } from "../lib/models/client/GmbHDetails";
import { OrderCompanyStatusEnum, OrderProductTypeEnum } from "../global-query-types";
import { GrundungServiceStatusEnum } from "../lib/models/client/GmbH";

export interface PreliminaryTaskReturn {
  showTask: boolean;
  addExistingCompanyOrder: CompanyById_companyById_orders | undefined;
}

export const checkForPreliminaryTask = (
  gmbh: GmbHDetails,
  bankAccountTaskCompleted: boolean | undefined = false
): PreliminaryTaskReturn => {
  const addExistingCompanyOrderInProgress = gmbh.orders.find(
    (order) =>
      order.productType === OrderProductTypeEnum.AddExistingCompany &&
      order.status === OrderCompanyStatusEnum.InProgress
  );

  const isGrundungService =
    !!gmbh.orders.find((order) => order.productType === OrderProductTypeEnum.CompanyFoundation) ||
    gmbh.grundungServiceStatus !== GrundungServiceStatusEnum.NOT_ACQUIRED;

  return {
    showTask:
      !!addExistingCompanyOrderInProgress || (isGrundungService && !bankAccountTaskCompleted),
    addExistingCompanyOrder: addExistingCompanyOrderInProgress
  };
};
