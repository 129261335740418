import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { ArrowRight } from "sharedComponents/icons/DesignSystemIcons/ArrowRight";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { InvoiceSource, OrderProductTypeEnum } from "global-query-types";
import { MigrateAdminServicePackageData } from "lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { capitalize } from "lodash";

export interface AdminServicePackageUpdateSuccessProps {
  gmbh: GmbHDetails;
  goToAdminServiceView: () => void;
}

const AdminServicePackageUpdateSuccess = ({
  t,
  gmbh,
  goToAdminServiceView
}: AdminServicePackageUpdateSuccessProps & WithTranslation) => {
  const migrateASPackageOrder = gmbh.orders.find(
    (order) => order.productType === OrderProductTypeEnum.MigrateAdminServicePackage
  ) as OrderData<MigrateAdminServicePackageData> | undefined;

  const invoicedBySteueragenten = gmbh.adminService?.invoicedBy === InvoiceSource.TaxAdvisor;

  return (
    <div
      data-testid="admin-service-package-update-success"
      className={"admin-service-package-update-success"}>
      <div className={"admin-service-package-update-success__title"}>
        <Typography category={"Headline"} size={200} weight={"Heavy"} data-testid={"title"}>
          {t("generic:migrate-admin-service-package.update-success.title")}
        </Typography>
        <Typography
          data-testid={"message"}
          category={"Paragraph"}
          size={100}
          weight={"Light"}
          className={"admin-service-package-update-success__message"}>
          {t(
            `generic:migrate-admin-service-package.update-success.${
              invoicedBySteueragenten ? "steueragenten-" : ""
            }message`,
            {
              selectedPackage: capitalize(migrateASPackageOrder?.extra.tier ?? "")
            }
          )}
        </Typography>
      </div>
      <RideButton
        data-testid={"go-to-admin-service-tab-button"}
        label={t(
          "generic:migrate-admin-service-package.update-success.go-to-admin-service-tab-button"
        )}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        rightIcon={<ArrowRight />}
        onClick={goToAdminServiceView}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(AdminServicePackageUpdateSuccess);
