import gql from "graphql-tag";

export const CREATE_FILE_DOWNLOAD_URL = gql`
  mutation CreateFileDownloadURL(
    $fileId: ID!
    $entityType: StorageNodesEntityType!
    $entityId: ID!
  ) {
    createFileDownloadURL(fileId: $fileId, entityType: $entityType, entityId: $entityId)
  }
`;
