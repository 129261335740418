import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { ViewAdminServiceSteps } from "./Steps/ViewAdminServiceSteps";
import LoadingAlert from "../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { Redirect, useParams } from "react-router";
import { GetMyCompanyCreation } from "../CompanyCreation/Queries";
import { MyCompanyCreationQuery_myCompanyCreation } from "../CompanyCreation/graphql/MyCompanyCreationQuery";
import { CompanyCreationStep, FlowTypeEnum } from "../../../global-query-types";
import { getFlowTypePath } from "../CompanyCreationFlowType/currentFlowType";
import { AdminServiceOnboarding } from "./Onboarding/AdminServiceOnboarding";
import { adminService, adminServiceOnboarding } from "../CompanyCreation/getCompletedSteps";
import { DataLayer } from "../../../lib/services/GoogleTagManager/GoogleTagManager";

interface AdminServicePageProps {
  t: TFunction;
  currentFlowType: FlowTypeEnum;
}

export const AdminServicePage = ({ t, currentFlowType }: AdminServicePageProps) => {
  const { id } = useParams<{ id: string }>();
  const [showCreationSteps, setShowCreationSteps] = useState(true);

  const { data, error, loading, refetch } = GetMyCompanyCreation(id);

  if (data?.myCompanyCreation?.adminCompany) {
    data.myCompanyCreation.company = data?.myCompanyCreation?.adminCompany;
  }

  const flowType = getFlowTypePath(currentFlowType);

  const handleChange = () => {
    setShowCreationSteps(!showCreationSteps);

    if (currentFlowType === FlowTypeEnum.ADMIN_SERVICE) {
      DataLayer.VirtualPageViewWithinFlow({
        stepName: adminService.STEP_ONE,
        flowType
      });
    }

    if (currentFlowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING) {
      DataLayer.VirtualPageViewWithinFlow({
        stepName: adminServiceOnboarding.STEP_ONE,
        flowType
      });
    }
  };

  if (loading) return <LoadingAlert t={t} message={t("generic:loading-message")} />;
  if (error) return <ErrorAlertLegacy t={t} error={error.message} />;

  const myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation | null | undefined =
    data?.myCompanyCreation;

  const currentStep = myCompanyCreationDetails?.currentStep || "";

  if (currentStep === CompanyCreationStep.EMAIL_CONFIRMED) {
    return <Redirect to={`/${flowType}/${myCompanyCreationDetails?.id}/status/confirmed`} />;
  }

  if (currentStep === CompanyCreationStep.ORDERED) {
    return <Redirect to={`/${flowType}/${myCompanyCreationDetails?.id}/status`} />;
  }

  if (currentStep === CompanyCreationStep.ONBOARDED) {
    return <Redirect to={`/${flowType}/${myCompanyCreationDetails?.id}/status`} />;
  }

  if (currentFlowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING) {
    return (
      <Container className="mb-5">
        <AdminServiceOnboarding
          t={t}
          handleChange={handleChange}
          showCreationSteps={showCreationSteps}
          currentStep={currentStep}
          myCompanyCreationDetails={myCompanyCreationDetails}
          refetch={refetch}
          currentFlowType={currentFlowType}
        />
      </Container>
    );
  }

  return (
    <Container className="mb-5">
      <Row className="">
        <Col xs={12} sm={6} md={4}>
          <Button
            data-testid="start-now"
            block
            onClick={handleChange}
            hidden={!showCreationSteps || currentStep.length > 0}
            className="start-now-btn">
            {t("generic:start-now")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex pl-5">
          <ViewAdminServiceSteps
            t={t}
            showCreationSteps={showCreationSteps}
            currentStep={currentStep}
            myCompanyCreationDetails={myCompanyCreationDetails!}
            refetchMyCompanyCreation={refetch}
            currentFlowType={currentFlowType}
          />
        </Col>
      </Row>
    </Container>
  );
};
