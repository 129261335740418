import React, { useState } from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { StorageNodeType } from "../../../../global-query-types";
import { getEndpoint } from "../../../../common/GraphqlClient/httpLink";
import { getToken } from "../../../../common/Authentication/getToken";
import {
  LocalStorageNode,
  RemoteStorageNode,
  toRemoteStorageNode
} from "../../../../lib/dataAdapters/toRemoteStorageNode";
import FileNode from "../../../../sharedComponents/File/FileNode";
import FolderNode from "../../../../sharedComponents/FolderNode/FolderNode";
import { LinkVariant, TextLink } from "../../../../sharedComponents/TextLink/TextLink";

interface KnowledgeDocuments extends WithTranslation {
  knowledgeDocumentsData: LocalStorageNode[];
  clientCommitted: boolean;
}

export const KnowledgeDocumentsSection = ({
  knowledgeDocumentsData,
  clientCommitted,
  t
}: KnowledgeDocuments) => {
  if (knowledgeDocumentsData.length === 0) {
    return (
      <div data-testid="no-documents-folder-found">{t("generic:create-document-storage")}</div>
    );
  }

  return (
    <div
      className="knowledge__documents-list node-tree__list node-tree__list--level-0 mb-35"
      data-testid="knowledge-documents-list">
      {!clientCommitted && (
        <KnowledgeDocumentsLocked
          lockedMessage={t("client:knowledge.knowledge-documents-locked")}
        />
      )}
      <div className="text-secondary my-35 pl-35">
        {t("client:knowledge.knowledge-documents-subtitle")}
      </div>
      {knowledgeDocumentsData.map((node, index) => (
        <KnowledgeDocumentsFolder node={node} key={index} clientCommitted={clientCommitted} />
      ))}
    </div>
  );
};

const KnowledgeDocumentsLocked = ({ lockedMessage }: { lockedMessage: string }) => (
  <div data-testid="knowledge-documents-locked" className="knowledge__documents-locked">
    <span className="knowledge__documents-locked__text">{lockedMessage}</span>
  </div>
);

const KnowledgeDocumentsFolder = ({
  node,
  clientCommitted
}: {
  node: LocalStorageNode;
  clientCommitted: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  const childNodes = node?.childNodes ?? [];
  const parentId = `${node.nodeId} ?? ""`;

  const onToggleExpand = () => setExpanded(!expanded);
  return (
    <div className="node-tree__folder" data-testid={`node-${node.nodeId}`}>
      <FolderNode
        node={toRemoteStorageNode(node, "")}
        readOnly
        expanded={expanded}
        onToggleExpand={onToggleExpand}
      />

      <div className="mt-2">
        {expanded &&
          childNodes
            .filter((childNode) => childNode.type === StorageNodeType.FILE)
            .map((childNode, index) => (
              <KnowledgeDocumentsFile
                node={toRemoteStorageNode(childNode, parentId)}
                key={index}
                clientCommitted={clientCommitted}
              />
            ))}
      </div>
    </div>
  );
};

const KnowledgeDocumentsFile = ({
  node,
  clientCommitted
}: {
  node: RemoteStorageNode;
  clientCommitted: boolean;
}) => {
  const token = getToken();
  const href = clientCommitted
    ? `${getEndpoint()}/api/client/storage/knowledge/${node.nodeId}?apiToken=${token}`
    : "#";

  return (
    <TextLink
      to={href}
      className="ml-5 d-block"
      variant={LinkVariant.info}
      data-testid={`node-${node.nodeId}`}>
      <FileNode node={node} readOnly />
    </TextLink>
  );
};
export default withTranslationReady(["client", "generic"])(KnowledgeDocumentsSection);
