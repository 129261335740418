import React, { useState } from "react";
import { FolderSelectOption } from "../FolderSelectField/FolderSelectField";
import UploadFileList, { FilePlacementProps } from "../UploadFileList/UploadFileList";
import { TFunction, WithTranslation } from "react-i18next";
import FileMultiSelectionHeader from "../FileMultiSelectionHeader/FileMultiSelectionHeader";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";

export interface MultipleFoldersSelectionProps extends WithTranslation {
  files: FilePlacementProps[];
  options: FolderSelectOption[];
  validationErrors: (string | undefined)[];
  onFolderSelectSome: (indexes: number[], folder?: FolderSelectOption) => void;
}

const MultipleFoldersSelection = ({
  files,
  options,
  validationErrors,
  onFolderSelectSome,
  t
}: MultipleFoldersSelectionProps) => {
  const [fileList, setFileList] = useState(files);
  const selectedCount = fileList.filter((f) => f.isSelected).length;

  const onFileSelect = (fileName: string, isSelected: boolean) => {
    const shallowCopy = [...fileList];

    shallowCopy.forEach((file, index) => {
      if (file.name === fileName) {
        shallowCopy[index] = { ...file, isSelected };
      }
    });

    setFileList(shallowCopy);
  };

  const applyDestinationFolder = (folder: FolderSelectOption | undefined): void => {
    const shallowCopy = [...fileList];

    const indexes: number[] = [];
    shallowCopy.forEach((file, index) => {
      if (file.isSelected) {
        indexes.push(index);
        shallowCopy[index] = {
          ...file,
          destinationFolderValue: folder?.value ?? "",
          isSelected: false
        };
      }
    });
    onFolderSelectSome(indexes, folder);

    setFileList(shallowCopy);
  };

  const applySelectionToAll = (isSelected: boolean) => {
    const shallowCopy = [...fileList];

    shallowCopy.forEach((file, index) => {
      shallowCopy[index] = { ...file, isSelected };
    });

    setFileList(shallowCopy);
  };
  const onSelectAll = () => applySelectionToAll(true);
  const onSelectNone = () => applySelectionToAll(false);

  const onFolderSelect = (index, folder) => onFolderSelectSome([index], folder);

  return (
    <div className="multiple-folders-selection">
      {selectedCount === 0 ? (
        <FileUploadHeader t={t} data-testid="upload-header-default" />
      ) : (
        <FileMultiSelectionHeader
          selectedCount={selectedCount}
          total={fileList.length}
          options={options}
          onSelectAll={onSelectAll}
          onSelectNone={onSelectNone}
          onChooseOption={applyDestinationFolder}
          data-testid="upload-header-multiple-files"
        />
      )}

      <UploadFileList
        files={fileList}
        options={options}
        isDisabled={selectedCount > 0}
        validationErrors={validationErrors}
        onFileSelect={onFileSelect}
        onFolderSelect={onFolderSelect}
        data-testid="upload-file-list"
      />
    </div>
  );
};

const FileUploadHeader = ({
  t,
  "data-testid": testId
}: {
  t: TFunction;
  "data-testid": string;
}) => (
  <div className="multiple-folders-selection-header" data-testid={testId}>
    <div className="multiple-folders-selection-header__info">
      {t("client:documents.modal.header.info")}
    </div>
    <div className="multiple-folders-selection-header__hint">
      {t("client:documents.modal.header.hint")}
    </div>
  </div>
);

export default withTranslationReady(["client"])(MultipleFoldersSelection);
