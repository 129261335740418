import gql from "graphql-tag";

export const GET_MY_ACCOUNTING_CLIENTS = gql`
  query GetMyAccountingClients($searchTerm: String, $start: Int, $limit: Int) {
    getMyAccountingClients(searchTerm: $searchTerm, start: $start, limit: $limit) {
      items {
        id
        client {
          id
          clientNumber
          thirdPartyTaxAdvisorClientNumber
          fiscalYear
          name
          legalForm
          brokerageAccounts {
            id
          }
        }
      }
      count
    }
  }
`;
