import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  FullPersonDetailsQuery,
  FullPersonDetailsQueryVariables
} from "./graphql/FullPersonDetailsQuery";

export const FULL_PERSON_DETAILS_QUERY = gql`
  query FullPersonDetailsQuery($id: ID!) {
    getPersonDetails(id: $id) {
      id

      user {
        id
        email
        confirmedAt
        signupTokenExpiryAt
        invitedAt
        hasFinApiAccount
        role
        hasTwoFactorAuth
        allowTwoFactorAuthPrompt
      }

      phoneNumber
      salutation
      title
      firstName
      lastName

      address {
        street
        streetLine2
        postalCode
        city
        country
      }

      personalTaxId
      taxOffice
      taxNumber

      dateOfBirth
      citizenship
      placeOfBirth
      birthName
      documentType
      documentNumber
      issuingAuthority
      locked
      isPartner

      userWallet {
        id
        tanganyWallets {
          id
          wallet
          walletProviderId
          type
          ownerCompany {
            id
          }
        }
      }

      memberships {
        id
        role
        person {
          id
          kycEntries {
            id
            status
            company {
              id
            }
          }
        }
        share {
          share
        }
        group {
          type
          company {
            id
            name
            legalForm
            deletedAt
            kycEntries {
              id
              status
              company {
                id
              }
            }
          }
        }
      }

      kycEntries {
        id
        type
        startedAt
        completedAt
        status
        incompleteReason
      }

      notaryLinks {
        id
        link
        notaryDocumentType
      }

      externalStorageNodes
    }
  }
`;
export const GetFullPersonDetails = (id: string) =>
  useQuery<FullPersonDetailsQuery, FullPersonDetailsQueryVariables>(FULL_PERSON_DETAILS_QUERY, {
    variables: { id: id }
  });
