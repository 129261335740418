import React from "react";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import DocuSignTask from "./DocuSignTask";
import { GetLinksByCompanyId } from "../../../../../lib/api/queries/GetLinksByCompanyId";
import { useParams } from "react-router";
import RequestFeedbackHandler from "../../../../../components/generic/RequestFeedbackHandler";
import { TaskStatesEnum } from "../../../../../global-query-types";

interface DocuSignTaskContainerProps {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

export const DocuSignTaskContainer = ({ task, onChange }: DocuSignTaskContainerProps) => {
  const { id }: { id: string } = useParams();

  const getLinksByCompanyIdRequest = GetLinksByCompanyId(id);

  return (
    <RequestFeedbackHandler requests={[getLinksByCompanyIdRequest]}>
      <DocuSignTask
        task={task}
        links={getLinksByCompanyIdRequest.data?.getLinksByCompanyId?.links ?? []}
        onChange={onChange}
      />
    </RequestFeedbackHandler>
  );
};
