import localStore from "../LocalStore";
import { KEY_TOKEN, KEY_TOKEN_REMEMBER, ONE_MONTH } from "./constants";

export const storeToken = (token: string, remember: boolean = false) => {
  localStore.setValue(KEY_TOKEN_REMEMBER, JSON.stringify(remember));

  if (!remember) {
    return localStore.setSessionValue(KEY_TOKEN, token);
  }

  localStore.setExpiringValue(KEY_TOKEN, token, ONE_MONTH);
};

export const updateToken = (token: string | null) => {
  if (!token) {
    localStore.removeValue(KEY_TOKEN);
    localStore.removeValue(KEY_TOKEN_REMEMBER);
    return;
  }

  const remember = ["true", true].indexOf(localStore.getValue(KEY_TOKEN_REMEMBER)) !== -1;
  storeToken(token, !!remember);
};
