// from https://stackoverflow.com/questions/54487137/how-to-recursively-omit-key-from-type
type OmitDistributive<T, K extends PropertyKey> = T extends any
  ? T extends object
    ? Id<OmitRecursively<T, K>>
    : T
  : never;
type Id<T> = {} & { [P in keyof T]: T[P] }; // Cosmetic use: only makes the tooltips expand the type; can be removed
type OmitRecursively<T extends any, K extends PropertyKey> = Omit<
  { [P in keyof T]: OmitDistributive<T[P], K> },
  K
>;

export type WithoutTypenames<T> = OmitRecursively<T, "__typename">;

export function removeTypenames<T>(data: T): WithoutTypenames<T> {
  if (!data || typeof data !== "object") {
    return data as any;
  }

  const result = { ...data };
  delete result["__typename"];

  for (const key of Object.keys(result)) {
    result[key] = removeTypenames(result[key]) as any;
  }

  return result as any;
}
