import React from "react";
import { myInvestments_myInvestments } from "../../../../lib/api/queries/graphql/myInvestments";
import InvestmentList from "./InvestmentList/InvestmentList";
import NoResultsPlaceholder from "../../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

interface InvestmentsListWrapperProps extends WithTranslation {
  investments: myInvestments_myInvestments[];
  onSelectInvestment: (id: string) => void;
}

const InvestmentsListWrapper = ({
  investments,
  onSelectInvestment,
  t
}: InvestmentsListWrapperProps) => {
  return (
    <div className="investment-list-wrapper">
      {!investments?.length ? (
        <NoResultsPlaceholder text={t("client:investments.noInvestments")} />
      ) : (
        <InvestmentList onSelectInvestment={onSelectInvestment} investments={investments} />
      )}
    </div>
  );
};

export default withTranslationReady("client")(InvestmentsListWrapper);
