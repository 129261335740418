import React from "react";
import BankAccountDetailsHeaderSection from "../BankAccountDetailsHeaderSection/BankAccountDetailsHeaderSection";
import { BankType } from "../../../../global-query-types";
import { getBankAccountDetailsById_getBankAccountDetailsById_transactions } from "../../../../lib/api/queries/graphql/getBankAccountDetailsById";
import BankAccountTransactionCard from "../BankAccountTransactionCard/BankAccountTransactionCard";
import { formatValue } from "../../../../common/formatters";
import { Container } from "react-bootstrap";

interface BankAccountDetailsProps {
  balance: number | null;
  transactions: getBankAccountDetailsById_getBankAccountDetailsById_transactions[];
}

const BankAccountDetails = ({ balance, transactions }: BankAccountDetailsProps) => {
  return (
    <>
      <BankAccountDetailsHeaderSection
        bank={BankType.Swan}
        name={"Mario's Bank Account"}
        balance={formatValue(balance)}
        iban={"iban"}
        companyName={"company"}
      />
      <br />
      <Container>
        {transactions.map((transaction) => (
          <BankAccountTransactionCard
            key={transaction.id}
            balance={transaction.amount?.toString() || ""}
            name={transaction.counterpart ?? ""}
            date={transaction.timestamp ?? ""}
          />
        ))}
      </Container>
      <br />
    </>
  );
};
export default BankAccountDetails;
