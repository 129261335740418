import React from "react";
import { IconProps } from "../../components/common/icons/IconProps";

export const PrivateInvestment = ({ size = 26 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="13" r="12.5" fill="currentColor" />
    <path
      d="M17 17.5V16.5C17 15.9696 16.7629 15.4609 16.341 15.0858C15.919 14.7107 15.3467 14.5 14.75 14.5H10.25C9.65326 14.5 9.08097 14.7107 8.65901 15.0858C8.23705 15.4609 8 15.9696 8 16.5V17.5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 12.5C13.8807 12.5 15 11.3807 15 10C15 8.61929 13.8807 7.5 12.5 7.5C11.1193 7.5 10 8.61929 10 10C10 11.3807 11.1193 12.5 12.5 12.5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
