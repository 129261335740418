import React from "react";
import { PageLayout } from "../../../sharedComponents/templates/PageLayout/PageLayout";
import { withTranslation, WithTranslation } from "react-i18next";
import ShelfCompanyOrderContainer from "../../components/ShelfCompanyOrder/ShelfCompanyOrderContainer";

const ShelfCompanyOrderScene = ({ t }: WithTranslation) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={t("shelf-companies-orders")}
      mainContent={<ShelfCompanyOrderContainer />}
    />
  );
};

export default withTranslation("header")(ShelfCompanyOrderScene);
