import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { WithTranslation, withTranslation } from "react-i18next";
import { Image } from "react-bootstrap";

interface RenderPhotosFromS3Props extends WithTranslation {
  imageData: string[];
}

const carouselInstance = (data: string[]) => (
  <Carousel>
    {data.map((image, idx) => (
      <Carousel.Item key={idx} className="custom-carousel-item">
        <div>
          <Image src={image} thumbnail data-testid="carousel-image" />
        </div>
      </Carousel.Item>
    ))}
  </Carousel>
);

export const RenderPhotosFromS3: React.FC<RenderPhotosFromS3Props> = (props) => {
  const { imageData } = props;

  return <>{carouselInstance(imageData)}</>;
};

export default withTranslation(["generic"])(RenderPhotosFromS3);
