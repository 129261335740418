import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProps, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { AssetTypes } from "lib/types/types";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import * as Yup from "yup";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";

const InvestingYearsPerAreaSchema = (t, assetsOptions: AssetTypes[]) => {
  const yearsTrading = {};

  assetsOptions.forEach((asset) => {
    yearsTrading[asset] = Yup.number()
      .required(t("generic:validation-required"))
      .integer(
        t("generic:ride-broker-onboarding.investing-years-per-areas.investment-years-validation")
      )
      .min(
        0,
        t("generic:ride-broker-onboarding.investing-years-per-areas.investment-years-validation")
      )
      .max(
        30,
        t("generic:ride-broker-onboarding.investing-years-per-areas.investment-years-validation")
      );
  });

  return Yup.object().shape(yearsTrading);
};

const InvestingYearsStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps & WithTranslation) => {
  let initialValues = {};
  const assetsOptions =
    Object.values(AssetTypes).filter((asset) => {
      if (order?.extra?.assetExperiences?.[asset]) {
        initialValues[asset] = order?.extra?.assetExperiences?.[asset]?.yearsTrading ?? undefined;
      }

      return order?.extra?.assetExperiences?.[asset];
    }) ?? [];

  const onSubmit = async () => {
      currentStep.complete();

      let assetExperiences = {};

      assetsOptions.forEach((asset) => {
        assetExperiences[asset] = {
          ...order.extra.assetExperiences[asset],
          yearsTrading: formik.values[asset]
        };
      });

      await saveData(
        {
          ...order.extra,
          assetExperiences,
          ...currentStep.chapter.order.serialize()
        },
        false
      );
    },
    formik = useRideFormik({
      initialValues,
      onSubmit: onSubmit,
      validateOnBlur: true,
      validationSchema: InvestingYearsPerAreaSchema(t, assetsOptions)
    });

  return (
    <FormikProvider value={formik}>
      <Form>
        <InvestingYearsPerArea assetOptionsSelected={assetsOptions} formik={formik} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

interface InvestingYearsPerAreaProps extends WithTranslation {
  assetOptionsSelected: AssetTypes[];
  formik: FormikProps<any>;
}

const InvestingYearsPerArea = withTranslationReady(["generic"])(
  ({ t, formik, assetOptionsSelected }: InvestingYearsPerAreaProps) => {
    return (
      <div data-testid="investing-years-per-areas">
        <StepHeading text={t("generic:ride-broker-onboarding.investing-years-per-areas.title")} />
        {assetOptionsSelected.map((investmentArea) => (
          <RideInput
            type="number"
            formik={formik}
            key={investmentArea}
            name={investmentArea}
            label={t(`generic:ride-broker-onboarding.investment-options.${investmentArea}`)}
            placeholder={t(
              "generic:ride-broker-onboarding.investing-years-per-areas.investment-years-placeholder"
            )}
            className="investing-years-per-areas__input"
          />
        ))}
      </div>
    );
  }
);

export default withTranslation(["generic"])(InvestingYearsStep);
