import { ValueFormatter } from "./ValueFormatter";
import { GermanDate } from "../serializers/GermanDate";

export class DateFormatter extends ValueFormatter {
  format(value, ...args) {
    if (value) {
      return new GermanDate(value, this.defaultValue).toString();
    }
    return super.format(value, ...args);
  }
}
