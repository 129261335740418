import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface NomineeDashboardProps extends WithTranslation {}

export class NomineeDashboard extends React.Component<NomineeDashboardProps> {
  render() {
    return <h3>Nominee Dashboard</h3>;
  }
}

export default withTranslation("nominee-dashboard")(NomineeDashboard);
