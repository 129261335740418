import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { WithTranslation } from "react-i18next";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import AdminServiceProductCard from "client/components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceProductCard/AdminServiceProductCard";
import * as Yup from "yup";
import { withTranslationReady } from "common/i18n/withTranslationReady";

export interface ChoosePackageCardProps {
  stepNumber: number;
  defaultTier?: AdminServiceTier;
  selectPackage: (tier: AdminServiceTier) => Promise<void>;
  status: OnboardingStepCardStatus;
  isTradingCompany: boolean;
}

const ChoosePackageCard = ({
  t,
  stepNumber,
  defaultTier,
  selectPackage,
  status,
  isTradingCompany
}: ChoosePackageCardProps & WithTranslation) => {
  const formik = useFormik({
    initialValues: defaultTier ? { adminService: defaultTier } : {},
    onSubmit: async (values) => {
      await selectPackage(values["adminService"]!);
    },
    validationSchema: Yup.object().shape({
      adminService: Yup.string()
        .oneOf(Object.values(AdminServiceTier))
        .required(t("generic:validation-required"))
    })
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <OnboardingStepCard
          number={stepNumber}
          data-testid={"choose-package"}
          title={t("generic:migrate-admin-service-package.steps.choose-package.title")}
          status={status}>
          <div className={"migrate-admin-service-choose-package-card"}>
            <AdminServiceProductCard
              inputName={"adminService"}
              disabledOptions={
                isTradingCompany
                  ? {
                      disabledTiers: [AdminServiceTier.Lite],
                      tooltipTextKey: "generic:product-card.admin-service.tooltip"
                    }
                  : undefined
              }
            />
            <RideButton
              type={"submit"}
              className={`admin-service-tiers-onboarding__order-with-costs`}
              data-testid={"order-with-costs-button"}
              label={t("generic:confirm-package-selection")}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
              disabled={!formik.values["adminService"]}
            />
          </div>
        </OnboardingStepCard>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady("generic")(ChoosePackageCard);
