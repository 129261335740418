import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

interface PtaSwitchOfferPartnerBannerProp extends WithTranslation {
  onClick: () => void;
}
const PtaSwitchOfferPartnerBanner = ({ t, onClick }: PtaSwitchOfferPartnerBannerProp) => {
  return (
    <div
      data-testid="pta-switch-offer-partner-banner"
      className={"pta-switch-offer-partner-banner"}>
      <Typography
        category={"Paragraph"}
        size={200}
        weight={"Light"}
        className={"pta-switch-offer-partner-banner__content"}>
        <span data-testid="title" className="pta-switch-offer-partner-banner__title">
          {t("generic:steuragenter-customer-migration.banner.title")}
        </span>
        <span data-testid="message">
          <Trans i18nKey={t("generic:steuragenter-customer-migration.banner.message")} />
        </span>
      </Typography>
      <RideButton
        size={RideButtonSize.SMALL}
        variant={RideButtonVariant.SECONDARY}
        className="pta-switch-offer-partner-banner__button ml-5"
        data-testid={"button"}
        label={t("generic:steuragenter-customer-migration.banner.button")}
        onClick={onClick}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(PtaSwitchOfferPartnerBanner);
