import { ApolloQueryResult } from "apollo-client";
import React from "react";
import { TFunction } from "i18next";
import { FlowTypeEnum } from "../../../../global-query-types";
import { Accordion } from "react-bootstrap";
import { AdminServiceExternalDirectorFormContainer } from "./EmployeeManagingDirector/ExternalDirectorForm";
import { OnboardingCompanyForm } from "./CompanyForm/OnboardingCompanyForm";
import { RefetchAndResetActiveStep } from "../../CompanyCreation/RefetchAndResetActiveStep";
import { AccordionElement } from "../../CompanyCreation/Steps/AccordionElement";
import { DirectorForm } from "../../CompanyCreation/Steps/Director/DirectorForm";
import { PersonalDetailForm } from "../../CompanyCreation/Steps/PersonalDetails/PersonalDetailForm";
import CompanyCreationShareholdersForm from "../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import {
  adminServiceOnboarding,
  arrayOfStepsInAdminServiceOnboarding,
  getCompletedSteps
} from "../../CompanyCreation/getCompletedSteps";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "../../CompanyCreation/graphql/MyCompanyCreationQuery";
import { UserService } from "../../../../lib/services/UserService/UserService";

interface ViewAdminServiceOnboardingStepsProps {
  t: TFunction;
  showCreationSteps: boolean;
  currentStep: string;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
}

export const ViewAdminServiceOnboardingSteps = ({
  t,
  showCreationSteps,
  currentStep,
  myCompanyCreationDetails,
  refetchMyCompanyCreation,
  currentFlowType
}: ViewAdminServiceOnboardingStepsProps) => {
  const completedSteps = getCompletedSteps(currentStep, arrayOfStepsInAdminServiceOnboarding);

  const lastStep = completedSteps[completedSteps.length - 1] ?? adminServiceOnboarding.STEP_ONE;

  const { refetchAndResetStep, resetActiveStep, toggleActiveKey, activeKey } =
    RefetchAndResetActiveStep({
      lastStep,
      refetchMyCompanyCreation,
      currentFlowType
    });

  const isActiveUser = UserService.instance.isActiveUser;

  return (
    <Accordion activeKey={activeKey} data-testid="accordion-steps" className="mt-5">
      <AccordionElement
        headerText={t("admin-service:onboard-steps-title.company")}
        eventKey={adminServiceOnboarding.STEP_ONE}
        onToggle={toggleActiveKey}
        buttonText={"1"}
        disabled={showCreationSteps && !completedSteps.includes(adminServiceOnboarding.STEP_ONE)}>
        <OnboardingCompanyForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          refetchAndResetStep={refetchAndResetStep}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("admin-service:onboard-steps-title.company-shareholders")}
        eventKey={adminServiceOnboarding.STEP_TWO}
        onToggle={toggleActiveKey}
        buttonText={"2"}
        disabled={!completedSteps.includes(adminServiceOnboarding.STEP_TWO)}>
        <CompanyCreationShareholdersForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
          isActiveUser={isActiveUser}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("admin-service:onboard-steps-title.shareholder-details")}
        eventKey={adminServiceOnboarding.STEP_THREE}
        onToggle={toggleActiveKey}
        buttonText={"3"}
        disabled={!completedSteps.includes(adminServiceOnboarding.STEP_THREE)}>
        <PersonalDetailForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("admin-service:onboard-steps-title.managing-director")}
        eventKey={adminServiceOnboarding.STEP_FOUR}
        onToggle={toggleActiveKey}
        buttonText={"4"}
        disabled={!completedSteps.includes(adminServiceOnboarding.STEP_FOUR)}>
        <DirectorForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("admin-service:onboard-steps-title.employee-managing-director")}
        eventKey={adminServiceOnboarding.STEP_FIVE}
        onToggle={toggleActiveKey}
        buttonText={"5"}
        disabled={!completedSteps.includes(adminServiceOnboarding.STEP_FIVE)}>
        <AdminServiceExternalDirectorFormContainer
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
    </Accordion>
  );
};
