import { FlowTypeEnum } from "../../../../../global-query-types";
import React from "react";

export const ShareHolderFormDescription = ({ t, currentFlowType }) => {
  if (currentFlowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING) {
    return t("trader-gmbh:shareholder-form-description-admin-service-onboarding");
  } else {
    const email = t("trader-gmbh:shareholder-form-description-email");
    return (
      <>
        <p>{t("trader-gmbh:shareholder-form-description")}</p>
        <p>{t("trader-gmbh:shareholder-form-description-2")}</p>
        <p>
          {t("trader-gmbh:shareholder-form-description-3")}
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </>
    );
  }
};
