import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { LinkVariant, TextLink } from "../../../../sharedComponents/TextLink/TextLink";

interface UploadControlsProps extends WithTranslation {
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  isLoading: boolean;
}

const UploadControls = ({ onSubmit, onCancel, isLoading, t }: UploadControlsProps) => {
  return (
    <div className="upload-controls">
      <div>
        <RideButtonDeprecated
          data-testid="submit-button"
          className="upload-controls__submit-button"
          variant={ButtonVariant.Primary}
          isLoading={isLoading}
          onClick={onSubmit}>
          {t("client:documents.modal.cta")}
        </RideButtonDeprecated>
      </div>
      <div>
        <TextLink
          data-testid="cancel-button"
          className="upload-controls__cancel-button"
          variant={LinkVariant.secondary}
          to="#"
          onClick={onCancel}>
          {t("client:documents.modal.cancel")}
        </TextLink>
      </div>
    </div>
  );
};

export default withTranslationReady("client")(UploadControls);
