import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";

export type ExistingWallet = { id: string } | undefined | null;

export interface PersonIdOrCompanyId {
  personId?: string | null;
  companyId?: string | null;
}

interface Props extends WithTranslation, PersonIdOrCompanyId {
  existingWallet: ExistingWallet;
  setupWallet: (args: PersonIdOrCompanyId) => Promise<void>;
  refetch: () => Promise<void>;
}

const NomineeCreateWallet = ({
  t,
  personId,
  companyId,
  existingWallet,
  setupWallet,
  refetch
}: Props) => {
  if (existingWallet) return null;

  const onCreate = async () => {
    await setupWallet({ personId, companyId });
    await refetch();
  };

  return (
    <Row>
      <Col>
        <Button data-testid="create-wallet-btn" onClick={onCreate}>
          {t("nominee-dashboard:create-wallet")}
        </Button>
      </Col>
    </Row>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(NomineeCreateWallet);
