import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { companyDetailsByCompanyId_companyById } from "../../../../graphql/companyDetailsByCompanyId";

export interface IProps {
  t: TFunction;
  companyData: companyDetailsByCompanyId_companyById;
}

export const BusinessAddress = ({ t, companyData }: IProps) => {
  return (
    <div className="mt-5">
      <Row>
        <Col xs={6} md={3}>
          <Row>
            <Col>
              <b>{t("generic:street")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="street">{companyData.businessAddress.street}</Col>
          </Row>
        </Col>
        <Col xs={6} md={3}>
          <Row>
            <Col>
              <b>{t("generic:postal-code")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="postal-code">{companyData.businessAddress.postalCode}</Col>
          </Row>
        </Col>
        <Col xs={6} md={3}>
          <Row>
            <Col>
              <b>{t("generic:city")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="city">{companyData.businessAddress.city}</Col>
          </Row>
        </Col>
        <Col xs={6} md={3}>
          <Row>
            <Col>
              <b>{t("generic:country")}</b>
            </Col>
          </Row>
          <Row>
            <Col data-testid="country">{"Deutschland"}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
