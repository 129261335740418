import React, { FunctionComponent } from "react";
import { Formik } from "formik";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Container, Form } from "react-bootstrap";
import { CompanyType, LegalForm, PublishType } from "global-query-types";
import { AddSPVCompanySchema } from "lib/validation/AddSPVCompanySchema";
import { useQuery } from "react-apollo";
import { GET_LEGAL_FORM } from "lib/api/queries/GetLegalForm";
import ErrorAlertLegacy from "sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { OptionItem } from "../../generic/Select";
import { SPVCompanyFormFields } from "./SPVCompanyFormFields";
import { getLegalForm, getLegalForm___type } from "lib/api/queries/graphql/getLegalForm";

export interface IValues {
  name: string;
  projectName: string;
  legalForm: LegalForm;
  capitalContribution: number;
  street: string;
  city: string;
  postalCode: string;
  type: CompanyType;
  tradeTaxFree: string;
  tokenized: string;
  status: PublishType;
  tokenizedShares: number;
  registration: string;
  boxLink: string;
  registerCourt: string;
  taxNumber: string;
  vatNumber: string;
}

const initialData: IValues = {
  name: "",
  projectName: "",
  legalForm: LegalForm.GmbH,
  capitalContribution: 0.0,
  street: "",
  city: "",
  postalCode: "",
  type: CompanyType.Spv,
  tradeTaxFree: "NO",
  tokenized: "NO",
  status: PublishType.Undisclosed,
  tokenizedShares: 0.0,
  registration: "",
  boxLink: "",
  registerCourt: "",
  taxNumber: "",
  vatNumber: ""
};

interface AddSPVCompanyFormProps extends WithTranslation {
  submitForm: (values: IValues) => void | Promise<void>;
  cancelForm: () => void | Promise<void>;
}

export const AddSPVCompanyForm: FunctionComponent<AddSPVCompanyFormProps> = (props) => {
  const { t, submitForm, cancelForm } = props;

  const { data: availableLegalForms, error: errorLoadingLF } = useQuery<
    getLegalForm,
    getLegalForm___type
  >(GET_LEGAL_FORM);

  const legalForms =
    availableLegalForms?.__type?.enumValues?.map(
      (item) =>
        ({
          title: t(`generic:legalForm.${item.name}`),
          value: item.name
        } as OptionItem)
    ) || [];

  const errorReason = errorLoadingLF && errorLoadingLF.message;

  return (
    <>
      <ErrorAlertLegacy t={t} error={errorReason} />
      <Formik
        initialValues={initialData}
        validateOnChange={true}
        validationSchema={AddSPVCompanySchema}
        onSubmit={submitForm}>
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Container>
              <SPVCompanyFormFields t={t} formik={formik} legalForms={legalForms} />

              <Button className="float-sm-right" type="submit">
                {t("spv-company:add-spv-company")}
              </Button>
              <Button
                className="btn-secondary float-sm-right mr-2"
                variant="link"
                onClick={cancelForm}>
                {t("generic:cancel")}
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withTranslation(["spv-company", "generic"])(AddSPVCompanyForm);
