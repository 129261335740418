import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { Trans, WithTranslation } from "react-i18next";
import {
  FileUploadArea,
  PlaceholderImage
} from "../../../sharedComponents/FileUploadArea/FileUploadArea";
import { FileEntityType, FileStorage } from "lib/ports/FileStorage";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { cloneDeep } from "lodash";
import { Form, FormikProvider, useFormik } from "formik";

interface ShareholderIdFileProps
  extends StepComponentProps<LowTaxBrokerOrderData>,
    WithTranslation {
  variant?: IdDocumentVariant | undefined;
  fileUploader: FileStorage;
}

export enum IdDocumentVariant {
  ID_FRONT = "id-front",
  ID_BACK = "id-back",
  PASSPORT = "passport"
}

const ShareholderIdFileStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData,
  fileUploader,
  variant = undefined
}: ShareholderIdFileProps) => {
  let moduleName = "shareholder-id-file";

  const index = currentStep?.chapter?.index ?? 0;
  const existingFile =
    variant === IdDocumentVariant.ID_BACK
      ? order.extra.shareholders?.[index]?.proofOfIDFile?.back ?? undefined
      : order.extra.shareholders?.[index]?.proofOfIDFile;

  async function uploadFile(file: File) {
    const { fileId, checksum } = await fileUploader.uploadFileForEntity(
      FileEntityType.Order,
      order.id ?? "missing-id",
      file
    );

    currentStep.complete();

    let proofOfIDFile = cloneDeep(order.extra.shareholders?.[index].proofOfIDFile ?? {});

    if (variant === IdDocumentVariant.ID_BACK) {
      proofOfIDFile.back = {
        id: fileId,
        checksum,
        name: file.name,
        size: file.size
      };
    } else {
      proofOfIDFile = {
        ...proofOfIDFile,
        id: fileId,
        checksum,
        name: file.name,
        size: file.size
      };
    }

    await saveData(
      {
        ...order.extra,
        shareholders: {
          ...order.extra.shareholders,
          [index]: {
            ...order.extra.shareholders?.[index],
            proofOfIDFile
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );
  }

  async function deleteFile() {
    currentStep.reOpen();

    let proofOfIDFile = cloneDeep(order.extra.shareholders?.[index].proofOfIDFile ?? {});

    if (variant === IdDocumentVariant.ID_BACK) {
      proofOfIDFile.back = null;
    } else {
      proofOfIDFile = {
        ...proofOfIDFile,
        id: null,
        checksum: null,
        name: null,
        size: null
      };
    }

    await saveData(
      {
        ...order.extra,
        shareholders: {
          ...order.extra.shareholders,
          [index]: {
            ...order.extra.shareholders?.[index],
            proofOfIDFile
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );
  }

  async function onSubmit() {
    await saveData(order.extra, false, false);
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div
          className={`${moduleName} subchapter-order-layout__scrollable`}
          data-testid={moduleName}>
          {!variant && (
            <FileUploadArea
              uploadFile={uploadFile}
              existingFile={existingFile}
              deleteFile={deleteFile}
            />
          )}
          {variant === IdDocumentVariant.ID_FRONT && (
            <>
              <div data-testid={"id-front-text"}>
                <Trans
                  i18nKey={
                    "generic:ride-broker-onboarding.documents.shareholder-id-file.id-front-instruction"
                  }
                />
              </div>
              <FileUploadArea
                uploadFile={uploadFile}
                existingFile={existingFile}
                deleteFile={deleteFile}
                placeholderImageType={PlaceholderImage.ID_FRONT}
              />
            </>
          )}
          {variant === IdDocumentVariant.ID_BACK && (
            <>
              <div data-testid={"id-back-text"}>
                <Trans
                  i18nKey={
                    "generic:ride-broker-onboarding.documents.shareholder-id-file.id-back-instruction"
                  }
                />
              </div>
              <FileUploadArea
                uploadFile={uploadFile}
                existingFile={existingFile}
                deleteFile={deleteFile}
                placeholderImageType={PlaceholderImage.ID_BACK}
              />
            </>
          )}
          {variant === IdDocumentVariant.PASSPORT && (
            <>
              <RideAlertBar
                type={AlertType.INFO}
                title={t(
                  "generic:ride-broker-onboarding.documents.shareholder-id-file.passport-instruction-title"
                )}
                message={
                  <Trans
                    i18nKey={
                      "generic:ride-broker-onboarding.documents.shareholder-id-file.passport-instruction"
                    }
                  />
                }
              />
              <FileUploadArea
                uploadFile={uploadFile}
                existingFile={existingFile}
                deleteFile={deleteFile}
                placeholderImageType={PlaceholderImage.PASSPORT}
              />
            </>
          )}
        </div>
        {{
          ...controls,
          props: {
            ...controls.props,
            disabled: !existingFile?.id
          }
        }}
      </Form>
    </FormikProvider>
  );
};

export const ShareholderIdFile = (
  fileStorage: FileStorage,
  variant?: IdDocumentVariant | undefined
) =>
  withTranslationReady(["generic"])((props: StepComponentProps & WithTranslation) => (
    <ShareholderIdFileStep {...props} fileUploader={fileStorage} variant={variant} />
  ));
