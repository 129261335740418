import gql from "graphql-tag";

export const CONFIRM_EMAIL_LINK = gql`
  mutation ConfirmEmailLink($verificationToken: ID!) {
    confirmEmailLink(verificationToken: $verificationToken) {
      feedback
      firstName
      salutation
      email
    }
  }
`;
