import React, { FunctionComponent, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { CompanyTitleInput } from "./FormFields/editFormFields/CompanyTitleInput";
import { CompanyMembershipShare } from "./FormFields/editFormFields/OwnershipShareFields";
import { CompanyLegalRegistration } from "./FormFields/editFormFields/LegalRegistrationFields";
import { CompanyTypeCapital } from "./FormFields/editFormFields/TypeCapitalFields";
import { BusinessAddressInput } from "sharedComponents/BusinessAddressInput/BusinessAddressInput";
import {
  getCompanyTypesTranslation,
  getInitialValues,
  getOwnerSince
} from "./companyInitialValues";
import { useFormik } from "formik";
import { AddUserCompanySchema } from "../../../../lib/validation/AddUserCompanySchema";
import { withoutLocalOffset } from "../../../../common/dateProvider";
import LoadingAlert from "../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import SuccessAlert from "../../../generic/SuccessAlert";
import {
  EditUserCompanyById,
  EditUserCompanyByIdVariables
} from "../../graphql/EditUserCompanyById";
import { CREATE_MEMBERSHIP_SHARE_COMPANY, EDIT_USER_COMPANY_BY_ID } from "../../Mutations";
import { useMutation } from "react-apollo";
import { availableOwnersRefetches } from "../../Refetches";
import { COMPANY_DETAILS_BY_COMPANY_ID, USER_COMPANIES_QUERY } from "../../Queries";
import {
  CreateMembershipShareCompany,
  CreateMembershipShareCompanyVariables
} from "../../graphql/CreateMembershipShareCompany";
import logger from "../../../../common/Logger";
import { companyDetailsByCompanyId_companyById } from "../../graphql/companyDetailsByCompanyId";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import { CompanyRegistrationNumbers } from "./FormFields/editFormFields/CompanyRegistrationNumbers";
import { clientLegalForms } from "../../../../common/staticData/LegalForms";
import { GetCompanyTypes } from "../../../../lib/api/queries/GetUserCompanyTypes";

interface CompanyEditLayoutProps {
  toggleEditLayout: () => void;
  t: TFunction;
  companyData: companyDetailsByCompanyId_companyById;
}

export const CompanyEditLayout: FunctionComponent<CompanyEditLayoutProps> = (props) => {
  const { toggleEditLayout, t, companyData } = props;

  const [selectedDate, setSelectedDate] = useState(getOwnerSince(companyData));

  const [callCompanyUpdateMutation, { error: errorCreateCompany, loading: loadingCreateCompany }] =
    useMutation<EditUserCompanyById, EditUserCompanyByIdVariables>(EDIT_USER_COMPANY_BY_ID);

  const [
    callCreateCompanyOwnership,
    { error: errorCreateCompanyOwnership, loading: loadingCreateCompanyOwnership }
  ] = useMutation<CreateMembershipShareCompany, CreateMembershipShareCompanyVariables>(
    CREATE_MEMBERSHIP_SHARE_COMPANY,
    {
      refetchQueries: [
        ...availableOwnersRefetches,
        {
          query: COMPANY_DETAILS_BY_COMPANY_ID,
          variables: { companyId: companyData.id }
        },
        {
          query: USER_COMPANIES_QUERY,
          variables: { type: null }
        }
      ]
    }
  );

  const { availableCompanyTypes } = GetCompanyTypes();

  const [success, setSuccess] = useState(false);

  const companyTypes = getCompanyTypesTranslation(availableCompanyTypes, t);

  const loading = loadingCreateCompany || loadingCreateCompanyOwnership;
  const errorReason =
    (errorCreateCompany && errorCreateCompany?.message) ||
    (errorCreateCompanyOwnership && errorCreateCompanyOwnership.message);

  const submitHandler = async (values) => {
    const ownerSince = withoutLocalOffset(selectedDate).toJSON();
    const tradeTaxFree = values.tradeTaxFree === "YES";
    const finalValues = { ...values, ownerSince, tradeTaxFree };
    try {
      logger.debug("User edit company by id");
      await callCompanyUpdateMutation({
        variables: {
          companyId: finalValues.companyId,
          name: finalValues.name,
          companyValue: finalValues.companyValue,
          legalForm: finalValues.legalForm,
          capitalContribution: finalValues.capitalContribution,
          street: finalValues.street,
          streetLine2: finalValues.streetLine2,
          city: finalValues.city,
          postalCode: finalValues.postalCode,
          type: finalValues.type,
          tradeTaxFree: finalValues.tradeTaxFree,
          registerCourt: finalValues.registerCourt,
          registration: finalValues.registration,
          taxNumber: finalValues.taxNumber,
          vatNumber: finalValues.vatNumber
        }
      });

      await callCreateCompanyOwnership({
        variables: {
          companyId: finalValues.companyId,
          ownerId: finalValues.ownerId,
          share: finalValues.percentageOwnership,
          startsAt: finalValues.ownerSince,
          endsAt: null
        }
      });

      setSuccess(true);
      setTimeout(toggleEditLayout, 300);
    } catch (error) {
      logger.errorMessage("User company update not successful");
      logger.error(error);
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(companyData),
    validateOnBlur: true,
    validationSchema: AddUserCompanySchema,
    onSubmit: submitHandler
  });

  return (
    <Container>
      <Row>
        <Col>
          <Link
            to="#"
            className="btn btn-link pl-0"
            onClick={() => toggleEditLayout()}
            data-testid="cancel">
            {t("generic:cancel")}
          </Link>
        </Col>
        <Col className="col-auto">
          <Button
            variant="primary"
            style={{ float: "right" }}
            data-testid="submit"
            onClick={() => formik.handleSubmit()}>
            {t("generic:save")}
          </Button>
        </Col>
      </Row>
      <div className="mt-4">
        <LoadingAlert t={t} loading={loading} />
        <ErrorAlertLegacy t={t} error={errorReason} />
        <SuccessAlert success={success} t={t} message={t("generic:success_heading")} />
      </div>

      <div className="mt-4">
        <CompanyTitleInput t={t} formik={formik} legalForms={clientLegalForms} />
        <CompanyMembershipShare
          t={t}
          formik={formik}
          companyData={companyData}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        <CompanyLegalRegistration className="mt-4" t={t} formik={formik} />
        <CompanyRegistrationNumbers t={t} formik={formik} />

        <CompanyTypeCapital t={t} formik={formik} companyTypes={companyTypes} />
        <BusinessAddressInput t={t} formik={formik} />
      </div>
    </Container>
  );
};
