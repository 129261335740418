import { useMutation } from "@apollo/react-hooks";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import React from "react";
import { CompanyDetailsSectionContainerProps } from "../CompanyDetailsSection/CompanyDetailsSectionContainer";
import BusinessPurposeSection from "./BusinessPurposeSection";
import { NOMINEE_UPDATE_COMPANY } from "../../../../lib/api/mutations/NOMINEE_UPDATE_COMPANY";
import {
  NomineeUpdateCompany,
  NomineeUpdateCompanyVariables
} from "../../../../lib/api/mutations/graphql/NomineeUpdateCompany";

export const BusinessPurposeSectionContainer = ({
  company,
  refetchCompany
}: CompanyDetailsSectionContainerProps) => {
  const [nomineeUpdateCompany, updateRequest] = useMutation<
    NomineeUpdateCompany,
    NomineeUpdateCompanyVariables
  >(NOMINEE_UPDATE_COMPANY);

  const updateCompany = async (variables) => {
    await nomineeUpdateCompany({
      variables: {
        ...variables,
        id: company.id
      }
    });
  };

  return (
    <RequestFeedbackHandler requests={[updateRequest]}>
      <BusinessPurposeSection company={company} refetch={refetchCompany} onSave={updateCompany} />
    </RequestFeedbackHandler>
  );
};
