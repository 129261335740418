import * as Yup from "yup";

export const CompanyTaxNumberSchema = () => {
  return Yup.object().shape({
    companyTaxNumber: Yup.string().nullable()
  });
};

export const CompanyTaxNumberForRideBrokerSchema = (t) => {
  return Yup.object().shape({
    companyTaxNumber: Yup.string().required(t("generic:validation-required"))
  });
};

export const taxNumberValidation = (t) =>
  Yup.string()
    .required(t("generic:validation-required"))
    .matches(/^[0-9/]*$/, t("generic:invalid-pattern-of-tax-number"));
