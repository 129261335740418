import { Button } from "react-bootstrap";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface SubmitButtonProps extends WithTranslation {}

const SubmitButton = ({ t }: SubmitButtonProps) => (
  <Button type="submit" data-testid="submit" className="step-submit-btn" variant="outline-light">
    {t("generic:save")}
  </Button>
);

export default withTranslation(["generic"])(SubmitButton);
