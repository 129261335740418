import React from "react";
import { WithTranslation } from "react-i18next";
import { FeatureFlags } from "global-query-types";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { TabGroup, TabProps } from "client/components/TabGroup/TabGroup";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import { GmbHDetails } from "lib/models/client/GmbHDetails";

export enum CompanyTabbedNavigationValue {
  HOME = "home",
  PRIMARY_DATA = "general-info",
  ADMIN_SERVICE = "admin-service",
  WEPA = "securities-booking",
  INVEST = "invest",
  BANKING = "banking",
  LEI_NUMBER = "lei",
  REAL_ESTATE = "real-estate"
}

interface CompanyTabbedNavigationProps extends WithTranslation {
  gmbh: GmbHDetails;
  value: CompanyTabbedNavigationValue;
  onChange?: (value: CompanyTabbedNavigationValue) => void;
}

export const CompanyTabbedNavigation = ({
  value,
  onChange,
  t,
  gmbh
}: CompanyTabbedNavigationProps) => {
  const handleClick = (newValue) => {
    onChange?.(newValue);
  };

  const showInvestForCompaniesWithCorporateShareholders = FeatureFlagService.instance.isEnabled(
    FeatureFlags.CorporateShareholderCompaniesForLowTaxBrokerOrders
  );

  const showInvestTab =
    showInvestForCompaniesWithCorporateShareholders || !gmbh.shareholders?.find((m) => !!m.company);

  const showBankingTab = FeatureFlagService.instance.isEnabled(
    FeatureFlags.BankingPageInCompanyNavigation
  );

  const showHomeTab = FeatureFlagService.instance.isEnabled(FeatureFlags.EnableHomeTab);

  const showLeiTab =
    gmbh.hasLeiSection &&
    FeatureFlagService.instance.isEnabled(FeatureFlags.GRO1070AddLEINumberSectionForLEIAdmin);

  const showRealEstateTab = FeatureFlagService.instance.isEnabled(
    FeatureFlags.RE52AddRealEstateSection
  );

  const showASTab = !gmbh.isWepaClient;

  const tabs = [
    showHomeTab && {
      label: t("generic:home-tab"),
      value: CompanyTabbedNavigationValue.HOME
    },
    {
      label: t("generic:master-data"),
      value: CompanyTabbedNavigationValue.PRIMARY_DATA
    },
    showInvestTab && {
      label: t(rideBrokerT("broker-v2:invest")),
      value: CompanyTabbedNavigationValue.INVEST
    },
    showASTab && {
      label: t("generic:admin-service"),
      value: CompanyTabbedNavigationValue.ADMIN_SERVICE
    },
    {
      label: t("generic:wepa-service"),
      value: CompanyTabbedNavigationValue.WEPA
    },
    showLeiTab && {
      label: t("generic:lei-number"),
      value: CompanyTabbedNavigationValue.LEI_NUMBER
    },
    showRealEstateTab && {
      label: t("generic:real-estate"),
      value: CompanyTabbedNavigationValue.REAL_ESTATE
    },
    showBankingTab && {
      label: t("generic:banking"),
      value: CompanyTabbedNavigationValue.BANKING
    }
  ].filter((t) => Boolean(t)) as TabProps[];

  return (
    <TabGroup
      dataTestId={"company-tabbed-navigation"}
      tabs={tabs}
      currentTab={value}
      onChange={handleClick}
    />
  );
};

export default withTranslationReady(["generic"])(CompanyTabbedNavigation);
