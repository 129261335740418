import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { PersonalInfoFields } from "./PersonalInfoFields";
import { TFunction } from "i18next";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../CompanyCreation/graphql/MyCompanyCreationQuery";
import { FlowTypeEnum, RolesInGroup, SalutationType } from "../../../../../global-query-types";
import { SalutationValueEnum } from "../../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import { UPDATE_ORDERER_PERSONAL_DETAILS } from "../../../CompanyCreation/Mutations";
import {
  UpdateOrdererPersonalDetail,
  UpdateOrdererPersonalDetailVariables
} from "../../../CompanyCreation/graphql/UpdateOrdererPersonalDetail";
import { useMutation } from "react-apollo";
import logger from "../../../../../common/Logger";
import { CompanyCreationShareholdersSchema } from "../../../../../lib/validation/CompanyCreationShareholdersSchema";

interface PersonalInfoFormProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation;
  refetchAndResetStep: (id: string) => Promise<void>;
  currentFlowType: FlowTypeEnum;
}

export const PersonalInfoForm = ({
  t,
  myCompanyCreationDetails,
  refetchAndResetStep,
  currentFlowType
}: PersonalInfoFormProps) => {
  const [updateOrdererPersonalDetail, { error, loading }] = useMutation<
    UpdateOrdererPersonalDetail,
    UpdateOrdererPersonalDetailVariables
  >(UPDATE_ORDERER_PERSONAL_DETAILS);

  const [agreed, setAgreed] = useState(false);
  const [localError, setLocalError] = useState("");

  const ordererDetails =
    myCompanyCreationDetails &&
    myCompanyCreationDetails.company?.group?.memberships
      ?.filter((elem) => elem.role === RolesInGroup.Orderer)
      ?.map((elem) => {
        return {
          id: elem.person?.id ?? "",
          title: elem.person?.title ?? "",
          firstName: elem.person?.firstName ?? "",
          lastName: elem.person?.lastName ?? "",
          phoneNumber: elem.person?.phoneNumber ?? "",
          salutation: SalutationValueEnum[elem.person?.salutation!] ?? SalutationValueEnum.Mr,
          email: elem.person?.user?.isDummyEmail ? "" : elem.person?.user?.email ?? "",
          disabled: elem.person?.locked ?? false
        };
      });

  const orderer = ordererDetails![0];

  const submitHandler = async (values) => {
    setLocalError("");
    if (!agreed) {
      setLocalError(t("admin-service:not-agreed-error"));
      return;
    }

    const ordererInfo = {
      id: values.id,
      title: values.title,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      salutation: SalutationType[SalutationValueEnum[values.salutation]]
    };

    try {
      await updateOrdererPersonalDetail({
        variables: { companyCreationId: myCompanyCreationDetails.id, orderer: ordererInfo }
      });

      await refetchAndResetStep(myCompanyCreationDetails.id);
      return;
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to Update Admin Service Orderer Personal Details:`);
      logger.error(error);
    }
  };

  const formik = useFormik({
    initialValues: orderer,
    validateOnChange: true,
    validationSchema: CompanyCreationShareholdersSchema,
    enableReinitialize: true,
    onSubmit: submitHandler
  });

  const render = () => {
    return (
      <FormikProvider value={formik}>
        <div>
          <Row>
            <Col className="steps-form-description">
              {t("admin-service:personalInfo-form-description")}
            </Col>
          </Row>
          <div className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
            <Container>
              <Row>
                <Col>{renderForm()}</Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <LoadingAlert t={t} loading={loading} />
                  <ErrorAlertLegacy t={t} error={error?.message} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </FormikProvider>
    );
  };

  const renderForm = () => {
    return (
      <Form>
        <PersonalInfoFields
          t={t}
          formik={formik}
          agreed={agreed}
          setAgreed={setAgreed}
          localError={localError}
          currentFlowType={currentFlowType}
          values={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          prefix={""}
          disabled={orderer.disabled}
        />
      </Form>
    );
  };

  return render();
};
