import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { getEntityName, StepComponentProps } from "../../ChaptersOrderLayout.partials";
import { formatRegisterCourt } from "common/formatters/registerCourtFormatter";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { FeatureFlags, LegalPersonType } from "global-query-types";
import { ReviewEntry } from "../../../ReviewEntry/ReviewEntry";
import moment from "moment";
import { ReviewField } from "../../LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { formatAddress } from "lib/formatters/addressFormatter";
import { Multiline } from "../../LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewAddress/ReviewAddress";
import { EmploymentOptionEnum } from "../../../../../lib/models/client/LowTaxBroker/EmploymentOption";
import { FeatureFlagService } from "../../../../../lib/services/FeatureFlagService/FeatureFlagService";

interface ReviewEntityFormProps {
  entityKey: "shareholder" | "employee";
  showBrokerInformation: boolean;
}

const ReviewEntityForm = (props: WithTranslation & StepComponentProps & ReviewEntityFormProps) => {
  const { order, currentStep, saveData, controls, t, entityKey, showBrokerInformation } = props;
  const entityIndex = currentStep.chapter.index ?? 0;
  const entity = order.extra[`${entityKey}s`][entityIndex] ?? {};
  const formatAddressAsNewBroker =
    order.extra.isNewBrokerEnabled &&
    FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerWithoutHouseNumber);

  const onSubmit = async (event) => {
    event.preventDefault();

    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const exhibitionDate = (value) => moment(value).format("DD.MM.YYYY").toString();

  const subChapter = currentStep.chapter;
  const chapter = subChapter.parent;
  const subChapterIndex = chapter?.getIndexOfSubChapter(subChapter);

  const generalInformationIndex = subChapter.getStepIndexByKey(`${entityKey}-general-information`);

  const companyEntries = () => {
    const companyData = entity.companyData ?? {};
    const companyAddress = entity.companyAddress ?? {};
    const companyRepresentative = entity.companyRepresentative ?? {};
    const representativeCitizenship = entity.representativeCitizenship ?? {};
    const representativeAddress = entity.representativeAddress ?? {};

    const companyAddressIndex = subChapter.getStepIndexByKey("shareholder-company-address");
    const companyRepresentativeIndex = subChapter.getStepIndexByKey(
      "shareholder-company-representative"
    );
    const representativeCitizenshipIndex = subChapter.getStepIndexByKey(
      "shareholder-representative-citizenship"
    );

    const representativeAddressIndex = subChapter.getStepIndexByKey(
      "shareholder-representative-address"
    );

    return (
      <>
        <ReviewEntry
          name="shareholder-general-information"
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${generalInformationIndex}/${order.id}/${order.submissionId}`}
          fields={{
            companyName: [getEntityName(t, entity, entityIndex)],
            registerCourt: [formatRegisterCourt(companyData.registerCourt)],
            registration: [companyData.registration]
          }}
        />

        <ReviewEntry
          name="shareholder-company-address"
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${companyAddressIndex}/${order.id}/${order.submissionId}`}
          fields={{
            registrationAddress: formatAddress(companyAddress, formatAddressAsNewBroker).split("\n")
          }}
        />

        <ReviewEntry
          name="shareholder-company-representative"
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${companyRepresentativeIndex}/${order.id}/${order.submissionId}`}
          fields={{
            personName: [personNameWithFallback(companyRepresentative)],
            email: [companyRepresentative.email],
            phoneNumber: [companyRepresentative.phoneNumber]
          }}
        />

        <ReviewEntry
          name="shareholder-representative-citizenship"
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${representativeCitizenshipIndex}/${order.id}/${order.submissionId}`}
          fields={{
            gender: [
              t(`shareholder-details:citizenship-information.${representativeCitizenship.gender}`)
            ],
            birthDate: [
              representativeCitizenship.birthDate
                ? exhibitionDate(representativeCitizenship.birthDate)
                : ""
            ],
            citizenship: [getCountryNameByCode(representativeCitizenship.citizenship)],
            birthCity: [representativeCitizenship.birthCity],
            usCitizenshipOrTaxResidence: [
              t(
                `generic:${
                  `${representativeCitizenship.notUSCitizenOrTaxResident}` === "false"
                    ? "yes"
                    : "no"
                }`
              )
            ]
          }}
        />

        <ReviewEntry
          name="shareholder-representative-address"
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${representativeAddressIndex}/${order.id}/${order.submissionId}`}
          fields={{
            residentialAddress: formatAddress(
              representativeAddress,
              formatAddressAsNewBroker
            ).split("\n")
          }}
        />
      </>
    );
  };

  const personEntries = () => {
    const personData = entity.personData ?? {};
    const personCitizenship = entity.personCitizenship ?? {};
    const personAddress = entity.personAddress ?? {};
    const personStatusInformation = entity.statusInformation ?? {};
    const employmentDetails = entity.employmentDetails ?? {};

    const showEmploymentSection =
      !!order.extra?.isNewBrokerEnabled &&
      (employmentDetails?.type === EmploymentOptionEnum.EMPLOYED ||
        employmentDetails?.type === EmploymentOptionEnum.SELFEMPLOYED);

    const statusInformationIndex = subChapter.getStepIndexByKey(
      `${entityKey === "shareholder" ? "majority-shareholder" : "employee"}-status-information`
    );

    const employerInformationIndex = subChapter.getStepIndexByKey(
      `${entityKey}-employer-information`
    );

    const personCitizenshipIndex = subChapter.getStepIndexByKey(
      `${entityKey}-citizenship-information`
    );

    const personAddressIndex = subChapter.getStepIndexByKey(`${entityKey}-residential-address`);

    const citizenshipInformationFields: { [key: string]: string[] } = showBrokerInformation
      ? {
          gender: [t(`shareholder-details:citizenship-information.${personCitizenship.gender}`)],
          birthDate: [
            personCitizenship.birthDate ? exhibitionDate(personCitizenship.birthDate) : ""
          ],
          citizenship: [getCountryNameByCode(personCitizenship.citizenship)],
          secondCitizenship: [getCountryNameByCode(personCitizenship.secondCitizenship)],
          birthCity: [personCitizenship.birthCity],
          birthCountry: [getCountryNameByCode(personCitizenship.birthCountry)],
          usCitizenshipOrTaxResidence: [
            t(
              `generic:${
                `${personCitizenship.notUSCitizenOrTaxResident}` === "false" ? "yes" : "no"
              }`
            )
          ]
        }
      : {
          gender: [t(`shareholder-details:citizenship-information.${personCitizenship.gender}`)],
          birthDate: [
            personCitizenship.birthDate ? exhibitionDate(personCitizenship.birthDate) : ""
          ],
          citizenship: [getCountryNameByCode(personCitizenship.citizenship)],
          birthCity: [personCitizenship.birthCity],
          usCitizenshipOrTaxResidence: [
            t(
              `generic:${
                `${personCitizenship.notUSCitizenOrTaxResident}` === "false" ? "yes" : "no"
              }`
            )
          ]
        };

    const countryMismatch = !!(
      personAddress?.country &&
      employmentDetails?.employerAddress?.country &&
      personAddress?.country !== employmentDetails?.employerAddress?.country
    );

    return (
      <>
        <ReviewEntry
          name={`${
            entityKey === "shareholder" && entity.shareholderType === LegalPersonType.Person
              ? "person-"
              : ""
          }${entityKey}-general-information`}
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${generalInformationIndex}/${order.id}/${order.submissionId}`}
          fields={{
            personName: [getEntityName(t, entity, entityIndex, entityKey)],
            email: [personData.email],
            phoneNumber: [personData.phoneNumber]
          }}
        />
        <ReviewEntry
          name={`${entityKey}-citizenship-information`}
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${personCitizenshipIndex}/${order.id}/${order.submissionId}`}
          fields={citizenshipInformationFields}
        />

        <ReviewEntry
          name={`${entityKey}-residential-address`}
          editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${personAddressIndex}/${order.id}/${order.submissionId}`}
          fields={{
            residentialAddress: formatAddress(personAddress, formatAddressAsNewBroker).split("\n")
          }}
        />

        {showBrokerInformation && (
          <>
            <div className="general-information-refactor" data-testid={"tax-information"}>
              <ReviewEntry
                name={`${
                  entityKey === "shareholder" ? "majority-shareholder" : "employee"
                }-status-information`}
                editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${statusInformationIndex}/${order.id}/${order.submissionId}`}
              />
              <ReviewField
                name="taxJurisdiction"
                label={t("generic:review-entry.taxJurisdiction")}
                value={
                  personStatusInformation.taxJurisdiction
                    ? getCountryNameByCode(personStatusInformation.taxJurisdiction)
                    : undefined
                }
              />
              <ReviewField
                name="taxId"
                label={t("generic:tax-id")}
                value={personStatusInformation.taxId}
              />
              {!!order.extra?.isNewBrokerEnabled && (
                <ReviewField
                  name="employmentOption"
                  label={t("generic:ride-broker-onboarding.employment-options.label")}
                  value={
                    employmentDetails.type &&
                    t(`generic:ride-broker-onboarding.employment-options.${employmentDetails.type}`)
                  }
                />
              )}
              <ReviewField
                name="politicallyExposed"
                label={t("generic:review-entry.politicallyExposed")}
                value={
                  personStatusInformation.politicallyExposed === undefined
                    ? undefined
                    : personStatusInformation.politicallyExposed
                    ? t("generic:yes")
                    : t("generic:no")
                }
              />
              {personStatusInformation.politicallyExposed && (
                <ReviewField
                  name="politicallyExposedContext"
                  label={t("generic:review-entry.politicallyExposedContext")}
                  value={personStatusInformation.politicallyExposedContext}
                />
              )}
            </div>

            {showEmploymentSection && (
              <div className="general-information-refactor">
                <ReviewEntry
                  name={`${entityKey}-employer-information`}
                  editLink={`/client/order/generic/${chapter?.key}/${subChapterIndex}/${employerInformationIndex}/${order.id}/${order.submissionId}`}
                />
                <ReviewField
                  name="employerName"
                  label={t("generic:employer-name")}
                  value={employmentDetails.employer}
                />
                <ReviewField
                  name="businessType"
                  label={t(
                    "generic:ride-broker-onboarding.employer-information.business-type.label"
                  )}
                  value={employmentDetails.employerBusiness}
                />
                <ReviewField
                  name="occupation"
                  label={t("generic:ride-broker-onboarding.employer-information.occupation.label")}
                  value={employmentDetails.occupation}
                />
                <ReviewField
                  name={`employerAddress`}
                  value={
                    employmentDetails.employerAddress && (
                      <Multiline>
                        {formatAddress(employmentDetails.employerAddress, formatAddressAsNewBroker)}
                      </Multiline>
                    )
                  }
                  label={t("generic:employer-address")}
                />
                {countryMismatch && (
                  <ReviewField
                    name="countryMismatchField"
                    label={t(
                      "generic:ride-broker-onboarding.employer-information.country-mismatch.label"
                    )}
                    value={employmentDetails.countryMismatch}
                  />
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const isPersonEntity =
    entityKey === "employee" || entity.shareholderType === LegalPersonType.Person;

  return (
    <form onSubmit={onSubmit} className="subchapter-order-layout__form">
      <div className="subchapter-order-layout__scrollable">
        {isPersonEntity ? personEntries() : companyEntries()}
      </div>

      {controls}
    </form>
  );
};

export const ReviewEntity = (
  entityKey: "shareholder" | "employee",
  showBrokerInformation: boolean = false
) =>
  withTranslationReady(["generic", "shareholder-details"])(
    (props: StepComponentProps & WithTranslation) => (
      <ReviewEntityForm
        {...props}
        entityKey={entityKey}
        showBrokerInformation={showBrokerInformation}
      />
    )
  );
