import React from "react";
import { WithTranslation } from "react-i18next";
import {
  BackgroundColorVariant,
  ClickableAreaVariant,
  RideTile
} from "sharedComponents/RideTile/RideTile";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";

export interface AdvertisementCampaignBannerProps extends WithTranslation {
  title: string;
  description: string;
  moreInfoUrl?: string;
  moreInfoText?: string;
  dataTestId?: string;
  className?: string;
  openInCurrentTab?: boolean;
}

const AdvertisementCampaignBanner = ({
  t,
  title,
  description,
  moreInfoUrl,
  moreInfoText,
  dataTestId = "advertisement-campaign-banner",
  openInCurrentTab,
  className
}: AdvertisementCampaignBannerProps) => {
  return (
    <RideTile
      data-testid={dataTestId}
      className={`advertisement-campaign-banner ${className}`}
      name={title}
      backgroundColorVariant={BackgroundColorVariant.rideGradient}
      footerSlot={
        moreInfoUrl ? (
          <div className="advertisement-campaign-banner__footer">
            <TextLink
              variant={LinkVariant.dark}
              to={moreInfoUrl}
              data-testid="ride-footer-url"
              target={openInCurrentTab ? undefined : "_blank"}>
              {moreInfoText ?? t("generic:advertisement-campaign-banner.footer")}
            </TextLink>
          </div>
        ) : undefined
      }
      clickableAreaVariant={ClickableAreaVariant.none}
      subTitleSlot={<div>{description}</div>}
    />
  );
};

export default withTranslationReady(["generic"])(AdvertisementCampaignBanner);
