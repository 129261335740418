import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  GetLowTaxBrokerOrderForCompany,
  GetLowTaxBrokerOrderForCompanyVariables
} from "lib/api/queries/graphql/GetLowTaxBrokerOrderForCompany";

export const GET_LOW_TAX_BROKER_ORDER_FOR_COMPANY = gql`
  query GetLowTaxBrokerOrderForCompany($companyId: ID!) {
    getLowTaxBrokerOrderForCompany(companyId: $companyId) {
      id
      submissionId
      productType
      ownerType
      ownerId
      entityType
      entityId
      status
      extra
      createdAt
      updatedAt
      transferredAt
    }
  }
`;

export const GetLTBOrderForCompany = (companyId: string | null | undefined) =>
  useQuery<GetLowTaxBrokerOrderForCompany, GetLowTaxBrokerOrderForCompanyVariables>(
    GET_LOW_TAX_BROKER_ORDER_FOR_COMPANY,
    {
      variables: {
        companyId: companyId ?? ""
      },
      skip: !companyId
    }
  );
