import { FeatureFlags, TaskStatesEnum } from "../../../../../global-query-types";
import React, { useState } from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { StepButton } from "../StepButton/StepButton";
import { getCompanyCreationSteps_getCompanyCreationSteps } from "lib/api/queries/graphql/getCompanyCreationSteps";
import {
  ActionableTask,
  toActionableTaskList
} from "../../../../../common/types/ProcessStatesTypes";
import ProcessTasks from "../ProcessTasks/ProcessTasks";
import ProcessGroupWrapper from "./ProcessGroupWrapper/ProcessGroupWrapper";
import { DisabledFeatureFlag } from "../../../../../sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { FeatureFlag } from "../../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { TaskModal } from "../../../../../components/client/company/components/tasks/TaskModal";

export interface ProcessGroupProps extends WithTranslation {
  index: number;
  group: getCompanyCreationSteps_getCompanyCreationSteps;
  nextGroupState: TaskStatesEnum | null | undefined;
  handleTaskStateChange: (ref: string, state: TaskStatesEnum) => Promise<void>;
}

const ProcessGroup = ({
  handleTaskStateChange,
  index,
  group,
  t,
  tReady,
  i18n,
  nextGroupState
}: ProcessGroupProps) => {
  const isInProgress = group.state === TaskStatesEnum.IN_PROGRESS;
  const isBeforeInProgress = !isInProgress && nextGroupState === TaskStatesEnum.IN_PROGRESS;
  const [depotSetupModalVisibility, setDepotSetupModalVisibility] = useState(false);
  const depotSetupModalStep = group.steps?.find((step) =>
    step.tasks?.find((task) => task.ref === "brokerDepotCreated")
  );
  const task =
    toActionableTaskList(depotSetupModalStep?.tasks).find(
      (task) => task.ref === "brokerDepotCreated"
    ) ?? null;

  const DepotSetupModal = () => {
    return task ? (
      <TaskModal
        task={task}
        onStateChange={handleTaskStateChange}
        onHide={() => setDepotSetupModalVisibility(false)}
        t={t}
        isVisible={depotSetupModalVisibility}
        tReady={tReady}
        i18n={i18n}
      />
    ) : (
      <></>
    );
  };

  return (
    <div>
      {depotSetupModalStep && <DepotSetupModal />}
      <div
        data-testid="process-state-group"
        className={`process-group-bis process-group-bis--${group.state} ${
          isBeforeInProgress && "process-group-bis--before-in-progress"
        }`}
        key={group.ref}>
        <StepButton
          state={group.state}
          index={index}
          isOpen={isInProgress}
          data-testid="process-state-group-number"
        />

        <ProcessGroupWrapper group={group}>
          <div
            className="process-group-bis__description"
            data-testid="process-state-group-description">
            {
              <div>
                <DisabledFeatureFlag name={FeatureFlags.LowTaxBrokerFromDepositAccountSetup}>
                  {t(`company-founding-steps:groups.${group.ref}-description`)}
                </DisabledFeatureFlag>
                <FeatureFlag name={FeatureFlags.LowTaxBrokerFromDepositAccountSetup}>
                  <Trans
                    i18nKey={
                      task && task.state === TaskStatesEnum.COMPLETED
                        ? `company-founding-steps:groups.${group.ref}-description`
                        : `company-founding-steps:groups.${group.ref}-description${
                            group.ref === "tradingSetup" ? "-brokersLink" : ""
                          }`
                    }
                    components={{
                      brokersLink: (
                        <a
                          href="https://www.ride.capital/faq/depotvergleich?utm_source=app&utm_medium=app"
                          target="_blank"
                          rel="noopener noreferrer">
                          here
                        </a>
                      ),
                      skipLink:
                        task?.state === TaskStatesEnum.TO_BE_DONE ? (
                          // eslint-disable-next-line
                          <a
                            data-testid="skip-as-anchor"
                            // eslint-disable-next-line
                            href="javascript:"
                            onClick={() => setDepotSetupModalVisibility(true)}>
                            skip
                          </a>
                        ) : (
                          <span data-testid="skip-as-span">skip</span>
                        )
                    }}
                  />
                </FeatureFlag>
              </div>
            }
          </div>

          {group.steps?.map((step, index) => (
            <GroupStep
              key={index}
              tasks={toActionableTaskList(step.tasks)}
              handleTaskStateChange={handleTaskStateChange}
            />
          ))}
        </ProcessGroupWrapper>
      </div>
    </div>
  );
};

const GroupStep = ({
  tasks,
  handleTaskStateChange
}: {
  tasks: ActionableTask[];
  handleTaskStateChange: (ref: string, state: TaskStatesEnum) => Promise<void>;
}) => {
  return (
    <div data-testid="process-state-group-step">
      <div>
        <ProcessTasks tasks={tasks} handleTaskStateChange={handleTaskStateChange} />
      </div>
    </div>
  );
};

export default withTranslation(["client", "company-founding-steps"])(ProcessGroup);
