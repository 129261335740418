import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  ClientTagsCompanyInput,
  ClientTagsPersonInput,
  isAdminServiceUser,
  isCreationServiceUser,
  isEmployeesCompany,
  isREInvestor,
  isShelfCompany,
  isTestCompany,
  isWePaCompany
} from "lib/types/clients/categories";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";

export interface ClientTagsProps extends WithTranslation {
  client: ClientTagsPersonInput & ClientTagsCompanyInput;
}

const ClientTags = ({ client, t }: ClientTagsProps) => {
  const needsREInvestorBadge = isREInvestor(client);
  const needsWePaBadge = isWePaCompany(client);
  const needsAdminBadge = isAdminServiceUser(client);
  const needsAdminOldBadge = needsAdminBadge && !client.adminService?.tier;
  const needsAdminLiteBadge = needsAdminBadge && client.adminService?.tier === "lite";
  const needsAdminStandardBadge = needsAdminBadge && client.adminService?.tier === "standard";
  const needsAdminProBadge = needsAdminBadge && client.adminService?.tier === "pro";
  const needsCreationBadge = isCreationServiceUser(client);
  const needsShelfBadge = isShelfCompany(client);
  const needsTestBadge = isTestCompany(client);
  const needsEmployeesBadge = isEmployeesCompany(client);
  const needsRIDESteuerberatungBadge =
    client.adminServiceInvoiceData?.isClientOf || client.wepaServiceInvoiceData?.isClientOf;

  return (
    <div data-testid="client-tags">
      {needsWePaBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:we-pa")}
          dataTestId="tag-we-pa"
        />
      )}

      {needsAdminOldBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:admin-old")}
          dataTestId="tag-admin-old"
        />
      )}

      {needsAdminLiteBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:admin-lite")}
          dataTestId="tag-admin-lite"
        />
      )}

      {needsAdminStandardBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:admin-standard")}
          dataTestId="tag-admin-standard"
        />
      )}

      {needsAdminProBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:admin-pro")}
          dataTestId="tag-admin-pro"
        />
      )}

      {needsCreationBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:creation")}
          dataTestId="tag-creation"
        />
      )}

      {needsREInvestorBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:re-investor")}
          dataTestId="tag-re-investor"
        />
      )}

      {needsShelfBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:shelf-company")}
          dataTestId="tag-shelf-company"
        />
      )}

      {needsTestBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:test-company")}
          dataTestId="tag-test-company"
        />
      )}

      {needsEmployeesBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t("nominee-dashboard:client-tags:employees")}
          dataTestId="tag-employees-company"
        />
      )}
      {needsRIDESteuerberatungBadge && (
        <RideBadgeStatus
          variant={RideBadgeVariantsEnum.INVERT_SOLID}
          color={RideBadgeColorsEnum.SUCCESS}
          label={t(
            `nominee-dashboard:client-tags.${
              client.adminServiceInvoiceData?.isClientOf ??
              client.wepaServiceInvoiceData?.isClientOf
            }`
          )}
          dataTestId="tag-vvSteuerberatung"
        />
      )}
    </div>
  );
};

export default withTranslation(["nominee-dashboard"])(ClientTags);
