import React from "react";
import { ActionableTask, ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import { TaskStatesEnum } from "global-query-types";
import ChoosePaymentMethodTask from "./ChoosePaymentMethodTask";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import { useParams } from "react-router";
import RequestFeedbackHandler from "../../../../../components/generic/RequestFeedbackHandler";
import { GET_STRIPE_CHECKOUT_SESSION } from "lib/api/mutations/GET_STRIPE_CHECKOUT_SESSION";
import {
  getStripeCheckoutSession,
  getStripeCheckoutSessionVariables
} from "lib/api/mutations/graphql/getStripeCheckoutSession";
import { useApolloClient } from "@apollo/react-hooks";
import { LocationHelperProps, withLocationHelper } from "../../../../../common/LocationHelper";
import { PaymentPlatformAccount } from "../../../../../payments-query-types";

interface ChoosePaymentMethodTaskContainerProps {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

export const ChoosePaymentMethodTaskContainer = withLocationHelper(
  ({
    task,
    onChange,
    locationHelper
  }: ChoosePaymentMethodTaskContainerProps & LocationHelperProps) => {
    const { id } = useParams<{ id: string }>();
    const companyByIdQuery = QueryCompanyById(id);
    const company = companyByIdQuery?.data?.companyById;

    const client = useApolloClient();

    const goToCheckoutSession = async () => {
      const currentUrl = new URL(document.URL);
      currentUrl.searchParams.set("checkout_source", "gs-task");

      const response = await client.query<
        getStripeCheckoutSession,
        getStripeCheckoutSessionVariables
      >({
        query: GET_STRIPE_CHECKOUT_SESSION,
        variables: {
          companyId: id,
          successUrl: currentUrl.toString(),
          cancelUrl: currentUrl.toString(),
          paymentPlatformAccount: PaymentPlatformAccount.RIDE
        }
      });

      const checkoutSessionUrl = response.data.getStripeCheckoutSession?.redirectUrl;
      if (checkoutSessionUrl) {
        locationHelper.redirect(checkoutSessionUrl);
      }
    };

    return (
      <div data-testid={"choose-payment-method-task-container"}>
        <RequestFeedbackHandler requests={[companyByIdQuery]}>
          {!!company && (
            <ChoosePaymentMethodTask
              task={task}
              onChange={onChange}
              company={company}
              goToCheckoutSession={goToCheckoutSession}
            />
          )}
        </RequestFeedbackHandler>
      </div>
    );
  }
);
