import React, { useState } from "react";
import {
  ButtonSize,
  ButtonVariant
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { ModalForm } from "../../../../components/nominee/ModalForm/ModalForm";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import AdminClubDealForm, { AdminClubFormValues } from "../AdminClubDealForm/AdminClubDealForm";
import { WithTranslation } from "react-i18next";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import logger from "../../../../common/Logger";

interface AdminClubDealAddModalProps extends WithTranslation {
  realEstateList: { id: string; label: string }[];
  addRequest?: MutationResult | QueryResult | RequestFeedbackInfo;
  onSubmit?: (values: AdminClubFormValues) => void;
}

const AdminClubDealAddModal = ({
  addRequest,
  onSubmit,
  realEstateList,
  t
}: AdminClubDealAddModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnSubmit = async (variables) => {
    try {
      await onSubmit?.(variables);
      setIsOpen(false);
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <ModalForm
      requestModalState={setIsOpen}
      isOpen={isOpen}
      isAdd={true}
      buttonSize={ButtonSize.Medium}
      buttonVariant={ButtonVariant.Secondary}
      buttonLabel={t("nominee-dashboard:investment-club-deals.add-club-deal")}
      title={t("nominee-dashboard:investment-club-deals.add-club-deal")}>
      <AdminClubDealForm
        realEstateList={realEstateList}
        request={addRequest}
        onSubmit={handleOnSubmit}
      />
    </ModalForm>
  );
};

export default withTranslationReady(["nominee-dashboard"])(AdminClubDealAddModal);
