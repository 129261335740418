import * as Yup from "yup";
import { TFunction } from "i18next";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";

const PasswordSchema = (passwordPolicy: AppConfigQuery_appConfig_passwordPolicy, t: TFunction) => {
  let validation = Yup.string()
    .required(t("generic:validation-required"))
    .min(
      passwordPolicy.minimumLength!,
      t("password:error_min", { minimum: passwordPolicy.minimumLength })
    )
    .max(
      passwordPolicy.maximumLength!,
      t("password:error_max", { maximum: passwordPolicy.maximumLength })
    );

  if (passwordPolicy.mustHaveUppercase) {
    validation = validation.uppercase(t("password:error_upper"));
  }

  if (passwordPolicy.mustHaveLowercase) {
    validation = validation.lowercase(t("password:error_lower"));
  }

  if (passwordPolicy.mustHaveDigits) {
    validation = validation.matches(/[0-9]+/, t("password:error_digits"));
  }

  if (passwordPolicy.mustHaveSymbols) {
    validation = validation.matches(/[^\w\n]|_/, t("password:error_symbols"));
  }

  if (passwordPolicy.mustNotHaveSpaces) {
    validation = validation.matches(/^\s*\S+\s*$/, t("password:error_whitespace"));
  }

  if (passwordPolicy.blacklist) {
    validation = validation.notOneOf(passwordPolicy.blacklist, t("password:error_blacklist"));
  }

  if (passwordPolicy.finApiWorkaround) {
    validation = validation.matches(
      /^[^*%\\/|='\\"“”‘’<>]+$/,
      t("password:error_finapi_workaround")
    );
  }

  return validation;
};

export default PasswordSchema;
