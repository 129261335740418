export type SecurityQuestionType = {
  question: SecurityQuestion;
  answer: string;
};
export enum QuestionNumber {
  First = "first",
  Second = "second",
  Third = "third"
}

export enum SecurityQuestion {
  BEST_FRIEND_CHILDHOOD = "what_was_the_name_of_a_best_friend_during_childhood",
  FATHERS_OCCUPATION = "what_was_your_fathers_occupation",
  FIRST_BOSS = "what_was_the_name_of_your_first_boss",
  FAVORITE_VACATION_SPOT = "where_is_your_favorite_vacation_spot",
  FAVORITE_MUSICIAN = "who_is_your_favorite_musician",
  FAVORITE_RESTAURANT = "what_is_your_favorite_restaurant",
  FAVORITE_BOOK = "what_is_the_title_of_your_favorite_book",
  FAVORITE_SPORTS_TEAM = "what_is_the_name_of_your_favorite_sports_team",
  CITY_MARRIAGE = "in_what_city_were_you_married",
  FIRST_CONCERT = "what_was_the_first_concert_you_attended",
  GRANDMOTHER_NAME = "what_is_the_name_of_your_grandmother",
  BORN_PLACE = "where_were_you_born",
  SHOE_SIZE = "what_is_your_shoe_size",
  BORN_CITY = "in_which_city_were_you_born",
  FIRST_CAR = "what_was_your_first_car",
  FAVORITE_POLITICIAN_LASTNAME = "what_is_the_lastname_of_your_favorite_politician",
  PARENTS_MEET_LOCATION = "where_did_your_parents_meet"
}
