import React from "react";

export const PaginationLast = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="29.5"
      y="29.5"
      width="27"
      height="27"
      rx="2.5"
      transform="rotate(-180 29.5 29.5)"
      stroke="#E1E1E1"
    />
    <path
      d="m18 12 4 4-4 4M11 12l4 4-4 4"
      stroke="#A3A3A3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
