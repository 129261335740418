import React, { useState } from "react";
import {
  ButtonSize,
  ButtonVariant
} from "../../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { NomineeRealEstateFormContainer } from "./NomineeRealEstateFormContainer";
import { ModalForm } from "../../../ModalForm/ModalForm";

export const NomineeRealEstateModal = ({ t }) => {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <ModalForm
      buttonVariant={ButtonVariant.Secondary}
      buttonSize={ButtonSize.Medium}
      buttonLabel={t("realEstate:add-real-estate-asset")}
      isAdd={true}
      isOpen={isOpen}
      requestModalState={setIsOpen}
      title={t("realEstate:add-real-estate")}>
      <NomineeRealEstateFormContainer hideForm={() => setIsOpen(false)} />
    </ModalForm>
  );
};
