import i18next from "i18next";

export class ValueFormatter {
  defaultValue: any;

  constructor(defaultValue: any = "---") {
    this.defaultValue = defaultValue;
  }

  format(value, ...args) {
    if (typeof value === "number") {
      if (isNaN(value)) return this.defaultValue;

      const locale = this.getLocale(args);
      const formatter = new Intl.NumberFormat(locale);
      return formatter.format(value);
    }

    if (!value) return this.defaultValue;
    if (typeof value === "string") return value;
    return this.defaultValue;
  }

  protected getLocale(args) {
    if (Array.isArray(args) && args.length > 0) {
      args = args[0];
    }

    let locale = i18next.language;
    if (args && args.locale) {
      locale = args.locale;
      delete args.locale;
    }
    if (!locale) {
      locale = "de-DE";
    }
    return locale;
  }
}
