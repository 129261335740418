import React from "react";

export const PhoneIcon = () => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9996 13.7278V16.287C15.0004 16.5245 14.9602 16.7597 14.8817 16.9774C14.8031 17.1951 14.6879 17.3905 14.5435 17.5511C14.399 17.7117 14.2285 17.834 14.0428 17.9101C13.8571 17.9862 13.6603 18.0145 13.465 17.9931C11.299 17.7078 9.2183 16.8109 7.39023 15.3742C5.68945 14.0644 4.24748 12.3169 3.16674 10.2558C1.9771 8.03034 1.23677 5.49657 1.00571 2.85976C0.988123 2.62386 1.01126 2.3861 1.07364 2.16163C1.13603 1.93716 1.2363 1.73089 1.36807 1.55596C1.49984 1.38102 1.66022 1.24125 1.839 1.14555C2.01779 1.04985 2.21106 1.00031 2.40651 1.00008H4.51826C4.85987 0.996008 5.19105 1.14261 5.45007 1.41257C5.70909 1.68252 5.87828 2.05741 5.92609 2.46735C6.01522 3.28635 6.18052 4.09049 6.41883 4.86445C6.51354 5.16978 6.53403 5.50162 6.47789 5.82063C6.42175 6.13965 6.29132 6.43248 6.10207 6.66441L5.20809 7.7478C6.21016 9.88348 7.6693 11.6518 9.43159 12.8662L10.3256 11.7828C10.517 11.5534 10.7586 11.3954 11.0218 11.3273C11.2851 11.2593 11.5589 11.2841 11.8108 11.3989C12.4495 11.6877 13.113 11.888 13.7888 11.9961C14.1308 12.0545 14.4431 12.2632 14.6663 12.5825C14.8895 12.9018 15.0081 13.3094 14.9996 13.7278Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
