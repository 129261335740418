import React from "react";
import {
  GetInvestmentById_getInvestmentById,
  GetInvestmentById_getInvestmentById_asset_realEstate_owner_memberships_company
} from "../../../../lib/api/queries/graphql/GetInvestmentById";
import { InvestmentState, LegalForm, StorageNodesEntityType } from "../../../../global-query-types";
import InvestmentTasks from "../InvestmentTasks/InvestmentTasks";
import InvestmentAssetManagement from "../InvestmentAssetManagement/InvestmentAssetManagement";
import { LocalStorageNode } from "../../../../lib/dataAdapters/toRemoteStorageNode";
import NodeTreeCard from "../../Files/NodeTreeCard/NodeTreeCard";

interface ClubDealSectionsProps {
  investment: GetInvestmentById_getInvestmentById;
  state: InvestmentState;
  company: GetInvestmentById_getInvestmentById_asset_realEstate_owner_memberships_company | null;
  files: LocalStorageNode[];
}

export const ClubDealSections = ({ investment, state, company, files }: ClubDealSectionsProps) => {
  return (
    <div data-testid="ride-club-sections">
      {state === InvestmentState.RideClubInProcess && <InvestmentTasks />}
      {state === InvestmentState.RideClubCompleted && (
        <>
          <InvestmentAssetManagement
            className="mt-6"
            name={company?.name ?? ""}
            capitalContribution={company?.capitalContribution ?? 0}
            legalForm={company?.legalForm ?? LegalForm.GmbH}
            tokenizedShares={company?.tokenizedShares ?? 0}
          />

          <div className="mt-5" data-testid="investment-files">
            {files.map((node, index) => (
              <NodeTreeCard
                title={node.name ?? ""}
                localNodes={node.childNodes ?? []}
                parentId={node.nodeId ?? ""}
                entityType={StorageNodesEntityType.Investment}
                entityId={investment.id}
                readOnly
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
