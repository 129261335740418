import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { LegalFormField } from "../../../../../generic/company-form/fields";
import { LegalForm } from "../../../../../../global-query-types";
import { OptionItem } from "../../../../../generic/Select";
import { CompanyNameField } from "../../../../../public/CompanyCreation/fields";

export interface IProps {
  t: TFunction;
  formik;
  legalForms: LegalForm[];
  className?: string;
}

export const CompanyFullNameInput = ({ t, formik, className, legalForms }: IProps) => {
  const legalFormOptions: OptionItem[] = legalForms.map((legalForm) => ({
    title: t(`generic:legalForm.${legalForm}`),
    value: legalForm
  }));

  return (
    <Row className={className}>
      <Col>
        <CompanyNameField t={t} label={t("generic:company-name")} formik={formik} placeholder="" />
      </Col>
      <Col>
        <LegalFormField t={t} formik={formik} legalForms={legalFormOptions} />
      </Col>
    </Row>
  );
};
