import React, { useMemo } from "react";
import { withTranslation } from "react-i18next";
import { FlowTypeEnum, RolesInGroup } from "../../../global-query-types";
import { getUniqPeopleAs } from "../../../common/getUniqPeopleAs";
import { AdminServiceListProps } from "../AdminServiceList/AdminServiceList";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideHeaderColumn } from "../../../lib/types/types";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import CompanyNameOrUnnamed from "../../../components/common/CompanyNameOrUnnamed";
import { formatValue } from "../../../common/formatters";
import Timestamp from "../../../components/generic/Timestamp";
import UserFinApiAccountStatus from "../UserFinApiAccountStatus/UserFinApiAccountStatus";
import { CompanyCreationsQuery_companyCreations_items_company_group_memberships_person_user } from "../../../lib/api/queries/graphql/CompanyCreationsQuery";
import RideTableCellMultiSlot from "../../../sharedComponents/RideTableCellMultiSlot/RideTableCellMultiSlot";
import { useSimpleSortBehaviour } from "../../../sharedComponents/useSimpleSortBehaviour/useSimpleSortBehaviour";
import ResumeLinkContainer from "../ResumeLink/ResumeLinkContainer";
import { ArchiveServiceContainer } from "../ArchiveService/ArchiveService";
import { DocumentTemplateDropdown } from "../DocumentTemplateDropdown/DocumentTemplateDropdown";
import { companyName } from "../../../lib/formatters/companyFormatter";
import { personNameWithFallback } from "lib/formatters/personFormatter";

const CompanyCreationList = ({ list, refetch, t }: AdminServiceListProps) => {
  const enrichedData = useMemo(
    () =>
      list
        .filter((creation) => !!creation.company)
        .map((creation) => {
          const shareholders = getUniqPeopleAs(creation.company!.group.memberships, [
            RolesInGroup.Shareholder,
            RolesInGroup.Director
          ]);

          const referralCode = creation.company!.discountCodeUse?.discountCode?.code ?? "";
          const firstPerson = shareholders[0]?.person;
          const inviteStatus =
            firstPerson?.user as CompanyCreationsQuery_companyCreations_items_company_group_memberships_person_user;

          return {
            ...creation,
            shareholders,
            referralCode,
            inviteStatus,
            companyName: companyName(creation.company!),
            userName: `${firstPerson?.firstName ?? ""} ${firstPerson?.lastName ?? ""}`,
            lastStep: `${creation.currentStep} ${creation.updatedAt}`
          };
        })
        .filter(
          (c) =>
            ![FlowTypeEnum.ADMIN_SERVICE, FlowTypeEnum.ADMIN_SERVICE_ONBOARDING].includes(
              c.flowType!
            )
        ),
    [list]
  );

  const columns: RideHeaderColumn[] = [
    { name: "companyName", label: t("nominee-dashboard:company-creation.company") },
    { name: "userName", label: t("nominee-dashboard:company-creation.user") },
    { name: "createdAt", label: t("nominee-dashboard:company-creation.flow-started") },
    { name: "currentStep", label: t("nominee-dashboard:company-creation.flow-current-step") },
    { name: "orderedAt", label: t("nominee-dashboard:company-creation.ordered-at") },
    { name: "inviteStatus", label: t("nominee-dashboard:company-creation.invite-status") },
    { name: "postOrderStatus", label: t("nominee-dashboard:company-creation.action") }
  ];

  const { sortedData, sortCallback } = useSimpleSortBehaviour(enrichedData);

  return (
    <div className="company-creation-list-table">
      <RideTableHeader columns={columns} sortCallback={sortCallback} />
      {sortedData.map((item, index) => (
        <RideTableRow key={index} colCount={columns.length} className="company-creation-list-row">
          <RideTableCellMultiSlot
            classes="mobile-left"
            dataTestId="company-name-and-flow-type-and-referral">
            <div data-testid="company-name">
              <TextLink
                to={`/nominee-companies/${item.company?.id}`}
                variant={LinkVariant.primary}
                data-testid="company-link">
                <CompanyNameOrUnnamed company={item.company!} />
              </TextLink>
            </div>
            <div
              className="company-creation-list-secondary-text company-creation-list-small"
              data-testid="flow-type">
              {t(`nominee-dashboard:company-creation.flow.${item.flowType}`)}
            </div>
            <div
              data-testid="referral"
              className="company-creation-list-secondary-text company-creation-list-small">
              ref: {formatValue(item.referral)} {formatValue(item.referralCode)}
            </div>
          </RideTableCellMultiSlot>

          <RideTableCellMultiSlot classes="mobile-right">
            {item.shareholders.map((shareholder) => (
              <div key={shareholder.person?.id} data-testid="user-name">
                <TextLink
                  to={`/nominee-persons/${shareholder.person?.id}`}
                  variant={LinkVariant.secondary}
                  data-testid="person-link">
                  {personNameWithFallback(shareholder.person)}
                </TextLink>
              </div>
            ))}
          </RideTableCellMultiSlot>

          <RideTableCellMultiSlot classes="mobile-left" dataTestId="created-at">
            <div className="company-creation-list-secondary-text">
              <Timestamp date={item.createdAt} />
            </div>
          </RideTableCellMultiSlot>

          <RideTableCellMultiSlot classes="mobile-right" dataTestId="current-step">
            <div className="company-creation-list-secondary-text">
              <div>{t(`nominee-dashboard:company-creation.step.${item.currentStep}`)}</div>
              <div>
                <Timestamp date={item.updatedAt} />
              </div>
            </div>
          </RideTableCellMultiSlot>

          <RideTableCellMultiSlot classes="mobile-left" dataTestId="ordered-at">
            <div className="company-creation-list-secondary-text">
              <Timestamp date={item.orderedAt} />
            </div>
          </RideTableCellMultiSlot>

          <RideTableCellMultiSlot classes="mobile-right" dataTestId="invite-status">
            <div className="company-creation-list-secondary-text">
              <Timestamp date={item.inviteStatus?.invitedAt ?? ""} />
            </div>
            <UserFinApiAccountStatus
              hasFinApiAccount={item.inviteStatus?.hasFinApiAccount ?? false}
            />
          </RideTableCellMultiSlot>

          <RideTableCellMultiSlot classes="company-creation-list-cell-actions mobile-full">
            <div className="company-creation-list-button-inline">
              <DocumentTemplateDropdown
                companyCreationId={item.id}
                type="company-creation"
                t={t}
                dataTestId="company-creation-list"
              />
            </div>
            <ResumeLinkContainer id={item.id} t={t} />
            <div className="company-creation-list-button-fullwidth company-creation-list-small">
              <ArchiveServiceContainer id={item.id} t={t} refetchCompanyCreation={refetch} />
            </div>
          </RideTableCellMultiSlot>
        </RideTableRow>
      ))}
    </div>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(CompanyCreationList);
