import React from "react";
import RideCardTable from "sharedComponents/RideCardTable/RideCardTable";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { UserRole } from "global-query-types";
import WepaBookingsTableContainer from "sharedComponents/WepaBookingsTable/WepaBookingsTableContainer";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { MyTaxAdvisorCompany } from "taxAdvisor/types/MyTaxAdvisorCompany";

interface WepaBookingsSectionProps extends WithTranslation {
  gmbh: GmbHDetails;
  myTaxAdvisorCompany?: MyTaxAdvisorCompany | null;
}

const WepaBookingsSection = ({ t, gmbh, myTaxAdvisorCompany }: WepaBookingsSectionProps) => {
  return (
    <>
      <h2 data-testid="wepa-bookings-section-title">{t("wepa-bookings:title")}</h2>
      <RideCardTable title={t("wepa-bookings:title")} data-testid="wepa-bookings-section">
        <WepaBookingsTableContainer
          asUser={UserRole.Nominee}
          gmbh={gmbh}
          myTaxAdvisorCompany={myTaxAdvisorCompany}
        />
      </RideCardTable>
    </>
  );
};

export default withTranslationReady("wepa-bookings")(WepaBookingsSection);
