import {
  Product,
  SelectedProduct,
  UnifiedShortOrderFormServerData
} from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormServerData";
import { OrderSummary } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { UnifiedShortOrderFormValues } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { LegalForm } from "global-query-types";
import { UtmParams } from "lib/services/UtmService/UtmService";

export const toUnifiedShortOrderFormData = (
  data: UnifiedShortOrderFormValues,
  utmParams?: UtmParams
): UnifiedShortOrderFormServerData => {
  const orderSummary = OrderSummary.fromShortOrderForm(data);
  return {
    selectedProducts: getProductSelection(orderSummary),
    personData: {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email
    },
    companyData: !data.companyFoundingService ? buildCompanyData(data) : undefined,
    orderSummary: {
      grundungServicePrice: orderSummary.getRecurringPrice(Product.GS),
      adminServicePrice: orderSummary.getRecurringPrice(Product.AS),
      lowTaxBrokerPrice: orderSummary.getRecurringPrice(Product.LTB),
      oneTimeFee:
        orderSummary.getTotalOneTimeFee() > 0 ? orderSummary.getTotalOneTimeFee() : undefined,
      discountValue: orderSummary.getTotalDiscount(),
      totalPrice: orderSummary.getTotal()
    },
    termsAndConditions: {
      privacyPolicyCheckbox: data.privacyPolicyCheckbox ?? false,
      termsAndConditionsCheckbox: data.termsAndConditionsCheckbox ?? false,
      allowsContact: data.allowsContact ?? false
    },
    discountCode: data.discountCode,
    orderSource: {
      ...(utmParams ?? {}),
      userReportedSource: data.userReportedSource
    },
    companyOwnsRealEstate: data.companyOwnsRealEstate,
    companyDoesNotFallIntoCategories: data.companyDoesNotFallIntoCategories,
    tradesCrypto: data.tradesCrypto,
    companyActivelyOperating: data.companyActivelyOperating,
    companyIssuesMoreThan50OutgoingInvoicesYearly:
      data.companyIssuesMoreThan50OutgoingInvoicesYearly,
    hasFiveOrMoreEmployees: data.hasFiveOrMoreEmployees,
    companyKeepsPhysicalInventory: data.companyKeepsPhysicalInventory,
    truthfulInformationProvided: data.truthfulInformationProvided
  };
};

function getProductSelection(orderSummary: OrderSummary): SelectedProduct[] {
  return orderSummary.getItems().map((i) => {
    return { product: i.product, tier: i.tier, numberOfAdditionalYears: i.numberOfAdditionalYears };
  });
}

function buildCompanyData(data: UnifiedShortOrderFormValues) {
  return {
    legalForm: data.companyLegalForm as LegalForm,
    hasCorporateShareholders: data.hasCorporateShareholders
      ? data.hasCorporateShareholders === "yes"
      : undefined,
    registerCourt: data.registerCourt ?? "",
    registration: data.registration ?? "",
    companyName: data.companyName ?? "",
    northDataUniqueKey: data.northDataUniqueKey ?? ""
  };
}
