import React from "react";
import { ClubDealsListContainer } from "../../components/investments/ClubDealsList/ClubDealsListContainer";
import { ModalLayout } from "../../../sharedComponents/templates/ModalLayout/ModalLayout";
import { useHistory } from "react-router-dom";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";

export const ClubDealListScene = () => {
  const history = useHistory();

  const handleClose = () => {
    history.push(`/client/investments`);
  };

  return (
    <FeatureFlag name={FeatureFlags.ClientInvestments}>
      <ModalLayout onClose={handleClose}>
        <ClubDealsListContainer />
      </ModalLayout>
    </FeatureFlag>
  );
};
