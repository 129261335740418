import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import React from "react";
import AddNewBankAccount from "./AddNewBankAccount";
import { useMutation } from "react-apollo";
import {
  UpsertBankAccount,
  UpsertBankAccountVariables
} from "lib/api/mutations/graphql/UpsertBankAccount";
import { UPSERT_BANK_ACCOUNT } from "lib/api/mutations/UPSERT_BANK_ACCOUNT";
import { useHistory, useParams } from "react-router";
import { BankType } from "global-query-types";
import { MY_GMBHS_QUERY } from "components/client/MyGmbHs/Queries";

export const AddNewBankAccountContainer = () => {
  const [upsertBankAccount, updateRequest] = useMutation<
    UpsertBankAccount,
    UpsertBankAccountVariables
  >(UPSERT_BANK_ACCOUNT);
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();

  const onSubmit = async (bankProvider: BankType) => {
    await upsertBankAccount({
      variables: {
        bankProvider,
        companyId
      },
      refetchQueries: [{ query: MY_GMBHS_QUERY }]
    });
    history.replace(`/client/banking`);
  };

  return (
    <RequestFeedbackHandler requests={[updateRequest]}>
      <AddNewBankAccount onSubmit={onSubmit} />
    </RequestFeedbackHandler>
  );
};
