import React from "react";
import RideIcon from "uiLibrary/components/RideIcon/RideIcon";
import { RideSize } from "lib/types/types";

interface ErrorFileCardProps {
  description: string;
  fileName: string;
  onErrorClose: () => void;
}

export const ErrorFileCard = ({ description, fileName, onErrorClose }: ErrorFileCardProps) => {
  const moduleName = "error-file-card";

  return (
    <div data-testid="error-file-card" className={moduleName}>
      <div className={`${moduleName}__error-icon`}>
        <RideIcon name="error" />
      </div>
      <div className={`${moduleName}__error-info`}>
        <span className={`${moduleName}__description`}>{description}</span>
        <span className={`${moduleName}__file-name`}>{fileName}</span>
      </div>
      <div className={`${moduleName}__error-close`}>
        <RideIcon
          dataTestId="close-error-button"
          onClick={onErrorClose}
          name="close-max"
          size={RideSize.SMALL}
        />
      </div>
    </div>
  );
};
