import React from "react";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { useParams } from "react-router";
import { useMutation } from "react-apollo";
import {
  UpdateCompanyById,
  UpdateCompanyByIdVariables
} from "lib/api/mutations/graphql/UpdateCompanyById";
import { UPDATE_COMPANY_BY_ID } from "../../../../lib/api/mutations/UPDATE_COMPANY_BY_ID";
import ClientTextFieldEdit from "../../ClientTextFieldEdit/ClientTextFieldEdit";
import StringSchema from "yup/lib/string";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { Trans, WithTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getSubmitPath } from "../../../../lib/routing/getSubmitPath";
import { QueryCompanyById } from "../../../../lib/api/queries/GetCompanyById";

export interface CompanyTextAreaFieldEditContainerProps extends WithTranslation {
  fieldName: string;
  section: string;
  asTextArea?: boolean;
  validation?: StringSchema;
  subtitle?: boolean;
}

const CompanyTextFieldEditContainer = ({
  fieldName,
  section,
  asTextArea,
  validation,
  subtitle = false,
  t
}: CompanyTextAreaFieldEditContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const companyRequest = QueryCompanyById(id);

  const [updateCompanyById, updateCompanyByIdRequests] = useMutation<
    UpdateCompanyById,
    UpdateCompanyByIdVariables
  >(UPDATE_COMPANY_BY_ID);
  const company = companyRequest.data?.companyById;
  const companyId = company?.id ?? "";

  const handleSave = async (value: string): Promise<void> => {
    await updateCompanyById({
      variables: {
        companyId,
        [fieldName]: value
      }
    });
  };

  const getSubtitle = () => {
    if (!subtitle) return <></>;

    return (
      <Trans
        i18nKey={`client:company.modal-subtitle.${fieldName}`}
        values={{ company: company }}
        components={{ bold: <strong /> }}
      />
    );
  };

  return (
    <RequestFeedbackHandler requests={[companyRequest]} noLoadingAnimation={true}>
      {company && (
        <ClientTextFieldEdit
          requests={[updateCompanyByIdRequests]}
          onSave={handleSave}
          value={company[fieldName] ?? ""}
          label={t(`client:company.field.${fieldName}`)}
          submitPath={getSubmitPath(pathname, companyId, section)}
          asTextArea={asTextArea}
          validation={validation}
          subtitle={getSubtitle()}
        />
      )}
    </RequestFeedbackHandler>
  );
};

export default withTranslationReady(["client"])(CompanyTextFieldEditContainer);
