import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ReviewCard } from "../../../sharedComponents/ReviewCard/ReviewCard";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { TradeCompany } from "lib/models/client/LowTaxBroker/TradeCompany";
import { ReviewFieldList } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewFieldList/ReviewFieldList";

type BrokerSetupData = Pick<
  LowTaxBrokerOrderData,
  "consentToHandlePersonalData" | "declareTaxInTheUS" | "securityQuestions"
>;

export interface ReviewBrokerSetupProps {
  setupData: BrokerSetupData;
  editLink?: string;
  isCompleted?: boolean;
  isNewBrokerEnabled: boolean;
}

export const ReviewBrokerSetup = withTranslationReady(["generic", "review-entry"])(
  ({
    t,
    setupData,
    editLink,
    tradeCompany,
    isCompleted,
    isNewBrokerEnabled
  }: ReviewBrokerSetupProps & TradeCompany & WithTranslation) => {
    const { consentToHandlePersonalData, declareTaxInTheUS, securityQuestions } = setupData;
    return (
      <ReviewCard dataTestId="broker-setup">
        <ReviewSectionTitle
          title={t("generic:review-entry.low-tax-broker.broker-setup")}
          editLink={editLink}
          isCompleted={isCompleted}
        />
        <ReviewFieldList>
          <ReviewField
            name="companyName"
            label={t("generic:review-entry.low-tax-broker.companyName")}
            value={tradeCompany.getTradeCompanyName()}
          />
          <ReviewField
            name="declareTaxInTheUS"
            label={t("generic:review-entry.low-tax-broker.declareTaxInTheUS")}
            value={
              declareTaxInTheUS === undefined
                ? undefined
                : declareTaxInTheUS === "yes"
                ? t("generic:yes")
                : t("generic:review-entry.low-tax-broker.not-declaring-tax-in-the-usa")
            }
          />
          {!isNewBrokerEnabled && (
            <>
              <ReviewField
                name="consentToHandlePersonalData"
                label={t("generic:review-entry.low-tax-broker.consentToHandlePersonalData")}
                value={
                  consentToHandlePersonalData === undefined
                    ? undefined
                    : consentToHandlePersonalData
                    ? t("generic:review-entry.low-tax-broker.agreed")
                    : t("generic:review-entry.low-tax-broker.not-declaring-tax-in-the-usa")
                }
              />
              <ReviewField
                name="securityQuestions"
                label={t("generic:review-entry.low-tax-broker.securityQuestions")}
                value={
                  securityQuestions &&
                  securityQuestions.first &&
                  securityQuestions.second &&
                  securityQuestions.third
                    ? t("generic:review-entry.low-tax-broker.completed")
                    : undefined
                }
              />
            </>
          )}
        </ReviewFieldList>
      </ReviewCard>
    );
  }
);
