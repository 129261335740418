import React from "react";
import { Accordion } from "react-bootstrap";
import { AccordionElement } from "../AccordionElement";
import { TFunction } from "i18next";
import { companyCreation } from "../../getCompletedSteps";
import CompanyCreationShareholdersForm from "../../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "../../graphql/MyCompanyCreationQuery";
import { ApolloQueryResult } from "apollo-client";

import { DirectorForm } from "../Director/DirectorForm";
import { CompanyCreationSharesForm } from "../Shares/CompanyCreationSharesForm";
import CompanyCreationNameForm from "../../../../../client/components/CompanyCreationNameForm/CompanyCreationNameForm";
import AddressForm from "../Address/AddressForm";
import { PersonalDetailForm } from "../PersonalDetails/PersonalDetailForm";
import { Order } from "../../../../../client/components/CompanyCreation/Steps/Order/Order";
import { FlowTypeEnum, RolesInGroup } from "../../../../../global-query-types";
import { RefetchAndResetActiveStep } from "../../RefetchAndResetActiveStep";
import { UserService } from "../../../../../lib/services/UserService/UserService";

interface ViewCompanyCreationStepsProps {
  t: TFunction;
  completedSteps: string[];
  showCreationSteps: boolean;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
}

export const ViewCompanyCreationSteps = ({
  completedSteps,
  showCreationSteps,
  myCompanyCreationDetails,
  t,
  refetchMyCompanyCreation,
  currentFlowType
}: ViewCompanyCreationStepsProps) => {
  const lastStep = completedSteps[completedSteps.length - 1] ?? companyCreation.STEP_ONE;

  const { refetchAndResetStep, resetActiveStep, toggleActiveKey, activeKey } =
    RefetchAndResetActiveStep({
      lastStep,
      refetchMyCompanyCreation,
      currentFlowType
    });

  const hasHoldingCompany = Boolean(
    myCompanyCreationDetails?.company?.group?.memberships?.find(
      (membership) => membership.role === RolesInGroup.Shareholder && !!membership.company
    )
  );
  const step5HeaderText = hasHoldingCompany
    ? t("trader-gmbh:steps-title.via-holding.director-details")
    : t("trader-gmbh:steps-title.shareholder-details");

  const isActiveUser = UserService.instance.isActiveUser;

  return (
    <Accordion activeKey={activeKey} data-testid="accordion-steps">
      <AccordionElement
        headerText={t("trader-gmbh:steps-title.shareholder")}
        eventKey={companyCreation.STEP_ONE}
        onToggle={toggleActiveKey}
        buttonText={"1"}
        disabled={showCreationSteps && !completedSteps.includes(companyCreation.STEP_ONE)}>
        <CompanyCreationShareholdersForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
          isActiveUser={isActiveUser}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("trader-gmbh:steps-title.company-shares")}
        eventKey={companyCreation.STEP_TWO}
        onToggle={toggleActiveKey}
        buttonText={"2"}
        disabled={!completedSteps.includes(companyCreation.STEP_TWO)}>
        <CompanyCreationSharesForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("trader-gmbh:steps-title.managing-director")}
        eventKey={companyCreation.STEP_THREE}
        onToggle={toggleActiveKey}
        buttonText={"3"}
        disabled={!completedSteps.includes(companyCreation.STEP_THREE)}>
        <DirectorForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("trader-gmbh:steps-title.company-name")}
        eventKey={companyCreation.STEP_FOUR}
        onToggle={toggleActiveKey}
        buttonText={"4"}
        disabled={!completedSteps.includes(companyCreation.STEP_FOUR)}>
        <CompanyCreationNameForm
          companyCreation={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={step5HeaderText}
        eventKey={companyCreation.STEP_FIVE}
        onToggle={toggleActiveKey}
        buttonText={"5"}
        disabled={!completedSteps.includes(companyCreation.STEP_FIVE)}>
        <PersonalDetailForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          resetActiveStep={resetActiveStep}
          refetchMyCompanyCreation={refetchMyCompanyCreation}
          currentFlowType={currentFlowType}
          hasHoldingCompany={hasHoldingCompany}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("trader-gmbh:steps-title.shareholder-address")}
        eventKey={companyCreation.STEP_SIX}
        onToggle={toggleActiveKey}
        buttonText={"6"}
        disabled={!completedSteps.includes(companyCreation.STEP_SIX)}>
        <AddressForm
          companyCreation={myCompanyCreationDetails}
          refetchAndResetStep={refetchAndResetStep}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("trader-gmbh:steps-title.order")}
        eventKey={companyCreation.STEP_SEVEN}
        onToggle={toggleActiveKey}
        buttonText={"7"}
        disabled={!completedSteps.includes(companyCreation.STEP_SEVEN)}>
        <Order
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          refetchAndResetStep={refetchAndResetStep}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
    </Accordion>
  );
};
