import React from "react";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";
import { AddressData } from "lib/models/client/AddressData";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";

export interface ReviewCompanyAddressProps {
  address: Partial<AddressData>;
  editLink: string;
  className?: string;
}

export const ReviewCompanyAddress = ({
  address,
  editLink,
  className = ""
}: ReviewCompanyAddressProps) => {
  return (
    <CardContainer className={className}>
      <ReviewEntry
        name="company-address"
        editLink={editLink}
        list={[
          <React.Fragment key={1}>
            {address.streetLine2 && <div>{address.streetLine2}</div>}
            <div>{address.streetLine1}</div>
            <div>
              {address.city}, {address.postalCode}
            </div>
            <div>{getCountryNameByCode(address.country)}</div>
          </React.Fragment>
        ]}
      />
    </CardContainer>
  );
};
