import * as Yup from "yup";

export const CompanyIBComplianceSchema = (t) => {
  return Yup.object().shape({
    "countries-list": Yup.string().required(t("generic:validation-required")),
    "company-is-not-in-a-regulatory-exchange": Yup.boolean().isTrue(
      t("generic:validation-required")
    ),
    "company-is-not-a-foreign-bank": Yup.boolean().isTrue(t("generic:validation-required"))
  });
};
