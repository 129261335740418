import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import UserBrokerSurvey, { SelectedAnswerInterface } from "./UserBrokerSurvey";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { useMutation } from "react-apollo";
import { SUBMIT_BROKERAGE_SURVEY } from "./mutations";
import {
  submitBrokerageSurvey,
  submitBrokerageSurveyVariables
} from "./graphql/submitBrokerageSurvey";

interface UserBrokerSurveyContainerProps {
  gmbh: GmbHDetails;
}

const UserBrokerSurveyContainer = ({ gmbh }: UserBrokerSurveyContainerProps) => {
  const [submitBrokerageSurveyMutation, submitBrokerageSurveyRequest] = useMutation<
    submitBrokerageSurvey,
    submitBrokerageSurveyVariables
  >(SUBMIT_BROKERAGE_SURVEY);

  const onSubmit = async (variables: SelectedAnswerInterface) => {
    await submitBrokerageSurveyMutation({
      variables: {
        ...variables,
        companyId: gmbh.id,
        answer: variables.answer ?? ""
      }
    });

    await gmbh.refetch();
  };

  return (
    <RequestFeedbackHandler requests={[submitBrokerageSurveyRequest]} showChildrenWhileLoading>
      <UserBrokerSurvey gmbh={gmbh} onSubmit={onSubmit} />
    </RequestFeedbackHandler>
  );
};

export default UserBrokerSurveyContainer;
