import { selectFieldFor, textFieldFor } from "../../generic/commonFields";
import {
  dateOfBirthFieldFor,
  phoneFieldFor,
  selectArrayFieldFor
} from "../../generic/Trader-GmbH/companyCreation/commonFields";
import {
  countryListWithFlag,
  defaultCountryOptions
} from "../../../lib/dataAdapters/countryUtils/countryUtils";
import { defaultCountryCode } from "../../../common/defaultCountry";
import React from "react";
import ReactSelect from "react-select";
import { taxOfficeSelectOptions } from "../../../common/staticData/taxOffices/taxOfficeSelectOptions";

const options = countryListWithFlag;

export const SalutationSelectField = ({ t, formik, salutationOptions }) => {
  const translatedSalutationOptions = salutationOptions.map((salutationOptions) => {
    return {
      title: t(`nominee-dashboard:person.salutationOptions.${salutationOptions}`),
      value: salutationOptions
    };
  });
  return selectFieldFor(
    t,
    formik,
    "salutation",
    "nominee-dashboard:person.salutation",
    translatedSalutationOptions
  );
};

export const TitleField = ({ t, formik }) =>
  textFieldFor(t, formik, "title", "nominee-dashboard:person.title");

export const PhoneField = ({ t, formik }) =>
  phoneFieldFor(
    t,
    formik,
    formik.values["phoneNumber"],
    "",
    defaultCountryCode,
    "phoneNumber",
    t("nominee-dashboard:person.phone-number"),
    (value) => {
      formik.setFieldValue("phoneNumber", value);
    },
    {}
  );

export const BirthPlaceField = ({ t, formik }) =>
  textFieldFor(t, formik, "placeOfBirth", "nominee-dashboard:person.place-of-birth");

export const BirthNameField = ({ t, formik }) =>
  textFieldFor(t, formik, "birthName", "nominee-dashboard:person.birthName");

export const PersonalTaxIdField = ({ t, formik }) =>
  textFieldFor(t, formik, "personalTaxId", "nominee-dashboard:person.personal-tax-id");

export const TaxNumberField = ({ t, formik }) =>
  textFieldFor(t, formik, "taxNumber", "nominee-dashboard:person.tax-number");

export const TaxOfficeSelect = ({ t, formik }) => (
  <div data-testid="taxOffice-select-container">
    <label htmlFor="taxOffice">{t("nominee-dashboard:person.tax-office")}</label>
    <ReactSelect
      className="select-field"
      name="taxOffice"
      options={taxOfficeSelectOptions}
      isSearchable={true}
      defaultValue={{ value: "", label: "" }}
      value={taxOfficeSelectOptions.find((option) => option.value === formik.values["taxOffice"])}
      onChange={(selectedOption) => formik.handleChange("taxOffice")(selectedOption?.value)}
      onBlur={() => formik.setFieldTouched("taxOffice", true)}
    />
  </div>
);

export const FirstNameField = ({ t, formik }) =>
  textFieldFor(t, formik, "firstName", "nominee-dashboard:person.first-name");

export const LastNameField = ({ t, formik }) =>
  textFieldFor(t, formik, "lastName", "nominee-dashboard:person.last-name");

export const DateOfBirthField = ({ t, formik }) => {
  const dateFormat: string = "dd/MM/yyyy";
  const label = t("generic:birth-date");
  const name = "dateOfBirth";
  const selectedDate = formik.values[name];
  return dateOfBirthFieldFor(
    t,
    formik,
    formik.values,
    name,
    label,
    dateFormat,
    selectedDate,
    "",
    "",
    ""
  );
};

export const DocumentNumberField = ({ t, formik }) =>
  textFieldFor(t, formik, "documentNumber", "nominee-dashboard:person.document-number");

export const IssuingAuthorityField = ({ t, formik }) =>
  textFieldFor(t, formik, "issuingAuthority", "nominee-dashboard:person.issuing-authority");

export const DocumentTypeSelectField = ({ t, formik, documentOptions }) => {
  const translatedDocumentTypeSelectField = documentOptions.map((documentOptions) => {
    return {
      title: t(`nominee-dashboard:person.documentOptions.${documentOptions}`),
      value: documentOptions
    };
  });
  return selectFieldFor(
    t,
    formik,
    "documentType",
    "nominee-dashboard:person.document-type",
    translatedDocumentTypeSelectField
  );
};

export const CitizenshipField = ({ t, formik }) => {
  const label = t("nominee-dashboard:person.citizenship");

  return selectArrayFieldFor(
    t,
    formik.values,
    formik,
    "citizenship",
    label,
    options,
    defaultCountryOptions,
    "",
    {}
  );
};
