import React from "react";
import { useMutation } from "react-apollo";
import logger from "../../../../common/Logger";
import RequestFeedbackHandler from "../../../generic/RequestFeedbackHandler";
import {
  ApplyDiscountCode,
  ApplyDiscountCodeVariables
} from "../../../public/CompanyCreation/graphql/ApplyDiscountCode";
import { APPLY_DISCOUNT_CODE } from "../../../public/CompanyCreation/Mutations";
import ReferralCodeSection from "../components/ReferralCodeSection";
import { NomineeCompanyDetails_companyById } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const ReferralCodeSectionContainer = ({
  company,
  companyCreationId,
  refetch
}: {
  company: NomineeCompanyDetails_companyById;
  companyCreationId: string;
  refetch: () => void;
}) => {
  const [callApplyDiscountCodeMutation, applyDiscountCodeRequest] = useMutation<
    ApplyDiscountCode,
    ApplyDiscountCodeVariables
  >(APPLY_DISCOUNT_CODE);

  const applyReferralCode = async (discountCode: string) => {
    try {
      await callApplyDiscountCodeMutation({
        variables: { companyCreationId, discountCode }
      });
    } catch (error) {
      logger.errorMessage(`Unable to apply discount code`);
      logger.error(error);
    }
    await refetch();
  };

  return (
    <RequestFeedbackHandler requests={[applyDiscountCodeRequest]}>
      <ReferralCodeSection company={company} applyReferralCode={applyReferralCode} />
    </RequestFeedbackHandler>
  );
};
