import { useMutation } from "@apollo/react-hooks";
import { UserService } from "lib/services/UserService/UserService";
import React from "react";
import { useHistory } from "react-router-dom";
import LoginCodeForm from "./LoginCodeForm";
import {
  VerifyCodeForTwoFactorAuthLogin,
  VerifyCodeForTwoFactorAuthLoginVariables
} from "lib/api/mutations/graphql/VerifyCodeForTwoFactorAuthLogin";
import { VERIFY_CODE_FOR_TWO_FACTOR_AUTH_LOGIN } from "lib/api/mutations/VERIFY_CODE_FOR_TWO_FACTOR_AUTH_LOGIN";
import { updateToken } from "common/Authentication/storeToken";
import { SendSmsForTwoFactorAuth } from "lib/api/mutations/graphql/SendSmsForTwoFactorAuth";
import { SEND_SMS_FOR_TWO_FACTOR_AUTH } from "lib/api/mutations/SEND_SMS_FOR_TWO_FACTOR_AUTH";
import localStore from "../../../common/LocalStore";

export const LoginCodeFormContainer = () => {
  const history = useHistory<{ redirectUrl?: string }>();
  const redirectUrl = history.location.state?.redirectUrl;

  const [verifyCodeForTwoFactorAuthMutation] = useMutation<
    VerifyCodeForTwoFactorAuthLogin,
    VerifyCodeForTwoFactorAuthLoginVariables
  >(VERIFY_CODE_FOR_TWO_FACTOR_AUTH_LOGIN);

  const [sendSmsForTwoFactorAuthMutation] = useMutation<SendSmsForTwoFactorAuth>(
    SEND_SMS_FOR_TWO_FACTOR_AUTH
  );

  const onVerifyClick = async (code: string) => {
    try {
      const result = await verifyCodeForTwoFactorAuthMutation({
        variables: {
          code: code
        }
      });

      if (result.data?.appToken) {
        updateToken(result.data.appToken);
        await UserService.instance?.meRequest?.refetch();
        localStore.removeValue("nextSmsAvailableAt");
        history.replace(redirectUrl ?? "/");
      }
    } catch (err) {
      return false;
    }
  };

  const sendVerificationCode = async () => {
    try {
      const result = await sendSmsForTwoFactorAuthMutation();
      if (result.data?.sendSmsForTwoFactorAuth?.nextSmsAvailableAt) {
        return new Date(result.data?.sendSmsForTwoFactorAuth?.nextSmsAvailableAt);
      }
    } catch (err) {
      return;
    }
  };

  const nextRequestAvailableAt = new Date(localStore.getExpiringValue("nextSmsAvailableAt"));

  return (
    <LoginCodeForm
      sendVerificationCode={sendVerificationCode}
      onVerifyClick={onVerifyClick}
      nextSmsRequestAvailableAt={nextRequestAvailableAt}
    />
  );
};
