import React from "react";
import { GmbH } from "lib/models/client/GmbH";
import GmbHGrundungServiceBadge from "../GmbHGrundungServiceBadge/GmbHGrundungServiceBadge";

interface CompanyFoundInProgressHeaderProps {
  gmbh: GmbH;
}

export const CompanyFoundInProgressHeader = ({ gmbh }: CompanyFoundInProgressHeaderProps) => {
  return (
    <>
      <h3 className="text-white mt-0 mt-md-2 text-md-center" data-testid="company-founding-status">
        <GmbHGrundungServiceBadge status={gmbh.grundungServiceStatus} />
      </h3>
      {!gmbh.hasAdminService && <div className="mt-0 mt-md-4" />}
    </>
  );
};
