import { Col, Row } from "react-bootstrap";
import { datePickerField } from "../../../../../generic/commonFields";
import React from "react";
import { TFunction } from "react-i18next";
import { FormikProps } from "formik";

interface IProps {
  t: TFunction;
  formik: FormikProps<any>;
}

export const FiscalYearInput = ({ formik }: IProps) => {
  return (
    <Row className="mt-5">
      <Col>
        {datePickerField("dd.MM", "TT.MM", "Von", "fiscal-year-date-picker", formik, "fiscalYear")}
      </Col>
    </Row>
  );
};
