import React, { useEffect } from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import AssignPartnerTaxAdvisor from "./AssignPartnerTaxAdvisor";
import { useMutation } from "@apollo/react-hooks";
import {
  AssignTaxServiceToCompany,
  AssignTaxServiceToCompanyVariables
} from "components/nominee/NomineeCompany/graphql/AssignTaxServiceToCompany";
import { ASSIGN_TAX_SERVICE_TO_COMPANY } from "components/nominee/NomineeCompany/Mutations";
import { GetTaxAdvisorCompaniesByNameQuery } from "lib/api/queries/GetTaxAdvisorCompaniesByName";
import { NOMINEE_COMPANY_DETAILS_QUERY } from "lib/api/queries/GetNomineeCompanyDetailsById";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetailsVariables
} from "lib/api/queries/graphql/NomineeCompanyDetails";
import { useLazyQuery } from "react-apollo";

export interface AssignPartnerTaxAdvisorContainerProps {
  companyId: string;
  ptaCompanyId: string;
  subContractCompanyId: string | null | undefined;
  refetch: () => Promise<void>;
}

export const AssignPartnerTaxAdvisorContainer = ({
  companyId,
  ptaCompanyId,
  subContractCompanyId,
  refetch
}: AssignPartnerTaxAdvisorContainerProps) => {
  const [assignTaxServiceToCompany, assignTaxServiceRequest] = useMutation<
    AssignTaxServiceToCompany,
    AssignTaxServiceToCompanyVariables
  >(ASSIGN_TAX_SERVICE_TO_COMPANY);
  const [callGetCompanyDetails, getCompanyDetailsRequest] = useLazyQuery<
    NomineeCompanyDetails,
    NomineeCompanyDetailsVariables
  >(NOMINEE_COMPANY_DETAILS_QUERY);

  const { data } = getCompanyDetailsRequest;

  useEffect(() => {
    if (ptaCompanyId) {
      callGetCompanyDetails({
        variables: {
          id: ptaCompanyId
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptaCompanyId]);

  const getTaxAdvisorCompaniesByNameRequest = GetTaxAdvisorCompaniesByNameQuery("");
  const onSubmit = async ({
    partnerTaxAdvisorCompanyId
  }: {
    partnerTaxAdvisorCompanyId: string;
  }): Promise<void> => {
    await assignTaxServiceToCompany({
      variables: { clientCompanyId: companyId, taxServiceCompanyId: partnerTaxAdvisorCompanyId }
    });
    await refetch();
    callGetCompanyDetails({
      variables: {
        id: partnerTaxAdvisorCompanyId
      }
    });
  };

  return (
    <RequestFeedbackHandler requests={[getCompanyDetailsRequest, assignTaxServiceRequest]}>
      <AssignPartnerTaxAdvisor
        onSubmit={onSubmit}
        ptaCompanies={getTaxAdvisorCompaniesByNameRequest.data?.getTaxAdvisorCompaniesByName ?? []}
        ptaCompany={data?.companyById && ptaCompanyId ? data.companyById : null}
        subContractCompanyId={subContractCompanyId}
      />
    </RequestFeedbackHandler>
  );
};
