import React from "react";
import ShortOrderFormCheckoutStep from "client/components/UnifiedShortOrderForm/CheckoutStep/CheckoutStep";
import { useMutation } from "@apollo/react-hooks";
import { UpsertOrder, UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import {
  DiscountCodeAllowedServicesEnum,
  OrderCompanyStatusEnum,
  OrderEntityType,
  OrderOwnerType,
  OrderProductTypeEnum
} from "global-query-types";
import { FinalizeOrder, FinalizeOrderVariables } from "lib/api/mutations/graphql/FinalizeOrder";
import { FINALIZE_ORDER } from "lib/api/mutations/FINALIZE_ORDER";
import {
  VerifyDiscountCode,
  VerifyDiscountCodeVariables
} from "lib/api/mutations/graphql/VerifyDiscountCode";
import { VERIFY_DISCOUNT_CODE } from "lib/api/mutations/VERIFY_DISCOUNT_CODE";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { UnifiedShortOrderFormServerData } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormServerData";
import { UtmParams } from "lib/services/UtmService/UtmService";
import { UnifiedShortOrderFormStepProps } from "client/components/UnifiedShortOrderForm/UnifiedShortOrderForm.partials";
import { DataLayer } from "lib/services/GoogleTagManager/GoogleTagManager";
import { toOrderGtmEvent } from "lib/dataAdapters/toOrderGtmEvent/toOrderGtmEvent";

interface ShortOrderFormCheckoutStepContainerProps extends UnifiedShortOrderFormStepProps {
  utmParams?: UtmParams;
}

export const CheckoutStepContainer = ({
  initialValues,
  goToPreviousStep,
  goToNextStep,
  persistData,
  utmParams
}: ShortOrderFormCheckoutStepContainerProps) => {
  const [upsertOrder, upsertRequest] = useMutation<UpsertOrder, UpsertOrderVariables>(UPSERT_ORDER);
  const [finalizeOrder, finalizeRequest] = useMutation<FinalizeOrder, FinalizeOrderVariables>(
    FINALIZE_ORDER
  );
  const [verifyDiscountCode] = useMutation<VerifyDiscountCode, VerifyDiscountCodeVariables>(
    VERIFY_DISCOUNT_CODE
  );
  const createAndFinalizeOrder = async (values: UnifiedShortOrderFormServerData) => {
    const orderCreated = (
      await upsertOrder({
        variables: {
          ownerType: OrderOwnerType.Guest,
          entityType: OrderEntityType.Company,
          status: OrderCompanyStatusEnum.InProgress,
          productType: OrderProductTypeEnum.UnifiedShortOrderForm,
          extra: values
        }
      })
    ).data?.upsertOrder;

    let userId: string | undefined;
    if (orderCreated?.id) {
      const finalizeOrderResult = await finalizeOrder({
        variables: {
          id: orderCreated.id,
          submissionId: orderCreated.submissionId
        }
      });

      userId = finalizeOrderResult.data?.finalizeOrder?.id ?? undefined;
    }

    DataLayer.OrderStarted({ userId, ...toOrderGtmEvent(values) });
  };

  const verifyDiscount = async (
    discountCode: string,
    selectedProducts: DiscountCodeAllowedServicesEnum[]
  ): Promise<{ isValid: boolean; discountValue: number }> => {
    const request = await verifyDiscountCode({
      variables: { discountCode, products: selectedProducts }
    });
    return request.data?.verifyDiscountCode ?? { isValid: false, discountValue: 0 };
  };

  return (
    <>
      <RequestFeedbackHandler requests={[upsertRequest, finalizeRequest]} />
      <ShortOrderFormCheckoutStep
        initialValues={initialValues}
        onSubmit={createAndFinalizeOrder}
        verifyDiscountCode={verifyDiscount}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
        persistData={persistData}
        utmParams={utmParams}
      />
    </>
  );
};
