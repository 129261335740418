import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { PaginationArgs } from "../../../common/PaginationArgs";
import { AllKycEntriesQuery, AllKycEntriesQueryVariables } from "./graphql/AllKycEntriesQuery";

const ALL_KYC_ENTRIES = gql`
  query AllKycEntriesQuery($limit: Int, $start: Int, $query: String) {
    allKycEntries(limit: $limit, start: $start, query: $query) {
      count
      kycEntries {
        id
        startedAt
        createdAt
        updatedAt
        completedAt
        user {
          confirmedAt
          createdAt
          email
        }
        person {
          id
          firstName
          lastName
          userWallet {
            tanganyWallets {
              wallet
              walletProviderId
              ownerCompany {
                id
                name
                legalForm
              }
            }
          }
        }
        company {
          id
          name
          legalForm
          testCompany
          ownedWallet {
            wallet
            walletProviderId
            ownerCompany {
              id
              name
              legalForm
            }
          }
        }
      }
    }
  }
`;

export const AllKycEntries = (query: string, { limit, start }: PaginationArgs) =>
  useQuery<AllKycEntriesQuery, AllKycEntriesQueryVariables>(ALL_KYC_ENTRIES, {
    variables: { query: query || undefined, limit, start }
  });
