import React from "react";
import { IconProps } from "../../components/common/icons/IconProps";

export const CheckboxFrame = ({ size = 18 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill="white" stroke="currentColor" />
  </svg>
);
