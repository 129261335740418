import gql from "graphql-tag";

/**
 * @deprecated
 */
export const GET_MY_TAX_ADVISOR_CLIENTS_LEGACY = gql`
  query GetMyTaxAdvisorClientsLegacy {
    getMyTaxAdvisorClients {
      items {
        id
        client {
          id
          clientNumber
          thirdPartyTaxAdvisorClientNumber
          fiscalYear
          name
          legalForm
          deletedAt
          brokerageAccounts {
            id
          }
          group {
            type
            memberships {
              role
              person {
                id
                firstName
                lastName
              }
              company {
                id
                name
                legalForm
              }
            }
          }
        }
        type
        taxAdvisorStatus
        createdAt
        updatedAt
        activatedAt
      }
    }
  }
`;
