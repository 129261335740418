import { WithTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { CompanyProcessStatesSectionContainer } from "../../../../client/components/CompanyProcessStatesSection/CompanyProcessStatesSectionContainer";
import { doNothing } from "../../../../common/stubs";
import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";

export interface ProcessStatesPreviewProps extends WithTranslation {
  companyCreationId: string;
  onExit: () => void;
}

export const ProcessStatesPreview = withTranslationReady(["nominee-dashboard"])(
  ({ companyCreationId, onExit, t }: ProcessStatesPreviewProps) => {
    return (
      <>
        <Button data-testid="preview-mode-toggle" onClick={onExit}>
          {t("nominee-dashboard:process-steps.exit-preview")}
        </Button>
        <CompanyProcessStatesSectionContainer
          gmbh={{
            companyCreationId: companyCreationId,
            isComplete: false,
            refetch: doNothing
          }}
        />
      </>
    );
  }
);
