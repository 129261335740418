import * as Yup from "yup";
import { AssetTypes } from "../types/types";

export const TradesLastThreeYearsSchema = (t, assetsOptions: AssetTypes[]) => {
  return Yup.object().shape({
    stocks: Yup.number()
      .notRequired()
      .when({
        is: () => assetsOptions.find((asset) => asset === "stocks"),
        then: Yup.number().required(t("generic:validation-required")),
        otherwise: Yup.number().notRequired()
      }),
    bonds: Yup.number()
      .notRequired()
      .when({
        is: () => assetsOptions.find((asset) => asset === "bonds"),
        then: Yup.number().required(t("generic:validation-required")),
        otherwise: Yup.number().notRequired()
      }),
    options: Yup.number()
      .notRequired()
      .when({
        is: () => assetsOptions.find((asset) => asset === "options"),
        then: Yup.number().required(t("generic:validation-required")),
        otherwise: Yup.number().notRequired()
      }),
    futures: Yup.number()
      .notRequired()
      .when({
        is: () => assetsOptions.find((asset) => asset === "futures"),
        then: Yup.number().required(t("generic:validation-required")),
        otherwise: Yup.number().notRequired()
      }),
    forex: Yup.number()
      .notRequired()
      .when({
        is: () => assetsOptions.find((asset) => asset === "forex"),
        then: Yup.number().required(t("generic:validation-required")),
        otherwise: Yup.number().notRequired()
      }),
    cfd: Yup.number()
      .notRequired()
      .when({
        is: () => assetsOptions.find((asset) => asset === "cfd"),
        then: Yup.number().required(t("generic:validation-required")),
        otherwise: Yup.number().notRequired()
      })
  });
};
