import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import ShareholderDetailsSectionTitle from "../../../../sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import {
  DropdownSelectOption,
  RideDropdownSelect
} from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { BusinessTypeEnum } from "lib/models/client/LowTaxBroker/BusinessTypeEnum";
import { OccupationEnum } from "lib/models/client/LowTaxBroker/OccupationEnum";
import GenericAddress from "../../../sharedComponents/GenericAddress/GenericAddress";
import { deepClone } from "common/deepClone";
import * as Yup from "yup";
import { requiredString } from "lib/validation/requiredString";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

export interface EmployerInformationStepProps {
  entityKey: "shareholder" | "employee";
}

const EmployerInformationStep = ({
  t,
  order,
  controls,
  saveData,
  currentStep,
  entityKey
}: StepComponentProps & WithTranslation & EmployerInformationStepProps) => {
  const includeBuildingNumber =
    (order.extra.isNewBrokerEnabled &&
      !FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerWithoutHouseNumber)) ||
    !order.extra.isNewBrokerEnabled;

  const index = currentStep?.chapter?.index ?? 0;

  let orderExtraEntityKey = `${entityKey}s`;
  let dictionaryCopy = order.extra[orderExtraEntityKey]
    ? deepClone(order.extra[orderExtraEntityKey])
    : {};
  const entity = dictionaryCopy[index] ?? {};
  const employmentDetails = entity.employmentDetails ?? {};
  const employerAddress = employmentDetails?.employerAddress ?? {};

  const initialValues = {
    name: employmentDetails.employer,
    occupation: employmentDetails.occupation,
    businessType: employmentDetails.employerBusiness,
    country: employerAddress.country ?? "DE",
    stateCode: employerAddress.stateCode,
    streetName: employerAddress.streetName,
    buildingNumber: employerAddress.buildingNumber,
    streetLine1: employerAddress.streetLine1,
    streetLine2: employerAddress.streetLine2,
    city: employerAddress.city,
    postalCode: employerAddress.postalCode,
    countryMismatch: employmentDetails.countryMismatch
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit: (values) => submitStep(values),
    validationSchema: EmployerInformationSchema(
      t,
      entity.personAddress?.country,
      includeBuildingNumber
    )
  });

  const countryMismatch = entity.personAddress?.country !== formik.values["country"];

  const submitStep = async (values) => {
    currentStep.complete();
    await saveData({
      ...order.extra,
      [orderExtraEntityKey]: {
        ...dictionaryCopy,
        [index]: {
          ...dictionaryCopy[index],
          employmentDetails: {
            ...dictionaryCopy[index].employmentDetails,
            employer: values["name"],
            occupation: values["occupation"],
            employerBusiness: values["businessType"],
            countryMismatch: countryMismatch ? values["countryMismatch"] : null,
            employerAddress: {
              country: values["country"],
              stateCode: values["stateCode"],
              city: values["city"],
              streetName: values["streetName"] ?? null,
              buildingNumber: values["buildingNumber"] ?? null,
              postalCode: values["postalCode"],
              streetLine1: values["streetLine1"] ?? null,
              streetLine2: values["streetLine2"]
            }
          }
        }
      },
      ...currentStep.chapter.order.serialize()
    });
  };

  const isCompleted = !!(formik.values["name"] &&
  formik.values["occupation"] &&
  formik.values["businessType"] &&
  formik.values["country"] &&
  formik.values["stateCode"] &&
  (includeBuildingNumber
    ? formik.values["streetName"] && formik.values["buildingNumber"]
    : formik.values["streetLine1"]) &&
  formik.values["city"] &&
  formik.values["postalCode"] &&
  countryMismatch
    ? formik.values["countryMismatch"]
    : true);

  const businessTypeOptions: DropdownSelectOption[] = Object.keys(BusinessTypeEnum).map(
    (businessType) => ({
      label: t(`generic:ride-broker-onboarding.employer-information.business-type.${businessType}`),
      value: BusinessTypeEnum[businessType]
    })
  );
  const occupationOptions: DropdownSelectOption[] = Object.keys(OccupationEnum).map(
    (occupation) => ({
      label: t(`generic:ride-broker-onboarding.employer-information.occupation.${occupation}`),
      value: OccupationEnum[occupation]
    })
  );

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="employer-information-form subchapter-order-layout__scrollable-address">
          <ShareholderDetailsSectionTitle
            isCompleted={isCompleted}
            prefix={`${currentStep?.disposition}`}
            text={t("generic:ride-broker-onboarding.employer-information.title")}
          />
          <RideTextInput
            name="name"
            label={t("generic:employer-name")}
            placeholder={t("generic:name")}
            className="ride-form-v2__field"
          />
          <RideDropdownSelect
            name={"businessType"}
            options={businessTypeOptions}
            label={t("generic:ride-broker-onboarding.employer-information.business-type.label")}
            className="ride-form-v2__field"
            isSearchable
            isClearable
          />
          <RideDropdownSelect
            name={"occupation"}
            options={occupationOptions}
            label={t("generic:ride-broker-onboarding.employer-information.occupation.label")}
            className="ride-form-v2__field"
            isSearchable
            isClearable
          />
          <GenericAddress
            formik={formik}
            displaySectionTitle={false}
            title={""}
            prefix={""}
            includeBuildingNumber={includeBuildingNumber}
          />
          {countryMismatch && (
            <>
              <RideAlertBar
                className={"employer-information-form__country-mismatch-alert"}
                type={AlertType.WARNING}
                message={t(
                  "generic:ride-broker-onboarding.employer-information.country-mismatch.alert"
                )}
                data-testid={"country-mismatch-alert"}
              />
              <RideTextInput
                name="countryMismatch"
                label={t(
                  "generic:ride-broker-onboarding.employer-information.country-mismatch.label"
                )}
                placeholder={t(
                  "generic:ride-broker-onboarding.employer-information.country-mismatch.placeholder"
                )}
                className="ride-form-v2__field"
              />
            </>
          )}
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export const EmployerInformationSchema = (t, personCountry, includesBuildingNumber) => {
  return Yup.object({
    stateCode: requiredString(t),
    city: requiredString(t),
    streetName: includesBuildingNumber ? requiredString(t) : Yup.string().nullable(),
    buildingNumber: includesBuildingNumber ? requiredString(t) : Yup.string().nullable(),
    streetLine1: includesBuildingNumber ? Yup.string().nullable() : requiredString(t),
    postalCode: requiredString(t),
    name: requiredString(t),
    businessType: requiredString(t),
    occupation: requiredString(t),
    countryMismatch: Yup.string()
      .nullable()
      .when("country", {
        is: (employerCountry) => employerCountry !== personCountry,
        then: (schema) => schema.required(t("generic:validation-required"))
      })
  });
};

export const EmployerInformation = (entityKey: "shareholder" | "employee") =>
  withTranslationReady(["generic"])((props: StepComponentProps & WithTranslation) => (
    <EmployerInformationStep {...props} entityKey={entityKey} />
  ));
