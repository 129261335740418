import gql from "graphql-tag";

export const VERIFY_DISCOUNT_CODE = gql`
  mutation VerifyDiscountCode(
    $discountCode: String!
    $products: [DiscountCodeAllowedServicesEnum!]
  ) {
    verifyDiscountCode(discountCode: $discountCode, products: $products) {
      isValid
      discountValue
    }
  }
`;
