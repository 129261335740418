import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  LatitudeField,
  LongitudeField,
  PhotoLinkField,
  RealEstateLabelField,
  RealEstateMyShareField,
  RealEstatePropertyValueField,
  RealEstateTypeField,
  ShortDescriptionField
} from "../generic/real-estate-form/fields";
import {
  AddressCityField,
  AddressCountryField,
  AddressPostalCodeField,
  AddressStreetField
} from "../generic/commonFields";
import OwnershipField from "./Ownership/OwnershipField";

export const RealEstateEditFormField = ({ t, formik }) => (
  <>
    <Row className="show-grid">
      <Col xs={6} xl={3}>
        <RealEstateTypeField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <RealEstateLabelField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <RealEstatePropertyValueField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <RealEstateMyShareField t={t} formik={formik} />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12}>
        <OwnershipField currentCompanyId={null} formik={formik} />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12}>
        <br />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12}>
        <small>
          <b>{t("realEstate:asset-location-info")}</b>
        </small>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} xl={6}>
        <AddressStreetField t={t} formik={formik} />
      </Col>
      <Col xs={4} xl={2}>
        <AddressPostalCodeField t={t} formik={formik} />
      </Col>
      <Col xs={4} xl={2}>
        <AddressCityField t={t} formik={formik} />
      </Col>
      <Col xs={4} xl={2}>
        <AddressCountryField t={t} />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12}>
        <small>
          <b>{t("realEstate:else")}</b>
        </small>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={3}>
        <LatitudeField t={t} formik={formik} />
      </Col>
      <Col xs={3}>
        <LongitudeField t={t} formik={formik} />
      </Col>
      <Col xs={6}>
        <PhotoLinkField t={t} formik={formik} />
      </Col>
    </Row>

    <Row>
      <Col xs={12}>
        <ShortDescriptionField t={t} formik={formik} />
      </Col>
    </Row>
  </>
);
