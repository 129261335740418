import { TFunction } from "i18next";
import React from "react";

interface ValueViewProps {
  name: string;
  value: string | any;
  testId: string;
  t: TFunction;
}

export const ValueView = ({ name, value, testId, t }: ValueViewProps) => {
  return (
    <dl>
      <dt>{t(name)}</dt>
      <dd data-testid={testId}>
        <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>
      </dd>
    </dl>
  );
};
