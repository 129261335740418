import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { ReviewShareholderSection } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholderSection/ReviewShareholderSection";

export interface ReviewGeneralInformationProps {
  name: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
}

export const ReviewGeneralInformation = withTranslationReady(["generic", "client"])(
  ({ name, email, phoneNumber, t }: ReviewGeneralInformationProps & WithTranslation) => {
    return (
      <ReviewShareholderSection
        title={t("client:shareholder-details.person-general-information.title")}>
        <ReviewField label={t("generic:name")} value={name} />
        <ReviewField label={t("generic:email")} value={email} />
        <ReviewField label={t("generic:phone-number")} value={phoneNumber} />
      </ReviewShareholderSection>
    );
  }
);
