import gql from "graphql-tag";

export const GET_THIRD_PARTY_TAX_ADVISOR_BY_CODE = gql`
  query getThirdPartyTaxAdvisorByCode($taxAdvisorCode: String!) {
    getThirdPartyTaxAdvisorByCode(taxAdvisorCode: $taxAdvisorCode) {
      person {
        firstName
        lastName
      }
    }
  }
`;
