import React, { PropsWithChildren } from "react";
import { Container } from "react-bootstrap";

interface BlankPageProps extends PropsWithChildren<{}> {
  title?: string;
  subTitle?: string;
  variant?: WidthVariantEnum;
}

export enum WidthVariantEnum {
  DEFAULT = "default",
  NARROW = "narrow"
}

// FIXME: Give better name to <BlankPage> component before we ship this ticket
export const BlankPage = ({
  title,
  children,
  subTitle,
  variant = WidthVariantEnum.DEFAULT
}: BlankPageProps) => {
  return (
    <div className={`blank-page blank-page--${variant}`}>
      <Container className={"justify-content-center"}>
        <div className="blank-page__title-component">
          <div className="blank-page__title" data-testid="blank-page-title">
            {title}
          </div>
          {subTitle && <div className="blank-page__sub-title">{subTitle}</div>}
        </div>

        <div data-testid="blank-page-content">{children}</div>
      </Container>
    </div>
  );
};
