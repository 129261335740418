import gql from "graphql-tag";

export const EXTERNAL_COMPANY_DATA_BY_NAME = gql`
  query ExternalCompanyDataByName($query: String!) {
    externalCompanyDataByName(query: $query) {
      companies {
        companyData {
          companyName
          legalForm
          registerCourt
          registration
          leiNumber
        }
        companyAddress {
          country
          city
          postalCode
          streetLine1
          streetLine2
        }
        hasCorporateShareholders
        northDataUniqueKey
      }
    }
  }
`;
