import React from "react";
import { RouteComponentProps } from "react-router";
import { AuthLayout } from "sharedComponents/templates/AuthLayout/AuthLayout";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ResetPasswordContainer } from "../../../auth/components/ResetPassword/ResetPasswordContainer";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";

interface ChangePasswordSceneProps extends WithTranslation, RouteComponentProps {
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy;
}

const ChangePasswordScene = ({ t, ...args }: ChangePasswordSceneProps) => {
  return (
    <AuthLayout title={t("auth:reset-password.title")} subtitle="">
      <ResetPasswordContainer />
    </AuthLayout>
  );
};

export default withTranslationReady(["auth"])(ChangePasswordScene);
