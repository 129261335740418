import React from "react";

export interface FolderBreadcrumbProps {
  path: string;
}

export const FolderBreadcrumb = ({ path }: FolderBreadcrumbProps) => {
  const parts = path.split("/");

  let items: JSX.Element[] = [];
  for (let i = 0; i < parts.length; i++) {
    if (i) items = [...items, <span key={items.length} className="folder-breadcrumb__separator" />];
    items = [...items, <span key={items.length}>{parts[i]}</span>];
  }

  return <div className="folder-breadcrumb">{items}</div>;
};
