import React from "react";
import ShareholderDetailsSectionTitle from "../../../sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import { WithTranslation } from "react-i18next";
import { RideCheckbox, RideCheckboxVariant } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { StepComponentProps } from "../../../ChaptersOrderLayout.partials";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { RideDatePicker } from "uiLibrary/v2/components/RideDatePicker/RideDatePicker";
import moment from "moment";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import * as Yup from "yup";
import { Gender } from "lib/models/client/LowTaxBroker/CitizenshipData";

interface CitizenshipInformationStepProps {
  entityKey: "shareholder" | "employee";
}

const CitizenshipInformationStep = ({
  entityKey,
  order,
  currentStep,
  saveData,
  controls,
  t
}: CitizenshipInformationStepProps & StepComponentProps & WithTranslation) => {
  const index = currentStep?.chapter?.index ?? 0;
  const entity =
    entityKey === "shareholder"
      ? order?.extra?.shareholders?.[index]
      : order?.extra?.employees?.[index];

  const submitShareholder = async (values) => {
    await saveData(
      {
        ...order.extra,
        shareholders: {
          ...order.extra.shareholders,
          [index]: {
            ...order.extra.shareholders[index],
            personCitizenship: {
              ...order.extra.shareholders[index].personCitizenship,
              gender: values.gender,
              birthDate: values.birthDate,
              birthCity: values.birthCity,
              birthCountry: values.birthCountry,
              citizenship: values.citizenship,
              secondCitizenship: values.secondCitizenship ?? null,
              notUSCitizenOrTaxResident: values.notUSCitizenOrTaxResident
            }
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const submitEmployee = async (values) => {
    await saveData(
      {
        ...order.extra,
        employees: {
          ...order.extra.employees,
          [index]: {
            ...order.extra.employees[index],
            personCitizenship: {
              ...order.extra.employees[index].personCitizenship,
              gender: values.gender,
              birthDate: values.birthDate,
              birthCity: values.birthCity,
              birthCountry: values.birthCountry,
              citizenship: values.citizenship,
              secondCitizenship: values.secondCitizenship ?? null,
              notUSCitizenOrTaxResident: values.notUSCitizenOrTaxResident
            }
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  async function onSubmit(values) {
    currentStep.complete();

    if (entityKey === "shareholder") {
      await submitShareholder(values);
    } else {
      await submitEmployee(values);
    }
  }

  const personCitizenship = entity?.personCitizenship;

  const formik = useRideFormik({
    initialValues: {
      gender: personCitizenship?.gender,
      birthDate: personCitizenship?.birthDate ?? "",
      birthCity: personCitizenship?.birthCity ?? "",
      birthCountry: personCitizenship?.birthCountry,
      citizenship: personCitizenship?.citizenship,
      secondCitizenship: personCitizenship?.secondCitizenship,
      notUSCitizenOrTaxResident: personCitizenship?.notUSCitizenOrTaxResident
    },
    onSubmit,
    validateOnBlur: true,
    validationSchema: CitizenshipInformationSchema(t)
  });

  const isCompleted = !!(
    formik.values.gender &&
    formik.values.birthDate &&
    formik.values.birthCity &&
    formik.values.birthCountry &&
    formik.values.citizenship &&
    formik.values.notUSCitizenOrTaxResident
  );

  const age = calculateAge(formik.values.birthDate);

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="fx-flat-entity-details-citizenship-information subchapter-order-layout__scrollable">
          <ShareholderDetailsSectionTitle
            text={t("shareholder-details:citizenship-information.person-title")}
            prefix={`${currentStep?.disposition}`}
            isCompleted={isCompleted}
          />
          <div className={"fx-flat-entity-details-citizenship-information__fields"}>
            <RideRadioSelect
              label={t("shareholder-details:citizenship-information.gender")}
              options={[
                { value: "male", label: t("shareholder-details:citizenship-information.male") },
                { value: "female", label: t("shareholder-details:citizenship-information.female") },
                {
                  value: "diverse",
                  label: t("shareholder-details:citizenship-information.diverse")
                }
              ]}
              name="gender"
              variant={RadioSelectVariant.Light}
            />
            <RideDatePicker
              name="birthDate"
              label={t("shareholder-details:citizenship-information.birthday")}
              className={"fx-flat-entity-details-citizenship-information__date-picker"}
            />
            {18 <= age && age < 21 && (
              <RideAlertBar
                data-testid={"margin-account-age-restriction-info"}
                type={AlertType.INFO}
                message={t(
                  "shareholder-details:citizenship-information.margin-account-age-restriction-info"
                )}
              />
            )}
            <CountrySelect
              name="citizenship"
              label={t("shareholder-details:citizenship-information.nationality")}
            />
            <CountrySelect
              name="secondCitizenship"
              label={t("shareholder-details:citizenship-information.second-nationality")}
              isClearable
              placeholder={"NA"}
            />
            <RideTextInput
              name="birthCity"
              label={t("shareholder-details:citizenship-information.birthplace")}
              placeholder="Berlin"
            />
            <CountrySelect
              name="birthCountry"
              label={t("shareholder-details:citizenship-information.birth-country")}
            />
            <RideCheckbox
              name="notUSCitizenOrTaxResident"
              label={t(`shareholder-details:citizenship-information.not-us-citizen--shareholder`)}
              variant={RideCheckboxVariant.Light}
            />
          </div>
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export const CitizenshipInformation = (entityKey: "shareholder" | "employee") =>
  withTranslationReady(["shareholder-details"])((props: StepComponentProps & WithTranslation) => (
    <CitizenshipInformationStep {...props} entityKey={entityKey} />
  ));

const calculateAge = (birthDate: string) => moment(Date.now()).diff(birthDate, "years");

const CitizenshipInformationSchema = (t) =>
  Yup.object().shape({
    gender: Yup.string().oneOf(Object.values(Gender)).required(t("generic:validation-required")),
    birthDate: Yup.string()
      .required(t("generic:validation-required"))
      .test(
        "min-18yo",
        t("shareholder-details:citizenship-information.broker-shareholder-min-18yo"),
        (value) => {
          return !!value && calculateAge(value) >= 18;
        }
      ),
    birthCity: Yup.string().required(t("generic:validation-required")),
    birthCountry: Yup.string().required(t("generic:validation-required")),
    citizenship: Yup.string().required(t("generic:validation-required")),
    secondCitizenship: Yup.string().nullable(),
    notUSCitizenOrTaxResident: Yup.boolean().required(t("generic:validation-required"))
  });
