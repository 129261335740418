import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PrivateIcon } from "../icons/PrivateIcon";
import ReturnLinkWithChevron from "../ReturnLinkWithChevron/ReturnLinkWithChevron";
import { useHistory } from "react-router-dom";

interface PersonOverviewHeaderProps {
  personFullName: string;
}

export const PersonOverviewHeader = ({ personFullName }: PersonOverviewHeaderProps) => {
  const { goBack } = useHistory();

  return (
    <Container
      data-testid="header-section"
      className={`company-header-section bg-dark pt-4 pb-4 pb-md-3`}
      fluid>
      <Row noGutters>
        <Col>
          <Container className="px-0">
            <Row>
              <Col>
                <ReturnLinkWithChevron onClick={goBack} data-testid="back-link" />
              </Col>
            </Row>
            <Row>
              <Col className="d-none d-md-block text-center">
                <PrivateIcon />
              </Col>
            </Row>
            <Row className="mt-3 mt-md-0">
              <Col>
                <h1
                  data-testid="person-full-name"
                  className="text-white mt-0 mt-md-3 mb-2 text-md-center">
                  {personFullName}
                </h1>
              </Col>
            </Row>
            <Row className="mt-3 mt-md-0">
              <Col>
                <div className="mb-45 mb-md-6" />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
