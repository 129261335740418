import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LowTaxBrokerShareholder } from "lib/models/client/LowTaxBroker/Shareholder";
import { ReviewShareholder } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholder/ReviewShareholder";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";

export interface ReviewShareholdersProps {
  editLink?: string;
  shareholders: Omit<LowTaxBrokerShareholder, "proofOfIDFile" | "proofOfAddressFile">[];
  isCompleted?: boolean;
  isNewBroker: boolean;
}

export const ReviewShareholders = withTranslationReady(["generic"])(
  ({
    t,
    editLink,
    shareholders,
    isCompleted,
    isNewBroker
  }: ReviewShareholdersProps & WithTranslation) => {
    return (
      <ReviewCard dataTestId="shareholder-details">
        <ReviewSectionTitle
          title={t("generic:ride-broker-onboarding.review.shareholders.title")}
          dataTestId="review-shareholders-title"
          editLink={editLink}
          className="review-shareholders-details__title"
          isCompleted={isCompleted}
        />
        <div className="review-shareholders-details__list">
          {shareholders.map((shareholder, index) => (
            <ReviewShareholder key={index} isNewBroker={isNewBroker} shareholder={shareholder} />
          ))}
        </div>
      </ReviewCard>
    );
  }
);
