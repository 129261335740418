import React from "react";
import CommonRoleCard from "../CommonRoleCard/CommonRoleCard";
import { companyRolesFromMemberships } from "../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import { GetAdvisedPerson_getAdvisedPerson } from "../../taxAdvisor/graphql/GetAdvisedPerson";

interface PersonRoleCardProps {
  person: GetAdvisedPerson_getAdvisedPerson;
  dataTestId?: string;
}

export const PersonRoleCard = ({
  person,
  dataTestId = "person-role-card"
}: PersonRoleCardProps) => {
  const roles = companyRolesFromMemberships(person?.memberships);

  return <CommonRoleCard roles={roles} dataTestId={dataTestId} hideVotingRights />;
};
