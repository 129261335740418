import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { PendingTaskSwitcherProps } from "../PendingTaskSwitcher";
import { CardContainer } from "../../../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { getPendingTaskURL } from "../../../../../../lib/types/PendingTask";

const ExternalURLProcessTask = ({ task, t }: PendingTaskSwitcherProps & WithTranslation) => {
  return (
    <CardContainer className="v2-card-container--alternative">
      <div className="p-4" data-testid="pending-task">
        <p className="font-weight-bold">{task.formName}</p>
        <p className="text-muted" data-testid="proceed-to-external-service-provider">
          {t("generic:pending-tasks.proceed-to-external-service")}
        </p>

        <div className="ltb-overview__buttons">
          <a
            data-testid="external-service-cta"
            className="ltb-overview__primary-action-btn"
            href={getPendingTaskURL(task)}
            target="_blank"
            rel="noopener noreferrer">
            {t("generic:pending-tasks.proceed-button")}
            <span className="ride-banner__button-icon"></span>
          </a>
        </div>
      </div>
    </CardContainer>
  );
};

export default withTranslationReady(["generic"])(ExternalURLProcessTask);
