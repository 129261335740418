import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { GetFormerClientAssignments_getFormerClientAssignments_items } from "lib/api/queries/graphql/GetFormerClientAssignments";
import RideCardTable from "../../../sharedComponents/RideCardTable/RideCardTable";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import { formatDate } from "common/formatters";
import EntityLink from "../../../components/common/EntityLink/EntityLink";
import { RideBadge, RideBadgeVariant } from "uiLibrary/components/RideBadge/RideBadge";
import { FormerClientAssignmentType } from "global-query-types";

interface FormerClientAssignmentsProps extends WithTranslation {
  assignments: GetFormerClientAssignments_getFormerClientAssignments_items[];
}

const FormerClientAssignments = ({ t, assignments }: FormerClientAssignmentsProps) => {
  const columns = [
    { name: "type", label: t("nominee-dashboard:former-client-assignments.type") },
    { name: "client", label: t("nominee-dashboard:former-client-assignments.client") },
    { name: "advisor", label: t("nominee-dashboard:former-client-assignments.advisor") },
    {
      name: "startFiscalYear",
      label: t("nominee-dashboard:former-client-assignments.startFiscalYear")
    },
    {
      name: "endFiscalYear",
      label: t("nominee-dashboard:former-client-assignments.endFiscalYear")
    },
    { name: "createdAt", label: t("nominee-dashboard:former-client-assignments.createdAt") },
    { name: "updatedAt", label: t("nominee-dashboard:former-client-assignments.updatedAt") }
  ];

  return (
    <RideCardTable
      title={t("nominee-dashboard:former-client-assignments.title")}
      data-testid="former-client-assignments">
      <RideTableHeader columns={columns} />

      {assignments.map(
        ({ id, type, client, advisor, startFiscalYear, endFiscalYear, createdAt, updatedAt }) => (
          <RideTableRow colCount={7} key={id}>
            <RideTableCell
              variant={RideTableCellVariant.text}
              dataTestId="assignment-type"
              value={
                <RideBadge
                  variant={
                    type === FormerClientAssignmentType.AdminService
                      ? RideBadgeVariant.PURPLE
                      : RideBadgeVariant.GREEN
                  }
                  label={t(`nominee-dashboard:former-client-assignments.types.${type}`)}
                />
              }
            />
            <RideTableCell
              variant={RideTableCellVariant.text}
              dataTestId="assignment-client"
              value={
                <EntityLink testIdPrefix="assignment-client" membership={{ company: client }} />
              }
            />
            <RideTableCell
              variant={RideTableCellVariant.text}
              dataTestId="assignment-advisor"
              value={
                <EntityLink testIdPrefix="assignment-advisor" membership={{ company: advisor }} />
              }
            />
            <RideTableCell
              variant={RideTableCellVariant.text}
              dataTestId="assignment-startFiscalYear"
              value={startFiscalYear}
            />
            <RideTableCell
              variant={RideTableCellVariant.text}
              dataTestId="assignment-endFiscalYear"
              value={endFiscalYear}
            />
            <RideTableCell
              variant={RideTableCellVariant.text}
              dataTestId="assignment-createdAt"
              value={formatDate(createdAt)}
            />
            <RideTableCell
              variant={RideTableCellVariant.text}
              dataTestId="assignment-updatedAt"
              value={formatDate(updatedAt)}
            />
          </RideTableRow>
        )
      )}
    </RideCardTable>
  );
};

export default withTranslationReady(["nominee-dashboard"])(FormerClientAssignments);
