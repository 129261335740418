import React, { PropsWithChildren, useCallback, useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { debounceEventHandler } from "lib/timing/debounceEventHandler";
import RideLoading from "sharedComponents/RideLoading/RideLoading";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { FormikMock } from "testTools/mocks/FormikMock";
import TaxAdvisorClientCard from "taxAdvisor/components/TaxAdvisorClientCard/TaxAdvisorClientCard";
import { SearchTermProvider } from "../TaxAdvisorClientCard/TaxAdvisorClientCard.partials";
import { TFunction } from "i18next";
import { GetMyTaxAdvisorClients_getMyTaxAdvisorClients_items } from "../../../lib/api/queries/graphql/GetMyTaxAdvisorClients";
import { RidePagination } from "../../../uiLibrary/designSystem/RidePagination/RidePagination";

type TaxService = GetMyTaxAdvisorClients_getMyTaxAdvisorClients_items;

export interface TaxAdvisorClientsProps {
  taxServices: TaxService[] | null | undefined;
  totalTaxServicesCount: number | undefined;
  loading: boolean;
  searchHandler: (searchTerm: string) => void;
  setStartHandler: (start: number) => void;
  pageSize: number;
}

const TaxAdvisorClients = ({
  t,
  taxServices,
  totalTaxServicesCount = taxServices?.length,
  loading,
  searchHandler,
  setStartHandler,
  pageSize
}: TaxAdvisorClientsProps & WithTranslation) => {
  const [start, setStart] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const onSearchInputTyped = useCallback(
    debounceEventHandler((e: React.FormEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;

      if (input.value.length === 1) {
        return;
      }

      setSearchTerm(input.value);
      searchHandler(input.value);
    }, 250),
    []
  );

  return (
    <div className="tax-advisor-clients">
      <div className="tax-advisor-clients__topbar">
        <Typography category="Headline" size={300} weight="Heavy" data-testid="company-count">
          {t("tax-advisor:clients.countLabel")}{" "}
          {loading ? "(-)" : totalTaxServicesCount && `(${totalTaxServicesCount})`}
        </Typography>
        <div className="tax-advisor-clients__search-area">
          <RideLoading
            data-testid={"loader"}
            className={`tax-advisor-clients__search-loader 
              tax-advisor-clients__search-loader--${loading ? "visible" : "hidden"}`}
          />
          <FormikMock>
            <RideTextInput
              name={"client-search"}
              placeholder={t("tax-advisor:clients.searchPlaceholder")}
              onInput={onSearchInputTyped}
            />
          </FormikMock>
        </div>
      </div>

      {!loading && (
        <SearchTermProvider value={{ searchTerm }}>
          <RidePagination
            count={totalTaxServicesCount}
            pageSize={pageSize}
            start={start}
            setStart={(start) => {
              setStartHandler(start);
              setStart(start);
            }}>
            <ListView emptyFallback={<EmptyList t={t} isSearching={!!searchTerm} />}>
              {taxServices?.map(
                (taxService, index) =>
                  taxService.client && (
                    <TaxAdvisorClientCard
                      key={taxService.id}
                      index={index}
                      taxService={taxService}
                    />
                  )
              )}
            </ListView>
          </RidePagination>
        </SearchTermProvider>
      )}
    </div>
  );
};

const ListView = ({
  emptyFallback,
  children
}: PropsWithChildren<{
  emptyFallback?: React.ReactNode;
}>) => {
  const count = React.Children.count(children);
  return count <= 0 && emptyFallback ? (
    <>{emptyFallback}</>
  ) : (
    <div className="tax-advisor-clients__list" data-testid="tax-advisor-clients-list">
      {children}
    </div>
  );
};

const EmptyList = ({ t, isSearching }: { t: TFunction; isSearching: boolean }) => {
  return isSearching ? <EmptySearchResult t={t} /> : <NoClients t={t} />;
};

const NoClients = ({ t }: { t: TFunction }) => {
  return (
    <Typography
      category={"Paragraph"}
      size={100}
      weight={"Light"}
      data-testid="no-clients"
      className="tax-advisor-clients__empty-list white-space--pre-line">
      {t("tax-advisor:clients.noClients")}
    </Typography>
  );
};

const EmptySearchResult = ({ t }: { t: TFunction }) => {
  return (
    <Typography
      category={"Paragraph"}
      size={100}
      weight={"Light"}
      data-testid="search-results-empty"
      className="tax-advisor-clients__empty-list white-space--pre-line">
      {t("tax-advisor:clients.searchResultsEmpty")}
    </Typography>
  );
};

export default withTranslationReady(["tax-advisor", "generic"])(TaxAdvisorClients);
