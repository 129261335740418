import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import React from "react";
import { RideMultiSelect } from "uiLibrary/components/RideMultiSelect/RideMultiSelect";
import { CompanyAssets } from "global-query-types";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { StepHeading } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";

export type SelectAssetTypesValues = {
  [CompanyAssets.REAL_ESTATE]: boolean;
  [CompanyAssets.SHARES_AND_FUNDS]: boolean;
  [CompanyAssets.DERIVATIVES]: boolean;
  [CompanyAssets.HOLDING]: boolean;
};

const SelectAssetTypes = ({
  t,
  order,
  saveData,
  controls,
  currentStep
}: WithTranslation & StepComponentProps) => {
  async function onSubmit(values: SelectAssetTypesValues) {
    currentStep.complete();

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize(),
      assetTypes: {
        [CompanyAssets.REAL_ESTATE]: values[CompanyAssets.REAL_ESTATE],
        [CompanyAssets.SHARES_AND_FUNDS]: values[CompanyAssets.SHARES_AND_FUNDS],
        [CompanyAssets.DERIVATIVES]: values[CompanyAssets.DERIVATIVES],
        [CompanyAssets.HOLDING]: values[CompanyAssets.HOLDING]
      }
    });
  }

  const initialValues: SelectAssetTypesValues = order.extra.assetTypes ?? {
    [CompanyAssets.DERIVATIVES]: true,
    [CompanyAssets.SHARES_AND_FUNDS]: true,
    [CompanyAssets.REAL_ESTATE]: true,
    [CompanyAssets.HOLDING]: true
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validate(values) {
      const noneSelected =
        !values[CompanyAssets.DERIVATIVES] &&
        !values[CompanyAssets.SHARES_AND_FUNDS] &&
        !values[CompanyAssets.REAL_ESTATE] &&
        !values[CompanyAssets.HOLDING];

      if (noneSelected) {
        return {
          "asset-types": t("generic:validation-required")
        };
      }

      return {};
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:company-setup.select-asset-types.title")} />

        <RideMultiSelect
          options={[
            {
              name: CompanyAssets.DERIVATIVES,
              label: t("generic:company-setup.select-asset-types.derivatives")
            },
            {
              name: CompanyAssets.SHARES_AND_FUNDS,
              label: t("generic:company-setup.select-asset-types.shares-and-funds")
            },
            {
              name: CompanyAssets.REAL_ESTATE,
              label: t("generic:company-setup.select-asset-types.real-estate")
            },
            {
              name: CompanyAssets.HOLDING,
              label: t("generic:company-setup.select-asset-types.holdings")
            }
          ]}
        />

        <InvalidFeedback hidden={!formik.errors["asset-types"]} testPrefix="asset-types">
          {formik.errors["asset-types"]}
        </InvalidFeedback>

        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SelectAssetTypes);
