import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import React from "react";
import { useParams } from "react-router";
import { QueryCompanyById } from "../../../lib/api/queries/GetCompanyById";
import { GetFiscalYearsByCompanyIdQuery } from "../../../lib/api/queries/GetFiscalYearsByCompanyId";
import { EntireFiscalYearConfirmationTransformer } from "./EntireFiscalYearConfirmation.partials";
import { useMutation } from "@apollo/react-hooks";
import {
  updateFiscalYearById,
  updateFiscalYearByIdVariables
} from "../../../lib/api/mutations/graphql/updateFiscalYearById";
import { UPDATE_FISCAL_YEAR_BY_ID } from "../../../lib/api/mutations/UPDATE_FISCAL_YEAR_BY_ID";
import { GetWepaBookings } from "../../../lib/api/queries/GetWepaBookings";
import {
  setWepaBookingFlag,
  setWepaBookingFlagVariables
} from "../../../lib/api/mutations/graphql/setWepaBookingFlag";
import { SET_WEPA_BOOKING_FLAG } from "../../../lib/api/mutations/SET_WEPA_BOOKING_FLAG";
import { WepaBookingStatusFlag } from "../../../global-query-types";
import * as Sentry from "@sentry/react";
import logger from "../../../common/Logger";

export const EntireFiscalYearConfirmationContainer = () => {
  const { companyId, year } = useParams<{ companyId: string; year: string }>();

  const companyRequest = QueryCompanyById(companyId);

  const fiscalYearsRequest = GetFiscalYearsByCompanyIdQuery(companyId);
  const [updateFiscalYearByIdCall, updateFiscalYearByIdRequest] = useMutation<
    updateFiscalYearById,
    updateFiscalYearByIdVariables
  >(UPDATE_FISCAL_YEAR_BY_ID);

  const confirmFiscalYearById = async (id: string) => {
    try {
      await updateFiscalYearByIdCall({
        variables: { id: id, dataUploadCompleted: true }
      });
    } catch (e) {
      Sentry.captureException(e);
      logger.errorMessage(`Unable to confirm fiscal year:`);
      logger.error(e);
    } finally {
      await fiscalYearsRequest.refetch();
    }
  };

  const wepaBookingsRequest = GetWepaBookings({ companyId });
  const [setWepaBookingFlagCall, setWepaBookingFlagRequest] = useMutation<
    setWepaBookingFlag,
    setWepaBookingFlagVariables
  >(SET_WEPA_BOOKING_FLAG);

  const confirmWepaBookingById = async (bookingId: number) => {
    try {
      await setWepaBookingFlagCall({
        variables: { bookingId, companyId, flag: WepaBookingStatusFlag.customer_final }
      });
    } catch (e) {
      Sentry.captureException(e);
      logger.errorMessage(`Unable to confirm wepa annual:`);
      logger.error(e);
    } finally {
      await wepaBookingsRequest.refetch();
    }
  };

  return (
    <RequestFeedbackHandler
      requests={[
        companyRequest,
        fiscalYearsRequest,
        updateFiscalYearByIdRequest,
        wepaBookingsRequest,
        setWepaBookingFlagRequest
      ]}
      hideErrors={true}>
      <EntireFiscalYearConfirmationTransformer
        company={companyRequest.data?.companyById}
        fiscalYears={fiscalYearsRequest.data?.getFiscalYearsByCompanyId}
        wepaBookings={wepaBookingsRequest.data?.getWepaBookings?.bookings}
        year={year}
        confirmFiscalYearById={confirmFiscalYearById}
        confirmWepaBookingById={confirmWepaBookingById}
      />
    </RequestFeedbackHandler>
  );
};
