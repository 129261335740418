import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getAllShelfCompanyOrders } from "./graphql/getAllShelfCompanyOrders";

export const GET_ALL_SHELF_COMPANY_ORDERS = gql`
  query getAllShelfCompanyOrders {
    getAllShelfCompanyOrders {
      count
      orders {
        id
        createdAt
        name
        city
        status
      }
    }
  }
`;

export const GetAllShelfCompanyOrders = () =>
  useQuery<getAllShelfCompanyOrders>(GET_ALL_SHELF_COMPANY_ORDERS, {
    variables: {},
    fetchPolicy: "no-cache"
  });
