import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { Typography } from "../../../uiLibrary/designSystem/styles/Typography/Typography";
import RideFormLayout from "../RideFormLayout/RideFormLayout";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../uiLibrary/designSystem/RideButton/RideButton";
import { useHistory, useParams } from "react-router-dom";

const RideBrokerInternalServerError = ({ t }: WithTranslation) => {
  const { companyId } = useParams<{ companyId: string }>();
  const module = "ride-broker-internal-server-error";
  const history = useHistory();

  return (
    <RideFormLayout>
      <div data-testid={module} className={module}>
        <Typography
          data-testid={`${module}-title`}
          className={`${module}__title`}
          category={"Headline"}
          size={200}
          weight={"Heavy"}>
          {t("generic:ride-broker-internal-server-error.title")}
        </Typography>
        <span data-testid={`${module}-subtitle`} className={`${module}__subtitle`}>
          {t("generic:ride-broker-internal-server-error.subtitle")}
        </span>
        <div className={`${module}__button-container`}>
          <RideButton
            data-testid={`${module}-button`}
            label={t("generic:ride-broker-internal-server-error.button")}
            variant={RideButtonVariant.TERTIARY}
            size={RideButtonSize.BIG}
            onClick={() => history.replace(`/client/entities/${companyId}/invest`)}
          />
        </div>
      </div>
    </RideFormLayout>
  );
};

export default withTranslationReady(["generic"])(RideBrokerInternalServerError);
