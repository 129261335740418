import React from "react";
import { TaxAdvisorCompany, TaxAdvisorSection } from "../TaxAdvisorSection/TaxAdvisorSection";

export interface TaxAdvisorListProps {
  taxAdvisors: TaxAdvisorCompany[] | null | undefined;
}

export const TaxAdvisorsList = ({ taxAdvisors }: TaxAdvisorListProps) => {
  return (
    <>
      {taxAdvisors?.map((taxAdvisor, index) => {
        return (
          <TaxAdvisorSection taxAdvisorCompany={taxAdvisor} key={`${taxAdvisor.id}-${index}`} />
        );
      })}
    </>
  );
};
