import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { SPVCompaniesQuery } from "./graphql/SPVCompaniesQuery";
import SPVCompany from "./SPVCompany";

export const SPV_COMPANIES_QUERY = gql`
  query SPVCompaniesQuery {
    nomineeCompanies {
      id
      name
      projectName
      legalForm
      registerCourt
      registration
      capitalContribution
      businessAddress {
        street
        city
        postalCode
      }
      tradeTaxFree
      tokenized
      status
      tokenizedShares
      boxLink
      taxNumber
      vatNumber
    }
  }
`;

interface SPVCompaniesProps extends WithTranslation {}

export const SPVCompanies: React.FunctionComponent<SPVCompaniesProps> = (props) => {
  const [firstRender, setFirstRender] = useState(true);

  const { data, loading, error } = useQuery<SPVCompaniesQuery>(SPV_COMPANIES_QUERY, {
    fetchPolicy: firstRender ? "network-only" : undefined,
    onCompleted: () => setFirstRender(false)
  });

  return <SPVCompany companyList={data!} loading={loading} SPVCompaniesError={error} />;
};

export default withTranslation(["spv-company"])(SPVCompanies);
