import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  BoxLinkField,
  CapitalContributionField,
  LegalFormField,
  ProjectNameField,
  PublishedStatusField,
  RegistryCourtField,
  TaxNumberField,
  TokenizedSharesField,
  TokenizedStatusField,
  TradeTaxFreeStatusField,
  VatNumberField
} from "../../generic/company-form/fields";
import {
  AddressCityField,
  AddressCountryField,
  AddressPostalCodeField,
  AddressStreetField,
  CompanyRegistrationField
} from "../../generic/commonFields";
import { CompanyNameField } from "../../public/CompanyCreation/fields";

export const SPVCompanyFormFields = ({ t, formik, legalForms }) => (
  <>
    <Row className="show-grid">
      <Col md={12} lg={6}>
        <CompanyNameField
          t={t}
          formik={formik}
          name="name"
          label={t("generic:company-name")}
          required={true}
        />
      </Col>
      <Col md={12} lg={6}>
        <ProjectNameField t={t} formik={formik} />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col xs={6} xl={3}>
        <RegistryCourtField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <CompanyRegistrationField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <TaxNumberField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <VatNumberField t={t} formik={formik} />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col md={6} lg={4}>
        <LegalFormField t={t} formik={formik} legalForms={legalForms} />
      </Col>
      <Col md={6} lg={4}>
        <TokenizedSharesField t={t} formik={formik} />
      </Col>
      <Col md={6} lg={4}>
        <TokenizedStatusField t={t} formik={formik} />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col md={6} lg={4}>
        <TradeTaxFreeStatusField t={t} formik={formik} />
      </Col>
      <Col md={6} lg={4}>
        <CapitalContributionField t={t} formik={formik} />
      </Col>
      <Col md={6} lg={4}>
        <PublishedStatusField t={t} formik={formik} />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col xs={12}>
        <BoxLinkField t={t} formik={formik} />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col xs={6} xl={3}>
        <AddressStreetField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <AddressPostalCodeField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <AddressCityField t={t} formik={formik} />
      </Col>
      <Col xs={6} xl={3}>
        <AddressCountryField t={t} />
      </Col>
    </Row>
  </>
);
