import { withTranslationReady } from "common/i18n/withTranslationReady";
import React from "react";
import { WithTranslation } from "react-i18next";
import { LegalForm } from "../../../../global-query-types";
import { formatCapitalContribution } from "../../../../common/formatters/formatCapitalContribution";
import { companyName } from "../../../../lib/formatters/companyFormatter";
import RideClubLabel from "../../../../sharedComponents/RideClubLabel/RideClubLabel";

export interface InvestmentAssetManagementProps extends WithTranslation {
  name: string;
  legalForm: LegalForm;
  capitalContribution: number;
  tokenizedShares: number;
  className?: string;
}

export interface InvestmentAssetManagementPropsWithTranslation
  extends InvestmentAssetManagementProps,
    WithTranslation {}

const InvestmentAssetManagement = ({
  t,
  name,
  legalForm,
  capitalContribution,
  tokenizedShares,
  className
}: InvestmentAssetManagementPropsWithTranslation) => {
  return (
    <div
      className={`investment-asset-management ${className ?? ""}`}
      data-testid="investment-asset-management">
      <RideClubLabel />

      <div className="investment-asset-management__title">{t("generic:asset-management")}</div>

      <div className="investment-asset-management__pair">
        <span className="investment-asset-management__key">{t("generic:spv")}:</span>
        <span className="investment-asset-management__value" data-testid="company-name">
          {companyName({ name, legalForm })}
        </span>
      </div>

      <div className="investment-asset-management__pair">
        <span className="investment-asset-management__key">
          {t("generic:capital-contribution")}:
        </span>
        <span
          className="investment-asset-management__value"
          data-testid="company-capital-contribution">
          {formatCapitalContribution(capitalContribution)}
        </span>
      </div>

      <div className="investment-asset-management__pair">
        <span className="investment-asset-management__key">{t("generic:tokenizedShares")}:</span>
        <span className="investment-asset-management__value" data-testid="company-token-number">
          {tokenizedShares}
        </span>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(InvestmentAssetManagement);
