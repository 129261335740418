import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { Trans, WithTranslation } from "react-i18next";
import { Typography } from "../../uiLibrary/designSystem/styles/Typography/Typography";
import { PaymentOption } from "../../client/components/MigrateAdminServicePackage/SetupPaymentCard/PaymentOption/PaymentOption";
import { PaymentMethod } from "../../lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { PartyPopper } from "../icons/PartyPopper";
import { formatCurrency } from "../../common/formatters";
import { AlertType, RideAlertBar } from "../../uiLibrary/components/RideAlertBar/RideAlertBar";
import { CopyableInformation } from "../../client/components/CopyableInformation/CopyableInformation";
import React from "react";

export interface ChoosePaymentMethodProps {
  formik: any;
  price: string | number | null | undefined;
  pricingInformation?: string | null | undefined;
  iban: string | null | undefined;
  companyName: string | null | undefined;
  showYearlyPrice?: boolean;
}

export const ChoosePaymentMethod = withTranslationReady(["generic"])(
  ({
    t,
    formik,
    price,
    iban,
    companyName,
    pricingInformation,
    showYearlyPrice = true
  }: ChoosePaymentMethodProps & WithTranslation) => {
    return (
      <div className={"setup-payment-card"} data-testid={"choose-payment-method"}>
        <div className={"setup-payment-card__content"}>
          <Typography
            data-testid="payment-method-choice-title"
            category={"Headline"}
            size={300}
            weight={"Heavy"}>
            {t("generic:payment-method-card.method-choice.title")}
          </Typography>
          <div className={"setup-payment-card__options"}>
            <PaymentOption
              data-testid={"sepa-payment-option"}
              name={"paymentMethod"}
              value={PaymentMethod.Sepa}
              label={t("generic:payment-method-card.method-choice.sepa.name")}
              feeMessage={t("generic:free")}
              icon={<PartyPopper />}
            />
            <PaymentOption
              data-testid={"manual-bank-transfer-payment-option"}
              name={"paymentMethod"}
              value={PaymentMethod.ManualBankTransfer}
              label={t("generic:payment-method-card.method-choice.manual.name")}
              feeMessage={t("generic:payment-method-card.method-choice.manual.name-suffix")}
            />
          </div>
          {formik.values.paymentMethod === PaymentMethod.Sepa && (
            <div className={"setup-payment-card__sepa-info"}>
              {showYearlyPrice && (
                <Typography
                  data-testid={"sepa-price"}
                  className={"setup-payment-card__sepa-price"}
                  category={"Paragraph"}
                  size={100}
                  weight={"Heavy"}>
                  {price &&
                    t("generic:product-card.pricePeriodicity.yearly", {
                      price: formatCurrency(price, {
                        fixed: 0
                      })
                    })}
                  {!!pricingInformation && pricingInformation}
                </Typography>
              )}
              <Typography
                data-testid="sepa-payment-observation"
                className={"setup-payment-card__observation"}
                category={"Paragraph"}
                size={100}
                weight={"Light"}>
                {t("generic:payment-method-card.method-choice.sepa.observation")}
              </Typography>
              <RideAlertBar
                data-testid={"sepa-correct-info-alert"}
                className={"setup-payment-card__alert"}
                type={AlertType.INFO}
                message={
                  <>
                    <Trans
                      i18nKey={"generic:payment-method-card.method-choice.sepa.alert-message"}
                      values={{ companyName }}
                    />
                    {iban && (
                      <div className={"setup-payment-card__copyable-iban"}>
                        <CopyableInformation
                          data-testid="copyable-iban"
                          label={t("generic:iban")}
                          value={iban}
                          showValue={true}
                        />
                      </div>
                    )}
                  </>
                }
              />
            </div>
          )}
          {formik.values.paymentMethod === PaymentMethod.ManualBankTransfer && (
            <div className={"setup-payment-card__manual-bank-transfer-info"}>
              {!!pricingInformation && (
                <Typography
                  data-testid={"price-info"}
                  className={"setup-payment-card__sepa-price"}
                  category={"Paragraph"}
                  size={100}
                  weight={"Heavy"}>
                  {pricingInformation}
                </Typography>
              )}
              <Typography
                data-testid="manual-payment-description"
                className={"setup-payment-card__observation"}
                category={"Paragraph"}
                size={100}
                weight={"Light"}>
                {t("generic:payment-method-card.method-choice.manual.description")}
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
);
