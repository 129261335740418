import * as Yup from "yup";
import { cityRegex, postalCode } from "./common";
import { FlowTypeEnum, LegalForm } from "../../global-query-types";

export const AdminServiceCompanyInfoSchema = (flowType: FlowTypeEnum) => () =>
  Yup.object().shape({
    companyName: Yup.string().required("generic:validation-required"),
    legalForm: Yup.mixed<LegalForm>().oneOf(Object.values(LegalForm)),
    registration: Yup.string().required("generic:validation-required"),
    registerCourt: Yup.string().required("generic:validation-required"),
    taxNumber:
      flowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING
        ? Yup.string().required("generic:validation-required")
        : Yup.string(),
    economicId: Yup.string(),
    street: Yup.string().required("generic:validation-required"),
    city: Yup.string()
      .matches(cityRegex, { message: "generic:validation-only-letters-spaces-dash" })
      .required("generic:validation-required"),
    postalCode: Yup.string()
      .matches(postalCode, { message: "generic:validation-only-numbers" })
      .required("generic:validation-required")
  });
