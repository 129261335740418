import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import { StepComponentProps, StepHeading } from "../../../ChaptersOrderLayout.partials";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { AddExistingCompanyOrderData } from "lib/models/client/AddExistingCompany/AddExistingCompanyOrderData";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { registerCourt } from "common/staticData/registerCourtOptions/registerCourt";
import * as Yup from "yup";
import { leiNumberValidationSchema } from "lib/validation/CompanyLEINumberSchema";
import { clientLegalForms } from "common/staticData/LegalForms";
import { taxNumberValidation } from "lib/validation/CompanyTaxNumberSchema";

const CompanyBasicInfoStep = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps<AddExistingCompanyOrderData> & WithTranslation) => {
  const supportedLegalForms = clientLegalForms;

  const companyData = order.extra?.companyData;

  const onSubmit = async (values: any) => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        companyData: {
          ...order.extra.companyData,
          companyName: values.companyName,
          legalForm: values.legalForm,
          registration: values.registration,
          registerCourt: values.registerCourt,
          leiNumber: values.leiNumber,
          taxNumber: values.taxNumber
        },
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: {
      companyName: companyData?.companyName,
      legalForm: companyData?.legalForm,
      registration: companyData?.registration,
      registerCourt: companyData?.registerCourt,
      leiNumber: companyData?.leiNumber,
      taxNumber: companyData?.taxNumber
    },
    onSubmit: onSubmit,
    validationSchema: CompanyBasicInfoSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div data-testid="company-basic-info" className={"company-basic-info"}>
          <StepHeading
            text={t("generic:add-existing-company.company-details.company-basic-info.title")}
            dataTestId="company-basic-info-title"
          />
          <div className={"company-basic-info__form"}>
            <RideTextInput
              name="companyName"
              label={t(
                "generic:add-existing-company.company-details.company-basic-info.company-name"
              )}
              placeholder={t(
                "generic:add-existing-company.company-details.company-basic-info.company-name-placeholder"
              )}
            />
            <RideDropdownSelect
              name="legalForm"
              label={t("generic:legal-form")}
              isClearable={true}
              options={supportedLegalForms.map((legalForm) => ({
                label: legalForm,
                value: legalForm
              }))}
            />
            <RideDropdownSelect
              label={t(
                "generic:add-existing-company.company-details.company-basic-info.register-court"
              )}
              name="registerCourt"
              options={registerCourt}
              placeholder={t(
                "generic:add-existing-company.company-details.company-basic-info.register-court-placeholder"
              )}
              isSearchable={true}
            />
            <RideTextInput
              name="registration"
              label={t("generic:add-existing-company.company-details.company-basic-info.hrb")}
              placeholder={t(
                "generic:add-existing-company.company-details.company-basic-info.hrb-placeholder"
              )}
            />
            <RideTextInput
              name={"taxNumber"}
              label={t(
                "generic:add-existing-company.company-details.company-basic-info.tax-number"
              )}
              placeholder={"12/345/67890"}
            />
            <RideTextInput
              name="leiNumber"
              label={t("generic:add-existing-company.company-details.company-basic-info.lei")}
              placeholder={t(
                "generic:add-existing-company.company-details.company-basic-info.lei-placeholder"
              )}
            />
            {controls}
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

const CompanyBasicInfoSchema = (t) => {
  return Yup.object().shape({
    companyName: Yup.string().required("generic:validation-required"),
    legalForm: Yup.string().required("generic:validation-required"),
    registerCourt: Yup.string().required("generic:validation-required"),
    registration: Yup.string().required("generic:validation-required"),
    leiNumber: leiNumberValidationSchema(t),
    taxNumber: taxNumberValidation(t)
  });
};

export default withTranslationReady(["generic"])(CompanyBasicInfoStep);
