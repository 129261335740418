import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";

export interface ApplicationReviewCardProps {
  status: OnboardingStepCardStatus;
  cardIndex: number;
}

const ApplicationReviewCard = ({
  t,
  status,
  cardIndex
}: ApplicationReviewCardProps & WithTranslation) => {
  return (
    <OnboardingStepCard
      data-testid="ltb-application-review-card"
      number={cardIndex}
      title={t("generic:low-tax-broker-overview.application-review.title")}
      status={status}>
      <RideAlertBar
        type={AlertType.INFO}
        title={t("generic:low-tax-broker-overview.application-review.content-title")}
        message={
          <Trans
            i18nKey={rideBrokerT(
              "generic:low-tax-broker-overview.application-review.content-description"
            )}
            components={{ bold: <strong /> }}
          />
        }
      />
    </OnboardingStepCard>
  );
};

export default withTranslationReady(["generic"])(ApplicationReviewCard);
