export enum SortDirection {
  ASCENDING = "ASC",
  DESCENDING = "DESC",
  INACTIVE = "INACTIVE"
}

export const getNextSortDirection = (direction: SortDirection): SortDirection => {
  if (direction === SortDirection.ASCENDING) {
    return SortDirection.DESCENDING;
  } else if (direction === SortDirection.DESCENDING) {
    return SortDirection.INACTIVE;
  } else {
    return SortDirection.ASCENDING;
  }
};
