import EntireFiscalYearConfirmation, { EntireFiscalYear } from "./EntireFiscalYearConfirmation";
import React from "react";
import { CompanyById_companyById } from "../../../lib/api/queries/graphql/CompanyById";
import { GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId } from "../../../lib/api/queries/graphql/GetFiscalYearsByCompanyId";
import { getWepaBookings_getWepaBookings_bookings } from "../../../lib/api/queries/graphql/getWepaBookings";
import { isCustomerFinal } from "../ConfirmAccountingYear/ConfirmAccountingYear";

export interface EntireFiscalYearConfirmationTransformerProps {
  company: CompanyById_companyById | undefined | null;
  fiscalYears: GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId[] | undefined | null;
  wepaBookings: getWepaBookings_getWepaBookings_bookings[] | undefined | null;
  year: string;
  confirmFiscalYearById: (id: string) => Promise<void>;
  confirmWepaBookingById: (bookingId: number) => Promise<void>;
}

export const EntireFiscalYearConfirmationTransformer = ({
  company,
  fiscalYears,
  wepaBookings,
  year,
  confirmFiscalYearById,
  confirmWepaBookingById
}: EntireFiscalYearConfirmationTransformerProps) => {
  const adminServiceFiscalYear = (fiscalYears ?? []).find((fiscalYear) => fiscalYear.year === year);
  const wepaAnnual = (wepaBookings ?? []).find((annual) => annual.fiscalYear === year);

  const isAdminServiceConfirmed =
    adminServiceFiscalYear?.dataUploadCompleted ?? !adminServiceFiscalYear?.id;
  const isWepaConfirmed = !wepaAnnual?.id || isCustomerFinal(wepaAnnual);

  const fiscalYear: EntireFiscalYear = {
    year,
    adminServiceFiscalYearId: adminServiceFiscalYear?.id ?? null,
    wepaAnnualId: wepaAnnual?.id ?? null,
    isConfirmed: isAdminServiceConfirmed && isWepaConfirmed
  };

  const confirmFiscalYear = async () => {
    if (!isAdminServiceConfirmed) {
      await confirmFiscalYearById(adminServiceFiscalYear?.id!);
    }

    if (!isWepaConfirmed) {
      await confirmWepaBookingById(wepaAnnual?.id!);
    }
  };

  return (
    <>
      {company && (adminServiceFiscalYear || wepaAnnual) && (
        <EntireFiscalYearConfirmation
          company={company}
          fiscalYear={fiscalYear}
          confirmFiscalYear={confirmFiscalYear}
        />
      )}
    </>
  );
};
