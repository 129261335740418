import React from "react";
import { useMutation } from "react-apollo";
import { INVITE_USER_MUTATION } from "../../../lib/api/mutations/INVITE_USER";
import InviteUserForm from "./InviteUserForm";

export const InviteUserFormContainer = () => {
  const [inviteUserMutation, inviteUserMutationRequest] = useMutation(INVITE_USER_MUTATION);

  const onSubmit = async (values, { resetForm }) => {
    await inviteUserMutation({
      variables: {
        email: values.email,
        role: values.role
      }
    });
    resetForm();
  };

  const successfulInvite = inviteUserMutationRequest?.data?.inviteUser?.feedback === "SUCCESS";

  return (
    <InviteUserForm
      onSubmit={onSubmit}
      requests={[inviteUserMutationRequest]}
      successfulInvite={successfulInvite}
    />
  );
};
