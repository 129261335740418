import { RidePagination } from "../../../uiLibrary/designSystem/RidePagination/RidePagination";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import React, { useState } from "react";
import FormerClientAssignments from "./FormerClientAssignments";
import { GetFormerClientAssignmentsQuery } from "../../../lib/api/queries/GetFormerClientAssignmentsQuery";

import { useQueryParams } from "../../../lib/hooks/useQueryParams";

export const FormerClientAssignmentsContainer = () => {
  const pageSize = 15;
  const [start, setStart] = useState(0);

  const query = useQueryParams();
  const clientId = query.get("clientId") ?? undefined;
  const advisorId = query.get("advisorId") ?? undefined;

  const request = GetFormerClientAssignmentsQuery({
    start,
    limit: pageSize,
    clientId,
    advisorId
  });

  return (
    <RidePagination
      count={request.data?.getFormerClientAssignments?.count ?? 0}
      start={start}
      pageSize={pageSize}
      setStart={setStart}>
      <RequestFeedbackHandler requests={[request]}>
        <FormerClientAssignments
          assignments={request.data?.getFormerClientAssignments?.items ?? []}
        />
      </RequestFeedbackHandler>
    </RidePagination>
  );
};
