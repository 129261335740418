export enum AssetType {
  STOCKS = "stocks",
  BONDS = "bonds",
  OPTIONS = "options",
  FUTURES = "futures",
  FOREX = "forex",
  CFD = "cfd",
  MARGIN = "margin"
}

export enum TradingExperienceLevel {
  LIMITED = "limited",
  GOOD = "good",
  EXTENSIVE = "extensive"
}

export interface AssetExperience {
  knowledgeLevel?: TradingExperienceLevel;
  tradesLastThreeYears?: number;
  yearsTrading?: number;
}

export type Assets = Partial<Record<AssetType, AssetExperience>>;
