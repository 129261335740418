import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import {
  RideBadgeColorsEnum,
  RideBadgeSizeVariant,
  RideBadgeStatus
} from "uiLibrary/components/RideBadgeStatus";

export interface InviteStatus {
  hasFinApiAccount: boolean;
  invitedAt: string | null;
}

interface UserFinApiAccountStatusProps extends WithTranslation {
  hasFinApiAccount: boolean;
}

const UserFinApiAccountStatus = ({ t, hasFinApiAccount }: UserFinApiAccountStatusProps) => {
  let label: string;
  let type = RideBadgeColorsEnum.INFO;

  if (hasFinApiAccount) {
    label = t("generic:finapi-account-yes");
    type = RideBadgeColorsEnum.SUCCESS;
  } else {
    label = t("generic:finapi-account-no");
    type = RideBadgeColorsEnum.DANGER;
  }

  return (
    <RideBadgeStatus
      dataTestId="finApi-account-status-label"
      label={label}
      color={type}
      sizeVariant={RideBadgeSizeVariant.SMALL}
    />
  );
};

export default withTranslation(["generic"])(UserFinApiAccountStatus);
