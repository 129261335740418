import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { MyCompaniesSummarized_companies } from "lib/api/queries/graphql/MyCompaniesSummarized";
import { useRideFormik } from "lib/hooks/useRideFormik";
import * as Yup from "yup";
import { requiredString } from "lib/validation/requiredString";
import { Form, FormikProvider } from "formik";
import { cloneDeep } from "lodash";
import { ChooseCompany, toCompanySummaries } from "./ChooseHoldingCompanyStep.partials";

export interface ChooseHoldingCompanyStepProps extends StepComponentProps, WithTranslation {
  rawCompanies: MyCompaniesSummarized_companies[];
}

export const ChooseHoldingCompanyStep = withTranslationReady(["generic"])(
  ({ order, t, rawCompanies, controls, currentStep, saveData }: ChooseHoldingCompanyStepProps) => {
    const companies = toCompanySummaries(rawCompanies);
    const holdingCompanies = order.extra?.holdingCompanies ?? {};

    const chapterKeyParts = currentStep.chapter.key.split("-");
    const holdingIndex = chapterKeyParts[chapterKeyParts.length - 1];

    const onSubmit = async (values: { holdingCompany: string | null }) => {
      const update = cloneDeep(holdingCompanies);

      const selected = companies.find((c) => c.id === values.holdingCompany);
      if (!selected) {
        console.warn(`selected company wasn't found`);
        return;
      }

      const getOrInit = (object: any, key: string) => {
        object[key] = object[key] || {};
        return object[key];
      };

      const holding = getOrInit(update, holdingIndex);
      holding.entityId = values.holdingCompany;

      const companyInfo = getOrInit(holding, "companyInfo");
      companyInfo.name = selected.name;
      companyInfo.legalForm = selected.legalForm;
      companyInfo.taxId = selected.taxNumber;
      companyInfo.lei = selected.lei;
      companyInfo.registryNumber = selected.registration;

      const address = getOrInit(holding, "companyAddress");
      address.streetLine1 = selected.businessAddress?.streetLine1;
      address.streetLine2 = selected.businessAddress?.streetLine2;
      address.country = selected.businessAddress?.country;
      address.city = selected.businessAddress?.city;
      address.postalCode = selected.businessAddress?.postalCode;

      currentStep.complete();

      await saveData({
        ...currentStep.chapter.order.serialize(),
        holdingCompanies: update
      });
    };

    const formik = useRideFormik({
      initialValues: {
        holdingCompany: holdingCompanies[holdingIndex]?.entityId
      },
      onSubmit,
      validationSchema: Yup.object({
        holdingCompany: requiredString(t)
      }),
      enableReinitialize: true
    });

    return (
      <div data-testid="choose-holding-company">
        <FormikProvider value={formik}>
          <Form>
            <ChooseCompany
              heading={t("generic:ride-broker-onboarding.choose-holding-company.title")}
              field="holdingCompany"
              companies={companies}
            />
            {controls}
          </Form>
        </FormikProvider>
      </div>
    );
  }
);
