import React from "react";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";
import { PendingText } from "../../../components/client/company/components/PendingText";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";

interface GmbHPurposeDetailsProps extends CompanyCardProps {}

const CompanyPurposeCard = ({ t, gmbh, handleEditField }: GmbHPurposeDetailsProps) => {
  return (
    <RideSectionCard
      title={t("client:company:purpose")}
      data-testid="gmbh-purpose-details"
      className="shadow-none mt-5">
      <RideCardValuePair
        data-testid="company-purpose"
        onEdit={handleEditField?.("business-purpose")}
        alignment={"Column"}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}>
        {gmbh.purpose ?? <PendingText t={t} />}
      </RideCardValuePair>
    </RideSectionCard>
  );
};

export default CompanyPurposeCard;
