import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { ViewCompanyCreationSteps } from "../ViewCompanyCreationSteps/ViewCompanyCreationSteps";
import { arrayOfSteps, companyCreation, getCompletedSteps } from "../../getCompletedSteps";
import LoadingAlert from "../../../../generic/LoadingAlert";
import { GetMyCompanyCreation } from "../../Queries";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../graphql/MyCompanyCreationQuery";
import { Redirect, useParams } from "react-router";
import { CompanyCreationStep, FlowTypeEnum } from "../../../../../global-query-types";
import { getFlowTypePath } from "../../../CompanyCreationFlowType/currentFlowType";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { DataLayer } from "../../../../../lib/services/GoogleTagManager/GoogleTagManager";

interface CompanyCreationPageProps {
  t: TFunction;
  currentFlowType: FlowTypeEnum;
}

export const CompanyCreationPage = ({ t, currentFlowType }: CompanyCreationPageProps) => {
  const { id } = useParams<{ id: string }>();
  const { data, error, loading, refetch } = GetMyCompanyCreation(id);

  const [showCreationSteps, setShowCreationSteps] = useState(true);
  const flowType = getFlowTypePath(currentFlowType);
  const handleChange = () => {
    setShowCreationSteps(!showCreationSteps);

    DataLayer.VirtualPageViewWithinFlow({
      stepName: companyCreation.STEP_ONE,
      flowType: flowType
    });
  };

  if (loading) return <LoadingAlert t={t} message={t("generic:loading-message")} />;
  if (error) return <ErrorAlertLegacy t={t} error={error.message} />;

  const myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation | null | undefined =
    data?.myCompanyCreation;

  const currentStep = myCompanyCreationDetails?.currentStep || "";
  const completedSteps = getCompletedSteps(currentStep, arrayOfSteps);

  if (currentStep === CompanyCreationStep.ORDERED) {
    return <Redirect to={`/${flowType}/${myCompanyCreationDetails?.id}/status`} />;
  }

  return (
    <Container className="mb-5 flex-fill">
      <Row className="mt-sm-5 mb-sm-4 mt-4 mb-3">
        <Col className="d-flex offset-sm-1 offset-0 pl-sm-4 pl-2 justify-content-center justify-content-sm-start">
          <Button
            data-testid="start-now"
            className="start-now-btn"
            hidden={!showCreationSteps || currentStep.length > 0}
            onClick={handleChange}>
            {t("generic:start-now")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex pl-5">
          <ViewCompanyCreationSteps
            completedSteps={completedSteps}
            t={t}
            showCreationSteps={showCreationSteps}
            myCompanyCreationDetails={myCompanyCreationDetails!}
            refetchMyCompanyCreation={refetch}
            currentFlowType={currentFlowType}
          />
        </Col>
      </Row>
    </Container>
  );
};
