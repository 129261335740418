export const taxOfficeList = [
  "Aachen-Kreis",
  "Aachen-Stadt",
  "Aalen",
  "Ahaus",
  "Alsfeld-Lauterbach",
  "Altena",
  "Altenburg",
  "Altenkirchen-Hachenburg",
  "Amberg",
  "Angermünde",
  "Annaberg",
  "Ansbach",
  "Arnsberg",
  "Aschaffenburg",
  "Augsburg-Land",
  "Augsburg-Stadt",
  "Aurich",
  "Backnang",
  "Bad Bentheim",
  "Bad Gandersheim",
  "Bad Homburg vor der Höhe",
  "Bad Kissingen",
  "Bad Kreuznach",
  "Bad Neuenahr-Ahrweiler",
  "Bad Neustadt a.d.Saale",
  "Bad Segeberg",
  "Bad Urach",
  "Baden-Baden",
  "Baden-Baden Außenstelle Bühl",
  "Balingen",
  "Bamberg",
  "Bautzen",
  "Bayreuth",
  "Beckum",
  "Bensheim",
  "Bensheim ASt. Fürth",
  "Berchtesgaden-Laufen",
  "Bergheim",
  "Bergisch Gladbach",
  "Bezeichnung",
  "Biberach",
  "Biberach ASt. Riedlingen",
  "Bielefeld-Außenstadt",
  "Bielefeld-Innenstadt",
  "Bietigheim-Bissingen",
  "Bingen-Alzey",
  "Bitburg-Prüm",
  "Bitburg-Prüm (Außenstelle)",
  "Bitterfeld-Wolfen",
  "Böblingen",
  "Bochum-Mitte",
  "Bochum-Süd",
  "Bonn-Außenstadt",
  "Bonn-Innenstadt",
  "Borken",
  "Bottrop",
  "Brandenburg",
  "Braunschweig-Altewiekring",
  "Braunschweig-Wilhelmstraße",
  "Bremen",
  "Bremerhaven",
  "Brilon",
  "Bruchsal",
  "Brühl",
  "Buchholz in der Nordheide",
  "Bünde",
  "Burgdorf",
  "Burghausen",
  "Calau",
  "Calw",
  "Celle",
  "Cham",
  "Charlottenburg",
  "Chemnitz-Mitte",
  "Chemnitz-Süd",
  "Cloppenburg",
  "Coburg",
  "Coesfeld",
  "Cottbus",
  "Cuxhaven",
  "Dachau",
  "Darmstadt",
  "Deggendorf",
  "Delmenhorst",
  "Dessau-Roßlau",
  "Detmold",
  "Dieburg",
  "Dillenburg",
  "Dillingen a.d. Donau",
  "Dingolfing",
  "Dinslaken",
  "Dithmarschen (16)",
  "Dithmarschen (23)",
  "Döbeln",
  "Dortmund-Hörde",
  "Dortmund-Ost",
  "Dortmund-Unna",
  "Dortmund-West",
  "Dresden-Nord",
  "Dresden-Süd",
  "Duisburg-Hamborn",
  "Duisburg-Süd",
  "Duisburg-West",
  "Düren",
  "Düsseldorf-Altstadt",
  "Düsseldorf-Mettmann",
  "Düsseldorf-Mitte",
  "Düsseldorf-Nord",
  "Düsseldorf-Süd",
  "Ebersberg",
  "Eberswalde",
  "Eckernförde-Schleswig",
  "Eggenfelden",
  "Ehingen",
  "Eichstätt",
  "Eilenburg",
  "Eisenach",
  "Eisleben",
  "Elmshorn",
  "Emden",
  "Emmendingen",
  "Erding",
  "Erfurt",
  "Erkelenz",
  "Erlangen",
  "Eschwege-Witzenhausen",
  "Essen-NordOst",
  "Essen-Süd",
  "Esslingen",
  "Ettlingen",
  "Euskirchen",
  "Flensburg",
  "Forchheim",
  "FR-Land ASt. Titisee-Neustadt",
  "Frankfurt (Oder)",
  "Frankfurt am Main - I",
  "Frankfurt am Main - II",
  "Frankfurt am Main - III",
  "Frankfurt am Main IV",
  "Frankfurt am Main V-Höchst",
  "Frankfurt/M V-Höchst",
  "Freiberg",
  "Freiburg-Land",
  "Freiburg-Stadt",
  "Freising",
  "Freudenstadt",
  "Friedberg (Hessen)",
  "Friedrichshafen",
  "Friedrichshain-Kreuzberg",
  "Fulda",
  "für Außenprüfung Bremen",
  "Fürstenfeldbruck",
  "Fürth",
  "Garmisch-Partenkirchen",
  "Geilenkirchen",
  "Geldern",
  "Gelnhausen",
  "Gelsenkirchen",
  "Genthin",
  "Gera",
  "Gießen",
  "Gifhorn",
  "Göppingen",
  "Göppingen ASt. Geislingen",
  "Görlitz",
  "Goslar",
  "Gotha",
  "Göttingen",
  "Grafenau",
  "Greifswald",
  "Grevenbroich",
  "Grimma",
  "Groß-Gerau",
  "Gummersbach",
  "Günzburg",
  "Gunzenhausen",
  "Güstrow",
  "Gütersloh",
  "Hagen",
  "Hagenow",
  "Haldensleben",
  "Halle(Saale)",
  "Hamburg für Steuererhebung",
  "Hamburg-Altona",
  "Hamburg-Am Tierpark",
  "Hamburg-Barmbek-Uhlenhorst",
  "Hamburg-Eimsbüttel",
  "Hamburg-Hansa",
  "Hamburg-Harburg",
  "Hamburg-Mitte",
  "Hamburg-Nord (17)",
  "Hamburg-Nord (49)",
  "Hamburg-Oberalster",
  "Hamburg-Ost (44)",
  "Hamburg-Ost (51)",
  "Hameln",
  "Hamm",
  "Hanau",
  "Hannover-Land I",
  "Hannover-Land I ASt. Springe",
  "Hannover-Land II",
  "Hannover-Mitte",
  "Hannover-Nord",
  "Hannover-Süd",
  "Hattingen",
  "Heidelberg",
  "Heidenheim",
  "Heilbronn",
  "Helmstedt",
  "Herford",
  "Herne",
  "Hersbruck",
  "Hersfeld-Rotenburg",
  "Herzberg am Harz",
  "Hilden",
  "Hildesheim-Alfeld",
  "Hilpoltstein",
  "Hof",
  "Hofheim am Taunus",
  "Holzminden",
  "Homburg - Außenst. St. Ingbert",
  "Homburg (Saar)",
  "Höxter",
  "Hoyerswerda",
  "Ibbenbüren",
  "Idar-Oberstein",
  "Ilmenau",
  "Ingolstadt",
  "Iserlohn",
  "Itzehoe",
  "Jena",
  "Jülich",
  "Kaiserslautern",
  "Kamp-Lintfort",
  "Karlsruhe-Durlach",
  "Karlsruhe-Stadt",
  "Kassel I",
  "Kassel II - Hofgeismar",
  "Kaufbeuren",
  "Kelheim",
  "Kempen",
  "Kempten-Immenstadt (123)",
  "Kempten-Immenstadt (127)",
  "Kiel",
  "Kitzingen",
  "Kleve",
  "Koblenz",
  "Köln-Altstadt",
  "Köln-Mitte",
  "Köln-Nord",
  "Köln-Ost",
  "Köln-Porz",
  "Köln-Süd",
  "Köln-West",
  "Königs Wusterhausen",
  "Konstanz",
  "Korbach-Frankenberg",
  "Krefeld",
  "Kronach",
  "Kulmbach",
  "Kusel-Landstuhl",
  "Kusel-Landstuhl (Außenstelle)",
  "Kyritz",
  "Lahr",
  "Landau",
  "Landesfinanzkasse Daun",
  "Landeshauptkasse Bremen",
  "Landsberg am Lech",
  "Landshut",
  "Langen",
  "Leer (Ostfriesland)",
  "Leipzig I",
  "Leipzig II",
  "Lemgo",
  "Leonberg",
  "Leverkusen",
  "Lichtenberg",
  "Lichtenfels",
  "Limburg-Weilburg",
  "Lindau (Bodensee)",
  "Lingen (Ems)",
  "Lippstadt",
  "Löbau",
  "Lohr a. Main",
  "Lörrach",
  "Lübbecke",
  "Lübeck",
  "Luckenwalde",
  "Lüdenscheid",
  "Lüdinghausen",
  "Ludwigsburg",
  "Ludwigshafen",
  "Lüneburg",
  "Magdeburg",
  "Mainz",
  "Mannheim-Neckarstadt",
  "Mannheim-Stadt",
  "Marburg-Biedenkopf",
  "Marl",
  "Marzahn-Hellersdorf",
  "Mayen",
  "Meißen",
  "Memmingen-Mindelheim",
  "Merseburg",
  "Merzig",
  "Meschede",
  "Michelstadt",
  "Miesbach",
  "Minden",
  "Mitte/Tiergarten",
  "Mittweida",
  "Mönchengladbach",
  "Montabaur-Diez",
  "Montabaur-Diez (Außenstelle)",
  "Mosbach",
  "Mosbach Außenstelle Walldürn",
  "Mühlacker",
  "Mühldorf a.Inn",
  "Mühlhausen",
  "Mülheim an der Ruhr",
  "Müllheim",
  "Münster-Außenstadt",
  "Münster-Innenstadt",
  "Münster-Steuerstrafsachen/St.",
  "Nauen",
  "Naumburg",
  "Neu-Ulm",
  "Neubrandenburg",
  "Neukölln",
  "Neumarkt i.d. Opf.",
  "Neumünster",
  "Neunkirchen",
  "Neuss",
  "Neustadt",
  "Neuwied",
  "Nidda",
  "Nienburg/Weser",
  "Norden",
  "Nordenham",
  "Nordfriesland - Hauptsitz Leck",
  "Nördlingen m.ASt.Don.wörth 111",
  "Nördlingen m.ASt.Don.wörth 152",
  "Northeim",
  "Nürnberg-Nord",
  "Nürnberg-Süd",
  "Nürnberg-Zentral",
  "Nürtingen",
  "Nürtingen ASt. Kirchheim",
  "Oberhausen-Nord",
  "Oberhausen-Süd",
  "Obernburg am Main mit Außenstelle Amorbach",
  "Offenbach am Main I",
  "Offenbach am Main II",
  "Offenburg",
  "Offenburg Außenstelle Achern",
  "Offenburg Außenstelle Kehl",
  "Offenburg Außenstelle Wolfach",
  "Öhringen",
  "Oldenburg (Oldb)",
  "Olpe",
  "Oranienburg",
  "Oschatz",
  "Osnabrück-Land",
  "Osnabrück-Stadt",
  "Osterholz-Scharmbeck",
  "Ostholstein",
  "Paderborn",
  "Pankow/Weißensee",
  "Papenburg",
  "Passau",
  "Peine",
  "Pfaffenhofen a.d. Ilm",
  "Pforzheim",
  "Pforzheim ASt. Neuenbürg",
  "Pinneberg",
  "Pirmasens",
  "Pirna",
  "Plauen",
  "Plön",
  "Pößneck",
  "Potsdam",
  "Prenzlauer Berg",
  "Quakenbrück",
  "Quedlinburg",
  "Rastatt",
  "Ratzeburg",
  "Ravensburg",
  "Recklinghausen",
  "Regensburg",
  "Reinickendorf",
  "Remscheid",
  "Rendsburg",
  "Reutlingen",
  "Rheingau-Taunus",
  "Ribnitz-Damgarten",
  "Rosenheim",
  "Rostock",
  "Rotenburg/Wümme",
  "Rottweil",
  "Rottweil ASt. Oberndorf",
  "Saarbrücken - Am Stadtgraben",
  "Saarbrücken - Mainzer Straße",
  "Saarbrücken Außenst. Sulzbach",
  "Saarbrücken Außenst.Völklingen",
  "Saarlouis",
  "Salzwedel",
  "Sankt Augustin",
  "Schleiden",
  "Schöneberg",
  "Schorndorf",
  "Schrobenhausen",
  "Schw. Hall ASt. Crailsheim",
  "Schwabach",
  "Schwäbisch Gmünd",
  "Schwäbisch Hall",
  "Schwalm-Eder",
  "Schwandorf",
  "Schwarzenberg",
  "Schweinfurt",
  "Schwelm",
  "Schwerin",
  "Schwetzingen",
  "Siegburg",
  "Siegen",
  "Sigmaringen",
  "Sigmaringen ASt. Bad Saulgau",
  "Simmern-Zell",
  "Simmern-Zell (Außenstelle)",
  "Singen",
  "Sinsheim",
  "Soest",
  "Solingen",
  "Soltau",
  "Sondershausen",
  "Sonneberg",
  "Spandau",
  "Speyer-Germersheim",
  "St. Wendel",
  "Stade",
  "Stadthagen",
  "Starnberg",
  "Staßfurt",
  "Steglitz",
  "Steinfurt",
  "Stendal",
  "Stollberg",
  "Stormarn",
  "Stralsund",
  "Straubing",
  "Strausberg",
  "Stuttgart I",
  "Stuttgart II",
  "Stuttgart III",
  "Stuttgart IV",
  "Suhl",
  "Sulingen",
  "Syke",
  "Tauberbischofsh. ASt.Bad Merg.",
  "Tauberbischofsheim",
  "Tempelhof",
  "Traunstein",
  "Treptow-Köpenick",
  "Trier",
  "Tübingen",
  "Tuttlingen",
  "Überlingen",
  "Uelzen-Lüchow",
  "Uffenheim",
  "Ulm",
  "Vechta",
  "Velbert",
  "Verden (Aller)",
  "Viersen",
  "Vill-Schw. ASt. Donaueschingen",
  "Villingen-Schwenningen",
  "Waiblingen",
  "Waldsassen",
  "Waldshut-Tiengen",
  "Waldshut-Tiengen ASt.Bad Säck.",
  "Wangen",
  "Warburg",
  "Waren",
  "Warendorf",
  "Wedding",
  "Weiden i.d. Opf.",
  "Weilheim-Schongau",
  "Weinheim",
  "Wesel",
  "Wesermünde",
  "Westerstede",
  "Wetzlar",
  "Wiedenbrück",
  "Wiesbaden I",
  "Wiesbaden II",
  "Wilhelmshaven",
  "Wilmersdorf",
  "Winsen (Luhe)",
  "Wipperfürth",
  "Wismar",
  "Witten",
  "Wittenberg",
  "Wittlich",
  "Wittmund",
  "Wolfenbüttel",
  "Wolfratshausen-Bad Tölz (104)",
  "Wolfratshausen-Bad Tölz (169)",
  "Worms-Kirchheimbolanden",
  "Wunsiedel",
  "Wuppertal-Barmen",
  "Wuppertal-Elberfeld",
  "Würzburg",
  "Zehlendorf",
  "Zeil a. Main",
  "Zentrale Prüfdienste",
  "Zeven",
  "Zschopau",
  "Zwickau",
  "Zwiesel"
];
