import React, { FunctionComponent, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import { AssertionError } from "assert";
import ErrorAlertLegacy from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import LoadingAlert from "../generic/LoadingAlert";
import SuccessAlert from "../generic/SuccessAlert";
import RealEstateEditForm from "./RealEstateEditForm";
import {
  UpdateRealEstateMutation,
  UpdateRealEstateMutationVariables
} from "./graphql/UpdateRealEstateMutation";
import { RealEstateQueryById_realEstatesById } from "./graphql/RealEstateQueryById";
import { RealEstateQueryByCompanyId_realEstateByCompanyId } from "./graphql/RealEstateQueryByCompanyId";
import { noPhotoLocation } from "./NewRealEstateForm";
import { CREATE_REAL_ESTATE_OWNERSHIP_MUTATION, UPDATE_REAL_ESTATE_MUTATION } from "./Mutations";
import { GetRealEstateOwnershipInfoByRealEstateId } from "./getRealEstateOwnershipInfoByRealEstateId";
import {
  CreateRealEstateOwnershipMutation,
  CreateRealEstateOwnershipMutationVariables
} from "./graphql/CreateRealEstateOwnershipMutation";
import logger from "../../common/Logger";
import { REAL_ESTATE_OWNERSHIP_QUERY_BY_REALESTATE_ID, REAL_ESTATE_QUERY } from "./Queries";

interface RealEstateProps extends WithTranslation {
  detailRecords:
    | RealEstateQueryById_realEstatesById
    | RealEstateQueryByCompanyId_realEstateByCompanyId;
}

export const createMembershipShare = async (
  assetId,
  assetOwner,
  assetShortName,
  assetOwnerSince,
  assetOwnershipShare,
  callCreateRealEstateOwnership
): Promise<boolean> => {
  try {
    logger.debug("User creates new RealEstate Ownership");
    const { data } = await callCreateRealEstateOwnership({
      variables: {
        realEstateOwnerId: assetOwner,
        realEstateName: assetShortName,
        realEstateId: assetId,
        share: assetOwnershipShare,
        startsAt: assetOwnerSince,
        endsAt: null
      }
    });

    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    logger.error(error);
    throw new AssertionError({ message: "Creating User RealEstate Ownership not successful" });
  }
};

export const UpdateRealEstate: FunctionComponent<RealEstateProps> = (props) => {
  const { t, detailRecords } = props;
  const [success, setSuccess] = useState(false);

  const { OwnershipDetails, ownershipErrorLoading, ownershipLoading } =
    GetRealEstateOwnershipInfoByRealEstateId(detailRecords.id);

  const [callUpdateMutation, { error, loading }] = useMutation<
    UpdateRealEstateMutation,
    UpdateRealEstateMutationVariables
  >(UPDATE_REAL_ESTATE_MUTATION, {
    refetchQueries: [{ query: REAL_ESTATE_QUERY }]
  });

  const [
    callCreateRealEstateOwnership,
    { error: errorCreateOwnership, loading: loadingCreateOwnership }
  ] = useMutation<CreateRealEstateOwnershipMutation, CreateRealEstateOwnershipMutationVariables>(
    CREATE_REAL_ESTATE_OWNERSHIP_MUTATION,
    {
      refetchQueries: [
        {
          query: REAL_ESTATE_OWNERSHIP_QUERY_BY_REALESTATE_ID,
          variables: { realEstateId: detailRecords.id }
        }
      ]
    }
  );

  const RealEstateDetailData = detailRecords;
  const errorReason =
    (error && error.message) ||
    (ownershipErrorLoading && ownershipErrorLoading.message) ||
    (errorCreateOwnership && errorCreateOwnership.message);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setSuccess(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const submitForm = async (values, assetOwnerSince, assetOwnershipShare) => {
    const ownerSince = assetOwnerSince;
    const myShare = assetOwnershipShare;
    try {
      const {
        data: { updateRealEstate }
      }: any = await callUpdateMutation({
        variables: {
          realEstateId: values.realEstateId,
          label: values.label,
          propertyValue: values.propertyValue,
          shortDescription: values.shortDescription,
          realEstateAssetType: values.realEstateAssetType,
          image: values.image || noPhotoLocation,
          street: values.street,
          city: values.city,
          postalCode: values.postalCode,
          latitude: values.latitude,
          longitude: values.longitude
        }
      });
      if (updateRealEstate) {
        const assetOwner = values.ownerId;
        const assetId = values.realEstateId;
        const assetShortName = values.label;
        const assetOwnerSince = ownerSince;
        const assetOwnershipShare = myShare;

        const respStatus = await createMembershipShare(
          assetId,
          assetOwner,
          assetShortName,
          assetOwnerSince,
          assetOwnershipShare,
          callCreateRealEstateOwnership
        );

        if (respStatus) {
          setSuccess(true);
          setTimeout(() => handleClose(), 3000);
        } else {
          setSuccess(false);
        }
      } else {
        setSuccess(false);
      }
    } catch (error) {
      throw new AssertionError({ message: "Real Estate update not successful" });
    }
  };

  return (
    <>
      <Button variant="link" size={"sm"} onClick={handleShow}>
        {t("generic:edit")}
      </Button>

      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("realEstate:edit-realestate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RealEstateEditForm
            RealEstateDetailData={RealEstateDetailData}
            OwnershipDetails={OwnershipDetails!}
            submitForm={submitForm}
            cancelForm={handleClose}
            loading={loading}
          />
        </Modal.Body>
        <ErrorAlertLegacy t={t} error={errorReason} />

        {ownershipLoading || loadingCreateOwnership || loading ? (
          <LoadingAlert t={t} message={t("realEstate:updaterealestate-loading-message")} />
        ) : null}
        {success ? (
          <SuccessAlert t={t} message={t("realEstate:updaterealestate-success-message")} />
        ) : null}
      </Modal>
    </>
  );
};

export default withTranslation(["generic", "realEstate", "errorCodes"])(UpdateRealEstate);
