import React from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { FileEntityType, FileStorage } from "lib/ports/FileStorage";
import UploadFile from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/UploadFile/UploadFile";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import DoneButton, {
  SubchapterButtonArea
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/DoneButton/DoneButton";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";

interface ShareholderIdOrPassportStepProps extends StepComponentProps<LowTaxBrokerOrderData> {
  fileUploader: FileStorage;
}

const ShareholderIdOrPassportStep = ({
  t,
  order,
  currentStep,
  saveData,
  fileUploader
}: ShareholderIdOrPassportStepProps & WithTranslation) => {
  const index = currentStep?.chapter?.index ?? 0;
  const existingFile = order.extra.shareholders?.[index]?.proofOfIDFile;

  async function uploadFile(file: File) {
    const { fileId, checksum } = await fileUploader.uploadFileForEntity(
      FileEntityType.Order,
      order.id ?? "missing-id",
      file
    );

    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        shareholders: {
          ...order.extra.shareholders,
          [index]: {
            ...order.extra.shareholders?.[index],
            proofOfIDFile: {
              id: fileId,
              checksum,
              name: file.name,
              size: file.size
            }
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );
  }

  async function deleteFile() {
    currentStep.reOpen();
    await saveData(
      {
        ...order.extra,
        shareholders: {
          ...order.extra.shareholders,
          [index]: {
            ...order.extra.shareholders?.[index],
            proofOfIDFile: undefined
          }
        },
        ...currentStep.chapter.order.serialize()
      },
      true
    );
  }

  return (
    <div className="subchapter-order-layout__form">
      <div className="subchapter-order-layout__scrollable">
        <UploadFile
          requirements={t(
            "generic:ride-broker-onboarding.documents.upload-file.requirements.shareholder-id-or-passport"
          )}
          uploadFile={uploadFile}
          existingFile={existingFile}
          deleteFile={deleteFile}
        />
      </div>
      <SubchapterButtonArea>
        <DoneButton isDisabled={!existingFile} />
      </SubchapterButtonArea>
    </div>
  );
};

export const ShareholderIdOrPassport = (fileUploader: FileStorage) =>
  withTranslationReady(["generic"])((props: StepComponentProps & WithTranslation) => (
    <ShareholderIdOrPassportStep {...props} fileUploader={fileUploader} />
  ));
