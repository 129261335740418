import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";

interface ConfirmationPromptProps extends WithTranslation {
  agree: boolean;
  message?: string;
  handleClose: () => void;
  handleConfirmation: () => Promise<void>;
}

export const ConfirmationPrompt = ({
  t,
  agree,
  handleClose,
  message,
  handleConfirmation
}: ConfirmationPromptProps) => {
  return (
    <>
      <Modal show={agree} onHide={handleClose} animation={true} data-testid={`prompt-confirmation`}>
        <Modal.Header closeButton />
        <Modal.Body data-testid="message">{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} data-testid="no-btn">
            {t("generic:no")}
          </Button>
          <Button variant="primary" onClick={handleConfirmation} data-testid="yes-btn">
            {t("generic:yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withTranslation(["generic"])(ConfirmationPrompt);
