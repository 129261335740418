import { FeatureFlags } from "global-query-types";
import { AppConfigQuery_appConfig_featureFlags } from "lib/api/queries/graphql/AppConfigQuery";

export const toFeatureFlagsMap = (
  featureFlagResponse: AppConfigQuery_appConfig_featureFlags[]
): Map<FeatureFlags, boolean> => {
  var featureFlagIsEnabled = new Map<FeatureFlags, boolean>();
  featureFlagResponse.forEach((feature) => {
    featureFlagIsEnabled.set(feature.name, feature.isEnabled);
  });
  return featureFlagIsEnabled;
};
