import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { formatCurrency } from "../../../../common/formatters";

export interface CashMultipleProps extends WithTranslation {
  value?: {
    before?: number;
    after?: number;
  };
  className?: String;
}

const CashMultiple = ({ value, className, t }: CashMultipleProps) => {
  return (
    <RideSectionCard
      title={t("client:investments.cash-multiple.title")}
      className={`cash-multiple ${className ?? ""}`}
      data-testid="cash-multiple">
      <div data-testid="cash-multiple-value" className="cash-multiple__value">
        {value?.before !== undefined ? <strong>{formatCurrency(value?.before)}</strong> : "---"} (
        {value?.after !== undefined ? formatCurrency(value?.after) : "---"})
      </div>
    </RideSectionCard>
  );
};

export default withTranslationReady(["client", "generic"])(CashMultiple);
