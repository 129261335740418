import { CircleWithArrowIcon } from "../icons/CircleWithArrowIcon";
import { ExternalURLIcon } from "../icons/ExternalURLIcon";
import { CircleWithArrowIconInverted } from "../icons/CircleWithArrowIconInverted";
import React from "react";
import { ExternalURLIconOnHover } from "sharedComponents/icons/ExternalURLIconOnHover";
import { ClickToCopy, ClickToCopyColorVariant } from "sharedComponents/ClickToCopy/ClickToCopy";
import {
  ButtonHeight,
  ButtonRadius,
  ButtonTextVariant,
  ButtonVariant,
  RideButtonDeprecated
} from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";

export enum IconVariant {
  arrowIcon = "arrowIcon",
  externalURLIcon = "externalURLIcon",
  copyToClipboard = "copyToClipboard",
  button = "button"
}

export enum ClickableAreaVariant {
  none = "none",
  entireTile = "entireTile"
}

export enum BackgroundColorVariant {
  rideGradient = "rideGradient",
  white = "white"
}

export interface RideTileProps {
  name: string | JSX.Element;
  subTitleSlot?: JSX.Element;
  imageSlot?: JSX.Element;
  detailsSlot?: JSX.Element;
  footerSlot?: JSX.Element;
  onClick?: (MouseEventHandler) => void;
  iconVariant?: IconVariant;
  "data-testid"?: string;
  className?: string;
  clickableAreaVariant?: ClickableAreaVariant;
  backgroundColorVariant?: BackgroundColorVariant;
  copyToClipboardText?: string;
  buttonLabel?: string;
  deleteButton?: JSX.Element | null;
}

export const RideTile = ({
  name,
  className,
  subTitleSlot,
  imageSlot,
  detailsSlot,
  footerSlot,
  onClick,
  iconVariant,
  "data-testid": dataTestId = "ride-tile",
  copyToClipboardText = "",
  buttonLabel = "",
  clickableAreaVariant = ClickableAreaVariant.entireTile,
  backgroundColorVariant = BackgroundColorVariant.white,
  deleteButton = null
}: RideTileProps) => {
  const RideTileArrowIcon = (
    <div data-testid="card-arrow-icon" className="ride-tile__icon">
      <CircleWithArrowIcon className="default-icon" />
      <CircleWithArrowIconInverted className="hover-icon" />
    </div>
  );
  const RideTileIconExternalUrl = (
    <div data-testid="external-url-icon" className="ride-tile__icon">
      <ExternalURLIcon className="default-icon" />
      <ExternalURLIconOnHover className="hover-icon" />
    </div>
  );

  const getIcon = (iconVariant: IconVariant | undefined) => {
    if (iconVariant === IconVariant.externalURLIcon) return RideTileIconExternalUrl;

    if (iconVariant === IconVariant.copyToClipboard)
      return (
        <div className="ride-tile__icon">
          <ClickToCopy
            clickToCopyColorVariant={ClickToCopyColorVariant.black}
            label={""}
            value={copyToClipboardText}
          />
        </div>
      );

    if (iconVariant === IconVariant.button)
      return (
        <div className="ride-tile__button" data-testid="button-icon">
          <RideButtonDeprecated
            variant={ButtonVariant.Primary}
            radius={ButtonRadius.Medium}
            height={ButtonHeight.LARGE}
            textVariant={ButtonTextVariant.V2Light}
            onClick={onClick}>
            {buttonLabel}
          </RideButtonDeprecated>
        </div>
      );

    return RideTileArrowIcon;
  };

  const icon = getIcon(iconVariant);

  const backgroundColorModifier = backgroundColorVariant
    ? `ride-tile--${backgroundColorVariant}`
    : "";

  return (
    <div
      className={`ride-tile ${backgroundColorModifier} ${
        clickableAreaVariant === ClickableAreaVariant.entireTile ? "ride-tile--entireTile" : ""
      } ${clickableAreaVariant !== ClickableAreaVariant.none ? "ride-tile--link" : ""} ${
        className ?? ""
      }`}
      data-testid={dataTestId}
      onClick={clickableAreaVariant === ClickableAreaVariant.entireTile ? onClick : undefined}>
      <div data-testid={`${dataTestId}-body`} className="ride-tile__body">
        {iconVariant && icon}
        <FeatureFlag name={FeatureFlags.DeleteDraftGSOrderForCustomer}>{deleteButton}</FeatureFlag>
        <div className="ride-tile__container">
          {imageSlot && (
            <div data-testid="ride-tile-image-slot" className="ride-tile__image-slot">
              {imageSlot}
            </div>
          )}
          <div className="ride-tile__name-column">
            <div data-testid="card-name" className="ride-tile__name">
              <div className="text-short" data-testid="ride-tile-name">
                {name}
              </div>
            </div>
            <div data-testid="ride-tile-subtitle-slot">{subTitleSlot}</div>
          </div>
          {detailsSlot && (
            <div data-testid="ride-tile-details-slot" className="ride-tile__details-slot">
              {detailsSlot}
            </div>
          )}
        </div>
      </div>
      {footerSlot}
    </div>
  );
};
