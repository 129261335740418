import React from "react";
import { AccountantList } from "./AccountantList";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { GetAllAccountantsRequest } from "../../../components/nominee/Accountant/Queries";

export const AccountantListContainer = () => {
  const allAccountantsRequest = GetAllAccountantsRequest();

  return (
    <RequestFeedbackHandler requests={[allAccountantsRequest]}>
      <AccountantList accountants={allAccountantsRequest.data?.getAllAccountants} />
    </RequestFeedbackHandler>
  );
};
