import gql from "graphql-tag";

export const VALIDATE_STRIPE_SESSION = gql`
  query ValidateStripeSession(
    $sessionId: String!
    $paymentPlatformAccount: PaymentPlatformAccount!
  ) {
    validateStripeSession(sessionId: $sessionId, paymentPlatformAccount: $paymentPlatformAccount)
  }
`;
