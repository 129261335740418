import { formatDateTime } from "common/formatters";
import { OrderCompanyStatusEnum } from "global-query-types";
import { RideHeaderColumn } from "lib/types/types";
import React from "react";
import { WithTranslation } from "react-i18next";
import RideTableCell, { RideTableCellVariant } from "sharedComponents/RideTableCell/RideTableCell";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import { RideBadgeColorsEnum, RideBadgeStatus, RideBadgeVariantsEnum } from "uiLibrary/components";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { getLowTaxBrokerOrders_getLowTaxBrokerOrders_items } from "../../../lib/api/queries/graphql/getLowTaxBrokerOrders";

interface NewBrokerOrdersListProps extends WithTranslation {
  ordersWithCompany: getLowTaxBrokerOrders_getLowTaxBrokerOrders_items[];
}

export const getOrderStatusToRideBadge = (status: string) => {
  switch (status) {
    case OrderCompanyStatusEnum.Completed:
      return { color: RideBadgeColorsEnum.SUCCESS, label: "Completed" };
    case OrderCompanyStatusEnum.Booked:
      return { color: RideBadgeColorsEnum.DANGER, label: "Need action" };
    case OrderCompanyStatusEnum.InProgress:
      return { color: RideBadgeColorsEnum.DARK, label: "Pending" };
    case OrderCompanyStatusEnum.Canceled:
      return { color: RideBadgeColorsEnum.DANGER, label: "Canceled" };
    default:
      return { color: RideBadgeColorsEnum.DARK, label: "Pending" };
  }
};

const NewBrokerOrdersList = ({ t, ordersWithCompany }: NewBrokerOrdersListProps) => {
  const columns: RideHeaderColumn[] = [
    t("generic:new-broker-order-list.columns.order"),
    t("generic:new-broker-order-list.columns.date"),
    t("generic:new-broker-order-list.columns.ride-status"),
    t("generic:new-broker-order-list.columns.ibkr-status"),
    t("generic:new-broker-order-list.columns.pending-tasks")
  ].map((label) => ({
    label,
    name: label.toLowerCase()
  }));

  return (
    <div data-testid="new-broker-orders-list-table" className={"low-tax-broker-orders-list-table"}>
      <RideTableHeader columns={columns} />
      {ordersWithCompany.map((ordersWithCompany, index) => {
        const order = ordersWithCompany.order ?? {
          id: "test-this",
          status: null,
          createdAt: null,
          extra: {} as any
        }; //TODO: look resolver (getLowTaxBrokerOrders) to order not be null
        const tradeCompany = ordersWithCompany.company;
        const ibkrStatus = order.extra?.applicationStatus
          ? `${order.extra?.applicationStatus}: ${order.extra?.applicationStatusDetails?.description} - ${order.extra?.applicationStatusDetails.state}`
          : "not fetched yet";
        const pendingTaskCount = order.extra?.pendingTasks?.length ?? 0;
        return (
          <RideTableRow
            colCount={columns.length}
            key={index}
            className={"low-tax-broker-orders-list-table__row"}>
            <RideTableCell
              link={`/admin/client-services/ride-broker/orders/${order.id}`}
              value={tradeCompany?.name || `non-existent-company-name ${order.id}`} //TODO: test, use formatter to companyName
              variant={RideTableCellVariant.primaryLinkDecorated}
              dataTestId={`${order.id}-trading-company-name`}
            />
            <RideTableCell
              value={formatDateTime(new Date(order.createdAt))}
              variant={RideTableCellVariant.secondaryText}
            />
            <RideTableCell
              value={
                <RideBadgeStatus
                  label={getOrderStatusToRideBadge(order.status ?? "").label}
                  color={getOrderStatusToRideBadge(order.status ?? "").color}
                  variant={RideBadgeVariantsEnum.OUTLINED}
                />
              }
            />
            <RideTableCell value={ibkrStatus} />
            <RideTableCell value={pendingTaskCount} />
          </RideTableRow>
        );
      })}
    </div>
  );
};

export default withTranslationReady(["generic"])(NewBrokerOrdersList);
