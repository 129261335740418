import { useEffect } from "react";
import { FormikConfig, FormikValues, useFormik } from "formik";

export const useRideFormik = <T extends FormikValues = FormikValues>({
  initialValues,
  initialTouched,
  enableReinitialize,
  validate,
  onSubmit,
  validationSchema
}: FormikConfig<T>) => {
  const formik = useFormik({
    initialValues,
    initialTouched,
    enableReinitialize,
    validate,
    onSubmit,
    validationSchema
  });

  useEffect(() => {
    const errors = Object.keys(formik.errors);
    if (formik.isSubmitting && errors.length > 0) {
      const sortErrors = Object.keys(formik.values).filter((v) => errors.includes(v));
      const element = document.getElementsByName(sortErrors[0])[0];
      if (element) {
        element.focus();
        element.scrollIntoView({ block: "center" });
      }
    }
  }, [formik.isSubmitting, formik.errors, formik.values]);

  return formik;
};
