import React, { PropsWithChildren } from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { TaskStatesEnum } from "../../../../global-query-types";
import { TASK_STATUS_PREFIX } from "../../../../admin/components/companies/ProcessStatesSection/ProcessStatesSection";
import { ClientSelect } from "../../../../client/components/ClientSelect/ClientSelect";
import { ActionableTask } from "../../../../common/types/ProcessStatesTypes";

interface SelectOption {
  value: TaskStatesEnum;
  label: string;
}

interface ProcessTaskNomineeViewProps extends PropsWithChildren<WithTranslation> {
  task: ActionableTask;
  isEditMode?: boolean;
  onChange?: (value: SelectOption) => void;
}

const ProcessTaskNomineeView = ({
  task,
  isEditMode = false,
  children,
  t
}: ProcessTaskNomineeViewProps) => {
  const statesTranslated = (state: string) => t(`company-founding-steps:tasks-states.${state}`);

  const options: SelectOption[] = [];
  for (const state in TaskStatesEnum) {
    options.push({
      value: state as TaskStatesEnum,
      label: statesTranslated(state)
    });
  }

  const stepRefStatus = `${TASK_STATUS_PREFIX}${task.ref}`;

  return (
    <Row data-testid="processSteps-task" className="my-2">
      <Col md={8}>
        <div data-testid="task-title" className="font-italic text-primary">
          {t(`company-founding-steps:tasks.name.${task.ref}`)}
        </div>
        <div data-testid="task-description">
          <h6 className="mb-0 mt-1">
            {t(`company-founding-steps:tasks.description-client-title`)}
          </h6>
          {t(`company-founding-steps:tasks.description.${task.ref}`)}
        </div>

        {children}
      </Col>
      {isEditMode ? (
        <Col style={{ minWidth: "150px" }}>
          <ClientSelect name={stepRefStatus} options={options} />
        </Col>
      ) : (
        <Col md={4} data-testid="task-state" className="text-primary">
          {statesTranslated(task.state)}
        </Col>
      )}
    </Row>
  );
};

export default withTranslationReady(["company-founding-steps"])(ProcessTaskNomineeView);
