import React from "react";
import { useMutation, useQuery } from "react-apollo";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import AssignThirdPartyTaxAdvisor from "./AssignThirdPartyTaxAdvisor";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetailsVariables
} from "lib/api/queries/graphql/NomineeCompanyDetails";
import { GET_THIRD_PARTY_TAX_ADVISOR_COMPANIES } from "lib/api/queries/GetThirdPartyTaxAdvisorCompaniesQuery";
import { NOMINEE_COMPANY_DETAILS_QUERY } from "lib/api/queries/GetNomineeCompanyDetailsById";
import {
  getThirdPartyTaxAdvisorCompanies,
  getThirdPartyTaxAdvisorCompaniesVariables
} from "lib/api/queries/graphql/getThirdPartyTaxAdvisorCompanies";
import { ASSIGN_THIRD_PARTY_TAX_SERVICE_TO_COMPANY } from "lib/api/mutations/ASSIGN_THIRD_PARTY_TAX_SERVICE_TO_COMPANY";
import {
  assignThirdPartyTaxServiceToCompany,
  assignThirdPartyTaxServiceToCompanyVariables
} from "lib/api/mutations/graphql/assignThirdPartyTaxServiceToCompany";

export interface AssignThirdPartyTaxAdvisorContainerProps {
  companyId: string;
  ptaCompanyId: string;
  thirdPartyTaxAdvisorCompanyId: string;
  refetch: () => Promise<void>;
}

export const AssignThirdPartyTaxAdvisorContainer = ({
  companyId,
  ptaCompanyId,
  thirdPartyTaxAdvisorCompanyId,
  refetch
}: AssignThirdPartyTaxAdvisorContainerProps) => {
  const [assignThirdPartyTaxServiceToCompanyMutation, assignThirdPartyTaxServiceToCompanyRequest] =
    useMutation<assignThirdPartyTaxServiceToCompany, assignThirdPartyTaxServiceToCompanyVariables>(
      ASSIGN_THIRD_PARTY_TAX_SERVICE_TO_COMPANY
    );

  const listOfPTAsResult = useQuery<
    getThirdPartyTaxAdvisorCompanies,
    getThirdPartyTaxAdvisorCompaniesVariables
  >(GET_THIRD_PARTY_TAX_ADVISOR_COMPANIES, {
    variables: { partnerTaxAdvisorId: ptaCompanyId },
    skip: !ptaCompanyId
  });

  const get3PTAResult = useQuery<NomineeCompanyDetails, NomineeCompanyDetailsVariables>(
    NOMINEE_COMPANY_DETAILS_QUERY,
    {
      variables: {
        id: thirdPartyTaxAdvisorCompanyId
      },
      skip: !thirdPartyTaxAdvisorCompanyId
    }
  );

  const onSubmit = async ({
    thirdPartyTaxAdvisorCompanyId
  }: {
    thirdPartyTaxAdvisorCompanyId: string;
  }): Promise<void> => {
    await assignThirdPartyTaxServiceToCompanyMutation({
      variables: { thirdPartyId: thirdPartyTaxAdvisorCompanyId, clientId: companyId }
    });

    await refetch();
  };

  return (
    <RequestFeedbackHandler
      requests={[get3PTAResult, listOfPTAsResult, assignThirdPartyTaxServiceToCompanyRequest]}>
      <AssignThirdPartyTaxAdvisor
        onSubmit={onSubmit}
        thirdPartyTaxAdvisorCompanies={listOfPTAsResult.data?.getThirdPartyTaxAdvisorCompanies}
        thirdPartyTaxAdvisorCompany={get3PTAResult.data?.companyById}
      />
    </RequestFeedbackHandler>
  );
};
