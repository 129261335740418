import React from "react";
import { useMutation, useQuery } from "react-apollo";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import ShelfCompanyOrderDetails from "./ShelfCompanyOrderDetails";
import {
  getOrderDetails_getOrderDetails_order,
  getOrderDetails_getOrderDetails_reservedCompany
} from "lib/api/queries/graphql/getOrderDetails";
import { UNASSIGN_SHELF_COMPANY_FROM_ORDER } from "lib/api/mutations/UNASSIGN_SHELF_COMPANY_FROM_ORDER";
import {
  UnAssignShelfCompanyFromOrder,
  UnAssignShelfCompanyFromOrderVariables
} from "lib/api/mutations/graphql/UnAssignShelfCompanyFromOrder";
import { GET_AVAILABLE_SHELF_COMPANIES } from "../../../lib/api/queries/GetAvailableShelfCompanies";
import {
  getAvailableShelfCompanies,
  getAvailableShelfCompanies_getAvailableShelfCompanies
} from "../../../lib/api/queries/graphql/getAvailableShelfCompanies";
import {
  transferShelfCompany,
  transferShelfCompanyVariables
} from "lib/api/mutations/graphql/transferShelfCompany";
import { TRANSFER_SHELF_COMPANY_FROM_ORDER } from "lib/api/mutations/TRANSFER_SHELF_COMPANY";
interface ShelfCompanyOrderDetailsContainerProps {
  order: getOrderDetails_getOrderDetails_order | null;
  orderCompany?: getOrderDetails_getOrderDetails_reservedCompany | null;
  onRefresh: () => Promise<void>;
}

const ShelfCompanyOrderDetailsContainer = ({
  order,
  orderCompany,
  onRefresh
}: ShelfCompanyOrderDetailsContainerProps) => {
  const [unAssignShelfCompanyFromOrderRequest] = useMutation<
    UnAssignShelfCompanyFromOrder,
    UnAssignShelfCompanyFromOrderVariables
  >(UNASSIGN_SHELF_COMPANY_FROM_ORDER);

  const handleUnAssign = async () => {
    if (order?.id) {
      await unAssignShelfCompanyFromOrderRequest({
        variables: {
          orderId: order.id
        }
      });
    }

    await onRefresh();
  };

  const [transferShelfCompanyRequest] = useMutation<
    transferShelfCompany,
    transferShelfCompanyVariables
  >(TRANSFER_SHELF_COMPANY_FROM_ORDER);

  const handleTransferShelfCompany = async () => {
    if (order?.id) {
      await transferShelfCompanyRequest({
        variables: {
          orderId: order.id
        }
      });
    }

    await onRefresh();
  };

  const allCompaniesRequest = useQuery<
    getAvailableShelfCompanies,
    getAvailableShelfCompanies_getAvailableShelfCompanies
  >(GET_AVAILABLE_SHELF_COMPANIES, {});

  const companiesResponse = allCompaniesRequest.data?.getAvailableShelfCompanies?.companies ?? [];

  return (
    <RequestFeedbackHandler requests={[allCompaniesRequest]}>
      <ShelfCompanyOrderDetails
        orderId={order?.id}
        companies={companiesResponse}
        orderDetails={{ ...order?.extra, status: order?.status }}
        orderCompany={orderCompany}
        handleUnAssign={handleUnAssign}
        handleTransfer={handleTransferShelfCompany}
        onRefresh={async () => onRefresh()}
      />
    </RequestFeedbackHandler>
  );
};

export default ShelfCompanyOrderDetailsContainer;
