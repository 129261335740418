import { OrderProductTypeEnum } from "global-query-types";
import { GetOrderQuery_getOrder } from "../../../api/queries/graphql/GetOrderQuery";
import { SETUP_COMPANY_FOUNDATION_CONFIG } from "./configs/setupCompanyFoundingForm";
import { ORDER_CHAPTERS_CONFIG_LOW_TAX_BROKER } from "./configs/lowTaxBrokerForm";
import { OrderChaptersConfig } from "../../../../client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { ORDER_COMPANY_FOUNDING_CONFIG } from "./configs/orderCompanyFoundingForm";
import { ADD_EXISTING_COMPANY_FORM } from "./configs/addExistingCompanyForm";
import { ADMIN_SERVICE_ORDER_FORM } from "lib/models/client/order/configs/adminServiceForm";

interface orderFlowInfoProps {
  config: OrderChaptersConfig;
  title: string;
  titleSidebar: string;
}

const OrderTypeConfigMap: {
  [key: string]: orderFlowInfoProps;
} = {
  [OrderProductTypeEnum.CompanyFoundation]: {
    config: SETUP_COMPANY_FOUNDATION_CONFIG,
    title: "generic:chapters-layout.default-company-name",
    titleSidebar: "generic:company-setup.gmbh-setup-sidebar-title"
  },
  [OrderProductTypeEnum.BrokerageAccount]: {
    config: ORDER_CHAPTERS_CONFIG_LOW_TAX_BROKER,
    title: "generic:chapters-layout.low-tax-broker-setup",
    titleSidebar: "generic:company-setup.low-tax-broker-sidebar-title"
  },
  [OrderProductTypeEnum.UserAccountViaCompanyFoundation]: {
    config: ORDER_COMPANY_FOUNDING_CONFIG,
    title: "generic:order-company-foundation.title",
    titleSidebar: "generic:order-company-foundation.title-sidebar"
  },
  [OrderProductTypeEnum.AddExistingCompany]: {
    config: ADD_EXISTING_COMPANY_FORM,
    title: "generic:add-existing-company.title",
    titleSidebar: "generic:add-existing-company.title-sidebar"
  },
  [OrderProductTypeEnum.AdminService]: {
    config: ADMIN_SERVICE_ORDER_FORM,
    title: "generic:admin-service-form.title",
    titleSidebar: "generic:admin-service-form.title-sidebar"
  }
};

export const getOrderFlowInfo = (order: GetOrderQuery_getOrder): orderFlowInfoProps => {
  if (order.productType) return OrderTypeConfigMap[order.productType];
  // TODO: Remove line 40 once we fix the product type in order to always come with a value.
  // It is a required field in the backend, so no reason to make it optional
  return OrderTypeConfigMap[OrderProductTypeEnum.BrokerageAccount];
};
