import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { Form, FormikProps, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";

const DescribeOperatingActivities = ({
  t,
  order,
  saveData,
  controls,
  currentStep
}: WithTranslation & StepComponentProps) => {
  async function onSubmit(values) {
    currentStep.complete();

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize(),
      additionalOperatingActivitiesDescription: values.operatingActivitiesDescription
    });
  }

  const formik = useRideFormik({
    initialValues: {
      operatingActivitiesDescription: order.extra.additionalOperatingActivitiesDescription ?? ""
    },
    onSubmit: onSubmit,
    validate(values) {
      if (values["operatingActivitiesDescription"].trim() === "") {
        return {
          "operating-activities-description": t("generic:validation-required")
        };
      }
      return {};
    }
  });

  return <DescribeOperatingActivitiesLayout formik={formik} controls={controls} />;
};

export default withTranslationReady(["generic"])(DescribeOperatingActivities);

interface DescribeOperatingActivitiesLayoutProps extends WithTranslation {
  formik: FormikProps<any>;
  controls: React.ReactElement;
}

export const DescribeOperatingActivitiesLayout = withTranslationReady(["generic"])(
  ({ t, formik, controls }: DescribeOperatingActivitiesLayoutProps) => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <StepHeading
            text={t("generic:company-setup.steps.describe-operating-activities.title")}
          />
          <RideTextInput
            name="operatingActivitiesDescription"
            placeholder={t("generic:company-setup.steps.describe-operating-activities.placeholder")}
            label={t("generic:company-setup.steps.describe-operating-activities.description")}
            asTextArea
          />
          <InvalidFeedback
            hidden={!formik.errors["operating-activities-description"]}
            testPrefix="operatingActivitiesDescription">
            {formik.errors["operating-activities-description"]}
          </InvalidFeedback>
          {controls}
        </Form>
      </FormikProvider>
    );
  }
);
