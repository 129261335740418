import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { GetStorageConfigQuery } from "../../../components/client/company/components/graphql/GetStorageConfigQuery";

export const GET_STORAGE_CONFIG_QUERY = gql`
  query GetStorageConfigQuery {
    getStorageConfig {
      config
    }
  }
`;

export const GetStorageConfig = () => useQuery<GetStorageConfigQuery>(GET_STORAGE_CONFIG_QUERY);
