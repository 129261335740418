import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import ChapterSummary from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ChapterSummary/ChapterSummary";

const BrokerSetupComplete = ({
  t,
  currentStep,
  order,
  saveData,
  controls
}: WithTranslation & StepComponentProps) => {
  async function onSubmit() {
    currentStep.complete();

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {},
    onSubmit: onSubmit
  });

  const module = "broker-setup-complete";

  return (
    <FormikProvider value={formik}>
      <Form className={module}>
        <div data-testid={module}>
          <ChapterSummary
            title={t("generic:ride-broker-onboarding.broker-setup-complete.title")}
            message={t("generic:ride-broker-onboarding.broker-setup-complete.summary")}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady("generic")(BrokerSetupComplete);
