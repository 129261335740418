import { OrderChaptersConfig } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import SelectCompanyFoundationProduct from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ConfigureOrder/SelectCompanyFoundationProduct/SelectCompanyFoundationProduct";
import ChooseCompanyLegalForm from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ConfigureOrder/ChooseCompanyLegalForm/ChooseCompanyLegalForm";
import ConfirmRideTaxAdvisorSwitch from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ConfigureOrder/ConfirmRideTaxAdvisorSwitch/ConfirmRideTaxAdvisorSwitch";
import SelectFinancialStatementsYears from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ConfigureOrder/SelectFinancialStatementsYears/SelectFinancialStatementsYears";
import ConfirmTaxAdvisorExistence from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ConfigureOrder/ConfirmTaxAdvisorExistence/ConfirmTaxAdvisorExistence";
import ContactDetails from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ContactDetails/ContactDetails/ContactDetails";
import TaxAdvisorContactDetails from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ContactDetails/TaxAdvisorContactDetails/TaxAdvisorContactDetails";
import ConfirmTaxAdvisorNecessity from "client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ConfigureOrder/ConfirmTaxAdvisorNecessity/ConfirmTaxAdvisorNecessity";
import ShortOrderReviewContainer from "../../../../../client/components/ChaptersOrderLayout/OrderCompanyFounding/chapters/ReviewAndSubmit/ShortOrderReview/ShortOrderReviewContainer";

export const ORDER_COMPANY_FOUNDING_CONFIG: OrderChaptersConfig = {
  chapters: {
    "configure-order": {
      icon: "rocket",
      title: "generic:order-company-foundation.chapters.configure-order"
    },
    "contact-details": {
      icon: "id-card",
      title: "generic:order-company-foundation.chapters.contact-details"
    },
    "review-and-submit": {
      icon: "rocket",
      title: "generic:order-company-foundation.chapters.review-and-submit"
    }
  },
  steps: {
    //configure-order
    "select-company-product": SelectCompanyFoundationProduct,
    "confirm-tax-advisor-necessity": ConfirmTaxAdvisorNecessity, //GS prototype (dynamic by SelectCompanyFoundationProduct)
    "choose-company-legal-form": ChooseCompanyLegalForm, //AS prototype (dynamic by SelectCompanyFoundationProduct)
    "confirm-tax-advisor-existence": ConfirmTaxAdvisorExistence, //GS prototype (dynamic by SelectCompanyFoundationProduct)
    "confirm-ride-tax-advisor-switch": ConfirmRideTaxAdvisorSwitch, //AS prototype (dynamic by SelectCompanyFoundationProduct) and dynamic by ConfirmTaxAdvisorExistence
    "select-financial-statements-years": SelectFinancialStatementsYears, //AS prototype(dynamic by SelectCompanyFoundationProduct and  by ConfirmTaxAdvisorExistence)

    //contact-details
    "client-contact-details": ContactDetails,
    "tax-advisor-contact-details": TaxAdvisorContactDetails, //Wepa prototype (dynamic by ConfirmRideTaxAdvisorSwitch)

    //review-and-submit
    "short-order-review": ShortOrderReviewContainer
  },
  contextualHelpSuffix: "gs-short-order-form-flow"
};
