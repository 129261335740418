import React from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/plain.css";
import { disabledTransformation } from "../../common/disabledTransformation";

type Props = {
  defaultCountry?: string;
  name: string;
  type?: string;
  value: string;
  label: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
};

const InputPhone = ({
  defaultCountry = "de",
  onChange,
  name,
  value,
  disabled,
  ...props
}: Props) => {
  const inputProps: any = {
    name: name,
    autoFocus: props.autoFocus,
    disabled: disabled,
    readOnly: disabled
  };

  if (disabled) {
    inputProps.onChange = () => null;
  }

  return (
    <>
      <div data-testid={`${name}-phone`}>
        <label>{props.label}</label>
        <PhoneInput
          country={defaultCountry}
          inputProps={inputProps}
          inputClass="phone-input"
          enableSearch
          disableSearchIcon
          autoFormat
          onChange={disabledTransformation(disabled, onChange)}
          value={value}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default InputPhone;
