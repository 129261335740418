import React, { PropsWithChildren } from "react";

export interface InvalidFeedbackProps extends PropsWithChildren<{}> {
  hidden: boolean;
  testPrefix?: string;
}

export const InvalidFeedback = ({ children, hidden, testPrefix }: InvalidFeedbackProps) => {
  if (hidden) return null;

  return (
    <div
      className="invalid-feedback d-block"
      data-testid={`${testPrefix ? `${testPrefix}-` : ""}invalid-feedback`}>
      {children}
    </div>
  );
};
