import React from "react";
import { LowTaxBrokerDashboardContainer } from "client/components/LowTaxBrokerDashboard/LowTaxBrokerDashboardContainer";
import { LowTaxBrokerOverviewContainer } from "client/components/LowTaxBrokerOverview/LowTaxBrokerOverviewContainer";
import { BrokerBankEnum } from "global-query-types";
import { BrokerageAccount } from "lib/types/types";
import { MyLowTaxBrokerOrder_myLowTaxBrokerOrder } from "lib/api/queries/graphql/MyLowTaxBrokerOrder";
import { GmbHDetails } from "../../../lib/models/client/GmbHDetails";

export type BrokerageAccountLTB = Pick<BrokerageAccount, "id" | "brokerBank" | "accountNumber">;
interface LowTaxBrokerViewSwitcherProps {
  gmbh: GmbHDetails;
  brokerageAccounts: BrokerageAccount[] | null;
  order: MyLowTaxBrokerOrder_myLowTaxBrokerOrder | null | undefined;
}

export const LowTaxBrokerViewSwitcher = ({
  order,
  gmbh,
  brokerageAccounts
}: LowTaxBrokerViewSwitcherProps) => {
  const hasNewBrokerOrder = order?.extra?.isNewBrokerEnabled ?? false;
  const brokerBank = hasNewBrokerOrder ? BrokerBankEnum.LTB2 : BrokerBankEnum.LTB;

  const showLTBDashboard =
    order &&
    brokerageAccounts?.find((brokerageAccount) => brokerageAccount.brokerBank === brokerBank);

  return (
    <div data-testid="low-tax-broker-view-switcher">
      {showLTBDashboard ? (
        <LowTaxBrokerDashboardContainer gmbh={gmbh} brokerageAccount={showLTBDashboard} />
      ) : (
        <LowTaxBrokerOverviewContainer companyId={gmbh.id} />
      )}
    </div>
  );
};
