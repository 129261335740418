import gql from "graphql-tag";

export const ADD_EXISTING_SHAREHOLDER_TO_COMPANY = gql`
  mutation AddExistingShareholderToCompany(
    $type: SearchTypeEnum!
    $entityId: ID!
    $companyId: ID!
  ) {
    addExistingShareholderToCompany(type: $type, entityId: $entityId, companyId: $companyId) {
      feedback
    }
  }
`;
