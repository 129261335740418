import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FlowTypeEnum } from "../../../../../global-query-types";
import { OptionItem } from "../../../../generic/Select";
import {
  CityField,
  CompanyNameField,
  CompanyRegistrationField,
  CompanyTaxNumberField,
  EconomicIdField,
  LegalFormField,
  PostcodeField,
  RegisterCourtField,
  StreetAndNumberField
} from "../../../CompanyCreation/fields";

export const CompanyFields = ({
  t,
  formik,
  legalForms,
  currentFlowType,
  prefix,
  disabled = false
}) => {
  const legalFormOptions: OptionItem[] = legalForms.map((legalForm) => {
    return {
      title: t(`generic:legalForm.${legalForm}`),
      value: legalForm
    };
  });

  return (
    <div className="flex-column" data-testid="company-fields">
      <div className="mt-md-5 px-md-5">
        <Row>
          <Col xs={12} md={6}>
            <CompanyNameField
              t={t}
              formik={formik}
              placeholder={t("admin-service:name")}
              label={t("admin-service:companyName")}
              disabled={disabled}
            />
          </Col>
          <Col xs={12} md={6}>
            <LegalFormField
              t={t}
              formik={formik}
              legalForms={legalFormOptions}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <CompanyRegistrationField t={t} formik={formik} disabled={disabled} />
          </Col>
          <Col xs={12} md={6}>
            <RegisterCourtField t={t} formik={formik} disabled={disabled} />
          </Col>
        </Row>
        {currentFlowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING ? (
          <Row className="mt-3">
            {/* This is invisible temporarily because Wirtschafts-ID are currently not being issued */}
            <Col xs={12} md={6} style={{ display: "none", visibility: "hidden" }}>
              <EconomicIdField t={t} formik={formik} disabled={disabled} />
            </Col>
            <Col xs={12} md={6}>
              <CompanyTaxNumberField t={t} formik={formik} disabled={disabled} />
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row className="mt-3">
          <Col xs={12} md={6}>
            <StreetAndNumberField t={t} formik={formik} disabled={disabled} />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col>
                <CityField t={t} formik={formik} disabled={disabled} />
              </Col>
              <Col>
                <PostcodeField t={t} formik={formik} disabled={disabled} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row className="mt-3">
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              data-testid={`${prefix}-submit`}
              className="step-submit-btn"
              variant="outline-light">
              {t("generic:save")}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
