import { PersonData } from "./PersonData";
import { CitizenshipData } from "./CitizenshipData";
import { AddressData } from "../AddressData";
import { Shareholder } from "./Shareholder";
import { Entity } from "./Entity";

export type EmployeeDict = {
  [key: number]: Employee;
};

export interface Employee {
  personData?: PersonData;

  personCitizenship?: CitizenshipData;

  personAddress?: AddressData;

  isManagingDirector?: boolean;
}

export class EmployeeEntity implements Employee, Entity {
  readonly key = "employee";

  personData?: PersonData;
  personCitizenship?: CitizenshipData;
  personAddress?: AddressData;
  isManagingDirector?: boolean;

  constructor(
    readonly index: number | string,
    { isManagingDirector, personAddress, personCitizenship, personData }: Employee
  ) {
    this.isManagingDirector = isManagingDirector;
    this.personAddress = personAddress;
    this.personCitizenship = personCitizenship;
    this.personData = personData;
  }

  get email() {
    return this.personData?.email;
  }
}

export const Employee = (data: Employee) => data;

Employee.testPersonData = Shareholder.testPersonData;
