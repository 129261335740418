import React, { useEffect, useRef } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { Form, FormikProvider, useFormik } from "formik";
import { InputState } from "../../../sharedComponents/FormControls/RideInput/RideInput";
import RidePhoneInput from "../../../sharedComponents/FormControls/RidePhoneInput/RidePhoneInput";

interface PhoneFormProps extends WithTranslation {
  onSubmit: (phone: string) => Promise<string> | void;
  isEdit: boolean;
  initialValue?: string;
}

const PhoneForm = ({ t, onSubmit, isEdit, initialValue }: PhoneFormProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [clickGetCodeError, setClickGetCodeError] = React.useState("");
  const [inputState, setInputState] = React.useState<InputState>(InputState.DEFAULT);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState = (setState: () => void) => {
    if (mountedRef.current) {
      setState();
    }
  };

  const onClickGetCodeButton = async (values) => {
    safeSetState(() => setIsLoading(true));
    const resultError = await onSubmit(values.phoneInput);

    if (resultError) {
      safeSetState(() =>
        setClickGetCodeError(
          resultError.toLowerCase().includes("invalid parameter")
            ? t("auth:phone-form.invalid-phone-number")
            : t("auth:phone-form.generic-error")
        )
      );
      safeSetState(() => setInputState(InputState.ERROR));
    } else {
      safeSetState(() => setInputState(InputState.ACCEPTED));
    }

    safeSetState(() => setIsLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      phoneInput: initialValue || ""
    },
    onSubmit: onClickGetCodeButton
  });

  const onChange = (
    value,
    data: {},
    event: React.ChangeEvent<HTMLInputElement>,
    formattedData: string
  ) => {
    formik.setFieldValue("phoneInput", value);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <div data-testid="phone-form" className="phoneForm">
          <div data-testid="phone-form-description" className="phoneForm__description">
            {t(`auth:phone-form.${isEdit ? "edit-description" : "description"}`)}
          </div>
          <div className="phoneForm__input">
            <RidePhoneInput
              name="phoneInput"
              value={formik.values.phoneInput}
              label={t("auth:phone-form.input-label")}
              onChange={onChange}
              state={inputState}
            />
            {clickGetCodeError && <div className="phoneForm__error">{clickGetCodeError}</div>}
          </div>
          <RideButtonDeprecated
            data-testid="get-a-code-button"
            variant={ButtonVariant.Primary}
            type="submit"
            className="phoneForm__button"
            isLoading={isLoading}>
            {t("auth:phone-form.get-a-code-button")}
          </RideButtonDeprecated>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["auth"])(PhoneForm);
