import React, { PropsWithChildren, useState } from "react";
import { WithTranslation } from "react-i18next";
import { getCompanyCreationSteps_getCompanyCreationSteps } from "../../../../../../lib/api/queries/graphql/getCompanyCreationSteps";
import { TaskStatesEnum, UserRole } from "../../../../../../global-query-types";
import { Collapse } from "react-bootstrap";
import { ButtonExpand } from "../../../../../../sharedComponents/Buttons/ButtonExpand/ButtonExpand";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";

export interface ProcessGroupWrapperProps extends PropsWithChildren<WithTranslation> {
  group: getCompanyCreationSteps_getCompanyCreationSteps;
}

const ProcessGroupWrapper = ({ group, t, children }: ProcessGroupWrapperProps) => {
  const [isGroupStepOpen, setIsGroupStepOpen] = useState(
    group.state === TaskStatesEnum.IN_PROGRESS
  );

  const openClientTasksCount = countOpenClientTasksForGroup(group);
  const showOpenTasksCountMessage = openClientTasksCount !== 0 && !isGroupStepOpen;

  return (
    <div className={`process-group-wrapper`}>
      <div>
        <div
          className={`process-group-wrapper__heading process-group-wrapper__heading--${group.state}`}>
          <h3
            data-testid="process-state-group-name"
            onClick={() => setIsGroupStepOpen(!isGroupStepOpen)}>
            {t(`company-founding-steps:groups.${group.ref}`)}
          </h3>
          &nbsp;
          <ButtonExpand
            onClick={() => setIsGroupStepOpen(!isGroupStepOpen)}
            expanded={isGroupStepOpen}
          />
        </div>
        {showOpenTasksCountMessage && (
          <div
            data-testid="count-client-tasks-to-be-done"
            className="process-group-wrapper__in-progress-steps">
            {t("company-founding-steps:count-client-tasks-to-be-done", {
              count: openClientTasksCount
            })}
          </div>
        )}
      </div>
      <Collapse in={isGroupStepOpen}>
        <div data-testid="process-group-tasks">{children}</div>
      </Collapse>
    </div>
  );
};

const countOpenClientTasksForGroup = (
  group: getCompanyCreationSteps_getCompanyCreationSteps
): number => {
  let openClientTasksCount = 0;

  for (const step of group.steps ?? []) {
    const openClientTasks = step.tasks?.filter(
      (task) =>
        task.responsibleParty === UserRole.Client && task.state === TaskStatesEnum.TO_BE_DONE
    );
    openClientTasksCount += openClientTasks?.length ?? 0;
  }

  return openClientTasksCount;
};

export default withTranslationReady(["company-founding-steps"])(ProcessGroupWrapper);
