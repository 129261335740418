import * as Yup from "yup";

export const SecurityQuestionsSchema = (t) =>
  Yup.object().shape({
    "first-question": Yup.string().required(t("generic:validation-required")),
    "first-answer": Yup.string().required(t("generic:validation-required")),

    "second-question": Yup.string().required(t("generic:validation-required")),
    "second-answer": Yup.string().required(t("generic:validation-required")),

    "third-question": Yup.string().required(t("generic:validation-required")),
    "third-answer": Yup.string().required(t("generic:validation-required"))
  });
