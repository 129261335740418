import React, { useEffect } from "react";
import ThirdPartyTaxAdvisorMigrate from "thirdPartyTaxAdvisor/components/WepaReform/ThirdPartyTaxAdvisorMigrate/ThirdPartyTaxAdvisorMigrate";
import { useHistory } from "react-router";
import { ModalLayout } from "sharedComponents/templates/ModalLayout/ModalLayout";
import localStore from "common/LocalStore";
import { GetMyTaxAdvisorCompanyWithClientsQuery } from "taxAdvisor/Queries";
import { CompanyType } from "global-query-types";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { SET_3PTA_COMPANY_ADDRESS } from "lib/api/mutations/SET_3PTA_COMPANY_ADDRESS";
import {
  Set3PTACompanyAddress,
  Set3PTACompanyAddressVariables
} from "lib/api/mutations/graphql/Set3PTACompanyAddress";
import { ThirdPartyTaxAdvisorAddressData } from "thirdPartyTaxAdvisor/components/ThirdPartyTaxAdvisorAddress/ThirdPartyTaxAdvisorAddress";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import NavigationService from "lib/services/NavigationService/NavigationService";
import logger from "common/Logger";
import { GET_3PTA_STRIPE_CHECKOUT_SESSION_URL } from "lib/api/queries/get3PTAStripeCheckoutSessionUrl";
import {
  get3PTAStripeCheckoutSessionUrl,
  get3PTAStripeCheckoutSessionUrlVariables
} from "lib/api/queries/graphql/get3PTAStripeCheckoutSessionUrl";
import { should3PTAMigrateWepaStandalone } from "common/functions/should3PTAMigrateWepaStandalone";
import { useQueryParams } from "lib/hooks/useQueryParams";
import {
  ValidateStripeSession,
  ValidateStripeSessionVariables
} from "lib/api/payment/queries/graphql/ValidateStripeSession";
import { VALIDATE_STRIPE_SESSION } from "lib/api/payment/queries/ValidateStripeSession";
import { getPaymentsContext } from "lib/api/payment/context";
import { SignWepaStandaloneContract } from "lib/api/mutations/graphql/SignWepaStandaloneContract";
import { SIGN_WEPA_STANDALONE_CONTRACT } from "lib/api/mutations/SIGN_WEPA_STANDALONE_CONTRACT";
import { usePaymentMutation } from "lib/hooks/payments/usePaymentMutation";
import {
  UpdatePaymentMethod,
  UpdatePaymentMethodVariables
} from "lib/api/payment/mutations/graphql/UpdatePaymentMethod";
import { UPDATE_PAYMENT_METHOD } from "lib/api/payment/mutations/UpdatePaymentMethod";
import { PaymentMethodType, PaymentPlatformAccount } from "payments-query-types";
import { GET_PAYMENT_METHOD_FOR_COMPANY } from "lib/api/payment/queries/GetPaymentMethodForCompany";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

export const ThirdPartyTaxAdvisorMigrateContainer = () => {
  const sessionId = useQueryParams().get("session_id");
  const history = useHistory();

  const getRequest = GetMyTaxAdvisorCompanyWithClientsQuery(CompanyType.ThirdPartyTaxService);
  const taxAdvisorCompanyWithClients = getRequest.data?.getMyTaxAdvisorCompanyWithClients;
  const taxAdvisorCompany = QueryCompanyById(taxAdvisorCompanyWithClients?.id).data?.companyById;

  const isFirstBatchMigration =
    taxAdvisorCompanyWithClients?.migrationRequiredFor3PTASince &&
    new Date(taxAdvisorCompanyWithClients?.migrationRequiredFor3PTASince).getTime() <
      new Date("2024-03-01").getTime();

  const goToSuccessPage = () => {
    history.push(`/third-party-tax-advisor/migrate/success`);
  };

  const onClose = () => {
    localStore.setSessionValue("3pta-migrate-flow-snoozed", true);
    history.push(`/`);
  };

  if (
    taxAdvisorCompanyWithClients?.id &&
    !should3PTAMigrateWepaStandalone(taxAdvisorCompanyWithClients)
  ) {
    history.push(`/`);
  }
  const [set3PTACompanyAddress] = useMutation<
    Set3PTACompanyAddress,
    Set3PTACompanyAddressVariables
  >(SET_3PTA_COMPANY_ADDRESS);

  const businessAddress = taxAdvisorCompany?.businessAddress;

  const set3PTACompanyAddressHandler = async (address: ThirdPartyTaxAdvisorAddressData) => {
    if (!taxAdvisorCompany?.id) {
      return;
    }
    await set3PTACompanyAddress({
      variables: {
        companyId: taxAdvisorCompany?.id,
        city: address.city,
        country: address.country,
        postalCode: address.postalCode,
        streetLine2: address.streetLine2,
        streetLine1: address.streetLine1
      }
    });
  };

  const downloadUnsignedContract = () => {
    const url = `${getEndpoint()}/api/3pta/document-templates/wepa-standalone?apiToken=${getToken()}`;
    NavigationService.instance?.openLink(url);
  };

  const [updatePaymentMethodMutation, updatePaymentMethodRequest] = usePaymentMutation<
    UpdatePaymentMethod,
    UpdatePaymentMethodVariables
  >(UPDATE_PAYMENT_METHOD);

  const updatePaymentMethod = async (paymentMethod: PaymentMethodType) => {
    if (taxAdvisorCompany?.id) {
      await updatePaymentMethodMutation({
        variables: {
          companyId: taxAdvisorCompany.id,
          paymentMethod,
          paymentPlatformAccount: PaymentPlatformAccount.RIDE
        },
        refetchQueries: [
          {
            query: GET_PAYMENT_METHOD_FOR_COMPANY,
            variables: {
              companyId: taxAdvisorCompany.id,
              paymentPlatformAccount: PaymentPlatformAccount.RIDE
            },
            ...getPaymentsContext()
          }
        ]
      });
    }
  };

  const apolloClient = useApolloClient();

  const signContract = async () => {
    try {
      await apolloClient.mutate<SignWepaStandaloneContract>({
        mutation: SIGN_WEPA_STANDALONE_CONTRACT
      });
    } catch (e) {
      logger.error(e);
    }
  };

  const getRedirectUrlHandler = async () => {
    if (taxAdvisorCompany?.id) {
      try {
        const result = await apolloClient.query<
          get3PTAStripeCheckoutSessionUrl,
          get3PTAStripeCheckoutSessionUrlVariables
        >({
          query: GET_3PTA_STRIPE_CHECKOUT_SESSION_URL,
          variables: { companyId: taxAdvisorCompany?.id }
        });

        return result.data.get3PTAStripeCheckoutSessionUrl;
      } catch (error) {
        if (error instanceof Error && error.message.includes("COMPANY_NOT_FOUND")) {
          logger.errorMessage(`Company was not found`);
          logger.error(error);
        }
      }
    }
  };

  const validateSession = async (sessionId: string) => {
    try {
      return await apolloClient.query<ValidateStripeSession, ValidateStripeSessionVariables>({
        query: VALIDATE_STRIPE_SESSION,
        variables: {
          sessionId,
          paymentPlatformAccount: PaymentPlatformAccount.RIDE
        },
        ...getPaymentsContext()
      });
    } catch (e) {
      logger.error(e);
    }
  };

  const completeOnboarding = async (sessionId: string) => {
    const result = await validateSession(sessionId);
    if (result?.data?.validateStripeSession) {
      await updatePaymentMethod(PaymentMethodType.Sepa);
      signContract(); //this is taking too long, so we are not waiting for it.
      goToSuccessPage();
    }
  };

  useEffect(() => {
    if (sessionId) {
      completeOnboarding(sessionId).then();
    }
    // eslint-disable-next-line
  }, [taxAdvisorCompany]);

  return (
    <>
      taxAdvisorCompany?.id && (
      <RequestFeedbackHandler
        requests={[
          updatePaymentMethodRequest,
          { error: getRequest.error, loading: getRequest.loading }
        ]}>
        <ModalLayout onClose={onClose}>
          <ThirdPartyTaxAdvisorMigrate
            goToSuccessPage={goToSuccessPage}
            downloadUnsignedContract={downloadUnsignedContract}
            updatePaymentMethod={updatePaymentMethod}
            signContract={signContract}
            saveAddress={set3PTACompanyAddressHandler}
            existingAddress={{
              postalCode: businessAddress?.postalCode,
              city: businessAddress?.city,
              country: businessAddress?.country ?? "",
              streetLine2: businessAddress?.streetLine2 ?? "",
              streetLine1: businessAddress?.street
            }}
            getRedirectUrlHandler={getRedirectUrlHandler}
            isFirstBatchMigration={isFirstBatchMigration}
          />
        </ModalLayout>
      </RequestFeedbackHandler>
      )
    </>
  );
};
