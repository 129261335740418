import React, { useState } from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import LowTaxBrokerOrdersFilters, {
  LowTaxBrokerOrdersFiltersData
} from "admin/components/LowTaxBrokerOrdersList/LowTaxBrokerOrdersFilters/LowTaxBrokerOrdersFilters";
import { RidePaginationWithSearch } from "../../../uiLibrary/designSystem/RidePagination/RidePaginationWithSearch";
import { GetWepaDirectOrders } from "../../../lib/api/queries/GetWepaDirectOrders";
import WepaDirectOrdersList from "./WepaDirectOrdersList";
import { useMutation } from "react-apollo";
import { ARCHIVE_WEPA_DIRECT } from "../../../lib/api/mutations/ARCHIVE_WEPA_DIRECT";
import {
  ArchiveWepaDirect,
  ArchiveWepaDirectVariables
} from "../../../lib/api/mutations/graphql/ArchiveWepaDirect";

const WepaDirectOrdersListContainer = () => {
  const pageSize = 15;
  const [start, setStart] = useState(0);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState<LowTaxBrokerOrdersFiltersData>({});

  const request = GetWepaDirectOrders({
    start,
    limit: pageSize,
    ...filters,
    query
  });
  const ordersWithCompany = request.data?.getWepaDirectOrders?.items;

  const [archiveWepaDirect] = useMutation<ArchiveWepaDirect, ArchiveWepaDirectVariables>(
    ARCHIVE_WEPA_DIRECT
  );

  const archiveOrder = async (id: string) => {
    await archiveWepaDirect({
      variables: { orderId: id }
    });

    window.location.reload();
  };

  //TODO LTB-1023: change this filter to Wepa Direct-specific filtering options
  return (
    <>
      <LowTaxBrokerOrdersFilters
        onFilterChange={(filters) => {
          setStart(0);
          setFilters(filters);
        }}
      />
      <RidePaginationWithSearch
        count={request.data?.getWepaDirectOrders?.count ?? 0}
        start={start}
        limit={pageSize}
        setStart={setStart}
        setQuery={setQuery}>
        <RequestFeedbackHandler requests={[request]}>
          <WepaDirectOrdersList
            ordersWithCompany={ordersWithCompany ?? []}
            archiveWepaOrder={archiveOrder}
          />
        </RequestFeedbackHandler>
      </RidePaginationWithSearch>
    </>
  );
};

export default WepaDirectOrdersListContainer;
