import React from "react";
import { ClientEntityTableContainer } from "../../components/ClientEntityTable/ClientEntityTableContainer";
import { Col, Row } from "react-bootstrap";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

const ClientEntitiesScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:client-entities")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ClientEntityTableContainer />
        </Col>
      </Row>
    </>
  );
};

export default withTranslationReady("header")(ClientEntitiesScene);
