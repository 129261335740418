import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { DocumentsSectionAdminOperations } from "../DocumentsSectionAdminOperations/DocumentsSectionAdminOperations";
import {
  DocumentsSectionContainer,
  DocumentsSectionContainerProps
} from "../DocumentsSectionAdminOperations/DocumentsSectionAdminOperationsContainer";
import { StorageNodesEntityType } from "global-query-types";
import { NodeTreeCardContainer } from "client/components/Files/NodeTreeCard/NodeTreeCardContainer";
import { NOMINEE_CREATE_PERSON_STORAGE_STRUCTURE } from "../../../lib/api/mutations/NOMINEE_CREATE_PERSON_STORAGE_STRUCTURE";

export const PersonDocumentsSectionContainer: DocumentsSectionContainer = ({
  entity: person,
  refetch,
  t
}: DocumentsSectionContainerProps) => {
  const [createStorageStructureMutation, storageStructureCreateRequest] = useMutation(
    NOMINEE_CREATE_PERSON_STORAGE_STRUCTURE
  );
  const createStorageStructure = async () => {
    await createStorageStructureMutation({
      variables: {
        id: person.id
      }
    });
  };

  const externalStorageNodes = JSON.parse(person.externalStorageNodes ?? "{}");
  const personRootNodeId: string | undefined = externalStorageNodes.personRoot;

  return (
    <DocumentsSectionAdminOperations
      rootNodeId={personRootNodeId}
      createStorageStructure={createStorageStructure}
      externalStorageNodes={externalStorageNodes}
      refetch={refetch}
      requests={[storageStructureCreateRequest]}
      t={t}>
      <NodeTreeCardContainer
        title={t("nominee-dashboard:person.person-documents")}
        entityId={person.id}
        nodeId={personRootNodeId}
        entityType={StorageNodesEntityType.Person}
      />
    </DocumentsSectionAdminOperations>
  );
};
