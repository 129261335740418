import React from "react";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";
import { personNameWithFallback, PersonName } from "lib/formatters/personFormatter";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";

export interface ReviewManagingDirectorsProps {
  directors: PersonName[];
  editLink: string;
  className?: string;
}

export const ReviewManagingDirectors = ({
  directors,
  editLink,
  className = ""
}: ReviewManagingDirectorsProps) => {
  return (
    <CardContainer data-testId="managing-directors" className={className}>
      <ReviewEntry
        name="managing-directors"
        editLink={editLink}
        list={directors.map((director, index) => (
          <React.Fragment key={index}>{personNameWithFallback(director)}</React.Fragment>
        ))}
      />
    </CardContainer>
  );
};
