import { AddressData as LTBAddressData } from "lib/models/client/LowTaxBroker/AddressData";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import { StateConverter } from "../converters/StateConverter";

function formatStreetNameAndNumber(
  streetName: string | undefined,
  buildingNumber: string | undefined
) {
  return streetName && buildingNumber ? [streetName, buildingNumber].join(" ") : undefined;
}

export const formatAddress = (address: LTBAddressData, formatForRideBroker = false): string => {
  const stateConverter = new StateConverter(); //inject
  const { city, streetLine1, streetLine2, postalCode, streetName, buildingNumber, stateCode } =
    address;
  const country = getCountryNameByCode(address.country);
  const stateName = stateConverter.getStateName(stateCode);

  const postalCodeWithCity = [postalCode, city].filter((field) => field && field !== "").join(" ");

  let addressFields;

  const street = formatForRideBroker
    ? streetLine1
    : formatStreetNameAndNumber(streetName, buildingNumber);

  addressFields = [streetLine2, street, postalCodeWithCity, stateName, country];

  return addressFields.filter((field) => field && field !== "").join("\n");
};
