import React from "react";
import { IconProps } from "./IconProps";

export const IconAlert = ({ className, size = 100 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.55">
      <path
        d="M58.6006 25.0001C54.6006 18.8001 45.6006 18.8001 41.7006 25.0001L15.1006 66.4001C10.8006 73.1001 15.6006 81.9001 23.6006 81.9001H76.7006C84.7006 81.9001 89.5006 73.1001 85.2006 66.4001L58.6006 25.0001ZM53.3006 40.0001L52.3006 60.1001H48.9006L47.9006 40.0001H53.3006ZM52.7006 68.6001C52.2006 69.1001 51.5006 69.4001 50.6006 69.4001C49.7006 69.4001 48.9006 69.1001 48.4006 68.6001C47.9006 68.1001 47.6006 67.3001 47.6006 66.3001C47.6006 65.3001 47.9006 64.6001 48.4006 64.1001C48.9006 63.6001 49.6006 63.3001 50.6006 63.3001C51.5006 63.3001 52.3006 63.6001 52.8006 64.1001C53.3006 64.6001 53.6006 65.4001 53.6006 66.3001C53.5006 67.3001 53.2006 68.1001 52.7006 68.6001Z"
        fill="#F53434"
      />
    </g>
  </svg>
);
