import React from "react";
import { FieldArray, Form, Formik } from "formik";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { TFunction } from "i18next";
import { ApolloError } from "apollo-client";
import { AdminServiceEmployeeManagingDirectorsSchema } from "../../../../../lib/validation/AdminServiceEmployeeManagingDirectorsSchema";
import { CompanyCreationField } from "../../../CompanyCreation/Steps/Shareholders/CompanyCreationField";

interface ExternalDirectorFormFieldsProps {
  t: TFunction;
  handleSubmit: (values) => Promise<void>;
  initialExternalDirectors;
  flowType?: any;
  error?: ApolloError;
  loading: boolean;
  stepOneFields;
  currentStep?: string;
}

export const ExternalDirectorFormFields = ({
  t,
  handleSubmit,
  initialExternalDirectors,
  flowType,
  error,
  loading,
  stepOneFields,
  currentStep
}: ExternalDirectorFormFieldsProps) => {
  return (
    <Formik
      initialValues={{ externalDirectors: initialExternalDirectors }}
      onSubmit={handleSubmit}
      validationSchema={AdminServiceEmployeeManagingDirectorsSchema}
      enableReinitialize={true}
      validateOnChange={true}>
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="mt-md-3 mt-0">
            <FieldArray
              name="externalDirectors"
              render={(arrayHelpers) => (
                <div>
                  {formik.values.externalDirectors &&
                    formik.values.externalDirectors.length > 0 &&
                    formik.values.externalDirectors.map((fields, index) => (
                      <div key={index}>
                        <CompanyCreationField
                          t={t}
                          arrayHelpers={arrayHelpers}
                          index={index}
                          formik={formik}
                          values={formik.values.externalDirectors[index]}
                          touched={(formik.touched.externalDirectors ?? [])[index]}
                          errors={(formik.errors.externalDirectors ?? [])[index]}
                          prefix={`externalDirectors.${index}.`}
                          currentFlowType={flowType}
                          currentStep={currentStep}
                          isCurrentUser={false}
                        />
                      </div>
                    ))}

                  <Container>
                    <Row>
                      <Col xs={"12"} md={"6"} className="mt-3">
                        <Button
                          variant="outline-light"
                          className="new-additional-btn"
                          block
                          disabled={arrayHelpers.form.values.externalDirectors.length >= 4}
                          data-testid="additional-externalDirectors"
                          onClick={(event) => {
                            event.preventDefault();
                            arrayHelpers.push(stepOneFields);
                          }}>
                          <FiPlus /> {t("trader-gmbh:new-director")}
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <LoadingAlert t={t} loading={loading} />
                        <ErrorAlertLegacy t={t} error={error?.message} />
                      </Col>
                    </Row>

                    {formik.values.externalDirectors.length > 0 ? (
                      <Row className="mt-3">
                        <Col className="legal-text">
                          {t("trader-gmbh:privacy-notice-shareholder")}
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row className="mt-3">
                      <Col>
                        <div className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            data-testid="submit"
                            className="step-submit-btn"
                            variant="outline-light">
                            {t("generic:save")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
