import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import React from "react";
import { WithTranslation } from "react-i18next";
import { TaskStatesEnum } from "../../../../../global-query-types";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import OpenBankAccountTaskContainer from "../OpenBankAccountTaskContainer/OpenBankAccountTaskContainer";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";

interface OpenBankAccountSwanTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const OpenBankAccountSwanTask = ({ task, onChange, t }: OpenBankAccountSwanTaskProps) => {
  const countryParam = "country=DEU";
  const firstStepLink = (
    <TextLink
      variant={LinkVariant.primary}
      to={t("company-founding-steps:tasks.openBankAccountSwan.firstStepLink")}>
      {t("company-founding-steps:tasks.openBankAccountSwan.firstStepLinkLabel")}
    </TextLink>
  );

  let onBoardingLink = t("company-founding-steps:tasks.openBankAccountSwan.openAccountLink");

  return (
    <OpenBankAccountTaskContainer onChange={onChange} task={task} bankName="Swan">
      <div>
        <div>
          <TextWithOneLink
            text={t("company-founding-steps:tasks.openBankAccountSwan.firstStep", {
              link: "{{link}}"
            })}
            field="link"
            value={firstStepLink}
          />
        </div>
        <div className={"open-bank-account-swan__button-container"}>
          <RideButtonDeprecated
            openExternalUrl={task.state === TaskStatesEnum.TO_BE_DONE}
            href={`${onBoardingLink}${onBoardingLink.includes("?") ? "&" : "?"}${countryParam}`}
            data-testid="openSwanAccountButton"
            className={`open-bank-account-swan__button${
              task.state === TaskStatesEnum.COMPLETED ? " button--secondary--disabled" : ""
            }`}>
            {t("company-founding-steps:tasks.openBankAccountSwan.openAccount")}
          </RideButtonDeprecated>
        </div>
      </div>
      {t("company-founding-steps:tasks.openBankAccountSwan.secondStep")}
      {t("company-founding-steps:tasks.openBankAccountSwan.thirdStep")}
    </OpenBankAccountTaskContainer>
  );
};

interface TextWithOneLinkProps {
  text: string;
  field: string;
  value: JSX.Element;
}

const TextWithOneLink = ({ text, field, value }: TextWithOneLinkProps) => {
  const fieldTemplate = `{{${field}}}`;
  const prefixLength = text.indexOf(fieldTemplate);
  if (prefixLength === -1) return <>{text}</>;
  const suffixIndex = prefixLength + fieldTemplate.length;

  return (
    <>
      {text.slice(0, prefixLength)}
      {value}
      {text.slice(suffixIndex)}
    </>
  );
};

export default withTranslationReady(["company-founding-steps"])(OpenBankAccountSwanTask);
