import BankSelectionOptions, {
  BankOption
} from "client/components/Company/tasks/BankSelectionOptions/BankSelectionOptions";
import React, { useState } from "react";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { getClientBankOptions } from "../../Company/tasks/BankSelectionTask/BankSelectionTask";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { BankType, FeatureFlags } from "global-query-types";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import NavigationService from "lib/services/NavigationService/NavigationService";

interface AddNewBankAccountProps extends WithTranslation {
  onSubmit: (bankProvider: BankType) => Promise<void>;
}

interface AddNewBankAccountWithBlacklistProps extends AddNewBankAccountProps {
  blacklist: BankType[];
}

export const navigateToBankPage = (bankProvider: BankType, t: TFunction) => {
  if (bankProvider === BankType.Swan) {
    const onBoardingLink = t("client:banking.addNewBankAccount.swanOnBoardingLink");
    const germanyParam = "country=DEU";
    NavigationService.instance?.openLink(
      `${onBoardingLink}${onBoardingLink.includes("?") ? "&" : "?"}${germanyParam}`
    );
  }

  if (bankProvider === BankType.Fyrst) {
    const onBoardingLink = t("client:banking.addNewBankAccount.fyrstOnBoardingLink");
    NavigationService.instance?.openLink(onBoardingLink);
  }

  if (bankProvider === BankType.Finom) {
    const onBoardingLink = t("client:banking.addNewBankAccount.finomOnBoardingLink");
    NavigationService.instance?.openLink(onBoardingLink);
  }
};

export const AddBankAccountNaked = ({
  banks,
  onSelect,
  t
}: {
  banks: BankOption[];
  onSelect: (bankName: string) => void;
  t: TFunction;
}) => (
  <>
    <div className="add-new-bank-account__title" data-testid="add-new-bank-account-title">
      {t("client:banking.addNewBankAccount.selectBankTitle")}
    </div>
    <p className="add-new-bank-account__description" data-testid="add-new-bank-account-description">
      {t("client:banking.addNewBankAccount.selectBankDescription")}
    </p>
    <BankSelectionOptions banks={banks} onSelect={onSelect} />
  </>
);

const AddBankAccountWithBlacklist = ({
  t,
  blacklist,
  onSubmit
}: AddNewBankAccountWithBlacklistProps) => {
  const bankAccountsWithoutOwn = getClientBankOptions().filter(
    (bank) => !blacklist.includes(bank.name)
  );
  const [banks, setBanks] = useState<BankOption[]>(bankAccountsWithoutOwn);

  const onSelect = (bankName: string) => {
    const _bankOptions = [...banks];
    _bankOptions.forEach((bankOption, index) => {
      _bankOptions[index] = { ...bankOption, isSelected: bankOption.name === bankName };
    });
    setBanks(_bankOptions);
  };

  const handleSubmit = async () => {
    const bankProvider = banks.find((bank) => bank.isSelected)?.name!;
    await onSubmit(bankProvider);
    navigateToBankPage(bankProvider, t);
  };

  return (
    <div data-testid="add-new-bank-account" className="add-new-bank-account">
      <AddBankAccountNaked banks={banks} onSelect={onSelect} t={t} />
      <div className="add-new-bank-account__button">
        <RideButtonDeprecated
          data-testid={"submit-bank-account"}
          variant={ButtonVariant.Primary}
          size={ButtonSize.Big}
          onClick={handleSubmit}>
          {t("client:banking.addNewBankAccount.selectBankButton")}
        </RideButtonDeprecated>
      </div>
    </div>
  );
};

const AddBankAccount = (props: AddNewBankAccountProps) => (
  <>
    <FeatureFlag name={FeatureFlags.SwanBankSelection}>
      <AddBankAccountWithBlacklist {...props} blacklist={[BankType.Own]} />
    </FeatureFlag>
    <DisabledFeatureFlag name={FeatureFlags.SwanBankSelection}>
      <AddBankAccountWithBlacklist {...props} blacklist={[BankType.Swan, BankType.Own]} />
    </DisabledFeatureFlag>
  </>
);

export default withTranslationReady(["client"])(AddBankAccount);
