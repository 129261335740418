import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import {
  CompanyTaxNumberForRideBrokerSchema,
  CompanyTaxNumberSchema
} from "lib/validation/CompanyTaxNumberSchema";

const CompanyTaxNumberStep = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const initialValues = {
    companyTaxNumber: order?.extra?.companyTaxId ?? ""
  };

  const onSubmit = async () => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        companyTaxId: formik.values["companyTaxNumber"],
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: !!order.extra.isNewBrokerEnabled
      ? CompanyTaxNumberForRideBrokerSchema(t)
      : CompanyTaxNumberSchema(),
    validateOnBlur: true
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <CompanyTaxId />
        {controls}
      </Form>
    </FormikProvider>
  );
};

const CompanyTaxId = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div data-testid="company-tax-number">
      <StepHeading text={t("generic:ride-broker-onboarding.company-tax-number.title")} />
      <RideTextInput
        name="companyTaxNumber"
        label={t("generic:tax-number")}
        placeholder={t("generic:tax-number-placeholder")}
      />
    </div>
  );
});

export default withTranslationReady(["generic"])(CompanyTaxNumberStep);
