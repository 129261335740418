import React from "react";
import { useHistory } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { CompanyDateEditContainer } from "../../components/Company/CompanyDateEdit/CompanyDateEditContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface CompanyNotarizationDateSceneProps extends WithTranslation {}

const CompanyNotarizationDateScene = ({ t }: CompanyNotarizationDateSceneProps) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.notarization")} />
      <ModalFormLayout title={t("client:company.edit.notarization")} onBack={goBack}>
        <CompanyDateEditContainer fieldName={"notarizationDate"} section="overview" />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation(["client"])(CompanyNotarizationDateScene);
