import React from "react";
import { countryListWithFlag } from "lib/dataAdapters/countryUtils/countryUtils";
import {
  RideDropdownSelect,
  RideDropdownSelectProps
} from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";

type CountrySelectProps = Omit<RideDropdownSelectProps, "options" | "isSearchable">;

export const CountrySelect = (props: CountrySelectProps) => {
  return <RideDropdownSelect {...props} options={countryListWithFlag} isSearchable />;
};
