import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { AdminClubDealListContainer } from "admin/components/AdminClubDealList/AdminClubDealListContainer";

const InvestmentClubDeals = ({ t, ...props }: WithTranslation) => {
  return (
    <>
      <h2>{t("nominee-dashboard:investment-club-deals.heading")}</h2>
      <AdminClubDealListContainer {...props} t={t} />
    </>
  );
};

export default withTranslationReady(["nominee-dashboard"])(InvestmentClubDeals);
