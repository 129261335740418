import React from "react";
import Helmet from "react-helmet";

export interface PageMetaDataProps {
  title: string;
}

export const PageMetaData = ({ title }: PageMetaDataProps) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
