import { copyToClipboard } from "common/copyToClipboard";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";

/**
 *
 * @deprecated use sharedComponents/ClickToCopy/ instead
 */
export const ClickToCopyDeprecated = ({ t, textToCopy }: { t: TFunction; textToCopy: string }) => {
  const [copied, setCopied] = useState(false);

  const tooltipText = copied
    ? t("generic:copy-to-clipboard.copied")
    : t("generic:copy-to-clipboard.copy");

  const handleCopy = () => {
    setCopied(copyToClipboard(textToCopy));
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={<Tooltip id={"copyTooltip"}>{tooltipText}</Tooltip>}>
      <Badge
        variant="light"
        onClick={handleCopy}
        style={{ cursor: "pointer" }}
        data-testid="text-to-copy">
        {textToCopy}
      </Badge>
    </OverlayTrigger>
  );
};
