import { Company } from "lib/types/types";
import React, { useState } from "react";
import PtaTerminationContractModal from "../PtaTerminationContractModal/PtaTerminationContractModal";
import PtaSwitchOfferPartnerBanner from "../PtaSwitchOfferPartnerBanner/PtaSwitchOfferPartnerBanner";
import { showAdminServicePTAMigrationToRideSteuerberatungFlow } from "lib/helpers/AdminServicePTAMigrationToRideSteuerberatungHelper";
import localStore from "common/LocalStore";
import { Container } from "react-bootstrap";

interface Props {
  company: Company;
}

export const RideSteuerberaterMigrationPopupWrapper = ({ company }: Props) => {
  const snoozeModalTag = "pta-migration-to-ride-steuerberatung-snoozed";
  const isMigrationEnabledForCompany =
    showAdminServicePTAMigrationToRideSteuerberatungFlow(company);
  const [showModal, setShowModal] = useState(false);

  React.useEffect(() => {
    if (isMigrationEnabledForCompany && !localStore.getSessionValue(snoozeModalTag)) {
      setShowModal(true);
    }
  }, [isMigrationEnabledForCompany]);

  function onHideModal() {
    setShowModal(false);
    snoozeModal();
  }

  function onConfirmModal() {
    snoozeModal();
  }

  function snoozeModal() {
    localStore.setSessionValue(snoozeModalTag, true);
  }

  return (
    <>
      {isMigrationEnabledForCompany && (
        <Container>
          <PtaSwitchOfferPartnerBanner onClick={() => setShowModal(true)} />
          {showModal && (
            <PtaTerminationContractModal onConfirm={onConfirmModal} onClose={onHideModal} />
          )}
        </Container>
      )}
    </>
  );
};
