import { ErrorCodes } from "../../global-query-types";
import logger from "../../common/Logger";

export class ErrorCodeConverter {
  convert(errorCodeString: string) {
    logger.debug(`About to show error '${errorCodeString}`);

    if (errorCodeString.startsWith("Network error: Failed to fetch")) {
      errorCodeString = ErrorCodes.NETWORK_ERROR;
    }

    if (errorCodeString.startsWith("GraphQL error: ")) {
      errorCodeString = errorCodeString.substring(15, errorCodeString.length);
    }

    logger.debug(`Converting error code '${errorCodeString}`);

    const errorCode = errorCodeString as ErrorCodes;
    switch (errorCode) {
      case ErrorCodes.NETWORK_ERROR:
        return "errorCodes:generic.network-error";
      case ErrorCodes.DATA_INCONSISTENT_ERROR:
        return "errorCodes:generic.data-inconsistent";
      case ErrorCodes.DRACOON_MAINTENANCE:
        return "errorCodes:dracoon.maintenance";
      case ErrorCodes.INVALID_PARAMETER_EMAIL_ERROR:
        return "errorCodes:generic.invalid-email-parameter";
      case ErrorCodes.UNAUTHORIZED_TO_UPDATE:
        return "errorCodes:generic.unauthorized-to-update";
      case ErrorCodes.UNAUTHORIZED_TO_READ:
        return "errorCodes:generic.unauthorized-to-read";
      case ErrorCodes.INVITE_USER_FORBIDDEN:
        return "errorCodes:invite-client.forbidden";
      case ErrorCodes.TERMS_AND_CONDITIONS_NOT_ACCEPTED:
        return "errorCodes:generic.terms-and-conditions-not-accepted";
      case ErrorCodes.GMBH_ORDER_REUSED_EMAIL:
        return "errorCodes:order-form.order-email-reused";
      case ErrorCodes.GMBH_ORDER_CURRENT_USER_NOT_SHAREHOLDER:
        return "errorCodes:order-form.current-user-not-shareholder";
      case ErrorCodes.GMBH_ORDER_NO_MANAGING_DIRECTOR:
        return "errorCodes:order-form.managing-director-missing";
      case ErrorCodes.GMBH_ORDER_ADDRESS_INFORMATION_MISSING:
        return "errorCodes:order-form.gmbh-address-missing";
      case ErrorCodes.GMBH_ORDER_COMPANY_REGISTER_COURT_MISSING:
        return "errorCodes:order-form.gmbh-register-court-missing";
      case ErrorCodes.GMBH_ORDER_COMPANY_REGISTRATION_MISSING:
        return "errorCodes:order-form.gmbh-company-registration-missing";
      case ErrorCodes.GMBH_ORDER_EMPLOYEE_IS_NOT_MANAGING_DIRECTOR:
        return "errorCodes:order-form.employee-is-not-managing-director";
      case ErrorCodes.INVALID_GMBH_ORDER_STRUCTURE:
        return "errorCodes:order-form.invalid-gmbh-order-structure";
      case ErrorCodes.COMPANY_NAME_MISSING:
        return "errorCodes:order-form.company-name-missing";
      case ErrorCodes.COMPANY_LEGAL_FORM_NOT_SUPPORTED:
        return "errorCodes:order-form.company-legal-form-not-supported";
      case ErrorCodes.COMPANY_LEGAL_FORM_MISSING:
        return "errorCodes:order-form.company-legal-form-missing";
      case ErrorCodes.GMBH_ORDER_ASSET_NOT_INFORMED:
        return "errorCodes:order-form.gmbh-asset-not-informed";
      case ErrorCodes.GMBH_ORDER_SHAREHOLDER_TYPE_NOT_SUPPORTED:
        return "errorCodes:order-form.invalid-shareholder-type";
      case ErrorCodes.INCORRECT_USER_PASSWORD:
        return "errorCodes:password-change.incorrect-user-password";
      case ErrorCodes.INCORRECT_USER_NEW_PASSWORD:
        return "errorCodes:password-change.incorrect-user-new-password";
      case ErrorCodes.LOGIN_FAILED_UNAUTHORIZED:
        return "errorCodes:login.unauthorized";
      case ErrorCodes.PASSWORD_CHANGE_ERROR:
        return "errorCodes:password-change.internal-error";
      case ErrorCodes.PASSWORD_CHANGE_FORBIDDEN:
        return "errorCodes:password-change.forbidden";
      case ErrorCodes.REGISTRATION_FORBIDDEN:
        return "errorCodes:registration.forbidden";
      case ErrorCodes.SIGNUP_ERROR:
        return "errorCodes:signup.internal-error";
      case ErrorCodes.SIGNUP_FORBIDDEN:
        return "errorCodes:signup.forbidden";
      case ErrorCodes.SIGNUP_TOKEN_EXPIRED:
        return "errorCodes:signup.token-expired";
      case ErrorCodes.USER_SESSION_INVALID_FORBIDDEN:
        return "errorCodes:user-session.invalid-forbidden";
      case ErrorCodes.FIELD_IS_REQUIRED:
        return "errorCodes:field-must-required.forbidden";
      case ErrorCodes.HUBSPOT_CONTACT_FIELD_MISSING:
        return "errorCodes:company-creation.hubspot-contact-field-missing";
      case ErrorCodes.HUBSPOT_CONTACT_NOT_FOUND:
        return "errorCodes:company-creation.hubspot-contact-not-found";
      case ErrorCodes.FINANCIAL_DATA_BANK_EXISTS_FOR_USER:
        return "errorCodes:financial-data.bank-exists-for-user";
      case ErrorCodes.FINANCIAL_DATA_BANK_NOT_FOUND:
        return "errorCodes:financial-data.bank-not-found";
      case ErrorCodes.FINANCIAL_DATA_MULTIPLE_BANKS_FOUND:
        return "errorCodes:financial-data.multiple-banks-found-aborting";
      case ErrorCodes.FINANCIAL_DATA_PSD2_WEBFORM_NOT_REQUIRED:
        return "errorCodes:financial-data.psd2-webform-not-required-but-should-be";
      case ErrorCodes.MAIL_SENDER_CONFIGURATION_INVALID:
        return "errorCodes:sender.config-invalid";
      case ErrorCodes.WALLET_NAME_ALREADY_EXISTS:
        return "errorCodes:wallet.name-already-exists";
      case ErrorCodes.EMAIL_ACCOUNT_ALREADY_EXISTS:
        return "errorCodes:generic.email-already-exists";
      case ErrorCodes.UPDATE_EMAIL_FORBIDDEN:
        return "errorCodes:email-change.email-update-forbidden";
      case ErrorCodes.UPDATE_EMAIL_TOKEN_EXPIRED:
        return "errorCodes:email-change.email-update-token-expire";
      case ErrorCodes.USER_DOES_NOT_EXIST:
        return "errorCodes:email-change.email-update-user-does-not-exists";
      case ErrorCodes.INVALID_UPDATE_EMAIL_TOKEN:
        return "errorCodes:email-change.invalid-update-email-token";
      case ErrorCodes.HOLDING_HAS_ASSETS_UNABLE_TO_DELETE:
        return "errorCodes:company.holding-has-assets-unable-to-delete";
      case ErrorCodes.HOLDING_HAS_WALLET_UNABLE_TO_DELETE:
        return "errorCodes:company.holding-has-wallet-unable-to-delete";
      case ErrorCodes.RESUME_TOKEN_EXPIRED:
        return "errorCodes:trader-gmbh.resume-token-expired";
      case ErrorCodes.RESUME_TOKEN_INVALID:
        return "errorCodes:trader-gmbh.resume-token-invalid";
      case ErrorCodes.RESUME_TOKEN_NOT_FOUND:
        return "errorCodes:trader-gmbh.resume-token-not-found";
      case ErrorCodes.ACCOUNT_VERIFICATION_TOKEN_EXPIRED:
        return "errorCodes:trader-gmbh.token-expired";
      case ErrorCodes.NODE_ALREADY_EXISTS:
        return "errorCodes:storage.node-already-exists";
      case ErrorCodes.CANNOT_DELETE_NON_EMPTY_FOLDER:
        return "errorCodes:storage.cannot-delete-non-empty-folder";
      case ErrorCodes.DISCOUNT_CODE_ALREADY_USED_BY_COMPANY_AND_PERSON:
        return "errorCodes:referral-code:referral-code-already-used";
      case ErrorCodes.DISCOUNT_CODE_NOT_FOUND:
        return "errorCodes:referral-code:referral-code-invalid-or-not-found";
      case ErrorCodes.DISCOUNT_CODE_BLACKLISTED:
        return "errorCodes:referral-code:referral-code-is-no-longer-valid";
      case ErrorCodes.TAX_SERVICE_STORAGE_USER_NOT_CREATED:
        return "errorCodes:tax-service:tax-service-storage-user-not-created";
      case ErrorCodes.COMPANY_NOT_FOUND:
        return "errorCodes:company.not-found";
      case ErrorCodes.IMPROPER_DISCOUNT_CODE_USAGE_STATUS_TRANSITION:
        return "errorCodes:referral-code:referral-code-status-invalid";
      case ErrorCodes.SUPERNOMINEE_RESTRICTED_FEATURE:
        return "errorCodes:invite-client:supernominee-restricted";
      case ErrorCodes.NOTARY_LINKS_MISSING:
        return "errorCodes:notary.links-missing";
      case ErrorCodes.COMPANY_UPDATE_FORBIDDEN:
        return "errorCodes:company.update-forbidden";
      case ErrorCodes.MISSING_ACCOUNT_NUMBER_BROKERAGE_ACCOUNT:
        return "errorCodes:brokerage-account.missing-account-number";
      case ErrorCodes.MISSING_TOKEN_BROKERAGE_ACCOUNT:
        return "errorCodes:brokerage-account.missing-token";
      case ErrorCodes.USER_NOT_THIRD_PARTY_TAX_ADVISOR:
        return "errorCodes:wepa.user-not-third-party-tax-advisor";
      case ErrorCodes.THIRD_PARTY_TAX_ADVISOR_NOT_FOUND:
        return "errorCodes:wepa.third-party-tax-advisor-not-found";
      case ErrorCodes.NOTARY_SERVICE_FIRST_PARTY_NOTARY_REQUIRED:
        return "errorCodes:nominee.notary-service-first-party-notary-required";
      case ErrorCodes.EMAIL_EXISTS_RECOMMEND_LOGIN_INSTEAD:
        return "errorCodes:generic.customer-already-exists";
      case ErrorCodes.SWAN_REQUEST_FAILED:
        return "errorCodes:generic.swan-request-failed";
      case ErrorCodes.IBAN_VALIDATION_ERROR:
        return "errorCodes:generic.iban-validation-error";
      case ErrorCodes.REFERRAL_CODE_NOT_ALLOWED_FOR_THIS_SERVICE:
        return "errorCodes:referral-code.referral-code-not-valid-for-this-service";
      case ErrorCodes.STRIPE_UPDATE_ERROR:
        return "errorCodes:stripe-update-error";
      default:
        logger.warn(`Displaying backend error code '${errorCode}' without translation`);
        return `${errorCode}`;
    }
  }
}
