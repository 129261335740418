import React from "react";
import { CompanyCardProps } from "../../../lib/types/types";
import { PendingText } from "../../../components/client/company/components/PendingText";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";
import { RideCardBodySeparator } from "../../RideCardBodySeparator/RideCardBodySeparator";

interface CompanyBankCardProps extends CompanyCardProps {}

const CompanyBankCard = ({ t, gmbh, handleEditField }: CompanyBankCardProps) => {
  return (
    <RideSectionCard
      data-testid="gmbh-bank-details"
      title={t("client:company:bank-accounts")}
      className="shadow-none mt-5">
      {gmbh.bankAccounts &&
        gmbh.bankAccounts.map((bankAccount, index, array) => {
          return (
            <React.Fragment key={index}>
              <RideCardValuePair
                data-testid="gmbh-bank-details-card"
                name={bankAccount.name || bankAccount.bankProvider}
                value={bankAccount.iban || "---"}
              />
              {index !== array.length - 1 && <RideCardBodySeparator />}
            </React.Fragment>
          );
        })}
      {gmbh.bankAccounts && !gmbh.bankAccounts.length && <PendingText t={t} />}
    </RideSectionCard>
  );
};

export default CompanyBankCard;
