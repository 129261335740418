import React from "react";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export interface LTBDashboardActionCardProps {
  dataTestId?: string;
  title: string;
  description: string;
  buttonLabel: string;
  buttonClickHandler: () => void;
  disabled?: boolean;
}

export const LTBDashboardActionCard = ({
  title,
  description,
  buttonLabel,
  buttonClickHandler,
  disabled = false,
  dataTestId = "ltb-dashboard-action-card"
}: LTBDashboardActionCardProps) => {
  const moduleName = "ltb-dashboard-action-card";
  return (
    <div data-testid={dataTestId} className={moduleName}>
      <div className={`${moduleName}__text`}>
        <div className={`${moduleName}__title`}>{title}</div>
        <div className={`${moduleName}__description`}>{description}</div>
      </div>
      <RideButtonDeprecated
        data-testid={`${dataTestId}-button`}
        onClick={buttonClickHandler}
        disabled={disabled}
        className={`${moduleName}__button`}
        variant={ButtonVariant.PrimaryPastel}>
        {buttonLabel}
      </RideButtonDeprecated>
    </div>
  );
};
