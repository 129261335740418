import { CompanyMigrationType } from "global-query-types";
import { CompanyById_companyById_migrations } from "lib/api/queries/graphql/CompanyById";
import { Company } from "../types/types";

export function isCompanyMigrationRequired(
  company: Company,
  migrationName: CompanyMigrationType
): boolean {
  return !!getCompanyMigrationRequired(company, migrationName);
}

export function getCompanyMigrationRequired(
  company: Company | null | undefined,
  migrationName: CompanyMigrationType
): CompanyById_companyById_migrations | null {
  const migrations = company?.migrations || [];
  const migration = migrations.find(
    (migration) =>
      migration.migrationName === migrationName &&
      !migration.realizedAt &&
      !!migration.requestedSince &&
      new Date(migration.requestedSince).getTime() <= new Date().getTime()
  );

  if (!migration) return null;

  return migration;
}
