import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import Table from "../../../components/generic/Table";
import { AssetType } from "../../../global-query-types";
import EntityLink from "../../../components/common/EntityLink/EntityLink";
import {
  AllInvestments_allInvestments_investments,
  AllInvestments_allInvestments_investments_asset_company,
  AllInvestments_allInvestments_investments_asset_realEstate,
  AllInvestments_allInvestments_investments_investor
} from "../../../lib/api/queries/graphql/AllInvestments";

interface InvestmentsTableProps extends WithTranslation {
  investments: AllInvestments_allInvestments_investments[];
}

interface InvestmentsTableRow {
  id: string;
  assetName: string;
  investor: AllInvestments_allInvestments_investments_investor;
  company: AllInvestments_allInvestments_investments_asset_company | null;
  realEstate: AllInvestments_allInvestments_investments_asset_realEstate | null;
  type: AssetType;
  isTokenized: boolean;
}

type InvestmentsData = InvestmentsTableRow[];

const InvestmentsTable = ({ investments, t }: InvestmentsTableProps) => {
  const investmentsData: InvestmentsData = investments.map((investment) => ({
    id: investment.id,
    assetName: investment.asset.company?.name || investment.asset.realEstate?.label || "",
    investor: investment.investor,
    company: investment.asset.company,
    realEstate: investment.asset.realEstate,
    type: investment.asset.type,
    isTokenized: investment.asset.isTokenized
  }));

  const columns = [
    {
      dataField: "id",
      text: t("nominee-dashboard:investments.id"),
      formatter: (_, investment: InvestmentsTableRow) => {
        return <span data-testid="investment-id">{investment.id}</span>;
      }
    },
    {
      dataField: "assetName",
      text: t("nominee-dashboard:investments.name"),
      formatter: (_, investment: InvestmentsTableRow) => {
        return (
          <>
            {investment.company && (
              <EntityLink membership={{ company: investment.company }} testIdPrefix="asset-name" />
            )}
            {investment.realEstate && (
              <span data-testid="real-estate-name">{investment.realEstate.label}</span>
            )}
          </>
        );
      }
    },
    {
      dataField: "investor",
      text: t("nominee-dashboard:investments.investor"),
      formatter: (_, investment: InvestmentsTableRow) => {
        return (
          <>
            {investment.investor.company?.contactPerson && (
              <div>
                <EntityLink
                  membership={{ person: investment.investor.company?.contactPerson }}
                  testIdPrefix="investor-contact"
                />
              </div>
            )}
            {investment.investor.person && (
              <div>
                <EntityLink membership={investment.investor} testIdPrefix="investor" />
              </div>
            )}
          </>
        );
      }
    },
    {
      dataField: "type",
      text: t("nominee-dashboard:investments.type"),
      formatter: (_, investment: InvestmentsTableRow) => {
        return <span data-testid="investment-type">{investment.type}</span>;
      }
    },
    {
      dataField: "isTokenized",
      text: t("nominee-dashboard:investments.tokenized"),
      formatter: (_, investment: InvestmentsTableRow) => {
        return (
          <span data-testid="investment-tokenized">
            {investment.isTokenized ? t("generic:yes") : "---"}
          </span>
        );
      }
    }
  ];

  return (
    <div data-testid="view-roles-table">
      <Table data={investmentsData} columns={columns} tableStyle="greenTable" keyField="id" />
    </div>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(InvestmentsTable);
