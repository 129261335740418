import React from "react";
import { WithTranslation } from "react-i18next";
import {
  LegalForm,
  NetLiquidationValueTag,
  TaxAdvisorStatusEnum,
  TaxServiceTypeEnum
} from "global-query-types";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import Timestamp from "components/generic/Timestamp";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import RideTableCell from "sharedComponents/RideTableCell/RideTableCell";
import EntityLink from "components/common/EntityLink/EntityLink";
import ClientTags from "components/common/ClientTags/ClientTags";
import {
  BrokerageAccount,
  BrokerageAccountStatusPill,
  NetLiquidationValue,
  NLV
} from "./TaxAdvisorSection.partials";

interface PartnerTaxAdvisor {
  id: string;
  name: string;
  legalForm?: LegalForm;
}

interface WepaSubContracting {
  partner: PartnerTaxAdvisor | null;
}

export interface TaxAdvisorClient {
  id: string;
  name: string;
  legalForm: LegalForm;
  deletedAt: string | null;
  adminService: { id: string } | null;
  companyCreation: { id: string } | null;
  wepaId: number | null;
  brokerageAccounts: BrokerageAccount[] | null;
  netLiquidationValues: NetLiquidationValue[] | null;
}

export interface TaxService {
  id: string;
  type?: TaxServiceTypeEnum | null;
  client: TaxAdvisorClient | null;
  taxAdvisorStatus: TaxAdvisorStatusEnum | null;
  updatedAt: any;
  activatedAt?: any | null;
}

export interface TaxAdvisorCompany {
  id: string;
  name: string;
  legalForm: LegalForm;
  myWepaSubContracting: WepaSubContracting | null;
  taxServicesProvided: TaxService[] | null;
}

export interface TaxAdvisorSectionProps {
  taxAdvisorCompany: TaxAdvisorCompany;
}

export const TaxAdvisorSection = ({ taxAdvisorCompany }: TaxAdvisorSectionProps) => {
  return (
    <div className="tax-advisor-section" data-testid="tax-advisor-section">
      <Header taxAdvisorCompany={taxAdvisorCompany} />
      <hr />
      <ClientsTable taxAdvisorCompany={taxAdvisorCompany} />
    </div>
  );
};

const Header = withTranslationReady(["tax-service"])(
  ({ t, taxAdvisorCompany }: TaxAdvisorSectionProps & WithTranslation) => (
    <div className="tax-advisor-section__header" data-testid="tax-advisor-section-header">
      <h3 className="tax-advisor-section__title">
        <EntityLink membership={{ company: taxAdvisorCompany }} testIdPrefix="tax-advisor" />
      </h3>
      {taxAdvisorCompany.myWepaSubContracting?.partner && (
        <div data-testid="tax-advisor-company-partner">
          <span>{t("tax-service:partner-link")}</span>
          <EntityLink
            membership={{ company: taxAdvisorCompany.myWepaSubContracting.partner }}
            testIdPrefix="partner-tax-advisor"
          />
        </div>
      )}
    </div>
  )
);

const ClientsTable = withTranslationReady(["tax-service"])(
  ({ t, taxAdvisorCompany }: TaxAdvisorSectionProps & WithTranslation) => {
    const columns = [
      t("tax-service:client-company-name-extended"),
      t("tax-service:client-tax-service-status"),
      t("tax-service:updated-at"),
      t("tax-service:client-tokens"),
      t("tax-service:client-nlv-complete-quarter"),
      t("tax-service:client-nlv-current-quarter"),
      t("tax-service:booked-status")
    ].map((column) => ({
      label: column,
      name: ""
    }));

    const taxServices = (taxAdvisorCompany.taxServicesProvided ?? []).filter(
      (taxService) => !taxService.client?.deletedAt
    );

    if (taxServices.length === 0) {
      return <div className="tax-advisor-section__no-data">{t("generic:no-data-available")}</div>;
    }

    return (
      <div data-testid="tax-advisor-section-client-table">
        <RideTableHeader columns={columns} />
        {taxServices.map((taxService, index) => (
          <RideTableRow colCount={columns.length} key={`${taxService.id}-${index}`}>
            <RideTableCell
              value={
                <EntityLink
                  membership={{ company: taxService.client }}
                  testIdPrefix="tax-advisor-client"
                />
              }
            />
            <RideTableCell value={taxService.taxAdvisorStatus} />
            <RideTableCell
              value={<Timestamp date={taxService.activatedAt ?? taxService.updatedAt} />}
            />
            <RideTableCell
              value={
                taxService.client?.brokerageAccounts?.length
                  ? taxService.client.brokerageAccounts.map((account) => (
                      <BrokerageAccountStatusPill key={account.id} account={account} />
                    ))
                  : "---"
              }
            />
            <RideTableCell
              value={
                <NLV
                  nlvs={taxService.client?.netLiquidationValues}
                  tag={NetLiquidationValueTag.Last}
                />
              }
            />
            <RideTableCell
              value={
                <NLV
                  nlvs={taxService.client?.netLiquidationValues}
                  tag={NetLiquidationValueTag.Current}
                />
              }
            />
            <RideTableCell value={taxService.client && <ClientTags client={taxService.client} />} />
          </RideTableRow>
        ))}
      </div>
    );
  }
);
