import React from "react";
import { Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { BounceLoader } from "react-spinners";

interface LoadingAlertProps {
  message?: string;
  loading?: boolean;
  noLoadingAnimation?: boolean;
  t: TFunction;
}

export default class LoadingAlert extends React.Component<LoadingAlertProps> {
  static defaultProps = {
    loading: true,
    noLoadingAnimation: false,
    message: "generic:query-loading-message"
  };

  render() {
    const { t, message, loading, noLoadingAnimation } = this.props;
    return loading && message ? (
      <div data-testid="loading-alert">
        {noLoadingAnimation ? null : (
          <>
            <Row className="d-flex justify-content-center">
              <BounceLoader size={100} color={"#48FFAF"} loading />
            </Row>
            <Row className="d-flex justify-content-center">
              <p>{t(message)}</p>
            </Row>
          </>
        )}
      </div>
    ) : null;
  }
}
