import { Container } from "react-bootstrap";
import { TaskType, UserRole } from "../../../../global-query-types";
import ProcessTaskNomineeView from "./ProcessTaskNomineeView";
import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { ActionableTask } from "common/types/ProcessStatesTypes";
import ProcessTaskOptions from "admin/components/companies/ProcessStatesSection/Components/ProcessTaskOptions";
import { TFunction } from "i18next";

interface ProcessTaskListsProps extends WithTranslation {
  tasks: ActionableTask[];
  isEditMode?: boolean;
}

const ProcessTasksLists = ({ tasks, isEditMode = false, t }: ProcessTaskListsProps) => {
  const clientTasks = tasks.filter((task) => task.responsibleParty === UserRole.Client);
  const nomineeTasks = tasks.filter((task) => task.responsibleParty === UserRole.Nominee);

  return (
    <>
      <div data-testid="tasks-for-client" className="mt-2 font-weight-bold">
        {t("company-founding-steps:tasks-lists.client")}
      </div>
      <Container data-testid="processSteps-tasks-list" className="mb-2">
        {clientTasks.map((task, index) => {
          return (
            <ProcessTask key={index} task={task} isEditMode={isEditMode} index={index} t={t} />
          );
        })}
      </Container>

      <div data-testid="tasks-for-nominee" className="mt-2 font-weight-bold">
        {t("company-founding-steps:tasks-lists.nominee")}
      </div>
      <Container data-testid="processSteps-tasks-list" className="mb-2">
        {nomineeTasks.map((task, index) => {
          return (
            <ProcessTask key={index} task={task} isEditMode={isEditMode} index={index} t={t} />
          );
        })}
      </Container>

      <hr />
    </>
  );
};

interface ProcessTaskProps {
  task: ActionableTask;
  isEditMode: boolean;
  index: number;
  t: TFunction;
}

const ProcessTask = ({ task, isEditMode, index, t }: ProcessTaskProps) => {
  const hasOptions = task.type !== TaskType.GENERIC || task.ref === "appointmentNotaryRequest";
  const ref = task.ref ?? "---";

  let taskDescription: string | null = t(
    `company-founding-steps:tasks.description-nominee.${task.ref}`
  );
  if (taskDescription?.trim() === "") {
    taskDescription = null;
  }

  return (
    <ProcessTaskNomineeView task={task} key={`${ref}${index}`} isEditMode={isEditMode}>
      <div className="text-prewrap">
        {taskDescription && (
          <h6 className="mb-0 mt-3">
            {t(`company-founding-steps:tasks.description-nominee-title`)}
          </h6>
        )}
        <p>{taskDescription}</p>

        {hasOptions && <ProcessTaskOptions task={task} isEditMode={isEditMode} />}
      </div>
    </ProcessTaskNomineeView>
  );
};

export default withTranslationReady(["company-founding-steps"])(ProcessTasksLists);
