import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import EditableSectionWithFormik from "sharedComponents/EditableSectionWithFormik/EditableSectionWithFormik";
import { InvoiceField, InvoiceSelectField, YesNoOptions } from "../InvoiceFields/InvoiceFields";
import { formatCompanyAddress } from "common/formatters/formatCompanyAddress";
import { ValueFormatter } from "common/formatters/ValueFormatter";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import {
  getThirdPartyTaxAdvisorCompanies_getThirdPartyTaxAdvisorCompanies,
  getThirdPartyTaxAdvisorCompanies_getThirdPartyTaxAdvisorCompanies_businessAddress
} from "lib/api/queries/graphql/getThirdPartyTaxAdvisorCompanies";

export type ThirdPartyTaxAdvisorWithAddress = Pick<
  getThirdPartyTaxAdvisorCompanies_getThirdPartyTaxAdvisorCompanies,
  "id" | "legalForm" | "name"
> & { businessAddress: ThirdPartyTaxAdvisorAddress | null };

export type ThirdPartyTaxAdvisorAddress = Pick<
  getThirdPartyTaxAdvisorCompanies_getThirdPartyTaxAdvisorCompanies_businessAddress,
  "city" | "country" | "street" | "postalCode" | "careOf"
>;

export interface AssignThirdPartyTaxAdvisorProps extends WithTranslation {
  thirdPartyTaxAdvisorCompany?: ThirdPartyTaxAdvisorWithAddress | null;
  thirdPartyTaxAdvisorCompanies?: ThirdPartyTaxAdvisorWithAddress[] | null;
  onSubmit: ({ thirdPartyTaxAdvisorCompanyId: string }) => Promise<void>;
}

interface SelectProps {
  options: { value: string | null; label: string }[];
  name: string;
  onChange: (param) => void;
  defaultValue: string | null;
  onInputChange?: (param) => void;
}

const Select = ({ options, name, defaultValue, onChange, onInputChange }: SelectProps) => {
  const defaultOption = options.find((option) => option.value === defaultValue);
  return (
    <div data-testid={`${name}-select-container`}>
      <ReactSelect
        name={name}
        defaultValue={defaultOption}
        onChange={(value) => onChange(value?.value)}
        options={options}
        isSearchable
        onInputChange={(value) => onInputChange?.(value)}
      />
    </div>
  );
};

const AssignThirdPartyTaxAdvisor = ({
  t,
  thirdPartyTaxAdvisorCompany,
  thirdPartyTaxAdvisorCompanies,
  onSubmit
}: AssignThirdPartyTaxAdvisorProps) => {
  const valueFormatter = new ValueFormatter();
  const [options, setOptions] = useState<{ label: string; value: string | null }[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<ThirdPartyTaxAdvisorWithAddress | null>(
    thirdPartyTaxAdvisorCompany ? thirdPartyTaxAdvisorCompany : null
  );

  const formikState = useFormik<any>({
    initialValues: {
      thirdPartyTaxAdvisorStatusSelectId: thirdPartyTaxAdvisorCompany?.id ? "Yes" : "No",
      thirdPartyTaxAdvisorCompanyId: thirdPartyTaxAdvisorCompany
        ? thirdPartyTaxAdvisorCompany?.id
        : null
    },
    onSubmit: async (values) => {
      await onSubmit({ thirdPartyTaxAdvisorCompanyId: values.thirdPartyTaxAdvisorCompanyId });
    }
  });

  const selectThirdPartyTaxAdvisorCompany = (value: string | null) => {
    const company = thirdPartyTaxAdvisorCompanies?.find((c) => c.id === value);

    company && setSelectedCompany(company);
  };

  useEffect(() => {
    if (thirdPartyTaxAdvisorCompanies) {
      const tempOptions = thirdPartyTaxAdvisorCompanies.map((c) => {
        return { label: c.name, value: c.id };
      });

      const unAssignValue = {
        label: "Unassign",
        value: null
      };

      setOptions([unAssignValue, ...tempOptions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdPartyTaxAdvisorCompanies]);

  useEffect(() => {
    selectThirdPartyTaxAdvisorCompany(formikState.values["thirdPartyTaxAdvisorCompanyId"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikState.values["thirdPartyTaxAdvisorCompanyId"]]);

  useEffect(() => {
    if (thirdPartyTaxAdvisorCompany) {
      selectThirdPartyTaxAdvisorCompany(thirdPartyTaxAdvisorCompany.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdPartyTaxAdvisorCompany]);

  return (
    <EditableSectionWithFormik
      formik={formikState}
      title={t("nominee-dashboard:admin-service.thirdPartyTaxAdvisor.title")}
      testId="assign-third-party-tax-advisor">
      {({ isEditMode, formik }) => (
        <div>
          <InvoiceSelectField
            options={YesNoOptions(t)}
            isEdit={isEditMode}
            formik={formik}
            name="thirdPartyTaxAdvisorStatusSelectId"
            label={t("nominee-dashboard:admin-service.thirdPartyTaxAdvisor.status")}
          />

          {formik.values["thirdPartyTaxAdvisorStatusSelectId"] === "Yes" && (
            <div>
              <InvoiceField
                data-testid="thirdPartyTaxAdvisorCompany"
                label={t("nominee-dashboard:admin-service.thirdPartyTaxAdvisor.company")}
                value={
                  isEditMode ? (
                    <Select
                      options={options}
                      name="thirdPartyTaxAdvisorCompanyId"
                      defaultValue={selectedCompany ? selectedCompany.id : null}
                      onChange={(value) => {
                        formik.setFieldValue("thirdPartyTaxAdvisorCompanyId", value);
                      }}
                    />
                  ) : (
                    valueFormatter.format(selectedCompany?.name)
                  )
                }
              />

              <InvoiceField
                data-testid="thirdPartyTaxAdvisorAddress"
                label={t("nominee-dashboard:admin-service.thirdPartyTaxAdvisor.address")}
                value={valueFormatter.format(
                  selectedCompany
                    ? formatCompanyAddress({
                        t,
                        name: "",
                        careOf: selectedCompany?.businessAddress?.careOf,
                        postalCode: selectedCompany?.businessAddress?.postalCode,
                        city: selectedCompany?.businessAddress?.city,
                        countryCode: "",
                        street: selectedCompany?.businessAddress?.street
                      })
                    : ""
                )}
              />

              <InvoiceField
                data-testid="thirdPartyTaxAdvisorCountry"
                label={t("nominee-dashboard:admin-service.thirdPartyTaxAdvisor.country")}
                value={valueFormatter.format(
                  selectedCompany?.businessAddress?.country
                    ? getCountryNameByCode(selectedCompany?.businessAddress?.country ?? "")
                    : ""
                )}
              />
            </div>
          )}
        </div>
      )}
    </EditableSectionWithFormik>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(AssignThirdPartyTaxAdvisor);
