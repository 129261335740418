import React, { useState } from "react";
import AddExistingShareholderSection from "./AddExistingShareholderSection";
import { Search } from "../../../../lib/api/queries/Search";
import { SearchTypeEnum } from "../../../../global-query-types";
import { debounce } from "lodash";

interface AddExistingShareholderContainerProps {
  companyId: string;
  onAddExistingShareholderClick: (isVisible: boolean) => void;
  onAddExistingShareholder: (
    type: SearchTypeEnum,
    entityId: string,
    companyId: string
  ) => Promise<void>;
}

export const AddExistingShareholderContainer = ({
  companyId,
  onAddExistingShareholderClick,
  onAddExistingShareholder
}: AddExistingShareholderContainerProps) => {
  const [searchParams, _setSearchParams] = useState({ type: SearchTypeEnum.Person, q: "" });
  const setSearchParams = debounce(_setSearchParams, 500);
  let search = Search(searchParams);

  const onSubmit = async ({ type, entityId }: { type: SearchTypeEnum; entityId: string }) => {
    await onAddExistingShareholder(type, entityId, companyId);
  };

  const onSearch = ({ type, queryText }: { type: SearchTypeEnum; queryText: string }) => {
    setSearchParams({ type: type, q: queryText });
  };

  return (
    <AddExistingShareholderSection
      onAddExistingShareholderClick={onAddExistingShareholderClick}
      onSubmit={onSubmit}
      options={search.data?.search ?? []}
      onSearch={onSearch}
    />
  );
};
