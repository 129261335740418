import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { NomineeCompanyDetails_companyById_bankAccounts } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import Table from "../../../../components/generic/Table";
import RideInput from "../../../../sharedComponents/FormControls/RideInput/RideInput";
import { ClientSelect } from "../../../../client/components/ClientSelect/ClientSelect";
import { FormikProps } from "formik";
import {
  listBankAccountPlanOptions,
  listBankProviderOptions
} from "../../../../common/staticData/AccountPlans";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { BankAccountDeleteModalContainer } from "../../../../sharedComponents/BankAccountDeleteModal/BankAccountDeleteModalContainer";

interface BankAccountsEditableTableProps extends WithTranslation {
  formik: FormikProps<any>;
  bankAccounts: NomineeCompanyDetails_companyById_bankAccounts[];
  onRefresh: () => Promise<void>;
}

const BankAccountsEditableTable = ({
  bankAccounts,
  onRefresh,
  formik,
  t
}: BankAccountsEditableTableProps) => {
  const columns = [
    {
      dataField: "name",
      text: t("nominee-dashboard:company.bank-accounts-table.name"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts, index) => {
        const name = `bankAccounts.${index}.name`;
        return <RideInput name={name} formik={formik} />;
      }
    },
    {
      dataField: "bankProvider",
      text: t("nominee-dashboard:company.bank-accounts-table.bankProvider"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts, index) => {
        const name = `bankAccounts.${index}.bankProvider`;
        return (
          <ClientSelect
            name={name}
            testIdPrefix={`${name}-select`}
            options={listBankProviderOptions(t)}
          />
        );
      }
    },
    {
      dataField: "iban",
      text: t("nominee-dashboard:company.bank-accounts-table.iban"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts, index) => {
        const name = `bankAccounts.${index}.iban`;
        return <RideInput name={name} formik={formik} />;
      }
    },
    {
      dataField: "plan",
      text: t("nominee-dashboard:company.bank-accounts-table.plan"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts, index) => {
        const name = `bankAccounts.${index}.plan`;
        return (
          <ClientSelect
            name={name}
            testIdPrefix={`${name}-select`}
            options={listBankAccountPlanOptions(t)}
          />
        );
      }
    },
    {
      dataField: "delete",
      text: t("nominee-dashboard:company.bank-accounts-table.delete"),
      formatter: (_, row: NomineeCompanyDetails_companyById_bankAccounts, index) => {
        return (
          <BankAccountDeleteModalContainer id={row.id} name={row.name} onRefresh={onRefresh}>
            {(setVisibility) => {
              return (
                <RideButtonDeprecated
                  variant={ButtonVariant.Danger}
                  size={ButtonSize.Small}
                  data-testid="bank-account-delete"
                  onClick={(e) => {
                    e.preventDefault();
                    setVisibility(true);
                  }}>
                  {t("nominee-dashboard:company.bank-accounts-table.delete-button")}
                </RideButtonDeprecated>
              );
            }}
          </BankAccountDeleteModalContainer>
        );
      }
    }
  ];
  return (
    <>
      <Table
        testid="bank-accounts-editable-table"
        data={bankAccounts}
        columns={columns}
        tableStyle="greenTable"
        keyField="id"
      />
    </>
  );
};

export default withTranslationReady(["nominee-dashboard", "client"])(BankAccountsEditableTable);
