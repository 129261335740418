import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  ButtonIntensity,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { RideCheckbox, RideCheckboxVariant } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { DownloadIcon } from "sharedComponents/icons/DownloadIcon";

export interface LegalDocumentsConfirmationItemProps {
  documentKey: string;
  documentName: string;
  documentContext?: string;
  documentUrl: string;
}

const LegalDocumentsConfirmationItem = ({
  t,
  documentKey,
  documentName,
  documentContext,
  documentUrl
}: LegalDocumentsConfirmationItemProps & WithTranslation) => {
  const onButtonClick = () => {
    let alink = document.createElement("a");
    alink.href = documentUrl;
    alink.target = "_blank";
    alink.click();
    alink.remove();
  };

  return (
    <div
      data-testid="legal-documents-confirmation-item"
      className="legal-documents-confirmation-item">
      {documentContext && (
        <div
          data-testid="legal-documents-confirmation-item-context"
          className="legal-documents-confirmation-item__context">
          {documentContext}
        </div>
      )}
      <RideButtonDeprecated
        data-testid="legal-documents-confirmation-item-download-button"
        className="legal-documents-confirmation-item__download-button"
        name={`legal-documents-${documentKey}-confirmation-item-download-button`}
        intensity={ButtonIntensity.Soft}
        type="button"
        onClick={onButtonClick}>
        <div className="legal-documents-confirmation-item__download-button__content">
          <DownloadIcon />
          <div className="legal-documents-confirmation-item__download-button__content__text">
            {t("generic:download-filename", { filename: documentName })}
          </div>
        </div>
      </RideButtonDeprecated>
      <RideCheckbox
        name={`legal-documents-${documentKey}-confirmation-item`}
        variant={RideCheckboxVariant.Light}
        label={t(
          "generic:ride-broker-onboarding.documents.confirm-legal-documents.i-understand-and-agree-with",
          { documentName }
        )}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(LegalDocumentsConfirmationItem);
