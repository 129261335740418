import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { AdminServiceStatusEnum } from "lib/models/client/GmbH";
import { RideBadgeIconEnum } from "uiLibrary/components/RideBadgeStatus/RideBadgeStatus";
import { GmbHAddServiceBadge, GmbHServiceStatusBadge } from "../GmbHServiceBadge/GmbHServiceBadge";

interface GmbHAdminServiceBadgeProps extends WithTranslation {
  status: AdminServiceStatusEnum;
  canOrder: boolean;
  onClick: () => void;
}

const GmbHAdminServiceBadge = ({ t, status, canOrder, onClick }: GmbHAdminServiceBadgeProps) => {
  if (
    status === AdminServiceStatusEnum.NOT_ACQUIRED ||
    status === AdminServiceStatusEnum.CANCELED
  ) {
    return canOrder ? (
      <GmbHAddServiceBadge
        dataTestId="gmbh-add-admin-service-badge"
        label={t("generic:gmbh-badge-status.admin-service.add")}
        onClick={onClick}
      />
    ) : null;
  }

  const { iconVariant, labelKey } = adminServiceBadgeProps(status);

  return (
    <GmbHServiceStatusBadge
      dataTestId="gmbh-admin-service-badge"
      iconVariant={iconVariant}
      label={t(labelKey)}
    />
  );
};

export default withTranslationReady(["generic"])(GmbHAdminServiceBadge);

interface ServiceStatusBadgeProps {
  iconVariant: RideBadgeIconEnum;
  labelKey: string;
}

const adminServiceBadgeProps = (
  status: Exclude<
    AdminServiceStatusEnum,
    AdminServiceStatusEnum.NOT_ACQUIRED | AdminServiceStatusEnum.CANCELED
  >
): ServiceStatusBadgeProps => {
  switch (status) {
    case AdminServiceStatusEnum.ACTIVE:
      return {
        iconVariant: RideBadgeIconEnum.GREEN,
        labelKey: "generic:gmbh-badge-status.admin-service.active"
      };
    case AdminServiceStatusEnum.ORDER_PENDING:
    case AdminServiceStatusEnum.WAITING_FOR_CONFIRMATION:
      return {
        iconVariant: RideBadgeIconEnum.ORANGE,
        labelKey: "generic:gmbh-badge-status.admin-service.set-up"
      };
  }
};
