import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import ShareholderDetailsSectionTitle from "../../../sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import { FormikProps } from "formik";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import RideAddressSearch from "../../../../RideAddressSearch/RideAddressSearch";

export interface GenericAddressProps extends WithTranslation {
  prefix: string;
  formik: FormikProps<any>;
  title: string;
  displaySectionTitle?: boolean;
  useInputForCountry?: boolean;
  disableFields?: string[];
}

const GenericAddress = ({
  prefix,
  formik,
  t,
  title,
  displaySectionTitle = true,
  useInputForCountry = false,
  disableFields
}: GenericAddressProps) => {
  const isComplete = !!(
    formik.values["country"] &&
    formik.values["streetLine1"] &&
    formik.values["city"] &&
    formik.values["postalCode"]
  );

  const module = "ride-form-v2";

  return (
    <div data-testid="residential-address" className={module}>
      {displaySectionTitle && (
        <ShareholderDetailsSectionTitle prefix={prefix} text={title} isCompleted={isComplete} />
      )}
      <RideAddressSearch
        className={`${module}__field`}
        onSelect={(address) => {
          formik.setFieldValue("streetLine1", address.Line1);
          formik.setFieldValue("streetLine2", address.Line2);
          formik.setFieldValue("city", address.City);
          formik.setFieldValue("postalCode", address.PostalCode);
          formik.setFieldValue("country", address.CountryIso2);
        }}
      />
      {useInputForCountry ? (
        <RideTextInput
          className={`${module}__field`}
          name="country"
          label={t("generic:country")}
          placeholder={t("generic:country")}
          disabled={disableFields?.includes("country")}
        />
      ) : (
        <CountrySelect
          className={`${module}__field`}
          name="country"
          label={t("generic:country")}
          disabled={disableFields?.includes("country")}
        />
      )}

      <RideTextInput
        className={`${module}__field`}
        name="streetLine1"
        label={t("generic:street-line-1")}
        placeholder={t("generic:street-line-1-placeholder")}
      />

      <RideTextInput
        className={`${module}__field`}
        name="streetLine2"
        label={`${t("generic:street-line-2")} ${t("generic:optional")}`}
        placeholder={t("generic:street-line-2-placeholder")}
      />
      <RideTextInput
        className={`${module}__field`}
        name="city"
        label={t("generic:city")}
        placeholder={t("generic:city-placeholder")}
      />
      <RideTextInput
        className={`${module}__field`}
        name="postalCode"
        label={t("generic:postal-code")}
        placeholder={t("generic:postal-code-placeholder")}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(GenericAddress);
