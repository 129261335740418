import ViewSecurityQuestionsModal from "client/components/LowTaxBrokerDashboard/ViewSecurityQuestions/ViewSecurityQuestionsModal/ViewSecurityQuestionsModal";
import { LTBDashboardActionCard } from "client/components/LowTaxBrokerDashboard/sharedComponents/LTBDashboardActionCard/LTBDashboardActionCard";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { SecurityQuestions } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { withTranslationReady } from "common/i18n/withTranslationReady";

export interface ViewSecurityQuestionsProps {
  securityQuestions: SecurityQuestions;
}
const ViewSecurityQuestions = ({
  t,
  securityQuestions
}: ViewSecurityQuestionsProps & WithTranslation) => {
  const [showSecurityQuestionsModal, setShowSecurityQuestionsModal] = useState(false);

  return (
    <div data-testid={"view-security-questions"}>
      {showSecurityQuestionsModal && (
        <ViewSecurityQuestionsModal
          securityQuestions={securityQuestions}
          onClose={() => setShowSecurityQuestionsModal(false)}
        />
      )}
      <LTBDashboardActionCard
        dataTestId={"security-questions-card"}
        title={t("generic:low-tax-broker-dashboard.security-questions.title")}
        description={t("generic:low-tax-broker-dashboard.security-questions.description")}
        buttonLabel={t("generic:low-tax-broker-dashboard.security-questions.button")}
        buttonClickHandler={() => setShowSecurityQuestionsModal(true)}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(ViewSecurityQuestions);
