import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { ReviewEntry } from "../../../../../ReviewEntry/ReviewEntry";
import { LegalPersonType } from "global-query-types";
import { GetOrderQuery_getOrder } from "lib/api/queries/graphql/GetOrderQuery";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";

export interface ReviewShareholdersProps extends WithTranslation {
  order: GetOrderQuery_getOrder;
  className?: string;
}

const ReviewShareholders = ({ order, t, className = "" }: ReviewShareholdersProps) => {
  const url = `/client/order/generic/shareholder-details/0/${order.id}/${order.submissionId}`;

  let shareholderCards: React.ReactElement[] = [];

  const shareholdersObject = order.extra.shareholders;
  for (const shareholderKey of Object.keys(shareholdersObject)) {
    const shareholderInfo = shareholdersObject[shareholderKey];

    shareholderCards.push(
      <div className="shareholder-card">
        <div className="shareholder-card__type" data-testid="shareholder-legal-person-type">
          {shareholderInfo.shareholderType === LegalPersonType.Company
            ? t("generic:company-setup.steps.review-and-submit.legalEntity")
            : t("generic:company-setup.steps.review-and-submit.individual")}
        </div>
        <div className="shareholder-card__name" data-testid="shareholder-name">
          {shareholderInfo.shareholderType === LegalPersonType.Company
            ? shareholderInfo.companyData.companyName
            : shareholderInfo.personData.firstName + " " + shareholderInfo.personData.lastName}
        </div>
      </div>
    );
  }

  return (
    <CardContainer className={className}>
      <ReviewEntry name={"shareholders"} editLink={url} list={shareholderCards} />
    </CardContainer>
  );
};

export default withTranslationReady("generic")(ReviewShareholders);
