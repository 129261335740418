import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";

interface KYCStatusProps extends WithTranslation {
  isKycRequired?: boolean;
  isKycSucceeded?: boolean;
}

const KYCStatusLabel = ({ t, isKycRequired, isKycSucceeded }: KYCStatusProps) => {
  let label;
  let type = RideBadgeColorsEnum.DARK;

  if (!isKycRequired) {
    label = t("generic:not-applicable");
  }

  if (isKycRequired && !isKycSucceeded) {
    label = t("generic:no");
    type = RideBadgeColorsEnum.DANGER;
  }

  if (isKycRequired && isKycSucceeded) {
    label = t("generic:yes");
    type = RideBadgeColorsEnum.SUCCESS;
  }

  return (
    <RideBadgeStatus
      dataTestId="kyc-status-label"
      color={type}
      variant={RideBadgeVariantsEnum.OUTLINED}
      label={label}
    />
  );
};

export default withTranslation(["generic"])(KYCStatusLabel);
