import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { PendingTask } from "../../../../lib/types/PendingTask";
import { OrderData } from "../../ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerData } from "../LowTaxBrokerOverview";
import { UpsertOrderVariables } from "../../../../lib/api/mutations/graphql/UpsertOrder";
import PendingTaskSwitcher, {
  fileUploadConstraints,
  IBKRFileSubmission
} from "./PendingTaskSwitcher/PendingTaskSwitcher";
import { CopyableInformation } from "../../CopyableInformation/CopyableInformation";
import { Typography } from "../../../../uiLibrary/designSystem/styles/Typography/Typography";
import { CardContainer } from "../../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";

export interface ApplicationReviewCardWithPendingTasksProps {
  status: OnboardingStepCardStatus;
  cardIndex: number;
  pendingTasks: PendingTask[];
  handleCreateSSOAndRedirect?: () => Promise<void>;
  order: OrderData<LowTaxBrokerData> | null | undefined;
  updateOrder: (order: UpsertOrderVariables) => Promise<void>;
  handleAgreeToIBKRForm?: (formNumber: string) => Promise<void>;
  handleSubmitFile?: (fileSubmission: IBKRFileSubmission) => Promise<void>;
}

const ApplicationReviewWithPendingTasksCard = ({
  t,
  status,
  cardIndex,
  pendingTasks,
  order,
  updateOrder,
  handleCreateSSOAndRedirect,
  handleAgreeToIBKRForm,
  handleSubmitFile
}: ApplicationReviewCardWithPendingTasksProps & WithTranslation) => {
  const completeTask = async (taskNumber: string) => {
    await updateOrder({
      id: order?.id,
      extra: { completedIBKRTasks: { ...order?.extra?.completedIBKRTasks, [taskNumber]: true } }
    });
  };

  return (
    <OnboardingStepCard
      data-testid="ltb-application-review-card-with-pending-tasks"
      number={cardIndex}
      title={t("generic:low-tax-broker-overview.application-review.title")}
      status={status}>
      <CardContainer className="v2-card-container--alternative">
        <div className="p-4 your-access-information" data-testid="access-information">
          <Typography
            data-testid="your-access-information-title"
            category={"Paragraph"}
            size={200}
            weight={"Heavy"}>
            {t("generic:low-tax-broker-overview.application-review.your-access-information-title")}
          </Typography>
          <div className={"your-access-information__copyable-data"}>
            <CopyableInformation
              data-testid="username-copyable-information"
              label={t("generic:low-tax-broker-overview.application-review.username-label")}
              value={order?.extra.ibCredentials?.username ?? ""}
              showValue={true}
            />
            <CopyableInformation
              data-testid="account-number-copyable-information"
              label={t("generic:low-tax-broker-overview.application-review.account-number-label")}
              value={order?.extra.ibCredentials?.accountNumber ?? ""}
              showValue={true}
            />
          </div>
        </div>
      </CardContainer>
      {pendingTasks.length === 0 && (
        <RideAlertBar
          type={AlertType.INFO}
          title={t("generic:low-tax-broker-overview.application-review.content-title")}
          message={
            <Trans
              i18nKey={"generic:low-tax-broker-overview.application-review.content-description"}
              components={{ bold: <strong /> }}
            />
          }
        />
      )}
      {pendingTasks.map((task) => (
        <PendingTaskSwitcher
          key={task.taskNumber}
          task={task}
          isTaskCompleted={order?.extra?.completedIBKRTasks?.[task.taskNumber ?? ""] ?? false}
          completeTask={() => completeTask(task.taskNumber ?? "")}
          handleCreateSSOAndRedirect={handleCreateSSOAndRedirect}
          handleAgreeToIBKRForm={handleAgreeToIBKRForm}
          handleSubmitFile={handleSubmitFile}
          fileUploadConstraints={fileUploadConstraints}
        />
      ))}
    </OnboardingStepCard>
  );
};

export default withTranslationReady(["generic"])(ApplicationReviewWithPendingTasksCard);
