import React from "react";

export const DiscordIcon = ({ className = "" }: { className?: string }) => (
  <svg
    data-testid="discord-icon"
    className={className}
    viewBox="0 0 53 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.7269 3.42307C41.309 1.82394 37.6545 0.661757 33.8338 0C33.3645 0.848349 32.8163 1.9894 32.4384 2.89709C28.3769 2.28629 24.3527 2.28629 20.3659 2.89709C19.988 1.9894 19.4273 0.848349 18.9539 0C15.1291 0.661757 11.4703 1.82821 8.05247 3.43154C1.15862 13.849 -0.710189 24.0078 0.224218 34.0223C4.79658 37.4368 9.22775 39.5111 13.5841 40.8684C14.6598 39.388 15.6191 37.8144 16.4455 36.1559C14.8715 35.5578 13.364 34.8198 11.9396 33.963C12.3175 33.683 12.6872 33.3903 13.0443 33.0891C21.7322 37.1527 31.1718 37.1527 39.7559 33.0891C40.1172 33.3903 40.4867 33.683 40.8605 33.963C39.4319 34.824 37.9203 35.562 36.3463 36.1602C37.1727 37.8144 38.1279 39.3923 39.2077 40.8726C43.5682 39.5153 48.0035 37.4411 52.5759 34.0223C53.6723 22.4129 50.703 12.3475 44.7269 3.42307ZM17.6291 27.8635C15.0211 27.8635 12.8823 25.4287 12.8823 22.4638C12.8823 19.4989 14.9754 17.06 17.6291 17.06C20.2828 17.06 22.4216 19.4946 22.3759 22.4638C22.38 25.4287 20.2828 27.8635 17.6291 27.8635ZM35.171 27.8635C32.563 27.8635 30.4242 25.4287 30.4242 22.4638C30.4242 19.4989 32.5173 17.06 35.171 17.06C37.8247 17.06 39.9635 19.4946 39.9178 22.4638C39.9178 25.4287 37.8247 27.8635 35.171 27.8635Z"
      fill="#303436"
    />
  </svg>
);
