import gql from "graphql-tag";

export const REAL_ESTATE_OWNERS_QUERY = gql`
  query RealEstateOwnersQuery {
    availableRealEstateOwners {
      companyId
      title
    }
  }
`;
