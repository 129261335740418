import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Container } from "react-bootstrap";
import { ThirdPartyTaxAdvisorInviteViaEmailContainer } from "../../components/ThirdPartyTaxAdvisorInviteViaEmail/ThirdPartyTaxAdvisorInviteViaEmailContainer";

interface InviteClientsSceneProps extends WithTranslation {}

const InviteClientsEmailScene = ({ t }: InviteClientsSceneProps) => {
  return (
    <>
      <h1 className="invite-page--title mb-25 mb-md-4" data-testid="invite-page-title">
        {t("invite-clients:email.title")}
      </h1>
      <div className="invite-page--content" data-testid="invite-page-content">
        <Container className="invite-client m-0 p-0">
          <ThirdPartyTaxAdvisorInviteViaEmailContainer t={t} />
        </Container>
      </div>
    </>
  );
};

export default withTranslationReady(["invite-clients"])(InviteClientsEmailScene);
