import {
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { useHistory } from "react-router-dom";

interface DoneButtonProps {
  isDisabled?: boolean;
  onClick?: () => void;
  type?: "submit" | "button" | "reset" | undefined;
}

const DoneButton = ({
  t,
  isDisabled,
  onClick,
  type = undefined
}: DoneButtonProps & WithTranslation) => {
  const history = useHistory();

  return (
    <RideButtonDeprecated
      data-testid="done-button"
      className="document-upload__done-button-area__done-button"
      variant={ButtonVariant.Primary}
      type={type}
      onClick={onClick ?? (() => history.goBack())}
      disabled={isDisabled}>
      {t("generic:done")}
    </RideButtonDeprecated>
  );
};

export const SubchapterButtonArea = ({ children }: PropsWithChildren<{}>) => (
  <div className="document-upload__done-button-area">{children}</div>
);

export default withTranslationReady(["generic"])(DoneButton);
