import { FileCard } from "../FileCard/FileCard";
import { ErrorFileCard } from "../ErrorFileCard/ErrorFileCard";
import React, { useState } from "react";
import { FileError } from "../UploadFile/UploadFile";
import { FileUploadSection, UploadedFileSection } from "./FileUploadArea.partials";
import { TFunction, WithTranslation } from "react-i18next";
import { FileInfo } from "lib/models/client/LowTaxBroker/FileInfo";
import {
  FileTooLargeError,
  InvalidFileTypeError
} from "../../../../../../lib/adapters/RideFileStorage/FileStorage";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import bytes from "bytes";

interface FileUploadAreaProps {
  t: TFunction;
  uploadFile: (file: File) => Promise<void>;
  existingFile?: FileInfo | undefined;
  deleteFile?: () => Promise<void>;
  placeholderImageType?: PlaceholderImage | null;
  className?: string | null;
  browserFileInsideDraggableArea?: boolean;
}

export interface FileDisplayInfo {
  name: string;
  size: number;
}

export enum PlaceholderImage {
  PASSPORT = "PASSPORT",
  ID_FRONT = "ID_FRONT",
  ID_BACK = "ID_BACK"
}

export const FileUploadArea = withTranslationReady(["generic"])(
  ({
    t,
    uploadFile,
    existingFile,
    deleteFile,
    placeholderImageType = null,
    className = null,
    browserFileInsideDraggableArea = false
  }: FileUploadAreaProps & WithTranslation) => {
    const [fileInfo, setFileInfo] = useState<FileDisplayInfo | undefined>(
      toFileDisplayInfo(existingFile)
    );
    const [fileUploadError, setFileUploadError] = useState<FileError | undefined>();

    async function onFileSelected(file: File) {
      let error: Error | undefined;

      try {
        await uploadFile(file);
      } catch (e) {
        error = e;
      }

      if (error) {
        setFileInfo(undefined);

        if (error instanceof FileTooLargeError) {
          setFileUploadError({
            description: t(
              "generic:ride-broker-onboarding.documents.upload-file.validation-errors.file-too-large",
              { maxFileSize: bytes(error.maxSizeInBytes) }
            ),
            fileName: file.name
          });
        } else if (error instanceof InvalidFileTypeError) {
          setFileUploadError({
            description: t(
              "generic:ride-broker-onboarding.documents.upload-file.validation-errors.invalid-file-type"
            ),
            fileName: file.name
          });
        } else {
          setFileUploadError({
            description: t(
              "generic:ride-broker-onboarding.documents.upload-file.validation-errors.default-error"
            ),
            fileName: file.name
          });
          console.error(error);
        }
      } else {
        setFileUploadError(undefined);
        setFileInfo({ name: file.name, size: file.size });
      }
    }

    async function onDelete() {
      setFileInfo(undefined);
      await deleteFile?.();
    }
    return (
      <div className={`${className ?? ""}`} data-testid={"file-upload-area"}>
        <FileUploadSection
          selectFile={onFileSelected}
          placeholderImageType={placeholderImageType}
          browserFileInsideDraggableArea={browserFileInsideDraggableArea}
        />
        {fileInfo && (
          <UploadedFileSection>
            <FileCard file={fileInfo} onDelete={onDelete} />
          </UploadedFileSection>
        )}
        {fileUploadError && (
          <UploadedFileSection>
            <ErrorFileCard
              description={fileUploadError.description}
              fileName={fileUploadError.fileName}
              onErrorClose={() => setFileUploadError(undefined)}
            />
          </UploadedFileSection>
        )}
      </div>
    );
  }
);

const toFileDisplayInfo = (file: FileInfo | undefined): FileDisplayInfo | undefined => {
  if (file?.name && file?.size) {
    return { name: file.name, size: file.size };
  }
  return undefined;
};
