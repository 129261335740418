import {
  OrderData,
  WithChapters
} from "../../../client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "../../models/client/LowTaxBroker/LowTaxBrokerOrder";
import { AssetTypes } from "../../types/types";

export const MINIMUM_LIQUID_NET_WORTH_IBKR_REQUIRED: number = 20000;

/**
 * FX Flat requirement related to trading permissions for AssetTypes.OPTIONS, AssetTypes.FUTURES, AssetTypes.CFD
 * @param order
 */
export const meetsMinimumLiquidNetWorthRequirement = (
  order: OrderData<LowTaxBrokerOrderData & WithChapters>
) => {
  const currentLiquidNetWorth = Number.parseInt(order.extra?.currentLiquidNetWorth ?? "not-valid");

  return currentLiquidNetWorth >= MINIMUM_LIQUID_NET_WORTH_IBKR_REQUIRED;
};

export const canBeSelectedAsAreaOfInvestment = (
  assetType: AssetTypes,
  order: OrderData<LowTaxBrokerOrderData & WithChapters>
): boolean => {
  if (!order.extra.isNewBrokerEnabled) {
    return true;
  }

  function shouldDisableOptions() {
    return (
      order.extra.investmentObjectives?.["preservation-of-capital"] &&
      order.extra.investmentObjectives?.["income"] &&
      order.extra.investmentObjectives?.["growth"]
    );
  }

  function shouldOnlyEnableBonds(): boolean {
    let result =
      !!order.extra.investmentObjectives?.["preservation-of-capital"] &&
      !order.extra.investmentObjectives?.["income"] &&
      !order.extra.investmentObjectives?.["growth"] &&
      !order.extra.investmentObjectives?.["hedging"] &&
      !order.extra.investmentObjectives?.["trading-profits"] &&
      !order.extra.investmentObjectives?.["speculation"];
    return result;
  }

  switch (assetType) {
    case AssetTypes.OPTIONS:
      return !shouldDisableOptions() && !shouldOnlyEnableBonds();
    case AssetTypes.FOREX:
      return (
        !(shouldDisableOptions() && !order.extra.investmentObjectives?.["hedging"]) &&
        !shouldOnlyEnableBonds()
      );
    case AssetTypes.MARGIN:
      return !shouldOnlyEnableBonds();
    case AssetTypes.STOCKS:
      return !shouldOnlyEnableBonds();
    case AssetTypes.FUTURES:
      return !shouldOnlyEnableBonds();
    default:
      return true;
  }
};
