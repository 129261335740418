import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import {
  AdvisorNumberField,
  EconomicIdField,
  LEIField,
  TaxNumberField,
  VatNumberField
} from "../../../../../generic/company-form/fields";

export interface IProps {
  t: TFunction;
  formik;
  className?: string;
}

export const CompanyRegistrationNumbers = ({ t, formik, className }: IProps) => {
  return (
    <Row className={className}>
      <Col xs={6} md={4}>
        <TaxNumberField t={t} formik={formik} />
      </Col>
      <Col xs={6} md={4}>
        <VatNumberField t={t} formik={formik} />
      </Col>
      <Col xs={6} md={4}>
        <EconomicIdField t={t} formik={formik} />
      </Col>
      <Col xs={6} md={4}>
        <LEIField t={t} formik={formik} />
      </Col>
      <Col xs={6} md={4}>
        <AdvisorNumberField t={t} formik={formik} />
      </Col>
    </Row>
  );
};
