import gql from "graphql-tag";

export const CREATE_NOTARY = gql`
  mutation CreateNotary(
    $email: String!
    $firstName: String!
    $lastName: String!
    $links: [NotaryLinkItem!]!
  ) {
    createNotary(email: $email, firstName: $firstName, lastName: $lastName, links: $links) {
      feedback
    }
  }
`;

export const UPDATE_NOTARY_TYPES = gql`
  mutation updateNotaryTypes(
    $singleShareholder: String
    $multipleShareholders: String
    $companyHolding: String
  ) {
    updateNotaryTypes(
      singleShareholder: $singleShareholder
      multipleShareholders: $multipleShareholders
      companyHolding: $companyHolding
    ) {
      feedback
    }
  }
`;
