import React from "react";
import { useParams } from "react-router";
import { ShelfCompanyOrderDetailsLayoutContainer } from "../../components/ShelfCompanyOrderDetailsLayout/ShelfCompanyOrderDetailsLayoutContainer";
import { useApolloClient } from "@apollo/react-hooks";

const ShelfCompanyOrderDetailsScene = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const client = useApolloClient();

  const refetchQueries = () => {
    client.reFetchObservableQueries();
  };

  return (
    <ShelfCompanyOrderDetailsLayoutContainer
      orderId={orderId}
      onRefresh={async () => refetchQueries()}
    />
  );
};

export default ShelfCompanyOrderDetailsScene;
