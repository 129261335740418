import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import React from "react";
import { WithTranslation } from "react-i18next";
import { TaskStatesEnum } from "../../../../../global-query-types";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import OpenBankAccountTaskContainer from "../OpenBankAccountTaskContainer/OpenBankAccountTaskContainer";

interface OpenBankAccountPentaTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const OpenBankAccountPentaTask = ({ task, onChange, t }: OpenBankAccountPentaTaskProps) => {
  return (
    <OpenBankAccountTaskContainer onChange={onChange} task={task} bankName="Penta">
      <>
        <div>{t("company-founding-steps:tasks.openBankAccountPenta.firstStep")}</div>
        <RideButtonDeprecated
          openExternalUrl={task.state === TaskStatesEnum.TO_BE_DONE}
          href={t("company-founding-steps:tasks.openBankAccountPenta.openAccountLink")}
          data-testid="open-bank-account-penta-button"
          className={`open-bank-account-penta__button ${
            task.state === TaskStatesEnum.COMPLETED ? "button--secondary--disabled" : ""
          }`}>
          {t("company-founding-steps:tasks.openBankAccountPenta.openAccount")}
        </RideButtonDeprecated>
      </>
      {t("company-founding-steps:tasks.openBankAccountPenta.secondStep")}
      {t("company-founding-steps:tasks.openBankAccountPenta.thirdStep")}
    </OpenBankAccountTaskContainer>
  );
};

export default withTranslationReady(["company-founding-steps"])(OpenBankAccountPentaTask);
