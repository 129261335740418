import { useEffect, useState } from "react";
import { FeatureFlagService } from "../services/FeatureFlagService/FeatureFlagService";
import logger from "../../common/Logger";
import { FeatureFlags } from "global-query-types";

const featureToggledBrokers = [
  {
    name: "JFD",
    flag: "JFDBroker"
  },
  {
    name: "GBE",
    flag: "GBEBroker"
  },
  {
    name: "ActivTrades",
    flag: "ActivTradesBroker"
  }
];

export const useDisabledBrokers = () => {
  const [disabledBrokers, setDisabledBrokers] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    Promise.all(
      featureToggledBrokers.map((toggleBroker) =>
        FeatureFlagService.instance.isEnabled(toggleBroker.flag as FeatureFlags)
      )
    )
      .then((enabled) => {
        const mapEntries = featureToggledBrokers.map((toggleBroker, index) => [
          toggleBroker.name,
          !enabled[index]
        ]);
        const newDisabledBrokers = Object.fromEntries(mapEntries);
        setDisabledBrokers(newDisabledBrokers);
      })
      .catch((err) => logger.error(err));
  }, []);

  return disabledBrokers;
};
