import React from "react";
import { RideButtonSize } from "uiLibrary/designSystem/RideButton/RideButton";

interface RideIconButtonProps {
  icon: React.ReactElement;
  "data-testid"?: string;
  onClick: () => void;
  disabled?: boolean;
  size?: RideButtonSize;
}

export const RideIconButton = ({
  icon,
  "data-testid": dataTestId = "ride-icon-button",
  onClick,
  disabled = false,
  size = RideButtonSize.BIG
}: RideIconButtonProps) => {
  const module = "ride-icon-button";
  return (
    <button
      data-testid={dataTestId}
      className={`${module} ${module}--${size}`}
      onClick={onClick}
      disabled={disabled}>
      <div className={`${module}__icon`}>
        {{ ...icon, props: { ...icon?.props, size: size === RideButtonSize.SMALL ? "16" : "24" } }}
      </div>
    </button>
  );
};
