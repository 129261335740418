import React, { FC } from "react";
import ShortOrderFormLayout from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/ShortOrderFormLayout";
import { useHistory } from "react-router-dom";

interface ShortOrderFormProps {
  route: string;
  steps: ShortOrderFormStep[];
  currentStepIndex: number;
}

export interface ShortOrderFormStep {
  title: string;
  component: FC<ShortOrderFormStepProps>;
}

export interface ShortOrderFormStepProps {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}

export const ShortOrderFormEngine = ({ route, steps, currentStepIndex }: ShortOrderFormProps) => {
  const history = useHistory();

  const maxIndex: number = steps.length - 1;
  const currentStep: number = clamp(0, maxIndex, currentStepIndex);

  const goToNextStep = () => {
    goToStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    goToStep(currentStep - 1);
  };

  const goToStep = (stepIndex: number) => {
    const index = clamp(0, maxIndex, stepIndex);
    history.push(`/order/${route}/${index}`);
  };

  const StepComponent = steps[currentStep].component;

  return (
    <ShortOrderFormLayout steps={steps.map((s) => s.title)} currentStepIndex={currentStep}>
      <StepComponent goToNextStep={goToNextStep} goToPreviousStep={goToPreviousStep} />
    </ShortOrderFormLayout>
  );
};

const clamp = (min: number, max: number, value: number) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};
