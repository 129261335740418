import LocalLanguageCodeProvider from "./LocalLanguageCodeProvider";
import { i18n } from "i18next";
import { AssertionError } from "assert";

export default class Language {
  private _i18n: i18n;
  private _localLanguageCodeProvider: LocalLanguageCodeProvider;

  constructor(
    i18n: i18n,
    languageCodeProvider: LocalLanguageCodeProvider = new LocalLanguageCodeProvider()
  ) {
    this._i18n = i18n;
    this._localLanguageCodeProvider = languageCodeProvider;
  }

  updateLanguage(languageCode: string) {
    if (languageCode !== "en" && languageCode !== "de") {
      throw new AssertionError({ message: "Only German (de) and English (en) supported" });
    }
    this._localLanguageCodeProvider.setLanguageCode(languageCode);
    this._i18n.changeLanguage(languageCode);
    document.documentElement.lang = languageCode;
  }
}
