import RideIcon, { RideIconModifier } from "uiLibrary/components/RideIcon/RideIcon";
import { RideSize } from "lib/types/types";
import { formatBytes } from "lib/formatters/numberFormatter";
import React from "react";

export interface FileDisplayInfo {
  name: string;
  size: number;
}

interface FileCardProps {
  file: FileDisplayInfo;
  onDelete?: () => void;
}

export const FileCard = ({ file, onDelete }: FileCardProps) => {
  const moduleName = "file-card";

  return (
    <div data-testid="file-card" className={moduleName}>
      <div className={`${moduleName}__file-icon`}>
        <RideIcon name="document-lines" />
      </div>
      <div className={`${moduleName}__file-info`}>
        <span className={`${moduleName}__name`}>{file.name}</span>
        <span className={`${moduleName}__size`}>{formatBytes(file.size)}</span>
      </div>
      {onDelete && (
        <RideIcon
          dataTestId="delete-button"
          onClick={onDelete}
          name="delete-max"
          size={RideSize.SMALL}
          modifier={RideIconModifier.CLICKABLE_CONTROL}
        />
      )}
    </div>
  );
};
