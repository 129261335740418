import React from "react";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { CompanyById_companyById_orders } from "../../../lib/api/queries/graphql/CompanyById";
import { AlertType, RideAlertBar } from "../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { OrderProductTypeEnum } from "../../../global-query-types";
import {
  ClickToCopy,
  ClickToCopyColorVariant
} from "../../../sharedComponents/ClickToCopy/ClickToCopy";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export interface LeiApplicationProps extends WithTranslation {
  companyId: string;
  lei: string | null | undefined;
  orders: CompanyById_companyById_orders[];
}

const LeiApplication = ({ t, orders, companyId, lei }: LeiApplicationProps) => {
  const { push } = useHistory();

  const leiOrder = orders.find((o) => o.productType === OrderProductTypeEnum.LEI);
  if (!leiOrder) return null;

  const isFulfilled = !!leiOrder.extra?.fulfilledAt;
  const alertType = isFulfilled ? AlertType.SUCCESS : AlertType.INFO;
  const state = isFulfilled ? "fulfilled" : "pending";

  const focusContent = isFulfilled ? (
    <div className="lei-application__value">
      <ClickToCopy
        value={lei ?? ""}
        label={lei ?? ""}
        clickToCopyColorVariant={ClickToCopyColorVariant.black}
        tooltipContent={t(`generic:copy-to-clipboard.copied`)}
      />
    </div>
  ) : (
    <ol>
      <li>{t(`generic:lei-application.${state}.steps.1`)}</li>
      <li>{t(`generic:lei-application.${state}.steps.2`)}</li>
    </ol>
  );

  const cta = isFulfilled ? (
    <RideButtonDeprecated
      type="button"
      variant={ButtonVariant.PrimaryPastel}
      data-testid="lei-application-cta"
      className="lei-application__cta"
      onClick={() => push(t(`generic:lei-application.${state}.ctaUrl`, { companyId }))}>
      {t(`generic:lei-application.${state}.cta`)}
    </RideButtonDeprecated>
  ) : (
    <RideButtonDeprecated
      type="button"
      variant={ButtonVariant.PrimaryPastel}
      openExternalUrl={true}
      href={t(`generic:lei-application.${state}.ctaUrl`, { companyId })}
      data-testid="lei-application-cta"
      className="lei-application__cta">
      {t(`generic:lei-application.${state}.cta`)}
    </RideButtonDeprecated>
  );

  return (
    <Container className="lei-application" data-testid="lei-application">
      <Row>
        <Col>
          <RideAlertBar
            type={alertType}
            title={t(`generic:lei-application.${state}.title`)}
            message={
              <>
                <div className="lei-application__content">
                  <p>{t(`generic:lei-application.${state}.preface`)}</p>
                  {focusContent}
                  <p>{t(rideBrokerT(`generic:lei-application.${state}.afterword`))}</p>
                </div>
                {cta}
              </>
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslationReady(["generic"])(LeiApplication);
