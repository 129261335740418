import React, { InputHTMLAttributes, ReactNode, useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { RideTooltip } from "../../../sharedComponents/RideTooltip/RideTooltip";
import { uniqueId } from "lodash";
import { InformationIcon } from "../../../sharedComponents/icons/DesignSystemIcons/InformationIcon";

export interface ProductCardProps {
  dataTestId?: string;
  icon: ReactNode;
  title: string;
  description: string;
  price: string;
  handleLearnMore?: () => void;
  input?: ReactNode;
  disabled?: boolean;
  tooltipText?: string;
}

const ProductCard = ({
  t,
  dataTestId = "product-card",
  icon,
  title,
  description,
  price,
  handleLearnMore,
  input,
  disabled,
  tooltipText
}: WithTranslation & ProductCardProps) => {
  const [tooltipId] = useState(uniqueId());
  const moduleName = "product-card";

  return (
    <label
      className={`${moduleName} ${disabled ? `${moduleName}--disabled` : ""} ${
        disabled || !input ? `${moduleName}--not-clickable` : ""
      }`}
      data-testid={dataTestId}
      onClick={(e) => {
        if (!input) {
          e.preventDefault();
        }
      }}>
      <div className={`${moduleName}__main-area`}>
        <div className={`${moduleName}__header`}>
          <div className={`${moduleName}__icon-title-wrapper`}>
            <div className={`${moduleName}__icon-and-input-area`}>
              <div
                data-testid={`${dataTestId}-icon-slot`}
                className={`${moduleName}__icon ${moduleName}__can-be-disabled`}>
                {icon}
              </div>
              <div className={`${moduleName}__input-and-tooltip-area`}>
                {tooltipText && (
                  <RideTooltip name={tooltipId} content={tooltipText ?? ""}>
                    <span className={`${moduleName}__tooltip-icon-wrapper`}>
                      <InformationIcon size={20} />
                    </span>
                  </RideTooltip>
                )}
                <div
                  data-testid={`${dataTestId}-input-slot`}
                  className={`${moduleName}__input-container ${moduleName}__can-be-disabled`}>
                  {input}
                </div>
              </div>
            </div>
            <Typography
              className={`${moduleName}__title ${moduleName}__can-be-disabled`}
              category="Headline"
              data-testid={`${dataTestId}-title`}
              size={300}
              weight="Heavy">
              {title}
            </Typography>
          </div>
        </div>
        <Typography
          className={`${moduleName}__description ${moduleName}__can-be-disabled`}
          category="Paragraph"
          size={200}
          weight="Light"
          data-testid={`${dataTestId}-description`}>
          {description}
        </Typography>
      </div>
      <div className={`${moduleName}__footer ${moduleName}__can-be-disabled`}>
        <Typography
          category="Paragraph"
          size={100}
          weight="Heavy"
          data-testid={`${dataTestId}-price`}
          className={`${moduleName}__price`}>
          {price}
        </Typography>
        {handleLearnMore && (
          <RideButton
            data-testid={`${dataTestId}-learn-more-link`}
            variant={RideButtonVariant.GHOST}
            size={RideButtonSize.SMALL}
            label={t("generic:product-card.learn-more")}
            className={`${moduleName}__learn-more-button`}
            onClick={(e) => {
              e.preventDefault();
              handleLearnMore();
            }}
          />
        )}
      </div>
    </label>
  );
};

type ProductCardCheckboxInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type">;

export const ProductCardCheckboxInput = ({
  className,
  ...props
}: ProductCardCheckboxInputProps) => {
  return <input type="checkbox" className={`product-card__checkbox ${className}`} {...props} />;
};

export const ProductCardRadioInput = ({ className, ...props }: ProductCardCheckboxInputProps) => {
  return <input type="radio" className={`product-card__radio ${className}`} {...props} />;
};

export default withTranslationReady(["generic"])(ProductCard);
