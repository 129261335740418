import { TFunction } from "i18next";
import React from "react";

export const EditKYCStatusPersonInfo = ({ t }: { t: TFunction }) => (
  <>
    <p data-testid="edit-kyc-status-information">{t("nominee-dashboard:kyc-status.pep-check")}</p>
    <p>{t("nominee-dashboard:kyc-status.completed-condition-start")}</p>
    <ol>
      {[1, 2, 3].map((i) => (
        <li key={i}>{t(`nominee-dashboard:kyc-status.completed-condition-${i}`)}</li>
      ))}
    </ol>
    <div>{t("nominee-dashboard:kyc-status.completed-condition-end")}</div>
  </>
);
