import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  getBankAccountDetailsById,
  getBankAccountDetailsByIdVariables
} from "./graphql/getBankAccountDetailsById";

export const GET_BANK_ACCOUNT_DETAILS_BY_ID = gql`
  query getBankAccountDetailsById($companyId: String!, $iban: String!, $bankAccountId: String!) {
    getBankAccountDetailsById(companyId: $companyId, iban: $iban, bankAccountId: $bankAccountId) {
      balance
      transactions {
        id
        amount
        timestamp
        currency
        counterpart
      }
    }
  }
`;

export const GetBankAccountDetailsById = (companyId: string, iban: string, bankAccountId: string) =>
  useQuery<getBankAccountDetailsById, getBankAccountDetailsByIdVariables>(
    GET_BANK_ACCOUNT_DETAILS_BY_ID,
    {
      variables: { companyId, iban, bankAccountId },
      fetchPolicy: "no-cache"
    }
  );
