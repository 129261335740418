import { Col, Row } from "react-bootstrap";
import React from "react";
import RealEstateTable from "../../../components/Investments/RealEstateTable";
import { UserRole } from "../../../global-query-types";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

const InvestmentClubAssets = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("nominee-dashboard:investment-club-assets.heading")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <RealEstateTable role={UserRole.Nominee} />
        </Col>
      </Row>
    </>
  );
};

export default withTranslationReady(["nominee-dashboard"])(InvestmentClubAssets);
