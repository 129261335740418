import React, { useState } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { ClickToCopy } from "sharedComponents/ClickToCopy/ClickToCopy";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { stripHtmlFormatter } from "lib/formatters/stripHtmlFormatter";
import { RideTooltip } from "sharedComponents/RideTooltip/RideTooltip";
import { uniqueId } from "lodash";

interface PtaMigrationFlowToRideSteuerberatungSuccessProps extends WithTranslation {
  onConfirm: () => void;
}

const PtaMigrationFlowToRideSteuerberatungSuccess = ({
  t,
  onConfirm
}: PtaMigrationFlowToRideSteuerberatungSuccessProps) => {
  const [hasCopied, setHasCopied] = useState(false);
  const emailTemplateKey = `generic:steuragenter-customer-migration.termination-email-template.body`;

  return (
    <div className="d-flex justify-content-center">
      <article className="d-flex flex-column px-5 w-100 w-lg-50">
        <div className={"pta-migration-flow-to-ride-steuerberatung-success__title mt-3"}>
          <div className={"pta-migration-flow-to-ride-steuerberatung-success__title__icon"} />
          <Typography category={"Headline"} size={200} weight={"Heavy"}>
            {t(`generic:steuragenter-customer-migration.success.title`)}
          </Typography>
        </div>
        <div className="my-3">
          <Typography category={"Paragraph"} size={100} weight={"Light"}>
            <Trans i18nKey={t(`generic:steuragenter-customer-migration.success.description`)} />
          </Typography>
        </div>
        <div className="d-flex flex-column align-items-end my-3">
          <CardContainer className="w-100 p-4">
            <Typography category={"Paragraph"} size={200} weight={"Light"}>
              <Trans i18nKey={emailTemplateKey} components={{ mark: <mark /> }} />
            </Typography>
          </CardContainer>
          <div className="mt-3">
            <ClickToCopy
              label={t(
                `generic:steuragenter-customer-migration.termination-email-template.click-to-copy`
              )}
              tooltipContent={t(
                `generic:steuragenter-customer-migration.termination-email-template.click-to-copy-success`
              )}
              value={stripHtmlFormatter(t(emailTemplateKey))}
              onClickExtra={() => setHasCopied(true)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-5 mb-4">
          <RideTooltip
            name={uniqueId()}
            content={
              hasCopied
                ? ""
                : t("generic:steuragenter-customer-migration.success.confirm-btn-tooltip")
            }>
            <RideButton
              data-testid={"confirm-btn"}
              disabled={!hasCopied}
              label={t(`generic:steuragenter-customer-migration.success.confirm-btn`)}
              className="flex-grow-0"
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
              onClick={onConfirm}
            />
          </RideTooltip>
        </div>
      </article>
    </div>
  );
};

export default withTranslationReady(["generic"])(PtaMigrationFlowToRideSteuerberatungSuccess);
