import { useQuery } from "react-apollo";
import {
  RealEstateQueryByCompanyId,
  RealEstateQueryByCompanyIdVariables
} from "./graphql/RealEstateQueryByCompanyId";
import { REAL_ESTATE_QUERY_BY_COMPANY_ID } from "./Queries";

export const GetRealEstateInfoByCompanyId = (companyId: string) => {
  const realEstateDetails = useQuery<
    RealEstateQueryByCompanyId,
    RealEstateQueryByCompanyIdVariables
  >(REAL_ESTATE_QUERY_BY_COMPANY_ID, {
    variables: { companyId: companyId }
  });
  return realEstateDetails;
};
