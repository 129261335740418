import { Dropdown } from "react-bootstrap";
import { getEndpoint } from "../../../common/GraphqlClient/httpLink";
import React from "react";
import { TFunction } from "i18next";
import { DropdownMenu } from "../../../components/nominee/common/DropdownMenu";
import { getToken } from "../../../common/Authentication/getToken";
import { ButtonVariant } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export type DocumentTemplateDefinition = { name: string; testId: string; format?: string };
export type DocumentTemplateType = "company-creation" | "admin-service";

const TEMPLATES = {
  "company-creation": [
    { name: "OMX1Shareholder", testId: "omx1shareholder-link" },
    { name: "Errichtungsprotokoll", testId: "errichtungsprotokoll-link" },
    { name: "Gesellschaftsvertrag", testId: "gesellschaftsvertrag-link" },
    { name: "Grundungsvollmacht", testId: "grundungs-vollmacht-link" },
    { name: "Handelsregisteranmeldung", testId: "handelsregisteranmeldung-link" },
    { name: "Liste der Gesellschafter", testId: "liste-der-gesellschafter-link" },
    { name: "Anschreiben Fremdnotar", testId: "anschreiben-drittnotar-link" },
    { name: "FYRST", testId: "FYRST-link" },
    { name: "LYNX", testId: "LYNX-link" },
    { name: "Eröffnungsbilanz 25k", testId: "eroffnungsbilanz25-link" },
    { name: "Eröffnungsbilanz 25k", testId: "eroffnungsbilanz25-pdf-link", format: "pdf" },
    { name: "Eröffnungsbilanz 12.5k", testId: "eroffnungsbilanz125-link" },
    { name: "Eröffnungsbilanz 12.5k", testId: "eroffnungsbilanz125-pdf-link", format: "pdf" },
    { name: "Erfassungsbogen", testId: "erfassungsbogen-link" },
    { name: "Erfassungsbogen", testId: "erfassungsbogen-pdf-link", format: "pdf" },
    { name: "W-8BEN-E", testId: "W8BENE-link" },
    { name: "Grundungsvollmacht-Holding", testId: "grundungsvollmacht-holding-link" },
    { name: "Errichtungsprotokoll-Holding", testId: "errichtungsprotokoll-holding-link" },
    { name: "Gesellschaftsvertrag-Holding", testId: "gesellschaftsvertrag-holding-link" },
    { name: "Handelsregisteranmeldung-Holding", testId: "handelsregisteranmeldung-holding-link" },
    { name: "Liste der Gesellschafter-Holding", testId: "liste-der-gesellschafter-holding-link" }
  ],

  "admin-service": [
    { name: "AS Lite", testId: "as-lite-vvsteuerberatung-link" },
    { name: "AS Standard", testId: "as-standard-vvsteuerberatung-link" },
    { name: "AS Pro", testId: "as-pro-vvsteuerberatung-link" },
    { name: "AS Lite RIDE Steuerberatung", testId: "as-lite-ride-steuerberatung-link" },
    { name: "AS Standard RIDE Steuerberatung", testId: "as-standard-ride-steuerberatung-link" },
    { name: "AS Pro RIDE Steuerberatung", testId: "as-pro-ride-steuerberatung-link" },
    { name: "Mandatsvereinbarung LITE", testId: "mandatsvereinbarung-lite-link" },
    {
      name: "Mandatsvereinbarung LITE",
      testId: "mandatsvereinbarung-lite-pdf-link",
      format: "pdf"
    },
    { name: "Mandatsvereinbarung STANDARD", testId: "mandatsvereinbarung-standard-link" },
    {
      name: "Mandatsvereinbarung STANDARD",
      testId: "mandatsvereinbarung-standard-pdf-link",
      format: "pdf"
    },
    { name: "Mandatsvereinbarung PRO", testId: "mandatsvereinbarung-pro-link" },
    { name: "Mandatsvereinbarung PRO", testId: "mandatsvereinbarung-pro-pdf-link", format: "pdf" },
    { name: "Mandatsvereinbarung PRO Breiden", testId: "mandatsvereinbarung-breiden-pro-link" },
    {
      name: "Mandatsvereinbarung PRO Breiden",
      testId: "mandatsvereinbarung-breiden-pro-pdf-link",
      format: "pdf"
    },
    { name: "Stammdatenblatt", testId: "stammdatenblatt-link" },
    { name: "Stammdatenblatt", testId: "stammdatenblatt-pdf-link", format: "pdf" },
    { name: "Steuervollmacht", testId: "steuervollmacht-link" },
    { name: "Steuervollmacht", testId: "steuervollmacht-pdf-link", format: "pdf" },
    { name: "--------- ALT ---------", testId: "alt-separator" },
    { name: "Mandatsvereinbarung Kemper", testId: "mandatsvereinbarung-kemper-link" },
    { name: "Mandatsvereinbarung Finovia", testId: "mandatsvereinbarung-finovia-link" },
    { name: "Mandatsvereinbarung Breiden", testId: "mandatsvereinbarung-breiden-link" },
    { name: "Mandatsvereinbarung", testId: "mandatsvereinbarung-link" },
    { name: "Sparschwein", testId: "sparschwein-link" },
    { name: "Trader", testId: "trader-link" },
    { name: "VollmachtSteuer", testId: "vollmachtsteuer-link" }
  ]
};

export const DocumentTemplateDropdown = ({
  companyCreationId,
  type,
  dataTestId = "document-template-dropdown",
  t
}: {
  companyCreationId: string;
  type: DocumentTemplateType;
  dataTestId?: string;
  t: TFunction;
}) => (
  <>
    <Dropdown drop="left">
      <Dropdown.Toggle
        data-testid={`${dataTestId}-toggle-button`}
        variant={ButtonVariant.Primary}
        className="document-dropdown-ride-button button button--primary button--extraSmall button--primary--without-icon "
        id={`documents-${companyCreationId}`}>
        {t("generic:documents")}
      </Dropdown.Toggle>
      <DropdownMenu>{generateDocumentLinks(companyCreationId, type, TEMPLATES[type])}</DropdownMenu>
    </Dropdown>
  </>
);

export const DocumentTemplateInlineDropdown = ({
  companyCreationId,
  type,
  dataTestId,
  label
}: {
  companyCreationId: string;
  type: DocumentTemplateType;
  dataTestId: string;
  label: string;
}) => (
  <Dropdown drop="left">
    <Dropdown.Toggle
      data-testid={dataTestId}
      variant="success"
      className="float-right btn btn-primary documentTemplateDropdown--toggle"
      id={`documents-${companyCreationId}`}>
      {label}
    </Dropdown.Toggle>
    <DropdownMenu>{generateDocumentLinks(companyCreationId, type, TEMPLATES[type])}</DropdownMenu>
  </Dropdown>
);

const generateDocumentLinks = (
  companyCreationId,
  type: DocumentTemplateType,
  templateList: DocumentTemplateDefinition[]
) => {
  const token = getToken();

  return templateList.map((document) => {
    const format = document.format ? `&format=${document.format}` : ``;
    const label = document.format ? `${document.name} (${document.format})` : document.name;
    return (
      <Dropdown.Item
        key={`${document.testId}`}
        data-testid={`${document.testId}`}
        rel="noopener noreferrer"
        href={`${getEndpoint()}/api/nominee/document-templates/${type}/${encodeURIComponent(
          document.name
        )}/${companyCreationId}?apiToken=${token}${format}`}
        target="_blank">
        {label}
      </Dropdown.Item>
    );
  });
};
