import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  GetUserForPersonQuery,
  GetUserForPersonQueryVariables
} from "lib/api/queries/graphql/GetUserForPersonQuery";

export const GET_USER_FOR_PERSON_QUERY = gql`
  query GetUserForPersonQuery($id: ID!) {
    getPersonDetails(id: $id) {
      id
      user {
        id
        email
        confirmedAt
        invitedAt
        role
      }
    }
  }
`;

export const GetUserForPerson = (id: string | null | undefined) =>
  useQuery<GetUserForPersonQuery, GetUserForPersonQueryVariables>(GET_USER_FOR_PERSON_QUERY, {
    variables: { id: id ?? "" },
    skip: !id
  });
