import gql from "graphql-tag";

export const AVAILABLE_WALLET_OWNERS_QUERY = gql`
  query AvailableWalletOwnersQuery {
    availableWalletOwners {
      title
      companyId
    }
  }
`;
