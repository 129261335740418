import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { RideCardBodySeparator } from "../../../../sharedComponents/RideCardBodySeparator/RideCardBodySeparator";
import { YearItem } from "../../../../sharedComponents/YearItem/YearItem";

export interface IRRProps extends WithTranslation {
  yearlyData: { [key: string]: { before: number; after: number } };
  total: { yearsLabel?: string; before?: number; after?: number };
}

const InternalRateOfReturn = ({ yearlyData, total, t }: IRRProps) => {
  let items = Object.keys(yearlyData)
    .sort()
    .map((year) => ({
      year,
      before: yearlyData[year].before,
      after: yearlyData[year].after
    }));

  let totalYear = `---`;

  if (items.length > 0) {
    totalYear = total.yearsLabel ?? "";
  }

  return (
    <RideSectionCard
      title={t("generic:IRR")}
      className="internal-rate-of-return"
      data-testid="internal-rate-of-return">
      <div className="internal-rate-of-return__title">{t("generic:IRR-before-and-after-tax")}</div>
      {items.map((item, key) => (
        <YearItem year={item.year} before={item.before} after={item.after} key={key} unit="%" />
      ))}

      {items.length === 0 && <div data-testid="internal-rate-of-return-no-year">---</div>}

      <RideCardBodySeparator />

      <div className="internal-rate-of-return__title">
        {t("generic:IRR-all-year-before-and-after-tax")}
      </div>
      {items.length > 0 && (
        <div
          data-testid="internal-rate-of-return-total-years"
          className="internal-rate-of-return__year">
          {totalYear}
        </div>
      )}
      <div
        data-testid="internal-rate-of-return-total-value"
        className="internal-rate-of-return__value">
        <strong>{total?.before ?? "---"}%</strong> ({total?.after ?? "---"}%)
      </div>
    </RideSectionCard>
  );
};

export default withTranslationReady(["client", "generic"])(InternalRateOfReturn);
