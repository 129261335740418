import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { IpfsDocumentQueryByDocId } from "./graphql/IpfsDocumentQueryByDocId";
import { ipfsGateWay } from "../../../common/IPFS/IPFSSettings";

export const IPFS_DOCUMENT_QUERY_BY_DOCID = gql`
  query IpfsDocumentQueryByDocId($documentId: String!) {
    getIPFSCIDByDocId(documentId: $documentId) {
      id
      CID
    }
  }
`;

interface IPFSQueryByDocumentIdProps extends WithTranslation {
  documentId: string | null;
}

export const IPFSQueryByDocumentId: React.FunctionComponent<IPFSQueryByDocumentIdProps> = (
  props
) => {
  const { t, documentId } = props;
  const [firstRender, setFirstRender] = useState(true);

  const { data } = useQuery<IpfsDocumentQueryByDocId>(IPFS_DOCUMENT_QUERY_BY_DOCID, {
    variables: { documentId: documentId },
    fetchPolicy: firstRender ? "network-only" : undefined,
    onCompleted: () => setFirstRender(false)
  });

  const ipfsDocumentInfo = data! && data!.getIPFSCIDByDocId!;
  if (!ipfsDocumentInfo) return <>{t("spv-company:downloading")}</>;

  return (
    <>
      <a
        href={`${ipfsGateWay}/${ipfsDocumentInfo.CID}`}
        data-testid="ipfs-link"
        target="_blank"
        rel="noopener noreferrer">
        <small>{ipfsDocumentInfo.CID}</small>
      </a>
    </>
  );
};

export default withTranslation(["spv-company"])(IPFSQueryByDocumentId);
