import React from "react";
import { RouteComponentProps } from "react-router";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../global-query-types";
import { CompanyDetailsContainerOld } from "../../components/CompanyDetails/CompanyDetailsContainerOld";
import CompanyDetailsContainer from "../../components/CompanyDetails/CompanyDetailsContainer";

interface MatchProps {
  id: string;
}

const CompanyDetailsScene = ({ match }: RouteComponentProps<MatchProps>) => {
  const { id: companyId } = match.params;

  const companyByIdAPI = FeatureFlagService.instance.isEnabled(FeatureFlags.CompanyByIdAPI);
  return companyByIdAPI ? (
    <CompanyDetailsContainer companyId={companyId} />
  ) : (
    <CompanyDetailsContainerOld companyId={companyId} />
  );
};

export default CompanyDetailsScene;
