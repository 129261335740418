import moment, { Moment } from "moment/moment";

export class FiscalFormatter {
  format(value: string, format: string): string {
    return value ? moment(value, format).format("DD.MM") : "---";
  }

  transformToMoment(value: string): Moment {
    return moment(`${value}.${moment().format("YYYY")}`, "DD.MM.YYYY");
  }
}
