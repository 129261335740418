import React from "react";
import AddExistingGmbH from "./AddExistingGmbH";
import { useMutation } from "react-apollo";
import { CREATE_COMPANY } from "../../../../components/nominee/NomineeCompany/Mutations";
import { CompanyType, FeatureFlags, LegalForm } from "../../../../global-query-types";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { useHistory } from "react-router";
import {
  CreateCompany,
  CreateCompanyVariables
} from "components/nominee/NomineeCompany/graphql/CreateCompany";

export interface ClientCompanyDetailsType {
  name: string;
  legalForm: LegalForm;
  registerCourt: string;
  registration: string;
  capitalContribution: number;
  type: CompanyType;
  street: string;
  city: string;
  postalCode: string;
}

export const AddExistingGmbHContainer = () => {
  const history = useHistory();

  const [createCompany, createCompanyMutation] = useMutation<CreateCompany, CreateCompanyVariables>(
    CREATE_COMPANY
  );

  const handleCompanySubmit = async (companyData: ClientCompanyDetailsType) => {
    const variables = {
      name: companyData.name,
      legalForm: companyData.legalForm,
      capitalContribution: companyData.capitalContribution,
      street: companyData.street,
      city: companyData.city,
      postalCode: companyData.postalCode,
      type: companyData.type,
      registerCourt: companyData.registerCourt,
      registration: companyData.registration
    };
    await createCompany({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: ["MyGmbHsQuery"]
    });
  };

  const onClose = () => {
    const shouldShowCompanyList = !FeatureFlagService.instance.isEnabled(
      FeatureFlags.ReplaceCompanyListByCompanyDropdown
    );

    const companyId = createCompanyMutation.data?.createCompany?.id;
    const url =
      companyId && !shouldShowCompanyList ? `/client/entities/${companyId}/general-info` : "/";

    history.push(url);
  };

  return <AddExistingGmbH handleCompanySubmit={handleCompanySubmit} onClose={onClose} />;
};
