import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { useRideField } from "lib/hooks/useRideField";

const CompanyActivelyOperatingQuestion = ({ t }: WithTranslation) => {
  const module = "company-actively-operating-question";
  const companyActivelyOperatingField = useRideField("companyActivelyOperating");

  const moduleName = "company-actively-operating";
  return (
    <div data-testid={module} className={moduleName}>
      <Typography category={"Headline"} size={300} weight={"Heavy"} data-testid={`${module}-title`}>
        {t("generic:short-order-form.company-actively-operating-question.title")}
      </Typography>
      <Typography
        category={"Paragraph"}
        size={100}
        weight={"Light"}
        data-testid={`${module}-description`}
        className={`${moduleName}__description`}>
        {t("generic:short-order-form.company-actively-operating-question.description")}
      </Typography>
      <RideRadioSelect
        className={`company-information-step__question-yes-no`}
        name={"companyActivelyOperating"}
        options={[
          {
            label: `${t("generic:yes")}.`,
            value: "yes"
          },
          {
            label: `${t("generic:no")}.`,
            value: "no"
          }
        ]}
        variant={RadioSelectVariant.Light}
      />
      {companyActivelyOperatingField.value === "yes" && (
        <>
          <div
            data-testid={"company-keep-physical-inventory"}
            className={`${moduleName}__subquestion`}>
            <Typography
              category={"Headline"}
              size={300}
              weight={"Heavy"}
              data-testid={`company-keep-physical-inventory-title`}>
              {t("generic:short-order-form.company-keep-physical-inventory-title")}
            </Typography>
            <RideRadioSelect
              className={`company-information-step__question-yes-no`}
              name={"companyKeepsPhysicalInventory"}
              options={[
                {
                  label: `${t("generic:yes")}.`,
                  value: "yes"
                },
                {
                  label: `${t("generic:no")}.`,
                  value: "no"
                }
              ]}
              variant={RadioSelectVariant.Light}
            />
          </div>
          <div
            data-testid={"company-issues-more-than-50-outgoing-invoices-yearly"}
            className={`${moduleName}__subquestion`}>
            <Typography
              category={"Headline"}
              size={300}
              weight={"Heavy"}
              data-testid={`company-issues-more-than-50-outgoing-invoices-yearly-title`}>
              {t(
                "generic:short-order-form.company-issues-more-than-50-outgoing-invoices-yearly-title"
              )}
            </Typography>
            <RideRadioSelect
              className={`company-information-step__question-yes-no`}
              name={"companyIssuesMoreThan50OutgoingInvoicesYearly"}
              options={[
                {
                  label: `${t("generic:yes")}.`,
                  value: "yes"
                },
                {
                  label: `${t("generic:no")}.`,
                  value: "no"
                }
              ]}
              variant={RadioSelectVariant.Light}
            />
          </div>
          <div data-testid={"company-employ-5-or-more"} className={`${moduleName}__subquestion`}>
            <Typography
              category={"Headline"}
              size={300}
              weight={"Heavy"}
              data-testid={`company-employ-5-or-more-title`}>
              {t("generic:short-order-form.company-employ-5-or-more-title")}
            </Typography>
            <RideRadioSelect
              className={`company-information-step__question-yes-no`}
              name={"hasFiveOrMoreEmployees"}
              options={[
                {
                  label: `${t("generic:yes")}.`,
                  value: "yes"
                },
                {
                  label: `${t("generic:no")}.`,
                  value: "no"
                }
              ]}
              variant={RadioSelectVariant.Light}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslationReady(["generic"])(CompanyActivelyOperatingQuestion);
