import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import RideIcon from "../../../uiLibrary/components/RideIcon/RideIcon";
import { RideSize } from "../../../lib/types/types";
import { useHistory } from "react-router-dom";

export interface ReviewEntryProps {
  name: string;
  fields?: { [key: string]: string[] };
  list?: React.ReactElement[];
  editLink: string;
}

export const ReviewEntry = withTranslationReady(["generic", "shareholder-details"])(
  ({ name, fields = {}, list = [], t, editLink }: ReviewEntryProps & WithTranslation) => {
    return (
      <div className="review-entry" data-testid={`${name}-review-entry`}>
        <ReviewEntryTitle name={name} editLink={editLink} />

        {Object.entries(fields).map(([key, value]) => (
          <React.Fragment key={key}>
            <div data-testid={`${key}-entry`}>
              <div className="review-entry__label" data-testid={`${key}-label`}>
                {t(`generic:review-entry.${key}`)}
              </div>
              <div className="review-entry__value" data-testid={key}>
                {value.length === 0 && t("shareholder-details:citizenship-information.undefined")}
                {value.length === 1 && !value[0]
                  ? t("shareholder-details:citizenship-information.undefined")
                  : value.map((line, index) => <div key={index}>{line}</div>)}
              </div>
            </div>
          </React.Fragment>
        ))}

        {list.map((content, index) => (
          <div key={index} className="review-entry__item">
            {content}
          </div>
        ))}
      </div>
    );
  }
);

interface ReviewEntryTitleProps {
  name: string;
  editLink: string;
}

export const ReviewEntryTitle = withTranslationReady(["generic", "shareholder-details"])(
  ({ t, name, editLink }: ReviewEntryTitleProps & WithTranslation) => {
    const { push } = useHistory();

    return (
      <div className="review-entry__title">
        <span data-testid={name}>{t(`generic:review-entry.${name}`)}</span>
        <RideIcon
          name="edit-max"
          size={RideSize.SMALL}
          onClick={() => push(editLink)}
          dataTestId={`edit-${name}`}
        />
      </div>
    );
  }
);
