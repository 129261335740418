import React from "react";
import { GetNomineeCompanyDetailsById } from "../../../lib/api/queries/GetNomineeCompanyDetailsById";
import { AdminBreadcrumb } from "./AdminBreadcrumb";

export interface AdminPackageSelectorBreadcrumbContainerProps {
  title: string;
  companyId: string;
}

export const AdminBreadcrumbContainer = ({
  companyId,
  title
}: AdminPackageSelectorBreadcrumbContainerProps) => {
  const companyRequest = GetNomineeCompanyDetailsById(companyId);
  const company = companyRequest.data?.companyById;

  return company ? (
    <AdminBreadcrumb
      title={title}
      links={[
        {
          url: `/nominee-companies/${company.id}`,
          title: `${company.name}`,
          className: "entity-link--GREEN"
        },
        { title }
      ]}
    />
  ) : null;
};
