import React, { useEffect } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { RideStatus } from "lib/types/types";
import SetupPaymentCard from "./SetupPaymentCard/SetupPaymentCard";
import ContractSignatureCard, {
  ContractSignatureCardFlow
} from "./ContractSignatureCard/ContractSignatureCard";
import ChoosePackageCard from "./ChoosePackageCard/ChoosePackageCard";
import {
  MigrateAdminServicePackageData,
  PaymentMethod
} from "lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import {
  FeatureFlags,
  InvoiceSource,
  OrderCompanyStatusEnum,
  OrderEntityType,
  OrderOwnerType,
  OrderProductTypeEnum
} from "global-query-types";
import { UserService } from "lib/services/UserService/UserService";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import localStore from "../../../common/LocalStore";
import { LocationHelperProps, withLocationHelper } from "common/LocationHelper";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { companyName } from "lib/formatters/companyFormatter";
import SelectedProductCard from "../SelectedProductCard/SelectedProductCard";
import { AdminServiceIcon } from "sharedComponents/icons/DesignSystemIcons/AdminServiceIcon";
import { RideBadge, RideBadgeVariant } from "uiLibrary/components/RideBadge/RideBadge";
import { shortOrderFormProductPrices } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { formatCurrency } from "common/formatters";
import logger from "../../../common/Logger";
import {
  ExternalContentPolicyExceptionService,
  PolicyExceptionService
} from "lib/services/PolicyExceptionService/PolicyExceptionService";

export interface MigrateAdminServicePackageProps {
  gmbh: GmbHDetails;
  upsertOrder: (data: UpsertOrderVariables) => Promise<void>;
  getRedirectUrlHandler: (companyId: string) => Promise<{ redirectUrl: string } | null | undefined>;
  sessionId?: string | null;
  policyExceptionService: PolicyExceptionService;
}

const getContractUrl = (tier, adminServiceId): string => {
  const documentName = `Mandatsvereinbarung%20${tier}`;
  return `${getEndpoint()}/api/client/document-templates/admin-service/${documentName}/${adminServiceId}?apiToken=${getToken()}&format=pdf`;
};

const MigrateAdminServicePackage = ({
  t,
  gmbh,
  upsertOrder,
  getRedirectUrlHandler,
  sessionId,
  locationHelper,
  policyExceptionService = ExternalContentPolicyExceptionService.instance()
}: MigrateAdminServicePackageProps & WithTranslation & LocationHelperProps) => {
  const order = gmbh.orders.find(
    (order) => order.productType === OrderProductTypeEnum.MigrateAdminServicePackage
  ) as OrderData<MigrateAdminServicePackageData> | undefined;

  const [tradingCompanyRuleOverriden, setTradingCompanyRuleOverriden] = React.useState(false);

  useEffect(() => {
    (async () => {
      const shouldOverrideTradingCompanyRule = await policyExceptionService.isExceptionApplicable(
        "Allow AS LITE Package",
        gmbh.id
      );

      setTradingCompanyRuleOverriden(shouldOverrideTradingCompanyRule);
    })().catch((e) => logger.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gmbh.id]);

  const bankAccount = gmbh.bankAccounts?.[0];

  const showSelectedPackage =
    FeatureFlagService.instance.isEnabled(
      FeatureFlags.LTB630MigrateAdminServicePackageShowSelectedPackage
    ) && !!order?.extra?.tier;

  const canResetChosenPackage =
    FeatureFlagService.instance.isEnabled(
      FeatureFlags.LTB631MigrateAdminServicePackageResetPackageChoice
    ) && !!order?.extra?.tier;

  const adminServiceTierBadgeConfig = {
    [AdminServiceTier.Lite]: <RideBadge label="Lite" variant={RideBadgeVariant.OCRE} />,
    [AdminServiceTier.Standard]: <RideBadge label="Standard" variant={RideBadgeVariant.PURPLE} />,
    [AdminServiceTier.Pro]: <RideBadge label="Pro" variant={RideBadgeVariant.GREEN} />
  };

  const adminServiceTier = order?.extra?.tier;

  const adminServiceBadge = adminServiceTier
    ? adminServiceTierBadgeConfig[adminServiceTier]
    : undefined;

  const invoicedBySteueragenten = gmbh.adminService?.invoicedBy === InvoiceSource.TaxAdvisor;
  const hidePaymentStepForSteueragentenClients = FeatureFlagService.instance.isEnabled(
    FeatureFlags.HidePaymentMethodSelectionForSteueragentenClients
  );
  const showPaymentStep = !(hidePaymentStepForSteueragentenClients && invoicedBySteueragenten);

  const choosePackageStatus = calculateStatus({ completed: !!order?.extra?.tier });
  const setupPaymentStatus = calculateStatus({
    completed:
      !showPaymentStep ||
      order?.extra.paymentMethod === PaymentMethod.ManualBankTransfer ||
      (order?.extra.paymentMethod === PaymentMethod.Sepa && !!order.extra.completedPayment),
    previousStepStatus: choosePackageStatus
  });
  const contractSignatureStatus = calculateStatus({
    completed: false,
    previousStepStatus: setupPaymentStatus
  });

  const hasOrderedLTB =
    gmbh.orders.some(
      (o) => o.productType === OrderProductTypeEnum.BrokerageAccount && o.deletedAt === null
    ) ?? false;

  const hasWepa = !!gmbh.wepaId;

  const isTradingCompany = (hasOrderedLTB || hasWepa) && !tradingCompanyRuleOverriden;

  const completeStep = async () => {
    await upsertOrder({
      id: order?.id,
      extra: {
        completedPayment: true,
        stripeSessionId: sessionId
      } as MigrateAdminServicePackageData
    });
  };

  if (sessionId) {
    completeStep();
    if (localStore.getValue("token-remember-set-for-stripe")) {
      localStore.setValue("token-remember", false);
      localStore.removeValue("token-remember-set-for-stripe");
    }

    locationHelper.redirect(`/client/entities/${gmbh.id}/admin-service/migrate`);
  }

  const createOrderWithPackageChoice = async (tier: AdminServiceTier) => {
    const currentUser = UserService.instance.currentUser;
    if (!currentUser?.person?.id) {
      return;
    }

    await upsertOrder({
      productType: OrderProductTypeEnum.MigrateAdminServicePackage,
      ownerType: OrderOwnerType.Person,
      ownerId: currentUser.person.id,
      entityType: OrderEntityType.Company,
      entityId: gmbh.id,
      status: OrderCompanyStatusEnum.InProgress,
      extra: {
        tier
      } as MigrateAdminServicePackageData
    });
  };

  const selectPackage = async (tier: AdminServiceTier) => {
    if (!order?.id) {
      await createOrderWithPackageChoice(tier);
    } else {
      await upsertOrder({
        id: order.id,
        extra: {
          tier
        } as MigrateAdminServicePackageData
      });
    }
  };

  const resetPackageChoice = async () => {
    await upsertOrder({
      id: order?.id,
      extra: {
        tier: null
      } as MigrateAdminServicePackageData
    });
  };

  const setupPayment = async (paymentMethod: PaymentMethod) => {
    if (!order) {
      return;
    }

    await upsertOrder({
      id: order?.id,
      extra: {
        paymentMethod
      } as MigrateAdminServicePackageData
    });

    if (paymentMethod === PaymentMethod.Sepa) {
      const rememberToken = localStore.getValue("token-remember");
      if (!rememberToken) {
        localStore.setValue("token-remember", true);
        localStore.setValue("token-remember-set-for-stripe", true);
      }

      const stripeUrl = await getRedirectUrlHandler(gmbh.id);
      if (stripeUrl) {
        locationHelper.redirect(stripeUrl.redirectUrl);
      }
    }
  };

  const getContractSignedUrl = (): string => {
    // TODO: add test coverage to this logic
    const documentName = `Mandatsvereinbarung%20${order?.extra?.tier?.toUpperCase()}`;
    return `${getEndpoint()}/api/client/admin-service/sign/${documentName}/${
      gmbh.adminService?.id
    }?tier=${order?.extra?.tier}&apiToken=${getToken()}`;
  };

  const getAdminServicePackageUpdateSuccessRoute = () => {
    return `/client/entities/${gmbh?.id}/admin-service/migrate/success`;
  };

  const messageWithCompanyName = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddCompanyNameToPackageSelectorFlow
  )
    ? t("generic:migrate-admin-service-package.company-name-message") + companyName(gmbh)
    : "";

  return (
    <div className="migrate-admin-service-package">
      <div className="migrate-admin-service-package__header migrate-admin-service-package__section">
        <Typography data-testid={"title"} category={"Headline"} size={200} weight={"Heavy"}>
          {`${t("generic:migrate-admin-service-package.title")}${messageWithCompanyName}`}
        </Typography>
        <Typography data-testid={"description"} category={"Paragraph"} size={100} weight={"Light"}>
          <Trans i18nKey={t("generic:migrate-admin-service-package.description")} />
        </Typography>
      </div>
      <div className={"migrate-admin-service-package__section"}>
        {showSelectedPackage && (
          <div
            data-testid={"selected-package-section"}
            className="migrate-admin-service-package__header">
            <Typography category={"Headline"} size={300} weight={"Heavy"} data-testid={"header"}>
              {t("generic:migrate-admin-service-package.your-package")}
            </Typography>
            <SelectedProductCard
              data-testid="adminService"
              icon={<AdminServiceIcon />}
              title={t("generic:product-card.admin-service.title")}
              due={t("generic:after-contract-signature")}
              price={t("generic:product-card.pricePeriodicity.yearly", {
                price: formatCurrency(shortOrderFormProductPrices.adminService[adminServiceTier!], {
                  fixed: 0
                })
              })}
              badge={adminServiceBadge}
            />
          </div>
        )}
        {canResetChosenPackage && (
          <RideButton
            data-testid="reset-package-choice-button"
            label={t("generic:migrate-admin-service-package.reset-package-selection")}
            variant={RideButtonVariant.GHOST}
            size={RideButtonSize.BIG}
            onClick={resetPackageChoice}
          />
        )}
      </div>
      <div className={"migrate-admin-service-package__section"}>
        <Typography
          data-testid={"steps-headline"}
          category={"Headline"}
          size={300}
          weight={"Heavy"}>
          {t("generic:migrate-admin-service-package.steps.headline")}
        </Typography>
        <ChoosePackageCard
          stepNumber={1}
          isTradingCompany={isTradingCompany}
          selectPackage={selectPackage}
          status={choosePackageStatus}
        />
        {showPaymentStep && (
          <SetupPaymentCard
            stepNumber={2}
            price={order?.extra?.tier && shortOrderFormProductPrices.adminService[order.extra.tier]}
            companyName={companyName(gmbh)}
            iban={bankAccount?.iban}
            setupPayment={setupPayment}
            status={setupPaymentStatus}
            footnote={t("generic:payment-method-card.payment-date-info-as-migration")}
          />
        )}
        <ContractSignatureCard
          stepNumber={showPaymentStep ? 3 : 2}
          selectedTier={order?.extra.tier}
          paymentMethod={order?.extra.paymentMethod}
          contractUrl={getContractUrl(order?.extra?.tier?.toUpperCase(), gmbh.adminService?.id)}
          downloadSignedContractUrl={getContractSignedUrl()}
          successPageUrl={getAdminServicePackageUpdateSuccessRoute()}
          status={contractSignatureStatus}
          invoicedBySteueragenten={invoicedBySteueragenten}
          flow={ContractSignatureCardFlow.ADMIN_SERVICE_MIGRATION}
        />
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(withLocationHelper(MigrateAdminServicePackage));

const calculateStatus = ({
  completed,
  previousStepStatus
}: {
  completed: boolean;
  previousStepStatus?: RideStatus;
}) => {
  if (previousStepStatus && previousStepStatus !== RideStatus.COMPLETED) {
    return RideStatus.LOCKED;
  }
  return completed ? RideStatus.COMPLETED : RideStatus.PENDING;
};
