import React, { PropsWithChildren } from "react";
import RideFooter, { AppsVariant } from "sharedComponents/RideFooter/RideFooter";
import { Container } from "react-bootstrap";
import ThirdPartyTaxAdvisorHeader from "thirdPartyTaxAdvisor/components/ThirdPartyTaxAdvisorHeader/ThirdPartyTaxAdvisorHeader";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import TwoFactorAuthenticationNotificationContainer from "auth/components/TwoFactorAuthenticationNotification/TwoFactorAuthenticationNotificationContainer";

export const ThirdPartyTaxAdvisorLayout = ({ children }: PropsWithChildren<{}>) => (
  <>
    <ThirdPartyTaxAdvisorHeader />
    <Container
      fluid
      data-testid="third-party-tax-advisor-layout"
      className="p-0 flex-fill ride-body">
      {children}
    </Container>
    <RideFooter app={AppsVariant.ThirdPartyTaxAdvisor} />
    <FeatureFlag name={FeatureFlags.TwoFactorAuthenticationSetup}>
      <TwoFactorAuthenticationNotificationContainer />
    </FeatureFlag>
  </>
);
