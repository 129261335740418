import { TFunction } from "i18next";
import { useMutation } from "react-apollo";
import {
  ApplyDiscountCode,
  ApplyDiscountCodeVariables
} from "../../public/CompanyCreation/graphql/ApplyDiscountCode";
import { APPLY_DISCOUNT_CODE } from "../../public/CompanyCreation/Mutations";
import logger from "../../../common/Logger";
import RequestFeedbackHandler from "../../generic/RequestFeedbackHandler";
import React from "react";
import { ApplyDiscountCodeComponent } from "./ApplyDiscountCode";

interface ApplyDiscountCodeContainerProps {
  t: TFunction;
  refetchAndResetStep: (id: string) => Promise<void>;
  companyCreationId: string;
  discountCode?: {
    id: string;
    code: string;
    referrerValue: number | null;
    referralValue: number | null;
  } | null;
}

export const ApplyDiscountCodeContainer = ({
  t,
  companyCreationId,
  discountCode,
  refetchAndResetStep
}: ApplyDiscountCodeContainerProps) => {
  const [callApplyDiscountCodeMutation, applyDiscountCodeRequest] = useMutation<
    ApplyDiscountCode,
    ApplyDiscountCodeVariables
  >(APPLY_DISCOUNT_CODE);

  const applyDiscountCode = async (discountCode: string) => {
    try {
      await callApplyDiscountCodeMutation({
        variables: { companyCreationId: companyCreationId, discountCode }
      });
      await refetchAndResetStep(companyCreationId);
    } catch (error) {
      logger.errorMessage(`Unable to apply discount code`);
      logger.error(error);
    }
  };

  return (
    <RequestFeedbackHandler requests={[applyDiscountCodeRequest]}>
      <div data-testid="discount-code-section">
        <ApplyDiscountCodeComponent
          t={t}
          applyDiscountCode={applyDiscountCode}
          discountCode={discountCode}
        />
      </div>
    </RequestFeedbackHandler>
  );
};
