import ResumeLinkContainer from "../../../../admin/components/ResumeLink/ResumeLinkContainer";
import { TFunction } from "i18next";
import { ResumeLinkType } from "../../../../global-query-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { NomineeCompanyDetails_companyById_companyCreation } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const MagicLinkSection = ({
  t,
  companyCreation
}: {
  t: TFunction;
  companyCreation?: NomineeCompanyDetails_companyById_companyCreation | null;
}) => {
  if (!companyCreation) return null;

  return (
    <>
      <Row data-testid="magic-link-section">
        <Col>
          <h2>{t("nominee-dashboard:company.magic-link")}</h2>
        </Col>
      </Row>

      <Row>
        <Col>
          <ResumeLinkContainer id={companyCreation.id} t={t} linkType={ResumeLinkType.MAGIC} />
        </Col>
      </Row>
    </>
  );
};
