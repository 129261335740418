import { useMutation } from "@apollo/react-hooks";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import React from "react";
import SPVCompanyDetailsSection, { IUpdateSPVDetails } from "./SPVCompanyDetailsSection";
import { AssertionError } from "assert";
import { REAL_ESTATE_OWNERS_QUERY } from "components/Investments/Ownership/Queries";
import {
  updateSPVCompanyById,
  updateSPVCompanyByIdVariables
} from "components/nominee/NomineeSpv/graphql/updateSPVCompanyById";
import { SPV_COMPANIES_QUERY } from "components/nominee/NomineeSpv/SPVCompanies";
import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import { UPDATE_COMPANY_BY_ID } from "lib/api/mutations/UPDATE_COMPANY_BY_ID";

export interface SPVCompanyDetailsSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
}

export const SPVCompanyDetailsSectionContainer = ({
  company
}: SPVCompanyDetailsSectionContainerProps) => {
  const [callUpdateMutation, updateRequest] = useMutation<
    updateSPVCompanyById,
    updateSPVCompanyByIdVariables
  >(UPDATE_COMPANY_BY_ID, {
    refetchQueries: [{ query: SPV_COMPANIES_QUERY }, { query: REAL_ESTATE_OWNERS_QUERY }]
  });

  const handleSubmit = async (values: IUpdateSPVDetails) => {
    try {
      await callUpdateMutation({
        variables: {
          companyId: company.id,
          name: values.name,
          projectName: values.projectName,
          legalForm: company.legalForm,
          capitalContribution: values.capitalContribution,
          street: company.businessAddress.street,
          city: company.businessAddress.city,
          postalCode: company.businessAddress.postalCode,
          status: values.status,
          tokenized: values.tokenized === "YES",
          tradeTaxFree: values.tradeTaxFree === "YES",
          tokenizedShares: values.tokenizedShares,
          registerCourt: company.registerCourt,
          boxLink: values.boxLink
        }
      });
    } catch (error) {
      throw new AssertionError({ message: "SPV Nominee update not successful" });
    }
  };

  return (
    <RequestFeedbackHandler requests={[updateRequest]}>
      <SPVCompanyDetailsSection onSubmit={handleSubmit} company={company} />
    </RequestFeedbackHandler>
  );
};
