import React from "react";
import { ThirdPartyTaxAdvisorInviteViaEmail } from "./ThirdPartyTaxAdvisorInviteViaEmail";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";
import { SendEmailInviteByThirdPartyTaxAdvisor } from "../../../lib/api/queries/SendEmailInviteByThirdPartyTaxAdvisor";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";

export const ThirdPartyTaxAdvisorInviteViaEmailContainer = ({ t }: { t: TFunction }) => {
  const history = useHistory();
  const [sendEmailInviteByThirdPartyTaxAdvisor, sendEmailInviteByThirdPartyTaxAdvisorRequest] =
    SendEmailInviteByThirdPartyTaxAdvisor();
  const submitHandler = async (values: any) => {
    try {
      const { data } = await sendEmailInviteByThirdPartyTaxAdvisor({
        variables: { email: values.email }
      });
      if (data?.sendEmailInviteByThirdPartyTaxAdvisor?.feedback === "SUCCESS") {
        history.push("/third-party-tax-advisor/invite-clients/email/success");
      }
    } catch (error) {}
  };
  return (
    <RequestFeedbackHandler requests={[sendEmailInviteByThirdPartyTaxAdvisorRequest]}>
      <ThirdPartyTaxAdvisorInviteViaEmail t={t} onSubmit={submitHandler} />
    </RequestFeedbackHandler>
  );
};
