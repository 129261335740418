import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { FormikProvider, useFormik } from "formik";
import { emailValidationWithTranslation } from "lib/validation/EmailSchema";
import * as Yup from "yup";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import RequestFeedbackForm from "sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { MutationResult } from "@apollo/react-common";
import { AuthHeader } from "sharedComponents/AuthHeader/AuthHeader";
import { Panel } from "sharedComponents/Panel/Panel";
import { ResetMutation } from "lib/api/mutations/graphql/ResetMutation";

interface LostPasswordFormProps extends WithTranslation {
  onSubmit: (email: string) => Promise<void>;
  resetRequest: MutationResult<ResetMutation>;
  isResetPasswordFlow: boolean;
}

const LostPasswordForm = ({
  onSubmit,
  resetRequest,
  t,
  tReady,
  isResetPasswordFlow
}: LostPasswordFormProps) => {
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: async (values) => {
      await onSubmit(values.email);
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      email: emailValidationWithTranslation(t)
    })
  });

  if (!tReady) {
    return null;
  }

  return (
    <>
      <AuthHeader
        title={
          isResetPasswordFlow
            ? t("auth:reset-password-not-logged-in.title")
            : t("auth:lost-password.title")
        }
        subtitle={
          isResetPasswordFlow
            ? t("auth:reset-password-not-logged-in.subtitle")
            : t("auth:lost-password.subtitle")
        }
      />
      <Panel>
        <div className="lost-password-form">
          <FormikProvider value={formik}>
            <RequestFeedbackForm
              requests={[resetRequest]}
              submitLabel={
                isResetPasswordFlow
                  ? t("auth:reset-password-not-logged-in.submit")
                  : t("auth:reset-password.submitEmail")
              }>
              <RideInput
                formik={formik}
                name="email"
                type="text"
                data-testid="email-input"
                label={t("auth:generic.email-address")}
              />
            </RequestFeedbackForm>
          </FormikProvider>
        </div>
      </Panel>
    </>
  );
};

export default withTranslationReady(["auth"])(LostPasswordForm);
