import gql from "graphql-tag";

export const NOMINEE_ADD_EXISTING_HOLDING_COMPANY = gql`
  mutation NomineeAddExistingHoldingCompany($assetCompanyId: ID!, $holdingCompanyId: ID!) {
    nomineeAddExistingHoldingCompany(
      assetCompanyId: $assetCompanyId
      holdingCompanyId: $holdingCompanyId
    ) {
      feedback
    }
  }
`;
