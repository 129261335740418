import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ViewAdminServiceOnboardingSteps } from "./ViewAdminServiceOnboardingSteps";

export const AdminServiceOnboarding = ({
  t,
  handleChange,
  showCreationSteps,
  currentStep,
  myCompanyCreationDetails,
  refetch,
  currentFlowType
}) => {
  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Button
            data-testid="start-now"
            block
            onClick={handleChange}
            hidden={!showCreationSteps || (currentStep.length > 0 && currentStep !== "ONBOARDING")}
            className="start-now-btn">
            {t("generic:start-now")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex pl-5">
          <ViewAdminServiceOnboardingSteps
            t={t}
            showCreationSteps={showCreationSteps}
            currentStep={currentStep}
            myCompanyCreationDetails={myCompanyCreationDetails!}
            refetchMyCompanyCreation={refetch}
            currentFlowType={currentFlowType}
          />
        </Col>
      </Row>
    </>
  );
};
