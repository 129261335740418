import gql from "graphql-tag";

export const NOMINEE_UPDATE_KYC_ENTRY = gql`
  mutation NomineeUpdateKycEntry($id: ID!, $status: KycStatusEnum!, $incompleteReason: String!) {
    nomineeUpdateKycEntry(id: $id, status: $status, incompleteReason: $incompleteReason) {
      feedback
    }
  }
`;

export const NOMINEE_SETUP_WALLET = gql`
  mutation NomineeSetupWallet($personId: ID, $companyId: ID) {
    nomineeSetupWallet(personId: $personId, companyId: $companyId) {
      id
    }
  }
`;
