import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";

export interface CompanyAccountingFrameworkCardProps extends CompanyCardProps {}

const CompanyAccountingFrameworkCard = ({
  t,
  gmbh,
  handleEditField
}: CompanyAccountingFrameworkCardProps) => (
  <RideSectionCard
    title={t(`client:company:field:accountingFramework`)}
    data-testid={`gmbh-accounting-framework-details`}
    className="shadow-none my-5">
    <RideCardValuePair
      data-testid={`company-accounting-framework`}
      onEdit={handleEditField?.("accounting-framework")}
      isLocked={false}
      lockTooltipContent={t("generic:fieldLocked")}>
      {gmbh.accountingFramework}
    </RideCardValuePair>
  </RideSectionCard>
);

export default CompanyAccountingFrameworkCard;
