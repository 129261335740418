import React from "react";
import social from "../../../config/social.json";

export interface SocialIconProps {
  platform: string;
}

export const SocialIcon = ({ platform }: SocialIconProps) => {
  const href = social[platform] || "#";

  return (
    <div className={`social-icon social-icon-${platform}`}>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={`social-icon-${platform}`}>
        <span className="d-none">{platform}</span>
      </a>
    </div>
  );
};
