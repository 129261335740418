import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import EditableSection from "../../../sharedComponents/EditableSection/EditableSection";
import { ValueView } from "../../../components/common/layout/ValueView";
import { UpdateDiscountCodeSchema } from "../../../lib/validation/UpdateDiscountCodeSchema";
import { ReferralCodeBlacklistingContainer } from "admin/components/ReferralCodeBlacklisting/ReferralCodeBlacklistingContainer";
import EntityLink from "components/common/EntityLink/EntityLink";
import { AllDiscountCodesQuery_allDiscountCodes } from "lib/api/queries/graphql/AllDiscountCodesQuery";
import { useHistory } from "react-router-dom";
import ReferralCodeFormViewMode from "../ReferralCodeFormViewMode/ReferralCodeFormViewMode";
import ReferralCodeFormEditMode from "../ReferralCodeFormEditMode/ReferralCodeFormEditMode";
import { isEmpty, assign } from "lodash";

interface ReferralCodeFormProps extends WithTranslation {
  referralCode: AllDiscountCodesQuery_allDiscountCodes;
  updateDiscountCode: (
    code: string,
    referrerValue: number,
    referralValue: number,
    discountCodeAllowedService: AllowedDiscountCodeService
  ) => Promise<void>;
}

export enum DiscountCodeAllowedServiceEnum {
  GS = "GS",
  AS = "AS"
}

export enum AllowedServiceExpansionEnum {
  GS = "founding-service",
  AS = "admin-service"
}

export type AllowedDiscountCodeService = {
  [key in DiscountCodeAllowedServiceEnum]: boolean;
};

export const ReferralCodeForm = ({
  t,
  referralCode,
  updateDiscountCode
}: ReferralCodeFormProps) => {
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);

  const title = t("nominee-dashboard:referral-code.heading");
  const testIdPrefix = "nomineeUpdateDiscountCode-";

  const referrerPartner = referralCode.referrerPerson;
  const code = referralCode.code ?? "";
  const referrerValue = referralCode.referrerValue ?? 0;
  const referralValue = referralCode.referralValue ?? 0;
  const discountCodeAllowedService: AllowedDiscountCodeService =
    referralCode.discountCodeAllowedService ?? {};

  const onEdit = () => {
    setEditMode(true);
  };

  const onView = () => {
    setEditMode(false);
  };

  if (isEmpty(discountCodeAllowedService)) {
    assign(discountCodeAllowedService, { GS: false, AS: false });
  }

  const formik = useFormik({
    initialValues: { code, referralValue, referrerValue, discountCodeAllowedService },
    enableReinitialize: true,
    validationSchema: UpdateDiscountCodeSchema,
    onSubmit: async (values) => {
      await updateDiscountCode(
        values.code,
        values.referralValue,
        values.referrerValue,
        values.discountCodeAllowedService
      );

      if (referralCode.code !== values.code)
        history.replace(`/nominee/referral-codes/${values.code}`);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditableSection
          onEdit={onEdit}
          isEditMode={editMode}
          onView={onView}
          testIdPrefix={testIdPrefix}
          title={title}>
          {editMode ? (
            <>
              <ReferralHeaderSection t={t} code={code} referrerPartner={referrerPartner} />
              <ReferralCodeFormEditMode
                t={t}
                formik={formik}
                allowedReferralCodeServices={discountCodeAllowedService}
              />
            </>
          ) : (
            <>
              <ReferralHeaderSection t={t} code={code} referrerPartner={referrerPartner} />
              <ReferralCodeFormViewMode
                t={t}
                code={code}
                referralValue={referralValue}
                referrerValue={referrerValue}
                allowedReferralCodeServices={discountCodeAllowedService}
              />
            </>
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

const ReferralHeaderSection = ({ t, code, referrerPartner }) => {
  return (
    <>
      <ReferralCodeBlacklistingContainer code={code} />
      <ValueView
        t={t}
        testId="referrer-name"
        name="nominee-dashboard:referral-code.referrer"
        value={<EntityLink membership={{ person: referrerPartner }} testIdPrefix="referrer" />}
      />
    </>
  );
};

export default withTranslationReady(["nominee-dashboard", "trader-gmbh", "generic"])(
  ReferralCodeForm
);
