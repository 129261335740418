import React from "react";
import { FlowTypeEnum } from "../../global-query-types";

const ReferralOrderType = ({
  flowType,
  testId = ""
}: {
  flowType: FlowTypeEnum;
  testId: string | undefined;
}) => {
  return <div data-testid={testId}>{convertOrderFlowToOrderType(flowType)}</div>;
};

export const convertOrderFlowToOrderType = (orderFlow: FlowTypeEnum | null): string => {
  if (
    orderFlow === FlowTypeEnum.ADMIN_SERVICE ||
    orderFlow === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING
  ) {
    return "AS";
  } else if (
    orderFlow === FlowTypeEnum.GMBH ||
    orderFlow === FlowTypeEnum.HOLDING_GMBH ||
    orderFlow === FlowTypeEnum.REAL_ESTATE_GMBH ||
    orderFlow === FlowTypeEnum.TRADER_HOLDING ||
    orderFlow === FlowTypeEnum.FOUNDER_HOLDING ||
    orderFlow === FlowTypeEnum.INVESTOR_HOLDING
  ) {
    return "GS";
  } else if (orderFlow === FlowTypeEnum.WEPA_SERVICE) {
    return "WEPA";
  } else {
    return orderFlow ?? "";
  }
};

export default ReferralOrderType;
