import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import React from "react";
import { FeatureFlagService } from "../../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../../../global-query-types";

const moduleName = "short-order-form-partners";

const Partners = ({ t }: WithTranslation) => {
  const showFxFlatLogo = !FeatureFlagService.instance.isEnabled(FeatureFlags.NewBroker);
  return (
    <div className={moduleName} data-testid="partners-section">
      {t("generic:partners-section-text")}
      <div className={`${moduleName}__logos`}>
        {showFxFlatLogo && <div className={`${moduleName}__fx-flat`} data-testid={"fx-flat"} />}
        <div className={`${moduleName}__interactive-brokers`} data-testid={"interactive-brokers"} />
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(Partners);
