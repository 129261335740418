import React from "react";
import { FlowTypeEnum, RolesInGroup } from "../../../global-query-types";
import { formatCompanyName } from "../../../common/formatters/CompanyNameFormatter";
import Table from "../../../components/generic/Table";
import { Link } from "react-router-dom";
import CompanyNameOrUnnamed from "../../../components/common/CompanyNameOrUnnamed";
import { formatValue } from "../../../common/formatters";
import { withTranslation, WithTranslation } from "react-i18next";
import ResumeLinkContainer from "../ResumeLink/ResumeLinkContainer";
import Timestamp from "../../../components/generic/Timestamp";
import { ArchiveServiceContainer } from "../ArchiveService/ArchiveService";
import { DocumentTemplateDropdown } from "../DocumentTemplateDropdown/DocumentTemplateDropdown";
import { getUniqPeopleAs } from "../../../common/getUniqPeopleAs";
import UserFinApiAccountStatus, {
  InviteStatus
} from "../UserFinApiAccountStatus/UserFinApiAccountStatus";
import { CompanyCreationsQuery_companyCreations_items } from "../../../lib/api/queries/graphql/CompanyCreationsQuery";

export interface AdminServiceListProps extends WithTranslation {
  list: CompanyCreationsQuery_companyCreations_items[];
  refetch: any;
}

export const AdminServiceList = ({ list, refetch, t }: AdminServiceListProps) => {
  const enrichedData = list
    .map((creation) => {
      const company = creation.adminCompany;
      const orderer = getUniqPeopleAs(company?.group?.memberships ?? [], [RolesInGroup.Orderer]);

      const firstPerson = orderer[0]?.person;
      const referralCode = company?.discountCodeUse?.discountCode?.code;
      const user = firstPerson?.user;

      return {
        ...creation,
        company,
        orderer,
        referralCode,
        user,

        companyName: company ? formatCompanyName(t, company) : undefined,
        userName: `${firstPerson?.firstName ?? ""} ${firstPerson?.lastName ?? ""}`,
        packageType: creation.packageType
      };
    })
    .filter(
      (c) =>
        c.flowType &&
        [FlowTypeEnum.ADMIN_SERVICE, FlowTypeEnum.ADMIN_SERVICE_ONBOARDING].includes(c.flowType)
    );

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc"
    }
  ];

  const columns = [
    {
      dataField: "companyName",
      text: t("nominee-dashboard:company-creation.company"),
      sort: true,
      formatter: (_, row) => (
        <div data-testid="company-name-and-package-type-and-referral">
          <div data-testid="company-name">
            {row.company ? (
              <Link
                to={`/nominee-companies/${row.company.id}`}
                className="company-link"
                data-testid="company-link">
                <CompanyNameOrUnnamed company={row.company} />
              </Link>
            ) : (
              "N/A"
            )}
          </div>
          <div data-testid="package-type">
            <small>
              {!row.packageType
                ? ""
                : t(
                    `nominee-dashboard:company-creation.admin-service.package-type.${row.packageType}`
                  )}
            </small>
          </div>
          <div data-testid="referral">
            <small>
              ref: {formatValue(row.referral)} {formatValue(row.referralCode)}
            </small>
          </div>
        </div>
      )
    },
    {
      dataField: "userName",
      text: t("nominee-dashboard:company-creation.user"),
      sort: true,
      formatter: (_, row) => (
        <div data-testid="user-name">
          {row.orderer.map(({ person: { id, firstName, lastName } }) => (
            <div key={id}>
              <Link to={`/nominee-persons/${id}`} className="person-link" data-testid="person-link">
                {(firstName ?? "") + " " + (lastName ?? "")}
              </Link>
            </div>
          ))}
        </div>
      )
    },
    {
      dataField: "createdAt",
      text: t("nominee-dashboard:company-creation.flow-started"),
      sort: true,
      formatter: (cell) => (
        <span data-testid="created-at">
          <Timestamp date={cell} />
        </span>
      )
    },
    {
      dataField: "currentStep",
      text: t("nominee-dashboard:company-creation.flow-current-step"),
      sort: true,
      formatter: (_, row) => (
        <div data-testid="current-step">
          <div>{t(`nominee-dashboard:company-creation.admin-service.step.${row.currentStep}`)}</div>
          <div>
            <Timestamp date={row.updatedAt} />
          </div>
        </div>
      )
    },
    {
      dataField: "orderedAt",
      text: t("nominee-dashboard:company-creation.ordered-at"),
      sort: true,
      formatter: (value) => (
        <div data-testid="ordered-at">
          <Timestamp date={value} />
        </div>
      )
    },
    {
      dataField: "user",
      text: t("nominee-dashboard:company-creation.invite-status"),
      sort: true,
      formatter: (user: InviteStatus) => (
        <span data-testid="invite-status">
          <Timestamp date={user?.invitedAt ?? ""} />
          <span>
            <UserFinApiAccountStatus hasFinApiAccount={user?.hasFinApiAccount ?? false} />
          </span>
        </span>
      )
    },
    {
      dataField: "",
      text: t("nominee-dashboard:company-creation.admin-service.resumeLink"),
      sort: true,
      formatter: (cell, row) => (
        <>
          <ResumeLinkContainer
            id={row.id}
            t={t}
            currentStep={row.currentStep}
            flowType={row.flowType}
          />
          <DocumentTemplateDropdown companyCreationId={row.id} type="admin-service" t={t} />
          <ArchiveServiceContainer id={row.id} t={t} refetchCompanyCreation={refetch} />
        </>
      )
    }
  ];

  return (
    <Table
      data={enrichedData}
      defaultSorted={defaultSorted}
      columns={columns}
      keyField="id"
      data-test-id="admin-service-table"
      tableStyle="greenTable"
    />
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(AdminServiceList);
