import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { useQuery } from "react-apollo";
import { CompanyType } from "global-query-types";
import { RidePaginationWithSearch } from "uiLibrary/designSystem/RidePagination/RidePaginationWithSearch";
import TradingTransactionHistoryCompaniesList from "./TradingTransactionHistoryCompaniesList";
import { ALL_COMPANIES_WITH_CONNECTED_BROKERAGE_ACCOUNT } from "../../../lib/api/queries/AllCompaniesWithConnectedBrokerageAccount";
import {
  AllCompaniesWithConnectedBrokerageAccount,
  AllCompaniesWithConnectedBrokerageAccountVariables
} from "../../../lib/api/queries/graphql/AllCompaniesWithConnectedBrokerageAccount";

export const TradingTransactionHistoryCompaniesListContainer = () => {
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let history = useHistory();

  const limit = 15;
  const start = parseInt(queryParams.get("start") ?? "0");

  const setStart = (value) => {
    queryParams.set("start", value);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const query = queryParams.get("query");
  const setQuery = (value) => {
    queryParams.set("query", value);
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const allCompaniesResponse = useQuery<
    AllCompaniesWithConnectedBrokerageAccount,
    AllCompaniesWithConnectedBrokerageAccountVariables
  >(ALL_COMPANIES_WITH_CONNECTED_BROKERAGE_ACCOUNT, {
    variables: {
      type: CompanyType.ClientEntity,
      query,
      start,
      limit
    }
  });
  const allCompanies = allCompaniesResponse.data?.allCompaniesWithConnectedBrokerageAccount;

  return (
    <RequestFeedbackHandler requests={[allCompaniesResponse]}>
      <RidePaginationWithSearch
        setQuery={setQuery}
        setStart={setStart}
        start={start}
        query={query}
        count={allCompanies?.count ?? 0}
        limit={limit}>
        <TradingTransactionHistoryCompaniesList companies={allCompanies?.companies ?? []} />
      </RidePaginationWithSearch>
    </RequestFeedbackHandler>
  );
};
