import React from "react";
import { ProductCardRadioInput } from "client/components/ProductCard/ProductCard";
import { Field } from "formik";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { useRideField } from "lib/hooks/useRideField";

interface PaymentOptionProps {
  "data-testid"?: string;
  name: string;
  value: string;
  label: string;
  feeMessage?: string;
  icon?: React.ReactElement | null;
}

export const PaymentOption = ({
  "data-testid": dataTestId = "payment-option",
  name,
  value,
  label,
  icon,
  feeMessage
}: PaymentOptionProps) => {
  const module = "payment-option";
  const field = useRideField(name);

  return (
    <div
      data-testid={dataTestId}
      className={`${module} ${field.value === value ? `${module}--selected` : ""}`}
      onClick={() => field.setValue(value)}>
      <div className={`${module}__top-area${icon ? "--with-icon" : ""}`}>
        {icon}
        <div className={`${module}__input`}>
          <Field
            type="radio"
            as={ProductCardRadioInput}
            data-testid={`${dataTestId}-radio`}
            name={name}
            value={value}
          />
        </div>
      </div>

      <div className={`${module}__labels`}>
        <Typography
          category={"Paragraph"}
          size={100}
          weight={"Heavy"}
          data-testid={`${dataTestId}-label`}>
          {label}
        </Typography>
        <Typography
          category={"Paragraph"}
          size={100}
          weight={"Light"}
          data-testid={`${dataTestId}-fee-message`}>
          {feeMessage}
        </Typography>
      </div>
    </div>
  );
};
