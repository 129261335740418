import React from "react";
import { TFunction } from "i18next";

export const EditKYCStatusCompanyInfo: ({ t }: { t: TFunction }) => JSX.Element = ({
  t
}: {
  t: TFunction;
}) => (
  <>
    <p data-testid="edit-kyc-status-information" />
    <p>{t("nominee-dashboard:kyc-status.company-condition.completed-condition-start")}</p>
    <ol>
      {[1, 2, 3, 4].map((i) => (
        <li key={i}>
          {t(`nominee-dashboard:kyc-status.company-condition.completed-condition-${i}`)}
        </li>
      ))}
    </ol>
    <div>{t("nominee-dashboard:kyc-status.company-condition.completed-condition-end")}</div>
  </>
);
