import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { LinkVariant } from "../TextLink/TextLink";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import TextLinkAsButton from "../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

interface DeleteTextLinkWithModalProps extends PropsWithChildren<WithTranslation> {
  onConfirm: () => Promise<void>;
}

const DeleteTextLinkWithModal = ({ onConfirm, children, t }: DeleteTextLinkWithModalProps) => {
  return (
    <div data-testid="delete-text-link-with-modal">
      <ConfirmationModal
        title={t("generic:delete-confirmation")}
        dialogBody={children}
        confirmLabel={t("generic:delete")}
        onConfirm={onConfirm}>
        {(setVisibility) => (
          <TextLinkAsButton
            variant={LinkVariant.danger}
            onClick={() => setVisibility(true)}
            data-testid="delete-label">
            {t("generic:delete")}
          </TextLinkAsButton>
        )}
      </ConfirmationModal>
    </div>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(DeleteTextLinkWithModal);
