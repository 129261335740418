import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { ClientSelect, ClientSelectOption } from "client/components/ClientSelect/ClientSelect";
import { FeatureFlags, StepStatesEnum, TaskStatesEnum, UserRole } from "global-query-types";
import { FoundingProcessConfigService } from "lib/services/FoundingProcessConfigService/FoundingProcessConfigService";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Col, Row } from "react-bootstrap";
import { FeatureFlag } from "../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

export interface FoundationServiceTasksTableFilters {
  state?: TaskStatesEnum | null;
  ref?: string;
  responsibleParty?: UserRole;
  stepGroup?: string;
  stepState?: StepStatesEnum | null;
}

export interface FoundationServiceTasksFiltersProps extends WithTranslation {
  onFilter: (filters: FoundationServiceTasksTableFilters) => Promise<void>;
}

const FoundationServiceTasksFilters = ({ onFilter, t }: FoundationServiceTasksFiltersProps) => {
  const formik = useFormik({
    initialValues: {
      statusFilter: ""
    },
    onSubmit: () => {}
  });

  const taskStatusOptions: ClientSelectOption[] = Object.keys(TaskStatesEnum).map((taskState) => {
    return { label: t(`company-founding-steps:tasks.state.${taskState}`), value: taskState };
  });

  const stepStatusOptions: ClientSelectOption[] = [
    { label: t("company-founding-steps:states.TO_BE_DONE"), value: "TO_BE_DONE" },
    { label: t(`company-founding-steps:states.IN_PROGRESS`), value: "IN_PROGRESS" },
    { label: t(`company-founding-steps:states.COMPLETED`), value: "COMPLETED" },
    { label: t(`company-founding-steps:states.NOT_REQUIRED`), value: "NOT_REQUIRED" }
  ];

  const ownerOptions: ClientSelectOption[] = [
    {
      label: t(`company-founding-steps:owners-lists.${UserRole.Client.toLowerCase()}`),
      value: UserRole.Client
    },
    {
      label: t(`company-founding-steps:owners-lists.${UserRole.Nominee.toLowerCase()}`),
      value: UserRole.Nominee
    }
  ];

  const refOptions: ClientSelectOption[] = FoundingProcessConfigService.instance
    .getFlatTasks()
    .map((task) => ({
      label: t(`company-founding-steps:tasks.name.${task.ref}`),
      value: task.ref
    }));

  const stepGroupOptions = FoundingProcessConfigService.instance
    .getStepGroups()
    .map((stepGroup) => {
      return {
        label: t(`company-founding-steps:steps.${stepGroup.ref}`),
        value: stepGroup.ref
      };
    });

  const [filters, setFilters] = React.useState<FoundationServiceTasksTableFilters>({
    ref: undefined,
    state: undefined,
    responsibleParty: undefined,
    stepGroup: undefined,
    stepState: undefined
  });

  const handleFilter = async (updatedFilters: FoundationServiceTasksTableFilters) => {
    await onFilter(updatedFilters);
    setFilters(updatedFilters);
  };

  return (
    <div data-testid="foundation-service-task-filter" className="foundation-service-task-filter">
      <FormikProvider value={formik}>
        <Form>
          <Row>
            <Col md={4} xs={12}>
              <ClientSelect
                name="statusFilter"
                label={t("company-founding-steps:filters.labels.status")}
                options={taskStatusOptions}
                isClearable={true}
                changeHandler={(state) => {
                  handleFilter({
                    ...filters,
                    state: (state as TaskStatesEnum) || null
                  });
                }}
                isSearchable
              />
            </Col>
            <Col md={4} xs={12}>
              <ClientSelect
                name="refFilter"
                label={t("company-founding-steps:filters.labels.task")}
                options={refOptions}
                isClearable={true}
                changeHandler={(ref) =>
                  handleFilter({
                    ...filters,
                    ref
                  })
                }
                isSearchable
              />
            </Col>
            <Col md={4} xs={12}>
              <ClientSelect
                name="ownerFilter"
                label={t("company-founding-steps:filters.labels.owner")}
                options={ownerOptions}
                isClearable={true}
                changeHandler={(owner) => {
                  handleFilter({
                    ...filters,
                    responsibleParty: (owner as UserRole) || null
                  });
                }}
                isSearchable
              />
            </Col>
          </Row>
          <Row>
            <FeatureFlag name={FeatureFlags.FoundationServiceStepFilter}>
              <Col md={4} xs={12}>
                <ClientSelect
                  name="stepFilter"
                  label={t("company-founding-steps:filters.labels.step")}
                  options={stepGroupOptions}
                  changeHandler={(stepGroup) => {
                    handleFilter({
                      ...filters,
                      stepGroup: stepGroup
                    });
                  }}
                  isClearable={true}
                  isSearchable
                />
              </Col>
            </FeatureFlag>
            <FeatureFlag name={FeatureFlags.FoundationServiceStepFilter}>
              <Col md={4} xs={12}>
                <ClientSelect
                  name="stepStatusFilter"
                  label={t("company-founding-steps:filters.labels.stepStatus")}
                  isClearable={true}
                  isSearchable
                  options={stepStatusOptions}
                  changeHandler={(stepStatus) => {
                    handleFilter({
                      ...filters,
                      stepState: (stepStatus as StepStatesEnum) || null
                    });
                  }}
                />
              </Col>
            </FeatureFlag>
          </Row>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady("company-founding-steps")(FoundationServiceTasksFilters);
