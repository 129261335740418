import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideModal, {
  RideModalBody,
  RideModalControls
} from "uiLibrary/designSystem/RideModal/RideModal";
import BankAccountForm from "../BankAccountForm/BankAccountForm";
import { Form, FormikProvider, useFormik } from "formik";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { BankType } from "global-query-types";
import { ButtonTrash } from "sharedComponents/Buttons/ButtonTrash/ButtonTrash";
import { BankAccountDeleteModalContainer } from "sharedComponents/BankAccountDeleteModal/BankAccountDeleteModalContainer";

export interface EditBankAccountModalProps extends WithTranslation {
  onSave: (vars: { id: string; bankProvider: BankType; iban?: string; name?: string }) => void;
  onClose: (doRefetch: boolean | undefined) => void;
  bankProvider: BankType;
  accountName: string;
  iban: string;
  id: string;
}

const EditBankAccountModal = ({
  t,
  id,
  iban,
  onSave,
  onClose,
  bankProvider,
  accountName
}: EditBankAccountModalProps) => {
  const formik = useFormik({
    initialValues: {
      name: accountName,
      bank: bankProvider,
      iban
    },
    onSubmit: (values) =>
      onSave({
        id: id,
        iban: values.iban,
        name: values.name,
        bankProvider: values.bank
      })
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <RideModal
          data-testid="edit-bank-account-modal"
          title={t("client:banking.editBankAccount.title")}
          onClose={() => onClose(false)}>
          <RideModalBody>
            <BankAccountForm formik={formik} companyOptions={[]} />
            <div className={"edit-bank-account-modal__delete"}>
              <BankAccountDeleteModalContainer
                id={id}
                name={accountName}
                onRefresh={async () => onClose(true)}
                children={(setVisibility) => {
                  return (
                    <ButtonTrash
                      data-testid="delete-bank-account-button"
                      onClick={() => setVisibility(true)}
                      type={"button"}>
                      {t("client:banking.editBankAccount.delete")}
                    </ButtonTrash>
                  );
                }}
              />
            </div>
          </RideModalBody>
          <RideModalControls>
            <RideButtonDeprecated
              width={ButtonWidth.FULL}
              radius={ButtonRadius.Soft}
              data-testid={"save-button"}
              type={"submit"}>
              {t("generic:save")}
            </RideButtonDeprecated>
          </RideModalControls>
        </RideModal>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic", "client"])(EditBankAccountModal);
