import React, { Fragment, useRef, useState } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import ChaptersSidebar, { ChapterData } from "../ChaptersSidebar/ChaptersSidebar";
import { GetOrderQuery_getOrder } from "lib/api/queries/graphql/GetOrderQuery";
import { useHistory, useParams } from "react-router-dom";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import logger from "common/Logger";
import { FinalizeOrderVariables } from "lib/api/mutations/graphql/FinalizeOrder";
import { ErrorAlert } from "sharedComponents/ErrorAlert/ErrorAlertLegacy";
import ChaptersProgressBar, {
  ChaptersProgressBarProps
} from "../ChaptersProgressBar/ChaptersProgressBar";
import {
  ChaptersContextualHelpWrapper,
  errorCodeToTitleKeyMap,
  OrderChaptersConfig,
  StepNavigationControls
} from "./ChaptersOrderLayout.partials";
import {
  ExternalContentItem,
  ExternalContentProvider,
  useExternalContentProvider as _useExternalContentProvider
} from "lib/ports/ExternalContentProvider";
import { RideStatus } from "lib/types/types";
import SubchapterOrderLayout from "../SubchapterOrderLayout/SubchapterOrderLayout";
import { Step } from "lib/models/client/order/Step";
import { Order } from "lib/models/client/order/Order";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { getOrderFlowInfo } from "lib/models/client/order/orderConfig";
import { ErrorCodes, FeatureFlags } from "global-query-types";
import RideFormLayout from "../RideFormLayout/RideFormLayout";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { ClientUrls } from "client/components/ClientHeader/ClientHeader";
import {
  FinalizeLTBOrder,
  FinalizeLTBOrderVariables
} from "../../../lib/api/mutations/graphql/FinalizeLTBOrder";
import RideModal, { RideModalBody } from "../../../uiLibrary/designSystem/RideModal/RideModal";
import { ExecutionResult } from "graphql";
import { AlertType, RideAlertBar } from "../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import { DataLayer, OrderEventProductType } from "lib/services/GoogleTagManager/GoogleTagManager";

export interface ChaptersOrderLayoutProps extends WithTranslation {
  title: string;
  onCancel: () => void;
  contextualHelp?: ExternalContentItem;
  order: GetOrderQuery_getOrder;
  config: OrderChaptersConfig;
  upsertOrder: (data: UpsertOrderVariables) => Promise<void>;
  finalizeOrder: (
    data: FinalizeOrderVariables | FinalizeLTBOrderVariables
  ) => Promise<ExecutionResult<FinalizeLTBOrder> | undefined>;
  useExternalContentProvider?: () => ExternalContentProvider;
}

const ChaptersOrderLayout = ({
  t,
  title,
  onCancel,
  config,
  order,
  upsertOrder,
  finalizeOrder,
  useExternalContentProvider = _useExternalContentProvider
}: ChaptersOrderLayoutProps) => {
  if (order.productType === "BrokerageAccount" && order.extra?.isNewBrokerEnabled === true) {
    config.chapters.documents.title = "generic:ride-broker-onboarding.chapters.agreements";
  }
  const { push } = useHistory();
  const module = "chapters-order-layout";
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [saveDataCopy, setSaveDataCopy] = useState<{
    data: any;
    shouldNotPush: boolean;
    shouldSave: boolean;
  }>({ data: {}, shouldNotPush: false, shouldSave: true });
  const [errorMessage, setErrorMessage] = useState("");

  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

  const [saveDataFunctions, setSaveDataFunctions] = useState<{
    resolve: (() => void) | null;
    reject: (() => void) | null;
  }>({ resolve: null, reject: null });

  const confirmCancelModalRef = useRef<{ trigger: () => Promise<void> }>();

  const {
    chapter,
    subChapterIndex: subChapterIndexString,
    stepIndex: stepIndexString
  } = useParams<{ chapter: string; subChapterIndex: string | undefined; stepIndex: string }>();
  const stepIndex = parseInt(stepIndexString);
  const subChapterIndex = subChapterIndexString ? parseInt(subChapterIndexString) : null;

  const orderFlow = new Order(order);
  const currentChapter = orderFlow.getChapter(chapter);
  let step: Step | undefined = currentChapter?.getStepByIndex(stepIndex);
  let subChapterStep: Step | undefined;

  const shouldHideProgressBar =
    (currentChapter?.subChapters?.length ?? 0) > 0 || (currentChapter?.steps?.length ?? 0) < 2;

  let subChapterSteps: Step[] | undefined = undefined;

  if (subChapterIndex !== null) {
    const currentSubChapter = currentChapter?.getSubChapterByIndex(subChapterIndex);
    subChapterSteps = currentSubChapter?.steps;
    subChapterStep = currentSubChapter?.getStepByIndex(stepIndex);

    step = currentSubChapter?.parentStepKey
      ? currentChapter?.getStepByKey(currentSubChapter.parentStepKey)
      : currentChapter?.steps?.[0]; //TODO: to test
  }

  if (!step) {
    console.error("!step", step);
    return <ErrorAlert t={t} error={"errorCodes:order-form.invalid-order-format"} />;
  }

  const stepKey = step.key;
  if (!config.steps[stepKey]) {
    console.error("!config.steps[stepKey]", stepKey);
    return <ErrorAlert t={t} error={"errorCodes:order-form.invalid-order-format"} />;
  }

  let totalSteps = currentChapter?.steps?.length ?? 0;
  let progressBarProps: ChaptersProgressBarProps = { title: "", totalSteps, currentStep: 0 };

  const obtainChapterCompletionStatus = (chapterKey: string) => {
    const chapter = orderFlow.getChapter(chapterKey);
    return chapter?.completed ? RideStatus.COMPLETED : RideStatus.PENDING;
  };

  const Content = config.steps[stepKey];
  const SubChapterContent = config.steps[subChapterStep?.key ?? ""];

  const chapterKeys = Object.keys(order.extra.chapters);
  for (let chapterKey of chapterKeys) {
    if (!config.chapters[chapterKey]) {
      console.error("!config.chapters[chapterKey]", chapterKey);
      return <ErrorAlert t={t} error={"errorCodes:order-form.invalid-order-format"} />;
    } else {
      obtainChapterCompletionStatus(chapterKey);
    }
  }

  progressBarProps = {
    totalSteps,
    currentStep: step.disposition,
    title: t(rideBrokerT(config.chapters[chapter].title))
  };

  const chapters: ChapterData[] = chapterKeys.map((chapterKey) => ({
    key: chapterKey,
    title: t(rideBrokerT(config.chapters[chapterKey].title)),
    icon: config.chapters[chapterKey].icon,
    status: obtainChapterCompletionStatus(chapterKey),
    active: chapterKey === chapter
  }));

  const setErrorModalBody = (e) => {
    const supportedErrors = [
      "EMAIL_ACCOUNT_ALREADY_EXISTS",
      "GMBH_ORDER_REUSED_EMAIL",
      "GMBH_ORDER_CURRENT_USER_NOT_SHAREHOLDER",
      "GMBH_ORDER_NO_MANAGING_DIRECTOR",
      "GMBH_ORDER_EMPLOYEE_IS_NOT_MANAGING_DIRECTOR",
      "GMBH_ORDER_ADDRESS_INFORMATION_MISSING",
      "INVALID_GMBH_ORDER_STRUCTURE",
      "GMBH_ORDER_SHAREHOLDER_TYPE_NOT_SUPPORTED",
      "GMBH_ORDER_COMPANY_REGISTER_COURT_MISSING",
      "GMBH_ORDER_COMPANY_REGISTRATION_MISSING",
      "COMPANY_NAME_MISSING",
      "COMPANY_LEGAL_FORM_NOT_SUPPORTED",
      "GMBH_ORDER_ASSET_NOT_INFORMED",
      "COMPANY_LEGAL_FORM_MISSING",
      "LOW_TAX_BROKER_CURRENT_USER_NOT_SHAREHOLDER",
      "LOW_TAX_BROKER_CURRENT_USER_EMAIL_IS_IN_MORE_THAN_1_SHAREHOLDER",
      "Network error: Failed to fetch"
    ];
    const error = supportedErrors.find((s) => e.message.includes(s));

    setErrorMessage(error ? errorCodeToTitleKeyMap[error] : "GENERIC_ERROR");
  };

  const saveDataExecutor =
    (data: any, shouldNotPush = false, shouldSave = true) =>
    async (resolve, reject) => {
      try {
        if (shouldSave) {
          await upsertOrder({
            id: order.id,
            submissionId: order.submissionId,
            ownerType: order.ownerType,
            ownerId: order.ownerId,
            status: order.status,
            entityType: order.entityType,
            entityId: order.entityId,
            productType: order.productType,
            extra: data
          });
        }

        const shouldPush = !shouldNotPush;

        const currentStep = (subChapterStep ?? step)!;
        const nextStep = currentStep.getNextStep();
        const nextChapter = nextStep?.chapter;

        if (!nextChapter) {
          setIsLoaderActive(true);

          const finalizeOrderResponse = await finalizeOrder({
            id: order.id ?? "",
            submissionId: order.submissionId ?? "",
            ownerType: order.ownerType!
          });

          setIsLoaderActive(false);

          if (shouldPush) {
            if (
              order.productType === "BrokerageAccount" &&
              FeatureFlagService.instance.isEnabled(FeatureFlags.LowTaxBrokerCustomerOverview)
            ) {
              push(ClientUrls.LOW_TAX_BROKER);
            } else {
              if (
                !finalizeOrderResponse ||
                finalizeOrderResponse?.data?.finalizeLTBOrder?.Status === "Success"
              ) {
                push(`/client/entities/${order.entityId}/general-info?order-completed`);
              }
            }
          }

          if (
            finalizeOrderResponse?.data?.finalizeLTBOrder?.Status === "Error" ||
            finalizeOrderResponse?.data?.finalizeLTBOrder?.Status === "Already processed"
          ) {
            setSubmissionErrors(finalizeOrderResponse?.data?.finalizeLTBOrder?.Errors!);
            reject();
            return;
          }

          DataLayer.OrderCompleted({
            ordered_products: {
              [OrderEventProductType.RIDE_BROKER]: {
                product: OrderEventProductType.RIDE_BROKER,
                quantity: 1,
                price: 0
              }
            }
          });

          resolve();
          return;
        }

        if (nextChapter.parent) {
          if (shouldPush) {
            push(
              `/client/order/generic/${nextChapter.parent.key}/${nextChapter.positionalIndex}/${nextStep?.index}/${order.id}/${order.submissionId}`
            );
          }
          resolve();
          return;
        }

        if (shouldPush) {
          push(
            `/client/order/generic/${nextChapter.key}/${nextStep?.index}/${order.id}/${order.submissionId}`
          );
        }
        resolve();
      } catch (e) {
        const showRideBrokerUnderMantainance = FeatureFlagService.instance.isEnabled(
          FeatureFlags.ShowRideBrokerUnderMaintenance
        );

        if (
          showRideBrokerUnderMantainance &&
          e.message.toString().includes(ErrorCodes.IBKR_COMMUNICATION_FAILURE)
        ) {
          push(`/client/ride-broker/internal-server-error/${order.entityId}`);
        } else {
          setErrorModalBody(e);
          setErrorModalVisible(true);
          setSaveDataFunctions({ resolve: resolve, reject: reject });
          setSaveDataCopy({ data, shouldSave, shouldNotPush });
          setIsLoaderActive(false);
          logger.error(e);
        }
      }
    };

  const saveData = async (data: any, shouldNotPush = false, shouldSave = true) => {
    return new Promise<void>(saveDataExecutor(data, shouldNotPush, shouldSave));
  };

  const retry = async () => {
    await saveDataExecutor(
      saveDataCopy.data,
      saveDataCopy.shouldNotPush,
      saveDataCopy.shouldSave
    )(saveDataFunctions.resolve, saveDataFunctions.reject);
  };

  const changeChapter = (chapterKey: string) => {
    push(`/client/order/generic/${chapterKey}/0/${order.id}/${order.submissionId}`);
  };

  const changeStep = (increment: number) => {
    push(
      `/client/order/generic/${chapter}/${stepIndex + increment}/${order.id}/${order.submissionId}`
    );
  };

  const changeSubChapterStep = (increment: number) => {
    push(
      `/client/order/generic/${chapter}/${subChapterIndex}/${stepIndex + increment}/${order.id}/${
        order.submissionId
      }`
    );
  };

  return (
    <>
      {submissionErrors.length > 0 && (
        <RideModal
          data-testid="submission-errors"
          title={t("generic:ibkr-submission-error-modal.title")}
          onClose={() => setSubmissionErrors([])}>
          <RideModalBody>
            <RideAlertBar
              type={AlertType.ERROR}
              title={t("generic:ibkr-submission-error-modal.subtitle")}
              message={
                <ul>
                  {submissionErrors.map((error, index) => {
                    return (
                      <li
                        className={`${module}__application-errors__list-item`}
                        data-testid={"submission-error-" + index}>
                        {error}
                      </li>
                    );
                  })}
                </ul>
              }
            />
          </RideModalBody>
        </RideModal>
      )}

      <ConfirmationModal
        title={
          errorMessage === "generic:error-modal.network-error-message"
            ? t("generic:error-modal.network-error")
            : t("generic:error-modal.title")
        }
        message={
          errorMessage === "GENERIC_ERROR" ? (
            <Trans
              i18nKey={"generic:error-modal.internal-error-message"}
              components={{
                EmailLink: (
                  <a data-testid="contact-email" href={"mailto:clientservices@ride.capital"}>
                    {"clientservices@ride.capital"}
                  </a>
                ),
                PhoneLink: (
                  <a data-testid="contact-phone" href={"tel:+4930726216919"}>
                    {"+4930726216919"}
                  </a>
                )
              }}
            />
          ) : (
            t(errorMessage)
          )
        }
        type={ConfirmationModalType.REGULAR}
        confirmLabel={t("generic:retry")}
        visible={errorModalVisible}
        onConfirm={() => {
          retry();
        }}
        onClose={() => {
          setErrorModalVisible(false);
          setSaveDataFunctions({ resolve: null, reject: null });
          setSaveDataCopy({ data: null, shouldSave: true, shouldNotPush: false });
        }}
      />
      {SubChapterContent && (
        <SubchapterOrderLayout
          stepDisposition={subChapterStep?.disposition}
          totalSteps={subChapterSteps?.length ?? 0}
          onCloseModal={() => {
            push(
              `/client/order/generic/${chapter}/${step?.index ?? 0}/${order.id}/${
                order.submissionId
              }`
            );
          }}
          confirmCancelModalRef={confirmCancelModalRef}
          title={t(`generic:company-setup.chapters.${chapter}`)}
          Content={
            <SubChapterContent
              order={order}
              saveData={saveData}
              currentStep={subChapterStep!}
              confirmCancelModalRef={confirmCancelModalRef}
              controls={
                <StepNavigationControls
                  changeStep={changeSubChapterStep}
                  stepIndex={stepIndex}
                  order={order}
                  saveData={saveData}
                  className={`${module}__step-navigation-controls--modal-control-area`}
                />
              }
            />
          }
        />
      )}
      <RideFormLayout
        title={title}
        closePageHandler={onCancel}
        leftSidebarContent={
          <ChaptersSidebar
            onChapterClick={changeChapter}
            title={t(rideBrokerT(getOrderFlowInfo(order).titleSidebar))}
            chapters={chapters}
          />
        }
        rightSidebarContent={
          <Fragment>
            {Object.values(config.alerts ?? {}).map(
              ({ shouldShow, component: AlertComponent }) => shouldShow(order) && <AlertComponent />
            )}
            <ChaptersContextualHelpWrapper
              stepKey={stepKey}
              useExternalContentProvider={useExternalContentProvider}
              suffix={config.contextualHelpSuffix}
            />
          </Fragment>
        }>
        <div className={`${module}__content-wrapper`}>
          <div className={`${module}__content`}>
            <div className={`${module}__content-inner`}>
              {shouldHideProgressBar ? undefined : (
                <ChaptersProgressBar
                  title={progressBarProps.title}
                  currentStep={progressBarProps.currentStep}
                  totalSteps={progressBarProps.totalSteps}
                />
              )}
              <div className={`${module}__content-step`}>
                <Content
                  order={order}
                  saveData={saveData}
                  currentStep={step}
                  controls={
                    <StepNavigationControls
                      changeStep={changeStep}
                      stepIndex={stepIndex}
                      order={order}
                      title={chapter === "review-and-submit" ? t(`generic:submit`) : undefined}
                      isLoaderActive={chapter === "review-and-submit" ? isLoaderActive : undefined}
                      saveData={saveData}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </RideFormLayout>
    </>
  );
};

export default withTranslationReady(["generic", "errorCodes"])(ChaptersOrderLayout);
