import React from "react";
import { useMutation } from "react-apollo";
import FileUploaded from "./FileUploaded";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { DELETE_STORAGE_NODE } from "lib/api/mutations/DELETE_STORAGE_NODE";
import { StorageNodesEntityType } from "global-query-types";
import {
  DeleteStorageNode,
  DeleteStorageNodeVariables
} from "lib/api/mutations/graphql/DeleteStorageNode";

export interface FileUploadedContainerProps {
  folderId: string;
  fileId: string;
  name: string;
  onDelete: () => Promise<void>;
  entityType?: StorageNodesEntityType;
  entityId?: string;
}

export const FileUploadedContainer = ({
  folderId,
  fileId,
  name,
  onDelete,
  entityType,
  entityId
}: FileUploadedContainerProps) => {
  const [deleteStorageNodeMutation, deleteStorageNodeRequest] = useMutation<
    DeleteStorageNode,
    DeleteStorageNodeVariables
  >(DELETE_STORAGE_NODE);

  const handleDelete = async () => {
    await deleteStorageNodeMutation({ variables: { folderId, fileId, entityType, entityId } });
    await onDelete();
  };

  return (
    <RequestFeedbackHandler requests={[deleteStorageNodeRequest]}>
      <FileUploaded name={name} onDelete={handleDelete} />
    </RequestFeedbackHandler>
  );
};
