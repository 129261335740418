import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ThirdPartyTaxAdvisorsCustomersContainer from "../../components/TaxAdvisorThirdPartyTaxAdvisorCustomers/TaxAdvisorThirdPartyTaxAdvisorCustomersContainer";
import RideOverview from "../../../sharedComponents/RideOverview/RideOverview";

const ThirdPartyTaxAdvisorsCustomersPage = ({ t }: WithTranslation) => {
  return (
    <RideOverview
      imageSrc={"/assets/tax-advisor/header-icon.svg"}
      title={t("thirdPartyPartners.overview.title")}
      subtitle={t("thirdPartyPartners.overview.subtitle")}>
      <ThirdPartyTaxAdvisorsCustomersContainer />
    </RideOverview>
  );
};

export default withTranslation("tax-advisor")(ThirdPartyTaxAdvisorsCustomersPage);
