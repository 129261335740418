import React, { PropsWithChildren } from "react";
import { ErrorIcon } from "sharedComponents/icons/ErrorIcon";
import { WarningIcon } from "sharedComponents/icons/WarningIcon";
import { SuccessIcon } from "../icons/SuccessIcon";

export enum AlertType {
  warning = "WARNING",
  error = "ERROR",
  success = "SUCCESS"
}

export interface AlertMessageProps extends PropsWithChildren<{}> {
  type?: AlertType;
  "data-testid"?: string;
}

export const AlertMessage = (props: AlertMessageProps) => {
  const { type = AlertType.error, children } = props;

  return (
    <div
      className={`alert-message alert-message--${type}`}
      data-testid={props["data-testid"] ?? ""}>
      <div className="alert-message__icon">
        {type === AlertType.error && (
          <span
            className="alert-message__icon-alert alert-message__icon"
            data-testid={`${props["data-testid"]}-icon-error`}>
            <ErrorIcon />
          </span>
        )}

        {type === AlertType.warning && (
          <span
            className="alert-message__icon-warning alert-message__icon"
            data-testid={`${props["data-testid"]}-icon-warning`}>
            <WarningIcon />
          </span>
        )}

        {type === AlertType.success && (
          <span
            className="alert-message__icon"
            data-testid={`${props["data-testid"]}-icon-success`}>
            <SuccessIcon />
          </span>
        )}
      </div>

      <div className="alert-message__content ">{children}</div>
    </div>
  );
};
