import gql from "graphql-tag";
import {
  getThirdPartyTaxAdvisorCompanies,
  getThirdPartyTaxAdvisorCompaniesVariables
} from "./graphql/getThirdPartyTaxAdvisorCompanies";
import { useQuery } from "react-apollo";

export const GET_THIRD_PARTY_TAX_ADVISOR_COMPANIES = gql`
  query getThirdPartyTaxAdvisorCompanies($partnerTaxAdvisorId: String!) {
    getThirdPartyTaxAdvisorCompanies(partnerTaxAdvisorId: $partnerTaxAdvisorId) {
      id
      name
      legalForm
      businessAddress {
        country
        careOf
        city
        street
        postalCode
      }
    }
  }
`;

export const GetThirdPartyTaxAdvisorCompanies = (partnerTaxAdvisorId: string) =>
  useQuery<getThirdPartyTaxAdvisorCompanies, getThirdPartyTaxAdvisorCompaniesVariables>(
    GET_THIRD_PARTY_TAX_ADVISOR_COMPANIES,
    { variables: { partnerTaxAdvisorId } }
  );
