import React, { PropsWithChildren } from "react";
import { RideTableCellAlignment } from "../RideTableCell/RideTableCell";

type RideTableCellMultiSlotProps = PropsWithChildren<{
  alignment?: RideTableCellAlignment;
  classes?: string;
  dataTestId?: string;
}>;

const RideTableCellMultiSlot = ({
  children,
  classes = "",
  alignment = RideTableCellAlignment.LEFT,
  dataTestId = "ride-table-cell-multi-slot"
}: RideTableCellMultiSlotProps) => {
  return (
    <div
      className={`ride-table-cell-multi-slot ride-table-cell-multi-slot--aligned-${alignment} ${classes}`}
      data-testid={dataTestId}>
      {children}
    </div>
  );
};

export default RideTableCellMultiSlot;
