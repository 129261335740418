import config from "../../config/trader-gmbh.json";
import tradeConfig from "../../config/trader-gmbh.json";
import adminServiceConfig from "../../config/admin-service.json";
import { FlowTypeEnum, PackageTypeEnum } from "../../global-query-types";
import { flowType } from "../../components/public/CompanyCreationFlowType/currentFlowType";
import investorConfig from "../../config/investor-gmbh.json";
import miniHoldingConfig from "../../config/mini-holding.json";
import holdingConfig from "../../config/holding.json";
import realEstateConfig from "../../config/real-estate-gmbh.json";
import gmbhConfig from "../../config/gmbh.json";
import adminServiceNextSteps from "../../config/admin-service-next-steps.json";

export const traderGmbHConfig = config;

const adminServicePackageDescription = adminServiceConfig;

export const getCompanyNameSuffixesByFlowType = (currentFlowType: FlowTypeEnum) => {
  if (currentFlowType === flowType.FOUNDER) {
    return miniHoldingConfig.companyNameSuffixes;
  }

  if (currentFlowType === flowType.INVESTOR) {
    return investorConfig.companyNameSuffixes;
  }

  if (currentFlowType === flowType.TRADER) {
    return tradeConfig.companyNameSuffixes;
  }

  if (currentFlowType === flowType.REAL_ESTATE) {
    return realEstateConfig.companyNameSuffixes;
  }

  if (currentFlowType === flowType.HOLDING) {
    return holdingConfig.companyNameSuffixes;
  }

  return gmbhConfig.companyNameSuffixes;
};

export const getPackageDetailsByPackageType = (packageType: PackageTypeEnum) => {
  if (packageType === PackageTypeEnum.INVESTOR) {
    return adminServicePackageDescription.investorListItems;
  }

  if (packageType === PackageTypeEnum.TRADER) {
    return adminServicePackageDescription.traderListItems;
  }

  if (packageType === PackageTypeEnum.PAPER_ASSETS) {
    return adminServicePackageDescription.paperAssetsListItems;
  }

  if (packageType === PackageTypeEnum.REAL_ESTATE) {
    return adminServicePackageDescription.realEstateListItems;
  }

  return gmbhConfig.companyNameSuffixes;
};

export const getPackageTypeTitle = (packageType: PackageTypeEnum) =>
  `admin-service:package-main-title.${packageType}`;

export const getAdminServiceNextSteps = (): string[] =>
  adminServiceNextSteps.adminServiceOrderListItems;

export const getOrderPackageAndPrice = (packageType: PackageTypeEnum) => ({
  ordering: `admin-service:ordering-for.${packageType}`,
  price: `admin-service:price.${packageType}`
});

export const DATE_FORMAT = "dd.MM.yyyy";

export const MOMENT_DATE_FORMAT = "DD.MM.YYYY";
