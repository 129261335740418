import React from "react";
import { GmbH } from "lib/models/client/GmbH";
import { FlowTypeEnum } from "global-query-types";

const FLOW_TYPE_TO_ICON_MAP = {
  default: "generic",
  [FlowTypeEnum.TRADER_HOLDING]: "stocks",
  [FlowTypeEnum.INVESTOR_HOLDING]: "stocks",
  [FlowTypeEnum.HOLDING_GMBH]: "holding",
  [FlowTypeEnum.REAL_ESTATE_GMBH]: "real-estate"
};

export const CompanyIcon = ({ gmbh }: { gmbh: GmbH }) => {
  let modifier = FLOW_TYPE_TO_ICON_MAP[gmbh.type() ?? ""] ?? FLOW_TYPE_TO_ICON_MAP.default;

  return (
    <div
      data-testid="company-icon"
      className={`gmbh-tile__company-icon gmbh-tile__company-icon--${modifier}`}
    />
  );
};
