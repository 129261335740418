import React from "react";
import { ApolloQueryResult } from "apollo-client";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "lib/api/queries/graphql/NomineeCompanyDetails";
import { UpdatePayrollInvoiceDataByCompany } from "lib/api/mutations/UPDATE_PAYROLL_INVOICE_DATA_BY_COMPANY";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import PayrollServiceSection, { PayrollInvoiceData } from "./PayrollServiceSection";

interface PayrollServiceSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
  refetch: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

const PayrollServiceSectionContainer = ({
  company,
  refetch
}: PayrollServiceSectionContainerProps) => {
  const [updatePayrollInvoiceDataByCompanyMutation, updatePayrollInvoiceDataByCompanyRequest] =
    UpdatePayrollInvoiceDataByCompany();

  const onSubmit = async (values: PayrollInvoiceData) => {
    await updatePayrollInvoiceDataByCompanyMutation({
      variables: { ...values, companyId: company.id }
    });
    await refetch();
  };

  return (
    <RequestFeedbackHandler
      requests={[updatePayrollInvoiceDataByCompanyRequest]}
      noLoadingAnimation>
      <PayrollServiceSection payrollInvoiceData={company.payrollInvoiceData} onSubmit={onSubmit} />
    </RequestFeedbackHandler>
  );
};

export default PayrollServiceSectionContainer;
