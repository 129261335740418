import React, { PropsWithChildren } from "react";

interface CardContainerProps extends PropsWithChildren<{}> {
  title?: string;
  className?: string;
}

export const Panel = ({ title, children, className = "" }: CardContainerProps) => {
  return (
    <div className={`panel ${className}`}>
      {title && <h3 className="panel__title">{title}</h3>}
      {children}
    </div>
  );
};
