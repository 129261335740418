import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { RideCurrencyInput } from "uiLibrary/components/RideCurrencyInput/RideCurrencyInput";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import * as Yup from "yup";
import { requiredPositiveNumber } from "../../../../../../../lib/validation/requiredPositiveNumber";

const EstimatedAnnualIncomeStep = ({
  t,
  order,
  currentStep,
  saveData,
  controls
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  async function onSubmit(values) {
    currentStep.complete();

    await saveData({
      ...order.extra,
      estimatedAnnualIncome: values["estimated-annual-income"],
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {
      "estimated-annual-income": order.extra.estimatedAnnualIncome
    },
    onSubmit,
    validationSchema: Yup.object({
      "estimated-annual-income": requiredPositiveNumber(t)
    })
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.estimated-annual-income.title")} />
        <RideCurrencyInput
          name="estimated-annual-income"
          label={t("generic:estimated-annual-income")}
        />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(EstimatedAnnualIncomeStep);
