import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { WithTranslation } from "react-i18next";
import { RideCurrencyInput } from "uiLibrary/components/RideCurrencyInput/RideCurrencyInput";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import * as Yup from "yup";
import { requiredPositiveNumber } from "lib/validation/requiredPositiveNumber";
import { MINIMUM_LIQUID_NET_WORTH_IBKR_REQUIRED } from "lib/validation/LowTaxBroker/tradingPermissionsHelper";
import { AssetTypes } from "lib/types/types";

export const CurrentLiquidNetWorthStep = withTranslationReady(["generic"])(
  ({
    order,
    currentStep,
    saveData,
    controls,
    t
  }: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
    async function onSubmit(values) {
      currentStep.complete();

      let assetExperiences = order.extra.assetExperiences;

      if (+values.currentLiquidNetWorth < MINIMUM_LIQUID_NET_WORTH_IBKR_REQUIRED) {
        assetExperiences = {
          ...order.extra.assetExperiences,
          [AssetTypes.OPTIONS]: undefined,
          [AssetTypes.CFD]: undefined,
          [AssetTypes.FUTURES]: undefined
        };
      }

      const estimatedTotalAssetsStep = currentStep.chapter.getStepByKey(
        "compliance-estimated-total-assets"
      );
      if (
        estimatedTotalAssetsStep &&
        values.currentLiquidNetWorth !== order.extra.currentLiquidNetWorth
      ) {
        estimatedTotalAssetsStep.reOpen();
      }

      await saveData(
        {
          ...order.extra,
          currentLiquidNetWorth: values.currentLiquidNetWorth,
          assetExperiences,
          ...currentStep.chapter.order.serialize()
        },
        false
      );
    }
    const formik = useRideFormik({
      initialValues: {
        currentLiquidNetWorth: order?.extra?.currentLiquidNetWorth
      },
      onSubmit,
      validationSchema: Yup.object({
        currentLiquidNetWorth: requiredPositiveNumber(t)
      })
    });

    return (
      <div data-testid="current-liquid-net-worth-step">
        <FormikProvider value={formik}>
          <Form>
            <CurrentLiquidNetWorth />
            {controls}
          </Form>
        </FormikProvider>
      </div>
    );
  }
);

const CurrentLiquidNetWorth = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div data-testid="current-liquid-net-worth">
      <StepHeading text={t("generic:ride-broker-onboarding.current-liquid-net-worth.title")} />
      <RideCurrencyInput name="currentLiquidNetWorth" label={t("generic:liquid-net-worth")} />
    </div>
  );
});
