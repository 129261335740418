import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import {
  UpdateCompanyById,
  UpdateCompanyByIdVariables
} from "../../../lib/api/mutations/graphql/UpdateCompanyById";
import { UPDATE_COMPANY_BY_ID } from "../../../lib/api/mutations/UPDATE_COMPANY_BY_ID";
import logger from "../../../common/Logger";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import FiscalYearSection from "./FiscalYearSection";
import { formatFiscalYear, transformFiscalYearToMoment } from "../../../common/formatters";
import { QueryCompanyById } from "../../../lib/api/queries/GetCompanyById";

const FiscalYearSectionContainer = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const getRequest = QueryCompanyById(id);
  const [updateCompanyById, updateCompanyByIdRequest] = useMutation<
    UpdateCompanyById,
    UpdateCompanyByIdVariables
  >(UPDATE_COMPANY_BY_ID);
  const company = getRequest.data?.companyById;
  const fiscalYear = company?.fiscalYear;

  const [fiscalYearStart, setFiscalYearStart] = useState<string | null>(null);
  const [fiscalYearEnd, setFiscalYearEnd] = useState<string | null>(null);

  useEffect(() => {
    if (fiscalYear) {
      setFiscalYearStart(transformFiscalYearToMoment(fiscalYear).toISOString());
      setFiscalYearEnd(transformFiscalYearToMoment(fiscalYear).add(-1, "days").toISOString());
    }
  }, [fiscalYear]);

  if (!company) return null;

  const selectDateHandler = (date: string) => {
    setFiscalYearStart(date);
    setFiscalYearEnd(moment(date).add(-1, "days").toISOString());
  };

  const saveHandler = () => {
    updateCompanyById({
      variables: {
        companyId: id,
        fiscalYear: formatFiscalYear(fiscalYearStart)
      }
    })
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        logger.errorMessage("Company update not successful");
        logger.error(error);
      });
  };

  return (
    <RequestFeedbackHandler requests={[updateCompanyByIdRequest]} noLoadingAnimation>
      <FiscalYearSection
        onSelectDate={selectDateHandler}
        onSave={saveHandler}
        fiscalYearStart={fiscalYearStart}
        fiscalYearEnd={fiscalYearEnd}
        company={company}
      />
    </RequestFeedbackHandler>
  );
};

export default FiscalYearSectionContainer;
