import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { search, searchVariables } from "./graphql/search";

export const SEARCH = gql`
  query search($q: String, $type: SearchTypeEnum!) {
    search(q: $q, type: $type) {
      id
      result
      type
    }
  }
`;

export const Search = (variables: searchVariables) =>
  useQuery<search, searchVariables>(SEARCH, { variables });
