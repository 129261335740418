import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";
import { IBKRFileSubmission, PendingTaskSwitcherProps } from "../PendingTaskSwitcher";
import { FileUploadArea } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/FileUploadArea/FileUploadArea";
import { FileTooLargeError, InvalidFileTypeError } from "lib/adapters/RideFileStorage/FileStorage";
import { FileType } from "lib/ports/FileStorage";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { CheckIcon } from "sharedComponents/icons/DesignSystemIcons/CheckIcon";
import { getUploadDocumentExtraRequirements } from "lib/types/PendingTask";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { RideDatePicker } from "uiLibrary/v2/components/RideDatePicker/RideDatePicker";
import * as Yup from "yup";
import {
  DropdownSelectOption,
  RideDropdownSelect
} from "../../../../../../uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import {
  AlertMessage,
  AlertType
} from "../../../../../../sharedComponents/AlertMessage/AlertMessage";

const UploadDocumentPendingTask = ({
  t,
  task,
  isTaskCompleted,
  completeTask,
  handleSubmitFile,
  fileUploadConstraints
}: PendingTaskSwitcherProps & WithTranslation) => {
  const [file, setFile] = useState<File | undefined>();
  const [error, setError] = useState<string | undefined>();

  const extraInformationRequired = getUploadDocumentExtraRequirements(task.formNumber ?? "");

  let documentTypeOptions: DropdownSelectOption[] = [];
  if (extraInformationRequired) {
    extraInformationRequired.documentTypes.forEach((doc) => {
      documentTypeOptions.push({
        label: doc,
        value: doc
      });
    });
  }

  const formik = useRideFormik({
    initialValues: { expirationDate: undefined, documentType: undefined },
    onSubmit: () => submitFile(),
    validationSchema: () => {
      return Yup.object().shape({
        expirationDate: extraInformationRequired?.requiresExpirationDate
          ? Yup.string()
              .required(t("generic:validation-required"))
              .test("expirationDate", t("generic:validation-future-date"), (value) => {
                return new Date(value!) > new Date();
              })
          : Yup.string().notRequired(),
        documentType: !!extraInformationRequired
          ? Yup.string().required(t("generic:validation-required"))
          : Yup.string().notRequired()
      });
    }
  });

  const { maxFileSizeInBytes = 0 } = fileUploadConstraints ?? {};
  const uploadFile = (file: File) => {
    if (file.size > maxFileSizeInBytes) {
      throw new FileTooLargeError(maxFileSizeInBytes, file.size);
    }

    const allowedFileTypes = Object.values(FileType);
    if (!allowedFileTypes.includes(file.type as FileType)) {
      throw new InvalidFileTypeError(allowedFileTypes, file.type as FileType);
    }

    setFile(file);
    setError(undefined);
  };

  const submitFile = async () => {
    if (file) {
      try {
        let params: IBKRFileSubmission = { file, formNumber: task?.formNumber ?? "" };
        if (formik.values["expirationDate"])
          params.expirationDate = formik.values["expirationDate"];
        if (formik.values["documentType"])
          params[extraInformationRequired?.parameterName ?? "documentType"] =
            formik.values["documentType"];

        await handleSubmitFile?.(params);
        await completeTask();
      } catch (e) {
        setError(e.message ?? "");
      }
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <CardContainer data-testid="upload-document" className="v2-card-container p-4">
          <div className="upload-document-pending-task__header">
            <Typography
              data-testid="upload-document-title"
              category="Paragraph"
              size={100}
              weight="Heavy">
              {t("generic:pending-tasks.provide-copy-of")}
            </Typography>
            <Typography
              data-testid="document-name"
              category="Paragraph"
              size={100}
              weight="Light"
              className="upload-document-pending-task__secondary-text">
              • {task.formName}
            </Typography>

            {extraInformationRequired && (
              <RideDropdownSelect
                name={"documentType"}
                options={documentTypeOptions}
                label={t("generic:low-tax-broker-order.file-section.document-type")}
                className="ride-form-v2__field upload-document-pending-task__document-type"
              />
            )}
            {extraInformationRequired?.requiresExpirationDate && (
              <RideDatePicker label={t("generic:expiration-date")} name="expirationDate" />
            )}

            <Typography
              className={`${
                extraInformationRequired ? "upload-document-pending-task__requirements" : ""
              }`}
              data-testid="requirements-title"
              category="Paragraph"
              size={200}
              weight="Heavy">
              {t("generic:ride-broker-onboarding.documents.upload-file.requirements.title")}
            </Typography>
            <Typography
              data-testid="requirements"
              category="Paragraph"
              size={200}
              weight="Light"
              className="upload-document-pending-task__secondary-text">
              {t("generic:pending-tasks.file-upload-requirements")}
            </Typography>
          </div>
          {isTaskCompleted ? (
            <div className="download-and-agree-pending-task__already-accepted">
              <CheckIcon className="download-and-agree-pending-task__check-icon" />
              <Typography
                category={"Paragraph"}
                size={100}
                weight={"Light"}
                data-testid={"document-submitted"}>
                {t("generic:document-submitted", {
                  documentName: task.formName
                })}
              </Typography>
            </div>
          ) : (
            <>
              <FileUploadArea
                uploadFile={async (file) => uploadFile(file)}
                browserFileInsideDraggableArea={true}
              />
              <button
                data-testid="submit-document"
                className="ltb-overview__primary-action-btn download-fill-and-upload-pending-task__submit"
                type="submit"
                disabled={
                  !file ||
                  (extraInformationRequired?.requiresExpirationDate &&
                    !formik.values["expirationDate"]) ||
                  (extraInformationRequired && !formik.values["documentType"])
                }>
                {t("generic:submit-document")}
              </button>
              {error && (
                <AlertMessage type={AlertType.error} data-testid="error-alert">
                  {error}
                </AlertMessage>
              )}
            </>
          )}
        </CardContainer>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(UploadDocumentPendingTask);
