import React from "react";
import SelectGmbh from "client/components/Banking/SelectGmbh/SelectGmbh";
import { ClientSelectOption } from "../../ClientSelect/ClientSelect";
import { useHistory } from "react-router";
import { GetMyGmbHs } from "components/client/MyGmbHs/Queries";

const SelectGmbhContainer = () => {
  const history = useHistory();
  const myGmbHsRequest = GetMyGmbHs();
  const onCompanySelect = (companyId: string) => {
    history.replace(`${companyId}/new`);
  };

  const companies = myGmbHsRequest.data?.companies ?? [];

  const options: ClientSelectOption[] =
    companies?.map((company) => {
      return {
        label: company.name,
        value: company.id
      };
    }) ?? [];

  return <SelectGmbh options={options} onCompanySelect={onCompanySelect} />;
};

export default SelectGmbhContainer;
