import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import ChaptersOrderLayout from "./ChaptersOrderLayout";
import { fakeContextualHelp } from "../ChaptersContextualHelp/exampleData";
import { UpsertOrder, UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { FinalizeOrder, FinalizeOrderVariables } from "lib/api/mutations/graphql/FinalizeOrder";
import { useMutation } from "@apollo/react-hooks";
import { FINALIZE_ORDER } from "lib/api/mutations/FINALIZE_ORDER";
import { useHistory, useParams } from "react-router-dom";
import { GetOrder } from "lib/api/queries/GetOrder";
import { AlertMessage, AlertType } from "sharedComponents/AlertMessage/AlertMessage";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import { getOrderFlowInfo } from "lib/models/client/order/orderConfig";
import { FINALIZE_LTB_ORDER } from "../../../lib/api/mutations/FINALIZE_LTB_ORDER";
import {
  FinalizeLTBOrder,
  FinalizeLTBOrderVariables
} from "../../../lib/api/mutations/graphql/FinalizeLTBOrder";

// TODO: we can't really use a RequestFeedbackHandler at this level, need to creatively problem solve something else

interface ChaptersOrderLayoutContainerProps extends WithTranslation {}

const ChaptersOrderLayoutContainer = ({ t }: ChaptersOrderLayoutContainerProps) => {
  const { orderId, submissionId } = useParams<{ orderId: string; submissionId: string }>();
  const { push } = useHistory();

  const getOrderRequest = GetOrder(orderId, submissionId);
  const order = getOrderRequest?.data?.getOrder;

  const [upsertOrderCall, upsertOrderRequest] = useMutation<UpsertOrder, UpsertOrderVariables>(
    UPSERT_ORDER
  );
  const [finalizeOrderCall, finalizeOrderRequest] = useMutation<
    FinalizeOrder,
    FinalizeOrderVariables
  >(FINALIZE_ORDER);
  const [finalizeLtbOrderCall] = useMutation<FinalizeLTBOrder, FinalizeLTBOrderVariables>(
    FINALIZE_LTB_ORDER
  );

  if (!order) {
    return <AlertMessage type={AlertType.error}>{t("generic:order-not-found")}</AlertMessage>;
  }

  const upsertOrder = async (variables: UpsertOrderVariables) => {
    await upsertOrderCall({ variables });
    await getOrderRequest.refetch();
  };

  const finalizeOrder = async (variables: FinalizeOrderVariables) => {
    if (order.extra?.isNewBrokerEnabled) {
      return await finalizeLtbOrderCall({
        variables: {
          id: variables.id,
          submissionId: variables.submissionId,
          ownerType: variables.ownerType
        }
      });
    } else {
      await finalizeOrderCall({ variables });
    }
  };

  const orderConfig = getOrderFlowInfo(order);
  return (
    <RequestFeedbackHandler
      requests={[getOrderRequest, upsertOrderRequest, finalizeOrderRequest]}
      showChildrenWhileLoading={true}
      hideErrors={true}>
      <ChaptersOrderLayout
        order={order}
        title={t(rideBrokerT(orderConfig.title))}
        contextualHelp={fakeContextualHelp}
        config={orderConfig.config}
        onCancel={() => {
          push(
            order?.productType === "BrokerageAccount"
              ? `/client/entities/${order?.entityId}/invest`
              : `/client/entities/${order?.entityId}/general-info`
          );
        }}
        upsertOrder={upsertOrder}
        finalizeOrder={finalizeOrder}
      />
    </RequestFeedbackHandler>
  );
};

export default withTranslationReady("generic")(ChaptersOrderLayoutContainer);
