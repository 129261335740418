import React, { useState } from "react";
import { useParams } from "react-router";
import LowTaxBrokerOrderDetails from "./LowTaxBrokerOrderDetails";
import { useMutation } from "@apollo/react-hooks";
import { UpsertOrder, UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import { SEND_LOW_TAX_BROKER_ORDER_TO_FXFLAT } from "lib/api/mutations/SEND_LOW_TAX_BROKER_ORDER_TO_FXFLAT";
import {
  sendLowTaxBrokerOrderToFxFlat,
  sendLowTaxBrokerOrderToFxFlatVariables
} from "lib/api/mutations/graphql/sendLowTaxBrokerOrderToFxFlat";
import * as Sentry from "@sentry/react";
import { GetLowTaxBrokerOrder } from "lib/api/queries/GetLowTaxBrokerOrder";
import { LeanCompanyDetails } from "lib/models/LeanCompanyDetails";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import {
  CancelLowTaxBrokerOrder,
  CancelLowTaxBrokerOrderVariables
} from "lib/api/mutations/graphql/CancelLowTaxBrokerOrder";
import { CANCEL_LOW_TAX_BROKER_ORDER } from "lib/api/mutations/CANCEL_LOW_TAX_BROKER_ORDER";
import { GENERATE_LOW_TAX_BROKER_MASKED_JSON } from "lib/api/mutations/GENERATE_LOW_TAX_BROKER_MASKED_JSON";
import {
  generateLowTaxBrokerMaskedJson,
  generateLowTaxBrokerMaskedJsonVariables
} from "lib/api/mutations/graphql/generateLowTaxBrokerMaskedJson";
import { SEND_IB_PASSWORD_BY_EMAIL } from "lib/api/mutations/SEND_IB_PASSWORD_BY_EMAIL";
import {
  SendIBPasswordByEmail,
  SendIBPasswordByEmailVariables
} from "lib/api/mutations/graphql/SendIBPasswordByEmail";
import { UpsertBrokerageAccountVariables } from "lib/api/mutations/graphql/UpsertBrokerageAccount";
import { UPSERT_BROKERAGE_ACCOUNT } from "lib/api/mutations/UPSERT_BROKERAGE_ACCOUNT";
import { GetCompanyBrokerageAccounts } from "lib/api/queries/GetCompanyBrokerageAccounts";
import { BrokerBankEnum, OrderProductTypeEnum } from "global-query-types";
import { FileEntityType, getFileStorage } from "lib/ports/FileStorage";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import { CONVERT_LTB_ORDER_TO_RIDE_BROKER } from "lib/api/mutations/CONVERT_LTB_ORDER_TO_RIDE_BROKER";
import { ConvertLTBOrderToRideBrokerVariables } from "lib/api/mutations/graphql/ConvertLTBOrderToRideBroker";
import { UPDATE_IBKR_CREDENTIAL } from "lib/api/mutations/UPDATE_IBKR_CREDENTIAL";
import {
  UpdateIBKRCredential,
  UpdateIBKRCredentialVariables
} from "lib/api/mutations/graphql/UpdateIBKRCredential";
import { GetUserForPerson } from "lib/api/queries/GetUserForPerson";
import { GET_IBKR_ACCOUNT_DETAILS } from "lib/api/queries/GetIBKRAccountDetails";
import { useQuery } from "react-apollo";
import {
  GetIBKRAccountDetails,
  GetIBKRAccountDetailsVariables
} from "lib/api/queries/graphql/GetIBKRAccountDetails";

const LowTaxBrokerOrderDetailsContainer = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const fileStorage = getFileStorage();

  const orderRequest = GetLowTaxBrokerOrder({ orderId });
  const order = orderRequest.data?.getLowTaxBrokerOrder?.order;
  const companyFromLTB = orderRequest.data?.getLowTaxBrokerOrder?.company;
  const tradeCompany = new LeanCompanyDetails(companyFromLTB);

  const accountDetailsRequest = useQuery<GetIBKRAccountDetails, GetIBKRAccountDetailsVariables>(
    GET_IBKR_ACCOUNT_DETAILS,
    {
      variables: { orderId },
      skip: !order?.extra?.ibCredentials?.accountNumber
    }
  );
  const accountDetails = accountDetailsRequest.data?.getIBKRAccountDetails;

  const company = QueryCompanyById(companyFromLTB?.id)?.data?.companyById;
  const addExistingCompanyOrder = company?.orders.find(
    (order) => order.productType === OrderProductTypeEnum.AddExistingCompany
  );
  const companyBrokerageAccountsQuery = GetCompanyBrokerageAccounts(
    order?.entityId,
    BrokerBankEnum.LTB
  );

  const companyBrokerageAccountsQuery2 = GetCompanyBrokerageAccounts(
    order?.entityId,
    BrokerBankEnum.LTB2
  );

  const ownerUserRequest = GetUserForPerson(order?.ownerId);
  const ownerUser = ownerUserRequest?.data?.getPersonDetails?.user;

  const [upsertOrderCall, upsertOrderRequest] = useMutation<UpsertOrder, UpsertOrderVariables>(
    UPSERT_ORDER
  );

  const [upsertBrokerageAccountMutation, upsertBrokerageAccountRequest] =
    useMutation(UPSERT_BROKERAGE_ACCOUNT);

  const [updateIBKRCredentialCall, updateIBKRCredentialRequest] = useMutation<
    UpdateIBKRCredential,
    UpdateIBKRCredentialVariables
  >(UPDATE_IBKR_CREDENTIAL);

  const [convertLTBOrderToRideBrokerMutation, convertLTBOrderToRideBrokerRequest] = useMutation(
    CONVERT_LTB_ORDER_TO_RIDE_BROKER
  );

  const [sendLowTaxBrokerOrderToFxFlatCall, sendLowTaxBrokerOrderToFxFlatRequest] = useMutation<
    sendLowTaxBrokerOrderToFxFlat,
    sendLowTaxBrokerOrderToFxFlatVariables
  >(SEND_LOW_TAX_BROKER_ORDER_TO_FXFLAT);

  const [generateLowTaxBrokerMaskedJsonCall, generateLowTaxBrokerMaskedJsonRequest] = useMutation<
    generateLowTaxBrokerMaskedJson,
    generateLowTaxBrokerMaskedJsonVariables
  >(GENERATE_LOW_TAX_BROKER_MASKED_JSON);

  const [cancelLowTaxBrokerOrderCall, cancelLowTaxBrokerOrderRequest] = useMutation<
    CancelLowTaxBrokerOrder,
    CancelLowTaxBrokerOrderVariables
  >(CANCEL_LOW_TAX_BROKER_ORDER);

  const [sendIBPasswordByEmail, sendIBPasswordByEmailRequest] = useMutation<
    SendIBPasswordByEmail,
    SendIBPasswordByEmailVariables
  >(SEND_IB_PASSWORD_BY_EMAIL);

  function withLoading<T extends any[], R>(handler: (...args: T) => Promise<R>) {
    return async (...args: T): Promise<R | undefined> => {
      setLoading(true);

      try {
        return await handler(...args);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };
  }

  const softDeleteOrder = withLoading(async (orderId: string) => {
    await cancelLowTaxBrokerOrderCall({
      variables: {
        id: orderId
      }
    });
  });

  const sendIBCredentialsEmail = withLoading(
    async ({ orderId, password }: { orderId: string; password: string }) => {
      await sendIBPasswordByEmail({
        variables: {
          orderId,
          password
        }
      });
      await orderRequest.refetch();
    }
  );

  const getSummary = async () => {
    const { id: orderId, extra } = order as unknown as OrderData<LowTaxBrokerOrderData>;

    if (!orderId || !extra?.orderSummaryPdf?.id) {
      return;
    }

    const blob = await fileStorage.getFileForEntity(
      FileEntityType.Order,
      orderId,
      extra.orderSummaryPdf.id
    );

    const url = window.URL.createObjectURL(blob);
    window.open(url);
  };

  const upsertOrder = withLoading(async (variables: UpsertOrderVariables) => {
    await upsertOrderCall({ variables });
    await orderRequest.refetch();
  });

  const upsertBrokerageAccount = withLoading(async (variables: UpsertBrokerageAccountVariables) => {
    await upsertBrokerageAccountMutation({ variables });
    await companyBrokerageAccountsQuery.refetch();
    await companyBrokerageAccountsQuery2.refetch();
  });

  const convertLTBOrderToRideBroker = withLoading(
    async (variables: ConvertLTBOrderToRideBrokerVariables) => {
      await convertLTBOrderToRideBrokerMutation({ variables });
      await orderRequest.refetch();
    }
  );

  const updateIBKRCredential = withLoading(async (variables: UpdateIBKRCredentialVariables) => {
    const result = await updateIBKRCredentialCall({
      variables
    });
    return result.data?.updateIBKRCredential as unknown;
  });

  const uploadFile = withLoading(async (file: File, orderId: string) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(`${getEndpoint()}/api/file/Order/${orderId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      body: formData,
      mode: "cors",
      credentials: "include"
    });

    return await response.json();
  });

  const sendToFxFlat = withLoading(async (orderId: string) => {
    setLoading(true);
    await sendLowTaxBrokerOrderToFxFlatCall({
      variables: {
        orderId: orderId
      }
    });
    await orderRequest.refetch();
  });

  const generateLowTaxBrokerMaskedJsonFn = withLoading(async (orderId: string) => {
    await generateLowTaxBrokerMaskedJsonCall({
      variables: {
        orderId: orderId
      }
    });
  });

  return (
    <RequestFeedbackHandler
      requests={[
        orderRequest,
        upsertOrderRequest,
        ownerUserRequest,
        accountDetailsRequest,
        sendIBPasswordByEmailRequest,
        companyBrokerageAccountsQuery,
        upsertBrokerageAccountRequest,
        updateIBKRCredentialRequest,
        cancelLowTaxBrokerOrderRequest,
        companyBrokerageAccountsQuery2,
        convertLTBOrderToRideBrokerRequest,
        sendLowTaxBrokerOrderToFxFlatRequest,
        generateLowTaxBrokerMaskedJsonRequest
      ]}
      showChildrenWhileLoading>
      <LowTaxBrokerOrderDetails
        softDeleteOrder={softDeleteOrder}
        sendToFxFlat={sendToFxFlat}
        order={order}
        ownerUser={ownerUser}
        accountDetails={accountDetails}
        addExistingCompanyOrder={addExistingCompanyOrder}
        brokerageAccounts={(
          companyBrokerageAccountsQuery.data?.companyBrokerageAccounts ?? []
        ).concat(companyBrokerageAccountsQuery2.data?.companyBrokerageAccounts ?? [])}
        tradeCompany={tradeCompany}
        upsertHandler={upsertOrder}
        updateIBKRCredential={updateIBKRCredential}
        upsertBrokerageAccount={upsertBrokerageAccount}
        uploadFile={uploadFile}
        getSummary={getSummary}
        isLoading={loading}
        generateLowTaxBrokerMaskedJson={generateLowTaxBrokerMaskedJsonFn}
        sendIBPasswordByEmail={sendIBCredentialsEmail}
        convertLTBOrderToRideBroker={convertLTBOrderToRideBroker}
      />
    </RequestFeedbackHandler>
  );
};

export default LowTaxBrokerOrderDetailsContainer;
