import { Form, FormikProvider, useFormik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useMutation } from "react-apollo";
import { Button, Col, Row } from "react-bootstrap";
import logger from "../../../common/Logger";
import { CreateReferralCodeSchema } from "../../../lib/validation/CreateReferralCodeSchema";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { FirstNameField, LastNameField } from "../../../components/nominee/common/PersonFields";
import {
  NomineeCreateReferralCode,
  NomineeCreateReferralCodeVariables
} from "../../../lib/api/mutations/graphql/NomineeCreateReferralCode";
import { NOMINEE_CREATE_REFERRAL_CODE } from "../../../lib/api/mutations/NOMINEE_CREATE_REFERRAL_CODE";
import { ALL_DISCOUNT_CODES } from "../../../lib/api/queries/AllDiscountCodes";

export const CreateReferralCode = ({ t }: { t: TFunction }) => {
  const [callCreateReferralCodeMutation, createReferralCodeRequest] = useMutation<
    NomineeCreateReferralCode,
    NomineeCreateReferralCodeVariables
  >(NOMINEE_CREATE_REFERRAL_CODE, { refetchQueries: [{ query: ALL_DISCOUNT_CODES }] });

  const createReferralCode = async (firstName: string, lastName: string) => {
    try {
      await callCreateReferralCodeMutation({ variables: { firstName, lastName } });
    } catch (error) {
      logger.errorMessage(`Unable to create referral code`);
      logger.error(error);
    }
  };
  return (
    <RequestFeedbackHandler requests={[createReferralCodeRequest]}>
      <CreateReferralCodeComponent t={t} createReferralCode={createReferralCode} />
    </RequestFeedbackHandler>
  );
};

interface CreateReferralCodeComponent {
  t: TFunction;
  createReferralCode: (firstName: string, lastName: string) => Promise<void>;
}

interface inputParams {
  firstName: string;
  lastName: string;
}

export const CreateReferralCodeComponent = ({
  t,
  createReferralCode
}: CreateReferralCodeComponent) => {
  const handleCreateReferralCode = async ({ firstName, lastName }: inputParams) => {
    await createReferralCode(firstName, lastName);
  };

  const formik = useFormik({
    initialValues: { firstName: "", lastName: "" },
    validationSchema: CreateReferralCodeSchema,
    onSubmit: handleCreateReferralCode
  });

  return (
    <div>
      <FormikProvider value={formik} data-testid="discount-code-form">
        <Form>
          <Row>
            <Col xs={12} md={4}>
              <FirstNameField t={t} formik={formik} />
            </Col>
            <Col xs={12} md={4}>
              <LastNameField t={t} formik={formik} />
            </Col>
            <Col xs={12} md={4}>
              <Button data-testid={`submit-button`} type="submit">
                {t("generic:create")}
              </Button>
            </Col>
          </Row>
          <br />
        </Form>
      </FormikProvider>
    </div>
  );
};
