import gql from "graphql-tag";

export const ALL_INVESTMENTS = gql`
  query AllInvestments($limit: Int, $start: Int, $query: String) {
    allInvestments(limit: $limit, start: $start, query: $query) {
      count
      investments {
        id
        share
        investor {
          type
          company {
            id
            name
            legalForm
            contactPerson {
              id
              firstName
              lastName
            }
          }
          person {
            id
            firstName
            lastName
          }
        }
        asset {
          id
          type
          isTokenized
          company {
            id
            name
            legalForm
          }
          realEstate {
            id
            label
          }
        }
      }
    }
  }
`;
