import React, { ReactNode } from "react";

export interface OrderedListProps {
  children?: ReactNode | ReactNode[];
}

export const OrderedList = ({ children }: OrderedListProps) => {
  if (children == null) return null;
  const items = children instanceof Array ? children : [children];
  if (!items.length) return null;
  return (
    <div data-testid="ordered-list" className="ordered-list">
      {items.map((item, index) => (
        <div key={index} className="ordered-list__item">
          <div data-testid="ordered-list-item-order" className="ordered-list__item-order">
            {index + 1}
          </div>
          <div data-testid="ordered-list-item-content" className="ordered-list__item-content">
            {item}
          </div>
        </div>
      ))}
    </div>
  );
};
