import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { getAllAccountants } from "./graphql/getAllAccountants";

const GET_ALL_ACCOUNTANTS = gql`
  query getAllAccountants {
    getAllAccountants {
      id
      email
      confirmedAt
      person {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GetAllAccountantsRequest = () => useQuery<getAllAccountants>(GET_ALL_ACCOUNTANTS);
