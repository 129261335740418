import React from "react";
import { PropsWithChildren } from "react";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

interface BreakpointDownProps extends PropsWithChildren<{}> {
  breakpoint: Breakpoint;
}

export const BreakpointDown = ({ breakpoint, children }: BreakpointDownProps) => {
  return (
    <div data-testid="breakpoint-down" className={`breakpoint-down breakpoint-down--${breakpoint}`}>
      {children}
    </div>
  );
};
