import React from "react";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { UploadFileResponse } from "lib/ports/FileStorage";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import OrderFilesTable, { TypeOfDocument } from "./OrderFilesTable";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { ErrorAlert } from "sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";

export type SupportedFilePaths =
  | "companyDocuments"
  | "shareholders"
  | "employees"
  | "holdingCompanies";

export interface OrderFile {
  path: SupportedFilePaths;
  subPath?: string;
  entityName: string;
  back?: boolean;
  fileKey: string;
  fileId?: string;
  fileName?: string;
  fileSize?: number;
  required: boolean;
  typeOfDocument?: TypeOfDocument;
  validThrough?: string;
  issuingDate?: string;
  issuingCountry?: string;
  readyToSend?: boolean;
}

interface OrderFilesTableContainerProps {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder["order"];
  files: OrderFile[];
  upsertHandler: (data: UpsertOrderVariables) => Promise<void>;
  uploadFile: (file: File, orderId: string) => Promise<UploadFileResponse>;
  isLoading: boolean;
}

const OrderFilesTableContainer = ({
  t,
  order,
  files,
  upsertHandler,
  uploadFile,
  isLoading
}: OrderFilesTableContainerProps & WithTranslation) => {
  if (!order) {
    return <ErrorAlert t={t} error={"Order not found"} />;
  }

  const getFile = async (fileId: string) => {
    try {
      await fetch(`${getEndpoint()}/api/file/Order/${order.id}/${fileId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        mode: "cors",
        credentials: "include"
      }).then((response) => {
        response.arrayBuffer().then((buffer) => {
          const contentType = response.headers.get("Content-Type");
          const blob = new Blob([buffer], { type: contentType ?? "" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
      });
    } catch (err) {
      console.error("Error fetching file ", err);
    }
  };

  return (
    <OrderFilesTable
      order={order}
      files={files}
      upsertHandler={upsertHandler}
      uploadFile={uploadFile}
      isLoading={isLoading}
      previewFile={getFile}
    />
  );
};

export default withTranslationReady([])(OrderFilesTableContainer);
