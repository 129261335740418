import { DocumentNode } from "graphql";
import { Client } from "../../../common/GraphqlClient/GraphqlClient";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import { ApolloQueryResult, NetworkStatus } from "apollo-client";

export type GenericQueryType<T, TVariables> = <T, TVariables>(
  dataKey: keyof T,
  params: GenericQueryParams<TVariables>
) => Promise<GenericQueryResult<NonNullable<T[keyof T]>>>;

type GenericQueryParams<TVariables> = { query: DocumentNode; variables: TVariables };

type GenericQueryResult<T> = { error: Error; type: "error" } | { data: T; type: "data" };

export const genericQuery = async <T, TVariables>(
  dataKey: keyof T,
  params: GenericQueryParams<TVariables>
): Promise<GenericQueryResult<NonNullable<T[keyof T]>>> => {
  let response: ApolloQueryResult<T>;

  try {
    response = await Client.query<T, TVariables>({
      ...params,
      fetchPolicy: "no-cache"
    });
  } catch (error) {
    response = {
      data: {
        [dataKey]: null
      } as unknown as T,
      errors: [error],
      stale: false,
      loading: false,
      networkStatus: NetworkStatus.ready
    };
  }

  if (response.errors && response.errors.length > 0) {
    response.errors.forEach((err) => {
      Sentry.captureException(err);
    });
    return {
      type: "error",
      error: new Error(`GraphQL Errors encountered: ${response.errors.length} errors`)
    };
  }

  const result = response.data[dataKey];
  if (!result) {
    Sentry.captureMessage(`${dataKey}: response.data.${dataKey} is null`, Severity.Error);
    return {
      type: "error",
      error: new Error(`response.data.${dataKey} is null`)
    };
  }

  return {
    type: "data",
    data: result as NonNullable<T[keyof T]>
  };
};
