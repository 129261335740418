import gql from "graphql-tag";

export const CREATE_ACCOUNTING_SERVICE = gql`
  mutation CreateAccountingService($accountantUserId: ID!, $clientCompanyId: ID!) {
    createAccountingService(
      accountantUserId: $accountantUserId
      clientCompanyId: $clientCompanyId
    ) {
      feedback
    }
  }
`;
