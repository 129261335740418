import gql from "graphql-tag";
import {
  getLowTaxBrokerOrder,
  getLowTaxBrokerOrderVariables
} from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { useQuery } from "react-apollo";

export const GET_LOW_TAX_BROKER_ORDER = gql`
  query getLowTaxBrokerOrder($orderId: ID!) {
    getLowTaxBrokerOrder(orderId: $orderId) {
      order {
        id
        productType
        entityId
        ownerId
        extra
        status
        createdAt
        updatedAt
        submissionId
      }
      company {
        id
        name
        legalForm
      }
    }
  }
`;

export const GetLowTaxBrokerOrder = (variables: getLowTaxBrokerOrderVariables) =>
  useQuery<getLowTaxBrokerOrder, getLowTaxBrokerOrderVariables>(GET_LOW_TAX_BROKER_ORDER, {
    variables,
    fetchPolicy: "no-cache"
  });
