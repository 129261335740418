import TagManager from "react-gtm-module";
import { RIDE_FLOWS, RIDE_FLOWS_STEPS } from "../../../flows";

export enum OrderEventProductType {
  GS = "GS",
  AS = "AS",
  LTB = "LTB",
  VG = "VG",
  WS = "WS",
  WD = "WD",
  RIDE_BROKER = "RIDE_BROKER"
}

export type OrderEventPayload = {
  userId?: string | undefined;
  ordered_products: {
    [product: string]: {
      product: OrderEventProductType;
      tier?: string;
      quantity: number;
      price?: number;
    };
  };
  order_summary?: {
    one_time_fee?: number;
    discount_value: number;
    total_price: number;
  };
} & UserPrivateInformation;

type StepCompletedPayload = {
  userId?: string | undefined;
  step_name: RIDE_FLOWS_STEPS;
  flow: RIDE_FLOWS;
} & UserPrivateInformation;

export interface UserPrivateInformation {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  birthDate?: string | null;
}

export class DataLayer {
  static CompleteOrder({ flowType }: { flowType?: string | undefined }) {
    if (this.isGoogleTagManagerDisabled()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "complete_order",
        flow_type: flowType ?? ""
      }
    });
  }

  static FormValidationError({
    formName,
    fieldName,
    errorMessage
  }: {
    formName: string;
    fieldName: string;
    errorMessage: string;
  }) {
    if (this.isGoogleTagManagerDisabled()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "form_validation_error",
        form_name: formName,
        field_name: fieldName,
        error_message: errorMessage
      }
    });
  }

  static Login({
    userId,
    role,
    userData
  }: {
    userId: string;
    role: string;
    userData?: UserPrivateInformation;
  }) {
    if (this.isGoogleTagManagerDisabled()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "login",
        userId,
        role,
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        phone: userData?.phoneNumber
      }
    });
  }

  static OrderStarted({ userId, ordered_products, order_summary, ...rest }: OrderEventPayload) {
    if (this.isGoogleTagManagerDisabled()) return;

    const userParams = !!userId ? { userId, ...rest } : { ...rest };

    TagManager.dataLayer({
      dataLayer: {
        event: "order_started",
        ordered_products,
        order_summary,
        ...userParams
      }
    });
  }

  static OrderCompleted({ userId, ordered_products, order_summary, ...rest }: OrderEventPayload) {
    if (this.isGoogleTagManagerDisabled()) return;

    const userParams = !!userId ? { userId, ...rest } : { ...rest };

    TagManager.dataLayer({
      dataLayer: {
        event: "order_completed",
        ordered_products,
        order_summary,
        ...userParams
      }
    });
  }

  static StepCompleted({ userId, step_name, ...rest }: StepCompletedPayload) {
    if (this.isGoogleTagManagerDisabled()) return;

    const userParams = !!userId ? { userId, ...rest } : { ...rest };

    TagManager.dataLayer({
      dataLayer: {
        event: "step_completed",
        step: step_name,
        ...userParams
      }
    });
  }

  static VirtualPageViewWithinFlow({
    flowType = "",
    stepName
  }: {
    flowType: string | undefined;
    stepName: string;
  }) {
    if (this.isGoogleTagManagerDisabled()) return;

    this.VirtualPageView({ path: `${flowType}/${stepName}` });
  }

  static VirtualPageView({ path }: { path: string }) {
    if (this.isGoogleTagManagerDisabled()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "virtual_page_view",
        page_path: path
      }
    });
  }

  static LeiRegistrationSubmission() {
    if (this.isGoogleTagManagerDisabled()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "lei_registration_submitted"
      }
    });
  }

  private static isGoogleTagManagerDisabled = (): boolean =>
    process.env["REACT_APP_GOOGLE_TAG_MANAGER"] !== "true";
}

class GoogleTagManager {
  static DataLayer = DataLayer;
}

export default GoogleTagManager;
