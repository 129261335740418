import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import ProductCard, { ProductCardCheckboxInput } from "client/components/ProductCard/ProductCard";
import { CompanyFoundingIcon } from "sharedComponents/icons/DesignSystemIcons/CompanyFoundingIcon";
import ProductLearnMoreModal, {
  ProductFeatureProps
} from "client/components/ProductLearnMoreModal/ProductLearnMoreModal";
import { RocketIcon } from "sharedComponents/icons/DesignSystemIcons/RocketIcon";
import { PadlockLockedIcon } from "sharedComponents/icons/DesignSystemIcons/PadlockLockedIcon";
import { EuroIcon } from "sharedComponents/icons/DesignSystemIcons/EuroIcon";
import { Field } from "formik";
import { CurrencyFormatter } from "common/formatters/CurrencyFormatter";
import { shortOrderFormProductPrices } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { useRideField } from "lib/hooks/useRideField";

export interface CompanyFoundingProductCardProps extends WithTranslation {
  inputName: string;
  disabled?: boolean;
}

const CompanyFoundingProductCard = ({
  t,
  inputName,
  disabled
}: CompanyFoundingProductCardProps) => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const field = useRideField(inputName);

  const features: ProductFeatureProps[] = [
    {
      icon: RocketIcon,
      tagline: t("generic:product-card.gmbh-grundung.learn-more-modal.effortless-setup"),
      description: t(
        "generic:product-card.gmbh-grundung.learn-more-modal.effortless-setup-description"
      )
    },
    {
      icon: PadlockLockedIcon,
      tagline: t("generic:product-card.gmbh-grundung.learn-more-modal.simple-secure-fast"),
      description: t(
        "generic:product-card.gmbh-grundung.learn-more-modal.simple-secure-fast-description"
      )
    },
    {
      icon: EuroIcon,
      tagline: t("generic:product-card.gmbh-grundung.learn-more-modal.clear-pricing"),
      description: t(
        "generic:product-card.gmbh-grundung.learn-more-modal.clear-pricing-description"
      )
    }
  ];

  const onClose = () => {
    setShowLearnMoreModal(false);
  };

  const priceFormatted: string = `${new CurrencyFormatter().format(
    shortOrderFormProductPrices.gmbhFounding,
    {
      fixed: 0
    }
  )}`;

  return (
    <>
      {showLearnMoreModal && (
        <ProductLearnMoreModal
          productName={t("generic:product-card.gmbh-grundung.title")}
          description={t("generic:product-card.gmbh-grundung.learn-more-modal.description")}
          price={priceFormatted}
          features={features}
          onSelectProduct={() => {
            field.setValue(true);
            onClose();
          }}
          onClose={onClose}
        />
      )}
      <ProductCard
        dataTestId={"company-founding-service"}
        title={t("generic:product-card.gmbh-grundung.title")}
        description={t("generic:product-card.gmbh-grundung.description")}
        price={priceFormatted}
        icon={<CompanyFoundingIcon size={32} />}
        input={
          <Field
            type="checkbox"
            as={ProductCardCheckboxInput}
            data-testid={"company-founding-service-checkbox"}
            name={inputName}
            disabled={disabled}
          />
        }
        handleLearnMore={() => setShowLearnMoreModal(true)}
        tooltipText={
          field.value && disabled ? t("generic:product-card.gmbh-grundung.tooltip") : undefined
        }
      />
    </>
  );
};

export default withTranslationReady(["generic"])(CompanyFoundingProductCard);
