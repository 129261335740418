import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Client } from "./common/GraphqlClient/GraphqlClient";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppRoot } from "./AppRoot";
import { ScrollToTop } from "./common/ScrollToTop";
import * as Sentry from "@sentry/react";
import { FileUploadServiceContainer } from "./sharedComponents/FileUploadServiceContainer/FileUploadServiceContainer";
import NavigationService from "./lib/services/NavigationService/NavigationService";
import { HubSpotService } from "./lib/services/HubspotService/HubSpotService";
import { ViewportHeightAdjuster } from "common/ViewportHeightAdjuster";

interface AppProps {
  // no props
}

type AppState = {
  // no state
};

const SentryFallback = () => <>"An error has occurred"</>;

class App extends React.Component<AppProps, AppState> {
  render() {
    NavigationService.instance = new NavigationService({});

    HubSpotService.instance = new HubSpotService();

    return (
      <Sentry.ErrorBoundary fallback={<SentryFallback />}>
        <ViewportHeightAdjuster />
        <BrowserRouter>
          <ScrollToTop />
          <ApolloProvider client={Client}>
            <div className="page-container content-wrap">
              <FileUploadServiceContainer />
              <AppRoot />
            </div>
          </ApolloProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
