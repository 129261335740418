import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideReadonlyText } from "uiLibrary/components/RideReadonlyText/RideReadonlyText";
import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { ConfirmationOfAcceptedBusinessActivitiesTradingSchema } from "../../../../../../../lib/validation/ConfirmationOfAcceptedBusinessActivitiesTradingSchema";

const ConfirmationOfAcceptedBusinessActivitiesTradingStep = ({
  order,
  controls,
  saveData,
  currentStep,
  t
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const initialValues = {
    confirmationOfAcceptedBusinessActivitiesTrading:
      order.extra.confirmationOfAcceptedBusinessActivitiesTrading ?? false
  };

  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        confirmationOfAcceptedBusinessActivitiesTrading:
          values.confirmationOfAcceptedBusinessActivitiesTrading,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: ConfirmationOfAcceptedBusinessActivitiesTradingSchema(t)
  });

  return (
    <div data-testid="confirmation-of-accepted-business-activities-trading-step">
      <FormikProvider value={formik}>
        <Form>
          <div
            data-testid="confirmation-of-accepted-business-activities-trading"
            className="confirmation-of-accepted-business-activities-trading">
            <StepHeading
              text={t(
                "generic:ride-broker-onboarding.confirmation-of-accepted-business-activities-trading.title"
              )}
            />
            <RideReadonlyText
              text={t(
                "generic:ride-broker-onboarding.confirmation-of-accepted-business-activities-trading.description"
              )}
            />
            <RideCheckbox
              name="confirmationOfAcceptedBusinessActivitiesTrading"
              label={t(
                "generic:ride-broker-onboarding.confirmation-of-accepted-business-activities-trading.label"
              )}
              className="confirmation-of-accepted-business-activities-trading__accept-checkbox"
            />
          </div>
          {controls}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic"])(
  ConfirmationOfAcceptedBusinessActivitiesTradingStep
);
