import gql from "graphql-tag";

export const SUBMIT_BROKERAGE_SURVEY = gql`
  mutation submitBrokerageSurvey(
    $companyId: ID!
    $answer: String!
    $reason: String
    $selectedBrokerBank: String
    $otherBrokerBank: String
    $otherReason: String
  ) {
    submitBrokerageSurvey(
      companyId: $companyId
      answer: $answer
      reason: $reason
      selectedBrokerBank: $selectedBrokerBank
      otherBrokerBank: $otherBrokerBank
      otherReason: $otherReason
    ) {
      feedback
    }
  }
`;
