import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQueryVariables
} from "./graphql/MyCompanyCreationQuery";
import { ResumeMyCompanyCreationStep } from "./graphql/ResumeMyCompanyCreationStep";
import { MyDiscountCode } from "./graphql/MyDiscountCode";
import { isAuthenticated } from "../../../common/Authentication/isAuthenticated";
import { FlowTypeEnum } from "../../../global-query-types";

export const MY_COMPANY_CREATION_QUERY = gql`
  fragment CompanyDetails on Company {
    __typename
    id
    name
    legalForm
    registration
    registerCourt
    taxNumber
    economicId
    locked
    assets
    businessAddress {
      careOf
      street
      postalCode
      city
      country
    }
    discountCodeUse {
      id
      discountCode {
        id
        code
        referrerValue
        referralValue
      }
      flowType
      status
    }
    group {
      memberships {
        role
        share {
          share
        }
        company {
          id
          name
          legalForm
          registerCourt
          registration
          businessAddress {
            street
            city
            postalCode
          }
        }
        person {
          id
          title
          salutation
          firstName
          lastName
          phoneNumber
          dateOfBirth
          taxNumber
          personalTaxId
          citizenship
          placeOfBirth
          birthName
          locked
          address {
            street
            city
            postalCode
            country
          }
          user {
            id
            email
            isDummyEmail
          }
          address {
            street
            postalCode
            city
            country
          }
        }
      }
    }
  }

  query MyCompanyCreationQuery($id: ID!) {
    myCompanyCreation(id: $id) {
      id
      currentStep
      postOrderStatus
      packageType
      flowType
      hasOperationalActivities
      operationalActivities
      referral
      company {
        ...CompanyDetails
      }
      adminCompany {
        ...CompanyDetails
      }
    }
  }
`;

export const GetMyCompanyCreation = (id) =>
  useQuery<MyCompanyCreationQuery, MyCompanyCreationQueryVariables>(MY_COMPANY_CREATION_QUERY, {
    variables: { id: id },
    skip: !id
  });

export const RESUME_MY_COMPANY_CREATION_STEP = gql`
  query ResumeMyCompanyCreationStep($id: ID) {
    resumeMyCompanyCreationStep(id: $id) {
      id
      flowType
      postOrderStatus
    }
  }
`;

export const GetResumeMyCompanyCreationStep = (id?: string, flowType?: FlowTypeEnum) =>
  useQuery<ResumeMyCompanyCreationStep>(RESUME_MY_COMPANY_CREATION_STEP, {
    variables: { id, flowType },
    skip: !isAuthenticated()
  });

export const GET_PACKAGE_TYPE = gql`
  query getPackageType {
    __type(name: "PackageTypeEnum") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const GetPackageType = () => useQuery(GET_PACKAGE_TYPE);

export const MY_DISCOUNT_CODE = gql`
  query MyDiscountCode {
    myDiscountCode {
      id
      code
      referrerValue
      referralValue
      uses {
        id
        usedByPerson {
          id
          firstName
          lastName
          user {
            id
            email
          }
        }
        status
      }
    }
  }
`;

export const GetMyDiscountCode = () => useQuery<MyDiscountCode>(MY_DISCOUNT_CODE);
