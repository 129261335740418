import { WithTranslation, withTranslation } from "react-i18next";
import React, { ReactNode, useEffect, useState } from "react";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { Form, FormikProps, FormikProvider } from "formik";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import TextLinkAsButton from "uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import { LinkVariant } from "sharedComponents/TextLink/TextLink";

interface Props extends WithTranslation {
  formik: FormikProps<any>;
  disableEditButton?: boolean;
  children: (props: { formik: FormikProps<any>; isEditMode: boolean }) => ReactNode;
  title: string;
  testId?: string;
}

const EditableSectionWithFormik = ({
  t,
  children,
  disableEditButton = false,
  formik,
  title,
  testId = "editable-section"
}: Props) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const onEdit = () => setIsEditMode(true);
  const onView = () => setIsEditMode(false);

  useEffect(() => {
    if (formik.isSubmitting) onView();
  }, [formik.submitCount, formik.isSubmitting]);

  const editButton = (
    <RideButtonDeprecated
      disabled={disableEditButton}
      variant={ButtonVariant.Primary}
      size={ButtonSize.Small}
      data-testid={`${testId}-edit-button`}
      onClick={onEdit}
      type="button"
      className="editable-section__edit-button">
      {t("generic:edit")}
    </RideButtonDeprecated>
  );
  const onEditButtons = (
    <div className="editable-section__on-edit-buttons">
      <TextLinkAsButton
        onClick={() => {
          formik.resetForm();
          onView();
        }}
        data-testid={`${testId}-cancel-button`}
        variant={LinkVariant.secondary}>
        {t("generic:cancel")}
      </TextLinkAsButton>
      <RideButtonDeprecated
        variant={ButtonVariant.Secondary}
        data-testid={`${testId}-save-button`}
        type="submit">
        {t("generic:save")}
      </RideButtonDeprecated>
    </div>
  );
  return (
    <FormikProvider value={formik}>
      <Form className="editable-section" data-testid={`${testId}-form`}>
        <RideSectionCard
          fixed={true}
          title={title}
          buttonSlot={!isEditMode ? editButton : undefined}>
          <div data-testid={`${testId}-content`}>{children({ formik, isEditMode })}</div>
          {isEditMode ? onEditButtons : undefined}
        </RideSectionCard>
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["generic"])(EditableSectionWithFormik);
