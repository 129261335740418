import NavigationService from "lib/services/NavigationService/NavigationService";
import React from "react";
import { useHistory } from "react-router-dom";

interface WebFormProps {
  url: string | undefined;
  bankAccountId: string | undefined;
  iban: string | undefined;
  companyId: string | undefined;
}

const BankAccountWebForm = ({ url, bankAccountId, iban, companyId }: WebFormProps) => {
  const history = useHistory();

  if (url) {
    const redirectUrl = `${NavigationService.instance?.baseUrl}/client/banking/${companyId}/${bankAccountId}/${iban}`;
    const webFormUrlWithRedirectUrl = `${url}?redirectUrl=${redirectUrl}`;
    NavigationService.instance?.openLink(webFormUrlWithRedirectUrl, "_self");
  } else {
    history.goBack();
  }

  return <></>;
};

export default BankAccountWebForm;
