import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RideNavbar } from "../../../sharedComponents/RideNavbar/RideNavbar";
import { MenuLink } from "../../../sharedComponents/MenuLink/MenuLink";
import { TaxAdvisorUrls } from "./TaxAdvisorUrls";
import { RideSidebar } from "../../../sharedComponents/RideSidebar/RideSidebar";
import NavigationService from "../../../lib/services/NavigationService/NavigationService";

const TaxAdvisorHeader = ({ t }: WithTranslation) => {
  const menu = [
    <MenuLink
      key="tax-advisor-clients"
      eventKey={TaxAdvisorUrls.CLIENTS_COMPANIES}
      path={TaxAdvisorUrls.CLIENTS_COMPANIES}>
      {t("tax-advisor-clients")}
    </MenuLink>,
    <MenuLink
      key="tax-advisor-third-party-customers"
      eventKey={TaxAdvisorUrls.THIRD_PARTY_TAX_ADVISORS_CUSTOMERS}
      path={TaxAdvisorUrls.THIRD_PARTY_TAX_ADVISORS_CUSTOMERS}>
      {t("tax-advisor-third-party-customers")}
    </MenuLink>,
    <MenuLink
      key="tax-advisor-former-clients"
      eventKey={TaxAdvisorUrls.FORMER_CLIENTS}
      path={TaxAdvisorUrls.FORMER_CLIENTS}>
      {t("tax-advisor-former-clients")}
    </MenuLink>,
    <MenuLink
      key="tax-advisor-former-third-party-clients"
      eventKey={TaxAdvisorUrls.FORMER_THIRD_PARTY_CLIENTS}
      path={TaxAdvisorUrls.FORMER_THIRD_PARTY_CLIENTS}>
      {t("tax-advisor-former-third-party-clients")}
    </MenuLink>,
    <MenuLink key="logout" path="/logout">
      {t("profile.logout")}
    </MenuLink>
  ];

  NavigationService.instance = new NavigationService(TaxAdvisorUrls);

  return (
    <div data-testid="tax-advisor-header">
      <RideNavbar sidebar={<RideSidebar menu={menu} activeKey="tax-advisor-clients" />}>
        {menu}
      </RideNavbar>
    </div>
  );
};

export default withTranslation("header")(TaxAdvisorHeader);
