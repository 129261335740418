import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Col, Container, Row } from "react-bootstrap";
import { SocialIcon } from "../../../client/components/SocialIcon/SocialIcon";
import { KnowledgeDocumentsContainer } from "./KnowledgeDocumentsContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { HeaderSection } from "client/components/HeaderSection/HeaderSection";

const Knowledge = ({ t }: WithTranslation) => {
  const usefulInformationTranslationKeysPrefix = [
    "produkte",
    "recht",
    "steuern",
    "immobilien",
    "sonstiges"
  ];

  const stayTunedTranslationKeysPrefix = ["blog", "newsletter", "webinare"];

  return (
    <>
      <PageMetaData title={t("client:knowledge.page-title")} />
      <div className="knowledge">
        <HeaderSection viewName="knowledge" title={t("client:knowledge.title")} />
        <Container className="px-md-0 mt-md-n55">
          <Row>
            <Col xs={12} md={12}>
              <RideSectionCard
                title={t("client:knowledge.knowledge-documents")}
                className="shadow-none mt-4 no-padding">
                <KnowledgeDocumentsContainer t={t} />
              </RideSectionCard>
            </Col>
          </Row>
        </Container>
        <Container className="px-md-0">
          <Row>
            <Col xs={12} md={6}>
              <RideSectionCard
                title={t("client:knowledge.useful-information")}
                className="shadow-none mt-4 no-padding">
                <KnowledgeLinkList
                  list={buildListWithTitleAndLink(
                    usefulInformationTranslationKeysPrefix,
                    "useful-information-info",
                    t
                  )}
                  testid="useful-information-link"
                />
              </RideSectionCard>
            </Col>
            <Col xs={12} md={6}>
              <RideSectionCard
                title={t("client:knowledge.stay-tuned")}
                className=" shadow-none mt-4 no-padding">
                <KnowledgeLinkList
                  list={buildListWithTitleAndLink(
                    stayTunedTranslationKeysPrefix,
                    "stay-tuned-info",
                    t
                  )}
                  testid="stay-tuned-link"
                />

                <p className="mt-4 mx-4 pb-0">{t("client:knowledge.follow-us-on")}</p>
                <div className="knowledge__social-container d-flex mx-4 mb-4 justify-content-between">
                  <SocialIcon platform="linkedin" />
                  <SocialIcon platform="facebook" />
                  <SocialIcon platform="twitter" />
                  <SocialIcon platform="youtube" />
                  <SocialIcon platform="instagram" />
                </div>
              </RideSectionCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

interface LinkItem {
  title: string;
  link: string;
}

const buildListWithTitleAndLink = (
  listWithPrefixes: string[],
  keyOfTranslationGroup: string,
  t
): LinkItem[] => {
  return listWithPrefixes.map((translationKey) => {
    return {
      title: t(`client:knowledge.${keyOfTranslationGroup}.ride-${translationKey}-title`),
      link: t(`client:knowledge.${keyOfTranslationGroup}.ride-${translationKey}-link`)
    };
  });
};

const KnowledgeLinkList = ({ list, testid }: { list: LinkItem[]; testid: string }) => (
  <ul className="list-group list-group-flush">
    {list.map((item, index) => (
      <KnowledgeLink item={item} testid={testid} key={index} />
    ))}
  </ul>
);

const KnowledgeLink = ({ item, testid }: { item: LinkItem; testid: string }) => {
  return (
    <li className="list-group-item">
      <a
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={testid}
        className="text-decoration-none text-dark knowledge__link">
        {item.title}
      </a>
    </li>
  );
};

export default withTranslationReady(["client"])(Knowledge);
