import React from "react";
import CodeForm from "./CodeForm";
import { Steps } from "../TwoFactorAuthenticationSetupForm/TwoFactorAuthenticationSetupForm";
import { useMutation } from "@apollo/react-hooks";
import {
  VerifyCodeForTwoFactorAuthSetup,
  VerifyCodeForTwoFactorAuthSetupVariables
} from "lib/api/mutations/graphql/VerifyCodeForTwoFactorAuthSetup";
import { VERIFY_CODE_FOR_TWO_FACTOR_AUTH_SETUP } from "lib/api/mutations/VERIFY_CODE_FOR_TWO_FACTOR_AUTH_SETUP";
import { updateToken } from "common/Authentication/storeToken";
import { SEND_SMS_FOR_TWO_FACTOR_AUTH } from "lib/api/mutations/SEND_SMS_FOR_TWO_FACTOR_AUTH";
import { SendSmsForTwoFactorAuth } from "lib/api/mutations/graphql/SendSmsForTwoFactorAuth";
import { ErrorCodes } from "global-query-types";

interface CodeFormContainerProps {
  goToStep: (step: Steps) => void;
  phoneNumber: string;
  nextSmsAvailableAtIsoValue: string;
}

const CodeFormContainer = ({
  goToStep,
  phoneNumber,
  nextSmsAvailableAtIsoValue
}: CodeFormContainerProps) => {
  const [verifyCodeForTwoFactorAuthMutation] = useMutation<
    VerifyCodeForTwoFactorAuthSetup,
    VerifyCodeForTwoFactorAuthSetupVariables
  >(VERIFY_CODE_FOR_TWO_FACTOR_AUTH_SETUP);

  const [sendSmsForTwoFactorAuthMutation] = useMutation<SendSmsForTwoFactorAuth>(
    SEND_SMS_FOR_TWO_FACTOR_AUTH
  );

  const onVerifyClick = async (code: string) => {
    try {
      const result = await verifyCodeForTwoFactorAuthMutation({
        variables: {
          code: code
        }
      });

      if (result.data?.appToken) {
        updateToken(result.data.appToken);
        goToStep(Steps.SUCCESS);
      }
    } catch (e) {
      if (e instanceof Error && e.message.includes(ErrorCodes.CODE_VERIFICATION_FAILED)) {
        return false;
      }
      goToStep(Steps.WARNING);
    }
  };

  const sendVerificationCode = async () => {
    try {
      const result = await sendSmsForTwoFactorAuthMutation();
      if (result.data?.sendSmsForTwoFactorAuth?.nextSmsAvailableAt) {
        return new Date(result.data?.sendSmsForTwoFactorAuth?.nextSmsAvailableAt);
      }
    } catch (err) {
      return;
    }
  };

  const nextSmsAvailableAt = nextSmsAvailableAtIsoValue
    ? new Date(nextSmsAvailableAtIsoValue)
    : undefined;

  return (
    <CodeForm
      onVerifyClick={onVerifyClick}
      phoneNumber={phoneNumber}
      sendSmsForTwoFactorAuth={sendVerificationCode}
      nextSmsRequestAvailableAt={nextSmsAvailableAt}
    />
  );
};

export default CodeFormContainer;
