import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import Table from "../../generic/Table";
import { formatPercentage } from "../../../common/formatters";
import { RoleEntry } from "../../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import EntityLink from "../EntityLink/EntityLink";
import KYCStatusLabel from "../../nominee/common/roles/KYCStatusLabel";
import { UserRole } from "../../../global-query-types";

interface RolesTableProps extends WithTranslation {
  roles: RoleEntry[] | null;
  asUser?: UserRole;
}

const ViewRolesTable = ({ t, roles, asUser = UserRole.Nominee }: RolesTableProps) => {
  const columns = [
    {
      dataField: "name",
      text: t("nominee-dashboard:company.shareholder-entity"),
      formatter: (_, row: RoleEntry) => {
        return <EntityLink testIdPrefix="shareholder" membership={row} asUser={asUser} />;
      }
    },
    {
      dataField: "isKycRequired",
      text: t("nominee-dashboard:company.status-kyc"),
      formatter: (isKycRequired, row) => (
        <KYCStatusLabel isKycRequired={isKycRequired} isKycSucceeded={row.isKycSucceeded} />
      )
    },
    {
      dataField: "is-director",
      text: t("nominee-dashboard:company.is-director"),
      formatter: (_, row: RoleEntry) => (
        <span data-testid="shareholder-director">
          {row.person?.id
            ? row.isManagingDirector
              ? t("generic:yes")
              : t("generic:no")
            : t("generic:not-applicable")}
        </span>
      )
    },
    {
      dataField: "is-silent-partner",
      text: t("nominee-dashboard:company.is-silent-partner"),
      formatter: (_, row: RoleEntry) => (
        <span data-testid="shareholder-silent-partner">
          {row.isSilentPartner ? t("generic:yes") : t("generic:no")}
        </span>
      )
    },
    {
      dataField: "share",
      text: t("nominee-dashboard:company.shareholder-share"),
      formatter: (_, row: RoleEntry) => (
        <span data-testid="shareholder-share">
          {row.share ? formatPercentage(row.share / 100) : "---"}
        </span>
      )
    },
    {
      dataField: "votes",
      text: t("nominee-dashboard:company.shareholder-votes"),
      formatter: (_, row: RoleEntry) => (
        <span data-testid="shareholder-votes">
          {row.votes ? formatPercentage(row.votes / 100) : "---"}
        </span>
      )
    }
  ];

  const rolesColumns =
    asUser === UserRole.TaxAdvisor
      ? columns.filter((elem) => elem?.dataField !== "isKycRequired")
      : columns;

  return (
    <div data-testid="view-roles-table">
      <Table data={roles} columns={rolesColumns} tableStyle="greenTable" keyField="id" />
    </div>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(ViewRolesTable);
