import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import RideModal, {
  RideModalBody,
  RideModalControls
} from "uiLibrary/designSystem/RideModal/RideModal";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import AddBankAccount from "../AddBankAccount/AddBankAccount";
import BankAccountForm from "../BankAccountForm/BankAccountForm";
import { Form, FormikProvider, useFormik } from "formik";
import { BankType, FeatureFlags } from "global-query-types";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { AddBankAccountNaked, navigateToBankPage } from "../AddNewBankAccount/AddNewBankAccount";
import { getClientBankOptions } from "../../Company/tasks/BankSelectionTask/BankSelectionTask";
import { BankOption } from "../../Company/tasks/BankSelectionOptions/BankSelectionOptions";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";

enum AddBankAccountModalState {
  AddNewOrExisting = "AddNewOrExisting",
  AddExisting = "AddExisting",
  AddNew = "AddNew"
}

export interface AddBankAccountModalProps extends WithTranslation {
  upsertHandler: (vars: { bankProvider: BankType; iban?: string; name?: string }) => Promise<void>;
  onClose: () => void;
}

const AddBankAccountModal = ({ t, upsertHandler, onClose }: AddBankAccountModalProps) => {
  const [view, setView] = useState(AddBankAccountModalState.AddNewOrExisting);

  const swanBankSelection = FeatureFlagService.instance.isEnabled(FeatureFlags.SwanBankSelection);
  const blacklist = swanBankSelection ? [BankType.Own] : [BankType.Swan, BankType.Own];

  const bankAccountsWithoutOwn = getClientBankOptions().filter(
    (bank) => !blacklist.includes(bank.name)
  );
  const [banks, setBanks] = useState<BankOption[]>(bankAccountsWithoutOwn);

  const onSelect = (bankName: string) => {
    const _bankOptions = [...banks];
    _bankOptions.forEach((bankOption, index) => {
      _bankOptions[index] = { ...bankOption, isSelected: bankOption.name === bankName };
    });
    setBanks(_bankOptions);
  };

  const handleSubmit = async () => {
    const bankProvider = banks.find((bank) => bank.isSelected)?.name!;
    await upsertHandler({ bankProvider });
    navigateToBankPage(bankProvider, t);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      bank: BankType.Penta,
      iban: ""
    },
    onSubmit: (values) =>
      upsertHandler({
        iban: values.iban,
        name: values.name,
        bankProvider: values.bank
      })
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <RideModal
          title={t("client:banking.addBankAccount.title")}
          onClose={onClose}
          data-testid={"add-bank-account-modal"}>
          <RideModalBody>
            {view === AddBankAccountModalState.AddNewOrExisting && (
              <AddBankAccount
                lightMode={true}
                onAddNew={() => setView(AddBankAccountModalState.AddNew)}
                onAddExisting={() => setView(AddBankAccountModalState.AddExisting)}
              />
            )}
            {view === AddBankAccountModalState.AddExisting && (
              <BankAccountForm formik={formik} companyOptions={[]} />
            )}
            {view === AddBankAccountModalState.AddNew && (
              <AddBankAccountNaked banks={banks} onSelect={onSelect} t={t} />
            )}
          </RideModalBody>

          {view === AddBankAccountModalState.AddExisting && (
            <RideModalControls>
              <RideButtonDeprecated
                width={ButtonWidth.FULL}
                radius={ButtonRadius.Soft}
                data-testid={"save-button"}
                type={"submit"}>
                {t("generic:save")}
              </RideButtonDeprecated>
            </RideModalControls>
          )}
          {view === AddBankAccountModalState.AddNew && (
            <RideModalControls>
              <RideButtonDeprecated
                data-testid={"submit-bank-account"}
                width={ButtonWidth.FULL}
                radius={ButtonRadius.Soft}
                type={"button"}
                onClick={async () => {
                  await handleSubmit();
                }}>
                {t("client:banking.addNewBankAccount.selectBankButton")}
              </RideButtonDeprecated>
            </RideModalControls>
          )}
        </RideModal>{" "}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["client"])(AddBankAccountModal);
