import { TFunction } from "i18next";
import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import logger from "../../common/Logger";
import TextLinkAsButton from "../../uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";

interface ArchiveWithConfirmationProps {
  title: string;
  onConfirm: () => Promise<void>;
  t: TFunction;
}

export const ArchiveWithConfirmation = ({ t, onConfirm, title }: ArchiveWithConfirmationProps) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleHide = () => setShow(false);

  const confirmArchive = async () => {
    try {
      await onConfirm();
    } catch (error) {
      logger.errorMessage(`Unable to archive the data`);
      logger.error(error);
    }
  };

  return (
    <>
      <TextLinkAsButton data-testid="archive-service-button" onClick={handleShow}>
        {t("generic:archive")}
      </TextLinkAsButton>

      <Modal
        show={show}
        onHide={handleHide}
        dialogClassName="modal-90w"
        aria-labelledby="archive-confirmation"
        data-testid="archive-modal">
        <Modal.Header closeButton>
          <Modal.Title id="archive-confirmation" data-testid="title">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <ConfirmationForm t={t} handleHide={handleHide} confirmArchive={confirmArchive} />
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

interface ConfirmationFormProps {
  t: TFunction;
  handleHide: () => void;
  confirmArchive: () => Promise<void>;
}

const ConfirmationForm = ({ t, handleHide, confirmArchive }: ConfirmationFormProps) => (
  <>
    <Col className="col-auto">
      <Button variant="outline-dark" data-testid="no" onClick={handleHide}>
        {t("generic:no")}
      </Button>
    </Col>

    <Col className="col-auto">
      <Button variant="primary" data-testid="yes" onClick={confirmArchive}>
        {t("generic:yes")}
      </Button>
    </Col>
  </>
);
