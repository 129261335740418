import React from "react";
import { AccountantsSection } from "admin/components/AccountantsSection/AccountantsSection";
import { useMutation, useQuery } from "react-apollo";
import {
  GetAccountingServicesByClient,
  GetAccountingServicesByClientVariables
} from "lib/api/queries/graphql/GetAccountingServicesByClient";
import { GET_ACCOUNTING_SERVICES_BY_CLIENT } from "lib/api/queries/GetAccountingServicesByClient";
import { CREATE_ACCOUNTING_SERVICE } from "lib/api/mutations/CREATE_ACCOUNTING_SERVICE";
import {
  CreateAccountingService,
  CreateAccountingServiceVariables
} from "lib/api/mutations/graphql/CreateAccountingService";
import { GetAllAccountantsRequest } from "components/nominee/Accountant/Queries";
import { Col, Row } from "react-bootstrap";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { DELETE_ACCOUNTING_SERVICE } from "../../../lib/api/mutations/DELETE_ACCOUNTING_SERVICE";
import {
  DeleteAccountingService,
  DeleteAccountingServiceVariables
} from "../../../lib/api/mutations/graphql/DeleteAccountingService";

interface AccountantsSectionContainerProps {
  companyId: string;
}

export const AccountantsSectionContainer = ({ companyId }: AccountantsSectionContainerProps) => {
  const getAllAccountants = GetAllAccountantsRequest();

  const getAccountingServicesByClient = useQuery<
    GetAccountingServicesByClient,
    GetAccountingServicesByClientVariables
  >(GET_ACCOUNTING_SERVICES_BY_CLIENT, {
    variables: {
      clientCompanyId: companyId
    }
  });

  const [createAccountingServiceMutation, createAccountingServiceRequest] = useMutation<
    CreateAccountingService,
    CreateAccountingServiceVariables
  >(CREATE_ACCOUNTING_SERVICE);

  const [deleteAccountingServiceMutation, deleteAccountingServiceRequest] = useMutation<
    DeleteAccountingService,
    DeleteAccountingServiceVariables
  >(DELETE_ACCOUNTING_SERVICE);

  const createAccountingService = async (accountantUserId: string) => {
    await createAccountingServiceMutation({
      variables: {
        clientCompanyId: companyId,
        accountantUserId: accountantUserId
      }
    });
    await getAccountingServicesByClient.refetch();
  };

  const deleteAccountingService = async (accountingServiceId: string) => {
    await deleteAccountingServiceMutation({
      variables: {
        accountingServiceId: accountingServiceId
      }
    });
    await getAccountingServicesByClient.refetch();
  };

  const allAccountants = getAllAccountants.data?.getAllAccountants ?? [];
  const accountingServices =
    getAccountingServicesByClient.data?.getAccountingServicesByClient ?? [];

  const loading =
    getAllAccountants.loading ||
    getAccountingServicesByClient.loading ||
    deleteAccountingServiceRequest.loading ||
    createAccountingServiceRequest.loading;

  return (
    <Row>
      <Col>
        <h2>{"Accountants"}</h2>
        <RequestFeedbackHandler
          requests={[createAccountingServiceRequest, deleteAccountingServiceRequest]}
        />
        <AccountantsSection
          loading={loading}
          allAccountants={allAccountants}
          accountingServices={accountingServices}
          createAccountingService={createAccountingService}
          deleteAccountingService={deleteAccountingService}
        />
      </Col>
    </Row>
  );
};
