import { TFunction } from "i18next";
import React from "react";
import {
  canAssignTaxService,
  isAdminService,
  isAdminServiceActivated,
  isAdminServiceComplete,
  isCompanyCreationPostOrdered
} from "./AdminServiceSectionAddon";
import { NomineeCompanyDetails_companyById } from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const AdminServiceAlertMessages = ({
  t,
  testid,
  message
}: {
  t: TFunction;
  testid: string;
  message: string;
}) => (
  <div className="alert alert-info" data-testid={testid}>
    {t(message)}
  </div>
);

export const TaxServiceMessages = ({
  t,
  company
}: {
  t: TFunction;
  company: NomineeCompanyDetails_companyById;
}) => {
  return (
    <>
      {isAdminServiceActivated(company) && (
        <AdminServiceAlertMessages
          t={t}
          testid={"activate-admin-service-message"}
          message={"nominee-dashboard:admin-service.admin-service-already-active"}
        />
      )}
      {!canAssignTaxService(company) && (
        <AdminServiceAlertMessages
          t={t}
          testid={"assign-tax-service-incomplete-message"}
          message={"nominee-dashboard:tax-service.cannot-assign-tax-service"}
        />
      )}
      {!isAdminServiceComplete(company) && (
        <AdminServiceAlertMessages
          t={t}
          testid={"incomplete-admin-service-message"}
          message={"nominee-dashboard:admin-service.admin-service-should-exist-and-be-complete"}
        />
      )}
      {!isAdminService(company) && !isCompanyCreationPostOrdered(company) && (
        <AdminServiceAlertMessages
          t={t}
          testid={"create-admin-service-message"}
          message={"nominee-dashboard:admin-service.company-creation-should-exist-and-be-complete"}
        />
      )}
    </>
  );
};
