import { SectionWrapper } from "admin/components/LowTaxBrokerOrderDetails/SectionWrapper/SectionWrapper";
import IBFirstLoginCredentials from "admin/components/LowTaxBrokerOrderDetails/CredentialsSection/IBFirstLoginCredentials/IBFirstLoginCredentials";
import SendIBCredentialsForm from "admin/components/LowTaxBrokerOrderDetails/CredentialsSection/SendIBCredentialsForm/SendIBCredentialsForm";
import React from "react";
import { WithTranslation } from "react-i18next";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import UpdateIBKREmail from "admin/components/LowTaxBrokerOrderDetails/CredentialsSection/UpdateIBKREmail/UpdateIBKREmail";
import { UpdateIBKRCredentialVariables } from "lib/api/mutations/graphql/UpdateIBKRCredential";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import { GetUserForPersonQuery_getPersonDetails_user } from "lib/api/queries/graphql/GetUserForPersonQuery";

export interface CredentialsSectionProps {
  order: getLowTaxBrokerOrder_getLowTaxBrokerOrder_order;
  ownerUser: GetUserForPersonQuery_getPersonDetails_user | null | undefined;
  accountDetails: unknown | null | undefined;
  upsertHandler: (data: UpsertOrderVariables) => Promise<void>;
  sendIBPasswordByEmail: (args: { orderId: string; password: string }) => Promise<void>;
  updateIBKRCredential: (variables: UpdateIBKRCredentialVariables) => Promise<unknown>;
  isLoading: boolean;
}

const CredentialsSection = ({
  order,
  ownerUser,
  accountDetails,
  upsertHandler,
  sendIBPasswordByEmail,
  updateIBKRCredential,
  isLoading,
  t
}: CredentialsSectionProps & WithTranslation) => {
  const handleIBCredentials = (accountNumber, username) =>
    upsertHandler({
      id: order?.id,
      extra: { ibCredentials: { accountNumber, username } }
    });

  return (
    <SectionWrapper title={t("generic:broker-order-details.ib-credentials")}>
      <IBFirstLoginCredentials
        disableEditButton={order.status !== "Completed" || order.extra?.isNewBrokerEnabled}
        username={order.extra?.ibCredentials?.username}
        accountNumber={order.extra?.ibCredentials?.accountNumber}
        onSave={handleIBCredentials}
      />
      <SendIBCredentialsForm
        order={order}
        ibCredentials={order.extra?.ibCredentials}
        onSubmit={sendIBPasswordByEmail}
        isLoading={isLoading}
      />
      <FeatureFlag name={FeatureFlags.ADB_RideBroker_UpdateIBKRCredential}>
        <UpdateIBKREmail
          order={order}
          ownerUser={ownerUser}
          accountDetails={accountDetails}
          updateIBKRCredential={updateIBKRCredential}
          isLoading={isLoading}
        />
      </FeatureFlag>
    </SectionWrapper>
  );
};

export default withTranslationReady(["generic"])(CredentialsSection);
