import { getCompanyCreationSteps_getCompanyCreationSteps_steps_tasks } from "../../lib/api/queries/graphql/getCompanyCreationSteps";
import { TaskStatesEnum, TaskType, UserRole } from "../../global-query-types";
import { Company } from "../../lib/types/types";

export interface ProcessStates {
  [key: string]: ProcessStep;
}

export interface ProcessStep {
  selected: ProcessStatesEnum;
}

export interface ActionableTask {
  ref: string;
  state: TaskStatesEnum;
  options?: ActionableTaskOptions | null;
  responsibleParty: UserRole;
  updatedAt: Date;
  type: TaskType;
}

export interface FoundingServiceProcessTask extends Omit<ActionableTask, "options" | "type"> {
  companyCreation: {
    company: Company;
  };
}

export interface ActionableTaskOptions {
  type: string | null;
  data: {
    [key: string]: string;
  };
}

export enum ProcessStatesEnum {
  TO_BE_DONE = "TO_BE_DONE",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_REQUIRED = "NOT_REQUIRED"
}

export const toActionableTask = (
  task: getCompanyCreationSteps_getCompanyCreationSteps_steps_tasks
): ActionableTask => {
  let options: ActionableTaskOptions | undefined;

  if (task.options) {
    options = {
      type: task.options.type || "unknown",
      data: task.options.data
    };
  }

  return {
    ref: task.ref ?? "",
    state: task.state ?? TaskStatesEnum.NOT_POSSIBLE_YET,
    options,
    responsibleParty: task.responsibleParty ?? UserRole.Nominee,
    updatedAt: new Date(task.updatedAt),
    type: task.type ?? TaskType.GENERIC
  };
};

export const toActionableTaskList = (
  taskList: getCompanyCreationSteps_getCompanyCreationSteps_steps_tasks[] | null | undefined
): ActionableTask[] => taskList?.map((task) => toActionableTask(task)) ?? [];
