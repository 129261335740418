import React, { FC } from "react";
import Header from "../../../../generic/Trader-GmbH/Header";
import Banner from "../../../../generic/Trader-GmbH/Banner";
import { CompanyCreationPage } from "./CompanyCreationPage";
import { withTranslation, WithTranslation } from "react-i18next";
import { FlowTypeEnum } from "../../../../../global-query-types";
import RideFooter from "../../../../../sharedComponents/RideFooter/RideFooter";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface CompanyCreationHomePageProps extends WithTranslation {
  flowType: FlowTypeEnum;
}

export const CompanyCreationPageLayout: FC<CompanyCreationHomePageProps> = ({ t, flowType }) => {
  return (
    <div className="trader-gmbh page-container">
      <PageMetaData title={t("trader-gmbh:page-title")} />
      <Header />
      <Banner currentFlowType={flowType} />
      <CompanyCreationPage t={t} currentFlowType={flowType} />
      <RideFooter />
    </div>
  );
};

export default withTranslation(["trader-gmbh", "generic", "errorCodes"])(CompanyCreationPageLayout);
