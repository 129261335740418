import React from "react";
import { useFormik } from "formik";
import EditableSectionWithFormik from "sharedComponents/EditableSectionWithFormik/EditableSectionWithFormik";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  InvoiceDateField,
  InvoiceFieldSection,
  InvoiceSelectField,
  InvoiceTextField,
  YesNoOptions
} from "../InvoiceFields/InvoiceFields";

export interface PayrollInvoiceData {
  payrollServiceType: string | null;
  payrollServiceStart: string | null;
  payrollServiceEnd: string | null;
  payrollServicePrice: string | null;
}

interface PayrollServiceSectionProps extends WithTranslation {
  payrollInvoiceData: PayrollInvoiceData | null;
  onSubmit: (variables: PayrollInvoiceData) => Promise<void>;
}

const PayrollServiceSection = ({ t, payrollInvoiceData, onSubmit }: PayrollServiceSectionProps) => {
  const formikState = useFormik({
    initialValues: {
      payrollServiceType: payrollInvoiceData?.payrollServiceType ?? null,
      payrollServiceStart: payrollInvoiceData?.payrollServiceStart ?? null,
      payrollServiceEnd: payrollInvoiceData?.payrollServiceEnd ?? null,
      payrollServicePrice: payrollInvoiceData?.payrollServicePrice ?? null
    },
    onSubmit: async (values) => {
      await onSubmit({
        ...values
      });
    },
    enableReinitialize: true
  });

  return (
    <EditableSectionWithFormik
      formik={formikState}
      title={t("nominee-dashboard:company.invoice.payroll.title")}
      testId="payroll-service-section">
      {({ isEditMode, formik }) => (
        <>
          <InvoiceFieldSection
            data-testid="payrollServiceType"
            label={t("nominee-dashboard:company.invoice.payroll.payrollServiceType")}>
            <InvoiceSelectField
              options={YesNoOptions(t)}
              isEdit={isEditMode}
              formik={formik}
              name="payrollServiceType"
              label={t("nominee-dashboard:company.invoice.payroll.payrollServiceType-type")}
            />
          </InvoiceFieldSection>

          <InvoiceFieldSection
            data-testid="payrollServiceStart"
            label={t("nominee-dashboard:company.invoice.payroll.payrollServiceStart")}>
            <InvoiceDateField
              name="payrollServiceStart"
              label={t("nominee-dashboard:company.invoice.payroll.payrollServiceStart-date")}
              isEdit={isEditMode}
              formik={formik}
            />
          </InvoiceFieldSection>

          <InvoiceFieldSection
            data-testid="payrollServiceEnd"
            label={t("nominee-dashboard:company.invoice.payroll.payrollServiceEnd")}>
            <InvoiceDateField
              name="payrollServiceEnd"
              label={t("nominee-dashboard:company.invoice.payroll.payrollServiceEnd-date")}
              isEdit={isEditMode}
              formik={formik}
            />
          </InvoiceFieldSection>

          <InvoiceFieldSection
            data-testid="payrollServicePrice"
            label={t("nominee-dashboard:company.invoice.payroll.payrollServicePrice")}>
            <InvoiceTextField
              name="payrollServicePrice"
              label={t("nominee-dashboard:company.invoice.payroll.payrollServicePrice-label")}
              isEdit={isEditMode}
              formik={formik}
            />
          </InvoiceFieldSection>
        </>
      )}
    </EditableSectionWithFormik>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(PayrollServiceSection);
