//From Backend definition
import { PersonData } from "../CompanyFounding/PersonData";
import { CompanyData } from "../CompanyData";
import { AddressData } from "../AddressData";
import { UtmParams } from "../../../services/UtmService/UtmService";

export interface UnifiedShortOrderFormServerData {
  companyData?: Pick<
    CompanyData,
    "legalForm" | "hasCorporateShareholders" | "registerCourt" | "registration" | "companyName"
  >;
  companyActivelyOperating?: string;
  companyAddress?: AddressData;
  discountCode?: string;
  companyDoesNotFallIntoCategories?: string;
  companyIssuesMoreThan50OutgoingInvoicesYearly?: string;
  companyKeepsPhysicalInventory?: string;
  companyOwnsRealEstate?: string;
  hasFiveOrMoreEmployees?: string;
  orderSource?: OrderSource;
  orderSummary: ShortOrderSummary;
  personData: Omit<PersonData, "authorizedToShareShareholderInfo">;
  selectedProducts: SelectedProduct[];
  termsAndConditions: TermsAndConditions;
  tradesCrypto?: string;
  truthfulInformationProvided?: boolean;
}

export interface SelectedProduct {
  product: Product;
  tier?: string;
  numberOfAdditionalYears?: number;
}

export enum Product {
  GS = "GS",
  AS = "AS",
  LTB = "LTB"
}

interface TermsAndConditions {
  termsAndConditionsCheckbox: boolean;
  privacyPolicyCheckbox: boolean;
  allowsContact: boolean;
}

interface ShortOrderSummary {
  grundungServicePrice?: number;
  adminServicePrice?: number;
  lowTaxBrokerPrice?: number;
  oneTimeFee?: number;
  discountValue: number;
  totalPrice: number;
}

interface OrderSource extends UtmParams {
  userReportedSource?: string;
}
