import React from "react";
import { WithTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { textFieldFor } from "components/generic/commonFields";
import { AddThirdPartyNotaryAndCompanySchema } from "lib/validation/AddThirdPartyNotaryAndCompanySchema";
import { onAddNotaryCompanyProps } from "components/nominee/ThirdPartyTaxAdvisorsList/types";

interface AddThirdPartyNotaryAndCompanyFormProps extends WithTranslation {
  onAddNotaryCompany: (variables: onAddNotaryCompanyProps) => Promise<void>;
  refetch: () => Promise<void>;
}

export const AddThirdPartyNotaryAndCompanyForm = ({
  onAddNotaryCompany,
  t,
  refetch
}: AddThirdPartyNotaryAndCompanyFormProps) => {
  const initialValues: onAddNotaryCompanyProps = {
    taxAdvisorFirstName: "",
    taxAdvisorLastName: "",
    taxAdvisorEmail: "",
    companyName: ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddThirdPartyNotaryAndCompanySchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await onAddNotaryCompany(values);
      await refetch();
    }
  });

  return (
    <div data-testid="add-notary-form">
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            {textFieldFor(t, formik, "taxAdvisorFirstName", "nominee-dashboard:person.first-name")}
          </Col>
          <Col>
            {textFieldFor(t, formik, "taxAdvisorLastName", "nominee-dashboard:person.last-name")}
          </Col>
          <Col>{textFieldFor(t, formik, "taxAdvisorEmail", "nominee-dashboard:person.email")}</Col>
          <Col>{textFieldFor(t, formik, "companyName", "nominee-dashboard:company.name")}</Col>
        </Row>
        <Row>
          <Col>
            <Button className="button" type="submit" data-testid="submit">
              {t("generic:add")}
            </Button>
          </Col>
          <Col />
          <Col />
        </Row>
      </Form>
    </div>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(
  AddThirdPartyNotaryAndCompanyForm
);
