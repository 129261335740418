import * as Sentry from "@sentry/react";
import React from "react";
import { useMutation } from "react-apollo";
import AddExistingHoldingCompany from "../../../components/nominee/NomineeCompany/AddExistingHoldingCompany";
import { NOMINEE_UPDATE_MEMBERSHIP } from "../../../components/nominee/NomineeCompany/Mutations";
import { FULL_PERSON_DETAILS_QUERY } from "../../../components/nominee/NomineePerson/Queries";
import { SearchTypeEnum } from "../../../global-query-types";
import {
  companyRolesFromMemberships,
  RoleEntry
} from "../../../lib/dataAdapters/membershipCategorization/membershipCategorization";
import { ADD_EXISTING_SHAREHOLDER_TO_COMPANY } from "../../../lib/api/mutations/ADD_EXISTING_SHAREHOLDER_TO_COMPANY";
import {
  AddExistingShareholderToCompany,
  AddExistingShareholderToCompanyVariables
} from "../../../lib/api/mutations/graphql/AddExistingShareholderToCompany";
import {
  NomineeAddEmptyCompany,
  NomineeAddEmptyCompanyVariables
} from "../../../lib/api/mutations/graphql/NomineeAddEmptyCompany";
import {
  NomineeAddExistingHoldingCompany,
  NomineeAddExistingHoldingCompanyVariables
} from "../../../lib/api/mutations/graphql/NomineeAddExistingHoldingCompany";
import {
  NomineeAddExternalDirectorForCompany,
  NomineeAddExternalDirectorForCompanyVariables
} from "../../../lib/api/mutations/graphql/NomineeAddExternalDirectorForCompany";
import { NOMINEE_ADD_EMPTY_COMPANY } from "../../../lib/api/mutations/NOMINEE_ADD_EMPTY_COMPANY";
import { NOMINEE_ADD_EXISTING_HOLDING_COMPANY } from "../../../lib/api/mutations/NOMINEE_ADD_EXISTING_HOLDING_COMPANY";
import { NOMINEE_ADD_EXTERNAL_DIRECTOR_FOR_COMPANY } from "../../../lib/api/mutations/NOMINEE_ADD_EXTERNAL_DIRECTOR_FOR_COMPANY";
import { NOMINEE_COMPANY_DETAILS_QUERY } from "../../../lib/api/queries/GetNomineeCompanyDetailsById";
import { NomineeCompanyDetails_companyById_group_memberships_person_memberships_group_company } from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { CompanyDetailsSectionContainerProps } from "../companies/CompanyDetailsSection/CompanyDetailsSectionContainer";
import RolesSection from "./RolesSection";

export const RolesSectionContainer = ({
  company,
  refetchCompany: refetch
}: CompanyDetailsSectionContainerProps) => {
  const memberships = company.group.memberships ?? [];
  const roles = companyRolesFromMemberships(memberships);

  const relatedCompanies =
    (company.group?.memberships
      ?.flatMap((membership) => membership?.person?.memberships?.flatMap((mm) => mm.group.company))
      .filter(
        (c) => !!c
      ) as NomineeCompanyDetails_companyById_group_memberships_person_memberships_group_company[]) ??
    [];

  const [updateMembershipMutation] = useMutation(NOMINEE_UPDATE_MEMBERSHIP);

  const [addEmptyCompanyMutation] = useMutation<
    NomineeAddEmptyCompany,
    NomineeAddEmptyCompanyVariables
  >(NOMINEE_ADD_EMPTY_COMPANY);

  const [addExternalManagingDirectorMutation] = useMutation<
    NomineeAddExternalDirectorForCompany,
    NomineeAddExternalDirectorForCompanyVariables
  >(NOMINEE_ADD_EXTERNAL_DIRECTOR_FOR_COMPANY);

  const [nomineeAddExistingHoldingCompanyMutation] = useMutation<
    NomineeAddExistingHoldingCompany,
    NomineeAddExistingHoldingCompanyVariables
  >(NOMINEE_ADD_EXISTING_HOLDING_COMPANY);

  const [addExistingShareholder] = useMutation<
    AddExistingShareholderToCompany,
    AddExistingShareholderToCompanyVariables
  >(ADD_EXISTING_SHAREHOLDER_TO_COMPANY);

  const submitRoles = async (roles: RoleEntry[]) => {
    for (const role of roles) {
      const fullPersonDetailsQuery = role.person
        ? [
            {
              query: FULL_PERSON_DETAILS_QUERY,
              variables: { id: role.person?.id }
            }
          ]
        : [];

      await updateMembershipMutation({
        variables: {
          companyId: company.id,
          memberPersonId: role.person?.id,
          memberCompanyId: role.company?.id,
          isManagingDirector: role.isManagingDirector ?? undefined,
          isSilentPartner: role.isSilentPartner ?? undefined,
          share: role.share,
          votes: role.votes
        },
        refetchQueries: [
          {
            query: NOMINEE_COMPANY_DETAILS_QUERY,
            variables: { id: company.id }
          },
          ...fullPersonDetailsQuery
        ]
      });
    }
  };

  const addEmptyCompany = async (assetCompanyId: string) => {
    await addEmptyCompanyMutation({ variables: { assetCompanyId } });
  };

  const addExternalManagingDirector = async (companyId: string) => {
    await addExternalManagingDirectorMutation({ variables: { companyId } });
  };

  const addHoldingCompany = async (assetCompanyId: string, holdingCompanyId: string) => {
    try {
      await nomineeAddExistingHoldingCompanyMutation({
        variables: { assetCompanyId, holdingCompanyId }
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const addExistingShareholderToCompany = async (
    type: SearchTypeEnum,
    entityId: string,
    companyId: string
  ) => {
    await addExistingShareholder({
      variables: {
        type,
        entityId,
        companyId
      }
    });
  };

  const onAddHoldingCompany = async (holdingCompanyId) => {
    await addHoldingCompany(company.id, holdingCompanyId);
    await refetch();
  };

  const onAddEmptyCompanyShareholder = async () => {
    await addEmptyCompany(company.id);
    await refetch();
  };

  const onAddExternalManagingDirector = async () => {
    await addExternalManagingDirector(company.id);
    await refetch();
  };

  const onAddExistingShareholder = async (
    type: SearchTypeEnum,
    entityId: string,
    companyId: string
  ) => {
    await addExistingShareholderToCompany(type, entityId, companyId);
    await refetch();
  };

  const handleSubmitRoles = async (values: { roles: RoleEntry[] }) => {
    await submitRoles(values.roles);
    await refetch();
  };

  return (
    <RolesSection
      onAddEmptyCompanyShareholder={onAddEmptyCompanyShareholder}
      onAddExternalManagingDirector={onAddExternalManagingDirector}
      handleSubmit={handleSubmitRoles}
      roles={roles}
      companyId={company.id}
      onAddExistingShareholder={onAddExistingShareholder}
      addHoldingCompany={
        <AddExistingHoldingCompany
          onSubmit={onAddHoldingCompany}
          companies={relatedCompanies}
          assetCompany={company}
        />
      }
    />
  );
};
