import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { clubDeals_clubDeals } from "../../../../lib/api/queries/graphql/clubDeals";
import { FormikProvider, useFormik } from "formik";
import RequestFeedbackForm from "../../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import RideInput from "../../../../sharedComponents/FormControls/RideInput/RideInput";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import { Col, Row } from "react-bootstrap";
import { ClientSelect } from "../../../../client/components/ClientSelect/ClientSelect";

export interface AdminClubFormValues {
  financedPercentage: number | undefined;
  maxInvestmentAmount: number | undefined;
  minInvestmentAmount: number | undefined;
  targetYield: number | undefined;
  realEstate: string;
}

interface AdminClubDealFormProps extends WithTranslation {
  clubDeal?: clubDeals_clubDeals;
  realEstateList: { id: string; label: string }[];
  request?: MutationResult | QueryResult | RequestFeedbackInfo;
  onSubmit?: (values: AdminClubFormValues) => void;
}

const AdminClubDealForm = ({
  request,
  clubDeal,
  realEstateList,
  onSubmit,
  t
}: AdminClubDealFormProps) => {
  const isLoading = false;
  const isOnEdit: boolean = clubDeal ? true : false;

  const initialValues = {
    financedPercentage: clubDeal?.financedPercentage,
    maxInvestmentAmount: clubDeal?.maxInvestmentAmount,
    minInvestmentAmount: clubDeal?.minInvestmentAmount,
    realEstate: clubDeal?.asset?.realEstate?.id,
    targetYield: clubDeal?.targetYield
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onSubmit?.({
        financedPercentage: values.financedPercentage ?? undefined,
        maxInvestmentAmount: values.maxInvestmentAmount ?? undefined,
        minInvestmentAmount: values.minInvestmentAmount ?? undefined,
        targetYield: values.targetYield ?? undefined,
        realEstate: values.realEstate ?? ""
      });
    }
  });

  let requests = request ? [request] : [];
  let realEstateOptions = realEstateList.map((realEstate) => ({
    label: realEstate.label,
    value: realEstate.id
  }));

  return (
    <FormikProvider value={formik}>
      <RequestFeedbackForm
        submitLabel={t("nominee-dashboard:investment-club-deals.submit")}
        requests={requests}>
        <Row>
          <Col className="mb-3">
            <RideInput
              formik={formik}
              name="minInvestmentAmount"
              isDisabled={isLoading}
              type="text"
              label={t("nominee-dashboard:investment-club-deals.columns.minInvestment")}
            />
          </Col>
          <Col className="mb-3">
            <RideInput
              formik={formik}
              name="maxInvestmentAmount"
              isDisabled={isLoading}
              type="text"
              label={t("nominee-dashboard:investment-club-deals.columns.maxInvestment")}
            />
          </Col>
          <Col className="mb-3">
            <RideInput
              formik={formik}
              name="targetYield"
              isDisabled={isLoading}
              type="text"
              label={t("nominee-dashboard:investment-club-deals.columns.targetYield")}
            />
          </Col>
          <Col className="mb-3">
            <RideInput
              formik={formik}
              name="financedPercentage"
              isDisabled={isLoading}
              type="text"
              label={t("nominee-dashboard:investment-club-deals.columns.financed")}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <ClientSelect
              name="realEstate"
              label={t("nominee-dashboard:investment-club-deals.columns.realEstate")}
              options={realEstateOptions}
              isSearchable={false}
              isClearable={false}
              testIdPrefix="realEstate-select"
            />
            {isOnEdit && (
              <p data-testid="club-deal-asset-warning">
                {t("nominee-dashboard:investment-club-deals.columns.realEstateDescription")}
              </p>
            )}
          </Col>
        </Row>
      </RequestFeedbackForm>
    </FormikProvider>
  );
};

export default withTranslationReady(["nominee-dashboard"])(AdminClubDealForm);
