import * as Yup from "yup";
import { isValidEthereumAddress } from "./isValidEthereumAddress";

export const WalletAddressSchema = () =>
  Yup.string()
    .required("generic:validation-required")
    .test("ethereumValidAddress", "generic:invalid-wallet-address", (value?: string | null) => {
      if (!value) {
        return false;
      }

      return isValidEthereumAddress(value);
    });
