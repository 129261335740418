import React from "react";
import { PortfolioDataCompletenessTaskContainer } from "./Tasks/PortfolioDataCompletenessTask/PortfolioDataCompletenessTaskContainer";
import { GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany } from "lib/api/administration/queries/graphql/GetEndOfYearTasksByCompany";

interface Props {
  index: number;
  task: GetEndOfYearTasksByCompany_getEndOfYearTasksByCompany;
}

export const ClientTaskSwitcher = ({ index, task }: Props) => {
  switch (task.taskName) {
    case "PortfolioDataConfirmation":
      return <PortfolioDataCompletenessTaskContainer index={index} task={task} />;
    default:
      return null;
  }
};
