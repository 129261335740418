import React from "react";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { RideHeaderColumn } from "../../../lib/types/types";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { formatDateTime } from "../../../common/formatters";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import { getAllShelfCompanyOrders_getAllShelfCompanyOrders_orders } from "../../../lib/api/queries/graphql/getAllShelfCompanyOrders";

const orderStatusToRideBadgeMap = {
  NEED_ACTION: { color: RideBadgeColorsEnum.DARK, label: "Need action" },
  TRANSFERRED: { color: RideBadgeColorsEnum.SUCCESS, label: "Transferred" },
  CANCELED: { color: RideBadgeColorsEnum.DANGER, label: "Canceled" },
  RESERVED: { color: RideBadgeColorsEnum.WARNING, label: "Reserved" }
};

export interface shelfCompanyOrdersTableProps extends WithTranslation {
  orders: getAllShelfCompanyOrders_getAllShelfCompanyOrders_orders[];
}

const ShelfCompanyOrdersTable = ({ t, orders }: shelfCompanyOrdersTableProps) => {
  const columns: RideHeaderColumn[] = [
    t("nominee-dashboard:shelf-company.vg-orders.order"),
    t("nominee-dashboard:shelf-company.vg-orders.date"),
    t("nominee-dashboard:shelf-company.vg-orders.city"),
    t("nominee-dashboard:shelf-company.vg-orders.status")
  ].map((label) => ({
    label,
    name: label.toLowerCase()
  }));

  return (
    <div data-testid="shelf-company-orders-table" className={"shelf-company-orders-table"}>
      <RideTableHeader columns={columns} />
      {orders.map((order, index) => (
        <RideTableRow
          colCount={columns.length}
          key={index}
          className={"shelf-company-orders-table__row"}>
          <RideTableCell
            link={`/admin/client-services/shelf-companies/orders/${order.id}`}
            value={order.name}
            variant={RideTableCellVariant.primaryLinkDecorated}
          />
          <RideTableCell
            value={formatDateTime(order.createdAt)}
            variant={RideTableCellVariant.secondaryText}
          />
          <RideTableCell value={order.city} variant={RideTableCellVariant.secondaryText} />
          <RideTableCell
            value={
              <RideBadgeStatus
                label={orderStatusToRideBadgeMap[order.status].label}
                color={orderStatusToRideBadgeMap[order.status].color}
                variant={RideBadgeVariantsEnum.OUTLINED}
              />
            }
          />
        </RideTableRow>
      ))}
    </div>
  );
};

export default withTranslationReady("nominee-dashboard")(ShelfCompanyOrdersTable);
