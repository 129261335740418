import React from "react";
import { useHistory } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { CompanySelectFieldEditContainer } from "../../components/Company/CompanySelectFieldEdit/CompanySelectFieldEditContainer";
import { registerCourt } from "../../../common/staticData/registerCourtOptions/registerCourt";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

const CompanyRegistryCourtScene = ({ t }: WithTranslation) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.registerCourt")} />
      <ModalFormLayout title={t("client:company.edit.registerCourt")} onBack={goBack}>
        <CompanySelectFieldEditContainer
          fieldName="registerCourt"
          section="registration"
          options={registerCourt}
        />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation(["client"])(CompanyRegistryCourtScene);
