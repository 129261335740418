import gql from "graphql-tag";

export const CREATE_THIRD_PARTY_TAX_SERVICE_COMPANY = gql`
  mutation createThirdPartyTaxServiceCompany(
    $taxAdvisorFirstName: String!
    $taxAdvisorLastName: String!
    $taxAdvisorEmail: String!
    $companyName: String!
  ) {
    createThirdPartyTaxServiceCompany(
      taxAdvisorFirstName: $taxAdvisorFirstName
      taxAdvisorLastName: $taxAdvisorLastName
      taxAdvisorEmail: $taxAdvisorEmail
      companyName: $companyName
    ) {
      feedback
    }
  }
`;

export const CREATE_WEPA_SUB_CONTRACT = gql`
  mutation createWepaSubContract($partnerTaxCompanyId: ID!, $thirdPartyTaxCompanyId: ID!) {
    createWepaSubContract(
      partnerTaxCompanyId: $partnerTaxCompanyId
      thirdPartyTaxCompanyId: $thirdPartyTaxCompanyId
    ) {
      feedback
    }
  }
`;
