import { WithTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { formatCurrency } from "../../../../../common/formatters";
import React from "react";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";

export enum PriceOperation {
  BASE,
  ADD,
  SUBTRACT
}

export interface PriceSectionProps {
  prices: PriceItem[];
  displayTotal?: boolean;
}

export interface PriceItem {
  key: string;
  value: number;
  operation: PriceOperation;
}

export const PriceSection = withTranslationReady(["generic"])(
  ({ prices, t, displayTotal = true }: PriceSectionProps & WithTranslation) => {
    const baseValue = prices.find((item) => item.operation === PriceOperation.BASE);

    const discounts = prices
      .filter((item) => item.operation === PriceOperation.SUBTRACT)
      .map((price) => price.value);
    const addons = prices
      .filter((item) => item.operation === PriceOperation.ADD)
      .map((price) => price.value);
    let finalPrice = 0.0;

    if (baseValue) {
      finalPrice = baseValue.value;
      discounts.forEach((discount) => (finalPrice -= discount));
      addons.forEach((addedValue) => (finalPrice += addedValue));
    }

    return (
      <div>
        <div className={"price-section__price-information"} data-testid="pricing-section">
          {baseValue && (
            <Row className={"price-section__price-information-row"}>
              <Col xs={"auto"}>{t(baseValue.key)}</Col>
              <Col className={"text-right"} data-testid={"base-value"}>
                {formatCurrency(baseValue.value)}
              </Col>
            </Row>
          )}
          {prices.map((item, index) =>
            item.operation !== PriceOperation.BASE ? (
              <Row className={"price-section__price-information-row"} key={index}>
                <Col xs={"auto"}>{t(item.key)}</Col>
                <Col
                  className={"text-right"}
                  data-testid={
                    item.operation === PriceOperation.SUBTRACT ? `discount-${index}` : ""
                  }>
                  {formatCurrency(item.value)}
                </Col>
              </Row>
            ) : (
              <></>
            )
          )}
          <hr className={"price-section__divider-2"} />
        </div>
        {displayTotal && (
          <div key={"total"}>
            <Row className={"price-section__total-price"} data-testid={"total-price-section"}>
              <Col xs={"auto"}>{t("generic:order-form.total-price")}</Col>
              <Col className={"text-right"} data-testid="total-price">
                {formatCurrency(finalPrice)}
              </Col>
            </Row>
            <hr className={"price-section__divider-3"} />
          </div>
        )}
      </div>
    );
  }
);
