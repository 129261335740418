import React from "react";
import { registerCourt } from "../../../common/staticData/registerCourtOptions/registerCourt";
import { ErrorMessage, FormikProvider } from "formik";
import ReactSelect from "react-select";
import { radiosFieldFor, selectFieldFor, textAreaFieldFor, textFieldFor } from "../commonFields";
import { TFunction } from "i18next";

export const ProjectNameField = ({ t, formik }) =>
  textFieldFor(t, formik, "projectName", "generic:projectName");

const customReactSelectStyles = {
  height: "48px",
  borderRadius: "10px",
  backgroundColor: "white",
  boxShadow: "none"
};

const customReactSelectStylesOnSuccess = {
  control: (provided) => ({
    ...provided,
    ...customReactSelectStyles,
    border: "solid 1px #48ffaf"
  })
};

const customReactSelectStylesOnError = {
  control: (provided) => ({
    ...provided,
    ...customReactSelectStyles,
    border: "solid 2px #e51c23"
  })
};

const customReactSelectStylesInitial = {
  control: (provided) => ({
    ...provided,
    ...customReactSelectStyles,
    border: "solid 2px #eaebeb"
  })
};

export const RegistryCourtField = ({ t, formik, placeholder = t("generic:please-choose") }) => {
  let customStyle = customReactSelectStylesInitial;
  const isInvalid = formik.touched["registerCourt"] && !!formik.errors["registerCourt"];
  const isValid = formik.touched["registerCourt"] && !formik.errors["registerCourt"];

  if (isInvalid) {
    customStyle = customReactSelectStylesOnError;
  }

  if (isValid) {
    customStyle = customReactSelectStylesOnSuccess;
  }

  if (isValid === undefined && isInvalid === undefined) {
    customStyle = customReactSelectStylesInitial;
  }

  return (
    <div data-testid="registerCourt-select-container">
      <label>{t("generic:registerCourt")}</label>
      <ReactSelect
        name={`registerCourt`}
        options={registerCourt}
        data-testid="registerCourt-select"
        styles={customStyle}
        isSearchable={true}
        placeholder={placeholder}
        value={registerCourt.find((option) => option.value === `${formik.values.registerCourt}`)}
        onChange={(selectedOption) => formik.handleChange(`registerCourt`)(selectedOption?.value)}
        onBlur={() => formik.setFieldTouched("registerCourt", true)}
      />
      <FormikProvider value={formik}>
        <ErrorMessage name={`registerCourt`}>
          {(error) => (
            <div className="invalid-feedback d-block" data-testid="registerCourt-invalid-feedback">
              {t(error)}
            </div>
          )}
        </ErrorMessage>
      </FormikProvider>
    </div>
  );
};

interface TextFieldProps {
  t: TFunction;
  formik: any;
  className?: string;
  legalForms?: any;
}

interface SelectFieldProps extends TextFieldProps {
  name: string;
  options: Array<{ label: string; value: any }>;
}

export const TaxNumberField = ({ t, formik }) =>
  textFieldFor(t, formik, "taxNumber", "generic:taxNumber");

export const EconomicIdField = ({ t, formik }) =>
  textFieldFor(t, formik, "economicId", "generic:economic-id");

export const AdvisorNumberField = ({ t, formik }) =>
  textFieldFor(t, formik, "advisorNumber", "generic:advisorNumber");

export const LEIField = ({ t, formik }) => textFieldFor(t, formik, "lei", "generic:lei");

export const VatNumberField = ({ t, formik }) =>
  textFieldFor(t, formik, "vatNumber", "generic:vatNumber");

export const LegalFormField = ({ t, formik, legalForms, className }: TextFieldProps) =>
  selectFieldFor(t, formik, "legalForm", "generic:legal-form", legalForms, { className });

export const CompanyRegistrationField = ({ t, formik, className }: TextFieldProps) =>
  textFieldFor(t, formik, "registration", "generic:registration", { className });

export const TokenizedSharesField = ({ t, formik }) =>
  textFieldFor(t, formik, "tokenizedShares", "generic:tokenizedShares", {
    type: "number"
  });

export const CompanyValueField = ({ t, formik }) =>
  textFieldFor(t, formik, "companyValue", "user-company:company-value", {
    type: "number",
    suffix: "€",
    required: true
  });

export const CompanyMyShareField = ({ t, formik }) =>
  textFieldFor(t, formik, "percentageOwnership", "realEstate:my-share", {
    type: "number",
    suffix: "%",
    required: true
  });

export const TokenizedStatusField = ({ t, formik }) =>
  selectFieldFor(t, formik, "tokenized", "generic:tokenized", [
    { title: t("generic:yes"), value: "YES" },
    { title: t("generic:no"), value: "NO" }
  ]);

export const TradeTaxFreeStatusField = ({ t, formik }) =>
  selectFieldFor(t, formik, "tradeTaxFree", "generic:trade-tax-free", [
    { title: t("generic:yes"), value: "YES" },
    { title: t("generic:no"), value: "NO" }
  ]);

export const TradeTaxFreeRadioField = ({ t, handleTaxFreeTradeChange, tradeTaxFreeType }) =>
  radiosFieldFor(
    t("generic:trade-tax-exempt"),
    "tradeTaxFree",
    false,
    [{ label: t("generic:no") }, { label: t("generic:yes") }],
    handleTaxFreeTradeChange,
    tradeTaxFreeType
  );

export const CapitalContributionField = ({ t, formik }) =>
  textFieldFor(t, formik, "capitalContribution", "generic:capital-contribution", {
    type: "number"
  });

export const PublishedStatusField = ({ t, formik }) =>
  selectFieldFor(t, formik, "status", "generic:publish-status", [
    { title: t("generic:publishStatus.Draft"), value: "Draft" },
    { title: t("generic:publishStatus.Published"), value: "Published" },
    { title: t("generic:publishStatus.Completed"), value: "Completed" },
    { title: t("generic:publishStatus.Disclosed"), value: "Disclosed" },
    { title: t("generic:publishStatus.Undisclosed"), value: "Undisclosed" }
  ]);

export const BoxLinkField = ({ t, formik }) =>
  textFieldFor(t, formik, "boxLink", "generic:boxLink");

export const CompanyTypeField = ({ t, formik, companyTypes }) =>
  selectFieldFor(t, formik, "type", "user-company:company-types", companyTypes);

export const BusinessPurposeShortField = ({ t, formik }) =>
  textFieldFor(t, formik, "businessPurposeShort", "generic:company-business-purpose-short", {
    required: false
  });

export const BusinessPurposeField = ({ t, formik }) =>
  textAreaFieldFor(formik, "businessPurpose", t("generic:company-business-purpose"), "", 8, false, {
    required: false
  });

export const EmployeesField = ({ t, formik }) =>
  textFieldFor(t, formik, "employees", "generic:employees", {
    required: false
  });

export const ClientNumberField = ({ t, formik }) =>
  textFieldFor(t, formik, "clientNumber", "nominee-dashboard:company.tax-advisor-client-number", {
    required: false
  });

export const SelectCompanyCategory = ({ formik, name, options }: SelectFieldProps) => (
  <span data-testid={`${name}-select-container`}>
    <ReactSelect
      name={name}
      options={options}
      isSearchable={true}
      defaultValue={formik.values[name]}
      value={options.find((option) => option.value === formik.values[name])}
      onChange={(selectedOption) => formik.handleChange(name)(selectedOption?.value)}
      onBlur={() => formik.setFieldTouched(name, true)}
    />
  </span>
);
