import gql from "graphql-tag";

export const FINALIZE_ORDER = gql`
  mutation FinalizeOrder($id: ID!, $submissionId: String!, $ownerType: OrderOwnerType) {
    finalizeOrder(id: $id, submissionId: $submissionId, ownerType: $ownerType) {
      feedback
      token
      id
    }
  }
`;
