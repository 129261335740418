import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { GrundungServiceStatusEnum } from "lib/models/client/GmbH";
import {
  RideBadgeColorsEnum,
  RideBadgeIconEnum,
  RideBadgeSizeVariant
} from "uiLibrary/components/RideBadgeStatus/RideBadgeStatus";
import { GmbHServiceStatusBadge } from "../GmbHServiceBadge/GmbHServiceBadge";

interface GmbHGrundungServiceBadgeProps extends WithTranslation {
  status: GrundungServiceStatusEnum;
}

const GmbHGrundungServiceBadge = ({ t, status }: GmbHGrundungServiceBadgeProps) => {
  if (status === GrundungServiceStatusEnum.NOT_ACQUIRED) {
    return null;
  }

  const { iconVariant, labelKey, color, size } = grundungServiceBadgeProps(status);

  return (
    <GmbHServiceStatusBadge
      dataTestId="gmbh-grundung-service-badge"
      iconVariant={iconVariant}
      label={t(labelKey)}
      color={color}
      size={size}
    />
  );
};

export default withTranslationReady(["generic"])(GmbHGrundungServiceBadge);

export interface ServiceStatusBadgeProps {
  iconVariant: RideBadgeIconEnum;
  labelKey: string;
  color?: RideBadgeColorsEnum;
  size?: RideBadgeSizeVariant;
}

const grundungServiceBadgeProps = (
  status: Exclude<GrundungServiceStatusEnum, GrundungServiceStatusEnum.NOT_ACQUIRED>
): ServiceStatusBadgeProps => {
  switch (status) {
    case GrundungServiceStatusEnum.FOUNDED:
      return {
        iconVariant: RideBadgeIconEnum.GREEN,
        labelKey: "generic:gmbh-badge-status.grundung-service.active"
      };
    case GrundungServiceStatusEnum.IN_FOUNDATION:
      return {
        iconVariant: RideBadgeIconEnum.PURPLE,
        labelKey: "generic:gmbh-badge-status.grundung-service.in-foundation",
        color: RideBadgeColorsEnum.ACCENT,
        size: RideBadgeSizeVariant.MEDIUM
      };
    case GrundungServiceStatusEnum.ORDER_PENDING:
      return {
        iconVariant: RideBadgeIconEnum.ORANGE,
        labelKey: "generic:gmbh-badge-status.grundung-service.draft"
      };
  }
};
