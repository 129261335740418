import React from "react";
import { NomineeCompanyDetails_companyById } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { Col, Row } from "react-bootstrap";

interface BrokerageSurveySectionProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
}

const BrokerageSurveySection = ({ company, t }: BrokerageSurveySectionProps) => {
  if (!company.brokerageSurvey) return null;

  return (
    <div data-testid="brokerage-survey-section">
      <h2 data-testid="brokerage-survey-title">{t("brokerSurvey:brokerSurvey")}</h2>
      <Row>
        <Col xs={12} md={6} data-testid="brokerage-survey-answer">
          <dl>
            <dt data-testid="brokerage-survey-answer-label">{t("brokerSurvey:answer")}</dt>
            <dd data-testid="brokerage-survey-answer-value">
              {t(`brokerSurvey:${company.brokerageSurvey.answer}`)}
            </dd>
          </dl>
        </Col>
        {company.brokerageSurvey.reason && (
          <Col xs={12} md={6} data-testid="brokerage-survey-reason">
            <dl>
              <dt data-testid="brokerage-survey-reason-label">
                {t(company.brokerageSurvey.reason)}
              </dt>
              <dd data-testid="brokerage-survey-reason-value">
                {company.brokerageSurvey.otherReason}
              </dd>
            </dl>
          </Col>
        )}
        {company.brokerageSurvey.selectedBrokerBank && (
          <Col xs={12} md={6} data-testid="brokerage-survey-broker">
            <dl>
              <dt data-testid="brokerage-survey-broker-label">
                {t(company.brokerageSurvey.selectedBrokerBank)}
              </dt>
              <dd data-testid="brokerage-survey-broker-value">
                {company.brokerageSurvey.otherBrokerBank}
              </dd>
            </dl>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default withTranslationReady("brokerSurvey")(BrokerageSurveySection);
