import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export interface FileDropzone extends File {
  path: string;
}

export default function Dropzone({
  handleOnDrop,
  multiple,
  dragLabel,
  dropLabel
}: {
  handleOnDrop(files: FileDropzone[]): void;
  multiple?: boolean;
  dragLabel: string;
  dropLabel: string;
}) {
  const onDrop = useCallback((acceptedFiles) => handleOnDrop(acceptedFiles), [handleOnDrop]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop
  });

  return (
    <>
      <div {...getRootProps()} className="clickable">
        <input {...getInputProps({ multiple })} data-testid="dropzone-input" />
        {isDragActive && !isDragReject ? (
          <p className="drop-area">{dragLabel}...</p>
        ) : (
          <p className="drop-area">{dropLabel}</p>
        )}
      </div>
    </>
  );
}
