import { WithTranslation, withTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { LegalForm } from "global-query-types";
import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { CompanyTitleInput } from "components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/CompanyTitleInput";
import { CompanyLegalRegistration } from "components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/LegalRegistrationFields";
import { BusinessAddressInput } from "sharedComponents/BusinessAddressInput/BusinessAddressInput";
import LockSwitchCheckbox from "components/nominee/common/LockSwitchCheckbox";
import { EntryDateFields } from "components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/EntryDateFields";
import { ClientNumberField, EmployeesField } from "components/generic/company-form/fields";
import { CompanyRegistrationNumbers } from "components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/CompanyRegistrationNumbers";
import { getRegisterCourtDirectoryURL } from "lib/dataAdapters/getRegisterCourtDirectoryURL/getRegisterCourtDirectoryURL";
import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import { LiabilityInput } from "components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/LiabilityInput";
import { FiscalYearInput } from "components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/FiscalYearInput";
import { TestCompanyTypesField } from "components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/TestCompanyTypesField";
import { FoundationDateInput } from "../../../../components/Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/FoundationDateInput";

interface EditCompanyFormProps extends WithTranslation {
  formik: FormikProps<any>;
  legalForms: LegalForm[];
  company: NomineeCompanyDetails_companyById;
}

const EditCompanyDetails = ({ formik, legalForms, company, t }: EditCompanyFormProps) => {
  const registerCourt = company.registerCourt;
  const address = company.businessAddress;

  const registerCourtDirectoryURL = getRegisterCourtDirectoryURL(address);

  return (
    <Container data-testid="company-edit-form">
      <Row className="mb-4">
        <Col>
          <LockSwitchCheckbox formik={formik} />
        </Col>
      </Row>

      <CompanyTitleInput t={t} formik={formik} legalForms={legalForms} />

      <CompanyLegalRegistration className="mt-4" t={t} formik={formik} />

      {!registerCourt && registerCourtDirectoryURL && (
        <Row data-testid="register-court-directory-url" className="mt-n3 pb-3">
          <Col>
            <a
              href={registerCourtDirectoryURL}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="external-register-court-directory-url">
              <Badge variant="primary">{t("nominee-dashboard:company.register-court-url")}</Badge>
            </a>
          </Col>
        </Row>
      )}

      <CompanyRegistrationNumbers t={t} formik={formik} />

      <BusinessAddressInput t={t} formik={formik} />

      <EntryDateFields t={t} formik={formik} />

      <Row>
        <Col>
          <EmployeesField t={t} formik={formik} />
        </Col>
        <Col>
          <ClientNumberField t={t} formik={formik} />
        </Col>
      </Row>

      <Row>
        <Col>
          <LiabilityInput t={t} formik={formik} />
        </Col>

        <Col>
          <FiscalYearInput t={t} formik={formik} />
        </Col>

        <Col>
          <FoundationDateInput t={t} formik={formik} />
        </Col>

        <Col>
          <TestCompanyTypesField t={t} formik={formik} />
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation(["generic", "nominee-dashboard", "client"])(EditCompanyDetails);
