import React from "react";
import {
  RideBadgeColorsEnum,
  RideBadgeSizeVariant,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import {
  ButtonHeight,
  ButtonRadius,
  ButtonVariant,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export interface CardWithButtonProps {
  onClick: () => void;
  title: string;
  description: string;
  buttonTitle: string;
  badgeText: string;
  "data-testid"?: string;
}

const CardWithButton = ({
  title,
  description,
  onClick,
  buttonTitle,
  badgeText,
  "data-testid": dataTestId
}: CardWithButtonProps) => {
  return (
    <div data-testid={dataTestId} className="card-with-button">
      <div className="card-with-button--wrapper">
        <RideBadgeStatus
          color={RideBadgeColorsEnum.GMBH_BADGE_STATUS}
          variant={RideBadgeVariantsEnum.SOLID}
          sizeVariant={RideBadgeSizeVariant.SMALL}
          label={badgeText}
        />
      </div>
      <div>
        <div data-testid="title" className="card-with-button--text">
          {title}
        </div>
        <div data-testid="description" className="card-with-button--label">
          {description}
        </div>
      </div>

      <RideButtonDeprecated
        data-testid={`${dataTestId}-button`}
        className="card-with-button--button"
        onClick={onClick}
        variant={ButtonVariant.Primary}
        type="button"
        radius={ButtonRadius.Soft}
        width={ButtonWidth.FULL}
        height={ButtonHeight.LARGE}>
        {buttonTitle}
      </RideButtonDeprecated>
    </div>
  );
};

export default CardWithButton;
