import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { AllDiscountCodesQuery_allDiscountCodes } from "../../../lib/api/queries/graphql/AllDiscountCodesQuery";
import { UpdateDiscountCodeVariables } from "../../../lib/api/mutations/graphql/UpdateDiscountCode";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { Col, Row } from "react-bootstrap";

interface ReferralCodeBlacklistingProps extends WithTranslation {
  allCodes: AllDiscountCodesQuery_allDiscountCodes[];
  code: string;
  updateReferralCode: (data: UpdateDiscountCodeVariables) => Promise<void>;
}

const ReferralCodeBlacklisting = ({
  t,
  allCodes,
  code,
  updateReferralCode
}: ReferralCodeBlacklistingProps) => {
  const referralCode = allCodes.find((r) => r.code === code);
  const { id, blacklisted } = referralCode ?? {};
  if (!id) return <>{t("nominee-dashboard:no-data-notice")}</>;

  const toggleBlacklist = () => updateReferralCode({ id, blacklisted: !blacklisted });

  const statuses = {
    blacklisted: {
      label: "blacklisted",
      color: RideBadgeColorsEnum.DARK,
      action: (
        <RideButtonDeprecated
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          data-testid={"re-activate"}
          onClick={toggleBlacklist}>
          {t("nominee-dashboard:re-activate")}
        </RideButtonDeprecated>
      )
    },
    active: {
      label: "active",
      color: RideBadgeColorsEnum.SUCCESS,
      action: (
        <RideButtonDeprecated
          variant={ButtonVariant.Danger}
          size={ButtonSize.Small}
          data-testid={"blacklist"}
          onClick={toggleBlacklist}>
          {t("nominee-dashboard:blacklist")}
        </RideButtonDeprecated>
      )
    }
  };

  const status = statuses[blacklisted ? "blacklisted" : "active"];

  return (
    <Row className="mb-3">
      <Col md={3}>
        <RideBadgeStatus
          label={t(`nominee-dashboard:${status.label}`)}
          color={status.color}
          variant={RideBadgeVariantsEnum.SOLID}
          dataTestId={"referral-code-status"}
        />
      </Col>

      <Col md={3}>{status.action}</Col>
    </Row>
  );
};

export default withTranslationReady(["nominee-dashboard"])(ReferralCodeBlacklisting);
