import React from "react";
import { MyGmbHsQuery_companies } from "../../../components/client/MyGmbHs/graphql/MyGmbHsQuery";
import NoResultsPlaceholder from "../../../sharedComponents/NoResultsPlaceholder/NoResultsPlaceholder";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { BankType, FeatureFlags } from "global-query-types";
import { useHistory } from "react-router";
import { BankAccount, Company } from "lib/types/types";
import BankAccountCard from "../Banking/BankAccountCard/BankAccountCard";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";

interface BankAccountsListProps extends WithTranslation {
  companies: MyGmbHsQuery_companies[];
  onEditBankAccount?: (bankAccount: BankAccount) => void;
}

interface BankAccountCardInput {
  bankAccountId: string;
  accountName: string | null;
  iban: string | null;
  bankProvider: BankType;
  company: Company;
}

const BankAccountsList = ({ t, companies, onEditBankAccount }: BankAccountsListProps) => {
  let bankAccounts: BankAccountCardInput[] = [];
  const companiesWithBankAccounts = companies.filter((company) => company.bankAccounts);
  const history = useHistory();

  const bankingPageInCompanyNavigation = FeatureFlagService.instance.isEnabled(
    FeatureFlags.BankingPageInCompanyNavigation
  );

  const handleEdit = (bankAccountProps: BankAccountCardInput) => {
    const bankAccount = companiesWithBankAccounts
      .flatMap((a) => a.bankAccounts)
      .find((a) => a?.id === bankAccountProps.bankAccountId);

    if (bankingPageInCompanyNavigation && bankAccount) {
      onEditBankAccount?.(bankAccount);
    } else {
      history.push(`/client/banking/edit/${bankAccount?.id}`);
    }
  };

  companiesWithBankAccounts.forEach((company) =>
    company.bankAccounts?.forEach((bankAccount) => {
      if (bankAccount.bankProvider) {
        bankAccounts.push({
          bankAccountId: bankAccount.id,
          accountName: bankAccount.name,
          iban: bankAccount.iban,
          bankProvider: bankAccount.bankProvider,
          company
        });
      }
    })
  );

  if (!bankAccounts.length) {
    return <NoResultsPlaceholder text={t("client:banking.no-results")} />;
  }

  return (
    <div data-testid={"bank-account-list"}>
      {bankAccounts.map((bankAccountProps, index) => (
        <BankAccountCard
          bankAccountId={bankAccountProps.bankAccountId}
          key={index}
          accountName={bankAccountProps.accountName}
          iban={bankAccountProps.iban}
          bank={bankAccountProps.bankProvider}
          company={bankAccountProps.company}
          onEdit={() => {
            handleEdit(bankAccountProps);
          }}
        />
      ))}
    </div>
  );
};

export default withTranslationReady("client")(BankAccountsList);
