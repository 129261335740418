import gql from "graphql-tag";

export const CREATE_LOW_TAX_BROKER_ORDER = gql`
  mutation CreateLowTaxBrokerOrder($companyId: String!) {
    createLowTaxBrokerOrder(companyId: $companyId) {
      id
      submissionId
      productType
      ownerType
      ownerId
      entityType
      entityId
      status
      extra
      createdAt
      updatedAt
      transferredAt
    }
  }
`;
