import React from "react";
import { FlowTypeEnum } from "../../../../global-query-types";
import { TFunction } from "react-i18next";

interface OrderAGBAgreementProps {
  t: TFunction;
  currentFlowType?: FlowTypeEnum;
}

export const OrderAGBAgreement = ({ t, currentFlowType }: OrderAGBAgreementProps) => {
  const AGBLink =
    process.env.REACT_APP_RIDE_AGB || "https://www.ride.capital/agb?utm_source=app&utm_medium=app";

  if (currentFlowType === FlowTypeEnum.WEPA_SERVICE)
    return (
      <span className="agb-agreement">
        <span>{t("wepa-service:agree-assignment-1")}</span>
        <a href={AGBLink} target="_blank" rel="noopener noreferrer">
          {t("wepa-service:agree-assignment-2")}
        </a>
        <span>{t("wepa-service:agree-assignment-3")}</span>
      </span>
    );

  return (
    <span className="agb-agreement">
      <span>{t("trader-gmbh:agree-assignment-1")}</span>
      <a href={AGBLink} target="_blank" rel="noopener noreferrer">
        {t("trader-gmbh:agree-assignment-2")}
      </a>
      <span>{t("trader-gmbh:agree-assignment-3")}</span>
    </span>
  );
};
