import React, { HTMLProps } from "react";
import { Button } from "react-bootstrap";

interface ClientSubmitProps extends HTMLProps<any> {
  className?: string;
  testId?: string;
}

export const ClientSubmit = ({ children, className, testId }: ClientSubmitProps) => {
  const classNameBase = "client-submit";
  const fullClassName = className ? `${classNameBase} ${className}` : classNameBase;
  return (
    <Button type={"submit"} data-testid={testId ?? "client-submit"} className={fullClassName}>
      {children}
    </Button>
  );
};
