import React, { ReactElement } from "react";
import { Image } from "react-bootstrap";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface AuthSuccessMessageProps {
  onSubmit: () => Promise<void>;
  message: string;
  image: string;
  submitText: string;
  "data-testid"?: string;
  buttonVariant?: ButtonVariant;
  infoText?: ReactElement;
  className?: string;
}

export const AuthMessage = ({
  onSubmit,
  message,
  submitText,
  image,
  "data-testid": testid,
  buttonVariant = ButtonVariant.Primary,
  infoText,
  className = ""
}: AuthSuccessMessageProps) => {
  return (
    <div className={`auth-message ${className}`} data-testid={`${testid}-message`}>
      <Image data-testid={`${testid}-image`} src={image} alt="icon" />
      <div className={`auth-message__text ${className}__text`} data-testid={testid}>
        {message}
      </div>
      {infoText && <>{infoText}</>}
      <RideButtonDeprecated
        variant={buttonVariant}
        size={ButtonSize.Big}
        data-testid={`${testid}-submit`}
        onClick={onSubmit}>
        {submitText}
      </RideButtonDeprecated>
    </div>
  );
};
