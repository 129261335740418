import React from "react";
import {
  AdminServiceStatusEnum,
  GrundungServiceStatusEnum,
  WepaStatusEnum
} from "lib/models/client/GmbH";
import GmbHGrundungServiceBadge from "../GmbHGrundungServiceBadge/GmbHGrundungServiceBadge";
import GmbHAdminServiceBadge from "../GmbHAdminServiceBadge/GmbHAdminServiceBadge";
import GmbHWepaBadge from "../GmbHWepaBadge/GmbHWepaBadge";

export interface GmbHServicesStatus {
  get grundungServiceStatus(): GrundungServiceStatusEnum;
  get adminServiceStatus(): AdminServiceStatusEnum;
  get wepaStatus(): WepaStatusEnum;
  get isWepaClient(): boolean;
}

interface GmbHBadgesProps {
  gmbhServicesStatus: GmbHServicesStatus;
  isCurrentUserDirector: boolean;
  addAdminServiceCallback: () => void;
  activateWepaCallback: () => void;
}

export const GmbHBadges = ({
  gmbhServicesStatus,
  isCurrentUserDirector,
  addAdminServiceCallback,
  activateWepaCallback
}: GmbHBadgesProps) => {
  const { grundungServiceStatus, adminServiceStatus } = gmbhServicesStatus;

  const doesNotHaveGrundungService =
    grundungServiceStatus === GrundungServiceStatusEnum.NOT_ACQUIRED;

  const grundungServiceOrderIsReady =
    grundungServiceStatus === GrundungServiceStatusEnum.FOUNDED ||
    grundungServiceStatus === GrundungServiceStatusEnum.IN_FOUNDATION;

  const hasAdminService =
    adminServiceStatus !== AdminServiceStatusEnum.NOT_ACQUIRED &&
    adminServiceStatus !== AdminServiceStatusEnum.CANCELED;

  const hasWepaService = gmbhServicesStatus.isWepaClient;

  return (
    <div className="gmbh-badges" data-testid="gmbh-badges">
      <GmbHGrundungServiceBadge status={gmbhServicesStatus.grundungServiceStatus} />
      <GmbHAdminServiceBadge
        onClick={addAdminServiceCallback}
        status={gmbhServicesStatus.adminServiceStatus}
        canOrder={
          (doesNotHaveGrundungService || grundungServiceOrderIsReady) && isCurrentUserDirector
        }
      />
      <GmbHWepaBadge
        status={gmbhServicesStatus.wepaStatus}
        canActivate={hasAdminService || hasWepaService}
        onClick={activateWepaCallback}
      />
    </div>
  );
};
