import { toFiscalYearDataCompletenessList } from "lib/dataAdapters/toFiscalYearDataCompletenessList/toFiscalYearDataCompletenessList";
import { GetFiscalYearsByCompanyIdQuery } from "lib/api/queries/GetFiscalYearsByCompanyId";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import React from "react";
import FiscalYearDataUploadConfirmation, {
  FiscalYearDataCompleteness
} from "./FiscalYearDataUploadConfirmation";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_FISCAL_YEAR_BY_ID } from "lib/api/mutations/UPDATE_FISCAL_YEAR_BY_ID";
import {
  updateFiscalYearById,
  updateFiscalYearByIdVariables
} from "lib/api/mutations/graphql/updateFiscalYearById";
import { UserRole } from "../../../global-query-types";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

interface CompanyIdWithFiscalYear extends Pick<GmbHDetails, "id" | "fiscalYear"> {}

export interface FiscalYearDataUploadConfirmationContainerProps {
  company: CompanyIdWithFiscalYear;
  role: UserRole;
}

export const FiscalYearDataUploadConfirmationContainer = ({
  company,
  role
}: FiscalYearDataUploadConfirmationContainerProps) => {
  const [updateFiscalYearByIdMutation] = useMutation<
    updateFiscalYearById,
    updateFiscalYearByIdVariables
  >(UPDATE_FISCAL_YEAR_BY_ID);

  const fiscalYearsByCompanyIdRequest = GetFiscalYearsByCompanyIdQuery(company.id);
  const fiscalYearsRecords = fiscalYearsByCompanyIdRequest.data?.getFiscalYearsByCompanyId ?? [];
  const fiscalYearsParsedRecords: FiscalYearDataCompleteness[] = toFiscalYearDataCompletenessList(
    company.fiscalYear,
    fiscalYearsRecords
  );

  const onChangeConfirmation = async (id: string, value: boolean) => {
    await updateFiscalYearByIdMutation({
      variables: { id: id, dataUploadCompleted: value }
    });
    await fiscalYearsByCompanyIdRequest.refetch();
  };

  return (
    <RequestFeedbackHandler requests={[fiscalYearsByCompanyIdRequest]}>
      {!!fiscalYearsRecords.length && (
        <FiscalYearDataUploadConfirmation
          fiscalYears={fiscalYearsParsedRecords}
          onChangeConfirmation={onChangeConfirmation}
          role={role}
          companyId={company.id}
        />
      )}
    </RequestFeedbackHandler>
  );
};
