import React from "react";
import { NotaryLinkField } from "../../../public/CompanyCreation/fields";
import { FormikProps } from "formik";
import { NotaryDocumentType } from "../../../../global-query-types";
import { TFunction } from "i18next";

interface EditNotaryLinksComponentProps {
  t: TFunction;
  formik: FormikProps<any>;
}

export const EditNotaryLinksComponent = ({ formik, t }: EditNotaryLinksComponentProps) => {
  const notaryDocumentTypes = Object.keys(NotaryDocumentType);
  return (
    <div data-testid="edit-notary-links">
      {notaryDocumentTypes.map((type) => {
        return (
          <div data-testid="edit-notary-link-type" key={type}>
            <NotaryLinkField name={type} t={t} formik={formik}></NotaryLinkField>
          </div>
        );
      })}
    </div>
  );
};
