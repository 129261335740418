import { companyName, CompanyName } from "../../lib/formatters/companyFormatter";
import { personNameWithFallback, PersonName } from "../../lib/formatters/personFormatter";

/**
 * @deprecated
 * @see CompanyName
 *      from /lib/formatters/companyFormatter.ts
 */
export interface CompanyNameParams extends CompanyName {}

/**
 * @deprecated
 * @see companyName
 *      from /lib/formatters/companyFormatter.ts
 */
export const formatCompanyName = (t, company: CompanyName) => companyName(company);

/**
 * @deprecated
 * @see PersonName
 *      from /lib/formatters/personFormatter.ts
 */
export interface PersonNameParams extends PersonName {}

/**
 * @deprecated
 * @see personNameWithFallback
 *      from /lib/formatters/personFormatter.ts
 */
export const formatPersonName = (t, person: PersonNameParams) => personNameWithFallback(person);
