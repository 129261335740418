import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { RealEstateQueryById_realEstatesById } from "./graphql/RealEstateQueryById";
import { RealEstateQueryByCompanyId_realEstateByCompanyId } from "./graphql/RealEstateQueryByCompanyId";
import { companyDetailsByCompanyId_companyById } from "./graphql/companyDetailsByCompanyId";
import LoadingAlert from "../generic/LoadingAlert";
import ErrorAlertLegacy from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import DetailPageView from "./DetailPageView";
import { GetRealEstateGallery } from "./getRealEstateGallery";

interface InvestmentsDetailsProps extends WithTranslation {
  detailRealEstateData: RealEstateQueryById_realEstatesById | null;
  detailInvestmentRealEstateData: RealEstateQueryByCompanyId_realEstateByCompanyId | null;
  investmentDetail: companyDetailsByCompanyId_companyById | null;
  backUrl: string;
}

export const InvestmentsDetails: React.FunctionComponent<InvestmentsDetailsProps> = (props) => {
  const { t, backUrl, detailInvestmentRealEstateData, investmentDetail } = props;

  const gallery =
    detailInvestmentRealEstateData && GetRealEstateGallery(detailInvestmentRealEstateData!.id);

  if (gallery!.loading) return <LoadingAlert t={t} message={t("generic:loading-message")} />;

  if (gallery!.error) return <ErrorAlertLegacy t={t} error={gallery!.error.message} />;

  return (
    <>
      <DetailPageView
        detailInvestmentRealEstateData={detailInvestmentRealEstateData!}
        investmentDetail={investmentDetail!}
        detailRealEstateData={null}
        gallery={gallery?.data}
        refetchGallery={gallery?.refetch || (() => {})}
        backUrl={backUrl}
      />
    </>
  );
};
export default withTranslation(["realEstate", "wallet", "errorCodes", "generic"])(
  InvestmentsDetails
);
