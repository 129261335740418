import React from "react";
import { TFunction } from "i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormField from "./FormField";

interface DateTimeFieldProps {
  t: TFunction;
  dateFormat?: string;
  type?: string;
  minDate?: Date;
  maxDate?: Date;
  openToDate?: Date;
  name: string;
  label: string;
  count?: number;
  onChange?: any;
  value: Date;
  isValid?: boolean;
  error?: string;
  input?: any;
  options?: string[] | { [key: string]: string } | Array<{ text: string; value: string }>;
  formText?: string;
  className?: any;
  customTimeInput?: any;
}

export const DateTimeField = ({
  label,
  className,
  value,
  dateFormat,
  maxDate,
  minDate,
  openToDate,
  onChange,
  error,
  name,
  t
}: DateTimeFieldProps) => {
  return (
    <FormField name={name} label={label} t={t} testid={`${name}-date-container`}>
      <DatePicker
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        showTimeSelect
        timeIntervals={15}
        dropdownMode="select"
        className={`${className} ${error ? "is-invalid" : ""}`}
        selected={value}
        dateFormat={dateFormat ?? "dd/MM/yyyy HH:mm"}
        maxDate={maxDate}
        minDate={minDate}
        openToDate={openToDate}
        onChange={onChange}
        name={name}
      />
    </FormField>
  );
};

export default DateTimeField;
