import {
  GetInvestmentById_getInvestmentById,
  GetInvestmentById_getInvestmentById_asset_brokerageAccountSet_companyAnnualData
} from "../../api/queries/graphql/GetInvestmentById";
import { round, sum } from "lodash";

export const toCashMultipleData = (
  investment: GetInvestmentById_getInvestmentById
): { before?: number; after?: number } => {
  let annualData = investment.asset.brokerageAccountSet?.companyAnnualData ?? [];

  if (annualData.length === 0) {
    return {};
  }

  let safeDivide = (nlv: null | number, deposit: null | number, payout: null | number) => {
    if (!nlv || !deposit) return 0;
    return nlv / (deposit + (payout ?? 0));
  };

  let before =
    sum(annualData.map((i) => safeDivide(i?.nlvPreTax, i?.depositsTotal, i?.payoutsTotal))) /
    annualData.length;
  let after =
    sum(annualData.map((i) => safeDivide(i.nlvPostTax, i.depositsTotal, i.payoutsTotal))) /
    annualData.length;

  return {
    before: round(before),
    after: round(after)
  };
};

export const toYearlyIIRData = (investment: GetInvestmentById_getInvestmentById) => {
  const result = {};

  let annualData = investment.asset.brokerageAccountSet?.companyAnnualData ?? [];

  for (let item of annualData) {
    const fiscalYearLabel = getFiscalYearLabel(item.dateFrom);

    result[fiscalYearLabel] = {
      before: item.xirrPreTax,
      after: item.xirrPostTax
    };
  }

  return result;
};

export const toYearlyAnnualProfitData = (investment: GetInvestmentById_getInvestmentById) => {
  const result = {};

  let annualData = investment.asset.brokerageAccountSet?.companyAnnualData ?? [];
  let prevItem:
    | GetInvestmentById_getInvestmentById_asset_brokerageAccountSet_companyAnnualData
    | undefined;

  for (let item of annualData) {
    const fiscalYearLabel = getFiscalYearLabel(item.dateFrom);

    result[fiscalYearLabel] = {
      before:
        (item.nlvPreTax ?? 0) -
        (prevItem?.nlvPreTax ?? 0) -
        (item.depositsTotal ?? 0) -
        (item.payoutsTotal ?? 0),
      after:
        (item.nlvPostTax ?? 0) -
        (prevItem?.nlvPostTax ?? 0) -
        (item.depositsTotal ?? 0) -
        (item.payoutsTotal ?? 0)
    };

    prevItem = item;
  }

  return result;
};

export const toTotalIRR = (investment: GetInvestmentById_getInvestmentById) => {
  let annualData = investment.asset.brokerageAccountSet?.companyAnnualData ?? [];

  annualData = annualData.filter(
    (data) => !isNaN(data?.xirrPreTax ?? NaN) && !isNaN(data?.xirrPostTax ?? NaN)
  );

  const xirrPreTax = annualData.map((data) => data.xirrPreTax);
  const xirrPostTax = annualData.map((data) => data.xirrPostTax);

  return {
    yearsLabel: getTotalYearsLabel(annualData),
    before: round(sum(xirrPreTax) / xirrPreTax.length, 2),
    after: round(sum(xirrPostTax) / xirrPreTax.length, 2)
  };
};

export const toTotalAnnualProfit = (investment: GetInvestmentById_getInvestmentById) => {
  let annualData = investment.asset.brokerageAccountSet?.companyAnnualData ?? [];
  let sortedAnnualData = annualData.sort((a, b) => {
    const firstTime = new Date(a.dateFrom).getTime();
    const secondTime = new Date(b.dateFrom).getTime();

    return firstTime < secondTime ? -1 : 1;
  });

  const lastIndex = sortedAnnualData.length - 1;

  if (lastIndex < 0) {
    return {};
  }

  const firstNlvPreTax = sortedAnnualData[0].nlvPreTax;
  const lastNlvPreTax = sortedAnnualData[lastIndex].nlvPreTax;

  const firstNlvPostTax = sortedAnnualData[0].nlvPostTax;
  const lastNlvPostTax = sortedAnnualData[lastIndex].nlvPostTax;

  const amount =
    sum(annualData.map((a) => (a.payoutsTotal ?? 0) * -1)) -
    sum(annualData.map((a) => a.depositsTotal));

  return {
    yearsLabel: getTotalYearsLabel(annualData),
    before: round(lastNlvPreTax! - firstNlvPreTax! - amount, 2),
    after: round(lastNlvPostTax! - firstNlvPostTax! - amount, 2)
  };
};

export const checkFiscalYearMatchingCalendarYear = (dateFrom) => {
  const castedDateFrom = new Date(dateFrom);

  const dayFrom = castedDateFrom.getDate();
  const monthFrom = castedDateFrom.getMonth();

  return dayFrom === 1 && monthFrom === 0;
};

export const getTotalYearsLabel = (annualData) => {
  if (annualData.length === 0) {
    return "";
  }
  const years = annualData.map((annual) => new Date(annual.dateFrom).getFullYear());

  const minYear = Math.min(...years);
  const maxYear = Math.max(...years);

  const oneOfDateFrom = annualData[0].dateFrom;
  const isFiscalYearMatchingCalendarYear = checkFiscalYearMatchingCalendarYear(oneOfDateFrom);

  return `${minYear}-${isFiscalYearMatchingCalendarYear ? maxYear : maxYear + 1}`;
};

export const getFiscalYearLabel = (dateFrom: string) => {
  const yearFrom = new Date(dateFrom).getFullYear();

  const isFiscalYearMatchingCalendarYear = checkFiscalYearMatchingCalendarYear(dateFrom);

  let fiscalYearLabel = isFiscalYearMatchingCalendarYear ? yearFrom : `${yearFrom}/${yearFrom + 1}`;

  return fiscalYearLabel;
};
