import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import {
  updatePayrollInvoiceDataByCompany,
  updatePayrollInvoiceDataByCompanyVariables
} from "./graphql/updatePayrollInvoiceDataByCompany";

const UPDATE_PAYROLL_INVOICE_DATA_BY_COMPANY = gql`
  mutation updatePayrollInvoiceDataByCompany(
    $companyId: String!
    $payrollServiceType: String
    $payrollServiceStart: String
    $payrollServiceEnd: String
    $payrollServicePrice: String
  ) {
    updatePayrollInvoiceDataByCompany(
      companyId: $companyId
      payrollServiceType: $payrollServiceType
      payrollServiceStart: $payrollServiceStart
      payrollServiceEnd: $payrollServiceEnd
      payrollServicePrice: $payrollServicePrice
    ) {
      feedback
    }
  }
`;

export const UpdatePayrollInvoiceDataByCompany = () =>
  useMutation<updatePayrollInvoiceDataByCompany, updatePayrollInvoiceDataByCompanyVariables>(
    UPDATE_PAYROLL_INVOICE_DATA_BY_COMPANY
  );
