import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import React, { useState } from "react";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import PayInModal from "client/components/LowTaxBrokerDashboard/PayIn/PayInModal/PayInModal";
import { LTBDashboardActionCard } from "client/components/LowTaxBrokerDashboard/sharedComponents/LTBDashboardActionCard/LTBDashboardActionCard";

export interface PayInProps {
  companyName: string;
  brokerageAccountNumber: string | null | undefined;
}

const PayIn = ({ t, companyName, brokerageAccountNumber }: PayInProps & WithTranslation) => {
  const [showModal, setShowModal] = useState(false);
  const isEnabled = FeatureFlagService.instance.isEnabled(FeatureFlags.LTBDashboardPayIn);

  return (
    <div data-testid={"pay-in"}>
      {showModal && (
        <PayInModal
          companyName={companyName}
          brokerageAccountNumber={brokerageAccountNumber}
          onClose={() => setShowModal(false)}
        />
      )}
      <LTBDashboardActionCard
        dataTestId={"pay-in-card"}
        title={t("generic:low-tax-broker-dashboard.pay-in.title")}
        description={t("generic:low-tax-broker-dashboard.pay-in.description")}
        buttonLabel={t("generic:low-tax-broker-dashboard.pay-in.button")}
        buttonClickHandler={() => setShowModal(true)}
        disabled={!isEnabled}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(PayIn);
