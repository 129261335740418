import TaskContainer from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainer";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { TaskStatesEnum, TaskType } from "../../../../../global-query-types";
import { OrderedList } from "sharedComponents/OrderedList/OrderedList";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import OpenBankAccountDetailsFootnote from "../OpenBankAccountDetailsFootnote/OpenBankAccountDetailsFootnote";

interface OpenBankAccountTaskContainerProps extends PropsWithChildren<WithTranslation> {
  task: ActionableTask;
  bankName: string;
  useLargeIcon?: boolean;
  onChange: (state: TaskStatesEnum, options?: ActionableTaskOptions) => Promise<void>;
}

const OpenBankAccountTaskContainer = ({
  task,
  bankName,
  onChange,
  children,
  useLargeIcon,
  t
}: OpenBankAccountTaskContainerProps) => {
  const bankId = bankName.toLowerCase();
  const iconName = useLargeIcon ? `${bankName}-large` : bankName;

  return (
    <TaskContainer
      onChange={onChange}
      task={task}
      testid={`open-bank-account-${bankId}`}
      translationKey={task.ref}
      useLargeIcon={useLargeIcon}
      iconPath={`/assets/client/banks/${iconName}.svg`}>
      <OrderedList>{children}</OrderedList>
      {task.type !== TaskType.BANK_ACCOUNT_OPEN_OWN && (
        <OpenBankAccountDetailsFootnote
          link={t(`company-founding-steps:tasks.openBankAccount${bankName}.footnoteLink`)}
        />
      )}
    </TaskContainer>
  );
};

export default withTranslationReady(["company-founding-steps"])(OpenBankAccountTaskContainer);
