import gql from "graphql-tag";

export const GET_LEGAL_FORM = gql`
  query getLegalForm {
    __type(name: "LegalForm") {
      name
      enumValues {
        name
      }
    }
  }
`;
