import React, { useEffect, useRef, useState } from "react";
import { WithTranslation } from "react-i18next";
import { Panel } from "sharedComponents/Panel/Panel";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideInput, { InputState } from "../../../sharedComponents/FormControls/RideInput/RideInput";
import RidePassword from "../../../sharedComponents/FormControls/RidePassword/RidePassword";
import { useHistory } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { Login_login } from "lib/api/mutations/graphql/Login";
import { loginSchemaWithTranslation } from "lib/validation/LoginSchema";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "components/generic/RequestFeedbackHandler";
import RequestFeedbackForm from "../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { RideCheckbox } from "sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import { storeToken } from "common/Authentication/storeToken";
import { UserService } from "lib/services/UserService/UserService";
import logger from "../../../common/Logger";
import { reset2faNotification } from "../TwoFactorAuthenticationNotification/TwoFactorAuthenticationNotification";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

export interface LoginFormProps {
  onSubmit: (email: string, password: string) => Promise<Login_login | undefined | null>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
  sendVerificationCode: () => Promise<void>;
  redirectUrl?: string;
}

const LoginForm = ({
  onSubmit,
  requests,
  sendVerificationCode,
  redirectUrl,
  t
}: LoginFormProps & WithTranslation) => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const initialValues = {
    email: "",
    password: ""
  };
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (values) => {
    setIsLoading(true);
    try {
      const result = await onSubmit(values.email, values.password);
      if (
        FeatureFlagService.instance.isEnabled(
          FeatureFlags.showPasswordResetForUsersLoggedInViaFinApi
        )
      ) {
        if (result?.isFinApiUser) {
          history.push("/reset-password");
        }
      }
      if (result?.token) {
        storeToken(result.token, values.remember);

        if (result.hasTwoFactorAuth) {
          await sendVerificationCode();
          history.replace("/2fa/login", {
            censoredPhoneNumber: result.censoredPhoneNumber,
            redirectUrl
          });
        } else {
          reset2faNotification();
          await UserService.instance?.meRequest?.refetch();
          history.replace(redirectUrl ?? "/");
        }
      }
    } catch (error) {
      logger.errorMessage("User sign up not successful");
      logger.error(error);
    }

    if (mountedRef.current) {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: submitHandler,
    validateOnBlur: true,
    validationSchema: loginSchemaWithTranslation(t)
  });

  const state = requests[0]?.error ? InputState.ERROR : InputState.DEFAULT;

  return (
    <div className="login-form">
      <Panel>
        <div className="login-form__panel-content">
          <FormikProvider value={formik}>
            <RequestFeedbackForm submitLabel={t("auth:login.sign-up")} requests={requests}>
              <RideInput
                formik={formik}
                name="email"
                state={state}
                isDisabled={isLoading}
                type="text"
                label={t("auth:generic.email-address")}
              />
              <RidePassword
                formik={formik}
                name="password"
                state={state}
                isDisabled={isLoading}
                label={t("auth:generic.password")}
              />

              <div className="login-form__submit_area login-form__link">
                <TextLink
                  variant={LinkVariant.secondary}
                  to="/lost-password"
                  data-testid="reset-link">
                  {t("auth:login.forgot-password-link")}
                </TextLink>
              </div>

              <div className="login-form__remember-me">
                <RideCheckbox formik={formik} name="remember" label={t("auth:login.remember-me")} />
              </div>
            </RequestFeedbackForm>
          </FormikProvider>
        </div>
      </Panel>

      <div className="login-form__footer">
        <TextLink
          variant={LinkVariant.secondary}
          className="login-form__link"
          to="/order/account-creation/0"
          data-testid="register-link">
          {t("auth:login.register")}
        </TextLink>
      </div>
    </div>
  );
};

export default withTranslationReady(["auth"])(LoginForm);
