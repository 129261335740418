import React, { useRef, useState } from "react";
import TaskCard, {
  TaskCardStatusEnum,
  RideBadgeVariant,
  DonutCompletionStatus
} from "client/components/TaskCard/TaskCard";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { ReactComponent as RefreshIcon } from "uiLibrary/Icons/RefreshIcon.svg";
import { RideTooltip } from "sharedComponents/RideTooltip/RideTooltip";
import { FolderIcon } from "sharedComponents/icons/FolderIcon";
import { GetWepaFileImportById_getWepaFileImportById } from "lib/api/queries/graphql/GetWepaFileImportById";
import { UploadIcon } from "sharedComponents/icons/DesignSystemIcons/UploadIcon";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

interface CardWrapperProps {
  portfolioName: string;
  status: TaskCardStatusEnum;
  index: number;
  completion?: DonutCompletionStatus;
  "data-testid"?: string;
  children: React.ReactNode;
}
export const CardWrapper = ({
  portfolioName,
  status,
  index,
  completion,
  children,
  "data-testid": dataTestId
}: CardWrapperProps) => {
  const { t } = useTranslation("generic");

  return (
    <TaskCard
      status={status}
      index={index}
      title={t("generic:client-tasks.portfolio-data-completeness.title", {
        portfolioId: portfolioName
      })}
      completion={completion}
      badgeVariant={RideBadgeVariant.PURPLE}
      badgeLabel={t("generic:client-tasks.portfolio-data-completeness.badge")}>
      <div data-testid={dataTestId} className="mt-1 mb-3">
        {children}
      </div>
    </TaskCard>
  );
};

interface RefreshRageButtonProps {
  onClick?: Function;
}
export const RefreshRageButton = ({ onClick }: RefreshRageButtonProps) => {
  const { t } = useTranslation("generic");
  const [state, setState] = useState(false);

  function onClickHandler() {
    if (state) {
      return;
    }

    onClick?.();
    setState(true);
    setTimeout(() => {
      setState(false);
    }, 1000);
  }

  return (
    <div role="button" onClick={onClickHandler} className="bg-secondary">
      <Typography category={"Paragraph"} size={200} weight={"Light"}>
        {t("generic:client-tasks.portfolio-data-completeness.click-to-refresh")}
      </Typography>
      &nbsp;
      <RefreshIcon
        style={{
          width: "0.8rem",
          animation: state ? "loader-1 1s cubic-bezier(0.77, 0, 0.175, 1) 1" : undefined
        }}
      />
    </div>
  );
};

interface ImportMetadataProps {
  importStatus: GetWepaFileImportById_getWepaFileImportById;
}
export const ImportMetadata = ({ importStatus }: ImportMetadataProps) => {
  return (
    <RideTooltip name="import-metadata-tooltip" content={importStatus.name || ""}>
      <div className="portfolio-import-list px-3 mb-3">
        <FolderIcon size={12} />
        &nbsp;
        <Typography category={"Paragraph"} size={300} weight={"Light"}>
          <Trans
            i18nKey={"generic:client-tasks.portfolio-data-completeness.import-file-name"}
            values={{
              date_from: importStatus.dateFrom,
              date_to: importStatus.dateTo,
              detected_accounts: (importStatus.detectedAccounts || []).join(", ")
            }}
            components={{ b: <b /> }}
          />
        </Typography>
      </div>
    </RideTooltip>
  );
};

interface UploadButtonProps {
  onUpload: (file: File) => void;
}
export const UploadButton = ({ onUpload }: UploadButtonProps) => {
  const { t } = useTranslation("generic");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onUpload(file);
    }
  };

  return (
    <>
      <RideButton
        variant={RideButtonVariant.SECONDARY}
        size={RideButtonSize.SMALL}
        leftIcon={<UploadIcon />}
        label={t("generic:client-tasks.portfolio-data-completeness.upload-button")}
        onClick={() => fileInputRef.current?.click()}
      />
      <input
        type="file"
        ref={fileInputRef}
        accept=".xml"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </>
  );
};

export const FaqLink = ({ children, ...props }: any) => (
  <a {...props} aria-label="FAQ">
    {children}
  </a>
);
