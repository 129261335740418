import { RideStatus } from "lib/types/types";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerData } from "client/components/LowTaxBrokerOverview/LowTaxBrokerOverview";
import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_order } from "lib/api/queries/graphql/getLowTaxBrokerOrder";
import { AddExistingCompanyOrderData } from "lib/models/client/AddExistingCompany/AddExistingCompanyOrderData";
import { OnboardingStepCardStatus } from "client/components/OnboardingStepCard/OnboardingStepCard";
import { calculateStatus } from "common/OnboardingStepCardHelper";

export interface LTBOverviewStatus {
  addCompanyDetails?: OnboardingStepCardStatus;
  completeApplication: OnboardingStepCardStatus;
  verifyId: OnboardingStepCardStatus;
  applicationReview: OnboardingStepCardStatus;
  setupAccount: OnboardingStepCardStatus;
  fundAccount: OnboardingStepCardStatus;
}

export const getLowTaxBrokerOverviewStatuses = (
  order:
    | OrderData<LowTaxBrokerData>
    | getLowTaxBrokerOrder_getLowTaxBrokerOrder_order
    | null
    | undefined,
  addExistingCompanyOrder?:
    | Pick<OrderData<AddExistingCompanyOrderData>, "status">
    | null
    | undefined
): LTBOverviewStatus => {
  const addCompanyDetailsStatus = addExistingCompanyOrder
    ? calculateStatus({
        completed: addExistingCompanyOrder.status === "Completed"
      })
    : RideStatus.COMPLETED;
  const completeApplicationStatus = calculateStatus({
    completed: order?.status === "Booked" || order?.status === "Completed",
    previousStepStatus: addCompanyDetailsStatus
  });
  const verifyIdStatus = calculateStatus({
    completed:
      order?.extra?.isNewBrokerEnabled || !!order?.extra?.onboarding?.hasCompletedVerifyIdStep,
    previousStepStatus: completeApplicationStatus
  });
  const applicationReviewStatus = calculateStatus({
    completed: !!order?.extra?.onboarding?.applicationReviewed,
    previousStepStatus: verifyIdStatus
  });
  const setupAccountStatus = calculateStatus({
    completed: !!order?.extra?.onboarding?.hasCompletedSetupAccountStep,
    previousStepStatus: applicationReviewStatus
  });
  const fundAccountStatus = calculateStatus({
    completed: !!order?.extra?.onboarding?.fundsTransferredAt,
    previousStepStatus: setupAccountStatus
  });

  const steps: LTBOverviewStatus = {
    completeApplication: completeApplicationStatus,
    verifyId: verifyIdStatus,
    applicationReview: applicationReviewStatus,
    setupAccount: setupAccountStatus,
    fundAccount: fundAccountStatus
  };

  if (addExistingCompanyOrder) {
    steps.addCompanyDetails = addCompanyDetailsStatus;
  }

  return steps;
};
