import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Col, Row } from "react-bootstrap";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { RideCheckbox, RideCheckboxVariant } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { RideUrls } from "common/URLs";
import { Form, FormikProps, FormikProvider } from "formik";
import i18n from "common/i18n";
import { useRideFormik } from "lib/hooks/useRideFormik";
import * as Yup from "yup";
import { EmailValidatorWithBackend } from "lib/validation/EmailValidatorWithBackend";

interface TaxAdvisorContactDetailsProps extends WithTranslation {}

interface TaxAdvisorContactDetailsInputsProps {
  formik: FormikProps<any>;
}

const TaxAdvisorContactDetailsInputs = ({
  formik,
  t
}: TaxAdvisorContactDetailsInputsProps & WithTranslation) => {
  return (
    <div data-testid="tax-advisor-contact-details-fields">
      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="fullName"
            label={t("generic:full-name")}
            placeholder={t("generic:full-name")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="email"
            label={t("generic:order-form.email")}
            placeholder={t("generic:order-form.email")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="phoneNumber"
            className={"order-form__input"}
            label={t("generic:order-form.phone")}
            type={"tel"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideCheckbox
            data-testid={"privacy-policy"}
            name="privacyPolicy"
            variant={RideCheckboxVariant.Light}
            label={
              <Trans
                i18nKey="generic:order-form.checkboxes.privacy-policy"
                components={{
                  privacy: (
                    <TextLink
                      to={RideUrls.PrivacyPolicy}
                      variant={LinkVariant.primary}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )
                }}
              />
            }
          />
        </Col>
      </Row>
    </div>
  );
};

const TaxAdvisorContactDetailsSchema = (t) => {
  return Yup.object().shape({
    fullName: Yup.string().required(t("generic:validation-required")),
    email: EmailValidatorWithBackend(t),
    phoneNumber: Yup.string().required(t("generic:validation-required")),
    privacyPolicy: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};

const TaxAdvisorContactDetails = ({
  t,
  currentStep,
  order,
  saveData,
  controls
}: TaxAdvisorContactDetailsProps & StepComponentProps & WithTranslation) => {
  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData({
      ...order.extra,
      advisorContactDetails: values,
      ...currentStep.chapter.order.serialize()
    });
  };

  const contacts = order.extra.advisorContactDetails ?? {};

  const formik = useRideFormik({
    initialValues: {
      fullName: contacts.fullName ?? "",
      email: contacts.email ?? "",
      phoneNumber: contacts.phoneNumber ?? "",
      privacyPolicy: contacts.privacyPolicy ?? false
    },
    onSubmit,
    validationSchema: TaxAdvisorContactDetailsSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:order-company-foundation.steps.advisor-contact-details.title")}
        />
        <TaxAdvisorContactDetailsInputs formik={formik} t={t} i18n={i18n} tReady={true} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(TaxAdvisorContactDetails);
