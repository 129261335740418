import { Col, Row } from "react-bootstrap";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import React from "react";
import { FormikProps } from "formik";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideCheckbox, RideCheckboxVariant } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";

interface NaturalPersonInputsProps extends WithTranslation {
  formik: FormikProps<any>;
  dataTestId?: string | undefined;
  disableEmail?: boolean;
  onPhoneCountryChange?: (countryCode: string) => void;
}

export const NaturalPersonInputs = withTranslationReady("generic")(
  ({ dataTestId, formik, disableEmail, t, onPhoneCountryChange }: NaturalPersonInputsProps) => (
    <>
      <div data-testid={dataTestId} className="natural-person__inputs">
        <Row className="natural-person__input">
          <Col>
            <RideInput
              formik={formik}
              name="firstName"
              label={t("generic:first-name")}
              placeholder={t("generic:first-name")}
              autoComplete="on"
            />
          </Col>
        </Row>

        <FeatureFlag name={FeatureFlags.AddMiddleNameToLTBForm}>
          <Row className="natural-person__input">
            <Col>
              <RideInput
                formik={formik}
                name="middleName"
                label={t("generic:middle-name")}
                placeholder={t("generic:middle-name")}
                autoComplete="on"
              />
            </Col>
          </Row>
        </FeatureFlag>

        <Row className="natural-person__input">
          <Col>
            <RideInput
              formik={formik}
              name="lastName"
              label={t("generic:last-name")}
              placeholder={t("generic:last-name")}
              autoComplete="on"
            />
          </Col>
        </Row>

        <Row className="natural-person__input">
          <Col>
            <RideInput
              formik={formik}
              name="phoneNumber"
              label={t("generic:order-form.phone")}
              type={"tel"}
              autoComplete="on"
              onCountryChange={onPhoneCountryChange}
            />
          </Col>
        </Row>

        <Row className="natural-person__input">
          <Col>
            <RideInput
              formik={formik}
              name="email"
              type="email"
              label={t("generic:order-form.email")}
              placeholder={t("generic:email")}
              autoComplete="on"
              isDisabled={disableEmail}
            />
          </Col>
        </Row>
        <RideCheckbox
          variant={RideCheckboxVariant.Light}
          name="authorizedToShareShareholderInfo"
          label={t("generic:order-form.authorized-to-share-shareholder-info")}
        />
      </div>
    </>
  )
);
