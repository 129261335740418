import RideModal, {
  RideModalBody
} from "../../../../../uiLibrary/designSystem/RideModal/RideModal";
import { Typography } from "../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import { Trans, WithTranslation } from "react-i18next";
import React from "react";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";

export interface RideSteuerberatungInformationModalProps {
  useRideSteuerberatung: boolean;
  onClose: () => void;
}

const RideSteuerberatungInformationModal = ({
  t,
  useRideSteuerberatung,
  onClose
}: RideSteuerberatungInformationModalProps & WithTranslation) => {
  const keyPrefix = useRideSteuerberatung
    ? "generic:impressum-ride-steuerberatung"
    : "generic:impressum-vvsteuerberatung";

  const mailTo = useRideSteuerberatung
    ? "mailto:steuerberatung@ride.capital"
    : "mailto:ops@vvsteuerberatung.de";

  return (
    <RideModal
      onClose={onClose}
      title={t(`${keyPrefix}.title`)}
      data-testid="impressum-ride-steuerberatung"
      fitContentHeight>
      <RideModalBody>
        {
          <div className={"contract-signature-card__modal-content"}>
            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              {t(`${keyPrefix}.content.information`)}
            </Typography>

            <div className="contract-signature-card__modal-content-contact">
              <Typography category={"Paragraph"} size={100} weight={"Light"}>
                <Trans
                  components={{
                    EmailLink: (
                      <a data-testid="contact-email" href={mailTo}>
                        {mailTo}
                      </a>
                    )
                  }}
                  i18nKey={`${keyPrefix}.content.email`}
                />
              </Typography>
              <Typography data-testid={"phone"} category={"Paragraph"} size={100} weight={"Light"}>
                {t(`${keyPrefix}.content.phone`)}
              </Typography>
              <Typography
                data-testid={"sales-tax-id"}
                category={"Paragraph"}
                size={100}
                weight={"Light"}>
                {t(`${keyPrefix}.content.sales-tax-identification-number`)}
              </Typography>
            </div>

            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              {t(`${keyPrefix}.content.regulatory-authority`)}
            </Typography>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Light"}
              className={"contract-signature-card__modal-content--tabbed"}>
              <Trans i18nKey={`${keyPrefix}.content.regulations`} />
            </Typography>
            <Typography category={"Paragraph"} size={100} weight={"Light"}>
              {t(`${keyPrefix}.content.liability-insurance`)}
            </Typography>
          </div>
        }
      </RideModalBody>
    </RideModal>
  );
};

export default withTranslationReady(["generic"])(RideSteuerberatungInformationModal);
