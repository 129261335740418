import React from "react";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { withTranslation, WithTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { BrokerNameEnum } from "global-query-types";
import { Col, Row } from "react-bootstrap";
import { ClientSelect } from "client/components/ClientSelect/ClientSelect";
import { ClientInput } from "client/components/ClientInput/ClientInput";
import { ClientSubmit } from "client/components/ClientSubmit/ClientSubmit";
import { ButtonTrash } from "sharedComponents/Buttons/ButtonTrash/ButtonTrash";
import { UpsertBrokerageAccountVariables } from "lib/api/mutations/graphql/UpsertBrokerageAccount";
import { DescriptionWithEmails } from "sharedComponents/DescriptionWithEmails/DescriptionWithEmails";
import { CompanyById_companyById_brokerageAccounts } from "lib/api/queries/graphql/CompanyById";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { articleLinks } from "../../config/brokerageAccountsConfig.json";
import { useDisabledBrokers } from "lib/hooks/useDisabledBrokers";
import ConfirmationModal from "sharedComponents/ConfirmationModal/ConfirmationModal";
import { rideBrokerT } from "../../../common/i18n/withTranslationReady";

export interface BrokerageAccountProps extends WithTranslation {
  upsertBrokerageAccount: (variables: UpsertBrokerageAccountVariables) => Promise<void>;
  deleteBrokerageAccount: (brokerageAccountId: string, companyID: string) => Promise<void>;
  companyId: string;
  brokerageAccount?: CompanyById_companyById_brokerageAccounts;
  brokerageAccountId?: string;
}

const accountNumberBrokers = [
  "FxFlatMetaTrader4",
  "FxFlatMetaTrader5",
  "WhSelfInvestFutures",
  "WhSelfInvestCFD",
  "GBE",
  "JFD",
  "JPM",
  "ActivTrades"
];

const noFieldBrokers = ["VBank"];

const BrokerageAccountValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    token: Yup.string().when("brokerName", {
      is: (value) => !accountNumberBrokers.includes(value) && !noFieldBrokers.includes(value),
      then: Yup.string().required(t("client:brokerage-account.access-token-required"))
    }),
    brokerName: Yup.string().required(t("client:brokerage-account.broker-bank-required")),
    queryId: Yup.string().when("brokerName", {
      is: (value) => !accountNumberBrokers.includes(value) && !noFieldBrokers.includes(value),
      then: Yup.string().required(t("client:brokerage-account.query-id-required"))
    }),
    accountNumber: Yup.string()
      .when("brokerName", {
        is: (value) => accountNumberBrokers.includes(value) && !noFieldBrokers.includes(value),
        then: Yup.string().required(t("client:brokerage-account.account-number-required"))
      })
      .when("brokerName", {
        is: "JFD",
        then: Yup.string()
          .required(t("client:brokerage-account.jfd-token-required"))
          .min(24, t("client:brokerage-account.jfd-token-is-too-short", { min: 24 }))
      })
  });
};

const BrokerAccountInfoLink = ({
  t,
  brokerName,
  brokerBank
}: {
  t: TFunction;
  brokerName: string;
  brokerBank: string;
}) => {
  const translatedBrokerName = t(
    rideBrokerT(`client:company.brokerage-account-list.brokerName.${brokerName}`)
  );

  const link = articleLinks[brokerBank];

  return (
    <div data-testid="broker-account-info-link-container">
      {t("client:brokerage-account.you-can-find-more-information", {
        brokerName: translatedBrokerName
      })}
      <TextLink
        data-testid="broker-account-info-link"
        variant={LinkVariant.primary}
        to={link}
        target="_blank">
        {t("client:brokerage-account.here")}
      </TextLink>
    </div>
  );
};

export const notUsableBrokers = [
  // Because it's LTB, it's automatically managed by the system
  BrokerNameEnum.LTB,
  BrokerNameEnum.LTB2,

  // This is for VIP clients only. We add it in the backend on our own
  BrokerNameEnum.PM1,

  // These are no longer supported for new accounts
  BrokerNameEnum.Pepperstone,
  BrokerNameEnum.AdmiralMarkets,
  BrokerNameEnum.ICMarkets,
  BrokerNameEnum.RoboForex,
  BrokerNameEnum.ThunderForex
];

const getAvailableBrokerOptions = (t: TFunction, disabledBrokers: { [key: string]: boolean }) =>
  Object.values(BrokerNameEnum)
    .filter((brokerName) => !disabledBrokers[brokerName])
    .filter((brokerName) => !notUsableBrokers.includes(brokerName))
    .map((brokerName) => ({
      label: t(`client:company.brokerage-account-list.brokerName.${brokerName}`),
      value: brokerName
    }));

export const BrokerageAccountForm = ({
  t,
  upsertBrokerageAccount,
  deleteBrokerageAccount,
  companyId,
  brokerageAccount,
  brokerageAccountId
}: BrokerageAccountProps) => {
  const { goBack } = useHistory();

  const disabledBrokers = useDisabledBrokers();

  const brokerNameOptions = getAvailableBrokerOptions(t, disabledBrokers);

  const submitHandler = async (values) => {
    const brokerBank =
      accountNumberBrokers.includes(values.brokerName) || noFieldBrokers.includes(values.brokerName)
        ? values.brokerName
        : "IB";

    await upsertBrokerageAccount({ ...values, companyId, brokerageAccountId, brokerBank });
    goBack();
  };

  const handleDelete = async () => {
    await deleteBrokerageAccount(brokerageAccount!.id, companyId);
    goBack();
  };

  const initialValues = {
    brokerBank: brokerageAccount?.brokerBank ?? "IB",
    brokerName: brokerageAccount?.brokerName ?? "",
    token: brokerageAccount?.token ?? "",
    queryId: brokerageAccount?.queryId ?? "",
    accountNumber: brokerageAccount?.accountNumber ?? ""
  };

  const brokerageAccountFields = () => {
    return (
      <>
        <Col xs={12} md={6}>
          <ClientInput
            name="token"
            type="text"
            label={t("client:brokerage-account.access-token")}
            className="mb-4"
            testId="token-input"
          />
        </Col>
        <Col xs={12} md={6}>
          <ClientInput
            name="queryId"
            type="text"
            label={t("client:brokerage-account.query-id")}
            className="mb-4"
            testId="queryId-input"
          />
        </Col>
      </>
    );
  };
  const brokerageAccountFieldsForAccountNumberBroker = () => {
    return (
      <Col xs={12} md={6}>
        <ClientInput
          name="accountNumber"
          type="text"
          label={t("client:brokerage-account.account-number")}
          className="mb-4"
          testId="account-number-input"
        />
      </Col>
    );
  };
  const brokerageAccountFieldsForJFDToken = () => {
    return (
      <Col xs={12} md={6}>
        <ClientInput
          name="accountNumber"
          type="text"
          label={t("client:brokerage-account.jfd-token")}
          className="mb-4"
          testId="account-number-input"
        />
      </Col>
    );
  };
  const noFieldsForBroker = () => {
    return <></>;
  };

  const formik = useFormik({
    initialValues,
    onSubmit: submitHandler,
    validationSchema: BrokerageAccountValidationSchema(t),
    validateOnChange: true,
    validateOnBlur: true
  });

  const brokerName = formik.values["brokerName"];

  let fields: JSX.Element = brokerageAccountFields();
  if (accountNumberBrokers.includes(brokerName)) {
    fields = brokerageAccountFieldsForAccountNumberBroker();
  }
  if (brokerName === BrokerNameEnum.JFD) {
    fields = brokerageAccountFieldsForJFDToken();
  }

  if (noFieldBrokers.includes(brokerName)) {
    fields = noFieldsForBroker();
  }

  return (
    <div className="brokerage-account-form">
      <FormikProvider value={formik}>
        <Form>
          <Row>
            <Col xs={12} md={6}>
              <ClientSelect
                name="brokerName"
                label={t("client:brokerage-account.broker-bank")}
                options={brokerNameOptions}
                changeHandler={(brokerName) => {
                  const brokerBank =
                    accountNumberBrokers.includes(brokerName) || noFieldBrokers.includes(brokerName)
                      ? brokerName
                      : "IB";
                  formik.setFieldValue("brokerName", brokerName);
                  formik.setFieldValue("brokerBank", brokerBank);
                }}
                isSearchable={false}
                isClearable={true}
                className="mb-4"
                testIdPrefix="brokerName-select"
                placeholder={t("client:brokerage-account.select-placeholder")}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              className="brokerage-account-form--info"
              data-testid="broker-name-disclaimer">
              <DescriptionWithEmails
                content={t("client:brokerage-account.broker-name-disclaimer")}
              />
            </Col>
            {fields}
          </Row>
          <Row>
            <Col xs={12} md={12} className="brokerage-account-form--info-links">
              {!!brokerName && (
                <BrokerAccountInfoLink
                  t={t}
                  brokerBank={formik.values["brokerBank"]}
                  brokerName={brokerName}
                />
              )}
            </Col>
          </Row>
          <div className="brokerage-account-form--submit">
            <ClientSubmit testId="brokerage-account-submit">
              {t("client:brokerage-account.save")}
            </ClientSubmit>
          </div>
          {brokerageAccountId && (
            <div className="brokerage-account-form--delete">
              <ConfirmationModal
                title={t("client:brokerage-account.delete-title")}
                dialogBody={
                  <div data-testid="brokerage-account-delete-broker-name">
                    {brokerageAccount?.brokerName &&
                      t(
                        `client:company.brokerage-account-list.brokerName.${brokerageAccount?.brokerName}`
                      )}
                    {!brokerageAccount?.brokerName && t(`client:brokerage-account.broker-bank`)}
                  </div>
                }
                cancelLabel={t(`client:company.brokerage-account-list.action-cancel`)}
                confirmLabel={t(`client:company.brokerage-account-list.action-delete`)}
                onConfirm={handleDelete}>
                {(setVisibility) => (
                  <ButtonTrash
                    data-testid="brokerage-account-delete"
                    onClick={(event) => {
                      event.preventDefault();
                      setVisibility(true);
                    }}>
                    {t("client:brokerage-account.delete")}
                  </ButtonTrash>
                )}
              </ConfirmationModal>
            </div>
          )}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslation(["generic", "client"])(BrokerageAccountForm);
