import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import {
  CompanyNameField,
  CompanyNameSuffixField
} from "../../../components/public/CompanyCreation/fields";
import { Form, Formik } from "formik";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "../../../components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import { FlowTypeEnum } from "../../../global-query-types";
import SubmitButton from "../../../components/public/CompanyCreation/Steps/SubmitButton";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_COMPANY_NAME } from "../../../components/public/CompanyCreation/Mutations";
import { ApolloQueryResult } from "apollo-client";
import logger from "../../../common/Logger";
import LoadingAlert from "../../../components/generic/LoadingAlert";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  UpdateCompanyName,
  UpdateCompanyNameVariables
} from "../../../components/public/CompanyCreation/graphql/UpdateCompanyName";
import { getCompanyNameSuffixesByFlowType } from "../../../lib/formatters/config";
import * as Yup from "yup";

const companyNameRegex = /^[0-9a-zA-ZäöüßÄÖÜẞ\s]+$/;
const gmbhRegex = /^((?!gmbh).)*$/;
const forbiddenCompanyName = /(invest)|(investment)|(partner)/;

const CompanyCreationNameFormSchema = (t) =>
  Yup.object().shape({
    companyName: Yup.string()
      .required("generic:validation-required")
      .matches(companyNameRegex, { message: "generic:validation-only-letters-numbers-spaces" })
      .test("forbiddenWords", "generic:validation-forbidden-words", (value) => {
        if (value && value.toLowerCase().match(forbiddenCompanyName)) return false;
        return true;
      })
      .test("gmbhName", t("generic:validation-gmbh-name"), (value) => {
        if (value && value.toLowerCase().match(gmbhRegex)) return true;
        return false;
      }),
    companyNameSuffix: Yup.string().required("generic:validation-required")
  });

interface CompanyCreationNameFormProps extends WithTranslation {
  companyCreation: MyCompanyCreationQuery_myCompanyCreation | undefined;
  resetActiveStep: (string) => void;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
}

const CompanyCreationNameForm = (props: CompanyCreationNameFormProps) => {
  const { t } = props;

  return (
    <>
      <Row>
        <Col className="steps-form-description">
          <p>{t("trader-gmbh:company-name-description-1")}</p>
          <p>{t("trader-gmbh:company-name-description-2")}</p>
          <p>{t("trader-gmbh:company-name-description-3")}</p>
        </Col>
      </Row>
      <div className="step-bg mt-md-45 px-md-4 pb-md-3 mt-35 px-0 pb-0">
        <Container>
          <Row>
            <Col>
              <div className="mt-md-4">
                <RenderForm {...props} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const RenderForm = ({
  t,
  companyCreation,
  refetchMyCompanyCreation,
  resetActiveStep,
  currentFlowType
}: CompanyCreationNameFormProps) => {
  const [updateCompanyName, { loading, error }] = useMutation<
    UpdateCompanyName,
    UpdateCompanyNameVariables
  >(UPDATE_COMPANY_NAME);

  const fullCompanyName = companyCreation?.company?.name ?? "";

  const companyNameSuffixFlowType = getCompanyNameSuffixesByFlowType(currentFlowType);

  const companyNameSuffix =
    companyNameSuffixFlowType?.find((s) => fullCompanyName.endsWith(s)) ??
    companyNameSuffixFlowType![0];

  const suffixIndex = fullCompanyName.lastIndexOf(companyNameSuffix);
  const companyName =
    suffixIndex >= 0 ? fullCompanyName.substring(0, suffixIndex - 1) : fullCompanyName;

  const onSubmit = async ({ companyName, companyNameSuffix }) => {
    if (!companyCreation) {
      return;
    }

    try {
      await updateCompanyName({
        variables: {
          companyCreationId: companyCreation.id,
          name: `${companyName} ${companyNameSuffix}`
        }
      });

      const data = await refetchMyCompanyCreation({ id: companyCreation.id });
      resetActiveStep(data.data.myCompanyCreation?.currentStep);
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to update company name:`);
      logger.error(error);
    }
  };

  return (
    <Formik
      initialValues={{ companyName, companyNameSuffix }}
      onSubmit={onSubmit}
      validationSchema={CompanyCreationNameFormSchema(t)}>
      {(formik) => (
        <Form noValidate>
          <Card.Body>
            <Row>
              <Col xs={12} md={6}>
                <CompanyNameField t={t} formik={formik} required={true} />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <CompanyNameSuffixField
                  t={t}
                  formik={formik}
                  companyNameSuffixFlowType={companyNameSuffixFlowType}
                />
              </Col>
            </Row>

            {loading ||
              (error?.message && (
                <Row className="mt-3">
                  <Col>
                    <LoadingAlert t={t} loading={loading} />
                    <ErrorAlertLegacy t={t} error={error?.message} />
                  </Col>
                </Row>
              ))}

            <Row className="mt-3 justify-content-end">
              <Col className="col-auto">
                <SubmitButton />
              </Col>
            </Row>
          </Card.Body>
        </Form>
      )}
    </Formik>
  );
};

export default withTranslation(["generic", "trader-gmbh"])(CompanyCreationNameForm);
