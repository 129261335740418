import React, { PropsWithChildren } from "react";

interface CardContainerProps extends PropsWithChildren<{}> {
  className?: string;
  "data-testid"?: string;
}

export const CardContainer = ({
  className = undefined,
  "data-testid": testId = undefined,
  children
}: CardContainerProps) => {
  return (
    <div className={`v2-card-container ${className ?? ""}`} data-testid={testId ?? ""}>
      {children}
    </div>
  );
};
