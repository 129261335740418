import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import React, { useImperativeHandle } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { deepClone } from "common/deepClone";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import GenericAddress from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/GenericAddress/GenericAddress";
import { AddressSchema } from "lib/validation/AddressSchema";

const CompanyAddress = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef
}: WithTranslation & StepComponentProps) => {
  const shareholderIndex = currentStep?.chapter?.index ?? 0;
  let shareholdersCopy = order.extra.shareholders ? deepClone(order.extra.shareholders) : {};
  const shareholder = shareholdersCopy[shareholderIndex] ?? {};
  const companyAddress = shareholder.companyAddress ?? {};

  const initialValues = {
    country: companyAddress.country ?? "DE",
    streetLine1: companyAddress.streetLine1 ?? "",
    streetLine2: companyAddress.streetLine2 ?? "",
    city: companyAddress.city ?? "",
    postalCode: companyAddress.postalCode ?? ""
  };

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    currentStep.complete();

    const { shouldReturnToParentChapter, ...data } = values;
    shareholder.companyAddress = data;

    await saveData(
      {
        ...order.extra,
        shareholders: shareholdersCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldReturnToParentChapter ?? false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: AddressSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="company-address-form subchapter-order-layout__scrollable">
          <GenericAddress
            formik={formik}
            prefix={`${currentStep?.disposition}`}
            title={t("generic:shareholder-details.company-address")}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(CompanyAddress);
