import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import ChoiceForm from "../../../../sharedComponents/ChoiceForm/choiceForm";
import { ButtonVariant } from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

interface AddBankAccountProps extends WithTranslation {
  onAddNew: () => void;
  onAddExisting: () => void;
  lightMode?: boolean;
}

const AddBankAccount = ({ t, onAddNew, onAddExisting, lightMode = false }: AddBankAccountProps) => {
  return (
    <ChoiceForm
      lightMode={lightMode}
      title={t("client:banking.addBankAccount.title")}
      firstSection={{
        title: t("client:banking.addBankAccount.existingBankAccount"),
        btnText: t("client:banking.addBankAccount.existingBankAccountBtn"),
        btnVariant: lightMode ? ButtonVariant.Primary : ButtonVariant.Secondary,
        testId: "add-existing-account"
      }}
      secondSection={{
        title: t("client:banking.addBankAccount.newBankAccount"),
        btnText: t("client:banking.addBankAccount.newBankAccountBtn"),
        btnVariant: ButtonVariant.Primary,
        testId: "add-new-account"
      }}
      onSelectFirst={onAddExisting}
      onSelectSecond={onAddNew}
    />
  );
};

export default withTranslationReady(["client"])(AddBankAccount);
