import { OrderChaptersConfig } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import SelectAssetTypes from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/BusinessPurpose/SelectAssetTypes/SelectAssetTypes";
import ReviewPurpose from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/BusinessPurpose/ReviewPurpose/ReviewPurpose";
import DescribeOperatingActivities from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/BusinessPurpose/DescribeOperatingActivities/DescribeOperatingActivities";
import ShareholdersList from "client/components/ChaptersOrderLayout/sharedComponents/ShareholdersList/ShareholdersList";
import { EntityGeneralInformation } from "client/components/ChaptersOrderLayout/sharedComponents/EntityGeneralInformation/EntityGeneralInformation";
import PersonCitizenshipInformationForm from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/PersonCitizenshipInformationForm/PersonCitizenshipInformationForm";
import PersonAddress from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/PersonAddress/PersonAddress";
import CompanyAddress from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyAddress/CompanyAddress";
import CompanyRepresentative from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentative/CompanyRepresentative";
import CompanyRepresentativeCitizenship from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentativeCitizenship/CompanyRepresentativeCitizenship";
import CompanyRepresentativeAddress from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/CompanyRepresentativeAddress/CompanyRepresentativeAddress";
import { ReviewEntity } from "client/components/ChaptersOrderLayout/sharedComponents/ReviewEntity/ReviewEntity";
import OwnershipDetails from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/OwnershipDetails/OwnershipDetails/OwnershipDetails";
import ManagingDirectorsList from "client/components/ChaptersOrderLayout/sharedComponents/ManagingDirectorsList/ManagingDirectorsList";
import CompanyName from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyName/CompanyName/CompanyName";
import SelectCompanyAddress from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyAddress/SelectCompanyAddress/SelectCompanyAddress";
import CompanyAddressForm from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyAddress/CompanyAddressForm/CompanyAddressForm";
import ReviewAndSubmitGmbhSetup from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ReviewAndSubmitCompanyFounding/ReviewAndSubmitCompanyFounding";
import { OrderProductTypeEnum } from "global-query-types";

export const SETUP_COMPANY_FOUNDATION_CONFIG: OrderChaptersConfig = {
  chapters: {
    "business-purpose": {
      icon: "rocket",
      title: "generic:company-setup.chapters.business-purpose"
    },
    "shareholder-details": {
      icon: "id-card",
      title: "generic:company-setup.chapters.shareholder-details"
    },
    "ownership-distribution": {
      icon: "structure",
      title: "generic:company-setup.chapters.ownership-distribution"
    },
    "managing-directors": {
      icon: "grid",
      title: "generic:company-setup.chapters.managing-directors"
    },
    "company-name": {
      icon: "bulb",
      title: "generic:company-setup.chapters.company-name"
    },
    "company-address": {
      icon: "location",
      title: "generic:company-setup.chapters.company-address"
    },
    "review-and-submit": {
      icon: "rocket",
      title: "generic:company-setup.chapters.review-and-submit"
    }
  },
  steps: {
    //business-purpose
    "select-asset-types": SelectAssetTypes,
    "review-business-purpose": ReviewPurpose,
    "describe-operating-activities": DescribeOperatingActivities, //dynamic. if there are additional operating activities

    //shareholder-details
    "shareholders-list": ShareholdersList(OrderProductTypeEnum.CompanyFoundation),
    //for each shareholder:
    "shareholder-general-information": EntityGeneralInformation("shareholder", true),
    "shareholder-citizenship-information": PersonCitizenshipInformationForm("shareholder"), //dynamic. If type is Person
    "shareholder-residential-address": PersonAddress("shareholder"), //dynamic. If type is Person
    "shareholder-company-address": CompanyAddress, //dynamic. If type is Company
    "shareholder-company-representative": CompanyRepresentative, //dynamic. If type is Company
    "shareholder-representative-citizenship": CompanyRepresentativeCitizenship, //dynamic. If type is Company
    "shareholder-representative-address": CompanyRepresentativeAddress, //dynamic. If type is Company
    "shareholder-review": ReviewEntity("shareholder"),

    //ownership-distribution
    "ownership-details": OwnershipDetails,

    //managing-directors
    "managing-directors-list": ManagingDirectorsList(),
    //for each new managing director
    "employee-general-information": EntityGeneralInformation("employee"),
    "employee-citizenship-information": PersonCitizenshipInformationForm("employee"),
    "employee-residential-address": PersonAddress("employee"),
    "employee-review": ReviewEntity("employee"),

    //company-name
    "company-name": CompanyName,

    //company-address
    "select-company-address": SelectCompanyAddress,
    "company-address-form": CompanyAddressForm,

    //review-and-submit
    "review-and-submit": ReviewAndSubmitGmbhSetup
  },
  contextualHelpSuffix: "gs-long-order-form-flow"
};
