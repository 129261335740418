import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import React from "react";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { UserService } from "lib/services/UserService/UserService";
import NavigationService from "lib/services/NavigationService/NavigationService";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerData } from "client/components/LowTaxBrokerOverview/LowTaxBrokerOverview";
import { LowTaxBrokerOnboardingUpdate } from "lib/models/client/LowTaxBroker/Onboarding";

interface VerifyIdentityCardProps {
  status: OnboardingStepCardStatus;
  order: OrderData<LowTaxBrokerData> | null | undefined;
  updateOrder: (order: LowTaxBrokerOnboardingUpdate) => Promise<void>;
  cardIndex: number;
}

export const VerifyIdentityCard = withTranslationReady(["generic"])(
  ({ t, status, order, updateOrder, cardIndex }: VerifyIdentityCardProps & WithTranslation) => {
    const userName: string = personNameWithFallback({
      firstName: UserService.instance.currentUser?.person?.firstName,
      lastName: UserService.instance.currentUser?.person?.lastName
    });

    const openPostIdentForm = async () => {
      if (!order) {
        return;
      }

      NavigationService.instance?.openLink(
        t(`generic:low-tax-broker-overview.verify-id.post-ident-form-link`)
      );

      await updateOrder({
        id: order.id,
        extra: {
          onboarding: {
            ...order.extra.onboarding,
            hasOpenedVerifyIdService: true
          }
        }
      });
    };

    const completeStep = async () => {
      if (!order) {
        return;
      }

      await updateOrder({
        id: order.id,
        extra: {
          onboarding: {
            ...order.extra.onboarding,
            hasCompletedVerifyIdStep: true
          }
        }
      });
    };

    return (
      <OnboardingStepCard
        number={cardIndex}
        data-testid="verify-id-card"
        title={t("generic:low-tax-broker-overview.verify-id.title")}
        status={status}>
        <div className="ltb-overview__content-box">
          <div className="ltb-overview__content-title">
            {t("generic:low-tax-broker-overview.verify-id.content-title")}
          </div>
          <div className="ltb-overview__content">
            {t("generic:low-tax-broker-overview.verify-id.content-description")}
          </div>
        </div>
        <div
          data-testid="authenticate-id-content"
          className="ltb-overview__content-box ltb-overview__verify-id-card">
          <div className="ltb-overview__verify-id-card__left-side">
            <div className="ltb-overview__verify-id-card__label">
              {t("generic:low-tax-broker-overview.verify-id.authenticate-id-label")}
            </div>
            <div className="ltb-overview__verify-id-card__person-name">{userName}</div>
          </div>
          <button
            data-testid="verify-id-button"
            className="ltb-overview__secondary-action-btn ltb-overview__verify-id-card__button"
            onClick={openPostIdentForm}>
            {t("generic:low-tax-broker-overview.verify-id.verify-id-button")}
            <span className="ltb-overview__verify-id-card__button-icon"></span>
          </button>
        </div>
        {order?.extra?.onboarding?.hasOpenedVerifyIdService && (
          <div className="ltb-overview__buttons">
            <button
              data-testid="verify-id-complete-step-button"
              className="ltb-overview__primary-action-btn"
              onClick={completeStep}>
              {t("generic:low-tax-broker-overview.verify-id.complete-step-button")}
            </button>
          </div>
        )}
      </OnboardingStepCard>
    );
  }
);
