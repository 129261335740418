/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PaymentMethodType {
  ManualBankTransfer = "ManualBankTransfer",
  Sepa = "Sepa"
}

export enum PaymentPlatformAccount {
  RIDE = "RIDE",
  vvSteuerberatung = "vvSteuerberatung"
}

//==============================================================
// END Enums and Input Objects
//==============================================================
