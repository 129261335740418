import React from "react";
import { Typography } from "../../../designSystem/styles/Typography/Typography";

export interface RideTableProps {
  title?: string;
  headers: string[];
  rows: string[][];
}

export const RideTable = ({ title, headers, rows }: RideTableProps) => {
  const module = "ride-table";

  return (
    <div className={module} data-testid={module}>
      {title && (
        <Typography
          data-testid={`${module}-title`}
          category={"Headline"}
          size={300}
          weight={"Heavy"}>
          {title}
        </Typography>
      )}

      <div className={`${module}__body`}>
        <div data-testid={`${module}-headers`} className={`${module}__body__headers`}>
          {headers.map((header, index) => (
            <div key={`header-${index}`} className={`${module}__body__headers__header`}>
              <Typography
                key={index}
                data-testid={`${module}-header-${index}`}
                category={"Paragraph"}
                size={100}
                weight={"Heavy"}>
                {header}
              </Typography>
            </div>
          ))}
        </div>
        <div className={`${module}__body__rows`}>
          {rows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              data-testid={`${module}-row-${rowIndex}`}
              className={`${module}__body__rows__row ${
                rowIndex % 2 === 0
                  ? `${module}__body__rows__row--even`
                  : `${module}__body__rows__row--odd`
              }`}>
              {row.map((entry, entryIndex) => (
                <div key={entryIndex} className={`${module}__body__rows__row__entry`}>
                  <Typography
                    key={entryIndex}
                    data-testid={`${module}-entry-${rowIndex}-${entryIndex}`}
                    category={"Paragraph"}
                    size={200}
                    weight={"Light"}>
                    {entry}
                  </Typography>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
