import React from "react";
import { LinkVariant, TextLink } from "../../../../sharedComponents/TextLink/TextLink";
import { TFunction } from "react-i18next";
import { useDisabledBrokers } from "../../../../lib/hooks/useDisabledBrokers";
import { BrokerNameEnum } from "../../../../global-query-types";

const availableBrokersWithLinks = [
  {
    id: BrokerNameEnum.Banx,
    name: "BanX",
    link: "https://banxbroker.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.Captrader,
    name: "Captrader",
    link: "https://www.captrader.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.FXFlat,
    name: "FXFlat",
    link: "https://www.fxflat.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.FxFlatMetaTrader4,
    name: "FXFlat MetaTrader 4",
    link: "https://www.metatrader4.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.FxFlatMetaTrader5,
    name: "FXFlat MetaTrader 5",
    link: "https://www.metatrader5.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.IB,
    name: "Interactive Brokers",
    link: "https://www.interactivebrokers.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.Lynx,
    name: "Lynx",
    link: "https://www.lynxbroker.de/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.Poolbroker,
    name: "Poolbroker",
    link: "https://www.poolbroker.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.WhSelfInvestFutures,
    name: "WHSelfInvest",
    link: "https://www.whselfinvest.de/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.GBE,
    name: "GBE",
    link: "https://gbebrokers.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.JFD,
    name: "JFD",
    link: "https://www.jfdbank.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.ActivTrades,
    name: "ActivTrades",
    link: "https://www.activtrades.com/?utm_source=app&utm_medium=app"
  },
  {
    id: BrokerNameEnum.VBank,
    name: "VBank",
    link: "https://www.v-bank.com/?utm_source=app&utm_medium=app"
  }
];

export const BrokersList = ({ t }: { t: TFunction }) => {
  const disabledBrokers = useDisabledBrokers();
  const brokers = availableBrokersWithLinks.filter(({ id }) => !disabledBrokers[id]);

  return (
    <div className="brokers-list" data-testid="brokers-list">
      <div className="brokers-list-description">
        {t("brokerSurvey:ride-supports-the-following-securities-accounts")}
      </div>
      <div className="brokers-list-links">
        {brokers.map((broker, index) => {
          return (
            <div className="brokers-list-link" key={broker.id}>
              <TextLink
                target="_blank"
                variant={LinkVariant.secondary}
                to={broker.link}
                data-testid="brokers-list-link">
                {broker.name}
              </TextLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};
