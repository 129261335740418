import gql from "graphql-tag";

export const NOMINEE_UPDATE_PERSON = gql`
  mutation NomineeUpdatePerson(
    $id: ID!
    $birthName: String
    $citizenship: String
    $city: String
    $country: String
    $dateOfBirth: String
    $documentNumber: String
    $documentType: DocumentType
    $firstName: String
    $issuingAuthority: String
    $lastName: String
    $personalTaxId: String
    $phoneNumber: String
    $placeOfBirth: String
    $postalCode: String
    $salutation: SalutationType
    $street: String
    $streetLine2: String
    $taxNumber: String
    $taxOffice: String
    $title: String
    $locked: Boolean
    $isPartner: Boolean
  ) {
    nomineeUpdatePerson(
      id: $id
      birthName: $birthName
      citizenship: $citizenship
      city: $city
      country: $country
      dateOfBirth: $dateOfBirth
      documentNumber: $documentNumber
      documentType: $documentType
      firstName: $firstName
      issuingAuthority: $issuingAuthority
      lastName: $lastName
      personalTaxId: $personalTaxId
      phoneNumber: $phoneNumber
      placeOfBirth: $placeOfBirth
      postalCode: $postalCode
      salutation: $salutation
      street: $street
      streetLine2: $streetLine2
      taxNumber: $taxNumber
      taxOffice: $taxOffice
      title: $title
      locked: $locked
      isPartner: $isPartner
    ) {
      feedback
    }
  }
`;
