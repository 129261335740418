import React, { ButtonHTMLAttributes } from "react";

export enum RideButtonVariant {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
  GHOST = "GHOST"
}

export enum RideButtonSize {
  SMALL = "SMALL",
  BIG = "BIG"
}

export interface RideButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  "data-testid"?: string;
  className?: string;
  label: string | React.ReactElement;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  variant: RideButtonVariant;
  size: RideButtonSize;
}

const moduleName = "ride-button-v2";

export const RideButton = ({
  "data-testid": dataTestId = moduleName,
  className,
  label,
  leftIcon,
  rightIcon,
  variant,
  size,
  ...props
}: RideButtonProps) => {
  const Icon = (position: "left" | "right", icon?: React.ReactElement) => {
    return (
      <div data-testid={`${dataTestId}-icon-${position}`} className={`${moduleName}__icon`}>
        {{
          ...icon,
          props: {
            ...icon?.props,
            size: size === RideButtonSize.SMALL ? "16" : "20",
            className: `${moduleName}__icon-${position}`
          }
        }}
      </div>
    );
  };

  const willRenderRightIcon = size !== RideButtonSize.SMALL && rightIcon;

  return (
    <button
      data-testid={dataTestId}
      className={`${moduleName} ${moduleName}--${variant} ${moduleName}--${size} ${
        !leftIcon && !rightIcon ? `${moduleName}--CENTERED` : ""
      } ${className ?? ""}`}
      {...props}>
      {leftIcon && Icon("left", leftIcon)}
      <span data-testid={`${dataTestId}-label`}>{label}</span>
      {willRenderRightIcon && Icon("right", rightIcon)}
    </button>
  );
};
