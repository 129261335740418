import generator from "generate-password";

export const generatePassword: () => string = () => {
  return generator.generate({
    length: 16,
    numbers: true,
    symbols: true,
    strict: true,
    exclude: "&'<>"
  });
};
