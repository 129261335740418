import { TFunction, Trans } from "react-i18next";
import React, { useState } from "react";
import { Typography } from "../../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../../../../uiLibrary/designSystem/RideButton/RideButton";
import { QuestionIcon } from "../../../../../../sharedComponents/icons/DesignSystemIcons/QuestionIcon";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "../../../../../../uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { withTranslationReady } from "../../../../../../common/i18n/withTranslationReady";
import RideModal, {
  RideModalBody
} from "../../../../../../uiLibrary/designSystem/RideModal/RideModal";
import { QuestionModalProps } from "../../CompanyInformationStep";

const WhyDoINeedACompanyModal = withTranslationReady(["generic"])(
  ({ t, onClose }: QuestionModalProps) => {
    return (
      <RideModal data-testid="why-do-i-need-a-gmbh-modal" title="" onClose={onClose}>
        <RideModalBody className="company-information-step__modal-body">
          <Typography category="Headline" size={100} weight={"Heavy"}>
            {t(
              "generic:short-order-form.company-information.do-you-have-a-company.why-do-i-need-a-company-modal.title"
            )}
          </Typography>

          <Typography category="Paragraph" size={100} weight={"Light"}>
            <Trans
              i18nKey={
                "generic:short-order-form.company-information.do-you-have-a-company.why-do-i-need-a-company-modal.content"
              }
            />
          </Typography>
        </RideModalBody>
      </RideModal>
    );
  }
);

const DoYouHaveACompanyQuestion = ({ t }: { t: TFunction }) => {
  const [showWhyDoINeedAGmbhModal, setShowWhyDoINeedAGmbhModal] = useState(false);

  return (
    <div data-testid="do-you-have-a-gmbh" className="company-information-step__question-section">
      {showWhyDoINeedAGmbhModal && (
        <WhyDoINeedACompanyModal onClose={() => setShowWhyDoINeedAGmbhModal(false)} />
      )}
      <Typography
        category="Headline"
        size={300}
        weight={"Heavy"}
        data-testid="do-you-have-a-gmbh-title">
        {t("generic:short-order-form.company-information.do-you-have-a-company.title")}
      </Typography>
      <RideButton
        data-testid="why-do-i-need-a-gmbh-button"
        type="button"
        variant={RideButtonVariant.GHOST}
        size={RideButtonSize.BIG}
        leftIcon={<QuestionIcon size={20} />}
        onClick={() => setShowWhyDoINeedAGmbhModal(true)}
        label={t(
          "generic:short-order-form.company-information.do-you-have-a-company.help-button-label"
        )}
      />
      <RideRadioSelect
        className="company-information-step__options"
        name={"customerAlreadyHasCompany"}
        options={[
          {
            label: t("generic:short-order-form.company-information.do-you-have-a-company.yes"),
            value: "yes"
          },
          {
            label: t("generic:short-order-form.company-information.do-you-have-a-company.no"),
            value: "no"
          }
        ]}
        variant={RadioSelectVariant.Light}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(DoYouHaveACompanyQuestion);
