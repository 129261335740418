import { ActionableTask, ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import { FeatureFlags, TaskStatesEnum } from "global-query-types";
import React from "react";
import TaskContainer from "./TaskContainer/TaskContainer";
import EnterTaxNumberTask from "../../../../../client/components/Company/tasks/EnterTaxNumberTask/EnterTaxNumberTask";
import AttendNotaryMeetingTask from "../../../../../client/components/Company/tasks/AttendNotaryMeetingTask/AttendNotaryMeetingTask";
import { FeatureFlagService } from "../../../../../lib/services/FeatureFlagService/FeatureFlagService";
import LTBActionableTask from "../../../../../client/components/Company/tasks/LTBActionableTask/LTBActionableTask";

export type OnTaskStateChange = (
  state: TaskStatesEnum,
  options?: ActionableTaskOptions,
  nextTaskToRenderRef?: string
) => Promise<void>;

export interface GenericTaskProps {
  task: ActionableTask;
  onChange: OnTaskStateChange;
  onCloseModal: () => void;
}

const GenericTask = ({ task, onChange, onCloseModal }: GenericTaskProps) => {
  const handleStateChange: OnTaskStateChange = async (state, options, nextTaskToRender) => {
    onCloseModal();
    await onChange(state, options, nextTaskToRender);
  };

  if (task.ref === "attendNotaryAppointment") {
    return <AttendNotaryMeetingTask task={task} onChange={handleStateChange} />;
  }

  if (task.ref === "enterTaxNumber") {
    return <EnterTaxNumberTask task={task} onChange={handleStateChange} />;
  }

  if (
    task.ref === "brokerDepotCreated" &&
    FeatureFlagService.instance.isEnabled(FeatureFlags.LowTaxBrokerFromDepositAccountSetup) &&
    task.state === TaskStatesEnum.TO_BE_DONE
  ) {
    return <LTBActionableTask task={task} onChange={handleStateChange} />;
  }

  return (
    <TaskContainer
      testid="generic"
      translationKey="generic"
      task={task}
      onChange={handleStateChange}
    />
  );
};

export default GenericTask;
