import React from "react";
import RideOverview from "../../../sharedComponents/RideOverview/RideOverview";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { ThirdPartyTaxAdvisorClientsContainer } from "../../components/ThirdPartyTaxAdvisorClients/ThirdPartyTaxAdvisorClientsContainer";
import { useHistory } from "react-router-dom";

interface ThirdPartyTaxAdvisorClientsSceneProps extends WithTranslation {}

const ThirdPartyTaxAdvisorClientsScene = ({ t }: ThirdPartyTaxAdvisorClientsSceneProps) => {
  const history = useHistory();

  return (
    <RideOverview
      title={t("third-party-tax-advisor:overview.title")}
      imageSrc={"/assets/third-party-tax-advisor/header-icon.svg"}
      buttonLabel={t("third-party-tax-advisor:inviteClients")}
      onAdd={() => {
        history.push("/third-party-tax-advisor/invite-clients/email");
      }}
      subtitle={t("third-party-tax-advisor:overview.subtitle")}>
      <ThirdPartyTaxAdvisorClientsContainer />
    </RideOverview>
  );
};

export default withTranslationReady("third-party-tax-advisor")(ThirdPartyTaxAdvisorClientsScene);
