import { AppConfigQuery_appConfig } from "lib/api/queries/graphql/AppConfigQuery";

export default class AppConfigService {
  static instance: AppConfigService;

  readonly appConfig: AppConfigQuery_appConfig;

  constructor(appConfig: AppConfigQuery_appConfig) {
    this.appConfig = appConfig;
  }
}
