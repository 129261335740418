import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import { BusinessAddressInput } from "sharedComponents/BusinessAddressInput/BusinessAddressInput";
import { CompanyLegalRegistration } from "../../../../Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/LegalRegistrationFields";
import {
  SalutationType,
  ShareholderCompany,
  ShareholderPerson
} from "../../../../../global-query-types";
import { CompanyFullNameInput } from "../../../../Investments/UserCompany/CompanyDetailPage/FormFields/editFormFields/CompanyFullNameFields";
import { MyCompanyCreationQuery_myCompanyCreation_company_group_memberships } from "../../graphql/MyCompanyCreationQuery";
import { CompanyCreationField } from "./CompanyCreationField";
import { SalutationValueEnum } from "../../../../../client/components/CompanyCreation/Steps/CompanyCreationShareholdersForm/CompanyCreationShareholdersForm";
import { CompanyHoldingStructureSchema } from "../../../../../lib/validation/CompanyHoldingStructureSchema";
import { holdingCompanyLegalForms } from "../../../../../common/staticData/LegalForms";

export interface CompanyHoldingStructure {
  person: ShareholderPerson;
  company: ShareholderCompany;
}

interface CompanyShareholderFormProps {
  t: TFunction;
  memberships?: MyCompanyCreationQuery_myCompanyCreation_company_group_memberships[] | null;
  onCreate: (value: CompanyHoldingStructure) => Promise<void>;
  onUpdate: (value: CompanyHoldingStructure) => Promise<void>;
}

export const CompanyShareholderForm = ({
  t,
  memberships,
  onUpdate,
  onCreate
}: CompanyShareholderFormProps) => {
  const company = memberships?.find((member) => member.company)?.company;
  const person = memberships?.find((member) => member.person)?.person;
  const salutation = person?.salutation ? SalutationValueEnum[person?.salutation!] : 0;

  const onSubmit = async (values) => {
    const person: ShareholderPerson = {
      salutation: SalutationType[SalutationValueEnum[values.salutation]],
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email
    };

    const company: ShareholderCompany = {
      name: values.companyName,
      legalForm: values.legalForm,
      registerCourt: values.registerCourt,
      registration: values.registration,
      street: values.street,
      postalCode: values.postalCode,
      city: values.city
    };

    const updatedValue: CompanyHoldingStructure = {
      person,
      company
    };

    if (memberships) {
      await onUpdate(updatedValue);
    } else {
      await onCreate(updatedValue);
    }
  };

  const formik = useFormik({
    initialValues: {
      companyName: company?.name ?? "",
      legalForm: company?.legalForm ?? "GmbH",
      registerCourt: company?.registerCourt ?? "",
      registration: company?.registration ?? "",
      street: company?.businessAddress?.street ?? "",
      postalCode: company?.businessAddress?.postalCode ?? "",
      city: company?.businessAddress?.city ?? "",

      salutation,
      title: person?.title ?? "",
      firstName: person?.firstName ?? "",
      lastName: person?.lastName ?? "",
      email: person?.user?.email ?? "",
      phoneNumber: person?.phoneNumber ?? ""
    },
    onSubmit,
    validationSchema: CompanyHoldingStructureSchema,
    enableReinitialize: true
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Card.Body data-testid="shareholder-form">
          <CompanyFullNameInput t={t} formik={formik} legalForms={holdingCompanyLegalForms} />
          <CompanyLegalRegistration t={t} formik={formik} inputClassName="text-input" />
          <BusinessAddressInput
            t={t}
            formik={formik}
            hideCountry={true}
            inputClassName="text-input"
            className="mt-2"
            showCareOfField={false}
          />
        </Card.Body>

        <CompanyCreationField
          t={t}
          formik={formik}
          index={-1}
          arrayHelpers={undefined}
          values={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          prefix={""}
          currentFlowType=""
          isCurrentUser={false}
          title={`${t("trader-gmbh:managing-director")} ${t("trader-gmbh:you-are")}`}
        />

        <Row className="mt-3">
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                data-testid="submit"
                className="step-submit-btn"
                variant="outline-light">
                {t("generic:save")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  );
};
