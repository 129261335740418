import React from "react";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { RideHeaderColumn } from "lib/types/types";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import RideTableCellMultiSlot from "sharedComponents/RideTableCellMultiSlot/RideTableCellMultiSlot";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import {
  DropdownSelectOption,
  RideDropdownSelect
} from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import { GetAccountingServicesByClient_getAccountingServicesByClient } from "lib/api/queries/graphql/GetAccountingServicesByClient";
import { personName } from "lib/formatters/personFormatter";
import { getAllAccountants_getAllAccountants } from "components/nominee/Accountant/graphql/getAllAccountants";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";

type Accountant = getAllAccountants_getAllAccountants;
type AccountingService = GetAccountingServicesByClient_getAccountingServicesByClient;

export interface AssignAccountantProps {
  loading: boolean;
  allAccountants: Accountant[];
  accountingServices: AccountingService[];
  createAccountingService: (accountantId: string) => Promise<void>;
  deleteAccountingService: (accountingServiceId: string) => Promise<void>;
}

export const AccountantsSection = ({
  loading,
  allAccountants,
  accountingServices,
  createAccountingService,
  deleteAccountingService
}: AssignAccountantProps) => {
  const [editMode, setEditMode] = React.useState(false);

  const columns: RideHeaderColumn[] = ["Name", editMode ? "Action" : ""]
    .filter((c) => !!c)
    .map((c) => ({
      name: c,
      label: c
    }));

  return (
    <>
      <RideTableHeader
        title={"Accountants"}
        columns={columns}
        headerAction={
          <ToggleEditButton loading={loading} editMode={editMode} setEditMode={setEditMode} />
        }
      />
      {accountingServices.map((e) => (
        <AccountantEntry
          key={e.id}
          loading={loading}
          entry={e}
          deleteAccountingService={deleteAccountingService}
          editMode={editMode}
        />
      ))}
      {editMode && (
        <AddAccountant
          loading={loading}
          allAccountants={allAccountants}
          accountingServices={accountingServices}
          createAccountingService={createAccountingService}
        />
      )}
    </>
  );
};

const ToggleEditButton = (props: {
  loading: boolean;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
}) => (
  <RideButtonDeprecated
    data-testid="toggle-edit-button"
    isLoading={props.loading}
    variant={props.editMode ? ButtonVariant.Grey : ButtonVariant.Primary}
    size={ButtonSize.Small}
    onClick={() => props.setEditMode(!props.editMode)}
    type="button">
    {props.editMode ? "Cancel" : "Edit"}
  </RideButtonDeprecated>
);

interface AccountantEntryProps {
  loading: boolean;
  entry: AccountingService;
  deleteAccountingService: (accountingServiceId: string) => Promise<void>;
  editMode: boolean;
}

const AccountantEntry = ({
  loading,
  entry,
  deleteAccountingService,
  editMode
}: AccountantEntryProps) => (
  <RideTableRow colCount={editMode ? 2 : 1} dataTestId={"accountant-entry"}>
    <RideTableCellMultiSlot>
      <div className="d-flex flex-column">
        <TextLink
          to={`/nominee-persons/${entry.accountant.person?.id}`}
          variant={LinkVariant.primary}>
          {personName(entry.accountant.person)}
        </TextLink>
        {entry.accountant.email}
      </div>
    </RideTableCellMultiSlot>
    {editMode && (
      <RideTableCellMultiSlot>
        <RideButton
          data-testid="unassign-button"
          label={"Unassign"}
          variant={RideButtonVariant.TERTIARY}
          size={RideButtonSize.SMALL}
          onClick={() => deleteAccountingService(entry.id)}
          disabled={loading}
        />
      </RideTableCellMultiSlot>
    )}
  </RideTableRow>
);

interface AddAccountantProps {
  loading: boolean;
  allAccountants: Accountant[];
  accountingServices: AccountingService[];
  createAccountingService: (accountantId: string) => Promise<void>;
}

const AddAccountant = ({
  loading,
  allAccountants,
  accountingServices,
  createAccountingService
}: AddAccountantProps) => {
  const formik = useRideFormik({
    initialValues: {
      accountant: ""
    },
    onSubmit: (values) => createAccountingService(values.accountant)
  });

  const notAssignedToClient = (accountant: Accountant) => {
    return !accountingServices.some((accSvc) => accSvc.accountant.id === accountant.id);
  };

  const options: DropdownSelectOption[] = allAccountants
    .filter(notAssignedToClient)
    .map((accountant) => ({
      label: (personName(accountant.person) ?? "Unnamed") + ` (${accountant.email})`,
      value: accountant.id
    }));

  return (
    <FormikProvider value={formik}>
      <Form>
        <RideTableRow colCount={2}>
          <RideTableCellMultiSlot>
            <div className={"mr-6"}>
              <RideDropdownSelect name={"accountant"} options={options} isClearable isSearchable />
            </div>
          </RideTableCellMultiSlot>
          <RideTableCellMultiSlot>
            <RideButton
              data-testid="assign-button"
              label={"Assign"}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.SMALL}
              type="submit"
              disabled={loading || !formik.values.accountant}
            />
          </RideTableCellMultiSlot>
        </RideTableRow>
      </Form>
    </FormikProvider>
  );
};
