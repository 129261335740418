import React from "react";
import { RideSize, RideStatus } from "lib/types/types";

export interface RideIconProps {
  name: string;
  status?: RideStatus;
  size?: Extract<RideSize, RideSize.SMALL | RideSize.MEDIUM>;
  onClick?: () => void;
  dataTestId?: string;
  modifier?: RideIconModifier;
}

export enum RideIconModifier {
  CLICKABLE_CONTROL = "clickable-control"
}

const RideIcon = ({
  name,
  size = RideSize.MEDIUM,
  status,
  onClick,
  dataTestId = "ride-icon",
  modifier
}: RideIconProps) => {
  const module = "ride-icon";
  let subIconClass = "";

  if (!!status) {
    const statusClass = `${status.toLowerCase()}`;
    subIconClass = `${module}--${statusClass} ${module}--${statusClass}--${size}`;
  }

  if (!!modifier) {
    subIconClass = `${subIconClass} ${module}--${modifier}`;
  }

  return (
    <div
      onClick={onClick}
      data-testid={dataTestId}
      className={`${module} ${module}--${name} ${module}--${size} ${subIconClass}`}
    />
  );
};

export default RideIcon;
