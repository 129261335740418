import gql from "graphql-tag";

export const APP_CONFIG_QUERY = gql`
  query AppConfigQuery {
    appConfig {
      passwordPolicy {
        version
        minimumLength
        maximumLength
        mustHaveDigits
        mustHaveSymbols
        mustHaveUppercase
        mustHaveLowercase
        mustNotHaveSpaces
        finApiWorkaround
        blacklist
      }
      errorCodes
      featureFlags {
        name
        isEnabled
      }
    }
  }
`;
