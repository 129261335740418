import React, { useState } from "react";
import {
  ConfirmEmailAsyncContainer,
  EmailConfirmResult
} from "../ConfirmEmailAsync/ConfirmEmailAsyncContainer";
import SetPasswordForm from "../SetPasswordForm/SetPasswordForm";
import { UserRole } from "../../../global-query-types";

interface SignUpContainerProps {
  signupToken: string;
  handleSubmit: (password: string) => Promise<void>;
  asUser?: UserRole;
}

const SignUpSection = ({
  signupToken,
  handleSubmit,
  asUser = UserRole.Client
}: SignUpContainerProps) => {
  const [email, setEmail] = useState<string | undefined>();
  const rolesToDisplayEmail = [
    UserRole.TaxAdvisor,
    UserRole.ThirdPartyTaxAdvisor,
    UserRole.Nominee
  ];

  const onConfirmEmail = (data: EmailConfirmResult) => {
    if (rolesToDisplayEmail.includes(asUser)) setEmail(data.email ?? undefined);
  };

  return (
    <>
      <ConfirmEmailAsyncContainer token={signupToken} onConfirm={onConfirmEmail} />
      <SetPasswordForm onSubmit={handleSubmit} requests={[]} email={email} />
    </>
  );
};

export default SignUpSection;
