import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { GetAdvisedPerson_getAdvisedPerson } from "../../graphql/GetAdvisedPerson";
import { Col, Container, Row } from "react-bootstrap";
import { StorageNodesEntityType } from "../../../global-query-types";
import { PersonOverviewHeader } from "../../../sharedComponents/PersonOverviewHeader/PersonOverviewHeader";
import PersonDetailsSection from "../../../components/common/PersonDetailsSection/PersonDetailsSection";
import { PersonRoleCard } from "../../../sharedComponents/PersonRoleCard/PersonRoleCard";
import { NodeTreeCardContainer } from "../../../client/components/Files/NodeTreeCard/NodeTreeCardContainer";

interface TaxAdvisorPersonDetailsProps extends WithTranslation {
  data: GetAdvisedPerson_getAdvisedPerson;
}

export const TaxAdvisorPersonDetails = ({ t, data }: TaxAdvisorPersonDetailsProps) => {
  const fullName = data ? `${data.firstName} ${data.lastName}` : t("generic:person.unknown");
  const storageNodes = JSON.parse(data.externalStorageNodes ?? "{}");

  return (
    <>
      <PersonOverviewHeader personFullName={fullName} />
      <Container>
        <Row>
          <Col>
            <PersonDetailsSection person={data} dataTestId="person-detail-section" />

            <PersonRoleCard person={data} dataTestId="roles-section" />

            <div className="my-5">
              <NodeTreeCardContainer
                title={t("tax-service:person-details.masterData-documents")}
                entityId={data.id}
                entityType={StorageNodesEntityType.Person}
                nodeId={storageNodes.personRoot}
                data-testid="documents-section"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withTranslation(["generic", "tax-service"])(TaxAdvisorPersonDetails);
