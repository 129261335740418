import React from "react";
import { useHistory } from "react-router-dom";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import NavigationService from "lib/services/NavigationService/NavigationService";
import { RideLogoBlack } from "sharedComponents/RideLogo/RideLogo";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { RideIconButton } from "uiLibrary/designSystem/RideIconButton/RideIconButton";
import { BreakpointUp } from "uiLibrary/responsive/BreakpointUp/BreakpointUp";
import { BreakpointDown } from "uiLibrary/responsive/BreakpointDown/BreakpointDown";
import { UserIcon } from "sharedComponents/icons/DesignSystemIcons/UserIcon";
import { PhoneFilledIcon } from "sharedComponents/icons/DesignSystemIcons/PhoneFilledIcon";

const GuestHeader = ({ t }: WithTranslation) => {
  const history = useHistory();

  const goToLoginPage = () => {
    history.push("/login");
  };

  const goToBookACall = () => {
    NavigationService.instance?.openLink(t("generic:book-a-call-url"));
  };

  return (
    <div data-testid="guest-header" className="guest-header">
      <RideLogoBlack />
      <div className={"guest-header__menu"}>
        <BreakpointDown breakpoint={"sm"}>
          <RideIconButton
            data-testid={"login-btn"}
            size={RideButtonSize.BIG}
            onClick={async () => goToLoginPage()}
            icon={<UserIcon />}
          />
          <RideIconButton
            data-testid={"book-a-call-btn"}
            size={RideButtonSize.BIG}
            onClick={async () => goToBookACall()}
            icon={<PhoneFilledIcon />}
          />
        </BreakpointDown>
        <BreakpointUp breakpoint={"md"}>
          <RideButton
            data-testid={"login-btn"}
            variant={RideButtonVariant.SECONDARY}
            size={RideButtonSize.SMALL}
            onClick={goToLoginPage}
            leftIcon={<UserIcon />}
            label={t("generic:home")}
          />
        </BreakpointUp>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(GuestHeader);
