import React from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

interface ReturnLinkProps {
  to?: string;
}

const ReturnLink = ({ t, to = "" }: ReturnLinkProps & WithTranslation) => {
  return (
    <Link to={to} data-testid="back-link" className="return-link-v2">
      <FaArrowLeft className="return-link-v2__arrow" />
      <span>{t("generic:back")}</span>
    </Link>
  );
};

export default withTranslationReady(["generic"])(ReturnLink);
