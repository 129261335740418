import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { CalendarIcon } from "sharedComponents/icons/DesignSystemIcons/CalendarIcon";
import { ProductSectionCard } from "client/components/ProductSectionCard/ProductSectionCard";
import { formatCurrency } from "common/formatters";
import { shortOrderFormProductPrices } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { getPreviousFiscalYears } from "lib/dateTime/getOfferedFiscalYears";
import {
  AlertType,
  RideAlertBar
} from "../../../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import { FiscalYearCard } from "./FiscalYearCard/FiscalYearCard";

export interface AdminServiceAdditionalYearsProps {
  tier: AdminServiceTier;
  inputName: string;
}

const className = `admin-service-additional-years`;

const AdminServiceAdditionalYears = ({
  t,
  tier,
  inputName
}: AdminServiceAdditionalYearsProps & WithTranslation) => {
  const previousFiscalYears = getPreviousFiscalYears();

  return (
    <ProductSectionCard
      dataTestId="admin-service-additional-years"
      title={t("generic:product-card.admin-service-additional-years.title")}
      description={t("generic:product-card.admin-service-additional-years.description")}
      icon={<CalendarIcon size={32} />}
      price={t("generic:product-card.pricePeriodicity.yearly", {
        price: formatCurrency(shortOrderFormProductPrices.adminService[tier], { fixed: 0 })
      })}>
      <div className={`${className}__options`}>
        {previousFiscalYears.map((year) => (
          <FiscalYearCard key={year} year={year} inputName={inputName} />
        ))}
      </div>
      <RideAlertBar
        data-testid={"banner"}
        type={AlertType.INFO}
        message={t("generic:product-card.admin-service-additional-years.banner")}
      />
    </ProductSectionCard>
  );
};

export default withTranslationReady("generic")(AdminServiceAdditionalYears);
