import React, { useState } from "react";
import {
  OtherTask,
  TaskInProgress,
  TaskToBeDone,
  TaskToComplete
} from "../../../../../components/client/company/components/ClientProcessTasks";
import { Col, ListGroup, Row } from "react-bootstrap";
import {
  ActionableTask,
  ActionableTaskOptions
} from "../../../../../common/types/ProcessStatesTypes";
import { sortObjectArrayByOrder } from "../../../../../common/functions/sortObjectArrayByOrder";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import TaskModal from "../../../../../components/client/company/components/tasks/TaskModal";
import { IconClientCompletedTask } from "../../../../../components/common/icons/IconClientCompletedTask";
import { FeatureFlags, TaskStatesEnum } from "../../../../../global-query-types";
import { TFunction, WithTranslation } from "react-i18next";
import { groupBy } from "../../../../../common/functions/groupBy";
import { useHistory } from "react-router-dom";
import { FeatureFlagService } from "../../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { useParams } from "react-router";

interface ProcessTasksProps extends WithTranslation {
  tasks: ActionableTask[];
  handleTaskStateChange: (
    ref: string,
    state: TaskStatesEnum,
    options?: ActionableTaskOptions
  ) => Promise<void>;
}

const REFS_WITH_OPEN_MODAL_IN_PROGRESS = ["instructionLetterSign", "gwgFormSigning"];

export const ProcessTasks = ({ tasks, t, handleTaskStateChange }: ProcessTasksProps) => {
  const [selectedModalTask, setSelectedModalTask] = useState<ActionableTask | null>(null);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const handleModalClose = () => {
    setSelectedModalTask(null);
  };

  const handleModalOpen = (task: ActionableTask) => {
    if (
      FeatureFlagService.instance.isEnabled(FeatureFlags.LowTaxBrokerFromDepositAccountSetup) &&
      task.ref === "brokerDepotCreated" &&
      task.state === TaskStatesEnum.TO_BE_DONE
    ) {
      history.push(`/client/entities/${id}/invest`);
    } else {
      setSelectedModalTask(task);
    }
  };

  const handleChange = async (
    ref: string,
    state: TaskStatesEnum,
    options?: ActionableTaskOptions,
    nextTaskToRenderRef?: string
  ) => {
    await handleTaskStateChange(ref, state, options);
    const nextTaskToRender = tasks.find((task) => task.ref === nextTaskToRenderRef);
    if (nextTaskToRender) {
      nextTaskToRender.state = TaskStatesEnum.TO_BE_DONE;
      handleModalOpen(nextTaskToRender);
    }
  };

  const groupedDataByRole = groupBy(tasks, "responsibleParty");
  const sortOrder = ["TO_BE_DONE", "IN_PROGRESS", "NOT_POSSIBLE_YET", "NOT_REQUIRED", "COMPLETED"];

  const client = sortObjectArrayByOrder(groupedDataByRole.Client, sortOrder).filter(
    (task) => task.state !== TaskStatesEnum.NOT_REQUIRED
  );

  const nominee = sortObjectArrayByOrder(groupedDataByRole.Nominee, sortOrder).filter(
    (task) => task.state !== TaskStatesEnum.NOT_REQUIRED
  );

  return (
    <>
      {selectedModalTask && (
        <TaskModal
          isVisible={true}
          task={selectedModalTask}
          onHide={handleModalClose}
          onStateChange={handleChange}
        />
      )}

      {client.length ? (
        <ClientTaskList tasks={client} handleModalOpen={handleModalOpen} t={t} />
      ) : (
        <ClientTaskEmpty t={t} />
      )}
      {nominee.length ? <NomineeTaskList tasks={nominee} t={t} /> : null}
    </>
  );
};

const ClientTaskEmpty = ({ t }: { t: TFunction }) => (
  <div data-testid="client-tasks-empty" className="tasks-list__client-tasks-empty">
    <IconClientCompletedTask />
    {t("company-founding-steps:client-tasks-empty-message")}
  </div>
);

const ClientTaskList = ({
  tasks,
  handleModalOpen,
  t
}: {
  tasks: ActionableTask[];
  t: TFunction;
  handleModalOpen: (task: ActionableTask) => void;
}) => (
  <div className="tasks-list__client-container">
    <ListGroup>
      {tasks.map((task, index) => (
        <ClientTask task={task} handleModalOpen={handleModalOpen} t={t} key={index} />
      ))}
    </ListGroup>
  </div>
);

const NomineeTaskList = ({ tasks, t }: { tasks: ActionableTask[]; t: TFunction }) => (
  <div className="tasks-list__nominee-container" data-testid="nominee-task-container">
    <h5 data-testid="nominee-tasks-title" className="tasks-list__nominee-container__heading">
      {t("company-founding-steps:nominee-tasks-title")}
    </h5>
    <ListGroup>
      {tasks.map((task, index) => (
        <NomineeTask task={task} t={t} key={index} />
      ))}
    </ListGroup>
  </div>
);

interface ClientTaskProps {
  task: ActionableTask;
  handleModalOpen: (task: ActionableTask) => void;
  t: TFunction;
}

const ClientTask = ({ task, handleModalOpen, t }: ClientTaskProps) => {
  if (task.state === TaskStatesEnum.TO_BE_DONE) {
    return <TaskToComplete task={task} t={t} handleModalOpen={handleModalOpen} />;
  }

  if (task.state === TaskStatesEnum.COMPLETED) {
    return <TaskToBeDone task={task} t={t} handleModalOpen={handleModalOpen} />;
  }

  if (REFS_WITH_OPEN_MODAL_IN_PROGRESS.includes(task.ref)) {
    return <TaskInProgress task={task} t={t} handleModalOpen={handleModalOpen} />;
  }

  return <OtherTask task={task} t={t} />;
};

const NomineeTask = ({ task, t }: { task: ActionableTask; t: TFunction }) => (
  <ListGroup.Item className={`tasks-list__nominee-task`}>
    <Row noGutters>
      <Col xs={12} md={5} data-testid="task-title" className="tasks-list__nominee-task__title">
        {t(`company-founding-steps:tasks.name.${task.ref}`)}
      </Col>
      <Col
        xs={12}
        md={{ offset: 1, span: 6 }}
        data-testid="task-state"
        className={`tasks-list__state tasks-list__state--${task.state}`}>
        {t(`company-founding-steps:tasks-states.${task.state}`)}
      </Col>
    </Row>
  </ListGroup.Item>
);

export default withTranslationReady(["company-founding-steps"])(ProcessTasks);
