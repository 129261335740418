/**
 * @deprecated use <a> tag or other html elements instead of calling `window.open`.
 *             Some browsers will block window.open as an unwanted popup
 *             and customers would then complain that our app doesn't work.
 */
export const openExternalURL = (url: string) => {
  window.open(url, "_blank");
};

// This is an experimental attempt to fix the issue of browsers blocking window.open
export const openAsyncExternalURL = async (promisedURL: Promise<string | null | undefined>) => {
  const win = window.open("/redirecting", "_blank");
  if (!win) {
    throw new Error("Failed to open a new tab");
  }

  try {
    const url = await promisedURL;

    if (!url) {
      win.close();
      return;
    }

    win.open(url, "_self");
  } catch (e) {
    win.close();
    throw e;
  }
};
