import React from "react";
import { RideStatus } from "lib/types/types";
import {
  ButtonHeight,
  ButtonRadius,
  ButtonVariant,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { DocumentStatusBadge } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/DocumentCard/DocumentStatusBadge/DocumentStatusBadge";

interface DocumentCardProps extends WithTranslation {
  "data-testid"?: string;
  text: string;
  numberOfAttachments: number;
  onEdit: () => void;
}

const DocumentCard = ({
  "data-testid": dataTestId,
  text,
  onEdit,
  numberOfAttachments,
  t
}: DocumentCardProps) => {
  const status = numberOfAttachments > 0 ? RideStatus.COMPLETED : RideStatus.PENDING;

  return (
    <div data-testid={dataTestId} className="document-card">
      <div className="document-card--wrapper">
        <DocumentStatusBadge status={status} />
      </div>
      <div data-testid="document-card-text" className="document-card--text">
        {text}
      </div>

      <RideButtonDeprecated
        data-testid={"document-card-edit-button"}
        className={`document-card--button`}
        onClick={onEdit}
        variant={ButtonVariant.Secondary}
        type="button"
        radius={ButtonRadius.Soft}
        width={ButtonWidth.FULL}
        height={ButtonHeight.LARGE}>
        {numberOfAttachments === 0
          ? t("generic:document-card.add-document")
          : numberOfAttachments === 1
          ? t("generic:document-card.view-attachment")
          : t("generic:document-card.view-attachments", { numberOfAttachments })}
      </RideButtonDeprecated>
    </div>
  );
};

export default withTranslationReady(["generic"])(DocumentCard);
