import gql from "graphql-tag";
import { EnhancedMutation } from "../../common/EnhancedMutation";
import { REAL_ESTATE_QUERY, USER_COMPANIES_QUERY } from "./Queries";
import { AVAILABLE_WALLET_OWNERS_QUERY } from "./CreateNewWallet";
import { REAL_ESTATE_OWNERS_QUERY } from "./Ownership/Queries";

export const CREATE_USER_COMPANY = gql`
  mutation CreateUserCompany($name: String!, $legalForm: LegalForm!, $companyValue: Float) {
    createCompany(name: $name, legalForm: $legalForm, companyValue: $companyValue) {
      id
      name
      legalForm
      companyValue
    }
  }
`;

export const EDIT_USER_COMPANY_BY_ID = gql`
  mutation EditUserCompanyById(
    $companyId: String!
    $name: String!
    $projectName: String
    $legalForm: LegalForm!
    $capitalContribution: Float!
    $street: String!
    $streetLine2: String!
    $city: String!
    $postalCode: String!
    $type: CompanyType
    $tradeTaxFree: Boolean
    $registerCourt: String
    $registration: String
    $companyValue: Float
    $taxNumber: String
    $vatNumber: String
  ) {
    updateCompanyById(
      companyId: $companyId
      name: $name
      projectName: $projectName
      legalForm: $legalForm
      capitalContribution: $capitalContribution
      street: $street
      streetLine2: $streetLine2
      city: $city
      postalCode: $postalCode
      type: $type
      tradeTaxFree: $tradeTaxFree
      registration: $registration
      registerCourt: $registerCourt
      companyValue: $companyValue
      taxNumber: $taxNumber
      vatNumber: $vatNumber
    ) {
      feedback
    }
  }
`;

export const CREATE_REAL_ESTATE_OWNERSHIP_MUTATION = gql`
  mutation CreateRealEstateOwnershipMutation(
    $realEstateOwnerId: ID!
    $realEstateName: String!
    $realEstateId: ID!
    $share: Float!
    $startsAt: DateTime!
    $endsAt: DateTime
  ) {
    createRealEstateOwnership(
      realEstateOwnerId: $realEstateOwnerId
      realEstateName: $realEstateName
      realEstateId: $realEstateId
      share: $share
      startsAt: $startsAt
      endsAt: $endsAt
    ) {
      feedback
    }
  }
`;

export const UPDATE_REAL_ESTATE_MUTATION = gql`
  mutation UpdateRealEstateMutation(
    $realEstateId: String!
    $label: String!
    $shortDescription: String!
    $realEstateAssetType: RealEstateAssetType!
    $image: String!
    $street: String!
    $city: String!
    $postalCode: String!
    $propertyValue: Float!
    $latitude: Float!
    $longitude: Float!
  ) {
    updateRealEstate(
      realEstateId: $realEstateId
      label: $label
      shortDescription: $shortDescription
      realEstateAssetType: $realEstateAssetType
      image: $image
      street: $street
      city: $city
      zipCode: $postalCode
      propertyValue: $propertyValue
      latitude: $latitude
      longitude: $longitude
    ) {
      id
      label
      shortDescription
      realEstateAssetType
      image
      realEstateAddress {
        street
        city
        postalCode
      }
      propertyValue
      latitude
      longitude
    }
  }
`;

export const CREATE_MEMBERSHIP_SHARE_COMPANY = gql`
  mutation CreateMembershipShareCompany(
    $ownerId: String
    $companyId: String!
    $share: Float!
    $startsAt: DateTime!
    $endsAt: DateTime
  ) {
    createCompanyOwnership(
      ownerId: $ownerId
      companyId: $companyId
      share: $share
      startsAt: $startsAt
      endsAt: $endsAt
    ) {
      feedback
    }
  }
`;

export const DELETE_REAL_ESTATE: EnhancedMutation = {
  document: gql`
    mutation DeleteRealEstate($id: String!) {
      deleteRealEstate(id: $id) {
        feedback
      }
    }
  `,

  refetches: [{ query: REAL_ESTATE_QUERY }]
};

export const DELETE_COMPANY: EnhancedMutation = {
  document: gql`
    mutation DeleteCompany($id: String!) {
      deleteCompany(id: $id) {
        feedback
      }
    }
  `,

  refetches: [
    { query: USER_COMPANIES_QUERY },
    { query: AVAILABLE_WALLET_OWNERS_QUERY },
    { query: REAL_ESTATE_OWNERS_QUERY }
  ]
};
