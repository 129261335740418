import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Step } from "lib/models/client/order/Step";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import CardWithButton from "client/components/ChaptersOrderLayout/OrderCompanyFounding/sharedComponents/CardWithButton/CardWithButton";

const SelectCompanyFoundationProduct = ({
  t,
  order,
  saveData,
  currentStep
}: StepComponentProps & WithTranslation) => {
  const [userOptedForNewCompany, setUserOptedForNewCompany] = useState(false);

  async function onSubmit() {
    currentStep.complete();

    if (userOptedForNewCompany) {
      prepareNewGmbHOptionFlow(currentStep);
    } else {
      prepareExistingGmbHOptionFlowWithASUpsell(currentStep);
    }

    await saveData({
      ...order.extra,
      userOptedForNewCompany,
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {},
    onSubmit
  });

  return (
    <div data-testid="review-purpose">
      <StepHeading
        text={t("generic:order-company-foundation.steps.select-company-foundation-product.title")}
        dataTestId="step-heading"
      />

      <FormikProvider value={formik}>
        <Form>
          <div className={"select-company-foundation-product"}>
            <CardWithButton
              onClick={async () => {
                setUserOptedForNewCompany(true);
                formik.submitForm();
              }}
              buttonTitle={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.found-with-ride-button"
              )}
              title={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.found-with-ride-title"
              )}
              description={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.found-with-ride-description"
              )}
              badgeText={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.found-with-ride-price"
              )}
              data-testid="found-with-ride"
            />
            <CardWithButton
              onClick={async () => {
                setUserOptedForNewCompany(false);
                formik.submitForm();
              }}
              buttonTitle={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.bring-my-company-to-ride-button"
              )}
              title={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.bring-my-company-to-ride-title"
              )}
              description={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.bring-my-company-to-ride-description"
              )}
              badgeText={t(
                "generic:order-company-foundation.steps.select-company-foundation-product.bring-my-company-to-ride-price"
              )}
              data-testid="bring-my-company-to-ride"
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

function prepareNewGmbHOptionFlow(currentStep: Step): void {
  currentStep.chapter.replaceSubsequentSteps(currentStep, [
    {
      key: "confirm-tax-advisor-necessity"
    }
  ]);
}

function prepareExistingGmbHOptionFlowWithASUpsell(currentStep: Step): void {
  currentStep.chapter.replaceSubsequentSteps(currentStep, [
    { key: "choose-company-legal-form" },
    { key: "confirm-tax-advisor-existence" },
    { key: "select-financial-statements-years" }
  ]);
}

export default withTranslationReady(["generic"])(SelectCompanyFoundationProduct);
