import Table from "components/generic/Table";
import { clubDeals_clubDeals } from "lib/api/queries/graphql/clubDeals";
import React from "react";
import { WithTranslation } from "react-i18next";
import { RealEstateLink } from "sharedComponents/RealEstateLink/RealEstateLink";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import AdminClubDealAddModal from "./AdminClubDealAddModal/AdminClubDealAddModal";
import { AdminClubFormValues } from "./AdminClubDealForm/AdminClubDealForm";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../components/generic/RequestFeedbackHandler";
import AdminClubDealEditModal from "./AdminClubDealEditModal/AdminClubDealEditModal";

export interface AdminClubDealListProps extends WithTranslation {
  clubDeals: clubDeals_clubDeals[];
  realEstateList: { id: string; label: string }[];
  onAddClubDeal?: (values: AdminClubFormValues) => void;
  onEditClubDeal?: (id: string, values: AdminClubFormValues) => void;
  addRequest?: MutationResult | QueryResult | RequestFeedbackInfo;
  editRequest?: MutationResult | QueryResult | RequestFeedbackInfo;
}

const AdminClubDealList = ({
  clubDeals,
  realEstateList,
  addRequest,
  editRequest,
  onAddClubDeal,
  onEditClubDeal,
  t
}: AdminClubDealListProps) => {
  interface ClubDealFormatter {
    (data: unknown, row: clubDeals_clubDeals): JSX.Element;
  }

  interface ClubDealColumn {
    dataField?: string;
    text: string;
    formatter: ClubDealFormatter;
  }

  const column = (
    columnName: string,
    formatter: (clubDeal: clubDeals_clubDeals) => JSX.Element
  ): ClubDealColumn => {
    return {
      dataField: columnName,
      text: t(`nominee-dashboard:investment-club-deals.columns.${columnName}`),
      formatter: (_: unknown, row: clubDeals_clubDeals) => (
        <span data-testid={`club-deal-${columnName}`}>{formatter(row)}</span>
      )
    };
  };

  const columns: ClubDealColumn[] = [
    column("id", (clubDeal) => (
      <AdminClubDealEditModal
        onSubmit={onEditClubDeal}
        addRequest={editRequest}
        clubDeal={clubDeal}
        realEstateList={realEstateList}
      />
    )),
    column("realEstate", (clubDeal) => <RealEstateLink realEstate={clubDeal.asset?.realEstate} />),
    column("minInvestment", (clubDeal) => <>{clubDeal.minInvestmentAmount}</>),
    column("maxInvestment", (clubDeal) => <>{clubDeal.maxInvestmentAmount}</>),
    column("targetYield", (clubDeal) => <>{clubDeal.targetYield}</>),
    column("financed", (clubDeal) => <>{clubDeal.financedPercentage}</>)
  ];

  return (
    <>
      <div className="mb-3 text-right">
        <AdminClubDealAddModal
          onSubmit={onAddClubDeal}
          addRequest={addRequest}
          realEstateList={realEstateList}
        />
      </div>
      <Table data={clubDeals} columns={columns} tableStyle="greenTable" keyField="id" />
    </>
  );
};

export default withTranslationReady(["nominee-dashboard", "generic"])(AdminClubDealList);
