import React from "react";
import { useMutation } from "react-apollo";
import { TFunction } from "i18next";
import { FlowTypeEnum, ResumeLinkType } from "../../../global-query-types";
import { ResumeLink } from "./ResumeLink";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import {
  GenerateResumeTokenLink,
  GenerateResumeTokenLinkVariables
} from "../../../lib/api/mutations/graphql/GenerateResumeTokenLink";
import { GENERATE_RESUME_TOKEN_LINK } from "../../../lib/api/mutations/GENERATE_RESUME_TOKEN_LINK";

type ContainerProps = {
  id: string;
  t: TFunction;
  currentStep?: string;
  flowType?: FlowTypeEnum;
  linkType?: ResumeLinkType;
};

const ResumeLinkContainer = ({
  id,
  t,
  currentStep,
  flowType,
  linkType = ResumeLinkType.RESUME
}: ContainerProps) => {
  const [generateResumeLinkToken, generateResumeLinkTokenRequest] = useMutation<
    GenerateResumeTokenLink,
    GenerateResumeTokenLinkVariables
  >(GENERATE_RESUME_TOKEN_LINK);

  const generateResumeLink = async (linkType: ResumeLinkType) => {
    const { data } = await generateResumeLinkToken({
      variables: { companyCreationId: id, linkType }
    });

    return data;
  };

  return (
    <RequestFeedbackHandler requests={[generateResumeLinkTokenRequest]} showChildrenWhileLoading>
      <ResumeLink
        t={t}
        currentStep={currentStep}
        flowType={flowType}
        linkType={linkType}
        generateResumeLink={generateResumeLink}
      />
    </RequestFeedbackHandler>
  );
};

export default ResumeLinkContainer;
