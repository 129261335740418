import { WebSocketLink } from "apollo-link-ws";
import logger from "../Logger";
import { getToken } from "../Authentication/getToken";

export const getWebsocketEndpoint = () => {
  const endpoint = process.env.REACT_APP_WS_ENDPOINT as string;
  if (endpoint == null || endpoint.length === 0) {
    throw new Error("Subscription websocket endpoint not configured");
  }
  logger.debug(`Subscription websocket endpoint is '${endpoint}'`);
  return endpoint;
};

export const buildConnectionParams = () => {
  // get the authentication token to enrich the request
  const token = getToken();
  return {
    Authorization: token?.length ? `Bearer ${token}` : ""
  };
};

export const websocketLink = new WebSocketLink({
  uri: getWebsocketEndpoint(),
  options: {
    reconnect: true,
    connectionParams: buildConnectionParams()
  }
});
