import React from "react";
import { useParams } from "react-router";
import { companyName } from "lib/formatters/companyFormatter";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { PageLayout } from "sharedComponents/templates/PageLayout/PageLayout";
import TradingTransactionHistory from "../TradingTransactionHistory/TradingTransactionHistory";
import { GetCompanyWithWepaTransactionSummary } from "lib/api/queries/CompanyWithWepaTransactionSummary";

export const TradingTransactionHistoryLayoutContainer = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { data, ...wepaTransactionSummaryRequest } =
    GetCompanyWithWepaTransactionSummary(companyId);

  return (
    <RequestFeedbackHandler requests={[wepaTransactionSummaryRequest]}>
      <PageLayout
        className="ride-layout"
        pageTitle={companyName({
          name: data?.companyById?.name,
          legalForm: data?.companyById?.legalForm
        })}
        mainContent={<TradingTransactionHistory data={data?.getWepaTransactionSummary} />}
      />
    </RequestFeedbackHandler>
  );
};
