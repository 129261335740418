import React from "react";
import {
  dateOfBirthFieldFor,
  phoneFieldFor,
  selectArrayFieldFor,
  textArrayFieldFor
} from "../../generic/Trader-GmbH/companyCreation/commonFields";
import {
  passwordFieldFor,
  radiosFieldFor,
  selectFieldFor,
  textFieldFor
} from "../../generic/commonFields";
import { OptionItem } from "../../generic/Select";
import {
  countryListWithFlag,
  defaultCountryOptions
} from "../../../lib/dataAdapters/countryUtils/countryUtils";

import { registerCourt } from "../../../common/staticData/registerCourtOptions/registerCourt";
import { TFunction } from "i18next";
import { FlowTypeEnum } from "../../../global-query-types";
import { ErrorMessage } from "../../generic/ErrorMessage";

export const TraderShareholdersFormKey = "Trader-GmbH:Shareholders";
export const TraderSharesFormKey = "Trader-GmbH:Shares";
export const TraderDirectorRoleFormKey = "Trader-GmbH:Director";
export const TraderCompanyFormKey = "Trader-GmbH:Company";
export const TraderShareholderDetailFormKey = "Trader-GmbH:Shareholder-Detail";
export const TraderAddressFormKey = "Trader-GmbH:Address";

const options = countryListWithFlag;

const optionalField = (t) => <span className="text-muted">{t("trader-gmbh:optional")}</span>;

export const TitleArrayField = ({
  t,
  values,
  formik,
  prefix,
  touched,
  currentFlowType,
  errors,
  disabled = false
}) => {
  const label = (currentFlowType = FlowTypeEnum.ADMIN_SERVICE ? (
    <span>
      {t("trader-gmbh:title")} ({optionalField(t)})
    </span>
  ) : (
    <span className="d-flex justify-content-between">
      {t("trader-gmbh:title")} {optionalField(t)}
    </span>
  ));
  return textArrayFieldFor(t, values, formik, prefix, "title", label, touched, errors, {
    form: TraderShareholdersFormKey,
    labelStyle: { width: "100%" },
    disabled
  });
};

export const FirstNameArrayField = ({
  t,
  values,
  formik,
  prefix,
  touched,
  errors,
  disabled = false
}) => {
  return textArrayFieldFor(
    t,
    values,
    formik,
    prefix,
    "firstName",
    t("trader-gmbh:first-name"),
    touched,
    errors,
    {
      form: TraderShareholdersFormKey,
      disabled
    }
  );
};

export const LastNameArrayField = ({
  t,
  values,
  formik,
  prefix,
  touched,
  errors,
  disabled = false
}) => {
  return textArrayFieldFor(
    t,
    values,
    formik,
    prefix,
    "lastName",
    t("trader-gmbh:last-name"),
    touched,
    errors,
    {
      form: TraderShareholdersFormKey,
      disabled
    }
  );
};

interface EmailArrayFieldProps {
  t: TFunction;
  values;
  formik;
  prefix: string;
  touched;
  errors;
  currentStep?: string;
  disabled?: boolean;
}

export const EmailArrayField = ({
  t,
  values,
  formik,
  prefix,
  touched,
  errors,
  currentStep,
  disabled = false
}: EmailArrayFieldProps) => {
  let optionalLabel: JSX.Element;
  if (currentStep === "EMPLOYEE_MANAGING_DIRECTOR") {
    optionalLabel = optionalField(t);
  } else {
    optionalLabel = <></>;
  }

  const label = (
    <span className="d-flex justify-content-between">
      {t("trader-gmbh:email")} {optionalLabel}
    </span>
  );

  return textArrayFieldFor(t, values, formik, prefix, "email", label, touched, errors, {
    form: TraderShareholdersFormKey,
    disabled
  });
};

export const PhoneField = ({
  t,
  formik,
  values,
  prefix,
  defaultCountry,
  handleChange,
  disabled = false
}) => {
  return phoneFieldFor(
    t,
    formik,
    values.phoneNumber,
    prefix,
    defaultCountry,
    "phoneNumber",
    t("trader-gmbh:phoneNumber"),
    handleChange,
    { form: TraderShareholdersFormKey, disabled }
  );
};

interface SalutationFieldProps {
  t: TFunction;
  values;
  prefix: string;
  options: any;
  handleChange: any;
  currentStep?: string;
  disabled?: boolean;
}

export const SalutationField = ({
  t,
  values,
  prefix,
  options,
  handleChange,
  currentStep,
  disabled = false
}: SalutationFieldProps) => {
  const name = currentStep === "EMPLOYEE_MANAGING_DIRECTOR" ? "salutationExtMD" : "salutation";
  const currentSelectedIndex =
    currentStep === "EMPLOYEE_MANAGING_DIRECTOR" ? values.salutationExtMD : values.salutation;

  return radiosFieldFor(
    t("trader-gmbh:salutation"),
    prefix + name,
    false,
    options,
    handleChange,
    currentSelectedIndex,
    undefined,
    disabled
  );
};

export const SharePercentageArrayField = ({
  t,
  values,
  formik,
  prefix,
  localError: formError,
  touched,
  errors,
  disabled,
  extra = {}
}) => {
  const label = "";
  let inputBorderColor = formError ? "shares-text-input border border-danger" : "shares-text-input";
  return textArrayFieldFor(t, values, formik, prefix, "share", label, touched, errors, {
    type: "number",
    suffix: "%",
    form: TraderSharesFormKey,
    className: inputBorderColor,
    disabled,
    ...extra
  });
};

interface CompanyNameFieldProps {
  t: TFunction;
  formik;
  placeholder?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  ignoreErrors?: boolean;
}

export const CompanyNameField = ({
  t,
  formik,
  placeholder = "",
  label,
  name = "companyName",
  disabled = false,
  required = false,
  ignoreErrors = false
}: CompanyNameFieldProps) => {
  return textFieldFor(t, formik, name, label, {
    errorComponent: ignoreErrors ? undefined : ErrorMessage,
    extra: { form: TraderCompanyFormKey },
    placeholder,
    className: "text-input",
    disabled,
    required
  });
};

export const CompanyNameSuffixField = ({ t, formik, companyNameSuffixFlowType }) => {
  const options: OptionItem[] = companyNameSuffixFlowType.map((suffix) => ({
    title: suffix + " " + t("generic:legalForm.GmbH"),
    value: suffix
  }));
  return selectFieldFor(t, formik, "companyNameSuffix", null, options);
};

export const NotaryLinkField = ({ t, formik, name }) => {
  const label = (
    <span className="d-flex justify-content-between">
      {t(`nominee-dashboard:person.notary-links.${name}`)}
    </span>
  );

  return textFieldFor(t, formik, name, label, {
    errorComponent: ErrorMessage,
    className: "text-input"
  });
};

export const AddressCareOfField = ({ t, formik }) => {
  const label = (
    <span className="d-flex justify-content-between">
      {t("generic:c/o")} {optionalField(t)}
    </span>
  );

  return textFieldFor(t, formik, "careOf", label, {
    errorComponent: ErrorMessage,
    extra: { form: TraderAddressFormKey },
    className: "text-input",
    placeholder: t("generic:c/o-placeholder")
  });
};

export const StreetAndNumberField = ({ t, formik, disabled = false }) =>
  textFieldFor(t, formik, "street", t("trader-gmbh:address.street"), {
    errorComponent: ErrorMessage,
    extra: { form: TraderAddressFormKey },
    className: "text-input",
    placeholder: "Mitte 123",
    disabled
  });

export const StreetLine2Field = ({ t, formik, disabled = false }) =>
  textFieldFor(t, formik, "streetLine2", t("generic:street-line-2"), {
    errorComponent: ErrorMessage,
    extra: { form: TraderAddressFormKey },
    className: "text-input",
    placeholder: t("generic:street-line-2-placeholder"),
    disabled
  });

export const CityField = ({ t, formik, disabled = false }) =>
  textFieldFor(t, formik, "city", t("trader-gmbh:address.city"), {
    errorComponent: ErrorMessage,
    extra: { form: TraderAddressFormKey },
    className: "text-input",
    placeholder: "Berlin",
    disabled
  });

export const PostcodeField = ({ t, formik, disabled = false }) =>
  textFieldFor(t, formik, "postalCode", t("trader-gmbh:address.postcode"), {
    errorComponent: ErrorMessage,
    extra: { form: TraderAddressFormKey },
    className: "number-post-code-text-input",
    placeholder: "12345",
    disabled
  });

export const CountryField = ({ t, formik }) => {
  const label = t("trader-gmbh:address.country");

  return selectArrayFieldFor(
    t,
    formik.values,
    formik,
    "country",
    label,
    options,
    defaultCountryOptions,
    "",
    { form: TraderAddressFormKey }
  );
};

export const DateOfBirthArrayField = ({ t, values, formik, prefix, touched, errors }) => {
  const exampleDOB = "(z.B. 17.03.1970)";
  const label = t("trader-gmbh:DOB") + " " + exampleDOB;
  const dateFormat: string = "dd.MM.yyyy";
  const name = "dateOfBirth";
  const selectedDate = values[name];

  return dateOfBirthFieldFor(
    t,
    formik,
    values,
    name,
    label,
    dateFormat,
    selectedDate,
    prefix,
    touched,
    errors,
    {
      className: "dateField-text-input",
      form: TraderShareholderDetailFormKey,
      input: {
        placeholderText: t("generic:date-placeholder")
      }
    }
  );
};

export const CitizenshipArrayField = ({ t, values, formik, prefix }) => {
  const label = t("trader-gmbh:citizenship");

  return selectArrayFieldFor(
    t,
    values,
    formik,
    "citizenship",
    label,
    options,
    defaultCountryOptions,
    prefix,
    { form: TraderShareholderDetailFormKey }
  );
};

export const StreetAndNumberArrayField = ({ t, values, formik, prefix, touched, errors }) =>
  textArrayFieldFor(
    t,
    values,
    formik,
    prefix,
    "street",
    t("trader-gmbh:address.street"),
    touched,
    errors,
    {
      form: TraderShareholderDetailFormKey,
      placeholder: "Musterstraße 42"
    }
  );

export const PostcodeArrayField = ({ t, values, formik, prefix, touched, errors }) =>
  textArrayFieldFor(
    t,
    values,
    formik,
    prefix,
    "postalCode",
    t("trader-gmbh:address.postcode"),
    touched,
    errors,
    {
      className: "number-post-code-text-input",
      form: TraderShareholderDetailFormKey,
      placeholder: "10123"
    }
  );

export const CityArrayField = ({ t, values, formik, prefix, touched, errors }) =>
  textArrayFieldFor(
    t,
    values,
    formik,
    prefix,
    "city",
    t("trader-gmbh:address.city"),
    touched,
    errors,
    {
      form: TraderShareholderDetailFormKey,
      placeholder: "Musterstadt"
    }
  );

export const CountryArrayField = ({ t, values, formik, prefix }) => {
  const label = t("trader-gmbh:address.country");
  return selectArrayFieldFor(
    t,
    values,
    formik,
    "country",
    label,
    options,
    defaultCountryOptions,
    prefix,
    {
      form: TraderShareholderDetailFormKey
    }
  );
};

export const PersonalTaxIdArrayField = ({ t, values, formik, prefix, touched, errors }) => {
  return textArrayFieldFor(
    t,
    values,
    formik,
    prefix,
    "personalTaxId",
    t("trader-gmbh:personal-tax-id"),
    touched,
    errors,
    {
      form: TraderShareholderDetailFormKey,
      placeholder: "99 999 999 999"
    }
  );
};

export const TaxNumberArrayField = ({ t, values, formik, prefix, touched, errors }) => {
  const label = (
    <span className="d-flex justify-content-between">
      {t("trader-gmbh:tax-number")} {optionalField(t)}
    </span>
  );

  return textArrayFieldFor(t, values, formik, prefix, "taxNumber", label, touched, errors, {
    form: TraderShareholderDetailFormKey,
    placeholder: "99/999/999"
  });
};

export const PasswordField = ({ t, formik }) =>
  passwordFieldFor(t, formik, "password", t("trader-gmbh:password"), {
    errorComponent: ErrorMessage
  });

export const ConfirmPasswordField = ({ t, formik }) =>
  passwordFieldFor(t, formik, "confirmPassword", t("trader-gmbh:confirm-password"), {
    errorComponent: ErrorMessage
  });

export const LegalFormField = ({ t, formik, legalForms, disabled = false }) =>
  selectFieldFor(t, formik, "legalForm", "generic:legal-form", legalForms, { disabled });

export const CompanyRegistrationField = ({ t, formik, disabled = false }) =>
  textFieldFor(t, formik, "registration", "generic:registration", {
    errorComponent: ErrorMessage,
    extra: { form: TraderCompanyFormKey },
    className: "text-input",
    disabled
  });

export const EconomicIdField = ({ t, formik, disabled = false }) =>
  textFieldFor(t, formik, "economicId", "admin-service:economic-id", {
    errorComponent: ErrorMessage,
    extra: { form: TraderCompanyFormKey },
    className: "text-input",
    disabled
  });

export const CompanyTaxNumberField = ({ t, formik, disabled = false }) =>
  textFieldFor(t, formik, "taxNumber", "admin-service:company-tax-number", {
    errorComponent: ErrorMessage,
    extra: { form: TraderCompanyFormKey },
    className: "text-input",
    disabled
  });

export const RegisterCourtField = ({ t, formik, disabled = false }) => {
  const label = t("generic:registerCourt");
  return selectArrayFieldFor(
    t,
    formik.values,
    formik,
    "registerCourt",
    label,
    registerCourt,
    "",
    "",
    { form: TraderCompanyFormKey, isDisabled: disabled }
  );
};

export const BirthNameArrayField = ({ t, values, formik, prefix, touched, errors }) => {
  const label = (
    <span>
      {t("trader-gmbh:birthName")} ({optionalField(t)})
    </span>
  );
  return textArrayFieldFor(t, values, formik, prefix, "birthName", label, touched, errors, {
    form: TraderShareholderDetailFormKey
  });
};

export const BirthPlaceArrayField = ({ t, values, formik, prefix, touched, errors }) =>
  textArrayFieldFor(
    t,
    values,
    formik,
    prefix,
    "placeOfBirth",
    t("trader-gmbh:placeOfBirth"),
    touched,
    errors,
    {
      form: TraderShareholderDetailFormKey
    }
  );
