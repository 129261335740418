import React from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";

const defaultZoom = 11;
const libraries = ["places", "geometry", "drawing"] as Libraries;

interface Location {
  lat: number;
  lng: number;
}

interface mapProps {
  initLocation: Location;
  markerName: string;
}

export const MapContainer: React.FC<mapProps> = (props) => {
  const { initLocation, markerName } = props;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries
  });
  const renderMap = (): JSX.Element => (
    <>
      <GoogleMap
        mapContainerStyle={{
          height: "calc(80vh - 80px)",
          width: "90%"
        }}
        zoom={defaultZoom}
        center={initLocation}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true
        }}>
        <Marker title={markerName} position={initLocation} />
      </GoogleMap>
    </>
  );

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div />;
};
