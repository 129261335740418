import React, { ReactNode } from "react";

export interface SelectedProductCardProps {
  "data-testid"?: string;
  icon: React.ReactNode;
  title: string;
  due?: string;
  price: React.ReactNode;
  badge?: ReactNode;
}

const SelectedProductCard = ({
  "data-testid": dataTestId,
  title,
  due,
  price,
  icon,
  badge
}: SelectedProductCardProps) => {
  const module = "selected-product-card";

  dataTestId = dataTestId ? `${dataTestId}-selected-product-card` : "selected-product-card";

  return (
    <div data-testid={dataTestId} className={module}>
      <div className={`${module}__product-information`}>
        <div className={`${module}__icon`} data-testid={`${dataTestId}-icon`}>
          {icon}
        </div>
        <div className={`${module}__title-section`}>
          <div data-testid={`${dataTestId}-title`}>{title}</div>
          {badge && <div data-testid={`${dataTestId}-badge`}>{badge}</div>}
        </div>
      </div>
      <div className={`${module}__pricing-information`}>
        <div
          data-testid={`${dataTestId}-price`}
          className={`${module}__pricing-information__price`}>
          {price}
        </div>
        {due && (
          <div data-testid={`${dataTestId}-due`} className={`${module}__pricing-information__due`}>
            {due}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedProductCard;
