import React, { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { radiosFieldFor, textFieldFor } from "../../../../../components/generic/commonFields";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../../../../components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { InvalidFeedback } from "../../../../../uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import { CompanyAssets } from "../../../../../global-query-types";

interface OperationalActivitiesProps extends WithTranslation {
  onSubmit: (
    hasOperationalActivities: boolean,
    operationalActivities?: string | null
  ) => Promise<void>;
  companyCreation: MyCompanyCreationQuery_myCompanyCreation;
}

const operationalActivitiesValidationSchema = Yup.object().shape({
  "has-operational-activities": Yup.number().required().integer().moreThan(-1)
});

const OperationalActivities = ({ t, companyCreation, onSubmit }: OperationalActivitiesProps) => {
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(-1);

  useEffect(() => {
    if (companyCreation.hasOperationalActivities === true) {
      setCurrentSelectedIndex(0);
    }

    if (companyCreation.hasOperationalActivities === false) {
      setCurrentSelectedIndex(1);
    }
  }, [companyCreation]);

  const formik = useFormik({
    initialValues: {
      "has-operational-activities": currentSelectedIndex,
      "activity-details": companyCreation.operationalActivities
    },
    validationSchema: operationalActivitiesValidationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const hasOperationalActivities = values["has-operational-activities"] === 0 ? true : false;
      onSubmit(hasOperationalActivities, values["activity-details"] ?? undefined);
    }
  });

  const handleOptionChange = (event) => {
    setCurrentSelectedIndex(parseInt(event.target.value));
    formik.setFieldValue("has-operational-activities", parseInt(event.target.value));
  };

  const hasRealEstate = companyCreation.company?.assets?.find(
    (option) => option === CompanyAssets.REAL_ESTATE
  );
  const hasOperationalActivities = currentSelectedIndex === 0;

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="operational-activities-step">
          <p data-testid="description">
            {t("trader-gmbh:operational-activities.description.introduction")}
          </p>
          <ul>
            <li data-testid="description">
              {t("trader-gmbh:operational-activities.description.list-1")}
            </li>
            <li data-testid="description">
              {t("trader-gmbh:operational-activities.description.list-2")}
            </li>
            <li data-testid="description">
              {t("trader-gmbh:operational-activities.description.list-3")}
            </li>
          </ul>
          <p data-testid="description">
            {t("trader-gmbh:operational-activities.description.other")}
          </p>

          <div className="operational-activities-step__details step-bg mt-md-45 mt-35">
            <p data-testid="question" className="operational-activities-step__question">
              {t("trader-gmbh:operational-activities.question")}
            </p>
            <div className="operational-activities-step__options-wrapper">
              {radiosFieldFor(
                "",
                "has-operational-activities",
                false,
                [{ label: t("generic:yes") }, { label: t("generic:no") }],
                handleOptionChange,
                currentSelectedIndex
              )}
            </div>

            {hasOperationalActivities && hasRealEstate && (
              <p data-testid="real-estate-hint">
                {t("trader-gmbh:operational-activities.real-estate-hint")}
              </p>
            )}
            {hasOperationalActivities && (
              <p data-testid="optional-question" className="operational-activities-step__question">
                {t("trader-gmbh:operational-activities.optional-question")}
              </p>
            )}
            {hasOperationalActivities &&
              textFieldFor(t, formik, "activity-details", "", {
                className: "text-input form-control"
              })}

            <InvalidFeedback hidden={formik.isValid} testPrefix="operational-activities">
              {t("generic:validation-required")}
            </InvalidFeedback>

            <div className="operational-activities-step__save-wrapper">
              <button
                type="submit"
                data-testid="activity-details-submit"
                className="step-submit-btn btn btn-outline-light">
                {t("trader-gmbh:operational-activities.submit")}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["generic", "trader-gmbh", "error-codes"])(OperationalActivities);
