import React from "react";

export const CopyIcon = ({ size = 24, className }: { size?: number; className?: string }) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    data-testid="copy-icon"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7 6.35L8.65 3.3C8.5 3.1 8.25 3 8 3H4C3.45 3 3 3.45 3 4V13C3 13.55 3.45 14 4 14H11C11.55 14 12 13.55 12 13V7.05C12 6.8 11.9 6.55 11.7 6.35ZM10.95 7L8 4V7H10.95ZM4 4V13H11V8H8C7.45 8 7 7.55 7 7V4H4Z"
      fill="currentColor"
    />
    <path d="M1 8H0V1C0 0.45 0.45 0 1 0H8V1H1V8Z" fill="currentColor" />
  </svg>
);
