import * as Yup from "yup";
import PasswordSchema from "./PasswordSchema";
import { TFunction } from "i18next";
import { AppConfigQuery_appConfig_passwordPolicy } from "lib/api/queries/graphql/AppConfigQuery";

export const ConfirmAccountSchema = (
  passwordPolicy: AppConfigQuery_appConfig_passwordPolicy | null | undefined,
  t: TFunction
) => {
  return Yup.object().shape({
    password: passwordPolicy ? PasswordSchema(passwordPolicy, t) : Yup.string(),
    confirmPassword: Yup.string().test("password-match", t("password:no_match"), function (value) {
      const { password } = this.parent;
      return password === value;
    })
  });
};
