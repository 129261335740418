import { getLowTaxBrokerOrder_getLowTaxBrokerOrder_company } from "../api/queries/graphql/getLowTaxBrokerOrder";
import { companyName, CompanyName } from "../formatters/companyFormatter";

export class LeanCompanyDetails {
  readonly id: getLowTaxBrokerOrder_getLowTaxBrokerOrder_company["id"];
  private name: getLowTaxBrokerOrder_getLowTaxBrokerOrder_company["name"];
  private legalForm: getLowTaxBrokerOrder_getLowTaxBrokerOrder_company["legalForm"] | null;

  constructor(company: getLowTaxBrokerOrder_getLowTaxBrokerOrder_company | null | undefined) {
    this.id = company ? company.id : "";
    this.name = company ? company.name : "";
    this.legalForm = company ? company.legalForm : null;
  }

  getFullName(): string {
    const company: CompanyName = {
      name: this.name,
      legalForm: this.legalForm
    };
    return companyName(company);
  }

  getTradeCompanyName(): string | undefined {
    if (!this.id) {
      return undefined;
    }
    const company: CompanyName = {
      name: this.name,
      legalForm: this.legalForm
    };
    return companyName(company);
  }
}
