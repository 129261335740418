import React, { useImperativeHandle } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import CitizenshipInformation from "../CitizenshipInformation/CitizenshipInformation";
import { deepClone } from "common/deepClone";
import * as Yup from "yup";

const PersonCitizenshipSchema = (t) => {
  const validDateDDMMYYYY = /^\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$/;

  return Yup.object().shape({
    gender: Yup.string().required(t("generic:validation-required")),
    birthDate: Yup.string()
      .matches(validDateDDMMYYYY, { message: t("generic:invalid-date") })
      .typeError(t("generic:invalid-date"))
      .required(t("generic:validation-required")),
    citizenship: Yup.string().required(t("generic:validation-required")),
    birthCity: Yup.string().required(t("generic:validation-required")),
    notUSCitizenOrTaxResident: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};

const PersonCitizenshipInformationFormComponent = ({
  t,
  order,
  currentStep,
  saveData,
  confirmCancelModalRef,
  controls,
  entityKey
}: WithTranslation & StepComponentProps & { entityKey: "shareholder" | "employee" }) => {
  const entityIndex = currentStep?.chapter?.index ?? 0;
  let dictionaryCopy = order.extra[`${entityKey}s`] ? deepClone(order.extra[`${entityKey}s`]) : {};
  const entity = dictionaryCopy[entityIndex] ?? {};
  const personCitizenship = entity.personCitizenship ?? {};

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldNotPush", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    currentStep.complete();

    const { shouldNotPush, ...data } = values;
    entity.personCitizenship = data;

    await saveData(
      {
        ...order.extra,
        [`${entityKey}s`]: dictionaryCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldNotPush ?? false
    );
  };

  const initialValues = {
    gender: personCitizenship.gender ?? "",
    birthDate: personCitizenship.birthDate ?? "",
    birthCity: personCitizenship.birthCity ?? "",
    citizenship: personCitizenship.citizenship ?? "DE",
    notUSCitizenOrTaxResident: personCitizenship.notUSCitizenOrTaxResident ?? false
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: PersonCitizenshipSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="company-representative-citizenship-form subchapter-order-layout__scrollable">
          <CitizenshipInformation
            formik={formik}
            prefix={`${currentStep?.disposition}`}
            title={t("shareholder-details:citizenship-information.person-title")}
            entityKey={entityKey}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

const PersonCitizenshipInformationForm =
  (entityKey: "shareholder" | "employee") => (props: StepComponentProps & WithTranslation) =>
    <PersonCitizenshipInformationFormComponent {...props} entityKey={entityKey} />;

export default (entityKey: "shareholder" | "employee") =>
  withTranslation(["generic", "shareholder-details"])(PersonCitizenshipInformationForm(entityKey));
