import React from "react";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { getCountryNameByCode } from "lib/dataAdapters/countryUtils/countryUtils";
import { formatDate } from "common/formatters";
import { proofOfIdentityTypeOptions } from "admin/components/OrderFilesTable/OrderFilesTable";
import ShareholderDetailsSectionTitle from "client/components/ChaptersOrderLayout/sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import {
  FileCard,
  FileDisplayInfo
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/FileCard/FileCard";
import DoneButton, {
  SubchapterButtonArea
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/DoneButton/DoneButton";
import { useHistory } from "react-router-dom";
import BackButton from "uiLibrary/components/Buttons/BackButton/BackButton";

const moduleName = "review-shareholder-id-document";

export const ReviewShareholderIdDocument = withTranslationReady(["generic"])(
  ({
    t,
    order,
    currentStep,
    saveData
  }: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
    const history = useHistory();

    const index = currentStep.chapter.index ?? 0;

    const proofOfIdFile = order.extra?.shareholders?.[index]?.proofOfIDFile;
    const documentType = proofOfIdFile?.typeOfDocument;
    const issuingCountry = proofOfIdFile?.issuingCountry;
    const validUntil = proofOfIdFile?.validThrough;

    const fileExists = (file): file is FileDisplayInfo => !!(file?.name && file?.size);

    const files: FileDisplayInfo[] = [proofOfIdFile, proofOfIdFile?.back].filter(fileExists);

    function goBack() {
      history.goBack();
    }

    async function submit() {
      currentStep.complete();

      await saveData({ ...order.extra, ...currentStep.chapter.order.serialize() }, true);

      const parentStep = currentStep.chapter.parent?.getStepByKey("personal-documents-list");

      if (parentStep) {
        const parentStepIndex = currentStep.chapter.parent?.getIndexOfStep(parentStep);
        history.push(
          `/client/order/generic/${parentStep.chapter.key}/${parentStepIndex}/${order.id}/${order.submissionId}`
        );
      }
    }

    return (
      <div className="subchapter-order-layout__form">
        <div className={`${moduleName} subchapter-order-layout__scrollable`}>
          <ShareholderDetailsSectionTitle
            text={t(
              "generic:ride-broker-onboarding.documents.review-shareholder-id-document.title"
            )}
          />
          <div className={`${moduleName}__fields`}>
            <ReviewField
              name="document-type"
              label={t("generic:low-tax-broker-order.file-section.document-type")}
              value={documentType && getLabelForValue(proofOfIdentityTypeOptions(t), documentType)}
            />
            <ReviewField
              name="issuing-country"
              label={t("generic:low-tax-broker-order.file-section.issuing-country")}
              value={issuingCountry && getCountryNameByCode(issuingCountry)}
            />
            <ReviewField
              name="valid-until"
              label={t("generic:low-tax-broker-order.file-section.valid-until")}
              value={validUntil && formatDate(validUntil)}
            />
          </div>
          <div data-testid="uploaded-files" className={`${moduleName}__uploaded-files`}>
            <div
              data-testid="uploaded-files-title"
              className={`${moduleName}__uploaded-files__title`}>
              {t(
                "generic:ride-broker-onboarding.documents.review-shareholder-id-document.uploaded-files"
              )}
            </div>
            <div className={`${moduleName}__uploaded-files__list`}>
              {files.map((file, index) => (
                <FileCard key={index} file={file} />
              ))}
            </div>
          </div>
        </div>
        <SubchapterButtonArea>
          <BackButton onClick={goBack} />
          <DoneButton onClick={submit} />
        </SubchapterButtonArea>
      </div>
    );
  }
);

const getLabelForValue = (
  options: { value: string; label: string }[],
  documentType: string
): string | undefined => {
  const selectedOption = options.find((option) => option.value === documentType);
  return selectedOption?.label;
};
