import { SwitchCheckboxField } from "../../generic/SwitchCheckboxField";
import React from "react";
import { FormikProps } from "formik";
import { withTranslation, WithTranslation } from "react-i18next";

interface LockSwitchCheckboxProps extends WithTranslation {
  formik: FormikProps<any>;
}

const LockSwitchCheckbox = ({ formik, t }: LockSwitchCheckboxProps) => {
  const state = formik.values["locked"];

  return (
    <SwitchCheckboxField
      name="locked"
      state={state}
      label={`${
        state ? t("nominee-dashboard:company:locked") : t("nominee-dashboard:company:unlocked")
      }`}
      handleClick={formik.handleChange}
    />
  );
};

export default withTranslation()(LockSwitchCheckbox);
