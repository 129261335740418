import React from "react";
import { RouteComponentProps } from "react-router";
import { AuthLayout } from "sharedComponents/templates/AuthLayout/AuthLayout";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { LoginCodeFormContainer } from "../components/LoginCodeForm/LoginCodeFormContainer";

interface LoginCodeSceneProps extends WithTranslation, RouteComponentProps {}

const LoginCodeScene = ({ t }: LoginCodeSceneProps) => {
  return (
    <>
      <PageMetaData title={t("auth:login-code.page-title")} />
      <AuthLayout title={t("auth:login-code.title")} subtitle={t("auth:login.subtitle")}>
        <LoginCodeFormContainer />
      </AuthLayout>
    </>
  );
};

export default withTranslationReady(["auth"])(LoginCodeScene);
