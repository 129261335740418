import gql from "graphql-tag";

export const EMAIL_UPDATE_BY_NOMINEE_WITHOUT_VERIFICATION = gql`
  mutation emailUpdateByNomineeWithoutVerification($newEmail: String!, $userId: String!) {
    updateEmail(newEmail: $newEmail, userId: $userId) {
      id
    }
  }
`;

export const APPROVE_TOKEN_TRANSFER = gql`
  mutation ApproveTokenTransfer(
    $tokenizedSPV: String!
    $walletProviderId: String!
    $targetWallet: String!
    $allowedAmount: Int!
  ) {
    approveTokenTransfer(
      companyId: $tokenizedSPV
      walletProviderId: $walletProviderId
      targetWalletAddress: $targetWallet
      amount: $allowedAmount
    ) {
      feedback
    }
  }
`;
