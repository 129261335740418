import React from "react";

interface LoadingBarProps {
  "data-testid"?: string;
}

export const LoadingBar = ({ "data-testid": dataTestId }: LoadingBarProps) => (
  <div className="loading-bar" data-testid={dataTestId ?? "loading-bar"}>
    <div className="loading-bar__trace" />
  </div>
);
