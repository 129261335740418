import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const RocketIcon = ({ size = 24, className }: IconProps) => (
  <svg
    data-testid="rocket-icon"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M12.75 22.5C12.655 22.5002 12.5608 22.4824 12.4725 22.4475C12.3496 22.3985 12.2419 22.3179 12.1602 22.2139C12.0786 22.1098 12.0259 21.986 12.0075 21.855L11.2575 16.605L12.7575 16.395L13.32 20.3475L15.75 18.39V12.75C15.7494 12.6513 15.7683 12.5534 15.8057 12.4621C15.843 12.3707 15.898 12.2876 15.9675 12.2175L19.02 9.165C19.649 8.54029 20.1478 7.79705 20.4877 6.97829C20.8276 6.15953 21.0017 5.2815 21 4.395V3H19.605C18.7185 2.99832 17.8405 3.17244 17.0217 3.51231C16.2029 3.85217 15.4597 4.35102 14.835 4.98L11.7825 8.0325C11.7124 8.10201 11.6293 8.15701 11.5379 8.19433C11.4466 8.23165 11.3487 8.25057 11.25 8.25H5.61L3.6525 10.695L7.605 11.2575L7.395 12.7575L2.145 12.0075C2.01401 11.9891 1.8902 11.9364 1.78615 11.8548C1.68209 11.7731 1.60148 11.6654 1.5525 11.5425C1.50291 11.4188 1.48719 11.2841 1.50697 11.1522C1.52674 11.0204 1.58129 10.8962 1.665 10.7925L4.665 7.0425C4.73409 6.95273 4.82262 6.87978 4.92394 6.82912C5.02525 6.77846 5.13673 6.75141 5.25 6.75H10.9425L13.77 3.915C14.5344 3.14633 15.4437 2.53705 16.4454 2.12249C17.447 1.70794 18.521 1.49635 19.605 1.5H21C21.3978 1.5 21.7794 1.65804 22.0607 1.93934C22.342 2.22064 22.5 2.60218 22.5 3V4.395C22.5037 5.47903 22.2921 6.55299 21.8775 7.55462C21.4629 8.55626 20.8537 9.46562 20.085 10.23L17.25 13.0575V18.75C17.2495 18.8627 17.2236 18.9737 17.1742 19.075C17.1249 19.1763 17.0534 19.2652 16.965 19.335L13.215 22.335C13.083 22.4409 12.9192 22.499 12.75 22.5Z"
      fill="currentColor"
    />
    <path
      d="M5.46606 17.469L11.4641 11.4709L12.5248 12.5316L6.52672 18.5296L5.46606 17.469Z"
      fill="currentColor"
    />
  </svg>
);
