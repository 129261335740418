import React from "react";
import EditBankAccount from "./EditBankAccount";
import { GetBankAccountById } from "../../../../lib/api/queries/GetBankAccountById";
import { useParams } from "react-router";
import { GetMyGmbHs } from "../../../../components/client/MyGmbHs/Queries";
import { BankType } from "../../../../global-query-types";
import { useMutation } from "@apollo/react-hooks";
import {
  UpsertBankAccount,
  UpsertBankAccountVariables
} from "../../../../lib/api/mutations/graphql/UpsertBankAccount";
import { UPSERT_BANK_ACCOUNT } from "../../../../lib/api/mutations/UPSERT_BANK_ACCOUNT";

interface EditBankAccountContainerProps {
  onRefresh: () => Promise<void>;
}

export const EditBankAccountContainer = ({ onRefresh }: EditBankAccountContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const bankAccount = GetBankAccountById(id);

  const getAllOfTheUserCompaniesRequest = GetMyGmbHs();

  const companies = getAllOfTheUserCompaniesRequest?.data?.companies ?? [];
  const companyOptions = companies.map((company) => {
    return {
      label: company.name,
      value: company.id
    };
  });

  const [upsertBankAccount, updateRequest] = useMutation<
    UpsertBankAccount,
    UpsertBankAccountVariables
  >(UPSERT_BANK_ACCOUNT);

  const handleEdit = async (variables: {
    bankProvider: BankType;
    companyId: string;
    iban: string;
    name: string;
  }) => {
    await upsertBankAccount({
      variables: {
        id: bankAccount.data?.getBankAccountById?.id,
        bankProvider: variables.bankProvider,
        iban: variables.iban,
        name: variables.name,
        plan: bankAccount.data?.getBankAccountById?.plan,
        companyId: variables.companyId
      }
    });

    await onRefresh();
  };

  return (
    <>
      {bankAccount.data?.getBankAccountById && (
        <EditBankAccount
          onRefresh={onRefresh}
          onEdit={handleEdit}
          companyOptions={companyOptions}
          requests={[updateRequest]}
          bankAccount={bankAccount.data.getBankAccountById}
        />
      )}
    </>
  );
};
