import * as Yup from "yup";
import { AssetTypes } from "../types/types";

export const ExperienceLevelPerAreasSchema = (t, assetsOptions: AssetTypes[]) => {
  const shape = {};

  assetsOptions.forEach((asset) => {
    shape[asset] = Yup.string().required(t("generic:validation-required"));
  });

  return Yup.object().shape(shape);
};
