import gql from "graphql-tag";

export const VALIDATE_IBAN = gql`
  query validateIban($iban: String!) {
    validateIban(iban: $iban) {
      iban
      isValid
      bankName
      bic
      errorMessageKey
    }
  }
`;
