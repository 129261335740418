import React from "react";
import { ListGroup } from "react-bootstrap";
import { FlowTypeEnum } from "../../../../global-query-types";

export const OrderAssignment = ({ t, currentFlowType }) => {
  if (currentFlowType === FlowTypeEnum.ADMIN_SERVICE) {
    return (
      <div className="align-assignment">
        <h4 className="step-title mt-35">{t("admin-service:assignment-title")}</h4>
        <ListGroup variant="flush" className="step-7-agreement d-flex px-0" as="ul">
          <ListGroup.Item as="li">{t("admin-service:assignment-1")}</ListGroup.Item>
          <ListGroup.Item as="li">{t("admin-service:assignment-2")}</ListGroup.Item>
          <ListGroup.Item as="li">{t("admin-service:assignment-3")}</ListGroup.Item>
        </ListGroup>
      </div>
    );
  }

  if (currentFlowType === FlowTypeEnum.WEPA_SERVICE) {
    return (
      <div className="align-assignment">
        <h4 className="step-title">{t("wepa-service:assignment-title")}</h4>
        {t("wepa-service:assignment")}
      </div>
    );
  }

  return (
    <div className="align-assignment">
      <h4 className="assignment-title">{t("trader-gmbh:assignment-title")}</h4>
      <ListGroup variant="flush" className="step-7-agreement d-flex px-0" as="ul">
        <ListGroup.Item as="li">{t("trader-gmbh:assignment-1")}</ListGroup.Item>
        <ListGroup.Item as="li">{t("trader-gmbh:assignment-2")}</ListGroup.Item>
        <ListGroup.Item as="li">{t("trader-gmbh:assignment-3")}</ListGroup.Item>
      </ListGroup>
    </div>
  );
};
