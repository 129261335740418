import gql from "graphql-tag";

export const GET_CHECKOUT_SESSION_URL = gql`
  query getCheckoutSessionUrl(
    $companyId: String!
    $paymentPlatformAccount: PaymentPlatformAccount!
  ) {
    getCheckoutSessionUrl(companyId: $companyId, paymentPlatformAccount: $paymentPlatformAccount) {
      redirectUrl
    }
  }
`;
