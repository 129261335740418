import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { FeatureFlagService } from "../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../global-query-types";

export const replaceableKeys = {
  "header:low-tax-broker-orders": "broker-v2:low-tax-broker-order",
  "company-founding-steps:tasks.done-btn.open-ltb": "broker-v2:tasks.done-btn.open-ltb",
  "client:company.brokerage-account-list.brokerName.LTB":
    "broker-v2:company.brokerage-account-list.brokerName.LTB",
  "company-setup.low-tax-broker-sidebar-title":
    "broker-v2:company-setup.low-tax-broker-sidebar-title",
  "generic:advertisement-campaign-banner.continue-ltb-order.title":
    "broker-v2:advertisement-campaign-banner.continue-ltb-order.title",
  "generic:advertisement-campaign-banner.continue-ltb-order.description":
    "broker-v2:advertisement-campaign-banner.continue-ltb-order.description",
  "generic:chapters-layout.low-tax-broker-setup": "broker-v2:chapters-layout.low-tax-broker-setup",
  "generic:company-setup.low-tax-broker-sidebar-title":
    "broker-v2:company-setup.low-tax-broker-sidebar-title",
  "generic:ride-broker-onboarding.consent-to-trading-on-own-account.description":
    "broker-v2:ride-broker-onboarding.consent-to-trading-on-own-account.description",
  "generic:low-tax-broker-overview.title": "broker-v2:low-tax-broker-overview.title",
  "generic:low-tax-broker-overview.subtitle": "broker-v2:low-tax-broker-overview.subtitle",
  "generic:low-tax-broker-overview.progress-text":
    "broker-v2:low-tax-broker-overview.progress-text",
  "generic:low-tax-broker-overview.banner.title": "broker-v2:low-tax-broker-overview.banner.title",
  "generic:low-tax-broker-overview.banner.description":
    "broker-v2:low-tax-broker-overview.banner.description",
  "generic:add-company-details.content-description":
    "broker-v2:add-company-details.content-description",
  "generic:complete-application.content-description":
    "broker-v2:complete-application.content-description",
  "generic:application-review.content-description":
    "broker-v2:application-review.content-description",
  "generic:setup-account.buttons-open-low-tax-broker-web-interface":
    "broker-v2:setup-account.buttons-open-low-tax-broker-web-interface",
  "generic:fund-account.celebration-modal.message":
    "broker-v2:fund-account.celebration-modal.message",
  "generic:lei-application.pending.afterword": "broker-v2:lei-application.pending.afterword",
  "generic:product-card.low-tax-broker.title": "broker-v2:product-card.low-tax-broker.title",
  "generic:product-card.low-tax-broker.learn-more-modal.description":
    "broker-v2:product-card.low-tax-broker.learn-more-modal.description",
  "generic:low-tax-broker-overview.complete-application.title":
    "broker-v2:low-tax-broker-overview.complete-application.title",
  "generic:low-tax-broker-overview.complete-application.content-title":
    "broker-v2:low-tax-broker-overview.complete-application.content-title",
  "generic:low-tax-broker-overview.complete-application.content-description":
    "broker-v2:low-tax-broker-overview.complete-application.content-description",
  "generic:invest": "broker-v2:invest"
};

export const withTranslationReady =
  (
    ns: string[] | string,
    options?: {
      withRef?: boolean;
    }
  ) =>
  <P extends WithTranslation>(component: React.ComponentType<P>) => {
    let finalNamespaces: string[] | string;

    if (Array.isArray(ns)) {
      finalNamespaces = [...["broker-v2"], ...ns];
    } else {
      finalNamespaces = ["broker-v2", ns];
    }

    const ExtendedComponent = withTranslation(finalNamespaces, options)(component);

    return (props: Omit<P, keyof WithTranslation>) => (
      <Suspense fallback={null}>
        <ExtendedComponent {...props} useSuspense={true} />
      </Suspense>
    );
  };

export const rideBrokerT = (key: string) => {
  const newBrokerEnabled = FeatureFlagService.instance.isEnabled(FeatureFlags.NewBroker);
  if (newBrokerEnabled && replaceableKeys[key]) {
    return replaceableKeys[key];
  } else {
    return key;
  }
};
