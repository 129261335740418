import React from "react";
import { WithTranslation } from "react-i18next";
import ClientTextFieldEdit from "../../ClientTextFieldEdit/ClientTextFieldEdit";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { useMutation } from "react-apollo";
import { UPDATE_EMAIL_WITH_VERIFICATION } from "lib/api/mutations/UPDATE_EMAIL_WITH_VERIFICATION";
import {
  UpdateEmailWithVerification,
  UpdateEmailWithVerificationVariables
} from "lib/api/mutations/graphql/UpdateEmailWithVerification";
import { emailValidationWithTranslation } from "lib/validation/EmailSchema";
import { GetMe, ME_QUERY } from "lib/api/queries/MeQuery";

const ProfileEmailEditContainer = ({ t }: WithTranslation) => {
  const meQueryRequest = GetMe();

  const [updateEmailWithVerification, updateEmailWithVerificationRequest] = useMutation<
    UpdateEmailWithVerification,
    UpdateEmailWithVerificationVariables
  >(UPDATE_EMAIL_WITH_VERIFICATION, {
    refetchQueries: [{ query: ME_QUERY, variables: { type: null } }]
  });

  const value = meQueryRequest.data?.me?.email ?? "";
  const onSave = async (newEmail: string) => {
    await updateEmailWithVerification({ variables: { newEmail } });
  };

  return (
    <ClientTextFieldEdit
      requests={[meQueryRequest, updateEmailWithVerificationRequest]}
      onSave={onSave}
      value={value}
      validation={emailValidationWithTranslation(t)}
      label={t("client:profile.edit.email")}
      submitPath={`/client/my-profile`}
    />
  );
};

export default withTranslationReady("client")(ProfileEmailEditContainer);
