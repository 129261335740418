import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepComponentProps, StepHeading } from "../../../ChaptersOrderLayout.partials";
import { ReviewBrokerSetup } from "../BrokerSetup/ReviewBrokerSetup/ReviewBrokerSetup";
import { ReviewCompanyInformation } from "../CompanyInformation/ReviewCompanyInformation/ReviewCompanyInformation";
import { ReviewBrokerCompliance } from "../BrokerCompliance/ReviewBrokerCompliance/ReviewBrokerCompliance";
import { ReviewShareholders } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholders";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { ReviewShareDistribution } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareDistribution/ReviewShareDistribution";
import { personName, personNameWithFallback } from "lib/formatters/personFormatter";
import { Order } from "lib/models/client/order/Order";
import { ReviewManagingDirectors } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ManagingDirectors/ReviewManagingDirectors/ReviewManagingDirectors";
import { ReviewDocuments } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ReviewDocuments/ReviewDocuments";
import { LegalDocuments } from "lib/models/client/LowTaxBroker/LegalDocuments";
import legalDocumentsConfig from "client/config/legalDocuments.json";
import { ReviewTrader } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ReviewAndSubmitLowTaxBroker/ReviewTrader/ReviewTrader";
import { UserService } from "lib/services/UserService/UserService";
import { useHistory } from "react-router-dom";
import { TradeCompany } from "lib/models/client/LowTaxBroker/TradeCompany";
import {
  MissingInformationWarning,
  MissingOrderingUserWarning
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ReviewAndSubmitLowTaxBroker/ReviewAndSubmitLowTaxBroker.partials";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

const ReviewAndSubmitLowTaxBroker = ({
  order,
  tradeCompany,
  controls,
  currentStep,
  saveData,
  t
}: WithTranslation & StepComponentProps<LowTaxBrokerOrderData> & TradeCompany) => {
  const { push } = useHistory();
  const currentUserEmail = UserService.instance.currentUser?.email;

  const chaptersKeys = Object.keys(order?.extra?.chapters).filter((c) => c !== "review-and-submit");

  const chapters = chaptersKeys.map((key) => currentStep.chapter.order.getChapter(key));
  const hasIncompleteChapters = chapters.filter((chapter) => !chapter?.completed).length > 0;

  const hasShareholderWithLoggedUserEmail = !!Object.entries(order.extra.shareholders ?? []).find(
    ([_, shareholder]) => shareholder.personData?.email === currentUserEmail
  );
  const hasDirectorWithLoggedUserEmail = !!Object.entries(order.extra.employees ?? []).find(
    ([_, employee]) => employee.personData?.email === currentUserEmail
  );

  const onSubmit = async () => {
    if (
      hasIncompleteChapters ||
      (!hasShareholderWithLoggedUserEmail && !hasDirectorWithLoggedUserEmail)
    ) {
      return;
    }

    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        ...currentStep.chapter.order.serialize()
      },
      true,
      false
    );

    if (FeatureFlagService.instance.isEnabled(FeatureFlags.LowTaxBrokerReviewDocument)) {
      push(`/client/order-submitted/${order.id}`);
    } else {
      push("/client/entities?ltb-order-completed");
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit
  });

  const shareholders = objectIndexToArray(order.extra.shareholders ?? {});
  const employees = objectIndexToArray(order.extra.employees ?? {});

  const orderFlow = new Order(order);

  const getEditLink = (chapter: string, stepKey?: string) => {
    const step = stepKey ? orderFlow.getChapter(chapter)?.getStepByKey(stepKey) : undefined;
    const stepIndex = step?.index ?? 0;
    return `/client/order/generic/${chapter}/${stepIndex}/${order.id}/${order.submissionId}`;
  };

  const isChapterCompleted = (chapter: string) => {
    return orderFlow.getChapter(chapter)?.completed;
  };

  const isStepComplete = (chapter: string, step: string) => {
    const isCompleted = orderFlow.getChapter(chapter)?.getStepByKey(step)?.completed;
    return isCompleted === undefined ? false : isCompleted;
  };

  const trader = shareholders.find(
    (shareholder) =>
      shareholder.personData?.email &&
      shareholder.personData.email === UserService.instance.currentUser?.email
  );

  const orderHasMajorityShareholderQuestionStep = orderFlow
    .getChapter("shareholder-details")
    ?.getStepByKey("company-has-majority-shareholders");

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:company-setup.steps.review-and-submit.title")} />
        {hasIncompleteChapters && <MissingInformationWarning />}
        {!(hasShareholderWithLoggedUserEmail || hasDirectorWithLoggedUserEmail) && (
          <MissingOrderingUserWarning currentUserEmail={currentUserEmail ?? ""} />
        )}
        <div className="review-and-submit-ltb__entries">
          <ReviewBrokerSetup
            tradeCompany={tradeCompany}
            setupData={{
              consentToHandlePersonalData: order.extra.consentToHandlePersonalData,
              declareTaxInTheUS: order.extra.declareTaxInTheUS,
              securityQuestions: order.extra.securityQuestions
            }}
            editLink={getEditLink("broker-setup")}
            isCompleted={isChapterCompleted("broker-setup")}
            isNewBrokerEnabled={order.extra?.isNewBrokerEnabled ?? false}
          />
          <ReviewCompanyInformation
            companyInformation={order.extra}
            editLink={getEditLink("company-information")}
            isCompleted={isChapterCompleted("company-information")}
            isNewBrokerEnabled={order.extra?.isNewBrokerEnabled ?? false}
          />
          <ReviewBrokerCompliance
            complianceData={order.extra}
            editLink={getEditLink("compliance")}
            isCompleted={isChapterCompleted("compliance")}
            isNewBrokerEnabled={order.extra?.isNewBrokerEnabled ?? false}
          />
          <ReviewShareDistribution
            shareDistribution={shareholders.map((shareholder) => ({
              name: personNameWithFallback(shareholder.personData, true),
              shares: shareholder.shares ?? 0
            }))}
            editLink={getEditLink("shareholder-details", "review-and-adjust-shares")}
            isCompleted={isStepComplete("shareholder-details", "review-and-adjust-shares")}
          />
          <ReviewShareholders
            shareholders={shareholders}
            editLink={getEditLink("shareholder-details", "shareholders-list")}
            isNewBroker={!!order.extra.isNewBrokerEnabled}
            isCompleted={
              (orderHasMajorityShareholderQuestionStep
                ? isStepComplete("shareholder-details", "company-has-majority-shareholders")
                : true) && isStepComplete("shareholder-details", "shareholders-list")
            }
          />
          <ReviewManagingDirectors
            managingDirectors={[...shareholders, ...employees]
              .filter((entity) => entity && entity.isManagingDirector)
              .map((entity) => ({ name: personName(entity.personData, true) }))}
            editLink={getEditLink("managing-directors")}
            isCompleted={isChapterCompleted("managing-directors")}
          />
          <ReviewTrader traderName={personNameWithFallback(trader?.personData, true)} />
          <ReviewDocuments
            order={order}
            personalDocumentsEntries={shareholders.map((shareholder) => ({
              personName: personNameWithFallback(shareholder.personData, true), //TODO: fallback is not being match with name used when add a new person (getEntityName)
              idOrPassportUploaded: !!shareholder.proofOfIDFile?.id,
              proofOfAddressUploaded: !!shareholder.proofOfAddressFile?.id
            }))}
            agreedWithLegalDocuments={agreedWithAllLegalDocuments(
              order.extra.legalDocuments ?? {},
              order.extra.isNewBrokerEnabled
            )}
            editLink={getEditLink("documents")}
            isCompleted={isChapterCompleted("documents")}
          />
        </div>
        {hasIncompleteChapters && <MissingInformationWarning />}
        {!(hasShareholderWithLoggedUserEmail || hasDirectorWithLoggedUserEmail) && (
          <MissingOrderingUserWarning currentUserEmail={currentUserEmail ?? ""} />
        )}
        {{ ...controls, props: { ...controls.props, disabled: hasIncompleteChapters } }}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ReviewAndSubmitLowTaxBroker);

function objectIndexToArray<T>(object: { [key: number]: T }): T[] {
  const result: T[] = [];

  for (const key in object) {
    result[key] = object[key];
  }

  return result;
}

const agreedWithAllLegalDocuments = (
  legalDocuments: LegalDocuments,
  isNewBrokerEnabled: boolean | undefined
) => {
  const docs = isNewBrokerEnabled ? legalDocumentsConfig.docsV2 : legalDocumentsConfig.docs;
  for (const { name } of docs) {
    if (!legalDocuments[name]) {
      return false;
    }
  }
  return true;
};
