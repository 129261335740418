import React, { useEffect } from "react";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { GetWepaCompany } from "lib/api/queries/GetWepaCompany";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import WepaCompanyDetails, { WepaProcessingTasks } from "./WepaCompanyDetails";
import { useMutation } from "@apollo/react-hooks";
import { GetWepaProcessingStatuses } from "../../../lib/api/queries/GetWepaProcessingStatuses";
import { SYNC_WEPA_COMPANY } from "../../../lib/api/mutations/SYNC_WEPA_COMPANY";
import {
  syncWepaCompany as SyncWepaCompany,
  syncWepaCompanyVariables as SyncWepaCompanyVariables
} from "../../../lib/api/mutations/graphql/syncWepaCompany";

type WepaCompanyDetailsContainerProps = { gmbh: GmbHDetails };

export const WepaCompanyDetailsContainer = ({ gmbh }: WepaCompanyDetailsContainerProps) => {
  const [getWepaCompany, getWepaCompanyRequest] = GetWepaCompany(gmbh.id);
  const wepaId = gmbh.wepaId;
  useEffect(() => {
    if (wepaId) getWepaCompany();
  }, [wepaId, getWepaCompany]);

  const getWepaProcessingStatuses = GetWepaProcessingStatuses(`${gmbh.wepaId}`);

  const [syncWepaCompanyMutation] = useMutation<SyncWepaCompany, SyncWepaCompanyVariables>(
    SYNC_WEPA_COMPANY
  );

  const details = getWepaCompanyRequest.data?.getWepaCompany;

  const syncWepaCompany = async () => {
    const result = await syncWepaCompanyMutation({
      variables: {
        companyId: gmbh.id
      }
    });

    return result.data?.syncWepaCompany?.feedback;
  };

  const getProcessingStatusesResult = () => {
    const processingStatusesResult = getWepaProcessingStatuses;

    if (
      processingStatusesResult &&
      processingStatusesResult.data &&
      processingStatusesResult.data.getWepaProcessingStatuses
    ) {
      return processingStatusesResult.data.getWepaProcessingStatuses;
    }

    return null;
  };

  const verifyIfSyncIsAvailable = async () => {
    const processingStatuses = getProcessingStatusesResult();

    return (
      processingStatuses?.downloading?.scheduled === 0 &&
      processingStatuses?.downloading?.running === 0 &&
      processingStatuses?.importing?.scheduled === 0 &&
      processingStatuses?.importing?.running === 0
    );
  };

  const getWepaProcessingTasks = () => {
    const processingStatuses = getProcessingStatusesResult();

    if (
      processingStatuses &&
      processingStatuses.importing &&
      processingStatuses.downloading &&
      processingStatuses.generatingAnnual
    ) {
      return {
        wepaProcessingTasks: {
          importing: { ...processingStatuses.importing },
          downloading: { ...processingStatuses.downloading },
          generatingAnnual: { ...processingStatuses.generatingAnnual }
        } as WepaProcessingTasks
      };
    }
  };

  const refetchData = async () => {
    await getWepaProcessingStatuses.refetch();
    await getWepaCompanyRequest.refetch();
  };

  return (
    <RequestFeedbackHandler requests={[getWepaCompanyRequest]}>
      {details && (
        <WepaCompanyDetails
          details={details}
          verifyIfSyncIsAvailable={verifyIfSyncIsAvailable}
          syncWepaCompany={syncWepaCompany}
          wepaProcesses={getWepaProcessingTasks()}
          refetch={refetchData}
        />
      )}
    </RequestFeedbackHandler>
  );
};
