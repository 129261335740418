import * as Yup from "yup";
import { ageValidator } from "../../common/ageValidator";
import { cityRegex, postalCode, taxIdTINOptional } from "./common";

export const CompanyCreationPersonalDetailSchema = () => {
  return Yup.object().shape({
    personalDetails: Yup.array().of(
      Yup.object().shape({
        dateOfBirth: Yup.date()
          .required("generic:validation-required")
          .test("ageValidator", "generic:age-limit-18", ageValidator),
        street: Yup.string().required("generic:validation-required"),
        city: Yup.string()
          .matches(cityRegex, { message: "generic:validation-only-letters-spaces-dash" })
          .required("generic:validation-required"),
        postalCode: Yup.string()
          .matches(postalCode, { message: "generic:validation-only-numbers" })
          .required("generic:validation-required"),
        personalTaxId: taxIdTINOptional,
        placeOfBirth: Yup.string().required("generic:validation-required"),
        birthName: Yup.string()
      })
    )
  });
};
