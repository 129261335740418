import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { WithTranslation, withTranslation } from "react-i18next";

interface TableProps extends WithTranslation {
  data: any[];
  columns: any[] | (() => any[]);
  tableStyle: string;
  keyField: string;
  testid?: string;

  [x: string]: any;
}

const Table = ({ t, data, columns, tableStyle, keyField, testid, ...rest }: TableProps) => {
  if (!data || !data.length) {
    return (
      <p className="risiko" data-testid="no-data-notice">
        {t("generic:no-data-available")}
      </p>
    );
  }

  return (
    <div className="table-container" data-testid={testid}>
      <BootstrapTable
        bootstrap4
        keyField={keyField}
        bordered={false}
        hover
        data={data}
        columns={columns}
        headerClasses={tableStyle}
        {...rest}
      />
    </div>
  );
};

export default withTranslation(["generic"])(Table);
