import { useMutation } from "@apollo/react-hooks";
import { COMPLETE_COMPANY_CREATION_ORDER } from "components/public/CompanyCreation/Mutations";
import React from "react";
import { useParams } from "react-router";
import {
  CompleteCompanyCreationOrder,
  CompleteCompanyCreationOrderVariables
} from "../../../components/public/CompanyCreation/graphql/CompleteCompanyCreationOrder";
import OrderAdminServiceGSUpsell from "./OrderAdminServiceGSUpsell";
import { FlowTypeEnum } from "global-query-types";
import { getFlowTypePath } from "components/public/CompanyCreationFlowType/currentFlowType";

interface OrderAdminServiceGSUpsellContainerProps {
  isNewFlow: boolean;
}

export const OrderAdminServiceGSUpsellContainer = ({
  isNewFlow
}: OrderAdminServiceGSUpsellContainerProps) => {
  const [completeCompanyCreationOrder] = useMutation<
    CompleteCompanyCreationOrder,
    CompleteCompanyCreationOrderVariables
  >(COMPLETE_COMPANY_CREATION_ORDER);

  const { companyCreationId } = useParams<{ companyCreationId: string }>();

  const onSubmit = async () => {
    await completeCompanyCreationOrder({
      variables: {
        companyCreationId,
        isASUpsellFlow: true
      }
    });
  };

  const oldFlowAdminServiceSuccessPageURL = `/${getFlowTypePath(
    FlowTypeEnum.ADMIN_SERVICE
  )}/${companyCreationId}/status`;

  const newGSFlowAdminServiceSuccessPageURL = "/order/gmbh/complete";

  return (
    <OrderAdminServiceGSUpsell
      isShadowUser={true}
      onSubmit={onSubmit}
      shadowUserSuccessPageUrl={
        isNewFlow ? newGSFlowAdminServiceSuccessPageURL : oldFlowAdminServiceSuccessPageURL
      }
    />
  );
};
