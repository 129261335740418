import React, { useState } from "react";
import { NomineeUpdateCompanyVariables } from "../../../../lib/api/mutations/graphql/NomineeUpdateCompany";
import { Col, Container, Row } from "react-bootstrap";
import { ValueView } from "../../../../components/common/layout/ValueView";
import { formatValue } from "../../../../common/formatters";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { TFunction } from "i18next";
import EditableSection from "../../../../sharedComponents/EditableSection/EditableSection";
import {
  BusinessPurposeField,
  BusinessPurposeShortField
} from "../../../../components/generic/company-form/fields";
import { Form, FormikProps, FormikProvider, useFormik } from "formik";
import { ApolloQueryResult } from "apollo-client";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import {
  GetTaxAdvisorClientCompany,
  GetTaxAdvisorClientCompany_getTaxAdvisorClientCompany
} from "../../../../lib/api/queries/graphql/GetTaxAdvisorClientCompany";

export interface BusinessPurposeSectionProps extends WithTranslation {
  company:
    | NomineeCompanyDetails_companyById
    | GetTaxAdvisorClientCompany_getTaxAdvisorClientCompany;
  onSave: (data: NomineeUpdateCompanyVariables) => Promise<void>;
  refetch: () =>
    | Promise<ApolloQueryResult<NomineeCompanyDetails>>
    | Promise<ApolloQueryResult<GetTaxAdvisorClientCompany>>;
}

const BusinessPurposeSection = ({ company, onSave, refetch, t }: BusinessPurposeSectionProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onView = () => {
    setIsEditMode(false);
  };

  const onSubmit = async (data) => {
    setIsEditMode(false);

    await onSave(data);
    await refetch();
  };

  const formik = useFormik({
    initialValues: {
      businessPurpose: company.businessPurpose,
      businessPurposeShort: company.businessPurposeShort
    },
    onSubmit: onSubmit,
    enableReinitialize: true
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditableSection
          testIdPrefix="business-purpose-"
          title={t("nominee-dashboard:company.business-purpose")}
          isEditMode={isEditMode}
          onEdit={onEdit}
          onView={onView}>
          {isEditMode ? (
            <EditMode formik={formik} company={company} t={t} />
          ) : (
            <ViewMode company={company} t={t} />
          )}
        </EditableSection>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(BusinessPurposeSection);

const ViewMode = ({
  company,
  t
}: {
  company:
    | NomineeCompanyDetails_companyById
    | GetTaxAdvisorClientCompany_getTaxAdvisorClientCompany;
  t: TFunction;
}) => (
  <Container>
    <Row>
      <Col>
        <ValueView
          name="generic:company-business-purpose"
          testId="business-purpose"
          value={formatValue(company.businessPurpose)}
          t={t}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <ValueView
          name="generic:company-business-purpose-short"
          testId="business-purpose-short"
          value={formatValue(company.businessPurposeShort)}
          t={t}
        />
      </Col>
    </Row>
  </Container>
);

const EditMode = ({
  formik,
  company,
  t
}: {
  formik: FormikProps<any>;
  company:
    | NomineeCompanyDetails_companyById
    | GetTaxAdvisorClientCompany_getTaxAdvisorClientCompany;
  t: TFunction;
}) => (
  <Container>
    <Row>
      <Col>
        <BusinessPurposeField t={t} formik={formik} />
      </Col>
    </Row>
    <Row>
      <Col>
        <BusinessPurposeShortField t={t} formik={formik} />
      </Col>
    </Row>
  </Container>
);
