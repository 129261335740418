import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { PayInInfo } from "client/components/LowTaxBrokerDashboard/PayIn/PayInModal/PayInModal";
import { OrderData } from "../../ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerData } from "../LowTaxBrokerOverview";
import { LowTaxBrokerOnboardingUpdate } from "lib/models/client/LowTaxBroker/Onboarding";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import moment from "moment";
import { MOMENT_DATE_FORMAT } from "lib/formatters/config";
import CelebrationModal from "../CelebrationModal/CelebrationModal";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { BrokerBankEnum, BrokerNameEnum, FeatureFlags } from "global-query-types";
import { UpsertBrokerageAccountVariables } from "lib/api/mutations/graphql/UpsertBrokerageAccount";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { RideStatus } from "lib/types/types";

interface FundAccountCardProps {
  status: OnboardingStepCardStatus;
  cardIndex: number;
  companyName: string;
  brokerageAccountNumber: string | null | undefined;
  order: OrderData<LowTaxBrokerData> | null | undefined;
  updateOrder: (order: LowTaxBrokerOnboardingUpdate) => Promise<void>;
  upsertBrokerageAccount: (data: UpsertBrokerageAccountVariables) => Promise<void>;
}

const FundAccountCard = ({
  t,
  status,
  cardIndex,
  companyName,
  brokerageAccountNumber,
  order,
  updateOrder,
  upsertBrokerageAccount
}: FundAccountCardProps & WithTranslation) => {
  const shouldCreateBrokerageAccount = FeatureFlagService.instance.isEnabled(
    FeatureFlags.LTB768CreateBrokerageAccountByUserInput
  );
  const [showCelebrationModal, setShowCelebrationModal] = useState(false);
  const showModal = () => {
    setShowCelebrationModal(true);
  };
  const hideModal = () => {
    setShowCelebrationModal(false);
  };

  const completeStep = async () => {
    await updateFundsTransferredAt();
    if (shouldCreateBrokerageAccount) {
      await createBrokerageAccount();
    }
    showModal();
  };

  const updateFundsTransferredAt = async () => {
    if (!order) {
      return;
    }

    await updateOrder({
      id: order.id,
      extra: {
        onboarding: {
          ...order.extra.onboarding,
          fundsTransferredAt: Date.now()
        }
      }
    });
  };

  const createBrokerageAccount = async () => {
    const data: UpsertBrokerageAccountVariables = {
      companyId: order?.entityId ?? "",
      brokerBank: BrokerBankEnum.LTB,
      brokerName: BrokerNameEnum.LTB,
      accountNumber: order?.extra.ibCredentials?.accountNumber ?? ""
    };

    await upsertBrokerageAccount(data);
  };

  const fundsTransferredAt = order?.extra?.onboarding?.fundsTransferredAt;
  const fundsTransferredAtDate = moment(fundsTransferredAt).utc(false).format(MOMENT_DATE_FORMAT);

  return (
    <div>
      <FeatureFlag name={FeatureFlags.LTBCustomerOverviewCompletionConfetti}>
        <CelebrationModal onClose={hideModal} visible={showCelebrationModal} />
      </FeatureFlag>
      <OnboardingStepCard
        data-testid="ltb-fund-account-card"
        number={cardIndex}
        title={t("generic:low-tax-broker-overview.fund-account.title")}
        status={status}
        forceShowContent={status === RideStatus.COMPLETED}>
        <PayInInfo companyName={companyName} brokerageAccountNumber={brokerageAccountNumber} />
        {fundsTransferredAt && (
          <RideAlertBar
            className={"ltb-overview__fund-account-card__alert-message-after-transference"}
            type={AlertType.SUCCESS}
            message={t(
              "generic:low-tax-broker-overview.fund-account.alert-message-after-transference",
              {
                date: fundsTransferredAtDate
              }
            )}
          />
        )}
        <div className="ltb-overview__buttons ltb-overview__fund-account-card__button">
          <button
            data-testid="fund-account-complete-step-button"
            className="ltb-overview__primary-action-btn"
            disabled={!!fundsTransferredAt}
            onClick={completeStep}>
            {t("generic:low-tax-broker-overview.fund-account.complete-step-button")}
          </button>
        </div>
      </OnboardingStepCard>
    </div>
  );
};

export default withTranslationReady(["generic"])(FundAccountCard);
