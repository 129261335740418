import gql from "graphql-tag";

export const COMPANY_DETAILS_BY_COMPANY_ID = gql`
  query companyDetailsByCompanyId($companyId: String!) {
    companyById(companyId: $companyId) {
      id
      name
      projectName
      legalForm
      locked
      registerCourt
      registration
      companyValue
      capitalContribution
      businessAddress {
        street
        streetLine2
        city
        postalCode
        country
      }
      type
      tradeTaxFree
      boxLink
      tokenized
      status
      tokenizedShares
      taxNumber
      vatNumber
      group {
        memberships {
          share {
            share
            startsAt
            membership {
              company {
                id
                name
                legalForm
              }
              person {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const REAL_ESTATE_QUERY_BY_ID = gql`
  query RealEstateQueryById($realEstateId: String!) {
    realEstatesById(realEstateId: $realEstateId) {
      id
      label
      shortDescription
      realEstateAssetType
      image
      realEstateAddress {
        street
        city
        postalCode
      }
      propertyValue
      latitude
      longitude
      owner {
        id
        memberships {
          id
          company {
            id
            name
            legalForm
          }
          person {
            id
          }
          share {
            share
            startsAt
          }
        }
      }
    }
  }
`;

export const REAL_ESTATE_QUERY_BY_COMPANY_ID = gql`
  query RealEstateQueryByCompanyId($companyId: String!) {
    realEstateByCompanyId(companyId: $companyId) {
      id
      label
      shortDescription
      realEstateAssetType
      image
      realEstateAddress {
        street
        city
        postalCode
      }
      propertyValue
      latitude
      longitude
      owner {
        id
        memberships {
          id
          company {
            id
            name
            legalForm
          }
          person {
            id
          }
          share {
            share
            startsAt
          }
        }
      }
    }
  }
`;

export const REAL_ESTATE_GALLERY_QUERY = gql`
  query RealEstateGallery($realEstateId: String!) {
    realEstateGallery(realEstateId: $realEstateId)
  }
`;

export const USER_COMPANIES_QUERY = gql`
  query UserCompaniesQuery($type: CompanyType) {
    companies(type: $type) {
      id
      name
      projectName
      legalForm
      registerCourt
      registration
      companyValue
      capitalContribution
      businessAddress {
        street
        city
        postalCode
      }
      type
      tradeTaxFree
      boxLink
      taxNumber
      vatNumber
      group {
        memberships {
          share {
            share
          }
        }
      }
    }
  }
`;

export const REAL_ESTATE_QUERY = gql`
  query RealEstateQuery {
    realEstates {
      id
      label
      shortDescription
      realEstateAssetType
      image
      realEstateAddress {
        street
        city
        postalCode
      }
      propertyValue
      latitude
      longitude
      owner {
        id
        memberships {
          id
          share {
            share
          }
        }
      }
    }
  }
`;

export const REAL_ESTATE_OWNERSHIP_QUERY_BY_REALESTATE_ID = gql`
  query RealEstateOwnershipQueryByRealEstateId($realEstateId: String) {
    realEstateOwnership(realEstateId: $realEstateId) {
      id
      share
      startsAt
      endsAt
      membership {
        company {
          id
          name
          legalForm
        }
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const COMPANY_OWNERSHIP_QUERY_BY_COMPANY_ID = gql`
  query CompanyOwnershipQueryByCompanyId($companyId: String) {
    companyOwnership(companyId: $companyId) {
      id
      share
      startsAt
      endsAt
      membership {
        company {
          id
          name
          legalForm
        }
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const TOKENIZED_REAL_ESTATES_QUERY = gql`
  query TokenizedRealEstatesQuery {
    tokenizedRealEstates {
      id
      label
      shortDescription
      realEstateAssetType
      image
      realEstateAddress {
        street
        city
        postalCode
      }
      latitude
      longitude
      companyId
      propertyValue
      tokenBalance {
        balance
        total
      }
    }
  }
`;
