import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import { useHistory } from "react-router";
import { ProfilePasswordEditContainer } from "../../components/profile/ProfilePasswordEdit/ProfilePasswordEditContainer";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

const ChangePasswordScene = ({ t }: WithTranslation) => {
  const { goBack } = useHistory();

  return (
    <>
      <PageMetaData title={t("client:profile.edit-page-title.password")} />
      <ModalFormLayout title={t("client:profile.edit.password")} onBack={goBack}>
        <ProfilePasswordEditContainer />
      </ModalFormLayout>
    </>
  );
};

export default withTranslationReady("client")(ChangePasswordScene);
