import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { PaymentMethodType } from "payments-query-types";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "../../../../OnboardingStepCard/OnboardingStepCard";
import {
  Header,
  paymentPeriodicity,
  productName,
  TableHeader
} from "../../../../UnifiedShortOrderForm/CheckoutStep/OrderCostSummary/OrderCostSummary";
import { formatCurrency } from "common/formatters";
import { ItemOrder } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { getAdminServiceProductCardBadge } from "../../../../UnifiedShortOrderForm/ProductSelectionStep/AdminServiceProductCard/AdminServiceProductCard";

/*
TODO: refactor to create a base component.
Related to src/client/components/WepaSection/WepaOnboarding/ReviewStep/ReviewStep.tsx
Related to src/client/components/AdminServiceTiersOnboarding/ReviewStep/ReviewStep.tsx
*/
interface ReviewStepProps extends WithTranslation {
  status: OnboardingStepCardStatus;
  companyName: string;
  fiscalYear: string;
  ASItemOrdered?: ItemOrder; //TODO: maybe only inform the tier.
  paymentMethod?: PaymentMethodType | null;
  onConfirm: () => Promise<void>;
}

const ReviewStep = ({
  t,
  status,
  companyName,
  paymentMethod,
  fiscalYear,
  ASItemOrdered,
  onConfirm
}: ReviewStepProps) => {
  const className = "admin-service-onboarding-review-step";
  const [isFinalizing, setIsFinalizing] = React.useState(false);

  const onConfirmHandler = async () => {
    try {
      setIsFinalizing(true);
      await onConfirm();
    } finally {
      setIsFinalizing(false);
    }
  };

  return (
    <OnboardingStepCard
      data-testid={"review-step"}
      number={4}
      title={t("generic:wepa-direct.steps.review.review-order")}
      status={status}>
      <div className={className}>
        <Typography
          category={"Headline"}
          size={300}
          weight={"Heavy"}
          data-testid={"chosen-payment-method"}>
          {t("generic:your-payment-method")}:{" "}
          {t(
            `generic:payment-method-card.method-choice.${
              paymentMethod === PaymentMethodType.Sepa ? "sepa" : "manual"
            }.name`
          )}
        </Typography>

        <div className={`${className}__company-information`}>
          <div className={`${className}__company-information-field`}>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              data-testid={"company-name-label"}>
              {`${t(`generic:company-name`)}: `}
            </Typography>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Light"}
              data-testid={"company-name"}>
              {companyName}
            </Typography>
          </div>
          <div className={`${className}__company-information-field`}>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              data-testid={"fiscal-year-starting-date-label"}>
              {`${t(`generic:wepa-direct.steps.fiscal-year-starting-date.title`)}: `}
            </Typography>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Light"}
              data-testid={"fiscal-year-starting-date"}>
              {fiscalYear}
            </Typography>
          </div>
        </div>

        {ASItemOrdered && <ASOrderCostSummary itemOrdered={ASItemOrdered} />}
      </div>

      <RideButton
        data-testid={"submit-order"}
        label={t("generic:wepa-direct.steps.review.button")}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        onClick={onConfirmHandler}
        disabled={isFinalizing}
      />

      <Typography
        data-testid="payment-disclaimer"
        category={"Paragraph"}
        size={200}
        weight={"Light"}
        className={`${className}__payment-disclaimer`}>
        {t("generic:payment-method-card.payment-date-info-as-ride-steuerberatung-migration")}
      </Typography>
    </OnboardingStepCard>
  );
};

interface ASOrderCostSummaryProps extends WithTranslation {
  itemOrdered: ItemOrder;
}
/*
TODO: only changing content.
 Refactor missing. Related to WepaOrderCostSummary and the OrderSummary
 */
const ASOrderCostSummary = withTranslationReady(["generic"])(
  ({ t, itemOrdered }: ASOrderCostSummaryProps) => {
    const tableHeader = [
      {
        name: "product-name",
        label: t("generic:short-order-form.checkout.order-summary.items-table.products")
      },
      {
        name: "payment-periodicity",
        label: t("generic:short-order-form.checkout.order-summary.items-table.payment-periodicity")
      },
      {
        name: "product-price",
        label: t("generic:short-order-form.checkout.order-summary.items-table.costs")
      }
    ];

    return (
      <div data-testid={"order-summary"} className={"short-order-form-order-summary"}>
        <Header t={t} />
        <div className={"short-order-form-order-summary__table"}>
          <div className={"short-order-form-order-summary__table-headers"}>
            {tableHeader.map((header) => (
              <TableHeader
                key={`order-summary-items-table-${header.name}`}
                name={header.name}
                label={header.label}
              />
            ))}
          </div>
          <span className={"short-order-form-order-summary__table-rows"}>
            <div
              className={"short-order-form-order-summary__table-row"}
              data-testid={`order-summary-items-row-company-setup`}>
              <div className="d-flex">
                <Typography
                  className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell text-nowrap`}
                  data-testid={"order-summary-items-cell-product-name"}
                  category="Paragraph"
                  size={100}
                  weight={"Heavy"}>
                  {productName(t)[itemOrdered.product]}
                </Typography>
                <div className="ml-3">{getAdminServiceProductCardBadge(itemOrdered.tier)}</div>
              </div>
              <Typography
                className={
                  "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
                }
                data-testid={"order-summary-items-cell-payment-periodicity"}
                category="Paragraph"
                size={200}
                weight={"Light"}>
                {paymentPeriodicity(t)[itemOrdered.product]}
              </Typography>
              <Typography
                className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell`}
                data-testid={"order-summary-items-cell-product-price"}
                category="Paragraph"
                size={100}
                weight={"Heavy"}>
                {itemOrdered.recurringPrice
                  ? `${formatCurrency(itemOrdered.recurringPrice, { fixed: 0 })}`
                  : ""}
              </Typography>
            </div>
          </span>
        </div>
      </div>
    );
  }
);

export default withTranslationReady(["generic"])(ReviewStep);
