import React, { ChangeEventHandler } from "react";
import { OptionItem, Select } from "../../generic/Select";
import { RadioOptionElement } from "../../generic/Radios";
import { TFunction } from "i18next";
import { FormikProps } from "formik";
import { radiosFieldFor } from "../../generic/commonFields";

export enum OwnershipType {
  personally,
  throughMyCompany
}

interface OwnershipTypeRadioFieldProps {
  label: string;
  value: OwnershipType;
  options: RadioOptionElement[];
  handleChange: ChangeEventHandler;
  required?: boolean;
}

export const OwnershipTypeRadioField = ({
  label,
  value,
  options,
  handleChange,
  required
}: OwnershipTypeRadioFieldProps) =>
  radiosFieldFor(label, "ownershipType", required, options, handleChange, value);

interface OwnerSelectFieldProps {
  t: TFunction;
  formik: FormikProps<{ ownerId: string }>;
  options: OptionItem[];
  noOwnerShouldBeSelected: boolean;
  handleTouch: () => void;
}

export const NO_OWNER_SELECTED = "---";

export const OwnerSelectField = ({
  t,
  formik,
  options,
  noOwnerShouldBeSelected,
  handleTouch
}: OwnerSelectFieldProps) => (
  <Select
    t={t}
    name="ownerId"
    value={noOwnerShouldBeSelected ? NO_OWNER_SELECTED : formik.values.ownerId}
    options={options}
    onChange={formik.handleChange}
    onMouseDown={handleTouch}
    isInvalid={formik.touched.ownerId && !!formik.errors.ownerId}
    error={formik.touched.ownerId ? formik.errors.ownerId : undefined}
  />
);
