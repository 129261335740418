import { useQuery } from "@apollo/react-hooks";
import { GetTaxAdvisorCompanies_taxAdvisorCompanies } from "./graphql/GetTaxAdvisorCompanies";
import gql from "graphql-tag";
import { getAllWepaServices } from "./graphql/getAllWepaServices";

const GET_ALL_WEPA_SERVICES = gql`
  query getAllWepaServices {
    getAllWepaServices {
      id
      currentStep
      createdAt
      company {
        id
        name
        legalForm
        contactPerson {
          id
          firstName
          lastName
          user {
            id
            email
          }
        }
      }
    }
  }
`;
export const GetAllWepaServices = () =>
  useQuery<getAllWepaServices, GetTaxAdvisorCompanies_taxAdvisorCompanies>(GET_ALL_WEPA_SERVICES);
