import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { CompanyById_companyById } from "../../../lib/api/queries/graphql/CompanyById";
import { CompanyHeaderSection } from "../CompanyHeaderSection/CompanyHeaderSection";
import { GmbHDetails } from "../../../lib/models/client/GmbHDetails";
import { Col, Container, Row } from "react-bootstrap";
import {
  RideCheckbox,
  RideCheckboxVariant
} from "../../../uiLibrary/components/RideCheckbox/RideCheckbox";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "../../../lib/hooks/useRideFormik";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../uiLibrary/designSystem/RideButton/RideButton";

export interface EntireFiscalYear {
  year: string;
  adminServiceFiscalYearId: string | null;
  wepaAnnualId: number | null;
  isConfirmed: boolean;
}

interface EntireFiscalYearConfirmationProps extends WithTranslation {
  company: Pick<CompanyById_companyById, "id" | "name" | "legalForm" | "fiscalYear">;
  fiscalYear: EntireFiscalYear;
  confirmFiscalYear: () => Promise<void>;
}

const EntireFiscalYearConfirmation = ({
  t,
  company,
  fiscalYear,
  confirmFiscalYear
}: EntireFiscalYearConfirmationProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (values: any) => {
    setIsLoading(true);
    await confirmFiscalYear();
    setIsLoading(false);
  };

  const formik = useRideFormik({
    initialValues: {
      hasConfirmedAllFilesUploaded: false,
      hasConfirmedAllBrokerageAccountsValidlyConnected: false
    },
    onSubmit: submit
  });

  const canSubmit = () => {
    const hasConfirmedAllFilesUploaded = fiscalYear.adminServiceFiscalYearId
      ? formik.values.hasConfirmedAllFilesUploaded
      : true;
    const hasConfirmedAllBrokerageAccountsValidlyConnected = fiscalYear.wepaAnnualId
      ? formik.values.hasConfirmedAllBrokerageAccountsValidlyConnected
      : true;
    return (
      !isLoading && hasConfirmedAllFilesUploaded && hasConfirmedAllBrokerageAccountsValidlyConnected
    );
  };

  const isAlreadyConfirmed = fiscalYear.isConfirmed;

  const uploadFilesCheckbox = (
    <RideCheckbox
      name={"hasConfirmedAllFilesUploaded"}
      isReadonly={false}
      variant={RideCheckboxVariant.Light}
      label={t("generic:fiscal-years.confirm-all-files-uploaded")}
    />
  );

  const connectBrokerageAccountsCheckbox = (
    <RideCheckbox
      name={"hasConfirmedAllBrokerageAccountsValidlyConnected"}
      isReadonly={false}
      variant={RideCheckboxVariant.Light}
      label={t("generic:fiscal-years.confirm-all-brokerage-accounts-validly-connected")}
    />
  );

  const alreadyConfirmedText = "generic:fiscal-years.already-confirmed-text";

  let confirmationText = "generic:fiscal-years.confirmation-text";
  let checkboxes = [uploadFilesCheckbox, connectBrokerageAccountsCheckbox];

  if (fiscalYear.wepaAnnualId === null) {
    confirmationText = "generic:fiscal-years.confirmation-text-only-file-uploads";
    checkboxes = [uploadFilesCheckbox];
  }

  if (fiscalYear.adminServiceFiscalYearId === null) {
    confirmationText = "generic:fiscal-years.confirmation-text-only-brokerage-accounts";
    checkboxes = [connectBrokerageAccountsCheckbox];
  }

  return (
    <div data-testid="entire-fiscal-year-confirmation">
      <CompanyHeaderSection
        gmbh={new GmbHDetails(company)}
        goBackLink={`/client/entities/${company.id}/admin-service`}
        showTabbedNavigation={false}
      />

      <Container>
        <Row>
          <Col>
            <h1 data-testid="confirmation-header">
              {t("generic:fiscal-years.confirmation-title", { year: fiscalYear.year })}
            </h1>

            {isAlreadyConfirmed ? (
              <>
                <p data-testid="confirmation-instructions">
                  {t(alreadyConfirmedText, { year: fiscalYear.year })}
                </p>
              </>
            ) : (
              <>
                <p data-testid="confirmation-instructions">
                  {t(confirmationText, { year: fiscalYear.year })}
                </p>
                <FormikProvider value={formik}>
                  <Form>
                    {checkboxes.map((checkbox, index) => (
                      <p key={index}>{checkbox}</p>
                    ))}
                    <RideButton
                      disabled={!canSubmit()}
                      data-testid={"confirm-fiscal-year"}
                      label={t("generic:fiscal-years.confirm-fiscal-year-cta")}
                      variant={RideButtonVariant.PRIMARY}
                      size={RideButtonSize.BIG}
                    />
                  </Form>
                </FormikProvider>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslationReady(["generic"])(EntireFiscalYearConfirmation);
