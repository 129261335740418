import * as React from "react";
import { FormEventHandler } from "react";
import { TFunction } from "i18next";
import { Form } from "react-bootstrap";
import { disabledTransformation } from "../../common/disabledTransformation";

export interface OptionItem {
  value: string | null;
  title: string;
  disabled?: boolean;
}

interface SelectProps {
  t: TFunction;
  name: string;
  label?: string | React.ReactElement;
  value: string | null;
  onChange?: FormEventHandler;
  onMouseDown?: FormEventHandler;
  isValid?: boolean;
  isInvalid?: boolean;
  error?: string;
  options: OptionItem[];
  formText?: string;
  disabled?: boolean;
  className?: any;
}

export const Select: React.FunctionComponent<SelectProps> = (props) => {
  const {
    t,
    name,
    label = "",
    onChange,
    onMouseDown,
    isValid,
    isInvalid,
    options,
    value,
    error = "",
    formText,
    disabled,
    className
  } = props;

  return (
    <Form.Group controlId={name} className={className}>
      {label && (
        <Form.Label column={false}>{typeof label === "string" ? t(label) : label}</Form.Label>
      )}

      <Form.Control
        as="select"
        data-testid={`${name}-select`}
        name={name}
        onChange={disabledTransformation(disabled, onChange)}
        onMouseDown={onMouseDown}
        isValid={isValid}
        isInvalid={isInvalid}
        disabled={disabled}
        value={value || ""}>
        {options.map((o) => (
          <option key={o.value || ""} value={o.value || ""} disabled={o.disabled}>
            {o.title}
          </option>
        ))}
      </Form.Control>

      <Form.Control.Feedback type="invalid" data-testid={`${name}-invalid-feedback`}>
        {t(error)}
      </Form.Control.Feedback>

      {formText ? <Form.Text className="text-muted">{t(formText)}</Form.Text> : null}
    </Form.Group>
  );
};
