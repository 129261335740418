import { InvestmentCardProps } from "client/components/investments/InvestmentCard/InvestmentCard";
import { AssetType, InvestmentState } from "global-query-types";
import {
  myInvestments_myInvestments,
  myInvestments_myInvestments_asset
} from "lib/api/queries/graphql/myInvestments";
import { companyName } from "../../formatters/companyFormatter";
import { personNameWithFallback } from "lib/formatters/personFormatter";

export interface InvestmentCardPropsWithId extends InvestmentCardProps {
  asset: myInvestments_myInvestments_asset;
}

export const toInvestmentCardPropsFromInvestment = (
  investment: myInvestments_myInvestments
): InvestmentCardPropsWithId => {
  if (investment.asset.type === AssetType.BrokerageAccountSet) {
    return toInvestmentCardPropsFromSecurityInvestment(investment);
  }

  if (investment.asset.type === AssetType.RealEstate) {
    return toInvestmentCardPropsFromRealEstateInvestment(investment);
  }

  return toInvestmentCardPropsFromCompanyInvestment(investment);
};

const toInvestmentCardPropsFromSecurityInvestment = (
  investment: myInvestments_myInvestments
): InvestmentCardPropsWithId => {
  let name =
    investment.asset.brokerageAccountSet?.company.brokerageAccounts
      ?.map((account) => account.brokerName)
      .join(", ") ?? "";

  if (name === "") {
    name = "---";
  }

  let annualData = investment.asset.brokerageAccountSet?.companyAnnualData?.sort((a, b) =>
    new Date(a.dateFrom) < new Date(b.dateFrom) ? 1 : -1
  );
  let latestAnnualData = annualData?.find((_) => true);

  let nlvPreTax = latestAnnualData?.nlvPreTax ?? undefined;
  let nlvPostTax = latestAnnualData?.nlvPostTax ?? undefined;

  return {
    id: investment.id,
    share: investment.share,
    asset: investment.asset,
    type: AssetType.BrokerageAccountSet,
    state: InvestmentState.Private,
    image: undefined,
    name,
    lastChange: investment.updatedAt,
    ownership: [companyName(investment.investor.company)],
    nlvPreTax,
    nlvPostTax
  };
};

const toInvestmentCardPropsFromRealEstateInvestment = (
  investment: myInvestments_myInvestments
): InvestmentCardPropsWithId => ({
  id: investment.id,
  share: investment.share,
  asset: investment.asset,
  type: AssetType.RealEstate,
  state: investment.state,
  image: investment.asset?.realEstate?.image,
  name: investment.asset.realEstate?.label ?? "",
  lastChange: investment.state === "Private" ? investment.updatedAt : undefined,
  ownership: investment.asset?.isTokenized
    ? [
        investment.investor.type === "Company"
          ? companyName(investment.investor?.company)
          : personNameWithFallback(investment.investor?.person)
      ]
    : undefined,
  nlvPreTax: investment.asset?.realEstate?.propertyValue ?? undefined,
  nlvPostTax: undefined
});

const toInvestmentCardPropsFromCompanyInvestment = (
  investment: myInvestments_myInvestments
): InvestmentCardPropsWithId => ({
  id: investment.id,
  share: investment.share,
  asset: investment.asset,
  type: AssetType.Company,
  state: investment.state,
  image: undefined,
  name: companyName(investment.asset?.company),
  lastChange: investment.state === "Private" ? investment.updatedAt : undefined,
  ownership: investment.asset?.isTokenized
    ? [
        investment.investor.type === "Company"
          ? companyName(investment.investor?.company)
          : personNameWithFallback(investment.investor?.person)
      ]
    : undefined,
  nlvPreTax: investment.asset?.company?.companyValue ?? undefined,
  nlvPostTax: undefined
});
