import React from "react";
import { PropsWithChildren } from "react";
import { Breakpoint } from "uiLibrary/responsive/BreakpointDown/BreakpointDown";

interface BreakpointUpProps extends PropsWithChildren<{}> {
  breakpoint: Breakpoint;
}

export const BreakpointUp = ({ breakpoint, children }: BreakpointUpProps) => {
  return (
    <div data-testid="breakpoint-up" className={`breakpoint-up breakpoint-up--${breakpoint}`}>
      {children}
    </div>
  );
};
