import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "../../../OnboardingStepCard/OnboardingStepCard";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { PaymentMethodType } from "payments-query-types";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import {
  Header,
  TableHeader
} from "../../../UnifiedShortOrderForm/CheckoutStep/OrderCostSummary/OrderCostSummary";
import { formatCurrency } from "common/formatters";
import WepaOnboardingPricingModal from "../WepaOnboardingPricingModal/WepaOnboardingPricingModal";

export interface ReviewStepProps {
  status: OnboardingStepCardStatus;
  companyName: string;
  fiscalYear: string;
  paymentMethod?: PaymentMethodType | null;
  finalizeOrder: () => Promise<void>;
}

const ReviewStep = ({
  t,
  status,
  companyName,
  fiscalYear,
  paymentMethod,
  finalizeOrder
}: ReviewStepProps & WithTranslation) => {
  const className = "admin-service-onboarding-review-step";

  return (
    <OnboardingStepCard
      data-testid={"wepa-onboarding-review-step"}
      number={5}
      title={t("generic:wepa-direct.steps.review.review-order")}
      status={status}>
      <div className={className}>
        <Typography
          category={"Headline"}
          size={300}
          weight={"Heavy"}
          data-testid={"chosen-payment-method"}>
          {t("generic:your-payment-method")}:{" "}
          {t(
            `generic:payment-method-card.method-choice.${
              paymentMethod === PaymentMethodType.Sepa ? "sepa" : "manual"
            }.name`
          )}
        </Typography>

        <div className={`${className}__company-information`}>
          <div className={`${className}__company-information-field`}>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              data-testid={"company-name-label"}>
              {`${t(`generic:company-name`)}: `}
            </Typography>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Light"}
              data-testid={"company-name"}>
              {companyName}
            </Typography>
          </div>
          <div className={`${className}__company-information-field`}>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              data-testid={"fiscal-year-starting-date-label"}>
              {`${t(`generic:wepa-direct.steps.fiscal-year-starting-date.title`)}: `}
            </Typography>
            <Typography
              category={"Paragraph"}
              size={100}
              weight={"Light"}
              data-testid={"fiscal-year-starting-date"}>
              {fiscalYear}
            </Typography>
          </div>
        </div>

        <WepaOrderCostSummary />
      </div>

      <RideButton
        data-testid={"submit-order"}
        label={t("generic:wepa-direct.steps.review.button")}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        onClick={finalizeOrder}
      />

      <Typography
        data-testid="payment-disclaimer"
        category={"Paragraph"}
        size={200}
        weight={"Light"}
        className={`${className}__payment-disclaimer`}>
        {t("generic:payment-method-card.payment-date-info-wepa-direct")}
      </Typography>
    </OnboardingStepCard>
  );
};

const WepaOrderCostSummary = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  const [showPricingModal, setShowPricingModal] = useState(false);

  const tableHeader = [
    {
      name: "product-name",
      label: t("generic:short-order-form.checkout.order-summary.items-table.products")
    },
    {
      name: "payment-periodicity",
      label: t("generic:short-order-form.checkout.order-summary.items-table.payment-periodicity")
    },
    {
      name: "product-price",
      label: t("generic:short-order-form.checkout.order-summary.items-table.costs")
    }
  ];

  return (
    <>
      {showPricingModal && (
        <WepaOnboardingPricingModal onClose={() => setShowPricingModal(false)} />
      )}
      <div data-testid={"order-summary"} className={"short-order-form-order-summary"}>
        <Header t={t} />
        <div className={"short-order-form-order-summary__table"}>
          <div className={"short-order-form-order-summary__table-headers"}>
            {tableHeader.map((header) => (
              <TableHeader
                key={`order-summary-items-table-${header.name}`}
                name={header.name}
                label={header.label}
              />
            ))}
          </div>
          <span className={"short-order-form-order-summary__table-rows"}>
            <div
              className={"short-order-form-order-summary__table-row"}
              data-testid={`order-summary-items-row-company-setup`}>
              <Typography
                className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell`}
                data-testid={"order-summary-items-cell-product-name"}
                category="Paragraph"
                size={100}
                weight={"Heavy"}>
                {t("generic:company-setup.name")}
              </Typography>
              <Typography
                className={
                  "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
                }
                data-testid={"order-summary-items-cell-payment-periodicity"}
                category="Paragraph"
                size={200}
                weight={"Light"}>
                {t("generic:short-order-form.payment-periodicity.one-time")}
              </Typography>
              <Typography
                className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell`}
                data-testid={"order-summary-items-cell-product-price"}
                category="Paragraph"
                size={100}
                weight={"Heavy"}>
                {formatCurrency(500, { fixed: 0 })}
              </Typography>
            </div>
            <div
              className={"short-order-form-order-summary__table-row"}
              data-testid={`order-summary-items-row-previous-year-bookings`}>
              <Typography
                className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell`}
                data-testid={"order-summary-items-cell-product-name"}
                category="Paragraph"
                size={100}
                weight={"Heavy"}>
                {t("generic:fiscal-years.previous-year-bookings")}
              </Typography>
              <Typography
                className={
                  "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
                }
                data-testid={"order-summary-items-cell-payment-periodicity"}
                category="Paragraph"
                size={200}
                weight={"Light"}>
                {t("generic:short-order-form.payment-periodicity.one-time")}
              </Typography>
              <span onClick={() => setShowPricingModal(true)}>
                <Typography
                  className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell wepa-order-summary__price-cell-link`}
                  data-testid={"order-summary-items-cell-product-price"}
                  category="Paragraph"
                  size={100}
                  weight={"Heavy"}>
                  {t("generic:wepa-direct.steps.review.automated-booking-price")}
                </Typography>
              </span>
            </div>
            <div
              className={"short-order-form-order-summary__table-row"}
              data-testid={`order-summary-items-row-automated-booking`}>
              <Typography
                className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-name-cell`}
                data-testid={"order-summary-items-cell-product-name"}
                category="Paragraph"
                size={100}
                weight={"Heavy"}>
                {t("generic:wepa-service")}
              </Typography>
              <Typography
                className={
                  "short-order-form-order-summary__table-cell short-order-form-order-summary__payment-periodicity-cell"
                }
                data-testid={"order-summary-items-cell-payment-periodicity"}
                category="Paragraph"
                size={200}
                weight={"Light"}>
                {t("generic:short-order-form.payment-periodicity.quarterly")}
              </Typography>
              <span onClick={() => setShowPricingModal(true)}>
                <Typography
                  className={`short-order-form-order-summary__table-cell short-order-form-order-summary__product-price-cell wepa-order-summary__price-cell-link`}
                  data-testid={"order-summary-items-cell-product-price"}
                  category="Paragraph"
                  size={100}
                  weight={"Heavy"}>
                  {t("generic:wepa-direct.steps.review.automated-booking-price")}
                </Typography>
              </span>
            </div>
          </span>
        </div>
      </div>
    </>
  );
});

export default withTranslationReady(["generic"])(ReviewStep);
