import { Chapter } from "./Chapter";

export interface StepCreateData {
  key: string;
  completed?: boolean;
}

export class Step {
  key: string;
  chapter: Chapter;
  completed?: boolean;

  constructor(data: any, chapter: Chapter) {
    this.chapter = chapter;
    this.key = data.key;
    this.completed = data.completed;
  }

  complete() {
    this.completed = true;
  }

  reOpen() {
    this.completed = false;
  }

  serialize() {
    return { key: this.key, completed: this.completed };
  }

  getAllSubchapters(): Chapter[] {
    return this.chapter.subChapters;
  }

  getAllStepSubchapters(): Chapter[] {
    return this.chapter.subChapters.filter((subchapter) => subchapter.parentStepKey === this.key);
  }

  getNextStep(): Step | undefined {
    return this.chapter.getStepAfter(this);
  }

  remove() {
    this.chapter.removeStep(this);
  }

  insertAfter(stepData: StepCreateData) {
    const step = new Step(stepData, this.chapter);
    this.chapter.insertStepAfter(this, step);
  }

  get index() {
    return this.chapter.getIndexOfStep(this);
  }

  get disposition() {
    return this.index + 1;
  }
}
