import React from "react";
import { GetInvestmentById_getInvestmentById } from "../../../../lib/api/queries/graphql/GetInvestmentById";
import InternalRateOfReturn from "../InternalRateOfReturn/InternalRateOfReturn";
import AnnualProfit from "../AnnualProfit/AnnualProfit";
import CashMultiple from "../CashMultiple/CashMultiple";
import {
  toCashMultipleData,
  toTotalAnnualProfit,
  toTotalIRR,
  toYearlyAnnualProfitData,
  toYearlyIIRData
} from "../../../../lib/dataAdapters/brokerageAccounts/brokerageAccounts";

export interface BrokerageAccountsDetailsProps {
  investment: GetInvestmentById_getInvestmentById;
  className?: string;
}

export const BrokerageAccountsSections = ({
  className,
  investment
}: BrokerageAccountsDetailsProps) => {
  const totalIRRData = toTotalIRR(investment);
  const yearlyData = toYearlyIIRData(investment);

  const totalAnnualProfit = toTotalAnnualProfit(investment);
  const yearlyAnnualProfitData = toYearlyAnnualProfitData(investment);

  const cashMultiple = toCashMultipleData(investment);

  return (
    <div
      className={`${className ?? ""} brokerage-accounts-sections`}
      data-testid="brokerage-accounts-sections">
      <InternalRateOfReturn total={totalIRRData} yearlyData={yearlyData} />

      <CashMultiple value={cashMultiple} className={"mt-5"} />

      <AnnualProfit
        total={totalAnnualProfit}
        yearlyData={yearlyAnnualProfitData}
        className={"mt-5"}
      />
    </div>
  );
};
