import * as Yup from "yup";
import { EmailValidation } from "./EmailSchema";

export const CompanyCreationShareholdersSchema = () => {
  return Yup.object().shape({
    title: Yup.string(),
    firstName: Yup.string().required("generic:validation-required"),
    lastName: Yup.string().required("generic:validation-required"),
    email: EmailValidation,
    phoneNumber: Yup.string().required("generic:validation-required"),

    shareholders: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
          firstName: Yup.string().required("generic:validation-required"),
          lastName: Yup.string().required("generic:validation-required"),
          email: EmailValidation,
          phoneNumber: Yup.string().required("generic:validation-required")
        })
      )
      .max(4)
  });
};
