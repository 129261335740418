import React from "react";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import SourcesOfWealthSpread from "client/components/FxFlatOrder/SourcesOfWealthSpread/SourcesOfWealthSpread";
import { withTranslation, WithTranslation } from "react-i18next";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";

import { SourcesOfWealthSpreadStepSchema } from "lib/validation/SourcesOfWealthSpreadSchema";
import { at, sum } from "lodash";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { SourceOfWealthType } from "lib/models/client/LowTaxBroker/SourcesOfWealth";

const SourcesOfWealthSpreadStep = ({
  t,
  controls,
  currentStep,
  saveData,
  order
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  let initialValues = {};
  const sourcesOfWealth =
    Object.values(SourceOfWealthType).filter((source) => {
      if (order.extra.sourcesOfWealth?.[source]) {
        initialValues[source] = order.extra.sourcesOfWealth?.[source]?.spread ?? undefined;
      }

      return order.extra.sourcesOfWealth?.[source];
    }) ?? [];

  async function onSubmit(values) {
    let sourcesOfWealthData = {};

    sourcesOfWealth.forEach((source) => {
      sourcesOfWealthData[source] = {
        ...order.extra.sourcesOfWealth?.[source],
        spread: values[source]
      };
    });

    const total = sum(at(values, sourcesOfWealth));

    if (total !== 100) {
      return;
    }

    currentStep.complete();
    await saveData(
      {
        ...order.extra,
        sourcesOfWealth: sourcesOfWealthData,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  }

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: SourcesOfWealthSpreadStepSchema(t, sourcesOfWealth)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <SourcesOfWealthSpread formik={formik} sourcesOfWealth={sourcesOfWealth} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslation(["generic"])(SourcesOfWealthSpreadStep);
