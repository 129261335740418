import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { GmbH } from "lib/models/client/GmbH";

interface TaxAdvisorProfileProps extends WithTranslation {
  taxAdvisor?: GmbH;
  firstName?: string | null;
  lastName?: string | null;
}

export const TaxAdvisorProfile = ({
  taxAdvisor,
  t,
  firstName,
  lastName
}: TaxAdvisorProfileProps) => {
  return (
    <div
      className="tax-advisor-profile d-flex align-items-center"
      data-testid="tax-advisor-profile">
      <div
        className="tax-advisor-profile__image mr-25 mr-md-3 flex-sm-grow-1 flex-shrink-0"
        data-testid="tax-advisor-image"
      />

      <div>
        <div className="tax-advisor-profile__title" data-testid="tax-advisor-title">
          {t("client:tax-advisor.title")}
        </div>

        <div className="tax-advisor-profile__name" data-testid="tax-advisor-name">
          {taxAdvisor ? (
            <>{taxAdvisor.name + " " + t(`client:legalForm.${taxAdvisor.legalForm}`)}</>
          ) : (
            <>{`${firstName} ${lastName}`}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["client"])(TaxAdvisorProfile);
