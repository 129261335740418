import { FoundingProcessConfig_foundingProcessConfig } from "../../api/queries/graphql/FoundingProcessConfig";

export class FoundingProcessConfigService {
  static instance: FoundingProcessConfigService;

  readonly foundingProcessConfig: FoundingProcessConfig_foundingProcessConfig;

  constructor(foundingProcessConfig: FoundingProcessConfig_foundingProcessConfig) {
    this.foundingProcessConfig = foundingProcessConfig;
  }

  get specialRefs() {
    return this.foundingProcessConfig.config.specialRefs;
  }

  // TODO Please reimplement this part (Copied logic from backend)
  getFlatTasks(): any[] {
    const groupToTasks = (group: any): any[] => group.steps.flatMap((step: any) => step.tasks);

    return (
      this.foundingProcessConfig.config.stepGroups?.flatMap((group) => groupToTasks(group)) ?? []
    );
  }
  getFlatSteps(): any[] {
    return this.foundingProcessConfig.config.stepGroups?.flatMap((group) => group.steps) ?? [];
  }
  getStepGroups(): any[] {
    return this.foundingProcessConfig.config.stepGroups;
  }
}
