import React from "react";

interface PrivateIconProps {
  size?: number;
  cx?: number;
  cy?: number;
  r?: number;
  fill?: string;
  dataTestId?: string;
}

export const PrivateIcon = ({
  size = 80,
  cx = 40,
  cy = 40,
  r = 40,
  fill = "#A3A3A3",
  dataTestId = "private-icon"
}: PrivateIconProps) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={dataTestId}>
    <circle cx={cx} cy={cy} r={r} fill={fill} />
    <path
      d="M54.4 54.4v-3.2c0-1.697-.759-3.325-2.11-4.525-1.35-1.2-3.18-1.875-5.09-1.875H32.8c-1.91 0-3.741.674-5.092 1.875-1.35 1.2-2.108 2.828-2.108 4.525v3.2M40 38.4a8 8 0 100-16 8 8 0 000 16z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
