import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ReviewDocumentEntry } from "../ReviewDocuments.partials";

export interface PersonalDocumentsEntry {
  personName: string;
  idOrPassportUploaded: boolean;
  proofOfAddressUploaded: boolean;
}

export const ReviewPersonalDocuments = withTranslationReady(["generic"])(
  ({
    t,
    personName,
    idOrPassportUploaded,
    proofOfAddressUploaded
  }: PersonalDocumentsEntry & WithTranslation) => {
    return (
      <div className="review-documents" data-testid="review-personal-documents">
        <div className="review-documents__review-personal-documents__person-name">{personName}</div>
        <div className="review-documents__subsection-title">
          {t("generic:ride-broker-onboarding.review.documents.personal-documents")}
        </div>
        <div className="review-documents__list">
          <ReviewDocumentEntry
            name={t(
              "generic:ride-broker-onboarding.documents.personal-documents-list.id-or-passport"
            )}
            uploaded={idOrPassportUploaded}
          />
          <ReviewDocumentEntry
            name={t(
              "generic:ride-broker-onboarding.documents.personal-documents-list.proof-of-address"
            )}
            uploaded={proofOfAddressUploaded}
          />
        </div>
      </div>
    );
  }
);
