import React from "react";

export const DeleteIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 6.00024H16V5.00024C16 3.34339 14.6569 2.00024 13 2.00024H11C9.34315 2.00024 8 3.34339 8 5.00024V6.00024H4C3.44772 6.00024 3 6.44796 3 7.00024C3 7.55253 3.44772 8.00024 4 8.00024H5V19.0002C5 20.6571 6.34315 22.0002 8 22.0002H16C17.6569 22.0002 19 20.6571 19 19.0002V8.00024H20C20.5523 8.00024 21 7.55253 21 7.00024C21 6.44796 20.5523 6.00024 20 6.00024ZM10 5.00049C10 4.44821 10.4477 4.00049 11 4.00049H13C13.5523 4.00049 14 4.44821 14 5.00049V6.00049H10V5.00049ZM17 19.0002C17 19.5525 16.5523 20.0002 16 20.0002H8C7.44772 20.0002 7 19.5525 7 19.0002V8.00024H17V19.0002Z"
      fill="currentColor"
    />
  </svg>
);
