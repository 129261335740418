import React from "react";
import CompanyInformationStep from "./CompanyInformationStep";
import { UnifiedShortOrderFormStepProps } from "../UnifiedShortOrderForm.partials";
import { useMutation } from "@apollo/react-hooks";
import {
  CreateCompanyLeadForUnifiedShortOrderForm,
  CreateCompanyLeadForUnifiedShortOrderFormVariables
} from "../../../../lib/api/mutations/graphql/CreateCompanyLeadForUnifiedShortOrderForm";
import { CREATE_COMPANY_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM } from "../../../../lib/api/mutations/CREATE_COMPANY_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";

export interface CompanyInformationStepContainerProps extends UnifiedShortOrderFormStepProps {}

export const CompanyInformationStepContainer = ({
  initialValues,
  persistData,
  goToNextStep,
  goToPreviousStep
}: CompanyInformationStepContainerProps) => {
  const [createCompanyLeadMutation, createCompanyLeadMutationRequest] = useMutation<
    CreateCompanyLeadForUnifiedShortOrderForm,
    CreateCompanyLeadForUnifiedShortOrderFormVariables
  >(CREATE_COMPANY_LEAD_FOR_UNIFIED_SHORT_ORDER_FORM);

  const createCompanyLeadHandler = async ({
    companyData,
    companyAddress,
    hasCorporateShareholders,
    contactEmail
  }: CreateCompanyLeadForUnifiedShortOrderFormVariables) => {
    await createCompanyLeadMutation({
      variables: { companyData, companyAddress, hasCorporateShareholders, contactEmail }
    });
  };

  return (
    <>
      <RequestFeedbackHandler requests={[createCompanyLeadMutationRequest]} />
      <CompanyInformationStep
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        persistData={persistData}
        initialValues={initialValues}
        createCompanyLeadHandler={createCompanyLeadHandler}
      />
    </>
  );
};
