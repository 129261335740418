import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { StepComponentProps, StepHeading } from "../../ChaptersOrderLayout.partials";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { requiredString } from "lib/validation/requiredString";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import {
  AlertType,
  BarVariant,
  RideAlertBar
} from "../../../../../uiLibrary/components/RideAlertBar/RideAlertBar";

const CurrentTaxAdvisorDetails = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps & WithTranslation) => {
  const moduleName = "current-tax-advisor-details";

  const formik = useRideFormik({
    initialValues: {
      fullName: order.extra?.taxAdvisor?.fullName,
      email: order.extra?.taxAdvisor?.email,
      phoneNumber: order.extra?.taxAdvisor?.phoneNumber
    },
    onSubmit: async (values) => {
      currentStep.complete();

      await saveData({
        ...currentStep.chapter.order.serialize(),
        taxAdvisor:
          values.fullName && values.email && values.phoneNumber
            ? {
                fullName: values.fullName,
                email: values.email,
                phoneNumber: values.phoneNumber
              }
            : null
      });
    },
    validate: (values) => {
      const allEmpty = !values.fullName && !values.email && !values.phoneNumber;
      const allFilled = !!values.fullName && !!values.email && !!values.phoneNumber;

      if (!allEmpty && !allFilled) {
        return {
          ...(!values.fullName && { fullName: t("generic:validation-required") }),
          ...(!values.email && { email: t("generic:validation-required") }),
          ...(!values.phoneNumber && { phoneNumber: t("generic:validation-required") })
        };
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div data-testid={moduleName}>
          <StepHeading
            dataTestId={`${moduleName}-title`}
            text={t("generic:current-tax-advisor-details.title")}
          />
          <RideAlertBar
            type={AlertType.INFO}
            barVariant={BarVariant.Rounded}
            message={t("generic:leave-all-blank-if-not-applicable")}
            className={`${moduleName}__alert`}
          />
          <div className={`${moduleName}__content`}>
            <RideTextInput
              name="fullName"
              label={t("generic:tax-firm-name")}
              placeholder={t("generic:tax-firm-name")}
            />
            <RideTextInput
              name="email"
              label={t("generic:email")}
              placeholder={t("generic:email")}
            />
            <RideInput
              formik={formik}
              name="phoneNumber"
              label={t("generic:phone-number")}
              type={"tel"}
              autoComplete="on"
              className={`${moduleName}__phone`}
            />
          </div>
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export const PreviousTaxAdvisorSchema = (t) => {
  return Yup.object({
    fullName: requiredString(t),
    email: requiredString(t),
    phoneNumber: requiredString(t)
  });
};

export default withTranslationReady(["generic"])(CurrentTaxAdvisorDetails);
