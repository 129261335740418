import i18next from "i18next";
import { ValueFormatter } from "./ValueFormatter";

export class TimeFormatter extends ValueFormatter {
  format(value, ...args) {
    if (value) {
      return new Date(value).toLocaleTimeString(i18next.language);
    }
    return super.format(value, ...args);
  }
}
