import React from "react";
import { useParams } from "react-router";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { AdminBreadcrumbContainer } from "../../components/AdminBreadcrumb/AdminBreadcrumbContainer";
import { WithTranslation } from "react-i18next";
import { AdminFiscalYearContainer } from "../../components/AdminFiscalYear/AdminFiscalYearContainer";

const AdminFiscalYearScene = ({ t }: WithTranslation) => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <AdminBreadcrumbContainer
        companyId={id}
        title={t("nominee-dashboard:company.fiscal-years")}
      />
      <AdminFiscalYearContainer companyId={id} />
    </>
  );
};

export default withTranslationReady(["nominee-dashboard"])(AdminFiscalYearScene);
