import { countries, getEmojiFlag } from "countries-list";
import countries2to3 from "countries-list/dist/countries2to3.json";
import { defaultCountryCode } from "../../../common/defaultCountry";

const countriesUsedAsNativeMoreFrequently = ["DE"];

const isCountryNameUsedAsNativeMoreFrequently = (normalizedCode: string) =>
  countriesUsedAsNativeMoreFrequently.includes(normalizedCode);

export const getCountryNameByCode = (code: string | undefined | null) => {
  const normalizedCode = code?.toUpperCase() ?? "";
  if (isCountryNameUsedAsNativeMoreFrequently(normalizedCode)) {
    return countries[normalizedCode]?.native;
  }
  return countries[normalizedCode]?.name ?? "";
};

const getCountryLabelWithFlag = (code: string) =>
  getEmojiFlag(code) + "\u00a0\u00a0" + getCountryNameByCode(code);

export const countryListWithFlag = Object.keys(countries).map((code) => ({
  value: code,
  label: getCountryLabelWithFlag(code)
}));

export const defaultCountryOptions = {
  label: getCountryLabelWithFlag(defaultCountryCode),
  value: defaultCountryCode
};

export const convertCountryCodeFromISO2ToISO3 = (code: string | undefined | null) => {
  return countries2to3[code?.toUpperCase() ?? ""];
};
