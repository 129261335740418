import gql from "graphql-tag";

export const GET_INVESTMENT_BY_ID = gql`
  query GetInvestmentById($id: ID!) {
    getInvestmentById(id: $id) {
      id
      investor {
        id
        type
        company {
          id
          name
          legalForm
        }
        person {
          id
          firstName
          lastName
        }
      }
      asset {
        id
        type
        company {
          id
          name
          legalForm
          type
          companyValue
        }
        brokerageAccountSet {
          id
          companyAnnualData {
            id
            createdAt
            updatedAt
            customerFinal
            dateCalculated
            dateFrom
            dateTo
            partnerFinal
            closed
            predata
            errors
            xirrPreTax
            xirrPostTax
            nlvPreTax
            nlvPreTaxCurrency
            nlvPostTax
            nlvPostTaxCurrency
            realizedStockWin
            realizedStockWinCurrency
            realizedStockLoss
            realizedStockLossCurrency
            realizedOther
            realizedOtherCurrency
            realizedTotal
            realizedTotalCurrency
            payoutsTotal
            payoutsTotalCurrency
            depositsTotal
            depositsTotalCurrency
            TaxPercentForecast
            TaxMoneyForecast
            TaxMoneyForecastCurrency
            TaxPercentForecastLiquidated
            TaxMoneyForecastLiquidated
            TaxMoneyForecastLiquidatedCurrency
          }
          company {
            name
            legalForm
            brokerageAccounts {
              brokerName
            }
          }
        }
        realEstate {
          id
          label
          image
          propertyValue
          owner {
            id
            memberships {
              id
              company {
                id
                name
                legalForm
                capitalContribution
                tokenizedShares
              }
            }
          }
        }
        isTokenized
      }
      share
      updatedAt
      state
    }
  }
`;
