import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Banner } from "../../generic/Banner";
import { WepaServiceOrderFlowContainer } from "./WepaServiceOrderFlowContainer";
import { RouteComponentProps } from "react-router";
import WepaThirdPartyTaxAdvisorContainer from "./WepaThirdPartyTaxAdvisorContainer";
import GuestHeaderDeprecated from "../../../guest/components/GuestHeaderDeprecated/GuestHeaderDeprecated";
import RideFooter from "../../../sharedComponents/RideFooter/RideFooter";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface WepaServiceOrderPagePropsMatchProps {
  taxAdvisorCode?: string;
}

interface WepaServiceOrderPageProps
  extends WithTranslation,
    RouteComponentProps<WepaServiceOrderPagePropsMatchProps> {}

const WepaServiceOrderPageComponent = ({ t, match }: WepaServiceOrderPageProps) => {
  const taxAdvisorCode = match.params.taxAdvisorCode;
  const bannerHeading = t("wepa-service:banner-heading");

  return (
    <div className="trader-gmbh">
      <PageMetaData title={t("wepa-service:page-title")} />
      <GuestHeaderDeprecated />
      <Banner bannerHeading={bannerHeading} bannerText={""} size="small" />
      {taxAdvisorCode && <WepaThirdPartyTaxAdvisorContainer taxAdvisorCode={taxAdvisorCode} />}
      <WepaServiceOrderFlowContainer t={t} taxAdvisorCode={taxAdvisorCode} />
      <RideFooter />
    </div>
  );
};

export default withTranslation(["generic", "admin-service", "wepa-service", "trader-gmbh"])(
  WepaServiceOrderPageComponent
);
