import React from "react";
import { FeatureFlags, TaskType } from "global-query-types";
import IbanViewTask from "../IbanViewTask/IbanViewTask";
import OpenBankAccountOwnTask from "../OpenBankAccountOwnTask/OpenBankAccountOwnTask";
import { FeatureFlag } from "../../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import OpenBankAccountPentaTask from "../OpenBankAccountPentaTask/OpenBankAccountPentaTask";
import { DocuSignTaskContainer } from "../DocuSignTask/DocuSignTaskContainer";
import BankSelectionTask from "../BankSelectionTask/BankSelectionTask";
import NotaryAppointmentTask from "../NotaryAppointmentTask/NotaryAppointmentTask";
import {
  GenericTaskProps,
  OnTaskStateChange
} from "../../../../../components/client/company/components/tasks/GenericTask";
import ChooseFiscalYearTask from "../ChooseFiscalYearTask/ChooseFiscalYearTask";
import UploadAccountStatementTask from "../UploadAccountStatementTask/UploadAccountStatementTask";
import TaskContainer from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainer";
import OpenBankAccountFyrstTask from "../OpenBankAccountFyrstTask/OpenBankAccountFyrstTask";
import { DisabledFeatureFlag } from "../../../../../sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import ProvideIBANTask from "../ProvideIBANTask/ProvideIBANTask";
import OpenBankAccountSwanTask from "../OpenBankAccountSwanTask/OpenBankAccountSwanTask";
import { ChoosePaymentMethodTaskContainer } from "../ChoosePaymentMethodTask/ChoosePaymentMethodTaskContainer";
import OpenBankAccountFinomTask from "../OpenBankAccountFinomTask/OpenBankAccountFinomTask";

const CustomTask = ({ task, onChange, onCloseModal }: GenericTaskProps) => {
  const handleStateChange: OnTaskStateChange = async (state, options, nextTaskToRender) => {
    onCloseModal();
    await onChange(state, options, nextTaskToRender);
  };

  if (task.type === TaskType.IBAN_VIEW) {
    return <IbanViewTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.DOCU_SIGN) {
    return <DocuSignTaskContainer task={task} onChange={handleStateChange} />;
  }

  if (task.ref === "appointmentNotaryRequest") {
    return <NotaryAppointmentTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.CHOOSE_PAYMENT_METHOD) {
    return <ChoosePaymentMethodTaskContainer task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.BANK_ACCOUNT_PROVIDER) {
    return (
      <>
        <FeatureFlag name={FeatureFlags.SwanBankSelection}>
          <BankSelectionTask shouldEnableSwan={true} task={task} onChange={handleStateChange} />
        </FeatureFlag>
        <DisabledFeatureFlag name={FeatureFlags.SwanBankSelection}>
          <BankSelectionTask shouldEnableSwan={false} task={task} onChange={handleStateChange} />
        </DisabledFeatureFlag>
      </>
    );
  }

  if (task.type === TaskType.IBAN) {
    return <ProvideIBANTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.BANK_ACCOUNT_OPEN_SWAN) {
    return <OpenBankAccountSwanTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.BANK_ACCOUNT_OPEN_PENTA) {
    return <OpenBankAccountPentaTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.BANK_ACCOUNT_OPEN_OWN) {
    return <OpenBankAccountOwnTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.BANK_ACCOUNT_OPEN_FYRST) {
    return <OpenBankAccountFyrstTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.BANK_ACCOUNT_OPEN_FINOM) {
    return <OpenBankAccountFinomTask task={task} onChange={handleStateChange} />;
  }

  if (task.type === TaskType.UPLOAD_DOCUMENT) {
    return (
      <>
        <FeatureFlag name={FeatureFlags.UploadProofOfSharedCapital}>
          <UploadAccountStatementTask task={task} onChange={onChange} onCloseModal={onCloseModal} />
        </FeatureFlag>
        <DisabledFeatureFlag name={FeatureFlags.UploadProofOfSharedCapital}>
          <TaskContainer
            testid="generic"
            translationKey="generic"
            task={task}
            onChange={handleStateChange}
          />
        </DisabledFeatureFlag>
      </>
    );
  }

  if (task.type === TaskType.CHOOSE_FISCAL_YEAR) {
    return <ChooseFiscalYearTask task={task} onChange={handleStateChange} />;
  }

  return null;
};

export default CustomTask;
