import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import AddSPVCompanyForm, { IValues } from "./AddSPVCompanyForm";
import { CreateSPVCompany, CreateSPVCompanyVariables } from "./graphql/CreateSPVCompany";
import { SPV_COMPANIES_QUERY } from "./SPVCompanies";
import logger from "../../../common/Logger";
import { AssertionError } from "assert";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import SuccessAlert from "../../generic/SuccessAlert";
import LoadingAlert from "../../generic/LoadingAlert";
import { REAL_ESTATE_OWNERS_QUERY } from "../../Investments/Ownership/Queries";
import { AddIcon } from "../../../sharedComponents/icons/add";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export const CREATE_SPV_COMPANY = gql`
  mutation CreateSPVCompany(
    $name: String!
    $projectName: String
    $legalForm: LegalForm!
    $capitalContribution: Float!
    $street: String!
    $city: String!
    $postalCode: String!
    $type: CompanyType
    $tradeTaxFree: Boolean
    $tokenized: Boolean
    $status: PublishType
    $tokenizedShares: Int
    $registerCourt: String
    $registration: String
    $boxLink: String
    $taxNumber: String
    $vatNumber: String
  ) {
    createCompany(
      name: $name
      projectName: $projectName
      legalForm: $legalForm
      capitalContribution: $capitalContribution
      street: $street
      city: $city
      postalCode: $postalCode
      type: $type
      tradeTaxFree: $tradeTaxFree
      tokenized: $tokenized
      status: $status
      tokenizedShares: $tokenizedShares
      registration: $registration
      boxLink: $boxLink
      registerCourt: $registerCourt
      taxNumber: $taxNumber
      vatNumber: $vatNumber
    ) {
      id
      name
      projectName
      legalForm
      registerCourt
      registration
      capitalContribution
      businessAddress {
        street
        city
        postalCode
      }
      tradeTaxFree
      tokenized
      tokenizedShares
      status
      boxLink
      taxNumber
      vatNumber
    }
  }
`;

export const createSubmitHandler = (createSPVCompany, handleClose, setSuccess) => {
  return async (values: IValues) => {
    try {
      logger.debug("User creates new company");
      const { data } = await createSPVCompany({
        variables: {
          name: values.name,
          projectName: values.projectName,
          legalForm: values.legalForm,
          capitalContribution: values.capitalContribution,
          street: values.street,
          city: values.city,
          postalCode: values.postalCode,
          type: values.type,
          tradeTaxFree: values.tradeTaxFree === "YES",
          tokenized: values.tokenized === "YES",
          status: values.status,
          tokenizedShares: values.tokenizedShares,
          registerCourt: values.registerCourt,
          registration: values.registration,
          boxLink: values.boxLink,
          taxNumber: values.taxNumber,
          vatNumber: values.vatNumber
        }
      });
      if (data) {
        setSuccess(true);
        setTimeout(handleClose, 600);
      }
    } catch (error) {
      logger.error(error);
      throw new AssertionError({ message: "Creating SPV Company not successful" });
    }
  };
};

interface AddSPVCompanyProps extends WithTranslation {}

export const AddSPVCompany: React.FunctionComponent<AddSPVCompanyProps> = (props) => {
  const { t } = props;
  const [showForm, setShowForm] = useState(false);
  const handleClose = () => {
    setShowForm(false);
    setSuccess(false);
  };
  const handleShow = () => setShowForm(true);
  const [success, setSuccess] = useState(false);

  const [createSPVCompany, { error: errorCreate, loading: loadingCreate }] = useMutation<
    CreateSPVCompany,
    CreateSPVCompanyVariables
  >(CREATE_SPV_COMPANY, {
    refetchQueries: [{ query: SPV_COMPANIES_QUERY }, { query: REAL_ESTATE_OWNERS_QUERY }]
  });

  const errorReason = errorCreate && errorCreate.message;

  const handleSubmit = createSubmitHandler(createSPVCompany, handleClose, setSuccess);

  const modalFormWrapper = (
    <Modal show={showForm} size="xl" onHide={handleClose}>
      <ErrorAlertLegacy t={t} error={errorReason} />
      <LoadingAlert t={t} loading={loadingCreate} message={t("generic:loading-message")} />
      <SuccessAlert t={t} success={success} message={t("generic:success_heading")} />
      <Modal.Header closeButton>
        <Modal.Title>{t("spv-company:add-spv-company")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddSPVCompanyForm submitForm={handleSubmit} cancelForm={handleClose} />
      </Modal.Body>
    </Modal>
  );
  return (
    <>
      <RideButtonDeprecated
        hasIcon={true}
        size={ButtonSize.Medium}
        variant={ButtonVariant.Secondary}
        onClick={handleShow}>
        <AddIcon />
        {t("spv-company:add-spv")}
      </RideButtonDeprecated>
      {showForm ? modalFormWrapper : null}
    </>
  );
};

export default withTranslation(["spv-company", "generic"])(AddSPVCompany);
