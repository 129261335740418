import { Form, FormikProvider, useFormik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { Button, InputGroup } from "react-bootstrap";
import { DiscountCodeField } from "../../generic/commonFields";
import { formatCurrency } from "../../../common/formatters";

interface ApplyDiscountCodeProps {
  t: TFunction;
  applyDiscountCode: (discountCode: string) => Promise<void>;
  discountCode?: {
    id: string;
    code: string;
    referrerValue: number | null;
    referralValue: number | null;
  } | null;
}

export const ApplyDiscountCodeComponent = ({
  t,
  applyDiscountCode,
  discountCode
}: ApplyDiscountCodeProps) => {
  const handleDiscountCode = async ({ discountCode }: { discountCode: string }) => {
    if (discountCode) {
      await applyDiscountCode(discountCode);
    }
  };

  const formik = useFormik({
    initialValues: { discountCode: "" },
    onSubmit: handleDiscountCode
  });

  if (discountCode) {
    return (
      <h6 data-testid="discount-code-applied-successfully" className="mt-3">
        {" "}
        {t("trader-gmbh:discount-code.applied-successfully", {
          referralValue: formatCurrency(discountCode?.referralValue)
        })}
      </h6>
    );
  }

  return (
    <FormikProvider value={formik} data-testid="discount-code-form">
      <Form>
        <hr />
        <InputGroup className="mt-4">
          <DiscountCodeField t={t} formik={formik} />
          <Button
            className="apply-referral-btn"
            variant="outline-light"
            data-testid={`submit-button`}
            type="submit">
            {t("trader-gmbh:discount-code.apply")}
          </Button>
        </InputGroup>
      </Form>
    </FormikProvider>
  );
};
