import React from "react";
import { PageLayout } from "sharedComponents/templates/PageLayout/PageLayout";
import FinanceAdsCodesTableContainer from "../../components/FinanceAdsCodesTable/FinanceAdsCodesTableContainer";

const FinanceAdsCodesScene = () => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle="FinanceAds Codes"
      mainContent={<FinanceAdsCodesTableContainer />}
    />
  );
};

export default FinanceAdsCodesScene;
