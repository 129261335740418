import { Col, Row } from "react-bootstrap";
import React from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { InvestmentsTableContainer } from "../../components/InvestmentsTable/InvestmentsTableContainer";

const AdminInvestmentsScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:client-investments")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <InvestmentsTableContainer />
        </Col>
      </Row>
    </>
  );
};

export default withTranslationReady("header")(AdminInvestmentsScene);
