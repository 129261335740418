import { selectFieldFor, textFieldFor } from "../../generic/commonFields";

export const TokenizedSPVSelectField = ({ t, formik, options }) =>
  selectFieldFor(t, formik, "tokenizedSPV", "nominee-dashboard:token", options);

export const SourceWalletSelectField = ({ t, formik, options }) =>
  selectFieldFor(t, formik, "walletProviderId", "nominee-dashboard:source-wallet", options);

export const TargetWalletField = ({ t, formik }) =>
  textFieldFor(t, formik, "targetWallet", "nominee-dashboard:target-wallet");

export const AllowedAmountField = ({ t, formik }) =>
  textFieldFor(t, formik, "allowedAmount", "nominee-dashboard:allowed-amount", {
    type: "number"
  });
