import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { TradingTransactionHistoryLayoutContainer } from "admin/components/TradingTransactionHistoryLayout/TradingTransactionHistoryLayoutContainer";

const TradingTransactionHistoryScene = ({ t }: WithTranslation) => {
  return <TradingTransactionHistoryLayoutContainer />;
};

export default withTranslationReady(["generic"])(TradingTransactionHistoryScene);
