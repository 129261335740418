export interface UnifiedShortOrderFormValues
  extends PersonalDataStepForm,
    CompanyInformationStepForm,
    ProductSelectionStepForm,
    ShortOrderFormCheckoutStepForm {}

export interface CompanyInformationStepForm {
  customerAlreadyHasCompany?: string;
  companyName?: string;
  companyLegalForm?: string;
  registerCourt?: string;
  registration?: string;
  companyAddress?: {
    country: string;
    city: string;
    postalCode: string;
    streetLine1: string;
    streetLine2: string;
  };
  hasCorporateShareholders?: string;
  companyDoesNotFallIntoCategories?: string;
  northDataUniqueKey?: string;
  companyOwnsRealEstate?: string;
  tradesCrypto?: string;
  truthfulInformationProvided?: boolean;
  companyActivelyOperating?: string;
  companyKeepsPhysicalInventory?: string;
  companyIssuesMoreThan50OutgoingInvoicesYearly?: string;
  hasFiveOrMoreEmployees?: string;
}

export interface ShortOrderFormCheckoutStepForm {
  termsAndConditionsCheckbox?: boolean;
  discountCode?: string;
  discountValue?: number;
}

export interface PersonalDataStepForm {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  privacyPolicyCheckbox?: boolean;
  allowsContact?: boolean;
  userReportedSource?: string;
}

export interface ProductSelectionStepForm {
  companyFoundingService?: boolean;
  adminService?: AdminServiceTier;
  lowTaxBroker?: boolean;
  startingFiscalYear?: number;
}

export enum AdminServiceTier {
  Lite = "lite",
  Standard = "standard",
  Pro = "pro"
}
