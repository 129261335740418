import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { FormikProps } from "formik";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import RideAddressSearch from "../../../../RideAddressSearch/RideAddressSearch";
import ShareholderDetailsSectionTitle from "../../../sharedComponents/ShareholderDetailsSectionTitle/ShareholderDetailsSectionTitle";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { StateConverter } from "lib/converters/StateConverter";

export interface GenericAddressProps extends WithTranslation {
  prefix: string;
  formik: FormikProps<any>;
  title: string;
  displaySectionTitle?: boolean;
  useInputForCountry?: boolean;
  disableFields?: string[];
  includeBuildingNumber?: boolean;
}

const GenericAddress = ({
  prefix,
  formik,
  t,
  title,
  displaySectionTitle = true,
  useInputForCountry = false,
  disableFields,
  includeBuildingNumber = true
}: GenericAddressProps) => {
  const stateConverter = new StateConverter();

  let isComplete = !!(
    formik.values["country"] &&
    formik.values["stateCode"] &&
    (includeBuildingNumber
      ? formik.values["streetName"] && formik.values["buildingNumber"]
      : formik.values["streetLine1"]) &&
    formik.values["city"] &&
    formik.values["postalCode"]
  );

  const module = "ride-form-v2";

  return (
    <div data-testid="residential-address" className={module}>
      {displaySectionTitle && (
        <ShareholderDetailsSectionTitle prefix={prefix} text={title} isCompleted={isComplete} />
      )}
      <RideAddressSearch
        className={`${module}__field`}
        onSelect={(address) => {
          formik.setFieldValue("streetLine2", "");
          formik.setFieldValue("city", address.City);
          formik.setFieldValue("postalCode", address.PostalCode);
          formik.setFieldValue("country", address.CountryIso2);
          formik.setFieldValue("stateCode", stateConverter.getStateCode(address.ProvinceName));

          if (includeBuildingNumber) {
            includeBuildingNumber && formik.setFieldValue("streetName", address.Street);
            includeBuildingNumber && formik.setFieldValue("buildingNumber", address.BuildingNumber);
          } else {
            formik.setFieldValue("streetLine1", `${address.Street} ${address.BuildingNumber}`);
          }
        }}
      />
      {useInputForCountry ? (
        <RideTextInput
          className={`${module}__field`}
          name="country"
          label={t("generic:country")}
          placeholder={t("generic:country")}
          disabled={disableFields?.includes("country")}
        />
      ) : (
        <CountrySelect
          className={`${module}__field`}
          name="country"
          label={t("generic:country")}
          disabled={disableFields?.includes("country")}
        />
      )}
      <>
        {includeBuildingNumber ? (
          <>
            <RideTextInput
              className={`${module}__field`}
              name="streetName"
              label={t("generic:ride-broker-onboarding:address:streetName")}
              placeholder={t("generic:ride-broker-onboarding:address:streetName-placeholder")}
            />
            <RideTextInput
              className={`${module}__field`}
              name="buildingNumber"
              label={t("generic:ride-broker-onboarding:address:buildingNumber")}
              placeholder={t("generic:ride-broker-onboarding:address:buildingNumber-placeholder")}
            />
          </>
        ) : (
          <RideTextInput
            className={`${module}__field`}
            name="streetLine1"
            label={t("generic:ride-broker-onboarding:address:streetNameAndNumber")}
            placeholder={t(
              "generic:ride-broker-onboarding:address:streetNameAndNumber-placeholder"
            )}
          />
        )}
      </>
      <RideTextInput
        className={`${module}__field`}
        name="streetLine2"
        label={`${t("generic:street-line-2")} ${t("generic:optional")}`}
        placeholder={t("generic:street-line-2-placeholder")}
      />
      <RideTextInput
        className={`${module}__field`}
        name="postalCode"
        label={t("generic:postal-code")}
        placeholder={t("generic:postal-code-placeholder")}
      />
      <RideDropdownSelect
        options={stateConverter.getStateOptions(formik.values["country"])}
        className={`${module}__field`}
        name="stateCode"
        label={t("generic:ride-broker-onboarding:address:state")}
        placeholder={t("generic:ride-broker-onboarding:address:state-placeholder")}
        isSearchable={true}
      />
      <RideTextInput
        className={`${module}__field`}
        name="city"
        label={t("generic:city")}
        placeholder={t("generic:city-placeholder")}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(GenericAddress);
