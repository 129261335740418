import { RideStatus } from "../lib/types/types";

export const calculateStatus = ({
  completed,
  previousStepStatus
}: {
  completed: boolean;
  previousStepStatus?: RideStatus;
}) => {
  if (previousStepStatus && previousStepStatus !== RideStatus.COMPLETED) {
    return RideStatus.LOCKED;
  }
  return completed ? RideStatus.COMPLETED : RideStatus.PENDING;
};
