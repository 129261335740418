import React from "react";
import { RemoteStorageNode } from "../../lib/dataAdapters/toRemoteStorageNode";
import { sortRemoteStorageNodeByFilesFirst } from "../../lib/dataAdapters/sortRemoteStorageNodeByFilesFirst";
import FileSystemNode, { FileSystemNodeProps } from "../FileSystemNode/FileSystemNode";

export interface NodeTreeProps extends Omit<FileSystemNodeProps, "node"> {
  nodes: RemoteStorageNode[];
}

const NodeTree = ({
  nodes,
  entityType,
  entityId,
  onDelete,
  refetch,
  readOnly,
  level
}: NodeTreeProps) => {
  return (
    <>
      {sortRemoteStorageNodeByFilesFirst(nodes).map((node, index) => (
        <FileSystemNode
          entityType={entityType}
          entityId={entityId}
          key={index}
          onDelete={onDelete}
          node={node}
          refetch={refetch}
          readOnly={readOnly}
          level={level}
        />
      ))}
    </>
  );
};

export default NodeTree;
