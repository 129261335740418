import React, { PropsWithChildren } from "react";
import { Form, FormikConfig, FormikProps, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { doNothing } from "common/stubs";

type FormikConfigProps = Omit<FormikConfig<any>, "initialValues" | "onSubmit">;

interface FormikMockProps extends FormikConfigProps {
  children: PropsWithChildren<{}>["children"] | React.FC<{ formik: FormikProps<any> }>;
  initialValues?: FormikConfig<any>["initialValues"];
  onSubmit?: (values: any) => void;
  withSubmitButton?: boolean;
}

export const FormikMock = ({
  initialValues,
  onSubmit,
  children: Children,
  withSubmitButton = false,
  ...props
}: FormikMockProps) => {
  const formik = useRideFormik({
    initialValues: initialValues ?? {},
    onSubmit: onSubmit ? (values) => onSubmit(values) : doNothing,
    ...props
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        {typeof Children === "function" ? <Children formik={formik} /> : Children}
        {withSubmitButton && (
          <button type="submit" data-testid="formik-mock-submit">
            Submit
          </button>
        )}
      </Form>
    </FormikProvider>
  );
};
