import React from "react";

import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import BankAccountDetails from "./BankAccountDetails";
import { GetBankAccountDetailsById } from "../../../../lib/api/queries/GetBankAccountDetailsById";
import { useParams } from "react-router";

const BankAccountDetailsContainer = () => {
  const { companyId, iban, bankAccountId } =
    useParams<{ companyId: string; iban: string; bankAccountId: string }>();
  const bankAccountDetailsRequest = GetBankAccountDetailsById(companyId, iban, bankAccountId);
  const bankAccountDetails = bankAccountDetailsRequest.data?.getBankAccountDetailsById ?? undefined;

  const balance = bankAccountDetails?.balance ?? null;
  const transactions = bankAccountDetails?.transactions ?? [];

  return (
    <RequestFeedbackHandler requests={[bankAccountDetailsRequest]}>
      <BankAccountDetails balance={balance} transactions={transactions} />
    </RequestFeedbackHandler>
  );
};

export default BankAccountDetailsContainer;
