import React from "react";

interface AuthHeaderProps {
  title?: string;
  subtitle?: string;
}

export const AuthHeader = ({ title, subtitle }: AuthHeaderProps) => {
  return (
    <div className="auth-header">
      {title && (
        <h1 className="auth-header__title" data-testid="auth-header-title">
          {title}
        </h1>
      )}
      {subtitle && (
        <h2 className="auth-header__subtitle" data-testid="auth-header-subtitle">
          {subtitle}
        </h2>
      )}
    </div>
  );
};
