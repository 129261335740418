export enum OccupationEnum {
  ACCOUNTANT = "Accountant",
  ANALYST = "Analyst",
  ARCHITECT = "Architect",
  ATTORNEY = "Attorney",
  AUDITOR = "Auditor",
  BUSINESS_OWNER = "Business Owner",
  CEO = "CEO",
  COMPLIANCE_OFFICER = "Compliance Officer",
  CUSTOMER_SERVICE_REPRESENTATIVE = "Customer Service Representative",
  DIRECTOR = "Director",
  DRAFTER = "Drafter",
  ENGINEER = "Engineer",
  ENTREPRENEUR = "Entrepreneur",
  EXECUTIVE = "Executive",
  EXECUTIVE_ASSISTANT = "Executive Assistant",
  EXECUTIVE_VICE_PRESIDENT = "Executive Vice President",
  GRAPHIC_DESIGNER = "Graphic Designer",
  HUMAN_RESOURCES_EMPLOYEE = "Human Resources Employee",
  HUMAN_RESOURCES_MANAGER = "Human Resources Manager",
  IT_ENGINEER = "IT Engineer",
  LEGAL_ASSISTANT = "Legal Assistant",
  MANAGING_DIRECTOR = "Managing Director",
  OFFICE_MANAGER = "Office Manager",
  OTHER = "Other",
  PRESIDENT = "President",
  PROJECT_MANAGER = "Project Manager",
  QUALITY_ASSURANCE_SPECIALIST = "Quality Assurance Specialist",
  RECRUITER = "Recruiter",
  SALES_REPRESENTATIVE = "Sales Representative",
  SENIOR_VICE_PRESIDENT = "Senior Vice President",
  SURVEYOR = "Surveyor",
  TECHNICIAN = "Technician",
  ACTOR = "Actor",
  ANTIQUE_DEALER = "Antique Dealer",
  ART_DEALER_OR_CURATOR = "Art Dealer or Curator",
  ART_DIRECTOR = "Art Director",
  ARTIST = "Artist",
  ASIAN_CLOTHING_MANUFACTURER = "Asian Clothing Manufacturer",
  AUCTION_HOUSE_WORKER = "Auction House Worker",
  AUTHOR = "Author",
  COLLECTIONS_AGENT = "Collections Agent",
  DANCER = "Dancer",
  DEALER_IN_EXOTIC_GOODS = "Dealer in Exotic Goods",
  EDITOR = "Editor",
  FASHION_DESIGNER = "Fashion Designer",
  FLORAL_DESIGNER = "Floral Designer",
  INTERIOR_DESIGNER = "Interior Designer",
  JEWELER = "Jeweler",
  MODEL = "Model",
  MULTIMEDIA_ARTISTS_AND_ANIMATOR = "Multimedia Artists and Animator",
  PHOTOGRAPHER = "Photographer",
  ARMS_MANUFACTURER_OR_DEALER = "Arms Manufacturer or Dealer",
  BUSINESS_DEVELOPMENT_SPECIALIST = "Business Development Specialist",
  CASHIER = "Cashier",
  CHARITY_DIRECTOR = "Charity Director",
  CLAIMS_ADJUSTER = "Claims Adjuster",
  DATABASE_ADMINISTRATOR = "Database Administrator",
  EVENT_PLANNER = "Event Planner",
  FINANCIAL_ADVISOR = "Financial Advisor",
  FINANCIAL_ANALYST = "Financial Analyst",
  FINANCIAL_CLERK = "Financial Clerk",
  FUNDRAISER = "Fundraiser",
  HOSPITALITY_DIRECTOR = "Hospitality Director",
  INSURANCE_AGENT = "Insurance Agent",
  INSURANCE_UNDERWRITER = "Insurance Underwriter",
  LOAN_OFFICER = "Loan Officer",
  MATHEMATICIAN = "Mathematician",
  NATURAL_RESOURCE_MINING_DIRECTOR = "Natural Resource Mining Director",
  NATURAL_RESOURCE_MINING_EMPLOYEE = "Natural Resource Mining Employee",
  PROPERTY_MANAGER = "Property Manager",
  PUBLIC_RELATIONS_FUNDRAISING_MANAGER = "Public Relations/Fundraising Manager",
  PUBLIC_RELATIONS_FUNDRAISING_SPECIALIST = "Public Relations/Fundraising Specialist",
  PUBLISHER = "Publisher",
  PURCHASING_AGENT = "Purchasing Agent",
  PURCHASING_MANAGER = "Purchasing Manager",
  REAL_ESTATE_AGENT = "Real Estate Agent",
  RELIGIOUS_ORGANIZATION_WORKER = "Religious Organization Worker",
  RETAIL_SALES_WORKER = "Retail Sales Worker",
  SHOP_ASSISTANT = "Shop Assistant",
  STATISTICIAN = "Statistician",
  TAX_COLLECTOR = "Tax Collector",
  TRAVEL_AGENT = "Travel Agent",
  COMMUNITY_HEALTH_EDUCATOR = "Community Health Educator",
  GUIDANCE_COUNSELOR = "Guidance Counselor",
  MENTAL_HEALTH_PROFESSIONAL = "Mental Health Professional",
  PROBATION_OFFICER = "Probation Officer",
  SOCIAL_WORKER = "Social Worker",
  SUBSTANCE_ABUSE_COUNSELOR = "Substance Abuse Counselor",
  COMPUTER_PROGRAMMER = "Computer Programmer",
  INFORMATION_SECURITY_ANALYST = "Information Security Analyst",
  NETWORK_SYSTEMS_ADMINISTRATOR = "Network Systems Administrator",
  SOFTWARE_DEVELOPER = "Software Developer",
  WEB_DEVELOPER = "Web Developer",
  BUILDING_INSPECTOR = "Building Inspector",
  CARPENTER = "Carpenter",
  CONSTRUCTION_WORKER = "Construction Worker",
  ELECTRICIAN = "Electrician",
  ELEVATOR_TECHNICIAN = "Elevator Technician",
  GEMOLOGIST = "Gemologist",
  GLAZIER = "Glazier",
  MAINTENANCE_EMPLOYEE = "Maintenance Employee",
  METAL_WORKER = "Metal Worker",
  MINER = "Miner",
  PAINTER = "Painter",
  PIPEFITTER = "Pipefitter",
  PLUMBER = "Plumber",
  RAILROAD_WORKER = "Railroad Worker",
  STEAMFITTER = "Steamfitter",
  STEELWORKER = "Steelworker",
  CHILDCARE_WORKER = "Childcare Worker",
  FOOD_SERVICES_WORKER = "Food Services Worker",
  HISTORIAN = "Historian",
  LIBRARIAN = "Librarian",
  NURSE = "Nurse",
  NUTRITIONIST = "Nutritionist",
  SCHOOL_PRINCIPAL = "School Principal",
  SPEECH_PATHOLOGIST = "Speech Pathologist",
  TEACHER = "Teacher",
  TEACHER_AIDE = "Teacher Aide",
  AGRICULTURAL_MANUFACTURER = "Agricultural Manufacturer",
  AGRICULTURAL_WORKER = "Agricultural Worker",
  ANIMAL_CARE_TECHNICIAN = "Animal Care Technician",
  EXPORTER_IMPORTER = "Exporter/Importer",
  FISHING_WORKER = "Fishing Worker",
  GAME_WARDEN = "Game Warden",
  GRAIN_ELEVATOR_OPERATOR = "Grain Elevator Operator",
  NATURAL_RESOURCE_DEALER = "Natural Resource Dealer",
  NATURAL_RESOURCE_MERCHANT = "Natural Resource Merchant",
  VETERINARIAN = "Veterinarian",
  VETERINARY_TECHNICIAN = "Veterinary Technician",
  WILDLIFE_BIOLOGIST = "Wildlife Biologist",
  ZOOLOGIST = "Zoologist",
  APPRAISER = "Appraiser",
  BUREAU_DE_CHANGE_DIRECTOR = "Bureau de Change Director",
  CASINO_EMPLOYEE = "Casino Employee",
  CRYPTOCURRENCY_SPECIALIST = "Cryptocurrency Specialist",
  ECONOMIST = "Economist",
  EXCHANGE_HOUSE_DIRECTOR = "Exchange House Director",
  GAMING_SERVICES_EMPLOYEE = "Gaming Services Employee",
  MONEY_TRANSFER_DIRECTOR = "Money Transfer Director",
  REMITTANCE_HOUSE_DIRECTOR = "Remittance House Director",
  TELLER = "Teller",
  BAKER = "Baker",
  BARTENDER = "Bartender",
  BUTCHER = "Butcher",
  CHEF = "Chef",
  SERVER = "Server",
  AUDIOLOGIST = "Audiologist",
  CHIROPRACTOR = "Chiropractor",
  DENTAL_ASSISTANT = "Dental Assistant",
  DENTAL_HYGIENIST = "Dental Hygienist",
  DENTIST = "Dentist",
  GENETIC_COUNSELOR = "Genetic Counselor",
  HOME_HEALTH_AIDE = "Home Health Aide",
  MASSAGE_THERAPIST = "Massage Therapist",
  MEDICAL_ASSISTANT = "Medical Assistant",
  OPTICIAN = "Optician",
  OPTOMETRIST = "Optometrist",
  PARAMEDIC = "Paramedic",
  PHARMACIST = "Pharmacist",
  PHYSICAL_THERAPIST = "Physical Therapist",
  PHYSICIAN = "Physician",
  PODIATRIST = "Podiatrist",
  PSYCHIATRIST = "Psychiatrist",
  PSYCHOLOGIST = "Psychologist",
  SCIENTIST = "Scientist",
  SURGEON = "Surgeon",
  ASSEMBLER = "Assembler",
  MECHANIC = "Mechanic",
  TELECOMMUNICATIONS_EQUIPMENT_MAINTENANCE_WORKER = "Telecommunications Equipment Maintenance Worker",
  ARBITRATOR_MEDIATOR = "Arbitrator/Mediator",
  CONCILIATOR = "Conciliator",
  COURT_REPORTER = "Court Reporter",
  JUDGE = "Judge",
  PARALEGAL = "Paralegal",
  ANTHROPOLOGIST = "Anthropologist",
  ARCHEOLOGIST = "Archeologist",
  ASTRONOMER = "Astronomer",
  CONSERVATION_SCIENTIST = "Conservation Scientist",
  ENVIRONMENTAL_SPECIALIST = "Environmental Specialist",
  FOOD_SCIENTIST = "Food Scientist",
  GEOGRAPHER = "Geographer",
  PHYSICIST = "Physicist",
  SOCIOLOGIST = "Sociologist",
  COURIER = "Courier",
  MARIJUANA_PACKAGER = "Marijuana Packager",
  INTERPRETER = "Interpreter",
  NEWS_CORRESPONDENT = "News Correspondent",
  TECHNICAL_WRITER = "Technical Writer",
  CORRECTIONS_OFFICER = "Corrections Officer",
  DETECTIVE = "Detective",
  FIRE_INSPECTOR = "Fire Insepctor",
  FIREFIGHTER = "Firefighter",
  MILITARY_PERSONNEL = "Military Personnel",
  PILOT = "Pilot",
  POLICE_OFFICER = "Police Officer",
  POSTAL_SERVICE_WORKER = "Postal Service Worker",
  PUBLIC_SAFETY_TELECOMMUNICATOR = "Public Safety Telecommunicator",
  SECURITY_GUARD = "Security Guard",
  COSMETOLOGIST = "Cosmetologist",
  FUNERAL_SERVICE_EMPLOYEE = "Funeral Service Employee",
  HAIRDRESSER = "Hairdresser",
  PERSONAL_TRAINER = "Personal Trainer",
  STYLIST = "Stylist",
  AIR_TRAFFIC_CONTROLLER = "Air Traffic Controller",
  BUS_DRIVER = "Bus Driver",
  CAR_SERVICE_DRIVER = "Car Service Driver",
  CHAUFFER = "Chauffer",
  FLIGHT_ATTENDANT = "Flight Attendant",
  HGV_LORRY_DRIVER = "HGV/Lorry Driver",
  TRUCK_DRIVER = "Truck Driver"
}
