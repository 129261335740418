import React, { useEffect, useState } from "react";
import { RideStatus } from "lib/types/types";
import SetupPaymentCard from "../../MigrateAdminServicePackage/SetupPaymentCard/SetupPaymentCard";
import ContractSignatureCard, {
  ContractSignatureCardFlow
} from "../../MigrateAdminServicePackage/ContractSignatureCard/ContractSignatureCard";
import { getEndpoint } from "common/GraphqlClient/httpLink";
import { getToken } from "common/Authentication/getToken";
import WepaProductCard from "client/components/WepaSection/WepaOnboarding/WepaProductCard/WepaProductCard";
import WepaSelectAdditionalFiscalYears from "client/components/WepaSection/WepaOnboarding/WepaSelectAdditionalFiscalYears/WepaSelectAdditionalFiscalYears";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "lib/api/mutations/graphql/UpsertOrder";
import { OrderCompanyStatusEnum, OrderEntityType, OrderProductTypeEnum } from "global-query-types";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import ReviewStep from "./ReviewStep/ReviewStep";
import { PaymentMethodType } from "../../../../payments-query-types";
import { FinalizeOrderVariables } from "../../../../lib/api/mutations/graphql/FinalizeOrder";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  mapPaymentMethodToPaymentsModule,
  PaymentMethod
} from "../../../../lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { ApolloQueryResult } from "apollo-client";
import { ValidateStripeSession } from "../../../../lib/api/payment/queries/graphql/ValidateStripeSession";
import { useHistory } from "react-router-dom";
import FiscalYearStartingDate from "./FiscalYearStartingDate/FiscalYearStartingDate";
import { WepaDirectOrderData } from "../../../../lib/models/client/WepaDirect/WepaDirectOrderData";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { DataLayer } from "lib/services/GoogleTagManager/GoogleTagManager";
import {
  WepaDirectOnboardingEvents,
  WePaDirectDirectSalesStep
} from "lib/services/GoogleTagManager/WepaDirectOnboardingEvents";

export interface WepaOnboardingProps {
  gmbh: GmbHDetails;
  upsertOrder: (order: UpsertOrderVariables) => Promise<UpsertOrder_upsertOrder | null | undefined>;
  finalizeOrder: (order: FinalizeOrderVariables) => Promise<void>;
}

export interface WithStripePayment {
  sessionId?: string | null;
  validateStripeSession: (
    sessionId: string
  ) => Promise<ApolloQueryResult<ValidateStripeSession> | undefined>;
  paymentMethod?: PaymentMethodType | null;
  goToCheckoutSession: () => void;
  updatePaymentMethod: (paymentMethod: PaymentMethodType) => Promise<void>;
}

const WepaOnboarding = ({
  t,
  gmbh,
  upsertOrder,
  finalizeOrder,
  paymentMethod,
  updatePaymentMethod,
  goToCheckoutSession,
  validateStripeSession,
  sessionId
}: WepaOnboardingProps & WithStripePayment & WithTranslation) => {
  const module = "wepa-onboarding";
  const history = useHistory();
  const [numberOfAdditionalYears, setNumberOfAdditionalYears] = useState<number | undefined>(
    undefined
  );

  const inProgressWepaDirectOrder = gmbh.orders.find(
    (order) =>
      order?.productType === OrderProductTypeEnum.WepaDirect &&
      order.status === OrderCompanyStatusEnum.InProgress
  ) as OrderData<WepaDirectOrderData>;

  const [fiscalYearStartingDate, setFiscalYearStartingDate] = useState<string | undefined>(
    gmbh.fiscalYear ?? inProgressWepaDirectOrder?.extra?.fiscalYearStartingDate ?? undefined
  );

  const contractStepCompleted = !!inProgressWepaDirectOrder?.extra?.contractSignedAt;
  const createOrder = async () => {
    await upsertOrder({
      status: OrderCompanyStatusEnum.InProgress,
      entityType: OrderEntityType.Company,
      entityId: gmbh.id,
      productType: OrderProductTypeEnum.WepaDirect,
      extra: {
        fiscalYearStartingDate,
        contractSignedAt: new Date(Date.now()),
        numberOfAdditionalYears
      }
    });
    WepaDirectOnboardingEvents.StepCompleted({
      step_name: WePaDirectDirectSalesStep.AGREE_WITH_CONTRACT
    });
  };

  const getContractUrl = (): string => {
    const documentName = "Servicevertrag WEPA Kunde V2407";
    return `${getEndpoint()}/api/client/document-templates/wepa-direct/${documentName}/${
      gmbh.id
    }?apiToken=${getToken()}&format=pdf`;
  };

  const iban = gmbh.bankAccounts?.[0]?.iban;

  const setupPaymentMethodHandler = async (option: PaymentMethod) => {
    if (option === PaymentMethod.Sepa) {
      goToCheckoutSession();
    } else {
      await updatePaymentMethodHandler(option);
    }
  };

  const updatePaymentMethodHandler = async (option: PaymentMethod) => {
    const paymentMethod = mapPaymentMethodToPaymentsModule(option);
    await updatePaymentMethod(paymentMethod);

    const stripeSessionId = sessionId ?? undefined;

    await upsertOrder({
      id: inProgressWepaDirectOrder?.id ?? "",
      extra: {
        paymentMethod,
        stripeSessionId
      }
    });

    WepaDirectOnboardingEvents.StepCompleted({
      step_name: WePaDirectDirectSalesStep.SETUP_PAYMENT
    });
  };

  useEffect(() => {
    if (sessionId && inProgressWepaDirectOrder?.id) {
      validateStripeSession(sessionId).then((result) => {
        if (result) {
          updatePaymentMethodHandler(PaymentMethod.Sepa).then(() => {});
        }
      });

      history.replace(`/client/entities/${gmbh.id}/securities-booking`);
    }
    // eslint-disable-next-line
  }, [inProgressWepaDirectOrder?.id]);

  const onFinalizeOrder = async () => {
    DataLayer.OrderCompleted({
      ordered_products: WepaDirectOnboardingEvents.wd_ordered_products
    });

    await finalizeOrder({
      id: inProgressWepaDirectOrder?.id ?? "",
      submissionId: inProgressWepaDirectOrder?.submissionId ?? ""
    });
    WepaDirectOnboardingEvents.StepCompleted({
      step_name: WePaDirectDirectSalesStep.REVIEW_AND_SIGN_CONTRACT
    });
  };

  return (
    <div data-testid={module} className={module}>
      <WepaProductCard />
      <FiscalYearStartingDate
        stepNumber={1}
        gmbh={gmbh}
        status={fiscalYearStartingDate ? RideStatus.COMPLETED : RideStatus.PENDING}
        saveFiscalYearStartingDate={(fiscalYearStartingDate) => {
          setFiscalYearStartingDate(fiscalYearStartingDate);
          WepaDirectOnboardingEvents.StepCompleted({
            step_name: WePaDirectDirectSalesStep.FISCAL_YEAR_STARTING_DATE_SETUP
          });
        }}
      />
      <WepaSelectAdditionalFiscalYears
        stepNumber={2}
        selectFiscalYears={async (additionalYears) => {
          setNumberOfAdditionalYears(additionalYears);
          WepaDirectOnboardingEvents.StepCompleted({
            step_name: WePaDirectDirectSalesStep.PREVIOUS_FISCAL_YEARS
          });
        }}
        status={
          !fiscalYearStartingDate
            ? RideStatus.LOCKED
            : numberOfAdditionalYears !== undefined || contractStepCompleted
            ? RideStatus.COMPLETED
            : RideStatus.PENDING
        }
        fiscalYearStartingDate={fiscalYearStartingDate}
      />
      <ContractSignatureCard
        stepNumber={3}
        flow={ContractSignatureCardFlow.WEPA_DIRECT}
        status={
          contractStepCompleted
            ? RideStatus.COMPLETED
            : numberOfAdditionalYears !== undefined && fiscalYearStartingDate
            ? RideStatus.PENDING
            : RideStatus.LOCKED
        }
        contractUrl={getContractUrl()}
        downloadSignedContractUrl={""}
        successPageUrl={""}
        selectedTier={undefined}
        paymentMethod={undefined}
        onAgreeToTermsAndConditions={createOrder}
      />
      <SetupPaymentCard
        stepNumber={4}
        companyName={gmbh.name}
        iban={iban ?? ""}
        price={undefined}
        pricingInformation={t("generic:payment-method-card.wepa-direct-pricing-information")}
        status={
          paymentMethod
            ? RideStatus.COMPLETED
            : contractStepCompleted
            ? RideStatus.PENDING
            : RideStatus.LOCKED
        }
        setupPayment={setupPaymentMethodHandler}
        showYearlyPrice={true}
        footnote={t("generic:payment-method-card.payment-date-info-wepa-direct")}
      />
      <ReviewStep
        status={!!paymentMethod ? RideStatus.PENDING : RideStatus.LOCKED}
        companyName={gmbh.name}
        fiscalYear={fiscalYearStartingDate ?? ""}
        paymentMethod={paymentMethod}
        finalizeOrder={onFinalizeOrder}
      />
    </div>
  );
};

export default withTranslationReady(["generic"])(WepaOnboarding);
