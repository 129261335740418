import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Container } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { FlowTypeEnum } from "../../../global-query-types";

interface IBannerProps extends WithTranslation {
  currentFlowType: FlowTypeEnum;
}

const Banner = ({ t, currentFlowType }: IBannerProps) => {
  return (
    <Jumbotron fluid data-testid="banner" className="banner py-4 py-md-6">
      <Container className="text-light">
        <h3 data-testid="banner-heading">{bannerHeadings(t, currentFlowType)}</h3>

        <p data-testid="banner-text" className="mb-0">
          {t("trader-gmbh:trader-gmbh.banner-text")}
        </p>
      </Container>
    </Jumbotron>
  );
};

const bannerHeadings = (t, currentFlowType) => {
  if (currentFlowType === FlowTypeEnum.TRADER_HOLDING)
    return t("trader-gmbh:trader-gmbh.banner-heading.trader");
  if (currentFlowType === FlowTypeEnum.INVESTOR_HOLDING)
    return t("trader-gmbh:trader-gmbh.banner-heading.investor");
  if (currentFlowType === FlowTypeEnum.FOUNDER_HOLDING)
    return t("trader-gmbh:trader-gmbh.banner-heading.mini-holding");
  if (currentFlowType === FlowTypeEnum.REAL_ESTATE_GMBH)
    return t("trader-gmbh:trader-gmbh.banner-heading.real-estate");
  if (currentFlowType === FlowTypeEnum.HOLDING_GMBH)
    return t("trader-gmbh:trader-gmbh.banner-heading.holding");
};

export default withTranslation(["trader-gmbh"])(Banner);
