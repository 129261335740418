import React from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import {
  AddressCareOfField,
  AddressCityField,
  AddressCountryField,
  AddressLineTwoField,
  AddressPostalCodeField,
  AddressStreetField
} from "../../components/generic/commonFields";

export interface IProps {
  t: TFunction;
  formik;
  hideCountry?: boolean;
  inputClassName?: string;
  className?: string;
  showCareOfField?: boolean;
}

export const BusinessAddressInput = ({
  t,
  formik,
  hideCountry,
  inputClassName,
  className = "mt-5",
  showCareOfField = true
}: IProps) => {
  return (
    <Row className={className}>
      <Col xs={6}>
        <AddressStreetField t={t} formik={formik} className={inputClassName} />
      </Col>
      <Col xs={6} md={3}>
        <AddressPostalCodeField t={t} formik={formik} className={inputClassName} />
      </Col>
      <Col xs={6} md={3}>
        <AddressCityField t={t} formik={formik} className={inputClassName} />
      </Col>
      {showCareOfField && (
        <Col xs={6} md={3}>
          <AddressCareOfField t={t} formik={formik} className={inputClassName} />
        </Col>
      )}
      <Col xs={6} md={3}>
        <AddressLineTwoField t={t} formik={formik} className={inputClassName} />
      </Col>
      {!hideCountry && (
        <Col xs={6} md={3}>
          <AddressCountryField t={t} formik={formik} className={inputClassName} />
        </Col>
      )}
    </Row>
  );
};
