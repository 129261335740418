import React from "react";
import { IconProps } from "sharedComponents/icons/DesignSystemIcons/IconProps";

export const GlobalIcon = ({ size = 24, className }: IconProps) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 24 24"
    data-testid="global-icon"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5316_30)">
      <path
        d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.4969 9.21619 21.3896 6.5473 19.4212 4.57884C17.4527 2.61039 14.7838 1.50314 12 1.5ZM3.015 12.2955L4.0188 12.6299L5.25 14.477V15.4394C5.25001 15.6383 5.32903 15.829 5.46968 15.9697L7.5 18V19.7824C6.17849 19.0176 5.07337 17.9291 4.28873 16.6193C3.50409 15.3095 3.06576 13.8215 3.015 12.2955ZM12 21C11.3512 20.9987 10.7045 20.9271 10.0712 20.7865L10.5 19.5L11.8535 16.1163C11.8986 16.0033 11.9157 15.8811 11.9031 15.7601C11.8905 15.6391 11.8486 15.523 11.7812 15.4217L10.7227 13.834C10.6542 13.7312 10.5614 13.647 10.4525 13.5887C10.3437 13.5305 10.2221 13.5 10.0986 13.5H6.4011L5.46495 12.0955L7.06058 10.5H8.25V12H9.75V9.9492L12.6512 4.87208L11.3489 4.12792L10.7077 5.25H8.65141L7.8369 4.02833C8.93035 3.45125 10.1321 3.10845 11.3654 3.02179C12.5987 2.93512 13.8366 3.10651 15 3.525V6C15 6.19891 15.079 6.38968 15.2197 6.53033C15.3603 6.67098 15.5511 6.75 15.75 6.75H16.8486C16.9721 6.75001 17.0936 6.71953 17.2025 6.66126C17.3114 6.603 17.4042 6.51876 17.4727 6.41603L18.1304 5.42947C18.9871 6.22831 19.6802 7.18631 20.1709 8.25H17.115C16.9416 8.25 16.7736 8.31007 16.6395 8.41999C16.5054 8.52992 16.4136 8.68291 16.3796 8.85292L15.838 12.206C15.8125 12.3637 15.8381 12.5255 15.9109 12.6676C15.9837 12.8098 16.1001 12.925 16.243 12.9965L18.75 14.25L19.2638 17.2918C18.4314 18.439 17.3394 19.3731 16.0769 20.0176C14.8145 20.6621 13.4174 20.9987 12 21Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5316_30">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
