import React from "react";
import { formatDate, formatTime } from "../../common/formatters";

const Timestamp = ({ date, testId = "" }) => (
  <div data-testid={testId}>
    <div>{formatDate(date)}</div>
    <div>{formatTime(date)}</div>
  </div>
);

export default Timestamp;
