import { getOrderDetails_getOrderDetails_order } from "lib/api/queries/graphql/getOrderDetails";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideTableHeader } from "sharedComponents/RideTableHeader/RideTableHeader";
import { RideTableRow } from "sharedComponents/RideTableRow/RideTableRow";
import RideTableCell from "sharedComponents/RideTableCell/RideTableCell";
import { UpsertOrderVariables } from "lib/api/mutations/graphql/UpsertOrder";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { deepClone } from "common/deepClone";
import { ErrorAlert } from "sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { TFunction } from "i18next";
import { UploadFileResponse } from "lib/ports/FileStorage";
import { cloneDeep, get, setWith, uniqueId } from "lodash";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import { DisabledFeatureFlag } from "sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import {
  RideModalDeprecated,
  RideModalSize,
  RideModalVariant
} from "sharedComponents/RideModalDeprecated/RideModalDeprecated";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { RideDatePicker } from "uiLibrary/v2/components/RideDatePicker/RideDatePicker";
import { Form, FormikProps, FormikProvider, useFormik } from "formik";
import { OrderFile } from "./OrderFilesTableContainer";
import { FileCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/FileCard/FileCard";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";

export enum TypeOfDocument {
  ID = "ID",
  PASSPORT = "PASSPORT",
  BANK_STATEMENT = "BANK_STATEMENT"
}

interface FileFields {
  typeOfDocument?: TypeOfDocument;
  issuingCountry?: string;
  validThrough?: string;
  issuingDate?: string;
}

interface OrderFilesTableProps extends WithTranslation {
  order: getOrderDetails_getOrderDetails_order;
  files: OrderFile[];
  upsertHandler: (data: UpsertOrderVariables) => Promise<void>;
  uploadFile: (file: File, orderId: string) => Promise<UploadFileResponse>;
  isLoading: boolean;
  previewFile: (fileId: string) => Promise<void>;
}

const OrderFilesTable = ({
  t,
  order,
  files,
  upsertHandler,
  uploadFile,
  isLoading,
  previewFile
}: OrderFilesTableProps) => {
  const [errorMessage, setErrorMessage] = useState("");

  const columns = [
    { name: "entity", label: t("generic:low-tax-broker-order.file-section.entity") },
    { name: "document", label: t("generic:low-tax-broker-order.file-section.document") },
    { name: "file", label: t("generic:low-tax-broker-order.file-section.file") },
    { name: "action", label: t("generic:low-tax-broker-order.file-section.action") }
  ];

  const approveByOpsToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.ApproveLTBDocumentByOps
  );

  if (approveByOpsToggle) {
    columns.splice(3, 0, { name: "approval", label: t("generic:approval") });
  }

  const handleSave = async (
    event,
    orderFile: OrderFile,
    fields: FileFields | undefined = undefined
  ) => {
    const updatedExtra = deepClone(order.extra ?? {});

    const fileSlot = new FileSlot(updatedExtra, orderFile);

    let file;

    if (!fields) {
      let uploadResponse;
      file = event.currentTarget?.files?.[0];

      uploadResponse = await uploadFile(file, order.id);

      if (!uploadResponse) {
        setErrorMessage(`File failed to upload`);
        return;
      } else {
        setErrorMessage("");
      }

      fileSlot.setFile({
        ...fileSlot.getFile(),
        id: uploadResponse.fileId,
        name: file.name,
        size: file.size,
        checksum: uploadResponse.checksum
      });

      fileSlot.setFile({
        ...fileSlot.getFile(),
        readyToSend: isReadyToSend(orderFile.fileKey, fileSlot.getFile(), !!orderFile.back)
      });
    } else {
      if (orderFile.fileKey === "proofOfIDFile") {
        fileSlot.setFile({
          ...fileSlot.getFile(),
          typeOfDocument: fields.typeOfDocument,
          issuingCountry: fields.issuingCountry,
          validThrough: fields.validThrough
        });

        if (approveByOpsToggle && fields.typeOfDocument === TypeOfDocument.PASSPORT) {
          fileSlot.setFile({
            ...fileSlot.getFile(),
            back: null
          });
        }
      }

      if (orderFile.fileKey === "proofOfAddressFile") {
        fileSlot.setFile({
          ...fileSlot.getFile(),
          typeOfDocument: fields.typeOfDocument,
          issuingDate: fields.issuingDate
        });
      }
      fileSlot.setFile({
        ...fileSlot.getFile(),
        readyToSend: isReadyToSend(orderFile.fileKey, fileSlot.getFile(), !!orderFile.back)
      });
    }

    try {
      await upsertHandler({
        id: order.id,
        entityId: order.entityId,
        extra: updatedExtra
      });

      if (!fields) {
        orderFile.fileName = file?.name;
        orderFile.fileSize = file?.size;
      }
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const handleDelete = async (orderFile: OrderFile) => {
    const clone = deepClone(order.extra);

    const fileSlot = new FileSlot(clone, orderFile);

    const backFile = fileSlot.getFile()?.back;

    fileSlot.setFile(
      backFile
        ? {
            back: backFile
          }
        : null
    );

    await upsertHandler({
      id: order.id,
      entityId: order.entityId,
      extra: clone
    });
  };
  return (
    <div data-testid={"file-section-table"} className={"file-section-table"}>
      {errorMessage && <ErrorAlert t={t} error={errorMessage} />}
      <RideTableHeader columns={columns} />
      {files.map((fileSlot) => (
        <FileSlotRow
          t={t}
          key={`${fileSlot.path}-${fileSlot.subPath}-${fileSlot.fileKey}${
            fileSlot.back ? "-back" : ""
          }`}
          columns={columns}
          fileSlot={fileSlot}
          handleDelete={handleDelete}
          handleSave={handleSave}
          previewFile={previewFile}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};

interface UploadedFileActionsProps {
  isLoading: boolean;
  previewFile: (fileId: string) => Promise<void>;
  showModal: () => void;
  handleSave: (e: any, fileSlot: OrderFile, fields?: FileFields) => Promise<void>;
  fileSlot: OrderFile;
  formik: FormikProps<any>;
  t: TFunction;
}

const UploadedFileActions = ({
  isLoading,
  previewFile,
  showModal,
  handleSave,
  fileSlot,
  formik,
  t
}: UploadedFileActionsProps) => {
  const { fileKey, fileId } = fileSlot;

  const approveByOpsToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.ApproveLTBDocumentByOps
  );

  return (
    <div className="file-section-table__actions">
      <PreviewButton
        fileId={fileId ?? ""}
        fileKey={fileKey}
        previewFile={previewFile}
        isLoading={isLoading}
        t={t}
      />
      <FeatureFlag name={FeatureFlags.IDDocumentAdditionalInformationForOps}>
        <FormikProvider value={formik}>
          <Form>
            <RideModalDeprecated
              title={`${t(`generic:edit`)} ${t(
                `generic:low-tax-broker-order.file-section.documents.${fileKey}`
              )}`}
              dialogBody={
                <div data-testid="edit-file-modal-body">
                  <div className="file-edit-modal">
                    <EditFileFields typeOfDocument={fileSlot.fileKey} back={fileSlot.back} t={t} />
                    <div className="file-edit-modal__file-card">
                      <FileCard
                        file={{ name: fileSlot.fileName ?? "", size: fileSlot.fileSize ?? 0 }}
                      />
                    </div>
                  </div>
                  <input
                    data-testid={`${fileKey}-file-upload-button`}
                    type="file"
                    id={`${fileKey}-file-upload-button-${fileId}`}
                    className={"file-section-table__file-input"}
                    onChange={(event) => handleSave(event, fileSlot)}
                    accept={"application/pdf, image/jpeg, image/jpg, image/png"}
                    disabled={isLoading}
                  />
                </div>
              }
              variant={RideModalVariant.ROUNDED}
              size={RideModalSize.LARGE}
              buttons={[
                {
                  "data-testid": `${fileKey}-save-button-inside-modal`,
                  variant: ButtonVariant.Primary,
                  children: approveByOpsToggle ? t("generic:approve-and-save") : t("generic:save"),
                  labelFor: `${fileKey}-file-save-button-${fileId}`,
                  onClick: async (e) => {
                    await handleSave(e, fileSlot, formik.values);
                  }
                },
                {
                  "data-testid": `${fileKey}-upload-button-inside-modal`,
                  variant: ButtonVariant.Secondary,
                  children: t("generic:upload"),
                  labelFor: `${fileKey}-file-upload-button-${fileId}`,
                  keepModalOpened: true
                },
                {
                  "data-testid": `${fileKey}-delete-button-inside-modal`,
                  variant: ButtonVariant.Danger,
                  children: t("generic:delete"),
                  labelFor: "delete",
                  onClick: () => showModal()
                }
              ]}>
              {(setVisibility) => (
                <ReviewEditButton
                  fileKey={fileKey}
                  isLoading={isLoading}
                  t={t}
                  showModal={setVisibility}
                />
              )}
            </RideModalDeprecated>
          </Form>
        </FormikProvider>
      </FeatureFlag>
      <DisabledFeatureFlag name={FeatureFlags.IDDocumentAdditionalInformationForOps}>
        <DeleteButton showModal={showModal} fileKey={fileKey} isLoading={isLoading} t={t} />
      </DisabledFeatureFlag>
    </div>
  );
};

export const proofOfAddressTypeOptions = (t) => [
  {
    label: t("generic:low-tax-broker-order.file-section.documents.bankStatement"),
    value: TypeOfDocument.BANK_STATEMENT
  }
];

const EditFileFields = ({
  typeOfDocument,
  back,
  t
}: { typeOfDocument?: string; back?: boolean; t } & Partial<WithTranslation>) => {
  return (
    <div className="file-edit-modal__form">
      {typeOfDocument === "proofOfIDFile" && !back && (
        <div className="file-edit-modal__form-fields">
          <TypeOfDocumentDropdownSelect t={t} options={proofOfIdentityTypeOptions(t)} />
          <CountrySelect
            name="issuingCountry"
            label={t("generic:low-tax-broker-order.file-section.issuing-country")}
          />
          <RideDatePicker
            label={t("generic:low-tax-broker-order.file-section.valid-until")}
            name="validThrough"
          />
        </div>
      )}
      {typeOfDocument === "proofOfAddressFile" && (
        <div>
          <TypeOfDocumentDropdownSelect t={t} options={proofOfAddressTypeOptions(t)} />
          <RideDatePicker
            label={t("generic:low-tax-broker-order.file-section.issue-date")}
            name="issuingDate"
          />
        </div>
      )}
    </div>
  );
};

interface FileSlotRowProps {
  t: TFunction;
  columns: { name: string; label: string }[];
  handleDelete: (fileSlot: OrderFile) => Promise<void>;
  handleSave: (event, fileSlot: OrderFile) => Promise<void>;
  isLoading: boolean;
  fileSlot: OrderFile;
  previewFile: (fileId: string) => Promise<void>;
}

const FileSlotRow = ({
  t,
  columns,
  fileSlot,
  handleDelete,
  handleSave,
  isLoading,
  previewFile
}: FileSlotRowProps) => {
  const formik = useFormik({
    initialValues: {
      typeOfDocument: fileSlot.typeOfDocument,
      issuingCountry: fileSlot.issuingCountry,
      validThrough: fileSlot.validThrough,
      issuingDate: fileSlot.issuingDate
    },
    onSubmit: async () => {
      await handleSave({}, fileSlot);
    }
  });

  const { entityName, fileKey, fileId } = fileSlot;

  const [modalShown, setModalShown] = useState(false);
  const showModal = () => {
    formik.resetForm();
    setModalShown(true);
  };
  const hideModal = () => {
    setModalShown(false);
    formik.resetForm();
  };

  const UploadButton = () => {
    const id = uniqueId();
    const approveByOpsToggle = FeatureFlagService.instance.isEnabled(
      FeatureFlags.ApproveLTBDocumentByOps
    );

    return (
      <div data-testid="file-upload-section" className="file-section-table__file-upload-box">
        <RideButtonDeprecated
          isLoading={isLoading}
          labelFor={`${fileKey}-file-upload-button-${id}`}
          data-testid={`${fileKey}-file-upload-label`}
          className={!fileSlot.required ? "button--secondary--disabled" : ""}>
          {t("generic:upload")}
        </RideButtonDeprecated>
        <input
          data-testid={`${fileKey}-file-upload-button`}
          type="file"
          id={`${fileKey}-file-upload-button-${id}`}
          className={"file-section-table__file-input"}
          onChange={(event) => handleSave(event, fileSlot)}
          accept={"application/pdf, image/jpeg, image/jpg, image/png"}
          disabled={approveByOpsToggle ? isLoading || !fileSlot.required : isLoading}
        />
      </div>
    );
  };

  const action = fileId ? (
    <UploadedFileActions
      previewFile={previewFile}
      isLoading={isLoading}
      showModal={showModal}
      handleSave={handleSave}
      fileSlot={fileSlot}
      formik={formik}
      t={t}
    />
  ) : (
    <UploadButton />
  );

  return (
    <>
      {modalShown && (
        <ConfirmationModal
          title={t("generic:confirm-action-modal-delete.title")}
          message={t("generic:confirm-action-modal-delete.messages.delete-file")}
          type={ConfirmationModalType.DANGER}
          confirmLabel={t("generic:delete")}
          visible={modalShown}
          onConfirm={async () => {
            await handleDelete(fileSlot);
          }}
          onClose={hideModal}
        />
      )}

      <RideTableRow colCount={columns.length}>
        <RideTableCell value={entityName} />
        <RideTableCell
          value={t(
            `generic:low-tax-broker-order.file-section.documents.${fileKey}${
              fileSlot.back ? "-back" : ""
            }`
          )}
        />
        <RideTableCell
          value={fileId ?? t("generic:low-tax-broker-order.file-section.not-uploaded")}
        />
        <FeatureFlag name={FeatureFlags.ApproveLTBDocumentByOps}>
          <RideTableCell
            value={fileSlot.readyToSend ? t("generic:approved") : t("generic:not-yet-approved")}
          />
        </FeatureFlag>
        <RideTableCell value={action} />
      </RideTableRow>
    </>
  );
};

const TypeOfDocumentDropdownSelect = ({
  t,
  options
}: {
  t: TFunction;
  options: { label: string; value: TypeOfDocument }[];
}) => {
  return (
    <RideDropdownSelect
      name="typeOfDocument"
      options={options}
      label={t("generic:low-tax-broker-order.file-section.document-type")}
      isOnModal={true}
    />
  );
};

const DeleteButton = ({
  fileKey,
  isLoading,
  showModal,
  t
}: {
  fileKey: string;
  isLoading: boolean;
  showModal: () => void;
  t: TFunction;
}) => (
  <RideButtonDeprecated
    variant={ButtonVariant.Danger}
    type="button"
    data-testid={`${fileKey}-file-delete-button`}
    isLoading={isLoading}
    onClick={() => {
      showModal();
    }}>
    {t("generic:delete")}
  </RideButtonDeprecated>
);

const ReviewEditButton = ({
  fileKey,
  isLoading,
  showModal,
  t
}: {
  fileKey: string;
  isLoading: boolean;
  showModal: (boolean) => void;
  t: TFunction;
}) => {
  const approveByOpsToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.ApproveLTBDocumentByOps
  );

  return (
    <RideButtonDeprecated
      variant={ButtonVariant.Primary}
      type="button"
      data-testid={`${fileKey}-file-edit-button`}
      isLoading={isLoading}
      onClick={() => {
        showModal(true);
      }}>
      {approveByOpsToggle ? t("generic:review") : t("generic:edit")}
    </RideButtonDeprecated>
  );
};

const PreviewButton = ({
  fileKey,
  fileId,
  isLoading,
  previewFile,
  t
}: {
  fileKey: string;
  isLoading: boolean;
  fileId: string;
  previewFile: (fileId: string) => Promise<void>;
  t: TFunction;
}) => (
  <RideButtonDeprecated
    variant={ButtonVariant.Primary}
    type="button"
    data-testid={`${fileKey}-file-preview-button`}
    isLoading={isLoading}
    onClick={async () => {
      await previewFile(fileId);
    }}>
    {t("generic:preview")}
  </RideButtonDeprecated>
);
const isReadyToSend = (fileKey: string, fileFromExtra, back: boolean): boolean => {
  if (fileKey === "proofOfIDFile" && !back) {
    return (
      !!fileFromExtra.validThrough &&
      !!fileFromExtra.issuingCountry &&
      !!fileFromExtra.typeOfDocument &&
      !!fileFromExtra.id
    );
  } else if (fileKey === "proofOfAddressFile") {
    return !!fileFromExtra.issuingDate && !!fileFromExtra.typeOfDocument && !!fileFromExtra.id;
  } else {
    return !!fileFromExtra.id;
  }
};

export const proofOfIdentityTypeOptions = (t) => {
  return [
    { label: t("generic:european-union-id-card"), value: TypeOfDocument.ID },
    { label: t("generic:passport"), value: TypeOfDocument.PASSPORT }
  ];
};

export default withTranslationReady(["generic"])(OrderFilesTable);

class FileSlot {
  constructor(
    private extra: getOrderDetails_getOrderDetails_order["extra"],
    private orderFile: OrderFile
  ) {}

  getFile() {
    const file = get(this.extra, this.path);

    return cloneDeep(file ?? {});
  }

  setFile(info: any) {
    setWith(this.extra, this.path, info, Object);
  }

  private get path(): string {
    let path: string = this.orderFile.path;

    if (this.orderFile.subPath) {
      path = `${path}.${this.orderFile.subPath}`;
    }

    path = `${path}.${this.orderFile.fileKey}`;

    if (this.orderFile.back) {
      path = `${path}.back`;
    }

    return path;
  }
}
