import React from "react";
import { LowTaxBrokerViewSwitcher } from "client/components/LowTaxBrokerViewSwitcher/LowTaxBrokerViewSwitcher";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { GetLTBOrderForCompany } from "lib/api/queries/GetLowTaxBrokerOrderForCompany";
import { BrokerageAccount } from "lib/types/types";
import { GmbHDetails } from "../../../lib/models/client/GmbHDetails";

export const LowTaxBrokerViewSwitcherContainer = ({
  gmbh,
  brokerageAccounts
}: {
  gmbh: GmbHDetails;
  brokerageAccounts: BrokerageAccount[] | null;
}) => {
  const getLowTaxBrokerOrderQuery = GetLTBOrderForCompany(gmbh.id);

  const order = getLowTaxBrokerOrderQuery?.data?.getLowTaxBrokerOrderForCompany;

  return (
    <RequestFeedbackHandler requests={[getLowTaxBrokerOrderQuery]}>
      <LowTaxBrokerViewSwitcher gmbh={gmbh} order={order} brokerageAccounts={brokerageAccounts} />
    </RequestFeedbackHandler>
  );
};
