import { ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { TaskStatesEnum } from "global-query-types";
import React from "react";
import { useMutation } from "react-apollo";
import {
  SetProcessTaskState,
  SetProcessTaskStateVariables
} from "components/client/company/graphql/SetProcessTaskState";
import { SET_PROCESS_TASK_STATE } from "components/client/company/Mutations";
import CompanyProcessStatesSection from "./CompanyProcessStatesSection";
import { GmbHWithActionableTasks } from "lib/models/client/GmbHWithActionableTasks";
import { GetCompanyCreationSteps } from "lib/api/queries/GetCompanyCreationSteps";
import logger from "common/Logger";
import { ValidatePaymentMethodHandler } from "../Entity/ValidatePaymentMethodHandler/ValidatePaymentMethodHandler";
import {
  ValidateStripeSession,
  ValidateStripeSessionVariables
} from "../../../lib/api/payment/queries/graphql/ValidateStripeSession";
import { VALIDATE_STRIPE_SESSION } from "../../../lib/api/payment/queries/ValidateStripeSession";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { PaymentMethod } from "../../../lib/models/client/MigrateAdminServicePackage/MigrateAdminServicePackageData";
import { useApolloClient } from "@apollo/react-hooks";
import { getPaymentsContext } from "../../../lib/api/payment/context";
import { PaymentPlatformAccount } from "../../../payments-query-types";

interface CompanyProcessStatesSectionContainerProps {
  gmbh: GmbHWithActionableTasks;
}

export const CompanyProcessStatesSectionContainer = ({
  gmbh
}: CompanyProcessStatesSectionContainerProps) => {
  const id = gmbh.companyCreationId;

  const { id: companyId } = useParams<{ id: string }>();

  const history = useHistory();
  const [setTaskStateMutation] = useMutation<SetProcessTaskState, SetProcessTaskStateVariables>(
    SET_PROCESS_TASK_STATE
  );

  const client = useApolloClient();

  const validateSession = async (sessionId: string) => {
    try {
      return await client.query<ValidateStripeSession, ValidateStripeSessionVariables>({
        query: VALIDATE_STRIPE_SESSION,
        variables: {
          sessionId,
          paymentPlatformAccount: PaymentPlatformAccount.RIDE
        },
        ...getPaymentsContext()
      });
    } catch (e) {
      logger.error(e);
    }
  };

  if (!id || gmbh.isComplete) {
    return null;
  }

  const processStepsRequest = GetCompanyCreationSteps(id);

  const handleTaskStateChange = async (
    ref: string,
    state: TaskStatesEnum,
    options?: ActionableTaskOptions
  ) => {
    await setTaskStateMutation({ variables: { companyCreationId: id, ref, state, options } });
    try {
      await processStepsRequest.refetch();
      await gmbh.refetch();
    } catch (e) {
      logger.error(e);
    }
  };

  const validateSessionAndCompleteGSTaskHandler = async (companyId: string, sessionId: string) => {
    const validSession = await validateSession(sessionId);

    if (validSession?.data.validateStripeSession) {
      await handleTaskStateChange("choosePaymentMethod", TaskStatesEnum.COMPLETED, {
        type: "PaymentMethod",
        data: { value: PaymentMethod.Sepa }
      });
    }

    history.push(`/client/entities/${companyId}/general-info`);
  };

  return (
    <RequestFeedbackHandler requests={[processStepsRequest]}>
      <ValidatePaymentMethodHandler
        completePaymentMethodTask={validateSessionAndCompleteGSTaskHandler}
        companyId={companyId}
      />
      <CompanyProcessStatesSection
        processSteps={processStepsRequest.data?.getCompanyCreationSteps ?? []}
        handleTaskStateChange={handleTaskStateChange}
      />
    </RequestFeedbackHandler>
  );
};
