import { LocalStorageNode } from "lib/dataAdapters/toRemoteStorageNode";
import { StorageNodesEntityType } from "../../../global-query-types";

export enum FileUploadStateEnum {
  Prepared,
  Upload,
  Success,
  Error
}

export interface PresignedUrl {
  partNumber: number;
  url: string;
}

export interface UploadURL {
  uploadId: string;
  s3_url: PresignedUrl;
}

export interface FinishFileUploadState extends LocalStorageNode {
  status: number | null;
  statusText: string | null;
}

export interface FileUploadOperations {
  createFileUploadURL: (
    nodeId: string,
    fileName: string,
    fileSize: number,
    entityType: StorageNodesEntityType,
    entityId: string
  ) => Promise<UploadURL>;
  finishFileUpload: (
    name: string,
    uploadId: string,
    partNumber: number,
    eTag: string,
    nodeId: string
  ) => Promise<FinishFileUploadState>;
  uploadFile: (url: string, file: File) => Promise<string>;
  refetch?: () => Promise<void>;
}

export const preventSpecialChars = (file: File): string => {
  let newFileName = "";
  const regex = new RegExp(/^-|[<>:|!@#$%^&+~?*/]|\.$/gm);

  const isFileNameWrong = regex.test(file.name);

  if (isFileNameWrong) {
    newFileName = file.name.replace(regex, "_");
  }

  return newFileName.length > 0 ? newFileName : file.name;
};

export class FileUploadState {
  uploaded: number = 0;
  total: number = 0;
  state: FileUploadStateEnum = FileUploadStateEnum.Prepared;
  promise?: Promise<FinishFileUploadState>;

  constructor(
    public nodeId: string,
    private entityType: StorageNodesEntityType,
    private entityId: string,
    private file: File,
    private operations: FileUploadOperations
  ) {}

  get name() {
    return this.file.name;
  }

  get fileSize() {
    return this.file.size;
  }

  async start() {
    this.promise = new Promise<FinishFileUploadState>(async (resolve, reject) => {
      try {
        const { s3_url, uploadId } = await this.operations.createFileUploadURL(
          this.nodeId,
          this.name,
          this.fileSize,
          this.entityType,
          this.entityId
        );
        const eTag = await this.operations.uploadFile(s3_url.url, this.file);
        const finalizeUpload = await this.operations.finishFileUpload(
          this.name,
          uploadId,
          s3_url.partNumber,
          eTag,
          this.nodeId
        );

        resolve(finalizeUpload);
      } catch (e) {
        reject(e);
      }
    });
  }

  async onCancel() {}

  async onRetry() {}
}

export class FileUploadService {
  private static _instance: FileUploadService;
  onUpdate?: (states: FileUploadState[]) => Promise<void>;
  private stateList: FileUploadState[] = [];

  constructor(private operations: FileUploadOperations) {}

  get currentStates() {
    return this.stateList;
  }

  static getInstance(operations?: FileUploadOperations) {
    if (!FileUploadService._instance && operations) {
      FileUploadService._instance = new FileUploadService(operations);
    }

    return FileUploadService._instance;
  }

  setRefetch(refetch: () => Promise<void>) {
    this.operations.refetch = refetch;
  }

  async addToQueue(
    nodeId: string,
    entityType: StorageNodesEntityType,
    entityId: string,
    file: File
  ) {
    const blob = file.slice(0, file.size, file.type);
    let newFile: File = new File([blob], preventSpecialChars(file), { type: file.type });
    const fileState = new FileUploadState(nodeId, entityType, entityId, newFile, this.operations);
    await fileState.start();
    this.stateList.push(fileState);

    await this.onUpdate?.(this.stateList);
  }

  async waitAll(): Promise<FinishFileUploadState[]> {
    const uploadedStorageNodes: FinishFileUploadState[] = [];

    try {
      for (const state of this.stateList) {
        const storageNode = await state.promise;
        if (storageNode) uploadedStorageNodes.push(storageNode);
      }
    } catch (e) {
      throw e;
    } finally {
      this.stateList = [];
    }

    await this.operations.refetch?.();
    return uploadedStorageNodes;
  }

  async waitOne(): Promise<FinishFileUploadState | null> {
    const nextFileState = this.stateList.shift();

    try {
      const storageNode = await nextFileState?.promise;
      if (storageNode) return storageNode;
    } catch (e) {
      throw e;
    }

    return null;
  }
}
