import { OrderCompanyStatusEnum } from "global-query-types";
import React from "react";
import { ClientSelect, ClientSelectOption } from "client/components/ClientSelect/ClientSelect";
import { Form, FormikProvider, useFormik } from "formik";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";

export interface LowTaxBrokerOrdersFiltersData {
  status?: OrderCompanyStatusEnum;
}

interface LowTaxBrokerOrdersFiltersProps extends WithTranslation {
  onFilterChange: (data: LowTaxBrokerOrdersFiltersData) => void;
}

const LowTaxBrokerOrdersFilters = ({ onFilterChange, t }: LowTaxBrokerOrdersFiltersProps) => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {}
  });

  const options: ClientSelectOption[] = [
    { label: "Completed", value: OrderCompanyStatusEnum.Completed },
    { label: "Need action", value: OrderCompanyStatusEnum.Booked },
    { label: "Pending", value: OrderCompanyStatusEnum.InProgress },
    { label: "Canceled", value: OrderCompanyStatusEnum.Canceled }
  ];

  return (
    <FormikProvider value={formik}>
      <Form>
        <ClientSelect
          name="status"
          label={t("generic:ltb-order-list.columns.status")}
          options={options}
          changeHandler={(status) => {
            onFilterChange({
              status: status === "" ? undefined : (status as OrderCompanyStatusEnum)
            });
          }}
          isClearable
          isSearchable
        />
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(LowTaxBrokerOrdersFilters);
