import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { AssetTypes } from "lib/types/types";
import { TradesLastThreeYearsSchema } from "lib/validation/TradesLastThreeYearsSchema";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import TradesLastThreeYears from "./TradesLastThreeYearsStep.partials";

const TradesLastThreeYearsStep = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  let initialValues = {};
  const assetsOptions =
    Object.values(AssetTypes).filter((asset) => {
      if (order.extra.assetExperiences?.[asset]) {
        initialValues[asset] = order.extra.assetExperiences?.[asset]?.tradesLastThreeYears ?? "";
      }

      return order.extra.assetExperiences?.[asset];
    }) ?? [];

  const onSubmit = async () => {
    let assetExperiences = {};

    assetsOptions.forEach((asset) => {
      assetExperiences[asset] = {
        ...order.extra.assetExperiences?.[asset],
        tradesLastThreeYears: formik.values[asset]
      };
    });

    currentStep.complete();
    await saveData(
      {
        ...order.extra,
        assetExperiences,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validationSchema: TradesLastThreeYearsSchema(t, assetsOptions)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <TradesLastThreeYears assetOptionsSelected={assetsOptions} formik={formik} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(TradesLastThreeYearsStep);
