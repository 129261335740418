import React from "react";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "../../../../uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { ConfettiAnimation } from "../../../../uiLibrary/v2/animations/confetti/ConfettiAnimation";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "../../../../common/i18n/withTranslationReady";

interface CelebrationModalProps {
  visible: boolean;
  onClose: () => void;
}

const CelebrationModal = ({ t, visible, onClose }: CelebrationModalProps & WithTranslation) => {
  return (
    <ConfirmationModal
      dataTestId="celebration-modal"
      title={t("generic:low-tax-broker-overview.fund-account.celebration-modal.title")}
      message={t(
        rideBrokerT("generic:low-tax-broker-overview.fund-account.celebration-modal.message")
      )}
      type={ConfirmationModalType.SUCCESS}
      confirmLabel={t("generic:done")}
      visible={visible}
      onConfirm={onClose}
      onClose={onClose}
      animation={ConfettiAnimation}
      illustration="successful"
    />
  );
};

export default withTranslationReady(["generic"])(CelebrationModal);
