import React, { useState } from "react";
import { GetCompanyCreations } from "../../../lib/api/queries/GetCompanyCreations";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import AdminServiceList from "./AdminServiceList";
import { CompanyCreationTypeEnum } from "../../../global-query-types";
import RideSearch from "../../../sharedComponents/RideSearch/RideSearch";
import { RidePagination } from "../../../uiLibrary/designSystem/RidePagination/RidePagination";

export const AdminServiceListContainer = () => {
  const pageSize = 15;
  const [start, setStart] = useState(0);
  const [query, setQuery] = useState("");

  const request = GetCompanyCreations(CompanyCreationTypeEnum.ADMIN_SERVICE, query, {
    start,
    limit: pageSize
  });

  return (
    <RideSearch query={query} onQueryChange={setQuery}>
      <RidePagination
        count={request.data?.companyCreations?.count ?? 0}
        start={start}
        pageSize={pageSize}
        setStart={setStart}>
        <RequestFeedbackHandler requests={[request]}>
          <AdminServiceList
            refetch={request.refetch}
            list={request.data?.companyCreations?.items ?? []}
          />
        </RequestFeedbackHandler>
      </RidePagination>
    </RideSearch>
  );
};
