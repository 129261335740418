import React from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { GetEvents } from "../../../lib/api/queries/GetEvents";
import { EventEntityType } from "../../../global-query-types";
import AuditLogTable from "./AuditLogTable";

export interface AuditLogParams {
  entityType: EventEntityType;
  entityId: string;
}

export const AuditLogContainer = (params: AuditLogParams) => {
  const getEventsRequest = GetEvents(params);
  const events = getEventsRequest.data?.getEvents ?? [];

  return (
    <RequestFeedbackHandler requests={[getEventsRequest]}>
      <AuditLogTable events={events} />
    </RequestFeedbackHandler>
  );
};
