import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { AssertionError } from "assert";
import logger from "common/Logger";
import { RealEstateFormSchema } from "lib/validation/RealEstateFormSchema";
import {
  CreateRealEstateMutation,
  CreateRealEstateMutationVariables
} from "./graphql/CreateRealEstateMutation";
import LoadingAlert from "../generic/LoadingAlert";
import SuccessAlert from "../generic/SuccessAlert";
import ErrorAlertLegacy from "../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { RealEstateFormFields } from "./RealEstateFormFields";
import { GetMe } from "lib/api/queries/MeQuery";
import { CREATE_REAL_ESTATE_MUTATION } from "lib/api/mutations/CREATE_REAL_ESTATE_MUTATION";

interface newRealEstateProps extends WithTranslation {}

export const noPhotoLocation = "/assets/no-photo.png";

export const NewRealEstateForm: React.FC<newRealEstateProps> = (props) => {
  const { t } = props;
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const userId = GetMe().data!.me!.person!.user!.id;
  const defaultPercentageOwnership = 100;

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setSuccess(false);
    setShow(true);
  };

  const [callCreateRealEstateMutation, { error, loading: loadingCreateRealEstate }] = useMutation<
    CreateRealEstateMutation,
    CreateRealEstateMutationVariables
  >(CREATE_REAL_ESTATE_MUTATION);

  const errorReason = error && error.message;

  const onSubmit = async (values) => {
    try {
      const { data } = await callCreateRealEstateMutation({
        variables: {
          label: values.label,
          propertyValue: values.propertyValue,
          shares: values.percentageOwnership,
          ownerCompanyId: values.ownerId,
          ownerUserId: userId
        } as CreateRealEstateMutationVariables
      });

      if (data) {
        setSuccess(true);
        handleClose();
      } else {
        setSuccess(false);
      }
    } catch (error) {
      logger.error(error);
      throw new AssertionError({ message: "Creating Real Estate not successful" });
    }
  };

  return (
    <>
      <Button
        className="add-plus-btn"
        variant="link"
        title={t("realEstate:add-real-estate-asset")}
        data-testid="add-button"
        onClick={handleShow}>
        +
      </Button>

      <Modal show={show} size="xl" onHide={handleClose}>
        <ErrorAlertLegacy t={t} error={errorReason} />
        {loadingCreateRealEstate ? (
          <LoadingAlert t={t} message={t("realEstate:createrealestate-loading-message")} />
        ) : null}
        <SuccessAlert
          t={t}
          success={success}
          message={t("realEstate:createrealestate-success-message")}
        />

        <Modal.Header closeButton>
          <Modal.Title>{t("realEstate:add-real-estate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              label: "",
              propertyValue: 0.0,
              percentageOwnership: defaultPercentageOwnership,
              ownerId: ""
            }}
            validationSchema={RealEstateFormSchema()}
            validateOnChange={true}
            onSubmit={onSubmit}>
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Container>
                  <RealEstateFormFields t={t} formik={formik} />

                  <Button className="button float-sm-right mr-2" type="submit" data-testid="submit">
                    {t("generic:save")}
                  </Button>
                </Container>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(["realEstate", "generic"])(NewRealEstateForm);
