import { useEffect } from "react";
import { DataLayer } from "../../lib/services/GoogleTagManager/GoogleTagManager";

interface Props {
  form: string;
  field: string;
  error?: string;
}

export const ReportValidationError = ({ form, field, error }: Props) => {
  useEffect(() => {
    if (!error) return;

    DataLayer.FormValidationError({
      formName: form,
      fieldName: field,
      errorMessage: error
    });
  }, [form, field, error]);

  return null;
};
