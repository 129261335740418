import React from "react";
import { MembersList } from "../../../components/common/MembersList";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";

interface CompanySilentPartnersCardProps extends CompanyCardProps {}

const CompanySilentPartnersCard = ({ t, gmbh }: CompanySilentPartnersCardProps) => {
  return (
    <RideSectionCard
      data-testid="gmbh-silent-partners"
      title={t("client:company:silent-partners")}
      className="company-shareholders-card">
      <MembersList
        t={t}
        members={gmbh.silentPartners}
        silentPartnerShares={gmbh.silentPartnerShares}
        showShares={true}
        showVotes={true}
        showAddress={true}
      />
    </RideSectionCard>
  );
};

export default CompanySilentPartnersCard;
