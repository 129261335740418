import React from "react";
import LoadingAlert from "../../generic/LoadingAlert";
import { withTranslation, WithTranslation } from "react-i18next";
import ErrorAlertLegacy from "../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import EmailUpdateForm from "./EmailUpdate/EmailUpdateForm";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { GetUserInfoByPersonId } from "./GetUserInfo";
import ApproveForm from "./ApproveForm";
import { walletOwner } from "./walletOwner";

interface UserDetailPagePropsMatchProps {
  id: string;
}

interface UserDetailPageProps
  extends WithTranslation,
    RouteComponentProps<UserDetailPagePropsMatchProps> {
  backUrl: string;
}

const UserDetailPage: React.FunctionComponent<UserDetailPageProps> = (props) => {
  const { t, backUrl, match } = props;
  const personId = match.params.id;
  const { loading, error, data } = GetUserInfoByPersonId(personId);

  if (loading) return <LoadingAlert t={t} message={t("generic:loading-message")} />;

  const userWallet = data?.getUserInfoByPersonId?.person?.userWallet || [];
  const wallets = userWallet[0]?.tanganyWallets?.map((walletInfo) => ({
    walletProviderId: walletInfo.walletProviderId,
    ownerName: walletOwner(t, walletInfo)
  }));

  return (
    <>
      <ErrorAlertLegacy t={t} error={error?.message} />
      {data?.getUserInfoByPersonId ? (
        <>
          <EmailUpdateForm
            email={data.getUserInfoByPersonId.email}
            userId={data.getUserInfoByPersonId.id}
            personId={personId}
          />
          <ApproveForm wallets={wallets || []} />
        </>
      ) : (
        ""
      )}
      <Link to={backUrl} className="btn btn-link">
        {t("generic:back")}
      </Link>
    </>
  );
};

export default withTranslation(["generic", "errorCodes"])(UserDetailPage);
