import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { FormerClientAssignmentsContainer } from "../../components/FormerClientAssignments/FormerClientAssignmentsContainer";

const FormerClientAssignmentsScene = (props: WithTranslation) => {
  return <FormerClientAssignmentsContainer />;
};

export default withTranslationReady(["nominee-dashboard"])(FormerClientAssignmentsScene);
