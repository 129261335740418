import gql from "graphql-tag";

export const FINALIZE_LTB_ORDER = gql`
  mutation FinalizeLTBOrder($id: ID!, $submissionId: String!, $ownerType: OrderOwnerType) {
    finalizeLTBOrder(id: $id, submissionId: $submissionId, ownerType: $ownerType) {
      Status
      Errors
    }
  }
`;
