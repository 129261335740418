import { ActionableTask } from "common/types/ProcessStatesTypes";
import React, { useState } from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { BankType, TaskType } from "global-query-types";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { ClientSelectOption } from "client/components/ClientSelect/ClientSelect";
import { TASK_INPUT_PREFIX } from "../ProcessStatesSection";
import ProcessOptionsService from "../lib/ProcessOptionsService";
import ReactSelect from "react-select";

interface ProcessTaskOptionsProps extends WithTranslation {
  task: ActionableTask;
  isEditMode: boolean;
}

const ProcessTaskOptions = ({ task, isEditMode }: ProcessTaskOptionsProps) => {
  const bankOptionsForClientSelect: ClientSelectOption[] = Object.values(BankType).map((bank) => ({
    label: bank,
    value: bank
  }));

  let editorType: TaskType = task.type;
  let optionValue = task.options?.data?.value;

  if (task.ref === "appointmentNotaryRequest") {
    editorType = TaskType.TEXT_AREA;
  }

  const name = `${TASK_INPUT_PREFIX}${task.ref}`;

  const [value, setValue] = useState(ProcessOptionsService.instance.values[name]);

  const inputProps = {
    value: value,
    onBlur: (event) => {
      const value = event.target.value;
      setValue(value);
      ProcessOptionsService.instance.values[name] = value;
    },
    onChange: (event) => {
      const value = event.target.value;
      setValue(value);
      ProcessOptionsService.instance.values[name] = value;
    }
  };

  if (editorType === TaskType.TEXT_AREA) {
    return (
      <RideInput
        name={name}
        label=""
        isDisabled={!isEditMode}
        asTextArea={true}
        inputProps={inputProps}
      />
    );
  }

  if (editorType === TaskType.BANK_ACCOUNT_PROVIDER && isEditMode) {
    return (
      <div data-testid={`${name}-select-container`}>
        <ReactSelect
          name={name}
          id={name}
          options={bankOptionsForClientSelect}
          value={bankOptionsForClientSelect.find((option) => option.value === value)}
          onChange={(selection) => {
            setValue(selection?.value);
            ProcessOptionsService.instance.values[name] = selection?.value;
          }}
          classNamePrefix="client-select"
          className={`client-select__container`}
        />
      </div>
    );
  }

  if (editorType === TaskType.BANK_ACCOUNT_PROVIDER && !isEditMode) {
    return <RideInput name={name} label="" isDisabled={true} inputProps={inputProps} />;
  }

  if (editorType === TaskType.IBAN) {
    return <RideInput name={name} label="" isDisabled={!isEditMode} inputProps={inputProps} />;
  }

  if (optionValue) {
    return <strong data-testid={`${name}-value`}>{optionValue}</strong>;
  }

  return null;
};

export default withTranslationReady(["company-founding-steps"])(ProcessTaskOptions);
