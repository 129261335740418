import { PhoneNumberUtil } from "google-libphonenumber";
import logger from "../../common/Logger";

export function splitPhoneNumberIntoComponents(phoneInputValue: string) {
  let rawInput = phoneInputValue.replaceAll(/[^0-9+]/g, "");
  if (!rawInput.startsWith("+")) {
    rawInput = `+${rawInput}`;
  }

  try {
    const instance = PhoneNumberUtil.getInstance();
    const phoneNumber = instance.parse(rawInput);

    const phoneNumberWithoutCountry = phoneNumber.getNationalNumber();

    const result = instance.getRegionCodeForNumber(phoneNumber);
    if (!result) {
      logger.error(new Error("Could not detect region code for phone number. Assuming DE"));
    }

    return {
      phoneCountry: result ?? "DE",
      phoneNumberWithoutCountry: phoneNumberWithoutCountry?.toString(),
      phoneNumber: rawInput.replace(/^\+/, "")
    };
  } catch (e) {
    console.error(e);
    return {
      phoneCountry: "DE",
      phoneNumberWithoutCountry: rawInput.replace(/^\+?49/, ""),
      phoneNumber: rawInput.replace(/^\+/, "")
    };
  }
}
