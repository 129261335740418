import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { StepHeading } from "../../ChaptersOrderLayout/ChaptersOrderLayout.partials";
import PercentageInputCard from "client/components/ChaptersOrderLayout/sharedComponents/PercentageInputCard/PercentageInputCard";
import { FormikProps } from "formik";
import { SourceOfWealthType } from "lib/models/client/LowTaxBroker/SourcesOfWealth";

interface SourcesOfWealthSpreadProps extends WithTranslation {
  formik: FormikProps<any>;
  sourcesOfWealth: SourceOfWealthType[];
}

const SourcesOfWealthSpread = ({ t, formik, sourcesOfWealth }: SourcesOfWealthSpreadProps) => {
  const total = sourcesOfWealth
    .map((source) => {
      formik.values[source] = formik.values[source] ? Math.abs(formik.values[source]) : undefined;
      return formik.values[source];
    })
    .reduce((acc, curr) => {
      let sum = acc;
      if (curr) {
        sum = acc + curr;
      }
      return sum;
    }, 0);

  const percentageTotalState =
    total === 100 ? "percentage-total--success" : total > 100 ? "percentage-total--error" : "";

  return (
    <div data-testid="sources-of-wealth-spread" className="sources-of-wealth-spread">
      <StepHeading text={t("generic:ride-broker-onboarding.sources-of-wealth-spread.title")} />
      {sourcesOfWealth.map((source) => {
        return (
          <PercentageInputCard
            key={source}
            label={t(`generic:ride-broker-onboarding.sources-of-wealth.${source}`)}
            name={source}
            placeholder={t(
              `generic:ride-broker-onboarding.sources-of-wealth.spread-value-placeholder`
            )}
          />
        );
      })}
      <span data-testid="percentage-total" className={`percentage-total ${percentageTotalState}`}>
        {total ? total : 0}% {t("generic:out-of")} 100%
      </span>
    </div>
  );
};

export default withTranslation(["generic"])(SourcesOfWealthSpread);
