import { getWepaManagerOauthRedirectionUrl } from "common/Authentication/getWepaOauthRedirection";
import { useEffect } from "react";

export const WepaManagerOauthScene = () => {
  useEffect(() => {
    window.location.href = getWepaManagerOauthRedirectionUrl();
  }, []);

  return null;
};
