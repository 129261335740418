import { withTranslation, WithTranslation } from "react-i18next";
import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { RolesSectionValidationSchema } from "../../../../lib/validation/RolesSectionValidationSchema";
import { Col, Row } from "react-bootstrap";
import EditRolesTable from "../../common/roles/EditRolesTable";
import ViewRolesTable from "../../../common/ViewRolesTable/ViewRolesTable";
import EditableSection from "../../../../sharedComponents/EditableSection/EditableSection";
import {
  RoleEntry,
  shareholderListValidation
} from "../../../../lib/dataAdapters/membershipCategorization/membershipCategorization";

interface RolesSectionProps extends WithTranslation {
  roles: RoleEntry[] | null;
  handleSubmit: (values: { roles: RoleEntry[] }) => Promise<void>;
  areVotesDisabled: boolean;
  extraEditingActions?: React.ReactNode;
}

/**
 * @Deprecated Use RoleSection instead
 */

const RolesSectionOld = ({
  t,
  areVotesDisabled,
  roles,
  handleSubmit,
  extraEditingActions
}: RolesSectionProps) => {
  const [editMode, setEditMode] = useState(false);
  const finishEditing = () => setEditMode(false);

  const [formWarning, setFormWarning] = useState("");

  const onSubmit = async (values: { roles: RoleEntry[] }) => {
    values.roles.forEach((role) => {
      role.votes = role.votes ?? 0;
    });
    await handleSubmit(values);
    setEditMode(false);
  };

  const formik = useFormik({
    initialValues: { roles: roles ?? [] },
    onSubmit,
    enableReinitialize: true,
    validationSchema: RolesSectionValidationSchema,
    validate: (values) => {
      const message = shareholderListValidation(values.roles);

      setFormWarning(message ? t(message) : "");
    }
  });

  const onEdit = () => {
    setEditMode(true);
  };

  const onView = () => {
    setEditMode(false);
  };

  return (
    <Row data-testid="roles-section">
      <Col>
        <FormikProvider value={formik}>
          <Form data-testid="roles-edit-form">
            <EditableSection
              title={t("nominee-dashboard:company.roles")}
              testIdPrefix="roles-"
              isEditMode={editMode}
              onEdit={onEdit}
              onView={onView}>
              {editMode ? (
                <>
                  <EditRolesTable
                    areVotesDisabled={areVotesDisabled}
                    onFinishEdit={finishEditing}
                    formik={formik}
                    formWarning={formWarning ?? ""}
                  />
                  {extraEditingActions ?? ""}
                </>
              ) : (
                <ViewRolesTable roles={roles} />
              )}
            </EditableSection>
          </Form>
        </FormikProvider>
      </Col>
    </Row>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(RolesSectionOld);
