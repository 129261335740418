import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "../../../uiLibrary/designSystem/styles/Typography/Typography";

interface ChangePaymentMethodPopupProps extends WithTranslation {}

const ChangePaymentMethodPopup = ({ t }: ChangePaymentMethodPopupProps) => {
  return (
    <div data-testid="change-payment-method-popup" className={"change-payment-method-popup"}>
      <Typography category={"Paragraph"} size={100} weight={"Heavy"} data-testid="title">
        {t("generic:change-payment-method.title")}
      </Typography>

      <Typography category={"Paragraph"} size={200} weight={"Light"} data-testid="description">
        <Trans
          i18nKey={"generic:change-payment-method.description"}
          components={{
            EmailLink: (
              <a data-testid="contact-email" href={"mailto:clientservices@ride.capital"}>
                {"clientservices@ride.capital"}
              </a>
            )
          }}
        />
      </Typography>
    </div>
  );
};

export default withTranslationReady(["generic"])(ChangePaymentMethodPopup);
