import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ArrowLeft } from "sharedComponents/icons/DesignSystemIcons/ArrowLeft";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

interface BackButtonProps extends WithTranslation {
  "data-testid"?: string;
  onClick: () => void;
  classNames?: string;
}

const BackButton = withTranslation(["generic"])(
  ({ t, onClick, "data-testid": dataTestId = "back-button", classNames }: BackButtonProps) => {
    return (
      <RideButton
        className={`${classNames ?? ""}`}
        data-testid={dataTestId}
        onClick={onClick}
        label={t("generic:back")}
        variant={RideButtonVariant.GHOST}
        size={RideButtonSize.BIG}
        leftIcon={<ArrowLeft />}
      />
    );
  }
);

export default BackButton;
