import gql from "graphql-tag";

export const CHANGE_PASSWORD_AND_LOGIN = gql`
  mutation ChangePasswordAndLogin($resetToken: ID!, $password: String!) {
    changePasswordAndLogin(resetToken: $resetToken, password: $password) {
      feedback
      token
    }
  }
`;
