import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "components/generic/RequestFeedbackHandler";
import { AuthAlertMessage } from "../shared/AuthAlertMessage/AuthSuccessMessage";
import { convertErrorCode } from "lib/converters";
import RidePassword from "../../../sharedComponents/FormControls/RidePassword/RidePassword";
import { FormikProvider, useFormik } from "formik";
import { passwordSchemaWithTranslation } from "../../../lib/validation/LoginSchema";
import { InputState } from "../../../sharedComponents/FormControls/RideInput/RideInput";
import RequestFeedbackForm from "../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { Panel } from "../../../sharedComponents/Panel/Panel";
import { AuthLayout } from "../../../sharedComponents/templates/AuthLayout/AuthLayout";

interface EmailUpdateConfirmationProps extends WithTranslation {
  onSubmit: (password: string) => Promise<void>;
  onGoToHomePage: () => Promise<void>;
  tokenValidationRequest: MutationResult | QueryResult | RequestFeedbackInfo;
  passwordValidationRequest: MutationResult | QueryResult | RequestFeedbackInfo;
  newEmail: String;
}

export const EmailUpdateConfirmation = ({
  tokenValidationRequest,
  passwordValidationRequest,
  onSubmit,
  onGoToHomePage,
  newEmail,
  t
}: EmailUpdateConfirmationProps) => {
  const formik = useFormik({
    initialValues: {
      password: ""
    },
    onSubmit: (values) => {
      onSubmit(values["password"]);
    },
    validateOnBlur: true,
    validationSchema: passwordSchemaWithTranslation(t)
  });

  if (tokenValidationRequest.loading) {
    return null;
  }

  const error = tokenValidationRequest.error?.message;

  if (error) {
    return (
      <div className="email-update-confirmation">
        <AuthLayout>
          <AuthAlertMessage
            message={t(convertErrorCode(error))}
            submitText={t("auth:email-update-confirmation.signIn")}
            data-testid="email-update-error"
            onSubmit={onGoToHomePage}
          />
        </AuthLayout>
      </div>
    );
  }

  const state = passwordValidationRequest.error ? InputState.ERROR : InputState.DEFAULT;
  const isLoading = false;

  return (
    <div className="email-update-confirmation">
      <AuthLayout
        title={t("auth:email-update-confirmation.title")}
        subtitle={t("auth:email-update-confirmation.subtitle", { newEmail })}>
        <Panel>
          <FormikProvider value={formik}>
            <RequestFeedbackForm
              submitLabel={t("auth:email-update-confirmation.submit")}
              requests={[passwordValidationRequest]}>
              <RidePassword
                formik={formik}
                name="password"
                state={state}
                isDisabled={isLoading}
                label={t("auth:generic.password")}
              />

              <div className="email-update-confirmation__reset-link">
                <TextLink
                  variant={LinkVariant.secondary}
                  to="/lost-password"
                  data-testid="reset-link">
                  {t("auth:login.forgot-password-link")}
                </TextLink>
              </div>
            </RequestFeedbackForm>
          </FormikProvider>
        </Panel>
      </AuthLayout>
    </div>
  );
};

export default withTranslationReady(["auth", "generic"])(EmailUpdateConfirmation);
