import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import React, { useImperativeHandle } from "react";
import { WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { deepClone } from "common/deepClone";
import { StepComponentProps } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import CitizenshipInformation from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/CitizenshipInformation/CitizenshipInformation";

const RepresentativeCitizenshipSchema = (t) => {
  const validDateDDMMYYYY = /^\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$/;

  return Yup.object().shape({
    gender: Yup.string().required(t("generic:validation-required")),
    birthDate: Yup.string()
      .matches(validDateDDMMYYYY, { message: "please enter a valid date" })
      .typeError("please enter a valid date")
      .required(t("generic:validation-required")),
    citizenship: Yup.string().required(t("generic:validation-required")),
    birthCity: Yup.string().required(t("generic:validation-required")),
    notUSCitizenOrTaxResident: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};

const CompanyRepresentativeCitizenship = ({
  t,
  order,
  saveData,
  controls,
  currentStep,
  confirmCancelModalRef
}: WithTranslation & StepComponentProps) => {
  const shareholderIndex = currentStep?.chapter?.index ?? 0;
  let shareholdersCopy = order.extra.shareholders ? deepClone(order.extra.shareholders) : {};
  const shareholder = shareholdersCopy[shareholderIndex] ?? {};
  const representativeCitizenship = shareholder.representativeCitizenship ?? {};

  const initialValues = {
    gender: representativeCitizenship.gender ?? "",
    birthDate: representativeCitizenship.birthDate ?? "",
    citizenship: representativeCitizenship.citizenship ?? "DE",
    birthCity: representativeCitizenship.birthCity ?? "",
    notUSCitizenOrTaxResident: representativeCitizenship.notUSCitizenOrTaxResident ?? false
  };

  useImperativeHandle(confirmCancelModalRef, () => ({
    async trigger() {
      await formik.setFieldValue("shouldReturnToParentChapter", true);
      await formik.submitForm();
    }
  }));

  const onSubmit = async (values) => {
    currentStep.complete();

    const { shouldReturnToParentChapter, ...data } = values;
    shareholder.representativeCitizenship = data;

    await saveData(
      {
        ...order.extra,
        shareholders: shareholdersCopy,
        ...currentStep.chapter.order.serialize()
      },
      shouldReturnToParentChapter ?? false
    );
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: RepresentativeCitizenshipSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form">
        <div className="company-representative-citizenship-form subchapter-order-layout__scrollable">
          <CitizenshipInformation
            formik={formik}
            prefix={`${currentStep?.disposition}`}
            title={t("shareholder-details:citizenship-information.company-title")}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic", "shareholder-details"])(
  CompanyRepresentativeCitizenship
);
