import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export interface HeaderSectionProps {
  title: string;
  viewName: string;
}

export const HeaderSection = ({ viewName, title }: HeaderSectionProps) => (
  <Container fluid>
    <Row className={`${viewName}__header`}>
      <Col className="px-0">
        <Container className="px-md-0">
          <Row>
            <Col>
              <h1 data-testid={`${viewName}-title`}>{title}</h1>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  </Container>
);
