import React from "react";
import { FormCheck } from "react-bootstrap";

export interface checkBoxProps {
  name?: string;
  value?: any;
  label: string | JSX.Element;
  required?: boolean;
  readOnly?: boolean;
  handleClick: any;
  state: boolean;
  disabled?: boolean;
  customLabelClass?: string;
}

export const CheckboxField = ({
  name,
  value,
  readOnly,
  required,
  label,
  state,
  handleClick,
  disabled,
  customLabelClass = "custom-checkbox"
}: checkBoxProps) => (
  <label className={`custom-control ${customLabelClass}`} data-testid={`${name}-checkbox`}>
    <FormCheck.Input
      readOnly={readOnly}
      required={required}
      data-testid={name}
      name={name}
      value={value}
      className="custom-control-input"
      type="checkbox"
      checked={state}
      onClick={handleClick}
      disabled={disabled}
    />
    <span className="custom-control-label pl-3" data-testid={`${name}-label`}>
      {label}
    </span>
  </label>
);
