import {
  ConfirmationModal,
  ConfirmationModalType
} from "../../../uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { ConfettiAnimation } from "../../../uiLibrary/v2/animations/confetti/ConfettiAnimation";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as queryString from "querystring";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

interface SuccessAnimatedModalProps extends WithTranslation {
  expectedQueryKey: string;
  urlAfterClose: string;
  title: string;
  message: string;
}

export const SuccessAnimatedModal = withTranslationReady(["generic"])(
  ({ t, expectedQueryKey, urlAfterClose, title, message }: SuccessAnimatedModalProps) => {
    let { search } = useLocation();
    if (search.startsWith("?")) search = search.substring(1);

    const query = queryString.parse(search);
    const initialOrderCompletedVisible = expectedQueryKey in query;
    const [orderCompletedVisible, setOrderCompletedVisible] = useState(
      initialOrderCompletedVisible
    );

    const { push } = useHistory();

    return (
      <ConfirmationModal
        dataTestId={"success-animated-modal"}
        title={title}
        message={message}
        type={ConfirmationModalType.SUCCESS}
        confirmLabel={t("generic:done")}
        visible={orderCompletedVisible}
        onConfirm={() => {}}
        onClose={() => {
          setOrderCompletedVisible(false);
          push(urlAfterClose);
        }}
        illustration="successful"
        animation={ConfettiAnimation}
      />
    );
  }
);
