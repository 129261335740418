import { withTranslation, WithTranslation } from "react-i18next";
import React, { useState } from "react";
import { FlowTypeEnum, RolesInGroup } from "../../../../../global-query-types";
import { RadioOptionElement, Radios } from "../../../../generic/Radios";
import { defaultCountryCode } from "../../../../../common/defaultCountry";
import { Form, FormikProvider, useFormik } from "formik";
import { Button, Col, Container, Row } from "react-bootstrap";
import { AddressFields } from "./AddressFields";
import { FaPlus } from "react-icons/fa";
import { CompanyCreationAddressSchema } from "../../../../../lib/validation/CompanyCreationAddressSchema";
import { useMutation } from "react-apollo";
import { UPDATE_COMPANY_ADDRESS } from "../../Mutations";
import {
  UpdateCompanyAddress,
  UpdateCompanyAddressVariables
} from "../../graphql/UpdateCompanyAddress";
import logger from "../../../../../common/Logger";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { MyCompanyCreationQuery_myCompanyCreation } from "../../graphql/MyCompanyCreationQuery";
import { formatCompanyAddress } from "../../../../../common/formatters/formatCompanyAddress";
import { getUniqPeopleAs } from "../../../../../common/getUniqPeopleAs";

interface Props extends WithTranslation {
  companyCreation: MyCompanyCreationQuery_myCompanyCreation | undefined;
  refetchAndResetStep: (id: string) => Promise<void>;
  currentFlowType: FlowTypeEnum;
}

const AddressForm = ({ t, companyCreation, refetchAndResetStep, currentFlowType }: Props) => {
  const [updateCompanyAddress, { error, loading }] = useMutation<
    UpdateCompanyAddress,
    UpdateCompanyAddressVariables
  >(UPDATE_COMPANY_ADDRESS);

  let initialSelectedIndex = 0;

  const memberships = companyCreation?.company?.group?.memberships ?? [];
  const shareholders = getUniqPeopleAs(memberships, [
    RolesInGroup.Shareholder,
    RolesInGroup.Director
  ]);

  const address = companyCreation?.company?.businessAddress;

  const currentAddress = formatCompanyAddress({
    t,
    name: companyCreation?.company?.name,
    careOf: address?.careOf,
    street: address?.street,
    postalCode: address?.postalCode,
    city: address?.city,
    countryCode: address?.country
  });

  const companyName = companyCreation?.company?.name;

  const isSomeAddressPresent = !!(
    address?.careOf ||
    address?.street ||
    address?.postalCode ||
    address?.city
  );

  const presetOptions: RadioOptionElement[] = shareholders
    .map((shareholder) => shareholder.person)
    .filter((person) => person)
    .map((person) => {
      const address = person!["address"];
      const fullName = `${person!.firstName} ${person!.lastName}`;

      return {
        label: formatCompanyAddress({
          t,
          name: companyCreation?.company?.name,
          careOf: fullName,
          street: address?.street,
          postalCode: address?.postalCode,
          city: address?.city,
          countryCode: address?.country
        })
      };
    });

  const customAddressContainsShareholderAddress = presetOptions
    .map((o) => o.label)
    .includes(currentAddress);

  const matchingShareholderAddressIndex = presetOptions.map((o) => o.label).indexOf(currentAddress);

  const handleAddressChange = (event) => {
    setSelectedIndex(+event.target["value"]);
  };

  const handleForm = (event) => {
    event.preventDefault();
    formik.setFieldValue("careOf", "");
    formik.setFieldValue("street", "");
    formik.setFieldValue("postalCode", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("country", defaultCountryCode);

    setSelectedIndex(shareholders.length);
  };

  const showForm = isSomeAddressPresent && !customAddressContainsShareholderAddress;

  if (matchingShareholderAddressIndex >= 0) {
    initialSelectedIndex = matchingShareholderAddressIndex;
  } else if (showForm) {
    initialSelectedIndex = shareholders.length;
    presetOptions.push({
      label: t("trader-gmbh:address-below")
    });
  }

  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);
  const customAddressIndex = selectedIndex >= shareholders.length;

  if (selectedIndex < shareholders.length) {
    presetOptions.splice(shareholders.length, 1);
  }

  if (customAddressIndex && !showForm) {
    presetOptions.push({
      label: t("trader-gmbh:address-below")
    });
  }

  const stepSixInitialValues = {
    careOf: address?.careOf ?? "",
    street: address?.street ?? "",
    postalCode: address?.postalCode ?? "",
    city: address?.city ?? "",
    country: address?.country ?? defaultCountryCode
  };

  const submitHandler = async (values) => {
    try {
      await updateCompanyAddress({
        variables: {
          companyCreationId: companyCreation?.id ?? "",
          businessAddress: values
        }
      });

      await refetchAndResetStep(companyCreation?.id ?? "");
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to update business address:`);
      logger.error(error);
    }
  };

  const handleSubmitHandler = async () => {
    const selectedCustomAddressOption = selectedIndex >= shareholders.length;
    if (selectedCustomAddressOption) {
      formik.handleSubmit();
      return;
    }

    const { person } = shareholders[selectedIndex];
    let address;
    if (person && person["address"]) {
      address = person["address"];
    }
    const fullName = `${person?.firstName} ${person?.lastName}`;

    const selectedAddressInfo = {
      careOf: fullName,
      street: address?.street,
      postalCode: address?.postalCode,
      city: address?.city,
      country: address?.country
    };

    await submitHandler(selectedAddressInfo);
  };

  const formik = useFormik({
    initialValues: stepSixInitialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: CompanyCreationAddressSchema,
    onSubmit: submitHandler
  });

  return (
    <div>
      <FormikProvider value={formik}>
        <Row>
          <Col className="steps-form-description">{t("trader-gmbh:address-form-description")}</Col>
        </Row>
        <div className="step-bg mt-md-45 px-md-5 pb-md-45 mt-0 px-0 pb-0">
          <Container>
            <Row>
              <Col className="mt-md-45 mt-0">
                <Radios
                  name="addressChoice"
                  selectedIndex={selectedIndex}
                  type="radio"
                  options={presetOptions}
                  handleChange={handleAddressChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={"12"} md={"6"} className="mt-4">
                <Button
                  variant="outline-light"
                  className="new-additional-btn"
                  block
                  hidden={customAddressIndex}
                  data-testid="other-address"
                  onClick={handleForm}>
                  <FaPlus /> {t("trader-gmbh:new-address")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <RenderForm
                  t={t}
                  formik={formik}
                  customAddressIndex={customAddressIndex}
                  companyName={companyName}
                />
              </Col>
            </Row>
            {loading || error?.message ? (
              <Row className="mt-3">
                <Col>
                  <LoadingAlert t={t} loading={loading} />
                  <ErrorAlertLegacy t={t} error={error?.message} />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row className="mt-3">
              <Col>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={handleSubmitHandler}
                    data-testid="submit"
                    className="step-submit-btn"
                    variant="outline-light">
                    {t("generic:save")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </FormikProvider>
    </div>
  );
};

const RenderForm = ({ t, formik, companyName, customAddressIndex }) => (
  <>
    <Form>
      {customAddressIndex && (
        <div data-testid="address-form">
          <Row className="mt-1">
            <Col>
              <div data-testid={`company-name`}>{companyName}</div>
            </Col>
          </Row>
          <AddressFields t={t} formik={formik} />
        </div>
      )}
    </Form>
  </>
);

export default withTranslation(["trader-gmbh", "generic"])(AddressForm);
