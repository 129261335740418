import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import {
  RadioSelectVariant,
  RideRadioSelect
} from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import * as Yup from "yup";

interface ConfirmRideTaxAdvisorSwitchProps {}

const SwitchToRideTaxAdvisorSchema = (t) => {
  return Yup.object().shape({
    switchToRideTaxAdvisor: Yup.string().required(t("generic:validation-required"))
  });
};

const ConfirmRideTaxAdvisorSwitch = ({
  t,
  currentStep,
  order,
  saveData,
  controls
}: ConfirmRideTaxAdvisorSwitchProps & StepComponentProps & WithTranslation) => {
  const onSubmit = async (values) => {
    currentStep.complete();

    if (!switchToRideTaxAdvisor(values)) {
      const contactDetailsChapter = currentStep.chapter.order.getChapter("contact-details");
      const yourContactsStep = contactDetailsChapter?.getStepByKey("client-contact-details");
      contactDetailsChapter!.replaceSubsequentSteps(yourContactsStep!, [
        { key: "tax-advisor-contact-details", completed: false }
      ]);
    }

    await saveData({
      ...order.extra,
      switchToRideTaxAdvisor: switchToRideTaxAdvisor(values),
      ...currentStep.chapter.order.serialize()
    });
  };

  const switchToRideTaxAdvisor = (values) => {
    return values.switchToRideTaxAdvisor === "yes";
  };

  const valueMapping = {
    true: "yes",
    false: "no"
  };

  const formik = useRideFormik({
    initialValues: {
      switchToRideTaxAdvisor: valueMapping[order.extra.switchToRideTaxAdvisor] ?? ""
    },
    onSubmit,
    validationSchema: SwitchToRideTaxAdvisorSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading
          text={t("generic:order-company-foundation.steps.confirm-ride-tax-advisor-switch.title")}
        />
        <div data-testid="confirm-ride-tax-advisor-switch">
          <RideRadioSelect
            options={[
              {
                value: "yes",
                label: t(
                  "generic:order-company-foundation.steps.confirm-ride-tax-advisor-switch.switch-to-ride-tax-advisor"
                )
              },
              {
                value: "no",
                label: t(
                  "generic:order-company-foundation.steps.confirm-ride-tax-advisor-switch.keep-personal-tax-advisor"
                )
              }
            ]}
            name="switchToRideTaxAdvisor"
            variant={RadioSelectVariant.Heavy}
            columns={1}
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ConfirmRideTaxAdvisorSwitch);
