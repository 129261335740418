import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { TFunction } from "i18next";
import { disabledTransformation } from "../../common/disabledTransformation";

interface ErrorComponentProps {
  t: TFunction;
  name: string;
  extra: { [key: string]: any };
}

interface TextInputProps {
  t: TFunction;
  name: string;
  label?: string | any;
  type: string;
  value: string | number;
  placeholder?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  error?: string;
  errorComponent?: React.FC<ErrorComponentProps>;
  formText?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  suffix?: string;
  className?: any;
  formGroupClass?: string;
  extra?: { [key: string]: any };
}

export default class TextInput extends React.Component<TextInputProps> {
  render() {
    const {
      t,
      type,
      name,
      label,
      placeholder,
      onChange,
      onBlur,
      isValid,
      isInvalid,
      error,
      formText,
      readOnly,
      disabled,
      value,
      required,
      suffix,
      className,
      formGroupClass,
      errorComponent,
      extra
    } = this.props;

    const ErrorComponent =
      errorComponent ??
      (({ t, name }: ErrorComponentProps) => (
        <Form.Control.Feedback type="invalid" data-testid={`${name}-invalid-feedback`}>
          {t(error ?? "")}
        </Form.Control.Feedback>
      ));

    return (
      <Form.Group controlId={name} className={formGroupClass}>
        {label ? (
          <Form.Label style={extra?.labelStyle ?? {}}>
            {typeof label === "string" ? t(label ?? "") : label}
            {required ? "*" : ""}
          </Form.Label>
        ) : (
          ""
        )}
        <InputGroup className={isInvalid ? "invalid-text-input is-invalid" : ""}>
          <Form.Control
            className={className}
            data-testid={`${name}-input`}
            name={name}
            type={type}
            placeholder={t(placeholder ?? "")}
            onChange={disabledTransformation(disabled, onChange)}
            onBlur={onBlur}
            isValid={isValid}
            isInvalid={isInvalid}
            readOnly={readOnly}
            disabled={disabled}
            value={String(value)}
          />
          {suffix ? (
            <InputGroup.Append>
              <InputGroup.Text>{suffix}</InputGroup.Text>
            </InputGroup.Append>
          ) : (
            ""
          )}
        </InputGroup>
        <ErrorComponent t={t} name={name} extra={extra ?? {}} />
        {formText ? <Form.Text className="text-muted">{t(formText)}</Form.Text> : null}
      </Form.Group>
    );
  }
}
