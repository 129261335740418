import React from "react";
import { WithTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { BrokerageAccountValidityFilter } from "global-query-types";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ClientSelect } from "client/components/ClientSelect/ClientSelect";

export interface TaxAdvisorsListFilterFields {
  brokerageAccountValidity?: BrokerageAccountValidityFilter;
}

interface TaxAdvisorsListFiltersProps extends WithTranslation {
  onFilter: (filters: TaxAdvisorsListFilterFields) => void;
}

const TaxAdvisorsListFilters = ({ t, onFilter }: TaxAdvisorsListFiltersProps) => {
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {}
  });

  const onChange = (validity: string) => {
    onFilter({
      brokerageAccountValidity: (validity as BrokerageAccountValidityFilter) || undefined
    });
    formik.setFieldValue("brokerage-account-validity", validity);
  };

  return (
    <div className="tax-advisors-list-filter__container" data-testid="tax-advisors-list-filters">
      <FormikProvider value={formik}>
        <ClientSelect
          label={t("nominee-dashboard:tax-advisors-list.filters.brokerage-account-validity")}
          name="brokerage-account-validity"
          options={Object.keys(BrokerageAccountValidityFilter).map((validity) => ({
            label: t(`generic:brokerage-account-validity.${validity}`),
            value: validity
          }))}
          changeHandler={onChange}
          isSearchable
          isClearable
          className="tax-advisors-list-filter"
        />
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(TaxAdvisorsListFilters);
