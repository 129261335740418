export const asyncDebounce = <T>(
  fn: (...args: any) => Promise<T>,
  wait
): ((...args: any) => Promise<T>) => {
  let timer;

  return (...args: any): Promise<T> => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(async () => {
        const result = await fn(...args);
        resolve(result);
      }, wait);
    });
  };
};
