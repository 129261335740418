import React, { useState } from "react";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import LowTaxBrokerOrdersList from "./LowTaxBrokerOrdersList";
import { GetLowTaxBrokerOrders } from "lib/api/queries/GetLowTaxBrokerOrders";
import { RidePagination } from "uiLibrary/designSystem/RidePagination/RidePagination";
import LowTaxBrokerOrdersFilters, {
  LowTaxBrokerOrdersFiltersData
} from "admin/components/LowTaxBrokerOrdersList/LowTaxBrokerOrdersFilters/LowTaxBrokerOrdersFilters";

const LowTaxBrokerOrdersListContainer = () => {
  const pageSize = 6000;
  const [start, setStart] = useState(0);
  const [filters, setFilters] = useState<LowTaxBrokerOrdersFiltersData>({});

  const request = GetLowTaxBrokerOrders({
    start,
    limit: pageSize,
    ...filters
  });
  const ordersWithCompany = request.data?.getLowTaxBrokerOrders?.items;

  return (
    <>
      <LowTaxBrokerOrdersFilters
        onFilterChange={(filters) => {
          setStart(0);
          setFilters(filters);
        }}
      />
      <RidePagination
        count={request.data?.getLowTaxBrokerOrders?.count ?? 0}
        start={start}
        pageSize={pageSize}
        setStart={setStart}>
        <RequestFeedbackHandler requests={[request]}>
          <LowTaxBrokerOrdersList ordersWithCompany={ordersWithCompany ?? []} />
        </RequestFeedbackHandler>
      </RidePagination>
    </>
  );
};

export default LowTaxBrokerOrdersListContainer;
