import React, { PropsWithChildren } from "react";
import { CardContainer } from "uiLibrary/designSystem/styles/CardContainer/CardContainer";

interface ReviewCardProps extends PropsWithChildren<{ className?: string }> {
  dataTestId?: string;
}

export const ReviewCard = ({ children, className, dataTestId }: ReviewCardProps) => {
  const testId = dataTestId ? `review-card-${dataTestId}` : "review-card";
  return (
    <CardContainer data-testid={testId} className={`review-card ${className ?? ""}`}>
      {children}
    </CardContainer>
  );
};
