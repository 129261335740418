import React from "react";
import { WithTranslation } from "react-i18next";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { CLUB_DEALS } from "lib/api/queries/ClubDeals";
import { useQuery } from "@apollo/react-hooks";
import AdminClubDealList from "./AdminClubDealList";
import { REAL_ESTATES } from "../../../lib/api/queries/RealEstates";
import { useMutation } from "react-apollo";
import { CREATE_CLUB_DEAL } from "../../../lib/api/mutations/CREATE_CLUB_DEAL";
import {
  CreateClubDeal,
  CreateClubDealVariables
} from "../../../lib/api/mutations/graphql/CreateClubDeal";
import { UPDATE_CLUB_DEAL } from "../../../lib/api/mutations/UPDATE_CLUB_DEAL";
import {
  UpdateClubDeal,
  UpdateClubDealVariables
} from "../../../lib/api/mutations/graphql/UpdateClubDeal";

export const AdminClubDealListContainer = (props: WithTranslation) => {
  const clubDealsRequest = useQuery(CLUB_DEALS);
  const realEstatesRequest = useQuery(REAL_ESTATES);
  const [createClubDealMutation, createClubDealMutationRequest] = useMutation<
    CreateClubDeal,
    CreateClubDealVariables
  >(CREATE_CLUB_DEAL);
  const [updateClubDealMutation, updateClubDealMutationRequest] = useMutation<
    UpdateClubDeal,
    UpdateClubDealVariables
  >(UPDATE_CLUB_DEAL);

  const clubDeals = clubDealsRequest.data?.clubDeals ?? [];
  const realEstateList = realEstatesRequest.data?.realEstates ?? [];

  const handleAddClubDeal = async (variables) => {
    await createClubDealMutation({
      variables: {
        realEstateId: variables.realEstate,
        targetYield: parseFloat(variables.targetYield),
        maxInvestmentAmount: parseFloat(variables.maxInvestmentAmount),
        minInvestmentAmount: parseFloat(variables.minInvestmentAmount),
        financedPercentage: parseFloat(variables.financedPercentage)
      }
    });
    await clubDealsRequest.refetch();
  };

  const handleEditClubDeal = async (id, variables) => {
    await updateClubDealMutation({
      variables: {
        id,
        realEstateId: variables.realEstate,
        targetYield: parseFloat(variables.targetYield),
        maxInvestmentAmount: parseFloat(variables.maxInvestmentAmount),
        minInvestmentAmount: parseFloat(variables.minInvestmentAmount),
        financedPercentage: parseFloat(variables.financedPercentage)
      }
    });
    await clubDealsRequest.refetch();
  };

  return (
    <RequestFeedbackHandler requests={[clubDealsRequest, realEstatesRequest]}>
      <AdminClubDealList
        onAddClubDeal={handleAddClubDeal}
        onEditClubDeal={handleEditClubDeal}
        addRequest={createClubDealMutationRequest}
        editRequest={updateClubDealMutationRequest}
        realEstateList={realEstateList}
        clubDeals={clubDeals}
      />
    </RequestFeedbackHandler>
  );
};
