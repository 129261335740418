import React, { useState } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import ProductCard, { ProductCardCheckboxInput } from "client/components/ProductCard/ProductCard";
import { LowTaxBrokerIcon } from "sharedComponents/icons/DesignSystemIcons/LowTaxBrokerIcon";
import ProductLearnMoreModal, {
  ProductFeatureProps
} from "client/components/ProductLearnMoreModal/ProductLearnMoreModal";
import { GlobalIcon } from "sharedComponents/icons/DesignSystemIcons/GlobalIcon";
import { WepaIcon } from "sharedComponents/icons/DesignSystemIcons/WepaIcon";
import { Field } from "formik";
import { useRideField } from "lib/hooks/useRideField";
import { FeatureFlags } from "../../../../../global-query-types";
import { FeatureFlagService } from "../../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { UmbrellaIcon } from "../../../../../sharedComponents/icons/DesignSystemIcons/UmbrellaIcon";

export interface LTBProductCardProps extends WithTranslation {
  inputName: string;
  disabled?: boolean;
}

const LTBProductCard = ({ t, inputName, disabled }: LTBProductCardProps) => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);

  const field = useRideField(inputName);
  const removeDepositEnsuranceFeature = FeatureFlagService.instance.isEnabled(
    FeatureFlags.NewBroker
  );

  const features: ProductFeatureProps[] = [
    {
      icon: LowTaxBrokerIcon,
      tagline: t("generic:product-card.low-tax-broker.learn-more-modal.feature-1-title"),
      description: t("generic:product-card.low-tax-broker.learn-more-modal.feature-1-description")
    },
    {
      icon: UmbrellaIcon,
      tagline: t("generic:product-card.low-tax-broker.learn-more-modal.feature-2-title"),
      description: t("generic:product-card.low-tax-broker.learn-more-modal.feature-2-description")
    },
    {
      icon: GlobalIcon,
      tagline: t("generic:product-card.low-tax-broker.learn-more-modal.feature-3-title"),
      description: t("generic:product-card.low-tax-broker.learn-more-modal.feature-3-description")
    },
    {
      icon: WepaIcon,
      tagline: t("generic:product-card.low-tax-broker.learn-more-modal.feature-4-title"),
      description: t("generic:product-card.low-tax-broker.learn-more-modal.feature-4-description")
    }
  ];

  if (removeDepositEnsuranceFeature) {
    features.splice(1, 1);
  }

  const onClose = () => {
    setShowLearnMoreModal(false);
  };

  return (
    <>
      {showLearnMoreModal && (
        <ProductLearnMoreModal
          productName={t(rideBrokerT("generic:product-card.low-tax-broker.title"))}
          description={
            <Trans
              i18nKey={rideBrokerT(
                "generic:product-card.low-tax-broker.learn-more-modal.description"
              )}
            />
          }
          features={features}
          price={t("generic:product-card.low-tax-broker.price")}
          onClose={onClose}
          onSelectProduct={() => {
            onClose();
            field.setValue(true);
          }}
        />
      )}
      <ProductCard
        dataTestId={"low-tax-broker"}
        title={t(rideBrokerT("generic:product-card.low-tax-broker.title"))}
        description={t("generic:product-card.low-tax-broker.description")}
        icon={<LowTaxBrokerIcon size={32} />}
        price={t("generic:product-card.low-tax-broker.price")}
        input={
          <Field
            type="checkbox"
            as={ProductCardCheckboxInput}
            data-testid={"low-tax-broker-checkbox"}
            name={inputName}
            disabled={disabled}
          />
        }
        handleLearnMore={() => setShowLearnMoreModal(true)}
        tooltipText={
          !field.value && disabled ? t("generic:product-card.low-tax-broker.tooltip") : undefined
        }
      />
    </>
  );
};

export default withTranslationReady(["generic"])(LTBProductCard);
