import React from "react";
import SetPasswordForm from "../../SetPasswordForm/SetPasswordForm";
import { Panel } from "sharedComponents/Panel/Panel";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RequestFeedbackInfo } from "components/generic/RequestFeedbackHandler";
import { MutationResult, QueryResult } from "react-apollo";

interface ResetPasswordFormProps extends WithTranslation {
  onSubmit: (password: string) => Promise<string | null | void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
}

const ResetPasswordForm = ({ onSubmit, ...props }: ResetPasswordFormProps) => {
  return (
    <div className="reset-password-form">
      <Panel>
        <SetPasswordForm {...props} onSubmit={onSubmit} />
      </Panel>
    </div>
  );
};

export default withTranslationReady(["auth"])(ResetPasswordForm);
