import { ClubDealCardProps } from "client/components/investments/ClubDealCard/ClubDealCard";
import { clubDeals_clubDeals } from "lib/api/queries/graphql/clubDeals";

export function toClubDealCardProps(clubDeal: clubDeals_clubDeals): ClubDealCardProps {
  const membership = clubDeal.asset?.realEstate?.owner.memberships?.find(
    (membership) => membership.company?.status
  );

  return {
    id: clubDeal.id,
    name: clubDeal.asset?.realEstate?.label ?? "",
    minInvestment: clubDeal.minInvestmentAmount ?? 0,
    maxInvestment: clubDeal.maxInvestmentAmount ?? 0,
    targetReturn: clubDeal.targetYield ?? 0,
    alreadyFinanced: clubDeal.financedPercentage ?? 0,
    image: clubDeal.asset?.realEstate?.image ?? "",
    status: membership?.company?.status
  };
}
