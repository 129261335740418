import React from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";

const TasksScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col>
          <h2>{t("header:tasks")}</h2>
        </Col>
      </Row>
      <div>This will be the place for all actionable tasks.</div>
    </>
  );
};

export default withTranslation("header")(TasksScene);
