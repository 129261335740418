import {
  syncCompanyCreationToHubspot,
  syncCompanyCreationToHubspotVariables
} from "lib/api/mutations/graphql/syncCompanyCreationToHubspot";
import { SYNC_COMPANY_CREATION_TO_HUBSPOT } from "lib/api/mutations/SYNC_COMPANY_CREATION_TO_HUBSPOT";
import { NomineeCompanyDetails_companyById } from "lib/api/queries/graphql/NomineeCompanyDetails";
import React from "react";
import { useMutation } from "react-apollo";
import HubspotIntegrationSection from "./HubspotIntegrationSection";

interface HubspotIntegrationSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
}

export const HubspotIntegrationSectionContainer = ({
  company
}: HubspotIntegrationSectionContainerProps) => {
  const [syncCompanyCreationToHubspotMutation] = useMutation<
    syncCompanyCreationToHubspot,
    syncCompanyCreationToHubspotVariables
  >(SYNC_COMPANY_CREATION_TO_HUBSPOT);

  const syncCompanyInHubspot = async (variables: syncCompanyCreationToHubspotVariables) => {
    await syncCompanyCreationToHubspotMutation({ variables });
  };

  return (
    <HubspotIntegrationSection company={company} syncCompanyInHubspot={syncCompanyInHubspot} />
  );
};
