import React from "react";
import { Col, Row } from "react-bootstrap";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";
import RidePercentageInput from "uiLibrary/components/RidePercentageInput/RidePercentageInput";

interface PercentageInputCardProps {
  header?: string;
  label: string;
  name: string;
  placeholder?: string;
}

const PercentageInputCard = ({
  header,
  label,
  name,
  placeholder = "0"
}: PercentageInputCardProps) => {
  return (
    <div className={"percentage-input-card"} data-testid={`${name}-percentage-input-card`}>
      <Row>
        <Col>
          {header && <div className={"percentage-input-card__type"}>{header}</div>}
          <div className={"percentage-input-card__name"}>{label}</div>
        </Col>
        <Col xs={"auto"}>
          <RidePercentageInput name={name} placeholder={placeholder} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ErrorMessageWithT name={name} extra={{}} />
        </Col>
      </Row>
    </div>
  );
};

export default PercentageInputCard;
