import React from "react";
import { RideCheckbox } from "sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import FolderSelectField, { FolderSelectOption } from "../FolderSelectField/FolderSelectField";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import FormField from "../../../../components/generic/FormField";
import { fixedOptionsForAdminService } from "../UploadModal/fixedOptionsForAdminService";

export interface FilePlacementProps {
  isSelected: boolean;
  name: string;
  destinationFolderValue: string;
}

export interface UploadFileListProps extends WithTranslation {
  files: FilePlacementProps[];
  options: FolderSelectOption[];
  validationErrors: (string | undefined)[];
  onFileSelect: (fileName: string, selected: boolean) => void;
  onFolderSelect: (index: number, folder?: FolderSelectOption) => void;
  "data-testid"?: string;
  isDisabled?: boolean;
}

const UploadFileList = ({
  files,
  options,
  validationErrors,
  onFileSelect,
  onFolderSelect,
  "data-testid": testId,
  isDisabled,
  t
}: UploadFileListProps) => (
  <div className="upload-file-list" data-testid={testId}>
    {files.map((file, index) => {
      const selectedFolderValue = options.find((o) => o.value === file.destinationFolderValue);

      return (
        <div key={index} className="upload-file-list__upload-file">
          <RideCheckbox
            className="upload-file-list__checkbox"
            data-testid="file-checkbox"
            name={file.name}
            label={
              <span data-testid="file-name" className="upload-file-list__file-name">
                {file.name}
              </span>
            }
            checked={file.isSelected}
            onChange={(value) => onFileSelect(file.name, value)}
          />
          <FormField
            name={`folder-${index}`}
            error={validationErrors[index]}
            testid={`folder-${index}-select-container`}
            className={`upload-file-list__folder-select-wrapper ${isDisabled ? "disabled" : ""}`}
            t={t}>
            <FolderSelectField
              name={`folder-${index}`}
              selectedOption={selectedFolderValue}
              isDisabled={isDisabled}
              className={`upload-file-list__folder-select ${
                validationErrors[index] ? "is-invalid" : ""
              }`}
              placeholder={t("client:documents.modal.placeholder")}
              options={options}
              onChange={(value) => {
                onFolderSelect(index, value);
              }}
              fixedOptions={fixedOptionsForAdminService}
            />
          </FormField>
        </div>
      );
    })}
  </div>
);

export default withTranslationReady(["client"])(UploadFileList);
