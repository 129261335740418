import React, { PropsWithChildren } from "react";
import { Col, Row } from "react-bootstrap";
import { TFunction } from "i18next";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import { MutationResult, QueryResult } from "react-apollo";
import { RideButtonDeprecated } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export type DocumentsSectionProps = PropsWithChildren<{
  rootNodeId: string | undefined;
  createStorageStructure: () => Promise<void>;
  createAdminServiceFolderStructure?: () => Promise<void>;
  updateStorageStructure?: () => Promise<void>;
  externalStorageNodes: { [key: string]: string | undefined };
  refetch: () => Promise<void>;
  requests: (MutationResult | QueryResult)[];
  t: TFunction;
}>;

export const DocumentsSectionAdminOperations: React.FC<DocumentsSectionProps> = ({
  rootNodeId,
  createStorageStructure,
  createAdminServiceFolderStructure,
  updateStorageStructure,
  externalStorageNodes,
  refetch,
  requests,
  t,
  children
}: DocumentsSectionProps) => {
  const needBasicStructure = externalStorageNodes._basicStructure !== "CREATED";
  const needAdminServiceStructure =
    externalStorageNodes._adminServiceStructure !== "CREATED" && createAdminServiceFolderStructure;
  const openURL = rootNodeId && !needBasicStructure && !needAdminServiceStructure;

  const createAllStructure = async () => {
    if (needBasicStructure) {
      await createStorageStructure();
    }

    if (needAdminServiceStructure) {
      await createAdminServiceFolderStructure!();
    }

    await refetch();
  };

  const updateAllStructure = async () => {
    await updateStorageStructure?.();

    await refetch();
  };

  const buttons: React.ReactNode[] = [];

  if (openURL) {
    buttons.push(
      <RideButtonDeprecated
        className="documents-section-admin-operations__button"
        data-testid="go-to-external-storage"
        key="go-to-external-storage"
        href={`https://dracoon.team/node/${rootNodeId}`}
        openExternalUrl>
        {t("nominee-dashboard:company.go-to-external-storage")}
      </RideButtonDeprecated>
    );
  }

  if (openURL && updateStorageStructure) {
    buttons.push(
      <RideButtonDeprecated
        className="documents-section-admin-operations__button"
        data-testid="update-external-storage"
        key="update-external-storage"
        onClick={updateAllStructure}>
        {t("nominee-dashboard:company.update-external-storage")}
      </RideButtonDeprecated>
    );
  }

  if (!openURL) {
    buttons.push(
      <RideButtonDeprecated
        className="documents-section-admin-operations__button"
        data-testid="create-storage-structure"
        key="create-storage-structure"
        onClick={createAllStructure}>
        {t("nominee-dashboard:company.create-storage-structure")}
      </RideButtonDeprecated>
    );
  }

  return (
    <>
      <Row data-testid="documents-section">
        <Col>
          <h2 className="documents-section__title">{t("nominee-dashboard:company.documents")}</h2>
        </Col>
      </Row>

      <Row>
        <Col>
          <RequestFeedbackHandler requests={requests}>{buttons}</RequestFeedbackHandler>

          {rootNodeId && children}
        </Col>
      </Row>
    </>
  );
};
