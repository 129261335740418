import React from "react";
import { StepComponentProps, StepHeading } from "../../../../ChaptersOrderLayout.partials";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import PercentageInputCard from "client/components/ChaptersOrderLayout/sharedComponents/PercentageInputCard/PercentageInputCard";
import { Form, FormikProvider, useFormik } from "formik";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import _, { sum } from "lodash";
import { Shareholder } from "lib/models/client/CompanyFounding/Shareholder";
import {
  DefineSharesSchema,
  MajorityShareSchema,
  MinorityShareSchema,
  ShareSchema
} from "lib/validation/DefineSharesSchema";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

const stepModule: string = "define-shares";

const DefineShares = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps & WithTranslation) => {
  const shareholdersList = toShareholdersArray(order?.extra?.shareholders ?? {});
  const hasMajorityShareholder = order?.extra?.hasMajorityShareholder === "yes";

  async function onSubmit(values) {
    const totalShares = sum(values.shares);

    if (totalShares > 100 || (!hasMajorityShareholder && totalShares !== 100)) {
      return;
    }

    currentStep.complete();

    const shareholdersListCopy = _.cloneDeep(shareholdersList);

    shareholdersListCopy.forEach((shareholder, index) => {
      shareholder.shares = values.shares[index];
    });

    await saveData(
      {
        ...order.extra,
        shareholders: toShareholdersObject(shareholdersListCopy),
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  }

  const shareSchema = order.extra.isNewBrokerEnabled
    ? ShareSchema(t)
    : hasMajorityShareholder
    ? MajorityShareSchema(t)
    : MinorityShareSchema(t);

  const formik = useFormik({
    initialValues: {
      shares: shareholdersList.map((shareholder) => shareholder.shares ?? undefined)
    },
    onSubmit,
    validateOnBlur: true,
    validationSchema: DefineSharesSchema(shareholdersList.length, shareSchema)
  });

  const total = sum(formik.values.shares.map((share) => share ?? 0));

  const considerMiddleName = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddMiddleNameToLTBForm
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.define-shares.title")} />
        <label className={`${stepModule}__context-label`}>
          {t("generic:ride-broker-onboarding.define-shares.share-distribution")}
        </label>
        {shareholdersList.map((shareholder, index) => (
          <PercentageInputCard
            key={index}
            label={personNameWithFallback(shareholder.personData, considerMiddleName)}
            name={`shares[${index}]`}
            placeholder={t(
              `generic:ride-broker-onboarding.define-shares.share-distribution-placeholder`
            )}
          />
        ))}
        {!order.extra.isNewBrokerEnabled && hasMajorityShareholder ? (
          <MajoritySharesInfo total={total} />
        ) : (
          <AllSharesInfo total={total} />
        )}
        {controls}
      </Form>
    </FormikProvider>
  );
};

type ShareholdersObject = {
  [key: number]: Shareholder;
};

const toShareholdersArray = (shareholdersObject: ShareholdersObject): Shareholder[] => {
  let result = [];

  for (const indexKey of Object.keys(shareholdersObject)) {
    result[indexKey] = shareholdersObject[indexKey];
  }

  return result;
};

const toShareholdersObject = (shareholdersArray: Shareholder[]): ShareholdersObject => {
  let result = {};

  shareholdersArray.forEach((shareholder, index) => {
    result[index] = shareholder;
  });

  return result;
};

interface CurrentShareCountSpanProps extends WithTranslation {
  total: number;
}

const MajoritySharesInfo = withTranslationReady(["generic"])(
  ({ t, total }: CurrentShareCountSpanProps) => {
    return (
      <div className={`${stepModule}__current-share-count-span`}>
        <span
          data-testid="current-share-count-span"
          className={`${stepModule}__current-share-count-span__${
            total > 100 || total < 0 ? "error" : "default"
          }`}>
          {`${t("generic:ride-broker-onboarding.define-shares.current-share-count")}: ${total}%`}
        </span>
      </div>
    );
  }
);

const AllSharesInfo = withTranslationReady(["generic"])(
  ({ t, total }: CurrentShareCountSpanProps) => {
    return (
      <div className={`${stepModule}__current-share-count-span`}>
        <span
          data-testid="current-share-count-span"
          className={`${stepModule}__current-share-count-span__${
            total === 100 ? "success" : "error"
          }`}>
          {`${total}% ${t("generic:out-of")} 100%`}
        </span>
      </div>
    );
  }
);

export default withTranslationReady(["generic"])(DefineShares);
