import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { GetAdvisedPerson, GetAdvisedPersonVariables } from "./graphql/GetAdvisedPerson";
import {
  GetMyTaxAdvisorCompanyWithClients,
  GetMyTaxAdvisorCompanyWithClientsVariables
} from "./graphql/GetMyTaxAdvisorCompanyWithClients";
import { GetMyTaxAdvisorCompanyWithTaxAdvisorClients } from "./graphql/GetMyTaxAdvisorCompanyWithTaxAdvisorClients";
import { CompanyType } from "../global-query-types";
import {
  GetMyTaxAdvisorCompany,
  GetMyTaxAdvisorCompanyVariables
} from "./graphql/GetMyTaxAdvisorCompany";

export const GET_MY_TAX_ADVISOR_COMPANY = gql`
  query GetMyTaxAdvisorCompany($companyType: String) {
    getMyTaxAdvisorCompanyWithClients(companyType: $companyType) {
      id
      name
      legalForm
      advisorNumber
      migrationRequiredFor3PTASince
      migrationRequiredFor3PTARealizedAt
    }
  }
`;

export const GetMyTaxAdvisorCompanyQuery = (companyType: CompanyType) =>
  useQuery<GetMyTaxAdvisorCompany, GetMyTaxAdvisorCompanyVariables>(GET_MY_TAX_ADVISOR_COMPANY, {
    variables: { companyType },
    fetchPolicy: "no-cache"
  });

export const GET_MY_TAX_ADVISOR_COMPANY_WITH_CLIENTS = gql`
  query GetMyTaxAdvisorCompanyWithClients($companyType: String) {
    getMyTaxAdvisorCompanyWithClients(companyType: $companyType) {
      id
      name
      legalForm
      advisorNumber
      migrationRequiredFor3PTASince
      migrationRequiredFor3PTARealizedAt
      taxServicesProvided {
        id
        taxAdvisorStatus
        client {
          id
          clientNumber
          thirdPartyTaxAdvisorClientNumber
          fiscalYear
          name
          legalForm
          deletedAt
          brokerageAccounts {
            id
          }
          group {
            type
            memberships {
              role
              person {
                id
                firstName
                lastName
              }
              company {
                id
                name
                legalForm
              }
            }
          }
        }
      }
    }
  }
`;

export const GetMyTaxAdvisorCompanyWithClientsQuery = (companyType: CompanyType) =>
  useQuery<GetMyTaxAdvisorCompanyWithClients, GetMyTaxAdvisorCompanyWithClientsVariables>(
    GET_MY_TAX_ADVISOR_COMPANY_WITH_CLIENTS,
    {
      variables: { companyType },
      fetchPolicy: "no-cache"
    }
  );

const GET_ADVISED_PERSON = gql`
  query GetAdvisedPerson($id: ID!) {
    getAdvisedPerson(id: $id) {
      id
      user {
        id
        email
        confirmedAt
        role
      }

      userWallet {
        id
      }

      phoneNumber
      salutation
      title
      firstName
      lastName
      locked
      isPartner
      address {
        street
        streetLine2
        postalCode
        city
        country
      }

      personalTaxId
      taxOffice
      taxNumber

      dateOfBirth
      citizenship
      placeOfBirth
      birthName
      documentType
      documentNumber
      issuingAuthority

      externalStorageNodes

      memberships {
        id
        role
        person {
          id
          kycEntries {
            id
            status
            company {
              id
            }
          }
        }
        share {
          share
        }
        group {
          type
          company {
            id
            name
            legalForm
            kycEntries {
              id
              status
              company {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GetAdvisedPersonQuery = (id: string) =>
  useQuery<GetAdvisedPerson, GetAdvisedPersonVariables>(GET_ADVISED_PERSON, {
    variables: { id }
  });

const GET_MY_TAX_ADVISOR_COMPANY_WITH_TAX_ADVISOR_CLIENTS = gql`
  query GetMyTaxAdvisorCompanyWithTaxAdvisorClients {
    getMyTaxAdvisorCompanyWithTaxAdvisorClients {
      companies {
        id
        name
        legalForm
        myWepaSubContracting {
          type
          partner {
            name
            id
          }
        }
        taxServicesProvided {
          id
          taxAdvisorStatus
          updatedAt
          client {
            id
            name
            legalForm
            deletedAt
            adminService {
              id
            }
            companyCreation {
              id
            }
            wepaId
            brokerageAccounts {
              id
              brokerName
              validity
              errorCodes
            }
            netLiquidationValues {
              id
              type
              year
              quarter
              tag
              valueCents
              valueCurrency
            }
          }
        }
        contactPerson {
          user {
            taxAdvisorCode
          }
          lastName
          firstName
        }
      }
    }
  }
`;

export const GetMyTaxAdvisorCompanyWithTaxAdvisorClientsQuery = () =>
  useQuery<GetMyTaxAdvisorCompanyWithTaxAdvisorClients>(
    GET_MY_TAX_ADVISOR_COMPANY_WITH_TAX_ADVISOR_CLIENTS
  );
