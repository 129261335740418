import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import {
  MultiSelectOption,
  RideMultiSelect
} from "uiLibrary/components/RideMultiSelect/RideMultiSelect";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { SourcesOfWealthStepSchema } from "lib/validation/SourcesOfWealthStepSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { InvalidFeedback } from "uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import { SourceOfWealthType } from "lib/models/client/LowTaxBroker/SourcesOfWealth";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";
import _ from "lodash";

const SourceOfWealthStep = ({
  t,
  controls,
  currentStep,
  saveData,
  order
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const showBusinessOperationSourceOfWealth = FeatureFlagService.instance.isEnabled(
    FeatureFlags.showBusinessOperationSourceOfWealth
  );

  let sourcesAvailable = Object.values(SourceOfWealthType);

  let initialValues = {
    [SourceOfWealthType.BUSINESS_OPERATION]:
      showBusinessOperationSourceOfWealth &&
      !!order.extra.sourcesOfWealth?.[SourceOfWealthType.BUSINESS_OPERATION],
    [SourceOfWealthType.OWNER_SHAREHOLDER_EQUITY_OR_LOAN_TO_COMPANY]:
      !!order.extra.sourcesOfWealth?.[
        SourceOfWealthType.OWNER_SHAREHOLDER_EQUITY_OR_LOAN_TO_COMPANY
      ],
    [SourceOfWealthType.PROPERTY]: !!order.extra.sourcesOfWealth?.[SourceOfWealthType.PROPERTY],
    [SourceOfWealthType.RETAINED_EARNINGS]:
      !!order.extra.sourcesOfWealth?.[SourceOfWealthType.RETAINED_EARNINGS],
    [SourceOfWealthType.MARKETING_TRADING_PROFITS]:
      !!order.extra.sourcesOfWealth?.[SourceOfWealthType.MARKETING_TRADING_PROFITS],
    [SourceOfWealthType.OTHERS]: !!order.extra.sourcesOfWealth?.[SourceOfWealthType.OTHERS],
    "other-sources-description":
      order.extra.sourcesOfWealth?.[SourceOfWealthType.OTHERS]?.description
  };

  if (!showBusinessOperationSourceOfWealth) {
    sourcesAvailable = _.without(sourcesAvailable, SourceOfWealthType.BUSINESS_OPERATION);
  }

  const onSubmit = async (values) => {
    currentStep.complete();

    let sourcesOfWealth = {};

    Object.values(SourceOfWealthType)
      .filter((name) => values[name])
      .forEach(
        (name) =>
          (sourcesOfWealth[name] = {
            ...order.extra.sourcesOfWealth?.[name],
            ...(name === SourceOfWealthType.OTHERS && {
              description: values["other-sources-description"]
            })
          })
      );

    await saveData(
      {
        ...order.extra,
        sourcesOfWealth,
        ...currentStep.chapter.order.serialize()
      },
      false
    );
  };
  const formik = useRideFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validationSchema: SourcesOfWealthStepSchema(t),
    validate(values) {
      const selected = sourcesAvailable.filter((name) => values[name]);
      if (selected.length === 0) {
        return { error: t("generic:validation-required") };
      }

      return {};
    }
  });

  const options: MultiSelectOption[] = sourcesAvailable.map((value) => ({
    label: t(`generic:ride-broker-onboarding.sources-of-wealth.${value}`),
    name: value
  }));

  return (
    <FormikProvider value={formik}>
      <Form>
        <div data-testid="sources-of-wealth-step" className="sources-of-wealth-step">
          <StepHeading text={t("generic:ride-broker-onboarding.sources-of-wealth.title")} />
          <RideMultiSelect options={options} columns={1} />
          {formik.values[SourceOfWealthType.OTHERS] && (
            <RideTextInput
              data-testid="other-sources-description"
              className="sources-of-wealth-step__other-sources-description"
              name="other-sources-description"
              label={t(
                "generic:ride-broker-onboarding.sources-of-wealth.other-sources-of-wealth-label"
              )}
              asTextArea
            />
          )}
        </div>
        <InvalidFeedback hidden={!formik.errors["error"]} testPrefix="error">
          {formik.errors["error"]}
        </InvalidFeedback>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SourceOfWealthStep);
