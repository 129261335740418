import React from "react";
import logger from "common/Logger";
import { FullPersonDetailsQuery_getPersonDetails_user } from "components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import { useMutation } from "@apollo/react-hooks";
import SignupTokenSection from "./SignupTokenSection";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import InviteClient from "../InviteClient/InviteClient";
import { GetMyPermissions } from "lib/api/queries/GetMyPermissions";
import LoginAsUserButton from "../LoginAsUser/LoginAsUserButton";
import { LOGIN_AS_USER } from "lib/api/mutations/LOGIN_AS_USER";
import { LoginAsUser, LoginAsUserVariables } from "lib/api/mutations/graphql/LoginAsUser";
import { EXTEND_SIGNUP_TOKEN_VALIDITY } from "lib/api/mutations/EXTEND_SIGNUP_TOKEN_VALIDITY";
import { NOMINEE_SEND_CUSTOMER_INVITE } from "lib/api/mutations/NOMINEE_SEND_CUSTOMER_INVITE";
import {
  ExtendSignupTokenValidity,
  ExtendSignupTokenValidityVariables
} from "lib/api/mutations/graphql/ExtendSignupTokenValidity";
import {
  NomineeSendCustomerInvite,
  NomineeSendCustomerInviteVariables
} from "lib/api/mutations/graphql/NomineeSendCustomerInvite";

type Props = {
  user?: FullPersonDetailsQuery_getPersonDetails_user | null;
  refetch: () => Promise<any>;
};

export const SignupTokenSectionContainer = ({ user, refetch }: Props) => {
  const [extendSignupTokenValidityMutation, extendSignupTokenValidityRequest] = useMutation<
    ExtendSignupTokenValidity,
    ExtendSignupTokenValidityVariables
  >(EXTEND_SIGNUP_TOKEN_VALIDITY);

  const [sendCustomerInvite, sendCustomerInviteRequest] = useMutation<
    NomineeSendCustomerInvite,
    NomineeSendCustomerInviteVariables
  >(NOMINEE_SEND_CUSTOMER_INVITE);

  const getMyPermissionsRequest = GetMyPermissions();

  const [loginAsUserMutation, loginAsUserRequest] = useMutation<LoginAsUser, LoginAsUserVariables>(
    LOGIN_AS_USER,
    {
      variables: {
        userId: user?.id ?? ""
      }
    }
  );

  const extendSignupTokenValidity = async (userId: string) => {
    try {
      await extendSignupTokenValidityMutation({
        variables: { id: userId }
      });
      await refetch();
    } catch (e) {
      logger.errorMessage("Unable to extend signup token validity:");
      logger.error(e);
    }
  };

  const sendClientInvite = async (userId: string) => {
    try {
      await sendCustomerInvite({ variables: { userId } });
      await refetch();
    } catch (error) {
      logger.errorMessage("Unable to invite client:");
      logger.error(error);
    }
  };

  const loginAsUser = async (userId: string) => {
    try {
      const result = await loginAsUserMutation({ variables: { userId } });
      return result.data?.loginAsUser?.token;
    } catch (error) {
      logger.errorMessage("Unable to log in as a tax advisor:");
      logger.error(error);
    }
  };

  const refreshAll = () => window.location.reload();

  if (!user) return null;

  return (
    <>
      <RequestFeedbackHandler requests={[extendSignupTokenValidityRequest]}>
        <SignupTokenSection user={user} extendSignupTokenValidity={extendSignupTokenValidity} />
      </RequestFeedbackHandler>

      <RequestFeedbackHandler requests={[sendCustomerInviteRequest]}>
        <InviteClient user={user} sendClientInvite={sendClientInvite} />
      </RequestFeedbackHandler>

      <RequestFeedbackHandler requests={[getMyPermissionsRequest, loginAsUserRequest]}>
        <LoginAsUserButton
          permissions={getMyPermissionsRequest.data?.me}
          loginAsUser={loginAsUser}
          user={user}
          refreshAll={refreshAll}
        />
      </RequestFeedbackHandler>
    </>
  );
};
