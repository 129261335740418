import { useQuery } from "react-apollo";
import React from "react";
import TaxAdvisorClients from "taxAdvisor/components/TaxAdvisorClients/TaxAdvisorClients";
import {
  GetMyTaxAdvisorClients,
  GetMyTaxAdvisorClientsVariables
} from "lib/api/queries/graphql/GetMyTaxAdvisorClients";
import { GET_MY_TAX_ADVISOR_CLIENTS } from "lib/api/queries/GetMyTaxAdvisorClients";

export const TaxAdvisorClientsContainer = () => {
  const limit = 10;

  const getRequest = useQuery<GetMyTaxAdvisorClients, GetMyTaxAdvisorClientsVariables>(
    GET_MY_TAX_ADVISOR_CLIENTS,
    {
      variables: {
        searchTerm: "",
        start: 0,
        limit
      }
    }
  );

  const taxServices = getRequest.data?.getMyTaxAdvisorClients?.items;
  const totalCount = getRequest.data?.getMyTaxAdvisorClients?.count;

  const searchHandler = async (searchTerm: string) => {
    await getRequest.refetch({
      searchTerm
    });
  };

  const setStartHandler = async (start: number) => {
    await getRequest.refetch({
      start,
      limit
    });
  };

  return (
    <TaxAdvisorClients
      taxServices={taxServices}
      totalTaxServicesCount={totalCount}
      loading={getRequest.loading}
      searchHandler={searchHandler}
      setStartHandler={setStartHandler}
      pageSize={limit}
    />
  );
};
