import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewField } from "../../../sharedComponents/ReviewField/ReviewField";
import { formatAddress } from "lib/formatters/addressFormatter";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { ReviewFieldList } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewFieldList/ReviewFieldList";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

type CompanyInformation = Pick<
  LowTaxBrokerOrderData,
  | "companyRegistryNumber"
  | "companyTaxId"
  | "companyAddress"
  | "companyBankAccount"
  | "companyLEINumber"
>;

export interface ReviewCompanyInformationProps {
  companyInformation: CompanyInformation;
  editLink?: string;
  isCompleted?: boolean;
  isNewBrokerEnabled: boolean;
}

export const ReviewCompanyInformation = withTranslationReady(["generic", "review-entry"])(
  ({
    t,
    companyInformation,
    editLink,
    isCompleted,
    isNewBrokerEnabled
  }: ReviewCompanyInformationProps & WithTranslation) => {
    const {
      companyRegistryNumber,
      companyTaxId,
      companyAddress,
      companyBankAccount,
      companyLEINumber
    } = companyInformation;

    const formatAddressForRideBroker =
      isNewBrokerEnabled &&
      FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerWithoutHouseNumber);

    return (
      <ReviewCard className="review-company-information">
        <ReviewSectionTitle
          title={t("generic:review-entry.low-tax-broker.company-information")}
          editLink={editLink}
          isCompleted={isCompleted}
        />
        <ReviewFieldList>
          <ReviewField
            name="companyAddress"
            label={t("generic:address")}
            value={
              companyAddress && (
                <Multiline>{formatAddress(companyAddress, formatAddressForRideBroker)}</Multiline>
              )
            }
          />
          <ReviewField
            name="companyTaxNumber"
            label={t("generic:tax-number")}
            value={companyTaxId}
            isMandatory={false}
          />
          <ReviewField
            name="companyRegistryNumber"
            label={t("generic:review-entry.low-tax-broker.companyRegistryNumber")}
            value={companyRegistryNumber}
          />
          <ReviewField
            name="companyLEINumber"
            label={t("generic:review-entry.low-tax-broker.companyLEINumber")}
            value={companyLEINumber}
          />
        </ReviewFieldList>
        {!isNewBrokerEnabled && (
          <>
            <hr />
            <ReviewFieldList>
              <ReviewField
                name="creditInstitutionName"
                label={t("generic:review-entry.low-tax-broker.creditInstitutionName")}
                value={companyBankAccount?.creditInstitutionName}
              />
              <ReviewField
                name="holderName"
                label={t("generic:ride-broker-onboarding.company-bank-account.holder-name")}
                value={companyBankAccount?.holderName}
              />
              <ReviewField
                name="iban"
                label={t("generic:ride-broker-onboarding.company-bank-account.iban")}
                value={companyBankAccount?.iban}
              />
              <ReviewField
                name="swiftBic"
                label={t("generic:review-entry.low-tax-broker.swiftBic")}
                value={companyBankAccount?.swiftBic}
              />
            </ReviewFieldList>
          </>
        )}
      </ReviewCard>
    );
  }
);

const Multiline = ({ children }: PropsWithChildren<{}>) => (
  <span className="white-space--pre-line">{children}</span>
);
