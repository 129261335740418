import React from "react";
import { WithTranslation } from "react-i18next";
import { MutationResult, QueryResult } from "react-apollo";
import { BankType, FeatureFlags } from "global-query-types";
import { FormikProvider, useFormik } from "formik";
import RequestFeedbackForm from "../../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { useHistory } from "react-router-dom";
import BankAccountForm from "../BankAccountForm/BankAccountForm";
import { RequestFeedbackInfo } from "components/generic/RequestFeedbackHandler";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";

export interface AddExistingBankAccountProps extends WithTranslation {
  onAddBankAccount: (vars: {
    bankProvider: BankType;
    companyId: string;
    iban: string;
    name: string;
  }) => Promise<void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
  companyOptions: { label: string; value: string }[];
}

const AddExistingBankAccount = ({
  requests,
  companyOptions,
  onAddBankAccount,
  t
}: AddExistingBankAccountProps) => {
  const history = useHistory();

  const onSubmit = async (bankAccount) => {
    await onAddBankAccount({
      bankProvider: bankAccount.bank,
      companyId: bankAccount.companyId,
      iban: bankAccount.iban,
      name: bankAccount.name
    });

    if (!FeatureFlagService.instance.isEnabled(FeatureFlags.BankingPageInCompanyNavigation)) {
      history.replace(`/client/banking`);
    }
  };

  const formik = useFormik({
    initialValues: { iban: "", name: "", companyId: "", bank: "" },
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <div className="admin-service-modal__wrapper">
      <div
        className="admin-service-modal__title d-flex justify-content-center"
        data-testid="modal-title-existing">
        <span className="text-black">{t("client:banking.addBankAccount.existing.title")}</span>
      </div>
      <div className="mt-35">
        <div className="ride-form ride-form--label rounded-sm px-md-55 pt-md-5 pb-md-55">
          <FormikProvider value={formik}>
            <RequestFeedbackForm
              requests={requests}
              submitLabel={t("client:banking.addBankAccount.existing.submitLabel")}>
              <BankAccountForm formik={formik} companyOptions={companyOptions} />
            </RequestFeedbackForm>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady(["client"])(AddExistingBankAccount);
