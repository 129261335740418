import gql from "graphql-tag";

export const MY_LOW_TAX_BROKER_ORDER = gql`
  query MyLowTaxBrokerOrder {
    myLowTaxBrokerOrder {
      id
      submissionId
      productType
      ownerType
      ownerId
      entityType
      entityId
      status
      extra
      createdAt
      updatedAt
      transferredAt
    }
  }
`;
