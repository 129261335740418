import { RemoteStorageNode } from "lib/dataAdapters/toRemoteStorageNode";
import React from "react";
import RideLoading from "sharedComponents/RideLoading/RideLoading";
import { UploadNodeState } from "sharedComponents/UploadWrapper/UploadWrapper";
import { InvalidFeedback } from "../../uiLibrary/designSystem/styles/InvalidFeedback/InvalidFeedback";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { DeleteIcon } from "../icons/DeleteIcon";
import { DownloadIcon } from "../icons/DownloadIcon";
import { FileSystemNodeProps } from "../FileSystemNode/FileSystemNode";

export interface FileNodeProps
  extends Omit<FileSystemNodeProps, "entityId" | "entityType">,
    WithTranslation {
  onDownload?: (node: RemoteStorageNode) => Promise<void>;
}

const FileNode = ({ node, onDelete, onDownload, readOnly, level, t }: FileNodeProps) => {
  const isUploading = node.uploadingState === UploadNodeState.UPLOADING;
  const didNodeFail = node.uploadingState !== UploadNodeState.FAILED;
  const isUploaded = !isUploading && didNodeFail;

  const handleDownload = () => onDownload?.(node);
  const handleDelete = () => onDelete?.(node);

  return (
    <div
      className={`node-tree__file-label node-tree__file-label--level-${level ?? 0}`}
      data-testid="node-file">
      <div className="node-tree__file-name-container">
        <span
          data-testid="file-name"
          className="node-tree__file-name"
          onClick={isUploaded ? handleDownload : undefined}>
          {node.name}
        </span>
        {didNodeFail && (
          <>
            <div className="node-tree__operation-wrapper--first">
              {isUploading ? (
                <RideLoading data-testid="node-is-uploading" />
              ) : (
                <div
                  className="node-tree__file-operation node-tree__file-operation--download"
                  data-testid="node-download"
                  onClick={handleDownload}>
                  <DownloadIcon />
                </div>
              )}
            </div>
            <>
              {!isUploading && !readOnly && (
                <div
                  className="node-tree__file-operation node-tree__file-operation--delete"
                  data-testid="node-delete"
                  onClick={handleDelete}>
                  <DeleteIcon />
                </div>
              )}
            </>
          </>
        )}
        <InvalidFeedback hidden={didNodeFail}>
          {t("generic:upload-invalid-feedback")}
        </InvalidFeedback>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(FileNode);
