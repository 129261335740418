import React from "react";
import RequestFeedbackHandler from "../../../generic/RequestFeedbackHandler";
import { FullPersonDetailsQuery_getPersonDetails } from "../graphql/FullPersonDetailsQuery";
import { useMutation } from "react-apollo";
import NotaryLinksSection from "./NotaryLinksSection";
import {
  UpdateNotaryLinks,
  UpdateNotaryLinksVariables
} from "../../../../lib/api/mutations/graphql/UpdateNotaryLinks";
import { UPDATE_NOTARY_LINKS } from "../../../../lib/api/mutations/UPDATE_NOTARY_LINKS";
import { NotaryLinkItem } from "../../../../global-query-types";
import { FULL_PERSON_DETAILS_QUERY } from "../Queries";

interface NotaryLinksSectionContainerProps {
  person: FullPersonDetailsQuery_getPersonDetails;
}

export const NotaryLinksSectionContainer = ({ person }: NotaryLinksSectionContainerProps) => {
  const [updateNotaryLinksMutation, updateNotaryLinksRequest] = useMutation<
    UpdateNotaryLinks,
    UpdateNotaryLinksVariables
  >(UPDATE_NOTARY_LINKS, {
    refetchQueries: [{ query: FULL_PERSON_DETAILS_QUERY, variables: { id: person.id } }]
  });

  const handleSubmit = async (notaryPersonId: string, links: NotaryLinkItem[]) => {
    await updateNotaryLinksMutation({
      variables: {
        notaryPersonId,
        links
      }
    });
  };

  return (
    <RequestFeedbackHandler requests={[updateNotaryLinksRequest]}>
      <NotaryLinksSection
        onSubmit={handleSubmit}
        personId={person.id}
        links={person?.notaryLinks ?? []}
      />
    </RequestFeedbackHandler>
  );
};
