import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { TFunction, Trans, WithTranslation } from "react-i18next";
import { Form, FormikProps, FormikProvider, useFormik } from "formik";
import { UserIcon } from "sharedComponents/icons/DesignSystemIcons/UserIcon";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import * as Yup from "yup";
import { RideCheckbox } from "sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { RideUrls } from "common/URLs";
import { CreateLeadForUnifiedShortOrderFormVariables } from "lib/api/mutations/graphql/CreateLeadForUnifiedShortOrderForm";
import {
  StepContent,
  StepContinueButton,
  StepHeader,
  StepLayout
} from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/StepLayout/StepLayout";
import { UnifiedShortOrderFormStepProps } from "client/components/UnifiedShortOrderForm/UnifiedShortOrderForm.partials";

export interface PersonalInformationStepProps extends UnifiedShortOrderFormStepProps {
  createLeadHandler: ({
    personData,
    privacyPolicyCheckbox,
    allowsContact
  }: Omit<CreateLeadForUnifiedShortOrderFormVariables, "hubspotCookie">) => Promise<void>;
}

const PersonalInformationStep = ({
  t,
  initialValues,
  goToNextStep,
  persistData,
  createLeadHandler
}: PersonalInformationStepProps & WithTranslation) => {
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      firstName: initialValues.firstName ?? "",
      lastName: initialValues.lastName ?? "",
      phoneNumber: initialValues.phoneNumber ?? "",
      email: initialValues.email ?? "",
      userReportedSource: initialValues.userReportedSource ?? "",
      privacyPolicyCheckbox: initialValues.privacyPolicyCheckbox ?? false,
      allowsContact: initialValues.allowsContact ?? false
    },
    onSubmit: async () => {
      await createLeadHandler({
        personData: {
          firstName: formik.values["firstName"],
          lastName: formik.values["lastName"],
          phoneNumber: formik.values["phoneNumber"],
          email: formik.values["email"]
        },
        userReportedSource: formik.values["userReportedSource"],
        privacyPolicyCheckbox: formik.values["privacyPolicyCheckbox"],
        allowsContact: formik.values["allowsContact"]
      });
      goToNextStep();
      persistData(formik.values);
    },
    validationSchema: PersonalInformationSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepLayout data-testid="personal-information-step" className="personal-information-step">
          <StepContent>
            <StepHeader
              data-testid={"personal-information-step-header"}
              title={t("generic:short-order-form.personal-information.title")}
              subtitle={t("generic:short-order-form.personal-information.subtitle")}
            />
            <PersonalData formik={formik} />
            <Checkboxes formik={formik} />
          </StepContent>
          <StepContinueButton label={t("generic:short-order-form.continue-save")} />
        </StepLayout>
      </Form>
    </FormikProvider>
  );
};

const PersonalData = withTranslationReady(["generic"])(
  ({ t, formik }: { formik: FormikProps<any> } & WithTranslation) => {
    return (
      <div className={"personal-information-step__personal-data"}>
        <div className={`personal-information-step__section-header`}>
          <UserIcon />
          <Typography
            data-testid="personal-data-header"
            category="Paragraph"
            size={100}
            weight={"Heavy"}>
            {t("generic:personal-data")}
          </Typography>
        </div>

        <div className={`personal-information-step__name-fields`}>
          <RideInput
            name="firstName"
            formik={formik}
            label={t("generic:first-name")}
            placeholder={t("generic:first-name")}
            className={"personal-information-step__input"}
            autoComplete="on"
          />
          <RideInput
            name="lastName"
            formik={formik}
            label={t("generic:last-name")}
            placeholder={t("generic:last-name")}
            className={"personal-information-step__input"}
            autoComplete="on"
          />
        </div>
        <RideInput
          type="tel"
          formik={formik}
          name="phoneNumber"
          className={"personal-information-step__input"}
          label={t("generic:phone")}
        />
        <RideInput
          name="email"
          formik={formik}
          type="email"
          label={t("generic:order-form.email")}
          placeholder={t("generic:email")}
          className={"personal-information-step__input"}
          autoComplete="on"
        />
        <RideInput
          name="userReportedSource"
          formik={formik}
          type="text"
          label={t("generic:order-form.user-reported-source")}
          placeholder={t("generic:order-form.user-reported-source-placeholder")}
          className={"personal-information-step__input"}
        />
      </div>
    );
  }
);

const Checkboxes = withTranslationReady(["generic"])(
  ({ t, formik }: { formik: FormikProps<any> } & WithTranslation) => {
    return (
      <div className={`personal-information-step__checkboxes`}>
        <RideCheckbox
          name="allowsContact"
          formik={formik}
          label={
            <Typography category="Paragraph" size={100} weight={"Light"}>
              {t("generic:short-order-form.checkboxes.receive-updates")}
            </Typography>
          }
        />
        <RideCheckbox
          name="privacyPolicyCheckbox"
          formik={formik}
          shouldShowErrorFeedback={true}
          label={
            <Typography category="Paragraph" size={100} weight={"Light"}>
              <Trans
                i18nKey="generic:short-order-form.checkboxes.privacy-policy"
                components={{
                  privacy: (
                    <TextLink
                      to={RideUrls.PrivacyPolicy}
                      variant={LinkVariant.secondary}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )
                }}
              />
            </Typography>
          }
        />
      </div>
    );
  }
);

const PersonalInformationSchema = (t: TFunction) => {
  return Yup.object().shape({
    firstName: Yup.string().required(t("generic:validation-required")),
    lastName: Yup.string().required(t("generic:validation-required")),
    phoneNumber: Yup.string().required(t("generic:validation-required")),
    email: Yup.string().required(t("generic:validation-required")),
    userReportedSource: Yup.string().required(t("generic:validation-required")),
    privacyPolicyCheckbox: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};

export default withTranslationReady(["generic"])(PersonalInformationStep);
