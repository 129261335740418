import React from "react";

export enum AlertType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error"
}

export enum BarVariant {
  Rectangle = "rectangle",
  Rounded = "rounded"
}

export interface AlertBarProps {
  "data-testid"?: string;
  type: AlertType;
  title?: string;
  message: React.ReactNode;
  barVariant?: BarVariant;
  className?: string;
}

const getIconNameFor = (alertType: AlertType): string => {
  const iconNames = {
    [AlertType.SUCCESS]: "success-green",
    [AlertType.INFO]: "info-blue",
    [AlertType.WARNING]: "warning-yellow",
    [AlertType.ERROR]: "error-red"
  };

  return iconNames[alertType];
};

export const RideAlertBar = ({
  "data-testid": dataTestId = "alert-bar",
  type,
  title,
  message,
  barVariant = BarVariant.Rounded,
  className
}: AlertBarProps) => {
  return (
    <div
      data-testid={dataTestId}
      className={`alert-bar alert-bar--${type} alert-bar--${barVariant} ${className}`}>
      <div data-testid={`alert-bar-icon--${getIconNameFor(type)}`} className="alert-bar__icon" />
      <div className="alert-bar__text-container">
        {title && (
          <span data-testid={`${dataTestId}-title`} className="alert-bar__title">
            {title}
          </span>
        )}
        <span
          data-testid={`${dataTestId}-message`}
          className={`alert-bar__message ${title ? "" : "alert-bar__message--without-title"}`}>
          {message}
        </span>
      </div>
    </div>
  );
};
