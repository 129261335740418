import gql from "graphql-tag";

export const USER_INFO_BY_PERSON_ID = gql`
  query userInfoByPersonId($personId: String!) {
    getUserInfoByPersonId(personId: $personId) {
      id
      email
      person {
        userWallet {
          tanganyWallets {
            wallet
            walletProviderId
            ownerCompany {
              id
              name
              legalForm
            }
          }
        }
      }
    }
  }
`;

export const CHECK_WALLET_WHITELISTED_STATUS = gql`
  query checkWalletWhitelistedStatus($walletProviderId: String!) {
    isWhitelisted(walletProviderId: $walletProviderId) {
      walletAddress
      status
    }
  }
`;

export const SPV_COMPANIES_WITH_TOKENIZED_INVESTMENT = gql`
  query SPVCompaniesWithTokenizedInvestment {
    nomineeCompanies {
      id
      projectName
      ownedTokenizedInvestment {
        id
        equityTokenAddress
      }
    }
  }
`;
