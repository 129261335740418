import React, { FC, ReactElement } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { RideThemes } from "../../components/common/layout/RideThemes";

interface RideSidebarProps {
  menu?: ReactElement[];
  theme?: RideThemes;
  activeKey?: string | number;
  onSelect?: () => void;
}

export type RideSidebar = FC<RideSidebarProps>;

export const RideSidebar = ({
  menu,
  theme = RideThemes.DARK,
  activeKey,
  onSelect = () => {}
}: RideSidebarProps) => {
  return (
    <Navbar
      data-testid="ride-sidebar"
      className={`ride-sidebar flex-fill`}
      bg={theme}
      variant={theme}>
      <Container className="align-self-start">
        <Nav className="flex-column" defaultActiveKey={activeKey} onSelect={onSelect}>
          {menu}
        </Nav>
      </Container>
    </Navbar>
  );
};
