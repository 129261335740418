import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import UpsertTokenizedInvestment from "./UpsertTokenizedInvestment";
import { GetTokenizedInfoByCompanyId } from "./getTokenizedInfo";
import { Spinner } from "react-bootstrap";
import { GetRealEstateInfoByCompanyId } from "../../Investments/getRealEstateInfo";

interface TokenizedInvestmentProps extends WithTranslation {
  projectName: string;
  companyId: string;
}

export const TokenizedInvestment: React.FunctionComponent<TokenizedInvestmentProps> = (props) => {
  const { projectName, companyId } = props;
  const tokenizedDetails = GetTokenizedInfoByCompanyId(companyId);
  const realEstateDetails = GetRealEstateInfoByCompanyId(companyId);

  if (tokenizedDetails.loading || realEstateDetails.loading)
    return <Spinner animation="grow" variant="primary" />;

  const tokenizedInfo = tokenizedDetails?.data?.getTokenizedDetailByCompanyId ?? {
    equityTokenAddress: "",
    spvWalletAddress: ""
  };

  const realEstateImage =
    realEstateDetails?.data?.realEstateByCompanyId?.image ?? "https://placehold.co/600x400";

  return (
    <>
      {tokenizedInfo ? (
        <UpsertTokenizedInvestment
          projectName={projectName}
          companyId={companyId}
          image={realEstateImage}
          equityTokenAddress={tokenizedInfo.equityTokenAddress}
          spvWalletAddress={tokenizedInfo.spvWalletAddress!}
        />
      ) : (
        <UpsertTokenizedInvestment
          projectName={projectName}
          companyId={companyId}
          image={realEstateImage}
          equityTokenAddress={""}
          spvWalletAddress={""}
        />
      )}
    </>
  );
};

export default withTranslation([""])(TokenizedInvestment);
