import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ReviewCard } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewCard/ReviewCard";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";
import { ReviewSectionTitle } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewSectionTitle/ReviewSectionTitle";
import { LTBOverviewStatus } from "lib/dataAdapters/lowTaxBrokerOverviewStatuses/lowTaxBrokerOverviewStatuses";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

interface LowTaxBrokerOverviewStatusesProps extends WithTranslation {
  overviewStatuses: LTBOverviewStatus;
}

const LowTaxBrokerOverviewStatuses = ({
  t,
  overviewStatuses
}: LowTaxBrokerOverviewStatusesProps) => {
  const AddCompanyDetailsCardInLowTaxBrokerOnbordingSteps = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddCompanyDetailsCardInLowTaxBrokerOnbordingSteps
  );
  const showCompanyDetailsCardInfo =
    AddCompanyDetailsCardInLowTaxBrokerOnbordingSteps && overviewStatuses.addCompanyDetails;

  return (
    <ReviewCard dataTestId="low-tax-broker-overview-statuses">
      <ReviewSectionTitle
        dataTestId="title"
        title={t("generic:low-tax-broker-order.details.overview-statuses.title")}
      />
      <div className={"low-tax-broker-order-details__overview-statuses-fields"}>
        {!!showCompanyDetailsCardInfo && (
          <ReviewField
            name="add-company-details"
            label={t("generic:low-tax-broker-overview.add-company-details.title")}
            value={overviewStatuses.addCompanyDetails}
          />
        )}
        <ReviewField
          name="complete-application"
          label={t("generic:low-tax-broker-overview.complete-application.title")}
          value={overviewStatuses.completeApplication}
        />
        <ReviewField
          name="verify-id"
          label={t("generic:low-tax-broker-overview.verify-id.title")}
          value={overviewStatuses.verifyId}
        />
        <ReviewField
          name="application-review"
          label={t("generic:low-tax-broker-overview.application-review.title")}
          value={overviewStatuses.applicationReview}
        />
        <ReviewField
          name="setup-account"
          label={t("generic:low-tax-broker-overview.setup-account.title")}
          value={overviewStatuses.setupAccount}
        />
        <ReviewField
          name="fund-account"
          label={t("generic:low-tax-broker-overview.fund-account.title")}
          value={overviewStatuses.fundAccount}
        />
      </div>
    </ReviewCard>
  );
};

export default withTranslationReady(["generic"])(LowTaxBrokerOverviewStatuses);
