import React from "react";

export const ExternalURLIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12.5" fill="#48FFAF" stroke="#48FFAF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.72702 8.72806L8.72702 10.213L14.6667 10.213L8.19669 16.683L9.25735 17.7437L15.7274 11.2736L15.7274 17.2133L17.2123 17.2133V8.72806H8.72702Z"
      fill="#303436"
    />
    <circle cx="13" cy="13" r="12.5" fill="#48FFAF" stroke="#48FFAF" />
    <path d="M12 9.59961H8V16.886V18.1719H16.5723V14" stroke="#303436" strokeWidth="1.5" />
    <path d="M14.5 7H19V11.5" stroke="#303436" strokeWidth="1.5" strokeLinecap="square" />
    <path
      d="M13 12.9992L17.8 8.19922"
      stroke="#303436"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
