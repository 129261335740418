import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  MyCompaniesSummarized,
  MyCompaniesSummarizedVariables
} from "./graphql/MyCompaniesSummarized";
import { CompanyType } from "global-query-types";

export const MY_COMPANIES_SUMMARIZED_QUERY = gql`
  query MyCompaniesSummarized($type: CompanyType, $relatedToPersonId: ID) {
    companies(type: $type, relatedToPersonId: $relatedToPersonId) {
      id
      name
      legalForm
      lei
      registration
      taxNumber
      businessAddress {
        street
        streetLine2
        city
        postalCode
        country
      }
      group {
        memberships {
          role
          share {
            share
          }
          company {
            id
            name
            legalForm
          }
          person {
            id
            firstName
            lastName
            phoneNumber
            dateOfBirth
            citizenship
            placeOfBirth
            salutation
            personalTaxId
            address {
              street
              streetLine2
              city
              postalCode
              country
            }
            user {
              id
              email
            }
          }
        }
      }
    }
  }
`;

export const GetMyCompaniesSummarized = (relatedToPersonId?: string | null) =>
  useQuery<MyCompaniesSummarized, MyCompaniesSummarizedVariables>(MY_COMPANIES_SUMMARIZED_QUERY, {
    variables: {
      type: CompanyType.ClientEntity,
      relatedToPersonId
    }
  });
