import gql from "graphql-tag";

export const UPSERT_ORDER = gql`
  mutation UpsertOrder(
    $id: String
    $productType: OrderProductTypeEnum
    $submissionId: String
    $extra: Json
    $entityType: OrderEntityType
    $status: OrderCompanyStatusEnum
    $entityId: String
    $ownerId: String
    $ownerType: OrderOwnerType
  ) {
    upsertOrder(
      id: $id
      productType: $productType
      submissionId: $submissionId
      extra: $extra
      entityType: $entityType
      status: $status
      entityId: $entityId
      ownerId: $ownerId
      ownerType: $ownerType
    ) {
      feedback
      submissionId
      id
    }
  }
`;
