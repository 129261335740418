import React, { useEffect, useRef } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { Col, Row } from "react-bootstrap";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { ApiFeedback } from "../../../global-query-types";

interface TwoFactorAuthenticationUserSetupProps extends WithTranslation {
  hasTwoFactorAuth: boolean;
  allowTwoFactorAuthPrompt: boolean;
  updateAllowTwoFactorAuthPrompt: () => Promise<boolean | null | undefined>;
  resetTwoFactorAuth: () => Promise<ApiFeedback | null | undefined>;
}

const TwoFactorAuthenticationUserSetup = ({
  hasTwoFactorAuth,
  allowTwoFactorAuthPrompt,
  updateAllowTwoFactorAuthPrompt,
  resetTwoFactorAuth,
  t
}: TwoFactorAuthenticationUserSetupProps) => {
  const [userHasTwoFactorAuth, setUserHasTwoFactorAuth] = React.useState(hasTwoFactorAuth);

  const [allowedTwoFactorAuthPrompt, setAllowedTwoFactorAuthPrompt] =
    React.useState(allowTwoFactorAuthPrompt);

  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const safeSetState = (setState: () => void) => {
    if (mountedRef.current) {
      setState();
    }
  };

  const onClickUpdateAllowTwoFactorAuthPrompt = async () => {
    const result = await updateAllowTwoFactorAuthPrompt();
    safeSetState(() => setAllowedTwoFactorAuthPrompt(result ?? true));
  };

  const onClickResetTwoFactorAuth = async () => {
    const result = await resetTwoFactorAuth();
    safeSetState(() => setUserHasTwoFactorAuth(result !== ApiFeedback.SUCCESS));
    safeSetState(() => setAllowedTwoFactorAuthPrompt(result === ApiFeedback.SUCCESS));
  };

  return (
    <div data-testid="two-factor-authentication-user-setup">
      <h2 data-testid={"two-factor-authentication-user-setup-title"}>
        {t("nominee-dashboard:twoFA.title")}
      </h2>
      <Row>
        <Col data-testid="two-factor-authentication-user-setup-update-allow-2fa-title">
          <dl>
            <dt>{t("nominee-dashboard:twoFA.updateAllow2FA.label")}</dt>
          </dl>
        </Col>
        <Col>
          <RideButtonDeprecated
            data-testid="two-factor-authentication-user-setup-update-allow-2fa-button"
            variant={allowedTwoFactorAuthPrompt ? ButtonVariant.Danger : ButtonVariant.Primary}
            size={ButtonSize.Small}
            onClick={onClickUpdateAllowTwoFactorAuthPrompt}
            disabled={userHasTwoFactorAuth}>
            {allowedTwoFactorAuthPrompt
              ? t("nominee-dashboard:twoFA.updateAllow2FA.disableTwoFANotification")
              : t("nominee-dashboard:twoFA.updateAllow2FA.enableTwoFANotification")}
          </RideButtonDeprecated>
        </Col>
      </Row>
      <Row>
        <Col data-testid="two-factor-authentication-user-setup-reset-2fa-title">
          <dl>
            <dt>{t("nominee-dashboard:twoFA.reset2FA.label")}</dt>
          </dl>
        </Col>
        <Col>
          <RideButtonDeprecated
            data-testid="two-factor-authentication-user-setup-reset-2fa-button"
            variant={ButtonVariant.Primary}
            size={ButtonSize.Small}
            disabled={!userHasTwoFactorAuth}
            onClick={onClickResetTwoFactorAuth}>
            {t("nominee-dashboard:twoFA.reset2FA.button")}
          </RideButtonDeprecated>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslationReady(["nominee-dashboard"])(TwoFactorAuthenticationUserSetup);
