import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

interface ContinueOrderBannerProps {
  dataTestId?: string;
  title?: string | undefined;
  message: string | ReactNode;
  ctaLink: string;
  onClickCancel?: () => Promise<void>;
  continueButtonLabel?: string | undefined;
}

const ContinueOrderBanner = ({
  t,
  dataTestId = "order-banner",
  message,
  title,
  ctaLink,
  onClickCancel,
  continueButtonLabel
}: ContinueOrderBannerProps & WithTranslation) => {
  return (
    <div data-testid={dataTestId} className="continue-order-banner">
      <div className={"continue-order-banner__text"}>
        {title && (
          <span data-testid={`${dataTestId}-title`} className="continue-order-banner__title">
            {title}
          </span>
        )}
        <span data-testid={`${dataTestId}-message`} className="continue-order-banner__message">
          {message}
        </span>
      </div>

      <div className="continue-order-banner__actions">
        {onClickCancel && (
          <span
            className="continue-order-banner__cancel"
            data-testid="cancel-order"
            onClick={onClickCancel}>
            {t("generic:cancel")}
          </span>
        )}
        <span className="continue-order-banner__continue">
          <Link to={ctaLink} data-testid="continue-order-link">
            {continueButtonLabel ?? t("generic:continue")}
          </Link>
        </span>
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(ContinueOrderBanner);
