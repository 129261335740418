import React from "react";
import RequestFeedbackHandler from "../../../generic/RequestFeedbackHandler";
import NotaryServiceSection from "../components/NotaryServiceSection";
import { AllNotaryPersons } from "../../NotaryList/Queries";
import { useMutation } from "react-apollo";
import { SET_NOTARIES } from "../Mutations";
import { NomineeCompanyDetails_companyById_companyCreation } from "../../../../lib/api/queries/graphql/NomineeCompanyDetails";

export const NotaryServiceSectionContainer = ({
  companyCreation,
  refetch
}: {
  companyCreation: NomineeCompanyDetails_companyById_companyCreation;
  refetch: () => void;
}) => {
  const [setNotariesMutation, setNotariesRequest] = useMutation(SET_NOTARIES);

  const handleSave = async (
    firstParty,
    firstPartyAppointment,
    thirdParty,
    thirdPartyAppointment
  ) => {
    await setNotariesMutation({
      variables: {
        companyCreationId: companyCreation.id,
        firstParty,
        firstPartyAppointment,
        thirdParty,
        thirdPartyAppointment
      }
    });

    await refetch();
  };

  const allNotaryPersonsRequest = AllNotaryPersons();

  return (
    <RequestFeedbackHandler requests={[setNotariesRequest, allNotaryPersonsRequest]}>
      <NotaryServiceSection
        notaryPersons={allNotaryPersonsRequest.data?.allNotaryPersons ?? []}
        companyCreation={companyCreation}
        onSave={handleSave}
      />
    </RequestFeedbackHandler>
  );
};
