import React from "react";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import CompanySelectFieldEdit from "./CompanySelectFieldEdit";
import { ClientSelectOption } from "../../ClientSelect/ClientSelect";
import { useParams } from "react-router";
import { useMutation } from "react-apollo";
import {
  UpdateCompanyById,
  UpdateCompanyByIdVariables
} from "../../../../lib/api/mutations/graphql/UpdateCompanyById";
import { UPDATE_COMPANY_BY_ID } from "../../../../lib/api/mutations/UPDATE_COMPANY_BY_ID";
import { QueryCompanyById } from "../../../../lib/api/queries/GetCompanyById";

export interface CompanySelectFieldEditContainerProps {
  fieldName: string;
  section: string;
  options: ClientSelectOption[];
}

export const CompanySelectFieldEditContainer = ({
  fieldName,
  section,
  options
}: CompanySelectFieldEditContainerProps) => {
  const [updateCompanyById, updateCompanyByIdRequests] = useMutation<
    UpdateCompanyById,
    UpdateCompanyByIdVariables
  >(UPDATE_COMPANY_BY_ID);

  const { id } = useParams<{ id: string }>();
  const companyRequest = QueryCompanyById(id);
  const company = companyRequest.data?.companyById;
  const value = company ? company[fieldName] : "";
  const companyId = company?.id ?? "";

  const handleSave = async (value: string): Promise<void> => {
    await updateCompanyById({
      variables: {
        companyId,
        [fieldName]: value
      }
    });
  };

  return (
    <RequestFeedbackHandler requests={[companyRequest]} noLoadingAnimation={true}>
      {company && (
        <CompanySelectFieldEdit
          onSave={handleSave}
          value={value}
          companyId={companyId}
          fieldName={fieldName}
          options={options}
          section={section}
          requests={[updateCompanyByIdRequests]}
        />
      )}
    </RequestFeedbackHandler>
  );
};
