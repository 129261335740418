import React, { useState } from "react";
import { InvestmentDetailsLayout } from "../../components/templates/InvestmentDetailsLayout/InvestmentDetailsLayout";
import { InvestmentDetailsContainer } from "../../components/investments/InvestmentDetails/InvestmentDetailsContainer";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { InvestmentCardPropsWithId } from "../../../lib/dataAdapters/investment/investment";
import { FeatureFlags } from "global-query-types";

const InvestmentScene = ({ t }: WithTranslation) => {
  const [title, setTitle] = useState<string>("");

  const handleSetTitle = (investment: InvestmentCardPropsWithId) => {
    setTitle(investment.name);
  };

  return (
    <FeatureFlag name={FeatureFlags.ClientInvestments}>
      <PageMetaData title={t("client:investments.investment.page-title")} />
      <InvestmentDetailsLayout title={title}>
        <InvestmentDetailsContainer onInvestmentLoad={handleSetTitle} />
      </InvestmentDetailsLayout>
    </FeatureFlag>
  );
};

export default withTranslationReady("client")(InvestmentScene);
