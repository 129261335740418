import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import RideFooter from "sharedComponents/RideFooter/RideFooter";
import GuestHeaderDeprecated from "../../../guest/components/GuestHeaderDeprecated/GuestHeaderDeprecated";

interface GuestLayoutProps extends PropsWithChildren<WithTranslation> {}

const GuestLayout = ({ children, t }: GuestLayoutProps) => (
  <div className="guest-layout">
    <GuestHeaderDeprecated />
    <div className="guest-layout__container" data-testid="guest-layout__container">
      {children}
    </div>
    <RideFooter />
  </div>
);

export default withTranslationReady(["generic"])(GuestLayout);
