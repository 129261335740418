import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";
import { PendingText } from "../../../components/client/company/components/PendingText";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";
import { UserRole } from "../../../global-query-types";

export interface CompanyClientNumberCardProps extends CompanyCardProps {
  field?: "client-number" | "third-party-tax-advisor-client-number";
  asUser: UserRole;
}

const CompanyClientNumberCard = ({
  t,
  gmbh,
  handleEditField,
  field = "client-number",
  asUser
}: CompanyClientNumberCardProps) => {
  const ableToEditRoles = [UserRole.TaxAdvisor, UserRole.ThirdPartyTaxAdvisor];
  const locked = ableToEditRoles.includes(asUser) ? false : gmbh.locked;
  const getFieldValue = () => {
    return field === "third-party-tax-advisor-client-number"
      ? gmbh.thirdPartyTaxAdvisorClientNumber
      : gmbh.clientNumber;
  };

  return (
    <RideSectionCard
      title={t(`client:company:${field}`)}
      data-testid={`gmbh-${field}-details`}
      className="shadow-none my-5">
      <RideCardValuePair
        data-testid={`company-${field}`}
        onEdit={handleEditField?.(field)}
        isLocked={locked}
        lockTooltipContent={t("generic:fieldLocked")}>
        {getFieldValue() ? getFieldValue() : <PendingText t={t} />}
      </RideCardValuePair>
    </RideSectionCard>
  );
};

export default CompanyClientNumberCard;
