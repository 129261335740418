import { getI18n } from "react-i18next";

export interface PersonName {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export const personNameWithFallback = (
  person: PersonName | null | undefined,
  considerMiddleName: boolean | null = false
): string => {
  if (!person) return "";

  const { firstName, lastName, middleName } = person;
  if (!firstName && !middleName && !lastName) return getI18n().t(`generic:unnamed`);
  return `${firstName ?? ""} ${considerMiddleName && middleName ? middleName.trim() + " " : ""}${
    lastName ?? ""
  }`.trim();
};

export const personName = (
  person: PersonName | null | undefined,
  considerMiddleName: boolean | null = false
): string | undefined => {
  if (!person) return undefined;

  const { firstName, lastName, middleName } = person;
  if (!firstName && !middleName && !lastName) return undefined;
  return `${firstName ? firstName.trim() : ""} ${
    considerMiddleName && middleName ? middleName.trim() + " " : ""
  }${lastName ? lastName.trim() : ""}`.trim();
};
