import { LegalForm } from "../../global-query-types";

export const nomineeLegalForms = [
  LegalForm.GmbH,
  LegalForm.GmbH_i_G,
  LegalForm.GmbH_Co_KG,
  LegalForm.UG,
  LegalForm.AG,
  LegalForm.KG,
  LegalForm.None_Einzelunternehmen,
  LegalForm.PartG,
  LegalForm.PartGmbB,
  LegalForm.MbH,
  LegalForm.Stiftung,
  LegalForm.EG
];

export const adminServiceLegalForms = [LegalForm.GmbH, LegalForm.UG];

export const clientLegalForms = [LegalForm.GmbH, LegalForm.UG, LegalForm.AG, LegalForm.KG];

export const holdingCompanyLegalForms = [LegalForm.GmbH, LegalForm.UG, LegalForm.AG, LegalForm.KG];

export const wepaServiceLegalForms = [LegalForm.GmbH, LegalForm.UG, LegalForm.AG];
