import { MyGmbHsQuery_companies } from "components/client/MyGmbHs/graphql/MyGmbHsQuery";
import React, { useState } from "react";
import { TFunction } from "i18next";
import { CompanyIcon } from "sharedComponents/CompanyIcon/CompanyIcon";
import { CompanyOrderStatus, FeatureFlags, OrderOwnerType } from "global-query-types";
import { RideTile, ClickableAreaVariant, IconVariant } from "sharedComponents/RideTile/RideTile";
import { UserService } from "lib/services/UserService/UserService";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { GmbHBadges } from "../GmbHBadges/GmbHBadges";
import {
  GetOrderQuery,
  GetOrderQuery_getOrder,
  GetOrderQueryVariables
} from "lib/api/queries/graphql/GetOrderQuery";
import { GET_ORDER_QUERY } from "lib/api/queries/GetOrder";
import { useApolloClient } from "@apollo/react-hooks";
import { GrundungServiceStatusEnum } from "lib/models/client/GmbH";
import { DELETE_COMPANY } from "components/Investments/Mutations";
import {
  DeleteCompany,
  DeleteCompanyVariables
} from "components/Investments/graphql/DeleteCompany";
import logger from "common/Logger";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import { DeleteIcon } from "sharedComponents/icons/DeleteIcon";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { getCompanyNameOrDefault } from "../../../lib/formatters/companyFormatter";

export interface GmbHTileProps {
  company: MyGmbHsQuery_companies;
  className?: string;
  t: TFunction;
  handleAddAdminServiceClick?: () => void;
  handleActivateWepaClick?: () => void;
  onCompanySelect: (gmbhURL: string) => void;
}

export const GmbHTile = ({
  company,
  className,
  t,
  handleAddAdminServiceClick,
  handleActivateWepaClick,
  onCompanySelect
}: GmbHTileProps) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const userService = UserService.instance?.currentUser;

  const gmbh = new GmbHDetails(company);
  const apolloClient = useApolloClient();

  const isMyCurrentUserDirector = gmbh.directors.some((director) => {
    return director.person.id === userService?.person?.id;
  });

  const onDeleteCompany = async () => {
    try {
      await apolloClient.mutate<DeleteCompany, DeleteCompanyVariables>({
        mutation: DELETE_COMPANY.document,
        variables: { id: company.id }
      });
    } catch (error) {
      logger.error(error);
    }
  };

  let subtitleSlot = (
    <GmbHBadges
      gmbhServicesStatus={gmbh}
      isCurrentUserDirector={isMyCurrentUserDirector}
      addAdminServiceCallback={() => handleAddAdminServiceClick?.()}
      activateWepaCallback={() => handleActivateWepaClick?.()}
    />
  );
  let imageSlot = <CompanyIcon gmbh={gmbh} />;

  const deleteButton = (
    <div
      data-testid="delete-button"
      className="ride-tile__delete-button"
      onClick={async (e) => {
        if (e.stopPropagation) e.stopPropagation();
        await setShowDeleteConfirmationModal(true);
      }}>
      <DeleteIcon />
    </div>
  );

  async function getOrder() {
    let order: GetOrderQuery_getOrder | null = null;
    try {
      const result = await apolloClient.query<GetOrderQuery, GetOrderQueryVariables>({
        query: GET_ORDER_QUERY,
        variables: {
          id: company.companyFoundationOrderId,
          ownerType: OrderOwnerType.Person
        }
      });
      order = result?.data?.getOrder;
    } catch (error) {
      if (error.message.includes("ORDER_NOT_FOUND")) {
        order = null;
      }
    }
    return order;
  }

  return (
    <>
      <ConfirmationModal
        dataTestId="delete-confirmation-modal"
        title={t("generic:confirm-action-modal-delete.title")}
        message={t("generic:confirm-action-modal-delete.messages.delete-company")}
        type={ConfirmationModalType.DANGER}
        visible={showDeleteConfirmationModal}
        confirmLabel={t("generic:delete")}
        onConfirm={() => onDeleteCompany()}
        onClose={() => setShowDeleteConfirmationModal(false)}
      />
      <RideTile
        className={`gmbh-tile ${className ?? ""}`}
        data-testid="gmbh-tile"
        name={getCompanyNameOrDefault(company.name)}
        subTitleSlot={subtitleSlot}
        imageSlot={imageSlot}
        buttonLabel={
          gmbh.orderStatus === CompanyOrderStatus.IN_PROGRESS
            ? t("generic:continue-order")
            : undefined
        }
        iconVariant={
          gmbh.orderStatus === CompanyOrderStatus.IN_PROGRESS
            ? IconVariant.button
            : IconVariant.arrowIcon
        }
        onClick={async () => {
          let url: string;
          if (
            FeatureFlagService.instance.isEnabled(
              FeatureFlags.ShowGSPendingOrderBannerInCompanyNavigation
            )
          ) {
            url = `/client/entities/${company.id}/general-info`;
          } else {
            let order = company.companyFoundationOrderId ? await getOrder() : null;
            if (order?.status === "Completed") {
              url = `/client/entities/${company.id}/general-info`;
            } else {
              url = order
                ? `/client/order/generic/business-purpose/0/${order?.id}/${order?.submissionId}`
                : gmbh.url;
            }
          }
          onCompanySelect(url);
        }}
        clickableAreaVariant={ClickableAreaVariant.entireTile}
        deleteButton={
          gmbh.grundungServiceStatus === GrundungServiceStatusEnum.ORDER_PENDING
            ? deleteButton
            : null
        }
      />
    </>
  );
};
