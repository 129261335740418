import React, { PropsWithChildren } from "react";
import { RidePagination } from "./RidePagination";
import RideSearch from "../../../sharedComponents/RideSearch/RideSearch";

interface RidePaginationWithSearchProps extends PropsWithChildren<{}> {
  query?: string | null;
  count: number;
  start?: number;
  limit: number;
  setQuery: (string) => void;
  setStart: (number) => void;
}

export const RidePaginationWithSearch = ({
  children,
  query,
  setQuery,
  count,
  start,
  limit,
  setStart
}: RidePaginationWithSearchProps) => {
  return (
    <RideSearch query={query ?? ""} onQueryChange={setQuery}>
      <RidePagination count={count} start={start ?? 0} pageSize={limit} setStart={setStart}>
        {children}
      </RidePagination>
    </RideSearch>
  );
};
