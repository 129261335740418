import React, { useState } from "react";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import { search_search } from "../../../../lib/api/queries/graphql/search";
import { FormikProvider, useFormik } from "formik";
import { SearchTypeEnum } from "../../../../global-query-types";
import ReactSelect from "react-select";

interface AddExistingShareholderSectionProps extends WithTranslation {
  onSubmit: ({ type, entityId }: { type: SearchTypeEnum; entityId: string }) => void;
  onSearch: ({ type, queryText }: { type: SearchTypeEnum; queryText: string }) => void;
  onAddExistingShareholderClick: (isVisible: boolean) => void;
  options: search_search[];
}

const AddExistingShareholderSection = ({
  t,
  options,
  onSubmit,
  onSearch,
  onAddExistingShareholderClick
}: AddExistingShareholderSectionProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const onClickAddExistingShareholder = () => {
    setIsVisible(true);
    onAddExistingShareholderClick(true);
  };

  const onCancelClicked = () => {
    setIsVisible(false);
    onAddExistingShareholderClick(false);
  };

  const personCompanyDropdownData = [
    { value: SearchTypeEnum.Person, label: "Person" },
    { value: SearchTypeEnum.Company, label: "Company" }
  ];

  const formik = useFormik({
    initialValues: {
      "shareholder-search": { label: "", value: "" },
      "person-company-dropdown": personCompanyDropdownData[0],
      "shareholder-search-input": ""
    },
    onSubmit: (values) => {
      onSubmit({
        type: values["person-company-dropdown"].value,
        entityId: values["shareholder-search"].value
      });
    }
  });

  const inputChangeHandler = (selectedOption, selectedCompany) => {
    formik.setFieldValue("shareholder-search-input", selectedOption);
    onSearch({ type: selectedCompany, queryText: selectedOption });
  };

  return (
    <div data-testid="add-existing-shareholder-container">
      {isVisible ? (
        <FormikProvider value={formik}>
          <div data-testid="shareholder-search-input-select-container">
            <ReactSelect
              id="shareholder-search-input"
              isSearchable={true}
              name="shareholder-search-input"
              value={formik.values["shareholder-search"]}
              options={options?.map((option): { label: string; value: string } => {
                return { label: option.result || "", value: option.id || "" };
              })}
              onInputChange={(selectedOption) => {
                inputChangeHandler(selectedOption, formik.values["person-company-dropdown"].value);
              }}
              className="mb-4 mt-4"
              onChange={(selectedOption) => {
                formik.setFieldValue("shareholder-search", selectedOption);
              }}
            />
          </div>
          <div data-testid="person-company-dropdown-select-container">
            <ReactSelect
              id="person-company-dropdown"
              name="person-company-dropdown"
              isSearchable={false}
              options={personCompanyDropdownData}
              defaultValue={formik.values["person-company-dropdown"]}
              className="mb-4 mt-4"
              onChange={(selectedOption) => {
                formik.setFieldValue("person-company-dropdown", selectedOption);
                formik.setFieldValue("shareholder-search", { label: "", value: "" });
                inputChangeHandler(" ", selectedOption?.value);
              }}
            />
          </div>
          <RideButtonDeprecated
            type="button"
            className="mr-2 mb-2"
            data-testid="submit-shareholder-button"
            variant={ButtonVariant.Primary}
            size={ButtonSize.Small}
            onClick={formik.submitForm}>
            <strong> {t("nominee-dashboard:roles.submit-shareholder-button")}</strong>
          </RideButtonDeprecated>

          <RideButtonDeprecated
            type="button"
            className="mr-2 mb-2"
            data-testid="cancel-shareholder-button"
            variant={ButtonVariant.Primary}
            size={ButtonSize.Small}
            onClick={onCancelClicked}>
            <strong> {t("generic:cancel")}</strong>
          </RideButtonDeprecated>
        </FormikProvider>
      ) : (
        <RideButtonDeprecated
          type="button"
          className="mr-2 mb-2"
          data-testid="add-shareholder-button"
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={onClickAddExistingShareholder}>
          <strong> {t("nominee-dashboard:roles.add-shareholder-button")}</strong>
        </RideButtonDeprecated>
      )}
    </div>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard"])(
  AddExistingShareholderSection
);
