import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { MeQuery } from "./graphql/MeQuery";

export const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      changedEmail
      role
      isShadow
      passwordChangedAt
      hasTwoFactorAuth
      allowTwoFactorAuthPrompt
      person {
        id
        firstName
        lastName
        dateOfBirth
        taxNumber
        locked
        phoneNumber
        address {
          street
          city
          postalCode
          country
        }
        user {
          id
        }
        userWallet {
          id
        }
        discountCode {
          code
        }
      }
    }
  }
`;

export const GetMe = () => useQuery<MeQuery>(ME_QUERY);
