import React from "react";
import { useHistory } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import ModalFormLayout from "../../../sharedComponents/templates/ModalFormLayout/ModalFormLayout";
import CompanyTextFieldEditContainer from "../../components/Company/CompanyTextFieldEdit/CompanyTextFieldEditContainer";
import { taxNumberValidationWithTranslation } from "../../../lib/validation/TaxNumberSchema";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface CompanyTaxNumberSceneProps extends WithTranslation {}

const CompanyTaxNumberScene = ({ t }: CompanyTaxNumberSceneProps) => {
  const { goBack } = useHistory();
  const validation = taxNumberValidationWithTranslation(t);

  return (
    <>
      <PageMetaData title={t("client:company.edit-page-title.taxNumber")} />
      <ModalFormLayout title={t("client:company.edit.taxNumber")} onBack={goBack}>
        <CompanyTextFieldEditContainer
          fieldName={"taxNumber"}
          section="numbers-and-ids"
          validation={validation}
        />
      </ModalFormLayout>
    </>
  );
};

export default withTranslation(["generic", "client"])(CompanyTaxNumberScene);
