import React from "react";
import HomeTab from "./HomeTab";
import { GetMe } from "../../../../lib/api/queries/MeQuery";
import { personNameWithFallback } from "../../../../lib/formatters/personFormatter";
import { GmbHDetails } from "../../../../lib/models/client/GmbHDetails";
import { GetCompanyCreationSteps } from "../../../../lib/api/queries/GetCompanyCreationSteps";

export interface HomeTabContainerProps {
  gmbh: GmbHDetails;
}

export const HomeTabContainer = ({ gmbh }: HomeTabContainerProps) => {
  const name = personNameWithFallback(GetMe().data?.me?.person);

  const processStepsRequest = GetCompanyCreationSteps(gmbh.companyCreationId ?? "");
  const processSteps = processStepsRequest.data?.getCompanyCreationSteps;
  const bankAccountTaskGroupCompleted = !!processSteps?.find(
    (t) => t.ref === "bankAccount" && t.state === "COMPLETED"
  );

  return (
    <HomeTab name={name} gmbh={gmbh} bankAccountTaskCompleted={bankAccountTaskGroupCompleted} />
  );
};
