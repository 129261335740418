import React from "react";

export const ExternalURLIconOnHover = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12.5" fill="#303436" stroke="#303436" />
    <path d="M12 9.59961H8V16.886V18.1719H16.5723V14" stroke="#48FFAF" strokeWidth="1.5" />
    <path d="M14.5 7H19V11.5" stroke="#48FFAF" strokeWidth="1.5" strokeLinecap="square" />
    <path
      d="M13 12.9992L17.8 8.19922"
      stroke="#48FFAF"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
