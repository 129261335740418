import gql from "graphql-tag";

export const CREATE_FILE_UPLOAD_URL = gql`
  mutation CreateFileUploadURL(
    $nodeId: ID!
    $fileName: String!
    $fileSize: Int!
    $entityType: StorageNodesEntityType!
    $entityId: ID!
  ) {
    createFileUploadURL(
      nodeId: $nodeId
      fileName: $fileName
      fileSize: $fileSize
      entityType: $entityType
      entityId: $entityId
    ) {
      uploadId
      s3_url {
        partNumber
        url
      }
    }
  }
`;
