import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Col, Row } from "react-bootstrap";
import RideInput from "sharedComponents/FormControls/RideInput/RideInput";
import { Form, FormikProps, FormikProvider } from "formik";
import { RideUrls } from "common/URLs";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import i18n from "common/i18n";
import * as Yup from "yup";
import { RideCheckbox, RideCheckboxVariant } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import { EmailValidatorWithBackend } from "lib/validation/EmailValidatorWithBackend";

interface ContactDetailsProps {}

interface ContactDetailsInputsProps {
  formik: FormikProps<any>;
}

const ContactDetailsInputs = ({ formik, t }: ContactDetailsInputsProps & WithTranslation) => {
  return (
    <div data-testid="contact-details-fields">
      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="firstName"
            label={t("generic:first-name")}
            placeholder={t("generic:first-name")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="lastName"
            label={t("generic:last-name")}
            placeholder={t("generic:last-name")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="phoneNumber"
            className={"order-form__input"}
            label={t("generic:order-form.phone")}
            type={"tel"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideInput
            formik={formik}
            name="email"
            type="email"
            label={t("generic:order-form.email")}
            placeholder={t("generic:email")}
            className={"order-form__input"}
            autoComplete="on"
          />
        </Col>
      </Row>

      <Row className={"order-form__input-row"}>
        <Col>
          <RideCheckbox
            data-testid={"privacy-policy"}
            name="privacyPolicy"
            variant={RideCheckboxVariant.Light}
            label={
              <Trans
                i18nKey="generic:order-form.checkboxes.privacy-policy"
                components={{
                  privacy: (
                    <TextLink
                      to={RideUrls.PrivacyPolicy}
                      variant={LinkVariant.primary}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )
                }}
              />
            }
          />
        </Col>
      </Row>
    </div>
  );
};

const ContactDetailsSchema = (t) => {
  return Yup.object().shape({
    firstName: Yup.string().required(t("generic:validation-required")),
    lastName: Yup.string().required(t("generic:validation-required")),
    phoneNumber: Yup.string().required(t("generic:validation-required")),
    email: EmailValidatorWithBackend(t),
    privacyPolicy: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};

const ContactDetails = ({
  t,
  currentStep,
  order,
  saveData,
  controls
}: ContactDetailsProps & StepComponentProps & WithTranslation) => {
  const onSubmit = async (values) => {
    currentStep.complete();

    await saveData({
      ...order.extra,
      contactDetails: values,
      ...currentStep.chapter.order.serialize()
    });
  };

  const contacts = order.extra.contactDetails ?? {};

  const formik = useRideFormik({
    initialValues: {
      firstName: contacts.firstName ?? "",
      lastName: contacts.lastName ?? "",
      phoneNumber: contacts.phoneNumber ?? "",
      email: contacts.email ?? "",
      privacyPolicy: contacts.privacyPolicy ?? false
    },
    onSubmit,
    validationSchema: ContactDetailsSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:order-company-foundation.steps.contact-details.title")} />
        <ContactDetailsInputs formik={formik} t={t} i18n={i18n} tReady={true} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ContactDetails);
