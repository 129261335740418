import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import GenericAddress from "client/components/ChaptersOrderLayout/CompanyFounding/sharedComponents/GenericAddress/GenericAddress";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { Form, FormikProvider } from "formik";
import { AddressSchema } from "../../../lib/validation/AddressSchema";
import { useRideFormik } from "../../../lib/hooks/useRideFormik";

export interface ThirdPartyTaxAdvisorAddressData {
  streetLine1?: string | undefined;
  streetLine2?: string | undefined;
  city?: string | undefined;
  postalCode?: string | undefined;
  country?: string | undefined;
}

export interface ThirdPartyTaxAdvisorAddressProps {
  address?: ThirdPartyTaxAdvisorAddressData;
  saveAddress: (address: ThirdPartyTaxAdvisorAddressData) => Promise<void>;
}

const ThirdPartyTaxAdvisorAddress = ({
  t,
  address,
  saveAddress
}: ThirdPartyTaxAdvisorAddressProps & WithTranslation) => {
  const module = "third-party-tax-advisor-address";

  const formik = useRideFormik({
    initialValues: {
      streetLine1: address?.streetLine1,
      streetLine2: address?.streetLine2,
      country: address?.country,
      postalCode: address?.postalCode,
      city: address?.city
    },
    enableReinitialize: true,
    onSubmit: async () => {
      await saveAddress(formik.values);
    },
    validationSchema: AddressSchema(t)
  });

  return (
    <div className={module}>
      <FormikProvider value={formik}>
        <Form>
          <Typography
            data-testid={"address-step-title"}
            category={"Headline"}
            size={200}
            weight={"Heavy"}>
            {t("generic:migrate-3pta.address.title")}
          </Typography>
          <GenericAddress prefix={""} displaySectionTitle={false} formik={formik} title={""} />
          <RideButton
            data-testid="save-address-button"
            className={`${module}__submit-button`}
            label={t("generic:migrate-3pta.address.submit-button")}
            variant={RideButtonVariant.PRIMARY}
            size={RideButtonSize.BIG}
            type="submit"
          />
        </Form>
      </FormikProvider>
    </div>
  );
};

export default withTranslationReady(["generic"])(ThirdPartyTaxAdvisorAddress);
