import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import ClubDealCard, { ClubDealCardProps } from "../../ClubDealCard/ClubDealCard";

export interface UnavailableClubDealsListProps extends WithTranslation {
  clubDeals: ClubDealCardProps[];
}

const UnavailableClubDealsList = ({ t, clubDeals }: UnavailableClubDealsListProps) => {
  return (
    <div className="unavailable-club-deals" data-testid="unavailable-club-deals">
      <div className="unavailable-club-deals__wrapper">
        <div className="unavailable-club-deals__title">
          {t("client:investments.clubDeals.dealsList.pastInvestmentProjects")}
        </div>
        <div className="unavailable-club-deals__list">
          {clubDeals.map((clubDeal, index) => (
            <ClubDealCard key={index} {...clubDeal} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default withTranslationReady("client")(UnavailableClubDealsList);
