import React, { ReactNode, useRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import lodash from "lodash";

interface OpenFileSelectionHandlerProps extends WithTranslation {
  testid?: string;
  className?: string;
  canUploadMultipleFiles: boolean;
  onSelect: (fileList: File[]) => void;
  children: (props: { openFileSelectPopUp: () => void }) => ReactNode;
}

const OpenFileSelectionHandler = ({
  testid = "open-file-selection-handler",
  canUploadMultipleFiles = false,
  onSelect,
  className,
  children
}: OpenFileSelectionHandlerProps) => {
  const id = lodash.uniqueId();
  const fileInput = useRef(null);

  const handleChange = (event) => {
    onSelect(Array.from(event.target.files));
    event.target.value = null;
  };

  const openFileSelectPopUp = () => {
    const element = fileInput.current! as any;
    element.click();
  };

  return (
    <div className={`file-input-group ${className ?? ""}`} data-testid={testid}>
      {children({ openFileSelectPopUp })}

      <input
        ref={fileInput}
        type="file"
        className="file-input form-control d-none"
        id={`custom-file-${id}`}
        data-testid="input-file"
        onChange={handleChange}
        multiple={canUploadMultipleFiles}
      />
    </div>
  );
};

export default withTranslation(["generic"])(OpenFileSelectionHandler);
