import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { AddressData } from "lib/models/client/LowTaxBroker/AddressData";
import { formatAddress } from "lib/formatters/addressFormatter";
import { ReviewShareholderSection } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/ReviewShareholders/ReviewShareholderSection/ReviewShareholderSection";
import { ReviewField } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/ReviewField/ReviewField";

export interface ReviewAddressProps {
  address: AddressData | undefined;
  isRideBroker?: boolean;
}

export const ReviewAddress = withTranslationReady(["generic"])(
  ({ t, address, isRideBroker }: ReviewAddressProps & WithTranslation) => {
    return (
      <ReviewShareholderSection
        title={t("generic:ride-broker-onboarding.review.shareholders.address")}>
        <ReviewField
          name="residential-address"
          label={t("generic:ride-broker-onboarding.review.shareholders.residential-address")}
          value={address && <Multiline>{formatAddress(address, isRideBroker)}</Multiline>}
        />
      </ReviewShareholderSection>
    );
  }
);

export const Multiline = ({ children }: PropsWithChildren<{}>) => (
  <span className="white-space--pre-line">{children}</span>
);
