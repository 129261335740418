import React from "react";
import { IconProps } from "./IconProps";

export const BusinessIcon = ({ size = 24, className }: IconProps) => {
  return (
    <svg
      data-testid="business-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 10.5C22.5 10.1022 22.342 9.72064 22.0607 9.43934C21.7794 9.15804 21.3978 9 21 9H16.5V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V22.5H22.5V10.5ZM15 3H3V21H15V3ZM16.5 10.5V21H21V10.5H16.5Z"
        fill="currentColor"
      />
      <path d="M10.5 10.5H12V13.5H10.5V10.5Z" fill="currentColor" />
      <path d="M10.5 15H12V18H10.5V15Z" fill="currentColor" />
      <path d="M6 15H7.5V18H6V15Z" fill="currentColor" />
      <path d="M6 10.5H7.5V13.5H6V10.5Z" fill="currentColor" />
      <path d="M6 6H7.5V9H6V6Z" fill="currentColor" />
      <path d="M10.5 6H12V9H10.5V6Z" fill="currentColor" />
    </svg>
  );
};
