import React from "react";
import { Alert } from "react-bootstrap";
import { TFunction } from "i18next";

interface SuccessAlertProps {
  message?: string;
  success?: boolean;
  t: TFunction;
}

export default class SuccessAlert extends React.Component<SuccessAlertProps> {
  static defaultProps = {
    success: true
  };

  render() {
    const { t, success, message } = this.props;
    return success && message ? (
      <Alert variant="success" className="alert" data-testid="success-alert">
        <h3>{t("generic:success_heading")}</h3>
        <p>{t(message)}</p>
      </Alert>
    ) : null;
  }
}
