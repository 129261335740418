import * as Yup from "yup";
import { emailValidationWithTranslation } from "./EmailSchema";

export const PersonalInformationSchema = (t) => {
  return Yup.object().shape({
    firstName: Yup.string().required(t("generic:validation-required")),
    lastName: Yup.string().required(t("generic:validation-required")),
    email: emailValidationWithTranslation(t),
    phoneNumber: Yup.string().required(t("generic:validation-required"))
  });
};
