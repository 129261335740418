import React from "react";
import { useHistory } from "react-router-dom";
import {
  ModalLayout,
  ModalLayoutVariant
} from "../../../../sharedComponents/templates/ModalLayout/ModalLayout";
import { AddNewGmbHContainer } from "../../../components/GmbH/AddNewGmbH/AddNewGmbHContainer";
import { useMutation } from "react-apollo";
import { NewCompanyCreationForUser } from "../../../../lib/api/mutations/graphql/NewCompanyCreationForUser";
import { NEW_COMPANY_CREATION_FOR_USER } from "../../../../lib/api/mutations/NEW_COMPANY_CREATION_FOR_USER";
import { FeatureFlagService } from "../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../../../global-query-types";

export const GmbHAddNewScene = () => {
  const history = useHistory();

  const [newCompanyCreationForUser] = useMutation<NewCompanyCreationForUser>(
    NEW_COMPANY_CREATION_FOR_USER
  );

  const handleClose = () => {
    const shouldShowDefaultCompany = FeatureFlagService.instance.isEnabled(
      FeatureFlags.ReplaceCompanyListByCompanyDropdown
    );
    history.replace(shouldShowDefaultCompany ? "/" : `/client/entities`);
  };

  const addGmbHNewModal = FeatureFlagService.instance.isEnabled(FeatureFlags.NewAddGmbHModal);

  const handleBack = () => {
    if (addGmbHNewModal) {
      history.goBack();
    } else {
      history.replace(`/client/my-gmbhs/add`);
    }
  };

  const handleOrder = async () => {
    const response = await newCompanyCreationForUser();
    history.push(`/booking/gmbh/${response.data?.newCompanyCreationForUser?.id ?? "start"}`);
  };

  return (
    <ModalLayout onClose={handleClose} onBack={handleBack} variant={ModalLayoutVariant.white}>
      <AddNewGmbHContainer onOrder={handleOrder} />
    </ModalLayout>
  );
};
