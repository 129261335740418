import React from "react";
import { GetFinanceAdsCodes } from "../../../lib/api/queries/getFinanceAdsCodes";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import FinanceAdsCodesTable from "./FinanceAdsCodesTable";

const FinanceAdsCodesTableContainer = () => {
  const getFinanceAdsCodes = GetFinanceAdsCodes();

  return (
    <RequestFeedbackHandler requests={[getFinanceAdsCodes]}>
      <FinanceAdsCodesTable
        financeAdsCodes={getFinanceAdsCodes.data?.getFinanceAdsCodes?.financeAdsCodes ?? []}
      />
    </RequestFeedbackHandler>
  );
};

export default FinanceAdsCodesTableContainer;
