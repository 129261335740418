import React from "react";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import ReviewShareholders from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ShareholderDetails/ReviewShareholders/ReviewShareholders";
import { ReviewOwnershipData } from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/OwnershipDetails/ReviewOwnershipData/ReviewOwnershipData";
import { ReviewManagingDirectors } from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/ManagingDirectors/ReviewManagingDirectors/ReviewManagingDirectors";
import { ReviewCompanyAddress } from "client/components/ChaptersOrderLayout/CompanyFounding/chapters/CompanyAddress/ReviewCompanyAddress/ReviewCompanyAddress";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { PersonName } from "lib/formatters/personFormatter";
import { Form, FormikProvider } from "formik";
import { AddExistingCompanyOrderData } from "lib/models/client/AddExistingCompany/AddExistingCompanyOrderData";
import ReviewCompanyInformation from "client/components/ChaptersOrderLayout/AddExistingCompany/CompanyInformation/ReviewCompanyInformation/ReviewCompanyInformation";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";

const ReviewAndSubmitAddExistingCompany = ({
  t,
  order,
  controls,
  saveData,
  currentStep
}: StepComponentProps<AddExistingCompanyOrderData> & WithTranslation) => {
  const orderFlow = currentStep.chapter.order;

  let initialValues = {};

  const onSubmit = async () => {
    currentStep.complete();

    await saveData(
      {
        ...order.extra,
        ...currentStep.chapter.order.serialize()
      },
      undefined,
      false
    );
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit
  });

  const { companyData, employees, shareholders, companyAddress } = order.extra;

  const shareholdersDirectors: PersonName[] = [];
  if (shareholders) {
    for (const shareholderKey of Object.keys(shareholders)) {
      const shareholder = shareholders[shareholderKey];
      if (shareholder.isManagingDirector) {
        shareholdersDirectors.push({
          firstName: shareholder.personData.firstName,
          lastName: shareholder.personData.lastName
        });
      } else if (shareholder.isRepresentativeManagingDirector) {
        shareholdersDirectors.push({
          firstName: shareholder.companyRepresentative.firstName,
          lastName: shareholder.companyRepresentative.lastName
        });
      }
    }
  }

  const employeeDirectors: PersonName[] = [];

  if (employees) {
    for (const employeeKey of Object.keys(employees)) {
      const employee = employees[employeeKey];
      if (employee.isManagingDirector) {
        employeeDirectors.push({
          firstName: employee.personData.firstName,
          lastName: employee.personData.lastName
        });
      }
    }
  }

  const shareholdersList = Object.keys(shareholders ?? {}).map((key) => shareholders?.[key]);

  const managingDirectors = [...shareholdersDirectors, ...employeeDirectors];

  const getEditLink = (chapter: string) => {
    return `/client/order/generic/${chapter}/0/${order.id}/${order.submissionId}`;
  };

  const isChapterCompleted = (chapter: string): boolean => {
    return Boolean(orderFlow.getChapter(chapter)?.completed);
  };

  const canSubmit = all(previousChaptersKeys.map(isChapterCompleted));

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="review-and-submit">
          <StepHeading text={t("generic:company-setup.steps.review-and-submit.title")} />
          <ReviewCompanyInformation
            className="review-and-submit__card"
            editLink={getEditLink("company-information")}
            companyData={companyData ?? {}}
          />
          <ReviewShareholders className="review-and-submit__card" order={order} />
          <ReviewOwnershipData
            className="review-and-submit__card"
            editLink={getEditLink("ownership-distribution")}
            shareholders={shareholdersList}
          />
          <ReviewManagingDirectors
            className="review-and-submit__card"
            editLink={getEditLink("managing-directors")}
            directors={managingDirectors}
          />
          <ReviewCompanyAddress
            className="review-and-submit__card"
            editLink={getEditLink("company-address")}
            address={companyAddress ?? {}}
          />
        </div>
        {{ ...controls, props: { ...controls.props, disabled: !canSubmit } }}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(ReviewAndSubmitAddExistingCompany);

const previousChaptersKeys: string[] = [
  "company-information",
  "shareholder-details",
  "ownership-distribution",
  "managing-directors",
  "company-address"
];

const all = (values: boolean[]) => {
  for (const value of values) {
    if (!value) {
      return false;
    }
  }
  return true;
};
