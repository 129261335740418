import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getWepaBookings, getWepaBookingsVariables } from "./graphql/getWepaBookings";

export const GET_WEPA_BOOKINGS = gql`
  query getWepaBookings($companyId: String!) {
    getWepaBookings(companyId: $companyId) {
      count
      bookings {
        id
        fileName
        status
        dateFrom
        dateTo
        dateCalculated
        url
        fiscalYear
      }
    }
  }
`;

export const GetWepaBookings = (variables: getWepaBookingsVariables) =>
  useQuery<getWepaBookings, getWepaBookingsVariables>(GET_WEPA_BOOKINGS, {
    variables
  });
