import { Client as apiClient } from "../GraphqlClient/GraphqlClient";
import gql from "graphql-tag";
import { updateToken } from "./storeToken";
import { RefreshTokenMutation } from "./graphql/RefreshTokenMutation";

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation {
    refreshToken {
      token
      feedback
    }
  }
`;

export const refreshToken = async () => {
  const result = await apiClient.mutate<RefreshTokenMutation>({ mutation: REFRESH_TOKEN_MUTATION });
  const newToken = result.data!.refreshToken!.token;
  updateToken(newToken);
};
