import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CompanyRegistrationCard from "sharedComponents/CompanyFields/CompanyRegistrationCard/CompanyRegistrationCard";
import CompanyShareholdersCard from "sharedComponents/CompanyFields/CompanyShareholdersCard/CompanyShareholdersCard";
import CompanyOverviewCard from "sharedComponents/CompanyFields/CompanyOverviewCard/CompanyOverviewCard";
import CompanyNumbersAndIdsCard from "sharedComponents/CompanyFields/CompanyNumbersAndIdsCard/CompanyNumbersAndIdsCard";
import CompanyPurposeCard from "sharedComponents/CompanyFields/CompanyPurposeCard/CompanyPurposeCard";
import CompanyBankCard from "sharedComponents/CompanyFields/CompanyBankCard/CompanyBankCard";
import CompanyFiscalYearCard from "sharedComponents/CompanyFields/CompanyFiscalYearCard/CompanyFiscalYearCard";
import CompanySilentPartnersCard from "sharedComponents/CompanyFields/CompanySilentPartnersCard/CompanySilentPartnersCard";
import UserBrokerSurveyContainer from "client/components/UserBrokerSurvey/UserBrokerSurveyContainer";
import { UserRole } from "global-query-types";

interface Props extends WithTranslation {
  gmbh: GmbHDetails;
}

const CompanyOverviewSection = ({ t, gmbh }: Props) => {
  const history = useHistory();

  const handleEditField = (field: string) => {
    if (gmbh.locked) {
      return undefined;
    }

    return () => history.push(`/client/entities/${gmbh.id}/general-info/${field}`);
  };

  return (
    <Container data-testid="company-overview-section" className="mt-5 mb-5">
      <UserBrokerSurveyContainer gmbh={gmbh} />
      <Row>
        <Col>
          <CompanyOverviewCard t={t} gmbh={gmbh} handleEditField={handleEditField} />
          <CompanyFiscalYearCard
            t={t}
            gmbh={gmbh}
            handleEditField={!gmbh.fiscalYear ? handleEditField : undefined}
            asUser={UserRole.Client}
          />
          <CompanyShareholdersCard t={t} gmbh={gmbh} />
          {gmbh.silentPartners.length > 0 && <CompanySilentPartnersCard t={t} gmbh={gmbh} />}
          <CompanyRegistrationCard t={t} gmbh={gmbh} handleEditField={handleEditField} />
          <CompanyNumbersAndIdsCard t={t} gmbh={gmbh} handleEditField={handleEditField} />
          <CompanyPurposeCard t={t} gmbh={gmbh} handleEditField={handleEditField} />
          <CompanyBankCard t={t} gmbh={gmbh} />
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation(["generic", "client"])(CompanyOverviewSection);
