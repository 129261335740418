import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import {
  AlertType,
  RideAlertBar
} from "../../../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../../../uiLibrary/designSystem/RideButton/RideButton";
import { Typography } from "../../../../../uiLibrary/designSystem/styles/Typography/Typography";
import TaskCard, { TaskCardStatusEnum } from "../../../TaskCard/TaskCard";
import { useHistory } from "react-router-dom";
import { ArrowRight } from "../../../../../sharedComponents/icons/DesignSystemIcons/ArrowRight";
import { PreliminaryTaskReturn } from "../../../../../common/checkForPreliminaryTask";

export interface PreliminaryTaskProps extends WithTranslation {
  task: PreliminaryTaskReturn;
  companyId: string;
}

const PreliminaryTask = ({ t, companyId, task }: PreliminaryTaskProps) => {
  const className = "preliminary-task-home-tab";
  const history = useHistory();
  const isExistingCompany = !!task.addExistingCompanyOrder;

  const existingCompanyCard = (
    <TaskCard
      status={TaskCardStatusEnum.ACTION_REQUIRED}
      index={1}
      title={t("generic:preliminary-task.home-tab.existing-company.task-title")}>
      <div data-testid={"finish-company-onboarding"} className={className}>
        <div className={`${className}__next-step`}>
          <Typography category={"Paragraph"} size={400} weight={"Heavy"}>
            <span data-testid={"header"}>{t("generic:preliminary-task.home-tab.next-step")}</span>
          </Typography>
          <div className={`${className}__purple-bar`}></div>
        </div>
        <Typography category={"Paragraph"} size={200} weight={"Light"} data-testid={"message"}>
          {t("generic:preliminary-task.home-tab.existing-company.description")}
        </Typography>
        <RideButton
          data-testid={"cta"}
          className={"preliminary-task-home-tab__cta"}
          label={t(`generic:preliminary-task.home-tab.existing-company.button-label`)}
          variant={RideButtonVariant.PRIMARY}
          rightIcon={<ArrowRight />}
          size={RideButtonSize.BIG}
          type={"button"}
          onClick={() =>
            history.push(
              `/client/order/generic/company-information/0/${task.addExistingCompanyOrder?.id}/${task.addExistingCompanyOrder?.submissionId}`
            )
          }
        />
      </div>
    </TaskCard>
  );

  const grundungServiceCard = (
    <TaskCard
      status={TaskCardStatusEnum.ACTION_REQUIRED}
      index={1}
      title={t("generic:preliminary-task.home-tab.grundung-service.task-title")}>
      <div data-testid={"finish-bank-account-task"}>
        <div className={`${className}__next-step`}>
          <Typography category={"Paragraph"} size={400} weight={"Heavy"}>
            <span data-testid={"header"}>{t("generic:preliminary-task.home-tab.next-step")}</span>
          </Typography>
          <div className={`${className}__purple-bar`}></div>
        </div>
        <RideAlertBar
          className={`${className}__alert`}
          type={AlertType.WARNING}
          title={t("generic:preliminary-task.home-tab.grundung-service.title")}
          message={t("generic:preliminary-task.home-tab.grundung-service.description")}
        />
        <RideButton
          data-testid={"cta"}
          className={"preliminary-task-home-tab__cta"}
          label={t(`generic:preliminary-task.home-tab.grundung-service.button-label`)}
          variant={RideButtonVariant.PRIMARY}
          size={RideButtonSize.BIG}
          type={"button"}
          rightIcon={<ArrowRight />}
          onClick={() => history.push(`/client/entities/${companyId}/general-info`)}
        />
      </div>
    </TaskCard>
  );
  return isExistingCompany ? existingCompanyCard : grundungServiceCard;
};

export default withTranslationReady(["generic"])(PreliminaryTask);
