import React, { useState } from "react";
import { PersonalInformationStepContainer } from "client/components/UnifiedShortOrderForm/PersonalInformationStep/PersonalInformationStepContainer";
import ProductSelectionPageStep from "client/components/UnifiedShortOrderForm/ProductSelectionStep/ProductSelectionStep";
import { CheckoutStepContainer } from "client/components/UnifiedShortOrderForm/CheckoutStep/CheckoutStepContainer";
import ThankYouStep from "client/components/ThankYouStep/ThankYouStep";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { UtmParams } from "lib/services/UtmService/UtmService";
import {
  ProductSelectionStepForm,
  UnifiedShortOrderFormValues
} from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { withProps } from "lib/jsx/withProps";
import {
  ShortOrderFormEngine,
  ShortOrderFormStep
} from "client/components/ShortOrderFormEngine/ShortOrderFormEngine";
import { CompanyInformationStepContainer } from "./CompanyInformationStep/CompanyInformationStepContainer";

export interface UnifiedShortOrderFormProps {
  utmParams: UtmParams;
  preselectedProducts: ProductSelectionStepForm;
  stepIndex: number;
}

const UnifiedShortOrderForm = ({
  utmParams,
  preselectedProducts,
  stepIndex,
  t
}: UnifiedShortOrderFormProps & WithTranslation) => {
  const [values, setValues] = useState<UnifiedShortOrderFormValues>({ ...preselectedProducts });

  const persistData = (newValues: UnifiedShortOrderFormValues) => {
    setValues(newValues);
  };

  const stepProps = { initialValues: values, persistData };

  const steps: ShortOrderFormStep[] = [
    {
      title: t("generic:short-order-form:personal-information.sidebar-title"),
      component: withProps(PersonalInformationStepContainer, stepProps)
    },
    {
      title: t("generic:short-order-form.company-information.title"),
      component: withProps(CompanyInformationStepContainer, stepProps)
    },
    {
      title: t("generic:choose-products"),
      component: withProps(ProductSelectionPageStep, stepProps)
    },
    {
      title: t("generic:checkout"),
      component: withProps(CheckoutStepContainer, { ...stepProps, utmParams })
    },
    {
      title: t("generic:short-order-form.thank-you.progress-bar-title"),
      component: withProps(ThankYouStep, stepProps)
    }
  ];

  return (
    <ShortOrderFormEngine route={"account-creation"} steps={steps} currentStepIndex={stepIndex} />
  );
};

export default withTranslationReady(["generic"])(UnifiedShortOrderForm);
