import * as Yup from "yup";
import { validate } from "../../common/taxValidator";
import { TFunction } from "i18next";

const taxValidation = /^([1-9][0-9]*)$/;

export const cityRegex = /^[a-zA-ZäöüßÄÖÜẞ\s-]+$/;
export const postalCode = /^[0-9]+$/;
export const personalTaxId = taxValidation;
export const taxNumber = taxValidation;

/// TIN works only for private individual tax identification numbers
export const taxIdTINValidation = (t?: TFunction) => {
  const translate = (text) => {
    return t ? t(text) : text;
  };

  return Yup.string()
    .matches(personalTaxId, {
      message: translate("generic:taxNumber-only-number-not-startswith-0")
    })
    .length(11, translate("generic:taxNumber-length-11"))
    .test(
      "taxValidator",
      translate("generic:invalid-taxNumber"),
      (value) => !value || validate(value)
    );
};

export const taxIdTINRequired = taxIdTINValidation().required("generic:validation-required");

export const taxIdTINOptional = taxIdTINValidation();
