import { QueryResult } from "react-apollo";
import { MeQuery, MeQuery_me } from "lib/api/queries/graphql/MeQuery";

export class UserService {
  static instance: UserService;

  currentUser: MeQuery_me | undefined;
  meRequest: QueryResult<MeQuery> | undefined;

  get isActiveUser() {
    return !!this.currentUser && !this.currentUser.isShadow;
  }
}
