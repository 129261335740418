import React, { useState } from "react";
import { withTranslationReady } from "../../../../common/i18n/withTranslationReady";
import AdminClubDealForm, { AdminClubFormValues } from "../AdminClubDealForm/AdminClubDealForm";
import { WithTranslation } from "react-i18next";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import logger from "../../../../common/Logger";
import { clubDeals_clubDeals } from "../../../../lib/api/queries/graphql/clubDeals";
import { LinkVariant } from "sharedComponents/TextLink/TextLink";
import TextLinkAsButton from "uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import { Modal } from "react-bootstrap";

interface AdminClubDealAddModalProps extends WithTranslation {
  realEstateList: { id: string; label: string }[];
  addRequest?: MutationResult | QueryResult | RequestFeedbackInfo;
  onSubmit?: (id: string, values: AdminClubFormValues) => void;
  clubDeal: clubDeals_clubDeals;
}

const AdminClubDealEditModal = ({
  addRequest,
  onSubmit,
  realEstateList,
  clubDeal,
  t
}: AdminClubDealAddModalProps) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const handleClickClubDeal = () => {
    setEditModalIsOpen(true);
  };

  const handleEditClubDeal = async (variables) => {
    try {
      await onSubmit?.(clubDeal.id, variables);
      setEditModalIsOpen(false);
    } catch (err: any) {
      logger.error(err);
    }
  };

  return (
    <>
      <TextLinkAsButton
        data-testid="club-deal-text-link-id"
        variant={LinkVariant.primary}
        onClick={handleClickClubDeal}>
        {clubDeal.id}
      </TextLinkAsButton>
      <Modal
        data-testid="club-deal-modal"
        size="xl"
        show={editModalIsOpen}
        onHide={() => setEditModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title data-testid="modal-title">{t("generic:edit")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdminClubDealForm
            realEstateList={realEstateList}
            clubDeal={clubDeal}
            request={addRequest}
            onSubmit={handleEditClubDeal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslationReady(["nominee-dashboard"])(AdminClubDealEditModal);
