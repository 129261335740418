import React from "react";
import { TFunction } from "i18next";
import { Col, Row } from "react-bootstrap";
import { ApolloQueryResult } from "apollo-client";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById,
  NomineeCompanyDetails_companyById_adminService
} from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { GetTaxAdvisorCompaniesMinimal_taxAdvisorCompanies } from "../../../lib/api/queries/graphql/GetTaxAdvisorCompaniesMinimal";
import { UpdateCompanyCreationVariables } from "../../../lib/api/mutations/graphql/UpdateCompanyCreation";
import { CreateAdminService } from "../../../components/nominee/NomineeCompany/components/adminServiceSection/CreateAdminService";
import { TaxServiceMessages } from "../../../components/nominee/NomineeCompany/components/adminServiceSection/AdminServiceAlertMessages";
import { AssignTaxService } from "../../../components/nominee/NomineeCompany/components/adminServiceSection/AdminServiceAssignTaxService";
import { ActivateTaxService } from "../../../components/nominee/NomineeCompany/components/adminServiceSection/AdminServiceActivateTaxService";
import { ReAssignFolderPermissions } from "../../../components/nominee/NomineeCompany/components/adminServiceSection/AdminServiceAssignFolderPermissions";
import { ResetStoragePermissions } from "../ResetStoragePermissions/ResetStoragePermissions";
import { ActivatedTaxServiceSection } from "../../../components/nominee/NomineeCompany/components/adminServiceSection/ActivatedTaxServiceSection";
import { UpdateAdminServiceSection } from "../../../components/nominee/NomineeCompany/components/adminServiceSection/UpdateAdminServiceSection";
import { canAssignTaxService } from "components/nominee/NomineeCompany/components/adminServiceSection/AdminServiceSectionAddon";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { formatValue } from "common/formatters";

interface AdminServiceSectionProps {
  t: TFunction;
  company: NomineeCompanyDetails_companyById;
  createAdminServiceFlow: (companyId: string) => Promise<void>;
  createAdminServiceFolderStructure: (companyId: string) => Promise<void>;
  activateTaxService: (taxServiceId: string) => Promise<boolean>;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
  taxAdvisorCompaniesList: GetTaxAdvisorCompaniesMinimal_taxAdvisorCompanies[];
  handleAssignTaxServiceCompanyChange: (id: string) => Promise<void>;
  handleAssignTaxAdvisorStoragePermissions: () => Promise<void>;
  updateTaxServiceActivatedAt: (taxServiceId: string, activatedAt: string) => Promise<void>;
  updateAdminService: (data: UpdateCompanyCreationVariables) => Promise<void>;
  resetStoragePermissions: () => Promise<void>;
}

export const AdminServiceSection = ({
  t,
  company,
  createAdminServiceFlow,
  createAdminServiceFolderStructure,
  activateTaxService,
  refetchCompany,
  taxAdvisorCompaniesList,
  handleAssignTaxServiceCompanyChange,
  handleAssignTaxAdvisorStoragePermissions,
  updateTaxServiceActivatedAt,
  updateAdminService,
  resetStoragePermissions
}: AdminServiceSectionProps) => {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <CreateAdminService
            t={t}
            company={company}
            createAdminServiceFlow={createAdminServiceFlow}
            refetchCompany={refetchCompany}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <TaxServiceMessages t={t} company={company} />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={12} sm={6} md={4}>
          {canAssignTaxService(company) && (
            <AssignTaxService
              t={t}
              company={company}
              handleAssignTaxServiceCompanyChange={handleAssignTaxServiceCompanyChange}
              taxAdvisorCompaniesList={taxAdvisorCompaniesList}
              refetchCompany={refetchCompany}
            />
          )}
        </Col>

        <Col xs={12} sm={6} md={8}>
          <ActivateTaxService
            t={t}
            company={company}
            activateTaxService={activateTaxService}
            createAdminServiceFolderStructure={createAdminServiceFolderStructure}
            handleAssignTaxAdvisorStoragePermissions={handleAssignTaxAdvisorStoragePermissions}
            refetchCompany={refetchCompany}
          />
          <ReAssignFolderPermissions
            t={t}
            company={company}
            refetchCompany={refetchCompany}
            handleAssignTaxAdvisorStoragePermissions={handleAssignTaxAdvisorStoragePermissions}
          />
          <ResetStoragePermissions
            t={t}
            refetchCompany={refetchCompany}
            resetStoragePermissions={resetStoragePermissions}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={12} sm={6} md={4}>
          {company.myTaxService?.taxAdvisorStatus === "ACTIVE" && (
            <ActivatedTaxServiceSection
              t={t}
              taxService={company.myTaxService}
              updateTaxServiceActivatedAt={updateTaxServiceActivatedAt}
            />
          )}
        </Col>
      </Row>

      {company.adminService && (
        <UpdateAdminServiceSection
          t={t}
          adminService={company.adminService}
          updateAdminService={updateAdminService}
          refetchCompany={refetchCompany}
        />
      )}

      {company.adminService && (
        <Row className="mb-4">
          <Col>
            <AdminServicePaymentSection t={t} adminService={company.adminService} />
          </Col>
        </Row>
      )}
    </>
  );
};

export const AdminServicePaymentSection = ({
  t,
  adminService
}: {
  t: TFunction;
  adminService: NomineeCompanyDetails_companyById_adminService;
}) => {
  return (
    <div data-testid="sepa-reference-number">
      <Typography category={"Headline"} size={400} weight={"Heavy"}>
        {t("generic:sepa-reference-number")}
      </Typography>
      <br />
      <Typography category={"Headline"} size={400} weight={"Light"}>
        {formatValue(adminService.sepaReferenceNumber)}
      </Typography>
    </div>
  );
};
