import gql from "graphql-tag";

export const DELETE_STORAGE_NODE = gql`
  mutation DeleteStorageNode(
    $folderId: ID!
    $fileId: ID
    $entityType: StorageNodesEntityType
    $entityId: ID
  ) {
    deleteStorageNode(
      folderId: $folderId
      fileId: $fileId
      entityType: $entityType
      entityId: $entityId
    ) {
      feedback
    }
  }
`;
