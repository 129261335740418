import React, { useState } from "react";
import { TFunction } from "i18next";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQuery_myCompanyCreation_company_group_memberships,
  MyCompanyCreationQuery_myCompanyCreation_company_group_memberships_person,
  MyCompanyCreationQueryVariables
} from "../../graphql/MyCompanyCreationQuery";
import { FlowTypeEnum, RolesInGroup } from "../../../../../global-query-types";
import { PersonalDetailFields } from "./PersonalDetailFields";
import { CompanyCreationPersonalDetailSchema } from "../../../../../lib/validation/CompanyCreationPersonalDetailSchema";
import { UPDATE_SHAREHOLDERS_DETAILS } from "../../Mutations";
import { useMutation } from "react-apollo";
import logger from "../../../../../common/Logger";
import { ApolloQueryResult } from "apollo-client";
import LoadingAlert from "../../../../generic/LoadingAlert";
import ErrorAlertLegacy from "../../../../../sharedComponents/ErrorAlert/ErrorAlertLegacy";
import { defaultCountryCode } from "../../../../../common/defaultCountry";

interface PersonalDetailFormProps {
  t: TFunction;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation | undefined;
  resetActiveStep: (string) => void;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
  hasHoldingCompany?: boolean;
}

const personalDetailFieldValues = (
  membership: MyCompanyCreationQuery_myCompanyCreation_company_group_memberships,
  hasRole: (
    person: MyCompanyCreationQuery_myCompanyCreation_company_group_memberships_person,
    role: RolesInGroup
  ) => boolean
) => {
  const person = membership.person;

  if (!person) {
    return {};
  }

  const personDateOfBirth = person.dateOfBirth ? new Date(person.dateOfBirth) : "";
  const fullName = `${person.firstName} ${person.lastName}`;

  return {
    id: person.id ?? "",
    fullName,
    isShareholder: hasRole(person, RolesInGroup.Shareholder),
    isDirector: hasRole(person, RolesInGroup.Director),
    firstName: person.firstName ?? "",
    lastName: person.lastName ?? "",
    dateOfBirth: personDateOfBirth,
    citizenship: person.citizenship ?? defaultCountryCode,
    street: person.address?.street ?? "",
    postalCode: person.address?.postalCode ?? "",
    city: person.address?.city ?? "",
    country: person.address?.country ?? defaultCountryCode,
    personalTaxId: person.personalTaxId ?? "",
    taxNumber: person.taxNumber ?? "",
    birthName: person.birthName ?? "",
    placeOfBirth: person.placeOfBirth ?? "",
    agreement: false
  };
};

const shareHoldersDetails = (memberships, t: TFunction) => {
  const hasRole = (
    person: MyCompanyCreationQuery_myCompanyCreation_company_group_memberships_person,
    role: RolesInGroup
  ) => {
    return !!memberships.find((member) => member.role === role && member?.person?.id === person.id);
  };

  const listedMemberships = memberships
    .filter(
      (membership) =>
        membership.role === RolesInGroup.Shareholder || membership.role === RolesInGroup.Director
    )
    .filter(
      (member, index, all) => all.map((a) => a?.person?.id).indexOf(member?.person?.id) === index
    )
    .map((membership) => personalDetailFieldValues(membership, hasRole))
    .filter((details) => !!details.id);

  const shareholderCount = listedMemberships.filter((details) => details.isShareholder).length;
  const directorCount = listedMemberships.filter(
    (details) => !details.isShareholder && details.isDirector
  ).length;

  listedMemberships.forEach((details, index) => {
    const key = details.isShareholder ? "trader-gmbh:shareholder" : "trader-gmbh:managing-director";
    const title = t(key);
    let counter = "";

    if (shareholderCount > 1 || directorCount > 1) {
      counter = ` ${index + 1}`;
    }

    details.title = title + counter;
  });

  return listedMemberships;
};

export const PersonalDetailForm = ({
  t,
  myCompanyCreationDetails,
  refetchMyCompanyCreation,
  resetActiveStep,
  currentFlowType,
  hasHoldingCompany = false
}: PersonalDetailFormProps) => {
  const [updateShareholdersDetails, { error, loading }] = useMutation(UPDATE_SHAREHOLDERS_DETAILS);

  const [localError, setLocalError] = useState("");

  const memberships = myCompanyCreationDetails?.company?.group?.memberships ?? [];

  const personalDetails = shareHoldersDetails(memberships, t);

  const submitHandler = async (values) => {
    setLocalError("");
    const agreed = values.personalDetails.map((rec) => rec.agreement);

    if (agreed.includes(false)) {
      setLocalError(
        t("trader-gmbh:not-agreed-error-agreement-1") +
          t("trader-gmbh:not-agreed-error-agreement-2")
      );

      return;
    }

    const allShareholders = values.personalDetails.map((details) => ({
      id: details.id,
      dateOfBirth: details.dateOfBirth,
      citizenship: details.citizenship,
      street: details.street,
      postalCode: details.postalCode,
      city: details.city,
      country: details.country,
      personalTaxId: details.personalTaxId,
      taxNumber: details.taxNumber,
      placeOfBirth: details.placeOfBirth,
      birthName: details.birthName
    }));
    const companyCreationId = myCompanyCreationDetails?.id ?? "";

    try {
      await updateShareholdersDetails({
        variables: {
          companyCreationId: companyCreationId,
          allShareholders
        }
      });

      const { data } = await refetchMyCompanyCreation({ id: companyCreationId });

      resetActiveStep(data.myCompanyCreation?.currentStep);

      return;
    } catch (error) {
      console.error(error);
      logger.errorMessage(`Unable to update shareholders details:`);
      logger.error(error);
    }
  };

  const formik = useFormik({
    initialValues: { personalDetails },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: CompanyCreationPersonalDetailSchema,
    onSubmit: submitHandler
  });

  const handlePersonalDetailsRender = () => {
    const personalDetailFields: JSX.Element[] =
      formik.values.personalDetails.map(mapPersonalDetailFields);
    return (
      <div className="flex-column step-bg">
        {personalDetailFields}
        <Row className="mt-3">
          <Col className="d-flex justify-content-end">
            <Button
              type="submit"
              data-testid="submit"
              className="step-submit-btn"
              variant="outline-light">
              {t("generic:save")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const mapPersonalDetailFields = (details, index: number, allValues) => {
    return (
      <div key={index} data-testid="personalDetails-form">
        <div className="mt-md-5 px-md-45 step-title" data-testid={`stepTitle`}>
          {details.title}: {details.fullName}
        </div>
        <div className="mt-md-35 px-md-45">
          <PersonalDetailFields
            t={t}
            formik={formik}
            values={formik.values.personalDetails[index]}
            touched={(formik.touched.personalDetails ?? [])[index]}
            errors={(formik.errors.personalDetails ?? [])[index]}
            prefix={`personalDetails.${index}.`}
            localError={localError}
          />
        </div>
        {loading || error?.message ? (
          <Row className="mt-3">
            <Col>
              <LoadingAlert t={t} loading={loading} />
              <ErrorAlertLegacy t={t} error={error?.message} />
            </Col>
          </Row>
        ) : (
          ""
        )}
        {allValues.length - 1 === index ? (
          ""
        ) : (
          <Row className="mt-3">
            <Col xs={"12"}>
              <hr className="border border-dark" />
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const personalDescription = (
    <>
      {t("trader-gmbh:personalDetails-form-description-1")}
      <br />
      {t("trader-gmbh:personalDetails-form-description-2")}
    </>
  );

  const holdingDescription = (
    <>
      {t("trader-gmbh:via-holding.personalDetails-form-description-1")}{" "}
      {t("trader-gmbh:via-holding.personalDetails-form-description-2")}
    </>
  );

  return (
    <FormikProvider value={formik}>
      <Row>
        <Col className="steps-form-description">
          {hasHoldingCompany ? holdingDescription : personalDescription}
        </Col>
      </Row>
      <Row className="step-bg mt-md-45 px-md-4 pb-md-45 mt-35 px-0 pb-0">
        <Col>
          <Form>
            <FieldArray name="personalDetails" render={handlePersonalDetailsRender} />
          </Form>
        </Col>
      </Row>
    </FormikProvider>
  );
};
