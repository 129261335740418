import gql from "graphql-tag";

export const UPDATE_CLUB_DEAL = gql`
  mutation UpdateClubDeal(
    $id: ID!
    $realEstateId: ID
    $targetYield: Int
    $minInvestmentAmount: Int
    $maxInvestmentAmount: Int
    $financedPercentage: Int
  ) {
    updateClubDeal(
      id: $id
      realEstateId: $realEstateId
      targetYield: $targetYield
      minInvestmentAmount: $minInvestmentAmount
      maxInvestmentAmount: $maxInvestmentAmount
      financedPercentage: $financedPercentage
    ) {
      feedback
    }
  }
`;
