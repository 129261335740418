import React from "react";
import { TFunction } from "i18next";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface SelectProps {
  t: TFunction;
  dateFormat: string;
  type?: string;
  minDate?: string | number | Date;
  maxDate?: string | number | Date;
  openToDate?: string | number | Date;
  name: string;
  label: string;
  count?: number;
  onChange?: any;
  selected: Date | null;
  isValid?: boolean;
  isInvalid?: boolean;
  error?: string;
  input?: any;
  options?: string[] | { [key: string]: string } | Array<{ text: string; value: string }>;
  formText?: string;
  className?: any;
  isClearable?: boolean;
}

export const DateField: React.FunctionComponent<SelectProps> = (props) => {
  const {
    label,
    dateFormat,
    input,
    maxDate,
    minDate,
    openToDate,
    onChange,
    selected,
    isValid,
    isInvalid,
    name,
    formText,
    className,
    error = "",
    t,
    isClearable = false
  } = props;

  const extraClassName = isInvalid ? "is-invalid" : "";

  return (
    <Form.Group className="mb-3" data-testid={`${name}-date-container`}>
      {label && (
        <>
          <Form.Label className="mr-3">{label}</Form.Label>
          <br />
        </>
      )}

      <DatePicker
        {...input}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        utcOffset={0}
        className={`${className} ${extraClassName}`}
        type="date"
        selected={selected}
        dateFormat={dateFormat}
        maxDate={maxDate}
        openToDate={openToDate}
        minDate={minDate}
        onChange={onChange}
        isValid={isValid}
        isInvalid={isInvalid}
        name={name}
        isClearable={isClearable}
      />
      {error ? (
        <Form.Control.Feedback type="invalid" data-testid={`${name}-invalid-feedback`}>
          {t(error)}
        </Form.Control.Feedback>
      ) : (
        ""
      )}

      {formText ? <Form.Text className="text-muted">{t(formText)}</Form.Text> : null}
    </Form.Group>
  );
};

export default DateField;
