import FileNode from "./FileNode";
import React from "react";
import { RemoteStorageNode } from "../../lib/dataAdapters/toRemoteStorageNode";
import { openExternalURL } from "../../common/ExternalURLOpener";
import { useMutation } from "react-apollo";
import {
  CreateFileDownloadURL,
  CreateFileDownloadURLVariables
} from "../../lib/api/mutations/graphql/CreateFileDownloadURL";
import { CREATE_FILE_DOWNLOAD_URL } from "../../lib/api/mutations/CREATE_FILE_DOWNLOAD_URL";
import RequestFeedbackHandler from "../../components/generic/RequestFeedbackHandler";
import { FileSystemNodeProps } from "../FileSystemNode/FileSystemNode";

export const FileNodeContainer = (props: FileSystemNodeProps) => {
  const { entityType, entityId } = props;

  const [createFileDownloadURLMutation, createFileDownloadURLRequest] = useMutation<
    CreateFileDownloadURL,
    CreateFileDownloadURLVariables
  >(CREATE_FILE_DOWNLOAD_URL);

  const createFileDownloadURL = async (fileId: string): Promise<string> => {
    const response = await createFileDownloadURLMutation({
      variables: { fileId, entityType, entityId }
    });
    return response.data?.createFileDownloadURL ?? "#no-data-received";
  };

  const downloadNode = async (node: RemoteStorageNode) => {
    await createFileDownloadURL(node.nodeId).then(openExternalURL);
  };

  return (
    <RequestFeedbackHandler
      requests={[createFileDownloadURLRequest]}
      noLoadingAnimation
      showChildrenWhileLoading>
      <FileNode {...props} onDownload={downloadNode} />
    </RequestFeedbackHandler>
  );
};
