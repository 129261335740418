import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import DeleteTextLinkWithModal from "../DeleteTextLinkWithModal/DeleteTextLinkWithModal";

export interface FileUploadedProps {
  name: string;
  onDelete: () => Promise<void>;
}

const FileUploaded = ({ name, onDelete }: FileUploadedProps) => {
  return (
    <div data-testid="file-uploaded" className="file-uploaded">
      <span data-testid="file-name" className="file-uploaded__name">
        {name}
      </span>
      <IoIosCheckmarkCircle data-testid="uploaded-icon" size={24} />
      <div className="file-uploaded__delete">
        <DeleteTextLinkWithModal onConfirm={onDelete} />
      </div>
    </div>
  );
};

export default FileUploaded;
