import gql from "graphql-tag";

export const SET_3PTA_COMPANY_ADDRESS = gql`
  mutation Set3PTACompanyAddress(
    $companyId: ID!
    $streetLine1: String
    $streetLine2: String
    $postalCode: String
    $city: String
    $country: String
  ) {
    set3PTACompanyAddress(
      companyId: $companyId
      streetLine1: $streetLine1
      streetLine2: $streetLine2
      postalCode: $postalCode
      city: $city
      country: $country
    ) {
      feedback
    }
  }
`;
