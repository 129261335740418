import { TFunction } from "i18next";
import React from "react";
import { Container } from "react-bootstrap";
import { FlowTypeEnum } from "../../../global-query-types";
import { useParams } from "react-router";
import { BackToMyGmbHsButtonContainer } from "client/components/BackToMyGmbHsButton/BackToMyGmbHsButtonContainer";
import { DisplayDiscountCodeContainer } from "../DisplayDiscountCode/DisplayDiscountCode";
interface OrderConfirmationPageProps {
  t: TFunction;
  currentFlowType?: FlowTypeEnum;
}

export const OrderConfirmationPage = ({ t, currentFlowType }: OrderConfirmationPageProps) => {
  const { confirmed } = useParams<{ confirmed: string }>();

  const onlyAdminService =
    currentFlowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING || confirmed === "confirmed";

  return (
    <Container className="trader-gmbh">
      <div className="d-flex justify-content-center">
        <img data-testid="email-icon" src={`/assets/sharedComponents/email-icon.svg`} alt="icon" />
      </div>
      <div className="order-status-description mt-3">
        {onlyAdminService ? <AdminServiceDescription t={t} /> : <FoundingDescription t={t} />}
        <DisplayDiscountCodeContainer t={t} />
        <div className="mt-3">
          <BackToMyGmbHsButtonContainer />
        </div>
      </div>
    </Container>
  );
};

const AdminServiceDescription = ({ t }: { t: TFunction }) => {
  return (
    <span data-testid="confirm-message-adminService">
      {t("admin-service:postOrder-status-onboarding-description")}
    </span>
  );
};

const FoundingDescription = ({ t }: { t: TFunction }) => {
  return (
    <span data-testid="confirm-message-foundingService">
      {t("generic:company-creation.post-order-status-description")}
    </span>
  );
};
