import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useLocation } from "react-router";

export type DragDropContextProps = {
  dragging: boolean;
  droppedFiles?: FileList;
  clearDroppedFiles: () => void;
};

const DragDropContext: React.Context<DragDropContextProps> = React.createContext({
  dragging: false
} as DragDropContextProps);

export const useDragDrop = () => useContext(DragDropContext);

export const DragDropProvider = ({ children }: PropsWithChildren<{}>) => {
  const initialState = {
    dragging: false,
    clearDroppedFiles() {
      setValue(initialState);
    }
  };
  const [value, _setValue] = useState<DragDropContextProps>(initialState);
  const setValue = debounce(_setValue);

  const common = (fn) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    fn(e);
  };

  const nothing = (e) => {};

  const onDragOverEnter = (e) => {
    setValue({ ...value, dragging: true });
  };

  const onDragLeaveEnd = (e) => {
    setValue({ ...value, dragging: false });
  };

  const onDrop = (e) => {
    setValue({ ...value, dragging: false, droppedFiles: e.dataTransfer?.files });
  };

  const { pathname } = useLocation();

  useEffect(() => {
    value.clearDroppedFiles();
    //  Note: because otherwise we'll run into infinite loop
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <DragDropContext.Provider value={value}>
      <div
        onDrag={common(nothing)}
        onDragStart={common(nothing)}
        onDragEnd={common(onDragLeaveEnd)}
        onDragOver={common(onDragOverEnter)}
        onDragEnter={common(onDragOverEnter)}
        onDragLeave={common(onDragLeaveEnd)}
        onDrop={common(onDrop)}>
        {children}
      </div>
    </DragDropContext.Provider>
  );
};
