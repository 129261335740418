import { ResetMutation } from "lib/api/mutations/graphql/ResetMutation";
import React, { useState } from "react";
import { MutationResult } from "react-apollo";
import LostPasswordForm from "./LostPasswordForm/LostPasswordForm";
import LostPasswordSuccess from "./LostPasswordSuccess/LostPasswordSuccess";

export enum LostPasswordPhase {
  form,
  success
}

interface LostPasswordProps {
  onSubmit: (email: string) => Promise<void>;
  resetRequest: MutationResult<ResetMutation>;
  phase: LostPasswordPhase;
  isResetPasswordFlow: boolean;
}

const LostPassword = ({ phase, onSubmit, ...props }: LostPasswordProps) => {
  const [email, setEmail] = useState("");
  const [currentPhase, setCurrentPhase] = useState(phase);

  const handleSubmit = async (email: string) => {
    setEmail(email);
    await onSubmit(email);
    setCurrentPhase(LostPasswordPhase.success);
  };

  return (
    <div className="lost-password">
      {currentPhase === LostPasswordPhase.form && (
        <LostPasswordForm {...props} onSubmit={handleSubmit} />
      )}
      {currentPhase === LostPasswordPhase.success && <LostPasswordSuccess email={email} />}
    </div>
  );
};

export default LostPassword;
