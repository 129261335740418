import React from "react";
import { LinkVariant, TextLink } from "../../../../../sharedComponents/TextLink/TextLink";
import { withTranslationReady } from "../../../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";

interface OpenBankAccountDetailsFootnoteProps extends WithTranslation {
  link: string;
}

const OpenBankAccountDetailsFootnote = ({ t, link }: OpenBankAccountDetailsFootnoteProps) => {
  return (
    <div
      data-testid="open-bank-account-details-footnote"
      className="open-bank-account-details-footnote">
      {t("company-founding-steps:tasks.openBankAccount.footnote.prefix")}&nbsp;
      <TextLink
        to={link}
        variant={LinkVariant.secondary}
        data-testid="open-bank-account-details-footnote-link"
        target="_blank"
        className="open-bank-account-details-footnote__link">
        {t("company-founding-steps:tasks.openBankAccount.footnote.linkLabel")}
      </TextLink>
      &nbsp;{t("company-founding-steps:tasks.openBankAccount.footnote.suffix")}
    </div>
  );
};

export default withTranslationReady("company-founding-steps")(OpenBankAccountDetailsFootnote);
