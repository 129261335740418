import React from "react";
import { useMutation } from "react-apollo";
import logger from "common/Logger";
import { GetStorageConfig } from "lib/api/queries/GetStorageConfig";
import { StorageNodesEntityType } from "global-query-types";
import { GetStorageNodesById } from "components/nominee/NomineeCompany/Queries";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import NodeTreeCard from "./NodeTreeCard";
import { RemoteStorageNode } from "lib/dataAdapters/toRemoteStorageNode";
import { DELETE_STORAGE_NODE } from "lib/api/mutations/DELETE_STORAGE_NODE";
import {
  DeleteStorageNode,
  DeleteStorageNodeVariables
} from "lib/api/mutations/graphql/DeleteStorageNode";

export interface NodeTreeCardContainerProps {
  title: string;
  entityId: string;
  nodeId?: string;
  entityType?: StorageNodesEntityType;
  "data-testid"?: string;
  readOnly?: boolean;
}

export const NodeTreeCardContainer = (props: NodeTreeCardContainerProps) => {
  const {
    title,
    entityId,
    nodeId,
    entityType = StorageNodesEntityType.Company,
    "data-testid": testId = "file-tree-card-container",
    readOnly = false
  } = props;

  const getStorageConfigRequest = GetStorageConfig();

  const parentId = `${nodeId ?? ""}`;
  const getStorageNodesByIdRequest = GetStorageNodesById({
    id: parentId,
    entityType,
    entityId,
    nested: true
  });

  const [deleteStorageNodeMutation, deleteStorageNodeRequest] = useMutation<
    DeleteStorageNode,
    DeleteStorageNodeVariables
  >(DELETE_STORAGE_NODE);

  const deleteStorageNode = async (node: RemoteStorageNode) => {
    try {
      await deleteStorageNodeMutation({
        variables: {
          folderId: node.parentId,
          fileId: node.nodeId,
          entityType,
          entityId: entityId
        }
      });
      await getStorageNodesByIdRequest.refetch();
    } catch (error) {
      logger.errorMessage(`Unable to delete storage node`);
      logger.error(error);
    }
  };

  const storageConfig = getStorageConfigRequest.data?.getStorageConfig;
  if (!storageConfig) {
    return null;
  }

  const refetchStorageNodes = async () => {
    await getStorageNodesByIdRequest.refetch();
  };

  return (
    <RequestFeedbackHandler
      requests={[getStorageConfigRequest, deleteStorageNodeRequest, getStorageNodesByIdRequest]}
      showChildrenWhileLoading>
      <NodeTreeCard
        data-testid={testId}
        title={title}
        localNodes={getStorageNodesByIdRequest.data?.getStorageNodes ?? []}
        parentId={parentId}
        onDelete={deleteStorageNode}
        entityType={entityType}
        entityId={entityId}
        refetch={refetchStorageNodes}
        readOnly={readOnly}
      />
    </RequestFeedbackHandler>
  );
};
