import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  KnowledgeDocuments,
  KnowledgeDocuments_getKnowledgeDocuments
} from "./graphql/KnowledgeDocuments";
import { ClientCommitted, ClientCommitted_clientCommitted } from "./graphql/ClientCommitted";

export const GET_KNOWLEDGE_DOCUMENTS = gql`
  query KnowledgeDocuments {
    getKnowledgeDocuments {
      nodeId
      name
      type
      childNodes {
        nodeId
        name
        type
      }
    }
  }
`;

export const GetKnowledgeDocuments = () =>
  useQuery<KnowledgeDocuments, KnowledgeDocuments_getKnowledgeDocuments>(GET_KNOWLEDGE_DOCUMENTS);

export const GET_CLIENT_COMMITTED = gql`
  query ClientCommitted {
    clientCommitted {
      committed
    }
  }
`;

export const GetClientCommitted = () =>
  useQuery<ClientCommitted, ClientCommitted_clientCommitted>(GET_CLIENT_COMMITTED);
