import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { getWepaBookings_getWepaBookings_bookings } from "lib/api/queries/graphql/getWepaBookings";
import { StorageConfigStructure } from "components/client/company/components/GetStorageConfig";
import BrokerageAccountList from "client/components/BrokerageAccountList/BrokerageAccountList";
import ConfirmAccountingYear from "client/components/ConfirmAccountingYear/ConfirmAccountingYear";
import DocumentSection from "client/components/DocumentSection/DocumentSection";
import React from "react";
import { useHistory } from "react-router-dom";
import { FeatureFlags, UserRole } from "../../../../global-query-types";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import WepaBookingsCard from "../../../../sharedComponents/CompanyFields/CompanyWepaBookingsCard/WepaBookingsCard";
import CompanyAccountingFrameworkCard from "../../../../sharedComponents/CompanyFields/CompanyAccountingFrameworkCard/CompanyAccountingFrameworkCard";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "../../../../uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../../uiLibrary/designSystem/RideButton/RideButton";
import NavigationService from "../../../../lib/services/NavigationService/NavigationService";
import { QuestionIcon } from "../../../../sharedComponents/icons/DesignSystemIcons/QuestionIcon";

interface WepaGeneralDataProps {
  gmbh: GmbHDetails;
  bookings: getWepaBookings_getWepaBookings_bookings[];
  onConfirmBooking: (bookingId: number) => void;
  wepaStructure: StorageConfigStructure[];
}

const WepaGeneralData = ({
  t,
  gmbh,
  bookings,
  onConfirmBooking,
  wepaStructure
}: WepaGeneralDataProps & WithTranslation) => {
  const history = useHistory();
  const handleEditBrokerageAccount = (id: string) => {
    history.push(`/client/entities/${gmbh.id}/securities-booking/edit/${id}`);
  };

  const handleAddBrokerageAccount = () => {
    history.push(`/client/entities/${gmbh.id}/securities-booking/new`);
  };

  let isWepaDirectClient =
    gmbh.orders.find((o) => o.productType === "WepaDirect" && o.status === "Completed") !==
    undefined;

  const getWepaBookingsInformation = () => {
    const isWepaDirect = gmbh.orders.find(
      (o) => o.productType === "WepaDirect" && o.status === "Completed"
    );
    const isASCustomer = gmbh.adminServiceStatus === "ACTIVE";
    const isWepaOldContract = !isWepaDirect && !isASCustomer;

    let suffix = "";

    if (isASCustomer) {
      suffix = "for-admin-service-client";
    }
    if (isWepaOldContract) {
      suffix = "for-wepa-standalone-client";
    }
    if (isWepaDirect) {
      suffix = "for-wepa-direct-client";
    }

    return (
      <div className={"wepa-general-data__bookings-header"}>
        <Typography
          data-testid={"client-booking-table-header"}
          category={"Headline"}
          size={300}
          weight={"Heavy"}>
          {t("generic:wepa-bookings.header")}
        </Typography>
        <Typography
          data-testid={"client-booking-table-description"}
          category={"Paragraph"}
          size={200}
          weight={"Light"}>
          {t(`generic:wepa-bookings.description.${suffix}`)}
        </Typography>
        {isWepaDirect && (
          <RideButton
            data-testid={"how-to-use-bookings-link"}
            label={t("generic:wepa-bookings.how-to-use-bookings-label")}
            variant={RideButtonVariant.GHOST}
            size={RideButtonSize.SMALL}
            leftIcon={<QuestionIcon size={20} />}
            onClick={() =>
              NavigationService.instance?.openLink(
                "https://www.ride.capital/faq/wepa-buchungsstapel-download"
              )
            }
          />
        )}
      </div>
    );
  };

  return (
    <div data-testid="wepa-service-general-data">
      <BrokerageAccountList
        list={gmbh.brokerageAccounts ?? []}
        onEdit={handleEditBrokerageAccount}
        onAdd={handleAddBrokerageAccount}
      />
      <ConfirmAccountingYear
        companyId={gmbh.id}
        bookings={bookings}
        onConfirmBooking={onConfirmBooking}
      />
      {gmbh.hasWepaStorage && (
        <>
          <DocumentSection
            testid="wepa"
            storageConfig={wepaStructure}
            gmbh={gmbh}
            nodeList={gmbh.wepaNodeList}
          />
        </>
      )}
      <FeatureFlag name={FeatureFlags.WepaBookingsDownloadForClient}>
        {getWepaBookingsInformation()}
      </FeatureFlag>
      <FeatureFlag name={FeatureFlags.WepaDirect}>
        {isWepaDirectClient && (
          <CompanyAccountingFrameworkCard
            t={t}
            gmbh={gmbh}
            handleEditField={(field) => () => history.push(`/client/entities/${gmbh.id}/${field}`)}
          />
        )}
      </FeatureFlag>
      <FeatureFlag name={FeatureFlags.WepaBookingsDownloadForClient}>
        <div className={"wepa-general-data__bookings-table"}>
          <WepaBookingsCard gmbh={gmbh} asUser={UserRole.Client} />
        </div>
      </FeatureFlag>
    </div>
  );
};

export default withTranslationReady(["generic"])(WepaGeneralData);
