import * as Yup from "yup";

export const RolesSectionValidationSchema = () =>
  Yup.object().shape({
    roles: Yup.array().of(
      Yup.object().shape({
        share: Yup.number()
          .max(100, "generic:percentage-ownership-max-100")
          .min(0, "generic:percentage-ownership-min-0")
      })
    )
  });
