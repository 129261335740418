import React from "react";
import { MyGmbHsBanners } from "./MyGmbHsBanners";
import { useQuery } from "react-apollo";
import { MY_LOW_TAX_BROKER_ORDER } from "lib/api/queries/MyLowTaxBrokerOrder";
import { MyLowTaxBrokerOrder } from "lib/api/queries/graphql/MyLowTaxBrokerOrder";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";

export const MyGmbHsBannersContainer = () => {
  const myLowTaxBrokerOrderQuery = useQuery<MyLowTaxBrokerOrder>(MY_LOW_TAX_BROKER_ORDER);

  return (
    <RequestFeedbackHandler requests={[myLowTaxBrokerOrderQuery]}>
      <MyGmbHsBanners order={myLowTaxBrokerOrderQuery.data?.myLowTaxBrokerOrder} />
    </RequestFeedbackHandler>
  );
};
