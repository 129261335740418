import React from "react";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { DragDropSplashScreenPopUpIcon } from "sharedComponents/icons/DragDropSplashScreenPopUpIcon";

interface DragDropPopUpSplashScreen extends WithTranslation {
  folderName?: string | null;
}

const DragDropPopUpSplashScreen = ({ t, folderName }: DragDropPopUpSplashScreen) => {
  return (
    <div className="drag-drop-pop-up-splashscreen" data-testid="drag-drop-pop-up-splashscreen">
      <div
        className="drag-drop-pop-up-splashscreen__image"
        data-testid="drag-drop-pop-up-splashscreen-icon">
        <DragDropSplashScreenPopUpIcon />
      </div>
      <p
        className="drag-drop-pop-up-splashscreen__hint"
        data-testid="drag-drop-pop-up-splashscreen-hint">
        {t("client:documents.upload-pop-up-by-drop")}
        <br />
        <b>[{folderName}]</b>
      </p>
    </div>
  );
};

export default withTranslationReady(["client"])(DragDropPopUpSplashScreen);
