import React from "react";
import { WithTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { CompanyHeaderSection } from "client/components/CompanyHeaderSection/CompanyHeaderSection";
import CompanyFiscalYearCard from "sharedComponents/CompanyFields/CompanyFiscalYearCard/CompanyFiscalYearCard";
import CompanyRegistrationCard from "sharedComponents/CompanyFields/CompanyRegistrationCard/CompanyRegistrationCard";
import CompanyOverviewCard from "sharedComponents/CompanyFields/CompanyOverviewCard/CompanyOverviewCard";
import CompanyNumbersAndIdsCard from "sharedComponents/CompanyFields/CompanyNumbersAndIdsCard/CompanyNumbersAndIdsCard";
import CompanyPurposeCard from "sharedComponents/CompanyFields/CompanyPurposeCard/CompanyPurposeCard";
import WepaBookingsCard from "sharedComponents/CompanyFields/CompanyWepaBookingsCard/WepaBookingsCard";
import CompanyClientNumberCard from "sharedComponents/CompanyFields/CompanyClientNumberCard/CompanyClientNumberCard";
import CompanyBankCard from "sharedComponents/CompanyFields/CompanyBankCard/CompanyBankCard";
import { Company } from "lib/types/types";
import { useHistory } from "react-router";
import { FeatureFlags, UserRole } from "global-query-types";
import { MyTaxAdvisorCompany } from "taxAdvisor/types/MyTaxAdvisorCompany";
import CompanyAccountingFrameworkCard from "sharedComponents/CompanyFields/CompanyAccountingFrameworkCard/CompanyAccountingFrameworkCard";
import DocumentSection from "client/components/DocumentSection/DocumentSection";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

interface Props extends WithTranslation {
  company: Company | null | undefined;
  goBackLink: string;
  myTaxAdvisorCompany: MyTaxAdvisorCompany | null | undefined;
}

const ThirdPartyTaxAdvisorClientOverviewSection = ({
  t,
  company,
  goBackLink,
  myTaxAdvisorCompany
}: Props) => {
  const history = useHistory();

  if (!company) return null;

  const gmbh = new GmbHDetails(company);

  const handleEditField = (field: string) => {
    return () => history.push(`/third-party-tax-advisor/clients/${gmbh.id}/${field}`);
  };

  return (
    <>
      <CompanyHeaderSection gmbh={gmbh} goBackLink={goBackLink} showTabbedNavigation={false} />
      <Container className="my-5">
        <Row>
          <Col>
            <WepaBookingsCard
              gmbh={gmbh}
              asUser={UserRole.ThirdPartyTaxAdvisor}
              myTaxAdvisorCompany={myTaxAdvisorCompany}
            />
            <CompanyClientNumberCard
              t={t}
              gmbh={gmbh}
              field="third-party-tax-advisor-client-number"
              handleEditField={handleEditField}
              asUser={UserRole.ThirdPartyTaxAdvisor}
            />
            <CompanyFiscalYearCard
              t={t}
              gmbh={gmbh}
              handleEditField={handleEditField}
              asUser={UserRole.ThirdPartyTaxAdvisor}
            />
            <CompanyAccountingFrameworkCard t={t} gmbh={gmbh} handleEditField={handleEditField} />
            <CompanyOverviewCard t={t} gmbh={gmbh} />
            <CompanyRegistrationCard t={t} gmbh={gmbh} />
            <CompanyNumbersAndIdsCard t={t} gmbh={gmbh} />
            <CompanyPurposeCard t={t} gmbh={gmbh} />
            <CompanyBankCard t={t} gmbh={gmbh} />

            <FeatureFlag name={FeatureFlags.PTAWepaDocuments}>
              <DocumentSection gmbh={gmbh} nodeList={gmbh.wepaNodeList} testid="wepa" />
            </FeatureFlag>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withTranslationReady(["client", "generic"])(
  ThirdPartyTaxAdvisorClientOverviewSection
);
