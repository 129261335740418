import React from "react";
import CompanyNameOrUnnamed from "../../../components/common/CompanyNameOrUnnamed";
import { MembersList } from "../../../components/common/MembersList";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";
import { formatDate } from "../../../common/formatters";
import { PendingText } from "../../../components/client/company/components/PendingText";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { CompanyCardProps } from "../../../lib/types/types";
import { RideCardBodySeparator } from "../../RideCardBodySeparator/RideCardBodySeparator";

interface GmbHOverviewDetailsProps extends CompanyCardProps {}

const CompanyOverviewCard = ({ t, gmbh, handleEditField }: GmbHOverviewDetailsProps) => {
  return (
    <RideSectionCard
      data-testid="gmbh-overview-details"
      title={t("client:company:overview")}
      className="shadow-none">
      <strong>
        <CompanyNameOrUnnamed company={gmbh.nameObj()} />
      </strong>

      <MembersList t={t} members={gmbh.directors} showBirthDate={true} />

      {gmbh.hasAddress && (
        <div data-testid="company-address">
          {gmbh.streetLine2} {gmbh.street} <br />
          {gmbh.postalCode} {gmbh.city}
        </div>
      )}

      <div className="pt-3" data-testid="company-legal-form">
        {t("generic:legal-form")}: {t(`generic:legalForm.${gmbh.legalForm}`)}
      </div>

      <RideCardBodySeparator />

      <RideCardValuePair
        name={t("generic:notarization-date")}
        value={gmbh.notarizationDate ? formatDate(gmbh.notarizationDate) : <PendingText t={t} />}
        data-testid="company-notarization-date"
        onEdit={handleEditField?.("notarization-date")}
        isLocked={gmbh.locked}
        lockTooltipContent={t("generic:fieldLocked")}
      />
    </RideSectionCard>
  );
};

export default CompanyOverviewCard;
