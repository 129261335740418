import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { TaskStatesEnum } from "global-query-types";
import ProcessGroup from "../Company/tasks/ProcessGroup/ProcessGroup";
import { ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import { getCompanyCreationSteps_getCompanyCreationSteps } from "lib/api/queries/graphql/getCompanyCreationSteps";
import { CardContainer } from "../../../uiLibrary/designSystem/styles/CardContainer/CardContainer";

interface ProcessStatesSectionProps extends WithTranslation {
  processSteps: getCompanyCreationSteps_getCompanyCreationSteps[];
  handleTaskStateChange: (
    ref: string,
    state: TaskStatesEnum,
    options?: ActionableTaskOptions
  ) => Promise<void>;
}

export const CompanyProcessStatesSection = ({
  processSteps,
  handleTaskStateChange
}: ProcessStatesSectionProps) => {
  return (
    <div data-testid="process-state-section" className="company-process-states-section pt-6">
      <Container>
        <CardContainer className="company-process-states-section__card-container">
          <Row noGutters>
            <Col>
              {processSteps
                .filter((group) => group.state !== TaskStatesEnum.NOT_REQUIRED)
                .map((group, index) => {
                  const nextGroup = processSteps[index + 1];
                  return (
                    <ProcessGroup
                      key={index}
                      handleTaskStateChange={handleTaskStateChange}
                      index={index}
                      group={group}
                      nextGroupState={nextGroup?.state}
                    />
                  );
                })}
            </Col>
          </Row>
        </CardContainer>
      </Container>
    </div>
  );
};

export default withTranslation(["client", "company-founding-steps"])(CompanyProcessStatesSection);
