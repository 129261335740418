import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CompanyTitle } from "./FormFields/viewFormFields/TitleFields";
import { CompanyMembershipShare } from "./FormFields/viewFormFields/OwnershipShareFields";
import { CompanyLegalRegistration } from "./FormFields/viewFormFields/LegalRegistrationFields";
import { CompanyTypeCapital } from "./FormFields/viewFormFields/TypeCapitalFields";
import { BusinessAddress } from "./FormFields/viewFormFields/AddressFields";
import { getCompanyOwner, getCompanyShare, getOwnerSince } from "./companyInitialValues";
import DeleteWithConfirmation from "../../../generic/DeleteWithConfirmation";
import { DELETE_COMPANY } from "../../Mutations";

export const CompanyViewLayout = ({ t, toggleEditLayout, companyData, backUrl, history }) => {
  const companyShare = getCompanyShare(companyData);
  const companyOwner = getCompanyOwner(companyData);
  const ownerSinceDateValue = getOwnerSince(companyData);
  return (
    <Container>
      <Row className="justify-content-between">
        <Col>
          <Link to={backUrl} className="btn btn-link pl-0" data-testid="back">
            {t("generic:back")}
          </Link>
        </Col>
        {!companyData["locked"] && (
          <Col className="col-auto">
            <Button variant="primary" data-testid="edit" onClick={toggleEditLayout}>
              {t("generic:edit")}
            </Button>
          </Col>
        )}
        <Col className="col-auto">
          <DeleteWithConfirmation
            title={t("user-company:delete-confirmation")}
            successTitle={t("user-company:delete-success")}
            mutation={DELETE_COMPANY}
            variables={{ id: companyData.id }}
            onDone={() => history.push(backUrl)}
          />
        </Col>
      </Row>
      <div className="mt-4">
        <CompanyTitle t={t} companyData={companyData} />
        <CompanyMembershipShare
          t={t}
          companyData={companyData}
          companyShare={companyShare}
          companyOwner={companyOwner}
          ownerSinceDateValue={ownerSinceDateValue}
        />
        <CompanyLegalRegistration t={t} companyData={companyData} />
        <CompanyTypeCapital t={t} companyData={companyData} />
        <BusinessAddress t={t} companyData={companyData} />
      </div>
    </Container>
  );
};

export default CompanyViewLayout;
