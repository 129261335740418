import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import {
  ButtonRadius,
  ButtonSize,
  ButtonWidth,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { BlankPage, WidthVariantEnum } from "../templates/BlankPage/BlankPage";
import { useHistory } from "react-router-dom";

interface MobileViewErrorProps extends WithTranslation {}

const MobileViewError = ({ t }: MobileViewErrorProps) => {
  const history = useHistory();

  return (
    <BlankPage variant={WidthVariantEnum.NARROW}>
      <div className="mobile-view-error" data-testid="mobile-view-error">
        <div className={"mobile-view-error__title-image"}>
          <img
            data-testid={"mobile-view-error-title-image"}
            src="/assets/sharedComponents/new-connection-icon.svg"
            alt="icon"
          />
        </div>
        <div className={"mobile-view-error__title"} data-testid={"mobile-view-error-title"}>
          {t("generic:mobile-view-error.title")}
        </div>
        <div className={"mobile-view-error__subtitle"} data-testid={"mobile-view-error-subtitle"}>
          {t("generic:mobile-view-error.subtitle")}
        </div>
        <div
          className={"mobile-view-error__back-button"}
          data-testid={"mobile-view-error-back-button"}>
          <RideButtonDeprecated
            width={ButtonWidth.FULL}
            size={ButtonSize.Medium}
            radius={ButtonRadius.Soft}
            data-testid={"back-button"}
            onClick={() => history.push("/")}>
            {t("generic:mobile-view-error.back-to-my-dashboard")}
          </RideButtonDeprecated>
        </div>
      </div>
    </BlankPage>
  );
};

export default withTranslationReady(["generic"])(MobileViewError);
