import React from "react";
import { Accordion } from "react-bootstrap";
import { AccordionElement } from "../../CompanyCreation/Steps/AccordionElement";
import {
  adminService,
  adminServiceNoPackageSelection,
  arrayOfStepsInAdminService,
  getCompletedSteps
} from "../../CompanyCreation/getCompletedSteps";
import { CompanyForm } from "./CompanyInfo/CompanyForm";
import {
  MyCompanyCreationQuery,
  MyCompanyCreationQuery_myCompanyCreation,
  MyCompanyCreationQueryVariables
} from "../../CompanyCreation/graphql/MyCompanyCreationQuery";
import { TFunction } from "i18next";
import { ApolloQueryResult } from "apollo-client";
import { PersonalInfoForm } from "./PersonalInfo/PersonalInfoForm";
import { PackageTypeForm } from "./PackageType/PackageTypeForm";
import { CompleteOrderForm } from "./CompleteOrder/CompleteOrderForm";
import { FeatureFlags, FlowTypeEnum } from "../../../../global-query-types";
import { RefetchAndResetActiveStep } from "../../CompanyCreation/RefetchAndResetActiveStep";
import { DisabledFeatureFlag } from "../../../../sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

export interface ViewAdminServiceStepsProps {
  t: TFunction;
  showCreationSteps: boolean;
  currentStep: string;
  myCompanyCreationDetails: MyCompanyCreationQuery_myCompanyCreation;
  refetchMyCompanyCreation: (
    variables?: MyCompanyCreationQueryVariables
  ) => Promise<ApolloQueryResult<MyCompanyCreationQuery>>;
  currentFlowType: FlowTypeEnum;
}

export const ViewAdminServiceSteps = ({
  t,
  showCreationSteps,
  currentStep,
  myCompanyCreationDetails,
  refetchMyCompanyCreation,
  currentFlowType
}: ViewAdminServiceStepsProps) => {
  const completedSteps = getCompletedSteps(currentStep, arrayOfStepsInAdminService);

  const lastStep = completedSteps[completedSteps.length - 1] ?? adminService.STEP_ONE;

  const { refetchAndResetStep, toggleActiveKey, activeKey } = RefetchAndResetActiveStep({
    lastStep,
    refetchMyCompanyCreation,
    currentFlowType
  });

  return (
    <Accordion activeKey={activeKey} data-testid="accordion-steps" className="mt-5">
      <AccordionElement
        headerText={t("admin-service:steps-title.shareholder")}
        eventKey={adminService.STEP_ONE}
        onToggle={toggleActiveKey}
        buttonText={"1"}
        disabled={showCreationSteps && !completedSteps.includes(adminService.STEP_ONE)}>
        <CompanyForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          refetchAndResetStep={refetchAndResetStep}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <AccordionElement
        headerText={t("admin-service:steps-title.managing-director")}
        eventKey={adminService.STEP_TWO}
        onToggle={toggleActiveKey}
        buttonText={"2"}
        disabled={!completedSteps.includes(adminService.STEP_TWO)}>
        <PersonalInfoForm
          t={t}
          myCompanyCreationDetails={myCompanyCreationDetails}
          refetchAndResetStep={refetchAndResetStep}
          currentFlowType={currentFlowType}
        />
      </AccordionElement>
      <DisabledFeatureFlag name={FeatureFlags.RemoveChoosePackageFromASOrder}>
        <AccordionElement
          headerText={t("admin-service:steps-title.package-type")}
          eventKey={adminService.STEP_THREE}
          onToggle={toggleActiveKey}
          buttonText={"3"}
          disabled={!completedSteps.includes(adminService.STEP_THREE)}>
          <PackageTypeForm
            t={t}
            myCompanyCreationDetails={myCompanyCreationDetails}
            refetchAndResetStep={refetchAndResetStep}
            currentFlowType={currentFlowType}
          />
        </AccordionElement>
        <AccordionElement
          headerText={t("admin-service:steps-title.order")}
          eventKey={adminService.STEP_FOUR}
          onToggle={toggleActiveKey}
          buttonText={"4"}
          disabled={!completedSteps.includes(adminService.STEP_FOUR)}>
          <CompleteOrderForm
            t={t}
            myCompanyCreationDetails={myCompanyCreationDetails}
            refetchAndResetStep={refetchAndResetStep}
            currentFlowType={currentFlowType}
          />
        </AccordionElement>
      </DisabledFeatureFlag>
      <FeatureFlag name={FeatureFlags.RemoveChoosePackageFromASOrder}>
        <AccordionElement
          headerText={t("admin-service:steps-title.order")}
          eventKey={adminServiceNoPackageSelection.STEP_THREE}
          onToggle={toggleActiveKey}
          buttonText={"3"}
          disabled={!completedSteps.includes(adminServiceNoPackageSelection.STEP_THREE)}>
          <CompleteOrderForm
            t={t}
            myCompanyCreationDetails={myCompanyCreationDetails}
            refetchAndResetStep={refetchAndResetStep}
            currentFlowType={currentFlowType}
          />
        </AccordionElement>
      </FeatureFlag>
    </Accordion>
  );
};
