import React from "react";
import { Col, Row } from "react-bootstrap";
import { companyDetailsByCompanyId_companyById } from "../../../../graphql/companyDetailsByCompanyId";
import { TFunction } from "i18next";
import { formatCompanyName } from "../../../../../../common/formatters/CompanyNameFormatter";

export interface IProps {
  companyData: companyDetailsByCompanyId_companyById;
  t: TFunction;
}

export const CompanyTitle = ({ companyData, t }: IProps) => {
  return (
    <Row>
      <Col>
        <h2 data-testid={"title"}>{formatCompanyName(t, companyData)}</h2>
      </Col>
    </Row>
  );
};
