import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "lib/api/queries/graphql/NomineeCompanyDetails";
import { ApolloQueryResult } from "apollo-client";
import ServiceInvoiceSection, {
  ServiceInvoiceData
} from "../ServiceInvoiceSection/ServiceInvoiceSection";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { UpdateWepaServiceInvoiceDataByCompany } from "../../../lib/api/mutations/UPDATE_WEPA_SERVICE_INVOICE_DATA_BY_COMPANY";

interface WepaServiceInvoiceSectionContainerProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
  refetch: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

const WepaServiceInvoiceSectionContainer = ({
  t,
  company,
  refetch
}: WepaServiceInvoiceSectionContainerProps) => {
  const [updateWepaServiceInvoiceDataMutation, updateWepaServiceInvoiceDataRequest] =
    UpdateWepaServiceInvoiceDataByCompany();

  const handleSubmit = async (values: ServiceInvoiceData) => {
    await updateWepaServiceInvoiceDataMutation({
      variables: {
        ...values,
        companyId: company.id
      }
    });
    await refetch();
  };

  return (
    <RequestFeedbackHandler requests={[updateWepaServiceInvoiceDataRequest]}>
      <ServiceInvoiceSection
        title={t("nominee-dashboard:company.invoice.wepa-service.title")}
        service="wepa"
        serviceInvoiceData={company.wepaServiceInvoiceData}
        onSubmit={handleSubmit}
      />
    </RequestFeedbackHandler>
  );
};

export default withTranslationReady(["nominee-dashboard"])(WepaServiceInvoiceSectionContainer);
