import React from "react";
import { ApolloQueryResult } from "apollo-client";
import { TFunction } from "i18next";
import { NomineeCompanyDetails } from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { ButtonVariant } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { RideButtonWithPrompt } from "../../../uiLibrary/v2/components/RideButtonWithPrompt/RideButtonWithPrompt";
import { ConfirmationModalType } from "../../../uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";

interface ResetStoragePermissionsProps {
  t: TFunction;
  resetStoragePermissions: () => Promise<void>;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

export const ResetStoragePermissions = ({
  t,
  refetchCompany,
  resetStoragePermissions
}: ResetStoragePermissionsProps) => {
  const onClick = async () => {
    await resetStoragePermissions();
    await refetchCompany();
  };

  return (
    <RideButtonWithPrompt
      data-testid="reset-storage-permissions"
      type="button"
      variant={ButtonVariant.Danger}
      onClick={onClick}
      prompt={{
        type: ConfirmationModalType.DANGER,
        confirmLabel: t("generic:delete"),
        title: t("generic:storage-permissions.reset-prompt.title"),
        message: t("generic:storage-permissions.reset-prompt.message")
      }}>
      {t("generic:storage-permissions.reset")}
    </RideButtonWithPrompt>
  );
};
