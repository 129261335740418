export enum BusinessTypeEnum {
  "ARCHITECTURE_ENGINEERING" = "Architecture/Engineering",
  "ARTS_DESIGN" = "Arts/Design",
  "BUSINESS_NON_FINANCE" = "Business, Non-Finance",
  "COMMUNITY_SOCIAL_SERVICE" = "Community/Social Service",
  "COMPUTER_INFORMATION_TECHNOLOGY" = "Computer/Information Technology",
  "CONSTRUCTION_EXTRACTION" = "Construction/Extraction",
  "EDUCATION_TRAINING_LIBRARY" = "Education/Training/Library",
  "FARMING_FISHING_FORESTRY" = "Farming, Fishing and Forestry",
  "FINANCE_BROKER_DEALER_BANK" = "Finance/Broker Dealer/Bank",
  "FOOD_PREPARATION_AND_SERVICING" = "Food Preparation and Servicing",
  HEALTHCARE = "Healthcare",
  "INSTALLATION_MAINTENANCE_AND_REPAIR" = "Installation, Maintenance, and Repair",
  LEGAL = "Legal",
  "LIFE_PHYSICAL_AND_SOCIAL_SCIENCE" = "Life, Physical and Social Science",
  "MARIJUANA_CANNABIS" = "Marijuana/Cannabis",
  "MEDIA_AND_COMMUNICATIONS" = "Media and Communications",
  "MILITARY_LAW_ENFORCEMENT_GOVERNMENT_PROTECTIVE_SERVICE" = "Military/Law Enforcement, Government, Protective Service",
  OTHER = "Other",
  "PERSONAL_CARE_SERVICE" = "Personal Care/Service",
  "PRODUCTION_AND_MANUFACTURING" = "Production and Manufacturing",
  "TRANSPORTATION_AND_MATERIAL_MOVING" = "Transportation and Material Moving"
}
