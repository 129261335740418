import { TFunction } from "i18next";
import { FlowTypeEnum, ResumeLinkType } from "../../../global-query-types";
import React, { useState } from "react";
import { copyToClipboard } from "../../../common/copyToClipboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import { GenerateResumeTokenLink } from "../../../lib/api/mutations/graphql/GenerateResumeTokenLink";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

type Props = {
  t: TFunction;
  currentStep?: string;
  flowType?: FlowTypeEnum;
  linkType: ResumeLinkType;
  generateResumeLink: (linkType: ResumeLinkType) => Promise<GenerateResumeTokenLink | undefined>;
};

export const ResumeLink = ({ t, currentStep, flowType, generateResumeLink, linkType }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      const data = await generateResumeLink(linkType);
      setCopied(copyToClipboard(data?.generateResumeTokenLink ?? ""));
      setTimeout(() => setCopied(false), 3000);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const tooltipText = getTooltipText(copied);

  const buttonText = getButtonText(t, currentStep, flowType, linkType);

  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={<Tooltip id={"copyTooltip"}>{tooltipText}</Tooltip>}>
      <RideButtonDeprecated
        onClick={() => handleCopy()}
        data-testid="resume-link-button"
        variant={ButtonVariant.Secondary}
        size={ButtonSize.ExtraSmall}>
        {buttonText}
      </RideButtonDeprecated>
    </OverlayTrigger>
  );
};

const getTooltipText = (copied: boolean) => {
  let tooltipText;
  if (copied) {
    tooltipText = "Copied";
  } else {
    tooltipText = "Copy";
  }
  return tooltipText;
};

const getButtonText = (
  t: TFunction,
  currentStep: string | undefined,
  flowType: FlowTypeEnum | undefined,
  linkType: ResumeLinkType
) => {
  if (linkType === ResumeLinkType.MAGIC) {
    return t("nominee-dashboard:company.magic-link");
  }

  if (currentStep === "EMAIL_CONFIRMED" || flowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING) {
    return t("nominee-dashboard:company-creation.onboarding-resume-link");
  }

  return t("nominee-dashboard:company-creation.resume-link");
};
