import { useQuery } from "@apollo/react-hooks";
import { OperationVariables } from "@apollo/react-common";
import { DocumentNode } from "graphql";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import { getPaymentsContext } from "lib/api/payment/context";

export const usePaymentQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => {
  return useQuery(query, { ...options, ...getPaymentsContext() });
};
