import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { WepaListContainer } from "../../components/WepaList/WepaListContainer";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { getWepaManagerOauthRedirectionUrl } from "common/Authentication/getWepaOauthRedirection";

const WepaServiceScene = ({ t }: WithTranslation) => {
  return (
    <>
      <Row>
        <Col lg={3} sm={6}>
          <h2>{t("header:wepa-companies")}</h2>
        </Col>
        <Col className="d-flex align-items-center">
          <div>
            <RideButton
              label={t("header:open-wepa-manager")}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
              onClick={() => {
                window.open(getWepaManagerOauthRedirectionUrl(), "_blank");
              }}></RideButton>
          </div>
        </Col>
      </Row>
      <WepaListContainer />
    </>
  );
};

export default withTranslation("header")(WepaServiceScene);
