import React, { useCallback, useEffect, useState } from "react";
import TaxAdvisorsListFilters, {
  TaxAdvisorsListFilterFields
} from "../TaxAdvisorsListFilters/TaxAdvisorsListFilters";
import { TaxAdvisorsList } from "./TaxAdvisorsList";
import { getEndpoint } from "../../../common/GraphqlClient/httpLink";
import { getToken } from "../../../common/Authentication/getToken";
import { GetTaxAdvisorCompanies_taxAdvisorCompanies } from "../../../lib/api/queries/graphql/GetTaxAdvisorCompanies";
import LoadingAlert from "../../../components/generic/LoadingAlert";
import { TFunction } from "i18next";

export const useTaxAdvisorCompaniesWithClients = (
  advisorsListFilterFields: TaxAdvisorsListFilterFields
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetTaxAdvisorCompanies_taxAdvisorCompanies[]>([]);

  const fetchTaxAdvisors = useCallback(async () => {
    const response = await fetch(
      `${getEndpoint()}/api/tax-advisor-company-with-clients${
        !!advisorsListFilterFields?.brokerageAccountValidity
          ? `?brokerageAccountValidity=${advisorsListFilterFields.brokerageAccountValidity}`
          : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        mode: "cors",
        credentials: "include"
      }
    );

    let taxAdvisorCompanies =
      (await response.json()) as GetTaxAdvisorCompanies_taxAdvisorCompanies[];
    setData(taxAdvisorCompanies);
  }, [advisorsListFilterFields]);

  useEffect(() => {
    setLoading(true);
    fetchTaxAdvisors().then(() => setLoading(false));
  }, [advisorsListFilterFields, fetchTaxAdvisors]);

  return { data, loading };
};

export interface TaxAdvisorsListContainerProps {
  t: TFunction;
}

export const TaxAdvisorsListContainer = ({ t }: TaxAdvisorsListContainerProps) => {
  const [filters, setFilters] = useState<TaxAdvisorsListFilterFields>({});

  const { data, loading } = useTaxAdvisorCompaniesWithClients(filters);

  return (
    <>
      <TaxAdvisorsListFilters onFilter={setFilters} />
      {loading ? (
        <LoadingAlert t={t} noLoadingAnimation={false} />
      ) : (
        <TaxAdvisorsList taxAdvisors={data} />
      )}
    </>
  );
};
