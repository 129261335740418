import React from "react";

export const RideSilverIcon = () => (
  <svg width="64" height="16" viewBox="0 0 64 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4026 10.6115L22.5153 16H17.7426L14.2872 11.3827H11.8191V16H7.84723V7.82352H14.7579C16.3323 7.82352 17.4847 6.95569 17.4847 5.66831C17.4847 4.42532 16.3332 3.55749 14.7579 3.55749H14.3179V0.0631068H14.8285C16.8285 0.0631068 18.4455 0.579713 19.646 1.58638C20.8464 2.59304 21.4553 3.95441 21.4553 5.66657C21.4557 7.84528 20.3511 9.65144 18.4026 10.6115ZM25.0583 15.9922V0H28.783V15.9922H25.0583ZM45.9702 2.23355C47.6136 3.71765 48.4234 5.62261 48.4234 7.94843C48.4234 10.3247 47.6149 12.2532 45.9702 13.7586C44.3268 15.2384 42.2332 15.9922 39.6843 15.9922H33.1851V0H39.6843C42.2332 0 44.3268 0.752495 45.9702 2.23355ZM42.9949 11.1482C43.8766 10.2777 44.3268 9.2197 44.3268 7.94843C44.3268 6.70413 43.8766 5.66744 42.9949 4.84618C42.1132 3.99924 40.9949 3.57533 39.6843 3.57533H37.2098V12.419H39.6843C40.9945 12.419 42.114 11.9955 42.9949 11.1482ZM64 3.45739H55.8183V6.37336H62.9787V9.75981H55.8183V12.533H64V15.9904H51.8094V0H64V3.45739ZM0 15.9917V11.2287H3.72468V15.9922L0 15.9917Z"
      fill="#A3A3A3"
    />
  </svg>
);
