import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { PageLayout } from "../../../sharedComponents/templates/PageLayout/PageLayout";
import ShelfCompanyOrderDetailsContainer from "../ShelfCompanyOrderDetails/ShelfCompanyOrderDetailsContainer";
import {
  getOrderDetails_getOrderDetails_order,
  getOrderDetails_getOrderDetails_reservedCompany
} from "../../../lib/api/queries/graphql/getOrderDetails";
import { RideBadgeColorsEnum, RideBadgeStatus } from "uiLibrary/components/RideBadgeStatus";

interface ShelfCompanyOrderDetailsLayoutProps extends WithTranslation {
  order: getOrderDetails_getOrderDetails_order | null;
  orderCompany?: getOrderDetails_getOrderDetails_reservedCompany | null;
  onRefresh: () => Promise<void>;
}

const ShelfCompanyOrderDetailsLayout = ({
  order,
  orderCompany,
  onRefresh
}: ShelfCompanyOrderDetailsLayoutProps) => {
  return (
    <PageLayout
      className="ride-layout"
      pageTitle={order?.extra.business.companyName}
      dataTestId="shelf-company-order-details-layout"
      headerContent={getOrderStatusBadge(order?.status)}
      mainContent={
        <ShelfCompanyOrderDetailsContainer
          order={order}
          orderCompany={orderCompany}
          onRefresh={async () => onRefresh()}
        />
      }
    />
  );
};

const getOrderStatusBadge = (status: string | undefined) => {
  switch (status) {
    case "Assigned":
      return <RideBadgeStatus label={"Reserved"} color={RideBadgeColorsEnum.WARNING} />;
    case "Transferred":
      return <RideBadgeStatus label={"Transferred"} color={RideBadgeColorsEnum.SUCCESS} />;
  }
};

export default withTranslationReady(["generic"])(ShelfCompanyOrderDetailsLayout);
