import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  GetMyFormerClientAssignments,
  GetMyFormerClientAssignmentsVariables
} from "./graphql/GetMyFormerClientAssignments";
import { FormerClientAssignmentType } from "global-query-types";

const GET_MY_FORMER_CLIENT_ASSIGNMENTS = gql`
  query GetMyFormerClientAssignments($type: FormerClientAssignmentType) {
    getMyFormerClientAssignments(type: $type) {
      id
      startFiscalYear
      endFiscalYear
      client {
        id
        name
        legalForm
        clientNumber
        fiscalYear
      }
    }
  }
`;

export const GetMyFormerClientAssignmentsQuery = (type?: FormerClientAssignmentType) =>
  useQuery<GetMyFormerClientAssignments, GetMyFormerClientAssignmentsVariables>(
    GET_MY_FORMER_CLIENT_ASSIGNMENTS,
    {
      variables: {
        type
      }
    }
  );
