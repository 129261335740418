import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../uiLibrary/designSystem/RideButton/RideButton";
import { CompanyById_companyById } from "../../../lib/api/queries/graphql/CompanyById";

export interface AdminPackageSelectorProps extends WithTranslation {
  company: CompanyById_companyById | null | undefined;
  onClickResetPackage: (companyId: string | undefined) => Promise<void>;
}

const AdminPackageSelector = ({ company, t, onClickResetPackage }: AdminPackageSelectorProps) => {
  let isDisabled = !company?.customerSignedAdminServiceContract;

  const onClick = async () => {
    await onClickResetPackage(company?.id);
  };

  return (
    <div data-testid="package-selector">
      <RideButton
        data-testid={"package-selector-button"}
        label={t("nominee-dashboard:package-selector.resetButtonLabel")}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.BIG}
        disabled={isDisabled}
        onClick={onClick}
      />
      <br />
      <p data-testid={"package-selector-action"}>
        {isDisabled
          ? t("nominee-dashboard:package-selector.resetButtonIsDisabledMessage")
          : t("nominee-dashboard:package-selector.resetButtonIsActiveMessage")}
      </p>
    </div>
  );
};

export default withTranslationReady(["nominee-dashboard"])(AdminPackageSelector);
