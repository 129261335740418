import React from "react";

export enum RideStickerBackgroundColorVariant {
  Transparent = "Transparent",
  RideGreen = "RideGreen"
}

export interface RideStickerProps {
  icon: JSX.Element;
  text: string;
  className?: string;
  stickerColorVariant?: RideStickerBackgroundColorVariant;
}

export const RideSticker = ({
  icon,
  text,
  className = "",
  stickerColorVariant: stickerColor = RideStickerBackgroundColorVariant.Transparent
}: RideStickerProps) => {
  return (
    <button
      className={`ride-sticker ride-sticker--${stickerColor} ${className}`}
      data-testid="ride-sticker">
      <div className="ride-sticker__icon" data-testid="ride-sticker-icon">
        {icon}
      </div>
      <div className="ride-sticker__text" data-testid="ride-sticker-text">
        {text}
      </div>
    </button>
  );
};
