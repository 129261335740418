import React from "react";
import ThirdPartyTaxAdvisorMigrateSuccess from "thirdPartyTaxAdvisor/components/WepaReform/ThirdPartyTaxAdvisorMigrateSuccess/ThirdPartyTaxAdvisorMigrateSuccess";
import { useHistory } from "react-router";
import { useQueryParams } from "lib/hooks/useQueryParams";

export const ThirdPartyTaxAdvisorMigrateSuccessContainer = () => {
  const history = useHistory();
  const sessionId = useQueryParams().get("session_id");

  const backToMyClients = () => {
    history.push(`/third-party-tax-advisor/clients`);
  };

  return (
    <ThirdPartyTaxAdvisorMigrateSuccess
      backToMyClients={backToMyClients}
      stripeSessionId={sessionId}
    />
  );
};
