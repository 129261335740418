import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import {
  StepHeading,
  StepComponentProps
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { RideRadioSelect } from "uiLibrary/components/RideRadioSelect/RideRadioSelect";
import { TypeOfInvestedCapitalSchema } from "lib/validation/TypeOfInvestedCapitalSchema";

//TODO: DEPRECATED ridecapital/backlog#1912
const TypeOfInvestedCapitalStep = ({
  t,
  order,
  currentStep,
  saveData,
  controls
}: StepComponentProps<any> & WithTranslation) => {
  async function onSubmit() {
    currentStep.complete();

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues: {
      "type-of-invested-capital": order.extra?.investedCapitalType ?? ""
    },
    onSubmit,
    validationSchema: TypeOfInvestedCapitalSchema(t)
  });

  const options = Object.values(["risk-capital", "livelihood-capital"]).map((option) => ({
    value: option,
    label: t(`generic:ride-broker-onboarding.type-of-invested-capital.${option}`)
  }));

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.type-of-invested-capital.title")} />
        <RideRadioSelect name="type-of-invested-capital" options={options} columns={1} />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(TypeOfInvestedCapitalStep);
