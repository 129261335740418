import { OrderChaptersConfig } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import DeclareTaxInTheUSStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/DeclareTaxInTheUSStep/DeclareTaxInTheUSStep";
import BrokerSetupFailed from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/BrokerSetupFailed/BrokerSetupFailed";
import ConsentToHandlePersonalDataStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/ConsentToHandlePersonalDataStep/ConsentToHandlePersonalDataStep";
import BrokerSetupComplete from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/BrokerSetupComplete/BrokerSetupComplete";
import CompanyRegistryNumberStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyRegistryNumberStep/CompanyRegistryNumberStep";
import CompanyAddressStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyAddressStep/CompanyAddressStep";
import CompanyTaxNumberStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyTaxNumberStep/CompanyTaxNumberStep";
import CompanyLEINumberStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyLEINumberStep/CompanyLEINumberStep";
import CompanyInformationComplete from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyInformationComplete/CompanyInformationComplete";
import IntendedInitialInvestmentStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/IntendedInitialInvestmentStep/IntendedInitialInvestmentStep";
import IntendedInitialInvestmentContextStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/IntendedInitialInvestmentContextStep/IntendedInitialInvestmentContextStep";
import TypeOfInvestedCapitalStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/TypeOfInvestedCapitalStep/TypeOfInvestedCapitalStep";
import TypeOfInvestedCapitalDisclaimerStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/TypeOfInvestedCapitalDisclaimerStep/TypeOfInvestedCapitalDisclaimerStep";
import AreasOfInvestmentStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/AreasOfInvestmentStep/AreasOfInvestmentStep";
import ExperienceLevelStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/ExperienceLevelStep/ExperienceLevelStep";
import InvestingYearsStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/InvestingYearsStep/InvestingYearsStep";
import TradesPerYearStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/TradesLastThreeYearsStep/TradesLastThreeYearsStep";
import { CurrentLiquidNetWorthStep } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/CurrentLiquidNetWorthStep/CurrentLiquidNetWorthStep";
import EstimatedAnnualIncomeStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/EstimatedAnnualIncomeStep/EstimatedAnnualIncomeStep";
import EstimatedTotalAssetsStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/EstimatedTotalAssetsStep/EstimatedTotalAssetsStep";
import SourceOfWealthStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/SourceOfWealthStep/SourceOfWealthStep";
import SourcesOfWealthSpreadStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/SourcesOfWealthSpreadStep/SourcesOfWealthSpreadStep";
import BrokerComplianceComplete from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/BrokerComplianceComplete/BrokerComplianceComplete";
import ShareholdersList from "client/components/ChaptersOrderLayout/sharedComponents/ShareholdersList/ShareholdersList";
import { EntityGeneralInformation } from "client/components/ChaptersOrderLayout/sharedComponents/EntityGeneralInformation/EntityGeneralInformation";
import { EntityStatusInformation } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/EntityStatusInformation/EntityStatusInformation";
import { CitizenshipInformation } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/CitizenshipInformation/CitizenshipInformation";
import PersonAddress from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/PersonAddress/PersonAddress";
import { ReviewEntity } from "client/components/ChaptersOrderLayout/sharedComponents/ReviewEntity/ReviewEntity";
import ManagingDirectorsList from "client/components/ChaptersOrderLayout/sharedComponents/ManagingDirectorsList/ManagingDirectorsList";
import ReviewAndSubmitLowTaxBrokerContainer from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/ReviewAndSubmitLowTaxBroker/ReviewAndSubmitLowTaxBrokerContainer";
import ConsentToTradingOnOwnAccountStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/ConsentToTradingOnOwnAccountStep/ConsentToTradingOnOwnAccountStep";
import DefineShares from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/DefineShares/DefineShares";
import PersonalDocumentsList from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/PersonalDocumentsList/PersonalDocumentsList";
import { getFileStorage } from "lib/ports/FileStorage";
import { ShareholderIdOrPassport } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ShareholderIdOrPassportStep/ShareholderIdOrPassportStep";
import { ShareholderProofOfAddress } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ShareholderProofOfAddressStep/ShareholderProofOfAddressStep";
import ConfirmLegalDocuments from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ConfirmLegalDocuments/ConfirmLegalDocuments";
import CompanyBankAccountStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyBankAccountStep/CompanyBankAccountStep";
import SecurityQuestionsStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/SecurityQuestionsStep/SecurityQuestionsStep";
import { OrderProductTypeEnum } from "global-query-types";
import { CompanyProofOfAddress } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/CompanyProofOfAddressStep/CompanyProofOfAddressStep";
import CompanyDocumentsStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/CompanyDocumentsStep/CompanyDocumentsStep";
import CompanyHasMajorityShareholderContainer from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/CompanyHasMajorityShareholder/CompanyHasMajorityShareholderContainer";
import { CFDInfoBannerConfig } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/sharedComponents/CFDInfoBanner/CFDInfoBanner";
import AssetsAvailableToTrade from "client/components/FxFlatOrder/AssetsAvailableToTrade/AssetsAvailableToTrade";
import { ChooseHoldingCompanyStepContainer } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/HoldingCompany/ChooseHoldingCompanyStep/ChooseHoldingCompanyStepContainer";
import { ReviewHoldingCompanyDataStep } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/HoldingCompany/ReviewHoldingCompanyDataStep/ReviewHoldingCompanyDataStep";
import { AssignHoldingShareholdersDirectorsStep } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/HoldingCompany/AssignHoldingShareholdersDirectorsStep/AssignHoldingShareholdersDirectorsStep";
import SelectIdDocumentTypeStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/SelectIdDocumentTypeStep/SelectIdDocumentTypeStep";
import {
  IdDocumentVariant,
  ShareholderIdFile
} from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ShareholderIdFile/ShareholderIdFile";
import { ReviewShareholderIdDocument } from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/UploadDocuments/ReviewShareholderIdDocument/ReviewShareholderIdDocument";
import ConfirmationOfAcceptedBusinessActivitiesTradingStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerSetup/ConfirmationOfAcceptedBusinessActivitiesTradingStep/ConfirmationOfAcceptedBusinessActivitiesTradingStep";
import CompanyRegulatoryInformationStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/CompanyInformation/CompanyRegulatoryInformationStep/CompanyRegulatoryInformationStep";
import TradingIntentionStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/TradingIntentionStep/TradingIntentionStep";
import SourcesOfWealthUsedForFundsStep from "../../../../../client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/SourcesOfWealthUsedForFundsStep/SourcesOfWealthUsedForFundsStep";
import InvestmentObjectivesStep from "client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/BrokerCompliance/InvestmentObjectivesStep/InvestmentObjectivesStep";
import { EmployerInformation } from "../../../../../client/components/ChaptersOrderLayout/LowTaxBrokerAccount/chapters/Shareholders/EmployerInformationStep/EmployerInformationStep";

export const ORDER_CHAPTERS_CONFIG_LOW_TAX_BROKER: OrderChaptersConfig = {
  chapters: {
    "broker-setup": {
      icon: "brush",
      title: "generic:ride-broker-onboarding.chapters.setup"
    },
    "company-information": {
      icon: "business",
      title: "generic:ride-broker-onboarding.chapters.company-information"
    },
    compliance: {
      icon: "compass",
      title: "generic:ride-broker-onboarding.chapters.compliance"
    },
    "shareholder-details": {
      icon: "id-card",
      title: "generic:ride-broker-onboarding.chapters.shareholders"
    },
    "managing-directors": {
      icon: "grid",
      title: "generic:ride-broker-onboarding.chapters.managing-directors"
    },
    documents: {
      icon: "document-lines",
      title: "generic:company-setup.chapters.documents"
    },
    "review-and-submit": {
      icon: "rocket",
      title: "generic:ride-broker-onboarding.chapters.review-and-submit"
    },
    "holding-company-0": {
      icon: "business",
      title: "generic:ride-broker-onboarding.chapters.company-information"
    },
    "holding-company-1": {
      icon: "business",
      title: "generic:ride-broker-onboarding.chapters.company-information"
    },
    "holding-company-2": {
      icon: "business",
      title: "generic:ride-broker-onboarding.chapters.company-information"
    },
    "holding-company-3": {
      icon: "business",
      title: "generic:ride-broker-onboarding.chapters.company-information"
    }
  },
  steps: {
    //broker-setup
    "declare-tax-in-the-us": DeclareTaxInTheUSStep,
    "broker-setup-failed": BrokerSetupFailed, // dynamic. If declareTaxInTheUS is yes
    "consent-to-handle-personal-data": ConsentToHandlePersonalDataStep,
    "consent-to-trading-on-own-account": ConsentToTradingOnOwnAccountStep,
    "confirmation-of-accepted-business-activities-trading":
      ConfirmationOfAcceptedBusinessActivitiesTradingStep,
    "security-questions": SecurityQuestionsStep,
    "broker-setup-complete": BrokerSetupComplete,

    //company-information
    "company-regulatory-information": CompanyRegulatoryInformationStep,
    "broker-company-registry-number": CompanyRegistryNumberStep,
    "company-address": CompanyAddressStep,
    "company-bank-account": CompanyBankAccountStep,
    "company-tax-id": CompanyTaxNumberStep,
    "company-lei-number": CompanyLEINumberStep,
    "broker-company-completed": CompanyInformationComplete,

    //compliance
    "compliance-intended-initial-investment": IntendedInitialInvestmentStep,
    "compliance-intended-initial-investment-context": IntendedInitialInvestmentContextStep, // dynamic
    "compliance-type-of-invested-capital": TypeOfInvestedCapitalStep, //legacy orders (deprecated)
    "compliance-type-of-invested-capital-disclaimer": TypeOfInvestedCapitalDisclaimerStep,
    "compliance-liquid-net-worth": CurrentLiquidNetWorthStep,
    "compliance-trading-intention": TradingIntentionStep,
    "compliance-investment-objectives": InvestmentObjectivesStep,
    "compliance-experienced-investment-areas": AreasOfInvestmentStep,
    "assets-available-to-trade": AssetsAvailableToTrade,
    "compliance-investment-experience-level": ExperienceLevelStep,
    "compliance-investing-experience": InvestingYearsStep,
    "compliance-trades-per-year": TradesPerYearStep,
    "compliance-estimated-annual-income": EstimatedAnnualIncomeStep,
    "compliance-estimated-total-assets": EstimatedTotalAssetsStep,
    "compliance-source-of-wealth": SourceOfWealthStep,
    "compliance-percentage-of-total-wealth": SourcesOfWealthSpreadStep,
    "compliance-sources-of-wealth-used-for-funds": SourcesOfWealthUsedForFundsStep,
    "compliance-complete": BrokerComplianceComplete,

    //shareholder-details
    "company-has-majority-shareholders": CompanyHasMajorityShareholderContainer,
    "shareholders-list": ShareholdersList(OrderProductTypeEnum.BrokerageAccount),
    "review-and-adjust-shares": DefineShares,
    //for each shareholder:
    "shareholder-general-information": EntityGeneralInformation("shareholder"),
    "majority-shareholder-status-information": EntityStatusInformation("shareholder"),
    "shareholder-citizenship-information": CitizenshipInformation("shareholder"),
    "shareholder-residential-address": PersonAddress("shareholder"),
    "shareholder-employer-information": EmployerInformation("shareholder"),
    "shareholder-review": ReviewEntity("shareholder", true), //TODO: consider use ReviewShareholder

    //managing-directors
    "managing-directors-list": ManagingDirectorsList(true),
    //for each new managing director
    "employee-general-information": EntityGeneralInformation("employee"),
    "employee-status-information": EntityStatusInformation("employee"),
    "employee-citizenship-information": CitizenshipInformation("employee"),
    "employee-residential-address": PersonAddress("employee"),
    "employee-employer-information": EmployerInformation("employee"),
    "employee-review": ReviewEntity("employee", true),

    //documents
    "personal-documents-list": PersonalDocumentsList,
    "shareholder-id-or-passport": ShareholderIdOrPassport(getFileStorage()),
    "shareholder-proof-of-address": ShareholderProofOfAddress(getFileStorage()),
    "company-documents-list": CompanyDocumentsStep,
    "company-proof-of-address": CompanyProofOfAddress(getFileStorage()), //modal for upload proof of address
    "confirm-legal-documents": ConfirmLegalDocuments,

    //1992
    "select-id-document-type": SelectIdDocumentTypeStep,
    "shareholder-id-file": ShareholderIdFile(getFileStorage(), IdDocumentVariant.ID_FRONT),
    "shareholder-id-file-back": ShareholderIdFile(getFileStorage(), IdDocumentVariant.ID_BACK),
    "shareholder-passport-file": ShareholderIdFile(getFileStorage(), IdDocumentVariant.PASSPORT),
    "shareholder-id-document-review": ReviewShareholderIdDocument,

    "review-and-submit": ReviewAndSubmitLowTaxBrokerContainer,

    //holding
    "choose-holding-company": ChooseHoldingCompanyStepContainer,
    "review-holding-company-data": ReviewHoldingCompanyDataStep,
    "assign-holding-shareholders-directors": AssignHoldingShareholdersDirectorsStep
  },
  alerts: {
    cfd: CFDInfoBannerConfig
  },
  contextualHelpSuffix: "ltb-flow"
};
