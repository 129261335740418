import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { AlertType, RideAlertBar } from "uiLibrary/components/RideAlertBar/RideAlertBar";
import { CopyableInformation } from "client/components/CopyableInformation/CopyableInformation";
import RideModal, {
  RideModalBody,
  RideModalControls
} from "uiLibrary/designSystem/RideModal/RideModal";
import {
  ButtonRadius,
  ButtonWidth,
  RideButtonDeprecated
} from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export interface PayInModalProps {
  companyName: string;
  brokerageAccountNumber: string | null | undefined;
  onClose: () => void;
}

const moduleName = "pay-in-modal";

const PayInModal = ({
  t,
  companyName,
  brokerageAccountNumber,
  onClose
}: PayInModalProps & WithTranslation) => {
  return (
    <RideModal title={t("generic:low-tax-broker-dashboard.pay-in-modal.title")} onClose={onClose}>
      <RideModalBody>
        <PayInInfo companyName={companyName} brokerageAccountNumber={brokerageAccountNumber} />
      </RideModalBody>
      <RideModalControls>
        <RideButtonDeprecated
          width={ButtonWidth.FULL}
          radius={ButtonRadius.Soft}
          data-testid={"close-button"}
          onClick={onClose}
          type={"button"}>
          {t("generic:close")}
        </RideButtonDeprecated>
      </RideModalControls>
    </RideModal>
  );
};

export default withTranslationReady(["generic"])(PayInModal);

export interface PayInInfoProps {
  companyName: string;
  brokerageAccountNumber: string | null | undefined;
}

export const PayInInfo = withTranslationReady(["generic"])(
  ({ t, companyName, brokerageAccountNumber }: PayInInfoProps & WithTranslation) => (
    <div className={moduleName} data-testid={moduleName}>
      <span data-testid="description">
        {t("generic:low-tax-broker-dashboard.pay-in-modal.description")}
      </span>

      <span className={`${moduleName}__title`} data-testid={"heading"}>
        {t("generic:low-tax-broker-dashboard.pay-in-modal.heading")}
      </span>

      <div data-testid={"bank-name"} className={`${moduleName}__copyable-entry`}>
        <CopyableInformation
          label={t("generic:bank-name")}
          value={t("generic:low-tax-broker-dashboard.pay-in-modal.bank-name-value")}
          showValue={true}
        />
      </div>
      <div data-testid={"iban"} className={`${moduleName}__copyable-entry`}>
        <CopyableInformation
          label={t("generic:iban")}
          value={t("generic:low-tax-broker-dashboard.pay-in-modal.iban-value")}
          showValue={true}
        />
      </div>
      <div data-testid={"bic"} className={`${moduleName}__copyable-entry`}>
        <CopyableInformation
          label={t("generic:bic")}
          value={t("generic:low-tax-broker-dashboard.pay-in-modal.bic-value")}
          showValue={true}
        />
      </div>
      <div data-testid={"reference-message"} className={`${moduleName}__copyable-entry`}>
        <CopyableInformation
          label={t("generic:reference-message")}
          value={`${companyName}, ${
            brokerageAccountNumber ?? t("generic:brokerage-account-is-null")
          }`}
          showValue={true}
        />
      </div>

      <RideAlertBar
        className={`${moduleName}__time-information`}
        type={AlertType.INFO}
        message={
          <Trans
            i18nKey={"generic:low-tax-broker-dashboard.pay-in-modal.transfer-time-information"}
            components={{ Strong: <strong></strong> }}
          />
        }
      />
    </div>
  )
);
