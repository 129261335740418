import React from "react";
import { FormikProvider, useFormik } from "formik";
import EmailSchema from "../../../lib/validation/EmailSchema";
import { Col, Row } from "react-bootstrap";
import { textFieldFor } from "../../../components/generic/commonFields";
import { UserRoleDropdown } from "../UserRoleDropdown/UserRoleDropdown";
import SuccessAlert from "../../../components/generic/SuccessAlert";
import { WithTranslation, withTranslation } from "react-i18next";
import RequestFeedbackForm from "sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { MutationResult } from "react-apollo";
import { UserRole } from "../../../global-query-types";

interface InviteUserFormProps extends WithTranslation {
  successfulInvite: boolean;
  requests: MutationResult<any>[];
  onSubmit: (values, { resetForm }) => Promise<void>;
}

const InviteUserForm = ({ t, successfulInvite, requests, onSubmit }: InviteUserFormProps) => {
  const formik = useFormik({
    initialValues: { email: "", role: UserRole.Client },
    validateOnChange: true,
    validationSchema: EmailSchema,
    enableReinitialize: true,
    onSubmit: onSubmit
  });

  return (
    <div data-testid="invite-user-form" className="invite-user-form">
      <FormikProvider value={formik}>
        {successfulInvite && <SuccessAlert t={t} message="success-message" />}
        <RequestFeedbackForm
          requests={requests}
          submitLabel={t("form-button")}
          buttonClassName="invite-user-form-submit">
          <Row>
            <Col className="invite-user-form__col">
              {textFieldFor(t, formik, "email", "nominee-dashboard:person.email", {
                placeholder: "notary@ride.capital",
                formGroupClass: "invite-user-form__email"
              })}
            </Col>

            <Col>
              <UserRoleDropdown
                name="role"
                testId="role-dropdown"
                label={t("invite-user:user-type")}
                formik={formik}
              />
            </Col>
          </Row>
        </RequestFeedbackForm>
      </FormikProvider>
    </div>
  );
};

export default withTranslation(["invite-user", "generic", "errorCodes"])(InviteUserForm);
