export enum SourceOfWealthType {
  BUSINESS_OPERATION = "business-operation",
  OWNER_SHAREHOLDER_EQUITY_OR_LOAN_TO_COMPANY = "owner-shareholder-equity-or-loan-to-company",
  MARKETING_TRADING_PROFITS = "marketing-trading-profits",
  PROPERTY = "property",
  RETAINED_EARNINGS = "retained-earnings",
  OTHERS = "others"
}

export interface SourceOfWealthData {
  spread?: number;
  description?: string; //used only when SourceOfWealthType.OTHERS
  usedForFunds?: boolean;
}

export type SourcesOfWealth = Partial<Record<SourceOfWealthType, SourceOfWealthData>>;
