import React, { useState } from "react";
import { ButtonExpand } from "sharedComponents/Buttons/ButtonExpand/ButtonExpand";
import { Collapse } from "react-bootstrap";

export const SectionWrapper = ({ title, children }) => {
  const [isGroupStepOpen, setIsGroupStepOpen] = useState(false);

  return (
    <>
      <div className="section-wrapper">
        <div className="section-wrapper__header">
          <h3
            data-testid="process-state-group-name"
            onClick={() => setIsGroupStepOpen(!isGroupStepOpen)}>
            {title}
          </h3>
          &nbsp;
          <ButtonExpand
            onClick={() => setIsGroupStepOpen(!isGroupStepOpen)}
            expanded={isGroupStepOpen}
          />
        </div>
      </div>
      <Collapse in={isGroupStepOpen}>
        <div className="section-wrapper__body" data-testid="process-group-tasks">
          {children}
        </div>
      </Collapse>
    </>
  );
};
