import React, { useState } from "react";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { AuthLayout } from "../../../sharedComponents/templates/AuthLayout/AuthLayout";
import SetPasswordForm from "../SetPasswordForm/SetPasswordForm";
import { Panel } from "../../../sharedComponents/Panel/Panel";
import { storeToken } from "../../../common/Authentication/storeToken";
import { useHistory } from "react-router-dom";
import { ConfirmEmailAsyncContainer } from "../ConfirmEmailAsync/ConfirmEmailAsyncContainer";
import { FeatureFlags, SalutationType } from "../../../global-query-types";
import logger from "../../../common/Logger";
import { MutationResult, QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../components/generic/RequestFeedbackHandler";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";

interface WelcomeFormProps extends WithTranslation {
  token: string;
  getNewClientFirstOrder: (
    userId
  ) => Promise<{ orderId: string; submissionId: string } | undefined>;
  confirmShadowAccountHandler: (
    token: string,
    password: string
  ) => Promise<{ updatedToken: string; userId: string } | undefined>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
}

const WelcomeForm = ({
  token,
  confirmShadowAccountHandler,
  getNewClientFirstOrder,
  requests = [],
  t
}: WelcomeFormProps) => {
  const history = useHistory();

  const [firstName, setFirstName] = useState<string | undefined>();
  const [salutation, setSalutation] = useState<SalutationType | undefined>();

  const onSubmit = async (password: string) => {
    try {
      const submitResponse = await confirmShadowAccountHandler(token, password);
      if (submitResponse?.updatedToken) {
        storeToken(submitResponse.updatedToken);

        const orderResponse = await getNewClientFirstOrder(submitResponse.userId);
        if (
          FeatureFlagService.instance.isEnabled(FeatureFlags.redirectToOrderFlowOnFirstLogin) &&
          orderResponse?.orderId &&
          orderResponse?.submissionId
        ) {
          history.replace(
            `/client/order/generic/business-purpose/0/${orderResponse.orderId}/${orderResponse.submissionId}`
          );
        } else {
          history.replace("/");
        }
      }
    } catch (e) {
      logger.debug(e);
    }
  };

  const onConfirmEmail = (data) => {
    setFirstName(data.firstName);
    setSalutation(data.salutation);
  };

  const welcomeMessageOptions = welcomeMessageOptionsGenerator({ firstName, salutation });

  return (
    <AuthLayout
      title={t("auth:welcome.title")}
      subtitle={t("auth:welcome.subtitle", welcomeMessageOptions)}>
      <Panel>
        <ConfirmEmailAsyncContainer token={token} onConfirm={onConfirmEmail} />
        <SetPasswordForm onSubmit={onSubmit} requests={requests} />
      </Panel>
    </AuthLayout>
  );
};

export default withTranslationReady(["auth"])(WelcomeForm);

export interface WelcomeMessageOptions {
  greetings: string;
  name?: string;
}

export const welcomeMessageOptionsGenerator = ({
  firstName,
  salutation
}: {
  firstName: string | undefined;
  salutation: SalutationType | undefined;
}): WelcomeMessageOptions => {
  let greetings = !salutation || !firstName ? "Hallo" : "Liebe";

  if (firstName) {
    greetings += salutation === SalutationType.Mr ? "r " : " ";
  }

  return { greetings, name: firstName };
};
