import React from "react";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import AddressSearch, { Address } from "react-loqate";
import { InputAutoComplete, RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";

interface RideAddressSearchProps extends WithTranslation {
  onSelect: (address: Address) => void;
  className?: string;
}

const RideAddressSearch = ({ t, onSelect, className = "" }: RideAddressSearchProps) => {
  const apiKey = process.env.REACT_APP_LOQATE_API_KEY ?? "";

  return (
    <AddressSearch
      limit={10}
      locale={"de_DE"}
      apiKey={apiKey}
      countries={["DE"]}
      components={{
        Input: AddressSearchComponent(t)
      }}
      className={`address-search ${className}`}
      listClassname={"address-search__list"}
      listItemClassname={"address-search__list-item"}
      onSelect={onSelect}
      inline
      debounce={250}
    />
  );
};

const AddressSearchComponent =
  (t: TFunction) => (props: React.InputHTMLAttributes<HTMLInputElement>) => {
    return (
      <RideTextInput
        {...props}
        name="chrome-please-dont-autofill-me"
        label={t("generic:address-search")}
        className="shareholder-details--text-input"
        placeholder={t("generic:address-search-placeholder")}
        autoComplete={InputAutoComplete.Off}
      />
    );
  };

export default withTranslationReady(["generic"])(RideAddressSearch);
