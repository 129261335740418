import React from "react";
import { useQuery } from "react-apollo";
import RequestFeedbackHandler from "../../../../components/generic/RequestFeedbackHandler";
import { CLUB_DEALS } from "lib/api/queries/ClubDeals";
import { ClubDealsList } from "./ClubDealsList";
import { useHistory } from "react-router-dom";

export const ClubDealsListContainer = () => {
  const history = useHistory();
  const clubDealsRequest = useQuery(CLUB_DEALS);

  const clubDeals = clubDealsRequest.data?.clubDeals ?? [];

  const handleSelect = (id: string) => {
    history.push(`/client/club-deals/${id}`);
  };

  return (
    <RequestFeedbackHandler requests={[clubDealsRequest]} noLoadingAnimation={true}>
      <ClubDealsList clubDeals={clubDeals} onSelect={handleSelect} />
    </RequestFeedbackHandler>
  );
};
