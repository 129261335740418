import React from "react";

interface ShareHolderFormTitleProps {
  t;
  arrayHelpers;
  index;
  currentStep?: string;
  title?: string;
}

export const ShareHolderFormTitle = ({
  t,
  arrayHelpers,
  index,
  currentStep,
  title
}: ShareHolderFormTitleProps) => {
  if (title) {
    return (
      <div className="step-title" data-testid={`stepTitle`}>
        {title}
      </div>
    );
  }

  if (currentStep === "EMPLOYEE_MANAGING_DIRECTOR") {
    return (
      <div className="step-title" data-testid={`stepTitle`}>
        {t("admin-service:ext-director") + " " + (index + 1)}
      </div>
    );
  }

  return (
    <div className="step-title" data-testid={`stepTitle`}>
      {!!arrayHelpers
        ? t("trader-gmbh:shareholder") + " " + (index + 2)
        : t("trader-gmbh:shareholder") + " 1 " + t("trader-gmbh:you-are")}
    </div>
  );
};
