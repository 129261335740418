import React from "react";
import { AuthMessage } from "../AuthMessage/AuthMessage";

interface AuthAlertMessageProps {
  onSubmit: () => Promise<void>;
  message: string;
  submitText: string;
  "data-testid"?: string;
}

export const AuthAlertMessage = ({
  onSubmit,
  message,
  submitText,
  "data-testid": testid
}: AuthAlertMessageProps) => {
  return (
    <AuthMessage
      message={message}
      onSubmit={onSubmit}
      submitText={submitText}
      data-testid={testid}
      image="/assets/sharedComponents/alert-icon.svg"
    />
  );
};
