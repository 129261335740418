import { LegalForm, LegalPersonType } from "../../../../global-query-types";
import { AddressData } from "../AddressData";
import { PersonData } from "./PersonData";
import { CitizenshipData } from "./CitizenshipData";
import { CompanyData } from "../CompanyData";
import { Entity } from "./Entity";
import { StatusInformation } from "../LowTaxBroker/StatusInformation";

export type ShareholderDict = {
  [key: number]: Shareholder;
};

export interface Shareholder {
  shareholderType?: LegalPersonType;
  personData?: PersonData;
  personCitizenship?: CitizenshipData;
  personAddress?: AddressData;
  statusInformation?: StatusInformation;
  companyData?: CompanyData;
  companyAddress?: AddressData;
  companyRepresentative?: PersonData;
  representativeAddress?: AddressData;
  representativeCitizenship?: CitizenshipData;
  isManagingDirector?: boolean;
  isRepresentativeManagingDirector?: boolean;
  shares?: number;
  thisShareholderRepresentsTheUserOrdering?: boolean;
}

export class ShareholderEntity implements Shareholder, Entity {
  readonly key = "shareholder";

  shareholderType?: LegalPersonType;
  personData?: PersonData;
  personCitizenship?: CitizenshipData;
  personAddress?: AddressData;
  companyData?: CompanyData;
  companyAddress?: AddressData;
  companyRepresentative?: PersonData;
  representativeAddress?: AddressData;
  representativeCitizenship?: CitizenshipData;
  isManagingDirector?: boolean;
  isRepresentativeManagingDirector?: boolean;
  shares?: number;

  constructor(
    readonly index: number | string,
    {
      companyAddress,
      companyData,
      companyRepresentative,
      isManagingDirector,
      isRepresentativeManagingDirector,
      personAddress,
      personCitizenship,
      personData,
      representativeAddress,
      representativeCitizenship,
      shareholderType,
      shares
    }: Shareholder
  ) {
    this.companyAddress = companyAddress;
    this.companyData = companyData;
    this.companyRepresentative = companyRepresentative;
    this.isManagingDirector = isManagingDirector;
    this.isRepresentativeManagingDirector = isRepresentativeManagingDirector;
    this.personAddress = personAddress;
    this.personCitizenship = personCitizenship;
    this.personData = personData;
    this.representativeAddress = representativeAddress;
    this.representativeCitizenship = representativeCitizenship;
    this.shareholderType = shareholderType;
    this.shares = shares;
  }

  get email() {
    if (this.shareholderType === LegalPersonType.Person) {
      return this.personData?.email;
    }

    if (this.shareholderType === LegalPersonType.Company) {
      return this.companyRepresentative?.email;
    }

    return undefined;
  }
}

export const Shareholder = (data: Shareholder) => data;

Shareholder.testPersonData = {
  firstName: "firstName",
  lastName: undefined,
  phoneNumber: undefined,
  email: undefined,
  authorizedToShareShareholderInfo: false
} as NonNullable<Shareholder["personData"]>;

Shareholder.testCompanyData = {
  companyName: "Hello world",
  legalForm: LegalForm.GmbH,
  registerCourt: "",
  registration: ""
} as NonNullable<Shareholder["companyData"]>;
