import React, { FormEvent } from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import DocumentCard from "../../LowTaxBrokerAccount/sharedComponents/DocumentCard/DocumentCard";
import { StepComponentProps } from "../../ChaptersOrderLayout.partials";
import { DocumentsListHeading } from "../../sharedComponents/DocumentsListHeading/DocumentsListHeading";
import { Chapter } from "../../../../../lib/models/client/order/Chapter";
import { useHistory } from "react-router-dom";
import { AdminServiceOrderData } from "../../../../../lib/models/client/AdminService/AdminServiceOrderData";

const AdminServiceFormDocuments = ({
  t,
  order,
  currentStep,
  controls,
  saveData
}: StepComponentProps<AdminServiceOrderData> & WithTranslation) => {
  const history = useHistory();

  const onEditDocumentCard = (subchapter: Chapter, stepKey: string) => {
    const step = subchapter.getStepByKey(stepKey);
    const positionalIndex = subchapter.positionalIndex;

    history.push(
      `/client/order/generic/documents/${positionalIndex}/${step?.index}/${order.id}/${order.submissionId}`
    );
  };

  const uploadIdChapter = currentStep.chapter.getSubChapterByKey("upload-id-documents");
  const uploadYearlyReportChapter = currentStep.chapter.getSubChapterByKey(
    "upload-last-yearly-report"
  );

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (currentStep.chapter.subChapters.every((s) => s.completed)) {
      currentStep.complete();
    }

    await saveData({
      ...order.extra,
      ...currentStep.chapter.order.serialize()
    });
  };

  const areAllDocumentsUploaded = (): boolean => {
    let hasBackFileUploaded: boolean = false;

    if (
      order.extra.documents?.passportOrID?.typeOfDocument === "ID" &&
      order.extra.documents?.passportOrID?.back?.id
    ) {
      hasBackFileUploaded = true;
    }

    return !!(
      (order.extra.documents?.lastYearlyReport?.id ||
        order.extra.documents?.lastYearlyReport?.notApplicable) &&
      order.extra.documents?.passportOrID?.id &&
      (hasBackFileUploaded || order.extra.documents?.passportOrID?.typeOfDocument === "PASSPORT")
    );
  };

  return (
    <DocumentsListHeading title={t("generic:admin-service-form.documents-step.title")}>
      <div className={"documents-list__content"}>
        <DocumentCard
          data-testid={`personal-documents-list-proof-of-id-card`}
          text={t(
            "generic:ride-broker-onboarding.documents.personal-documents-list.id-or-passport"
          )}
          numberOfAttachments={
            order.extra?.documents?.passportOrID && order.extra.documents.passportOrID.id ? 1 : 0
          }
          onEdit={() => onEditDocumentCard(uploadIdChapter!, "select-id-document-type")} //TODO: remove bangs
          key={`proof-of-id`}
        />
        <DocumentCard
          data-testid={`last-yearly-report-card`}
          text={t("generic:admin-service-form.documents-step.last-yearly-report")}
          numberOfAttachments={
            order.extra?.documents?.lastYearlyReport && order.extra.documents.lastYearlyReport.id
              ? 1
              : 0
          }
          onEdit={() => onEditDocumentCard(uploadYearlyReportChapter!, "last-yearly-report")} //TODO: remove bangs
          key={`yearly-report`}
        />
      </div>

      <form onSubmit={onSubmit}>
        {{
          ...controls,
          props: {
            ...controls.props,
            disabled: !areAllDocumentsUploaded()
          }
        }}
      </form>
    </DocumentsListHeading>
  );
};

export default withTranslationReady(["generic"])(AdminServiceFormDocuments);
