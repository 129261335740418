import React from "react";
import { GetMyPermissionsQuery_me } from "../../../lib/api/queries/graphql/GetMyPermissionsQuery";
import { RideButtonDeprecated } from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { FullPersonDetailsQuery_getPersonDetails_user } from "../../../components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import { UserRole } from "../../../global-query-types";
import { Col, Row } from "react-bootstrap";
import { storeToken } from "../../../common/Authentication/storeToken";

interface LoginAsUserButtonProps extends WithTranslation {
  permissions: GetMyPermissionsQuery_me | null | undefined;
  loginAsUser: (userId: string) => Promise<string | null | undefined>;
  refreshAll: () => void;
  user: FullPersonDetailsQuery_getPersonDetails_user;
}

const LoginAsUserButton = ({
  permissions,
  loginAsUser,
  refreshAll,
  user,
  t
}: LoginAsUserButtonProps) => {
  const taxAdvisorRoles = [UserRole.TaxAdvisor, UserRole.ThirdPartyTaxAdvisor, UserRole.Client];
  if (!taxAdvisorRoles.includes(user.role)) return null;
  if (!permissions?.canLoginAsTaxAdvisor) return null;

  const onClick = async () => {
    const token = await loginAsUser(user.id);
    if (!token) return;

    storeToken(token);
    refreshAll();
  };

  return (
    <Row className="mt-5">
      <Col xs="auto">
        <RideButtonDeprecated data-testid="login-as-user-btn" onClick={onClick}>
          {t("nominee-dashboard:login-as-user")}
        </RideButtonDeprecated>
      </Col>
    </Row>
  );
};

export default withTranslationReady(["nominee-dashboard"])(LoginAsUserButton);
