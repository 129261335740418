import React from "react";
import { TFunction } from "i18next";
import { Col, Row } from "react-bootstrap";
import {
  BirthNameArrayField,
  BirthPlaceArrayField,
  CitizenshipArrayField,
  CityArrayField,
  CountryArrayField,
  DateOfBirthArrayField,
  PersonalTaxIdArrayField,
  PostcodeArrayField,
  StreetAndNumberArrayField
} from "../../fields";
import { FormikProps } from "formik";
import { PersonalDetailAgreement } from "../../../../generic/Admin-Service/PersonalDetailAgreement";

interface PersonalDetailFieldsProps {
  t: TFunction;
  formik: FormikProps<any>;
  values: any;
  prefix;
  touched;
  errors;
  localError;
}

export const PersonalDetailFields = (props: PersonalDetailFieldsProps) => {
  const agreement = () => (
    <span className="admin-service-agreement">
      <span>{props.t("trader-gmbh:agreement-text")}</span>
    </span>
  );

  return (
    <div>
      <Row>
        <Col xs={"12"} md={"6"}>
          <DateOfBirthArrayField {...props} />
        </Col>
        <Col xs={"12"} md={"6"}>
          <BirthNameArrayField {...props} />
        </Col>
      </Row>
      <Row>
        <Col xs={"12"} md={"6"}>
          <BirthPlaceArrayField {...props} />
        </Col>
        <Col xs={"12"} md={"6"}>
          <CitizenshipArrayField {...props} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={"12"}>
          <hr />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={"12"} md={"6"}>
          <StreetAndNumberArrayField {...props} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12} lg={4}>
              <PostcodeArrayField {...props} />
            </Col>
            <Col xs={12} lg={8}>
              <CityArrayField {...props} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={"12"} md={"6"}>
          <CountryArrayField {...props} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={"12"}>
          <hr />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={"12"} md={"6"}>
          <PersonalTaxIdArrayField {...props} />
        </Col>
      </Row>
      <Row>
        <Col className="ml-2">
          <PersonalDetailAgreement AgreementComponent={agreement} {...props} />
        </Col>
      </Row>
    </div>
  );
};
