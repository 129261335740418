import React from "react";
import { IconProps } from "./IconProps";

export const IconClientCompletedTask = ({ className, size = 50 }: IconProps) => (
  <svg
    className={className}
    width={size}
    height="42"
    viewBox="0 0 50 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 26C9 22.1 12.1 19 16 19C19.9 19 23 22.1 23 26"
      stroke="#A3A3A3"
      strokeMiterlimit="10"
    />
    <path
      d="M49 14C49 14 45.7 7.39999 38 7.99999C38.3 8.49999 49 19.5 49 41"
      stroke="#A3A3A3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.9997 6C41.9997 2.9 37.9997 8 37.9997 8C37.9997 8 37.1997 1 44.9997 1"
      stroke="#A3A3A3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28 9.99996C32.6 4.79996 38 7.99996 38 7.99996C38 7.99996 31 10.5 31 18"
      stroke="#A3A3A3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M37.9988 8C37.9988 8 35.6988 13.7 37.9988 20"
      stroke="#A3A3A3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M1 29H43" stroke="#A3A3A3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M3 33H29" stroke="#A3A3A3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M9 37H23" stroke="#A3A3A3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M13 41H19" stroke="#A3A3A3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M5 25H1" stroke="#A3A3A3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M31 25H27" stroke="#A3A3A3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M16 11V15" stroke="#A3A3A3" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M26.6008 15.3999L23.8008 18.1999"
      stroke="#A3A3A3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M5.39844 15.3999L8.19844 18.1999"
      stroke="#A3A3A3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
