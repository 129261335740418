import React, { ReactNode } from "react";
import { RideCheckbox } from "../RideCheckbox/RideCheckbox";

interface RideMultiSelectProps {
  options: MultiSelectOption[];
  columns?: 1 | 2;
  dataTestid?: string;
}

export interface MultiSelectOption {
  label: ReactNode;
  name: string;
  isReadonly?: boolean;
}

export const RideMultiSelect = ({
  options,
  columns = 2,
  dataTestid = "ride-multi-select"
}: RideMultiSelectProps) => (
  <div data-testid={dataTestid} className={`ride-multi-select ride-multi-select--${columns}-col`}>
    {options.map((option) => (
      <RideCheckbox
        name={option.name}
        label={option.label}
        key={option.name}
        isReadonly={option.isReadonly ?? false}
      />
    ))}
  </div>
);
