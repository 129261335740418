import { DataLayer, OrderEventProductType, UserPrivateInformation } from "./GoogleTagManager";
import { RIDE_FLOWS } from "../../../flows";

export enum WePaDirectDirectSalesStep {
  FISCAL_YEAR_STARTING_DATE_SETUP = "select-starting-fiscal-year-date",
  PREVIOUS_FISCAL_YEARS = "select-additional-fiscal-years",
  DOWNLOAD_CONTRACT = "download-general-terms-and-conditions",
  AGREE_WITH_CONTRACT = "agree-with-general-terms-and-conditions",
  SETUP_PAYMENT = "setup-payment",
  REVIEW_AND_SIGN_CONTRACT = "review_and_sign_contract"
}

export class WepaDirectOnboardingEvents {
  static wd_ordered_products = {
    [OrderEventProductType.WD]: {
      product: OrderEventProductType.WD,
      quantity: 1
    }
  };

  static StepCompleted({
    userId,
    step_name,
    ...rest
  }: {
    step_name: WePaDirectDirectSalesStep;
    userId?: string | undefined;
  } & UserPrivateInformation) {
    DataLayer.StepCompleted({
      flow: RIDE_FLOWS.WEPA_DIRECT_DIRECT_SALES,
      step_name,
      ...rest
    });
  }
}
