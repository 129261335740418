import React, { PropsWithChildren } from "react";
import RideFooter, { AppsVariant } from "sharedComponents/RideFooter/RideFooter";
import { Container } from "react-bootstrap";
import TaxAdvisorHeader from "taxAdvisor/components/TaxAdvisorHeader/TaxAdvisorHeader";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import TwoFactorAuthenticationNotificationContainer from "auth/components/TwoFactorAuthenticationNotification/TwoFactorAuthenticationNotificationContainer";

export const TaxAdvisorLayout = ({ children }: PropsWithChildren<{}>) => (
  <>
    <TaxAdvisorHeader />
    <Container fluid data-testid="tax-advisor-layout" className="p-0 flex-fill ride-body">
      {children}
    </Container>
    <RideFooter app={AppsVariant.PartnerTaxAdvisor} />
    <FeatureFlag name={FeatureFlags.TwoFactorAuthenticationSetup}>
      <TwoFactorAuthenticationNotificationContainer />
    </FeatureFlag>
  </>
);
