import React from "react";
import { PrivateInvestment } from "../icons/PrivateInvestment";
import { withTranslationReady } from "../../common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import { InvestmentState } from "../../global-query-types";
import { RideCardOwnership } from "sharedComponents/RideCardOwnership/RideCardOwnership";

export interface ChainOwnershipForStateProps {
  state: InvestmentState;
  ownership: string[];
}

interface ChainOwnershipWithTranslationsProps
  extends ChainOwnershipForStateProps,
    WithTranslation {}

const ChainOwnershipForState = ({ state, ownership, t }: ChainOwnershipWithTranslationsProps) => {
  return (
    <>
      {state === InvestmentState.Private && (
        <div data-testid="card-label-private" className="chain-ownership__label-private">
          <PrivateInvestment />
          {t("generic:investment-private")}
        </div>
      )}
      {state === InvestmentState.RideClubInProcess && (
        <div data-testid="card-label-in-process" className="chain-ownership__label-in-process">
          <div className="chain-ownership__label">{t("generic:investment-inProcess")}</div>
        </div>
      )}
      {state === InvestmentState.RideClubCompleted && <RideCardOwnership ownership={ownership} />}
    </>
  );
};

export default withTranslationReady("generic")(ChainOwnershipForState);
