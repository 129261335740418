import React, { PropsWithChildren } from "react";
import { RideStatus } from "lib/types/types";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../uiLibrary/designSystem/RideButton/RideButton";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

export type OnboardingStepCardStatus = Extract<
  RideStatus,
  RideStatus.LOCKED | RideStatus.PENDING | RideStatus.COMPLETED
>;

export interface EditableOnboardingStepCardProps {
  forceOpening?: boolean;
  toggleEditing?: ((stepOnboardingKey: string) => void) | null;
}

export interface OnboardingStepCardProps extends PropsWithChildren<{}> {
  "data-testid"?: string;
  number: number;
  title: string;
  status: OnboardingStepCardStatus;
  forceShowContent?: boolean | null;
  toggleEditing?: (() => void) | null;
}

const OnboardingStepCard = ({
  t,
  "data-testid": dataTestId,
  number,
  title,
  status,
  children,
  forceShowContent = false,
  toggleEditing
}: OnboardingStepCardProps & WithTranslation) => {
  /*
    this component was initially conceived to be used in sequential steps
    but later turned into a sort of accordion-like card.
    To achieve this, "toggleEditing" forces opening and closing according to outer logic.
   */
  const showContents = status === RideStatus.PENDING || forceShowContent;
  const showEditButton = status === RideStatus.COMPLETED && !showContents && !!toggleEditing;

  return (
    <div
      data-testid={dataTestId}
      data-status={status}
      className={`ltb-overview-card ltb-overview-card--${status}`}>
      <div className="ltb-overview-card__header">
        {status === RideStatus.COMPLETED ? <CheckIcon /> : <NumberIcon number={number} />}
        <span
          className={`ltb-overview-card__title ${
            toggleEditing ? "ltb-overview-card__title--editable" : ""
          }`}>
          <span data-testid={`${dataTestId}-title`}>{title}</span>
          {showEditButton && (
            <RideButton
              type={"button"}
              data-testid={"edit-button"}
              label={t("generic:edit-field")}
              variant={RideButtonVariant.GHOST}
              size={RideButtonSize.SMALL}
              onClick={() => toggleEditing?.()}
            />
          )}
        </span>
      </div>
      {showContents && <div className="ltb-overview-card__content">{children}</div>}
    </div>
  );
};

const NumberIcon = ({ number }: { number: number }) => (
  <div data-testid="number-icon" className="ltb-overview-card__number-icon">
    {number}
  </div>
);

const CheckIcon = () => <div data-testid="check-icon" className="ltb-overview-card__check-icon" />;

export default withTranslationReady(["generic"])(OnboardingStepCard);
