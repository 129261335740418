import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  GetProcessTasksQuery,
  GetProcessTasksQueryVariables
} from "./graphql/GetProcessTasksQuery";

export const GET_PROCESS_TASKS_QUERY = gql`
  query GetProcessTasksQuery(
    $companyName: String
    $state: TaskStatesEnum
    $ref: String
    $stepGroup: String
    $stepState: StepStatesEnum
    $responsibleParty: UserRole
    $start: Int
    $limit: Int
    $sortColumn: String
    $sortOrder: String
  ) {
    getProcessTasks(
      companyName: $companyName
      state: $state
      ref: $ref
      stepGroup: $stepGroup
      stepState: $stepState
      responsibleParty: $responsibleParty
      start: $start
      limit: $limit
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      count
      processTasks {
        ref
        state
        updatedAt
        type
        responsibleParty
        companyCreation {
          company {
            id
            name
            legalForm
          }
        }
      }
    }
  }
`;

export const GetProcessTasks = (variables: GetProcessTasksQueryVariables) =>
  useQuery<GetProcessTasksQuery, GetProcessTasksQueryVariables>(GET_PROCESS_TASKS_QUERY, {
    variables,
    fetchPolicy: "no-cache"
  });
