import { RideCheckbox } from "uiLibrary/components/RideCheckbox/RideCheckbox";
import React from "react";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { WithTranslation } from "react-i18next";
import RideIcon, { RideIconModifier } from "uiLibrary/components/RideIcon/RideIcon";
import { RideSize } from "lib/types/types";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";

export interface MDPersonCandidate {
  field: "isManagingDirector" | "isRepresentativeManagingDirector";
  name: string;
  index: string;
  label: string;
  initialValue: boolean;
  isFullControl?: boolean;
  entityKey: "shareholder" | "employee";
}

export interface ManagingDirectorCheckBoxProps {
  module: string;
  person: MDPersonCandidate;
  onPreview?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  onClick?: () => Promise<void>;
}

export const ManagingDirectorControl = ({
  module,
  person,
  onPreview,
  onDelete,
  onClick
}: ManagingDirectorCheckBoxProps) => {
  return (
    <div className={`${module}__wrapper`}>
      <div className={`${module}__item`}>
        <RideCheckbox
          name={person.name}
          label={person.label}
          isReadonly={person.isFullControl}
          renderValidationErrors={false}
          onClick={onClick}
        />

        <div className={`${module}__icons`}>
          {person.isFullControl && (
            <RideIcon
              onClick={onPreview}
              dataTestId="managing-director-preview"
              name="visible-big"
              size={RideSize.SMALL}
              modifier={RideIconModifier.CLICKABLE_CONTROL}
            />
          )}

          {person.isFullControl && (
            <RideIcon
              onClick={onDelete}
              dataTestId="managing-director-delete"
              name="delete-max"
              size={RideSize.SMALL}
              modifier={RideIconModifier.CLICKABLE_CONTROL}
            />
          )}
        </div>
      </div>
      <ErrorMessageWithT name={person.name} extra={{}} />
    </div>
  );
};

export interface ManagingDirectorAddButtonProps {
  module: string;
  onClick: () => Promise<void>;
}

ManagingDirectorControl.AddButton = withTranslationReady("generic")(
  ({ module, onClick, t }: ManagingDirectorAddButtonProps & WithTranslation) => {
    return (
      <div className={`${module}__wrapper`}>
        <div
          className={`ride-checkbox-v2--heavy ${module}__add-button`}
          data-testid="add-managing-director"
          onClick={onClick}>
          <span className={`${module}__add-icon`} />
          <span className="label">{t("generic:managing-directors.add")}</span>
        </div>
      </div>
    );
  }
);
