import React from "react";

export enum MainContentVariant {
  GREY = "grey",
  WHITE = "white"
}

interface PageLayoutProps {
  pageTitle: string;
  mainContent: JSX.Element;
  headerContent?: JSX.Element;
  className?: string;
  dataTestId?: string;
  mainContentVariant?: MainContentVariant;
}

export const PageLayout = ({
  pageTitle,
  headerContent,
  mainContent,
  className = "page-layout",
  dataTestId = "page-layout",
  mainContentVariant = MainContentVariant.GREY
}: PageLayoutProps) => {
  return (
    <div className={`${className} page-layout`} data-testid={dataTestId}>
      <div className={`${className}__header page-layout__header`}>
        <h2
          className={`${className}__title page-layout__title`}
          data-testid={`${dataTestId}__title`}>
          {pageTitle}
        </h2>
        {headerContent && <div data-testid={`${dataTestId}__header-content`}>{headerContent}</div>}
      </div>
      {mainContent && (
        <div
          className={`${className}__children page-layout__children page-layout__children--${mainContentVariant}`}
          data-testid={`${dataTestId}__children`}>
          <div
            className={`${className}__children-container page-layout__children__children-container`}
            data-testid={`${dataTestId}__children-container`}>
            {mainContent}
          </div>
        </div>
      )}
    </div>
  );
};
