import React from "react";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";

export interface AddCompanyDetailsCardProps {
  cardIndex: number;
  status: OnboardingStepCardStatus;
  onAddCompanyDetails: () => void;
}

const AddCompanyDetailsCard = ({
  t,
  cardIndex,
  status,
  onAddCompanyDetails
}: AddCompanyDetailsCardProps & WithTranslation) => {
  return (
    <OnboardingStepCard
      data-testid="add-company-details-card"
      number={cardIndex}
      status={status}
      title={t("generic:low-tax-broker-overview.add-company-details.title")}>
      <div className="ltb-overview__content-box">
        <div className="ltb-overview__content-title">
          {t("generic:low-tax-broker-overview.add-company-details.content-title")}
        </div>
        <div className="ltb-overview__content">
          {t(
            rideBrokerT("generic:low-tax-broker-overview.add-company-details.content-description")
          )}
        </div>
      </div>
      <div className="ltb-overview__buttons">
        <button
          className="ltb-overview__primary-action-btn"
          data-testid="add-company-details-button"
          onClick={onAddCompanyDetails}>
          {t("generic:low-tax-broker-overview.add-company-details.button-text")}
        </button>
      </div>
    </OnboardingStepCard>
  );
};

export default withTranslationReady("generic")(AddCompanyDetailsCard);
