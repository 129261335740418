import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import RideCardTable from "../../../sharedComponents/RideCardTable/RideCardTable";
import { RideHeaderColumn } from "../../../lib/types/types";
import RideTableCell, {
  RideTableCellAlignment,
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import { RideBadgeColorsEnum, RideBadgeStatus } from "uiLibrary/components/RideBadgeStatus";
import ConfirmationModal from "../../../sharedComponents/ConfirmationModal/ConfirmationModal";
import { RideModalSize } from "sharedComponents/RideModalDeprecated/RideModalDeprecated";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { UserRole } from "../../../global-query-types";
import { useHistory } from "react-router-dom";

export enum FiscalYearDataCompletenessStatusEnum {
  NOT_POSSIBLE_YET = "NOT_POSSIBLE_YET",
  CONFIRMED = "CONFIRMED",
  TO_BE_CONFIRMED = "TO_BE_CONFIRMED"
}

export interface FiscalYearDataCompleteness {
  id: string;
  fiscalYear: string;
  dueDate: string;
  status: FiscalYearDataCompletenessStatusEnum;
}

interface IFiscalYearDataUploadConfirmation extends WithTranslation {
  fiscalYears: FiscalYearDataCompleteness[];
  onChangeConfirmation: (id: string, value: boolean) => Promise<void>;
  role: UserRole;
  companyId: string;
}

const FiscalYearDataUploadConfirmation = ({
  fiscalYears,
  onChangeConfirmation,
  t,
  role,
  companyId
}: IFiscalYearDataUploadConfirmation) => {
  const { push } = useHistory();

  const columns: RideHeaderColumn[] = [
    {
      name: "fiscalYear",
      label: t("client:company.admin-service.fiscal-year-data-upload-confirmation.fiscalYear")
    },

    {
      name: "dueDate",
      label: t("client:company.admin-service.fiscal-year-data-upload-confirmation.dueDate")
    },

    {
      name: "status",
      label: t("client:company.admin-service.fiscal-year-data-upload-confirmation.status"),
      alignment: RideTableCellAlignment.CENTER
    }
  ];

  function disableActionButton(row: FiscalYearDataCompleteness): boolean {
    return false;
  }

  function alternativeDueDate(row: FiscalYearDataCompleteness): boolean {
    return row.fiscalYear.startsWith("01.01.2023");
  }

  const getActionCell = (row, toBeConfirmed: boolean, canBeUndone: boolean) => {
    const isDisabled = disableActionButton(row);
    const status = t(
      `client:company.admin-service.fiscal-year-data-upload-confirmation.statusEnum.${row.status}`
    );
    const alignment = RideTableCellAlignment.CENTER;

    const year = row.fiscalYear.split(" - ")[0].split(".")[2];

    if (toBeConfirmed) {
      return (
        <RideTableCell
          value={status}
          alignment={alignment}
          variant={RideTableCellVariant.actionButton}
          isDisabled={isDisabled}
          action={() => {
            push(`/client/entities/${companyId}/fiscal-years/${year}/confirmation`);
          }}
        />
      );
    }

    if (canBeUndone) {
      return (
        <ConfirmationModal
          size={RideModalSize.LARGE}
          title={t("client:company.admin-service.fiscal-year-data-upload-confirmation.modalTitle")}
          dialogBody={t(
            "client:company.admin-service.fiscal-year-data-upload-confirmation.modalBody"
          )}
          confirmLabel={t(
            "client:company.admin-service.fiscal-year-data-upload-confirmation.modalConfirmButton"
          )}
          cancelLabel={t(
            "client:company.admin-service.fiscal-year-data-upload-confirmation.modalCancelButton"
          )}
          onConfirm={async () => {
            await onChangeConfirmation(row.id, false);
          }}>
          {(setVisibility) => (
            <RideTableCell
              value={t(
                `client:company.admin-service.fiscal-year-data-upload-confirmation.statusEnum.UNDO_CONFIRMATION`
              )}
              alignment={alignment}
              variant={RideTableCellVariant.actionButton}
              action={() => {
                setVisibility(true);
              }}
            />
          )}
        </ConfirmationModal>
      );
    }

    return (
      <RideTableCell value={status} alignment={alignment} variant={RideTableCellVariant.text} />
    );
  };

  const getDueDateCell = (row, toBeConfirmed: boolean) => {
    const isAlternativeDueDate = alternativeDueDate(row);
    let dueDate = row.dueDate;
    if (isAlternativeDueDate) dueDate = "15.02.2024";

    return toBeConfirmed ? (
      <RideTableCell
        badge={<RideBadgeStatus color={RideBadgeColorsEnum.WARNING} label={dueDate} />}
        variant={RideTableCellVariant.badge}
      />
    ) : (
      <RideTableCell value={dueDate} variant={RideTableCellVariant.text} />
    );
  };

  return (
    <RideCardTable
      data-testid="fiscal-year-data-upload-confirmation-table"
      title={t("client:company.admin-service.fiscal-year-data-upload-confirmation.title")}>
      <RideTableHeader columns={columns} />
      {fiscalYears.map((row: FiscalYearDataCompleteness) => {
        const toBeConfirmed = row.status === FiscalYearDataCompletenessStatusEnum.TO_BE_CONFIRMED;
        const canBeUndone =
          role === UserRole.Nominee &&
          row.status === FiscalYearDataCompletenessStatusEnum.CONFIRMED;

        return (
          <RideTableRow colCount={columns.length} key={row.id}>
            <RideTableCell value={row.fiscalYear} />
            {getDueDateCell(row, toBeConfirmed)}
            {getActionCell(row, toBeConfirmed, canBeUndone)}
          </RideTableRow>
        );
      })}
      <p className="fiscal-year-data-upload-confirmation__footer">
        <TextLink
          variant={LinkVariant.dark}
          to={t(
            "client:company.admin-service.fiscal-year-data-upload-confirmation.footer-hint-link-href"
          )}
          target="_blank">
          {t(
            "client:company.admin-service.fiscal-year-data-upload-confirmation.footer-hint-link-text"
          )}
        </TextLink>
        {t(
          "client:company.admin-service.fiscal-year-data-upload-confirmation.footer-hint-remaining-text"
        )}
      </p>
    </RideCardTable>
  );
};

export default withTranslationReady(["client"])(FiscalYearDataUploadConfirmation);
