import { GetMyGmbHs } from "./Queries";
import MyGmbHs from "./MyGmbHs";
import React from "react";
import RequestFeedbackHandler from "../../generic/RequestFeedbackHandler";

export const MyGmbHsContainer = () => {
  const myGmbHsRequest = GetMyGmbHs();

  return (
    <RequestFeedbackHandler requests={[myGmbHsRequest]} noLoadingAnimation={true}>
      <MyGmbHs myGmbHs={myGmbHsRequest.data} />
    </RequestFeedbackHandler>
  );
};
