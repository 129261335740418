import { RideUrls } from "common/URLs";
import { FooterUrl } from "./RideFooter";

export const FooterConfig: { footerUrls: FooterUrl[]; showLanguages: boolean } = {
  footerUrls: [
    {
      url: RideUrls.Impressum,
      urlTranslationKey: "footer:impressum"
    },
    {
      url: RideUrls.PrivacyPolicy,
      urlTranslationKey: "footer:data-privacy-short"
    },
    {
      url: RideUrls.TermsAndConditions,
      urlTranslationKey: "footer:terms-short"
    }
  ],
  showLanguages: false
};
