import { RolesInGroup } from "../global-query-types";
import uniqBy from "lodash/uniqBy";
import { MyCompanyCreationQuery_myCompanyCreation_company_group_memberships } from "../components/public/CompanyCreation/graphql/MyCompanyCreationQuery";
import {
  CompanyCreationsQuery_companyCreations_items_adminCompany_group_memberships,
  CompanyCreationsQuery_companyCreations_items_company_group_memberships
} from "../lib/api/queries/graphql/CompanyCreationsQuery";

export const getUniqPeopleAs = (
  memberships:
    | (
        | CompanyCreationsQuery_companyCreations_items_company_group_memberships
        | MyCompanyCreationQuery_myCompanyCreation_company_group_memberships
        | CompanyCreationsQuery_companyCreations_items_adminCompany_group_memberships
      )[]
    | null
    | undefined,
  roles: RolesInGroup[]
) => {
  if (!memberships) {
    return [];
  }

  const selectedMemberships = memberships
    .filter((membership) => membership.person)
    .filter((membership) => roles.includes(membership.role));

  return uniqBy(selectedMemberships, (membership) => membership?.person?.id);
};
