import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { RideTextInput } from "uiLibrary/components/RideTextInput/RideTextInput";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { IbanValidation } from "lib/validation/IbanSchema";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { deepClone } from "common/deepClone";
import { useApolloClient } from "@apollo/react-hooks";
import {
  validateIban,
  validateIbanVariables
} from "../../../../../../../lib/api/queries/graphql/validateIban";
import { VALIDATE_IBAN } from "../../../../../../../lib/api/queries/validateIban";
import { convertErrorCode } from "../../../../../../../lib/converters";

interface CompanyBankAccountStepProps extends WithTranslation {}

const CompanyBankAccount = withTranslationReady(["generic"])(({ t }: WithTranslation) => {
  return (
    <div data-testid="company-bank-account" className="company-bank-account">
      <StepHeading text={t("generic:ride-broker-onboarding.company-bank-account.title")} />
      <RideTextInput
        name="accountHolderName"
        label={t("generic:ride-broker-onboarding.company-bank-account.holder-name")}
        placeholder={t(
          "generic:ride-broker-onboarding.company-bank-account.holder-name-placeholder"
        )}
        className="company-bank-account__field"
      />
      <RideTextInput
        name="iban"
        label={t("generic:ride-broker-onboarding.company-bank-account.iban")}
        placeholder={t("generic:ride-broker-onboarding.company-bank-account.iban-placeholder")}
        className="company-bank-account__field"
      />
    </div>
  );
});

const CompanyBankAccountStep = ({
  t,
  currentStep,
  order,
  saveData,
  controls
}: StepComponentProps<LowTaxBrokerOrderData> & CompanyBankAccountStepProps) => {
  const apolloClient = useApolloClient();

  const initialValues = {
    accountHolderName: order.extra.companyBankAccount?.holderName ?? "",
    iban: order.extra.companyBankAccount?.iban ?? ""
  };

  const CompanyBankAccountSchema = (t) => {
    return Yup.object().shape({
      accountHolderName: Yup.string().required(t("generic:validation-required")),
      iban: IbanValidation
    });
  };

  const sanitizeIban = (iban: string): string => {
    let sanitizedIban = iban.replaceAll(" ", "");
    sanitizedIban = sanitizedIban.replaceAll("-", "");
    return sanitizedIban;
  };

  const onSubmit = async () => {
    const iban = formik.values["iban"];
    let ibanValidationResult;
    try {
      let sanitizedIban = sanitizeIban(iban);
      const validateIbanRequest = await apolloClient.query<validateIban, validateIbanVariables>({
        query: VALIDATE_IBAN,
        variables: {
          iban: sanitizedIban
        }
      });

      ibanValidationResult = validateIbanRequest?.data?.validateIban;

      if (!ibanValidationResult?.isValid) {
        formik.setFieldError("iban", convertErrorCode(ibanValidationResult?.errorMessageKey));
        return;
      }
    } catch (err) {
      console.error(err);
    }
    await saveStep(ibanValidationResult);
  };

  const formik = useRideFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validationSchema: CompanyBankAccountSchema(t),
    onSubmit
  });

  const saveStep = async (ibanValidationResult: any) => {
    currentStep.complete();

    let extra: LowTaxBrokerOrderData = deepClone(order.extra);
    if (extra.companyBankAccount === undefined) {
      extra.companyBankAccount = {};
    }
    extra.companyBankAccount.holderName = formik.values["accountHolderName"];
    extra.companyBankAccount.iban = sanitizeIban(formik.values["iban"]);
    extra.companyBankAccount.creditInstitutionName = ibanValidationResult.bankName;
    extra.companyBankAccount.swiftBic = ibanValidationResult.bic;

    await saveData({ ...extra, ...currentStep.chapter.order.serialize() }, false);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <CompanyBankAccount />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(CompanyBankAccountStep);
