import React from "react";
import { WithTranslation } from "react-i18next";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  StepComponentProps,
  StepHeading
} from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import GenericAddress from "../../../sharedComponents/GenericAddress/GenericAddress";
import { LowTaxBrokerAddressSchema, RideBrokerAddressSchema } from "lib/validation/AddressSchema";
import { deepClone } from "common/deepClone";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

const CompanyAddressStep = ({
  t,
  order,
  controls,
  currentStep,
  saveData
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const includeBuildingNumber =
    (order.extra.isNewBrokerEnabled &&
      !FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerWithoutHouseNumber)) ||
    !order.extra.isNewBrokerEnabled;

  const initialValues = {
    country: "DE",
    stateCode: order.extra.companyAddress?.stateCode,
    city: order.extra.companyAddress?.city,
    streetLine1: order.extra.companyAddress?.streetLine1,
    streetName: order.extra.companyAddress?.streetName,
    buildingNumber: order.extra.companyAddress?.buildingNumber,
    streetLine2: order.extra.companyAddress?.streetLine2,
    postalCode: order.extra.companyAddress?.postalCode
  };

  async function onSubmit(values) {
    currentStep.complete();

    const valuesCopy = deepClone(values);

    await saveData({
      ...order.extra,
      companyAddress: {
        ...valuesCopy,
        country: "DE"
      },
      ...currentStep.chapter.order.serialize()
    });
  }

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: includeBuildingNumber
      ? LowTaxBrokerAddressSchema(t)
      : RideBrokerAddressSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepHeading text={t("generic:ride-broker-onboarding.company-address.title")} />
        <GenericAddress
          prefix={""}
          title={""}
          displaySectionTitle={false}
          formik={formik}
          disableFields={["country"]}
          includeBuildingNumber={includeBuildingNumber}
        />
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(CompanyAddressStep);
