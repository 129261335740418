import { WithTranslation, withTranslation } from "react-i18next";
import React, { PropsWithChildren } from "react";
import { Button, Col, Row } from "react-bootstrap";

export interface EditableSectionProps extends PropsWithChildren<WithTranslation> {
  title: String;
  testIdPrefix?: String;
  isEditMode: Boolean;
  onEdit: () => void;
  onView: () => void;
  enableSticky?: boolean;
}

const EditableSection = ({
  t,
  children,
  title,
  testIdPrefix,
  isEditMode,
  onEdit,
  onView,
  enableSticky = false
}: EditableSectionProps) => (
  <>
    <h2>
      {!isEditMode && (
        <Button
          variant="primary"
          className="float-right"
          data-testid={`${testIdPrefix ?? ""}edit-button`}
          onClick={onEdit}>
          {t("generic:edit")}
        </Button>
      )}
      <span data-testid={`${testIdPrefix ?? ""}section-title`}>{title}</span>
    </h2>

    <div data-testid={`${testIdPrefix ?? ""}content`}>{children}</div>

    {isEditMode ? (
      <Row className="mt-4 editable-section__sticky-mode">
        <Col>
          <Button
            variant="primary"
            className="float-right"
            data-testid={`${testIdPrefix ?? ""}save-button`}
            type="submit">
            {t("generic:save")}
          </Button>

          <Button
            variant="outline-secondary"
            data-testid={`${testIdPrefix ?? ""}cancel-button`}
            onClick={onView}>
            {t("generic:cancel")}
          </Button>
        </Col>
      </Row>
    ) : (
      enableSticky && (
        <span
          className="editable-section__sticky-mode float-right mt-4"
          data-testid={`${testIdPrefix ?? ""}sticky-edit-btn`}>
          <Button size="sm" onClick={onEdit}>
            {t("generic:edit")}
          </Button>
        </span>
      )
    )}
  </>
);

export default withTranslation(["generic"])(EditableSection);
