import React, { FC } from "react";
import Header from "../../generic/Trader-GmbH/Header";
import { Banner } from "../../generic/Banner";
import { withTranslation, WithTranslation } from "react-i18next";
import { AdminServicePage } from "./AdminServicePage";
import { FlowTypeEnum } from "../../../global-query-types";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";

interface AdminServiceLayoutProps extends WithTranslation {
  flowType: FlowTypeEnum;
}

export const AdminServiceLayout: FC<AdminServiceLayoutProps> = ({ t, flowType }) => {
  const bannerHeading =
    flowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING
      ? t("admin-service:admin-service.banner-heading-onboarding")
      : t("admin-service:admin-service.banner-heading");
  const bannerText =
    flowType === FlowTypeEnum.ADMIN_SERVICE_ONBOARDING
      ? t("admin-service:admin-service.banner-text-onboarding")
      : t("admin-service:admin-service.banner-text");

  return (
    <div className="trader-gmbh">
      <PageMetaData title={t("admin-service:page-title")} />
      <Header />
      <Banner bannerHeading={bannerHeading} bannerText={bannerText} />
      <AdminServicePage t={t} currentFlowType={flowType} />
    </div>
  );
};

export default withTranslation(["admin-service", "trader-gmbh", "generic", "errorCodes"])(
  AdminServiceLayout
);
