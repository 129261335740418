import React from "react";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import ReviewAndSubmitAdminServiceForm from "./ReviewAndSubmitAdminServiceForm";
import { useMutation } from "@apollo/react-hooks";
import { UpsertOrder } from "lib/api/mutations/graphql/UpsertOrder";
import { UPSERT_ORDER } from "lib/api/mutations/UPSERT_ORDER";
import { OrderCompanyStatusEnum } from "global-query-types";
import { StepComponentProps } from "../../ChaptersOrderLayout.partials";
import { AdminServiceOrderData } from "lib/models/client/AdminService/AdminServiceOrderData";

const ReviewAndSubmitAdminServiceFormContainer = (props: StepComponentProps) => {
  const [upsertOrderCall, upsertOrderRequest] = useMutation<UpsertOrder>(UPSERT_ORDER);

  const saveData = async (orderData: AdminServiceOrderData) => {
    await upsertOrderCall({
      variables: {
        id: props.order.id,
        status: OrderCompanyStatusEnum.Booked,
        extra: { ...orderData }
      }
    });
  };

  return (
    <RequestFeedbackHandler requests={[upsertOrderRequest]}>
      <ReviewAndSubmitAdminServiceForm {...props} saveData={saveData} />{" "}
    </RequestFeedbackHandler>
  );
};

export default ReviewAndSubmitAdminServiceFormContainer;
