import gql from "graphql-tag";

export const REAL_ESTATES = gql`
  query realEstates {
    realEstates {
      id
      label
    }
  }
`;
