import React, { FC } from "react";
import { WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import RideProgressBar from "uiLibrary/components/RideProgressBar/RideProgressBar";
import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { FeatureFlags, OrderCompanyStatusEnum } from "global-query-types";
import { CompleteApplicationCard } from "client/components/LowTaxBrokerOverview/CompleteApplicationCard/CompleteApplicationCard";
import { VerifyIdentityCard } from "client/components/LowTaxBrokerOverview/VerifyIdentityCard/VerifyIdentityCard";
import { WithLowTaxBrokerOnboarding } from "lib/models/client/LowTaxBroker/Onboarding";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { RideStatus } from "lib/types/types";
import AdvertisementCampaignBanner from "client/components/AdvertisementCampaignBanner/AdvertisementCampaignBanner";
import ApplicationReviewCard from "./ApplicationReviewCard/ApplicationReviewCard";
import FundAccountCard from "./FundAccountCard/FundAccountCard";
import SetupAccountCard from "./SetupAccountCard/SetupAccountCard";
import { getLowTaxBrokerOverviewStatuses } from "lib/dataAdapters/lowTaxBrokerOverviewStatuses/lowTaxBrokerOverviewStatuses";
import { CompanyById_companyById } from "lib/api/queries/graphql/CompanyById";
import { companyName } from "lib/formatters/companyFormatter";
import { AddExistingCompanyOrderData } from "lib/models/client/AddExistingCompany/AddExistingCompanyOrderData";
import AddCompanyDetailsCard from "client/components/LowTaxBrokerOverview/AddCompanyDetailsCard/AddCompanyDetailsCard";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { useHistory } from "react-router-dom";
import ApplicationReviewWithPendingTasksCard from "./ApplicationReviewWithPendingTasksCard/ApplicationReviewWithPendingTasksCard";
import { UpsertOrderVariables } from "../../../lib/api/mutations/graphql/UpsertOrder";
import { IBKRFileSubmission } from "./ApplicationReviewWithPendingTasksCard/PendingTaskSwitcher/PendingTaskSwitcher";
import { UpsertBrokerageAccountVariables } from "lib/api/mutations/graphql/UpsertBrokerageAccount";

export type LowTaxBrokerData = LowTaxBrokerOrderData & WithLowTaxBrokerOnboarding;

export interface LowTaxBrokerOverviewProps {
  addExistingCompanyOrder:
    | Pick<OrderData<AddExistingCompanyOrderData>, "status" | "id" | "submissionId">
    | null
    | undefined;
  order: OrderData<LowTaxBrokerData> | null | undefined;
  company: CompanyById_companyById | null | undefined;
  createOrder: () => Promise<OrderData<LowTaxBrokerData>>;
  cancelOrder: (orderId: string) => Promise<void>;
  updateOrder: (order: UpsertOrderVariables) => Promise<void>;
  upsertBrokerageAccount: (data: UpsertBrokerageAccountVariables) => Promise<void>;
  goToOrderForm: (order: OrderData<LowTaxBrokerData>) => void;
  handleCreateSSOAndRedirect?: () => Promise<void>;
  handleAgreeToIBKRForm?: (formNumber: string) => Promise<void>;
  handleSubmitFile?: (fileSubmission: IBKRFileSubmission) => Promise<void>;
}

export const LowTaxBrokerOverview = ({
  addExistingCompanyOrder,
  order,
  createOrder,
  cancelOrder,
  updateOrder,
  upsertBrokerageAccount,
  company,
  goToOrderForm,
  handleCreateSSOAndRedirect,
  handleAgreeToIBKRForm,
  handleSubmitFile
}: LowTaxBrokerOverviewProps) => {
  const history = useHistory();

  const addCompanyDetailsCard = FeatureFlagService.instance.isEnabled(
    FeatureFlags.AddCompanyDetailsCardInLowTaxBrokerOnbordingSteps
  );
  if (!addCompanyDetailsCard) {
    addExistingCompanyOrder = undefined;
  }
  const goToAddExistingGmbhForm = () => {
    const addExistingCompanyNewForm = FeatureFlagService.instance.isEnabled(
      FeatureFlags.AddExistingCompanyNewForm
    );
    if (addExistingCompanyNewForm) {
      history.push(
        `/client/order/generic/company-information/0/${addExistingCompanyOrder?.id}/${addExistingCompanyOrder?.submissionId}`
      );
    } else {
      history.push(`/client/my-gmbhs/add/existing`);
    }
  };

  const cardsStatus = getLowTaxBrokerOverviewStatuses(order, addExistingCompanyOrder);
  const numberOfCompletedSteps = Object.values(cardsStatus).filter(
    (step) => step === RideStatus.COMPLETED
  ).length;
  const progressValue = Math.round(
    (numberOfCompletedSteps / Object.keys(cardsStatus).length) * 100
  );

  return (
    <div className="ltb-overview__container">
      <div className="ltb-overview">
        {(!order || order.status === OrderCompanyStatusEnum.Canceled) && <LTBBanner />}
        <Progress percentage={progressValue} />

        <Cards
          cards={[
            addExistingCompanyOrder
              ? ({ cardIndex }) => (
                  <AddCompanyDetailsCard
                    cardIndex={cardIndex}
                    status={cardsStatus.addCompanyDetails ?? RideStatus.COMPLETED}
                    onAddCompanyDetails={goToAddExistingGmbhForm}
                  />
                )
              : undefined,
            ({ cardIndex }) => (
              <CompleteApplicationCard
                cardIndex={cardIndex}
                status={cardsStatus.completeApplication}
                order={order}
                createOrder={createOrder}
                cancelOrder={cancelOrder}
                goToOrderForm={goToOrderForm}
              />
            ),
            order?.extra?.isNewBrokerEnabled
              ? undefined
              : ({ cardIndex }) => (
                  <VerifyIdentityCard
                    cardIndex={cardIndex}
                    status={cardsStatus.verifyId}
                    order={order}
                    updateOrder={updateOrder}
                  />
                ),
            ({ cardIndex }) =>
              order?.extra?.isNewBrokerEnabled ? (
                <ApplicationReviewWithPendingTasksCard
                  cardIndex={cardIndex}
                  status={cardsStatus.applicationReview}
                  pendingTasks={order.extra?.pendingTasks ?? []}
                  order={order}
                  updateOrder={updateOrder}
                  handleCreateSSOAndRedirect={handleCreateSSOAndRedirect}
                  handleAgreeToIBKRForm={handleAgreeToIBKRForm}
                  handleSubmitFile={handleSubmitFile}
                />
              ) : (
                <ApplicationReviewCard
                  cardIndex={cardIndex}
                  status={cardsStatus.applicationReview}
                />
              ),
            ({ cardIndex }) => (
              <SetupAccountCard
                cardIndex={cardIndex}
                order={order}
                updateOrderOnboarding={(orderId, onboarding) =>
                  updateOrder({ id: orderId, extra: { onboarding } })
                }
                status={cardsStatus.setupAccount}
              />
            ),
            ({ cardIndex }) => (
              <FundAccountCard
                cardIndex={cardIndex}
                status={cardsStatus.fundAccount}
                companyName={companyName(company)}
                brokerageAccountNumber={order?.extra.ibCredentials?.accountNumber}
                order={order}
                updateOrder={updateOrder}
                upsertBrokerageAccount={upsertBrokerageAccount}
              />
            )
          ]}
        />
      </div>
    </div>
  );
};

const LTBBanner = withTranslationReady(["generic"])(({ t }: WithTranslation) => (
  <AdvertisementCampaignBanner
    dataTestId="ltb-banner"
    className="ltb-overview__banner"
    title={t(rideBrokerT("generic:low-tax-broker-overview.banner.title"))}
    description={t(rideBrokerT("generic:low-tax-broker-overview.banner.description"))}
    moreInfoUrl={t("generic:low-tax-broker-overview.banner.footer-url")}
  />
));

const Progress = withTranslationReady(["generic"])(
  ({ t, percentage }: { percentage: number } & WithTranslation) => (
    <div className="ltb-overview__progress">
      <RideProgressBar percentage={percentage} />
      <div className="ltb-overview__progress-text">
        {t(rideBrokerT("generic:low-tax-broker-overview.progress-text"), { percentage })}
      </div>
    </div>
  )
);

const Cards = ({ cards }: { cards: (FC<{ cardIndex: number }> | undefined)[] }) => {
  return (
    <div className="ltb-overview__cards">
      {cards
        .filter((card) => !!card)
        .map((Card, index) => Card && <Card key={index} cardIndex={index + 1} />)}
    </div>
  );
};
