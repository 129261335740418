import gql from "graphql-tag";

export const CONFIRM_SHADOW_ACCOUNT = gql`
  mutation ConfirmShadowAccount($verificationToken: ID!, $password: String!) {
    confirmShadowAccount(verificationToken: $verificationToken, password: $password) {
      id
      token
    }
  }
`;
