import React from "react";
import { CompanyCardProps } from "../../../lib/types/types";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { PendingText } from "../../../components/client/company/components/PendingText";
import RideCardValuePair from "../../RideCardValuePair/RideCardValuePair";
import { formatFiscalYear, transformFiscalYearToMoment } from "../../../common/formatters";
import { UserRole } from "../../../global-query-types";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import { doNothing } from "common/stubs";
import { ButtonVariant } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

export interface GmbHFiscalYearDetailsProps extends CompanyCardProps {
  asUser: UserRole;
}

const CompanyFiscalYearCard = ({
  t,
  gmbh,
  handleEditField,
  asUser
}: GmbHFiscalYearDetailsProps) => {
  const isGmbHLockedForUser = () =>
    [UserRole.TaxAdvisor, UserRole.ThirdPartyTaxAdvisor].includes(asUser) ? false : gmbh.locked;

  const fiscalYearStart =
    gmbh.fiscalYear && formatFiscalYear(transformFiscalYearToMoment(gmbh.fiscalYear));
  const fiscalYearEnd =
    gmbh.fiscalYear &&
    formatFiscalYear(transformFiscalYearToMoment(gmbh.fiscalYear).add(-1, "days"));

  return (
    <>
      <RideSectionCard
        title={t("client:company:fiscal-year")}
        data-testid="gmbh-fiscal-year-details"
        className="shadow-none my-5">
        <ConfirmationModal
          confirmButtonVariant={ButtonVariant.Primary}
          title={t(`client:company.modal.fiscal-year`)}
          cancelLabel={t("generic:no")}
          confirmLabel={t("generic:yes")}
          onConfirm={handleEditField ? handleEditField("fiscal-year") : doNothing}>
          {(setVisibility) => (
            <RideCardValuePair
              data-testid="company-fiscal-year"
              onEdit={handleEditField ? () => setVisibility(true) : undefined}
              isLocked={isGmbHLockedForUser()}
              lockTooltipContent={t("generic:fieldLocked")}>
              {gmbh.fiscalYear ? (
                <span>
                  {t("client:company.fiscal-year-dates-from")}
                  <span className="font-weight-bold">{fiscalYearStart}</span>
                  {t("client:company.fiscal-year-dates-to")}
                  <span className="font-weight-bold">{fiscalYearEnd}</span>
                </span>
              ) : (
                <PendingText t={t} />
              )}
            </RideCardValuePair>
          )}
        </ConfirmationModal>
      </RideSectionCard>
    </>
  );
};

export default CompanyFiscalYearCard;
