import React, { ChangeEvent } from "react";
import { FormikProps } from "formik";
import { TFunction } from "i18next";
import {
  KYCStatusRadioField,
  ShortIncompleteReasonField
} from "../../../generic/kyc-update-form/fields";

interface EditKYCStatusProps {
  formik: FormikProps<any>;
  selectedIndex: number;
  handleRadioChange: (event: ChangeEvent<HTMLInputElement>) => void;
  KYCStatuses: { [key: string]: number };
  t: TFunction;
  InfoComponent: ({ t }: { t: TFunction }) => JSX.Element;
}

export const EditKYCStatus = ({
  formik,
  selectedIndex,
  handleRadioChange,
  KYCStatuses,
  t,
  InfoComponent
}: EditKYCStatusProps) => {
  const statusCheckboxes = Object.keys(KYCStatuses).map((kycStatus) => ({
    label: t(`nominee-dashboard:kyc-status.checkboxLabels.${kycStatus.toLowerCase()}`)
  }));

  return (
    <>
      <InfoComponent t={t} />

      <KYCStatusRadioField
        options={statusCheckboxes}
        handleChange={handleRadioChange}
        currentSelectedIndex={selectedIndex}
      />

      <ShortIncompleteReasonField t={t} formik={formik} rowCount={3} />
    </>
  );
};
